import { css } from '@emotion/react';
import { DateTime } from 'luxon';

import {
  USER_AFFILIATIONS,
  US_STATES,
  DISTANCE_FILTER_IN_MILES,
  SelectOption,
  USER_GENDERS,
  UserSchoolMembershipStatus,
} from '@jebel/constants';

import { FIELDS_CONFIG } from 'shared/constants';
import { HeadlinesType, SpreadsheetFiltersType } from 'shared/features/spreadsheet';
import {
  OrganizationInfoFragment,
  SortOrder,
  UserSchoolMembershipFilter,
  UserSchoolMembershipSort,
} from 'shared/graphql';

export enum MembersReportHeaders {
  name = 'Name',
  email = 'Email Address',
  type = 'Type',
  gender = 'Gender',
  birthDate = 'Date Of Birth',
  GraduatingYear = 'Graduating Year',
  PhoneNumber = 'Phone Number',
  posts = 'Posts',
  roles = 'Roles',
  createdOn = 'Created On',
  MemberSince = 'Member Since',
  status = 'Status',
}

const GENDER_FILTER_OPTIONS: SelectOption[] = [
  {
    value: USER_GENDERS.male,
    label: 'Male',
  },
  {
    value: USER_GENDERS.female,
    label: 'Female',
  },
  {
    value: 'EMPTY',
    label: '(Empty)',
  },
];

const STATUSES_FILTER_OPTIONS: SelectOption[] = [
  {
    value: UserSchoolMembershipStatus.Active,
    label: 'Active',
  },
  {
    value: UserSchoolMembershipStatus.Inactive,
    label: 'Inactive',
  },
  {
    value: UserSchoolMembershipStatus.InvitationSent,
    label: 'Invitation Sent',
  },
  {
    value: UserSchoolMembershipStatus.Pending,
    label: 'Pending',
  },
  {
    value: UserSchoolMembershipStatus.Rejected,
    label: 'Rejected',
  },
];

const AFFILIATION_FILTER_OPTIONS: SelectOption[] = [
  {
    value: USER_AFFILIATIONS.staff,
    label: 'Staff',
  },
  {
    value: USER_AFFILIATIONS.alumni,
    label: 'Alumni',
  },
  {
    value: USER_AFFILIATIONS.student,
    label: 'Student',
  },
  {
    value: USER_AFFILIATIONS.parent,
    label: 'Parent',
  },
  {
    value: USER_AFFILIATIONS.other,
    label: 'Other',
  },
];

export const MembersSpreadsheetHeader: HeadlinesType<UserSchoolMembershipSort> = [
  {
    name: 'name',
    label: 'NAME',
    id: 'name',
    sortPath: (order: SortOrder) => [{ user: { firstName: order } }, { user: { lastName: order } }],
    css: css`
      min-width: 10rem;
    `,
  },
  {
    name: 'email',
    label: 'EMAIL ADDRESS',
    id: 'email',
    sortPath: (order: SortOrder) => [{ user: { email: order } }],
    css: css`
      width: 14.6%;
    `,
  },
  {
    name: 'type',
    label: 'TYPE',
    id: 'type',
    sortPath: (order: SortOrder) => [{ user: { affiliation: order } }],
    css: css`
      width: 8.5%;
    `,
  },
  {
    name: 'gender',
    label: 'GENDER',
    id: 'gender',
    sortPath: (order: SortOrder) => [{ user: { gender: order } }],
  },
  {
    name: 'birthDate',
    label: 'DATE OF BIRTH',
    id: 'birthDate',
    sortPath: (order: SortOrder) => [{ user: { birthDate: order } }],
    css: css`
      width: 8.75%;
    `,
  },
  {
    id: 'graduatingYear',
    name: 'graduatingYear',
    label: 'GRADUATING YEAR',
    sortPath: (order: SortOrder) => [{ user: { graduatingYear: order } }],
    css: css`
      width: 8.75%;
    `,
  },
  {
    name: 'posts',
    label: 'POSTS',
    id: 'posts',
    css: css`
      width: 5.8%;
    `,
  },
  {
    name: 'roles',
    label: 'ROLES',
    id: 'roles',
    css: css`
      width: 8.5%;
    `,
  },
  {
    name: 'createdAt',
    label: 'CREATED ON',
    id: 'createdAt',
    sortPath: (order: SortOrder) => [{ user: { createdAt: order } }],
    css: css`
      width: 10.3%;
    `,
  },
  {
    id: 'status',
    name: 'status',
    label: 'STATUS',
    sortPath: (order: SortOrder) => [{ status: order }],
    css: css`
      width: 13.96%;
    `,
  },
];

export const memberSpreadsheetFilters = (context: {
  organizations: OrganizationInfoFragment[];
  graduatingYears: SelectOption[];
}): SpreadsheetFiltersType<UserSchoolMembershipFilter> => {
  return [
    {
      type: 'select',
      variants: AFFILIATION_FILTER_OPTIONS,
      label: 'Affiliation',
      name: 'affiliation',
      placeholder: 'User affiliation',

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        return {
          user: { affiliation: { equals: value } },
        };
      },
    },

    {
      type: 'select',
      name: 'gender',
      label: 'Gender',
      placeholder: 'Gender',

      variants: GENDER_FILTER_OPTIONS,

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        if (value === 'EMPTY') {
          return {
            user: { gender: { is_empty: true } },
          };
        }

        return {
          user: { gender: { equals: value } },
        };
      },
    },
    {
      type: 'select',
      variants: STATUSES_FILTER_OPTIONS,
      label: 'Status',
      name: 'status',
      placeholder: 'User Status',

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        return {
          status: { equals: value },
        };
      },
    },

    {
      type: 'select',
      label: 'Class Year',
      name: 'graduatingYear',
      placeholder: 'Years',
      size: 'full',

      variants: context.graduatingYears.map(option => ({ value: option.value })),

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        const date = DateTime.now().set({ year: Number(value), month: 1, day: 1 });

        return {
          user: { graduatingYear: { equals: date.toSQLDate() } },
        };
      },
    },

    {
      type: 'select',
      variants: FIELDS_CONFIG.industry.map(({ label }) => ({ value: label })),
      label: 'Industry',
      name: 'industry',
      placeholder: 'Select Industry',
      size: 'full',

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        return {
          user: {
            currentIndustry: {
              equals: FIELDS_CONFIG.industry?.find(({ label }) => label === value)?.value,
            },
          },
        };
      },
    },
    {
      type: 'input',
      name: 'city',
      label: 'City',

      filterPath: (value: string) => ({
        user: { userPreferences: { address: { city: { contains: value } } } },
      }),
    },
    {
      type: 'select',
      variants: US_STATES.map(({ abbreviation }) => ({ value: abbreviation })),
      label: 'State',
      name: 'state',
      placeholder: 'Select State',

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        return {
          user: { userPreferences: { address: { state: { equals: value } } } },
        };
      },
    },
    {
      type: 'input',
      name: 'zip',
      label: 'ZIP Code',

      customFilterPath: (value: string) => ({
        startPointZip: value,
      }),
    },
    {
      type: 'select',
      variants: Object.values(DISTANCE_FILTER_IN_MILES)
        .slice(1, 5)
        .map(distance => ({
          value: distance.toString(),
        })),
      label: 'Distance',
      name: 'distance',
      customFilterPath: (value: string) => ({
        radius: value.split(' ')[0],
      }),
    },
    {
      type: 'select',
      variants: context.organizations.map(({ name }) => ({ value: name || '' })),
      label: 'Related organizations',
      name: 'relatedOrganizations',
      placeholder: 'Select organization',
      size: 'full',

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        return {
          user: { ownedOrganizations: { some: { name: { equals: value } } } },
        };
      },
    },
    {
      type: 'input',
      name: 'groups',
      label: 'Groups',
      size: 'full',

      filterPath: (value: string) => ({
        user: { joinedGroups: { some: { title: { contains: value } } } },
      }),
    },
    {
      type: 'input',
      name: 'education',
      label: 'Education',
      size: 'full',

      filterPath: (value: string) => ({
        user: { education: { some: { degree: { contains: value } } } },
      }),
    },
  ];
};
