import { ChangeEvent, useState } from 'react';
import * as yup from 'yup';

import { REGEX_SQL_FORMAT, USER_AFFILIATIONS } from '@jebel/constants';
import { formatSQLDate, readCSV } from '@jebel/utils';

import { recordDebug, recordError } from 'shared/utils/record';

import { usePreventBrowserClose } from './usePreventBrowserClose';
import { useToast } from './useToast';

const CSV_EXTENSION = '.csv';

enum Header {
  AFFILIATION = 'Affiliation',
  DESCRIPTION = 'Affiliation Description',
  EMAIL = 'Email',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  PHONE_NUMBER = 'Phone Number',
  GRADUATION_YEAR = 'Graduation Year',
}

export interface UserInvitationParsed {
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber?: string;
  affiliation: string;
  affiliationDescription?: string;
  graduatingYear?: string;
}

const UserInvitationParsedSchema = yup.object({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  email: yup.string().email().required(),

  affiliation: yup
    .string()
    .oneOf(Object.values(USER_AFFILIATIONS))
    .required()
    .transform(value => value.toLowerCase()),

  affiliationDescription: yup.string(),

  graduatingYear: yup
    .string()
    .matches(REGEX_SQL_FORMAT, "Invalid graduating year format, use 'YYYY-MM-DD'."),

  phoneNumber: yup.string(),
});

interface Options {
  /**
   * Handles the start of the CSV parsing.
   */
  onStart?(): void;

  /**
   * Handles the completion of the CSV parsing.
   * @param parsed The parsed user invitations.
   */
  onComplete?(parsed: UserInvitationParsed[]): Promise<void> | void;
}

/**
 * Custom hook for reading and parsing a CSV file containing user invitations.
 * @param options - Optional configuration options.
 * @returns An object with the following properties:
 *   - `reading`: A boolean indicating if the CSV file is being read.
 *   - `handle`: A function to handle the file change event.
 */
export function useUserInvitationCSV(options?: Options) {
  const [reading, setReading] = useState(false);

  const { showError } = useToast();
  const { prevent: preventClose, allow: allowClose } = usePreventBrowserClose();

  return {
    /**
     * Indicates if the CSV file is being read.
     */
    reading,

    /**
     * Handles the {@linkcode HTMLInputElement} change event.
     * @param event The file change event.
     */
    async handle(event: ChangeEvent<HTMLInputElement>): Promise<void> {
      const file = event.target.files?.[0];

      if (!file) {
        return;
      }

      if (!file.name?.endsWith(CSV_EXTENSION)) {
        showError('Please check the file type. Only CSV files are allowed.');
        return;
      }

      setReading(true);
      preventClose('Please do not close this tab while the reading process is in progress.');

      options?.onStart?.();

      try {
        const text = await file.text();

        const content = readCSV<Record<string, string>>(text, {
          hasHeaders: true,
          skipEmpty: true,
        });

        const invitations: UserInvitationParsed[] = [];

        for (const item of content) {
          const invitation: UserInvitationParsed = UserInvitationParsedSchema.validateSync({
            firstname: item[Header.FIRST_NAME],
            lastName: item[Header.LAST_NAME],
            email: item[Header.EMAIL],
            affiliation: item[Header.AFFILIATION],
            affiliationDescription: item[Header.DESCRIPTION],
            graduatingYear: item[Header.GRADUATION_YEAR],
            phoneNumber: item[Header.PHONE_NUMBER],
          });

          if (invitation.affiliation === USER_AFFILIATIONS.alumni && !invitation.graduatingYear) {
            throw new Error(`Missing graduation year for email: ${invitation.email}`);
          }

          if (
            invitation.affiliation === USER_AFFILIATIONS.other &&
            !invitation.affiliationDescription
          ) {
            throw new Error(`Missing affiliation description for email: ${invitation.email}`);
          }

          if (invitation.graduatingYear) {
            invitation.graduatingYear = formatSQLDate(invitation.graduatingYear, {
              // Throws an error if the date cannot be parsed.
              // https://github.com/jebelapp/jebel/issues/1747
              throwsOnInvalid: true,
            });
          }

          invitations.push(invitation);
        }

        recordDebug(`${invitations.length} user invitation(s) parsed`);
        await options?.onComplete?.(invitations);
      } catch (err) {
        recordError(err);

        if (err instanceof yup.ValidationError) {
          for (const error of err.errors) {
            showError(error, { reportable: false });
          }
        }

        if (err instanceof Error) {
          showError(err.message);
        }
      } finally {
        setReading(false);
        allowClose();
      }
    },
  };
}
