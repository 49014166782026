import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Date: any;
  DateTime: any;
  JSON: any;
  Time: any;
};

/** RoleAddon create input from accessibleLayouts */
export type AccessibleLayouts_RoleAddonCreateInput = {
  accessibleLayouts?: InputMaybe<RoleAddonAccessibleLayoutsRelationInput>;
  businessPermissions?: InputMaybe<Scalars['JSON']>;
  forumPermissions?: InputMaybe<Scalars['JSON']>;
  groupsPermissions?: InputMaybe<Scalars['JSON']>;
  /** Define the permissions within the "Home Posts". */
  homePostsPermissions?: InputMaybe<Scalars['JSON']>;
  /** Assigning this role to a user at registration */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isSystemRole?: InputMaybe<Scalars['Boolean']>;
  membersPermissions?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<RoleAddonRoleRelationInput>;
  school?: InputMaybe<RoleAddonSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  schoolPostsPermissions?: InputMaybe<Scalars['JSON']>;
  users?: InputMaybe<RoleAddonUsersRelationInput>;
};

/** RoleAddon update input from accessibleLayouts */
export type AccessibleLayouts_RoleAddonUpdateInput = {
  data: RoleAddonUpdateInput;
  filter?: InputMaybe<RoleAddonKeyFilter>;
};

export type AdClick = {
  __typename?: 'AdClick';
  _description?: Maybe<Scalars['String']>;
  ad?: Maybe<AdvertisingCampaignAd>;
  /** Cost of this impression expressed on USD dollars. */
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

/** AdClicks create input */
export type AdClickCreateInput = {
  ad: AdClicksAdRelationInput;
  /** Cost of this impression expressed on USD dollars. */
  cost?: InputMaybe<Scalars['Float']>;
  school?: InputMaybe<AdClicksSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user: AdClicksUserRelationInput;
};

/** AdClicks create many input */
export type AdClickCreateManyInput = {
  ad: AdClicksAdManyRelationInput;
  /** Cost of this impression expressed on USD dollars. */
  cost?: InputMaybe<Scalars['Float']>;
  school?: InputMaybe<AdClicksSchoolManyRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user: AdClicksUserManyRelationInput;
};

/** AdClicks delete input */
export type AdClickDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** AdClickFieldsPermissions create input */
export type AdClickFieldsPermissions = {
  cost?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AdClickFilter = {
  AND?: InputMaybe<Array<AdClickFilter>>;
  OR?: InputMaybe<Array<AdClickFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  ad?: InputMaybe<AdvertisingCampaignAdFilter>;
  cost?: InputMaybe<FloatPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type AdClickGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: AdClickGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type AdClickGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  ad?: InputMaybe<AdvertisingCampaignAdGroupByQuery>;
  cost?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type AdClickKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** AdClickListResponse output */
export type AdClickListResponse = {
  __typename?: 'AdClickListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<AdClick>;
};

/** AdClickManyResponse output */
export type AdClickManyResponse = {
  __typename?: 'AdClickManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<AdClick>;
};

/** No longer supported. Use `sort` instead. */
export type AdClickOrderBy =
  | 'cost_ASC'
  | 'cost_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** AdClicks subscription payload */
export type AdClickPayload = {
  __typename?: 'AdClickPayload';
  mutation: MutationType;
  node?: Maybe<AdClick>;
  previousValues?: Maybe<AdClick>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AdClickRelationFilter = {
  every?: InputMaybe<AdClickFilter>;
  none?: InputMaybe<AdClickFilter>;
  some?: InputMaybe<AdClickFilter>;
};

export type AdClickSort = {
  ad?: InputMaybe<AdvertisingCampaignAdSort>;
  cost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

/** AdClicks subscription filter */
export type AdClickSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<AdClickFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** AdClicks update input */
export type AdClickUpdateByFilterInput = {
  cost?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** AdClicks update input */
export type AdClickUpdateInput = {
  ad?: InputMaybe<AdClicksAdUpdateRelationInput>;
  /** Cost of this impression expressed on USD dollars. */
  cost?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  school?: InputMaybe<AdClicksSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<AdClicksUserUpdateRelationInput>;
};

export type AdClick_PermissionFilter = {
  AND?: InputMaybe<Array<AdClick_PermissionFilter>>;
  OR?: InputMaybe<Array<AdClick_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  ad?: InputMaybe<AdvertisingCampaignAd_PermissionFilter>;
  cost?: InputMaybe<FloatPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
};

export type AdClick_PermissionRelationFilter = {
  every?: InputMaybe<AdClick_PermissionFilter>;
  none?: InputMaybe<AdClick_PermissionFilter>;
  some?: InputMaybe<AdClick_PermissionFilter>;
};

/** AdClicks relation input */
export type AdClicksAdManyRelationInput = {
  connect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
};

/** AdClicks relation input */
export type AdClicksAdRelationInput = {
  connect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  create?: InputMaybe<UserClicks_AdvertisingCampaignAdCreateInput>;
};

/** AdClicks relation input */
export type AdClicksAdUpdateRelationInput = {
  connect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  create?: InputMaybe<UserClicks_AdvertisingCampaignAdCreateInput>;
  disconnect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  reconnect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  update?: InputMaybe<UserClicks_AdvertisingCampaignAdUpdateInput>;
};

/** AdClicks relation input */
export type AdClicksSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** AdClicks relation input */
export type AdClicksSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<AdClicks_SchoolConfigurationCreateInput>;
};

/** AdClicks relation input */
export type AdClicksSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<AdClicks_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<AdClicks_SchoolConfigurationUpdateInput>;
};

/** AdClicks relation input */
export type AdClicksUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** AdClicks relation input */
export type AdClicksUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<AdClicks_UserCreateInput>;
};

/** AdClicks relation input */
export type AdClicksUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<AdClicks_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<AdClicks_UserUpdateInput>;
};

/** SchoolConfiguration create input from adClicks */
export type AdClicks_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address: SchoolConfigurationAddressRelationInput;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from adClicks */
export type AdClicks_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** Users create input from adClicks */
export type AdClicks_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from adClicks */
export type AdClicks_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Users create input from adLikes */
export type AdLikes_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from adLikes */
export type AdLikes_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

export type AdView = {
  __typename?: 'AdView';
  _description?: Maybe<Scalars['String']>;
  ad?: Maybe<AdvertisingCampaignAd>;
  /** Cost of this impression expressed on USD dollars. */
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

/** AdViews create input */
export type AdViewCreateInput = {
  ad?: InputMaybe<AdViewsAdRelationInput>;
  /** Cost of this impression expressed on USD dollars. */
  cost?: InputMaybe<Scalars['Float']>;
  school?: InputMaybe<AdViewsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<AdViewsUserRelationInput>;
};

/** AdViews create many input */
export type AdViewCreateManyInput = {
  ad: AdViewsAdManyRelationInput;
  /** Cost of this impression expressed on USD dollars. */
  cost?: InputMaybe<Scalars['Float']>;
  school?: InputMaybe<AdViewsSchoolManyRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user: AdViewsUserManyRelationInput;
};

/** AdViews delete input */
export type AdViewDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** AdViewFieldsPermissions create input */
export type AdViewFieldsPermissions = {
  cost?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AdViewFilter = {
  AND?: InputMaybe<Array<AdViewFilter>>;
  OR?: InputMaybe<Array<AdViewFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  ad?: InputMaybe<AdvertisingCampaignAdFilter>;
  cost?: InputMaybe<FloatPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type AdViewGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: AdViewGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type AdViewGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  ad?: InputMaybe<AdvertisingCampaignAdGroupByQuery>;
  cost?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type AdViewKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** AdViewListResponse output */
export type AdViewListResponse = {
  __typename?: 'AdViewListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<AdView>;
};

/** AdViewManyResponse output */
export type AdViewManyResponse = {
  __typename?: 'AdViewManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<AdView>;
};

/** No longer supported. Use `sort` instead. */
export type AdViewOrderBy =
  | 'cost_ASC'
  | 'cost_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** AdViews subscription payload */
export type AdViewPayload = {
  __typename?: 'AdViewPayload';
  mutation: MutationType;
  node?: Maybe<AdView>;
  previousValues?: Maybe<AdView>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AdViewRelationFilter = {
  every?: InputMaybe<AdViewFilter>;
  none?: InputMaybe<AdViewFilter>;
  some?: InputMaybe<AdViewFilter>;
};

export type AdViewSort = {
  ad?: InputMaybe<AdvertisingCampaignAdSort>;
  cost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

/** AdViews subscription filter */
export type AdViewSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<AdViewFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** AdViews update input */
export type AdViewUpdateByFilterInput = {
  cost?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** AdViews update input */
export type AdViewUpdateInput = {
  ad?: InputMaybe<AdViewsAdUpdateRelationInput>;
  /** Cost of this impression expressed on USD dollars. */
  cost?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  school?: InputMaybe<AdViewsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<AdViewsUserUpdateRelationInput>;
};

export type AdView_PermissionFilter = {
  AND?: InputMaybe<Array<AdView_PermissionFilter>>;
  OR?: InputMaybe<Array<AdView_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  ad?: InputMaybe<AdvertisingCampaignAd_PermissionFilter>;
  cost?: InputMaybe<FloatPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
};

export type AdView_PermissionRelationFilter = {
  every?: InputMaybe<AdView_PermissionFilter>;
  none?: InputMaybe<AdView_PermissionFilter>;
  some?: InputMaybe<AdView_PermissionFilter>;
};

/** AdViews relation input */
export type AdViewsAdManyRelationInput = {
  connect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
};

/** AdViews relation input */
export type AdViewsAdRelationInput = {
  connect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  create?: InputMaybe<UserViews_AdvertisingCampaignAdCreateInput>;
};

/** AdViews relation input */
export type AdViewsAdUpdateRelationInput = {
  connect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  create?: InputMaybe<UserViews_AdvertisingCampaignAdCreateInput>;
  disconnect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  reconnect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  update?: InputMaybe<UserViews_AdvertisingCampaignAdUpdateInput>;
};

/** AdViews relation input */
export type AdViewsSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** AdViews relation input */
export type AdViewsSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<AdViews_SchoolConfigurationCreateInput>;
};

/** AdViews relation input */
export type AdViewsSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<AdViews_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<AdViews_SchoolConfigurationUpdateInput>;
};

/** AdViews relation input */
export type AdViewsUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** AdViews relation input */
export type AdViewsUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<AdViews_UserCreateInput>;
};

/** AdViews relation input */
export type AdViewsUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<AdViews_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<AdViews_UserUpdateInput>;
};

/** SchoolConfiguration create input from adViews */
export type AdViews_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from adViews */
export type AdViews_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** Users create input from adViews */
export type AdViews_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from adViews */
export type AdViews_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** AdClicks create input from ad */
export type Ad_AdClickCreateInput = {
  ad?: InputMaybe<AdClicksAdRelationInput>;
  /** Cost of this impression expressed on USD dollars. */
  cost?: InputMaybe<Scalars['Float']>;
  school?: InputMaybe<AdClicksSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<AdClicksUserRelationInput>;
};

/** AdClicks update input from ad */
export type Ad_AdClickUpdateInput = {
  data: AdClickUpdateInput;
  filter?: InputMaybe<AdClickKeyFilter>;
};

/** AdViews create input from ad */
export type Ad_AdViewCreateInput = {
  ad?: InputMaybe<AdViewsAdRelationInput>;
  /** Cost of this impression expressed on USD dollars. */
  cost?: InputMaybe<Scalars['Float']>;
  school?: InputMaybe<AdViewsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<AdViewsUserRelationInput>;
};

/** AdViews update input from ad */
export type Ad_AdViewUpdateInput = {
  data: AdViewUpdateInput;
  filter?: InputMaybe<AdViewKeyFilter>;
};

/** Audiences create input from ad */
export type Ad_AudienceCreateInput = {
  ad?: InputMaybe<AudiencesAdRelationInput>;
  affiliation?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  distanceFromZip?: InputMaybe<Scalars['Int']>;
  endAge?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  groups?: InputMaybe<AudiencesGroupsRelationInput>;
  highSchools?: InputMaybe<AudiencesHighSchoolsRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  organization?: InputMaybe<AudiencesOrganizationRelationInput>;
  schoolCommunities?: InputMaybe<AudiencesSchoolCommunitiesRelationInput>;
  size?: InputMaybe<Scalars['Int']>;
  startAge?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zipCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Audiences update input from ad */
export type Ad_AudienceUpdateInput = {
  data: AudienceUpdateInput;
  filter?: InputMaybe<AudienceKeyFilter>;
};

/** BillingRegisteredAds create input from ad */
export type Ad_BillingRegisteredAdCreateInput = {
  ad?: InputMaybe<BillingRegisteredAdsAdRelationInput>;
  billingAd?: InputMaybe<BillingRegisteredAdsBillingAdRelationInput>;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  price?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

/** BillingRegisteredAds update input from ad */
export type Ad_BillingRegisteredAdUpdateInput = {
  data: BillingRegisteredAdUpdateInput;
  filter?: InputMaybe<BillingRegisteredAdKeyFilter>;
};

/** Roles create input from addon */
export type Addon_RoleCreateInput = {
  addon?: InputMaybe<RolesAddonRelationInput>;
  apiTokens?: InputMaybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: InputMaybe<RolesAuthenticationProfilesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notificationTemplates?: InputMaybe<RolesNotificationTemplatesRelationInput>;
  permissions?: InputMaybe<PermissionsInput>;
  teamMembers?: InputMaybe<RolesTeamMembersRelationInput>;
  users?: InputMaybe<RolesUsersRelationInput>;
};

/** Roles update input from addon */
export type Addon_RoleUpdateInput = {
  addon?: InputMaybe<RolesAddonUpdateRelationInput>;
  apiTokens?: InputMaybe<RolesApiTokensUpdateRelationInput>;
  authenticationProfiles?: InputMaybe<RolesAuthenticationProfilesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notificationTemplates?: InputMaybe<RolesNotificationTemplatesUpdateRelationInput>;
  permissions?: InputMaybe<PermissionsInput>;
  teamMembers?: InputMaybe<RolesTeamMembersUpdateRelationInput>;
  users?: InputMaybe<RolesUsersUpdateRelationInput>;
};

export type Address = {
  __typename?: 'Address';
  _description?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  configuration?: Maybe<SchoolConfiguration>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  educationalInstitutions?: Maybe<EducationalInstitution>;
  events?: Maybe<EventListResponse>;
  id?: Maybe<Scalars['ID']>;
  organizations?: Maybe<OrganizationLocation>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usersAdresses?: Maybe<UserPreferenceListResponse>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EventGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EventOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EventSort>>;
};

export type AddressUsersAdressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserPreferenceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserPreferenceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserPreferenceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserPreferenceSort>>;
};

/** Addresses create input */
export type AddressCreateInput = {
  city?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<AddressesConfigurationRelationInput>;
  educationalInstitutions?: InputMaybe<AddressesEducationalInstitutionsRelationInput>;
  events?: InputMaybe<AddressesEventsRelationInput>;
  organizations?: InputMaybe<AddressesOrganizationsRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  usersAdresses?: InputMaybe<AddressesUsersAdressesRelationInput>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Addresses create many input */
export type AddressCreateManyInput = {
  city?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<AddressesConfigurationManyRelationInput>;
  educationalInstitutions?: InputMaybe<AddressesEducationalInstitutionsManyRelationInput>;
  events?: InputMaybe<AddressesEventsManyRelationInput>;
  organizations?: InputMaybe<AddressesOrganizationsManyRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  usersAdresses?: InputMaybe<AddressesUsersAdressesManyRelationInput>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Addresses delete input */
export type AddressDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** AddressFieldsPermissions create input */
export type AddressFieldsPermissions = {
  city?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['Boolean']>;
  street1?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  zip?: InputMaybe<Scalars['Boolean']>;
};

export type AddressFilter = {
  AND?: InputMaybe<Array<AddressFilter>>;
  OR?: InputMaybe<Array<AddressFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<StringPredicate>;
  configuration?: InputMaybe<SchoolConfigurationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  educationalInstitutions?: InputMaybe<EducationalInstitutionFilter>;
  events?: InputMaybe<EventRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  organizations?: InputMaybe<OrganizationLocationFilter>;
  state?: InputMaybe<StringPredicate>;
  street1?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  usersAdresses?: InputMaybe<UserPreferenceRelationFilter>;
  zip?: InputMaybe<StringPredicate>;
};

export type AddressGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: AddressGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type AddressGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  city?: InputMaybe<Array<GroupByField>>;
  configuration?: InputMaybe<SchoolConfigurationGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  educationalInstitutions?: InputMaybe<EducationalInstitutionGroupByQuery>;
  events?: InputMaybe<EventGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  organizations?: InputMaybe<OrganizationLocationGroupByQuery>;
  state?: InputMaybe<Array<GroupByField>>;
  street1?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  usersAdresses?: InputMaybe<UserPreferenceGroupByQuery>;
  zip?: InputMaybe<Array<GroupByField>>;
};

export type AddressKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** AddressListResponse output */
export type AddressListResponse = {
  __typename?: 'AddressListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Address>;
};

/** AddressManyResponse output */
export type AddressManyResponse = {
  __typename?: 'AddressManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Address>;
};

/** No longer supported. Use `sort` instead. */
export type AddressOrderBy =
  | 'city_ASC'
  | 'city_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'state_ASC'
  | 'state_DESC'
  | 'street1_ASC'
  | 'street1_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'zip_ASC'
  | 'zip_DESC';

/** Addresses subscription payload */
export type AddressPayload = {
  __typename?: 'AddressPayload';
  mutation: MutationType;
  node?: Maybe<Address>;
  previousValues?: Maybe<Address>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AddressRelationFilter = {
  every?: InputMaybe<AddressFilter>;
  none?: InputMaybe<AddressFilter>;
  some?: InputMaybe<AddressFilter>;
};

export type AddressSort = {
  city?: InputMaybe<SortOrder>;
  configuration?: InputMaybe<SchoolConfigurationSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  educationalInstitutions?: InputMaybe<EducationalInstitutionSort>;
  id?: InputMaybe<SortOrder>;
  organizations?: InputMaybe<OrganizationLocationSort>;
  state?: InputMaybe<SortOrder>;
  street1?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

/** Addresses subscription filter */
export type AddressSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<AddressFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** Addresses update input */
export type AddressUpdateByFilterInput = {
  city?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  state?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  street1?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  zip?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** Addresses update input */
export type AddressUpdateInput = {
  city?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<AddressesConfigurationUpdateRelationInput>;
  educationalInstitutions?: InputMaybe<AddressesEducationalInstitutionsUpdateRelationInput>;
  events?: InputMaybe<AddressesEventsUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  organizations?: InputMaybe<AddressesOrganizationsUpdateRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  usersAdresses?: InputMaybe<AddressesUsersAdressesUpdateRelationInput>;
  zip?: InputMaybe<Scalars['String']>;
};

/** EducationalInstitutions create input from address */
export type Address_EducationalInstitutionCreateInput = {
  activities?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<EducationalInstitutionsAddressRelationInput>;
  degree?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  fieldOfStudy?: InputMaybe<Scalars['String']>;
  gpa?: InputMaybe<Scalars['Float']>;
  highSchool?: InputMaybe<EducationalInstitutionsHighSchoolRelationInput>;
  highSchoolId?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<EducationalInstitutionsImageRelationInput>;
  imageId?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
  user?: InputMaybe<EducationalInstitutionsUserRelationInput>;
};

/** EducationalInstitutions update input from address */
export type Address_EducationalInstitutionUpdateInput = {
  activities?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<EducationalInstitutionsAddressUpdateRelationInput>;
  degree?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  fieldOfStudy?: InputMaybe<Scalars['String']>;
  gpa?: InputMaybe<Scalars['Float']>;
  highSchool?: InputMaybe<EducationalInstitutionsHighSchoolUpdateRelationInput>;
  highSchoolId?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<EducationalInstitutionsImageUpdateRelationInput>;
  imageId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  user?: InputMaybe<EducationalInstitutionsUserUpdateRelationInput>;
};

/** OrganizationLocations create input from address */
export type Address_OrganizationLocationCreateInput = {
  address?: InputMaybe<OrganizationLocationsAddressRelationInput>;
  contactName: Scalars['String'];
  email: Scalars['String'];
  link: Scalars['String'];
  locationName: Scalars['String'];
  operationalHours: Scalars['String'];
  organization?: InputMaybe<OrganizationLocationsOrganizationRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** OrganizationLocations update input from address */
export type Address_OrganizationLocationUpdateInput = {
  address?: InputMaybe<OrganizationLocationsAddressUpdateRelationInput>;
  contactName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  locationName?: InputMaybe<Scalars['String']>;
  operationalHours?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationLocationsOrganizationUpdateRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type Address_PermissionFilter = {
  AND?: InputMaybe<Array<Address_PermissionFilter>>;
  OR?: InputMaybe<Array<Address_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<StringPredicate>;
  configuration?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  educationalInstitutions?: InputMaybe<EducationalInstitution_PermissionFilter>;
  events?: InputMaybe<Event_PermissionRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  organizations?: InputMaybe<OrganizationLocation_PermissionFilter>;
  state?: InputMaybe<StringPredicate>;
  street1?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  usersAdresses?: InputMaybe<UserPreference_PermissionRelationFilter>;
  zip?: InputMaybe<StringPredicate>;
};

export type Address_PermissionRelationFilter = {
  every?: InputMaybe<Address_PermissionFilter>;
  none?: InputMaybe<Address_PermissionFilter>;
  some?: InputMaybe<Address_PermissionFilter>;
};

/** SchoolConfiguration create input from address */
export type Address_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from address */
export type Address_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** UserPreferences create input from address */
export type Address_UserPreferenceCreateInput = {
  address?: InputMaybe<UserPreferencesAddressRelationInput>;
  jobsOfInterest?: InputMaybe<UserPreferencesJobsOfInterestRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  secondaryPhoneNumber?: InputMaybe<Scalars['String']>;
  stripeInformation?: InputMaybe<UserPreferencesStripeInformationRelationInput>;
  user?: InputMaybe<UserPreferencesUserRelationInput>;
};

/** UserPreferences update input from address */
export type Address_UserPreferenceUpdateInput = {
  data: UserPreferenceUpdateInput;
  filter?: InputMaybe<UserPreferenceKeyFilter>;
};

/** Addresses relation input */
export type AddressesConfigurationManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** Addresses relation input */
export type AddressesConfigurationRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<Address_SchoolConfigurationCreateInput>;
};

/** Addresses relation input */
export type AddressesConfigurationUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<Address_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<Address_SchoolConfigurationUpdateInput>;
};

/** Addresses relation input */
export type AddressesEducationalInstitutionsManyRelationInput = {
  connect?: InputMaybe<EducationalInstitutionKeyFilter>;
};

/** Addresses relation input */
export type AddressesEducationalInstitutionsRelationInput = {
  connect?: InputMaybe<EducationalInstitutionKeyFilter>;
  create?: InputMaybe<Address_EducationalInstitutionCreateInput>;
};

/** Addresses relation input */
export type AddressesEducationalInstitutionsUpdateRelationInput = {
  connect?: InputMaybe<EducationalInstitutionKeyFilter>;
  create?: InputMaybe<Address_EducationalInstitutionCreateInput>;
  disconnect?: InputMaybe<EducationalInstitutionKeyFilter>;
  reconnect?: InputMaybe<EducationalInstitutionKeyFilter>;
  update?: InputMaybe<Address_EducationalInstitutionUpdateInput>;
};

/** Addresses relation input */
export type AddressesEventsManyRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
};

/** Addresses relation input */
export type AddressesEventsRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Location_EventCreateInput>>>;
};

/** Addresses relation input */
export type AddressesEventsUpdateRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Location_EventCreateInput>>>;
  disconnect?: InputMaybe<Array<EventKeyFilter>>;
  reconnect?: InputMaybe<Array<EventKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Location_EventUpdateInput>>>;
};

/** Addresses relation input */
export type AddressesOrganizationsManyRelationInput = {
  connect?: InputMaybe<OrganizationLocationKeyFilter>;
};

/** Addresses relation input */
export type AddressesOrganizationsRelationInput = {
  connect?: InputMaybe<OrganizationLocationKeyFilter>;
  create?: InputMaybe<Address_OrganizationLocationCreateInput>;
};

/** Addresses relation input */
export type AddressesOrganizationsUpdateRelationInput = {
  connect?: InputMaybe<OrganizationLocationKeyFilter>;
  create?: InputMaybe<Address_OrganizationLocationCreateInput>;
  disconnect?: InputMaybe<OrganizationLocationKeyFilter>;
  reconnect?: InputMaybe<OrganizationLocationKeyFilter>;
  update?: InputMaybe<Address_OrganizationLocationUpdateInput>;
};

/** Addresses relation input */
export type AddressesUsersAdressesManyRelationInput = {
  connect?: InputMaybe<Array<UserPreferenceKeyFilter>>;
};

/** Addresses relation input */
export type AddressesUsersAdressesRelationInput = {
  connect?: InputMaybe<Array<UserPreferenceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Address_UserPreferenceCreateInput>>>;
};

/** Addresses relation input */
export type AddressesUsersAdressesUpdateRelationInput = {
  connect?: InputMaybe<Array<UserPreferenceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Address_UserPreferenceCreateInput>>>;
  disconnect?: InputMaybe<Array<UserPreferenceKeyFilter>>;
  reconnect?: InputMaybe<Array<UserPreferenceKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Address_UserPreferenceUpdateInput>>>;
};

/** Advertising create input from adsCampaigns */
export type AdsCampaigns_AdvertisingCreateInput = {
  adsCampaigns?: InputMaybe<AdvertisingAdsCampaignsRelationInput>;
  advertisingSpecialOffers?: InputMaybe<AdvertisingAdvertisingSpecialOffersRelationInput>;
  organization: AdvertisingOrganizationRelationInput;
  supporter?: InputMaybe<AdvertisingSupporterRelationInput>;
};

/** Advertising update input from adsCampaigns */
export type AdsCampaigns_AdvertisingUpdateInput = {
  adsCampaigns?: InputMaybe<AdvertisingAdsCampaignsUpdateRelationInput>;
  advertisingSpecialOffers?: InputMaybe<AdvertisingAdvertisingSpecialOffersUpdateRelationInput>;
  organization?: InputMaybe<AdvertisingOrganizationUpdateRelationInput>;
  supporter?: InputMaybe<AdvertisingSupporterUpdateRelationInput>;
};

/** AdvertisingSampaigns create input from ads */
export type Ads_AdvertisingSampaignCreateInput = {
  ads?: InputMaybe<AdvertisingSampaignsAdsRelationInput>;
  advertising: AdvertisingSampaignsAdvertisingRelationInput;
  endDate: Scalars['DateTime'];
  name: Scalars['String'];
  /** For ongoing campaigns - should be set for lighter queries filters */
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  /**
   * {
   *     shortName: 'America/Cancun',
   *     longName: 'Eastern Standard Time',
   *     abbreviation: 'EST',
   *   },
   *   {
   *     shortName: 'America/Regina',
   *     longName: 'Central Standard Time',
   *     abbreviation: 'CST',
   *   },
   *   {
   *     shortName: 'America/Phoenix',
   *     longName: 'Mountain Standard Time',
   *     abbreviation: 'MST',
   *   },
   *   {
   *     shortName: 'US/Pacific',
   *     longName: 'Pacific Standard Time',
   *     abbreviation: 'PST',
   *   },
   *   {
   *     shortName: 'US/Alaska',
   *     longName: 'Alaska Standard Time',
   *     abbreviation: 'AKST',
   *   },
   *   {
   *     shortName: 'US/Hawaii',
   *     longName: 'Hawaii Standard Time',
   *     abbreviation: 'HST',
   *   }
   */
  timezone?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

/** AdvertisingSampaigns update input from ads */
export type Ads_AdvertisingSampaignUpdateInput = {
  ads?: InputMaybe<AdvertisingSampaignsAdsUpdateRelationInput>;
  advertising?: InputMaybe<AdvertisingSampaignsAdvertisingUpdateRelationInput>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  /** For ongoing campaigns - should be set for lighter queries filters */
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  /**
   * {
   *     shortName: 'America/Cancun',
   *     longName: 'Eastern Standard Time',
   *     abbreviation: 'EST',
   *   },
   *   {
   *     shortName: 'America/Regina',
   *     longName: 'Central Standard Time',
   *     abbreviation: 'CST',
   *   },
   *   {
   *     shortName: 'America/Phoenix',
   *     longName: 'Mountain Standard Time',
   *     abbreviation: 'MST',
   *   },
   *   {
   *     shortName: 'US/Pacific',
   *     longName: 'Pacific Standard Time',
   *     abbreviation: 'PST',
   *   },
   *   {
   *     shortName: 'US/Alaska',
   *     longName: 'Alaska Standard Time',
   *     abbreviation: 'AKST',
   *   },
   *   {
   *     shortName: 'US/Hawaii',
   *     longName: 'Hawaii Standard Time',
   *     abbreviation: 'HST',
   *   }
   */
  timezone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** BillingsAds create input from ads */
export type Ads_BillingsAdCreateInput = {
  ads?: InputMaybe<BillingsAdsAdsRelationInput>;
  organization?: InputMaybe<BillingsAdsOrganizationRelationInput>;
  registeredBillingAds?: InputMaybe<BillingsAdsRegisteredBillingAdsRelationInput>;
};

/** BillingsAds update input from ads */
export type Ads_BillingsAdUpdateInput = {
  ads?: InputMaybe<BillingsAdsAdsUpdateRelationInput>;
  organization?: InputMaybe<BillingsAdsOrganizationUpdateRelationInput>;
  registeredBillingAds?: InputMaybe<BillingsAdsRegisteredBillingAdsUpdateRelationInput>;
};

export type Advertising = {
  __typename?: 'Advertising';
  _description?: Maybe<Scalars['String']>;
  adsCampaigns?: Maybe<AdvertisingSampaignListResponse>;
  advertisingSpecialOffers?: Maybe<DiscountListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  organization?: Maybe<Organization>;
  supporter?: Maybe<AdvertisingSupporter>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdvertisingAdsCampaignsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingSampaignFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingSampaignGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingSampaignOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingSampaignSort>>;
};

export type AdvertisingAdvertisingSpecialOffersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DiscountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DiscountGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DiscountOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DiscountSort>>;
};

/** Advertising relation input */
export type AdvertisingAdsCampaignsManyRelationInput = {
  connect?: InputMaybe<Array<AdvertisingSampaignKeyFilter>>;
};

/** Advertising relation input */
export type AdvertisingAdsCampaignsRelationInput = {
  connect?: InputMaybe<Array<AdvertisingSampaignKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Advertising_AdvertisingSampaignCreateInput>>>;
};

/** Advertising relation input */
export type AdvertisingAdsCampaignsUpdateRelationInput = {
  connect?: InputMaybe<Array<AdvertisingSampaignKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Advertising_AdvertisingSampaignCreateInput>>>;
  disconnect?: InputMaybe<Array<AdvertisingSampaignKeyFilter>>;
  reconnect?: InputMaybe<Array<AdvertisingSampaignKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Advertising_AdvertisingSampaignUpdateInput>>>;
};

/** Advertising relation input */
export type AdvertisingAdvertisingSpecialOffersManyRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
};

/** Advertising relation input */
export type AdvertisingAdvertisingSpecialOffersRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Advertising_DiscountCreateInput>>>;
};

/** Advertising relation input */
export type AdvertisingAdvertisingSpecialOffersUpdateRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Advertising_DiscountCreateInput>>>;
  disconnect?: InputMaybe<Array<DiscountKeyFilter>>;
  reconnect?: InputMaybe<Array<DiscountKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Advertising_DiscountUpdateInput>>>;
};

export type AdvertisingCampaignAd = {
  __typename?: 'AdvertisingCampaignAd';
  _description?: Maybe<Scalars['String']>;
  audiences?: Maybe<AudienceListResponse>;
  billingAds?: Maybe<BillingsAd>;
  billingRegisteredAd?: Maybe<BillingRegisteredAdListResponse>;
  callToAction?: Maybe<Scalars['String']>;
  callToActionLink?: Maybe<Scalars['String']>;
  callToActionText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  dailyBudget?: Maybe<Scalars['Float']>;
  dailyBudgetEnabled?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['Int']>;
  fridayEnabled?: Maybe<Scalars['Boolean']>;
  fridayEndDate?: Maybe<Scalars['Int']>;
  fridayStartDate?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<File>;
  mondayEnabled?: Maybe<Scalars['Boolean']>;
  mondayEndDate?: Maybe<Scalars['Int']>;
  mondayStartDate?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  primaryText?: Maybe<Scalars['String']>;
  /** Run the campaign continuously or permanently */
  runConstantly?: Maybe<Scalars['Boolean']>;
  sampaign?: Maybe<AdvertisingSampaign>;
  saturdayEnabled?: Maybe<Scalars['Boolean']>;
  saturdayEndDate?: Maybe<Scalars['Int']>;
  saturdayStartDate?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  sundayEnabled?: Maybe<Scalars['Boolean']>;
  sundayEndDate?: Maybe<Scalars['Int']>;
  sundayStartDate?: Maybe<Scalars['Int']>;
  thursdayEnabled?: Maybe<Scalars['Boolean']>;
  thursdayEndDate?: Maybe<Scalars['Int']>;
  thursdayStartDate?: Maybe<Scalars['Int']>;
  todaySpend?: Maybe<Scalars['Float']>;
  totalBudget?: Maybe<Scalars['Float']>;
  totalBudgetEnabled?: Maybe<Scalars['Boolean']>;
  totalSpend?: Maybe<Scalars['Float']>;
  tuesdayEnabled?: Maybe<Scalars['Boolean']>;
  tuesdayEndDate?: Maybe<Scalars['Int']>;
  tuesdayStartDate?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userClicks?: Maybe<AdClickListResponse>;
  userLikes?: Maybe<UserListResponse>;
  userViews?: Maybe<AdViewListResponse>;
  wednesdayEnabled?: Maybe<Scalars['Boolean']>;
  wednesdayEndDate?: Maybe<Scalars['Int']>;
  wednesdayStartDate?: Maybe<Scalars['Int']>;
};

export type AdvertisingCampaignAdAudiencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AudienceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AudienceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AudienceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AudienceSort>>;
};

export type AdvertisingCampaignAdBillingRegisteredAdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingRegisteredAdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingRegisteredAdGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingRegisteredAdOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingRegisteredAdSort>>;
};

export type AdvertisingCampaignAdUserClicksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdClickFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdClickGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdClickOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdClickSort>>;
};

export type AdvertisingCampaignAdUserLikesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

export type AdvertisingCampaignAdUserViewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdViewFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdViewGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdViewOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdViewSort>>;
};

/** AdvertisingCampaignAds create input */
export type AdvertisingCampaignAdCreateInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdRelationInput>;
  callToAction: Scalars['String'];
  callToActionLink: Scalars['String'];
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  primaryText: Scalars['String'];
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign?: InputMaybe<AdvertisingCampaignAdsSampaignRelationInput>;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget: Scalars['Float'];
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

/** AdvertisingCampaignAds create many input */
export type AdvertisingCampaignAdCreateManyInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesManyRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsManyRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdManyRelationInput>;
  callToAction: Scalars['String'];
  callToActionLink: Scalars['String'];
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageManyRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  primaryText: Scalars['String'];
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign: AdvertisingCampaignAdsSampaignManyRelationInput;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget: Scalars['Float'];
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksManyRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesManyRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsManyRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

/** AdvertisingCampaignAds delete input */
export type AdvertisingCampaignAdDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** AdvertisingCampaignAdFieldsPermissions create input */
export type AdvertisingCampaignAdFieldsPermissions = {
  callToAction?: InputMaybe<Scalars['Boolean']>;
  callToActionLink?: InputMaybe<Scalars['Boolean']>;
  callToActionText?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  dailyBudget?: InputMaybe<Scalars['Boolean']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Boolean']>;
  fridayStartDate?: InputMaybe<Scalars['Boolean']>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Boolean']>;
  mondayStartDate?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  primaryText?: InputMaybe<Scalars['Boolean']>;
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Boolean']>;
  saturdayStartDate?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Boolean']>;
  sundayStartDate?: InputMaybe<Scalars['Boolean']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Boolean']>;
  thursdayStartDate?: InputMaybe<Scalars['Boolean']>;
  todaySpend?: InputMaybe<Scalars['Boolean']>;
  totalBudget?: InputMaybe<Scalars['Boolean']>;
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Boolean']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Boolean']>;
  tuesdayStartDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Boolean']>;
  wednesdayStartDate?: InputMaybe<Scalars['Boolean']>;
};

export type AdvertisingCampaignAdFilter = {
  AND?: InputMaybe<Array<AdvertisingCampaignAdFilter>>;
  OR?: InputMaybe<Array<AdvertisingCampaignAdFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  audiences?: InputMaybe<AudienceRelationFilter>;
  billingAds?: InputMaybe<BillingsAdFilter>;
  billingRegisteredAd?: InputMaybe<BillingRegisteredAdRelationFilter>;
  callToAction?: InputMaybe<StringPredicate>;
  callToActionLink?: InputMaybe<StringPredicate>;
  callToActionText?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  dailyBudget?: InputMaybe<FloatPredicate>;
  dailyBudgetEnabled?: InputMaybe<BoolPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  fridayEnabled?: InputMaybe<BoolPredicate>;
  fridayEndDate?: InputMaybe<IntPredicate>;
  fridayStartDate?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  image?: InputMaybe<FileFilter>;
  mondayEnabled?: InputMaybe<BoolPredicate>;
  mondayEndDate?: InputMaybe<IntPredicate>;
  mondayStartDate?: InputMaybe<IntPredicate>;
  name?: InputMaybe<StringPredicate>;
  primaryText?: InputMaybe<StringPredicate>;
  runConstantly?: InputMaybe<BoolPredicate>;
  sampaign?: InputMaybe<AdvertisingSampaignFilter>;
  saturdayEnabled?: InputMaybe<BoolPredicate>;
  saturdayEndDate?: InputMaybe<IntPredicate>;
  saturdayStartDate?: InputMaybe<IntPredicate>;
  status?: InputMaybe<StringPredicate>;
  sundayEnabled?: InputMaybe<BoolPredicate>;
  sundayEndDate?: InputMaybe<IntPredicate>;
  sundayStartDate?: InputMaybe<IntPredicate>;
  thursdayEnabled?: InputMaybe<BoolPredicate>;
  thursdayEndDate?: InputMaybe<IntPredicate>;
  thursdayStartDate?: InputMaybe<IntPredicate>;
  todaySpend?: InputMaybe<FloatPredicate>;
  totalBudget?: InputMaybe<FloatPredicate>;
  totalBudgetEnabled?: InputMaybe<BoolPredicate>;
  totalSpend?: InputMaybe<FloatPredicate>;
  tuesdayEnabled?: InputMaybe<BoolPredicate>;
  tuesdayEndDate?: InputMaybe<IntPredicate>;
  tuesdayStartDate?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userClicks?: InputMaybe<AdClickRelationFilter>;
  userLikes?: InputMaybe<UserRelationFilter>;
  userViews?: InputMaybe<AdViewRelationFilter>;
  wednesdayEnabled?: InputMaybe<BoolPredicate>;
  wednesdayEndDate?: InputMaybe<IntPredicate>;
  wednesdayStartDate?: InputMaybe<IntPredicate>;
};

export type AdvertisingCampaignAdGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: AdvertisingCampaignAdGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type AdvertisingCampaignAdGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  audiences?: InputMaybe<AudienceGroupByQuery>;
  billingAds?: InputMaybe<BillingsAdGroupByQuery>;
  billingRegisteredAd?: InputMaybe<BillingRegisteredAdGroupByQuery>;
  callToAction?: InputMaybe<Array<GroupByField>>;
  callToActionLink?: InputMaybe<Array<GroupByField>>;
  callToActionText?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  dailyBudget?: InputMaybe<Array<GroupByField>>;
  dailyBudgetEnabled?: InputMaybe<Array<GroupByField>>;
  fridayEnabled?: InputMaybe<Array<GroupByField>>;
  fridayEndDate?: InputMaybe<Array<GroupByField>>;
  fridayStartDate?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  image?: InputMaybe<FileGroupByQuery>;
  mondayEnabled?: InputMaybe<Array<GroupByField>>;
  mondayEndDate?: InputMaybe<Array<GroupByField>>;
  mondayStartDate?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  primaryText?: InputMaybe<Array<GroupByField>>;
  runConstantly?: InputMaybe<Array<GroupByField>>;
  sampaign?: InputMaybe<AdvertisingSampaignGroupByQuery>;
  saturdayEnabled?: InputMaybe<Array<GroupByField>>;
  saturdayEndDate?: InputMaybe<Array<GroupByField>>;
  saturdayStartDate?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  sundayEnabled?: InputMaybe<Array<GroupByField>>;
  sundayEndDate?: InputMaybe<Array<GroupByField>>;
  sundayStartDate?: InputMaybe<Array<GroupByField>>;
  thursdayEnabled?: InputMaybe<Array<GroupByField>>;
  thursdayEndDate?: InputMaybe<Array<GroupByField>>;
  thursdayStartDate?: InputMaybe<Array<GroupByField>>;
  todaySpend?: InputMaybe<Array<GroupByField>>;
  totalBudget?: InputMaybe<Array<GroupByField>>;
  totalBudgetEnabled?: InputMaybe<Array<GroupByField>>;
  totalSpend?: InputMaybe<Array<GroupByField>>;
  tuesdayEnabled?: InputMaybe<Array<GroupByField>>;
  tuesdayEndDate?: InputMaybe<Array<GroupByField>>;
  tuesdayStartDate?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  userClicks?: InputMaybe<AdClickGroupByQuery>;
  userLikes?: InputMaybe<UserGroupByQuery>;
  userViews?: InputMaybe<AdViewGroupByQuery>;
  wednesdayEnabled?: InputMaybe<Array<GroupByField>>;
  wednesdayEndDate?: InputMaybe<Array<GroupByField>>;
  wednesdayStartDate?: InputMaybe<Array<GroupByField>>;
};

export type AdvertisingCampaignAdKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** AdvertisingCampaignAdListResponse output */
export type AdvertisingCampaignAdListResponse = {
  __typename?: 'AdvertisingCampaignAdListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<AdvertisingCampaignAd>;
};

/** AdvertisingCampaignAdManyResponse output */
export type AdvertisingCampaignAdManyResponse = {
  __typename?: 'AdvertisingCampaignAdManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<AdvertisingCampaignAd>;
};

/** No longer supported. Use `sort` instead. */
export type AdvertisingCampaignAdOrderBy =
  | 'callToActionLink_ASC'
  | 'callToActionLink_DESC'
  | 'callToActionText_ASC'
  | 'callToActionText_DESC'
  | 'callToAction_ASC'
  | 'callToAction_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'dailyBudgetEnabled_ASC'
  | 'dailyBudgetEnabled_DESC'
  | 'dailyBudget_ASC'
  | 'dailyBudget_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'fridayEnabled_ASC'
  | 'fridayEnabled_DESC'
  | 'fridayEndDate_ASC'
  | 'fridayEndDate_DESC'
  | 'fridayStartDate_ASC'
  | 'fridayStartDate_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'mondayEnabled_ASC'
  | 'mondayEnabled_DESC'
  | 'mondayEndDate_ASC'
  | 'mondayEndDate_DESC'
  | 'mondayStartDate_ASC'
  | 'mondayStartDate_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'primaryText_ASC'
  | 'primaryText_DESC'
  | 'runConstantly_ASC'
  | 'runConstantly_DESC'
  | 'saturdayEnabled_ASC'
  | 'saturdayEnabled_DESC'
  | 'saturdayEndDate_ASC'
  | 'saturdayEndDate_DESC'
  | 'saturdayStartDate_ASC'
  | 'saturdayStartDate_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'sundayEnabled_ASC'
  | 'sundayEnabled_DESC'
  | 'sundayEndDate_ASC'
  | 'sundayEndDate_DESC'
  | 'sundayStartDate_ASC'
  | 'sundayStartDate_DESC'
  | 'thursdayEnabled_ASC'
  | 'thursdayEnabled_DESC'
  | 'thursdayEndDate_ASC'
  | 'thursdayEndDate_DESC'
  | 'thursdayStartDate_ASC'
  | 'thursdayStartDate_DESC'
  | 'todaySpend_ASC'
  | 'todaySpend_DESC'
  | 'totalBudgetEnabled_ASC'
  | 'totalBudgetEnabled_DESC'
  | 'totalBudget_ASC'
  | 'totalBudget_DESC'
  | 'totalSpend_ASC'
  | 'totalSpend_DESC'
  | 'tuesdayEnabled_ASC'
  | 'tuesdayEnabled_DESC'
  | 'tuesdayEndDate_ASC'
  | 'tuesdayEndDate_DESC'
  | 'tuesdayStartDate_ASC'
  | 'tuesdayStartDate_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'wednesdayEnabled_ASC'
  | 'wednesdayEnabled_DESC'
  | 'wednesdayEndDate_ASC'
  | 'wednesdayEndDate_DESC'
  | 'wednesdayStartDate_ASC'
  | 'wednesdayStartDate_DESC';

/** AdvertisingCampaignAds subscription payload */
export type AdvertisingCampaignAdPayload = {
  __typename?: 'AdvertisingCampaignAdPayload';
  mutation: MutationType;
  node?: Maybe<AdvertisingCampaignAd>;
  previousValues?: Maybe<AdvertisingCampaignAd>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AdvertisingCampaignAdRelationFilter = {
  every?: InputMaybe<AdvertisingCampaignAdFilter>;
  none?: InputMaybe<AdvertisingCampaignAdFilter>;
  some?: InputMaybe<AdvertisingCampaignAdFilter>;
};

export type AdvertisingCampaignAdSort = {
  billingAds?: InputMaybe<BillingsAdSort>;
  callToAction?: InputMaybe<SortOrder>;
  callToActionLink?: InputMaybe<SortOrder>;
  callToActionText?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  dailyBudget?: InputMaybe<SortOrder>;
  dailyBudgetEnabled?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  fridayEnabled?: InputMaybe<SortOrder>;
  fridayEndDate?: InputMaybe<SortOrder>;
  fridayStartDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<FileSort>;
  mondayEnabled?: InputMaybe<SortOrder>;
  mondayEndDate?: InputMaybe<SortOrder>;
  mondayStartDate?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  primaryText?: InputMaybe<SortOrder>;
  runConstantly?: InputMaybe<SortOrder>;
  sampaign?: InputMaybe<AdvertisingSampaignSort>;
  saturdayEnabled?: InputMaybe<SortOrder>;
  saturdayEndDate?: InputMaybe<SortOrder>;
  saturdayStartDate?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  sundayEnabled?: InputMaybe<SortOrder>;
  sundayEndDate?: InputMaybe<SortOrder>;
  sundayStartDate?: InputMaybe<SortOrder>;
  thursdayEnabled?: InputMaybe<SortOrder>;
  thursdayEndDate?: InputMaybe<SortOrder>;
  thursdayStartDate?: InputMaybe<SortOrder>;
  todaySpend?: InputMaybe<SortOrder>;
  totalBudget?: InputMaybe<SortOrder>;
  totalBudgetEnabled?: InputMaybe<SortOrder>;
  totalSpend?: InputMaybe<SortOrder>;
  tuesdayEnabled?: InputMaybe<SortOrder>;
  tuesdayEndDate?: InputMaybe<SortOrder>;
  tuesdayStartDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  wednesdayEnabled?: InputMaybe<SortOrder>;
  wednesdayEndDate?: InputMaybe<SortOrder>;
  wednesdayStartDate?: InputMaybe<SortOrder>;
};

/** AdvertisingCampaignAds subscription filter */
export type AdvertisingCampaignAdSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<AdvertisingCampaignAdFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** AdvertisingCampaignAds update input */
export type AdvertisingCampaignAdUpdateByFilterInput = {
  callToAction?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  callToActionLink?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  callToActionText?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  dailyBudget?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  dailyBudgetEnabled?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  fridayEnabled?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  fridayEndDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  fridayStartDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  mondayEnabled?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  mondayEndDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  mondayStartDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  primaryText?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  runConstantly?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  saturdayEnabled?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  saturdayEndDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  saturdayStartDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  sundayEnabled?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  sundayEndDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  sundayStartDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  thursdayEnabled?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  thursdayEndDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  thursdayStartDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  todaySpend?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  totalBudget?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  totalBudgetEnabled?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  totalSpend?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  tuesdayEnabled?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  tuesdayEndDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  tuesdayStartDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  wednesdayEnabled?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  wednesdayEndDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  wednesdayStartDate?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
};

/** AdvertisingCampaignAds update input */
export type AdvertisingCampaignAdUpdateInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsUpdateRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdUpdateRelationInput>;
  callToAction?: InputMaybe<Scalars['String']>;
  callToActionLink?: InputMaybe<Scalars['String']>;
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageUpdateRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryText?: InputMaybe<Scalars['String']>;
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign?: InputMaybe<AdvertisingCampaignAdsSampaignUpdateRelationInput>;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget?: InputMaybe<Scalars['Float']>;
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksUpdateRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesUpdateRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsUpdateRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

export type AdvertisingCampaignAd_PermissionFilter = {
  AND?: InputMaybe<Array<AdvertisingCampaignAd_PermissionFilter>>;
  OR?: InputMaybe<Array<AdvertisingCampaignAd_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  audiences?: InputMaybe<Audience_PermissionRelationFilter>;
  billingAds?: InputMaybe<BillingsAd_PermissionFilter>;
  billingRegisteredAd?: InputMaybe<BillingRegisteredAd_PermissionRelationFilter>;
  callToAction?: InputMaybe<StringPredicate>;
  callToActionLink?: InputMaybe<StringPredicate>;
  callToActionText?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  dailyBudget?: InputMaybe<FloatPredicate>;
  dailyBudgetEnabled?: InputMaybe<BoolPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  fridayEnabled?: InputMaybe<BoolPredicate>;
  fridayEndDate?: InputMaybe<IntPredicate>;
  fridayStartDate?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  image?: InputMaybe<File_PermissionFilter>;
  mondayEnabled?: InputMaybe<BoolPredicate>;
  mondayEndDate?: InputMaybe<IntPredicate>;
  mondayStartDate?: InputMaybe<IntPredicate>;
  name?: InputMaybe<StringPredicate>;
  primaryText?: InputMaybe<StringPredicate>;
  runConstantly?: InputMaybe<BoolPredicate>;
  sampaign?: InputMaybe<AdvertisingSampaign_PermissionFilter>;
  saturdayEnabled?: InputMaybe<BoolPredicate>;
  saturdayEndDate?: InputMaybe<IntPredicate>;
  saturdayStartDate?: InputMaybe<IntPredicate>;
  status?: InputMaybe<StringPredicate>;
  sundayEnabled?: InputMaybe<BoolPredicate>;
  sundayEndDate?: InputMaybe<IntPredicate>;
  sundayStartDate?: InputMaybe<IntPredicate>;
  thursdayEnabled?: InputMaybe<BoolPredicate>;
  thursdayEndDate?: InputMaybe<IntPredicate>;
  thursdayStartDate?: InputMaybe<IntPredicate>;
  todaySpend?: InputMaybe<FloatPredicate>;
  totalBudget?: InputMaybe<FloatPredicate>;
  totalBudgetEnabled?: InputMaybe<BoolPredicate>;
  totalSpend?: InputMaybe<FloatPredicate>;
  tuesdayEnabled?: InputMaybe<BoolPredicate>;
  tuesdayEndDate?: InputMaybe<IntPredicate>;
  tuesdayStartDate?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userClicks?: InputMaybe<AdClick_PermissionRelationFilter>;
  userLikes?: InputMaybe<User_PermissionRelationFilter>;
  userViews?: InputMaybe<AdView_PermissionRelationFilter>;
  wednesdayEnabled?: InputMaybe<BoolPredicate>;
  wednesdayEndDate?: InputMaybe<IntPredicate>;
  wednesdayStartDate?: InputMaybe<IntPredicate>;
};

export type AdvertisingCampaignAd_PermissionRelationFilter = {
  every?: InputMaybe<AdvertisingCampaignAd_PermissionFilter>;
  none?: InputMaybe<AdvertisingCampaignAd_PermissionFilter>;
  some?: InputMaybe<AdvertisingCampaignAd_PermissionFilter>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsAudiencesManyRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsAudiencesRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Ad_AudienceCreateInput>>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsAudiencesUpdateRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Ad_AudienceCreateInput>>>;
  disconnect?: InputMaybe<Array<AudienceKeyFilter>>;
  reconnect?: InputMaybe<Array<AudienceKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Ad_AudienceUpdateInput>>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsBillingAdsManyRelationInput = {
  connect?: InputMaybe<BillingsAdKeyFilter>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsBillingAdsRelationInput = {
  connect?: InputMaybe<BillingsAdKeyFilter>;
  create?: InputMaybe<Ads_BillingsAdCreateInput>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsBillingAdsUpdateRelationInput = {
  connect?: InputMaybe<BillingsAdKeyFilter>;
  create?: InputMaybe<Ads_BillingsAdCreateInput>;
  disconnect?: InputMaybe<BillingsAdKeyFilter>;
  reconnect?: InputMaybe<BillingsAdKeyFilter>;
  update?: InputMaybe<Ads_BillingsAdUpdateInput>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsBillingRegisteredAdManyRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredAdKeyFilter>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsBillingRegisteredAdRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Ad_BillingRegisteredAdCreateInput>>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsBillingRegisteredAdUpdateRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Ad_BillingRegisteredAdCreateInput>>>;
  disconnect?: InputMaybe<Array<BillingRegisteredAdKeyFilter>>;
  reconnect?: InputMaybe<Array<BillingRegisteredAdKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Ad_BillingRegisteredAdUpdateInput>>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsImageManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsImageRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<AdvertisingCampaignAds_Image_FileCreateInput>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsImageUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<AdvertisingCampaignAds_Image_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<AdvertisingCampaignAds_Image_FileUpdateInput>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsSampaignManyRelationInput = {
  connect?: InputMaybe<AdvertisingSampaignKeyFilter>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsSampaignRelationInput = {
  connect?: InputMaybe<AdvertisingSampaignKeyFilter>;
  create?: InputMaybe<Ads_AdvertisingSampaignCreateInput>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsSampaignUpdateRelationInput = {
  connect?: InputMaybe<AdvertisingSampaignKeyFilter>;
  create?: InputMaybe<Ads_AdvertisingSampaignCreateInput>;
  disconnect?: InputMaybe<AdvertisingSampaignKeyFilter>;
  reconnect?: InputMaybe<AdvertisingSampaignKeyFilter>;
  update?: InputMaybe<Ads_AdvertisingSampaignUpdateInput>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsUserClicksManyRelationInput = {
  connect?: InputMaybe<Array<AdClickKeyFilter>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsUserClicksRelationInput = {
  connect?: InputMaybe<Array<AdClickKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Ad_AdClickCreateInput>>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsUserClicksUpdateRelationInput = {
  connect?: InputMaybe<Array<AdClickKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Ad_AdClickCreateInput>>>;
  disconnect?: InputMaybe<Array<AdClickKeyFilter>>;
  reconnect?: InputMaybe<Array<AdClickKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Ad_AdClickUpdateInput>>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsUserLikesManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsUserLikesRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<AdLikes_UserCreateInput>>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsUserLikesUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<AdLikes_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<AdLikes_UserUpdateInput>>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsUserViewsManyRelationInput = {
  connect?: InputMaybe<Array<AdViewKeyFilter>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsUserViewsRelationInput = {
  connect?: InputMaybe<Array<AdViewKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Ad_AdViewCreateInput>>>;
};

/** AdvertisingCampaignAds relation input */
export type AdvertisingCampaignAdsUserViewsUpdateRelationInput = {
  connect?: InputMaybe<Array<AdViewKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Ad_AdViewCreateInput>>>;
  disconnect?: InputMaybe<Array<AdViewKeyFilter>>;
  reconnect?: InputMaybe<Array<AdViewKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Ad_AdViewUpdateInput>>>;
};

/** Files create input from advertisingCampaignAds_image */
export type AdvertisingCampaignAds_Image_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from advertisingCampaignAds_image */
export type AdvertisingCampaignAds_Image_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** Advertising create input */
export type AdvertisingCreateInput = {
  adsCampaigns?: InputMaybe<AdvertisingAdsCampaignsRelationInput>;
  advertisingSpecialOffers?: InputMaybe<AdvertisingAdvertisingSpecialOffersRelationInput>;
  organization?: InputMaybe<AdvertisingOrganizationRelationInput>;
  supporter?: InputMaybe<AdvertisingSupporterRelationInput>;
};

/** Advertising create many input */
export type AdvertisingCreateManyInput = {
  adsCampaigns?: InputMaybe<AdvertisingAdsCampaignsManyRelationInput>;
  advertisingSpecialOffers?: InputMaybe<AdvertisingAdvertisingSpecialOffersManyRelationInput>;
  organization: AdvertisingOrganizationManyRelationInput;
  supporter?: InputMaybe<AdvertisingSupporterManyRelationInput>;
};

/** Advertising delete input */
export type AdvertisingDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** AdvertisingFieldsPermissions create input */
export type AdvertisingFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AdvertisingFilter = {
  AND?: InputMaybe<Array<AdvertisingFilter>>;
  OR?: InputMaybe<Array<AdvertisingFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  adsCampaigns?: InputMaybe<AdvertisingSampaignRelationFilter>;
  advertisingSpecialOffers?: InputMaybe<DiscountRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  organization?: InputMaybe<OrganizationFilter>;
  supporter?: InputMaybe<AdvertisingSupporterFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type AdvertisingGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: AdvertisingGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type AdvertisingGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  adsCampaigns?: InputMaybe<AdvertisingSampaignGroupByQuery>;
  advertisingSpecialOffers?: InputMaybe<DiscountGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  organization?: InputMaybe<OrganizationGroupByQuery>;
  supporter?: InputMaybe<AdvertisingSupporterGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type AdvertisingKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** AdvertisingListResponse output */
export type AdvertisingListResponse = {
  __typename?: 'AdvertisingListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Advertising>;
};

/** AdvertisingManyResponse output */
export type AdvertisingManyResponse = {
  __typename?: 'AdvertisingManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Advertising>;
};

/** No longer supported. Use `sort` instead. */
export type AdvertisingOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** Advertising relation input */
export type AdvertisingOrganizationManyRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
};

/** Advertising relation input */
export type AdvertisingOrganizationRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<Advertising_OrganizationCreateInput>;
};

/** Advertising relation input */
export type AdvertisingOrganizationUpdateRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<Advertising_OrganizationCreateInput>;
  disconnect?: InputMaybe<OrganizationKeyFilter>;
  reconnect?: InputMaybe<OrganizationKeyFilter>;
  update?: InputMaybe<Advertising_OrganizationUpdateInput>;
};

/** Advertising subscription payload */
export type AdvertisingPayload = {
  __typename?: 'AdvertisingPayload';
  mutation: MutationType;
  node?: Maybe<Advertising>;
  previousValues?: Maybe<Advertising>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AdvertisingSampaign = {
  __typename?: 'AdvertisingSampaign';
  _description?: Maybe<Scalars['String']>;
  ads?: Maybe<AdvertisingCampaignAdListResponse>;
  advertising?: Maybe<Advertising>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** For ongoing campaigns - should be set for lighter queries filters */
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  /**
   * {
   *     shortName: 'America/Cancun',
   *     longName: 'Eastern Standard Time',
   *     abbreviation: 'EST',
   *   },
   *   {
   *     shortName: 'America/Regina',
   *     longName: 'Central Standard Time',
   *     abbreviation: 'CST',
   *   },
   *   {
   *     shortName: 'America/Phoenix',
   *     longName: 'Mountain Standard Time',
   *     abbreviation: 'MST',
   *   },
   *   {
   *     shortName: 'US/Pacific',
   *     longName: 'Pacific Standard Time',
   *     abbreviation: 'PST',
   *   },
   *   {
   *     shortName: 'US/Alaska',
   *     longName: 'Alaska Standard Time',
   *     abbreviation: 'AKST',
   *   },
   *   {
   *     shortName: 'US/Hawaii',
   *     longName: 'Hawaii Standard Time',
   *     abbreviation: 'HST',
   *   }
   */
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdvertisingSampaignAdsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingCampaignAdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingCampaignAdGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingCampaignAdOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingCampaignAdSort>>;
};

/** AdvertisingSampaigns create input */
export type AdvertisingSampaignCreateInput = {
  ads?: InputMaybe<AdvertisingSampaignsAdsRelationInput>;
  advertising?: InputMaybe<AdvertisingSampaignsAdvertisingRelationInput>;
  endDate: Scalars['DateTime'];
  name: Scalars['String'];
  /** For ongoing campaigns - should be set for lighter queries filters */
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  /**
   * {
   *     shortName: 'America/Cancun',
   *     longName: 'Eastern Standard Time',
   *     abbreviation: 'EST',
   *   },
   *   {
   *     shortName: 'America/Regina',
   *     longName: 'Central Standard Time',
   *     abbreviation: 'CST',
   *   },
   *   {
   *     shortName: 'America/Phoenix',
   *     longName: 'Mountain Standard Time',
   *     abbreviation: 'MST',
   *   },
   *   {
   *     shortName: 'US/Pacific',
   *     longName: 'Pacific Standard Time',
   *     abbreviation: 'PST',
   *   },
   *   {
   *     shortName: 'US/Alaska',
   *     longName: 'Alaska Standard Time',
   *     abbreviation: 'AKST',
   *   },
   *   {
   *     shortName: 'US/Hawaii',
   *     longName: 'Hawaii Standard Time',
   *     abbreviation: 'HST',
   *   }
   */
  timezone?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

/** AdvertisingSampaigns create many input */
export type AdvertisingSampaignCreateManyInput = {
  ads?: InputMaybe<AdvertisingSampaignsAdsManyRelationInput>;
  advertising: AdvertisingSampaignsAdvertisingManyRelationInput;
  endDate: Scalars['DateTime'];
  name: Scalars['String'];
  /** For ongoing campaigns - should be set for lighter queries filters */
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  /**
   * {
   *     shortName: 'America/Cancun',
   *     longName: 'Eastern Standard Time',
   *     abbreviation: 'EST',
   *   },
   *   {
   *     shortName: 'America/Regina',
   *     longName: 'Central Standard Time',
   *     abbreviation: 'CST',
   *   },
   *   {
   *     shortName: 'America/Phoenix',
   *     longName: 'Mountain Standard Time',
   *     abbreviation: 'MST',
   *   },
   *   {
   *     shortName: 'US/Pacific',
   *     longName: 'Pacific Standard Time',
   *     abbreviation: 'PST',
   *   },
   *   {
   *     shortName: 'US/Alaska',
   *     longName: 'Alaska Standard Time',
   *     abbreviation: 'AKST',
   *   },
   *   {
   *     shortName: 'US/Hawaii',
   *     longName: 'Hawaii Standard Time',
   *     abbreviation: 'HST',
   *   }
   */
  timezone?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

/** AdvertisingSampaigns delete input */
export type AdvertisingSampaignDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** AdvertisingSampaignFieldsPermissions create input */
export type AdvertisingSampaignFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AdvertisingSampaignFilter = {
  AND?: InputMaybe<Array<AdvertisingSampaignFilter>>;
  OR?: InputMaybe<Array<AdvertisingSampaignFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  ads?: InputMaybe<AdvertisingCampaignAdRelationFilter>;
  advertising?: InputMaybe<AdvertisingFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  endDate?: InputMaybe<DateTimePredicate>;
  id?: InputMaybe<IdPredicate>;
  name?: InputMaybe<StringPredicate>;
  startDate?: InputMaybe<DateTimePredicate>;
  status?: InputMaybe<StringPredicate>;
  timezone?: InputMaybe<StringPredicate>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type AdvertisingSampaignGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: AdvertisingSampaignGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type AdvertisingSampaignGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  ads?: InputMaybe<AdvertisingCampaignAdGroupByQuery>;
  advertising?: InputMaybe<AdvertisingGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  endDate?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  startDate?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  timezone?: InputMaybe<Array<GroupByField>>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type AdvertisingSampaignKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** AdvertisingSampaignListResponse output */
export type AdvertisingSampaignListResponse = {
  __typename?: 'AdvertisingSampaignListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<AdvertisingSampaign>;
};

/** AdvertisingSampaignManyResponse output */
export type AdvertisingSampaignManyResponse = {
  __typename?: 'AdvertisingSampaignManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<AdvertisingSampaign>;
};

/** No longer supported. Use `sort` instead. */
export type AdvertisingSampaignOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'endDate_ASC'
  | 'endDate_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'startDate_ASC'
  | 'startDate_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'timezone_ASC'
  | 'timezone_DESC'
  | 'type_ASC'
  | 'type_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** AdvertisingSampaigns subscription payload */
export type AdvertisingSampaignPayload = {
  __typename?: 'AdvertisingSampaignPayload';
  mutation: MutationType;
  node?: Maybe<AdvertisingSampaign>;
  previousValues?: Maybe<AdvertisingSampaign>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AdvertisingSampaignRelationFilter = {
  every?: InputMaybe<AdvertisingSampaignFilter>;
  none?: InputMaybe<AdvertisingSampaignFilter>;
  some?: InputMaybe<AdvertisingSampaignFilter>;
};

export type AdvertisingSampaignSort = {
  advertising?: InputMaybe<AdvertisingSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** AdvertisingSampaigns subscription filter */
export type AdvertisingSampaignSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<AdvertisingSampaignFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** AdvertisingSampaigns update input */
export type AdvertisingSampaignUpdateByFilterInput = {
  endDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  startDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  timezone?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  type?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
};

/** AdvertisingSampaigns update input */
export type AdvertisingSampaignUpdateInput = {
  ads?: InputMaybe<AdvertisingSampaignsAdsUpdateRelationInput>;
  advertising?: InputMaybe<AdvertisingSampaignsAdvertisingUpdateRelationInput>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** For ongoing campaigns - should be set for lighter queries filters */
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  /**
   * {
   *     shortName: 'America/Cancun',
   *     longName: 'Eastern Standard Time',
   *     abbreviation: 'EST',
   *   },
   *   {
   *     shortName: 'America/Regina',
   *     longName: 'Central Standard Time',
   *     abbreviation: 'CST',
   *   },
   *   {
   *     shortName: 'America/Phoenix',
   *     longName: 'Mountain Standard Time',
   *     abbreviation: 'MST',
   *   },
   *   {
   *     shortName: 'US/Pacific',
   *     longName: 'Pacific Standard Time',
   *     abbreviation: 'PST',
   *   },
   *   {
   *     shortName: 'US/Alaska',
   *     longName: 'Alaska Standard Time',
   *     abbreviation: 'AKST',
   *   },
   *   {
   *     shortName: 'US/Hawaii',
   *     longName: 'Hawaii Standard Time',
   *     abbreviation: 'HST',
   *   }
   */
  timezone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AdvertisingSampaign_PermissionFilter = {
  AND?: InputMaybe<Array<AdvertisingSampaign_PermissionFilter>>;
  OR?: InputMaybe<Array<AdvertisingSampaign_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  ads?: InputMaybe<AdvertisingCampaignAd_PermissionRelationFilter>;
  advertising?: InputMaybe<Advertising_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  endDate?: InputMaybe<DateTimePredicate>;
  id?: InputMaybe<IdPredicate>;
  name?: InputMaybe<StringPredicate>;
  startDate?: InputMaybe<DateTimePredicate>;
  status?: InputMaybe<StringPredicate>;
  timezone?: InputMaybe<StringPredicate>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type AdvertisingSampaign_PermissionRelationFilter = {
  every?: InputMaybe<AdvertisingSampaign_PermissionFilter>;
  none?: InputMaybe<AdvertisingSampaign_PermissionFilter>;
  some?: InputMaybe<AdvertisingSampaign_PermissionFilter>;
};

/** AdvertisingSampaigns relation input */
export type AdvertisingSampaignsAdsManyRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
};

/** AdvertisingSampaigns relation input */
export type AdvertisingSampaignsAdsRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Sampaign_AdvertisingCampaignAdCreateInput>>>;
};

/** AdvertisingSampaigns relation input */
export type AdvertisingSampaignsAdsUpdateRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Sampaign_AdvertisingCampaignAdCreateInput>>>;
  disconnect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  reconnect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Sampaign_AdvertisingCampaignAdUpdateInput>>>;
};

/** AdvertisingSampaigns relation input */
export type AdvertisingSampaignsAdvertisingManyRelationInput = {
  connect?: InputMaybe<AdvertisingKeyFilter>;
};

/** AdvertisingSampaigns relation input */
export type AdvertisingSampaignsAdvertisingRelationInput = {
  connect?: InputMaybe<AdvertisingKeyFilter>;
  create?: InputMaybe<AdsCampaigns_AdvertisingCreateInput>;
};

/** AdvertisingSampaigns relation input */
export type AdvertisingSampaignsAdvertisingUpdateRelationInput = {
  connect?: InputMaybe<AdvertisingKeyFilter>;
  create?: InputMaybe<AdsCampaigns_AdvertisingCreateInput>;
  disconnect?: InputMaybe<AdvertisingKeyFilter>;
  reconnect?: InputMaybe<AdvertisingKeyFilter>;
  update?: InputMaybe<AdsCampaigns_AdvertisingUpdateInput>;
};

export type AdvertisingSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationSort>;
  supporter?: InputMaybe<AdvertisingSupporterSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Advertising create input from advertisingSpecialOffers */
export type AdvertisingSpecialOffers_AdvertisingCreateInput = {
  adsCampaigns?: InputMaybe<AdvertisingAdsCampaignsRelationInput>;
  advertisingSpecialOffers?: InputMaybe<AdvertisingAdvertisingSpecialOffersRelationInput>;
  organization?: InputMaybe<AdvertisingOrganizationRelationInput>;
  supporter?: InputMaybe<AdvertisingSupporterRelationInput>;
};

/** Advertising update input from advertisingSpecialOffers */
export type AdvertisingSpecialOffers_AdvertisingUpdateInput = {
  adsCampaigns?: InputMaybe<AdvertisingAdsCampaignsUpdateRelationInput>;
  advertisingSpecialOffers?: InputMaybe<AdvertisingAdvertisingSpecialOffersUpdateRelationInput>;
  organization?: InputMaybe<AdvertisingOrganizationUpdateRelationInput>;
  supporter?: InputMaybe<AdvertisingSupporterUpdateRelationInput>;
};

/** Advertising subscription filter */
export type AdvertisingSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<AdvertisingFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

export type AdvertisingSupporter = {
  __typename?: 'AdvertisingSupporter';
  _description?: Maybe<Scalars['String']>;
  advertising?: Maybe<Advertising>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  isSupporter?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** AdvertisingSupporter relation input */
export type AdvertisingSupporterAdvertisingManyRelationInput = {
  connect?: InputMaybe<AdvertisingKeyFilter>;
};

/** AdvertisingSupporter relation input */
export type AdvertisingSupporterAdvertisingRelationInput = {
  connect?: InputMaybe<AdvertisingKeyFilter>;
  create?: InputMaybe<Supporter_AdvertisingCreateInput>;
};

/** AdvertisingSupporter relation input */
export type AdvertisingSupporterAdvertisingUpdateRelationInput = {
  connect?: InputMaybe<AdvertisingKeyFilter>;
  create?: InputMaybe<Supporter_AdvertisingCreateInput>;
  disconnect?: InputMaybe<AdvertisingKeyFilter>;
  reconnect?: InputMaybe<AdvertisingKeyFilter>;
  update?: InputMaybe<Supporter_AdvertisingUpdateInput>;
};

/** AdvertisingSupporter create input */
export type AdvertisingSupporterCreateInput = {
  advertising?: InputMaybe<AdvertisingSupporterAdvertisingRelationInput>;
  isSupporter?: InputMaybe<Scalars['Boolean']>;
};

/** AdvertisingSupporter create many input */
export type AdvertisingSupporterCreateManyInput = {
  advertising: AdvertisingSupporterAdvertisingManyRelationInput;
  isSupporter?: InputMaybe<Scalars['Boolean']>;
};

/** AdvertisingSupporter delete input */
export type AdvertisingSupporterDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** AdvertisingSupporterFieldsPermissions create input */
export type AdvertisingSupporterFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isSupporter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AdvertisingSupporterFilter = {
  AND?: InputMaybe<Array<AdvertisingSupporterFilter>>;
  OR?: InputMaybe<Array<AdvertisingSupporterFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  advertising?: InputMaybe<AdvertisingFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isSupporter?: InputMaybe<BoolPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type AdvertisingSupporterGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: AdvertisingSupporterGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type AdvertisingSupporterGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  advertising?: InputMaybe<AdvertisingGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isSupporter?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type AdvertisingSupporterKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** AdvertisingSupporterListResponse output */
export type AdvertisingSupporterListResponse = {
  __typename?: 'AdvertisingSupporterListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<AdvertisingSupporter>;
};

/** Advertising relation input */
export type AdvertisingSupporterManyRelationInput = {
  connect?: InputMaybe<AdvertisingSupporterKeyFilter>;
};

/** AdvertisingSupporterManyResponse output */
export type AdvertisingSupporterManyResponse = {
  __typename?: 'AdvertisingSupporterManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<AdvertisingSupporter>;
};

/** No longer supported. Use `sort` instead. */
export type AdvertisingSupporterOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isSupporter_ASC'
  | 'isSupporter_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** AdvertisingSupporter subscription payload */
export type AdvertisingSupporterPayload = {
  __typename?: 'AdvertisingSupporterPayload';
  mutation: MutationType;
  node?: Maybe<AdvertisingSupporter>;
  previousValues?: Maybe<AdvertisingSupporter>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Advertising relation input */
export type AdvertisingSupporterRelationInput = {
  connect?: InputMaybe<AdvertisingSupporterKeyFilter>;
  create?: InputMaybe<Advertising_AdvertisingSupporterCreateInput>;
};

export type AdvertisingSupporterSort = {
  advertising?: InputMaybe<AdvertisingSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isSupporter?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** AdvertisingSupporter subscription filter */
export type AdvertisingSupporterSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<AdvertisingSupporterFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** AdvertisingSupporter update input */
export type AdvertisingSupporterUpdateByFilterInput = {
  isSupporter?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
};

/** AdvertisingSupporter update input */
export type AdvertisingSupporterUpdateInput = {
  advertising?: InputMaybe<AdvertisingSupporterAdvertisingUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  isSupporter?: InputMaybe<Scalars['Boolean']>;
};

/** Advertising relation input */
export type AdvertisingSupporterUpdateRelationInput = {
  connect?: InputMaybe<AdvertisingSupporterKeyFilter>;
  create?: InputMaybe<Advertising_AdvertisingSupporterCreateInput>;
  disconnect?: InputMaybe<AdvertisingSupporterKeyFilter>;
  reconnect?: InputMaybe<AdvertisingSupporterKeyFilter>;
  update?: InputMaybe<Advertising_AdvertisingSupporterUpdateInput>;
};

export type AdvertisingSupporter_PermissionFilter = {
  AND?: InputMaybe<Array<AdvertisingSupporter_PermissionFilter>>;
  OR?: InputMaybe<Array<AdvertisingSupporter_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  advertising?: InputMaybe<Advertising_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isSupporter?: InputMaybe<BoolPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

/** Advertising update input */
export type AdvertisingUpdateInput = {
  adsCampaigns?: InputMaybe<AdvertisingAdsCampaignsUpdateRelationInput>;
  advertisingSpecialOffers?: InputMaybe<AdvertisingAdvertisingSpecialOffersUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  organization?: InputMaybe<AdvertisingOrganizationUpdateRelationInput>;
  supporter?: InputMaybe<AdvertisingSupporterUpdateRelationInput>;
};

/** AdvertisingSampaigns create input from advertising */
export type Advertising_AdvertisingSampaignCreateInput = {
  ads?: InputMaybe<AdvertisingSampaignsAdsRelationInput>;
  advertising?: InputMaybe<AdvertisingSampaignsAdvertisingRelationInput>;
  endDate: Scalars['DateTime'];
  name: Scalars['String'];
  /** For ongoing campaigns - should be set for lighter queries filters */
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  /**
   * {
   *     shortName: 'America/Cancun',
   *     longName: 'Eastern Standard Time',
   *     abbreviation: 'EST',
   *   },
   *   {
   *     shortName: 'America/Regina',
   *     longName: 'Central Standard Time',
   *     abbreviation: 'CST',
   *   },
   *   {
   *     shortName: 'America/Phoenix',
   *     longName: 'Mountain Standard Time',
   *     abbreviation: 'MST',
   *   },
   *   {
   *     shortName: 'US/Pacific',
   *     longName: 'Pacific Standard Time',
   *     abbreviation: 'PST',
   *   },
   *   {
   *     shortName: 'US/Alaska',
   *     longName: 'Alaska Standard Time',
   *     abbreviation: 'AKST',
   *   },
   *   {
   *     shortName: 'US/Hawaii',
   *     longName: 'Hawaii Standard Time',
   *     abbreviation: 'HST',
   *   }
   */
  timezone?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

/** AdvertisingSampaigns update input from advertising */
export type Advertising_AdvertisingSampaignUpdateInput = {
  data: AdvertisingSampaignUpdateInput;
  filter?: InputMaybe<AdvertisingSampaignKeyFilter>;
};

/** AdvertisingSupporter create input from advertising */
export type Advertising_AdvertisingSupporterCreateInput = {
  advertising?: InputMaybe<AdvertisingSupporterAdvertisingRelationInput>;
  isSupporter?: InputMaybe<Scalars['Boolean']>;
};

/** AdvertisingSupporter update input from advertising */
export type Advertising_AdvertisingSupporterUpdateInput = {
  advertising?: InputMaybe<AdvertisingSupporterAdvertisingUpdateRelationInput>;
  isSupporter?: InputMaybe<Scalars['Boolean']>;
};

/** Discounts create input from advertising */
export type Advertising_DiscountCreateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingRelationInput>;
  amountPercent: Scalars['Int'];
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostRelationInput>;
  expirationDate: Scalars['DateTime'];
  images?: InputMaybe<DiscountsImagesRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationRelationInput>;
  payment?: InputMaybe<DiscountsPaymentRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsRelationInput>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  terms: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

/** Discounts update input from advertising */
export type Advertising_DiscountUpdateInput = {
  data: DiscountUpdateInput;
  filter?: InputMaybe<DiscountKeyFilter>;
};

/** Organizations create input from advertising */
export type Advertising_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from advertising */
export type Advertising_OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

export type Advertising_PermissionFilter = {
  AND?: InputMaybe<Array<Advertising_PermissionFilter>>;
  OR?: InputMaybe<Array<Advertising_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  adsCampaigns?: InputMaybe<AdvertisingSampaign_PermissionRelationFilter>;
  advertisingSpecialOffers?: InputMaybe<Discount_PermissionRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  organization?: InputMaybe<Organization_PermissionFilter>;
  supporter?: InputMaybe<AdvertisingSupporter_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type AggregationFunctionType =
  | 'ANY_VALUE'
  | 'AVG'
  | 'COUNT'
  | 'GROUP_CONCAT'
  | 'MAX'
  | 'MIN'
  | 'STDDEV_POP'
  | 'STDDEV_SAMP'
  | 'SUM'
  | 'VAR_POP'
  | 'VAR_SAMP';

export type ApiToken = {
  __typename?: 'ApiToken';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<RoleListResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApiTokenRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleSort>>;
};

/** ApiTokens create input */
export type ApiTokenCreateInput = {
  name: Scalars['String'];
  roles?: InputMaybe<ApiTokensRolesRelationInput>;
};

/** ApiTokens delete input */
export type ApiTokenDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type ApiTokenFilter = {
  AND?: InputMaybe<Array<ApiTokenFilter>>;
  OR?: InputMaybe<Array<ApiTokenFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  name?: InputMaybe<StringPredicate>;
  roles?: InputMaybe<RoleRelationFilter>;
  token?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type ApiTokenGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: ApiTokenGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type ApiTokenGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  roles?: InputMaybe<RoleGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type ApiTokenKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** ApiTokenListResponse output */
export type ApiTokenListResponse = {
  __typename?: 'ApiTokenListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<ApiToken>;
};

/** ApiTokenManyResponse output */
export type ApiTokenManyResponse = {
  __typename?: 'ApiTokenManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<ApiToken>;
};

/** No longer supported. Use `sort` instead. */
export type ApiTokenOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'token_ASC'
  | 'token_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** ApiTokens subscription payload */
export type ApiTokenPayload = {
  __typename?: 'ApiTokenPayload';
  mutation: MutationType;
  node?: Maybe<ApiToken>;
  previousValues?: Maybe<ApiToken>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ApiTokenRelationFilter = {
  every?: InputMaybe<ApiTokenFilter>;
  none?: InputMaybe<ApiTokenFilter>;
  some?: InputMaybe<ApiTokenFilter>;
};

/** API Token Response */
export type ApiTokenResponse = {
  __typename?: 'ApiTokenResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<RoleListResponse>;
  token: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** API Token Response */
export type ApiTokenResponseRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleSort>>;
};

export type ApiTokenSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** ApiTokens subscription filter */
export type ApiTokenSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<ApiTokenFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** ApiTokens update input */
export type ApiTokenUpdateByFilterInput = {
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  token?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** ApiTokens update input */
export type ApiTokenUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<ApiTokensRolesUpdateRelationInput>;
};

export type ApiToken_PermissionFilter = {
  AND?: InputMaybe<Array<ApiToken_PermissionFilter>>;
  OR?: InputMaybe<Array<ApiToken_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  name?: InputMaybe<StringPredicate>;
  roles?: InputMaybe<Role_PermissionRelationFilter>;
  token?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type ApiToken_PermissionRelationFilter = {
  every?: InputMaybe<ApiToken_PermissionFilter>;
  none?: InputMaybe<ApiToken_PermissionFilter>;
  some?: InputMaybe<ApiToken_PermissionFilter>;
};

/** ApiTokens relation input */
export type ApiTokensRolesRelationInput = {
  connect?: InputMaybe<Array<RoleKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<ApiTokens_RoleCreateInput>>>;
};

/** ApiTokens relation input */
export type ApiTokensRolesUpdateRelationInput = {
  connect?: InputMaybe<Array<RoleKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<ApiTokens_RoleCreateInput>>>;
  disconnect?: InputMaybe<Array<RoleKeyFilter>>;
  reconnect?: InputMaybe<Array<RoleKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<ApiTokens_RoleUpdateInput>>>;
};

/** Roles create input from apiTokens */
export type ApiTokens_RoleCreateInput = {
  addon?: InputMaybe<RolesAddonRelationInput>;
  apiTokens?: InputMaybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: InputMaybe<RolesAuthenticationProfilesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notificationTemplates?: InputMaybe<RolesNotificationTemplatesRelationInput>;
  permissions?: InputMaybe<PermissionsInput>;
  teamMembers?: InputMaybe<RolesTeamMembersRelationInput>;
  users?: InputMaybe<RolesUsersRelationInput>;
};

/** Roles update input from apiTokens */
export type ApiTokens_RoleUpdateInput = {
  data: RoleUpdateInput;
  filter?: InputMaybe<RoleKeyFilter>;
};

/** Application */
export type Application = {
  __typename?: 'Application';
  appType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: ApplicationStatusEnum;
};

/** ApplicationDeleteMutationInput */
export type ApplicationDeleteMutationInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

/** Application install input */
export type ApplicationInstallInput = {
  appType: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status?: InputMaybe<ApplicationStatusEnum>;
};

/** ApplicationListResponse output */
export type ApplicationListResponse = {
  __typename?: 'ApplicationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Application>;
};

/** SchoolConfigurationImages create input from applicationLogo */
export type ApplicationLogo_SchoolConfigurationImageCreateInput = {
  applicationLogo?: InputMaybe<SchoolConfigurationImagesApplicationLogoRelationInput>;
  applicationLogoId?: InputMaybe<Scalars['String']>;
  browserLogo?: InputMaybe<SchoolConfigurationImagesBrowserLogoRelationInput>;
  browserLogoId?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfigurationImagesConfigurationRelationInput>;
  emailBanner?: InputMaybe<SchoolConfigurationImagesEmailBannerRelationInput>;
  emailLogo?: InputMaybe<SchoolConfigurationImagesEmailLogoRelationInput>;
  logo?: InputMaybe<SchoolConfigurationImagesLogoRelationInput>;
  splashImage?: InputMaybe<SchoolConfigurationImagesSplashImageRelationInput>;
};

/** SchoolConfigurationImages update input from applicationLogo */
export type ApplicationLogo_SchoolConfigurationImageUpdateInput = {
  data: SchoolConfigurationImageUpdateInput;
  filter?: InputMaybe<SchoolConfigurationImageKeyFilter>;
};

/** Application Status Enum */
export type ApplicationStatusEnum = 'ACTIVE' | 'INACTIVE';

/** Application update input */
export type ApplicationUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ApplicationStatusEnum>;
};

/** Async Session */
export type AsyncSession = {
  __typename?: 'AsyncSession';
  sessionId: Scalars['String'];
};

export type AsyncSessionStatusResponse = {
  __typename?: 'AsyncSessionStatusResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type Audience = {
  __typename?: 'Audience';
  _description?: Maybe<Scalars['String']>;
  ad?: Maybe<AdvertisingCampaignAdListResponse>;
  affiliation?: Maybe<Array<Maybe<Scalars['String']>>>;
  city?: Maybe<Array<Maybe<Scalars['String']>>>;
  clubs?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  distanceFromZip?: Maybe<Scalars['Int']>;
  endAge?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  graduatingYear?: Maybe<Array<Maybe<Scalars['Int']>>>;
  groups?: Maybe<GroupListResponse>;
  /** Relationship between the selected high schools for some audiences */
  highSchools?: Maybe<HighSchoolLocationListResponse>;
  hobbies?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  industry?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  schoolCommunities?: Maybe<SchoolConfigurationListResponse>;
  size?: Maybe<Scalars['Int']>;
  startAge?: Maybe<Scalars['Int']>;
  state?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AudienceAdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingCampaignAdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingCampaignAdGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingCampaignAdOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingCampaignAdSort>>;
};

export type AudienceGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupSort>>;
};

export type AudienceHighSchoolsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HighSchoolLocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<HighSchoolLocationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<HighSchoolLocationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HighSchoolLocationSort>>;
};

export type AudienceSchoolCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationSort>>;
};

/** Audiences create input */
export type AudienceCreateInput = {
  ad?: InputMaybe<AudiencesAdRelationInput>;
  affiliation?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  distanceFromZip?: InputMaybe<Scalars['Int']>;
  endAge?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  groups?: InputMaybe<AudiencesGroupsRelationInput>;
  highSchools?: InputMaybe<AudiencesHighSchoolsRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  organization?: InputMaybe<AudiencesOrganizationRelationInput>;
  schoolCommunities?: InputMaybe<AudiencesSchoolCommunitiesRelationInput>;
  size?: InputMaybe<Scalars['Int']>;
  startAge?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zipCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Audiences create many input */
export type AudienceCreateManyInput = {
  ad?: InputMaybe<AudiencesAdManyRelationInput>;
  affiliation?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  distanceFromZip?: InputMaybe<Scalars['Int']>;
  endAge?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  groups?: InputMaybe<AudiencesGroupsManyRelationInput>;
  highSchools?: InputMaybe<AudiencesHighSchoolsManyRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  organization?: InputMaybe<AudiencesOrganizationManyRelationInput>;
  schoolCommunities?: InputMaybe<AudiencesSchoolCommunitiesManyRelationInput>;
  size?: InputMaybe<Scalars['Int']>;
  startAge?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zipCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Audiences delete input */
export type AudienceDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** AudienceFieldsPermissions create input */
export type AudienceFieldsPermissions = {
  affiliation?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['Boolean']>;
  clubs?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  distanceFromZip?: InputMaybe<Scalars['Boolean']>;
  endAge?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['Boolean']>;
  graduatingYear?: InputMaybe<Scalars['Boolean']>;
  hobbies?: InputMaybe<Scalars['Boolean']>;
  industry?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Boolean']>;
  startAge?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  zipCode?: InputMaybe<Scalars['Boolean']>;
};

export type AudienceFilter = {
  AND?: InputMaybe<Array<AudienceFilter>>;
  OR?: InputMaybe<Array<AudienceFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  ad?: InputMaybe<AdvertisingCampaignAdRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  distanceFromZip?: InputMaybe<IntPredicate>;
  endAge?: InputMaybe<IntPredicate>;
  gender?: InputMaybe<StringPredicate>;
  groups?: InputMaybe<GroupRelationFilter>;
  highSchools?: InputMaybe<HighSchoolLocationRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  name?: InputMaybe<StringPredicate>;
  organization?: InputMaybe<OrganizationFilter>;
  schoolCommunities?: InputMaybe<SchoolConfigurationRelationFilter>;
  size?: InputMaybe<IntPredicate>;
  startAge?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type AudienceGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: AudienceGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type AudienceGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  ad?: InputMaybe<AdvertisingCampaignAdGroupByQuery>;
  affiliation?: InputMaybe<Array<GroupByField>>;
  city?: InputMaybe<Array<GroupByField>>;
  clubs?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  distanceFromZip?: InputMaybe<Array<GroupByField>>;
  endAge?: InputMaybe<Array<GroupByField>>;
  gender?: InputMaybe<Array<GroupByField>>;
  graduatingYear?: InputMaybe<Array<GroupByField>>;
  groups?: InputMaybe<GroupGroupByQuery>;
  highSchools?: InputMaybe<HighSchoolLocationGroupByQuery>;
  hobbies?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  industry?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  organization?: InputMaybe<OrganizationGroupByQuery>;
  schoolCommunities?: InputMaybe<SchoolConfigurationGroupByQuery>;
  size?: InputMaybe<Array<GroupByField>>;
  startAge?: InputMaybe<Array<GroupByField>>;
  state?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  zipCode?: InputMaybe<Array<GroupByField>>;
};

export type AudienceKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** AudienceListResponse output */
export type AudienceListResponse = {
  __typename?: 'AudienceListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Audience>;
};

/** AudienceManyResponse output */
export type AudienceManyResponse = {
  __typename?: 'AudienceManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Audience>;
};

/** No longer supported. Use `sort` instead. */
export type AudienceOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'distanceFromZip_ASC'
  | 'distanceFromZip_DESC'
  | 'endAge_ASC'
  | 'endAge_DESC'
  | 'gender_ASC'
  | 'gender_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'size_ASC'
  | 'size_DESC'
  | 'startAge_ASC'
  | 'startAge_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** Audiences subscription payload */
export type AudiencePayload = {
  __typename?: 'AudiencePayload';
  mutation: MutationType;
  node?: Maybe<Audience>;
  previousValues?: Maybe<Audience>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AudienceRelationFilter = {
  every?: InputMaybe<AudienceFilter>;
  none?: InputMaybe<AudienceFilter>;
  some?: InputMaybe<AudienceFilter>;
};

export type AudienceSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  distanceFromZip?: InputMaybe<SortOrder>;
  endAge?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationSort>;
  size?: InputMaybe<SortOrder>;
  startAge?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Audiences subscription filter */
export type AudienceSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<AudienceFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** Audiences update input */
export type AudienceUpdateByFilterInput = {
  affiliation?: InputMaybe<Array<InputMaybe<UpdateByFilterListStringInput>>>;
  city?: InputMaybe<Array<InputMaybe<UpdateByFilterListStringInput>>>;
  clubs?: InputMaybe<Array<InputMaybe<UpdateByFilterListStringInput>>>;
  distanceFromZip?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  endAge?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  gender?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  graduatingYear?: InputMaybe<Array<InputMaybe<UpdateByFilterListIntInput>>>;
  hobbies?: InputMaybe<Array<InputMaybe<UpdateByFilterListStringInput>>>;
  industry?: InputMaybe<Array<InputMaybe<UpdateByFilterListStringInput>>>;
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  size?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  startAge?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  state?: InputMaybe<Array<InputMaybe<UpdateByFilterListStringInput>>>;
  zipCode?: InputMaybe<Array<InputMaybe<UpdateByFilterListStringInput>>>;
};

/** Audiences update input */
export type AudienceUpdateInput = {
  ad?: InputMaybe<AudiencesAdUpdateRelationInput>;
  affiliation?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  distanceFromZip?: InputMaybe<Scalars['Int']>;
  endAge?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  groups?: InputMaybe<AudiencesGroupsUpdateRelationInput>;
  highSchools?: InputMaybe<AudiencesHighSchoolsUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['ID']>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<AudiencesOrganizationUpdateRelationInput>;
  schoolCommunities?: InputMaybe<AudiencesSchoolCommunitiesUpdateRelationInput>;
  size?: InputMaybe<Scalars['Int']>;
  startAge?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zipCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Audience_PermissionFilter = {
  AND?: InputMaybe<Array<Audience_PermissionFilter>>;
  OR?: InputMaybe<Array<Audience_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  ad?: InputMaybe<AdvertisingCampaignAd_PermissionRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  distanceFromZip?: InputMaybe<IntPredicate>;
  endAge?: InputMaybe<IntPredicate>;
  gender?: InputMaybe<StringPredicate>;
  groups?: InputMaybe<Group_PermissionRelationFilter>;
  highSchools?: InputMaybe<HighSchoolLocation_PermissionRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  name?: InputMaybe<StringPredicate>;
  organization?: InputMaybe<Organization_PermissionFilter>;
  schoolCommunities?: InputMaybe<SchoolConfiguration_PermissionRelationFilter>;
  size?: InputMaybe<IntPredicate>;
  startAge?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type Audience_PermissionRelationFilter = {
  every?: InputMaybe<Audience_PermissionFilter>;
  none?: InputMaybe<Audience_PermissionFilter>;
  some?: InputMaybe<Audience_PermissionFilter>;
};

/** Audiences relation input */
export type AudiencesAdManyRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
};

/** Audiences relation input */
export type AudiencesAdRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Audiences_AdvertisingCampaignAdCreateInput>>>;
};

/** Audiences relation input */
export type AudiencesAdUpdateRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Audiences_AdvertisingCampaignAdCreateInput>>>;
  disconnect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  reconnect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Audiences_AdvertisingCampaignAdUpdateInput>>>;
};

/** Audiences relation input */
export type AudiencesGroupsManyRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
};

/** Audiences relation input */
export type AudiencesGroupsRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Audiences_GroupCreateInput>>>;
};

/** Audiences relation input */
export type AudiencesGroupsUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Audiences_GroupCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Audiences_GroupUpdateInput>>>;
};

/** Audiences relation input */
export type AudiencesHighSchoolsManyRelationInput = {
  connect?: InputMaybe<Array<HighSchoolLocationKeyFilter>>;
};

/** Audiences relation input */
export type AudiencesHighSchoolsRelationInput = {
  connect?: InputMaybe<Array<HighSchoolLocationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Audiences_HighSchoolLocationCreateInput>>>;
};

/** Audiences relation input */
export type AudiencesHighSchoolsUpdateRelationInput = {
  connect?: InputMaybe<Array<HighSchoolLocationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Audiences_HighSchoolLocationCreateInput>>>;
  disconnect?: InputMaybe<Array<HighSchoolLocationKeyFilter>>;
  reconnect?: InputMaybe<Array<HighSchoolLocationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Audiences_HighSchoolLocationUpdateInput>>>;
};

/** Audiences relation input */
export type AudiencesOrganizationManyRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
};

/** Audiences relation input */
export type AudiencesOrganizationRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<Audiences_OrganizationCreateInput>;
};

/** Audiences relation input */
export type AudiencesOrganizationUpdateRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<Audiences_OrganizationCreateInput>;
  disconnect?: InputMaybe<OrganizationKeyFilter>;
  reconnect?: InputMaybe<OrganizationKeyFilter>;
  update?: InputMaybe<Audiences_OrganizationUpdateInput>;
};

/** Audiences relation input */
export type AudiencesSchoolCommunitiesManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
};

/** Audiences relation input */
export type AudiencesSchoolCommunitiesRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Audiences_SchoolConfigurationCreateInput>>>;
};

/** Audiences relation input */
export type AudiencesSchoolCommunitiesUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Audiences_SchoolConfigurationCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Audiences_SchoolConfigurationUpdateInput>>>;
};

/** AdvertisingCampaignAds create input from audiences */
export type Audiences_AdvertisingCampaignAdCreateInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdRelationInput>;
  callToAction: Scalars['String'];
  callToActionLink: Scalars['String'];
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  primaryText: Scalars['String'];
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign?: InputMaybe<AdvertisingCampaignAdsSampaignRelationInput>;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget: Scalars['Float'];
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

/** AdvertisingCampaignAds update input from audiences */
export type Audiences_AdvertisingCampaignAdUpdateInput = {
  data: AdvertisingCampaignAdUpdateInput;
  filter?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
};

/** Groups create input from audiences */
export type Audiences_GroupCreateInput = {
  audiences?: InputMaybe<GroupsAudiencesRelationInput>;
  creator?: InputMaybe<GroupsCreatorRelationInput>;
  description: Scalars['String'];
  groupAdminPreferences?: InputMaybe<GroupsGroupAdminPreferencesRelationInput>;
  groupToJoin?: InputMaybe<GroupsGroupToJoinRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<GroupsLogoRelationInput>;
  members?: InputMaybe<GroupsMembersRelationInput>;
  membersCount?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<GroupsPostsRelationInput>;
  postsCount?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<GroupsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Groups update input from audiences */
export type Audiences_GroupUpdateInput = {
  data: GroupUpdateInput;
  filter?: InputMaybe<GroupKeyFilter>;
};

/** HighSchoolLocations create input from audiences */
export type Audiences_HighSchoolLocationCreateInput = {
  audiences?: InputMaybe<HighSchoolLocationsAudiencesRelationInput>;
  city?: InputMaybe<Scalars['String']>;
  educations?: InputMaybe<HighSchoolLocationsEducationsRelationInput>;
  logo?: InputMaybe<HighSchoolLocationsLogoRelationInput>;
  logoId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** HighSchoolLocations update input from audiences */
export type Audiences_HighSchoolLocationUpdateInput = {
  data: HighSchoolLocationUpdateInput;
  filter?: InputMaybe<HighSchoolLocationKeyFilter>;
};

/** Organizations create input from audiences */
export type Audiences_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from audiences */
export type Audiences_OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

/** SchoolConfiguration create input from audiences */
export type Audiences_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from audiences */
export type Audiences_SchoolConfigurationUpdateInput = {
  data: SchoolConfigurationUpdateInput;
  filter?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** Auth response */
export type Auth = {
  __typename?: 'Auth';
  accessToken?: Maybe<Scalars['String']>;
  accessTokenExpiresAt?: Maybe<Scalars['Int']>;
  idToken?: Maybe<Scalars['String']>;
  idTokenExpiresAt?: Maybe<Scalars['Int']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type AuthenticationProfile = {
  __typename?: 'AuthenticationProfile';
  _description?: Maybe<Scalars['String']>;
  attributes?: Maybe<AuthenticationProfileAttributes>;
  audiences?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  databaseName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  managementDomain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<RoleListResponse>;
  secret?: Maybe<Scalars['String']>;
  selfSignUpEmailDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
  selfSignUpEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AuthenticationProfileRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleSort>>;
};

/** Authentication Profile Attributes */
export type AuthenticationProfileAttributes = CognitoAuthProfileAttributes;

/** Authentication profile connection options */
export type AuthenticationProfileConnectionOptions = {
  __typename?: 'AuthenticationProfileConnectionOptions';
  facebook?: Maybe<FacebookOptions>;
  github?: Maybe<GithubOptions>;
  google?: Maybe<GoogleOptions>;
};

/** Authentication profile connection options input */
export type AuthenticationProfileConnectionsOptionsInput = {
  facebook?: InputMaybe<FacebookOptionsInput>;
  github?: InputMaybe<GithubOptionsInput>;
  google?: InputMaybe<GoogleOptionsInput>;
};

/** AuthenticationProfiles create input */
export type AuthenticationProfileCreateInput = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clientId?: InputMaybe<Scalars['String']>;
  databaseName?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  managementDomain?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  roles?: InputMaybe<AuthenticationProfilesRolesRelationInput>;
  secret?: InputMaybe<Scalars['String']>;
  selfSignUpEmailDomains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selfSignUpEnabled?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

/** AuthenticationProfiles create many input */
export type AuthenticationProfileCreateManyInput = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clientId?: InputMaybe<Scalars['String']>;
  databaseName?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  managementDomain?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  roles?: InputMaybe<AuthenticationProfilesRolesManyRelationInput>;
  secret?: InputMaybe<Scalars['String']>;
  selfSignUpEmailDomains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selfSignUpEnabled?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

/** AuthenticationProfiles delete input */
export type AuthenticationProfileDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type AuthenticationProfileFilter = {
  AND?: InputMaybe<Array<AuthenticationProfileFilter>>;
  OR?: InputMaybe<Array<AuthenticationProfileFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  databaseName?: InputMaybe<StringPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  domain?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  managementDomain?: InputMaybe<StringPredicate>;
  name?: InputMaybe<StringPredicate>;
  roles?: InputMaybe<RoleRelationFilter>;
  secret?: InputMaybe<StringPredicate>;
  selfSignUpEnabled?: InputMaybe<BoolPredicate>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type AuthenticationProfileGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: AuthenticationProfileGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type AuthenticationProfileGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  audiences?: InputMaybe<Array<GroupByField>>;
  clientId?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  databaseName?: InputMaybe<Array<GroupByField>>;
  domain?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  managementDomain?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  roles?: InputMaybe<RoleGroupByQuery>;
  secret?: InputMaybe<Array<GroupByField>>;
  selfSignUpEmailDomains?: InputMaybe<Array<GroupByField>>;
  selfSignUpEnabled?: InputMaybe<Array<GroupByField>>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type AuthenticationProfileKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** AuthenticationProfileListResponse output */
export type AuthenticationProfileListResponse = {
  __typename?: 'AuthenticationProfileListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<AuthenticationProfile>;
};

/** AuthenticationProfileManyResponse output */
export type AuthenticationProfileManyResponse = {
  __typename?: 'AuthenticationProfileManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<AuthenticationProfile>;
};

/** No longer supported. Use `sort` instead. */
export type AuthenticationProfileOrderBy =
  | 'clientId_ASC'
  | 'clientId_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'databaseName_ASC'
  | 'databaseName_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'domain_ASC'
  | 'domain_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'managementDomain_ASC'
  | 'managementDomain_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'secret_ASC'
  | 'secret_DESC'
  | 'selfSignUpEnabled_ASC'
  | 'selfSignUpEnabled_DESC'
  | 'type_ASC'
  | 'type_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** AuthenticationProfiles subscription payload */
export type AuthenticationProfilePayload = {
  __typename?: 'AuthenticationProfilePayload';
  mutation: MutationType;
  node?: Maybe<AuthenticationProfile>;
  previousValues?: Maybe<AuthenticationProfile>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AuthenticationProfileRelationFilter = {
  every?: InputMaybe<AuthenticationProfileFilter>;
  none?: InputMaybe<AuthenticationProfileFilter>;
  some?: InputMaybe<AuthenticationProfileFilter>;
};

export type AuthenticationProfileSort = {
  clientId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  databaseName?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  managementDomain?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  secret?: InputMaybe<SortOrder>;
  selfSignUpEnabled?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** AuthenticationProfiles subscription filter */
export type AuthenticationProfileSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<AuthenticationProfileFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** AuthenticationProfiles update input */
export type AuthenticationProfileUpdateByFilterInput = {
  audiences?: InputMaybe<Array<InputMaybe<UpdateByFilterListStringInput>>>;
  clientId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  databaseName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  domain?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  managementDomain?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  secret?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  selfSignUpEmailDomains?: InputMaybe<Array<InputMaybe<UpdateByFilterListStringInput>>>;
  selfSignUpEnabled?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  type?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** AuthenticationProfiles update input */
export type AuthenticationProfileUpdateInput = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clientId?: InputMaybe<Scalars['String']>;
  databaseName?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  managementDomain?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<AuthenticationProfilesRolesUpdateRelationInput>;
  secret?: InputMaybe<Scalars['String']>;
  selfSignUpEmailDomains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selfSignUpEnabled?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AuthenticationProfile_PermissionFilter = {
  AND?: InputMaybe<Array<AuthenticationProfile_PermissionFilter>>;
  OR?: InputMaybe<Array<AuthenticationProfile_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  databaseName?: InputMaybe<StringPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  domain?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  managementDomain?: InputMaybe<StringPredicate>;
  name?: InputMaybe<StringPredicate>;
  roles?: InputMaybe<Role_PermissionRelationFilter>;
  secret?: InputMaybe<StringPredicate>;
  selfSignUpEnabled?: InputMaybe<BoolPredicate>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type AuthenticationProfile_PermissionRelationFilter = {
  every?: InputMaybe<AuthenticationProfile_PermissionFilter>;
  none?: InputMaybe<AuthenticationProfile_PermissionFilter>;
  some?: InputMaybe<AuthenticationProfile_PermissionFilter>;
};

/** AuthenticationProfiles relation input */
export type AuthenticationProfilesRolesManyRelationInput = {
  connect?: InputMaybe<Array<RoleKeyFilter>>;
};

/** AuthenticationProfiles relation input */
export type AuthenticationProfilesRolesRelationInput = {
  connect?: InputMaybe<Array<RoleKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<AuthenticationProfiles_RoleCreateInput>>>;
};

/** AuthenticationProfiles relation input */
export type AuthenticationProfilesRolesUpdateRelationInput = {
  connect?: InputMaybe<Array<RoleKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<AuthenticationProfiles_RoleCreateInput>>>;
  disconnect?: InputMaybe<Array<RoleKeyFilter>>;
  reconnect?: InputMaybe<Array<RoleKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<AuthenticationProfiles_RoleUpdateInput>>>;
};

/** Roles create input from authenticationProfiles */
export type AuthenticationProfiles_RoleCreateInput = {
  addon?: InputMaybe<RolesAddonRelationInput>;
  apiTokens?: InputMaybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: InputMaybe<RolesAuthenticationProfilesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notificationTemplates?: InputMaybe<RolesNotificationTemplatesRelationInput>;
  permissions?: InputMaybe<PermissionsInput>;
  teamMembers?: InputMaybe<RolesTeamMembersRelationInput>;
  users?: InputMaybe<RolesUsersRelationInput>;
};

/** Roles update input from authenticationProfiles */
export type AuthenticationProfiles_RoleUpdateInput = {
  data: RoleUpdateInput;
  filter?: InputMaybe<RoleKeyFilter>;
};

export type AuthenticationSetting = {
  __typename?: 'AuthenticationSetting';
  _description?: Maybe<Scalars['String']>;
  allowedCallbacks?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedLogouts?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedWebOrigins?: Maybe<Array<Maybe<Scalars['String']>>>;
  connections?: Maybe<AuthenticationProfileConnectionOptions>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AuthenticationSettingFilter = {
  AND?: InputMaybe<Array<AuthenticationSettingFilter>>;
  OR?: InputMaybe<Array<AuthenticationSettingFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

/** AuthenticationSettings subscription payload */
export type AuthenticationSettingPayload = {
  __typename?: 'AuthenticationSettingPayload';
  mutation: MutationType;
  node?: Maybe<AuthenticationSetting>;
  previousValues?: Maybe<AuthenticationSetting>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** AuthenticationSettings subscription filter */
export type AuthenticationSettingSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<AuthenticationSettingFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** AuthenticationSettings update input */
export type AuthenticationSettingUpdateInput = {
  allowedCallbacks?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  allowedLogouts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  allowedWebOrigins?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  connections?: InputMaybe<AuthenticationProfileConnectionsOptionsInput>;
};

/** Events create input from author */
export type Author_EventCreateInput = {
  author?: InputMaybe<EventsAuthorRelationInput>;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<EventsImagesRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<EventsLocationRelationInput>;
  otherSponsors?: InputMaybe<EventsOtherSponsorsRelationInput>;
  school?: InputMaybe<EventsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  sponsorRequests?: InputMaybe<EventsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleSponsor?: InputMaybe<EventsTitleSponsorRelationInput>;
};

/** Events update input from author */
export type Author_EventUpdateInput = {
  data: EventUpdateInput;
  filter?: InputMaybe<EventKeyFilter>;
};

/** GroupPosts create input from author */
export type Author_GroupPostCreateInput = {
  author?: InputMaybe<GroupPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<GroupPostsFlaggedByRelationInput>;
  group: GroupPostsGroupRelationInput;
  groupAdminPreferences?: InputMaybe<GroupPostsGroupAdminPreferencesRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<GroupPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<GroupPostsMediaRelationInput>;
  mentions?: InputMaybe<GroupPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** GroupPosts update input from author */
export type Author_GroupPostUpdateInput = {
  data: GroupPostUpdateInput;
  filter?: InputMaybe<GroupPostKeyFilter>;
};

/** HomeFeedPosts create input from author */
export type Author_HomeFeedPostCreateInput = {
  author?: InputMaybe<HomeFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<HomeFeedPostsDiscountRelationInput>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<HomeFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<HomeFeedPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<HomeFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<HomeFeedPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<HomeFeedPostsSchoolsRelationInput>;
  /**
   * Approved - post is displayed
   * Pending - default status, awaits auto-approval/approved by admins. Not displayed
   * Rejected - auto-rejected/rejected by admins
   * reportReviewPending - post got flagged, pending review
   */
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** HomeFeedPosts update input from author */
export type Author_HomeFeedPostUpdateInput = {
  data: HomeFeedPostUpdateInput;
  filter?: InputMaybe<HomeFeedPostKeyFilter>;
};

/** InboxMessages create input from author */
export type Author_InboxMessageCreateInput = {
  author?: InputMaybe<InboxMessagesAuthorRelationInput>;
  deletedBy?: InputMaybe<InboxMessagesDeletedByRelationInput>;
  inbox: InboxMessagesInboxRelationInput;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isHide?: InputMaybe<InboxMessagesIsHideRelationInput>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<InboxMessagesMediaRelationInput>;
  messageMedia?: InputMaybe<InboxMessagesMessageMediaRelationInput>;
  reply?: InputMaybe<Scalars['Boolean']>;
  text: Scalars['String'];
};

/** InboxMessages update input from author */
export type Author_InboxMessageUpdateInput = {
  data: InboxMessageUpdateInput;
  filter?: InputMaybe<InboxMessageKeyFilter>;
};

/** LegacyFeedPosts create input from author */
export type Author_LegacyFeedPostCreateInput = {
  author?: InputMaybe<LegacyFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<LegacyFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<LegacyFeedPostsLastFlaggedByRelationInput>;
  legacyDate?: InputMaybe<Scalars['Date']>;
  media?: InputMaybe<LegacyFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<LegacyFeedPostsMentionsRelationInput>;
  month?: InputMaybe<LegacyFeedPostsMonthRelationInput>;
  monthId?: InputMaybe<Scalars['String']>;
  /** Date where the post will be available on the feed. */
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<LegacyFeedPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** LegacyFeedPosts update input from author */
export type Author_LegacyFeedPostUpdateInput = {
  data: LegacyFeedPostUpdateInput;
  filter?: InputMaybe<LegacyFeedPostKeyFilter>;
};

/** SchoolNewsPosts create input from author */
export type Author_SchoolNewsPostCreateInput = {
  author?: InputMaybe<SchoolNewsPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<SchoolNewsPostsFlaggedByRelationInput>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned: Scalars['Boolean'];
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<SchoolNewsPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<SchoolNewsPostsMediaRelationInput>;
  mentions?: InputMaybe<SchoolNewsPostsMentionsRelationInput>;
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<SchoolNewsPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** SchoolNewsPosts update input from author */
export type Author_SchoolNewsPostUpdateInput = {
  data: SchoolNewsPostUpdateInput;
  filter?: InputMaybe<SchoolNewsPostKeyFilter>;
};

/** TeamMembers update input from avatar */
export type Avatar_TeamMemberUpdateInput = {
  data: TeamMemberUpdateInput;
  filter?: InputMaybe<TeamMemberKeyFilter>;
};

/** Users create input from avatar */
export type Avatar_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from avatar */
export type Avatar_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

export type AwsSignInfoResponse = {
  __typename?: 'AwsSignInfoResponse';
  fields: Scalars['JSON'];
  path: Scalars['String'];
  url: Scalars['String'];
};

export type BigIntPredicateHaving = {
  AND?: InputMaybe<Array<BigIntPredicateHaving>>;
  OR?: InputMaybe<Array<BigIntPredicateHaving>>;
  equals?: InputMaybe<Scalars['BigInt']>;
  gt?: InputMaybe<Scalars['BigInt']>;
  gte?: InputMaybe<Scalars['BigInt']>;
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['BigInt']>;
  lte?: InputMaybe<Scalars['BigInt']>;
  not_equals?: InputMaybe<Scalars['BigInt']>;
  not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

/** BillingRegisteredAds create input from billingAd */
export type BillingAd_BillingRegisteredAdCreateInput = {
  ad?: InputMaybe<BillingRegisteredAdsAdRelationInput>;
  billingAd?: InputMaybe<BillingRegisteredAdsBillingAdRelationInput>;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  price?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

/** BillingRegisteredAds update input from billingAd */
export type BillingAd_BillingRegisteredAdUpdateInput = {
  data: BillingRegisteredAdUpdateInput;
  filter?: InputMaybe<BillingRegisteredAdKeyFilter>;
};

/** AdvertisingCampaignAds create input from billingAds */
export type BillingAds_AdvertisingCampaignAdCreateInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdRelationInput>;
  callToAction: Scalars['String'];
  callToActionLink: Scalars['String'];
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  primaryText: Scalars['String'];
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign?: InputMaybe<AdvertisingCampaignAdsSampaignRelationInput>;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget: Scalars['Float'];
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

/** AdvertisingCampaignAds update input from billingAds */
export type BillingAds_AdvertisingCampaignAdUpdateInput = {
  data: AdvertisingCampaignAdUpdateInput;
  filter?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
};

/** Organizations create input from billingAds */
export type BillingAds_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from billingAds */
export type BillingAds_OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

export type BillingCurrentPlanResponse = {
  __typename?: 'BillingCurrentPlanResponse';
  customerId?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<BillingPlanFeature>>>;
  id?: Maybe<Scalars['ID']>;
  limitMetrics?: Maybe<Array<Maybe<BillingPlanLimitMetrics>>>;
  name?: Maybe<Scalars['String']>;
  nextPlan?: Maybe<BillingNextPlanResponse>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentDetails?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  status?: Maybe<WorkspaceStatus>;
  subscriptionId?: Maybe<Scalars['String']>;
  trialEnd?: Maybe<Scalars['DateTime']>;
};

export type BillingDetailsOrigin = 'member' | 'organization' | 'workspace';

export type BillingDetailsResponse = {
  __typename?: 'BillingDetailsResponse';
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  last4?: Maybe<Scalars['String']>;
  origin: BillingDetailsOrigin;
};

export type BillingInvoiceItem = {
  __typename?: 'BillingInvoiceItem';
  amountDue?: Maybe<Scalars['Float']>;
  amountPaid?: Maybe<Scalars['Float']>;
  amountRemaining?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  endingBalance?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoicePdf?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  organization?: Maybe<BillingInvoiceItemOrganizationInfo>;
  paid?: Maybe<Scalars['Boolean']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<BillingInvoiceItemPlanInfo>;
  project?: Maybe<BillingInvoiceItemProjectInfo>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type BillingInvoiceItemOrganizationInfo = {
  __typename?: 'BillingInvoiceItemOrganizationInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BillingInvoiceItemPlanInfo = {
  __typename?: 'BillingInvoiceItemPlanInfo';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BillingInvoiceItemProjectInfo = {
  __typename?: 'BillingInvoiceItemProjectInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BillingInvoicesListFilterType = 'CUSTOMER' | 'PROJECT';

/** BillingInvoicesListResponse output */
export type BillingInvoicesListResponse = {
  __typename?: 'BillingInvoicesListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<BillingInvoiceItem>;
};

export type BillingLimitMetricItem = {
  __typename?: 'BillingLimitMetricItem';
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  showPriority?: Maybe<Scalars['Int']>;
  tooltip?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type BillingMetricOverageItem = {
  __typename?: 'BillingMetricOverageItem';
  value?: Maybe<Scalars['Float']>;
  warning?: Maybe<Scalars['String']>;
};

export type BillingMetricUsageItem = {
  __typename?: 'BillingMetricUsageItem';
  limitMetric?: Maybe<BillingLimitMetricItem>;
  overage?: Maybe<BillingMetricOverageItem>;
  value?: Maybe<Scalars['Float']>;
};

export type BillingMetricUsageQuotaItem = {
  __typename?: 'BillingMetricUsageQuotaItem';
  limitMetric?: Maybe<BillingLimitMetricItem>;
  value?: Maybe<Scalars['Float']>;
};

/** BillingMetricUsageQuotasListResponse output */
export type BillingMetricUsageQuotasListResponse = {
  __typename?: 'BillingMetricUsageQuotasListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<BillingMetricUsageQuotaItem>;
};

export type BillingMetricUsagesListFilter = {
  entryDate: DateTimePredicate;
};

/** BillingMetricUsagesListResponse output */
export type BillingMetricUsagesListResponse = {
  __typename?: 'BillingMetricUsagesListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<BillingMetricUsageItem>;
};

export type BillingNextPlanResponse = {
  __typename?: 'BillingNextPlanResponse';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export type BillingPlanBaseInfo = {
  __typename?: 'BillingPlanBaseInfo';
  capacity?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  featuresTitle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isLegacy?: Maybe<Scalars['Boolean']>;
  limitMetrics?: Maybe<Array<Maybe<BillingPlanLimitMetricItem>>>;
  name?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type BillingPlanFeature = {
  __typename?: 'BillingPlanFeature';
  displayName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type BillingPlanLimitMetricItem = {
  __typename?: 'BillingPlanLimitMetricItem';
  displayName?: Maybe<Scalars['String']>;
  hardLimit?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  overagePrice?: Maybe<Scalars['Int']>;
  softLimit?: Maybe<Scalars['Float']>;
};

export type BillingPlanLimitMetrics = {
  __typename?: 'BillingPlanLimitMetrics';
  hardLimit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overagePrice?: Maybe<Scalars['String']>;
  softLimit?: Maybe<Scalars['String']>;
};

/** BillingPlanUpdateMutationInput */
export type BillingPlanUpdateMutationInput = {
  planId: Scalars['ID'];
  projectID: Scalars['ID'];
};

export type BillingRegisteredAd = {
  __typename?: 'BillingRegisteredAd';
  _description?: Maybe<Scalars['String']>;
  ad?: Maybe<AdvertisingCampaignAd>;
  billingAd?: Maybe<BillingsAd>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  paidDate?: Maybe<Scalars['DateTime']>;
  price?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** BillingRegisteredAds create input */
export type BillingRegisteredAdCreateInput = {
  ad?: InputMaybe<BillingRegisteredAdsAdRelationInput>;
  billingAd?: InputMaybe<BillingRegisteredAdsBillingAdRelationInput>;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  price?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

/** BillingRegisteredAds create many input */
export type BillingRegisteredAdCreateManyInput = {
  ad?: InputMaybe<BillingRegisteredAdsAdManyRelationInput>;
  billingAd: BillingRegisteredAdsBillingAdManyRelationInput;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  price?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

/** BillingRegisteredAds delete input */
export type BillingRegisteredAdDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** BillingRegisteredAdFieldsPermissions create input */
export type BillingRegisteredAdFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  paidDate?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BillingRegisteredAdFilter = {
  AND?: InputMaybe<Array<BillingRegisteredAdFilter>>;
  OR?: InputMaybe<Array<BillingRegisteredAdFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  ad?: InputMaybe<AdvertisingCampaignAdFilter>;
  billingAd?: InputMaybe<BillingsAdFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  paidDate?: InputMaybe<DateTimePredicate>;
  price?: InputMaybe<IntPredicate>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type BillingRegisteredAdGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: BillingRegisteredAdGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type BillingRegisteredAdGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  ad?: InputMaybe<AdvertisingCampaignAdGroupByQuery>;
  billingAd?: InputMaybe<BillingsAdGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  paidDate?: InputMaybe<Array<GroupByField>>;
  price?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type BillingRegisteredAdKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** BillingRegisteredAdListResponse output */
export type BillingRegisteredAdListResponse = {
  __typename?: 'BillingRegisteredAdListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<BillingRegisteredAd>;
};

/** BillingRegisteredAdManyResponse output */
export type BillingRegisteredAdManyResponse = {
  __typename?: 'BillingRegisteredAdManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<BillingRegisteredAd>;
};

/** No longer supported. Use `sort` instead. */
export type BillingRegisteredAdOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'paidDate_ASC'
  | 'paidDate_DESC'
  | 'price_ASC'
  | 'price_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** BillingRegisteredAds subscription payload */
export type BillingRegisteredAdPayload = {
  __typename?: 'BillingRegisteredAdPayload';
  mutation: MutationType;
  node?: Maybe<BillingRegisteredAd>;
  previousValues?: Maybe<BillingRegisteredAd>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingRegisteredAdRelationFilter = {
  every?: InputMaybe<BillingRegisteredAdFilter>;
  none?: InputMaybe<BillingRegisteredAdFilter>;
  some?: InputMaybe<BillingRegisteredAdFilter>;
};

export type BillingRegisteredAdSort = {
  ad?: InputMaybe<AdvertisingCampaignAdSort>;
  billingAd?: InputMaybe<BillingsAdSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paidDate?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** BillingRegisteredAds subscription filter */
export type BillingRegisteredAdSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<BillingRegisteredAdFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** BillingRegisteredAds update input */
export type BillingRegisteredAdUpdateByFilterInput = {
  paidDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  price?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
};

/** BillingRegisteredAds update input */
export type BillingRegisteredAdUpdateInput = {
  ad?: InputMaybe<BillingRegisteredAdsAdUpdateRelationInput>;
  billingAd?: InputMaybe<BillingRegisteredAdsBillingAdUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  price?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

/** AdvertisingCampaignAds create input from billingRegisteredAd */
export type BillingRegisteredAd_AdvertisingCampaignAdCreateInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdRelationInput>;
  callToAction: Scalars['String'];
  callToActionLink: Scalars['String'];
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  primaryText: Scalars['String'];
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign?: InputMaybe<AdvertisingCampaignAdsSampaignRelationInput>;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget: Scalars['Float'];
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

/** AdvertisingCampaignAds update input from billingRegisteredAd */
export type BillingRegisteredAd_AdvertisingCampaignAdUpdateInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsUpdateRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdUpdateRelationInput>;
  callToAction?: InputMaybe<Scalars['String']>;
  callToActionLink?: InputMaybe<Scalars['String']>;
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageUpdateRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryText?: InputMaybe<Scalars['String']>;
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign?: InputMaybe<AdvertisingCampaignAdsSampaignUpdateRelationInput>;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget?: InputMaybe<Scalars['Float']>;
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksUpdateRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesUpdateRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsUpdateRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

export type BillingRegisteredAd_PermissionFilter = {
  AND?: InputMaybe<Array<BillingRegisteredAd_PermissionFilter>>;
  OR?: InputMaybe<Array<BillingRegisteredAd_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  ad?: InputMaybe<AdvertisingCampaignAd_PermissionFilter>;
  billingAd?: InputMaybe<BillingsAd_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  paidDate?: InputMaybe<DateTimePredicate>;
  price?: InputMaybe<IntPredicate>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type BillingRegisteredAd_PermissionRelationFilter = {
  every?: InputMaybe<BillingRegisteredAd_PermissionFilter>;
  none?: InputMaybe<BillingRegisteredAd_PermissionFilter>;
  some?: InputMaybe<BillingRegisteredAd_PermissionFilter>;
};

/** BillingRegisteredAds relation input */
export type BillingRegisteredAdsAdManyRelationInput = {
  connect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
};

/** BillingRegisteredAds relation input */
export type BillingRegisteredAdsAdRelationInput = {
  connect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  create?: InputMaybe<BillingRegisteredAd_AdvertisingCampaignAdCreateInput>;
};

/** BillingRegisteredAds relation input */
export type BillingRegisteredAdsAdUpdateRelationInput = {
  connect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  create?: InputMaybe<BillingRegisteredAd_AdvertisingCampaignAdCreateInput>;
  disconnect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  reconnect?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  update?: InputMaybe<BillingRegisteredAd_AdvertisingCampaignAdUpdateInput>;
};

/** BillingRegisteredAds relation input */
export type BillingRegisteredAdsBillingAdManyRelationInput = {
  connect?: InputMaybe<BillingsAdKeyFilter>;
};

/** BillingRegisteredAds relation input */
export type BillingRegisteredAdsBillingAdRelationInput = {
  connect?: InputMaybe<BillingsAdKeyFilter>;
  create?: InputMaybe<RegisteredBillingAds_BillingsAdCreateInput>;
};

/** BillingRegisteredAds relation input */
export type BillingRegisteredAdsBillingAdUpdateRelationInput = {
  connect?: InputMaybe<BillingsAdKeyFilter>;
  create?: InputMaybe<RegisteredBillingAds_BillingsAdCreateInput>;
  disconnect?: InputMaybe<BillingsAdKeyFilter>;
  reconnect?: InputMaybe<BillingsAdKeyFilter>;
  update?: InputMaybe<RegisteredBillingAds_BillingsAdUpdateInput>;
};

/** Discounts create input from billingRegisteredDiscounts */
export type BillingRegisteredDiscounts_DiscountCreateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingRelationInput>;
  amountPercent: Scalars['Int'];
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostRelationInput>;
  expirationDate: Scalars['DateTime'];
  images?: InputMaybe<DiscountsImagesRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationRelationInput>;
  payment?: InputMaybe<DiscountsPaymentRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsRelationInput>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  terms: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

/** Discounts update input from billingRegisteredDiscounts */
export type BillingRegisteredDiscounts_DiscountUpdateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingUpdateRelationInput>;
  amountPercent?: InputMaybe<Scalars['Int']>;
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsUpdateRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostUpdateRelationInput>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<DiscountsImagesUpdateRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationUpdateRelationInput>;
  payment?: InputMaybe<DiscountsPaymentUpdateRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsUpdateRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsUpdateRelationInput>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  terms?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedUpdateRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

export type BillingRegisteredSchoolSupporter = {
  __typename?: 'BillingRegisteredSchoolSupporter';
  _description?: Maybe<Scalars['String']>;
  billingSchoolSupporters?: Maybe<BillingSchoolSupporter>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  paidDate?: Maybe<Scalars['DateTime']>;
  planPayDate?: Maybe<Scalars['DateTime']>;
  price?: Maybe<Scalars['Int']>;
  schoolSupporter?: Maybe<SchoolSupporter>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** BillingRegisteredSchoolSupporters create input */
export type BillingRegisteredSchoolSupporterCreateInput = {
  billingSchoolSupporters?: InputMaybe<BillingRegisteredSchoolSupportersBillingSchoolSupportersRelationInput>;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  planPayDate?: InputMaybe<Scalars['DateTime']>;
  price?: InputMaybe<Scalars['Int']>;
  schoolSupporter?: InputMaybe<BillingRegisteredSchoolSupportersSchoolSupporterRelationInput>;
  status?: InputMaybe<Scalars['String']>;
};

/** BillingRegisteredSchoolSupporters create many input */
export type BillingRegisteredSchoolSupporterCreateManyInput = {
  billingSchoolSupporters: BillingRegisteredSchoolSupportersBillingSchoolSupportersManyRelationInput;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  planPayDate?: InputMaybe<Scalars['DateTime']>;
  price?: InputMaybe<Scalars['Int']>;
  schoolSupporter?: InputMaybe<BillingRegisteredSchoolSupportersSchoolSupporterManyRelationInput>;
  status?: InputMaybe<Scalars['String']>;
};

/** BillingRegisteredSchoolSupporters delete input */
export type BillingRegisteredSchoolSupporterDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** BillingRegisteredSchoolSupporterFieldsPermissions create input */
export type BillingRegisteredSchoolSupporterFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  paidDate?: InputMaybe<Scalars['Boolean']>;
  planPayDate?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BillingRegisteredSchoolSupporterFilter = {
  AND?: InputMaybe<Array<BillingRegisteredSchoolSupporterFilter>>;
  OR?: InputMaybe<Array<BillingRegisteredSchoolSupporterFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  billingSchoolSupporters?: InputMaybe<BillingSchoolSupporterFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  paidDate?: InputMaybe<DateTimePredicate>;
  planPayDate?: InputMaybe<DateTimePredicate>;
  price?: InputMaybe<IntPredicate>;
  schoolSupporter?: InputMaybe<SchoolSupporterFilter>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type BillingRegisteredSchoolSupporterGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: BillingRegisteredSchoolSupporterGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type BillingRegisteredSchoolSupporterGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  billingSchoolSupporters?: InputMaybe<BillingSchoolSupporterGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  paidDate?: InputMaybe<Array<GroupByField>>;
  planPayDate?: InputMaybe<Array<GroupByField>>;
  price?: InputMaybe<Array<GroupByField>>;
  schoolSupporter?: InputMaybe<SchoolSupporterGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type BillingRegisteredSchoolSupporterKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** BillingRegisteredSchoolSupporterListResponse output */
export type BillingRegisteredSchoolSupporterListResponse = {
  __typename?: 'BillingRegisteredSchoolSupporterListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<BillingRegisteredSchoolSupporter>;
};

/** BillingRegisteredSchoolSupporterManyResponse output */
export type BillingRegisteredSchoolSupporterManyResponse = {
  __typename?: 'BillingRegisteredSchoolSupporterManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<BillingRegisteredSchoolSupporter>;
};

/** No longer supported. Use `sort` instead. */
export type BillingRegisteredSchoolSupporterOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'paidDate_ASC'
  | 'paidDate_DESC'
  | 'planPayDate_ASC'
  | 'planPayDate_DESC'
  | 'price_ASC'
  | 'price_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** BillingRegisteredSchoolSupporters subscription payload */
export type BillingRegisteredSchoolSupporterPayload = {
  __typename?: 'BillingRegisteredSchoolSupporterPayload';
  mutation: MutationType;
  node?: Maybe<BillingRegisteredSchoolSupporter>;
  previousValues?: Maybe<BillingRegisteredSchoolSupporter>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingRegisteredSchoolSupporterRelationFilter = {
  every?: InputMaybe<BillingRegisteredSchoolSupporterFilter>;
  none?: InputMaybe<BillingRegisteredSchoolSupporterFilter>;
  some?: InputMaybe<BillingRegisteredSchoolSupporterFilter>;
};

export type BillingRegisteredSchoolSupporterSort = {
  billingSchoolSupporters?: InputMaybe<BillingSchoolSupporterSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paidDate?: InputMaybe<SortOrder>;
  planPayDate?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  schoolSupporter?: InputMaybe<SchoolSupporterSort>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** BillingRegisteredSchoolSupporters subscription filter */
export type BillingRegisteredSchoolSupporterSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<BillingRegisteredSchoolSupporterFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** BillingRegisteredSchoolSupporters update input */
export type BillingRegisteredSchoolSupporterUpdateByFilterInput = {
  paidDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  planPayDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  price?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
};

/** BillingRegisteredSchoolSupporters update input */
export type BillingRegisteredSchoolSupporterUpdateInput = {
  billingSchoolSupporters?: InputMaybe<BillingRegisteredSchoolSupportersBillingSchoolSupportersUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  planPayDate?: InputMaybe<Scalars['DateTime']>;
  price?: InputMaybe<Scalars['Int']>;
  schoolSupporter?: InputMaybe<BillingRegisteredSchoolSupportersSchoolSupporterUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
};

export type BillingRegisteredSchoolSupporter_PermissionFilter = {
  AND?: InputMaybe<Array<BillingRegisteredSchoolSupporter_PermissionFilter>>;
  OR?: InputMaybe<Array<BillingRegisteredSchoolSupporter_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  billingSchoolSupporters?: InputMaybe<BillingSchoolSupporter_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  paidDate?: InputMaybe<DateTimePredicate>;
  planPayDate?: InputMaybe<DateTimePredicate>;
  price?: InputMaybe<IntPredicate>;
  schoolSupporter?: InputMaybe<SchoolSupporter_PermissionFilter>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type BillingRegisteredSchoolSupporter_PermissionRelationFilter = {
  every?: InputMaybe<BillingRegisteredSchoolSupporter_PermissionFilter>;
  none?: InputMaybe<BillingRegisteredSchoolSupporter_PermissionFilter>;
  some?: InputMaybe<BillingRegisteredSchoolSupporter_PermissionFilter>;
};

/** SchoolSupporter create input from billingRegisteredSchoolSupporter */
export type BillingRegisteredSchoolSupporter_SchoolSupporterCreateInput = {
  billingRegisteredSchoolSupporter?: InputMaybe<SchoolSupporterBillingRegisteredSchoolSupporterRelationInput>;
  school?: InputMaybe<SchoolSupporterSchoolRelationInput>;
  startDate: Scalars['Date'];
  status: Scalars['String'];
  supporter?: InputMaybe<SchoolSupporterSupporterRelationInput>;
  userClicks?: InputMaybe<SchoolSupporterUserClicksRelationInput>;
  userSearches?: InputMaybe<SchoolSupporterUserSearchesRelationInput>;
};

/** SchoolSupporter update input from billingRegisteredSchoolSupporter */
export type BillingRegisteredSchoolSupporter_SchoolSupporterUpdateInput = {
  billingRegisteredSchoolSupporter?: InputMaybe<SchoolSupporterBillingRegisteredSchoolSupporterUpdateRelationInput>;
  school?: InputMaybe<SchoolSupporterSchoolUpdateRelationInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Scalars['String']>;
  supporter?: InputMaybe<SchoolSupporterSupporterUpdateRelationInput>;
  userClicks?: InputMaybe<SchoolSupporterUserClicksUpdateRelationInput>;
  userSearches?: InputMaybe<SchoolSupporterUserSearchesUpdateRelationInput>;
};

/** BillingRegisteredSchoolSupporters relation input */
export type BillingRegisteredSchoolSupportersBillingSchoolSupportersManyRelationInput = {
  connect?: InputMaybe<BillingSchoolSupporterKeyFilter>;
};

/** BillingRegisteredSchoolSupporters relation input */
export type BillingRegisteredSchoolSupportersBillingSchoolSupportersRelationInput = {
  connect?: InputMaybe<BillingSchoolSupporterKeyFilter>;
  create?: InputMaybe<BillingRegisteredSchoolSupporters_BillingSchoolSupporterCreateInput>;
};

/** BillingRegisteredSchoolSupporters relation input */
export type BillingRegisteredSchoolSupportersBillingSchoolSupportersUpdateRelationInput = {
  connect?: InputMaybe<BillingSchoolSupporterKeyFilter>;
  create?: InputMaybe<BillingRegisteredSchoolSupporters_BillingSchoolSupporterCreateInput>;
  disconnect?: InputMaybe<BillingSchoolSupporterKeyFilter>;
  reconnect?: InputMaybe<BillingSchoolSupporterKeyFilter>;
  update?: InputMaybe<BillingRegisteredSchoolSupporters_BillingSchoolSupporterUpdateInput>;
};

/** BillingRegisteredSchoolSupporters relation input */
export type BillingRegisteredSchoolSupportersSchoolSupporterManyRelationInput = {
  connect?: InputMaybe<SchoolSupporterKeyFilter>;
};

/** BillingRegisteredSchoolSupporters relation input */
export type BillingRegisteredSchoolSupportersSchoolSupporterRelationInput = {
  connect?: InputMaybe<SchoolSupporterKeyFilter>;
  create?: InputMaybe<BillingRegisteredSchoolSupporter_SchoolSupporterCreateInput>;
};

/** BillingRegisteredSchoolSupporters relation input */
export type BillingRegisteredSchoolSupportersSchoolSupporterUpdateRelationInput = {
  connect?: InputMaybe<SchoolSupporterKeyFilter>;
  create?: InputMaybe<BillingRegisteredSchoolSupporter_SchoolSupporterCreateInput>;
  disconnect?: InputMaybe<SchoolSupporterKeyFilter>;
  reconnect?: InputMaybe<SchoolSupporterKeyFilter>;
  update?: InputMaybe<BillingRegisteredSchoolSupporter_SchoolSupporterUpdateInput>;
};

/** BillingSchoolSupporters create input from billingRegisteredSchoolSupporters */
export type BillingRegisteredSchoolSupporters_BillingSchoolSupporterCreateInput = {
  billingRegisteredSchoolSupporters?: InputMaybe<BillingSchoolSupportersBillingRegisteredSchoolSupportersRelationInput>;
  supporter?: InputMaybe<BillingSchoolSupportersSupporterRelationInput>;
};

/** BillingSchoolSupporters update input from billingRegisteredSchoolSupporters */
export type BillingRegisteredSchoolSupporters_BillingSchoolSupporterUpdateInput = {
  billingRegisteredSchoolSupporters?: InputMaybe<BillingSchoolSupportersBillingRegisteredSchoolSupportersUpdateRelationInput>;
  supporter?: InputMaybe<BillingSchoolSupportersSupporterUpdateRelationInput>;
};

export type BillingRegisteredSpecialOffer = {
  __typename?: 'BillingRegisteredSpecialOffer';
  _description?: Maybe<Scalars['String']>;
  billingSpecialOffers?: Maybe<BillingSpecialOffer>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  paidDate?: Maybe<Scalars['DateTime']>;
  planPayDate?: Maybe<Scalars['DateTime']>;
  price?: Maybe<Scalars['Int']>;
  specialOffer?: Maybe<Discount>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** BillingRegisteredSpecialOffers create input */
export type BillingRegisteredSpecialOfferCreateInput = {
  billingSpecialOffers?: InputMaybe<BillingRegisteredSpecialOffersBillingSpecialOffersRelationInput>;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  planPayDate: Scalars['DateTime'];
  price?: InputMaybe<Scalars['Int']>;
  specialOffer?: InputMaybe<BillingRegisteredSpecialOffersSpecialOfferRelationInput>;
  status?: InputMaybe<Scalars['String']>;
};

/** BillingRegisteredSpecialOffers create many input */
export type BillingRegisteredSpecialOfferCreateManyInput = {
  billingSpecialOffers: BillingRegisteredSpecialOffersBillingSpecialOffersManyRelationInput;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  planPayDate: Scalars['DateTime'];
  price?: InputMaybe<Scalars['Int']>;
  specialOffer?: InputMaybe<BillingRegisteredSpecialOffersSpecialOfferManyRelationInput>;
  status?: InputMaybe<Scalars['String']>;
};

/** BillingRegisteredSpecialOffers delete input */
export type BillingRegisteredSpecialOfferDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** BillingRegisteredSpecialOfferFieldsPermissions create input */
export type BillingRegisteredSpecialOfferFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  paidDate?: InputMaybe<Scalars['Boolean']>;
  planPayDate?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BillingRegisteredSpecialOfferFilter = {
  AND?: InputMaybe<Array<BillingRegisteredSpecialOfferFilter>>;
  OR?: InputMaybe<Array<BillingRegisteredSpecialOfferFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  billingSpecialOffers?: InputMaybe<BillingSpecialOfferFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  paidDate?: InputMaybe<DateTimePredicate>;
  planPayDate?: InputMaybe<DateTimePredicate>;
  price?: InputMaybe<IntPredicate>;
  specialOffer?: InputMaybe<DiscountFilter>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type BillingRegisteredSpecialOfferGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: BillingRegisteredSpecialOfferGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type BillingRegisteredSpecialOfferGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  billingSpecialOffers?: InputMaybe<BillingSpecialOfferGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  paidDate?: InputMaybe<Array<GroupByField>>;
  planPayDate?: InputMaybe<Array<GroupByField>>;
  price?: InputMaybe<Array<GroupByField>>;
  specialOffer?: InputMaybe<DiscountGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type BillingRegisteredSpecialOfferKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** BillingRegisteredSpecialOfferListResponse output */
export type BillingRegisteredSpecialOfferListResponse = {
  __typename?: 'BillingRegisteredSpecialOfferListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<BillingRegisteredSpecialOffer>;
};

/** BillingRegisteredSpecialOfferManyResponse output */
export type BillingRegisteredSpecialOfferManyResponse = {
  __typename?: 'BillingRegisteredSpecialOfferManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<BillingRegisteredSpecialOffer>;
};

/** No longer supported. Use `sort` instead. */
export type BillingRegisteredSpecialOfferOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'paidDate_ASC'
  | 'paidDate_DESC'
  | 'planPayDate_ASC'
  | 'planPayDate_DESC'
  | 'price_ASC'
  | 'price_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** BillingRegisteredSpecialOffers subscription payload */
export type BillingRegisteredSpecialOfferPayload = {
  __typename?: 'BillingRegisteredSpecialOfferPayload';
  mutation: MutationType;
  node?: Maybe<BillingRegisteredSpecialOffer>;
  previousValues?: Maybe<BillingRegisteredSpecialOffer>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingRegisteredSpecialOfferRelationFilter = {
  every?: InputMaybe<BillingRegisteredSpecialOfferFilter>;
  none?: InputMaybe<BillingRegisteredSpecialOfferFilter>;
  some?: InputMaybe<BillingRegisteredSpecialOfferFilter>;
};

export type BillingRegisteredSpecialOfferSort = {
  billingSpecialOffers?: InputMaybe<BillingSpecialOfferSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paidDate?: InputMaybe<SortOrder>;
  planPayDate?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  specialOffer?: InputMaybe<DiscountSort>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** BillingRegisteredSpecialOffers subscription filter */
export type BillingRegisteredSpecialOfferSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<BillingRegisteredSpecialOfferFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** BillingRegisteredSpecialOffers update input */
export type BillingRegisteredSpecialOfferUpdateByFilterInput = {
  paidDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  planPayDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  price?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
};

/** BillingRegisteredSpecialOffers update input */
export type BillingRegisteredSpecialOfferUpdateInput = {
  billingSpecialOffers?: InputMaybe<BillingRegisteredSpecialOffersBillingSpecialOffersUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  planPayDate?: InputMaybe<Scalars['DateTime']>;
  price?: InputMaybe<Scalars['Int']>;
  specialOffer?: InputMaybe<BillingRegisteredSpecialOffersSpecialOfferUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
};

export type BillingRegisteredSpecialOffer_PermissionFilter = {
  AND?: InputMaybe<Array<BillingRegisteredSpecialOffer_PermissionFilter>>;
  OR?: InputMaybe<Array<BillingRegisteredSpecialOffer_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  billingSpecialOffers?: InputMaybe<BillingSpecialOffer_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  paidDate?: InputMaybe<DateTimePredicate>;
  planPayDate?: InputMaybe<DateTimePredicate>;
  price?: InputMaybe<IntPredicate>;
  specialOffer?: InputMaybe<Discount_PermissionFilter>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type BillingRegisteredSpecialOffer_PermissionRelationFilter = {
  every?: InputMaybe<BillingRegisteredSpecialOffer_PermissionFilter>;
  none?: InputMaybe<BillingRegisteredSpecialOffer_PermissionFilter>;
  some?: InputMaybe<BillingRegisteredSpecialOffer_PermissionFilter>;
};

/** BillingRegisteredSpecialOffers relation input */
export type BillingRegisteredSpecialOffersBillingSpecialOffersManyRelationInput = {
  connect?: InputMaybe<BillingSpecialOfferKeyFilter>;
};

/** BillingRegisteredSpecialOffers relation input */
export type BillingRegisteredSpecialOffersBillingSpecialOffersRelationInput = {
  connect?: InputMaybe<BillingSpecialOfferKeyFilter>;
  create?: InputMaybe<RegisteredSpecialOffers_BillingSpecialOfferCreateInput>;
};

/** BillingRegisteredSpecialOffers relation input */
export type BillingRegisteredSpecialOffersBillingSpecialOffersUpdateRelationInput = {
  connect?: InputMaybe<BillingSpecialOfferKeyFilter>;
  create?: InputMaybe<RegisteredSpecialOffers_BillingSpecialOfferCreateInput>;
  disconnect?: InputMaybe<BillingSpecialOfferKeyFilter>;
  reconnect?: InputMaybe<BillingSpecialOfferKeyFilter>;
  update?: InputMaybe<RegisteredSpecialOffers_BillingSpecialOfferUpdateInput>;
};

/** BillingRegisteredSpecialOffers relation input */
export type BillingRegisteredSpecialOffersSpecialOfferManyRelationInput = {
  connect?: InputMaybe<DiscountKeyFilter>;
};

/** BillingRegisteredSpecialOffers relation input */
export type BillingRegisteredSpecialOffersSpecialOfferRelationInput = {
  connect?: InputMaybe<DiscountKeyFilter>;
  create?: InputMaybe<BillingRegisteredDiscounts_DiscountCreateInput>;
};

/** BillingRegisteredSpecialOffers relation input */
export type BillingRegisteredSpecialOffersSpecialOfferUpdateRelationInput = {
  connect?: InputMaybe<DiscountKeyFilter>;
  create?: InputMaybe<BillingRegisteredDiscounts_DiscountCreateInput>;
  disconnect?: InputMaybe<DiscountKeyFilter>;
  reconnect?: InputMaybe<DiscountKeyFilter>;
  update?: InputMaybe<BillingRegisteredDiscounts_DiscountUpdateInput>;
};

export type BillingSchoolSupporter = {
  __typename?: 'BillingSchoolSupporter';
  _description?: Maybe<Scalars['String']>;
  billingRegisteredSchoolSupporters?: Maybe<BillingRegisteredSchoolSupporterListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  supporter?: Maybe<Organization>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BillingSchoolSupporterBillingRegisteredSchoolSupportersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingRegisteredSchoolSupporterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingRegisteredSchoolSupporterGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingRegisteredSchoolSupporterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingRegisteredSchoolSupporterSort>>;
};

/** BillingSchoolSupporters create input */
export type BillingSchoolSupporterCreateInput = {
  billingRegisteredSchoolSupporters?: InputMaybe<BillingSchoolSupportersBillingRegisteredSchoolSupportersRelationInput>;
  supporter?: InputMaybe<BillingSchoolSupportersSupporterRelationInput>;
};

/** BillingSchoolSupporters create many input */
export type BillingSchoolSupporterCreateManyInput = {
  billingRegisteredSchoolSupporters?: InputMaybe<BillingSchoolSupportersBillingRegisteredSchoolSupportersManyRelationInput>;
  supporter: BillingSchoolSupportersSupporterManyRelationInput;
};

/** BillingSchoolSupporters delete input */
export type BillingSchoolSupporterDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** BillingSchoolSupporterFieldsPermissions create input */
export type BillingSchoolSupporterFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BillingSchoolSupporterFilter = {
  AND?: InputMaybe<Array<BillingSchoolSupporterFilter>>;
  OR?: InputMaybe<Array<BillingSchoolSupporterFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  billingRegisteredSchoolSupporters?: InputMaybe<BillingRegisteredSchoolSupporterRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  supporter?: InputMaybe<OrganizationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type BillingSchoolSupporterGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: BillingSchoolSupporterGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type BillingSchoolSupporterGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  billingRegisteredSchoolSupporters?: InputMaybe<BillingRegisteredSchoolSupporterGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  supporter?: InputMaybe<OrganizationGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type BillingSchoolSupporterKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** BillingSchoolSupporterListResponse output */
export type BillingSchoolSupporterListResponse = {
  __typename?: 'BillingSchoolSupporterListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<BillingSchoolSupporter>;
};

/** BillingSchoolSupporterManyResponse output */
export type BillingSchoolSupporterManyResponse = {
  __typename?: 'BillingSchoolSupporterManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<BillingSchoolSupporter>;
};

/** No longer supported. Use `sort` instead. */
export type BillingSchoolSupporterOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** BillingSchoolSupporters subscription payload */
export type BillingSchoolSupporterPayload = {
  __typename?: 'BillingSchoolSupporterPayload';
  mutation: MutationType;
  node?: Maybe<BillingSchoolSupporter>;
  previousValues?: Maybe<BillingSchoolSupporter>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingSchoolSupporterSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  supporter?: InputMaybe<OrganizationSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** BillingSchoolSupporters subscription filter */
export type BillingSchoolSupporterSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<BillingSchoolSupporterFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** BillingSchoolSupporters update input */
export type BillingSchoolSupporterUpdateInput = {
  billingRegisteredSchoolSupporters?: InputMaybe<BillingSchoolSupportersBillingRegisteredSchoolSupportersUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  supporter?: InputMaybe<BillingSchoolSupportersSupporterUpdateRelationInput>;
};

/** Organizations create input from billingSchoolSupporter */
export type BillingSchoolSupporter_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from billingSchoolSupporter */
export type BillingSchoolSupporter_OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

export type BillingSchoolSupporter_PermissionFilter = {
  AND?: InputMaybe<Array<BillingSchoolSupporter_PermissionFilter>>;
  OR?: InputMaybe<Array<BillingSchoolSupporter_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  billingRegisteredSchoolSupporters?: InputMaybe<BillingRegisteredSchoolSupporter_PermissionRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  supporter?: InputMaybe<Organization_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

/** BillingSchoolSupporters relation input */
export type BillingSchoolSupportersBillingRegisteredSchoolSupportersManyRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredSchoolSupporterKeyFilter>>;
};

/** BillingSchoolSupporters relation input */
export type BillingSchoolSupportersBillingRegisteredSchoolSupportersRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredSchoolSupporterKeyFilter>>;
  create?: InputMaybe<
    Array<InputMaybe<BillingSchoolSupporters_BillingRegisteredSchoolSupporterCreateInput>>
  >;
};

/** BillingSchoolSupporters relation input */
export type BillingSchoolSupportersBillingRegisteredSchoolSupportersUpdateRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredSchoolSupporterKeyFilter>>;
  create?: InputMaybe<
    Array<InputMaybe<BillingSchoolSupporters_BillingRegisteredSchoolSupporterCreateInput>>
  >;
  disconnect?: InputMaybe<Array<BillingRegisteredSchoolSupporterKeyFilter>>;
  reconnect?: InputMaybe<Array<BillingRegisteredSchoolSupporterKeyFilter>>;
  update?: InputMaybe<
    Array<InputMaybe<BillingSchoolSupporters_BillingRegisteredSchoolSupporterUpdateInput>>
  >;
};

/** BillingSchoolSupporters relation input */
export type BillingSchoolSupportersSupporterManyRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
};

/** BillingSchoolSupporters relation input */
export type BillingSchoolSupportersSupporterRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<BillingSchoolSupporter_OrganizationCreateInput>;
};

/** BillingSchoolSupporters relation input */
export type BillingSchoolSupportersSupporterUpdateRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<BillingSchoolSupporter_OrganizationCreateInput>;
  disconnect?: InputMaybe<OrganizationKeyFilter>;
  reconnect?: InputMaybe<OrganizationKeyFilter>;
  update?: InputMaybe<BillingSchoolSupporter_OrganizationUpdateInput>;
};

/** BillingRegisteredSchoolSupporters create input from billingSchoolSupporters */
export type BillingSchoolSupporters_BillingRegisteredSchoolSupporterCreateInput = {
  billingSchoolSupporters?: InputMaybe<BillingRegisteredSchoolSupportersBillingSchoolSupportersRelationInput>;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  planPayDate?: InputMaybe<Scalars['DateTime']>;
  price?: InputMaybe<Scalars['Int']>;
  schoolSupporter?: InputMaybe<BillingRegisteredSchoolSupportersSchoolSupporterRelationInput>;
  status?: InputMaybe<Scalars['String']>;
};

/** BillingRegisteredSchoolSupporters update input from billingSchoolSupporters */
export type BillingSchoolSupporters_BillingRegisteredSchoolSupporterUpdateInput = {
  data: BillingRegisteredSchoolSupporterUpdateInput;
  filter?: InputMaybe<BillingRegisteredSchoolSupporterKeyFilter>;
};

export type BillingSpecialOffer = {
  __typename?: 'BillingSpecialOffer';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  organization?: Maybe<Organization>;
  registeredSpecialOffers?: Maybe<BillingRegisteredSpecialOfferListResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BillingSpecialOfferRegisteredSpecialOffersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingRegisteredSpecialOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingRegisteredSpecialOfferGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingRegisteredSpecialOfferOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingRegisteredSpecialOfferSort>>;
};

/** BillingSpecialOffers create input */
export type BillingSpecialOfferCreateInput = {
  organization?: InputMaybe<BillingSpecialOffersOrganizationRelationInput>;
  registeredSpecialOffers?: InputMaybe<BillingSpecialOffersRegisteredSpecialOffersRelationInput>;
};

/** BillingSpecialOffers create many input */
export type BillingSpecialOfferCreateManyInput = {
  organization: BillingSpecialOffersOrganizationManyRelationInput;
  registeredSpecialOffers?: InputMaybe<BillingSpecialOffersRegisteredSpecialOffersManyRelationInput>;
};

/** BillingSpecialOffers delete input */
export type BillingSpecialOfferDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** BillingSpecialOfferFieldsPermissions create input */
export type BillingSpecialOfferFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BillingSpecialOfferFilter = {
  AND?: InputMaybe<Array<BillingSpecialOfferFilter>>;
  OR?: InputMaybe<Array<BillingSpecialOfferFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  organization?: InputMaybe<OrganizationFilter>;
  registeredSpecialOffers?: InputMaybe<BillingRegisteredSpecialOfferRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type BillingSpecialOfferGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: BillingSpecialOfferGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type BillingSpecialOfferGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  organization?: InputMaybe<OrganizationGroupByQuery>;
  registeredSpecialOffers?: InputMaybe<BillingRegisteredSpecialOfferGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type BillingSpecialOfferKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** BillingSpecialOfferListResponse output */
export type BillingSpecialOfferListResponse = {
  __typename?: 'BillingSpecialOfferListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<BillingSpecialOffer>;
};

/** BillingSpecialOfferManyResponse output */
export type BillingSpecialOfferManyResponse = {
  __typename?: 'BillingSpecialOfferManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<BillingSpecialOffer>;
};

/** No longer supported. Use `sort` instead. */
export type BillingSpecialOfferOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** BillingSpecialOffers subscription payload */
export type BillingSpecialOfferPayload = {
  __typename?: 'BillingSpecialOfferPayload';
  mutation: MutationType;
  node?: Maybe<BillingSpecialOffer>;
  previousValues?: Maybe<BillingSpecialOffer>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingSpecialOfferSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** BillingSpecialOffers subscription filter */
export type BillingSpecialOfferSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<BillingSpecialOfferFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** BillingSpecialOffers update input */
export type BillingSpecialOfferUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  organization?: InputMaybe<BillingSpecialOffersOrganizationUpdateRelationInput>;
  registeredSpecialOffers?: InputMaybe<BillingSpecialOffersRegisteredSpecialOffersUpdateRelationInput>;
};

export type BillingSpecialOffer_PermissionFilter = {
  AND?: InputMaybe<Array<BillingSpecialOffer_PermissionFilter>>;
  OR?: InputMaybe<Array<BillingSpecialOffer_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  organization?: InputMaybe<Organization_PermissionFilter>;
  registeredSpecialOffers?: InputMaybe<BillingRegisteredSpecialOffer_PermissionRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

/** BillingSpecialOffers relation input */
export type BillingSpecialOffersOrganizationManyRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
};

/** BillingSpecialOffers relation input */
export type BillingSpecialOffersOrganizationRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<BillingSpecialOffers_OrganizationCreateInput>;
};

/** BillingSpecialOffers relation input */
export type BillingSpecialOffersOrganizationUpdateRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<BillingSpecialOffers_OrganizationCreateInput>;
  disconnect?: InputMaybe<OrganizationKeyFilter>;
  reconnect?: InputMaybe<OrganizationKeyFilter>;
  update?: InputMaybe<BillingSpecialOffers_OrganizationUpdateInput>;
};

/** BillingSpecialOffers relation input */
export type BillingSpecialOffersRegisteredSpecialOffersManyRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredSpecialOfferKeyFilter>>;
};

/** BillingSpecialOffers relation input */
export type BillingSpecialOffersRegisteredSpecialOffersRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredSpecialOfferKeyFilter>>;
  create?: InputMaybe<
    Array<InputMaybe<BillingSpecialOffers_BillingRegisteredSpecialOfferCreateInput>>
  >;
};

/** BillingSpecialOffers relation input */
export type BillingSpecialOffersRegisteredSpecialOffersUpdateRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredSpecialOfferKeyFilter>>;
  create?: InputMaybe<
    Array<InputMaybe<BillingSpecialOffers_BillingRegisteredSpecialOfferCreateInput>>
  >;
  disconnect?: InputMaybe<Array<BillingRegisteredSpecialOfferKeyFilter>>;
  reconnect?: InputMaybe<Array<BillingRegisteredSpecialOfferKeyFilter>>;
  update?: InputMaybe<
    Array<InputMaybe<BillingSpecialOffers_BillingRegisteredSpecialOfferUpdateInput>>
  >;
};

/** BillingRegisteredSpecialOffers create input from billingSpecialOffers */
export type BillingSpecialOffers_BillingRegisteredSpecialOfferCreateInput = {
  billingSpecialOffers?: InputMaybe<BillingRegisteredSpecialOffersBillingSpecialOffersRelationInput>;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  planPayDate: Scalars['DateTime'];
  price?: InputMaybe<Scalars['Int']>;
  specialOffer?: InputMaybe<BillingRegisteredSpecialOffersSpecialOfferRelationInput>;
  status?: InputMaybe<Scalars['String']>;
};

/** BillingRegisteredSpecialOffers update input from billingSpecialOffers */
export type BillingSpecialOffers_BillingRegisteredSpecialOfferUpdateInput = {
  data: BillingRegisteredSpecialOfferUpdateInput;
  filter?: InputMaybe<BillingRegisteredSpecialOfferKeyFilter>;
};

/** Organizations create input from billingSpecialOffers */
export type BillingSpecialOffers_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from billingSpecialOffers */
export type BillingSpecialOffers_OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

export type BillingTransaction = {
  __typename?: 'BillingTransaction';
  amount?: Maybe<Scalars['Int']>;
  cardBrand?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  refunded?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type BillingsAd = {
  __typename?: 'BillingsAd';
  _description?: Maybe<Scalars['String']>;
  ads?: Maybe<AdvertisingCampaignAdListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  organization?: Maybe<Organization>;
  registeredBillingAds?: Maybe<BillingRegisteredAdListResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BillingsAdAdsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingCampaignAdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingCampaignAdGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingCampaignAdOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingCampaignAdSort>>;
};

export type BillingsAdRegisteredBillingAdsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingRegisteredAdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingRegisteredAdGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingRegisteredAdOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingRegisteredAdSort>>;
};

/** BillingsAds create input */
export type BillingsAdCreateInput = {
  ads?: InputMaybe<BillingsAdsAdsRelationInput>;
  organization?: InputMaybe<BillingsAdsOrganizationRelationInput>;
  registeredBillingAds?: InputMaybe<BillingsAdsRegisteredBillingAdsRelationInput>;
};

/** BillingsAds create many input */
export type BillingsAdCreateManyInput = {
  ads?: InputMaybe<BillingsAdsAdsManyRelationInput>;
  organization: BillingsAdsOrganizationManyRelationInput;
  registeredBillingAds?: InputMaybe<BillingsAdsRegisteredBillingAdsManyRelationInput>;
};

/** BillingsAds delete input */
export type BillingsAdDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** BillingsAdFieldsPermissions create input */
export type BillingsAdFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BillingsAdFilter = {
  AND?: InputMaybe<Array<BillingsAdFilter>>;
  OR?: InputMaybe<Array<BillingsAdFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  ads?: InputMaybe<AdvertisingCampaignAdRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  organization?: InputMaybe<OrganizationFilter>;
  registeredBillingAds?: InputMaybe<BillingRegisteredAdRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type BillingsAdGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: BillingsAdGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type BillingsAdGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  ads?: InputMaybe<AdvertisingCampaignAdGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  organization?: InputMaybe<OrganizationGroupByQuery>;
  registeredBillingAds?: InputMaybe<BillingRegisteredAdGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type BillingsAdKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** BillingsAdListResponse output */
export type BillingsAdListResponse = {
  __typename?: 'BillingsAdListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<BillingsAd>;
};

/** BillingsAdManyResponse output */
export type BillingsAdManyResponse = {
  __typename?: 'BillingsAdManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<BillingsAd>;
};

/** No longer supported. Use `sort` instead. */
export type BillingsAdOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** BillingsAds subscription payload */
export type BillingsAdPayload = {
  __typename?: 'BillingsAdPayload';
  mutation: MutationType;
  node?: Maybe<BillingsAd>;
  previousValues?: Maybe<BillingsAd>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingsAdSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** BillingsAds subscription filter */
export type BillingsAdSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<BillingsAdFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** BillingsAds update input */
export type BillingsAdUpdateInput = {
  ads?: InputMaybe<BillingsAdsAdsUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  organization?: InputMaybe<BillingsAdsOrganizationUpdateRelationInput>;
  registeredBillingAds?: InputMaybe<BillingsAdsRegisteredBillingAdsUpdateRelationInput>;
};

export type BillingsAd_PermissionFilter = {
  AND?: InputMaybe<Array<BillingsAd_PermissionFilter>>;
  OR?: InputMaybe<Array<BillingsAd_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  ads?: InputMaybe<AdvertisingCampaignAd_PermissionRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  organization?: InputMaybe<Organization_PermissionFilter>;
  registeredBillingAds?: InputMaybe<BillingRegisteredAd_PermissionRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

/** BillingsAds relation input */
export type BillingsAdsAdsManyRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
};

/** BillingsAds relation input */
export type BillingsAdsAdsRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<BillingAds_AdvertisingCampaignAdCreateInput>>>;
};

/** BillingsAds relation input */
export type BillingsAdsAdsUpdateRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<BillingAds_AdvertisingCampaignAdCreateInput>>>;
  disconnect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  reconnect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<BillingAds_AdvertisingCampaignAdUpdateInput>>>;
};

/** BillingsAds relation input */
export type BillingsAdsOrganizationManyRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
};

/** BillingsAds relation input */
export type BillingsAdsOrganizationRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<BillingAds_OrganizationCreateInput>;
};

/** BillingsAds relation input */
export type BillingsAdsOrganizationUpdateRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<BillingAds_OrganizationCreateInput>;
  disconnect?: InputMaybe<OrganizationKeyFilter>;
  reconnect?: InputMaybe<OrganizationKeyFilter>;
  update?: InputMaybe<BillingAds_OrganizationUpdateInput>;
};

/** BillingsAds relation input */
export type BillingsAdsRegisteredBillingAdsManyRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredAdKeyFilter>>;
};

/** BillingsAds relation input */
export type BillingsAdsRegisteredBillingAdsRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<BillingAd_BillingRegisteredAdCreateInput>>>;
};

/** BillingsAds relation input */
export type BillingsAdsRegisteredBillingAdsUpdateRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<BillingAd_BillingRegisteredAdCreateInput>>>;
  disconnect?: InputMaybe<Array<BillingRegisteredAdKeyFilter>>;
  reconnect?: InputMaybe<Array<BillingRegisteredAdKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<BillingAd_BillingRegisteredAdUpdateInput>>>;
};

export type BoolPredicate = {
  equals?: InputMaybe<Scalars['Boolean']>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  not_equals?: InputMaybe<Scalars['Boolean']>;
};

export type BoolPredicateHaving = {
  AND?: InputMaybe<Array<BoolPredicateHaving>>;
  OR?: InputMaybe<Array<BoolPredicateHaving>>;
  equals?: InputMaybe<Scalars['Boolean']>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  not_equals?: InputMaybe<Scalars['Boolean']>;
};

/** SchoolConfigurationImages create input from browserLogo */
export type BrowserLogo_SchoolConfigurationImageCreateInput = {
  applicationLogo?: InputMaybe<SchoolConfigurationImagesApplicationLogoRelationInput>;
  applicationLogoId?: InputMaybe<Scalars['String']>;
  browserLogo?: InputMaybe<SchoolConfigurationImagesBrowserLogoRelationInput>;
  browserLogoId?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfigurationImagesConfigurationRelationInput>;
  emailBanner?: InputMaybe<SchoolConfigurationImagesEmailBannerRelationInput>;
  emailLogo?: InputMaybe<SchoolConfigurationImagesEmailLogoRelationInput>;
  logo?: InputMaybe<SchoolConfigurationImagesLogoRelationInput>;
  splashImage?: InputMaybe<SchoolConfigurationImagesSplashImageRelationInput>;
};

/** SchoolConfigurationImages update input from browserLogo */
export type BrowserLogo_SchoolConfigurationImageUpdateInput = {
  data: SchoolConfigurationImageUpdateInput;
  filter?: InputMaybe<SchoolConfigurationImageKeyFilter>;
};

export type BusinessFilterByZipRequestResponse = {
  __typename?: 'BusinessFilterByZipRequestResponse';
  count?: Maybe<Scalars['Int']>;
  items: Array<Organization>;
};

export type BusinessReport = {
  __typename?: 'BusinessReport';
  _description?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countSchoolSupporter?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByFirstName?: Maybe<Scalars['String']>;
  createdByLastName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  industry?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  logoID?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** BusinessReportFieldsPermissions create input */
export type BusinessReportFieldsPermissions = {
  city?: InputMaybe<Scalars['Boolean']>;
  countSchoolSupporter?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByFirstName?: InputMaybe<Scalars['Boolean']>;
  createdByLastName?: InputMaybe<Scalars['Boolean']>;
  industry?: InputMaybe<Scalars['Boolean']>;
  locationName?: InputMaybe<Scalars['Boolean']>;
  logoID?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  organizationName?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessReportFilter = {
  AND?: InputMaybe<Array<BusinessReportFilter>>;
  OR?: InputMaybe<Array<BusinessReportFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<StringPredicate>;
  countSchoolSupporter?: InputMaybe<IntPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdByFirstName?: InputMaybe<StringPredicate>;
  createdByLastName?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  industry?: InputMaybe<StringPredicate>;
  locationName?: InputMaybe<StringPredicate>;
  logoID?: InputMaybe<StringPredicate>;
  organizationId?: InputMaybe<StringPredicate>;
  organizationName?: InputMaybe<StringPredicate>;
  state?: InputMaybe<StringPredicate>;
  status?: InputMaybe<StringPredicate>;
};

export type BusinessReportGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: BusinessReportGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type BusinessReportGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  city?: InputMaybe<Array<GroupByField>>;
  countSchoolSupporter?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdByFirstName?: InputMaybe<Array<GroupByField>>;
  createdByLastName?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  industry?: InputMaybe<Array<GroupByField>>;
  locationName?: InputMaybe<Array<GroupByField>>;
  logoID?: InputMaybe<Array<GroupByField>>;
  organizationId?: InputMaybe<Array<GroupByField>>;
  organizationName?: InputMaybe<Array<GroupByField>>;
  state?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
};

/** BusinessReportListResponse output */
export type BusinessReportListResponse = {
  __typename?: 'BusinessReportListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<BusinessReport>;
};

/** BusinessReportManyResponse output */
export type BusinessReportManyResponse = {
  __typename?: 'BusinessReportManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<BusinessReport>;
};

/** No longer supported. Use `sort` instead. */
export type BusinessReportOrderBy =
  | 'city_ASC'
  | 'city_DESC'
  | 'countSchoolSupporter_ASC'
  | 'countSchoolSupporter_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'createdByFirstName_ASC'
  | 'createdByFirstName_DESC'
  | 'createdByLastName_ASC'
  | 'createdByLastName_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'industry_ASC'
  | 'industry_DESC'
  | 'locationName_ASC'
  | 'locationName_DESC'
  | 'logoID_ASC'
  | 'logoID_DESC'
  | 'organizationId_ASC'
  | 'organizationId_DESC'
  | 'organizationName_ASC'
  | 'organizationName_DESC'
  | 'state_ASC'
  | 'state_DESC'
  | 'status_ASC'
  | 'status_DESC';

/** BusinessReport subscription payload */
export type BusinessReportPayload = {
  __typename?: 'BusinessReportPayload';
  mutation: MutationType;
  node?: Maybe<BusinessReport>;
  previousValues?: Maybe<BusinessReport>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BusinessReportSort = {
  city?: InputMaybe<SortOrder>;
  countSchoolSupporter?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdByFirstName?: InputMaybe<SortOrder>;
  createdByLastName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  industry?: InputMaybe<SortOrder>;
  locationName?: InputMaybe<SortOrder>;
  logoID?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrder>;
  organizationName?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

/** BusinessReport subscription filter */
export type BusinessReportSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<BusinessReportFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** BusinessReport update input */
export type BusinessReportUpdateByFilterInput = {
  city?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  countSchoolSupporter?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  createdByFirstName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  createdByLastName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  industry?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  locationName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  logoID?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  organizationId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  organizationName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  state?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

export type BusinessReport_PermissionFilter = {
  AND?: InputMaybe<Array<BusinessReport_PermissionFilter>>;
  OR?: InputMaybe<Array<BusinessReport_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<StringPredicate>;
  countSchoolSupporter?: InputMaybe<IntPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdByFirstName?: InputMaybe<StringPredicate>;
  createdByLastName?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  industry?: InputMaybe<StringPredicate>;
  locationName?: InputMaybe<StringPredicate>;
  logoID?: InputMaybe<StringPredicate>;
  organizationId?: InputMaybe<StringPredicate>;
  organizationName?: InputMaybe<StringPredicate>;
  state?: InputMaybe<StringPredicate>;
  status?: InputMaybe<StringPredicate>;
};

export type CareersFilterByZipRequestResponse = {
  __typename?: 'CareersFilterByZipRequestResponse';
  count?: Maybe<Scalars['Int']>;
  items: Array<JobOffer>;
};

/** ChangePasswordInput */
export type ChangePasswordInput = {
  authProfileId: Scalars['ID'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type CheckResetPasswordCodeInput = {
  resetPasswordCode: Scalars['String'];
};

export type CheckResetPasswordCodeSuccess = {
  __typename?: 'CheckResetPasswordCodeSuccess';
  email: Scalars['String'];
};

export type CiCdMigration = {
  __typename?: 'CiCdMigration';
  _description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** CiCdMigrations create input */
export type CiCdMigrationCreateInput = {
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** CiCdMigrations create many input */
export type CiCdMigrationCreateManyInput = {
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** CiCdMigrations delete input */
export type CiCdMigrationDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** CiCdMigrationFieldsPermissions create input */
export type CiCdMigrationFieldsPermissions = {
  code?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CiCdMigrationFilter = {
  AND?: InputMaybe<Array<CiCdMigrationFilter>>;
  OR?: InputMaybe<Array<CiCdMigrationFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  name?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type CiCdMigrationGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: CiCdMigrationGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type CiCdMigrationGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  code?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type CiCdMigrationKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** CiCdMigrationListResponse output */
export type CiCdMigrationListResponse = {
  __typename?: 'CiCdMigrationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CiCdMigration>;
};

/** CiCdMigrationManyResponse output */
export type CiCdMigrationManyResponse = {
  __typename?: 'CiCdMigrationManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CiCdMigration>;
};

/** No longer supported. Use `sort` instead. */
export type CiCdMigrationOrderBy =
  | 'code_ASC'
  | 'code_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** CiCdMigrations subscription payload */
export type CiCdMigrationPayload = {
  __typename?: 'CiCdMigrationPayload';
  mutation: MutationType;
  node?: Maybe<CiCdMigration>;
  previousValues?: Maybe<CiCdMigration>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CiCdMigrationSort = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** CiCdMigrations subscription filter */
export type CiCdMigrationSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<CiCdMigrationFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** CiCdMigrations update input */
export type CiCdMigrationUpdateByFilterInput = {
  code?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** CiCdMigrations update input */
export type CiCdMigrationUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CiCdMigration_PermissionFilter = {
  AND?: InputMaybe<Array<CiCdMigration_PermissionFilter>>;
  OR?: InputMaybe<Array<CiCdMigration_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  name?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type ClaimOrganizationResponse = {
  __typename?: 'ClaimOrganizationResponse';
  /** ID of the created organization */
  id: Scalars['ID'];
};

/** SchoolConfiguration create input from classScorecard */
export type ClassScorecard_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from classScorecard */
export type ClassScorecard_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** Authentication Profile Attributes for Cognito */
export type CognitoAuthProfileAttributes = {
  __typename?: 'CognitoAuthProfileAttributes';
  clientAuthDomain?: Maybe<Scalars['String']>;
};

export type CollectiveIntelligenceThread = {
  __typename?: 'CollectiveIntelligenceThread';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  creator?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<FileListResponse>;
  schools?: Maybe<SchoolConfigurationListResponse>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  views?: Maybe<UserListResponse>;
};

export type CollectiveIntelligenceThreadImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FileSort>>;
};

export type CollectiveIntelligenceThreadSchoolsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationSort>>;
};

export type CollectiveIntelligenceThreadViewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

/** CollectiveIntelligenceThreads create input */
export type CollectiveIntelligenceThreadCreateInput = {
  creator?: InputMaybe<CollectiveIntelligenceThreadsCreatorRelationInput>;
  images?: InputMaybe<CollectiveIntelligenceThreadsImagesRelationInput>;
  schools?: InputMaybe<CollectiveIntelligenceThreadsSchoolsRelationInput>;
  text: Scalars['String'];
  title: Scalars['String'];
  views?: InputMaybe<CollectiveIntelligenceThreadsViewsRelationInput>;
};

/** CollectiveIntelligenceThreads create many input */
export type CollectiveIntelligenceThreadCreateManyInput = {
  creator?: InputMaybe<CollectiveIntelligenceThreadsCreatorManyRelationInput>;
  images?: InputMaybe<CollectiveIntelligenceThreadsImagesManyRelationInput>;
  schools?: InputMaybe<CollectiveIntelligenceThreadsSchoolsManyRelationInput>;
  text: Scalars['String'];
  title: Scalars['String'];
  views?: InputMaybe<CollectiveIntelligenceThreadsViewsManyRelationInput>;
};

/** CollectiveIntelligenceThreads delete input */
export type CollectiveIntelligenceThreadDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** CollectiveIntelligenceThreadFieldsPermissions create input */
export type CollectiveIntelligenceThreadFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CollectiveIntelligenceThreadFilter = {
  AND?: InputMaybe<Array<CollectiveIntelligenceThreadFilter>>;
  OR?: InputMaybe<Array<CollectiveIntelligenceThreadFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  creator?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  images?: InputMaybe<FileRelationFilter>;
  schools?: InputMaybe<SchoolConfigurationRelationFilter>;
  text?: InputMaybe<StringPredicate>;
  title?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  views?: InputMaybe<UserRelationFilter>;
};

export type CollectiveIntelligenceThreadGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: CollectiveIntelligenceThreadGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type CollectiveIntelligenceThreadGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  creator?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  images?: InputMaybe<FileGroupByQuery>;
  schools?: InputMaybe<SchoolConfigurationGroupByQuery>;
  text?: InputMaybe<Array<GroupByField>>;
  title?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  views?: InputMaybe<UserGroupByQuery>;
};

export type CollectiveIntelligenceThreadKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** CollectiveIntelligenceThreadListResponse output */
export type CollectiveIntelligenceThreadListResponse = {
  __typename?: 'CollectiveIntelligenceThreadListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CollectiveIntelligenceThread>;
};

/** CollectiveIntelligenceThreadManyResponse output */
export type CollectiveIntelligenceThreadManyResponse = {
  __typename?: 'CollectiveIntelligenceThreadManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CollectiveIntelligenceThread>;
};

/** No longer supported. Use `sort` instead. */
export type CollectiveIntelligenceThreadOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'text_ASC'
  | 'text_DESC'
  | 'title_ASC'
  | 'title_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** CollectiveIntelligenceThreads subscription payload */
export type CollectiveIntelligenceThreadPayload = {
  __typename?: 'CollectiveIntelligenceThreadPayload';
  mutation: MutationType;
  node?: Maybe<CollectiveIntelligenceThread>;
  previousValues?: Maybe<CollectiveIntelligenceThread>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CollectiveIntelligenceThreadRelationFilter = {
  every?: InputMaybe<CollectiveIntelligenceThreadFilter>;
  none?: InputMaybe<CollectiveIntelligenceThreadFilter>;
  some?: InputMaybe<CollectiveIntelligenceThreadFilter>;
};

export type CollectiveIntelligenceThreadSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  creator?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** CollectiveIntelligenceThreads subscription filter */
export type CollectiveIntelligenceThreadSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<CollectiveIntelligenceThreadFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** CollectiveIntelligenceThreads update input */
export type CollectiveIntelligenceThreadUpdateByFilterInput = {
  text?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  title?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** CollectiveIntelligenceThreads update input */
export type CollectiveIntelligenceThreadUpdateInput = {
  creator?: InputMaybe<CollectiveIntelligenceThreadsCreatorUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<CollectiveIntelligenceThreadsImagesUpdateRelationInput>;
  schools?: InputMaybe<CollectiveIntelligenceThreadsSchoolsUpdateRelationInput>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  views?: InputMaybe<CollectiveIntelligenceThreadsViewsUpdateRelationInput>;
};

export type CollectiveIntelligenceThread_PermissionFilter = {
  AND?: InputMaybe<Array<CollectiveIntelligenceThread_PermissionFilter>>;
  OR?: InputMaybe<Array<CollectiveIntelligenceThread_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  creator?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  images?: InputMaybe<File_PermissionRelationFilter>;
  schools?: InputMaybe<SchoolConfiguration_PermissionRelationFilter>;
  text?: InputMaybe<StringPredicate>;
  title?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  views?: InputMaybe<User_PermissionRelationFilter>;
};

export type CollectiveIntelligenceThread_PermissionRelationFilter = {
  every?: InputMaybe<CollectiveIntelligenceThread_PermissionFilter>;
  none?: InputMaybe<CollectiveIntelligenceThread_PermissionFilter>;
  some?: InputMaybe<CollectiveIntelligenceThread_PermissionFilter>;
};

/** CollectiveIntelligenceThreads relation input */
export type CollectiveIntelligenceThreadsCreatorManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** CollectiveIntelligenceThreads relation input */
export type CollectiveIntelligenceThreadsCreatorRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<CollectiveIntelligenceThreads_UserCreateInput>;
};

/** CollectiveIntelligenceThreads relation input */
export type CollectiveIntelligenceThreadsCreatorUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<CollectiveIntelligenceThreads_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<CollectiveIntelligenceThreads_UserUpdateInput>;
};

/** CollectiveIntelligenceThreads relation input */
export type CollectiveIntelligenceThreadsImagesManyRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
};

/** CollectiveIntelligenceThreads relation input */
export type CollectiveIntelligenceThreadsImagesRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<CollectiveIntelligenceThreads_Images_FileCreateInput>>>;
};

/** CollectiveIntelligenceThreads relation input */
export type CollectiveIntelligenceThreadsImagesUpdateRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<CollectiveIntelligenceThreads_Images_FileCreateInput>>>;
  disconnect?: InputMaybe<Array<FileKeyFilter>>;
  reconnect?: InputMaybe<Array<FileKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<CollectiveIntelligenceThreads_Images_FileUpdateInput>>>;
};

/** CollectiveIntelligenceThreads relation input */
export type CollectiveIntelligenceThreadsSchoolsManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
};

/** CollectiveIntelligenceThreads relation input */
export type CollectiveIntelligenceThreadsSchoolsRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<ForumThreads_SchoolConfigurationCreateInput>>>;
};

/** CollectiveIntelligenceThreads relation input */
export type CollectiveIntelligenceThreadsSchoolsUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<ForumThreads_SchoolConfigurationCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<ForumThreads_SchoolConfigurationUpdateInput>>>;
};

/** CollectiveIntelligenceThreads relation input */
export type CollectiveIntelligenceThreadsViewsManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** CollectiveIntelligenceThreads relation input */
export type CollectiveIntelligenceThreadsViewsRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<ViewedThreads_UserCreateInput>>>;
};

/** CollectiveIntelligenceThreads relation input */
export type CollectiveIntelligenceThreadsViewsUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<ViewedThreads_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<ViewedThreads_UserUpdateInput>>>;
};

/** Users create input from collectiveIntelligenceThreads */
export type CollectiveIntelligenceThreads_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from collectiveIntelligenceThreads */
export type CollectiveIntelligenceThreads_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Files create input from collectiveIntelligenceThreads_images */
export type CollectiveIntelligenceThreads_Images_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from collectiveIntelligenceThreads_images */
export type CollectiveIntelligenceThreads_Images_FileUpdateInput = {
  data: FileUpdateInput;
  filter?: InputMaybe<FileKeyFilter>;
};

/** SchoolConfiguration create input from colorSchema */
export type ColorSchema_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address: SchoolConfigurationAddressRelationInput;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from colorSchema */
export type ColorSchema_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** JobEmployees create input from companyImage */
export type CompanyImage_JobEmployeeCreateInput = {
  companyImage?: InputMaybe<JobEmployeesCompanyImageRelationInput>;
  /** Store the company location like address */
  companyLocation?: InputMaybe<SmartAddressInput>;
  /** Store the name of the company without having a real relationship */
  companyName?: InputMaybe<Scalars['String']>;
  employmentType: Scalars['String'];
  endDate?: InputMaybe<Scalars['Date']>;
  isCurrentlyWorking: Scalars['Boolean'];
  positionDescription?: InputMaybe<Scalars['String']>;
  positionTitle: Scalars['String'];
  startDate: Scalars['Date'];
  user?: InputMaybe<JobEmployeesUserRelationInput>;
};

/** JobEmployees update input from companyImage */
export type CompanyImage_JobEmployeeUpdateInput = {
  data: JobEmployeeUpdateInput;
  filter?: InputMaybe<JobEmployeeKeyFilter>;
};

/** Computed field mode */
export type ComputedFieldMode = 'STORED' | 'VIRTUAL';

/** Addresses create input from configuration */
export type Configuration_AddressCreateInput = {
  city?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<AddressesConfigurationRelationInput>;
  educationalInstitutions?: InputMaybe<AddressesEducationalInstitutionsRelationInput>;
  events?: InputMaybe<AddressesEventsRelationInput>;
  organizations?: InputMaybe<AddressesOrganizationsRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  usersAdresses?: InputMaybe<AddressesUsersAdressesRelationInput>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Addresses update input from configuration */
export type Configuration_AddressUpdateInput = {
  city?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<AddressesConfigurationUpdateRelationInput>;
  educationalInstitutions?: InputMaybe<AddressesEducationalInstitutionsUpdateRelationInput>;
  events?: InputMaybe<AddressesEventsUpdateRelationInput>;
  organizations?: InputMaybe<AddressesOrganizationsUpdateRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  usersAdresses?: InputMaybe<AddressesUsersAdressesUpdateRelationInput>;
  zip?: InputMaybe<Scalars['String']>;
};

/** SchoolConfigurationColorSchemes create input from configuration */
export type Configuration_SchoolConfigurationColorSchemeCreateInput = {
  accent1?: InputMaybe<Scalars['String']>;
  accent2?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfigurationColorSchemesConfigurationRelationInput>;
  primary?: InputMaybe<Scalars['String']>;
  secondary?: InputMaybe<Scalars['String']>;
  sidebar?: InputMaybe<Scalars['String']>;
  sidebarFont?: InputMaybe<Scalars['String']>;
};

/** SchoolConfigurationColorSchemes update input from configuration */
export type Configuration_SchoolConfigurationColorSchemeUpdateInput = {
  accent1?: InputMaybe<Scalars['String']>;
  accent2?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfigurationColorSchemesConfigurationUpdateRelationInput>;
  primary?: InputMaybe<Scalars['String']>;
  secondary?: InputMaybe<Scalars['String']>;
  sidebar?: InputMaybe<Scalars['String']>;
  sidebarFont?: InputMaybe<Scalars['String']>;
};

/** SchoolConfigurationExternalLinks create input from configuration */
export type Configuration_SchoolConfigurationExternalLinkCreateInput = {
  configuration?: InputMaybe<SchoolConfigurationExternalLinksConfigurationRelationInput>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  link: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

/** SchoolConfigurationExternalLinks update input from configuration */
export type Configuration_SchoolConfigurationExternalLinkUpdateInput = {
  data: SchoolConfigurationExternalLinkUpdateInput;
  filter?: InputMaybe<SchoolConfigurationExternalLinkKeyFilter>;
};

/** SchoolConfigurationImages create input from configuration */
export type Configuration_SchoolConfigurationImageCreateInput = {
  applicationLogo?: InputMaybe<SchoolConfigurationImagesApplicationLogoRelationInput>;
  applicationLogoId?: InputMaybe<Scalars['String']>;
  browserLogo?: InputMaybe<SchoolConfigurationImagesBrowserLogoRelationInput>;
  browserLogoId?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfigurationImagesConfigurationRelationInput>;
  emailBanner?: InputMaybe<SchoolConfigurationImagesEmailBannerRelationInput>;
  emailLogo?: InputMaybe<SchoolConfigurationImagesEmailLogoRelationInput>;
  logo?: InputMaybe<SchoolConfigurationImagesLogoRelationInput>;
  splashImage?: InputMaybe<SchoolConfigurationImagesSplashImageRelationInput>;
};

/** SchoolConfigurationImages update input from configuration */
export type Configuration_SchoolConfigurationImageUpdateInput = {
  applicationLogo?: InputMaybe<SchoolConfigurationImagesApplicationLogoUpdateRelationInput>;
  applicationLogoId?: InputMaybe<Scalars['String']>;
  browserLogo?: InputMaybe<SchoolConfigurationImagesBrowserLogoUpdateRelationInput>;
  browserLogoId?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfigurationImagesConfigurationUpdateRelationInput>;
  emailBanner?: InputMaybe<SchoolConfigurationImagesEmailBannerUpdateRelationInput>;
  emailLogo?: InputMaybe<SchoolConfigurationImagesEmailLogoUpdateRelationInput>;
  logo?: InputMaybe<SchoolConfigurationImagesLogoUpdateRelationInput>;
  splashImage?: InputMaybe<SchoolConfigurationImagesSplashImageUpdateRelationInput>;
};

/** SchoolConfigurationLayouts create input from configuration */
export type Configuration_SchoolConfigurationLayoutCreateInput = {
  configuration?: InputMaybe<SchoolConfigurationLayoutsConfigurationRelationInput>;
  icon?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAlwaysShown?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  navbarOrderNumber?: InputMaybe<Scalars['Int']>;
  roleAddon?: InputMaybe<SchoolConfigurationLayoutsRoleAddonRelationInput>;
  route?: InputMaybe<Scalars['String']>;
  sidebarOrderNumber: Scalars['Int'];
};

/** SchoolConfigurationLayouts update input from configuration */
export type Configuration_SchoolConfigurationLayoutUpdateInput = {
  data: SchoolConfigurationLayoutUpdateInput;
  filter?: InputMaybe<SchoolConfigurationLayoutKeyFilter>;
};

/** SchoolConfigurationTopBar create input from configuration */
export type Configuration_SchoolConfigurationTopBarCreateInput = {
  configuration?: InputMaybe<SchoolConfigurationTopBarConfigurationRelationInput>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  label?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  text: Scalars['String'];
  title: Scalars['String'];
};

/** SchoolConfigurationTopBar update input from configuration */
export type Configuration_SchoolConfigurationTopBarUpdateInput = {
  configuration?: InputMaybe<SchoolConfigurationTopBarConfigurationUpdateRelationInput>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  label?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ContentEngagementReport = {
  __typename?: 'ContentEngagementReport';
  authorId?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  flagReason?: Maybe<Scalars['String']>;
  flaggedAt?: Maybe<Scalars['DateTime']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isAutoReported?: Maybe<Scalars['Boolean']>;
  isFlagged?: Maybe<Scalars['String']>;
  isSupporter?: Maybe<Scalars['Int']>;
  lastFlaggedByName?: Maybe<Scalars['String']>;
  legacyDate?: Maybe<Scalars['Date']>;
  likesCount?: Maybe<Scalars['Int']>;
  reactionsCount?: Maybe<Scalars['Int']>;
  reportStatus?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContentEngagementReportListResponse = {
  __typename?: 'ContentEngagementReportListResponse';
  count: Scalars['Int'];
  items: Array<ContentEngagementReport>;
};

export type ContentReport = {
  __typename?: 'ContentReport';
  _description?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  flagReason?: Maybe<Scalars['String']>;
  flaggedAt?: Maybe<Scalars['DateTime']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isAutoReported?: Maybe<Scalars['Boolean']>;
  isFlagged?: Maybe<Scalars['Boolean']>;
  isSupporter?: Maybe<Scalars['Int']>;
  lastFlaggedByEmail?: Maybe<Scalars['String']>;
  lastFlaggedByName?: Maybe<Scalars['String']>;
  legacyDate?: Maybe<Scalars['Date']>;
  postDate?: Maybe<Scalars['DateTime']>;
  reportStatus?: Maybe<Scalars['String']>;
  schools?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** ContentReportFieldsPermissions create input */
export type ContentReportFieldsPermissions = {
  authorId?: InputMaybe<Scalars['Boolean']>;
  authorName?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['Boolean']>;
  flaggedAt?: InputMaybe<Scalars['Boolean']>;
  groupId?: InputMaybe<Scalars['Boolean']>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isSupporter?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedByEmail?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedByName?: InputMaybe<Scalars['Boolean']>;
  legacyDate?: InputMaybe<Scalars['Boolean']>;
  postDate?: InputMaybe<Scalars['Boolean']>;
  reportStatus?: InputMaybe<Scalars['Boolean']>;
  schools?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type ContentReportFilter = {
  AND?: InputMaybe<Array<ContentReportFilter>>;
  OR?: InputMaybe<Array<ContentReportFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  authorId?: InputMaybe<StringPredicate>;
  authorName?: InputMaybe<StringPredicate>;
  category?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  flagReason?: InputMaybe<StringPredicate>;
  flaggedAt?: InputMaybe<DateTimePredicate>;
  groupId?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  isAutoReported?: InputMaybe<BoolPredicate>;
  isFlagged?: InputMaybe<BoolPredicate>;
  isSupporter?: InputMaybe<IntPredicate>;
  lastFlaggedByEmail?: InputMaybe<StringPredicate>;
  lastFlaggedByName?: InputMaybe<StringPredicate>;
  legacyDate?: InputMaybe<DatePredicate>;
  postDate?: InputMaybe<DateTimePredicate>;
  reportStatus?: InputMaybe<StringPredicate>;
  schools?: InputMaybe<StringPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type ContentReportGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: ContentReportGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type ContentReportGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  authorId?: InputMaybe<Array<GroupByField>>;
  authorName?: InputMaybe<Array<GroupByField>>;
  category?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  flagReason?: InputMaybe<Array<GroupByField>>;
  flaggedAt?: InputMaybe<Array<GroupByField>>;
  groupId?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  isAutoReported?: InputMaybe<Array<GroupByField>>;
  isFlagged?: InputMaybe<Array<GroupByField>>;
  isSupporter?: InputMaybe<Array<GroupByField>>;
  lastFlaggedByEmail?: InputMaybe<Array<GroupByField>>;
  lastFlaggedByName?: InputMaybe<Array<GroupByField>>;
  legacyDate?: InputMaybe<Array<GroupByField>>;
  postDate?: InputMaybe<Array<GroupByField>>;
  reportStatus?: InputMaybe<Array<GroupByField>>;
  schools?: InputMaybe<Array<GroupByField>>;
  text?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

/** ContentReportListResponse output */
export type ContentReportListResponse = {
  __typename?: 'ContentReportListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<ContentReport>;
};

/** ContentReportManyResponse output */
export type ContentReportManyResponse = {
  __typename?: 'ContentReportManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<ContentReport>;
};

/** No longer supported. Use `sort` instead. */
export type ContentReportOrderBy =
  | 'authorId_ASC'
  | 'authorId_DESC'
  | 'authorName_ASC'
  | 'authorName_DESC'
  | 'category_ASC'
  | 'category_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'flagReason_ASC'
  | 'flagReason_DESC'
  | 'flaggedAt_ASC'
  | 'flaggedAt_DESC'
  | 'groupId_ASC'
  | 'groupId_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isAutoReported_ASC'
  | 'isAutoReported_DESC'
  | 'isFlagged_ASC'
  | 'isFlagged_DESC'
  | 'isSupporter_ASC'
  | 'isSupporter_DESC'
  | 'lastFlaggedByEmail_ASC'
  | 'lastFlaggedByEmail_DESC'
  | 'lastFlaggedByName_ASC'
  | 'lastFlaggedByName_DESC'
  | 'legacyDate_ASC'
  | 'legacyDate_DESC'
  | 'postDate_ASC'
  | 'postDate_DESC'
  | 'reportStatus_ASC'
  | 'reportStatus_DESC'
  | 'schools_ASC'
  | 'schools_DESC'
  | 'text_ASC'
  | 'text_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** ContentReport subscription payload */
export type ContentReportPayload = {
  __typename?: 'ContentReportPayload';
  mutation: MutationType;
  node?: Maybe<ContentReport>;
  previousValues?: Maybe<ContentReport>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContentReportSort = {
  authorId?: InputMaybe<SortOrder>;
  authorName?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  flagReason?: InputMaybe<SortOrder>;
  flaggedAt?: InputMaybe<SortOrder>;
  groupId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAutoReported?: InputMaybe<SortOrder>;
  isFlagged?: InputMaybe<SortOrder>;
  isSupporter?: InputMaybe<SortOrder>;
  lastFlaggedByEmail?: InputMaybe<SortOrder>;
  lastFlaggedByName?: InputMaybe<SortOrder>;
  legacyDate?: InputMaybe<SortOrder>;
  postDate?: InputMaybe<SortOrder>;
  reportStatus?: InputMaybe<SortOrder>;
  schools?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** ContentReport subscription filter */
export type ContentReportSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<ContentReportFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** ContentReport update input */
export type ContentReportUpdateByFilterInput = {
  authorId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  authorName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  category?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  flagReason?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  flaggedAt?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  groupId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  isAutoReported?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isFlagged?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isSupporter?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  lastFlaggedByEmail?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  lastFlaggedByName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  legacyDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateInput>>>;
  postDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  reportStatus?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  schools?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  text?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

export type ContentReport_PermissionFilter = {
  AND?: InputMaybe<Array<ContentReport_PermissionFilter>>;
  OR?: InputMaybe<Array<ContentReport_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  authorId?: InputMaybe<StringPredicate>;
  authorName?: InputMaybe<StringPredicate>;
  category?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  flagReason?: InputMaybe<StringPredicate>;
  flaggedAt?: InputMaybe<DateTimePredicate>;
  groupId?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  isAutoReported?: InputMaybe<BoolPredicate>;
  isFlagged?: InputMaybe<BoolPredicate>;
  isSupporter?: InputMaybe<IntPredicate>;
  lastFlaggedByEmail?: InputMaybe<StringPredicate>;
  lastFlaggedByName?: InputMaybe<StringPredicate>;
  legacyDate?: InputMaybe<DatePredicate>;
  postDate?: InputMaybe<DateTimePredicate>;
  reportStatus?: InputMaybe<StringPredicate>;
  schools?: InputMaybe<StringPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

/** Users create input from createdGroups */
export type CreatedGroups_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from createdGroups */
export type CreatedGroups_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Users create input from createdJobOffers */
export type CreatedJobOffers_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from createdJobOffers */
export type CreatedJobOffers_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Users create input from createdSchoolNewsPosts */
export type CreatedSchoolNewsPosts_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from createdSchoolNewsPosts */
export type CreatedSchoolNewsPosts_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** CollectiveIntelligenceThreads create input from creator */
export type Creator_CollectiveIntelligenceThreadCreateInput = {
  creator?: InputMaybe<CollectiveIntelligenceThreadsCreatorRelationInput>;
  images?: InputMaybe<CollectiveIntelligenceThreadsImagesRelationInput>;
  schools?: InputMaybe<CollectiveIntelligenceThreadsSchoolsRelationInput>;
  text: Scalars['String'];
  title: Scalars['String'];
  views?: InputMaybe<CollectiveIntelligenceThreadsViewsRelationInput>;
};

/** CollectiveIntelligenceThreads update input from creator */
export type Creator_CollectiveIntelligenceThreadUpdateInput = {
  data: CollectiveIntelligenceThreadUpdateInput;
  filter?: InputMaybe<CollectiveIntelligenceThreadKeyFilter>;
};

/** Groups create input from creator */
export type Creator_GroupCreateInput = {
  audiences?: InputMaybe<GroupsAudiencesRelationInput>;
  creator?: InputMaybe<GroupsCreatorRelationInput>;
  description: Scalars['String'];
  groupAdminPreferences?: InputMaybe<GroupsGroupAdminPreferencesRelationInput>;
  groupToJoin?: InputMaybe<GroupsGroupToJoinRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<GroupsLogoRelationInput>;
  members?: InputMaybe<GroupsMembersRelationInput>;
  membersCount?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<GroupsPostsRelationInput>;
  postsCount?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<GroupsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Groups update input from creator */
export type Creator_GroupUpdateInput = {
  data: GroupUpdateInput;
  filter?: InputMaybe<GroupKeyFilter>;
};

/** JobOffers create input from creator */
export type Creator_JobOfferCreateInput = {
  creator?: InputMaybe<JobOffersCreatorRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  engagementCount?: InputMaybe<Scalars['Int']>;
  interestedUsers?: InputMaybe<JobOffersInterestedUsersRelationInput>;
  managerCreator?: InputMaybe<JobOffersManagerCreatorRelationInput>;
  organization?: InputMaybe<JobOffersOrganizationRelationInput>;
  position: Scalars['String'];
  postDate?: InputMaybe<Scalars['DateTime']>;
  schools?: InputMaybe<JobOffersSchoolsRelationInput>;
};

/** JobOffers update input from creator */
export type Creator_JobOfferUpdateInput = {
  data: JobOfferUpdateInput;
  filter?: InputMaybe<JobOfferKeyFilter>;
};

export type CurrentLocationZipRequestInput = {
  latitude: Scalars['String'];
  longitude: Scalars['String'];
};

export type CurrentLocationZipRequestSuccess = {
  __typename?: 'CurrentLocationZipRequestSuccess';
  zip: Scalars['String'];
};

/** Custom Table Field Type */
export type CustomTableField = {
  __typename?: 'CustomTableField';
  computedMode?: Maybe<ComputedFieldMode>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  fieldType?: Maybe<FieldType>;
  fieldTypeAttributes?: Maybe<FieldTypeAttributes>;
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  totalAdRevenue?: Maybe<Scalars['Float']>;
  totalAdvertisements?: Maybe<Scalars['Int']>;
  totalAllRevenue?: Maybe<Scalars['Float']>;
  totalDiscounts?: Maybe<Scalars['Int']>;
  totalDiscountsRevenue?: Maybe<Scalars['Float']>;
  totalMembers?: Maybe<Scalars['Int']>;
  totalSupporters?: Maybe<Scalars['Int']>;
  totalSupportersRevenue?: Maybe<Scalars['Float']>;
  totalVolume?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Dashboard create input */
export type DashboardCreateInput = {
  school?: InputMaybe<DashboardSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  totalAdRevenue?: InputMaybe<Scalars['Float']>;
  totalAdvertisements?: InputMaybe<Scalars['Int']>;
  totalAllRevenue?: InputMaybe<Scalars['Float']>;
  totalDiscounts?: InputMaybe<Scalars['Int']>;
  totalDiscountsRevenue?: InputMaybe<Scalars['Float']>;
  totalMembers?: InputMaybe<Scalars['Int']>;
  totalSupporters?: InputMaybe<Scalars['Int']>;
  totalSupportersRevenue?: InputMaybe<Scalars['Float']>;
  totalVolume?: InputMaybe<Scalars['Int']>;
};

/** Dashboard create many input */
export type DashboardCreateManyInput = {
  school?: InputMaybe<DashboardSchoolManyRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  totalAdRevenue?: InputMaybe<Scalars['Float']>;
  totalAdvertisements?: InputMaybe<Scalars['Int']>;
  totalAllRevenue?: InputMaybe<Scalars['Float']>;
  totalDiscounts?: InputMaybe<Scalars['Int']>;
  totalDiscountsRevenue?: InputMaybe<Scalars['Float']>;
  totalMembers?: InputMaybe<Scalars['Int']>;
  totalSupporters?: InputMaybe<Scalars['Int']>;
  totalSupportersRevenue?: InputMaybe<Scalars['Float']>;
  totalVolume?: InputMaybe<Scalars['Int']>;
};

/** Dashboard delete input */
export type DashboardDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** DashboardFieldsPermissions create input */
export type DashboardFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  totalAdRevenue?: InputMaybe<Scalars['Boolean']>;
  totalAdvertisements?: InputMaybe<Scalars['Boolean']>;
  totalAllRevenue?: InputMaybe<Scalars['Boolean']>;
  totalDiscounts?: InputMaybe<Scalars['Boolean']>;
  totalDiscountsRevenue?: InputMaybe<Scalars['Boolean']>;
  totalMembers?: InputMaybe<Scalars['Boolean']>;
  totalSupporters?: InputMaybe<Scalars['Boolean']>;
  totalSupportersRevenue?: InputMaybe<Scalars['Boolean']>;
  totalVolume?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type DashboardFilter = {
  AND?: InputMaybe<Array<DashboardFilter>>;
  OR?: InputMaybe<Array<DashboardFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  totalAdRevenue?: InputMaybe<FloatPredicate>;
  totalAdvertisements?: InputMaybe<IntPredicate>;
  totalAllRevenue?: InputMaybe<FloatPredicate>;
  totalDiscounts?: InputMaybe<IntPredicate>;
  totalDiscountsRevenue?: InputMaybe<FloatPredicate>;
  totalMembers?: InputMaybe<IntPredicate>;
  totalSupporters?: InputMaybe<IntPredicate>;
  totalSupportersRevenue?: InputMaybe<FloatPredicate>;
  totalVolume?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type DashboardGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: DashboardGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type DashboardGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  totalAdRevenue?: InputMaybe<Array<GroupByField>>;
  totalAdvertisements?: InputMaybe<Array<GroupByField>>;
  totalAllRevenue?: InputMaybe<Array<GroupByField>>;
  totalDiscounts?: InputMaybe<Array<GroupByField>>;
  totalDiscountsRevenue?: InputMaybe<Array<GroupByField>>;
  totalMembers?: InputMaybe<Array<GroupByField>>;
  totalSupporters?: InputMaybe<Array<GroupByField>>;
  totalSupportersRevenue?: InputMaybe<Array<GroupByField>>;
  totalVolume?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type DashboardKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** DashboardListResponse output */
export type DashboardListResponse = {
  __typename?: 'DashboardListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Dashboard>;
};

/** DashboardManyResponse output */
export type DashboardManyResponse = {
  __typename?: 'DashboardManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Dashboard>;
};

/** SchoolConfiguration create input from dashboardMetrics */
export type DashboardMetrics_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from dashboardMetrics */
export type DashboardMetrics_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** No longer supported. Use `sort` instead. */
export type DashboardOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'totalAdRevenue_ASC'
  | 'totalAdRevenue_DESC'
  | 'totalAdvertisements_ASC'
  | 'totalAdvertisements_DESC'
  | 'totalAllRevenue_ASC'
  | 'totalAllRevenue_DESC'
  | 'totalDiscountsRevenue_ASC'
  | 'totalDiscountsRevenue_DESC'
  | 'totalDiscounts_ASC'
  | 'totalDiscounts_DESC'
  | 'totalMembers_ASC'
  | 'totalMembers_DESC'
  | 'totalSupportersRevenue_ASC'
  | 'totalSupportersRevenue_DESC'
  | 'totalSupporters_ASC'
  | 'totalSupporters_DESC'
  | 'totalVolume_ASC'
  | 'totalVolume_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** Dashboard subscription payload */
export type DashboardPayload = {
  __typename?: 'DashboardPayload';
  mutation: MutationType;
  node?: Maybe<Dashboard>;
  previousValues?: Maybe<Dashboard>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DashboardRelationFilter = {
  every?: InputMaybe<DashboardFilter>;
  none?: InputMaybe<DashboardFilter>;
  some?: InputMaybe<DashboardFilter>;
};

/** Dashboard relation input */
export type DashboardSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** Dashboard relation input */
export type DashboardSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<DashboardMetrics_SchoolConfigurationCreateInput>;
};

/** Dashboard relation input */
export type DashboardSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<DashboardMetrics_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<DashboardMetrics_SchoolConfigurationUpdateInput>;
};

export type DashboardSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  totalAdRevenue?: InputMaybe<SortOrder>;
  totalAdvertisements?: InputMaybe<SortOrder>;
  totalAllRevenue?: InputMaybe<SortOrder>;
  totalDiscounts?: InputMaybe<SortOrder>;
  totalDiscountsRevenue?: InputMaybe<SortOrder>;
  totalMembers?: InputMaybe<SortOrder>;
  totalSupporters?: InputMaybe<SortOrder>;
  totalSupportersRevenue?: InputMaybe<SortOrder>;
  totalVolume?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Dashboard subscription filter */
export type DashboardSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<DashboardFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** Dashboard update input */
export type DashboardUpdateByFilterInput = {
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  totalAdRevenue?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  totalAdvertisements?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  totalAllRevenue?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  totalDiscounts?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  totalDiscountsRevenue?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  totalMembers?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  totalSupporters?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  totalSupportersRevenue?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  totalVolume?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
};

/** Dashboard update input */
export type DashboardUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  school?: InputMaybe<DashboardSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  totalAdRevenue?: InputMaybe<Scalars['Float']>;
  totalAdvertisements?: InputMaybe<Scalars['Int']>;
  totalAllRevenue?: InputMaybe<Scalars['Float']>;
  totalDiscounts?: InputMaybe<Scalars['Int']>;
  totalDiscountsRevenue?: InputMaybe<Scalars['Float']>;
  totalMembers?: InputMaybe<Scalars['Int']>;
  totalSupporters?: InputMaybe<Scalars['Int']>;
  totalSupportersRevenue?: InputMaybe<Scalars['Float']>;
  totalVolume?: InputMaybe<Scalars['Int']>;
};

export type Dashboard_PermissionFilter = {
  AND?: InputMaybe<Array<Dashboard_PermissionFilter>>;
  OR?: InputMaybe<Array<Dashboard_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  totalAdRevenue?: InputMaybe<FloatPredicate>;
  totalAdvertisements?: InputMaybe<IntPredicate>;
  totalAllRevenue?: InputMaybe<FloatPredicate>;
  totalDiscounts?: InputMaybe<IntPredicate>;
  totalDiscountsRevenue?: InputMaybe<FloatPredicate>;
  totalMembers?: InputMaybe<IntPredicate>;
  totalSupporters?: InputMaybe<IntPredicate>;
  totalSupportersRevenue?: InputMaybe<FloatPredicate>;
  totalVolume?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type Dashboard_PermissionRelationFilter = {
  every?: InputMaybe<Dashboard_PermissionFilter>;
  none?: InputMaybe<Dashboard_PermissionFilter>;
  some?: InputMaybe<Dashboard_PermissionFilter>;
};

/** Date Field Attributes */
export type DateFieldTypeAttributes = {
  __typename?: 'DateFieldTypeAttributes';
  format: Scalars['String'];
};

export type DatePartFunctionType =
  | 'DATE'
  | 'DAY'
  | 'DAY_NAME'
  | 'DAY_OF_MONTH'
  | 'DAY_OF_WEEK'
  | 'DAY_OF_YEAR'
  | 'HOUR'
  | 'LAST_DAY'
  | 'MICROSECOND'
  | 'MINUTE'
  | 'MONTH'
  | 'MONTH_NAME'
  | 'QUARTER'
  | 'SECOND'
  | 'TIME'
  | 'WEEK'
  | 'WEEK_DAY'
  | 'WEEK_OF_YEAR'
  | 'YEAR'
  | 'YEAR_WEEK';

export type DatePredicate = {
  equals?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<Scalars['Date']>>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  not_equals?: InputMaybe<Scalars['Date']>;
  not_in?: InputMaybe<Array<Scalars['Date']>>;
  relative?: InputMaybe<DateRelativePredicates>;
};

export type DatePredicateHaving = {
  AND?: InputMaybe<Array<DatePredicateHaving>>;
  OR?: InputMaybe<Array<DatePredicateHaving>>;
  equals?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<Scalars['Date']>>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  not_equals?: InputMaybe<Scalars['Date']>;
  not_in?: InputMaybe<Array<Scalars['Date']>>;
};

export type DateRelativePredicateType = {
  op?: InputMaybe<RelativePredicateOpEnum>;
  unit?: InputMaybe<RelativePredicateUnitEnum>;
  value: Scalars['String'];
};

export type DateRelativePredicates = {
  gt?: InputMaybe<DateRelativePredicateType>;
  gte?: InputMaybe<DateRelativePredicateType>;
  lt?: InputMaybe<DateRelativePredicateType>;
  lte?: InputMaybe<DateRelativePredicateType>;
};

export type DateTimePredicate = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not_equals?: InputMaybe<Scalars['DateTime']>;
  not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  relative?: InputMaybe<DateRelativePredicates>;
};

export type DateTimePredicateHaving = {
  AND?: InputMaybe<Array<DateTimePredicateHaving>>;
  OR?: InputMaybe<Array<DateTimePredicateHaving>>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not_equals?: InputMaybe<Scalars['DateTime']>;
  not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

/** Date Type Format Enum */
export type DateTypeFormatEnum = 'DATE' | 'DATETIME';

export type Debt = {
  __typename?: 'Debt';
  _description?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  debtOperationHistories?: Maybe<DebtOperationHistoryListResponse>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  organization?: Maybe<Organization>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DebtDebtOperationHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DebtOperationHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DebtOperationHistoryGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DebtOperationHistoryOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DebtOperationHistorySort>>;
};

/** Debts create input */
export type DebtCreateInput = {
  amount?: InputMaybe<Scalars['Int']>;
  debtOperationHistories?: InputMaybe<DebtsDebtOperationHistoriesRelationInput>;
  organization?: InputMaybe<DebtsOrganizationRelationInput>;
};

/** Debts create many input */
export type DebtCreateManyInput = {
  amount?: InputMaybe<Scalars['Int']>;
  debtOperationHistories?: InputMaybe<DebtsDebtOperationHistoriesManyRelationInput>;
  organization?: InputMaybe<DebtsOrganizationManyRelationInput>;
};

/** Debts delete input */
export type DebtDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** DebtFieldsPermissions create input */
export type DebtFieldsPermissions = {
  amount?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type DebtFilter = {
  AND?: InputMaybe<Array<DebtFilter>>;
  OR?: InputMaybe<Array<DebtFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<IntPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  debtOperationHistories?: InputMaybe<DebtOperationHistoryRelationFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  organization?: InputMaybe<OrganizationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type DebtGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: DebtGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type DebtGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  amount?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  debtOperationHistories?: InputMaybe<DebtOperationHistoryGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  organization?: InputMaybe<OrganizationGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type DebtKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** DebtListResponse output */
export type DebtListResponse = {
  __typename?: 'DebtListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Debt>;
};

/** DebtManyResponse output */
export type DebtManyResponse = {
  __typename?: 'DebtManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Debt>;
};

/** DebtOperationHistories relation input */
export type DebtOperationHistoriesDebtManyRelationInput = {
  connect?: InputMaybe<DebtKeyFilter>;
};

/** DebtOperationHistories relation input */
export type DebtOperationHistoriesDebtRelationInput = {
  connect?: InputMaybe<DebtKeyFilter>;
  create?: InputMaybe<DebtOperationHistories_DebtCreateInput>;
};

/** DebtOperationHistories relation input */
export type DebtOperationHistoriesDebtUpdateRelationInput = {
  connect?: InputMaybe<DebtKeyFilter>;
  create?: InputMaybe<DebtOperationHistories_DebtCreateInput>;
  disconnect?: InputMaybe<DebtKeyFilter>;
  reconnect?: InputMaybe<DebtKeyFilter>;
  update?: InputMaybe<DebtOperationHistories_DebtUpdateInput>;
};

/** DebtOperationHistories relation input */
export type DebtOperationHistoriesSchoolsManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
};

/** DebtOperationHistories relation input */
export type DebtOperationHistoriesSchoolsRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<DebtOperations_SchoolConfigurationCreateInput>>>;
};

/** DebtOperationHistories relation input */
export type DebtOperationHistoriesSchoolsUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<DebtOperations_SchoolConfigurationCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<DebtOperations_SchoolConfigurationUpdateInput>>>;
};

/** Debts create input from debtOperationHistories */
export type DebtOperationHistories_DebtCreateInput = {
  amount?: InputMaybe<Scalars['Int']>;
  debtOperationHistories?: InputMaybe<DebtsDebtOperationHistoriesRelationInput>;
  organization?: InputMaybe<DebtsOrganizationRelationInput>;
};

/** Debts update input from debtOperationHistories */
export type DebtOperationHistories_DebtUpdateInput = {
  amount?: InputMaybe<Scalars['Int']>;
  debtOperationHistories?: InputMaybe<DebtsDebtOperationHistoriesUpdateRelationInput>;
  organization?: InputMaybe<DebtsOrganizationUpdateRelationInput>;
};

export type DebtOperationHistory = {
  __typename?: 'DebtOperationHistory';
  _description?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  billingType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  dateTimeOperation?: Maybe<Scalars['DateTime']>;
  debt?: Maybe<Debt>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  operation?: Maybe<Scalars['String']>;
  schools?: Maybe<SchoolConfigurationListResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DebtOperationHistorySchoolsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationSort>>;
};

/** DebtOperationHistories create input */
export type DebtOperationHistoryCreateInput = {
  amount?: InputMaybe<Scalars['Int']>;
  billingType?: InputMaybe<Scalars['String']>;
  dateTimeOperation: Scalars['DateTime'];
  debt?: InputMaybe<DebtOperationHistoriesDebtRelationInput>;
  operation: Scalars['String'];
  schools?: InputMaybe<DebtOperationHistoriesSchoolsRelationInput>;
};

/** DebtOperationHistories create many input */
export type DebtOperationHistoryCreateManyInput = {
  amount?: InputMaybe<Scalars['Int']>;
  billingType?: InputMaybe<Scalars['String']>;
  dateTimeOperation: Scalars['DateTime'];
  debt: DebtOperationHistoriesDebtManyRelationInput;
  operation: Scalars['String'];
  schools?: InputMaybe<DebtOperationHistoriesSchoolsManyRelationInput>;
};

/** DebtOperationHistories delete input */
export type DebtOperationHistoryDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** DebtOperationHistoryFieldsPermissions create input */
export type DebtOperationHistoryFieldsPermissions = {
  amount?: InputMaybe<Scalars['Boolean']>;
  billingType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  dateTimeOperation?: InputMaybe<Scalars['Boolean']>;
  operation?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type DebtOperationHistoryFilter = {
  AND?: InputMaybe<Array<DebtOperationHistoryFilter>>;
  OR?: InputMaybe<Array<DebtOperationHistoryFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<IntPredicate>;
  billingType?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  dateTimeOperation?: InputMaybe<DateTimePredicate>;
  debt?: InputMaybe<DebtFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  operation?: InputMaybe<StringPredicate>;
  schools?: InputMaybe<SchoolConfigurationRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type DebtOperationHistoryGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: DebtOperationHistoryGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type DebtOperationHistoryGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  amount?: InputMaybe<Array<GroupByField>>;
  billingType?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  dateTimeOperation?: InputMaybe<Array<GroupByField>>;
  debt?: InputMaybe<DebtGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  operation?: InputMaybe<Array<GroupByField>>;
  schools?: InputMaybe<SchoolConfigurationGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type DebtOperationHistoryKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** DebtOperationHistoryListResponse output */
export type DebtOperationHistoryListResponse = {
  __typename?: 'DebtOperationHistoryListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<DebtOperationHistory>;
};

/** DebtOperationHistoryManyResponse output */
export type DebtOperationHistoryManyResponse = {
  __typename?: 'DebtOperationHistoryManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<DebtOperationHistory>;
};

/** No longer supported. Use `sort` instead. */
export type DebtOperationHistoryOrderBy =
  | 'amount_ASC'
  | 'amount_DESC'
  | 'billingType_ASC'
  | 'billingType_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'dateTimeOperation_ASC'
  | 'dateTimeOperation_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'operation_ASC'
  | 'operation_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** DebtOperationHistories subscription payload */
export type DebtOperationHistoryPayload = {
  __typename?: 'DebtOperationHistoryPayload';
  mutation: MutationType;
  node?: Maybe<DebtOperationHistory>;
  previousValues?: Maybe<DebtOperationHistory>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DebtOperationHistoryRelationFilter = {
  every?: InputMaybe<DebtOperationHistoryFilter>;
  none?: InputMaybe<DebtOperationHistoryFilter>;
  some?: InputMaybe<DebtOperationHistoryFilter>;
};

export type DebtOperationHistorySort = {
  amount?: InputMaybe<SortOrder>;
  billingType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  dateTimeOperation?: InputMaybe<SortOrder>;
  debt?: InputMaybe<DebtSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  operation?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** DebtOperationHistories subscription filter */
export type DebtOperationHistorySubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<DebtOperationHistoryFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** DebtOperationHistories update input */
export type DebtOperationHistoryUpdateByFilterInput = {
  amount?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  billingType?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  dateTimeOperation?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  operation?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
};

/** DebtOperationHistories update input */
export type DebtOperationHistoryUpdateInput = {
  amount?: InputMaybe<Scalars['Int']>;
  billingType?: InputMaybe<Scalars['String']>;
  dateTimeOperation?: InputMaybe<Scalars['DateTime']>;
  debt?: InputMaybe<DebtOperationHistoriesDebtUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  operation?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<DebtOperationHistoriesSchoolsUpdateRelationInput>;
};

export type DebtOperationHistory_PermissionFilter = {
  AND?: InputMaybe<Array<DebtOperationHistory_PermissionFilter>>;
  OR?: InputMaybe<Array<DebtOperationHistory_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<IntPredicate>;
  billingType?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  dateTimeOperation?: InputMaybe<DateTimePredicate>;
  debt?: InputMaybe<Debt_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  operation?: InputMaybe<StringPredicate>;
  schools?: InputMaybe<SchoolConfiguration_PermissionRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type DebtOperationHistory_PermissionRelationFilter = {
  every?: InputMaybe<DebtOperationHistory_PermissionFilter>;
  none?: InputMaybe<DebtOperationHistory_PermissionFilter>;
  some?: InputMaybe<DebtOperationHistory_PermissionFilter>;
};

/** SchoolConfiguration create input from debtOperations */
export type DebtOperations_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from debtOperations */
export type DebtOperations_SchoolConfigurationUpdateInput = {
  data: SchoolConfigurationUpdateInput;
  filter?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** No longer supported. Use `sort` instead. */
export type DebtOrderBy =
  | 'amount_ASC'
  | 'amount_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** Debts subscription payload */
export type DebtPayload = {
  __typename?: 'DebtPayload';
  mutation: MutationType;
  node?: Maybe<Debt>;
  previousValues?: Maybe<Debt>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DebtSort = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Debts subscription filter */
export type DebtSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<DebtFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** Debts update input */
export type DebtUpdateByFilterInput = {
  amount?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
};

/** Debts update input */
export type DebtUpdateInput = {
  amount?: InputMaybe<Scalars['Int']>;
  debtOperationHistories?: InputMaybe<DebtsDebtOperationHistoriesUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  organization?: InputMaybe<DebtsOrganizationUpdateRelationInput>;
};

/** DebtOperationHistories create input from debt */
export type Debt_DebtOperationHistoryCreateInput = {
  amount?: InputMaybe<Scalars['Int']>;
  billingType?: InputMaybe<Scalars['String']>;
  dateTimeOperation: Scalars['DateTime'];
  debt?: InputMaybe<DebtOperationHistoriesDebtRelationInput>;
  operation: Scalars['String'];
  schools?: InputMaybe<DebtOperationHistoriesSchoolsRelationInput>;
};

/** DebtOperationHistories update input from debt */
export type Debt_DebtOperationHistoryUpdateInput = {
  data: DebtOperationHistoryUpdateInput;
  filter?: InputMaybe<DebtOperationHistoryKeyFilter>;
};

/** Organizations create input from debt */
export type Debt_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from debt */
export type Debt_OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

export type Debt_PermissionFilter = {
  AND?: InputMaybe<Array<Debt_PermissionFilter>>;
  OR?: InputMaybe<Array<Debt_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<IntPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  debtOperationHistories?: InputMaybe<DebtOperationHistory_PermissionRelationFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  organization?: InputMaybe<Organization_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

/** Debts relation input */
export type DebtsDebtOperationHistoriesManyRelationInput = {
  connect?: InputMaybe<Array<DebtOperationHistoryKeyFilter>>;
};

/** Debts relation input */
export type DebtsDebtOperationHistoriesRelationInput = {
  connect?: InputMaybe<Array<DebtOperationHistoryKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Debt_DebtOperationHistoryCreateInput>>>;
};

/** Debts relation input */
export type DebtsDebtOperationHistoriesUpdateRelationInput = {
  connect?: InputMaybe<Array<DebtOperationHistoryKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Debt_DebtOperationHistoryCreateInput>>>;
  disconnect?: InputMaybe<Array<DebtOperationHistoryKeyFilter>>;
  reconnect?: InputMaybe<Array<DebtOperationHistoryKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Debt_DebtOperationHistoryUpdateInput>>>;
};

/** Debts relation input */
export type DebtsOrganizationManyRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
};

/** Debts relation input */
export type DebtsOrganizationRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<Debt_OrganizationCreateInput>;
};

/** Debts relation input */
export type DebtsOrganizationUpdateRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<Debt_OrganizationCreateInput>;
  disconnect?: InputMaybe<OrganizationKeyFilter>;
  reconnect?: InputMaybe<OrganizationKeyFilter>;
  update?: InputMaybe<Debt_OrganizationUpdateInput>;
};

/** Users create input from deleteInboxMessages */
export type DeleteInboxMessages_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from deleteInboxMessages */
export type DeleteInboxMessages_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

/** InboxMessages create input from deletedBy */
export type DeletedBy_InboxMessageCreateInput = {
  author?: InputMaybe<InboxMessagesAuthorRelationInput>;
  deletedBy?: InputMaybe<InboxMessagesDeletedByRelationInput>;
  inbox?: InputMaybe<InboxMessagesInboxRelationInput>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isHide?: InputMaybe<InboxMessagesIsHideRelationInput>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<InboxMessagesMediaRelationInput>;
  messageMedia?: InputMaybe<InboxMessagesMessageMediaRelationInput>;
  reply?: InputMaybe<Scalars['Boolean']>;
  text: Scalars['String'];
};

/** InboxMessages update input from deletedBy */
export type DeletedBy_InboxMessageUpdateInput = {
  data: InboxMessageUpdateInput;
  filter?: InputMaybe<InboxMessageKeyFilter>;
};

/** DeployDataResponse */
export type DeployDataResponse = {
  __typename?: 'DeployDataResponse';
  buildName: Scalars['String'];
  uploadBuildUrl: Scalars['String'];
  uploadMetaDataUrl: Scalars['String'];
};

export type DeployModeEnum = 'FULL' | 'FUNCTIONS' | 'MIGRATIONS' | 'ONLY_PLUGINS' | 'ONLY_PROJECT';

/** DeployOptions */
export type DeployOptions = {
  extensionNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mode?: InputMaybe<DeployModeEnum>;
  nodeVersion?: InputMaybe<Scalars['String']>;
  pluginNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DeployStatusEnum =
  | 'compiling'
  | 'complete_error'
  | 'complete_success'
  | 'deploying'
  | 'initialize'
  | 'preparing';

/** DeployStatusResult */
export type DeployStatusResult = {
  __typename?: 'DeployStatusResult';
  message?: Maybe<Scalars['String']>;
  status: DeployStatusEnum;
};

/** DeployingBuildInput */
export type DeployingBuildInput = {
  buildName: Scalars['String'];
  options?: InputMaybe<DeployOptions>;
};

export type DeploymentAbItemResponse = {
  __typename?: 'DeploymentABItemResponse';
  dateDeploy?: Maybe<Scalars['DateTime']>;
  statusDeploy?: Maybe<Scalars['String']>;
  urlDeploy?: Maybe<Scalars['String']>;
  userDeploy?: Maybe<SystemMemberAccountInfo>;
  versionDeploy?: Maybe<Scalars['String']>;
  versionFrontEnd?: Maybe<Scalars['String']>;
};

export type Discount = {
  __typename?: 'Discount';
  _description?: Maybe<Scalars['String']>;
  advertising?: Maybe<Advertising>;
  amountPercent?: Maybe<Scalars['Int']>;
  billingRegisteredDiscounts?: Maybe<BillingRegisteredSpecialOfferListResponse>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  discountRedeemPost?: Maybe<HomeFeedPostListResponse>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<FileListResponse>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Organization>;
  payment?: Maybe<PaymentTransaction>;
  redemptions?: Maybe<DiscountRedemptionListResponse>;
  schools?: Maybe<SchoolConfigurationListResponse>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalClicks?: Maybe<Scalars['Int']>;
  totalSpent?: Maybe<Scalars['Int']>;
  totalViews?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usersRedeemed?: Maybe<UserListResponse>;
  usersRedeemedCount?: Maybe<Scalars['Int']>;
};

export type DiscountBillingRegisteredDiscountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingRegisteredSpecialOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingRegisteredSpecialOfferGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingRegisteredSpecialOfferOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingRegisteredSpecialOfferSort>>;
};

export type DiscountDiscountRedeemPostArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HomeFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<HomeFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<HomeFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HomeFeedPostSort>>;
};

export type DiscountImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FileSort>>;
};

export type DiscountRedemptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DiscountRedemptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DiscountRedemptionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DiscountRedemptionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DiscountRedemptionSort>>;
};

export type DiscountSchoolsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationSort>>;
};

export type DiscountUsersRedeemedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

/** Discounts create input */
export type DiscountCreateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingRelationInput>;
  amountPercent: Scalars['Int'];
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostRelationInput>;
  expirationDate: Scalars['DateTime'];
  images?: InputMaybe<DiscountsImagesRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationRelationInput>;
  payment?: InputMaybe<DiscountsPaymentRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsRelationInput>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  terms: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

/** Discounts create many input */
export type DiscountCreateManyInput = {
  advertising?: InputMaybe<DiscountsAdvertisingManyRelationInput>;
  amountPercent: Scalars['Int'];
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsManyRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostManyRelationInput>;
  expirationDate: Scalars['DateTime'];
  images?: InputMaybe<DiscountsImagesManyRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationManyRelationInput>;
  payment?: InputMaybe<DiscountsPaymentManyRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsManyRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsManyRelationInput>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  terms: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedManyRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

export type DiscountCreateRequestInput = {
  amountPercent: Scalars['Int'];
  category: Scalars['String'];
  createdByAdmin?: InputMaybe<Scalars['Boolean']>;
  expirationDate: Scalars['DateTime'];
  images?: InputMaybe<DiscountsImagesRelationInput>;
  organizationId?: InputMaybe<Scalars['ID']>;
  schools?: InputMaybe<DiscountsSchoolsRelationInput>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  terms: Scalars['String'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type DiscountCreateRequestResponse = {
  __typename?: 'DiscountCreateRequestResponse';
  id: Scalars['ID'];
};

/** Discounts delete input */
export type DiscountDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** DiscountFieldsPermissions create input */
export type DiscountFieldsPermissions = {
  amountPercent?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  expirationDate?: InputMaybe<Scalars['Boolean']>;
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  terms?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['Boolean']>;
  totalClicks?: InputMaybe<Scalars['Boolean']>;
  totalSpent?: InputMaybe<Scalars['Boolean']>;
  totalViews?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  usersRedeemedCount?: InputMaybe<Scalars['Boolean']>;
};

export type DiscountFilter = {
  AND?: InputMaybe<Array<DiscountFilter>>;
  OR?: InputMaybe<Array<DiscountFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  advertising?: InputMaybe<AdvertisingFilter>;
  amountPercent?: InputMaybe<IntPredicate>;
  billingRegisteredDiscounts?: InputMaybe<BillingRegisteredSpecialOfferRelationFilter>;
  category?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  discountRedeemPost?: InputMaybe<HomeFeedPostRelationFilter>;
  expirationDate?: InputMaybe<DateTimePredicate>;
  id?: InputMaybe<IdPredicate>;
  images?: InputMaybe<FileRelationFilter>;
  isSchoolDiscount?: InputMaybe<BoolPredicate>;
  organization?: InputMaybe<OrganizationFilter>;
  payment?: InputMaybe<PaymentTransactionFilter>;
  redemptions?: InputMaybe<DiscountRedemptionRelationFilter>;
  schools?: InputMaybe<SchoolConfigurationRelationFilter>;
  startDate?: InputMaybe<DateTimePredicate>;
  status?: InputMaybe<StringPredicate>;
  terms?: InputMaybe<StringPredicate>;
  timezone?: InputMaybe<StringPredicate>;
  title?: InputMaybe<StringPredicate>;
  totalClicks?: InputMaybe<IntPredicate>;
  totalSpent?: InputMaybe<IntPredicate>;
  totalViews?: InputMaybe<IntPredicate>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  usersRedeemed?: InputMaybe<UserRelationFilter>;
  usersRedeemedCount?: InputMaybe<IntPredicate>;
};

export type DiscountGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: DiscountGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type DiscountGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  advertising?: InputMaybe<AdvertisingGroupByQuery>;
  amountPercent?: InputMaybe<Array<GroupByField>>;
  billingRegisteredDiscounts?: InputMaybe<BillingRegisteredSpecialOfferGroupByQuery>;
  category?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  discountRedeemPost?: InputMaybe<HomeFeedPostGroupByQuery>;
  expirationDate?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  images?: InputMaybe<FileGroupByQuery>;
  isSchoolDiscount?: InputMaybe<Array<GroupByField>>;
  organization?: InputMaybe<OrganizationGroupByQuery>;
  payment?: InputMaybe<PaymentTransactionGroupByQuery>;
  redemptions?: InputMaybe<DiscountRedemptionGroupByQuery>;
  schools?: InputMaybe<SchoolConfigurationGroupByQuery>;
  startDate?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  terms?: InputMaybe<Array<GroupByField>>;
  timezone?: InputMaybe<Array<GroupByField>>;
  title?: InputMaybe<Array<GroupByField>>;
  totalClicks?: InputMaybe<Array<GroupByField>>;
  totalSpent?: InputMaybe<Array<GroupByField>>;
  totalViews?: InputMaybe<Array<GroupByField>>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  usersRedeemed?: InputMaybe<UserGroupByQuery>;
  usersRedeemedCount?: InputMaybe<Array<GroupByField>>;
};

export type DiscountKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** DiscountListResponse output */
export type DiscountListResponse = {
  __typename?: 'DiscountListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Discount>;
};

/** DiscountManyResponse output */
export type DiscountManyResponse = {
  __typename?: 'DiscountManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Discount>;
};

/** No longer supported. Use `sort` instead. */
export type DiscountOrderBy =
  | 'amountPercent_ASC'
  | 'amountPercent_DESC'
  | 'category_ASC'
  | 'category_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'expirationDate_ASC'
  | 'expirationDate_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isSchoolDiscount_ASC'
  | 'isSchoolDiscount_DESC'
  | 'startDate_ASC'
  | 'startDate_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'terms_ASC'
  | 'terms_DESC'
  | 'timezone_ASC'
  | 'timezone_DESC'
  | 'title_ASC'
  | 'title_DESC'
  | 'totalClicks_ASC'
  | 'totalClicks_DESC'
  | 'totalSpent_ASC'
  | 'totalSpent_DESC'
  | 'totalViews_ASC'
  | 'totalViews_DESC'
  | 'type_ASC'
  | 'type_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'usersRedeemedCount_ASC'
  | 'usersRedeemedCount_DESC';

/** Discounts subscription payload */
export type DiscountPayload = {
  __typename?: 'DiscountPayload';
  mutation: MutationType;
  node?: Maybe<Discount>;
  previousValues?: Maybe<Discount>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Discounts create input from discountRedeemPost */
export type DiscountRedeemPost_DiscountCreateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingRelationInput>;
  amountPercent: Scalars['Int'];
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostRelationInput>;
  expirationDate: Scalars['DateTime'];
  images?: InputMaybe<DiscountsImagesRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationRelationInput>;
  payment?: InputMaybe<DiscountsPaymentRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsRelationInput>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  terms: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

/** Discounts update input from discountRedeemPost */
export type DiscountRedeemPost_DiscountUpdateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingUpdateRelationInput>;
  amountPercent?: InputMaybe<Scalars['Int']>;
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsUpdateRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostUpdateRelationInput>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<DiscountsImagesUpdateRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationUpdateRelationInput>;
  payment?: InputMaybe<DiscountsPaymentUpdateRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsUpdateRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsUpdateRelationInput>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  terms?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedUpdateRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

export type DiscountRedemption = {
  __typename?: 'DiscountRedemption';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  /** Relationship between discounts and their redemptions. */
  discount?: Maybe<Discount>;
  discountId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** School where the discount was redeemed. */
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

/** DiscountRedemptions create input */
export type DiscountRedemptionCreateInput = {
  discount?: InputMaybe<DiscountRedemptionsDiscountRelationInput>;
  discountId?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<DiscountRedemptionsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<DiscountRedemptionsUserRelationInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** DiscountRedemptions create many input */
export type DiscountRedemptionCreateManyInput = {
  discount: DiscountRedemptionsDiscountManyRelationInput;
  discountId?: InputMaybe<Scalars['String']>;
  school: DiscountRedemptionsSchoolManyRelationInput;
  schoolId?: InputMaybe<Scalars['String']>;
  user: DiscountRedemptionsUserManyRelationInput;
  userId?: InputMaybe<Scalars['String']>;
};

/** DiscountRedemptions delete input */
export type DiscountRedemptionDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** DiscountRedemptionFieldsPermissions create input */
export type DiscountRedemptionFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  discountId?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
};

export type DiscountRedemptionFilter = {
  AND?: InputMaybe<Array<DiscountRedemptionFilter>>;
  OR?: InputMaybe<Array<DiscountRedemptionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  discount?: InputMaybe<DiscountFilter>;
  discountId?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
  userId?: InputMaybe<StringPredicate>;
};

export type DiscountRedemptionGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: DiscountRedemptionGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type DiscountRedemptionGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  discount?: InputMaybe<DiscountGroupByQuery>;
  discountId?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
  userId?: InputMaybe<Array<GroupByField>>;
};

export type DiscountRedemptionKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** DiscountRedemptionListResponse output */
export type DiscountRedemptionListResponse = {
  __typename?: 'DiscountRedemptionListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<DiscountRedemption>;
};

/** DiscountRedemptionManyResponse output */
export type DiscountRedemptionManyResponse = {
  __typename?: 'DiscountRedemptionManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<DiscountRedemption>;
};

/** No longer supported. Use `sort` instead. */
export type DiscountRedemptionOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'discountId_ASC'
  | 'discountId_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'userId_ASC'
  | 'userId_DESC';

/** DiscountRedemptions subscription payload */
export type DiscountRedemptionPayload = {
  __typename?: 'DiscountRedemptionPayload';
  mutation: MutationType;
  node?: Maybe<DiscountRedemption>;
  previousValues?: Maybe<DiscountRedemption>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DiscountRedemptionRelationFilter = {
  every?: InputMaybe<DiscountRedemptionFilter>;
  none?: InputMaybe<DiscountRedemptionFilter>;
  some?: InputMaybe<DiscountRedemptionFilter>;
};

export type DiscountRedemptionSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  discount?: InputMaybe<DiscountSort>;
  discountId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
  userId?: InputMaybe<SortOrder>;
};

/** DiscountRedemptions subscription filter */
export type DiscountRedemptionSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<DiscountRedemptionFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** DiscountRedemptions update input */
export type DiscountRedemptionUpdateByFilterInput = {
  discountId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  userId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** DiscountRedemptions update input */
export type DiscountRedemptionUpdateInput = {
  discount?: InputMaybe<DiscountRedemptionsDiscountUpdateRelationInput>;
  discountId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  school?: InputMaybe<DiscountRedemptionsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<DiscountRedemptionsUserUpdateRelationInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DiscountRedemption_PermissionFilter = {
  AND?: InputMaybe<Array<DiscountRedemption_PermissionFilter>>;
  OR?: InputMaybe<Array<DiscountRedemption_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  discount?: InputMaybe<Discount_PermissionFilter>;
  discountId?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
  userId?: InputMaybe<StringPredicate>;
};

export type DiscountRedemption_PermissionRelationFilter = {
  every?: InputMaybe<DiscountRedemption_PermissionFilter>;
  none?: InputMaybe<DiscountRedemption_PermissionFilter>;
  some?: InputMaybe<DiscountRedemption_PermissionFilter>;
};

/** DiscountRedemptions relation input */
export type DiscountRedemptionsDiscountManyRelationInput = {
  connect?: InputMaybe<DiscountKeyFilter>;
};

/** DiscountRedemptions relation input */
export type DiscountRedemptionsDiscountRelationInput = {
  connect?: InputMaybe<DiscountKeyFilter>;
  create?: InputMaybe<Redemptions_DiscountCreateInput>;
};

/** DiscountRedemptions relation input */
export type DiscountRedemptionsDiscountUpdateRelationInput = {
  connect?: InputMaybe<DiscountKeyFilter>;
  create?: InputMaybe<Redemptions_DiscountCreateInput>;
  disconnect?: InputMaybe<DiscountKeyFilter>;
  reconnect?: InputMaybe<DiscountKeyFilter>;
  update?: InputMaybe<Redemptions_DiscountUpdateInput>;
};

/** DiscountRedemptions relation input */
export type DiscountRedemptionsSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** DiscountRedemptions relation input */
export type DiscountRedemptionsSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<DiscountRedemptions_SchoolConfigurationCreateInput>;
};

/** DiscountRedemptions relation input */
export type DiscountRedemptionsSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<DiscountRedemptions_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<DiscountRedemptions_SchoolConfigurationUpdateInput>;
};

/** DiscountRedemptions relation input */
export type DiscountRedemptionsUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** DiscountRedemptions relation input */
export type DiscountRedemptionsUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<DiscountRedemptions_UserCreateInput>;
};

/** DiscountRedemptions relation input */
export type DiscountRedemptionsUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<DiscountRedemptions_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<DiscountRedemptions_UserUpdateInput>;
};

/** SchoolConfiguration create input from discountRedemptions */
export type DiscountRedemptions_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from discountRedemptions */
export type DiscountRedemptions_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** Users create input from discountRedemptions */
export type DiscountRedemptions_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from discountRedemptions */
export type DiscountRedemptions_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

export type DiscountRelationFilter = {
  every?: InputMaybe<DiscountFilter>;
  none?: InputMaybe<DiscountFilter>;
  some?: InputMaybe<DiscountFilter>;
};

export type DiscountSort = {
  advertising?: InputMaybe<AdvertisingSort>;
  amountPercent?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  expirationDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isSchoolDiscount?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationSort>;
  payment?: InputMaybe<PaymentTransactionSort>;
  startDate?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  terms?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalClicks?: InputMaybe<SortOrder>;
  totalSpent?: InputMaybe<SortOrder>;
  totalViews?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  usersRedeemedCount?: InputMaybe<SortOrder>;
};

/** Discounts subscription filter */
export type DiscountSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<DiscountFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** Discounts update input */
export type DiscountUpdateByFilterInput = {
  amountPercent?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  category?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  expirationDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  isSchoolDiscount?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  startDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  terms?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  timezone?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  title?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  totalClicks?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  totalSpent?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  totalViews?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  type?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  usersRedeemedCount?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
};

/** Discounts update input */
export type DiscountUpdateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingUpdateRelationInput>;
  amountPercent?: InputMaybe<Scalars['Int']>;
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsUpdateRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostUpdateRelationInput>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<DiscountsImagesUpdateRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationUpdateRelationInput>;
  payment?: InputMaybe<DiscountsPaymentUpdateRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsUpdateRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsUpdateRelationInput>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  terms?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedUpdateRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

export type DiscountUpdateStatusRequestInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  status: Scalars['String'];
};

/** DiscountRedemptions create input from discount */
export type Discount_DiscountRedemptionCreateInput = {
  discount?: InputMaybe<DiscountRedemptionsDiscountRelationInput>;
  discountId?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<DiscountRedemptionsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<DiscountRedemptionsUserRelationInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** DiscountRedemptions update input from discount */
export type Discount_DiscountRedemptionUpdateInput = {
  data: DiscountRedemptionUpdateInput;
  filter?: InputMaybe<DiscountRedemptionKeyFilter>;
};

/** HomeFeedPosts create input from discount */
export type Discount_HomeFeedPostCreateInput = {
  author?: InputMaybe<HomeFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<HomeFeedPostsDiscountRelationInput>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<HomeFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<HomeFeedPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<HomeFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<HomeFeedPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<HomeFeedPostsSchoolsRelationInput>;
  /**
   * Approved - post is displayed
   * Pending - default status, awaits auto-approval/approved by admins. Not displayed
   * Rejected - auto-rejected/rejected by admins
   * reportReviewPending - post got flagged, pending review
   */
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** HomeFeedPosts update input from discount */
export type Discount_HomeFeedPostUpdateInput = {
  data: HomeFeedPostUpdateInput;
  filter?: InputMaybe<HomeFeedPostKeyFilter>;
};

export type Discount_PermissionFilter = {
  AND?: InputMaybe<Array<Discount_PermissionFilter>>;
  OR?: InputMaybe<Array<Discount_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  advertising?: InputMaybe<Advertising_PermissionFilter>;
  amountPercent?: InputMaybe<IntPredicate>;
  billingRegisteredDiscounts?: InputMaybe<BillingRegisteredSpecialOffer_PermissionRelationFilter>;
  category?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  discountRedeemPost?: InputMaybe<HomeFeedPost_PermissionRelationFilter>;
  expirationDate?: InputMaybe<DateTimePredicate>;
  id?: InputMaybe<IdPredicate>;
  images?: InputMaybe<File_PermissionRelationFilter>;
  isSchoolDiscount?: InputMaybe<BoolPredicate>;
  organization?: InputMaybe<Organization_PermissionFilter>;
  payment?: InputMaybe<PaymentTransaction_PermissionFilter>;
  redemptions?: InputMaybe<DiscountRedemption_PermissionRelationFilter>;
  schools?: InputMaybe<SchoolConfiguration_PermissionRelationFilter>;
  startDate?: InputMaybe<DateTimePredicate>;
  status?: InputMaybe<StringPredicate>;
  terms?: InputMaybe<StringPredicate>;
  timezone?: InputMaybe<StringPredicate>;
  title?: InputMaybe<StringPredicate>;
  totalClicks?: InputMaybe<IntPredicate>;
  totalSpent?: InputMaybe<IntPredicate>;
  totalViews?: InputMaybe<IntPredicate>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  usersRedeemed?: InputMaybe<User_PermissionRelationFilter>;
  usersRedeemedCount?: InputMaybe<IntPredicate>;
};

export type Discount_PermissionRelationFilter = {
  every?: InputMaybe<Discount_PermissionFilter>;
  none?: InputMaybe<Discount_PermissionFilter>;
  some?: InputMaybe<Discount_PermissionFilter>;
};

/** Discounts relation input */
export type DiscountsAdvertisingManyRelationInput = {
  connect?: InputMaybe<AdvertisingKeyFilter>;
};

/** Discounts relation input */
export type DiscountsAdvertisingRelationInput = {
  connect?: InputMaybe<AdvertisingKeyFilter>;
  create?: InputMaybe<AdvertisingSpecialOffers_AdvertisingCreateInput>;
};

/** Discounts relation input */
export type DiscountsAdvertisingUpdateRelationInput = {
  connect?: InputMaybe<AdvertisingKeyFilter>;
  create?: InputMaybe<AdvertisingSpecialOffers_AdvertisingCreateInput>;
  disconnect?: InputMaybe<AdvertisingKeyFilter>;
  reconnect?: InputMaybe<AdvertisingKeyFilter>;
  update?: InputMaybe<AdvertisingSpecialOffers_AdvertisingUpdateInput>;
};

/** Discounts relation input */
export type DiscountsBillingRegisteredDiscountsManyRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredSpecialOfferKeyFilter>>;
};

/** Discounts relation input */
export type DiscountsBillingRegisteredDiscountsRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredSpecialOfferKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<SpecialOffer_BillingRegisteredSpecialOfferCreateInput>>>;
};

/** Discounts relation input */
export type DiscountsBillingRegisteredDiscountsUpdateRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredSpecialOfferKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<SpecialOffer_BillingRegisteredSpecialOfferCreateInput>>>;
  disconnect?: InputMaybe<Array<BillingRegisteredSpecialOfferKeyFilter>>;
  reconnect?: InputMaybe<Array<BillingRegisteredSpecialOfferKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<SpecialOffer_BillingRegisteredSpecialOfferUpdateInput>>>;
};

/** Discounts relation input */
export type DiscountsDiscountRedeemPostManyRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
};

/** Discounts relation input */
export type DiscountsDiscountRedeemPostRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Discount_HomeFeedPostCreateInput>>>;
};

/** Discounts relation input */
export type DiscountsDiscountRedeemPostUpdateRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Discount_HomeFeedPostCreateInput>>>;
  disconnect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  reconnect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Discount_HomeFeedPostUpdateInput>>>;
};

/** Discounts relation input */
export type DiscountsImagesManyRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
};

/** Discounts relation input */
export type DiscountsImagesRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Discounts_Images_FileCreateInput>>>;
};

/** Discounts relation input */
export type DiscountsImagesUpdateRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Discounts_Images_FileCreateInput>>>;
  disconnect?: InputMaybe<Array<FileKeyFilter>>;
  reconnect?: InputMaybe<Array<FileKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Discounts_Images_FileUpdateInput>>>;
};

/** Discounts relation input */
export type DiscountsOrganizationManyRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
};

/** Discounts relation input */
export type DiscountsOrganizationRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<Discounts_OrganizationCreateInput>;
};

/** Discounts relation input */
export type DiscountsOrganizationUpdateRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<Discounts_OrganizationCreateInput>;
  disconnect?: InputMaybe<OrganizationKeyFilter>;
  reconnect?: InputMaybe<OrganizationKeyFilter>;
  update?: InputMaybe<Discounts_OrganizationUpdateInput>;
};

/** Discounts relation input */
export type DiscountsPaymentManyRelationInput = {
  connect?: InputMaybe<PaymentTransactionKeyFilter>;
};

/** Discounts relation input */
export type DiscountsPaymentRelationInput = {
  connect?: InputMaybe<PaymentTransactionKeyFilter>;
  create?: InputMaybe<Discounts_PaymentTransactionCreateInput>;
};

/** Discounts relation input */
export type DiscountsPaymentUpdateRelationInput = {
  connect?: InputMaybe<PaymentTransactionKeyFilter>;
  create?: InputMaybe<Discounts_PaymentTransactionCreateInput>;
  disconnect?: InputMaybe<PaymentTransactionKeyFilter>;
  reconnect?: InputMaybe<PaymentTransactionKeyFilter>;
  update?: InputMaybe<Discounts_PaymentTransactionUpdateInput>;
};

/** Discounts relation input */
export type DiscountsRedemptionsManyRelationInput = {
  connect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
};

/** Discounts relation input */
export type DiscountsRedemptionsRelationInput = {
  connect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Discount_DiscountRedemptionCreateInput>>>;
};

/** Discounts relation input */
export type DiscountsRedemptionsUpdateRelationInput = {
  connect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Discount_DiscountRedemptionCreateInput>>>;
  disconnect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
  reconnect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Discount_DiscountRedemptionUpdateInput>>>;
};

/** Discounts relation input */
export type DiscountsSchoolsManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
};

/** Discounts relation input */
export type DiscountsSchoolsRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Offers_SchoolConfigurationCreateInput>>>;
};

/** Discounts relation input */
export type DiscountsSchoolsUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Offers_SchoolConfigurationCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Offers_SchoolConfigurationUpdateInput>>>;
};

/** Discounts relation input */
export type DiscountsUsersRedeemedManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** Discounts relation input */
export type DiscountsUsersRedeemedRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<RedeemedDiscounts_UserCreateInput>>>;
};

/** Discounts relation input */
export type DiscountsUsersRedeemedUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<RedeemedDiscounts_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<RedeemedDiscounts_UserUpdateInput>>>;
};

/** Organizations create input from discounts */
export type Discounts_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from discounts */
export type Discounts_OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

/** PaymentTransactions create input from discounts */
export type Discounts_PaymentTransactionCreateInput = {
  discounts?: InputMaybe<PaymentTransactionsDiscountsRelationInput>;
  invoiceId?: InputMaybe<Scalars['String']>;
  invoiceStatus?: InputMaybe<Scalars['String']>;
  /** Stripe method id */
  paymentMethodId: Scalars['String'];
};

/** PaymentTransactions update input from discounts */
export type Discounts_PaymentTransactionUpdateInput = {
  discounts?: InputMaybe<PaymentTransactionsDiscountsUpdateRelationInput>;
  invoiceId?: InputMaybe<Scalars['String']>;
  invoiceStatus?: InputMaybe<Scalars['String']>;
  /** Stripe method id */
  paymentMethodId?: InputMaybe<Scalars['String']>;
};

/** Files create input from discounts_images */
export type Discounts_Images_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from discounts_images */
export type Discounts_Images_FileUpdateInput = {
  data: FileUpdateInput;
  filter?: InputMaybe<FileKeyFilter>;
};

/** Users create input from education */
export type Education_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from education */
export type Education_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

export type EducationalInstitution = {
  __typename?: 'EducationalInstitution';
  _description?: Maybe<Scalars['String']>;
  activities?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  degree?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  fieldOfStudy?: Maybe<Scalars['String']>;
  gpa?: Maybe<Scalars['Float']>;
  /** Relationship between the school and the user's education experience */
  highSchool?: Maybe<HighSchoolLocation>;
  highSchoolId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** Store the image of the education history */
  image?: Maybe<File>;
  imageId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

/** EducationalInstitutions create input */
export type EducationalInstitutionCreateInput = {
  activities?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<EducationalInstitutionsAddressRelationInput>;
  degree?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  fieldOfStudy?: InputMaybe<Scalars['String']>;
  gpa?: InputMaybe<Scalars['Float']>;
  highSchool?: InputMaybe<EducationalInstitutionsHighSchoolRelationInput>;
  highSchoolId?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<EducationalInstitutionsImageRelationInput>;
  imageId?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
  user?: InputMaybe<EducationalInstitutionsUserRelationInput>;
};

/** EducationalInstitutions create many input */
export type EducationalInstitutionCreateManyInput = {
  activities?: InputMaybe<Scalars['String']>;
  address: EducationalInstitutionsAddressManyRelationInput;
  degree?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  fieldOfStudy?: InputMaybe<Scalars['String']>;
  gpa?: InputMaybe<Scalars['Float']>;
  highSchool?: InputMaybe<EducationalInstitutionsHighSchoolManyRelationInput>;
  highSchoolId?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<EducationalInstitutionsImageManyRelationInput>;
  imageId?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
  user?: InputMaybe<EducationalInstitutionsUserManyRelationInput>;
};

/** EducationalInstitutions delete input */
export type EducationalInstitutionDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** EducationalInstitutionFieldsPermissions create input */
export type EducationalInstitutionFieldsPermissions = {
  activities?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  degree?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  fieldOfStudy?: InputMaybe<Scalars['Boolean']>;
  gpa?: InputMaybe<Scalars['Boolean']>;
  highSchoolId?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type EducationalInstitutionFilter = {
  AND?: InputMaybe<Array<EducationalInstitutionFilter>>;
  OR?: InputMaybe<Array<EducationalInstitutionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  activities?: InputMaybe<StringPredicate>;
  address?: InputMaybe<AddressFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  degree?: InputMaybe<StringPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  endDate?: InputMaybe<DatePredicate>;
  fieldOfStudy?: InputMaybe<StringPredicate>;
  gpa?: InputMaybe<FloatPredicate>;
  highSchool?: InputMaybe<HighSchoolLocationFilter>;
  highSchoolId?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  image?: InputMaybe<FileFilter>;
  imageId?: InputMaybe<StringPredicate>;
  startDate?: InputMaybe<DatePredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type EducationalInstitutionGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: EducationalInstitutionGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type EducationalInstitutionGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  activities?: InputMaybe<Array<GroupByField>>;
  address?: InputMaybe<AddressGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  degree?: InputMaybe<Array<GroupByField>>;
  description?: InputMaybe<Array<GroupByField>>;
  endDate?: InputMaybe<Array<GroupByField>>;
  fieldOfStudy?: InputMaybe<Array<GroupByField>>;
  gpa?: InputMaybe<Array<GroupByField>>;
  highSchool?: InputMaybe<HighSchoolLocationGroupByQuery>;
  highSchoolId?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  image?: InputMaybe<FileGroupByQuery>;
  imageId?: InputMaybe<Array<GroupByField>>;
  startDate?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type EducationalInstitutionKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** EducationalInstitutionListResponse output */
export type EducationalInstitutionListResponse = {
  __typename?: 'EducationalInstitutionListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<EducationalInstitution>;
};

/** EducationalInstitutionManyResponse output */
export type EducationalInstitutionManyResponse = {
  __typename?: 'EducationalInstitutionManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<EducationalInstitution>;
};

/** No longer supported. Use `sort` instead. */
export type EducationalInstitutionOrderBy =
  | 'activities_ASC'
  | 'activities_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'degree_ASC'
  | 'degree_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'description_ASC'
  | 'description_DESC'
  | 'endDate_ASC'
  | 'endDate_DESC'
  | 'fieldOfStudy_ASC'
  | 'fieldOfStudy_DESC'
  | 'gpa_ASC'
  | 'gpa_DESC'
  | 'highSchoolId_ASC'
  | 'highSchoolId_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'imageId_ASC'
  | 'imageId_DESC'
  | 'startDate_ASC'
  | 'startDate_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** EducationalInstitutions subscription payload */
export type EducationalInstitutionPayload = {
  __typename?: 'EducationalInstitutionPayload';
  mutation: MutationType;
  node?: Maybe<EducationalInstitution>;
  previousValues?: Maybe<EducationalInstitution>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EducationalInstitutionRelationFilter = {
  every?: InputMaybe<EducationalInstitutionFilter>;
  none?: InputMaybe<EducationalInstitutionFilter>;
  some?: InputMaybe<EducationalInstitutionFilter>;
};

export type EducationalInstitutionSort = {
  activities?: InputMaybe<SortOrder>;
  address?: InputMaybe<AddressSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  degree?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  fieldOfStudy?: InputMaybe<SortOrder>;
  gpa?: InputMaybe<SortOrder>;
  highSchool?: InputMaybe<HighSchoolLocationSort>;
  highSchoolId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<FileSort>;
  imageId?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

/** EducationalInstitutions subscription filter */
export type EducationalInstitutionSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<EducationalInstitutionFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** EducationalInstitutions update input */
export type EducationalInstitutionUpdateByFilterInput = {
  activities?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  degree?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  description?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  endDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateInput>>>;
  fieldOfStudy?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  gpa?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  highSchoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  imageId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  startDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateInput>>>;
};

/** EducationalInstitutions update input */
export type EducationalInstitutionUpdateInput = {
  activities?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<EducationalInstitutionsAddressUpdateRelationInput>;
  degree?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  fieldOfStudy?: InputMaybe<Scalars['String']>;
  gpa?: InputMaybe<Scalars['Float']>;
  highSchool?: InputMaybe<EducationalInstitutionsHighSchoolUpdateRelationInput>;
  highSchoolId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<EducationalInstitutionsImageUpdateRelationInput>;
  imageId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  user?: InputMaybe<EducationalInstitutionsUserUpdateRelationInput>;
};

export type EducationalInstitution_PermissionFilter = {
  AND?: InputMaybe<Array<EducationalInstitution_PermissionFilter>>;
  OR?: InputMaybe<Array<EducationalInstitution_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  activities?: InputMaybe<StringPredicate>;
  address?: InputMaybe<Address_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  degree?: InputMaybe<StringPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  endDate?: InputMaybe<DatePredicate>;
  fieldOfStudy?: InputMaybe<StringPredicate>;
  gpa?: InputMaybe<FloatPredicate>;
  highSchool?: InputMaybe<HighSchoolLocation_PermissionFilter>;
  highSchoolId?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  image?: InputMaybe<File_PermissionFilter>;
  imageId?: InputMaybe<StringPredicate>;
  startDate?: InputMaybe<DatePredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
};

export type EducationalInstitution_PermissionRelationFilter = {
  every?: InputMaybe<EducationalInstitution_PermissionFilter>;
  none?: InputMaybe<EducationalInstitution_PermissionFilter>;
  some?: InputMaybe<EducationalInstitution_PermissionFilter>;
};

/** EducationalInstitutions relation input */
export type EducationalInstitutionsAddressManyRelationInput = {
  connect?: InputMaybe<AddressKeyFilter>;
};

/** EducationalInstitutions relation input */
export type EducationalInstitutionsAddressRelationInput = {
  connect?: InputMaybe<AddressKeyFilter>;
  create?: InputMaybe<EducationalInstitutions_AddressCreateInput>;
};

/** EducationalInstitutions relation input */
export type EducationalInstitutionsAddressUpdateRelationInput = {
  connect?: InputMaybe<AddressKeyFilter>;
  create?: InputMaybe<EducationalInstitutions_AddressCreateInput>;
  disconnect?: InputMaybe<AddressKeyFilter>;
  reconnect?: InputMaybe<AddressKeyFilter>;
  update?: InputMaybe<EducationalInstitutions_AddressUpdateInput>;
};

/** EducationalInstitutions relation input */
export type EducationalInstitutionsHighSchoolManyRelationInput = {
  connect?: InputMaybe<HighSchoolLocationKeyFilter>;
};

/** EducationalInstitutions relation input */
export type EducationalInstitutionsHighSchoolRelationInput = {
  connect?: InputMaybe<HighSchoolLocationKeyFilter>;
  create?: InputMaybe<Educations_HighSchoolLocationCreateInput>;
};

/** EducationalInstitutions relation input */
export type EducationalInstitutionsHighSchoolUpdateRelationInput = {
  connect?: InputMaybe<HighSchoolLocationKeyFilter>;
  create?: InputMaybe<Educations_HighSchoolLocationCreateInput>;
  disconnect?: InputMaybe<HighSchoolLocationKeyFilter>;
  reconnect?: InputMaybe<HighSchoolLocationKeyFilter>;
  update?: InputMaybe<Educations_HighSchoolLocationUpdateInput>;
};

/** EducationalInstitutions relation input */
export type EducationalInstitutionsImageManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** EducationalInstitutions relation input */
export type EducationalInstitutionsImageRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<EducationalInstitutions_Image_FileCreateInput>;
};

/** EducationalInstitutions relation input */
export type EducationalInstitutionsImageUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<EducationalInstitutions_Image_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<EducationalInstitutions_Image_FileUpdateInput>;
};

/** EducationalInstitutions relation input */
export type EducationalInstitutionsUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** EducationalInstitutions relation input */
export type EducationalInstitutionsUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<Education_UserCreateInput>;
};

/** EducationalInstitutions relation input */
export type EducationalInstitutionsUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<Education_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<Education_UserUpdateInput>;
};

/** Addresses create input from educationalInstitutions */
export type EducationalInstitutions_AddressCreateInput = {
  city?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<AddressesConfigurationRelationInput>;
  educationalInstitutions?: InputMaybe<AddressesEducationalInstitutionsRelationInput>;
  events?: InputMaybe<AddressesEventsRelationInput>;
  organizations?: InputMaybe<AddressesOrganizationsRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  usersAdresses?: InputMaybe<AddressesUsersAdressesRelationInput>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Addresses update input from educationalInstitutions */
export type EducationalInstitutions_AddressUpdateInput = {
  city?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<AddressesConfigurationUpdateRelationInput>;
  educationalInstitutions?: InputMaybe<AddressesEducationalInstitutionsUpdateRelationInput>;
  events?: InputMaybe<AddressesEventsUpdateRelationInput>;
  organizations?: InputMaybe<AddressesOrganizationsUpdateRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  usersAdresses?: InputMaybe<AddressesUsersAdressesUpdateRelationInput>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Files create input from educationalInstitutions_image */
export type EducationalInstitutions_Image_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from educationalInstitutions_image */
export type EducationalInstitutions_Image_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** HighSchoolLocations create input from educations */
export type Educations_HighSchoolLocationCreateInput = {
  audiences?: InputMaybe<HighSchoolLocationsAudiencesRelationInput>;
  city?: InputMaybe<Scalars['String']>;
  educations?: InputMaybe<HighSchoolLocationsEducationsRelationInput>;
  logo?: InputMaybe<HighSchoolLocationsLogoRelationInput>;
  logoId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** HighSchoolLocations update input from educations */
export type Educations_HighSchoolLocationUpdateInput = {
  audiences?: InputMaybe<HighSchoolLocationsAudiencesUpdateRelationInput>;
  city?: InputMaybe<Scalars['String']>;
  educations?: InputMaybe<HighSchoolLocationsEducationsUpdateRelationInput>;
  logo?: InputMaybe<HighSchoolLocationsLogoUpdateRelationInput>;
  logoId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** SchoolConfigurationImages create input from emailBanner */
export type EmailBanner_SchoolConfigurationImageCreateInput = {
  applicationLogo?: InputMaybe<SchoolConfigurationImagesApplicationLogoRelationInput>;
  applicationLogoId?: InputMaybe<Scalars['String']>;
  browserLogo?: InputMaybe<SchoolConfigurationImagesBrowserLogoRelationInput>;
  browserLogoId?: InputMaybe<Scalars['String']>;
  configuration: SchoolConfigurationImagesConfigurationRelationInput;
  emailBanner?: InputMaybe<SchoolConfigurationImagesEmailBannerRelationInput>;
  emailLogo?: InputMaybe<SchoolConfigurationImagesEmailLogoRelationInput>;
  logo?: InputMaybe<SchoolConfigurationImagesLogoRelationInput>;
  splashImage?: InputMaybe<SchoolConfigurationImagesSplashImageRelationInput>;
};

/** SchoolConfigurationImages update input from emailBanner */
export type EmailBanner_SchoolConfigurationImageUpdateInput = {
  data: SchoolConfigurationImageUpdateInput;
  filter?: InputMaybe<SchoolConfigurationImageKeyFilter>;
};

/** SchoolConfigurationImages create input from emailLogo */
export type EmailLogo_SchoolConfigurationImageCreateInput = {
  applicationLogo?: InputMaybe<SchoolConfigurationImagesApplicationLogoRelationInput>;
  applicationLogoId?: InputMaybe<Scalars['String']>;
  browserLogo?: InputMaybe<SchoolConfigurationImagesBrowserLogoRelationInput>;
  browserLogoId?: InputMaybe<Scalars['String']>;
  configuration: SchoolConfigurationImagesConfigurationRelationInput;
  emailBanner?: InputMaybe<SchoolConfigurationImagesEmailBannerRelationInput>;
  emailLogo?: InputMaybe<SchoolConfigurationImagesEmailLogoRelationInput>;
  logo?: InputMaybe<SchoolConfigurationImagesLogoRelationInput>;
  splashImage?: InputMaybe<SchoolConfigurationImagesSplashImageRelationInput>;
};

/** SchoolConfigurationImages update input from emailLogo */
export type EmailLogo_SchoolConfigurationImageUpdateInput = {
  data: SchoolConfigurationImageUpdateInput;
  filter?: InputMaybe<SchoolConfigurationImageKeyFilter>;
};

export type EnvironmentBackupItem = {
  __typename?: 'EnvironmentBackupItem';
  name: Scalars['String'];
  size: Scalars['Float'];
};

export type EnvironmentItem = {
  __typename?: 'EnvironmentItem';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** EnvironmentSetupInput */
export type EnvironmentSetupInput = {
  deleteLock?: InputMaybe<Scalars['Boolean']>;
  introspection?: InputMaybe<Scalars['Boolean']>;
};

export type EnvironmentVariable = {
  __typename?: 'EnvironmentVariable';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

/** EnvironmentVariables create input */
export type EnvironmentVariableCreateInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

/** EnvironmentVariables create many input */
export type EnvironmentVariableCreateManyInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

/** EnvironmentVariables delete input */
export type EnvironmentVariableDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type EnvironmentVariableFilter = {
  AND?: InputMaybe<Array<EnvironmentVariableFilter>>;
  OR?: InputMaybe<Array<EnvironmentVariableFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  name?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  value?: InputMaybe<StringPredicate>;
};

export type EnvironmentVariableGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: EnvironmentVariableGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type EnvironmentVariableGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  value?: InputMaybe<Array<GroupByField>>;
};

export type EnvironmentVariableKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** EnvironmentVariableListResponse output */
export type EnvironmentVariableListResponse = {
  __typename?: 'EnvironmentVariableListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<EnvironmentVariable>;
};

/** EnvironmentVariableManyResponse output */
export type EnvironmentVariableManyResponse = {
  __typename?: 'EnvironmentVariableManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<EnvironmentVariable>;
};

/** No longer supported. Use `sort` instead. */
export type EnvironmentVariableOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'value_ASC'
  | 'value_DESC';

/** EnvironmentVariables subscription payload */
export type EnvironmentVariablePayload = {
  __typename?: 'EnvironmentVariablePayload';
  mutation: MutationType;
  node?: Maybe<EnvironmentVariable>;
  previousValues?: Maybe<EnvironmentVariable>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EnvironmentVariableSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

/** EnvironmentVariables subscription filter */
export type EnvironmentVariableSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<EnvironmentVariableFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** EnvironmentVariables update input */
export type EnvironmentVariableUpdateByFilterInput = {
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  value?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** EnvironmentVariables update input */
export type EnvironmentVariableUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  _description?: Maybe<Scalars['String']>;
  author?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<FileListResponse>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  location?: Maybe<Address>;
  otherSponsors?: Maybe<OrganizationListResponse>;
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  sponsorRequests?: Maybe<EventSponsorRequestListResponse>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleSponsor?: Maybe<Organization>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EventImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FileSort>>;
};

export type EventOtherSponsorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationSort>>;
};

export type EventSponsorRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EventSponsorRequestFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EventSponsorRequestGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EventSponsorRequestOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EventSponsorRequestSort>>;
};

/** Events create input */
export type EventCreateInput = {
  author?: InputMaybe<EventsAuthorRelationInput>;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<EventsImagesRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<EventsLocationRelationInput>;
  otherSponsors?: InputMaybe<EventsOtherSponsorsRelationInput>;
  school?: InputMaybe<EventsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  sponsorRequests?: InputMaybe<EventsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleSponsor?: InputMaybe<EventsTitleSponsorRelationInput>;
};

/** Events create many input */
export type EventCreateManyInput = {
  author: EventsAuthorManyRelationInput;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<EventsImagesManyRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<EventsLocationManyRelationInput>;
  otherSponsors?: InputMaybe<EventsOtherSponsorsManyRelationInput>;
  school?: InputMaybe<EventsSchoolManyRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  sponsorRequests?: InputMaybe<EventsSponsorRequestsManyRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleSponsor?: InputMaybe<EventsTitleSponsorManyRelationInput>;
};

/** Events delete input */
export type EventDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** EventFieldsPermissions create input */
export type EventFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type EventFilter = {
  AND?: InputMaybe<Array<EventFilter>>;
  OR?: InputMaybe<Array<EventFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<UserFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  date?: InputMaybe<DateTimePredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  images?: InputMaybe<FileRelationFilter>;
  isApproved?: InputMaybe<BoolPredicate>;
  link?: InputMaybe<StringPredicate>;
  location?: InputMaybe<AddressFilter>;
  otherSponsors?: InputMaybe<OrganizationRelationFilter>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  sponsorRequests?: InputMaybe<EventSponsorRequestRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  timezone?: InputMaybe<StringPredicate>;
  title?: InputMaybe<StringPredicate>;
  titleSponsor?: InputMaybe<OrganizationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type EventGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: EventGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type EventGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  author?: InputMaybe<UserGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  date?: InputMaybe<Array<GroupByField>>;
  description?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  images?: InputMaybe<FileGroupByQuery>;
  isApproved?: InputMaybe<Array<GroupByField>>;
  link?: InputMaybe<Array<GroupByField>>;
  location?: InputMaybe<AddressGroupByQuery>;
  otherSponsors?: InputMaybe<OrganizationGroupByQuery>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  sponsorRequests?: InputMaybe<EventSponsorRequestGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  timezone?: InputMaybe<Array<GroupByField>>;
  title?: InputMaybe<Array<GroupByField>>;
  titleSponsor?: InputMaybe<OrganizationGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type EventKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** EventListResponse output */
export type EventListResponse = {
  __typename?: 'EventListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Event>;
};

/** EventManyResponse output */
export type EventManyResponse = {
  __typename?: 'EventManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Event>;
};

/** No longer supported. Use `sort` instead. */
export type EventOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'date_ASC'
  | 'date_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'description_ASC'
  | 'description_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isApproved_ASC'
  | 'isApproved_DESC'
  | 'link_ASC'
  | 'link_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'timezone_ASC'
  | 'timezone_DESC'
  | 'title_ASC'
  | 'title_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** Organizations create input from eventOtherSponsored */
export type EventOtherSponsored_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from eventOtherSponsored */
export type EventOtherSponsored_OrganizationUpdateInput = {
  data: OrganizationUpdateInput;
  filter?: InputMaybe<OrganizationKeyFilter>;
};

/** Events subscription payload */
export type EventPayload = {
  __typename?: 'EventPayload';
  mutation: MutationType;
  node?: Maybe<Event>;
  previousValues?: Maybe<Event>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EventRelationFilter = {
  every?: InputMaybe<EventFilter>;
  none?: InputMaybe<EventFilter>;
  some?: InputMaybe<EventFilter>;
};

export type EventSort = {
  author?: InputMaybe<UserSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  date?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isApproved?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  location?: InputMaybe<AddressSort>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  titleSponsor?: InputMaybe<OrganizationSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EventSponsorRequest = {
  __typename?: 'EventSponsorRequest';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  event?: Maybe<Event>;
  id?: Maybe<Scalars['ID']>;
  sponsor?: Maybe<Organization>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** EventSponsorRequests create input */
export type EventSponsorRequestCreateInput = {
  event?: InputMaybe<EventSponsorRequestsEventRelationInput>;
  sponsor?: InputMaybe<EventSponsorRequestsSponsorRelationInput>;
  status: Scalars['String'];
};

/** EventSponsorRequests create many input */
export type EventSponsorRequestCreateManyInput = {
  event: EventSponsorRequestsEventManyRelationInput;
  sponsor: EventSponsorRequestsSponsorManyRelationInput;
  status: Scalars['String'];
};

/** EventSponsorRequests delete input */
export type EventSponsorRequestDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** EventSponsorRequestFieldsPermissions create input */
export type EventSponsorRequestFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type EventSponsorRequestFilter = {
  AND?: InputMaybe<Array<EventSponsorRequestFilter>>;
  OR?: InputMaybe<Array<EventSponsorRequestFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  event?: InputMaybe<EventFilter>;
  id?: InputMaybe<IdPredicate>;
  sponsor?: InputMaybe<OrganizationFilter>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type EventSponsorRequestGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: EventSponsorRequestGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type EventSponsorRequestGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  event?: InputMaybe<EventGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  sponsor?: InputMaybe<OrganizationGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type EventSponsorRequestKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** EventSponsorRequestListResponse output */
export type EventSponsorRequestListResponse = {
  __typename?: 'EventSponsorRequestListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<EventSponsorRequest>;
};

/** EventSponsorRequestManyResponse output */
export type EventSponsorRequestManyResponse = {
  __typename?: 'EventSponsorRequestManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<EventSponsorRequest>;
};

/** No longer supported. Use `sort` instead. */
export type EventSponsorRequestOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** EventSponsorRequests subscription payload */
export type EventSponsorRequestPayload = {
  __typename?: 'EventSponsorRequestPayload';
  mutation: MutationType;
  node?: Maybe<EventSponsorRequest>;
  previousValues?: Maybe<EventSponsorRequest>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EventSponsorRequestRelationFilter = {
  every?: InputMaybe<EventSponsorRequestFilter>;
  none?: InputMaybe<EventSponsorRequestFilter>;
  some?: InputMaybe<EventSponsorRequestFilter>;
};

export type EventSponsorRequestSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  event?: InputMaybe<EventSort>;
  id?: InputMaybe<SortOrder>;
  sponsor?: InputMaybe<OrganizationSort>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** EventSponsorRequests subscription filter */
export type EventSponsorRequestSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<EventSponsorRequestFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** EventSponsorRequests update input */
export type EventSponsorRequestUpdateByFilterInput = {
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
};

/** EventSponsorRequests update input */
export type EventSponsorRequestUpdateInput = {
  event?: InputMaybe<EventSponsorRequestsEventUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  sponsor?: InputMaybe<EventSponsorRequestsSponsorUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
};

export type EventSponsorRequest_PermissionFilter = {
  AND?: InputMaybe<Array<EventSponsorRequest_PermissionFilter>>;
  OR?: InputMaybe<Array<EventSponsorRequest_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  event?: InputMaybe<Event_PermissionFilter>;
  id?: InputMaybe<IdPredicate>;
  sponsor?: InputMaybe<Organization_PermissionFilter>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type EventSponsorRequest_PermissionRelationFilter = {
  every?: InputMaybe<EventSponsorRequest_PermissionFilter>;
  none?: InputMaybe<EventSponsorRequest_PermissionFilter>;
  some?: InputMaybe<EventSponsorRequest_PermissionFilter>;
};

/** EventSponsorRequests relation input */
export type EventSponsorRequestsEventManyRelationInput = {
  connect?: InputMaybe<EventKeyFilter>;
};

/** EventSponsorRequests relation input */
export type EventSponsorRequestsEventRelationInput = {
  connect?: InputMaybe<EventKeyFilter>;
  create?: InputMaybe<SponsorRequests_EventCreateInput>;
};

/** EventSponsorRequests relation input */
export type EventSponsorRequestsEventUpdateRelationInput = {
  connect?: InputMaybe<EventKeyFilter>;
  create?: InputMaybe<SponsorRequests_EventCreateInput>;
  disconnect?: InputMaybe<EventKeyFilter>;
  reconnect?: InputMaybe<EventKeyFilter>;
  update?: InputMaybe<SponsorRequests_EventUpdateInput>;
};

/** EventSponsorRequests relation input */
export type EventSponsorRequestsSponsorManyRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
};

/** EventSponsorRequests relation input */
export type EventSponsorRequestsSponsorRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<SponsorRequests_OrganizationCreateInput>;
};

/** EventSponsorRequests relation input */
export type EventSponsorRequestsSponsorUpdateRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<SponsorRequests_OrganizationCreateInput>;
  disconnect?: InputMaybe<OrganizationKeyFilter>;
  reconnect?: InputMaybe<OrganizationKeyFilter>;
  update?: InputMaybe<SponsorRequests_OrganizationUpdateInput>;
};

export type EventStatusUpdateRequestInput = {
  id: Scalars['ID'];
  isApproved: Scalars['Boolean'];
  status: Scalars['String'];
};

/** Events subscription filter */
export type EventSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<EventFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** Organizations create input from eventTitleSponsored */
export type EventTitleSponsored_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from eventTitleSponsored */
export type EventTitleSponsored_OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

/** Events update input */
export type EventUpdateByFilterInput = {
  date?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  description?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  isApproved?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  link?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  timezone?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  title?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** Events update input */
export type EventUpdateInput = {
  author?: InputMaybe<EventsAuthorUpdateRelationInput>;
  date?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<EventsImagesUpdateRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<EventsLocationUpdateRelationInput>;
  otherSponsors?: InputMaybe<EventsOtherSponsorsUpdateRelationInput>;
  school?: InputMaybe<EventsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  sponsorRequests?: InputMaybe<EventsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleSponsor?: InputMaybe<EventsTitleSponsorUpdateRelationInput>;
};

/** EventSponsorRequests create input from event */
export type Event_EventSponsorRequestCreateInput = {
  event?: InputMaybe<EventSponsorRequestsEventRelationInput>;
  sponsor?: InputMaybe<EventSponsorRequestsSponsorRelationInput>;
  status: Scalars['String'];
};

/** EventSponsorRequests update input from event */
export type Event_EventSponsorRequestUpdateInput = {
  data: EventSponsorRequestUpdateInput;
  filter?: InputMaybe<EventSponsorRequestKeyFilter>;
};

export type Event_PermissionFilter = {
  AND?: InputMaybe<Array<Event_PermissionFilter>>;
  OR?: InputMaybe<Array<Event_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<User_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  date?: InputMaybe<DateTimePredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  images?: InputMaybe<File_PermissionRelationFilter>;
  isApproved?: InputMaybe<BoolPredicate>;
  link?: InputMaybe<StringPredicate>;
  location?: InputMaybe<Address_PermissionFilter>;
  otherSponsors?: InputMaybe<Organization_PermissionRelationFilter>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  sponsorRequests?: InputMaybe<EventSponsorRequest_PermissionRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  timezone?: InputMaybe<StringPredicate>;
  title?: InputMaybe<StringPredicate>;
  titleSponsor?: InputMaybe<Organization_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type Event_PermissionRelationFilter = {
  every?: InputMaybe<Event_PermissionFilter>;
  none?: InputMaybe<Event_PermissionFilter>;
  some?: InputMaybe<Event_PermissionFilter>;
};

/** Events relation input */
export type EventsAuthorManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** Events relation input */
export type EventsAuthorRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<PostedEvents_UserCreateInput>;
};

/** Events relation input */
export type EventsAuthorUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<PostedEvents_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<PostedEvents_UserUpdateInput>;
};

export type EventsFilterByZipRequestResponse = {
  __typename?: 'EventsFilterByZipRequestResponse';
  count?: Maybe<Scalars['Int']>;
  items: Array<Event>;
};

/** Events relation input */
export type EventsImagesManyRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
};

/** Events relation input */
export type EventsImagesRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Events_Images_FileCreateInput>>>;
};

/** Events relation input */
export type EventsImagesUpdateRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Events_Images_FileCreateInput>>>;
  disconnect?: InputMaybe<Array<FileKeyFilter>>;
  reconnect?: InputMaybe<Array<FileKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Events_Images_FileUpdateInput>>>;
};

/** Events relation input */
export type EventsLocationManyRelationInput = {
  connect?: InputMaybe<AddressKeyFilter>;
};

/** Events relation input */
export type EventsLocationRelationInput = {
  connect?: InputMaybe<AddressKeyFilter>;
  create?: InputMaybe<Events_AddressCreateInput>;
};

/** Events relation input */
export type EventsLocationUpdateRelationInput = {
  connect?: InputMaybe<AddressKeyFilter>;
  create?: InputMaybe<Events_AddressCreateInput>;
  disconnect?: InputMaybe<AddressKeyFilter>;
  reconnect?: InputMaybe<AddressKeyFilter>;
  update?: InputMaybe<Events_AddressUpdateInput>;
};

/** Events relation input */
export type EventsOtherSponsorsManyRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
};

/** Events relation input */
export type EventsOtherSponsorsRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<EventOtherSponsored_OrganizationCreateInput>>>;
};

/** Events relation input */
export type EventsOtherSponsorsUpdateRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<EventOtherSponsored_OrganizationCreateInput>>>;
  disconnect?: InputMaybe<Array<OrganizationKeyFilter>>;
  reconnect?: InputMaybe<Array<OrganizationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<EventOtherSponsored_OrganizationUpdateInput>>>;
};

/** Events relation input */
export type EventsSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** Events relation input */
export type EventsSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<Events_SchoolConfigurationCreateInput>;
};

/** Events relation input */
export type EventsSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<Events_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<Events_SchoolConfigurationUpdateInput>;
};

/** Events relation input */
export type EventsSponsorRequestsManyRelationInput = {
  connect?: InputMaybe<Array<EventSponsorRequestKeyFilter>>;
};

/** Events relation input */
export type EventsSponsorRequestsRelationInput = {
  connect?: InputMaybe<Array<EventSponsorRequestKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Event_EventSponsorRequestCreateInput>>>;
};

/** Events relation input */
export type EventsSponsorRequestsUpdateRelationInput = {
  connect?: InputMaybe<Array<EventSponsorRequestKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Event_EventSponsorRequestCreateInput>>>;
  disconnect?: InputMaybe<Array<EventSponsorRequestKeyFilter>>;
  reconnect?: InputMaybe<Array<EventSponsorRequestKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Event_EventSponsorRequestUpdateInput>>>;
};

/** Events relation input */
export type EventsTitleSponsorManyRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
};

/** Events relation input */
export type EventsTitleSponsorRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<EventTitleSponsored_OrganizationCreateInput>;
};

/** Events relation input */
export type EventsTitleSponsorUpdateRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<EventTitleSponsored_OrganizationCreateInput>;
  disconnect?: InputMaybe<OrganizationKeyFilter>;
  reconnect?: InputMaybe<OrganizationKeyFilter>;
  update?: InputMaybe<EventTitleSponsored_OrganizationUpdateInput>;
};

/** Addresses create input from events */
export type Events_AddressCreateInput = {
  city?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<AddressesConfigurationRelationInput>;
  educationalInstitutions?: InputMaybe<AddressesEducationalInstitutionsRelationInput>;
  events?: InputMaybe<AddressesEventsRelationInput>;
  organizations?: InputMaybe<AddressesOrganizationsRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  usersAdresses?: InputMaybe<AddressesUsersAdressesRelationInput>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Addresses update input from events */
export type Events_AddressUpdateInput = {
  city?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<AddressesConfigurationUpdateRelationInput>;
  educationalInstitutions?: InputMaybe<AddressesEducationalInstitutionsUpdateRelationInput>;
  events?: InputMaybe<AddressesEventsUpdateRelationInput>;
  organizations?: InputMaybe<AddressesOrganizationsUpdateRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  usersAdresses?: InputMaybe<AddressesUsersAdressesUpdateRelationInput>;
  zip?: InputMaybe<Scalars['String']>;
};

/** SchoolConfiguration create input from events */
export type Events_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from events */
export type Events_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** Files create input from events_images */
export type Events_Images_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from events_images */
export type Events_Images_FileUpdateInput = {
  data: FileUpdateInput;
  filter?: InputMaybe<FileKeyFilter>;
};

/** SchoolConfiguration create input from externalLinks */
export type ExternalLinks_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address: SchoolConfigurationAddressRelationInput;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from externalLinks */
export type ExternalLinks_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** Facebook connection params */
export type FacebookOptions = {
  __typename?: 'FacebookOptions';
  app_id: Scalars['String'];
  app_secret: Scalars['String'];
  enabled: Scalars['Boolean'];
};

/** Facebook connection params input */
export type FacebookOptionsInput = {
  app_id: Scalars['String'];
  app_secret: Scalars['String'];
  enabled: Scalars['Boolean'];
};

/** Field Data Features */
export type FieldDataFeatures = {
  __typename?: 'FieldDataFeatures';
  create: Scalars['Boolean'];
  sort: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** Field Schema Features */
export type FieldSchemaFeatures = {
  __typename?: 'FieldSchemaFeatures';
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** Field types */
export type FieldType =
  | 'DATE'
  | 'FILE'
  | 'GEO'
  | 'ID'
  | 'JSON'
  | 'MISSING_RELATION'
  | 'NUMBER'
  | 'ONE_WAY_RELATION'
  | 'RELATION'
  | 'SMART'
  | 'SWITCH'
  | 'TEXT'
  | 'UUID';

/** Field Type Attributes */
export type FieldTypeAttributes =
  | DateFieldTypeAttributes
  | FileFieldTypeAttributes
  | GeoFieldTypeAttributes
  | MissingRelationFieldTypeAttributes
  | NumberFieldTypeAttributes
  | SmartFieldTypeAttributes
  | SwitchFieldTypeAttributes
  | TextFieldTypeAttributes
  | UuidFieldTypeAttributes;

/** Field Type Attributes Input */
export type FieldTypeAttributesInput = {
  autoIncrement?: InputMaybe<Scalars['Boolean']>;
  currency?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['Int']>;
  fieldSize?: InputMaybe<Scalars['Int']>;
  format?: InputMaybe<Scalars['String']>;
  isBigInt?: InputMaybe<Scalars['Boolean']>;
  listOptions?: InputMaybe<Array<Scalars['String']>>;
  maxSize?: InputMaybe<Scalars['Int']>;
  maxValue?: InputMaybe<Scalars['Float']>;
  minValue?: InputMaybe<Scalars['Float']>;
  precision?: InputMaybe<Scalars['Int']>;
  srid?: InputMaybe<Scalars['Int']>;
  typeRestrictions?: InputMaybe<Array<Scalars['String']>>;
};

export type File = {
  __typename?: 'File';
  _description?: Maybe<Scalars['String']>;
  advertisingCampaignAds_image?: Maybe<AdvertisingCampaignAdListResponse>;
  collectiveIntelligenceThreads_images?: Maybe<CollectiveIntelligenceThreadListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  discounts_images?: Maybe<DiscountListResponse>;
  /** No longer supported. Use `downloadUrl` instead. */
  downloadStorageUrl?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  educationalInstitutions_image?: Maybe<EducationalInstitutionListResponse>;
  events_images?: Maybe<EventListResponse>;
  /** No longer supported. Use `system.fileUploadSignInfo.AwsSignInfoResponse.fields` instead. */
  fields?: Maybe<Scalars['JSON']>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  groupPosts_media?: Maybe<GroupPostListResponse>;
  groups_logo?: Maybe<GroupListResponse>;
  highSchoolLocations_logo?: Maybe<HighSchoolLocationListResponse>;
  homeFeedPosts_media?: Maybe<HomeFeedPostListResponse>;
  id?: Maybe<Scalars['ID']>;
  inboxMessageMedia_media?: Maybe<InboxMessageMediaListResponse>;
  inboxMessages_media?: Maybe<InboxMessageListResponse>;
  jobEmployees_companyImage?: Maybe<JobEmployeeListResponse>;
  legacyFeedPosts_media?: Maybe<LegacyFeedPostListResponse>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  organizations_logo?: Maybe<OrganizationListResponse>;
  organizations_media?: Maybe<OrganizationListResponse>;
  previewUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: Maybe<SchoolConfigurationImageListResponse>;
  schoolConfigurationImages_browserLogo?: Maybe<SchoolConfigurationImageListResponse>;
  schoolConfigurationImages_emailBanner?: Maybe<SchoolConfigurationImageListResponse>;
  schoolConfigurationImages_emailLogo?: Maybe<SchoolConfigurationImageListResponse>;
  schoolConfigurationImages_logo?: Maybe<SchoolConfigurationImageListResponse>;
  schoolConfigurationImages_splashImage?: Maybe<SchoolConfigurationImageListResponse>;
  schoolNewsPosts_media?: Maybe<SchoolNewsPostListResponse>;
  settings_landingPageImage?: Maybe<SettingListResponse>;
  settings_menuBarLogo?: Maybe<SettingListResponse>;
  shareUrl?: Maybe<Scalars['String']>;
  teamMembers_avatar?: Maybe<TeamMemberListResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** No longer supported */
  uploadUrl?: Maybe<Scalars['String']>;
  /** No longer supported */
  uploaded?: Maybe<Scalars['Boolean']>;
  userNotifications_image?: Maybe<UserNotificationListResponse>;
  users_avatar?: Maybe<UserListResponse>;
};

export type FileAdvertisingCampaignAds_ImageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingCampaignAdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingCampaignAdGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingCampaignAdOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingCampaignAdSort>>;
};

export type FileCollectiveIntelligenceThreads_ImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CollectiveIntelligenceThreadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<CollectiveIntelligenceThreadGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<CollectiveIntelligenceThreadOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<CollectiveIntelligenceThreadSort>>;
};

export type FileDiscounts_ImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DiscountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DiscountGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DiscountOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DiscountSort>>;
};

export type FileEducationalInstitutions_ImageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EducationalInstitutionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EducationalInstitutionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EducationalInstitutionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EducationalInstitutionSort>>;
};

export type FileEvents_ImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EventGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EventOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EventSort>>;
};

export type FileGroupPosts_MediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupPostSort>>;
};

export type FileGroups_LogoArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupSort>>;
};

export type FileHighSchoolLocations_LogoArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HighSchoolLocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<HighSchoolLocationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<HighSchoolLocationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HighSchoolLocationSort>>;
};

export type FileHomeFeedPosts_MediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HomeFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<HomeFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<HomeFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HomeFeedPostSort>>;
};

export type FileInboxMessageMedia_MediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxMessageMediaFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxMessageMediaGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxMessageMediaOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxMessageMediaSort>>;
};

export type FileInboxMessages_MediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxMessageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxMessageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxMessageSort>>;
};

export type FileJobEmployees_CompanyImageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<JobEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<JobEmployeeGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<JobEmployeeOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<JobEmployeeSort>>;
};

export type FileLegacyFeedPosts_MediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedPostSort>>;
};

export type FileOrganizations_LogoArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationSort>>;
};

export type FileOrganizations_MediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationSort>>;
};

export type FileSchoolConfigurationImages_ApplicationLogoArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationImageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationImageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationImageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationImageSort>>;
};

export type FileSchoolConfigurationImages_BrowserLogoArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationImageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationImageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationImageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationImageSort>>;
};

export type FileSchoolConfigurationImages_EmailBannerArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationImageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationImageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationImageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationImageSort>>;
};

export type FileSchoolConfigurationImages_EmailLogoArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationImageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationImageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationImageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationImageSort>>;
};

export type FileSchoolConfigurationImages_LogoArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationImageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationImageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationImageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationImageSort>>;
};

export type FileSchoolConfigurationImages_SplashImageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationImageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationImageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationImageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationImageSort>>;
};

export type FileSchoolNewsPosts_MediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolNewsPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolNewsPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolNewsPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolNewsPostSort>>;
};

export type FileSettings_LandingPageImageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SettingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SettingGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SettingOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SettingSort>>;
};

export type FileSettings_MenuBarLogoArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SettingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SettingGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SettingOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SettingSort>>;
};

export type FileTeamMembers_AvatarArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TeamMemberFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<TeamMemberGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TeamMemberSort>>;
};

export type FileUserNotifications_ImageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserNotificationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserNotificationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserNotificationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserNotificationSort>>;
};

export type FileUsers_AvatarArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

/** Files create input */
export type FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files create many input */
export type FileCreateManyInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageManyRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesManyRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesManyRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageManyRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesManyRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaManyRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoManyRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoManyRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaManyRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaManyRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaManyRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageManyRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaManyRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoManyRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaManyRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoManyRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoManyRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerManyRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoManyRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoManyRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageManyRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaManyRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarManyRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageManyRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarManyRelationInput>;
};

/** Files delete input */
export type FileDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** File Field Attributes */
export type FileFieldTypeAttributes = {
  __typename?: 'FileFieldTypeAttributes';
  expiration?: Maybe<Scalars['Int']>;
  format: Scalars['String'];
  maxSize?: Maybe<Scalars['Int']>;
  /** @deprecated Field is deprecated */
  showTitle?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field is deprecated */
  showUrl?: Maybe<Scalars['Boolean']>;
  typeRestrictions?: Maybe<Array<Scalars['String']>>;
};

/** FileFieldsPermissions create input */
export type FileFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  downloadUrl?: InputMaybe<Scalars['Boolean']>;
  fields?: InputMaybe<Scalars['Boolean']>;
  fileId?: InputMaybe<Scalars['Boolean']>;
  filename?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['Boolean']>;
  mods?: InputMaybe<Scalars['Boolean']>;
  provider?: InputMaybe<Scalars['Boolean']>;
  public?: InputMaybe<Scalars['Boolean']>;
  shareUrl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  uploadUrl?: InputMaybe<Scalars['Boolean']>;
  uploaded?: InputMaybe<Scalars['Boolean']>;
};

export type FileFilter = {
  AND?: InputMaybe<Array<FileFilter>>;
  OR?: InputMaybe<Array<FileFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  advertisingCampaignAds_image?: InputMaybe<AdvertisingCampaignAdRelationFilter>;
  collectiveIntelligenceThreads_images?: InputMaybe<CollectiveIntelligenceThreadRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  discounts_images?: InputMaybe<DiscountRelationFilter>;
  downloadUrl?: InputMaybe<StringPredicate>;
  educationalInstitutions_image?: InputMaybe<EducationalInstitutionRelationFilter>;
  events_images?: InputMaybe<EventRelationFilter>;
  fileId?: InputMaybe<StringPredicate>;
  filename?: InputMaybe<StringPredicate>;
  groupPosts_media?: InputMaybe<GroupPostRelationFilter>;
  groups_logo?: InputMaybe<GroupRelationFilter>;
  highSchoolLocations_logo?: InputMaybe<HighSchoolLocationRelationFilter>;
  homeFeedPosts_media?: InputMaybe<HomeFeedPostRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  inboxMessageMedia_media?: InputMaybe<InboxMessageMediaRelationFilter>;
  inboxMessages_media?: InputMaybe<InboxMessageRelationFilter>;
  jobEmployees_companyImage?: InputMaybe<JobEmployeeRelationFilter>;
  legacyFeedPosts_media?: InputMaybe<LegacyFeedPostRelationFilter>;
  organizations_logo?: InputMaybe<OrganizationRelationFilter>;
  organizations_media?: InputMaybe<OrganizationRelationFilter>;
  provider?: InputMaybe<StringPredicate>;
  public?: InputMaybe<BoolPredicate>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<SchoolConfigurationImageRelationFilter>;
  schoolConfigurationImages_browserLogo?: InputMaybe<SchoolConfigurationImageRelationFilter>;
  schoolConfigurationImages_emailBanner?: InputMaybe<SchoolConfigurationImageRelationFilter>;
  schoolConfigurationImages_emailLogo?: InputMaybe<SchoolConfigurationImageRelationFilter>;
  schoolConfigurationImages_logo?: InputMaybe<SchoolConfigurationImageRelationFilter>;
  schoolConfigurationImages_splashImage?: InputMaybe<SchoolConfigurationImageRelationFilter>;
  schoolNewsPosts_media?: InputMaybe<SchoolNewsPostRelationFilter>;
  settings_landingPageImage?: InputMaybe<SettingRelationFilter>;
  settings_menuBarLogo?: InputMaybe<SettingRelationFilter>;
  shareUrl?: InputMaybe<StringPredicate>;
  teamMembers_avatar?: InputMaybe<TeamMemberRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  uploadUrl?: InputMaybe<StringPredicate>;
  uploaded?: InputMaybe<BoolPredicate>;
  userNotifications_image?: InputMaybe<UserNotificationRelationFilter>;
  users_avatar?: InputMaybe<UserRelationFilter>;
};

export type FileGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: FileGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type FileGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  advertisingCampaignAds_image?: InputMaybe<AdvertisingCampaignAdGroupByQuery>;
  collectiveIntelligenceThreads_images?: InputMaybe<CollectiveIntelligenceThreadGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  discounts_images?: InputMaybe<DiscountGroupByQuery>;
  downloadUrl?: InputMaybe<Array<GroupByField>>;
  educationalInstitutions_image?: InputMaybe<EducationalInstitutionGroupByQuery>;
  events_images?: InputMaybe<EventGroupByQuery>;
  fields?: InputMaybe<Array<GroupByField>>;
  fileId?: InputMaybe<Array<GroupByField>>;
  filename?: InputMaybe<Array<GroupByField>>;
  groupPosts_media?: InputMaybe<GroupPostGroupByQuery>;
  groups_logo?: InputMaybe<GroupGroupByQuery>;
  highSchoolLocations_logo?: InputMaybe<HighSchoolLocationGroupByQuery>;
  homeFeedPosts_media?: InputMaybe<HomeFeedPostGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  inboxMessageMedia_media?: InputMaybe<InboxMessageMediaGroupByQuery>;
  inboxMessages_media?: InputMaybe<InboxMessageGroupByQuery>;
  jobEmployees_companyImage?: InputMaybe<JobEmployeeGroupByQuery>;
  legacyFeedPosts_media?: InputMaybe<LegacyFeedPostGroupByQuery>;
  meta?: InputMaybe<Array<GroupByField>>;
  mods?: InputMaybe<Array<GroupByField>>;
  organizations_logo?: InputMaybe<OrganizationGroupByQuery>;
  organizations_media?: InputMaybe<OrganizationGroupByQuery>;
  provider?: InputMaybe<Array<GroupByField>>;
  public?: InputMaybe<Array<GroupByField>>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<SchoolConfigurationImageGroupByQuery>;
  schoolConfigurationImages_browserLogo?: InputMaybe<SchoolConfigurationImageGroupByQuery>;
  schoolConfigurationImages_emailBanner?: InputMaybe<SchoolConfigurationImageGroupByQuery>;
  schoolConfigurationImages_emailLogo?: InputMaybe<SchoolConfigurationImageGroupByQuery>;
  schoolConfigurationImages_logo?: InputMaybe<SchoolConfigurationImageGroupByQuery>;
  schoolConfigurationImages_splashImage?: InputMaybe<SchoolConfigurationImageGroupByQuery>;
  schoolNewsPosts_media?: InputMaybe<SchoolNewsPostGroupByQuery>;
  settings_landingPageImage?: InputMaybe<SettingGroupByQuery>;
  settings_menuBarLogo?: InputMaybe<SettingGroupByQuery>;
  shareUrl?: InputMaybe<Array<GroupByField>>;
  teamMembers_avatar?: InputMaybe<TeamMemberGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  uploadUrl?: InputMaybe<Array<GroupByField>>;
  uploaded?: InputMaybe<Array<GroupByField>>;
  userNotifications_image?: InputMaybe<UserNotificationGroupByQuery>;
  users_avatar?: InputMaybe<UserGroupByQuery>;
};

export type FileKeyFilter = {
  fileId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** FileListResponse output */
export type FileListResponse = {
  __typename?: 'FileListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<File>;
};

/** FileManyResponse output */
export type FileManyResponse = {
  __typename?: 'FileManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<File>;
};

/** No longer supported. Use `sort` instead. */
export type FileOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'downloadUrl_ASC'
  | 'downloadUrl_DESC'
  | 'fields_ASC'
  | 'fields_DESC'
  | 'fileId_ASC'
  | 'fileId_DESC'
  | 'filename_ASC'
  | 'filename_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'meta_ASC'
  | 'meta_DESC'
  | 'mods_ASC'
  | 'mods_DESC'
  | 'provider_ASC'
  | 'provider_DESC'
  | 'public_ASC'
  | 'public_DESC'
  | 'shareUrl_ASC'
  | 'shareUrl_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'uploadUrl_ASC'
  | 'uploadUrl_DESC'
  | 'uploaded_ASC'
  | 'uploaded_DESC';

/** Files subscription payload */
export type FilePayload = {
  __typename?: 'FilePayload';
  mutation: MutationType;
  node?: Maybe<File>;
  previousValues?: Maybe<File>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FileRelationFilter = {
  every?: InputMaybe<FileFilter>;
  none?: InputMaybe<FileFilter>;
  some?: InputMaybe<FileFilter>;
};

export type FileSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  downloadUrl?: InputMaybe<SortOrder>;
  fileId?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  provider?: InputMaybe<SortOrder>;
  public?: InputMaybe<SortOrder>;
  shareUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uploadUrl?: InputMaybe<SortOrder>;
  uploaded?: InputMaybe<SortOrder>;
};

export type FileStackSignInfoResponse = {
  __typename?: 'FileStackSignInfoResponse';
  apiKey: Scalars['String'];
  path: Scalars['String'];
  policy: Scalars['String'];
  signature: Scalars['String'];
};

/** Files subscription filter */
export type FileSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<FileFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** File Type Format Enum */
export type FileTypeFormatEnum = 'FILE' | 'IMAGE';

/** Files update input */
export type FileUpdateByFilterInput = {
  downloadUrl?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  fields?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  filename?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  meta?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  mods?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  provider?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  public?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  shareUrl?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  uploadUrl?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  uploaded?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
};

/** Files update input */
export type FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

export type FileUploadInfoResponse = {
  __typename?: 'FileUploadInfoResponse';
  apiKey: Scalars['String'];
  path: Scalars['String'];
  policy: Scalars['String'];
  signature: Scalars['String'];
};

export type File_PermissionFilter = {
  AND?: InputMaybe<Array<File_PermissionFilter>>;
  OR?: InputMaybe<Array<File_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  advertisingCampaignAds_image?: InputMaybe<AdvertisingCampaignAd_PermissionRelationFilter>;
  collectiveIntelligenceThreads_images?: InputMaybe<CollectiveIntelligenceThread_PermissionRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  discounts_images?: InputMaybe<Discount_PermissionRelationFilter>;
  downloadUrl?: InputMaybe<StringPredicate>;
  educationalInstitutions_image?: InputMaybe<EducationalInstitution_PermissionRelationFilter>;
  events_images?: InputMaybe<Event_PermissionRelationFilter>;
  fileId?: InputMaybe<StringPredicate>;
  filename?: InputMaybe<StringPredicate>;
  groupPosts_media?: InputMaybe<GroupPost_PermissionRelationFilter>;
  groups_logo?: InputMaybe<Group_PermissionRelationFilter>;
  highSchoolLocations_logo?: InputMaybe<HighSchoolLocation_PermissionRelationFilter>;
  homeFeedPosts_media?: InputMaybe<HomeFeedPost_PermissionRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  inboxMessageMedia_media?: InputMaybe<InboxMessageMedia_PermissionRelationFilter>;
  inboxMessages_media?: InputMaybe<InboxMessage_PermissionRelationFilter>;
  jobEmployees_companyImage?: InputMaybe<JobEmployee_PermissionRelationFilter>;
  legacyFeedPosts_media?: InputMaybe<LegacyFeedPost_PermissionRelationFilter>;
  organizations_logo?: InputMaybe<Organization_PermissionRelationFilter>;
  organizations_media?: InputMaybe<Organization_PermissionRelationFilter>;
  provider?: InputMaybe<StringPredicate>;
  public?: InputMaybe<BoolPredicate>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<SchoolConfigurationImage_PermissionRelationFilter>;
  schoolConfigurationImages_browserLogo?: InputMaybe<SchoolConfigurationImage_PermissionRelationFilter>;
  schoolConfigurationImages_emailBanner?: InputMaybe<SchoolConfigurationImage_PermissionRelationFilter>;
  schoolConfigurationImages_emailLogo?: InputMaybe<SchoolConfigurationImage_PermissionRelationFilter>;
  schoolConfigurationImages_logo?: InputMaybe<SchoolConfigurationImage_PermissionRelationFilter>;
  schoolConfigurationImages_splashImage?: InputMaybe<SchoolConfigurationImage_PermissionRelationFilter>;
  schoolNewsPosts_media?: InputMaybe<SchoolNewsPost_PermissionRelationFilter>;
  settings_landingPageImage?: InputMaybe<Setting_PermissionRelationFilter>;
  settings_menuBarLogo?: InputMaybe<Setting_PermissionRelationFilter>;
  shareUrl?: InputMaybe<StringPredicate>;
  teamMembers_avatar?: InputMaybe<TeamMember_PermissionRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  uploadUrl?: InputMaybe<StringPredicate>;
  uploaded?: InputMaybe<BoolPredicate>;
  userNotifications_image?: InputMaybe<UserNotification_PermissionRelationFilter>;
  users_avatar?: InputMaybe<User_PermissionRelationFilter>;
};

export type File_PermissionRelationFilter = {
  every?: InputMaybe<File_PermissionFilter>;
  none?: InputMaybe<File_PermissionFilter>;
  some?: InputMaybe<File_PermissionFilter>;
};

/** Files relation input */
export type FilesAdvertisingCampaignAds_ImageManyRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
};

/** Files relation input */
export type FilesAdvertisingCampaignAds_ImageRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Image_AdvertisingCampaignAdCreateInput>>>;
};

/** Files relation input */
export type FilesAdvertisingCampaignAds_ImageUpdateRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Image_AdvertisingCampaignAdCreateInput>>>;
  disconnect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  reconnect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Image_AdvertisingCampaignAdUpdateInput>>>;
};

/** Files relation input */
export type FilesCollectiveIntelligenceThreads_ImagesManyRelationInput = {
  connect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
};

/** Files relation input */
export type FilesCollectiveIntelligenceThreads_ImagesRelationInput = {
  connect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Images_CollectiveIntelligenceThreadCreateInput>>>;
};

/** Files relation input */
export type FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput = {
  connect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Images_CollectiveIntelligenceThreadCreateInput>>>;
  disconnect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  reconnect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Images_CollectiveIntelligenceThreadUpdateInput>>>;
};

/** Files relation input */
export type FilesDiscounts_ImagesManyRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
};

/** Files relation input */
export type FilesDiscounts_ImagesRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Images_DiscountCreateInput>>>;
};

/** Files relation input */
export type FilesDiscounts_ImagesUpdateRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Images_DiscountCreateInput>>>;
  disconnect?: InputMaybe<Array<DiscountKeyFilter>>;
  reconnect?: InputMaybe<Array<DiscountKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Images_DiscountUpdateInput>>>;
};

/** Files relation input */
export type FilesEducationalInstitutions_ImageManyRelationInput = {
  connect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
};

/** Files relation input */
export type FilesEducationalInstitutions_ImageRelationInput = {
  connect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Image_EducationalInstitutionCreateInput>>>;
};

/** Files relation input */
export type FilesEducationalInstitutions_ImageUpdateRelationInput = {
  connect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Image_EducationalInstitutionCreateInput>>>;
  disconnect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
  reconnect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Image_EducationalInstitutionUpdateInput>>>;
};

/** Files relation input */
export type FilesEvents_ImagesManyRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
};

/** Files relation input */
export type FilesEvents_ImagesRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Images_EventCreateInput>>>;
};

/** Files relation input */
export type FilesEvents_ImagesUpdateRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Images_EventCreateInput>>>;
  disconnect?: InputMaybe<Array<EventKeyFilter>>;
  reconnect?: InputMaybe<Array<EventKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Images_EventUpdateInput>>>;
};

/** Files relation input */
export type FilesGroupPosts_MediaManyRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
};

/** Files relation input */
export type FilesGroupPosts_MediaRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_GroupPostCreateInput>>>;
};

/** Files relation input */
export type FilesGroupPosts_MediaUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_GroupPostCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupPostKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Media_GroupPostUpdateInput>>>;
};

/** Files relation input */
export type FilesGroups_LogoManyRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
};

/** Files relation input */
export type FilesGroups_LogoRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Logo_GroupCreateInput>>>;
};

/** Files relation input */
export type FilesGroups_LogoUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Logo_GroupCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Logo_GroupUpdateInput>>>;
};

/** Files relation input */
export type FilesHighSchoolLocations_LogoManyRelationInput = {
  connect?: InputMaybe<Array<HighSchoolLocationKeyFilter>>;
};

/** Files relation input */
export type FilesHighSchoolLocations_LogoRelationInput = {
  connect?: InputMaybe<Array<HighSchoolLocationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Logo_HighSchoolLocationCreateInput>>>;
};

/** Files relation input */
export type FilesHighSchoolLocations_LogoUpdateRelationInput = {
  connect?: InputMaybe<Array<HighSchoolLocationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Logo_HighSchoolLocationCreateInput>>>;
  disconnect?: InputMaybe<Array<HighSchoolLocationKeyFilter>>;
  reconnect?: InputMaybe<Array<HighSchoolLocationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Logo_HighSchoolLocationUpdateInput>>>;
};

/** Files relation input */
export type FilesHomeFeedPosts_MediaManyRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
};

/** Files relation input */
export type FilesHomeFeedPosts_MediaRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_HomeFeedPostCreateInput>>>;
};

/** Files relation input */
export type FilesHomeFeedPosts_MediaUpdateRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_HomeFeedPostCreateInput>>>;
  disconnect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  reconnect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Media_HomeFeedPostUpdateInput>>>;
};

/** Files relation input */
export type FilesInboxMessageMedia_MediaManyRelationInput = {
  connect?: InputMaybe<Array<InboxMessageMediaKeyFilter>>;
};

/** Files relation input */
export type FilesInboxMessageMedia_MediaRelationInput = {
  connect?: InputMaybe<Array<InboxMessageMediaKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_InboxMessageMediaCreateInput>>>;
};

/** Files relation input */
export type FilesInboxMessageMedia_MediaUpdateRelationInput = {
  connect?: InputMaybe<Array<InboxMessageMediaKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_InboxMessageMediaCreateInput>>>;
  disconnect?: InputMaybe<Array<InboxMessageMediaKeyFilter>>;
  reconnect?: InputMaybe<Array<InboxMessageMediaKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Media_InboxMessageMediaUpdateInput>>>;
};

/** Files relation input */
export type FilesInboxMessages_MediaManyRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
};

/** Files relation input */
export type FilesInboxMessages_MediaRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_InboxMessageCreateInput>>>;
};

/** Files relation input */
export type FilesInboxMessages_MediaUpdateRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_InboxMessageCreateInput>>>;
  disconnect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  reconnect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Media_InboxMessageUpdateInput>>>;
};

/** Files relation input */
export type FilesJobEmployees_CompanyImageManyRelationInput = {
  connect?: InputMaybe<Array<JobEmployeeKeyFilter>>;
};

/** Files relation input */
export type FilesJobEmployees_CompanyImageRelationInput = {
  connect?: InputMaybe<Array<JobEmployeeKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<CompanyImage_JobEmployeeCreateInput>>>;
};

/** Files relation input */
export type FilesJobEmployees_CompanyImageUpdateRelationInput = {
  connect?: InputMaybe<Array<JobEmployeeKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<CompanyImage_JobEmployeeCreateInput>>>;
  disconnect?: InputMaybe<Array<JobEmployeeKeyFilter>>;
  reconnect?: InputMaybe<Array<JobEmployeeKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<CompanyImage_JobEmployeeUpdateInput>>>;
};

/** Files relation input */
export type FilesLegacyFeedPosts_MediaManyRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
};

/** Files relation input */
export type FilesLegacyFeedPosts_MediaRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_LegacyFeedPostCreateInput>>>;
};

/** Files relation input */
export type FilesLegacyFeedPosts_MediaUpdateRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_LegacyFeedPostCreateInput>>>;
  disconnect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  reconnect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Media_LegacyFeedPostUpdateInput>>>;
};

/** Files relation input */
export type FilesOrganizations_LogoManyRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
};

/** Files relation input */
export type FilesOrganizations_LogoRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Logo_OrganizationCreateInput>>>;
};

/** Files relation input */
export type FilesOrganizations_LogoUpdateRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Logo_OrganizationCreateInput>>>;
  disconnect?: InputMaybe<Array<OrganizationKeyFilter>>;
  reconnect?: InputMaybe<Array<OrganizationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Logo_OrganizationUpdateInput>>>;
};

/** Files relation input */
export type FilesOrganizations_MediaManyRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
};

/** Files relation input */
export type FilesOrganizations_MediaRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_OrganizationCreateInput>>>;
};

/** Files relation input */
export type FilesOrganizations_MediaUpdateRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_OrganizationCreateInput>>>;
  disconnect?: InputMaybe<Array<OrganizationKeyFilter>>;
  reconnect?: InputMaybe<Array<OrganizationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Media_OrganizationUpdateInput>>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_ApplicationLogoManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_ApplicationLogoRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<ApplicationLogo_SchoolConfigurationImageCreateInput>>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<ApplicationLogo_SchoolConfigurationImageCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<ApplicationLogo_SchoolConfigurationImageUpdateInput>>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_BrowserLogoManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_BrowserLogoRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<BrowserLogo_SchoolConfigurationImageCreateInput>>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<BrowserLogo_SchoolConfigurationImageCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<BrowserLogo_SchoolConfigurationImageUpdateInput>>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_EmailBannerManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_EmailBannerRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<EmailBanner_SchoolConfigurationImageCreateInput>>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<EmailBanner_SchoolConfigurationImageCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<EmailBanner_SchoolConfigurationImageUpdateInput>>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_EmailLogoManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_EmailLogoRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<EmailLogo_SchoolConfigurationImageCreateInput>>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<EmailLogo_SchoolConfigurationImageCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<EmailLogo_SchoolConfigurationImageUpdateInput>>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_LogoManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_LogoRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Logo_SchoolConfigurationImageCreateInput>>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_LogoUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Logo_SchoolConfigurationImageCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Logo_SchoolConfigurationImageUpdateInput>>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_SplashImageManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_SplashImageRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<SplashImage_SchoolConfigurationImageCreateInput>>>;
};

/** Files relation input */
export type FilesSchoolConfigurationImages_SplashImageUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<SplashImage_SchoolConfigurationImageCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationImageKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<SplashImage_SchoolConfigurationImageUpdateInput>>>;
};

/** Files relation input */
export type FilesSchoolNewsPosts_MediaManyRelationInput = {
  connect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
};

/** Files relation input */
export type FilesSchoolNewsPosts_MediaRelationInput = {
  connect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_SchoolNewsPostCreateInput>>>;
};

/** Files relation input */
export type FilesSchoolNewsPosts_MediaUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Media_SchoolNewsPostCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Media_SchoolNewsPostUpdateInput>>>;
};

export type FilesSetting = {
  __typename?: 'FilesSetting';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  filemanagername?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** FilesSettings create input */
export type FilesSettingCreateInput = {
  filemanagername?: InputMaybe<Scalars['String']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<Scalars['JSON']>;
};

/** FilesSettings create many input */
export type FilesSettingCreateManyInput = {
  filemanagername?: InputMaybe<Scalars['String']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<Scalars['JSON']>;
};

/** FilesSettings delete input */
export type FilesSettingDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** FilesSettingFieldsPermissions create input */
export type FilesSettingFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  filemanagername?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type FilesSettingFilter = {
  AND?: InputMaybe<Array<FilesSettingFilter>>;
  OR?: InputMaybe<Array<FilesSettingFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  filemanagername?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  isEnabled?: InputMaybe<BoolPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type FilesSettingGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: FilesSettingGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type FilesSettingGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  filemanagername?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  isEnabled?: InputMaybe<Array<GroupByField>>;
  settings?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type FilesSettingKeyFilter = {
  filemanagername?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** FilesSettingListResponse output */
export type FilesSettingListResponse = {
  __typename?: 'FilesSettingListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<FilesSetting>;
};

/** FilesSettingManyResponse output */
export type FilesSettingManyResponse = {
  __typename?: 'FilesSettingManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<FilesSetting>;
};

/** No longer supported. Use `sort` instead. */
export type FilesSettingOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'filemanagername_ASC'
  | 'filemanagername_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isEnabled_ASC'
  | 'isEnabled_DESC'
  | 'settings_ASC'
  | 'settings_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** FilesSettings subscription payload */
export type FilesSettingPayload = {
  __typename?: 'FilesSettingPayload';
  mutation: MutationType;
  node?: Maybe<FilesSetting>;
  previousValues?: Maybe<FilesSetting>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilesSettingSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  filemanagername?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isEnabled?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** FilesSettings subscription filter */
export type FilesSettingSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<FilesSettingFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** FilesSettings update input */
export type FilesSettingUpdateByFilterInput = {
  filemanagername?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  isEnabled?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  settings?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
};

/** FilesSettings update input */
export type FilesSettingUpdateInput = {
  filemanagername?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<Scalars['JSON']>;
};

export type FilesSetting_PermissionFilter = {
  AND?: InputMaybe<Array<FilesSetting_PermissionFilter>>;
  OR?: InputMaybe<Array<FilesSetting_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  filemanagername?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  isEnabled?: InputMaybe<BoolPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

/** Files relation input */
export type FilesTeamMembers_AvatarManyRelationInput = {
  connect?: InputMaybe<Array<TeamMemberKeyFilter>>;
};

/** Files relation input */
export type FilesTeamMembers_AvatarRelationInput = {
  connect?: InputMaybe<Array<TeamMemberKeyFilter>>;
};

/** Files relation input */
export type FilesTeamMembers_AvatarUpdateRelationInput = {
  connect?: InputMaybe<Array<TeamMemberKeyFilter>>;
  disconnect?: InputMaybe<Array<TeamMemberKeyFilter>>;
  reconnect?: InputMaybe<Array<TeamMemberKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Avatar_TeamMemberUpdateInput>>>;
};

/** Files relation input */
export type FilesUserNotifications_ImageManyRelationInput = {
  connect?: InputMaybe<Array<UserNotificationKeyFilter>>;
};

/** Files relation input */
export type FilesUserNotifications_ImageRelationInput = {
  connect?: InputMaybe<Array<UserNotificationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Image_UserNotificationCreateInput>>>;
};

/** Files relation input */
export type FilesUserNotifications_ImageUpdateRelationInput = {
  connect?: InputMaybe<Array<UserNotificationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Image_UserNotificationCreateInput>>>;
  disconnect?: InputMaybe<Array<UserNotificationKeyFilter>>;
  reconnect?: InputMaybe<Array<UserNotificationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Image_UserNotificationUpdateInput>>>;
};

/** Files relation input */
export type FilesUsers_AvatarManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** Files relation input */
export type FilesUsers_AvatarRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Avatar_UserCreateInput>>>;
};

/** Files relation input */
export type FilesUsers_AvatarUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Avatar_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Avatar_UserUpdateInput>>>;
};

export type FlagPostRequestInput = {
  flagReason?: InputMaybe<Scalars['String']>;
  postId: Scalars['ID'];
  postType: Scalars['String'];
};

/** GroupPosts create input from flaggedBy */
export type FlaggedBy_GroupPostCreateInput = {
  author?: InputMaybe<GroupPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<GroupPostsFlaggedByRelationInput>;
  group: GroupPostsGroupRelationInput;
  groupAdminPreferences?: InputMaybe<GroupPostsGroupAdminPreferencesRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<GroupPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<GroupPostsMediaRelationInput>;
  mentions?: InputMaybe<GroupPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** GroupPosts update input from flaggedBy */
export type FlaggedBy_GroupPostUpdateInput = {
  data: GroupPostUpdateInput;
  filter?: InputMaybe<GroupPostKeyFilter>;
};

/** HomeFeedPosts create input from flaggedBy */
export type FlaggedBy_HomeFeedPostCreateInput = {
  author?: InputMaybe<HomeFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<HomeFeedPostsDiscountRelationInput>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<HomeFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<HomeFeedPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<HomeFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<HomeFeedPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<HomeFeedPostsSchoolsRelationInput>;
  /**
   * Approved - post is displayed
   * Pending - default status, awaits auto-approval/approved by admins. Not displayed
   * Rejected - auto-rejected/rejected by admins
   * reportReviewPending - post got flagged, pending review
   */
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** HomeFeedPosts update input from flaggedBy */
export type FlaggedBy_HomeFeedPostUpdateInput = {
  data: HomeFeedPostUpdateInput;
  filter?: InputMaybe<HomeFeedPostKeyFilter>;
};

/** LegacyFeedPosts create input from flaggedBy */
export type FlaggedBy_LegacyFeedPostCreateInput = {
  author?: InputMaybe<LegacyFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<LegacyFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<LegacyFeedPostsLastFlaggedByRelationInput>;
  legacyDate?: InputMaybe<Scalars['Date']>;
  media?: InputMaybe<LegacyFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<LegacyFeedPostsMentionsRelationInput>;
  month?: InputMaybe<LegacyFeedPostsMonthRelationInput>;
  monthId?: InputMaybe<Scalars['String']>;
  /** Date where the post will be available on the feed. */
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<LegacyFeedPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** LegacyFeedPosts update input from flaggedBy */
export type FlaggedBy_LegacyFeedPostUpdateInput = {
  data: LegacyFeedPostUpdateInput;
  filter?: InputMaybe<LegacyFeedPostKeyFilter>;
};

/** SchoolNewsPosts create input from flaggedBy */
export type FlaggedBy_SchoolNewsPostCreateInput = {
  author?: InputMaybe<SchoolNewsPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<SchoolNewsPostsFlaggedByRelationInput>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned: Scalars['Boolean'];
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<SchoolNewsPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<SchoolNewsPostsMediaRelationInput>;
  mentions?: InputMaybe<SchoolNewsPostsMentionsRelationInput>;
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<SchoolNewsPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** SchoolNewsPosts update input from flaggedBy */
export type FlaggedBy_SchoolNewsPostUpdateInput = {
  data: SchoolNewsPostUpdateInput;
  filter?: InputMaybe<SchoolNewsPostKeyFilter>;
};

/** Users create input from flaggedGroupPosts */
export type FlaggedGroupPosts_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from flaggedGroupPosts */
export type FlaggedGroupPosts_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

/** Users create input from flaggedHomeFeedPosts */
export type FlaggedHomeFeedPosts_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from flaggedHomeFeedPosts */
export type FlaggedHomeFeedPosts_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

/** Users create input from flaggedLegacyFeedPosts */
export type FlaggedLegacyFeedPosts_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from flaggedLegacyFeedPosts */
export type FlaggedLegacyFeedPosts_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

export type FlaggedPostApproveRequestInput = {
  authorId: Scalars['ID'];
  groupId?: InputMaybe<Scalars['ID']>;
  legacyDate?: InputMaybe<Scalars['String']>;
  postId: Scalars['ID'];
  postType: Scalars['String'];
};

export type FlaggedPostDeleteRequestInput = {
  authorId: Scalars['ID'];
  postId: Scalars['ID'];
  postType: Scalars['String'];
};

/** Users create input from flaggedSchoolNewsPosts */
export type FlaggedSchoolNewsPosts_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from flaggedSchoolNewsPosts */
export type FlaggedSchoolNewsPosts_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

export type FloatPredicate = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not_equals?: InputMaybe<Scalars['Float']>;
  not_in?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatPredicateHaving = {
  AND?: InputMaybe<Array<FloatPredicateHaving>>;
  OR?: InputMaybe<Array<FloatPredicateHaving>>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not_equals?: InputMaybe<Scalars['Float']>;
  not_in?: InputMaybe<Array<Scalars['Float']>>;
};

/** SchoolConfiguration create input from forumThreads */
export type ForumThreads_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from forumThreads */
export type ForumThreads_SchoolConfigurationUpdateInput = {
  data: SchoolConfigurationUpdateInput;
  filter?: InputMaybe<SchoolConfigurationKeyFilter>;
};

export type FrontendUtilizationAbResponse = {
  __typename?: 'FrontendUtilizationABResponse';
  CDN?: Maybe<Scalars['Boolean']>;
  assets?: Maybe<Scalars['Int']>;
  customStates?: Maybe<Scalars['Int']>;
  functions?: Maybe<Scalars['Int']>;
  layouts?: Maybe<Scalars['Int']>;
  libraries?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  requests?: Maybe<Scalars['Int']>;
  resources?: Maybe<Scalars['Int']>;
};

/** FunctionInfo */
export type FunctionInfo = {
  application?: Maybe<Application>;
  description?: Maybe<Scalars['String']>;
  functionType: FunctionType;
  name: Scalars['String'];
};

/** FunctionInfoCheck */
export type FunctionInfoCheck = {
  __typename?: 'FunctionInfoCheck';
  version?: Maybe<Scalars['String']>;
};

/** FunctionInfoFilter */
export type FunctionInfoFilter = {
  description?: InputMaybe<Scalars['String']>;
  functionType?: InputMaybe<FunctionType>;
  name?: InputMaybe<Scalars['String']>;
};

/** FunctionInfoOrderBy */
export type FunctionInfoOrderBy =
  | 'description_ASC'
  | 'description_DESC'
  | 'functionType_ASC'
  | 'functionType_DESC'
  | 'name_ASC'
  | 'name_DESC';

/** FunctionListResponse output */
export type FunctionListResponse = {
  __typename?: 'FunctionListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<FunctionInfo>;
};

/** FunctionResolverInfo */
export type FunctionResolverInfo = FunctionInfo & {
  __typename?: 'FunctionResolverInfo';
  application?: Maybe<Application>;
  description?: Maybe<Scalars['String']>;
  functionType: FunctionType;
  gqlType: Scalars['String'];
  name: Scalars['String'];
};

/** FunctionTaskInfo */
export type FunctionTaskInfo = FunctionInfo & {
  __typename?: 'FunctionTaskInfo';
  application?: Maybe<Application>;
  description?: Maybe<Scalars['String']>;
  functionType: FunctionType;
  name: Scalars['String'];
  scheduleExpression?: Maybe<Scalars['String']>;
};

/** FunctionTriggerInfo */
export type FunctionTriggerInfo = FunctionInfo & {
  __typename?: 'FunctionTriggerInfo';
  application?: Maybe<Application>;
  description?: Maybe<Scalars['String']>;
  functionType: FunctionType;
  name: Scalars['String'];
  operation: Scalars['String'];
  tableName: Scalars['String'];
  type: Scalars['String'];
};

/** FunctionType */
export type FunctionType = 'resolver' | 'schedule' | 'task' | 'trigger' | 'webhook';

/** FunctionWebhookInfo */
export type FunctionWebhookInfo = FunctionInfo & {
  __typename?: 'FunctionWebhookInfo';
  application?: Maybe<Application>;
  description?: Maybe<Scalars['String']>;
  functionType: FunctionType;
  httpMethod: Scalars['String'];
  name: Scalars['String'];
  workspaceFullPath: Scalars['String'];
  workspaceRelativePath: Scalars['String'];
};

export type GeneratedLinkPreview = {
  __typename?: 'GeneratedLinkPreview';
  /** The description of the link. */
  description?: Maybe<Scalars['String']>;
  /** The URL of the image. */
  imageURL?: Maybe<Scalars['String']>;
  /** The title of the link. */
  title: Scalars['String'];
  /** The URL of the website. */
  url?: Maybe<Scalars['String']>;
};

/** Geo Field Attributes */
export type GeoFieldTypeAttributes = {
  __typename?: 'GeoFieldTypeAttributes';
  format: Scalars['String'];
  srid?: Maybe<Scalars['Int']>;
};

/** Github connection params */
export type GithubOptions = {
  __typename?: 'GithubOptions';
  client_id: Scalars['String'];
  client_secret: Scalars['String'];
  enabled: Scalars['Boolean'];
};

/** Github connection params input */
export type GithubOptionsInput = {
  client_id: Scalars['String'];
  client_secret: Scalars['String'];
  enabled: Scalars['Boolean'];
};

/** Google connection params */
export type GoogleOptions = {
  __typename?: 'GoogleOptions';
  client_id: Scalars['String'];
  client_secret: Scalars['String'];
  enabled: Scalars['Boolean'];
};

/** Google connection params input */
export type GoogleOptionsInput = {
  client_id: Scalars['String'];
  client_secret: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type GraduatingYear = {
  __typename?: 'GraduatingYear';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  graduateCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

/** GraduatingYears create input */
export type GraduatingYearCreateInput = {
  graduateCount: Scalars['Int'];
  school?: InputMaybe<GraduatingYearsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** GraduatingYears create many input */
export type GraduatingYearCreateManyInput = {
  graduateCount: Scalars['Int'];
  school?: InputMaybe<GraduatingYearsSchoolManyRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** GraduatingYears delete input */
export type GraduatingYearDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** GraduatingYearFieldsPermissions create input */
export type GraduatingYearFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  graduateCount?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type GraduatingYearFilter = {
  AND?: InputMaybe<Array<GraduatingYearFilter>>;
  OR?: InputMaybe<Array<GraduatingYearFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  graduateCount?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  year?: InputMaybe<IntPredicate>;
};

export type GraduatingYearGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: GraduatingYearGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type GraduatingYearGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  graduateCount?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  year?: InputMaybe<Array<GroupByField>>;
};

export type GraduatingYearKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** GraduatingYearListResponse output */
export type GraduatingYearListResponse = {
  __typename?: 'GraduatingYearListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<GraduatingYear>;
};

/** GraduatingYearManyResponse output */
export type GraduatingYearManyResponse = {
  __typename?: 'GraduatingYearManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<GraduatingYear>;
};

/** No longer supported. Use `sort` instead. */
export type GraduatingYearOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'graduateCount_ASC'
  | 'graduateCount_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'year_ASC'
  | 'year_DESC';

/** GraduatingYears subscription payload */
export type GraduatingYearPayload = {
  __typename?: 'GraduatingYearPayload';
  mutation: MutationType;
  node?: Maybe<GraduatingYear>;
  previousValues?: Maybe<GraduatingYear>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GraduatingYearRelationFilter = {
  every?: InputMaybe<GraduatingYearFilter>;
  none?: InputMaybe<GraduatingYearFilter>;
  some?: InputMaybe<GraduatingYearFilter>;
};

export type GraduatingYearSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  graduateCount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

/** GraduatingYears subscription filter */
export type GraduatingYearSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<GraduatingYearFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** GraduatingYears update input */
export type GraduatingYearUpdateByFilterInput = {
  graduateCount?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  year?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
};

/** GraduatingYears update input */
export type GraduatingYearUpdateInput = {
  graduateCount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  school?: InputMaybe<GraduatingYearsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type GraduatingYear_PermissionFilter = {
  AND?: InputMaybe<Array<GraduatingYear_PermissionFilter>>;
  OR?: InputMaybe<Array<GraduatingYear_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  graduateCount?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  year?: InputMaybe<IntPredicate>;
};

export type GraduatingYear_PermissionRelationFilter = {
  every?: InputMaybe<GraduatingYear_PermissionFilter>;
  none?: InputMaybe<GraduatingYear_PermissionFilter>;
  some?: InputMaybe<GraduatingYear_PermissionFilter>;
};

export type GraduatingYearsMetric = {
  __typename?: 'GraduatingYearsMetric';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  expectedUsersCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  missingUsersCount?: Maybe<Scalars['Int']>;
  missingUsersPercentage?: Maybe<Scalars['Float']>;
  registeredUsersCount?: Maybe<Scalars['Int']>;
  registeredUsersPercentage?: Maybe<Scalars['Float']>;
  school?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['String']>;
};

/** GraduatingYearsMetricFieldsPermissions create input */
export type GraduatingYearsMetricFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  expectedUsersCount?: InputMaybe<Scalars['Boolean']>;
  missingUsersCount?: InputMaybe<Scalars['Boolean']>;
  missingUsersPercentage?: InputMaybe<Scalars['Boolean']>;
  registeredUsersCount?: InputMaybe<Scalars['Boolean']>;
  registeredUsersPercentage?: InputMaybe<Scalars['Boolean']>;
  school?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type GraduatingYearsMetricFilter = {
  AND?: InputMaybe<Array<GraduatingYearsMetricFilter>>;
  OR?: InputMaybe<Array<GraduatingYearsMetricFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  expectedUsersCount?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  missingUsersCount?: InputMaybe<IntPredicate>;
  missingUsersPercentage?: InputMaybe<FloatPredicate>;
  registeredUsersCount?: InputMaybe<IntPredicate>;
  registeredUsersPercentage?: InputMaybe<FloatPredicate>;
  school?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  year?: InputMaybe<StringPredicate>;
};

export type GraduatingYearsMetricGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: GraduatingYearsMetricGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type GraduatingYearsMetricGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  expectedUsersCount?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  missingUsersCount?: InputMaybe<Array<GroupByField>>;
  missingUsersPercentage?: InputMaybe<Array<GroupByField>>;
  registeredUsersCount?: InputMaybe<Array<GroupByField>>;
  registeredUsersPercentage?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  year?: InputMaybe<Array<GroupByField>>;
};

/** GraduatingYearsMetricListResponse output */
export type GraduatingYearsMetricListResponse = {
  __typename?: 'GraduatingYearsMetricListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<GraduatingYearsMetric>;
};

/** GraduatingYearsMetricManyResponse output */
export type GraduatingYearsMetricManyResponse = {
  __typename?: 'GraduatingYearsMetricManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<GraduatingYearsMetric>;
};

/** No longer supported. Use `sort` instead. */
export type GraduatingYearsMetricOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'expectedUsersCount_ASC'
  | 'expectedUsersCount_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'missingUsersCount_ASC'
  | 'missingUsersCount_DESC'
  | 'missingUsersPercentage_ASC'
  | 'missingUsersPercentage_DESC'
  | 'registeredUsersCount_ASC'
  | 'registeredUsersCount_DESC'
  | 'registeredUsersPercentage_ASC'
  | 'registeredUsersPercentage_DESC'
  | 'school_ASC'
  | 'school_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'year_ASC'
  | 'year_DESC';

/** GraduatingYearsMetrics subscription payload */
export type GraduatingYearsMetricPayload = {
  __typename?: 'GraduatingYearsMetricPayload';
  mutation: MutationType;
  node?: Maybe<GraduatingYearsMetric>;
  previousValues?: Maybe<GraduatingYearsMetric>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GraduatingYearsMetricSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  expectedUsersCount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  missingUsersCount?: InputMaybe<SortOrder>;
  missingUsersPercentage?: InputMaybe<SortOrder>;
  registeredUsersCount?: InputMaybe<SortOrder>;
  registeredUsersPercentage?: InputMaybe<SortOrder>;
  school?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

/** GraduatingYearsMetrics subscription filter */
export type GraduatingYearsMetricSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<GraduatingYearsMetricFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** GraduatingYearsMetrics update input */
export type GraduatingYearsMetricUpdateByFilterInput = {
  expectedUsersCount?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  missingUsersCount?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  missingUsersPercentage?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  registeredUsersCount?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  registeredUsersPercentage?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  school?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  year?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

export type GraduatingYearsMetric_PermissionFilter = {
  AND?: InputMaybe<Array<GraduatingYearsMetric_PermissionFilter>>;
  OR?: InputMaybe<Array<GraduatingYearsMetric_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  expectedUsersCount?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  missingUsersCount?: InputMaybe<IntPredicate>;
  missingUsersPercentage?: InputMaybe<FloatPredicate>;
  registeredUsersCount?: InputMaybe<IntPredicate>;
  registeredUsersPercentage?: InputMaybe<FloatPredicate>;
  school?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  year?: InputMaybe<StringPredicate>;
};

/** GraduatingYears relation input */
export type GraduatingYearsSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** GraduatingYears relation input */
export type GraduatingYearsSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<ClassScorecard_SchoolConfigurationCreateInput>;
};

/** GraduatingYears relation input */
export type GraduatingYearsSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<ClassScorecard_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<ClassScorecard_SchoolConfigurationUpdateInput>;
};

/** GraphQLCreateFileCustomInput */
export type GraphQlCreateFileCustomInput = {
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
};

/** GraphQLCreateFileItemInput */
export type GraphQlCreateFileItemInput = {
  create?: InputMaybe<GraphQlCreateFileCustomInput>;
};

/** GraphQLFileItemResponse */
export type GraphQlFileItemResponse = {
  __typename?: 'GraphQLFileItemResponse';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  _description?: Maybe<Scalars['String']>;
  audiences?: Maybe<AudienceListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  creator?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  groupAdminPreferences?: Maybe<GroupAdminPreferenceListResponse>;
  groupToJoin?: Maybe<GroupsMembersRequestListResponse>;
  id?: Maybe<Scalars['ID']>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<File>;
  members?: Maybe<UserListResponse>;
  membersCount?: Maybe<Scalars['Int']>;
  posts?: Maybe<GroupPostListResponse>;
  postsCount?: Maybe<Scalars['Int']>;
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupAudiencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AudienceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AudienceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AudienceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AudienceSort>>;
};

export type GroupGroupAdminPreferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupAdminPreferenceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupAdminPreferenceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupAdminPreferenceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupAdminPreferenceSort>>;
};

export type GroupGroupToJoinArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupsMembersRequestFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupsMembersRequestGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupsMembersRequestOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupsMembersRequestSort>>;
};

export type GroupMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

export type GroupPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupPostSort>>;
};

export type GroupAdminPreference = {
  __typename?: 'GroupAdminPreference';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  group?: Maybe<GroupListResponse>;
  groupPosts?: Maybe<GroupPostListResponse>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type GroupAdminPreferenceGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupSort>>;
};

export type GroupAdminPreferenceGroupPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupPostSort>>;
};

/** GroupAdminPreferences create input */
export type GroupAdminPreferenceCreateInput = {
  group?: InputMaybe<GroupAdminPreferencesGroupRelationInput>;
  groupPosts?: InputMaybe<GroupAdminPreferencesGroupPostsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<GroupAdminPreferencesUserRelationInput>;
};

/** GroupAdminPreferences create many input */
export type GroupAdminPreferenceCreateManyInput = {
  group?: InputMaybe<GroupAdminPreferencesGroupManyRelationInput>;
  groupPosts?: InputMaybe<GroupAdminPreferencesGroupPostsManyRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user: GroupAdminPreferencesUserManyRelationInput;
};

/** GroupAdminPreferences delete input */
export type GroupAdminPreferenceDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** GroupAdminPreferenceFieldsPermissions create input */
export type GroupAdminPreferenceFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type GroupAdminPreferenceFilter = {
  AND?: InputMaybe<Array<GroupAdminPreferenceFilter>>;
  OR?: InputMaybe<Array<GroupAdminPreferenceFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  group?: InputMaybe<GroupRelationFilter>;
  groupPosts?: InputMaybe<GroupPostRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type GroupAdminPreferenceGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: GroupAdminPreferenceGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type GroupAdminPreferenceGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  group?: InputMaybe<GroupGroupByQuery>;
  groupPosts?: InputMaybe<GroupPostGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type GroupAdminPreferenceKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** GroupAdminPreferenceListResponse output */
export type GroupAdminPreferenceListResponse = {
  __typename?: 'GroupAdminPreferenceListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<GroupAdminPreference>;
};

/** GroupAdminPreferenceManyResponse output */
export type GroupAdminPreferenceManyResponse = {
  __typename?: 'GroupAdminPreferenceManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<GroupAdminPreference>;
};

/** No longer supported. Use `sort` instead. */
export type GroupAdminPreferenceOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** GroupAdminPreferences subscription payload */
export type GroupAdminPreferencePayload = {
  __typename?: 'GroupAdminPreferencePayload';
  mutation: MutationType;
  node?: Maybe<GroupAdminPreference>;
  previousValues?: Maybe<GroupAdminPreference>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GroupAdminPreferenceRelationFilter = {
  every?: InputMaybe<GroupAdminPreferenceFilter>;
  none?: InputMaybe<GroupAdminPreferenceFilter>;
  some?: InputMaybe<GroupAdminPreferenceFilter>;
};

export type GroupAdminPreferenceSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

/** GroupAdminPreferences subscription filter */
export type GroupAdminPreferenceSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<GroupAdminPreferenceFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** GroupAdminPreferences update input */
export type GroupAdminPreferenceUpdateByFilterInput = {
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
};

/** GroupAdminPreferences update input */
export type GroupAdminPreferenceUpdateInput = {
  group?: InputMaybe<GroupAdminPreferencesGroupUpdateRelationInput>;
  groupPosts?: InputMaybe<GroupAdminPreferencesGroupPostsUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<GroupAdminPreferencesUserUpdateRelationInput>;
};

export type GroupAdminPreference_PermissionFilter = {
  AND?: InputMaybe<Array<GroupAdminPreference_PermissionFilter>>;
  OR?: InputMaybe<Array<GroupAdminPreference_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  group?: InputMaybe<Group_PermissionRelationFilter>;
  groupPosts?: InputMaybe<GroupPost_PermissionRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
};

export type GroupAdminPreference_PermissionRelationFilter = {
  every?: InputMaybe<GroupAdminPreference_PermissionFilter>;
  none?: InputMaybe<GroupAdminPreference_PermissionFilter>;
  some?: InputMaybe<GroupAdminPreference_PermissionFilter>;
};

/** GroupAdminPreferences relation input */
export type GroupAdminPreferencesGroupManyRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
};

/** GroupAdminPreferences relation input */
export type GroupAdminPreferencesGroupPostsManyRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
};

/** GroupAdminPreferences relation input */
export type GroupAdminPreferencesGroupPostsRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<GroupAdminPreferences_GroupPostCreateInput>>>;
};

/** GroupAdminPreferences relation input */
export type GroupAdminPreferencesGroupPostsUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<GroupAdminPreferences_GroupPostCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupPostKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<GroupAdminPreferences_GroupPostUpdateInput>>>;
};

/** GroupAdminPreferences relation input */
export type GroupAdminPreferencesGroupRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<GroupAdminPreferences_GroupCreateInput>>>;
};

/** GroupAdminPreferences relation input */
export type GroupAdminPreferencesGroupUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<GroupAdminPreferences_GroupCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<GroupAdminPreferences_GroupUpdateInput>>>;
};

/** GroupAdminPreferences relation input */
export type GroupAdminPreferencesUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** GroupAdminPreferences relation input */
export type GroupAdminPreferencesUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<GroupAdminPreferences_UserCreateInput>;
};

/** GroupAdminPreferences relation input */
export type GroupAdminPreferencesUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<GroupAdminPreferences_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<GroupAdminPreferences_UserUpdateInput>;
};

/** Groups create input from groupAdminPreferences */
export type GroupAdminPreferences_GroupCreateInput = {
  audiences?: InputMaybe<GroupsAudiencesRelationInput>;
  creator?: InputMaybe<GroupsCreatorRelationInput>;
  description: Scalars['String'];
  groupAdminPreferences?: InputMaybe<GroupsGroupAdminPreferencesRelationInput>;
  groupToJoin?: InputMaybe<GroupsGroupToJoinRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<GroupsLogoRelationInput>;
  members?: InputMaybe<GroupsMembersRelationInput>;
  membersCount?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<GroupsPostsRelationInput>;
  postsCount?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<GroupsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** GroupPosts create input from groupAdminPreferences */
export type GroupAdminPreferences_GroupPostCreateInput = {
  author?: InputMaybe<GroupPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<GroupPostsFlaggedByRelationInput>;
  group?: InputMaybe<GroupPostsGroupRelationInput>;
  groupAdminPreferences?: InputMaybe<GroupPostsGroupAdminPreferencesRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<GroupPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<GroupPostsMediaRelationInput>;
  mentions?: InputMaybe<GroupPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** GroupPosts update input from groupAdminPreferences */
export type GroupAdminPreferences_GroupPostUpdateInput = {
  data: GroupPostUpdateInput;
  filter?: InputMaybe<GroupPostKeyFilter>;
};

/** Groups update input from groupAdminPreferences */
export type GroupAdminPreferences_GroupUpdateInput = {
  data: GroupUpdateInput;
  filter?: InputMaybe<GroupKeyFilter>;
};

/** Users create input from groupAdminPreferences */
export type GroupAdminPreferences_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from groupAdminPreferences */
export type GroupAdminPreferences_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

export type GroupByField = {
  as?: InputMaybe<Scalars['String']>;
  fn?: InputMaybe<Array<InputMaybe<GroupByFieldFunction>>>;
};

export type GroupByFieldFunction = {
  abs?: InputMaybe<Scalars['Boolean']>;
  aggregate?: InputMaybe<AggregationFunctionType>;
  ascii?: InputMaybe<Scalars['Boolean']>;
  bitLength?: InputMaybe<Scalars['Boolean']>;
  ceil?: InputMaybe<Scalars['Boolean']>;
  charLength?: InputMaybe<Scalars['Boolean']>;
  crc32?: InputMaybe<Scalars['Boolean']>;
  datePart?: InputMaybe<DatePartFunctionType>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  floor?: InputMaybe<Scalars['Boolean']>;
  hex?: InputMaybe<Scalars['Boolean']>;
  ifNull?: InputMaybe<Scalars['String']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  left?: InputMaybe<Scalars['Int']>;
  length?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<PatternFunctionArguments>;
  locate?: InputMaybe<LocateFunctionArguments>;
  lower?: InputMaybe<Scalars['Boolean']>;
  lpad?: InputMaybe<StringPadFunctionArguments>;
  ltrim?: InputMaybe<Scalars['Boolean']>;
  mod?: InputMaybe<Scalars['Int']>;
  notLike?: InputMaybe<PatternFunctionArguments>;
  nullIf?: InputMaybe<Scalars['String']>;
  replace?: InputMaybe<ReplaceFunctionArguments>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  right?: InputMaybe<Scalars['Int']>;
  round?: InputMaybe<Scalars['Int']>;
  rpad?: InputMaybe<StringPadFunctionArguments>;
  rtrim?: InputMaybe<Scalars['Boolean']>;
  sign?: InputMaybe<Scalars['Boolean']>;
  substring?: InputMaybe<SubstringFunctionArguments>;
  trim?: InputMaybe<TrimFunctionArguments>;
  truncate?: InputMaybe<Scalars['Int']>;
  upper?: InputMaybe<Scalars['Boolean']>;
};

export type GroupByResponse = {
  __typename?: 'GroupByResponse';
  AdClickGroup: AdClickListResponse;
  AdViewGroup: AdViewListResponse;
  AddressGroup: AddressListResponse;
  AdvertisingCampaignAdGroup: AdvertisingCampaignAdListResponse;
  AdvertisingGroup: AdvertisingListResponse;
  AdvertisingSampaignGroup: AdvertisingSampaignListResponse;
  AdvertisingSupporterGroup: AdvertisingSupporterListResponse;
  ApiTokenGroup: ApiTokenListResponse;
  AudienceGroup: AudienceListResponse;
  AuthenticationProfileGroup: AuthenticationProfileListResponse;
  BigInt?: Maybe<Scalars['BigInt']>;
  BillingRegisteredAdGroup: BillingRegisteredAdListResponse;
  BillingRegisteredSchoolSupporterGroup: BillingRegisteredSchoolSupporterListResponse;
  BillingRegisteredSpecialOfferGroup: BillingRegisteredSpecialOfferListResponse;
  BillingSchoolSupporterGroup: BillingSchoolSupporterListResponse;
  BillingSpecialOfferGroup: BillingSpecialOfferListResponse;
  BillingsAdGroup: BillingsAdListResponse;
  Boolean?: Maybe<Scalars['Boolean']>;
  BusinessReportGroup: BusinessReportListResponse;
  CiCdMigrationGroup: CiCdMigrationListResponse;
  CollectiveIntelligenceThreadGroup: CollectiveIntelligenceThreadListResponse;
  ContentReportGroup: ContentReportListResponse;
  DashboardGroup: DashboardListResponse;
  Date?: Maybe<Scalars['Date']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  DebtGroup: DebtListResponse;
  DebtOperationHistoryGroup: DebtOperationHistoryListResponse;
  DiscountGroup: DiscountListResponse;
  DiscountRedemptionGroup: DiscountRedemptionListResponse;
  EducationalInstitutionGroup: EducationalInstitutionListResponse;
  EnvironmentVariableGroup: EnvironmentVariableListResponse;
  EventGroup: EventListResponse;
  EventSponsorRequestGroup: EventSponsorRequestListResponse;
  FileGroup: FileListResponse;
  FilesSettingGroup: FilesSettingListResponse;
  Float?: Maybe<Scalars['Float']>;
  GraduatingYearGroup: GraduatingYearListResponse;
  GraduatingYearsMetricGroup: GraduatingYearsMetricListResponse;
  GroupAdminPreferenceGroup: GroupAdminPreferenceListResponse;
  GroupGroup: GroupListResponse;
  GroupIds?: Maybe<Array<Scalars['ID']>>;
  GroupPostGroup: GroupPostListResponse;
  GroupsMembersRequestGroup: GroupsMembersRequestListResponse;
  GroupsStatGroup: GroupsStatListResponse;
  HighSchoolLocationGroup: HighSchoolLocationListResponse;
  HomeFeedItemGroup: HomeFeedItemListResponse;
  HomeFeedPostGroup: HomeFeedPostListResponse;
  ID?: Maybe<Scalars['ID']>;
  InboxGroup: InboxListResponse;
  InboxMessageGroup: InboxMessageListResponse;
  InboxMessageMediaGroup: InboxMessageMediaListResponse;
  Int?: Maybe<Scalars['Int']>;
  JSON?: Maybe<Scalars['JSON']>;
  JobEmployeeGroup: JobEmployeeListResponse;
  JobOfferGroup: JobOfferListResponse;
  LegacyFeedMonthGroup: LegacyFeedMonthListResponse;
  LegacyFeedPostGroup: LegacyFeedPostListResponse;
  LegacyFeedYearGroup: LegacyFeedYearListResponse;
  NotificationTemplateGroup: NotificationTemplateListResponse;
  OrganizationGroup: OrganizationListResponse;
  OrganizationLikeGroup: OrganizationLikeListResponse;
  OrganizationLocationGroup: OrganizationLocationListResponse;
  OrganizationManagerPreferenceGroup: OrganizationManagerPreferenceListResponse;
  PaymentTransactionGroup: PaymentTransactionListResponse;
  PostMentionGroup: PostMentionListResponse;
  RemoteVariableGroup: RemoteVariableListResponse;
  RoleAddonGroup: RoleAddonListResponse;
  RoleGroup: RoleListResponse;
  SchoolConfigurationColorSchemeGroup: SchoolConfigurationColorSchemeListResponse;
  SchoolConfigurationExternalLinkGroup: SchoolConfigurationExternalLinkListResponse;
  SchoolConfigurationGroup: SchoolConfigurationListResponse;
  SchoolConfigurationImageGroup: SchoolConfigurationImageListResponse;
  SchoolConfigurationLayoutGroup: SchoolConfigurationLayoutListResponse;
  SchoolConfigurationTopBarGroup: SchoolConfigurationTopBarListResponse;
  SchoolConfigurationWordsBlacklistGroup: SchoolConfigurationWordsBlacklistListResponse;
  SchoolNewsPostGroup: SchoolNewsPostListResponse;
  SchoolSupporterClickGroup: SchoolSupporterClickListResponse;
  SchoolSupporterGroup: SchoolSupporterListResponse;
  SchoolSupporterSearchGroup: SchoolSupporterSearchListResponse;
  String?: Maybe<Scalars['String']>;
  StripeInformationGroup: StripeInformationListResponse;
  TeamMemberGroup: TeamMemberListResponse;
  TrendingSearchGroup: TrendingSearchListResponse;
  UserConfigurationGroup: UserConfigurationListResponse;
  UserGroup: UserListResponse;
  UserNotificationGroup: UserNotificationListResponse;
  UserNotificationsByUserGroup: UserNotificationsByUserListResponse;
  UserPreferenceGroup: UserPreferenceListResponse;
  UserSchoolMembershipGroup: UserSchoolMembershipListResponse;
  UserStatusGroup: UserStatusListResponse;
  UserSupportersOnlyGroup: UserSupportersOnlyListResponse;
  UsersCountGroup: UsersCountListResponse;
};

export type GroupByResponseAdClickGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdClickFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdClickGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdClickOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdClickSort>>;
};

export type GroupByResponseAdViewGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdViewFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdViewGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdViewOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdViewSort>>;
};

export type GroupByResponseAddressGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AddressFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AddressGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AddressOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AddressSort>>;
};

export type GroupByResponseAdvertisingCampaignAdGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingCampaignAdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingCampaignAdGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingCampaignAdOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingCampaignAdSort>>;
};

export type GroupByResponseAdvertisingGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingSort>>;
};

export type GroupByResponseAdvertisingSampaignGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingSampaignFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingSampaignGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingSampaignOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingSampaignSort>>;
};

export type GroupByResponseAdvertisingSupporterGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingSupporterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingSupporterGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingSupporterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingSupporterSort>>;
};

export type GroupByResponseApiTokenGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ApiTokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<ApiTokenGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<ApiTokenOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ApiTokenSort>>;
};

export type GroupByResponseAudienceGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AudienceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AudienceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AudienceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AudienceSort>>;
};

export type GroupByResponseAuthenticationProfileGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AuthenticationProfileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AuthenticationProfileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AuthenticationProfileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AuthenticationProfileSort>>;
};

export type GroupByResponseBillingRegisteredAdGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingRegisteredAdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingRegisteredAdGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingRegisteredAdOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingRegisteredAdSort>>;
};

export type GroupByResponseBillingRegisteredSchoolSupporterGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingRegisteredSchoolSupporterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingRegisteredSchoolSupporterGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingRegisteredSchoolSupporterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingRegisteredSchoolSupporterSort>>;
};

export type GroupByResponseBillingRegisteredSpecialOfferGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingRegisteredSpecialOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingRegisteredSpecialOfferGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingRegisteredSpecialOfferOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingRegisteredSpecialOfferSort>>;
};

export type GroupByResponseBillingSchoolSupporterGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingSchoolSupporterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingSchoolSupporterGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingSchoolSupporterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingSchoolSupporterSort>>;
};

export type GroupByResponseBillingSpecialOfferGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingSpecialOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingSpecialOfferGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingSpecialOfferOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingSpecialOfferSort>>;
};

export type GroupByResponseBillingsAdGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingsAdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingsAdGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingsAdOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingsAdSort>>;
};

export type GroupByResponseBusinessReportGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BusinessReportFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BusinessReportGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BusinessReportOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BusinessReportSort>>;
};

export type GroupByResponseCiCdMigrationGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CiCdMigrationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<CiCdMigrationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<CiCdMigrationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<CiCdMigrationSort>>;
};

export type GroupByResponseCollectiveIntelligenceThreadGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CollectiveIntelligenceThreadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<CollectiveIntelligenceThreadGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<CollectiveIntelligenceThreadOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<CollectiveIntelligenceThreadSort>>;
};

export type GroupByResponseContentReportGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ContentReportFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<ContentReportGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContentReportOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ContentReportSort>>;
};

export type GroupByResponseDashboardGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DashboardFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DashboardGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DashboardOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DashboardSort>>;
};

export type GroupByResponseDebtGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DebtFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DebtGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DebtOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DebtSort>>;
};

export type GroupByResponseDebtOperationHistoryGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DebtOperationHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DebtOperationHistoryGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DebtOperationHistoryOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DebtOperationHistorySort>>;
};

export type GroupByResponseDiscountGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DiscountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DiscountGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DiscountOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DiscountSort>>;
};

export type GroupByResponseDiscountRedemptionGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DiscountRedemptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DiscountRedemptionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DiscountRedemptionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DiscountRedemptionSort>>;
};

export type GroupByResponseEducationalInstitutionGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EducationalInstitutionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EducationalInstitutionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EducationalInstitutionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EducationalInstitutionSort>>;
};

export type GroupByResponseEnvironmentVariableGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EnvironmentVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EnvironmentVariableGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EnvironmentVariableOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EnvironmentVariableSort>>;
};

export type GroupByResponseEventGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EventGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EventOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EventSort>>;
};

export type GroupByResponseEventSponsorRequestGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EventSponsorRequestFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EventSponsorRequestGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EventSponsorRequestOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EventSponsorRequestSort>>;
};

export type GroupByResponseFileGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FileSort>>;
};

export type GroupByResponseFilesSettingGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilesSettingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FilesSettingGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FilesSettingOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FilesSettingSort>>;
};

export type GroupByResponseGraduatingYearGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GraduatingYearFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GraduatingYearGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GraduatingYearOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GraduatingYearSort>>;
};

export type GroupByResponseGraduatingYearsMetricGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GraduatingYearsMetricFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GraduatingYearsMetricGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GraduatingYearsMetricOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GraduatingYearsMetricSort>>;
};

export type GroupByResponseGroupAdminPreferenceGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupAdminPreferenceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupAdminPreferenceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupAdminPreferenceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupAdminPreferenceSort>>;
};

export type GroupByResponseGroupGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupSort>>;
};

export type GroupByResponseGroupPostGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupPostSort>>;
};

export type GroupByResponseGroupsMembersRequestGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupsMembersRequestFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupsMembersRequestGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupsMembersRequestOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupsMembersRequestSort>>;
};

export type GroupByResponseGroupsStatGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupsStatFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupsStatGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupsStatOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupsStatSort>>;
};

export type GroupByResponseHighSchoolLocationGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HighSchoolLocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<HighSchoolLocationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<HighSchoolLocationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HighSchoolLocationSort>>;
};

export type GroupByResponseHomeFeedItemGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HomeFeedItemFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<HomeFeedItemGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<HomeFeedItemOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HomeFeedItemSort>>;
};

export type GroupByResponseHomeFeedPostGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HomeFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<HomeFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<HomeFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HomeFeedPostSort>>;
};

export type GroupByResponseInboxGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxSort>>;
};

export type GroupByResponseInboxMessageGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxMessageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxMessageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxMessageSort>>;
};

export type GroupByResponseInboxMessageMediaGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxMessageMediaFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxMessageMediaGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxMessageMediaOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxMessageMediaSort>>;
};

export type GroupByResponseJobEmployeeGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<JobEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<JobEmployeeGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<JobEmployeeOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<JobEmployeeSort>>;
};

export type GroupByResponseJobOfferGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<JobOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<JobOfferGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<JobOfferOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<JobOfferSort>>;
};

export type GroupByResponseLegacyFeedMonthGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedMonthFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedMonthGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedMonthOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedMonthSort>>;
};

export type GroupByResponseLegacyFeedPostGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedPostSort>>;
};

export type GroupByResponseLegacyFeedYearGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedYearFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedYearGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedYearOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedYearSort>>;
};

export type GroupByResponseNotificationTemplateGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<NotificationTemplateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<NotificationTemplateGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<NotificationTemplateOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<NotificationTemplateSort>>;
};

export type GroupByResponseOrganizationGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationSort>>;
};

export type GroupByResponseOrganizationLikeGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationLikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationLikeGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationLikeOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationLikeSort>>;
};

export type GroupByResponseOrganizationLocationGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationLocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationLocationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationLocationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationLocationSort>>;
};

export type GroupByResponseOrganizationManagerPreferenceGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationManagerPreferenceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationManagerPreferenceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationManagerPreferenceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationManagerPreferenceSort>>;
};

export type GroupByResponsePaymentTransactionGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PaymentTransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<PaymentTransactionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<PaymentTransactionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PaymentTransactionSort>>;
};

export type GroupByResponsePostMentionGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PostMentionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<PostMentionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<PostMentionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PostMentionSort>>;
};

export type GroupByResponseRemoteVariableGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RemoteVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RemoteVariableGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RemoteVariableOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RemoteVariableSort>>;
};

export type GroupByResponseRoleAddonGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleAddonFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleAddonGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleAddonOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleAddonSort>>;
};

export type GroupByResponseRoleGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleSort>>;
};

export type GroupByResponseSchoolConfigurationColorSchemeGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationColorSchemeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationColorSchemeGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationColorSchemeOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationColorSchemeSort>>;
};

export type GroupByResponseSchoolConfigurationExternalLinkGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationExternalLinkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationExternalLinkGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationExternalLinkOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationExternalLinkSort>>;
};

export type GroupByResponseSchoolConfigurationGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationSort>>;
};

export type GroupByResponseSchoolConfigurationImageGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationImageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationImageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationImageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationImageSort>>;
};

export type GroupByResponseSchoolConfigurationLayoutGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationLayoutFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationLayoutGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationLayoutOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationLayoutSort>>;
};

export type GroupByResponseSchoolConfigurationTopBarGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationTopBarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationTopBarGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationTopBarOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationTopBarSort>>;
};

export type GroupByResponseSchoolConfigurationWordsBlacklistGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationWordsBlacklistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationWordsBlacklistGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationWordsBlacklistOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationWordsBlacklistSort>>;
};

export type GroupByResponseSchoolNewsPostGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolNewsPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolNewsPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolNewsPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolNewsPostSort>>;
};

export type GroupByResponseSchoolSupporterClickGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolSupporterClickFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolSupporterClickGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolSupporterClickOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolSupporterClickSort>>;
};

export type GroupByResponseSchoolSupporterGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolSupporterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolSupporterGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolSupporterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolSupporterSort>>;
};

export type GroupByResponseSchoolSupporterSearchGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolSupporterSearchFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolSupporterSearchGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolSupporterSearchOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolSupporterSearchSort>>;
};

export type GroupByResponseStripeInformationGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<StripeInformationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<StripeInformationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<StripeInformationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<StripeInformationSort>>;
};

export type GroupByResponseTeamMemberGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TeamMemberFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<TeamMemberGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TeamMemberSort>>;
};

export type GroupByResponseTrendingSearchGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TrendingSearchFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<TrendingSearchGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<TrendingSearchOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TrendingSearchSort>>;
};

export type GroupByResponseUserConfigurationGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserConfigurationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserConfigurationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserConfigurationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserConfigurationSort>>;
};

export type GroupByResponseUserGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

export type GroupByResponseUserNotificationGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserNotificationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserNotificationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserNotificationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserNotificationSort>>;
};

export type GroupByResponseUserNotificationsByUserGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserNotificationsByUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserNotificationsByUserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserNotificationsByUserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserNotificationsByUserSort>>;
};

export type GroupByResponseUserPreferenceGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserPreferenceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserPreferenceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserPreferenceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserPreferenceSort>>;
};

export type GroupByResponseUserSchoolMembershipGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserSchoolMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserSchoolMembershipGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserSchoolMembershipOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSchoolMembershipSort>>;
};

export type GroupByResponseUserStatusGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserStatusFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserStatusGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserStatusOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserStatusSort>>;
};

export type GroupByResponseUserSupportersOnlyGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserSupportersOnlyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserSupportersOnlyGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserSupportersOnlyOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSupportersOnlySort>>;
};

export type GroupByResponseUsersCountGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UsersCountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UsersCountGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UsersCountOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UsersCountSort>>;
};

export type GroupBySort = {
  alias: Scalars['String'];
  direction: SortOrder;
};

/** Groups create input */
export type GroupCreateInput = {
  audiences?: InputMaybe<GroupsAudiencesRelationInput>;
  creator?: InputMaybe<GroupsCreatorRelationInput>;
  description: Scalars['String'];
  groupAdminPreferences?: InputMaybe<GroupsGroupAdminPreferencesRelationInput>;
  groupToJoin?: InputMaybe<GroupsGroupToJoinRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<GroupsLogoRelationInput>;
  members?: InputMaybe<GroupsMembersRelationInput>;
  membersCount?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<GroupsPostsRelationInput>;
  postsCount?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<GroupsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Groups create many input */
export type GroupCreateManyInput = {
  audiences?: InputMaybe<GroupsAudiencesManyRelationInput>;
  creator: GroupsCreatorManyRelationInput;
  description: Scalars['String'];
  groupAdminPreferences?: InputMaybe<GroupsGroupAdminPreferencesManyRelationInput>;
  groupToJoin?: InputMaybe<GroupsGroupToJoinManyRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<GroupsLogoManyRelationInput>;
  members?: InputMaybe<GroupsMembersManyRelationInput>;
  membersCount?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<GroupsPostsManyRelationInput>;
  postsCount?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<GroupsSchoolManyRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type GroupCreateRequestSuccess = {
  __typename?: 'GroupCreateRequestSuccess';
  groupId?: Maybe<Scalars['String']>;
};

/** Groups delete input */
export type GroupDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** GroupFieldsPermissions create input */
export type GroupFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  membersCount?: InputMaybe<Scalars['Boolean']>;
  postsCount?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type GroupFilter = {
  AND?: InputMaybe<Array<GroupFilter>>;
  OR?: InputMaybe<Array<GroupFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  audiences?: InputMaybe<AudienceRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  creator?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  groupAdminPreferences?: InputMaybe<GroupAdminPreferenceRelationFilter>;
  groupToJoin?: InputMaybe<GroupsMembersRequestRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  isApproved?: InputMaybe<BoolPredicate>;
  logo?: InputMaybe<FileFilter>;
  members?: InputMaybe<UserRelationFilter>;
  membersCount?: InputMaybe<IntPredicate>;
  posts?: InputMaybe<GroupPostRelationFilter>;
  postsCount?: InputMaybe<IntPredicate>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  status?: InputMaybe<StringPredicate>;
  title?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type GroupGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: GroupGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type GroupGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  audiences?: InputMaybe<AudienceGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  creator?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  groupAdminPreferences?: InputMaybe<GroupAdminPreferenceGroupByQuery>;
  groupToJoin?: InputMaybe<GroupsMembersRequestGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isApproved?: InputMaybe<Array<GroupByField>>;
  logo?: InputMaybe<FileGroupByQuery>;
  members?: InputMaybe<UserGroupByQuery>;
  membersCount?: InputMaybe<Array<GroupByField>>;
  posts?: InputMaybe<GroupPostGroupByQuery>;
  postsCount?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  title?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type GroupIdentifiersGroupByField = {
  as: Scalars['String'];
};

export type GroupKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** GroupListResponse output */
export type GroupListResponse = {
  __typename?: 'GroupListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Group>;
};

/** GroupManyResponse output */
export type GroupManyResponse = {
  __typename?: 'GroupManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Group>;
};

/** No longer supported. Use `sort` instead. */
export type GroupOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'description_ASC'
  | 'description_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isApproved_ASC'
  | 'isApproved_DESC'
  | 'membersCount_ASC'
  | 'membersCount_DESC'
  | 'postsCount_ASC'
  | 'postsCount_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'title_ASC'
  | 'title_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** Groups subscription payload */
export type GroupPayload = {
  __typename?: 'GroupPayload';
  mutation: MutationType;
  node?: Maybe<Group>;
  previousValues?: Maybe<Group>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GroupPost = {
  __typename?: 'GroupPost';
  _description?: Maybe<Scalars['String']>;
  author?: Maybe<User>;
  commentsAllowed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  flagReason?: Maybe<Scalars['String']>;
  flaggedAt?: Maybe<Scalars['DateTime']>;
  flaggedBy?: Maybe<UserListResponse>;
  group?: Maybe<Group>;
  groupAdminPreferences?: Maybe<GroupAdminPreference>;
  id?: Maybe<Scalars['ID']>;
  isAutoReported?: Maybe<Scalars['Boolean']>;
  isFlagged?: Maybe<Scalars['Boolean']>;
  isPinned?: Maybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: Maybe<Scalars['Boolean']>;
  lastFlaggedBy?: Maybe<User>;
  /** Media files: [video, media] */
  media?: Maybe<FileListResponse>;
  mentions?: Maybe<PostMentionListResponse>;
  reportStatus?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupPostFlaggedByArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

export type GroupPostMediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FileSort>>;
};

export type GroupPostMentionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PostMentionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<PostMentionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<PostMentionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PostMentionSort>>;
};

/** GroupPosts create input */
export type GroupPostCreateInput = {
  author?: InputMaybe<GroupPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<GroupPostsFlaggedByRelationInput>;
  group?: InputMaybe<GroupPostsGroupRelationInput>;
  groupAdminPreferences?: InputMaybe<GroupPostsGroupAdminPreferencesRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<GroupPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<GroupPostsMediaRelationInput>;
  mentions?: InputMaybe<GroupPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** GroupPosts create many input */
export type GroupPostCreateManyInput = {
  author: GroupPostsAuthorManyRelationInput;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<GroupPostsFlaggedByManyRelationInput>;
  group: GroupPostsGroupManyRelationInput;
  groupAdminPreferences?: InputMaybe<GroupPostsGroupAdminPreferencesManyRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<GroupPostsLastFlaggedByManyRelationInput>;
  media?: InputMaybe<GroupPostsMediaManyRelationInput>;
  mentions?: InputMaybe<GroupPostsMentionsManyRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** GroupPosts delete input */
export type GroupPostDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** GroupPostFieldsPermissions create input */
export type GroupPostFieldsPermissions = {
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['Boolean']>;
  flaggedAt?: InputMaybe<Scalars['Boolean']>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  reportStatus?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type GroupPostFilter = {
  AND?: InputMaybe<Array<GroupPostFilter>>;
  OR?: InputMaybe<Array<GroupPostFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<UserFilter>;
  commentsAllowed?: InputMaybe<BoolPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  flagReason?: InputMaybe<StringPredicate>;
  flaggedAt?: InputMaybe<DateTimePredicate>;
  flaggedBy?: InputMaybe<UserRelationFilter>;
  group?: InputMaybe<GroupFilter>;
  groupAdminPreferences?: InputMaybe<GroupAdminPreferenceFilter>;
  id?: InputMaybe<IdPredicate>;
  isAutoReported?: InputMaybe<BoolPredicate>;
  isFlagged?: InputMaybe<BoolPredicate>;
  isPinned?: InputMaybe<BoolPredicate>;
  isReviewed?: InputMaybe<BoolPredicate>;
  lastFlaggedBy?: InputMaybe<UserFilter>;
  media?: InputMaybe<FileRelationFilter>;
  mentions?: InputMaybe<PostMentionRelationFilter>;
  reportStatus?: InputMaybe<StringPredicate>;
  status?: InputMaybe<StringPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type GroupPostGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: GroupPostGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type GroupPostGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  author?: InputMaybe<UserGroupByQuery>;
  commentsAllowed?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  flagReason?: InputMaybe<Array<GroupByField>>;
  flaggedAt?: InputMaybe<Array<GroupByField>>;
  flaggedBy?: InputMaybe<UserGroupByQuery>;
  group?: InputMaybe<GroupGroupByQuery>;
  groupAdminPreferences?: InputMaybe<GroupAdminPreferenceGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isAutoReported?: InputMaybe<Array<GroupByField>>;
  isFlagged?: InputMaybe<Array<GroupByField>>;
  isPinned?: InputMaybe<Array<GroupByField>>;
  isReviewed?: InputMaybe<Array<GroupByField>>;
  lastFlaggedBy?: InputMaybe<UserGroupByQuery>;
  media?: InputMaybe<FileGroupByQuery>;
  mentions?: InputMaybe<PostMentionGroupByQuery>;
  reportStatus?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  text?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type GroupPostKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** GroupPostListResponse output */
export type GroupPostListResponse = {
  __typename?: 'GroupPostListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<GroupPost>;
};

/** GroupPostManyResponse output */
export type GroupPostManyResponse = {
  __typename?: 'GroupPostManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<GroupPost>;
};

/** No longer supported. Use `sort` instead. */
export type GroupPostOrderBy =
  | 'commentsAllowed_ASC'
  | 'commentsAllowed_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'flagReason_ASC'
  | 'flagReason_DESC'
  | 'flaggedAt_ASC'
  | 'flaggedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isAutoReported_ASC'
  | 'isAutoReported_DESC'
  | 'isFlagged_ASC'
  | 'isFlagged_DESC'
  | 'isPinned_ASC'
  | 'isPinned_DESC'
  | 'isReviewed_ASC'
  | 'isReviewed_DESC'
  | 'reportStatus_ASC'
  | 'reportStatus_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'text_ASC'
  | 'text_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** GroupPosts subscription payload */
export type GroupPostPayload = {
  __typename?: 'GroupPostPayload';
  mutation: MutationType;
  node?: Maybe<GroupPost>;
  previousValues?: Maybe<GroupPost>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GroupPostRelationFilter = {
  every?: InputMaybe<GroupPostFilter>;
  none?: InputMaybe<GroupPostFilter>;
  some?: InputMaybe<GroupPostFilter>;
};

export type GroupPostSort = {
  author?: InputMaybe<UserSort>;
  commentsAllowed?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  flagReason?: InputMaybe<SortOrder>;
  flaggedAt?: InputMaybe<SortOrder>;
  group?: InputMaybe<GroupSort>;
  groupAdminPreferences?: InputMaybe<GroupAdminPreferenceSort>;
  id?: InputMaybe<SortOrder>;
  isAutoReported?: InputMaybe<SortOrder>;
  isFlagged?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isReviewed?: InputMaybe<SortOrder>;
  lastFlaggedBy?: InputMaybe<UserSort>;
  reportStatus?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** GroupPosts subscription filter */
export type GroupPostSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<GroupPostFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** GroupPosts update input */
export type GroupPostUpdateByFilterInput = {
  commentsAllowed?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  flagReason?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  flaggedAt?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  isAutoReported?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isFlagged?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isPinned?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isReviewed?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  reportStatus?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  text?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** GroupPosts update input */
export type GroupPostUpdateInput = {
  author?: InputMaybe<GroupPostsAuthorUpdateRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<GroupPostsFlaggedByUpdateRelationInput>;
  group?: InputMaybe<GroupPostsGroupUpdateRelationInput>;
  groupAdminPreferences?: InputMaybe<GroupPostsGroupAdminPreferencesUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<GroupPostsLastFlaggedByUpdateRelationInput>;
  media?: InputMaybe<GroupPostsMediaUpdateRelationInput>;
  mentions?: InputMaybe<GroupPostsMentionsUpdateRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type GroupPost_PermissionFilter = {
  AND?: InputMaybe<Array<GroupPost_PermissionFilter>>;
  OR?: InputMaybe<Array<GroupPost_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<User_PermissionFilter>;
  commentsAllowed?: InputMaybe<BoolPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  flagReason?: InputMaybe<StringPredicate>;
  flaggedAt?: InputMaybe<DateTimePredicate>;
  flaggedBy?: InputMaybe<User_PermissionRelationFilter>;
  group?: InputMaybe<Group_PermissionFilter>;
  groupAdminPreferences?: InputMaybe<GroupAdminPreference_PermissionFilter>;
  id?: InputMaybe<IdPredicate>;
  isAutoReported?: InputMaybe<BoolPredicate>;
  isFlagged?: InputMaybe<BoolPredicate>;
  isPinned?: InputMaybe<BoolPredicate>;
  isReviewed?: InputMaybe<BoolPredicate>;
  lastFlaggedBy?: InputMaybe<User_PermissionFilter>;
  media?: InputMaybe<File_PermissionRelationFilter>;
  mentions?: InputMaybe<PostMention_PermissionRelationFilter>;
  reportStatus?: InputMaybe<StringPredicate>;
  status?: InputMaybe<StringPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type GroupPost_PermissionRelationFilter = {
  every?: InputMaybe<GroupPost_PermissionFilter>;
  none?: InputMaybe<GroupPost_PermissionFilter>;
  some?: InputMaybe<GroupPost_PermissionFilter>;
};

/** PostMentions create input from groupPost */
export type GroupPost_PostMentionCreateInput = {
  groupPost?: InputMaybe<PostMentionsGroupPostRelationInput>;
  homePost?: InputMaybe<PostMentionsHomePostRelationInput>;
  legacyPost?: InputMaybe<PostMentionsLegacyPostRelationInput>;
  legacyPostId?: InputMaybe<Scalars['String']>;
  mentionId?: InputMaybe<Scalars['String']>;
  mentionText?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  schoolPost?: InputMaybe<PostMentionsSchoolPostRelationInput>;
  type?: InputMaybe<Scalars['String']>;
};

/** PostMentions update input from groupPost */
export type GroupPost_PostMentionUpdateInput = {
  data: PostMentionUpdateInput;
  filter?: InputMaybe<PostMentionKeyFilter>;
};

/** GroupPosts relation input */
export type GroupPostsAuthorManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** GroupPosts relation input */
export type GroupPostsAuthorRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<GroupPosts_UserCreateInput>;
};

/** GroupPosts relation input */
export type GroupPostsAuthorUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<GroupPosts_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<GroupPosts_UserUpdateInput>;
};

/** GroupPosts relation input */
export type GroupPostsFlaggedByManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** GroupPosts relation input */
export type GroupPostsFlaggedByRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedGroupPosts_UserCreateInput>>>;
};

/** GroupPosts relation input */
export type GroupPostsFlaggedByUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedGroupPosts_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<FlaggedGroupPosts_UserUpdateInput>>>;
};

/** GroupPosts relation input */
export type GroupPostsGroupAdminPreferencesManyRelationInput = {
  connect?: InputMaybe<GroupAdminPreferenceKeyFilter>;
};

/** GroupPosts relation input */
export type GroupPostsGroupAdminPreferencesRelationInput = {
  connect?: InputMaybe<GroupAdminPreferenceKeyFilter>;
  create?: InputMaybe<GroupPosts_GroupAdminPreferenceCreateInput>;
};

/** GroupPosts relation input */
export type GroupPostsGroupAdminPreferencesUpdateRelationInput = {
  connect?: InputMaybe<GroupAdminPreferenceKeyFilter>;
  create?: InputMaybe<GroupPosts_GroupAdminPreferenceCreateInput>;
  disconnect?: InputMaybe<GroupAdminPreferenceKeyFilter>;
  reconnect?: InputMaybe<GroupAdminPreferenceKeyFilter>;
  update?: InputMaybe<GroupPosts_GroupAdminPreferenceUpdateInput>;
};

/** GroupPosts relation input */
export type GroupPostsGroupManyRelationInput = {
  connect?: InputMaybe<GroupKeyFilter>;
};

/** GroupPosts relation input */
export type GroupPostsGroupRelationInput = {
  connect?: InputMaybe<GroupKeyFilter>;
  create?: InputMaybe<Posts_GroupCreateInput>;
};

/** GroupPosts relation input */
export type GroupPostsGroupUpdateRelationInput = {
  connect?: InputMaybe<GroupKeyFilter>;
  create?: InputMaybe<Posts_GroupCreateInput>;
  disconnect?: InputMaybe<GroupKeyFilter>;
  reconnect?: InputMaybe<GroupKeyFilter>;
  update?: InputMaybe<Posts_GroupUpdateInput>;
};

/** GroupPosts relation input */
export type GroupPostsLastFlaggedByManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** GroupPosts relation input */
export type GroupPostsLastFlaggedByRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<LastFlaggedGroupPost_UserCreateInput>;
};

/** GroupPosts relation input */
export type GroupPostsLastFlaggedByUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<LastFlaggedGroupPost_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<LastFlaggedGroupPost_UserUpdateInput>;
};

/** GroupPosts relation input */
export type GroupPostsMediaManyRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
};

/** GroupPosts relation input */
export type GroupPostsMediaRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<GroupPosts_Media_FileCreateInput>>>;
};

/** GroupPosts relation input */
export type GroupPostsMediaUpdateRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<GroupPosts_Media_FileCreateInput>>>;
  disconnect?: InputMaybe<Array<FileKeyFilter>>;
  reconnect?: InputMaybe<Array<FileKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<GroupPosts_Media_FileUpdateInput>>>;
};

/** GroupPosts relation input */
export type GroupPostsMentionsManyRelationInput = {
  connect?: InputMaybe<Array<PostMentionKeyFilter>>;
};

/** GroupPosts relation input */
export type GroupPostsMentionsRelationInput = {
  connect?: InputMaybe<Array<PostMentionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<GroupPost_PostMentionCreateInput>>>;
};

/** GroupPosts relation input */
export type GroupPostsMentionsUpdateRelationInput = {
  connect?: InputMaybe<Array<PostMentionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<GroupPost_PostMentionCreateInput>>>;
  disconnect?: InputMaybe<Array<PostMentionKeyFilter>>;
  reconnect?: InputMaybe<Array<PostMentionKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<GroupPost_PostMentionUpdateInput>>>;
};

/** GroupAdminPreferences create input from groupPosts */
export type GroupPosts_GroupAdminPreferenceCreateInput = {
  group?: InputMaybe<GroupAdminPreferencesGroupRelationInput>;
  groupPosts?: InputMaybe<GroupAdminPreferencesGroupPostsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<GroupAdminPreferencesUserRelationInput>;
};

/** GroupAdminPreferences update input from groupPosts */
export type GroupPosts_GroupAdminPreferenceUpdateInput = {
  group?: InputMaybe<GroupAdminPreferencesGroupUpdateRelationInput>;
  groupPosts?: InputMaybe<GroupAdminPreferencesGroupPostsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<GroupAdminPreferencesUserUpdateRelationInput>;
};

/** Users create input from groupPosts */
export type GroupPosts_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from groupPosts */
export type GroupPosts_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Files create input from groupPosts_media */
export type GroupPosts_Media_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from groupPosts_media */
export type GroupPosts_Media_FileUpdateInput = {
  data: FileUpdateInput;
  filter?: InputMaybe<FileKeyFilter>;
};

export type GroupRelationFilter = {
  every?: InputMaybe<GroupFilter>;
  none?: InputMaybe<GroupFilter>;
  some?: InputMaybe<GroupFilter>;
};

export type GroupSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  creator?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isApproved?: InputMaybe<SortOrder>;
  logo?: InputMaybe<FileSort>;
  membersCount?: InputMaybe<SortOrder>;
  postsCount?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Groups subscription filter */
export type GroupSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<GroupFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** Groups create input from groupToJoin */
export type GroupToJoin_GroupCreateInput = {
  audiences?: InputMaybe<GroupsAudiencesRelationInput>;
  creator?: InputMaybe<GroupsCreatorRelationInput>;
  description: Scalars['String'];
  groupAdminPreferences?: InputMaybe<GroupsGroupAdminPreferencesRelationInput>;
  groupToJoin?: InputMaybe<GroupsGroupToJoinRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<GroupsLogoRelationInput>;
  members?: InputMaybe<GroupsMembersRelationInput>;
  membersCount?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<GroupsPostsRelationInput>;
  postsCount?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<GroupsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Groups update input from groupToJoin */
export type GroupToJoin_GroupUpdateInput = {
  audiences?: InputMaybe<GroupsAudiencesUpdateRelationInput>;
  creator?: InputMaybe<GroupsCreatorUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  groupAdminPreferences?: InputMaybe<GroupsGroupAdminPreferencesUpdateRelationInput>;
  groupToJoin?: InputMaybe<GroupsGroupToJoinUpdateRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<GroupsLogoUpdateRelationInput>;
  members?: InputMaybe<GroupsMembersUpdateRelationInput>;
  membersCount?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<GroupsPostsUpdateRelationInput>;
  postsCount?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<GroupsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Groups update input */
export type GroupUpdateByFilterInput = {
  description?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  isApproved?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  membersCount?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  postsCount?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  title?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** Groups update input */
export type GroupUpdateInput = {
  audiences?: InputMaybe<GroupsAudiencesUpdateRelationInput>;
  creator?: InputMaybe<GroupsCreatorUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  groupAdminPreferences?: InputMaybe<GroupsGroupAdminPreferencesUpdateRelationInput>;
  groupToJoin?: InputMaybe<GroupsGroupToJoinUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<GroupsLogoUpdateRelationInput>;
  members?: InputMaybe<GroupsMembersUpdateRelationInput>;
  membersCount?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<GroupsPostsUpdateRelationInput>;
  postsCount?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<GroupsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** GroupAdminPreferences create input from group */
export type Group_GroupAdminPreferenceCreateInput = {
  group?: InputMaybe<GroupAdminPreferencesGroupRelationInput>;
  groupPosts?: InputMaybe<GroupAdminPreferencesGroupPostsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<GroupAdminPreferencesUserRelationInput>;
};

/** GroupAdminPreferences update input from group */
export type Group_GroupAdminPreferenceUpdateInput = {
  data: GroupAdminPreferenceUpdateInput;
  filter?: InputMaybe<GroupAdminPreferenceKeyFilter>;
};

/** GroupPosts create input from group */
export type Group_GroupPostCreateInput = {
  author?: InputMaybe<GroupPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<GroupPostsFlaggedByRelationInput>;
  group?: InputMaybe<GroupPostsGroupRelationInput>;
  groupAdminPreferences?: InputMaybe<GroupPostsGroupAdminPreferencesRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<GroupPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<GroupPostsMediaRelationInput>;
  mentions?: InputMaybe<GroupPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** GroupPosts update input from group */
export type Group_GroupPostUpdateInput = {
  data: GroupPostUpdateInput;
  filter?: InputMaybe<GroupPostKeyFilter>;
};

/** GroupsMembersRequests create input from group */
export type Group_GroupsMembersRequestCreateInput = {
  group?: InputMaybe<GroupsMembersRequestsGroupRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<GroupsMembersRequestsUserRelationInput>;
};

/** GroupsMembersRequests update input from group */
export type Group_GroupsMembersRequestUpdateInput = {
  data: GroupsMembersRequestUpdateInput;
  filter?: InputMaybe<GroupsMembersRequestKeyFilter>;
};

export type Group_PermissionFilter = {
  AND?: InputMaybe<Array<Group_PermissionFilter>>;
  OR?: InputMaybe<Array<Group_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  audiences?: InputMaybe<Audience_PermissionRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  creator?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  groupAdminPreferences?: InputMaybe<GroupAdminPreference_PermissionRelationFilter>;
  groupToJoin?: InputMaybe<GroupsMembersRequest_PermissionRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  isApproved?: InputMaybe<BoolPredicate>;
  logo?: InputMaybe<File_PermissionFilter>;
  members?: InputMaybe<User_PermissionRelationFilter>;
  membersCount?: InputMaybe<IntPredicate>;
  posts?: InputMaybe<GroupPost_PermissionRelationFilter>;
  postsCount?: InputMaybe<IntPredicate>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  status?: InputMaybe<StringPredicate>;
  title?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type Group_PermissionRelationFilter = {
  every?: InputMaybe<Group_PermissionFilter>;
  none?: InputMaybe<Group_PermissionFilter>;
  some?: InputMaybe<Group_PermissionFilter>;
};

/** Groups relation input */
export type GroupsAudiencesManyRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
};

/** Groups relation input */
export type GroupsAudiencesRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Groups_AudienceCreateInput>>>;
};

/** Groups relation input */
export type GroupsAudiencesUpdateRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Groups_AudienceCreateInput>>>;
  disconnect?: InputMaybe<Array<AudienceKeyFilter>>;
  reconnect?: InputMaybe<Array<AudienceKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Groups_AudienceUpdateInput>>>;
};

/** Groups relation input */
export type GroupsCreatorManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** Groups relation input */
export type GroupsCreatorRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<CreatedGroups_UserCreateInput>;
};

/** Groups relation input */
export type GroupsCreatorUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<CreatedGroups_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<CreatedGroups_UserUpdateInput>;
};

/** Groups relation input */
export type GroupsGroupAdminPreferencesManyRelationInput = {
  connect?: InputMaybe<Array<GroupAdminPreferenceKeyFilter>>;
};

/** Groups relation input */
export type GroupsGroupAdminPreferencesRelationInput = {
  connect?: InputMaybe<Array<GroupAdminPreferenceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Group_GroupAdminPreferenceCreateInput>>>;
};

/** Groups relation input */
export type GroupsGroupAdminPreferencesUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupAdminPreferenceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Group_GroupAdminPreferenceCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupAdminPreferenceKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupAdminPreferenceKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Group_GroupAdminPreferenceUpdateInput>>>;
};

/** Groups relation input */
export type GroupsGroupToJoinManyRelationInput = {
  connect?: InputMaybe<Array<GroupsMembersRequestKeyFilter>>;
};

/** Groups relation input */
export type GroupsGroupToJoinRelationInput = {
  connect?: InputMaybe<Array<GroupsMembersRequestKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Group_GroupsMembersRequestCreateInput>>>;
};

/** Groups relation input */
export type GroupsGroupToJoinUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupsMembersRequestKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Group_GroupsMembersRequestCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupsMembersRequestKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupsMembersRequestKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Group_GroupsMembersRequestUpdateInput>>>;
};

/** Groups relation input */
export type GroupsLogoManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** Groups relation input */
export type GroupsLogoRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<Groups_Logo_FileCreateInput>;
};

/** Groups relation input */
export type GroupsLogoUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<Groups_Logo_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<Groups_Logo_FileUpdateInput>;
};

/** Groups relation input */
export type GroupsMembersManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** Groups relation input */
export type GroupsMembersRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<JoinedGroups_UserCreateInput>>>;
};

export type GroupsMembersRequest = {
  __typename?: 'GroupsMembersRequest';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  group?: Maybe<Group>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

/** GroupsMembersRequests create input */
export type GroupsMembersRequestCreateInput = {
  group?: InputMaybe<GroupsMembersRequestsGroupRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<GroupsMembersRequestsUserRelationInput>;
};

/** GroupsMembersRequests create many input */
export type GroupsMembersRequestCreateManyInput = {
  group?: InputMaybe<GroupsMembersRequestsGroupManyRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<GroupsMembersRequestsUserManyRelationInput>;
};

/** GroupsMembersRequests delete input */
export type GroupsMembersRequestDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** GroupsMembersRequestFieldsPermissions create input */
export type GroupsMembersRequestFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type GroupsMembersRequestFilter = {
  AND?: InputMaybe<Array<GroupsMembersRequestFilter>>;
  OR?: InputMaybe<Array<GroupsMembersRequestFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  group?: InputMaybe<GroupFilter>;
  id?: InputMaybe<IdPredicate>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type GroupsMembersRequestGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: GroupsMembersRequestGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type GroupsMembersRequestGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  group?: InputMaybe<GroupGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type GroupsMembersRequestKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** GroupsMembersRequestListResponse output */
export type GroupsMembersRequestListResponse = {
  __typename?: 'GroupsMembersRequestListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<GroupsMembersRequest>;
};

/** GroupsMembersRequestManyResponse output */
export type GroupsMembersRequestManyResponse = {
  __typename?: 'GroupsMembersRequestManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<GroupsMembersRequest>;
};

/** No longer supported. Use `sort` instead. */
export type GroupsMembersRequestOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** GroupsMembersRequests subscription payload */
export type GroupsMembersRequestPayload = {
  __typename?: 'GroupsMembersRequestPayload';
  mutation: MutationType;
  node?: Maybe<GroupsMembersRequest>;
  previousValues?: Maybe<GroupsMembersRequest>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GroupsMembersRequestRelationFilter = {
  every?: InputMaybe<GroupsMembersRequestFilter>;
  none?: InputMaybe<GroupsMembersRequestFilter>;
  some?: InputMaybe<GroupsMembersRequestFilter>;
};

export type GroupsMembersRequestSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  group?: InputMaybe<GroupSort>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

/** GroupsMembersRequests subscription filter */
export type GroupsMembersRequestSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<GroupsMembersRequestFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** GroupsMembersRequests update input */
export type GroupsMembersRequestUpdateByFilterInput = {
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
};

/** GroupsMembersRequests update input */
export type GroupsMembersRequestUpdateInput = {
  group?: InputMaybe<GroupsMembersRequestsGroupUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<GroupsMembersRequestsUserUpdateRelationInput>;
};

export type GroupsMembersRequest_PermissionFilter = {
  AND?: InputMaybe<Array<GroupsMembersRequest_PermissionFilter>>;
  OR?: InputMaybe<Array<GroupsMembersRequest_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  group?: InputMaybe<Group_PermissionFilter>;
  id?: InputMaybe<IdPredicate>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
};

export type GroupsMembersRequest_PermissionRelationFilter = {
  every?: InputMaybe<GroupsMembersRequest_PermissionFilter>;
  none?: InputMaybe<GroupsMembersRequest_PermissionFilter>;
  some?: InputMaybe<GroupsMembersRequest_PermissionFilter>;
};

/** GroupsMembersRequests relation input */
export type GroupsMembersRequestsGroupManyRelationInput = {
  connect?: InputMaybe<GroupKeyFilter>;
};

/** GroupsMembersRequests relation input */
export type GroupsMembersRequestsGroupRelationInput = {
  connect?: InputMaybe<GroupKeyFilter>;
  create?: InputMaybe<GroupToJoin_GroupCreateInput>;
};

/** GroupsMembersRequests relation input */
export type GroupsMembersRequestsGroupUpdateRelationInput = {
  connect?: InputMaybe<GroupKeyFilter>;
  create?: InputMaybe<GroupToJoin_GroupCreateInput>;
  disconnect?: InputMaybe<GroupKeyFilter>;
  reconnect?: InputMaybe<GroupKeyFilter>;
  update?: InputMaybe<GroupToJoin_GroupUpdateInput>;
};

/** GroupsMembersRequests relation input */
export type GroupsMembersRequestsUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** GroupsMembersRequests relation input */
export type GroupsMembersRequestsUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<UserJoining_UserCreateInput>;
};

/** GroupsMembersRequests relation input */
export type GroupsMembersRequestsUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<UserJoining_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<UserJoining_UserUpdateInput>;
};

/** Groups relation input */
export type GroupsMembersUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<JoinedGroups_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<JoinedGroups_UserUpdateInput>>>;
};

export type GroupsMembersUpsertResponse = {
  __typename?: 'GroupsMembersUpsertResponse';
  message: Scalars['String'];
  successful: Scalars['Boolean'];
};

/** Groups relation input */
export type GroupsPostsManyRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
};

/** Groups relation input */
export type GroupsPostsRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Group_GroupPostCreateInput>>>;
};

/** Groups relation input */
export type GroupsPostsUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Group_GroupPostCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupPostKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Group_GroupPostUpdateInput>>>;
};

/** Groups relation input */
export type GroupsSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** Groups relation input */
export type GroupsSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<Groups_SchoolConfigurationCreateInput>;
};

/** Groups relation input */
export type GroupsSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<Groups_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<Groups_SchoolConfigurationUpdateInput>;
};

export type GroupsStat = {
  __typename?: 'GroupsStat';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  totalGroups?: Maybe<Scalars['Int']>;
  totalGroupsMembers?: Maybe<Scalars['Int']>;
  totalGroupsPending?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** GroupsStats create input */
export type GroupsStatCreateInput = {
  totalGroups?: InputMaybe<Scalars['Int']>;
  totalGroupsMembers?: InputMaybe<Scalars['Int']>;
  totalGroupsPending?: InputMaybe<Scalars['Int']>;
};

/** GroupsStats create many input */
export type GroupsStatCreateManyInput = {
  totalGroups?: InputMaybe<Scalars['Int']>;
  totalGroupsMembers?: InputMaybe<Scalars['Int']>;
  totalGroupsPending?: InputMaybe<Scalars['Int']>;
};

/** GroupsStats delete input */
export type GroupsStatDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** GroupsStatFieldsPermissions create input */
export type GroupsStatFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  totalGroups?: InputMaybe<Scalars['Boolean']>;
  totalGroupsMembers?: InputMaybe<Scalars['Boolean']>;
  totalGroupsPending?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type GroupsStatFilter = {
  AND?: InputMaybe<Array<GroupsStatFilter>>;
  OR?: InputMaybe<Array<GroupsStatFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  totalGroups?: InputMaybe<IntPredicate>;
  totalGroupsMembers?: InputMaybe<IntPredicate>;
  totalGroupsPending?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type GroupsStatGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: GroupsStatGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type GroupsStatGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  totalGroups?: InputMaybe<Array<GroupByField>>;
  totalGroupsMembers?: InputMaybe<Array<GroupByField>>;
  totalGroupsPending?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type GroupsStatKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** GroupsStatListResponse output */
export type GroupsStatListResponse = {
  __typename?: 'GroupsStatListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<GroupsStat>;
};

/** GroupsStatManyResponse output */
export type GroupsStatManyResponse = {
  __typename?: 'GroupsStatManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<GroupsStat>;
};

/** No longer supported. Use `sort` instead. */
export type GroupsStatOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'totalGroupsMembers_ASC'
  | 'totalGroupsMembers_DESC'
  | 'totalGroupsPending_ASC'
  | 'totalGroupsPending_DESC'
  | 'totalGroups_ASC'
  | 'totalGroups_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** GroupsStats subscription payload */
export type GroupsStatPayload = {
  __typename?: 'GroupsStatPayload';
  mutation: MutationType;
  node?: Maybe<GroupsStat>;
  previousValues?: Maybe<GroupsStat>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GroupsStatSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  totalGroups?: InputMaybe<SortOrder>;
  totalGroupsMembers?: InputMaybe<SortOrder>;
  totalGroupsPending?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** GroupsStats subscription filter */
export type GroupsStatSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<GroupsStatFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** GroupsStats update input */
export type GroupsStatUpdateByFilterInput = {
  totalGroups?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  totalGroupsMembers?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  totalGroupsPending?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
};

/** GroupsStats update input */
export type GroupsStatUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  totalGroups?: InputMaybe<Scalars['Int']>;
  totalGroupsMembers?: InputMaybe<Scalars['Int']>;
  totalGroupsPending?: InputMaybe<Scalars['Int']>;
};

export type GroupsStat_PermissionFilter = {
  AND?: InputMaybe<Array<GroupsStat_PermissionFilter>>;
  OR?: InputMaybe<Array<GroupsStat_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  totalGroups?: InputMaybe<IntPredicate>;
  totalGroupsMembers?: InputMaybe<IntPredicate>;
  totalGroupsPending?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

/** Audiences create input from groups */
export type Groups_AudienceCreateInput = {
  ad?: InputMaybe<AudiencesAdRelationInput>;
  affiliation?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  distanceFromZip?: InputMaybe<Scalars['Int']>;
  endAge?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  groups?: InputMaybe<AudiencesGroupsRelationInput>;
  highSchools?: InputMaybe<AudiencesHighSchoolsRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  organization?: InputMaybe<AudiencesOrganizationRelationInput>;
  schoolCommunities?: InputMaybe<AudiencesSchoolCommunitiesRelationInput>;
  size?: InputMaybe<Scalars['Int']>;
  startAge?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zipCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Audiences update input from groups */
export type Groups_AudienceUpdateInput = {
  data: AudienceUpdateInput;
  filter?: InputMaybe<AudienceKeyFilter>;
};

/** SchoolConfiguration create input from groups */
export type Groups_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from groups */
export type Groups_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** Files create input from groups_logo */
export type Groups_Logo_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from groups_logo */
export type Groups_Logo_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

export type Having = {
  AND?: InputMaybe<Array<Having>>;
  OR?: InputMaybe<Array<Having>>;
  alias?: InputMaybe<Scalars['String']>;
  bigint?: InputMaybe<BigIntPredicateHaving>;
  bool?: InputMaybe<BoolPredicateHaving>;
  date?: InputMaybe<DatePredicateHaving>;
  datetime?: InputMaybe<DateTimePredicateHaving>;
  float?: InputMaybe<FloatPredicateHaving>;
  id?: InputMaybe<IdPredicateHaving>;
  int?: InputMaybe<IntPredicateHaving>;
  string?: InputMaybe<StringPredicateHaving>;
};

/** Users create input from hiddenInboxMessages */
export type HiddenInboxMessages_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from hiddenInboxMessages */
export type HiddenInboxMessages_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

/** Users create input from hiddenInboxes */
export type HiddenInboxes_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from hiddenInboxes */
export type HiddenInboxes_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

export type HighSchoolLocation = {
  __typename?: 'HighSchoolLocation';
  _description?: Maybe<Scalars['String']>;
  audiences?: Maybe<AudienceListResponse>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  educations?: Maybe<EducationalInstitutionListResponse>;
  id?: Maybe<Scalars['ID']>;
  /** Logo of the High School */
  logo?: Maybe<File>;
  logoId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zip?: Maybe<Scalars['String']>;
};

export type HighSchoolLocationAudiencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AudienceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AudienceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AudienceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AudienceSort>>;
};

export type HighSchoolLocationEducationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EducationalInstitutionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EducationalInstitutionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EducationalInstitutionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EducationalInstitutionSort>>;
};

/** HighSchoolLocations create input */
export type HighSchoolLocationCreateInput = {
  audiences?: InputMaybe<HighSchoolLocationsAudiencesRelationInput>;
  city?: InputMaybe<Scalars['String']>;
  educations?: InputMaybe<HighSchoolLocationsEducationsRelationInput>;
  logo?: InputMaybe<HighSchoolLocationsLogoRelationInput>;
  logoId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** HighSchoolLocations create many input */
export type HighSchoolLocationCreateManyInput = {
  audiences?: InputMaybe<HighSchoolLocationsAudiencesManyRelationInput>;
  city?: InputMaybe<Scalars['String']>;
  educations?: InputMaybe<HighSchoolLocationsEducationsManyRelationInput>;
  logo?: InputMaybe<HighSchoolLocationsLogoManyRelationInput>;
  logoId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** HighSchoolLocations delete input */
export type HighSchoolLocationDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** HighSchoolLocationFieldsPermissions create input */
export type HighSchoolLocationFieldsPermissions = {
  city?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  logoId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['Boolean']>;
  street?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  zip?: InputMaybe<Scalars['Boolean']>;
};

export type HighSchoolLocationFilter = {
  AND?: InputMaybe<Array<HighSchoolLocationFilter>>;
  OR?: InputMaybe<Array<HighSchoolLocationFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  audiences?: InputMaybe<AudienceRelationFilter>;
  city?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  educations?: InputMaybe<EducationalInstitutionRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  logo?: InputMaybe<FileFilter>;
  logoId?: InputMaybe<StringPredicate>;
  name?: InputMaybe<StringPredicate>;
  state?: InputMaybe<StringPredicate>;
  street?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  zip?: InputMaybe<StringPredicate>;
};

export type HighSchoolLocationGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: HighSchoolLocationGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type HighSchoolLocationGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  audiences?: InputMaybe<AudienceGroupByQuery>;
  city?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  educations?: InputMaybe<EducationalInstitutionGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  logo?: InputMaybe<FileGroupByQuery>;
  logoId?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  state?: InputMaybe<Array<GroupByField>>;
  street?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  zip?: InputMaybe<Array<GroupByField>>;
};

export type HighSchoolLocationKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** HighSchoolLocationListResponse output */
export type HighSchoolLocationListResponse = {
  __typename?: 'HighSchoolLocationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<HighSchoolLocation>;
};

/** HighSchoolLocationManyResponse output */
export type HighSchoolLocationManyResponse = {
  __typename?: 'HighSchoolLocationManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<HighSchoolLocation>;
};

/** No longer supported. Use `sort` instead. */
export type HighSchoolLocationOrderBy =
  | 'city_ASC'
  | 'city_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'logoId_ASC'
  | 'logoId_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'state_ASC'
  | 'state_DESC'
  | 'street_ASC'
  | 'street_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'zip_ASC'
  | 'zip_DESC';

/** HighSchoolLocations subscription payload */
export type HighSchoolLocationPayload = {
  __typename?: 'HighSchoolLocationPayload';
  mutation: MutationType;
  node?: Maybe<HighSchoolLocation>;
  previousValues?: Maybe<HighSchoolLocation>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type HighSchoolLocationRelationFilter = {
  every?: InputMaybe<HighSchoolLocationFilter>;
  none?: InputMaybe<HighSchoolLocationFilter>;
  some?: InputMaybe<HighSchoolLocationFilter>;
};

export type HighSchoolLocationSort = {
  city?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  logo?: InputMaybe<FileSort>;
  logoId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

/** HighSchoolLocations subscription filter */
export type HighSchoolLocationSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<HighSchoolLocationFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** HighSchoolLocations update input */
export type HighSchoolLocationUpdateByFilterInput = {
  city?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  logoId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  state?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  street?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  zip?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** HighSchoolLocations update input */
export type HighSchoolLocationUpdateInput = {
  audiences?: InputMaybe<HighSchoolLocationsAudiencesUpdateRelationInput>;
  city?: InputMaybe<Scalars['String']>;
  educations?: InputMaybe<HighSchoolLocationsEducationsUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  logo?: InputMaybe<HighSchoolLocationsLogoUpdateRelationInput>;
  logoId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type HighSchoolLocation_PermissionFilter = {
  AND?: InputMaybe<Array<HighSchoolLocation_PermissionFilter>>;
  OR?: InputMaybe<Array<HighSchoolLocation_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  audiences?: InputMaybe<Audience_PermissionRelationFilter>;
  city?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  educations?: InputMaybe<EducationalInstitution_PermissionRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  logo?: InputMaybe<File_PermissionFilter>;
  logoId?: InputMaybe<StringPredicate>;
  name?: InputMaybe<StringPredicate>;
  state?: InputMaybe<StringPredicate>;
  street?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  zip?: InputMaybe<StringPredicate>;
};

export type HighSchoolLocation_PermissionRelationFilter = {
  every?: InputMaybe<HighSchoolLocation_PermissionFilter>;
  none?: InputMaybe<HighSchoolLocation_PermissionFilter>;
  some?: InputMaybe<HighSchoolLocation_PermissionFilter>;
};

/** HighSchoolLocations relation input */
export type HighSchoolLocationsAudiencesManyRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
};

/** HighSchoolLocations relation input */
export type HighSchoolLocationsAudiencesRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HighSchools_AudienceCreateInput>>>;
};

/** HighSchoolLocations relation input */
export type HighSchoolLocationsAudiencesUpdateRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HighSchools_AudienceCreateInput>>>;
  disconnect?: InputMaybe<Array<AudienceKeyFilter>>;
  reconnect?: InputMaybe<Array<AudienceKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<HighSchools_AudienceUpdateInput>>>;
};

/** HighSchoolLocations relation input */
export type HighSchoolLocationsEducationsManyRelationInput = {
  connect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
};

/** HighSchoolLocations relation input */
export type HighSchoolLocationsEducationsRelationInput = {
  connect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HighSchool_EducationalInstitutionCreateInput>>>;
};

/** HighSchoolLocations relation input */
export type HighSchoolLocationsEducationsUpdateRelationInput = {
  connect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HighSchool_EducationalInstitutionCreateInput>>>;
  disconnect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
  reconnect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<HighSchool_EducationalInstitutionUpdateInput>>>;
};

/** HighSchoolLocations relation input */
export type HighSchoolLocationsLogoManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** HighSchoolLocations relation input */
export type HighSchoolLocationsLogoRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<HighSchoolLocations_Logo_FileCreateInput>;
};

/** HighSchoolLocations relation input */
export type HighSchoolLocationsLogoUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<HighSchoolLocations_Logo_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<HighSchoolLocations_Logo_FileUpdateInput>;
};

/** Files create input from highSchoolLocations_logo */
export type HighSchoolLocations_Logo_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from highSchoolLocations_logo */
export type HighSchoolLocations_Logo_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** EducationalInstitutions create input from highSchool */
export type HighSchool_EducationalInstitutionCreateInput = {
  activities?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<EducationalInstitutionsAddressRelationInput>;
  degree?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  fieldOfStudy?: InputMaybe<Scalars['String']>;
  gpa?: InputMaybe<Scalars['Float']>;
  highSchool?: InputMaybe<EducationalInstitutionsHighSchoolRelationInput>;
  highSchoolId?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<EducationalInstitutionsImageRelationInput>;
  imageId?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
  user?: InputMaybe<EducationalInstitutionsUserRelationInput>;
};

/** EducationalInstitutions update input from highSchool */
export type HighSchool_EducationalInstitutionUpdateInput = {
  data: EducationalInstitutionUpdateInput;
  filter?: InputMaybe<EducationalInstitutionKeyFilter>;
};

/** Audiences create input from highSchools */
export type HighSchools_AudienceCreateInput = {
  ad?: InputMaybe<AudiencesAdRelationInput>;
  affiliation?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  distanceFromZip?: InputMaybe<Scalars['Int']>;
  endAge?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  groups?: InputMaybe<AudiencesGroupsRelationInput>;
  highSchools?: InputMaybe<AudiencesHighSchoolsRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  organization?: InputMaybe<AudiencesOrganizationRelationInput>;
  schoolCommunities?: InputMaybe<AudiencesSchoolCommunitiesRelationInput>;
  size?: InputMaybe<Scalars['Int']>;
  startAge?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zipCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Audiences update input from highSchools */
export type HighSchools_AudienceUpdateInput = {
  data: AudienceUpdateInput;
  filter?: InputMaybe<AudienceKeyFilter>;
};

export type HomeFeedItem = {
  __typename?: 'HomeFeedItem';
  _description?: Maybe<Scalars['String']>;
  amountPercent?: Maybe<Scalars['Int']>;
  authorAvatar?: Maybe<Scalars['JSON']>;
  authorEmail?: Maybe<Scalars['String']>;
  authorFirstName?: Maybe<Scalars['String']>;
  authorFullName?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  authorLastName?: Maybe<Scalars['String']>;
  commentsAllowed?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isFuture?: Maybe<Scalars['Int']>;
  isPinned?: Maybe<Scalars['Int']>;
  legacyDate?: Maybe<Scalars['Date']>;
  location?: Maybe<Scalars['JSON']>;
  media?: Maybe<Scalars['JSON']>;
  mentions?: Maybe<Scalars['JSON']>;
  postDate?: Maybe<Scalars['DateTime']>;
  postType?: Maybe<Scalars['String']>;
  reportStatus?: Maybe<Scalars['String']>;
  schools?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** HomeFeedItemFieldsPermissions create input */
export type HomeFeedItemFieldsPermissions = {
  amountPercent?: InputMaybe<Scalars['Boolean']>;
  authorAvatar?: InputMaybe<Scalars['Boolean']>;
  authorEmail?: InputMaybe<Scalars['Boolean']>;
  authorFirstName?: InputMaybe<Scalars['Boolean']>;
  authorFullName?: InputMaybe<Scalars['Boolean']>;
  authorId?: InputMaybe<Scalars['Boolean']>;
  authorLastName?: InputMaybe<Scalars['Boolean']>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<Scalars['Boolean']>;
  expirationDate?: InputMaybe<Scalars['Boolean']>;
  groupId?: InputMaybe<Scalars['Boolean']>;
  isFuture?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  legacyDate?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<Scalars['Boolean']>;
  mentions?: InputMaybe<Scalars['Boolean']>;
  postDate?: InputMaybe<Scalars['Boolean']>;
  postType?: InputMaybe<Scalars['Boolean']>;
  reportStatus?: InputMaybe<Scalars['Boolean']>;
  schools?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type HomeFeedItemFilter = {
  AND?: InputMaybe<Array<HomeFeedItemFilter>>;
  OR?: InputMaybe<Array<HomeFeedItemFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  amountPercent?: InputMaybe<IntPredicate>;
  authorEmail?: InputMaybe<StringPredicate>;
  authorFirstName?: InputMaybe<StringPredicate>;
  authorFullName?: InputMaybe<StringPredicate>;
  authorId?: InputMaybe<StringPredicate>;
  authorLastName?: InputMaybe<StringPredicate>;
  commentsAllowed?: InputMaybe<IntPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  discount?: InputMaybe<StringPredicate>;
  expirationDate?: InputMaybe<DateTimePredicate>;
  groupId?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  isFuture?: InputMaybe<IntPredicate>;
  isPinned?: InputMaybe<IntPredicate>;
  legacyDate?: InputMaybe<DatePredicate>;
  postDate?: InputMaybe<DateTimePredicate>;
  postType?: InputMaybe<StringPredicate>;
  reportStatus?: InputMaybe<StringPredicate>;
  schools?: InputMaybe<StringPredicate>;
  startDate?: InputMaybe<DateTimePredicate>;
  status?: InputMaybe<StringPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type HomeFeedItemGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: HomeFeedItemGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type HomeFeedItemGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  amountPercent?: InputMaybe<Array<GroupByField>>;
  authorAvatar?: InputMaybe<Array<GroupByField>>;
  authorEmail?: InputMaybe<Array<GroupByField>>;
  authorFirstName?: InputMaybe<Array<GroupByField>>;
  authorFullName?: InputMaybe<Array<GroupByField>>;
  authorId?: InputMaybe<Array<GroupByField>>;
  authorLastName?: InputMaybe<Array<GroupByField>>;
  commentsAllowed?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  discount?: InputMaybe<Array<GroupByField>>;
  expirationDate?: InputMaybe<Array<GroupByField>>;
  groupId?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  isFuture?: InputMaybe<Array<GroupByField>>;
  isPinned?: InputMaybe<Array<GroupByField>>;
  legacyDate?: InputMaybe<Array<GroupByField>>;
  location?: InputMaybe<Array<GroupByField>>;
  media?: InputMaybe<Array<GroupByField>>;
  mentions?: InputMaybe<Array<GroupByField>>;
  postDate?: InputMaybe<Array<GroupByField>>;
  postType?: InputMaybe<Array<GroupByField>>;
  reportStatus?: InputMaybe<Array<GroupByField>>;
  schools?: InputMaybe<Array<GroupByField>>;
  startDate?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  text?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

/** HomeFeedItemListResponse output */
export type HomeFeedItemListResponse = {
  __typename?: 'HomeFeedItemListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<HomeFeedItem>;
};

/** HomeFeedItemManyResponse output */
export type HomeFeedItemManyResponse = {
  __typename?: 'HomeFeedItemManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<HomeFeedItem>;
};

/** No longer supported. Use `sort` instead. */
export type HomeFeedItemOrderBy =
  | 'amountPercent_ASC'
  | 'amountPercent_DESC'
  | 'authorAvatar_ASC'
  | 'authorAvatar_DESC'
  | 'authorEmail_ASC'
  | 'authorEmail_DESC'
  | 'authorFirstName_ASC'
  | 'authorFirstName_DESC'
  | 'authorFullName_ASC'
  | 'authorFullName_DESC'
  | 'authorId_ASC'
  | 'authorId_DESC'
  | 'authorLastName_ASC'
  | 'authorLastName_DESC'
  | 'commentsAllowed_ASC'
  | 'commentsAllowed_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'discount_ASC'
  | 'discount_DESC'
  | 'expirationDate_ASC'
  | 'expirationDate_DESC'
  | 'groupId_ASC'
  | 'groupId_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isFuture_ASC'
  | 'isFuture_DESC'
  | 'isPinned_ASC'
  | 'isPinned_DESC'
  | 'legacyDate_ASC'
  | 'legacyDate_DESC'
  | 'location_ASC'
  | 'location_DESC'
  | 'media_ASC'
  | 'media_DESC'
  | 'mentions_ASC'
  | 'mentions_DESC'
  | 'postDate_ASC'
  | 'postDate_DESC'
  | 'postType_ASC'
  | 'postType_DESC'
  | 'reportStatus_ASC'
  | 'reportStatus_DESC'
  | 'schools_ASC'
  | 'schools_DESC'
  | 'startDate_ASC'
  | 'startDate_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'text_ASC'
  | 'text_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** HomeFeedItems subscription payload */
export type HomeFeedItemPayload = {
  __typename?: 'HomeFeedItemPayload';
  mutation: MutationType;
  node?: Maybe<HomeFeedItem>;
  previousValues?: Maybe<HomeFeedItem>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type HomeFeedItemSort = {
  amountPercent?: InputMaybe<SortOrder>;
  authorEmail?: InputMaybe<SortOrder>;
  authorFirstName?: InputMaybe<SortOrder>;
  authorFullName?: InputMaybe<SortOrder>;
  authorId?: InputMaybe<SortOrder>;
  authorLastName?: InputMaybe<SortOrder>;
  commentsAllowed?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  discount?: InputMaybe<SortOrder>;
  expirationDate?: InputMaybe<SortOrder>;
  groupId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isFuture?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  legacyDate?: InputMaybe<SortOrder>;
  postDate?: InputMaybe<SortOrder>;
  postType?: InputMaybe<SortOrder>;
  reportStatus?: InputMaybe<SortOrder>;
  schools?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** HomeFeedItems subscription filter */
export type HomeFeedItemSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<HomeFeedItemFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** HomeFeedItems update input */
export type HomeFeedItemUpdateByFilterInput = {
  amountPercent?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  authorAvatar?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  authorEmail?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  authorFirstName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  authorFullName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  authorId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  authorLastName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  commentsAllowed?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  discount?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  expirationDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  groupId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  isFuture?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  isPinned?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  legacyDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateInput>>>;
  location?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  media?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  mentions?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  postDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  postType?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  reportStatus?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  schools?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  startDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  text?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

export type HomeFeedItem_PermissionFilter = {
  AND?: InputMaybe<Array<HomeFeedItem_PermissionFilter>>;
  OR?: InputMaybe<Array<HomeFeedItem_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  amountPercent?: InputMaybe<IntPredicate>;
  authorEmail?: InputMaybe<StringPredicate>;
  authorFirstName?: InputMaybe<StringPredicate>;
  authorFullName?: InputMaybe<StringPredicate>;
  authorId?: InputMaybe<StringPredicate>;
  authorLastName?: InputMaybe<StringPredicate>;
  commentsAllowed?: InputMaybe<IntPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  discount?: InputMaybe<StringPredicate>;
  expirationDate?: InputMaybe<DateTimePredicate>;
  groupId?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  isFuture?: InputMaybe<IntPredicate>;
  isPinned?: InputMaybe<IntPredicate>;
  legacyDate?: InputMaybe<DatePredicate>;
  postDate?: InputMaybe<DateTimePredicate>;
  postType?: InputMaybe<StringPredicate>;
  reportStatus?: InputMaybe<StringPredicate>;
  schools?: InputMaybe<StringPredicate>;
  startDate?: InputMaybe<DateTimePredicate>;
  status?: InputMaybe<StringPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type HomeFeedPost = {
  __typename?: 'HomeFeedPost';
  _description?: Maybe<Scalars['String']>;
  author?: Maybe<User>;
  commentsAllowed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  /** Stores the discount id of the post redeemed by the user. */
  discount?: Maybe<Discount>;
  flagReason?: Maybe<Scalars['String']>;
  flaggedAt?: Maybe<Scalars['DateTime']>;
  flaggedBy?: Maybe<UserListResponse>;
  id?: Maybe<Scalars['ID']>;
  isAutoReported?: Maybe<Scalars['Boolean']>;
  isFlagged?: Maybe<Scalars['Boolean']>;
  isPinned?: Maybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: Maybe<Scalars['Boolean']>;
  lastFlaggedBy?: Maybe<User>;
  /** Media files: [video, image] */
  media?: Maybe<FileListResponse>;
  mentions?: Maybe<PostMentionListResponse>;
  reportStatus?: Maybe<Scalars['String']>;
  schools?: Maybe<SchoolConfigurationListResponse>;
  /**
   * Approved - post is displayed
   * Pending - default status, awaits auto-approval/approved by admins. Not displayed
   * Rejected - auto-rejected/rejected by admins
   * reportReviewPending - post got flagged, pending review
   */
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HomeFeedPostFlaggedByArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

export type HomeFeedPostMediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FileSort>>;
};

export type HomeFeedPostMentionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PostMentionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<PostMentionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<PostMentionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PostMentionSort>>;
};

export type HomeFeedPostSchoolsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationSort>>;
};

/** HomeFeedPosts create input */
export type HomeFeedPostCreateInput = {
  author?: InputMaybe<HomeFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<HomeFeedPostsDiscountRelationInput>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<HomeFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<HomeFeedPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<HomeFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<HomeFeedPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<HomeFeedPostsSchoolsRelationInput>;
  /**
   * Approved - post is displayed
   * Pending - default status, awaits auto-approval/approved by admins. Not displayed
   * Rejected - auto-rejected/rejected by admins
   * reportReviewPending - post got flagged, pending review
   */
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** HomeFeedPosts create many input */
export type HomeFeedPostCreateManyInput = {
  author: HomeFeedPostsAuthorManyRelationInput;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<HomeFeedPostsDiscountManyRelationInput>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<HomeFeedPostsFlaggedByManyRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<HomeFeedPostsLastFlaggedByManyRelationInput>;
  media?: InputMaybe<HomeFeedPostsMediaManyRelationInput>;
  mentions?: InputMaybe<HomeFeedPostsMentionsManyRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<HomeFeedPostsSchoolsManyRelationInput>;
  /**
   * Approved - post is displayed
   * Pending - default status, awaits auto-approval/approved by admins. Not displayed
   * Rejected - auto-rejected/rejected by admins
   * reportReviewPending - post got flagged, pending review
   */
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** HomeFeedPosts delete input */
export type HomeFeedPostDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** HomeFeedPostFieldsPermissions create input */
export type HomeFeedPostFieldsPermissions = {
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['Boolean']>;
  flaggedAt?: InputMaybe<Scalars['Boolean']>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  reportStatus?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type HomeFeedPostFilter = {
  AND?: InputMaybe<Array<HomeFeedPostFilter>>;
  OR?: InputMaybe<Array<HomeFeedPostFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<UserFilter>;
  commentsAllowed?: InputMaybe<BoolPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  discount?: InputMaybe<DiscountFilter>;
  flagReason?: InputMaybe<StringPredicate>;
  flaggedAt?: InputMaybe<DateTimePredicate>;
  flaggedBy?: InputMaybe<UserRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  isAutoReported?: InputMaybe<BoolPredicate>;
  isFlagged?: InputMaybe<BoolPredicate>;
  isPinned?: InputMaybe<BoolPredicate>;
  isReviewed?: InputMaybe<BoolPredicate>;
  lastFlaggedBy?: InputMaybe<UserFilter>;
  media?: InputMaybe<FileRelationFilter>;
  mentions?: InputMaybe<PostMentionRelationFilter>;
  reportStatus?: InputMaybe<StringPredicate>;
  schools?: InputMaybe<SchoolConfigurationRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type HomeFeedPostGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: HomeFeedPostGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type HomeFeedPostGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  author?: InputMaybe<UserGroupByQuery>;
  commentsAllowed?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  discount?: InputMaybe<DiscountGroupByQuery>;
  flagReason?: InputMaybe<Array<GroupByField>>;
  flaggedAt?: InputMaybe<Array<GroupByField>>;
  flaggedBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isAutoReported?: InputMaybe<Array<GroupByField>>;
  isFlagged?: InputMaybe<Array<GroupByField>>;
  isPinned?: InputMaybe<Array<GroupByField>>;
  isReviewed?: InputMaybe<Array<GroupByField>>;
  lastFlaggedBy?: InputMaybe<UserGroupByQuery>;
  media?: InputMaybe<FileGroupByQuery>;
  mentions?: InputMaybe<PostMentionGroupByQuery>;
  reportStatus?: InputMaybe<Array<GroupByField>>;
  schools?: InputMaybe<SchoolConfigurationGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  text?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type HomeFeedPostKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** HomeFeedPostListResponse output */
export type HomeFeedPostListResponse = {
  __typename?: 'HomeFeedPostListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<HomeFeedPost>;
};

/** HomeFeedPostManyResponse output */
export type HomeFeedPostManyResponse = {
  __typename?: 'HomeFeedPostManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<HomeFeedPost>;
};

/** No longer supported. Use `sort` instead. */
export type HomeFeedPostOrderBy =
  | 'commentsAllowed_ASC'
  | 'commentsAllowed_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'flagReason_ASC'
  | 'flagReason_DESC'
  | 'flaggedAt_ASC'
  | 'flaggedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isAutoReported_ASC'
  | 'isAutoReported_DESC'
  | 'isFlagged_ASC'
  | 'isFlagged_DESC'
  | 'isPinned_ASC'
  | 'isPinned_DESC'
  | 'isReviewed_ASC'
  | 'isReviewed_DESC'
  | 'reportStatus_ASC'
  | 'reportStatus_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'text_ASC'
  | 'text_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** HomeFeedPosts subscription payload */
export type HomeFeedPostPayload = {
  __typename?: 'HomeFeedPostPayload';
  mutation: MutationType;
  node?: Maybe<HomeFeedPost>;
  previousValues?: Maybe<HomeFeedPost>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type HomeFeedPostRelationFilter = {
  every?: InputMaybe<HomeFeedPostFilter>;
  none?: InputMaybe<HomeFeedPostFilter>;
  some?: InputMaybe<HomeFeedPostFilter>;
};

export type HomeFeedPostSort = {
  author?: InputMaybe<UserSort>;
  commentsAllowed?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  discount?: InputMaybe<DiscountSort>;
  flagReason?: InputMaybe<SortOrder>;
  flaggedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAutoReported?: InputMaybe<SortOrder>;
  isFlagged?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isReviewed?: InputMaybe<SortOrder>;
  lastFlaggedBy?: InputMaybe<UserSort>;
  reportStatus?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** HomeFeedPosts subscription filter */
export type HomeFeedPostSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<HomeFeedPostFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** HomeFeedPosts update input */
export type HomeFeedPostUpdateByFilterInput = {
  commentsAllowed?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  flagReason?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  flaggedAt?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  isAutoReported?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isFlagged?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isPinned?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isReviewed?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  reportStatus?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  text?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** HomeFeedPosts update input */
export type HomeFeedPostUpdateInput = {
  author?: InputMaybe<HomeFeedPostsAuthorUpdateRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<HomeFeedPostsDiscountUpdateRelationInput>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<HomeFeedPostsFlaggedByUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<HomeFeedPostsLastFlaggedByUpdateRelationInput>;
  media?: InputMaybe<HomeFeedPostsMediaUpdateRelationInput>;
  mentions?: InputMaybe<HomeFeedPostsMentionsUpdateRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<HomeFeedPostsSchoolsUpdateRelationInput>;
  /**
   * Approved - post is displayed
   * Pending - default status, awaits auto-approval/approved by admins. Not displayed
   * Rejected - auto-rejected/rejected by admins
   * reportReviewPending - post got flagged, pending review
   */
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type HomeFeedPost_PermissionFilter = {
  AND?: InputMaybe<Array<HomeFeedPost_PermissionFilter>>;
  OR?: InputMaybe<Array<HomeFeedPost_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<User_PermissionFilter>;
  commentsAllowed?: InputMaybe<BoolPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  discount?: InputMaybe<Discount_PermissionFilter>;
  flagReason?: InputMaybe<StringPredicate>;
  flaggedAt?: InputMaybe<DateTimePredicate>;
  flaggedBy?: InputMaybe<User_PermissionRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  isAutoReported?: InputMaybe<BoolPredicate>;
  isFlagged?: InputMaybe<BoolPredicate>;
  isPinned?: InputMaybe<BoolPredicate>;
  isReviewed?: InputMaybe<BoolPredicate>;
  lastFlaggedBy?: InputMaybe<User_PermissionFilter>;
  media?: InputMaybe<File_PermissionRelationFilter>;
  mentions?: InputMaybe<PostMention_PermissionRelationFilter>;
  reportStatus?: InputMaybe<StringPredicate>;
  schools?: InputMaybe<SchoolConfiguration_PermissionRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type HomeFeedPost_PermissionRelationFilter = {
  every?: InputMaybe<HomeFeedPost_PermissionFilter>;
  none?: InputMaybe<HomeFeedPost_PermissionFilter>;
  some?: InputMaybe<HomeFeedPost_PermissionFilter>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsAuthorManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsAuthorRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<HomeFeedPosts_UserCreateInput>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsAuthorUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<HomeFeedPosts_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<HomeFeedPosts_UserUpdateInput>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsDiscountManyRelationInput = {
  connect?: InputMaybe<DiscountKeyFilter>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsDiscountRelationInput = {
  connect?: InputMaybe<DiscountKeyFilter>;
  create?: InputMaybe<DiscountRedeemPost_DiscountCreateInput>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsDiscountUpdateRelationInput = {
  connect?: InputMaybe<DiscountKeyFilter>;
  create?: InputMaybe<DiscountRedeemPost_DiscountCreateInput>;
  disconnect?: InputMaybe<DiscountKeyFilter>;
  reconnect?: InputMaybe<DiscountKeyFilter>;
  update?: InputMaybe<DiscountRedeemPost_DiscountUpdateInput>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsFlaggedByManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsFlaggedByRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedHomeFeedPosts_UserCreateInput>>>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsFlaggedByUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedHomeFeedPosts_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<FlaggedHomeFeedPosts_UserUpdateInput>>>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsLastFlaggedByManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsLastFlaggedByRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<LastFlaggedHomePost_UserCreateInput>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsLastFlaggedByUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<LastFlaggedHomePost_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<LastFlaggedHomePost_UserUpdateInput>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsMediaManyRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsMediaRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HomeFeedPosts_Media_FileCreateInput>>>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsMediaUpdateRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HomeFeedPosts_Media_FileCreateInput>>>;
  disconnect?: InputMaybe<Array<FileKeyFilter>>;
  reconnect?: InputMaybe<Array<FileKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<HomeFeedPosts_Media_FileUpdateInput>>>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsMentionsManyRelationInput = {
  connect?: InputMaybe<Array<PostMentionKeyFilter>>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsMentionsRelationInput = {
  connect?: InputMaybe<Array<PostMentionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HomePost_PostMentionCreateInput>>>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsMentionsUpdateRelationInput = {
  connect?: InputMaybe<Array<PostMentionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HomePost_PostMentionCreateInput>>>;
  disconnect?: InputMaybe<Array<PostMentionKeyFilter>>;
  reconnect?: InputMaybe<Array<PostMentionKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<HomePost_PostMentionUpdateInput>>>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsSchoolsManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsSchoolsRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HomePosts_SchoolConfigurationCreateInput>>>;
};

/** HomeFeedPosts relation input */
export type HomeFeedPostsSchoolsUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HomePosts_SchoolConfigurationCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<HomePosts_SchoolConfigurationUpdateInput>>>;
};

/** Users create input from homeFeedPosts */
export type HomeFeedPosts_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from homeFeedPosts */
export type HomeFeedPosts_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Files create input from homeFeedPosts_media */
export type HomeFeedPosts_Media_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from homeFeedPosts_media */
export type HomeFeedPosts_Media_FileUpdateInput = {
  data: FileUpdateInput;
  filter?: InputMaybe<FileKeyFilter>;
};

/** PostMentions create input from homePost */
export type HomePost_PostMentionCreateInput = {
  groupPost?: InputMaybe<PostMentionsGroupPostRelationInput>;
  homePost?: InputMaybe<PostMentionsHomePostRelationInput>;
  legacyPost?: InputMaybe<PostMentionsLegacyPostRelationInput>;
  legacyPostId?: InputMaybe<Scalars['String']>;
  mentionId?: InputMaybe<Scalars['String']>;
  mentionText?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  schoolPost?: InputMaybe<PostMentionsSchoolPostRelationInput>;
  type?: InputMaybe<Scalars['String']>;
};

/** PostMentions update input from homePost */
export type HomePost_PostMentionUpdateInput = {
  data: PostMentionUpdateInput;
  filter?: InputMaybe<PostMentionKeyFilter>;
};

/** SchoolConfiguration create input from homePosts */
export type HomePosts_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from homePosts */
export type HomePosts_SchoolConfigurationUpdateInput = {
  data: SchoolConfigurationUpdateInput;
  filter?: InputMaybe<SchoolConfigurationKeyFilter>;
};

export type IdPredicate = {
  contains?: InputMaybe<Scalars['ID']>;
  ends_with?: InputMaybe<Scalars['ID']>;
  equals?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  not_contains?: InputMaybe<Scalars['ID']>;
  not_ends_with?: InputMaybe<Scalars['ID']>;
  not_equals?: InputMaybe<Scalars['ID']>;
  not_in?: InputMaybe<Array<Scalars['ID']>>;
  not_starts_with?: InputMaybe<Scalars['ID']>;
  starts_with?: InputMaybe<Scalars['ID']>;
};

export type IdPredicateHaving = {
  AND?: InputMaybe<Array<IdPredicateHaving>>;
  OR?: InputMaybe<Array<IdPredicateHaving>>;
  contains?: InputMaybe<Scalars['ID']>;
  ends_with?: InputMaybe<Scalars['ID']>;
  equals?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  not_contains?: InputMaybe<Scalars['ID']>;
  not_ends_with?: InputMaybe<Scalars['ID']>;
  not_equals?: InputMaybe<Scalars['ID']>;
  not_in?: InputMaybe<Array<Scalars['ID']>>;
  not_starts_with?: InputMaybe<Scalars['ID']>;
  starts_with?: InputMaybe<Scalars['ID']>;
};

/** AdvertisingCampaignAds create input from image */
export type Image_AdvertisingCampaignAdCreateInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdRelationInput>;
  callToAction: Scalars['String'];
  callToActionLink: Scalars['String'];
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  primaryText: Scalars['String'];
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign: AdvertisingCampaignAdsSampaignRelationInput;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget: Scalars['Float'];
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

/** AdvertisingCampaignAds update input from image */
export type Image_AdvertisingCampaignAdUpdateInput = {
  data: AdvertisingCampaignAdUpdateInput;
  filter?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
};

/** EducationalInstitutions create input from image */
export type Image_EducationalInstitutionCreateInput = {
  activities?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<EducationalInstitutionsAddressRelationInput>;
  degree?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  fieldOfStudy?: InputMaybe<Scalars['String']>;
  gpa?: InputMaybe<Scalars['Float']>;
  highSchool?: InputMaybe<EducationalInstitutionsHighSchoolRelationInput>;
  highSchoolId?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<EducationalInstitutionsImageRelationInput>;
  imageId?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
  user?: InputMaybe<EducationalInstitutionsUserRelationInput>;
};

/** EducationalInstitutions update input from image */
export type Image_EducationalInstitutionUpdateInput = {
  data: EducationalInstitutionUpdateInput;
  filter?: InputMaybe<EducationalInstitutionKeyFilter>;
};

/** UserNotifications create input from image */
export type Image_UserNotificationCreateInput = {
  /**
   * Set "true" for notifications that should be deleted (e.g. on trade confirm)
   * Need this to get rid of templateKey filter
   */
  deletable?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<UserNotificationsImageRelationInput>;
  /**
   * Optional notification params
   *
   * params: Record<string, any>
   */
  infoJSON?: InputMaybe<Scalars['JSON']>;
  /** Contains the related item id, used to group notifications, could be post id, group id, profile id, etc */
  relatedItemID?: InputMaybe<Scalars['String']>;
  sender?: InputMaybe<UserNotificationsSenderRelationInput>;
  template: UserNotificationsTemplateRelationInput;
  userNotificationsByUsers?: InputMaybe<UserNotificationsUserNotificationsByUsersRelationInput>;
};

/** UserNotifications update input from image */
export type Image_UserNotificationUpdateInput = {
  data: UserNotificationUpdateInput;
  filter?: InputMaybe<UserNotificationKeyFilter>;
};

/** CollectiveIntelligenceThreads create input from images */
export type Images_CollectiveIntelligenceThreadCreateInput = {
  creator?: InputMaybe<CollectiveIntelligenceThreadsCreatorRelationInput>;
  images?: InputMaybe<CollectiveIntelligenceThreadsImagesRelationInput>;
  schools?: InputMaybe<CollectiveIntelligenceThreadsSchoolsRelationInput>;
  text: Scalars['String'];
  title: Scalars['String'];
  views?: InputMaybe<CollectiveIntelligenceThreadsViewsRelationInput>;
};

/** CollectiveIntelligenceThreads update input from images */
export type Images_CollectiveIntelligenceThreadUpdateInput = {
  data: CollectiveIntelligenceThreadUpdateInput;
  filter?: InputMaybe<CollectiveIntelligenceThreadKeyFilter>;
};

/** Discounts create input from images */
export type Images_DiscountCreateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingRelationInput>;
  amountPercent: Scalars['Int'];
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostRelationInput>;
  expirationDate: Scalars['DateTime'];
  images?: InputMaybe<DiscountsImagesRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationRelationInput>;
  payment?: InputMaybe<DiscountsPaymentRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsRelationInput>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  terms: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

/** Discounts update input from images */
export type Images_DiscountUpdateInput = {
  data: DiscountUpdateInput;
  filter?: InputMaybe<DiscountKeyFilter>;
};

/** Events create input from images */
export type Images_EventCreateInput = {
  author?: InputMaybe<EventsAuthorRelationInput>;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<EventsImagesRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<EventsLocationRelationInput>;
  otherSponsors?: InputMaybe<EventsOtherSponsorsRelationInput>;
  school?: InputMaybe<EventsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  sponsorRequests?: InputMaybe<EventsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleSponsor?: InputMaybe<EventsTitleSponsorRelationInput>;
};

/** Events update input from images */
export type Images_EventUpdateInput = {
  data: EventUpdateInput;
  filter?: InputMaybe<EventKeyFilter>;
};

/** SchoolConfiguration create input from images */
export type Images_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address: SchoolConfigurationAddressRelationInput;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from images */
export type Images_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** ImportCSV */
export type ImportCsv = {
  data: ImportCsvData;
  tableId: Scalars['String'];
};

export type ImportCsvData = {
  fields?: InputMaybe<Scalars['String']>;
  hasHeader?: InputMaybe<Scalars['Boolean']>;
  matchField?: InputMaybe<Scalars['String']>;
  overwrite?: InputMaybe<Scalars['Boolean']>;
  tableFields?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

/** ImportedTable */
export type ImportedTable = {
  __typename?: 'ImportedTable';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Inbox = {
  __typename?: 'Inbox';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  /** Groups to which the message was sent */
  destinationGroups?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  isHide?: Maybe<UserListResponse>;
  messages?: Maybe<InboxMessageListResponse>;
  /** Save either the content of the subject or the structure to get more details. */
  subject?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserListResponse>;
};

export type InboxIsHideArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

export type InboxMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxMessageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxMessageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxMessageSort>>;
};

export type InboxUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

/** Inbox create input */
export type InboxCreateInput = {
  /** Groups to which the message was sent */
  destinationGroups?: InputMaybe<Scalars['JSON']>;
  isHide?: InputMaybe<InboxIsHideRelationInput>;
  messages?: InputMaybe<InboxMessagesRelationInput>;
  /** Save either the content of the subject or the structure to get more details. */
  subject?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<InboxUsersRelationInput>;
};

/** Inbox create many input */
export type InboxCreateManyInput = {
  /** Groups to which the message was sent */
  destinationGroups?: InputMaybe<Scalars['JSON']>;
  isHide?: InputMaybe<InboxIsHideManyRelationInput>;
  messages?: InputMaybe<InboxMessagesManyRelationInput>;
  /** Save either the content of the subject or the structure to get more details. */
  subject?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<InboxUsersManyRelationInput>;
};

/** Inbox delete input */
export type InboxDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** Inbox Events List Filter */
export type InboxEventsListFilter = {
  isCompleted?: InputMaybe<IntPredicate>;
};

/** InboxFieldsPermissions create input */
export type InboxFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  destinationGroups?: InputMaybe<Scalars['Boolean']>;
  subject?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InboxFilter = {
  AND?: InputMaybe<Array<InboxFilter>>;
  OR?: InputMaybe<Array<InboxFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isHide?: InputMaybe<UserRelationFilter>;
  messages?: InputMaybe<InboxMessageRelationFilter>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  users?: InputMaybe<UserRelationFilter>;
};

export type InboxGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: InboxGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type InboxGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  destinationGroups?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  isHide?: InputMaybe<UserGroupByQuery>;
  messages?: InputMaybe<InboxMessageGroupByQuery>;
  subject?: InputMaybe<Array<GroupByField>>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  users?: InputMaybe<UserGroupByQuery>;
};

/** Inbox relation input */
export type InboxIsHideManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** Inbox relation input */
export type InboxIsHideRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HiddenInboxes_UserCreateInput>>>;
};

/** Inbox relation input */
export type InboxIsHideUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HiddenInboxes_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<HiddenInboxes_UserUpdateInput>>>;
};

export type InboxKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** InboxListResponse output */
export type InboxListResponse = {
  __typename?: 'InboxListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Inbox>;
};

/** InboxManyResponse output */
export type InboxManyResponse = {
  __typename?: 'InboxManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Inbox>;
};

export type InboxMessage = {
  __typename?: 'InboxMessage';
  _description?: Maybe<Scalars['String']>;
  author?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  deletedBy?: Maybe<UserListResponse>;
  id?: Maybe<Scalars['ID']>;
  inbox?: Maybe<Inbox>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHide?: Maybe<UserListResponse>;
  isRead?: Maybe<Scalars['Boolean']>;
  media?: Maybe<FileListResponse>;
  messageMedia?: Maybe<InboxMessageMedia>;
  reply?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InboxMessageDeletedByArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

export type InboxMessageIsHideArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

export type InboxMessageMediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FileSort>>;
};

/** InboxMessages create input */
export type InboxMessageCreateInput = {
  author?: InputMaybe<InboxMessagesAuthorRelationInput>;
  deletedBy?: InputMaybe<InboxMessagesDeletedByRelationInput>;
  inbox?: InputMaybe<InboxMessagesInboxRelationInput>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isHide?: InputMaybe<InboxMessagesIsHideRelationInput>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<InboxMessagesMediaRelationInput>;
  messageMedia?: InputMaybe<InboxMessagesMessageMediaRelationInput>;
  reply?: InputMaybe<Scalars['Boolean']>;
  text: Scalars['String'];
};

/** InboxMessages create many input */
export type InboxMessageCreateManyInput = {
  author: InboxMessagesAuthorManyRelationInput;
  deletedBy?: InputMaybe<InboxMessagesDeletedByManyRelationInput>;
  inbox: InboxMessagesInboxManyRelationInput;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isHide?: InputMaybe<InboxMessagesIsHideManyRelationInput>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<InboxMessagesMediaManyRelationInput>;
  messageMedia?: InputMaybe<InboxMessagesMessageMediaManyRelationInput>;
  reply?: InputMaybe<Scalars['Boolean']>;
  text: Scalars['String'];
};

/** InboxMessages delete input */
export type InboxMessageDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** InboxMessageFieldsPermissions create input */
export type InboxMessageFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  reply?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InboxMessageFilter = {
  AND?: InputMaybe<Array<InboxMessageFilter>>;
  OR?: InputMaybe<Array<InboxMessageFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<UserFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  deletedBy?: InputMaybe<UserRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  inbox?: InputMaybe<InboxFilter>;
  isDeleted?: InputMaybe<BoolPredicate>;
  isHide?: InputMaybe<UserRelationFilter>;
  isRead?: InputMaybe<BoolPredicate>;
  media?: InputMaybe<FileRelationFilter>;
  messageMedia?: InputMaybe<InboxMessageMediaFilter>;
  reply?: InputMaybe<BoolPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type InboxMessageGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: InboxMessageGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type InboxMessageGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  author?: InputMaybe<UserGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  deletedBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  inbox?: InputMaybe<InboxGroupByQuery>;
  isDeleted?: InputMaybe<Array<GroupByField>>;
  isHide?: InputMaybe<UserGroupByQuery>;
  isRead?: InputMaybe<Array<GroupByField>>;
  media?: InputMaybe<FileGroupByQuery>;
  messageMedia?: InputMaybe<InboxMessageMediaGroupByQuery>;
  reply?: InputMaybe<Array<GroupByField>>;
  text?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type InboxMessageKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** InboxMessageListResponse output */
export type InboxMessageListResponse = {
  __typename?: 'InboxMessageListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<InboxMessage>;
};

/** InboxMessageManyResponse output */
export type InboxMessageManyResponse = {
  __typename?: 'InboxMessageManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<InboxMessage>;
};

export type InboxMessageMedia = {
  __typename?: 'InboxMessageMedia';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  media?: Maybe<FileListResponse>;
  message?: Maybe<InboxMessageListResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InboxMessageMediaMediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FileSort>>;
};

export type InboxMessageMediaMessageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxMessageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxMessageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxMessageSort>>;
};

/** InboxMessageMedia create input */
export type InboxMessageMediaCreateInput = {
  media?: InputMaybe<InboxMessageMediaMediaRelationInput>;
  message?: InputMaybe<InboxMessageMediaMessageRelationInput>;
};

/** InboxMessageMedia create many input */
export type InboxMessageMediaCreateManyInput = {
  media?: InputMaybe<InboxMessageMediaMediaManyRelationInput>;
  message?: InputMaybe<InboxMessageMediaMessageManyRelationInput>;
};

/** InboxMessageMedia delete input */
export type InboxMessageMediaDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** InboxMessageMediaFieldsPermissions create input */
export type InboxMessageMediaFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InboxMessageMediaFilter = {
  AND?: InputMaybe<Array<InboxMessageMediaFilter>>;
  OR?: InputMaybe<Array<InboxMessageMediaFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  media?: InputMaybe<FileRelationFilter>;
  message?: InputMaybe<InboxMessageRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type InboxMessageMediaGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: InboxMessageMediaGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type InboxMessageMediaGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  media?: InputMaybe<FileGroupByQuery>;
  message?: InputMaybe<InboxMessageGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type InboxMessageMediaKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** InboxMessageMediaListResponse output */
export type InboxMessageMediaListResponse = {
  __typename?: 'InboxMessageMediaListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<InboxMessageMedia>;
};

/** InboxMessageMediaManyResponse output */
export type InboxMessageMediaManyResponse = {
  __typename?: 'InboxMessageMediaManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<InboxMessageMedia>;
};

/** InboxMessageMedia relation input */
export type InboxMessageMediaMediaManyRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
};

/** InboxMessageMedia relation input */
export type InboxMessageMediaMediaRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<InboxMessageMedia_Media_FileCreateInput>>>;
};

/** InboxMessageMedia relation input */
export type InboxMessageMediaMediaUpdateRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<InboxMessageMedia_Media_FileCreateInput>>>;
  disconnect?: InputMaybe<Array<FileKeyFilter>>;
  reconnect?: InputMaybe<Array<FileKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<InboxMessageMedia_Media_FileUpdateInput>>>;
};

/** InboxMessageMedia relation input */
export type InboxMessageMediaMessageManyRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
};

/** InboxMessageMedia relation input */
export type InboxMessageMediaMessageRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<MessageMedia_InboxMessageCreateInput>>>;
};

/** InboxMessageMedia relation input */
export type InboxMessageMediaMessageUpdateRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<MessageMedia_InboxMessageCreateInput>>>;
  disconnect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  reconnect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<MessageMedia_InboxMessageUpdateInput>>>;
};

/** No longer supported. Use `sort` instead. */
export type InboxMessageMediaOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** InboxMessageMedia subscription payload */
export type InboxMessageMediaPayload = {
  __typename?: 'InboxMessageMediaPayload';
  mutation: MutationType;
  node?: Maybe<InboxMessageMedia>;
  previousValues?: Maybe<InboxMessageMedia>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InboxMessageMediaRelationFilter = {
  every?: InputMaybe<InboxMessageMediaFilter>;
  none?: InputMaybe<InboxMessageMediaFilter>;
  some?: InputMaybe<InboxMessageMediaFilter>;
};

export type InboxMessageMediaSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** InboxMessageMedia subscription filter */
export type InboxMessageMediaSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<InboxMessageMediaFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** InboxMessageMedia update input */
export type InboxMessageMediaUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  media?: InputMaybe<InboxMessageMediaMediaUpdateRelationInput>;
  message?: InputMaybe<InboxMessageMediaMessageUpdateRelationInput>;
};

export type InboxMessageMedia_PermissionFilter = {
  AND?: InputMaybe<Array<InboxMessageMedia_PermissionFilter>>;
  OR?: InputMaybe<Array<InboxMessageMedia_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  media?: InputMaybe<File_PermissionRelationFilter>;
  message?: InputMaybe<InboxMessage_PermissionRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type InboxMessageMedia_PermissionRelationFilter = {
  every?: InputMaybe<InboxMessageMedia_PermissionFilter>;
  none?: InputMaybe<InboxMessageMedia_PermissionFilter>;
  some?: InputMaybe<InboxMessageMedia_PermissionFilter>;
};

/** Files create input from inboxMessageMedia_media */
export type InboxMessageMedia_Media_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from inboxMessageMedia_media */
export type InboxMessageMedia_Media_FileUpdateInput = {
  data: FileUpdateInput;
  filter?: InputMaybe<FileKeyFilter>;
};

/** No longer supported. Use `sort` instead. */
export type InboxMessageOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isDeleted_ASC'
  | 'isDeleted_DESC'
  | 'isRead_ASC'
  | 'isRead_DESC'
  | 'reply_ASC'
  | 'reply_DESC'
  | 'text_ASC'
  | 'text_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** InboxMessages subscription payload */
export type InboxMessagePayload = {
  __typename?: 'InboxMessagePayload';
  mutation: MutationType;
  node?: Maybe<InboxMessage>;
  previousValues?: Maybe<InboxMessage>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InboxMessageRelationFilter = {
  every?: InputMaybe<InboxMessageFilter>;
  none?: InputMaybe<InboxMessageFilter>;
  some?: InputMaybe<InboxMessageFilter>;
};

export type InboxMessageSort = {
  author?: InputMaybe<UserSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inbox?: InputMaybe<InboxSort>;
  isDeleted?: InputMaybe<SortOrder>;
  isRead?: InputMaybe<SortOrder>;
  messageMedia?: InputMaybe<InboxMessageMediaSort>;
  reply?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** InboxMessages subscription filter */
export type InboxMessageSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<InboxMessageFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** InboxMessages update input */
export type InboxMessageUpdateByFilterInput = {
  isDeleted?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isRead?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  reply?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  text?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** InboxMessages update input */
export type InboxMessageUpdateInput = {
  author?: InputMaybe<InboxMessagesAuthorUpdateRelationInput>;
  deletedBy?: InputMaybe<InboxMessagesDeletedByUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  inbox?: InputMaybe<InboxMessagesInboxUpdateRelationInput>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isHide?: InputMaybe<InboxMessagesIsHideUpdateRelationInput>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<InboxMessagesMediaUpdateRelationInput>;
  messageMedia?: InputMaybe<InboxMessagesMessageMediaUpdateRelationInput>;
  reply?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};

export type InboxMessage_PermissionFilter = {
  AND?: InputMaybe<Array<InboxMessage_PermissionFilter>>;
  OR?: InputMaybe<Array<InboxMessage_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<User_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  deletedBy?: InputMaybe<User_PermissionRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  inbox?: InputMaybe<Inbox_PermissionFilter>;
  isDeleted?: InputMaybe<BoolPredicate>;
  isHide?: InputMaybe<User_PermissionRelationFilter>;
  isRead?: InputMaybe<BoolPredicate>;
  media?: InputMaybe<File_PermissionRelationFilter>;
  messageMedia?: InputMaybe<InboxMessageMedia_PermissionFilter>;
  reply?: InputMaybe<BoolPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type InboxMessage_PermissionRelationFilter = {
  every?: InputMaybe<InboxMessage_PermissionFilter>;
  none?: InputMaybe<InboxMessage_PermissionFilter>;
  some?: InputMaybe<InboxMessage_PermissionFilter>;
};

/** InboxMessages relation input */
export type InboxMessagesAuthorManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** InboxMessages relation input */
export type InboxMessagesAuthorRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<SentMessages_UserCreateInput>;
};

/** InboxMessages relation input */
export type InboxMessagesAuthorUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<SentMessages_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<SentMessages_UserUpdateInput>;
};

/** InboxMessages relation input */
export type InboxMessagesDeletedByManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** InboxMessages relation input */
export type InboxMessagesDeletedByRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<DeleteInboxMessages_UserCreateInput>>>;
};

/** InboxMessages relation input */
export type InboxMessagesDeletedByUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<DeleteInboxMessages_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<DeleteInboxMessages_UserUpdateInput>>>;
};

/** InboxMessages relation input */
export type InboxMessagesInboxManyRelationInput = {
  connect?: InputMaybe<InboxKeyFilter>;
};

/** InboxMessages relation input */
export type InboxMessagesInboxRelationInput = {
  connect?: InputMaybe<InboxKeyFilter>;
  create?: InputMaybe<Messages_InboxCreateInput>;
};

/** InboxMessages relation input */
export type InboxMessagesInboxUpdateRelationInput = {
  connect?: InputMaybe<InboxKeyFilter>;
  create?: InputMaybe<Messages_InboxCreateInput>;
  disconnect?: InputMaybe<InboxKeyFilter>;
  reconnect?: InputMaybe<InboxKeyFilter>;
  update?: InputMaybe<Messages_InboxUpdateInput>;
};

/** InboxMessages relation input */
export type InboxMessagesIsHideManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** InboxMessages relation input */
export type InboxMessagesIsHideRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HiddenInboxMessages_UserCreateInput>>>;
};

/** InboxMessages relation input */
export type InboxMessagesIsHideUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<HiddenInboxMessages_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<HiddenInboxMessages_UserUpdateInput>>>;
};

/** Inbox relation input */
export type InboxMessagesManyRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
};

/** InboxMessages relation input */
export type InboxMessagesMediaManyRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
};

/** InboxMessages relation input */
export type InboxMessagesMediaRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<InboxMessages_Media_FileCreateInput>>>;
};

/** InboxMessages relation input */
export type InboxMessagesMediaUpdateRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<InboxMessages_Media_FileCreateInput>>>;
  disconnect?: InputMaybe<Array<FileKeyFilter>>;
  reconnect?: InputMaybe<Array<FileKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<InboxMessages_Media_FileUpdateInput>>>;
};

/** InboxMessages relation input */
export type InboxMessagesMessageMediaManyRelationInput = {
  connect?: InputMaybe<InboxMessageMediaKeyFilter>;
};

/** InboxMessages relation input */
export type InboxMessagesMessageMediaRelationInput = {
  connect?: InputMaybe<InboxMessageMediaKeyFilter>;
  create?: InputMaybe<Message_InboxMessageMediaCreateInput>;
};

/** InboxMessages relation input */
export type InboxMessagesMessageMediaUpdateRelationInput = {
  connect?: InputMaybe<InboxMessageMediaKeyFilter>;
  create?: InputMaybe<Message_InboxMessageMediaCreateInput>;
  disconnect?: InputMaybe<InboxMessageMediaKeyFilter>;
  reconnect?: InputMaybe<InboxMessageMediaKeyFilter>;
  update?: InputMaybe<Message_InboxMessageMediaUpdateInput>;
};

/** Inbox relation input */
export type InboxMessagesRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Inbox_InboxMessageCreateInput>>>;
};

/** Inbox relation input */
export type InboxMessagesUpdateRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Inbox_InboxMessageCreateInput>>>;
  disconnect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  reconnect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Inbox_InboxMessageUpdateInput>>>;
};

/** Files create input from inboxMessages_media */
export type InboxMessages_Media_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from inboxMessages_media */
export type InboxMessages_Media_FileUpdateInput = {
  data: FileUpdateInput;
  filter?: InputMaybe<FileKeyFilter>;
};

/** No longer supported. Use `sort` instead. */
export type InboxOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'destinationGroups_ASC'
  | 'destinationGroups_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'subject_ASC'
  | 'subject_DESC'
  | 'type_ASC'
  | 'type_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** Inbox subscription payload */
export type InboxPayload = {
  __typename?: 'InboxPayload';
  mutation: MutationType;
  node?: Maybe<Inbox>;
  previousValues?: Maybe<Inbox>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InboxRelationFilter = {
  every?: InputMaybe<InboxFilter>;
  none?: InputMaybe<InboxFilter>;
  some?: InputMaybe<InboxFilter>;
};

export type InboxSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Inbox subscription filter */
export type InboxSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<InboxFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** Inbox update input */
export type InboxUpdateByFilterInput = {
  destinationGroups?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  subject?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  type?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
};

/** Inbox update input */
export type InboxUpdateInput = {
  /** Groups to which the message was sent */
  destinationGroups?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  isHide?: InputMaybe<InboxIsHideUpdateRelationInput>;
  messages?: InputMaybe<InboxMessagesUpdateRelationInput>;
  /** Save either the content of the subject or the structure to get more details. */
  subject?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<InboxUsersUpdateRelationInput>;
};

/** Inbox relation input */
export type InboxUsersManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** Inbox relation input */
export type InboxUsersRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Inboxes_UserCreateInput>>>;
};

/** Inbox relation input */
export type InboxUsersUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Inboxes_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Inboxes_UserUpdateInput>>>;
};

/** InboxMessages create input from inbox */
export type Inbox_InboxMessageCreateInput = {
  author?: InputMaybe<InboxMessagesAuthorRelationInput>;
  deletedBy?: InputMaybe<InboxMessagesDeletedByRelationInput>;
  inbox?: InputMaybe<InboxMessagesInboxRelationInput>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isHide?: InputMaybe<InboxMessagesIsHideRelationInput>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<InboxMessagesMediaRelationInput>;
  messageMedia?: InputMaybe<InboxMessagesMessageMediaRelationInput>;
  reply?: InputMaybe<Scalars['Boolean']>;
  text: Scalars['String'];
};

/** InboxMessages update input from inbox */
export type Inbox_InboxMessageUpdateInput = {
  data: InboxMessageUpdateInput;
  filter?: InputMaybe<InboxMessageKeyFilter>;
};

export type Inbox_PermissionFilter = {
  AND?: InputMaybe<Array<Inbox_PermissionFilter>>;
  OR?: InputMaybe<Array<Inbox_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isHide?: InputMaybe<User_PermissionRelationFilter>;
  messages?: InputMaybe<InboxMessage_PermissionRelationFilter>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  users?: InputMaybe<User_PermissionRelationFilter>;
};

export type Inbox_PermissionRelationFilter = {
  every?: InputMaybe<Inbox_PermissionFilter>;
  none?: InputMaybe<Inbox_PermissionFilter>;
  some?: InputMaybe<Inbox_PermissionFilter>;
};

/** Users create input from inboxes */
export type Inboxes_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from inboxes */
export type Inboxes_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

/** Table Create Index Input */
export type IndexCreateInput = {
  columns: Array<TableIndexColumnInput>;
  force?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  tableId: Scalars['ID'];
  type: TableIndexType;
};

/** Table Delete Index Input */
export type IndexDeleteInput = {
  id: Scalars['ID'];
};

/** Table Update Index Input */
export type IndexUpdateInput = {
  columns?: InputMaybe<Array<TableIndexColumnInput>>;
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TableIndexType>;
};

export type IntPredicate = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not_equals?: InputMaybe<Scalars['Int']>;
  not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntPredicateHaving = {
  AND?: InputMaybe<Array<IntPredicateHaving>>;
  OR?: InputMaybe<Array<IntPredicateHaving>>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not_equals?: InputMaybe<Scalars['Int']>;
  not_in?: InputMaybe<Array<Scalars['Int']>>;
};

/** JobOffers create input from interestedUsers */
export type InterestedUsers_JobOfferCreateInput = {
  creator?: InputMaybe<JobOffersCreatorRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  engagementCount?: InputMaybe<Scalars['Int']>;
  interestedUsers?: InputMaybe<JobOffersInterestedUsersRelationInput>;
  managerCreator?: InputMaybe<JobOffersManagerCreatorRelationInput>;
  organization?: InputMaybe<JobOffersOrganizationRelationInput>;
  position: Scalars['String'];
  postDate?: InputMaybe<Scalars['DateTime']>;
  schools?: InputMaybe<JobOffersSchoolsRelationInput>;
};

/** JobOffers update input from interestedUsers */
export type InterestedUsers_JobOfferUpdateInput = {
  data: JobOfferUpdateInput;
  filter?: InputMaybe<JobOfferKeyFilter>;
};

export type IntrospectionQueryResponse = {
  __typename?: 'IntrospectionQueryResponse';
  url: Scalars['String'];
};

export type InvitationRoleEnum = 'ADMIN' | 'EDITOR' | 'VIEWER';

/** Invited By Name */
export type InvitedByName = {
  __typename?: 'InvitedByName';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  workspaceName?: Maybe<Scalars['String']>;
};

/** InvokeData */
export type InvokeData = {
  functionName: Scalars['String'];
  inputArgs?: InputMaybe<Scalars['String']>;
  nodeVersion?: InputMaybe<Scalars['String']>;
};

/** InvokeFunctionResponse */
export type InvokeFunctionResponse = {
  __typename?: 'InvokeFunctionResponse';
  responseData: Scalars['String'];
};

/** Inbox create input from isHide */
export type IsHide_InboxCreateInput = {
  /** Groups to which the message was sent */
  destinationGroups?: InputMaybe<Scalars['JSON']>;
  isHide?: InputMaybe<InboxIsHideRelationInput>;
  messages?: InputMaybe<InboxMessagesRelationInput>;
  /** Save either the content of the subject or the structure to get more details. */
  subject?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<InboxUsersRelationInput>;
};

/** InboxMessages create input from isHide */
export type IsHide_InboxMessageCreateInput = {
  author?: InputMaybe<InboxMessagesAuthorRelationInput>;
  deletedBy?: InputMaybe<InboxMessagesDeletedByRelationInput>;
  inbox: InboxMessagesInboxRelationInput;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isHide?: InputMaybe<InboxMessagesIsHideRelationInput>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<InboxMessagesMediaRelationInput>;
  messageMedia?: InputMaybe<InboxMessagesMessageMediaRelationInput>;
  reply?: InputMaybe<Scalars['Boolean']>;
  text: Scalars['String'];
};

/** InboxMessages update input from isHide */
export type IsHide_InboxMessageUpdateInput = {
  data: InboxMessageUpdateInput;
  filter?: InputMaybe<InboxMessageKeyFilter>;
};

/** Inbox update input from isHide */
export type IsHide_InboxUpdateInput = {
  data: InboxUpdateInput;
  filter?: InputMaybe<InboxKeyFilter>;
};

/** IsSocialUser */
export type IsSocialUser = {
  email: Scalars['String'];
};

export type IsSocialUserResponse = {
  __typename?: 'IsSocialUserResponse';
  isSocialUser?: Maybe<Scalars['Boolean']>;
};

export type JobEmployee = {
  __typename?: 'JobEmployee';
  _description?: Maybe<Scalars['String']>;
  /** Store the image of the company */
  companyImage?: Maybe<File>;
  /** Store the company location like address */
  companyLocation?: Maybe<SmartAddress>;
  /** Store the name of the company without having a real relationship */
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  isCurrentlyWorking?: Maybe<Scalars['Boolean']>;
  positionDescription?: Maybe<Scalars['String']>;
  positionTitle?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

/** JobEmployees create input */
export type JobEmployeeCreateInput = {
  companyImage?: InputMaybe<JobEmployeesCompanyImageRelationInput>;
  /** Store the company location like address */
  companyLocation?: InputMaybe<SmartAddressInput>;
  /** Store the name of the company without having a real relationship */
  companyName?: InputMaybe<Scalars['String']>;
  employmentType: Scalars['String'];
  endDate?: InputMaybe<Scalars['Date']>;
  isCurrentlyWorking: Scalars['Boolean'];
  positionDescription?: InputMaybe<Scalars['String']>;
  positionTitle: Scalars['String'];
  startDate: Scalars['Date'];
  user?: InputMaybe<JobEmployeesUserRelationInput>;
};

/** JobEmployees create many input */
export type JobEmployeeCreateManyInput = {
  companyImage?: InputMaybe<JobEmployeesCompanyImageManyRelationInput>;
  /** Store the company location like address */
  companyLocation?: InputMaybe<SmartAddressInput>;
  /** Store the name of the company without having a real relationship */
  companyName?: InputMaybe<Scalars['String']>;
  employmentType: Scalars['String'];
  endDate?: InputMaybe<Scalars['Date']>;
  isCurrentlyWorking: Scalars['Boolean'];
  positionDescription?: InputMaybe<Scalars['String']>;
  positionTitle: Scalars['String'];
  startDate: Scalars['Date'];
  user: JobEmployeesUserManyRelationInput;
};

/** JobEmployees delete input */
export type JobEmployeeDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** JobEmployeeFieldsPermissions create input */
export type JobEmployeeFieldsPermissions = {
  companyLocation?: InputMaybe<Scalars['Boolean']>;
  companyName?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  employmentType?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  isCurrentlyWorking?: InputMaybe<Scalars['Boolean']>;
  positionDescription?: InputMaybe<Scalars['Boolean']>;
  positionTitle?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type JobEmployeeFilter = {
  AND?: InputMaybe<Array<JobEmployeeFilter>>;
  OR?: InputMaybe<Array<JobEmployeeFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  companyImage?: InputMaybe<FileFilter>;
  companyLocation?: InputMaybe<SmartAddressPredicate>;
  companyName?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  employmentType?: InputMaybe<StringPredicate>;
  endDate?: InputMaybe<DatePredicate>;
  id?: InputMaybe<IdPredicate>;
  isCurrentlyWorking?: InputMaybe<BoolPredicate>;
  positionDescription?: InputMaybe<StringPredicate>;
  positionTitle?: InputMaybe<StringPredicate>;
  startDate?: InputMaybe<DatePredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type JobEmployeeGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: JobEmployeeGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type JobEmployeeGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  companyImage?: InputMaybe<FileGroupByQuery>;
  companyLocation?: InputMaybe<SmartAddressGroupByQuery>;
  companyName?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  employmentType?: InputMaybe<Array<GroupByField>>;
  endDate?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  isCurrentlyWorking?: InputMaybe<Array<GroupByField>>;
  positionDescription?: InputMaybe<Array<GroupByField>>;
  positionTitle?: InputMaybe<Array<GroupByField>>;
  startDate?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type JobEmployeeKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** JobEmployeeListResponse output */
export type JobEmployeeListResponse = {
  __typename?: 'JobEmployeeListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<JobEmployee>;
};

/** JobEmployeeManyResponse output */
export type JobEmployeeManyResponse = {
  __typename?: 'JobEmployeeManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<JobEmployee>;
};

/** No longer supported. Use `sort` instead. */
export type JobEmployeeOrderBy =
  | 'companyLocation_city_ASC'
  | 'companyLocation_city_DESC'
  | 'companyLocation_country_ASC'
  | 'companyLocation_country_DESC'
  | 'companyLocation_state_ASC'
  | 'companyLocation_state_DESC'
  | 'companyLocation_street1_ASC'
  | 'companyLocation_street1_DESC'
  | 'companyLocation_street2_ASC'
  | 'companyLocation_street2_DESC'
  | 'companyLocation_zip_ASC'
  | 'companyLocation_zip_DESC'
  | 'companyName_ASC'
  | 'companyName_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'employmentType_ASC'
  | 'employmentType_DESC'
  | 'endDate_ASC'
  | 'endDate_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isCurrentlyWorking_ASC'
  | 'isCurrentlyWorking_DESC'
  | 'positionDescription_ASC'
  | 'positionDescription_DESC'
  | 'positionTitle_ASC'
  | 'positionTitle_DESC'
  | 'startDate_ASC'
  | 'startDate_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** JobEmployees subscription payload */
export type JobEmployeePayload = {
  __typename?: 'JobEmployeePayload';
  mutation: MutationType;
  node?: Maybe<JobEmployee>;
  previousValues?: Maybe<JobEmployee>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type JobEmployeeRelationFilter = {
  every?: InputMaybe<JobEmployeeFilter>;
  none?: InputMaybe<JobEmployeeFilter>;
  some?: InputMaybe<JobEmployeeFilter>;
};

export type JobEmployeeSort = {
  companyImage?: InputMaybe<FileSort>;
  companyLocation?: InputMaybe<SmartAddressSort>;
  companyName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  employmentType?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCurrentlyWorking?: InputMaybe<SortOrder>;
  positionDescription?: InputMaybe<SortOrder>;
  positionTitle?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

/** JobEmployees subscription filter */
export type JobEmployeeSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<JobEmployeeFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** JobEmployees update input */
export type JobEmployeeUpdateByFilterInput = {
  companyName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  employmentType?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  endDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateInput>>>;
  isCurrentlyWorking?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  positionDescription?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  positionTitle?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  startDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateInput>>>;
};

/** JobEmployees update input */
export type JobEmployeeUpdateInput = {
  companyImage?: InputMaybe<JobEmployeesCompanyImageUpdateRelationInput>;
  /** Store the company location like address */
  companyLocation?: InputMaybe<SmartAddressInput>;
  /** Store the name of the company without having a real relationship */
  companyName?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['ID']>;
  isCurrentlyWorking?: InputMaybe<Scalars['Boolean']>;
  positionDescription?: InputMaybe<Scalars['String']>;
  positionTitle?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  user?: InputMaybe<JobEmployeesUserUpdateRelationInput>;
};

export type JobEmployee_PermissionFilter = {
  AND?: InputMaybe<Array<JobEmployee_PermissionFilter>>;
  OR?: InputMaybe<Array<JobEmployee_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  companyImage?: InputMaybe<File_PermissionFilter>;
  companyLocation?: InputMaybe<SmartAddressPredicate>;
  companyName?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  employmentType?: InputMaybe<StringPredicate>;
  endDate?: InputMaybe<DatePredicate>;
  id?: InputMaybe<IdPredicate>;
  isCurrentlyWorking?: InputMaybe<BoolPredicate>;
  positionDescription?: InputMaybe<StringPredicate>;
  positionTitle?: InputMaybe<StringPredicate>;
  startDate?: InputMaybe<DatePredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
};

export type JobEmployee_PermissionRelationFilter = {
  every?: InputMaybe<JobEmployee_PermissionFilter>;
  none?: InputMaybe<JobEmployee_PermissionFilter>;
  some?: InputMaybe<JobEmployee_PermissionFilter>;
};

/** JobEmployees relation input */
export type JobEmployeesCompanyImageManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** JobEmployees relation input */
export type JobEmployeesCompanyImageRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<JobEmployees_CompanyImage_FileCreateInput>;
};

/** JobEmployees relation input */
export type JobEmployeesCompanyImageUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<JobEmployees_CompanyImage_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<JobEmployees_CompanyImage_FileUpdateInput>;
};

/** JobEmployees relation input */
export type JobEmployeesUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** JobEmployees relation input */
export type JobEmployeesUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<JobExperience_UserCreateInput>;
};

/** JobEmployees relation input */
export type JobEmployeesUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<JobExperience_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<JobExperience_UserUpdateInput>;
};

/** Files create input from jobEmployees_companyImage */
export type JobEmployees_CompanyImage_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from jobEmployees_companyImage */
export type JobEmployees_CompanyImage_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** Users create input from jobExperience */
export type JobExperience_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from jobExperience */
export type JobExperience_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

export type JobOffer = {
  __typename?: 'JobOffer';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  creator?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  engagementCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  interestedUsers?: Maybe<UserPreferenceListResponse>;
  managerCreator?: Maybe<OrganizationManagerPreference>;
  organization?: Maybe<Organization>;
  position?: Maybe<Scalars['String']>;
  postDate?: Maybe<Scalars['DateTime']>;
  schools?: Maybe<SchoolConfigurationListResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type JobOfferInterestedUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserPreferenceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserPreferenceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserPreferenceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserPreferenceSort>>;
};

export type JobOfferSchoolsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationSort>>;
};

/** JobOffers create input */
export type JobOfferCreateInput = {
  creator?: InputMaybe<JobOffersCreatorRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  engagementCount?: InputMaybe<Scalars['Int']>;
  interestedUsers?: InputMaybe<JobOffersInterestedUsersRelationInput>;
  managerCreator?: InputMaybe<JobOffersManagerCreatorRelationInput>;
  organization?: InputMaybe<JobOffersOrganizationRelationInput>;
  position: Scalars['String'];
  postDate?: InputMaybe<Scalars['DateTime']>;
  schools?: InputMaybe<JobOffersSchoolsRelationInput>;
};

/** JobOffers create many input */
export type JobOfferCreateManyInput = {
  creator?: InputMaybe<JobOffersCreatorManyRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  engagementCount?: InputMaybe<Scalars['Int']>;
  interestedUsers?: InputMaybe<JobOffersInterestedUsersManyRelationInput>;
  managerCreator?: InputMaybe<JobOffersManagerCreatorManyRelationInput>;
  organization: JobOffersOrganizationManyRelationInput;
  position: Scalars['String'];
  postDate?: InputMaybe<Scalars['DateTime']>;
  schools?: InputMaybe<JobOffersSchoolsManyRelationInput>;
};

/** JobOffers delete input */
export type JobOfferDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** JobOfferFieldsPermissions create input */
export type JobOfferFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  employmentType?: InputMaybe<Scalars['Boolean']>;
  engagementCount?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Boolean']>;
  postDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type JobOfferFilter = {
  AND?: InputMaybe<Array<JobOfferFilter>>;
  OR?: InputMaybe<Array<JobOfferFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  creator?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  employmentType?: InputMaybe<StringPredicate>;
  engagementCount?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  interestedUsers?: InputMaybe<UserPreferenceRelationFilter>;
  managerCreator?: InputMaybe<OrganizationManagerPreferenceFilter>;
  organization?: InputMaybe<OrganizationFilter>;
  position?: InputMaybe<StringPredicate>;
  postDate?: InputMaybe<DateTimePredicate>;
  schools?: InputMaybe<SchoolConfigurationRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type JobOfferGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: JobOfferGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type JobOfferGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  creator?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  employmentType?: InputMaybe<Array<GroupByField>>;
  engagementCount?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  interestedUsers?: InputMaybe<UserPreferenceGroupByQuery>;
  managerCreator?: InputMaybe<OrganizationManagerPreferenceGroupByQuery>;
  organization?: InputMaybe<OrganizationGroupByQuery>;
  position?: InputMaybe<Array<GroupByField>>;
  postDate?: InputMaybe<Array<GroupByField>>;
  schools?: InputMaybe<SchoolConfigurationGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type JobOfferKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** JobOfferListResponse output */
export type JobOfferListResponse = {
  __typename?: 'JobOfferListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<JobOffer>;
};

/** JobOfferManyResponse output */
export type JobOfferManyResponse = {
  __typename?: 'JobOfferManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<JobOffer>;
};

/** No longer supported. Use `sort` instead. */
export type JobOfferOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'description_ASC'
  | 'description_DESC'
  | 'employmentType_ASC'
  | 'employmentType_DESC'
  | 'engagementCount_ASC'
  | 'engagementCount_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'position_ASC'
  | 'position_DESC'
  | 'postDate_ASC'
  | 'postDate_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** JobOffers subscription payload */
export type JobOfferPayload = {
  __typename?: 'JobOfferPayload';
  mutation: MutationType;
  node?: Maybe<JobOffer>;
  previousValues?: Maybe<JobOffer>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type JobOfferRelationFilter = {
  every?: InputMaybe<JobOfferFilter>;
  none?: InputMaybe<JobOfferFilter>;
  some?: InputMaybe<JobOfferFilter>;
};

export type JobOfferSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  creator?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  employmentType?: InputMaybe<SortOrder>;
  engagementCount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  managerCreator?: InputMaybe<OrganizationManagerPreferenceSort>;
  organization?: InputMaybe<OrganizationSort>;
  position?: InputMaybe<SortOrder>;
  postDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** JobOffers subscription filter */
export type JobOfferSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<JobOfferFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** JobOffers update input */
export type JobOfferUpdateByFilterInput = {
  description?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  employmentType?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  engagementCount?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  position?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  postDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
};

/** JobOffers update input */
export type JobOfferUpdateInput = {
  creator?: InputMaybe<JobOffersCreatorUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  engagementCount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  interestedUsers?: InputMaybe<JobOffersInterestedUsersUpdateRelationInput>;
  managerCreator?: InputMaybe<JobOffersManagerCreatorUpdateRelationInput>;
  organization?: InputMaybe<JobOffersOrganizationUpdateRelationInput>;
  position?: InputMaybe<Scalars['String']>;
  postDate?: InputMaybe<Scalars['DateTime']>;
  schools?: InputMaybe<JobOffersSchoolsUpdateRelationInput>;
};

export type JobOffer_PermissionFilter = {
  AND?: InputMaybe<Array<JobOffer_PermissionFilter>>;
  OR?: InputMaybe<Array<JobOffer_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  creator?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  employmentType?: InputMaybe<StringPredicate>;
  engagementCount?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  interestedUsers?: InputMaybe<UserPreference_PermissionRelationFilter>;
  managerCreator?: InputMaybe<OrganizationManagerPreference_PermissionFilter>;
  organization?: InputMaybe<Organization_PermissionFilter>;
  position?: InputMaybe<StringPredicate>;
  postDate?: InputMaybe<DateTimePredicate>;
  schools?: InputMaybe<SchoolConfiguration_PermissionRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type JobOffer_PermissionRelationFilter = {
  every?: InputMaybe<JobOffer_PermissionFilter>;
  none?: InputMaybe<JobOffer_PermissionFilter>;
  some?: InputMaybe<JobOffer_PermissionFilter>;
};

/** JobOffers relation input */
export type JobOffersCreatorManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** JobOffers relation input */
export type JobOffersCreatorRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<CreatedJobOffers_UserCreateInput>;
};

/** JobOffers relation input */
export type JobOffersCreatorUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<CreatedJobOffers_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<CreatedJobOffers_UserUpdateInput>;
};

/** JobOffers relation input */
export type JobOffersInterestedUsersManyRelationInput = {
  connect?: InputMaybe<Array<UserPreferenceKeyFilter>>;
};

/** JobOffers relation input */
export type JobOffersInterestedUsersRelationInput = {
  connect?: InputMaybe<Array<UserPreferenceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<JobsOfInterest_UserPreferenceCreateInput>>>;
};

/** JobOffers relation input */
export type JobOffersInterestedUsersUpdateRelationInput = {
  connect?: InputMaybe<Array<UserPreferenceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<JobsOfInterest_UserPreferenceCreateInput>>>;
  disconnect?: InputMaybe<Array<UserPreferenceKeyFilter>>;
  reconnect?: InputMaybe<Array<UserPreferenceKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<JobsOfInterest_UserPreferenceUpdateInput>>>;
};

/** JobOffers relation input */
export type JobOffersManagerCreatorManyRelationInput = {
  connect?: InputMaybe<OrganizationManagerPreferenceKeyFilter>;
};

/** JobOffers relation input */
export type JobOffersManagerCreatorRelationInput = {
  connect?: InputMaybe<OrganizationManagerPreferenceKeyFilter>;
  create?: InputMaybe<PostedJobOffers_OrganizationManagerPreferenceCreateInput>;
};

/** JobOffers relation input */
export type JobOffersManagerCreatorUpdateRelationInput = {
  connect?: InputMaybe<OrganizationManagerPreferenceKeyFilter>;
  create?: InputMaybe<PostedJobOffers_OrganizationManagerPreferenceCreateInput>;
  disconnect?: InputMaybe<OrganizationManagerPreferenceKeyFilter>;
  reconnect?: InputMaybe<OrganizationManagerPreferenceKeyFilter>;
  update?: InputMaybe<PostedJobOffers_OrganizationManagerPreferenceUpdateInput>;
};

/** JobOffers relation input */
export type JobOffersOrganizationManyRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
};

/** JobOffers relation input */
export type JobOffersOrganizationRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<JobOffers_OrganizationCreateInput>;
};

/** JobOffers relation input */
export type JobOffersOrganizationUpdateRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<JobOffers_OrganizationCreateInput>;
  disconnect?: InputMaybe<OrganizationKeyFilter>;
  reconnect?: InputMaybe<OrganizationKeyFilter>;
  update?: InputMaybe<JobOffers_OrganizationUpdateInput>;
};

/** JobOffers relation input */
export type JobOffersSchoolsManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
};

/** JobOffers relation input */
export type JobOffersSchoolsRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<JobOffers_SchoolConfigurationCreateInput>>>;
};

/** JobOffers relation input */
export type JobOffersSchoolsUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<JobOffers_SchoolConfigurationCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<JobOffers_SchoolConfigurationUpdateInput>>>;
};

/** Organizations create input from jobOffers */
export type JobOffers_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from jobOffers */
export type JobOffers_OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

/** SchoolConfiguration create input from jobOffers */
export type JobOffers_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from jobOffers */
export type JobOffers_SchoolConfigurationUpdateInput = {
  data: SchoolConfigurationUpdateInput;
  filter?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** UserPreferences create input from jobsOfInterest */
export type JobsOfInterest_UserPreferenceCreateInput = {
  address?: InputMaybe<UserPreferencesAddressRelationInput>;
  jobsOfInterest?: InputMaybe<UserPreferencesJobsOfInterestRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  secondaryPhoneNumber?: InputMaybe<Scalars['String']>;
  stripeInformation?: InputMaybe<UserPreferencesStripeInformationRelationInput>;
  user?: InputMaybe<UserPreferencesUserRelationInput>;
};

/** UserPreferences update input from jobsOfInterest */
export type JobsOfInterest_UserPreferenceUpdateInput = {
  data: UserPreferenceUpdateInput;
  filter?: InputMaybe<UserPreferenceKeyFilter>;
};

/** Users create input from joinedGroups */
export type JoinedGroups_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from joinedGroups */
export type JoinedGroups_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

/** GroupPosts create input from lastFlaggedBy */
export type LastFlaggedBy_GroupPostCreateInput = {
  author?: InputMaybe<GroupPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<GroupPostsFlaggedByRelationInput>;
  group?: InputMaybe<GroupPostsGroupRelationInput>;
  groupAdminPreferences?: InputMaybe<GroupPostsGroupAdminPreferencesRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<GroupPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<GroupPostsMediaRelationInput>;
  mentions?: InputMaybe<GroupPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** GroupPosts update input from lastFlaggedBy */
export type LastFlaggedBy_GroupPostUpdateInput = {
  author?: InputMaybe<GroupPostsAuthorUpdateRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<GroupPostsFlaggedByUpdateRelationInput>;
  group?: InputMaybe<GroupPostsGroupUpdateRelationInput>;
  groupAdminPreferences?: InputMaybe<GroupPostsGroupAdminPreferencesUpdateRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<GroupPostsLastFlaggedByUpdateRelationInput>;
  media?: InputMaybe<GroupPostsMediaUpdateRelationInput>;
  mentions?: InputMaybe<GroupPostsMentionsUpdateRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** HomeFeedPosts create input from lastFlaggedBy */
export type LastFlaggedBy_HomeFeedPostCreateInput = {
  author?: InputMaybe<HomeFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<HomeFeedPostsDiscountRelationInput>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<HomeFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<HomeFeedPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<HomeFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<HomeFeedPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<HomeFeedPostsSchoolsRelationInput>;
  /**
   * Approved - post is displayed
   * Pending - default status, awaits auto-approval/approved by admins. Not displayed
   * Rejected - auto-rejected/rejected by admins
   * reportReviewPending - post got flagged, pending review
   */
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** HomeFeedPosts update input from lastFlaggedBy */
export type LastFlaggedBy_HomeFeedPostUpdateInput = {
  author?: InputMaybe<HomeFeedPostsAuthorUpdateRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<HomeFeedPostsDiscountUpdateRelationInput>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<HomeFeedPostsFlaggedByUpdateRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<HomeFeedPostsLastFlaggedByUpdateRelationInput>;
  media?: InputMaybe<HomeFeedPostsMediaUpdateRelationInput>;
  mentions?: InputMaybe<HomeFeedPostsMentionsUpdateRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<HomeFeedPostsSchoolsUpdateRelationInput>;
  /**
   * Approved - post is displayed
   * Pending - default status, awaits auto-approval/approved by admins. Not displayed
   * Rejected - auto-rejected/rejected by admins
   * reportReviewPending - post got flagged, pending review
   */
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** LegacyFeedPosts create input from lastFlaggedBy */
export type LastFlaggedBy_LegacyFeedPostCreateInput = {
  author?: InputMaybe<LegacyFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<LegacyFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<LegacyFeedPostsLastFlaggedByRelationInput>;
  legacyDate?: InputMaybe<Scalars['Date']>;
  media?: InputMaybe<LegacyFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<LegacyFeedPostsMentionsRelationInput>;
  month?: InputMaybe<LegacyFeedPostsMonthRelationInput>;
  monthId?: InputMaybe<Scalars['String']>;
  /** Date where the post will be available on the feed. */
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<LegacyFeedPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** LegacyFeedPosts update input from lastFlaggedBy */
export type LastFlaggedBy_LegacyFeedPostUpdateInput = {
  author?: InputMaybe<LegacyFeedPostsAuthorUpdateRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<LegacyFeedPostsFlaggedByUpdateRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<LegacyFeedPostsLastFlaggedByUpdateRelationInput>;
  legacyDate?: InputMaybe<Scalars['Date']>;
  media?: InputMaybe<LegacyFeedPostsMediaUpdateRelationInput>;
  mentions?: InputMaybe<LegacyFeedPostsMentionsUpdateRelationInput>;
  month?: InputMaybe<LegacyFeedPostsMonthUpdateRelationInput>;
  monthId?: InputMaybe<Scalars['String']>;
  /** Date where the post will be available on the feed. */
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<LegacyFeedPostsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** SchoolNewsPosts create input from lastFlaggedBy */
export type LastFlaggedBy_SchoolNewsPostCreateInput = {
  author?: InputMaybe<SchoolNewsPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<SchoolNewsPostsFlaggedByRelationInput>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned: Scalars['Boolean'];
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<SchoolNewsPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<SchoolNewsPostsMediaRelationInput>;
  mentions?: InputMaybe<SchoolNewsPostsMentionsRelationInput>;
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<SchoolNewsPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** SchoolNewsPosts update input from lastFlaggedBy */
export type LastFlaggedBy_SchoolNewsPostUpdateInput = {
  author?: InputMaybe<SchoolNewsPostsAuthorUpdateRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<SchoolNewsPostsFlaggedByUpdateRelationInput>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<SchoolNewsPostsLastFlaggedByUpdateRelationInput>;
  media?: InputMaybe<SchoolNewsPostsMediaUpdateRelationInput>;
  mentions?: InputMaybe<SchoolNewsPostsMentionsUpdateRelationInput>;
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<SchoolNewsPostsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** Users create input from lastFlaggedGroupPost */
export type LastFlaggedGroupPost_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from lastFlaggedGroupPost */
export type LastFlaggedGroupPost_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Users create input from lastFlaggedHomePost */
export type LastFlaggedHomePost_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from lastFlaggedHomePost */
export type LastFlaggedHomePost_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Users create input from lastFlaggedLegacyPost */
export type LastFlaggedLegacyPost_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from lastFlaggedLegacyPost */
export type LastFlaggedLegacyPost_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Users create input from lastFlaggedSchoolPost */
export type LastFlaggedSchoolPost_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from lastFlaggedSchoolPost */
export type LastFlaggedSchoolPost_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** SchoolConfiguration create input from layout */
export type Layout_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address: SchoolConfigurationAddressRelationInput;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from layout */
export type Layout_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

export type LegacyFeedMonth = {
  __typename?: 'LegacyFeedMonth';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  legacyPosts?: Maybe<LegacyFeedPostListResponse>;
  legacyYears?: Maybe<LegacyFeedYear>;
  month?: Maybe<Scalars['Int']>;
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegacyFeedMonthLegacyPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedPostSort>>;
};

/** LegacyFeedMonths create input */
export type LegacyFeedMonthCreateInput = {
  legacyPosts?: InputMaybe<LegacyFeedMonthsLegacyPostsRelationInput>;
  legacyYears?: InputMaybe<LegacyFeedMonthsLegacyYearsRelationInput>;
  month?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<LegacyFeedMonthsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

/** LegacyFeedMonths create many input */
export type LegacyFeedMonthCreateManyInput = {
  legacyPosts?: InputMaybe<LegacyFeedMonthsLegacyPostsManyRelationInput>;
  legacyYears: LegacyFeedMonthsLegacyYearsManyRelationInput;
  month?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<LegacyFeedMonthsSchoolManyRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

/** LegacyFeedMonths delete input */
export type LegacyFeedMonthDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** LegacyFeedMonthFieldsPermissions create input */
export type LegacyFeedMonthFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LegacyFeedMonthFilter = {
  AND?: InputMaybe<Array<LegacyFeedMonthFilter>>;
  OR?: InputMaybe<Array<LegacyFeedMonthFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  legacyPosts?: InputMaybe<LegacyFeedPostRelationFilter>;
  legacyYears?: InputMaybe<LegacyFeedYearFilter>;
  month?: InputMaybe<IntPredicate>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type LegacyFeedMonthGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: LegacyFeedMonthGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type LegacyFeedMonthGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  legacyPosts?: InputMaybe<LegacyFeedPostGroupByQuery>;
  legacyYears?: InputMaybe<LegacyFeedYearGroupByQuery>;
  month?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type LegacyFeedMonthKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** LegacyFeedMonthListResponse output */
export type LegacyFeedMonthListResponse = {
  __typename?: 'LegacyFeedMonthListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<LegacyFeedMonth>;
};

/** LegacyFeedMonthManyResponse output */
export type LegacyFeedMonthManyResponse = {
  __typename?: 'LegacyFeedMonthManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<LegacyFeedMonth>;
};

/** No longer supported. Use `sort` instead. */
export type LegacyFeedMonthOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'month_ASC'
  | 'month_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** LegacyFeedMonths subscription payload */
export type LegacyFeedMonthPayload = {
  __typename?: 'LegacyFeedMonthPayload';
  mutation: MutationType;
  node?: Maybe<LegacyFeedMonth>;
  previousValues?: Maybe<LegacyFeedMonth>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LegacyFeedMonthRelationFilter = {
  every?: InputMaybe<LegacyFeedMonthFilter>;
  none?: InputMaybe<LegacyFeedMonthFilter>;
  some?: InputMaybe<LegacyFeedMonthFilter>;
};

export type LegacyFeedMonthSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  legacyYears?: InputMaybe<LegacyFeedYearSort>;
  month?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** LegacyFeedMonths subscription filter */
export type LegacyFeedMonthSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<LegacyFeedMonthFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** LegacyFeedMonths update input */
export type LegacyFeedMonthUpdateByFilterInput = {
  month?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** LegacyFeedMonths update input */
export type LegacyFeedMonthUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  legacyPosts?: InputMaybe<LegacyFeedMonthsLegacyPostsUpdateRelationInput>;
  legacyYears?: InputMaybe<LegacyFeedMonthsLegacyYearsUpdateRelationInput>;
  month?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<LegacyFeedMonthsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type LegacyFeedMonth_PermissionFilter = {
  AND?: InputMaybe<Array<LegacyFeedMonth_PermissionFilter>>;
  OR?: InputMaybe<Array<LegacyFeedMonth_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  legacyPosts?: InputMaybe<LegacyFeedPost_PermissionRelationFilter>;
  legacyYears?: InputMaybe<LegacyFeedYear_PermissionFilter>;
  month?: InputMaybe<IntPredicate>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type LegacyFeedMonth_PermissionRelationFilter = {
  every?: InputMaybe<LegacyFeedMonth_PermissionFilter>;
  none?: InputMaybe<LegacyFeedMonth_PermissionFilter>;
  some?: InputMaybe<LegacyFeedMonth_PermissionFilter>;
};

/** LegacyFeedMonths relation input */
export type LegacyFeedMonthsLegacyPostsManyRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
};

/** LegacyFeedMonths relation input */
export type LegacyFeedMonthsLegacyPostsRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Month_LegacyFeedPostCreateInput>>>;
};

/** LegacyFeedMonths relation input */
export type LegacyFeedMonthsLegacyPostsUpdateRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Month_LegacyFeedPostCreateInput>>>;
  disconnect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  reconnect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Month_LegacyFeedPostUpdateInput>>>;
};

/** LegacyFeedMonths relation input */
export type LegacyFeedMonthsLegacyYearsManyRelationInput = {
  connect?: InputMaybe<LegacyFeedYearKeyFilter>;
};

/** LegacyFeedMonths relation input */
export type LegacyFeedMonthsLegacyYearsRelationInput = {
  connect?: InputMaybe<LegacyFeedYearKeyFilter>;
  create?: InputMaybe<LegacyMonths_LegacyFeedYearCreateInput>;
};

/** LegacyFeedMonths relation input */
export type LegacyFeedMonthsLegacyYearsUpdateRelationInput = {
  connect?: InputMaybe<LegacyFeedYearKeyFilter>;
  create?: InputMaybe<LegacyMonths_LegacyFeedYearCreateInput>;
  disconnect?: InputMaybe<LegacyFeedYearKeyFilter>;
  reconnect?: InputMaybe<LegacyFeedYearKeyFilter>;
  update?: InputMaybe<LegacyMonths_LegacyFeedYearUpdateInput>;
};

/** LegacyFeedMonths relation input */
export type LegacyFeedMonthsSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** LegacyFeedMonths relation input */
export type LegacyFeedMonthsSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<LegacyFeedMonths_SchoolConfigurationCreateInput>;
};

/** LegacyFeedMonths relation input */
export type LegacyFeedMonthsSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<LegacyFeedMonths_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<LegacyFeedMonths_SchoolConfigurationUpdateInput>;
};

/** SchoolConfiguration create input from legacyFeedMonths */
export type LegacyFeedMonths_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from legacyFeedMonths */
export type LegacyFeedMonths_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

export type LegacyFeedPost = {
  __typename?: 'LegacyFeedPost';
  _description?: Maybe<Scalars['String']>;
  author?: Maybe<User>;
  commentsAllowed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  flagReason?: Maybe<Scalars['String']>;
  flaggedAt?: Maybe<Scalars['DateTime']>;
  flaggedBy?: Maybe<UserListResponse>;
  id?: Maybe<Scalars['ID']>;
  isAutoReported?: Maybe<Scalars['Boolean']>;
  isFlagged?: Maybe<Scalars['Boolean']>;
  isPinned?: Maybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: Maybe<Scalars['Boolean']>;
  lastFlaggedBy?: Maybe<User>;
  legacyDate?: Maybe<Scalars['Date']>;
  /** Media files: [video, image] */
  media?: Maybe<FileListResponse>;
  mentions?: Maybe<PostMentionListResponse>;
  /** Relationship between legacy posts and their months on the timeline. */
  month?: Maybe<LegacyFeedMonth>;
  monthId?: Maybe<Scalars['String']>;
  /** Date where the post will be available on the feed. */
  postDate?: Maybe<Scalars['DateTime']>;
  reportStatus?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegacyFeedPostFlaggedByArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

export type LegacyFeedPostMediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FileSort>>;
};

export type LegacyFeedPostMentionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PostMentionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<PostMentionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<PostMentionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PostMentionSort>>;
};

/** LegacyFeedPosts create input */
export type LegacyFeedPostCreateInput = {
  author?: InputMaybe<LegacyFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<LegacyFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<LegacyFeedPostsLastFlaggedByRelationInput>;
  legacyDate?: InputMaybe<Scalars['Date']>;
  media?: InputMaybe<LegacyFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<LegacyFeedPostsMentionsRelationInput>;
  month?: InputMaybe<LegacyFeedPostsMonthRelationInput>;
  monthId?: InputMaybe<Scalars['String']>;
  /** Date where the post will be available on the feed. */
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<LegacyFeedPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** LegacyFeedPosts create many input */
export type LegacyFeedPostCreateManyInput = {
  author: LegacyFeedPostsAuthorManyRelationInput;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<LegacyFeedPostsFlaggedByManyRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<LegacyFeedPostsLastFlaggedByManyRelationInput>;
  legacyDate?: InputMaybe<Scalars['Date']>;
  media?: InputMaybe<LegacyFeedPostsMediaManyRelationInput>;
  mentions?: InputMaybe<LegacyFeedPostsMentionsManyRelationInput>;
  month?: InputMaybe<LegacyFeedPostsMonthManyRelationInput>;
  monthId?: InputMaybe<Scalars['String']>;
  /** Date where the post will be available on the feed. */
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<LegacyFeedPostsSchoolManyRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** LegacyFeedPosts delete input */
export type LegacyFeedPostDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** LegacyFeedPostFieldsPermissions create input */
export type LegacyFeedPostFieldsPermissions = {
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['Boolean']>;
  flaggedAt?: InputMaybe<Scalars['Boolean']>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  legacyDate?: InputMaybe<Scalars['Boolean']>;
  monthId?: InputMaybe<Scalars['Boolean']>;
  postDate?: InputMaybe<Scalars['Boolean']>;
  reportStatus?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LegacyFeedPostFilter = {
  AND?: InputMaybe<Array<LegacyFeedPostFilter>>;
  OR?: InputMaybe<Array<LegacyFeedPostFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<UserFilter>;
  commentsAllowed?: InputMaybe<BoolPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  flagReason?: InputMaybe<StringPredicate>;
  flaggedAt?: InputMaybe<DateTimePredicate>;
  flaggedBy?: InputMaybe<UserRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  isAutoReported?: InputMaybe<BoolPredicate>;
  isFlagged?: InputMaybe<BoolPredicate>;
  isPinned?: InputMaybe<BoolPredicate>;
  isReviewed?: InputMaybe<BoolPredicate>;
  lastFlaggedBy?: InputMaybe<UserFilter>;
  legacyDate?: InputMaybe<DatePredicate>;
  media?: InputMaybe<FileRelationFilter>;
  mentions?: InputMaybe<PostMentionRelationFilter>;
  month?: InputMaybe<LegacyFeedMonthFilter>;
  monthId?: InputMaybe<StringPredicate>;
  postDate?: InputMaybe<DateTimePredicate>;
  reportStatus?: InputMaybe<StringPredicate>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type LegacyFeedPostGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: LegacyFeedPostGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type LegacyFeedPostGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  author?: InputMaybe<UserGroupByQuery>;
  commentsAllowed?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  flagReason?: InputMaybe<Array<GroupByField>>;
  flaggedAt?: InputMaybe<Array<GroupByField>>;
  flaggedBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isAutoReported?: InputMaybe<Array<GroupByField>>;
  isFlagged?: InputMaybe<Array<GroupByField>>;
  isPinned?: InputMaybe<Array<GroupByField>>;
  isReviewed?: InputMaybe<Array<GroupByField>>;
  lastFlaggedBy?: InputMaybe<UserGroupByQuery>;
  legacyDate?: InputMaybe<Array<GroupByField>>;
  media?: InputMaybe<FileGroupByQuery>;
  mentions?: InputMaybe<PostMentionGroupByQuery>;
  month?: InputMaybe<LegacyFeedMonthGroupByQuery>;
  monthId?: InputMaybe<Array<GroupByField>>;
  postDate?: InputMaybe<Array<GroupByField>>;
  reportStatus?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  text?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type LegacyFeedPostKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** LegacyFeedPostListResponse output */
export type LegacyFeedPostListResponse = {
  __typename?: 'LegacyFeedPostListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<LegacyFeedPost>;
};

/** LegacyFeedPostManyResponse output */
export type LegacyFeedPostManyResponse = {
  __typename?: 'LegacyFeedPostManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<LegacyFeedPost>;
};

/** No longer supported. Use `sort` instead. */
export type LegacyFeedPostOrderBy =
  | 'commentsAllowed_ASC'
  | 'commentsAllowed_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'flagReason_ASC'
  | 'flagReason_DESC'
  | 'flaggedAt_ASC'
  | 'flaggedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isAutoReported_ASC'
  | 'isAutoReported_DESC'
  | 'isFlagged_ASC'
  | 'isFlagged_DESC'
  | 'isPinned_ASC'
  | 'isPinned_DESC'
  | 'isReviewed_ASC'
  | 'isReviewed_DESC'
  | 'legacyDate_ASC'
  | 'legacyDate_DESC'
  | 'monthId_ASC'
  | 'monthId_DESC'
  | 'postDate_ASC'
  | 'postDate_DESC'
  | 'reportStatus_ASC'
  | 'reportStatus_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'text_ASC'
  | 'text_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** LegacyFeedPosts subscription payload */
export type LegacyFeedPostPayload = {
  __typename?: 'LegacyFeedPostPayload';
  mutation: MutationType;
  node?: Maybe<LegacyFeedPost>;
  previousValues?: Maybe<LegacyFeedPost>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LegacyFeedPostRelationFilter = {
  every?: InputMaybe<LegacyFeedPostFilter>;
  none?: InputMaybe<LegacyFeedPostFilter>;
  some?: InputMaybe<LegacyFeedPostFilter>;
};

export type LegacyFeedPostSort = {
  author?: InputMaybe<UserSort>;
  commentsAllowed?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  flagReason?: InputMaybe<SortOrder>;
  flaggedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAutoReported?: InputMaybe<SortOrder>;
  isFlagged?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isReviewed?: InputMaybe<SortOrder>;
  lastFlaggedBy?: InputMaybe<UserSort>;
  legacyDate?: InputMaybe<SortOrder>;
  month?: InputMaybe<LegacyFeedMonthSort>;
  monthId?: InputMaybe<SortOrder>;
  postDate?: InputMaybe<SortOrder>;
  reportStatus?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** LegacyFeedPosts subscription filter */
export type LegacyFeedPostSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<LegacyFeedPostFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** LegacyFeedPosts update input */
export type LegacyFeedPostUpdateByFilterInput = {
  commentsAllowed?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  flagReason?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  flaggedAt?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  isAutoReported?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isFlagged?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isPinned?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isReviewed?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  legacyDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateInput>>>;
  monthId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  postDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  reportStatus?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  text?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** LegacyFeedPosts update input */
export type LegacyFeedPostUpdateInput = {
  author?: InputMaybe<LegacyFeedPostsAuthorUpdateRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<LegacyFeedPostsFlaggedByUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<LegacyFeedPostsLastFlaggedByUpdateRelationInput>;
  legacyDate?: InputMaybe<Scalars['Date']>;
  media?: InputMaybe<LegacyFeedPostsMediaUpdateRelationInput>;
  mentions?: InputMaybe<LegacyFeedPostsMentionsUpdateRelationInput>;
  month?: InputMaybe<LegacyFeedPostsMonthUpdateRelationInput>;
  monthId?: InputMaybe<Scalars['String']>;
  /** Date where the post will be available on the feed. */
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<LegacyFeedPostsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type LegacyFeedPost_PermissionFilter = {
  AND?: InputMaybe<Array<LegacyFeedPost_PermissionFilter>>;
  OR?: InputMaybe<Array<LegacyFeedPost_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<User_PermissionFilter>;
  commentsAllowed?: InputMaybe<BoolPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  flagReason?: InputMaybe<StringPredicate>;
  flaggedAt?: InputMaybe<DateTimePredicate>;
  flaggedBy?: InputMaybe<User_PermissionRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  isAutoReported?: InputMaybe<BoolPredicate>;
  isFlagged?: InputMaybe<BoolPredicate>;
  isPinned?: InputMaybe<BoolPredicate>;
  isReviewed?: InputMaybe<BoolPredicate>;
  lastFlaggedBy?: InputMaybe<User_PermissionFilter>;
  legacyDate?: InputMaybe<DatePredicate>;
  media?: InputMaybe<File_PermissionRelationFilter>;
  mentions?: InputMaybe<PostMention_PermissionRelationFilter>;
  month?: InputMaybe<LegacyFeedMonth_PermissionFilter>;
  monthId?: InputMaybe<StringPredicate>;
  postDate?: InputMaybe<DateTimePredicate>;
  reportStatus?: InputMaybe<StringPredicate>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type LegacyFeedPost_PermissionRelationFilter = {
  every?: InputMaybe<LegacyFeedPost_PermissionFilter>;
  none?: InputMaybe<LegacyFeedPost_PermissionFilter>;
  some?: InputMaybe<LegacyFeedPost_PermissionFilter>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsAuthorManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsAuthorRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<LegacyFeedPosts_UserCreateInput>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsAuthorUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<LegacyFeedPosts_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<LegacyFeedPosts_UserUpdateInput>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsFlaggedByManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsFlaggedByRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedLegacyFeedPosts_UserCreateInput>>>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsFlaggedByUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedLegacyFeedPosts_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<FlaggedLegacyFeedPosts_UserUpdateInput>>>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsLastFlaggedByManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsLastFlaggedByRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<LastFlaggedLegacyPost_UserCreateInput>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsLastFlaggedByUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<LastFlaggedLegacyPost_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<LastFlaggedLegacyPost_UserUpdateInput>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsMediaManyRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsMediaRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<LegacyFeedPosts_Media_FileCreateInput>>>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsMediaUpdateRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<LegacyFeedPosts_Media_FileCreateInput>>>;
  disconnect?: InputMaybe<Array<FileKeyFilter>>;
  reconnect?: InputMaybe<Array<FileKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<LegacyFeedPosts_Media_FileUpdateInput>>>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsMentionsManyRelationInput = {
  connect?: InputMaybe<Array<PostMentionKeyFilter>>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsMentionsRelationInput = {
  connect?: InputMaybe<Array<PostMentionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<LegacyPost_PostMentionCreateInput>>>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsMentionsUpdateRelationInput = {
  connect?: InputMaybe<Array<PostMentionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<LegacyPost_PostMentionCreateInput>>>;
  disconnect?: InputMaybe<Array<PostMentionKeyFilter>>;
  reconnect?: InputMaybe<Array<PostMentionKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<LegacyPost_PostMentionUpdateInput>>>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsMonthManyRelationInput = {
  connect?: InputMaybe<LegacyFeedMonthKeyFilter>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsMonthRelationInput = {
  connect?: InputMaybe<LegacyFeedMonthKeyFilter>;
  create?: InputMaybe<LegacyPosts_LegacyFeedMonthCreateInput>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsMonthUpdateRelationInput = {
  connect?: InputMaybe<LegacyFeedMonthKeyFilter>;
  create?: InputMaybe<LegacyPosts_LegacyFeedMonthCreateInput>;
  disconnect?: InputMaybe<LegacyFeedMonthKeyFilter>;
  reconnect?: InputMaybe<LegacyFeedMonthKeyFilter>;
  update?: InputMaybe<LegacyPosts_LegacyFeedMonthUpdateInput>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<LegacyPosts_SchoolConfigurationCreateInput>;
};

/** LegacyFeedPosts relation input */
export type LegacyFeedPostsSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<LegacyPosts_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<LegacyPosts_SchoolConfigurationUpdateInput>;
};

/** Users create input from legacyFeedPosts */
export type LegacyFeedPosts_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from legacyFeedPosts */
export type LegacyFeedPosts_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Files create input from legacyFeedPosts_media */
export type LegacyFeedPosts_Media_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from legacyFeedPosts_media */
export type LegacyFeedPosts_Media_FileUpdateInput = {
  data: FileUpdateInput;
  filter?: InputMaybe<FileKeyFilter>;
};

export type LegacyFeedYear = {
  __typename?: 'LegacyFeedYear';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  legacyMonths?: Maybe<LegacyFeedMonthListResponse>;
  legacyYear?: Maybe<Scalars['Int']>;
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegacyFeedYearLegacyMonthsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedMonthFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedMonthGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedMonthOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedMonthSort>>;
};

/** LegacyFeedYears create input */
export type LegacyFeedYearCreateInput = {
  legacyMonths?: InputMaybe<LegacyFeedYearsLegacyMonthsRelationInput>;
  legacyYear?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<LegacyFeedYearsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

/** LegacyFeedYears create many input */
export type LegacyFeedYearCreateManyInput = {
  legacyMonths?: InputMaybe<LegacyFeedYearsLegacyMonthsManyRelationInput>;
  legacyYear?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<LegacyFeedYearsSchoolManyRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

/** LegacyFeedYears delete input */
export type LegacyFeedYearDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** LegacyFeedYearFieldsPermissions create input */
export type LegacyFeedYearFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  legacyYear?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LegacyFeedYearFilter = {
  AND?: InputMaybe<Array<LegacyFeedYearFilter>>;
  OR?: InputMaybe<Array<LegacyFeedYearFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  legacyMonths?: InputMaybe<LegacyFeedMonthRelationFilter>;
  legacyYear?: InputMaybe<IntPredicate>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type LegacyFeedYearGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: LegacyFeedYearGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type LegacyFeedYearGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  legacyMonths?: InputMaybe<LegacyFeedMonthGroupByQuery>;
  legacyYear?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type LegacyFeedYearKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
  legacyYear?: InputMaybe<Scalars['Int']>;
};

/** LegacyFeedYearListResponse output */
export type LegacyFeedYearListResponse = {
  __typename?: 'LegacyFeedYearListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<LegacyFeedYear>;
};

/** LegacyFeedYearManyResponse output */
export type LegacyFeedYearManyResponse = {
  __typename?: 'LegacyFeedYearManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<LegacyFeedYear>;
};

/** No longer supported. Use `sort` instead. */
export type LegacyFeedYearOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'legacyYear_ASC'
  | 'legacyYear_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** LegacyFeedYears subscription payload */
export type LegacyFeedYearPayload = {
  __typename?: 'LegacyFeedYearPayload';
  mutation: MutationType;
  node?: Maybe<LegacyFeedYear>;
  previousValues?: Maybe<LegacyFeedYear>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LegacyFeedYearRelationFilter = {
  every?: InputMaybe<LegacyFeedYearFilter>;
  none?: InputMaybe<LegacyFeedYearFilter>;
  some?: InputMaybe<LegacyFeedYearFilter>;
};

export type LegacyFeedYearSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  legacyYear?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** LegacyFeedYears subscription filter */
export type LegacyFeedYearSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<LegacyFeedYearFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** LegacyFeedYears update input */
export type LegacyFeedYearUpdateByFilterInput = {
  legacyYear?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** LegacyFeedYears update input */
export type LegacyFeedYearUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  legacyMonths?: InputMaybe<LegacyFeedYearsLegacyMonthsUpdateRelationInput>;
  legacyYear?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<LegacyFeedYearsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type LegacyFeedYear_PermissionFilter = {
  AND?: InputMaybe<Array<LegacyFeedYear_PermissionFilter>>;
  OR?: InputMaybe<Array<LegacyFeedYear_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  legacyMonths?: InputMaybe<LegacyFeedMonth_PermissionRelationFilter>;
  legacyYear?: InputMaybe<IntPredicate>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type LegacyFeedYear_PermissionRelationFilter = {
  every?: InputMaybe<LegacyFeedYear_PermissionFilter>;
  none?: InputMaybe<LegacyFeedYear_PermissionFilter>;
  some?: InputMaybe<LegacyFeedYear_PermissionFilter>;
};

/** LegacyFeedYears relation input */
export type LegacyFeedYearsLegacyMonthsManyRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedMonthKeyFilter>>;
};

/** LegacyFeedYears relation input */
export type LegacyFeedYearsLegacyMonthsRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedMonthKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<LegacyYears_LegacyFeedMonthCreateInput>>>;
};

/** LegacyFeedYears relation input */
export type LegacyFeedYearsLegacyMonthsUpdateRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedMonthKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<LegacyYears_LegacyFeedMonthCreateInput>>>;
  disconnect?: InputMaybe<Array<LegacyFeedMonthKeyFilter>>;
  reconnect?: InputMaybe<Array<LegacyFeedMonthKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<LegacyYears_LegacyFeedMonthUpdateInput>>>;
};

/** LegacyFeedYears relation input */
export type LegacyFeedYearsSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** LegacyFeedYears relation input */
export type LegacyFeedYearsSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<LegacyFeedYears_SchoolConfigurationCreateInput>;
};

/** LegacyFeedYears relation input */
export type LegacyFeedYearsSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<LegacyFeedYears_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<LegacyFeedYears_SchoolConfigurationUpdateInput>;
};

/** SchoolConfiguration create input from legacyFeedYears */
export type LegacyFeedYears_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from legacyFeedYears */
export type LegacyFeedYears_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** LegacyFeedYears create input from legacyMonths */
export type LegacyMonths_LegacyFeedYearCreateInput = {
  legacyMonths?: InputMaybe<LegacyFeedYearsLegacyMonthsRelationInput>;
  legacyYear?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<LegacyFeedYearsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

/** LegacyFeedYears update input from legacyMonths */
export type LegacyMonths_LegacyFeedYearUpdateInput = {
  legacyMonths?: InputMaybe<LegacyFeedYearsLegacyMonthsUpdateRelationInput>;
  legacyYear?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<LegacyFeedYearsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

/** PostMentions create input from legacyPost */
export type LegacyPost_PostMentionCreateInput = {
  groupPost?: InputMaybe<PostMentionsGroupPostRelationInput>;
  homePost?: InputMaybe<PostMentionsHomePostRelationInput>;
  legacyPost?: InputMaybe<PostMentionsLegacyPostRelationInput>;
  legacyPostId?: InputMaybe<Scalars['String']>;
  mentionId?: InputMaybe<Scalars['String']>;
  mentionText?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  schoolPost?: InputMaybe<PostMentionsSchoolPostRelationInput>;
  type?: InputMaybe<Scalars['String']>;
};

/** PostMentions update input from legacyPost */
export type LegacyPost_PostMentionUpdateInput = {
  data: PostMentionUpdateInput;
  filter?: InputMaybe<PostMentionKeyFilter>;
};

/** LegacyFeedMonths create input from legacyPosts */
export type LegacyPosts_LegacyFeedMonthCreateInput = {
  legacyPosts?: InputMaybe<LegacyFeedMonthsLegacyPostsRelationInput>;
  legacyYears?: InputMaybe<LegacyFeedMonthsLegacyYearsRelationInput>;
  month?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<LegacyFeedMonthsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

/** LegacyFeedMonths update input from legacyPosts */
export type LegacyPosts_LegacyFeedMonthUpdateInput = {
  legacyPosts?: InputMaybe<LegacyFeedMonthsLegacyPostsUpdateRelationInput>;
  legacyYears?: InputMaybe<LegacyFeedMonthsLegacyYearsUpdateRelationInput>;
  month?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<LegacyFeedMonthsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

/** SchoolConfiguration create input from legacyPosts */
export type LegacyPosts_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from legacyPosts */
export type LegacyPosts_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** LegacyFeedMonths create input from legacyYears */
export type LegacyYears_LegacyFeedMonthCreateInput = {
  legacyPosts?: InputMaybe<LegacyFeedMonthsLegacyPostsRelationInput>;
  legacyYears?: InputMaybe<LegacyFeedMonthsLegacyYearsRelationInput>;
  month?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<LegacyFeedMonthsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

/** LegacyFeedMonths update input from legacyYears */
export type LegacyYears_LegacyFeedMonthUpdateInput = {
  data: LegacyFeedMonthUpdateInput;
  filter?: InputMaybe<LegacyFeedMonthKeyFilter>;
};

/** Users create input from likedOrganizations */
export type LikedOrganizations_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from likedOrganizations */
export type LikedOrganizations_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

export type LocateFunctionArguments = {
  pos?: InputMaybe<Scalars['Int']>;
  str: Scalars['String'];
};

/** Events create input from location */
export type Location_EventCreateInput = {
  author?: InputMaybe<EventsAuthorRelationInput>;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<EventsImagesRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<EventsLocationRelationInput>;
  otherSponsors?: InputMaybe<EventsOtherSponsorsRelationInput>;
  school?: InputMaybe<EventsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  sponsorRequests?: InputMaybe<EventsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleSponsor?: InputMaybe<EventsTitleSponsorRelationInput>;
};

/** Events update input from location */
export type Location_EventUpdateInput = {
  data: EventUpdateInput;
  filter?: InputMaybe<EventKeyFilter>;
};

/** Organizations create input from location */
export type Location_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from location */
export type Location_OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

/** LoginResponse */
export type LoginResponse = {
  __typename?: 'LoginResponse';
  auth?: Maybe<Auth>;
  success?: Maybe<Scalars['Boolean']>;
  workspaces?: Maybe<Array<WorkspaceInfo>>;
};

/** Groups create input from logo */
export type Logo_GroupCreateInput = {
  audiences?: InputMaybe<GroupsAudiencesRelationInput>;
  creator?: InputMaybe<GroupsCreatorRelationInput>;
  description: Scalars['String'];
  groupAdminPreferences?: InputMaybe<GroupsGroupAdminPreferencesRelationInput>;
  groupToJoin?: InputMaybe<GroupsGroupToJoinRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<GroupsLogoRelationInput>;
  members?: InputMaybe<GroupsMembersRelationInput>;
  membersCount?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<GroupsPostsRelationInput>;
  postsCount?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<GroupsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Groups update input from logo */
export type Logo_GroupUpdateInput = {
  data: GroupUpdateInput;
  filter?: InputMaybe<GroupKeyFilter>;
};

/** HighSchoolLocations create input from logo */
export type Logo_HighSchoolLocationCreateInput = {
  audiences?: InputMaybe<HighSchoolLocationsAudiencesRelationInput>;
  city?: InputMaybe<Scalars['String']>;
  educations?: InputMaybe<HighSchoolLocationsEducationsRelationInput>;
  logo?: InputMaybe<HighSchoolLocationsLogoRelationInput>;
  logoId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** HighSchoolLocations update input from logo */
export type Logo_HighSchoolLocationUpdateInput = {
  data: HighSchoolLocationUpdateInput;
  filter?: InputMaybe<HighSchoolLocationKeyFilter>;
};

/** Organizations create input from logo */
export type Logo_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from logo */
export type Logo_OrganizationUpdateInput = {
  data: OrganizationUpdateInput;
  filter?: InputMaybe<OrganizationKeyFilter>;
};

/** SchoolConfigurationImages create input from logo */
export type Logo_SchoolConfigurationImageCreateInput = {
  applicationLogo?: InputMaybe<SchoolConfigurationImagesApplicationLogoRelationInput>;
  applicationLogoId?: InputMaybe<Scalars['String']>;
  browserLogo?: InputMaybe<SchoolConfigurationImagesBrowserLogoRelationInput>;
  browserLogoId?: InputMaybe<Scalars['String']>;
  configuration: SchoolConfigurationImagesConfigurationRelationInput;
  emailBanner?: InputMaybe<SchoolConfigurationImagesEmailBannerRelationInput>;
  emailLogo?: InputMaybe<SchoolConfigurationImagesEmailLogoRelationInput>;
  logo?: InputMaybe<SchoolConfigurationImagesLogoRelationInput>;
  splashImage?: InputMaybe<SchoolConfigurationImagesSplashImageRelationInput>;
};

/** SchoolConfigurationImages update input from logo */
export type Logo_SchoolConfigurationImageUpdateInput = {
  data: SchoolConfigurationImageUpdateInput;
  filter?: InputMaybe<SchoolConfigurationImageKeyFilter>;
};

export type ManagerAcceptRequestInput = {
  businessId: Scalars['String'];
  userId: Scalars['String'];
};

/** JobOffers create input from managerCreator */
export type ManagerCreator_JobOfferCreateInput = {
  creator?: InputMaybe<JobOffersCreatorRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  engagementCount?: InputMaybe<Scalars['Int']>;
  interestedUsers?: InputMaybe<JobOffersInterestedUsersRelationInput>;
  managerCreator?: InputMaybe<JobOffersManagerCreatorRelationInput>;
  organization?: InputMaybe<JobOffersOrganizationRelationInput>;
  position: Scalars['String'];
  postDate?: InputMaybe<Scalars['DateTime']>;
  schools?: InputMaybe<JobOffersSchoolsRelationInput>;
};

/** JobOffers update input from managerCreator */
export type ManagerCreator_JobOfferUpdateInput = {
  data: JobOfferUpdateInput;
  filter?: InputMaybe<JobOfferKeyFilter>;
};

/** Users create input from managerPreferences */
export type ManagerPreferences_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from managerPreferences */
export type ManagerPreferences_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Organizations create input from managers */
export type Managers_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from managers */
export type Managers_OrganizationUpdateInput = {
  data: OrganizationUpdateInput;
  filter?: InputMaybe<OrganizationKeyFilter>;
};

/** GroupPosts create input from media */
export type Media_GroupPostCreateInput = {
  author?: InputMaybe<GroupPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<GroupPostsFlaggedByRelationInput>;
  group?: InputMaybe<GroupPostsGroupRelationInput>;
  groupAdminPreferences?: InputMaybe<GroupPostsGroupAdminPreferencesRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<GroupPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<GroupPostsMediaRelationInput>;
  mentions?: InputMaybe<GroupPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** GroupPosts update input from media */
export type Media_GroupPostUpdateInput = {
  data: GroupPostUpdateInput;
  filter?: InputMaybe<GroupPostKeyFilter>;
};

/** HomeFeedPosts create input from media */
export type Media_HomeFeedPostCreateInput = {
  author?: InputMaybe<HomeFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<HomeFeedPostsDiscountRelationInput>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<HomeFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<HomeFeedPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<HomeFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<HomeFeedPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<HomeFeedPostsSchoolsRelationInput>;
  /**
   * Approved - post is displayed
   * Pending - default status, awaits auto-approval/approved by admins. Not displayed
   * Rejected - auto-rejected/rejected by admins
   * reportReviewPending - post got flagged, pending review
   */
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** HomeFeedPosts update input from media */
export type Media_HomeFeedPostUpdateInput = {
  data: HomeFeedPostUpdateInput;
  filter?: InputMaybe<HomeFeedPostKeyFilter>;
};

/** InboxMessages create input from media */
export type Media_InboxMessageCreateInput = {
  author?: InputMaybe<InboxMessagesAuthorRelationInput>;
  deletedBy?: InputMaybe<InboxMessagesDeletedByRelationInput>;
  inbox?: InputMaybe<InboxMessagesInboxRelationInput>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isHide?: InputMaybe<InboxMessagesIsHideRelationInput>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<InboxMessagesMediaRelationInput>;
  messageMedia?: InputMaybe<InboxMessagesMessageMediaRelationInput>;
  reply?: InputMaybe<Scalars['Boolean']>;
  text: Scalars['String'];
};

/** InboxMessageMedia create input from media */
export type Media_InboxMessageMediaCreateInput = {
  media?: InputMaybe<InboxMessageMediaMediaRelationInput>;
  message?: InputMaybe<InboxMessageMediaMessageRelationInput>;
};

/** InboxMessageMedia update input from media */
export type Media_InboxMessageMediaUpdateInput = {
  data: InboxMessageMediaUpdateInput;
  filter?: InputMaybe<InboxMessageMediaKeyFilter>;
};

/** InboxMessages update input from media */
export type Media_InboxMessageUpdateInput = {
  data: InboxMessageUpdateInput;
  filter?: InputMaybe<InboxMessageKeyFilter>;
};

/** LegacyFeedPosts create input from media */
export type Media_LegacyFeedPostCreateInput = {
  author?: InputMaybe<LegacyFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<LegacyFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<LegacyFeedPostsLastFlaggedByRelationInput>;
  legacyDate?: InputMaybe<Scalars['Date']>;
  media?: InputMaybe<LegacyFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<LegacyFeedPostsMentionsRelationInput>;
  month?: InputMaybe<LegacyFeedPostsMonthRelationInput>;
  monthId?: InputMaybe<Scalars['String']>;
  /** Date where the post will be available on the feed. */
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<LegacyFeedPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** LegacyFeedPosts update input from media */
export type Media_LegacyFeedPostUpdateInput = {
  data: LegacyFeedPostUpdateInput;
  filter?: InputMaybe<LegacyFeedPostKeyFilter>;
};

/** Organizations create input from media */
export type Media_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from media */
export type Media_OrganizationUpdateInput = {
  data: OrganizationUpdateInput;
  filter?: InputMaybe<OrganizationKeyFilter>;
};

/** SchoolNewsPosts create input from media */
export type Media_SchoolNewsPostCreateInput = {
  author?: InputMaybe<SchoolNewsPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<SchoolNewsPostsFlaggedByRelationInput>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned: Scalars['Boolean'];
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<SchoolNewsPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<SchoolNewsPostsMediaRelationInput>;
  mentions?: InputMaybe<SchoolNewsPostsMentionsRelationInput>;
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<SchoolNewsPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** SchoolNewsPosts update input from media */
export type Media_SchoolNewsPostUpdateInput = {
  data: SchoolNewsPostUpdateInput;
  filter?: InputMaybe<SchoolNewsPostKeyFilter>;
};

/** Groups create input from members */
export type Members_GroupCreateInput = {
  audiences?: InputMaybe<GroupsAudiencesRelationInput>;
  creator: GroupsCreatorRelationInput;
  description: Scalars['String'];
  groupAdminPreferences?: InputMaybe<GroupsGroupAdminPreferencesRelationInput>;
  groupToJoin?: InputMaybe<GroupsGroupToJoinRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<GroupsLogoRelationInput>;
  members?: InputMaybe<GroupsMembersRelationInput>;
  membersCount?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<GroupsPostsRelationInput>;
  postsCount?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<GroupsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Groups update input from members */
export type Members_GroupUpdateInput = {
  data: GroupUpdateInput;
  filter?: InputMaybe<GroupKeyFilter>;
};

/** GroupPosts create input from mentions */
export type Mentions_GroupPostCreateInput = {
  author?: InputMaybe<GroupPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<GroupPostsFlaggedByRelationInput>;
  group?: InputMaybe<GroupPostsGroupRelationInput>;
  groupAdminPreferences?: InputMaybe<GroupPostsGroupAdminPreferencesRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<GroupPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<GroupPostsMediaRelationInput>;
  mentions?: InputMaybe<GroupPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** GroupPosts update input from mentions */
export type Mentions_GroupPostUpdateInput = {
  author?: InputMaybe<GroupPostsAuthorUpdateRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<GroupPostsFlaggedByUpdateRelationInput>;
  group?: InputMaybe<GroupPostsGroupUpdateRelationInput>;
  groupAdminPreferences?: InputMaybe<GroupPostsGroupAdminPreferencesUpdateRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<GroupPostsLastFlaggedByUpdateRelationInput>;
  media?: InputMaybe<GroupPostsMediaUpdateRelationInput>;
  mentions?: InputMaybe<GroupPostsMentionsUpdateRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** HomeFeedPosts create input from mentions */
export type Mentions_HomeFeedPostCreateInput = {
  author?: InputMaybe<HomeFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<HomeFeedPostsDiscountRelationInput>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<HomeFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<HomeFeedPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<HomeFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<HomeFeedPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<HomeFeedPostsSchoolsRelationInput>;
  /**
   * Approved - post is displayed
   * Pending - default status, awaits auto-approval/approved by admins. Not displayed
   * Rejected - auto-rejected/rejected by admins
   * reportReviewPending - post got flagged, pending review
   */
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** HomeFeedPosts update input from mentions */
export type Mentions_HomeFeedPostUpdateInput = {
  author?: InputMaybe<HomeFeedPostsAuthorUpdateRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<HomeFeedPostsDiscountUpdateRelationInput>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<HomeFeedPostsFlaggedByUpdateRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<HomeFeedPostsLastFlaggedByUpdateRelationInput>;
  media?: InputMaybe<HomeFeedPostsMediaUpdateRelationInput>;
  mentions?: InputMaybe<HomeFeedPostsMentionsUpdateRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<HomeFeedPostsSchoolsUpdateRelationInput>;
  /**
   * Approved - post is displayed
   * Pending - default status, awaits auto-approval/approved by admins. Not displayed
   * Rejected - auto-rejected/rejected by admins
   * reportReviewPending - post got flagged, pending review
   */
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** LegacyFeedPosts create input from mentions */
export type Mentions_LegacyFeedPostCreateInput = {
  author?: InputMaybe<LegacyFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<LegacyFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<LegacyFeedPostsLastFlaggedByRelationInput>;
  legacyDate?: InputMaybe<Scalars['Date']>;
  media?: InputMaybe<LegacyFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<LegacyFeedPostsMentionsRelationInput>;
  month?: InputMaybe<LegacyFeedPostsMonthRelationInput>;
  monthId?: InputMaybe<Scalars['String']>;
  /** Date where the post will be available on the feed. */
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<LegacyFeedPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** LegacyFeedPosts update input from mentions */
export type Mentions_LegacyFeedPostUpdateInput = {
  author?: InputMaybe<LegacyFeedPostsAuthorUpdateRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<LegacyFeedPostsFlaggedByUpdateRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<LegacyFeedPostsLastFlaggedByUpdateRelationInput>;
  legacyDate?: InputMaybe<Scalars['Date']>;
  media?: InputMaybe<LegacyFeedPostsMediaUpdateRelationInput>;
  mentions?: InputMaybe<LegacyFeedPostsMentionsUpdateRelationInput>;
  month?: InputMaybe<LegacyFeedPostsMonthUpdateRelationInput>;
  monthId?: InputMaybe<Scalars['String']>;
  /** Date where the post will be available on the feed. */
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<LegacyFeedPostsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** SchoolNewsPosts create input from mentions */
export type Mentions_SchoolNewsPostCreateInput = {
  author?: InputMaybe<SchoolNewsPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<SchoolNewsPostsFlaggedByRelationInput>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned: Scalars['Boolean'];
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<SchoolNewsPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<SchoolNewsPostsMediaRelationInput>;
  mentions?: InputMaybe<SchoolNewsPostsMentionsRelationInput>;
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<SchoolNewsPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** SchoolNewsPosts update input from mentions */
export type Mentions_SchoolNewsPostUpdateInput = {
  author?: InputMaybe<SchoolNewsPostsAuthorUpdateRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<SchoolNewsPostsFlaggedByUpdateRelationInput>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<SchoolNewsPostsLastFlaggedByUpdateRelationInput>;
  media?: InputMaybe<SchoolNewsPostsMediaUpdateRelationInput>;
  mentions?: InputMaybe<SchoolNewsPostsMentionsUpdateRelationInput>;
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<SchoolNewsPostsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** InboxMessages create input from messageMedia */
export type MessageMedia_InboxMessageCreateInput = {
  author?: InputMaybe<InboxMessagesAuthorRelationInput>;
  deletedBy?: InputMaybe<InboxMessagesDeletedByRelationInput>;
  inbox?: InputMaybe<InboxMessagesInboxRelationInput>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isHide?: InputMaybe<InboxMessagesIsHideRelationInput>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<InboxMessagesMediaRelationInput>;
  messageMedia?: InputMaybe<InboxMessagesMessageMediaRelationInput>;
  reply?: InputMaybe<Scalars['Boolean']>;
  text: Scalars['String'];
};

/** InboxMessages update input from messageMedia */
export type MessageMedia_InboxMessageUpdateInput = {
  data: InboxMessageUpdateInput;
  filter?: InputMaybe<InboxMessageKeyFilter>;
};

/** InboxMessageMedia create input from message */
export type Message_InboxMessageMediaCreateInput = {
  media?: InputMaybe<InboxMessageMediaMediaRelationInput>;
  message?: InputMaybe<InboxMessageMediaMessageRelationInput>;
};

/** InboxMessageMedia update input from message */
export type Message_InboxMessageMediaUpdateInput = {
  media?: InputMaybe<InboxMessageMediaMediaUpdateRelationInput>;
  message?: InputMaybe<InboxMessageMediaMessageUpdateRelationInput>;
};

/** Inbox create input from messages */
export type Messages_InboxCreateInput = {
  /** Groups to which the message was sent */
  destinationGroups?: InputMaybe<Scalars['JSON']>;
  isHide?: InputMaybe<InboxIsHideRelationInput>;
  messages?: InputMaybe<InboxMessagesRelationInput>;
  /** Save either the content of the subject or the structure to get more details. */
  subject?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<InboxUsersRelationInput>;
};

/** Inbox update input from messages */
export type Messages_InboxUpdateInput = {
  /** Groups to which the message was sent */
  destinationGroups?: InputMaybe<Scalars['JSON']>;
  isHide?: InputMaybe<InboxIsHideUpdateRelationInput>;
  messages?: InputMaybe<InboxMessagesUpdateRelationInput>;
  /** Save either the content of the subject or the structure to get more details. */
  subject?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<InboxUsersUpdateRelationInput>;
};

/** MissingRelation */
export type MissingRelation = {
  __typename?: 'MissingRelation';
  table: Scalars['String'];
};

/** MissingRelation Field Attributes */
export type MissingRelationFieldTypeAttributes = {
  __typename?: 'MissingRelationFieldTypeAttributes';
  missingTable: Scalars['String'];
};

/** LegacyFeedPosts create input from month */
export type Month_LegacyFeedPostCreateInput = {
  author?: InputMaybe<LegacyFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<LegacyFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<LegacyFeedPostsLastFlaggedByRelationInput>;
  legacyDate?: InputMaybe<Scalars['Date']>;
  media?: InputMaybe<LegacyFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<LegacyFeedPostsMentionsRelationInput>;
  month?: InputMaybe<LegacyFeedPostsMonthRelationInput>;
  monthId?: InputMaybe<Scalars['String']>;
  /** Date where the post will be available on the feed. */
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<LegacyFeedPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** LegacyFeedPosts update input from month */
export type Month_LegacyFeedPostUpdateInput = {
  data: LegacyFeedPostUpdateInput;
  filter?: InputMaybe<LegacyFeedPostKeyFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  ImportCSV?: Maybe<AsyncSession>;
  IsSocialUser?: Maybe<IsSocialUserResponse>;
  /**
   * Accept a user's request to join a school.
   * Only users with the role of "School Admin" can invoke this mutation.
   */
  acceptSchoolMembers: SuccessResponse;
  /**
   * Activates school memberships.
   * Only users with the role of "SchoolAdmin" can invoke this mutation.
   */
  activateSchoolMembers: SuccessResponse;
  adClickCreate: AdClick;
  adClickCreateMany: AdClickManyResponse;
  adClickDelete?: Maybe<SuccessResponse>;
  adClickDeleteByFilter?: Maybe<SuccessResponse>;
  adClickDestroy?: Maybe<SuccessResponse>;
  adClickDestroyByFilter?: Maybe<SuccessResponse>;
  adClickRestore: AdClick;
  adClickUpdate: AdClick;
  adClickUpdateByFilter: AdClickManyResponse;
  adViewCreate: AdView;
  adViewCreateMany: AdViewManyResponse;
  adViewDelete?: Maybe<SuccessResponse>;
  adViewDeleteByFilter?: Maybe<SuccessResponse>;
  adViewDestroy?: Maybe<SuccessResponse>;
  adViewDestroyByFilter?: Maybe<SuccessResponse>;
  adViewRestore: AdView;
  adViewUpdate: AdView;
  adViewUpdateByFilter: AdViewManyResponse;
  addressCreate: Address;
  addressCreateMany: AddressManyResponse;
  addressDelete?: Maybe<SuccessResponse>;
  addressDeleteByFilter?: Maybe<SuccessResponse>;
  addressDestroy?: Maybe<SuccessResponse>;
  addressDestroyByFilter?: Maybe<SuccessResponse>;
  addressRestore: Address;
  addressUpdate: Address;
  addressUpdateByFilter: AddressManyResponse;
  advertisingCampaignAdCreate: AdvertisingCampaignAd;
  advertisingCampaignAdCreateMany: AdvertisingCampaignAdManyResponse;
  advertisingCampaignAdDelete?: Maybe<SuccessResponse>;
  advertisingCampaignAdDeleteByFilter?: Maybe<SuccessResponse>;
  advertisingCampaignAdDestroy?: Maybe<SuccessResponse>;
  advertisingCampaignAdDestroyByFilter?: Maybe<SuccessResponse>;
  advertisingCampaignAdRestore: AdvertisingCampaignAd;
  advertisingCampaignAdUpdate: AdvertisingCampaignAd;
  advertisingCampaignAdUpdateByFilter: AdvertisingCampaignAdManyResponse;
  advertisingCreate: Advertising;
  advertisingCreateMany: AdvertisingManyResponse;
  advertisingDelete?: Maybe<SuccessResponse>;
  advertisingDeleteByFilter?: Maybe<SuccessResponse>;
  advertisingDestroy?: Maybe<SuccessResponse>;
  advertisingDestroyByFilter?: Maybe<SuccessResponse>;
  advertisingRestore: Advertising;
  advertisingSampaignCreate: AdvertisingSampaign;
  advertisingSampaignCreateMany: AdvertisingSampaignManyResponse;
  advertisingSampaignDelete?: Maybe<SuccessResponse>;
  advertisingSampaignDeleteByFilter?: Maybe<SuccessResponse>;
  advertisingSampaignDestroy?: Maybe<SuccessResponse>;
  advertisingSampaignDestroyByFilter?: Maybe<SuccessResponse>;
  advertisingSampaignRestore: AdvertisingSampaign;
  advertisingSampaignUpdate: AdvertisingSampaign;
  advertisingSampaignUpdateByFilter: AdvertisingSampaignManyResponse;
  advertisingSupporterCreate: AdvertisingSupporter;
  advertisingSupporterCreateMany: AdvertisingSupporterManyResponse;
  advertisingSupporterDelete?: Maybe<SuccessResponse>;
  advertisingSupporterDeleteByFilter?: Maybe<SuccessResponse>;
  advertisingSupporterDestroy?: Maybe<SuccessResponse>;
  advertisingSupporterDestroyByFilter?: Maybe<SuccessResponse>;
  advertisingSupporterRestore: AdvertisingSupporter;
  advertisingSupporterUpdate: AdvertisingSupporter;
  advertisingSupporterUpdateByFilter: AdvertisingSupporterManyResponse;
  advertisingUpdate: Advertising;
  apiTokenCreate: ApiTokenResponse;
  apiTokenDelete?: Maybe<SuccessResponse>;
  apiTokenDeleteByFilter?: Maybe<SuccessResponse>;
  apiTokenDestroy?: Maybe<SuccessResponse>;
  apiTokenDestroyByFilter?: Maybe<SuccessResponse>;
  apiTokenRestore: ApiToken;
  apiTokenUpdate: ApiToken;
  apiTokenUpdateByFilter: ApiTokenManyResponse;
  /** @deprecated No longer supported. Use `system.applicationDelete` instead. */
  applicationDelete?: Maybe<SuccessResponse>;
  /** @deprecated No longer supported. Use `system.applicationInstall` instead. */
  applicationInstall?: Maybe<Application>;
  /** @deprecated No longer supported. Use `system.applicationUpdate` instead. */
  applicationUpdate?: Maybe<Application>;
  audienceCreate: Audience;
  audienceCreateMany: AudienceManyResponse;
  audienceDelete?: Maybe<SuccessResponse>;
  audienceDeleteByFilter?: Maybe<SuccessResponse>;
  audienceDestroy?: Maybe<SuccessResponse>;
  audienceDestroyByFilter?: Maybe<SuccessResponse>;
  audienceRestore: Audience;
  audienceUpdate: Audience;
  audienceUpdateByFilter: AudienceManyResponse;
  authenticationProfileCreate: AuthenticationProfile;
  authenticationProfileCreateMany: AuthenticationProfileManyResponse;
  authenticationProfileDelete?: Maybe<SuccessResponse>;
  authenticationProfileDeleteByFilter?: Maybe<SuccessResponse>;
  authenticationProfileDestroy?: Maybe<SuccessResponse>;
  authenticationProfileDestroyByFilter?: Maybe<SuccessResponse>;
  authenticationProfileRestore: AuthenticationProfile;
  authenticationProfileUpdate: AuthenticationProfile;
  authenticationProfileUpdateByFilter: AuthenticationProfileManyResponse;
  authenticationSettingsUpdate: AuthenticationSetting;
  /** @deprecated No longer supported. Use `system.billingPlanUpdate` instead. */
  billingPlanUpdate?: Maybe<BillingCurrentPlanResponse>;
  billingRegisteredAdCreate: BillingRegisteredAd;
  billingRegisteredAdCreateMany: BillingRegisteredAdManyResponse;
  billingRegisteredAdDelete?: Maybe<SuccessResponse>;
  billingRegisteredAdDeleteByFilter?: Maybe<SuccessResponse>;
  billingRegisteredAdDestroy?: Maybe<SuccessResponse>;
  billingRegisteredAdDestroyByFilter?: Maybe<SuccessResponse>;
  billingRegisteredAdRestore: BillingRegisteredAd;
  billingRegisteredAdUpdate: BillingRegisteredAd;
  billingRegisteredAdUpdateByFilter: BillingRegisteredAdManyResponse;
  billingRegisteredSchoolSupporterCreate: BillingRegisteredSchoolSupporter;
  billingRegisteredSchoolSupporterCreateMany: BillingRegisteredSchoolSupporterManyResponse;
  billingRegisteredSchoolSupporterDelete?: Maybe<SuccessResponse>;
  billingRegisteredSchoolSupporterDeleteByFilter?: Maybe<SuccessResponse>;
  billingRegisteredSchoolSupporterDestroy?: Maybe<SuccessResponse>;
  billingRegisteredSchoolSupporterDestroyByFilter?: Maybe<SuccessResponse>;
  billingRegisteredSchoolSupporterRestore: BillingRegisteredSchoolSupporter;
  billingRegisteredSchoolSupporterUpdate: BillingRegisteredSchoolSupporter;
  billingRegisteredSchoolSupporterUpdateByFilter: BillingRegisteredSchoolSupporterManyResponse;
  billingRegisteredSpecialOfferCreate: BillingRegisteredSpecialOffer;
  billingRegisteredSpecialOfferCreateMany: BillingRegisteredSpecialOfferManyResponse;
  billingRegisteredSpecialOfferDelete?: Maybe<SuccessResponse>;
  billingRegisteredSpecialOfferDeleteByFilter?: Maybe<SuccessResponse>;
  billingRegisteredSpecialOfferDestroy?: Maybe<SuccessResponse>;
  billingRegisteredSpecialOfferDestroyByFilter?: Maybe<SuccessResponse>;
  billingRegisteredSpecialOfferRestore: BillingRegisteredSpecialOffer;
  billingRegisteredSpecialOfferUpdate: BillingRegisteredSpecialOffer;
  billingRegisteredSpecialOfferUpdateByFilter: BillingRegisteredSpecialOfferManyResponse;
  billingSchoolSupporterCreate: BillingSchoolSupporter;
  billingSchoolSupporterCreateMany: BillingSchoolSupporterManyResponse;
  billingSchoolSupporterDelete?: Maybe<SuccessResponse>;
  billingSchoolSupporterDeleteByFilter?: Maybe<SuccessResponse>;
  billingSchoolSupporterDestroy?: Maybe<SuccessResponse>;
  billingSchoolSupporterDestroyByFilter?: Maybe<SuccessResponse>;
  billingSchoolSupporterRestore: BillingSchoolSupporter;
  billingSchoolSupporterUpdate: BillingSchoolSupporter;
  billingSpecialOfferCreate: BillingSpecialOffer;
  billingSpecialOfferCreateMany: BillingSpecialOfferManyResponse;
  billingSpecialOfferDelete?: Maybe<SuccessResponse>;
  billingSpecialOfferDeleteByFilter?: Maybe<SuccessResponse>;
  billingSpecialOfferDestroy?: Maybe<SuccessResponse>;
  billingSpecialOfferDestroyByFilter?: Maybe<SuccessResponse>;
  billingSpecialOfferRestore: BillingSpecialOffer;
  billingSpecialOfferUpdate: BillingSpecialOffer;
  billingsAdCreate: BillingsAd;
  billingsAdCreateMany: BillingsAdManyResponse;
  billingsAdDelete?: Maybe<SuccessResponse>;
  billingsAdDeleteByFilter?: Maybe<SuccessResponse>;
  billingsAdDestroy?: Maybe<SuccessResponse>;
  billingsAdDestroyByFilter?: Maybe<SuccessResponse>;
  billingsAdRestore: BillingsAd;
  billingsAdUpdate: BillingsAd;
  businessReportUpdateByFilter: BusinessReportManyResponse;
  ciCdMigrationCreate: CiCdMigration;
  ciCdMigrationCreateMany: CiCdMigrationManyResponse;
  ciCdMigrationDelete?: Maybe<SuccessResponse>;
  ciCdMigrationDeleteByFilter?: Maybe<SuccessResponse>;
  ciCdMigrationDestroy?: Maybe<SuccessResponse>;
  ciCdMigrationDestroyByFilter?: Maybe<SuccessResponse>;
  ciCdMigrationRestore: CiCdMigration;
  ciCdMigrationUpdate: CiCdMigration;
  ciCdMigrationUpdateByFilter: CiCdMigrationManyResponse;
  claimOrganization: ClaimOrganizationResponse;
  collectiveIntelligenceThreadCreate: CollectiveIntelligenceThread;
  collectiveIntelligenceThreadCreateMany: CollectiveIntelligenceThreadManyResponse;
  collectiveIntelligenceThreadDelete?: Maybe<SuccessResponse>;
  collectiveIntelligenceThreadDeleteByFilter?: Maybe<SuccessResponse>;
  collectiveIntelligenceThreadDestroy?: Maybe<SuccessResponse>;
  collectiveIntelligenceThreadDestroyByFilter?: Maybe<SuccessResponse>;
  collectiveIntelligenceThreadRestore: CollectiveIntelligenceThread;
  collectiveIntelligenceThreadUpdate: CollectiveIntelligenceThread;
  collectiveIntelligenceThreadUpdateByFilter: CollectiveIntelligenceThreadManyResponse;
  contactOrganizationRequest: SuccessResponse;
  contentReportUpdateByFilter: ContentReportManyResponse;
  dashboardCreate: Dashboard;
  dashboardCreateMany: DashboardManyResponse;
  dashboardDelete?: Maybe<SuccessResponse>;
  dashboardDeleteByFilter?: Maybe<SuccessResponse>;
  dashboardDestroy?: Maybe<SuccessResponse>;
  dashboardDestroyByFilter?: Maybe<SuccessResponse>;
  dashboardRestore: Dashboard;
  dashboardUpdate: Dashboard;
  dashboardUpdateByFilter: DashboardManyResponse;
  debtCreate: Debt;
  debtCreateMany: DebtManyResponse;
  debtDelete?: Maybe<SuccessResponse>;
  debtDeleteByFilter?: Maybe<SuccessResponse>;
  debtDestroy?: Maybe<SuccessResponse>;
  debtDestroyByFilter?: Maybe<SuccessResponse>;
  debtOperationHistoryCreate: DebtOperationHistory;
  debtOperationHistoryCreateMany: DebtOperationHistoryManyResponse;
  debtOperationHistoryDelete?: Maybe<SuccessResponse>;
  debtOperationHistoryDeleteByFilter?: Maybe<SuccessResponse>;
  debtOperationHistoryDestroy?: Maybe<SuccessResponse>;
  debtOperationHistoryDestroyByFilter?: Maybe<SuccessResponse>;
  debtOperationHistoryRestore: DebtOperationHistory;
  debtOperationHistoryUpdate: DebtOperationHistory;
  debtOperationHistoryUpdateByFilter: DebtOperationHistoryManyResponse;
  debtRestore: Debt;
  debtUpdate: Debt;
  debtUpdateByFilter: DebtManyResponse;
  /** @deprecated No longer supported. Use `system.deploy` instead. */
  deploy?: Maybe<Scalars['Boolean']>;
  discountCreate: Discount;
  discountCreateMany: DiscountManyResponse;
  discountCreateRequest: DiscountCreateRequestResponse;
  discountDelete?: Maybe<SuccessResponse>;
  discountDeleteByFilter?: Maybe<SuccessResponse>;
  discountDestroy?: Maybe<SuccessResponse>;
  discountDestroyByFilter?: Maybe<SuccessResponse>;
  discountRedemptionCreate: DiscountRedemption;
  discountRedemptionCreateMany: DiscountRedemptionManyResponse;
  discountRedemptionDelete?: Maybe<SuccessResponse>;
  discountRedemptionDeleteByFilter?: Maybe<SuccessResponse>;
  discountRedemptionDestroy?: Maybe<SuccessResponse>;
  discountRedemptionDestroyByFilter?: Maybe<SuccessResponse>;
  discountRedemptionRestore: DiscountRedemption;
  discountRedemptionUpdate: DiscountRedemption;
  discountRedemptionUpdateByFilter: DiscountRedemptionManyResponse;
  discountRestore: Discount;
  discountUpdate: Discount;
  discountUpdateByFilter: DiscountManyResponse;
  discountUpdateStatusRequest: SuccessResponse;
  educationalInstitutionCreate: EducationalInstitution;
  educationalInstitutionCreateMany: EducationalInstitutionManyResponse;
  educationalInstitutionDelete?: Maybe<SuccessResponse>;
  educationalInstitutionDeleteByFilter?: Maybe<SuccessResponse>;
  educationalInstitutionDestroy?: Maybe<SuccessResponse>;
  educationalInstitutionDestroyByFilter?: Maybe<SuccessResponse>;
  educationalInstitutionRestore: EducationalInstitution;
  educationalInstitutionUpdate: EducationalInstitution;
  educationalInstitutionUpdateByFilter: EducationalInstitutionManyResponse;
  environmentVariableCreate: EnvironmentVariable;
  environmentVariableCreateMany: EnvironmentVariableManyResponse;
  environmentVariableDelete?: Maybe<SuccessResponse>;
  environmentVariableDeleteByFilter?: Maybe<SuccessResponse>;
  environmentVariableDestroy?: Maybe<SuccessResponse>;
  environmentVariableDestroyByFilter?: Maybe<SuccessResponse>;
  environmentVariableRestore: EnvironmentVariable;
  environmentVariableUpdate: EnvironmentVariable;
  environmentVariableUpdateByFilter: EnvironmentVariableManyResponse;
  eventCreate: Event;
  eventCreateMany: EventManyResponse;
  eventDelete?: Maybe<SuccessResponse>;
  eventDeleteByFilter?: Maybe<SuccessResponse>;
  eventDestroy?: Maybe<SuccessResponse>;
  eventDestroyByFilter?: Maybe<SuccessResponse>;
  eventRestore: Event;
  eventSponsorCreateRequest: SuccessResponse;
  eventSponsorRequestCreate: EventSponsorRequest;
  eventSponsorRequestCreateMany: EventSponsorRequestManyResponse;
  eventSponsorRequestDelete?: Maybe<SuccessResponse>;
  eventSponsorRequestDeleteByFilter?: Maybe<SuccessResponse>;
  eventSponsorRequestDestroy?: Maybe<SuccessResponse>;
  eventSponsorRequestDestroyByFilter?: Maybe<SuccessResponse>;
  eventSponsorRequestRestore: EventSponsorRequest;
  eventSponsorRequestUpdate: EventSponsorRequest;
  eventSponsorRequestUpdateByFilter: EventSponsorRequestManyResponse;
  eventStatusUpdateRequest: SuccessResponse;
  eventUpdate: Event;
  eventUpdateByFilter: EventManyResponse;
  /** @deprecated No longer supported. Use `system.fieldCreate` instead. */
  fieldCreate: TableField;
  /** @deprecated No longer supported. Use `system.fieldDelete` instead. */
  fieldDelete: SuccessResponse;
  /** @deprecated No longer supported. Use `system.fieldUpdate` instead. */
  fieldUpdate: TableField;
  /** @deprecated No longer supported. Use `system.fieldUpdatePosition` instead. */
  fieldUpdatePosition: SuccessResponse;
  fileCreate: File;
  fileCreateMany: FileManyResponse;
  fileDelete?: Maybe<SuccessResponse>;
  fileDeleteByFilter?: Maybe<SuccessResponse>;
  fileDestroy?: Maybe<SuccessResponse>;
  fileDestroyByFilter?: Maybe<SuccessResponse>;
  fileRestore: File;
  fileUpdate: File;
  fileUpdateByFilter: FileManyResponse;
  filesSettingCreate: FilesSetting;
  filesSettingCreateMany: FilesSettingManyResponse;
  filesSettingDelete?: Maybe<SuccessResponse>;
  filesSettingDeleteByFilter?: Maybe<SuccessResponse>;
  filesSettingDestroy?: Maybe<SuccessResponse>;
  filesSettingDestroyByFilter?: Maybe<SuccessResponse>;
  filesSettingRestore: FilesSetting;
  filesSettingUpdate: FilesSetting;
  filesSettingUpdateByFilter: FilesSettingManyResponse;
  flagPostRequest: SuccessResponse;
  flaggedPostApproveRequest: SuccessResponse;
  flaggedPostDeleteRequest: SuccessResponse;
  graduatingYearCreate: GraduatingYear;
  graduatingYearCreateMany: GraduatingYearManyResponse;
  graduatingYearDelete?: Maybe<SuccessResponse>;
  graduatingYearDeleteByFilter?: Maybe<SuccessResponse>;
  graduatingYearDestroy?: Maybe<SuccessResponse>;
  graduatingYearDestroyByFilter?: Maybe<SuccessResponse>;
  graduatingYearRestore: GraduatingYear;
  graduatingYearUpdate: GraduatingYear;
  graduatingYearUpdateByFilter: GraduatingYearManyResponse;
  graduatingYearsMetricUpdateByFilter: GraduatingYearsMetricManyResponse;
  groupAdminPreferenceCreate: GroupAdminPreference;
  groupAdminPreferenceCreateMany: GroupAdminPreferenceManyResponse;
  groupAdminPreferenceDelete?: Maybe<SuccessResponse>;
  groupAdminPreferenceDeleteByFilter?: Maybe<SuccessResponse>;
  groupAdminPreferenceDestroy?: Maybe<SuccessResponse>;
  groupAdminPreferenceDestroyByFilter?: Maybe<SuccessResponse>;
  groupAdminPreferenceRestore: GroupAdminPreference;
  groupAdminPreferenceUpdate: GroupAdminPreference;
  groupAdminPreferenceUpdateByFilter: GroupAdminPreferenceManyResponse;
  groupCreate: Group;
  groupCreateMany: GroupManyResponse;
  groupCreateRequest: GroupCreateRequestSuccess;
  groupDelete?: Maybe<SuccessResponse>;
  groupDeleteByFilter?: Maybe<SuccessResponse>;
  groupDestroy?: Maybe<SuccessResponse>;
  groupDestroyByFilter?: Maybe<SuccessResponse>;
  groupDetailsUpdateRequest: SuccessResponse;
  /** Demote a group member to a regular member. */
  groupMemberDemote: SuccessResponse;
  /** Promote a group member to a manager. */
  groupMemberPromote: SuccessResponse;
  /** Remove a member from a group. */
  groupMemberRemove: SuccessResponse;
  groupMembersRequestUpsert: GroupsMembersUpsertResponse;
  groupPostCreate: GroupPost;
  groupPostCreateMany: GroupPostManyResponse;
  groupPostDelete?: Maybe<SuccessResponse>;
  groupPostDeleteByFilter?: Maybe<SuccessResponse>;
  groupPostDestroy?: Maybe<SuccessResponse>;
  groupPostDestroyByFilter?: Maybe<SuccessResponse>;
  groupPostRestore: GroupPost;
  groupPostUpdate: GroupPost;
  groupPostUpdateByFilter: GroupPostManyResponse;
  groupRejectRequest: SuccessResponse;
  groupRestore: Group;
  groupUpdate: Group;
  groupUpdateByFilter: GroupManyResponse;
  groupsMembersRequestCreate: GroupsMembersRequest;
  groupsMembersRequestCreateMany: GroupsMembersRequestManyResponse;
  groupsMembersRequestDelete?: Maybe<SuccessResponse>;
  groupsMembersRequestDeleteByFilter?: Maybe<SuccessResponse>;
  groupsMembersRequestDestroy?: Maybe<SuccessResponse>;
  groupsMembersRequestDestroyByFilter?: Maybe<SuccessResponse>;
  groupsMembersRequestRestore: GroupsMembersRequest;
  groupsMembersRequestUpdate: GroupsMembersRequest;
  groupsMembersRequestUpdateByFilter: GroupsMembersRequestManyResponse;
  groupsStatCreate: GroupsStat;
  groupsStatCreateMany: GroupsStatManyResponse;
  groupsStatDelete?: Maybe<SuccessResponse>;
  groupsStatDeleteByFilter?: Maybe<SuccessResponse>;
  groupsStatDestroy?: Maybe<SuccessResponse>;
  groupsStatDestroyByFilter?: Maybe<SuccessResponse>;
  groupsStatRestore: GroupsStat;
  groupsStatUpdate: GroupsStat;
  groupsStatUpdateByFilter: GroupsStatManyResponse;
  highSchoolLocationCreate: HighSchoolLocation;
  highSchoolLocationCreateMany: HighSchoolLocationManyResponse;
  highSchoolLocationDelete?: Maybe<SuccessResponse>;
  highSchoolLocationDeleteByFilter?: Maybe<SuccessResponse>;
  highSchoolLocationDestroy?: Maybe<SuccessResponse>;
  highSchoolLocationDestroyByFilter?: Maybe<SuccessResponse>;
  highSchoolLocationRestore: HighSchoolLocation;
  highSchoolLocationUpdate: HighSchoolLocation;
  highSchoolLocationUpdateByFilter: HighSchoolLocationManyResponse;
  homeFeedItemUpdateByFilter: HomeFeedItemManyResponse;
  homeFeedPostCreate: HomeFeedPost;
  homeFeedPostCreateMany: HomeFeedPostManyResponse;
  homeFeedPostDelete?: Maybe<SuccessResponse>;
  homeFeedPostDeleteByFilter?: Maybe<SuccessResponse>;
  homeFeedPostDestroy?: Maybe<SuccessResponse>;
  homeFeedPostDestroyByFilter?: Maybe<SuccessResponse>;
  homeFeedPostRestore: HomeFeedPost;
  homeFeedPostUpdate: HomeFeedPost;
  homeFeedPostUpdateByFilter: HomeFeedPostManyResponse;
  /**
   * Inactivates school memberships.
   * Only users with the role of "School Admin" can invoke this mutation.
   */
  inactivateSchoolMembers: SuccessResponse;
  inboxCreate: Inbox;
  inboxCreateMany: InboxManyResponse;
  inboxDelete?: Maybe<SuccessResponse>;
  inboxDeleteByFilter?: Maybe<SuccessResponse>;
  inboxDestroy?: Maybe<SuccessResponse>;
  inboxDestroyByFilter?: Maybe<SuccessResponse>;
  inboxMessageCreate: InboxMessage;
  inboxMessageCreateMany: InboxMessageManyResponse;
  inboxMessageDelete?: Maybe<SuccessResponse>;
  inboxMessageDeleteByFilter?: Maybe<SuccessResponse>;
  inboxMessageDestroy?: Maybe<SuccessResponse>;
  inboxMessageDestroyByFilter?: Maybe<SuccessResponse>;
  inboxMessageMediaCreate: InboxMessageMedia;
  inboxMessageMediaCreateMany: InboxMessageMediaManyResponse;
  inboxMessageMediaDelete?: Maybe<SuccessResponse>;
  inboxMessageMediaDeleteByFilter?: Maybe<SuccessResponse>;
  inboxMessageMediaDestroy?: Maybe<SuccessResponse>;
  inboxMessageMediaDestroyByFilter?: Maybe<SuccessResponse>;
  inboxMessageMediaRestore: InboxMessageMedia;
  inboxMessageMediaUpdate: InboxMessageMedia;
  inboxMessageRestore: InboxMessage;
  inboxMessageUpdate: InboxMessage;
  inboxMessageUpdateByFilter: InboxMessageManyResponse;
  inboxRestore: Inbox;
  inboxUpdate: Inbox;
  inboxUpdateByFilter: InboxManyResponse;
  indexCreate: TableIndex;
  indexDelete?: Maybe<SuccessResponse>;
  indexUpdate: TableIndex;
  /** @deprecated No longer supported. Use `system.invoke` instead. */
  invoke?: Maybe<InvokeFunctionResponse>;
  jobEmployeeCreate: JobEmployee;
  jobEmployeeCreateMany: JobEmployeeManyResponse;
  jobEmployeeDelete?: Maybe<SuccessResponse>;
  jobEmployeeDeleteByFilter?: Maybe<SuccessResponse>;
  jobEmployeeDestroy?: Maybe<SuccessResponse>;
  jobEmployeeDestroyByFilter?: Maybe<SuccessResponse>;
  jobEmployeeRestore: JobEmployee;
  jobEmployeeUpdate: JobEmployee;
  jobEmployeeUpdateByFilter: JobEmployeeManyResponse;
  jobOfferCreate: JobOffer;
  jobOfferCreateMany: JobOfferManyResponse;
  jobOfferDelete?: Maybe<SuccessResponse>;
  jobOfferDeleteByFilter?: Maybe<SuccessResponse>;
  jobOfferDestroy?: Maybe<SuccessResponse>;
  jobOfferDestroyByFilter?: Maybe<SuccessResponse>;
  jobOfferRestore: JobOffer;
  jobOfferUpdate: JobOffer;
  jobOfferUpdateByFilter: JobOfferManyResponse;
  legacyFeedMonthCreate: LegacyFeedMonth;
  legacyFeedMonthCreateMany: LegacyFeedMonthManyResponse;
  legacyFeedMonthDelete?: Maybe<SuccessResponse>;
  legacyFeedMonthDeleteByFilter?: Maybe<SuccessResponse>;
  legacyFeedMonthDestroy?: Maybe<SuccessResponse>;
  legacyFeedMonthDestroyByFilter?: Maybe<SuccessResponse>;
  legacyFeedMonthRestore: LegacyFeedMonth;
  legacyFeedMonthUpdate: LegacyFeedMonth;
  legacyFeedMonthUpdateByFilter: LegacyFeedMonthManyResponse;
  legacyFeedPostCreate: LegacyFeedPost;
  legacyFeedPostCreateMany: LegacyFeedPostManyResponse;
  legacyFeedPostDelete?: Maybe<SuccessResponse>;
  legacyFeedPostDeleteByFilter?: Maybe<SuccessResponse>;
  legacyFeedPostDestroy?: Maybe<SuccessResponse>;
  legacyFeedPostDestroyByFilter?: Maybe<SuccessResponse>;
  legacyFeedPostRestore: LegacyFeedPost;
  legacyFeedPostUpdate: LegacyFeedPost;
  legacyFeedPostUpdateByFilter: LegacyFeedPostManyResponse;
  legacyFeedYearCreate: LegacyFeedYear;
  legacyFeedYearCreateMany: LegacyFeedYearManyResponse;
  legacyFeedYearDelete?: Maybe<SuccessResponse>;
  legacyFeedYearDeleteByFilter?: Maybe<SuccessResponse>;
  legacyFeedYearDestroy?: Maybe<SuccessResponse>;
  legacyFeedYearDestroyByFilter?: Maybe<SuccessResponse>;
  legacyFeedYearRestore: LegacyFeedYear;
  legacyFeedYearUpdate: LegacyFeedYear;
  legacyFeedYearUpdateByFilter: LegacyFeedYearManyResponse;
  managerAcceptRequest: SuccessResponse;
  messageSendRequest: Inbox;
  needHelpSendRequest: SuccessResponse;
  notificationTemplateCreate: NotificationTemplate;
  notificationTemplateCreateMany: NotificationTemplateManyResponse;
  notificationTemplateDelete?: Maybe<SuccessResponse>;
  notificationTemplateDeleteByFilter?: Maybe<SuccessResponse>;
  notificationTemplateDestroy?: Maybe<SuccessResponse>;
  notificationTemplateDestroyByFilter?: Maybe<SuccessResponse>;
  notificationTemplateRestore: NotificationTemplate;
  notificationTemplateUpdate: NotificationTemplate;
  notificationTemplateUpdateByFilter: NotificationTemplateManyResponse;
  organizationCreate: Organization;
  organizationCreateMany: OrganizationManyResponse;
  organizationDelete?: Maybe<SuccessResponse>;
  organizationDeleteByFilter?: Maybe<SuccessResponse>;
  organizationDestroy?: Maybe<SuccessResponse>;
  organizationDestroyByFilter?: Maybe<SuccessResponse>;
  organizationLikeCreate: OrganizationLike;
  organizationLikeCreateMany: OrganizationLikeManyResponse;
  organizationLikeDelete?: Maybe<SuccessResponse>;
  organizationLikeDeleteByFilter?: Maybe<SuccessResponse>;
  organizationLikeDestroy?: Maybe<SuccessResponse>;
  organizationLikeDestroyByFilter?: Maybe<SuccessResponse>;
  organizationLikeRestore: OrganizationLike;
  organizationLikeUpdate: OrganizationLike;
  organizationLocationCreate: OrganizationLocation;
  organizationLocationCreateMany: OrganizationLocationManyResponse;
  organizationLocationDelete?: Maybe<SuccessResponse>;
  organizationLocationDeleteByFilter?: Maybe<SuccessResponse>;
  organizationLocationDestroy?: Maybe<SuccessResponse>;
  organizationLocationDestroyByFilter?: Maybe<SuccessResponse>;
  organizationLocationRestore: OrganizationLocation;
  organizationLocationUpdate: OrganizationLocation;
  organizationLocationUpdateByFilter: OrganizationLocationManyResponse;
  organizationManagerPreferenceCreate: OrganizationManagerPreference;
  organizationManagerPreferenceCreateMany: OrganizationManagerPreferenceManyResponse;
  organizationManagerPreferenceDelete?: Maybe<SuccessResponse>;
  organizationManagerPreferenceDeleteByFilter?: Maybe<SuccessResponse>;
  organizationManagerPreferenceDestroy?: Maybe<SuccessResponse>;
  organizationManagerPreferenceDestroyByFilter?: Maybe<SuccessResponse>;
  organizationManagerPreferenceRestore: OrganizationManagerPreference;
  organizationManagerPreferenceUpdate: OrganizationManagerPreference;
  organizationManagerPreferenceUpdateByFilter: OrganizationManagerPreferenceManyResponse;
  organizationRestore: Organization;
  organizationUpdate: Organization;
  organizationUpdateByFilter: OrganizationManyResponse;
  paymentTransactionCreate: PaymentTransaction;
  paymentTransactionCreateMany: PaymentTransactionManyResponse;
  paymentTransactionDelete?: Maybe<SuccessResponse>;
  paymentTransactionDeleteByFilter?: Maybe<SuccessResponse>;
  paymentTransactionDestroy?: Maybe<SuccessResponse>;
  paymentTransactionDestroyByFilter?: Maybe<SuccessResponse>;
  paymentTransactionRestore: PaymentTransaction;
  paymentTransactionUpdate: PaymentTransaction;
  paymentTransactionUpdateByFilter: PaymentTransactionManyResponse;
  postMentionCreate: PostMention;
  postMentionCreateMany: PostMentionManyResponse;
  postMentionDelete?: Maybe<SuccessResponse>;
  postMentionDeleteByFilter?: Maybe<SuccessResponse>;
  postMentionDestroy?: Maybe<SuccessResponse>;
  postMentionDestroyByFilter?: Maybe<SuccessResponse>;
  postMentionRestore: PostMention;
  postMentionUpdate: PostMention;
  postMentionUpdateByFilter: PostMentionManyResponse;
  /** @deprecated No longer supported. Use `system.prepareDeploy` instead. */
  prepareDeploy: DeployDataResponse;
  /**
   * Promote a school member to school administrator.
   * Only users with the role of "School Admin" can invoke this mutation.
   */
  promoteSchoolMember: SuccessResponse;
  redeemDiscount: SuccessWithMessageResponse;
  /**
   * Rejects a user's request to join a school.
   * Only users with the role of "School Admin" can invoke this mutation.
   */
  rejectSchoolMembers: SuccessResponse;
  remoteVariableCreate: RemoteVariable;
  remoteVariableCreateMany: RemoteVariableManyResponse;
  remoteVariableDelete?: Maybe<SuccessResponse>;
  remoteVariableDeleteByFilter?: Maybe<SuccessResponse>;
  remoteVariableDestroy?: Maybe<SuccessResponse>;
  remoteVariableDestroyByFilter?: Maybe<SuccessResponse>;
  remoteVariableRestore: RemoteVariable;
  remoteVariableUpdate: RemoteVariable;
  remoteVariableUpdateByFilter: RemoteVariableManyResponse;
  renewJobOffer: SuccessResponse;
  /** Create a business user account and business profile. */
  requestBusinessAccess: RegisterBusinessUserResponse;
  /** Request access to a community. */
  requestCommunityAccess: SuccessResponse;
  /**
   * Resend school approval to user that are already approved.
   * Only users with the role of "School Admin" can invoke this mutation.
   */
  resendSchoolApprovals: SuccessResponse;
  /**
   * Reset user credentials and send an email to setup a new password.
   * See https://github.com/8base-services/jebel/issues/1448
   */
  resetUserCredentials: SuccessResponse;
  roleAddonCreate: RoleAddon;
  roleAddonCreateMany: RoleAddonManyResponse;
  roleAddonDelete?: Maybe<SuccessResponse>;
  roleAddonDeleteByFilter?: Maybe<SuccessResponse>;
  roleAddonDestroy?: Maybe<SuccessResponse>;
  roleAddonDestroyByFilter?: Maybe<SuccessResponse>;
  roleAddonRestore: RoleAddon;
  roleAddonUpdate: RoleAddon;
  roleAddonUpdateByFilter: RoleAddonManyResponse;
  roleCreate: Role;
  roleCreateMany: RoleManyResponse;
  roleDelete?: Maybe<SuccessResponse>;
  roleDeleteByFilter?: Maybe<SuccessResponse>;
  roleDestroy?: Maybe<SuccessResponse>;
  roleDestroyByFilter?: Maybe<SuccessResponse>;
  roleRestore: Role;
  roleUpdate: Role;
  roleUpdateByFilter: RoleManyResponse;
  schoolConfigurationColorSchemeCreate: SchoolConfigurationColorScheme;
  schoolConfigurationColorSchemeCreateMany: SchoolConfigurationColorSchemeManyResponse;
  schoolConfigurationColorSchemeDelete?: Maybe<SuccessResponse>;
  schoolConfigurationColorSchemeDeleteByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationColorSchemeDestroy?: Maybe<SuccessResponse>;
  schoolConfigurationColorSchemeDestroyByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationColorSchemeRestore: SchoolConfigurationColorScheme;
  schoolConfigurationColorSchemeUpdate: SchoolConfigurationColorScheme;
  schoolConfigurationColorSchemeUpdateByFilter: SchoolConfigurationColorSchemeManyResponse;
  schoolConfigurationCreate: SchoolConfiguration;
  schoolConfigurationCreateMany: SchoolConfigurationManyResponse;
  schoolConfigurationDelete?: Maybe<SuccessResponse>;
  schoolConfigurationDeleteByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationDestroy?: Maybe<SuccessResponse>;
  schoolConfigurationDestroyByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationExternalLinkCreate: SchoolConfigurationExternalLink;
  schoolConfigurationExternalLinkCreateMany: SchoolConfigurationExternalLinkManyResponse;
  schoolConfigurationExternalLinkDelete?: Maybe<SuccessResponse>;
  schoolConfigurationExternalLinkDeleteByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationExternalLinkDestroy?: Maybe<SuccessResponse>;
  schoolConfigurationExternalLinkDestroyByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationExternalLinkRestore: SchoolConfigurationExternalLink;
  schoolConfigurationExternalLinkUpdate: SchoolConfigurationExternalLink;
  schoolConfigurationExternalLinkUpdateByFilter: SchoolConfigurationExternalLinkManyResponse;
  schoolConfigurationImageCreate: SchoolConfigurationImage;
  schoolConfigurationImageCreateMany: SchoolConfigurationImageManyResponse;
  schoolConfigurationImageDelete?: Maybe<SuccessResponse>;
  schoolConfigurationImageDeleteByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationImageDestroy?: Maybe<SuccessResponse>;
  schoolConfigurationImageDestroyByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationImageRestore: SchoolConfigurationImage;
  schoolConfigurationImageUpdate: SchoolConfigurationImage;
  schoolConfigurationImageUpdateByFilter: SchoolConfigurationImageManyResponse;
  schoolConfigurationLayoutCreate: SchoolConfigurationLayout;
  schoolConfigurationLayoutCreateMany: SchoolConfigurationLayoutManyResponse;
  schoolConfigurationLayoutDelete?: Maybe<SuccessResponse>;
  schoolConfigurationLayoutDeleteByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationLayoutDestroy?: Maybe<SuccessResponse>;
  schoolConfigurationLayoutDestroyByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationLayoutRestore: SchoolConfigurationLayout;
  schoolConfigurationLayoutUpdate: SchoolConfigurationLayout;
  schoolConfigurationLayoutUpdateByFilter: SchoolConfigurationLayoutManyResponse;
  schoolConfigurationRestore: SchoolConfiguration;
  schoolConfigurationTopBarCreate: SchoolConfigurationTopBar;
  schoolConfigurationTopBarCreateMany: SchoolConfigurationTopBarManyResponse;
  schoolConfigurationTopBarDelete?: Maybe<SuccessResponse>;
  schoolConfigurationTopBarDeleteByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationTopBarDestroy?: Maybe<SuccessResponse>;
  schoolConfigurationTopBarDestroyByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationTopBarRestore: SchoolConfigurationTopBar;
  schoolConfigurationTopBarUpdate: SchoolConfigurationTopBar;
  schoolConfigurationTopBarUpdateByFilter: SchoolConfigurationTopBarManyResponse;
  schoolConfigurationUpdate: SchoolConfiguration;
  schoolConfigurationUpdateByFilter: SchoolConfigurationManyResponse;
  schoolConfigurationWordsBlacklistCreate: SchoolConfigurationWordsBlacklist;
  schoolConfigurationWordsBlacklistCreateMany: SchoolConfigurationWordsBlacklistManyResponse;
  schoolConfigurationWordsBlacklistDelete?: Maybe<SuccessResponse>;
  schoolConfigurationWordsBlacklistDeleteByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationWordsBlacklistDestroy?: Maybe<SuccessResponse>;
  schoolConfigurationWordsBlacklistDestroyByFilter?: Maybe<SuccessResponse>;
  schoolConfigurationWordsBlacklistRestore: SchoolConfigurationWordsBlacklist;
  schoolConfigurationWordsBlacklistUpdate: SchoolConfigurationWordsBlacklist;
  schoolConfigurationWordsBlacklistUpdateByFilter: SchoolConfigurationWordsBlacklistManyResponse;
  schoolNewsPostCreate: SchoolNewsPost;
  schoolNewsPostCreateMany: SchoolNewsPostManyResponse;
  schoolNewsPostDelete?: Maybe<SuccessResponse>;
  schoolNewsPostDeleteByFilter?: Maybe<SuccessResponse>;
  schoolNewsPostDestroy?: Maybe<SuccessResponse>;
  schoolNewsPostDestroyByFilter?: Maybe<SuccessResponse>;
  schoolNewsPostRestore: SchoolNewsPost;
  schoolNewsPostUpdate: SchoolNewsPost;
  schoolNewsPostUpdateByFilter: SchoolNewsPostManyResponse;
  schoolSupporterClickCreate: SchoolSupporterClick;
  schoolSupporterClickCreateMany: SchoolSupporterClickManyResponse;
  schoolSupporterClickDelete?: Maybe<SuccessResponse>;
  schoolSupporterClickDeleteByFilter?: Maybe<SuccessResponse>;
  schoolSupporterClickDestroy?: Maybe<SuccessResponse>;
  schoolSupporterClickDestroyByFilter?: Maybe<SuccessResponse>;
  schoolSupporterClickRestore: SchoolSupporterClick;
  schoolSupporterClickUpdate: SchoolSupporterClick;
  schoolSupporterCreate: SchoolSupporter;
  schoolSupporterCreateMany: SchoolSupporterManyResponse;
  schoolSupporterDelete?: Maybe<SuccessResponse>;
  schoolSupporterDeleteByFilter?: Maybe<SuccessResponse>;
  schoolSupporterDestroy?: Maybe<SuccessResponse>;
  schoolSupporterDestroyByFilter?: Maybe<SuccessResponse>;
  schoolSupporterRestore: SchoolSupporter;
  schoolSupporterSearchCreate: SchoolSupporterSearch;
  schoolSupporterSearchCreateMany: SchoolSupporterSearchManyResponse;
  schoolSupporterSearchDelete?: Maybe<SuccessResponse>;
  schoolSupporterSearchDeleteByFilter?: Maybe<SuccessResponse>;
  schoolSupporterSearchDestroy?: Maybe<SuccessResponse>;
  schoolSupporterSearchDestroyByFilter?: Maybe<SuccessResponse>;
  schoolSupporterSearchRestore: SchoolSupporterSearch;
  schoolSupporterSearchUpdate: SchoolSupporterSearch;
  schoolSupporterUpdate: SchoolSupporter;
  schoolSupporterUpdateByFilter: SchoolSupporterManyResponse;
  /** Send invites to businesses to join the community and create a business. */
  sendBusinessAdministratorInvites: SuccessResponse;
  /**
   * Send invitations to users to be business managers.
   * Non-registered users will receive an email with a link to register.
   */
  sendBusinessManagerInvites: SuccessResponse;
  sendInboxMessageNotificationRequest: SuccessResponse;
  sendInvitationTo8base?: Maybe<SuccessResponse>;
  sendPostReactionNotificationRequest: SuccessResponse;
  /** Send invites to users to join the community as school administrators. */
  sendSchoolAdministratorInvites: SuccessResponse;
  /** Allow to request access without a community invitation. */
  sendSchoolMemberInvites: SuccessResponse;
  settingsUpdate: Setting;
  stripeCreatePaymentIntent?: Maybe<StripeCreatePaymentIntentResponse>;
  stripeDetachPaymentMethod?: Maybe<StripeDetachPaymentMethodResponse>;
  stripeInformationCreate: StripeInformation;
  stripeInformationCreateMany: StripeInformationManyResponse;
  stripeInformationDelete?: Maybe<SuccessResponse>;
  stripeInformationDeleteByFilter?: Maybe<SuccessResponse>;
  stripeInformationDestroy?: Maybe<SuccessResponse>;
  stripeInformationDestroyByFilter?: Maybe<SuccessResponse>;
  stripeInformationRestore: StripeInformation;
  stripeInformationUpdate: StripeInformation;
  stripeInformationUpdateByFilter: StripeInformationManyResponse;
  stripeUpdateCustomerDefaultPaymentMethod?: Maybe<StripeUpdateCustomerDefaultPaymentMethodResponse>;
  system?: Maybe<SystemMutation>;
  /** @deprecated No longer supported. Use `system.tableCreate` instead. */
  tableCreate: Table;
  /** @deprecated No longer supported. Use `system.tableDelete` instead. */
  tableDelete: SuccessResponse;
  /** @deprecated No longer supported. Use `system.tableUpdate` instead. */
  tableUpdate: Table;
  teamInvitationDelete?: Maybe<SuccessResponse>;
  teamInvitationDeleteByFilter?: Maybe<SuccessResponse>;
  teamInvitationDestroy?: Maybe<SuccessResponse>;
  teamInvitationDestroyByFilter?: Maybe<SuccessResponse>;
  teamInvitationRestore: TeamInvitation;
  teamMemberDelete?: Maybe<SuccessResponse>;
  teamMemberDeleteByFilter?: Maybe<SuccessResponse>;
  teamMemberDestroy?: Maybe<SuccessResponse>;
  teamMemberDestroyByFilter?: Maybe<SuccessResponse>;
  teamMemberRestore: TeamMember;
  teamMemberUpdate: TeamMember;
  teamMemberUpdateByFilter: TeamMemberManyResponse;
  trendingSearchCreate: TrendingSearch;
  trendingSearchCreateMany: TrendingSearchManyResponse;
  trendingSearchDelete?: Maybe<SuccessResponse>;
  trendingSearchDeleteByFilter?: Maybe<SuccessResponse>;
  trendingSearchDestroy?: Maybe<SuccessResponse>;
  trendingSearchDestroyByFilter?: Maybe<SuccessResponse>;
  trendingSearchRestore: TrendingSearch;
  trendingSearchUpdate: TrendingSearch;
  trendingSearchUpdateByFilter: TrendingSearchManyResponse;
  updateHomeFeedPostRequest: UpdateHomeFeedPostResponse;
  updatePost: UpdatePostResponse;
  userAuthorize: UserAuthorizeResponse;
  userChangePassword?: Maybe<SuccessResponse>;
  userCompleteRegistration: UserCompleteRegistrationResponse;
  userConfigurationCreate: UserConfiguration;
  userConfigurationCreateMany: UserConfigurationManyResponse;
  userConfigurationDelete?: Maybe<SuccessResponse>;
  userConfigurationDeleteByFilter?: Maybe<SuccessResponse>;
  userConfigurationDestroy?: Maybe<SuccessResponse>;
  userConfigurationDestroyByFilter?: Maybe<SuccessResponse>;
  userConfigurationRestore: UserConfiguration;
  userConfigurationUpdate: UserConfiguration;
  userConfigurationUpdateByFilter: UserConfigurationManyResponse;
  userCreate: User;
  userCreateMany: UserManyResponse;
  userDelete?: Maybe<SuccessResponse>;
  userDeleteByFilter?: Maybe<SuccessResponse>;
  userDestroy?: Maybe<SuccessResponse>;
  userDestroyByFilter?: Maybe<SuccessResponse>;
  userEmailChangeRequest: UserEmailChangeRequestResponse;
  userForgotPasswordChange: SuccessResponse;
  userForgotPasswordEmailSend: SuccessResponse;
  userInviteMembersToGroup: SuccessResponse;
  userLogin?: Maybe<LoginResponse>;
  userNotificationCreate: UserNotification;
  userNotificationCreateMany: UserNotificationManyResponse;
  userNotificationDelete?: Maybe<SuccessResponse>;
  userNotificationDeleteByFilter?: Maybe<SuccessResponse>;
  userNotificationDestroy?: Maybe<SuccessResponse>;
  userNotificationDestroyByFilter?: Maybe<SuccessResponse>;
  userNotificationRestore: UserNotification;
  userNotificationUpdate: UserNotification;
  userNotificationUpdateByFilter: UserNotificationManyResponse;
  userNotificationsByUserCreate: UserNotificationsByUser;
  userNotificationsByUserCreateMany: UserNotificationsByUserManyResponse;
  userNotificationsByUserDelete?: Maybe<SuccessResponse>;
  userNotificationsByUserDeleteByFilter?: Maybe<SuccessResponse>;
  userNotificationsByUserDestroy?: Maybe<SuccessResponse>;
  userNotificationsByUserDestroyByFilter?: Maybe<SuccessResponse>;
  userNotificationsByUserRestore: UserNotificationsByUser;
  userNotificationsByUserUpdate: UserNotificationsByUser;
  userNotificationsByUserUpdateByFilter: UserNotificationsByUserManyResponse;
  userPasswordChangeRequest: SuccessResponse;
  userPasswordForgot?: Maybe<SuccessResponse>;
  userPasswordForgotConfirm?: Maybe<SuccessResponse>;
  userPreferenceCreate: UserPreference;
  userPreferenceCreateMany: UserPreferenceManyResponse;
  userPreferenceDelete?: Maybe<SuccessResponse>;
  userPreferenceDeleteByFilter?: Maybe<SuccessResponse>;
  userPreferenceDestroy?: Maybe<SuccessResponse>;
  userPreferenceDestroyByFilter?: Maybe<SuccessResponse>;
  userPreferenceRestore: UserPreference;
  userPreferenceUpdate: UserPreference;
  userPreferenceUpdateByFilter: UserPreferenceManyResponse;
  userRefreshToken?: Maybe<Auth>;
  userRestore: User;
  userSchoolMembershipCreate: UserSchoolMembership;
  userSchoolMembershipCreateMany: UserSchoolMembershipManyResponse;
  userSchoolMembershipDelete?: Maybe<SuccessResponse>;
  userSchoolMembershipDeleteByFilter?: Maybe<SuccessResponse>;
  userSchoolMembershipDestroy?: Maybe<SuccessResponse>;
  userSchoolMembershipDestroyByFilter?: Maybe<SuccessResponse>;
  userSchoolMembershipRestore: UserSchoolMembership;
  userSchoolMembershipUpdate: UserSchoolMembership;
  userSchoolMembershipUpdateByFilter: UserSchoolMembershipManyResponse;
  /** @deprecated No longer supported. Use `userSignUpWithToken` instead. */
  userSignUp: User;
  /** @deprecated No longer supported. Use `userVerificationEmailResend` instead. */
  userSignUpResend?: Maybe<SuccessResponse>;
  userSignUpWithPassword: User;
  userSignUpWithToken: User;
  userSocialCompleteRegistration: SuccessResponse;
  userStatusUpdateByFilter: UserStatusManyResponse;
  userSupportersOnlyUpdateByFilter: UserSupportersOnlyManyResponse;
  userUpdate: User;
  userUpdateByFilter: UserManyResponse;
  userUpdateRoles: SuccessResponse;
  userVerificationEmailResend?: Maybe<SuccessResponse>;
  usersCountCreate: UsersCount;
  usersCountCreateMany: UsersCountManyResponse;
  usersCountDelete?: Maybe<SuccessResponse>;
  usersCountDeleteByFilter?: Maybe<SuccessResponse>;
  usersCountDestroy?: Maybe<SuccessResponse>;
  usersCountDestroyByFilter?: Maybe<SuccessResponse>;
  usersCountRestore: UsersCount;
  usersCountUpdate: UsersCount;
  usersCountUpdateByFilter: UsersCountManyResponse;
  /** @deprecated No longer supported. Use `system.viewCreate` instead. */
  viewCreate: Table;
  viewUpdate: Table;
  /** @deprecated No longer supported. Use `system.workspaceCreate` instead. */
  workspaceCreate?: Maybe<WorkspaceCreateResponse>;
  /** @deprecated No longer supported. Use `system.workspaceCreateAsync` instead. */
  workspaceCreateAsync?: Maybe<WorkspaceCreateResponse>;
  workspaceDelete?: Maybe<SuccessResponse>;
  /** @deprecated No longer supported. Use `system.workspaceLeave` instead. */
  workspaceLeave?: Maybe<SuccessResponse>;
  /** @deprecated No longer supported. Use `system.workspaceUpdate` instead. */
  workspaceUpdate?: Maybe<WorkspaceUpdateResponse>;
};

export type MutationImportCsvArgs = {
  data: ImportCsv;
};

export type MutationIsSocialUserArgs = {
  data: IsSocialUser;
};

export type MutationAcceptSchoolMembersArgs = {
  filter: UserFilter;
  school: SchoolConfigurationKeyFilter;
};

export type MutationActivateSchoolMembersArgs = {
  filter: UserFilter;
  school: SchoolConfigurationKeyFilter;
};

export type MutationAdClickCreateArgs = {
  data: AdClickCreateInput;
};

export type MutationAdClickCreateManyArgs = {
  data: Array<InputMaybe<AdClickCreateManyInput>>;
};

export type MutationAdClickDeleteArgs = {
  data?: InputMaybe<AdClickDeleteInput>;
  filter?: InputMaybe<AdClickKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdClickDeleteByFilterArgs = {
  filter: AdClickFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdClickDestroyArgs = {
  filter?: InputMaybe<AdClickKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdClickDestroyByFilterArgs = {
  filter: AdClickFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdClickRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAdClickUpdateArgs = {
  data: AdClickUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AdClickKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdClickUpdateByFilterArgs = {
  data: AdClickUpdateByFilterInput;
  filter?: InputMaybe<AdClickFilter>;
};

export type MutationAdViewCreateArgs = {
  data: AdViewCreateInput;
};

export type MutationAdViewCreateManyArgs = {
  data: Array<InputMaybe<AdViewCreateManyInput>>;
};

export type MutationAdViewDeleteArgs = {
  data?: InputMaybe<AdViewDeleteInput>;
  filter?: InputMaybe<AdViewKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdViewDeleteByFilterArgs = {
  filter: AdViewFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdViewDestroyArgs = {
  filter?: InputMaybe<AdViewKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdViewDestroyByFilterArgs = {
  filter: AdViewFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdViewRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAdViewUpdateArgs = {
  data: AdViewUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AdViewKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdViewUpdateByFilterArgs = {
  data: AdViewUpdateByFilterInput;
  filter?: InputMaybe<AdViewFilter>;
};

export type MutationAddressCreateArgs = {
  data: AddressCreateInput;
};

export type MutationAddressCreateManyArgs = {
  data: Array<InputMaybe<AddressCreateManyInput>>;
};

export type MutationAddressDeleteArgs = {
  data?: InputMaybe<AddressDeleteInput>;
  filter?: InputMaybe<AddressKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAddressDeleteByFilterArgs = {
  filter: AddressFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAddressDestroyArgs = {
  filter?: InputMaybe<AddressKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAddressDestroyByFilterArgs = {
  filter: AddressFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAddressRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAddressUpdateArgs = {
  data: AddressUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AddressKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAddressUpdateByFilterArgs = {
  data: AddressUpdateByFilterInput;
  filter?: InputMaybe<AddressFilter>;
};

export type MutationAdvertisingCampaignAdCreateArgs = {
  data: AdvertisingCampaignAdCreateInput;
};

export type MutationAdvertisingCampaignAdCreateManyArgs = {
  data: Array<InputMaybe<AdvertisingCampaignAdCreateManyInput>>;
};

export type MutationAdvertisingCampaignAdDeleteArgs = {
  data?: InputMaybe<AdvertisingCampaignAdDeleteInput>;
  filter?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingCampaignAdDeleteByFilterArgs = {
  filter: AdvertisingCampaignAdFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingCampaignAdDestroyArgs = {
  filter?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingCampaignAdDestroyByFilterArgs = {
  filter: AdvertisingCampaignAdFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingCampaignAdRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAdvertisingCampaignAdUpdateArgs = {
  data: AdvertisingCampaignAdUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingCampaignAdUpdateByFilterArgs = {
  data: AdvertisingCampaignAdUpdateByFilterInput;
  filter?: InputMaybe<AdvertisingCampaignAdFilter>;
};

export type MutationAdvertisingCreateArgs = {
  data: AdvertisingCreateInput;
};

export type MutationAdvertisingCreateManyArgs = {
  data: Array<InputMaybe<AdvertisingCreateManyInput>>;
};

export type MutationAdvertisingDeleteArgs = {
  data?: InputMaybe<AdvertisingDeleteInput>;
  filter?: InputMaybe<AdvertisingKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingDeleteByFilterArgs = {
  filter: AdvertisingFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingDestroyArgs = {
  filter?: InputMaybe<AdvertisingKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingDestroyByFilterArgs = {
  filter: AdvertisingFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAdvertisingSampaignCreateArgs = {
  data: AdvertisingSampaignCreateInput;
};

export type MutationAdvertisingSampaignCreateManyArgs = {
  data: Array<InputMaybe<AdvertisingSampaignCreateManyInput>>;
};

export type MutationAdvertisingSampaignDeleteArgs = {
  data?: InputMaybe<AdvertisingSampaignDeleteInput>;
  filter?: InputMaybe<AdvertisingSampaignKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingSampaignDeleteByFilterArgs = {
  filter: AdvertisingSampaignFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingSampaignDestroyArgs = {
  filter?: InputMaybe<AdvertisingSampaignKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingSampaignDestroyByFilterArgs = {
  filter: AdvertisingSampaignFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingSampaignRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAdvertisingSampaignUpdateArgs = {
  data: AdvertisingSampaignUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AdvertisingSampaignKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingSampaignUpdateByFilterArgs = {
  data: AdvertisingSampaignUpdateByFilterInput;
  filter?: InputMaybe<AdvertisingSampaignFilter>;
};

export type MutationAdvertisingSupporterCreateArgs = {
  data: AdvertisingSupporterCreateInput;
};

export type MutationAdvertisingSupporterCreateManyArgs = {
  data: Array<InputMaybe<AdvertisingSupporterCreateManyInput>>;
};

export type MutationAdvertisingSupporterDeleteArgs = {
  data?: InputMaybe<AdvertisingSupporterDeleteInput>;
  filter?: InputMaybe<AdvertisingSupporterKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingSupporterDeleteByFilterArgs = {
  filter: AdvertisingSupporterFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingSupporterDestroyArgs = {
  filter?: InputMaybe<AdvertisingSupporterKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingSupporterDestroyByFilterArgs = {
  filter: AdvertisingSupporterFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingSupporterRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAdvertisingSupporterUpdateArgs = {
  data: AdvertisingSupporterUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AdvertisingSupporterKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdvertisingSupporterUpdateByFilterArgs = {
  data: AdvertisingSupporterUpdateByFilterInput;
  filter?: InputMaybe<AdvertisingSupporterFilter>;
};

export type MutationAdvertisingUpdateArgs = {
  data: AdvertisingUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AdvertisingKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationApiTokenCreateArgs = {
  data: ApiTokenCreateInput;
};

export type MutationApiTokenDeleteArgs = {
  data?: InputMaybe<ApiTokenDeleteInput>;
  filter?: InputMaybe<ApiTokenKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationApiTokenDeleteByFilterArgs = {
  filter: ApiTokenFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationApiTokenDestroyArgs = {
  filter?: InputMaybe<ApiTokenKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationApiTokenDestroyByFilterArgs = {
  filter: ApiTokenFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationApiTokenRestoreArgs = {
  id: Scalars['String'];
};

export type MutationApiTokenUpdateArgs = {
  data: ApiTokenUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ApiTokenKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationApiTokenUpdateByFilterArgs = {
  data: ApiTokenUpdateByFilterInput;
  filter?: InputMaybe<ApiTokenFilter>;
};

export type MutationApplicationDeleteArgs = {
  data: ApplicationDeleteMutationInput;
};

export type MutationApplicationInstallArgs = {
  data: ApplicationInstallInput;
};

export type MutationApplicationUpdateArgs = {
  data: ApplicationUpdateInput;
};

export type MutationAudienceCreateArgs = {
  data: AudienceCreateInput;
};

export type MutationAudienceCreateManyArgs = {
  data: Array<InputMaybe<AudienceCreateManyInput>>;
};

export type MutationAudienceDeleteArgs = {
  data?: InputMaybe<AudienceDeleteInput>;
  filter?: InputMaybe<AudienceKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAudienceDeleteByFilterArgs = {
  filter: AudienceFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAudienceDestroyArgs = {
  filter?: InputMaybe<AudienceKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAudienceDestroyByFilterArgs = {
  filter: AudienceFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAudienceRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAudienceUpdateArgs = {
  data: AudienceUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AudienceKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAudienceUpdateByFilterArgs = {
  data: AudienceUpdateByFilterInput;
  filter?: InputMaybe<AudienceFilter>;
};

export type MutationAuthenticationProfileCreateArgs = {
  data: AuthenticationProfileCreateInput;
};

export type MutationAuthenticationProfileCreateManyArgs = {
  data: Array<InputMaybe<AuthenticationProfileCreateManyInput>>;
};

export type MutationAuthenticationProfileDeleteArgs = {
  data?: InputMaybe<AuthenticationProfileDeleteInput>;
  filter?: InputMaybe<AuthenticationProfileKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileDeleteByFilterArgs = {
  filter: AuthenticationProfileFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileDestroyArgs = {
  filter?: InputMaybe<AuthenticationProfileKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileDestroyByFilterArgs = {
  filter: AuthenticationProfileFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAuthenticationProfileUpdateArgs = {
  data: AuthenticationProfileUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AuthenticationProfileKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileUpdateByFilterArgs = {
  data: AuthenticationProfileUpdateByFilterInput;
  filter?: InputMaybe<AuthenticationProfileFilter>;
};

export type MutationAuthenticationSettingsUpdateArgs = {
  data: AuthenticationSettingUpdateInput;
};

export type MutationBillingPlanUpdateArgs = {
  data: BillingPlanUpdateMutationInput;
};

export type MutationBillingRegisteredAdCreateArgs = {
  data: BillingRegisteredAdCreateInput;
};

export type MutationBillingRegisteredAdCreateManyArgs = {
  data: Array<InputMaybe<BillingRegisteredAdCreateManyInput>>;
};

export type MutationBillingRegisteredAdDeleteArgs = {
  data?: InputMaybe<BillingRegisteredAdDeleteInput>;
  filter?: InputMaybe<BillingRegisteredAdKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredAdDeleteByFilterArgs = {
  filter: BillingRegisteredAdFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredAdDestroyArgs = {
  filter?: InputMaybe<BillingRegisteredAdKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredAdDestroyByFilterArgs = {
  filter: BillingRegisteredAdFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredAdRestoreArgs = {
  id: Scalars['String'];
};

export type MutationBillingRegisteredAdUpdateArgs = {
  data: BillingRegisteredAdUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BillingRegisteredAdKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredAdUpdateByFilterArgs = {
  data: BillingRegisteredAdUpdateByFilterInput;
  filter?: InputMaybe<BillingRegisteredAdFilter>;
};

export type MutationBillingRegisteredSchoolSupporterCreateArgs = {
  data: BillingRegisteredSchoolSupporterCreateInput;
};

export type MutationBillingRegisteredSchoolSupporterCreateManyArgs = {
  data: Array<InputMaybe<BillingRegisteredSchoolSupporterCreateManyInput>>;
};

export type MutationBillingRegisteredSchoolSupporterDeleteArgs = {
  data?: InputMaybe<BillingRegisteredSchoolSupporterDeleteInput>;
  filter?: InputMaybe<BillingRegisteredSchoolSupporterKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredSchoolSupporterDeleteByFilterArgs = {
  filter: BillingRegisteredSchoolSupporterFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredSchoolSupporterDestroyArgs = {
  filter?: InputMaybe<BillingRegisteredSchoolSupporterKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredSchoolSupporterDestroyByFilterArgs = {
  filter: BillingRegisteredSchoolSupporterFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredSchoolSupporterRestoreArgs = {
  id: Scalars['String'];
};

export type MutationBillingRegisteredSchoolSupporterUpdateArgs = {
  data: BillingRegisteredSchoolSupporterUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BillingRegisteredSchoolSupporterKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredSchoolSupporterUpdateByFilterArgs = {
  data: BillingRegisteredSchoolSupporterUpdateByFilterInput;
  filter?: InputMaybe<BillingRegisteredSchoolSupporterFilter>;
};

export type MutationBillingRegisteredSpecialOfferCreateArgs = {
  data: BillingRegisteredSpecialOfferCreateInput;
};

export type MutationBillingRegisteredSpecialOfferCreateManyArgs = {
  data: Array<InputMaybe<BillingRegisteredSpecialOfferCreateManyInput>>;
};

export type MutationBillingRegisteredSpecialOfferDeleteArgs = {
  data?: InputMaybe<BillingRegisteredSpecialOfferDeleteInput>;
  filter?: InputMaybe<BillingRegisteredSpecialOfferKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredSpecialOfferDeleteByFilterArgs = {
  filter: BillingRegisteredSpecialOfferFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredSpecialOfferDestroyArgs = {
  filter?: InputMaybe<BillingRegisteredSpecialOfferKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredSpecialOfferDestroyByFilterArgs = {
  filter: BillingRegisteredSpecialOfferFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredSpecialOfferRestoreArgs = {
  id: Scalars['String'];
};

export type MutationBillingRegisteredSpecialOfferUpdateArgs = {
  data: BillingRegisteredSpecialOfferUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BillingRegisteredSpecialOfferKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingRegisteredSpecialOfferUpdateByFilterArgs = {
  data: BillingRegisteredSpecialOfferUpdateByFilterInput;
  filter?: InputMaybe<BillingRegisteredSpecialOfferFilter>;
};

export type MutationBillingSchoolSupporterCreateArgs = {
  data: BillingSchoolSupporterCreateInput;
};

export type MutationBillingSchoolSupporterCreateManyArgs = {
  data: Array<InputMaybe<BillingSchoolSupporterCreateManyInput>>;
};

export type MutationBillingSchoolSupporterDeleteArgs = {
  data?: InputMaybe<BillingSchoolSupporterDeleteInput>;
  filter?: InputMaybe<BillingSchoolSupporterKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingSchoolSupporterDeleteByFilterArgs = {
  filter: BillingSchoolSupporterFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingSchoolSupporterDestroyArgs = {
  filter?: InputMaybe<BillingSchoolSupporterKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingSchoolSupporterDestroyByFilterArgs = {
  filter: BillingSchoolSupporterFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingSchoolSupporterRestoreArgs = {
  id: Scalars['String'];
};

export type MutationBillingSchoolSupporterUpdateArgs = {
  data: BillingSchoolSupporterUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BillingSchoolSupporterKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingSpecialOfferCreateArgs = {
  data: BillingSpecialOfferCreateInput;
};

export type MutationBillingSpecialOfferCreateManyArgs = {
  data: Array<InputMaybe<BillingSpecialOfferCreateManyInput>>;
};

export type MutationBillingSpecialOfferDeleteArgs = {
  data?: InputMaybe<BillingSpecialOfferDeleteInput>;
  filter?: InputMaybe<BillingSpecialOfferKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingSpecialOfferDeleteByFilterArgs = {
  filter: BillingSpecialOfferFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingSpecialOfferDestroyArgs = {
  filter?: InputMaybe<BillingSpecialOfferKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingSpecialOfferDestroyByFilterArgs = {
  filter: BillingSpecialOfferFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingSpecialOfferRestoreArgs = {
  id: Scalars['String'];
};

export type MutationBillingSpecialOfferUpdateArgs = {
  data: BillingSpecialOfferUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BillingSpecialOfferKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingsAdCreateArgs = {
  data: BillingsAdCreateInput;
};

export type MutationBillingsAdCreateManyArgs = {
  data: Array<InputMaybe<BillingsAdCreateManyInput>>;
};

export type MutationBillingsAdDeleteArgs = {
  data?: InputMaybe<BillingsAdDeleteInput>;
  filter?: InputMaybe<BillingsAdKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingsAdDeleteByFilterArgs = {
  filter: BillingsAdFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingsAdDestroyArgs = {
  filter?: InputMaybe<BillingsAdKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingsAdDestroyByFilterArgs = {
  filter: BillingsAdFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBillingsAdRestoreArgs = {
  id: Scalars['String'];
};

export type MutationBillingsAdUpdateArgs = {
  data: BillingsAdUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BillingsAdKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationBusinessReportUpdateByFilterArgs = {
  data: BusinessReportUpdateByFilterInput;
  filter?: InputMaybe<BusinessReportFilter>;
};

export type MutationCiCdMigrationCreateArgs = {
  data: CiCdMigrationCreateInput;
};

export type MutationCiCdMigrationCreateManyArgs = {
  data: Array<InputMaybe<CiCdMigrationCreateManyInput>>;
};

export type MutationCiCdMigrationDeleteArgs = {
  data?: InputMaybe<CiCdMigrationDeleteInput>;
  filter?: InputMaybe<CiCdMigrationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationDeleteByFilterArgs = {
  filter: CiCdMigrationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationDestroyArgs = {
  filter?: InputMaybe<CiCdMigrationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationDestroyByFilterArgs = {
  filter: CiCdMigrationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCiCdMigrationUpdateArgs = {
  data: CiCdMigrationUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CiCdMigrationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationUpdateByFilterArgs = {
  data: CiCdMigrationUpdateByFilterInput;
  filter?: InputMaybe<CiCdMigrationFilter>;
};

export type MutationClaimOrganizationArgs = {
  data: OrganizationCreateInput;
};

export type MutationCollectiveIntelligenceThreadCreateArgs = {
  data: CollectiveIntelligenceThreadCreateInput;
};

export type MutationCollectiveIntelligenceThreadCreateManyArgs = {
  data: Array<InputMaybe<CollectiveIntelligenceThreadCreateManyInput>>;
};

export type MutationCollectiveIntelligenceThreadDeleteArgs = {
  data?: InputMaybe<CollectiveIntelligenceThreadDeleteInput>;
  filter?: InputMaybe<CollectiveIntelligenceThreadKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCollectiveIntelligenceThreadDeleteByFilterArgs = {
  filter: CollectiveIntelligenceThreadFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCollectiveIntelligenceThreadDestroyArgs = {
  filter?: InputMaybe<CollectiveIntelligenceThreadKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCollectiveIntelligenceThreadDestroyByFilterArgs = {
  filter: CollectiveIntelligenceThreadFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCollectiveIntelligenceThreadRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCollectiveIntelligenceThreadUpdateArgs = {
  data: CollectiveIntelligenceThreadUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CollectiveIntelligenceThreadKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCollectiveIntelligenceThreadUpdateByFilterArgs = {
  data: CollectiveIntelligenceThreadUpdateByFilterInput;
  filter?: InputMaybe<CollectiveIntelligenceThreadFilter>;
};

export type MutationContactOrganizationRequestArgs = {
  subject: Scalars['String'];
  text: Scalars['String'];
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationContentReportUpdateByFilterArgs = {
  data: ContentReportUpdateByFilterInput;
  filter?: InputMaybe<ContentReportFilter>;
};

export type MutationDashboardCreateArgs = {
  data: DashboardCreateInput;
};

export type MutationDashboardCreateManyArgs = {
  data: Array<InputMaybe<DashboardCreateManyInput>>;
};

export type MutationDashboardDeleteArgs = {
  data?: InputMaybe<DashboardDeleteInput>;
  filter?: InputMaybe<DashboardKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDashboardDeleteByFilterArgs = {
  filter: DashboardFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDashboardDestroyArgs = {
  filter?: InputMaybe<DashboardKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDashboardDestroyByFilterArgs = {
  filter: DashboardFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDashboardRestoreArgs = {
  id: Scalars['String'];
};

export type MutationDashboardUpdateArgs = {
  data: DashboardUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DashboardKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDashboardUpdateByFilterArgs = {
  data: DashboardUpdateByFilterInput;
  filter?: InputMaybe<DashboardFilter>;
};

export type MutationDebtCreateArgs = {
  data: DebtCreateInput;
};

export type MutationDebtCreateManyArgs = {
  data: Array<InputMaybe<DebtCreateManyInput>>;
};

export type MutationDebtDeleteArgs = {
  data?: InputMaybe<DebtDeleteInput>;
  filter?: InputMaybe<DebtKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDebtDeleteByFilterArgs = {
  filter: DebtFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDebtDestroyArgs = {
  filter?: InputMaybe<DebtKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDebtDestroyByFilterArgs = {
  filter: DebtFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDebtOperationHistoryCreateArgs = {
  data: DebtOperationHistoryCreateInput;
};

export type MutationDebtOperationHistoryCreateManyArgs = {
  data: Array<InputMaybe<DebtOperationHistoryCreateManyInput>>;
};

export type MutationDebtOperationHistoryDeleteArgs = {
  data?: InputMaybe<DebtOperationHistoryDeleteInput>;
  filter?: InputMaybe<DebtOperationHistoryKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDebtOperationHistoryDeleteByFilterArgs = {
  filter: DebtOperationHistoryFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDebtOperationHistoryDestroyArgs = {
  filter?: InputMaybe<DebtOperationHistoryKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDebtOperationHistoryDestroyByFilterArgs = {
  filter: DebtOperationHistoryFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDebtOperationHistoryRestoreArgs = {
  id: Scalars['String'];
};

export type MutationDebtOperationHistoryUpdateArgs = {
  data: DebtOperationHistoryUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DebtOperationHistoryKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDebtOperationHistoryUpdateByFilterArgs = {
  data: DebtOperationHistoryUpdateByFilterInput;
  filter?: InputMaybe<DebtOperationHistoryFilter>;
};

export type MutationDebtRestoreArgs = {
  id: Scalars['String'];
};

export type MutationDebtUpdateArgs = {
  data: DebtUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DebtKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDebtUpdateByFilterArgs = {
  data: DebtUpdateByFilterInput;
  filter?: InputMaybe<DebtFilter>;
};

export type MutationDeployArgs = {
  data?: InputMaybe<DeployingBuildInput>;
};

export type MutationDiscountCreateArgs = {
  data: DiscountCreateInput;
};

export type MutationDiscountCreateManyArgs = {
  data: Array<InputMaybe<DiscountCreateManyInput>>;
};

export type MutationDiscountCreateRequestArgs = {
  data: DiscountCreateRequestInput;
};

export type MutationDiscountDeleteArgs = {
  data?: InputMaybe<DiscountDeleteInput>;
  filter?: InputMaybe<DiscountKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDiscountDeleteByFilterArgs = {
  filter: DiscountFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDiscountDestroyArgs = {
  filter?: InputMaybe<DiscountKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDiscountDestroyByFilterArgs = {
  filter: DiscountFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDiscountRedemptionCreateArgs = {
  data: DiscountRedemptionCreateInput;
};

export type MutationDiscountRedemptionCreateManyArgs = {
  data: Array<InputMaybe<DiscountRedemptionCreateManyInput>>;
};

export type MutationDiscountRedemptionDeleteArgs = {
  data?: InputMaybe<DiscountRedemptionDeleteInput>;
  filter?: InputMaybe<DiscountRedemptionKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDiscountRedemptionDeleteByFilterArgs = {
  filter: DiscountRedemptionFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDiscountRedemptionDestroyArgs = {
  filter?: InputMaybe<DiscountRedemptionKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDiscountRedemptionDestroyByFilterArgs = {
  filter: DiscountRedemptionFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDiscountRedemptionRestoreArgs = {
  id: Scalars['String'];
};

export type MutationDiscountRedemptionUpdateArgs = {
  data: DiscountRedemptionUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DiscountRedemptionKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDiscountRedemptionUpdateByFilterArgs = {
  data: DiscountRedemptionUpdateByFilterInput;
  filter?: InputMaybe<DiscountRedemptionFilter>;
};

export type MutationDiscountRestoreArgs = {
  id: Scalars['String'];
};

export type MutationDiscountUpdateArgs = {
  data: DiscountUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DiscountKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDiscountUpdateByFilterArgs = {
  data: DiscountUpdateByFilterInput;
  filter?: InputMaybe<DiscountFilter>;
};

export type MutationDiscountUpdateStatusRequestArgs = {
  data: DiscountUpdateStatusRequestInput;
};

export type MutationEducationalInstitutionCreateArgs = {
  data: EducationalInstitutionCreateInput;
};

export type MutationEducationalInstitutionCreateManyArgs = {
  data: Array<InputMaybe<EducationalInstitutionCreateManyInput>>;
};

export type MutationEducationalInstitutionDeleteArgs = {
  data?: InputMaybe<EducationalInstitutionDeleteInput>;
  filter?: InputMaybe<EducationalInstitutionKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEducationalInstitutionDeleteByFilterArgs = {
  filter: EducationalInstitutionFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEducationalInstitutionDestroyArgs = {
  filter?: InputMaybe<EducationalInstitutionKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEducationalInstitutionDestroyByFilterArgs = {
  filter: EducationalInstitutionFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEducationalInstitutionRestoreArgs = {
  id: Scalars['String'];
};

export type MutationEducationalInstitutionUpdateArgs = {
  data: EducationalInstitutionUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EducationalInstitutionKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEducationalInstitutionUpdateByFilterArgs = {
  data: EducationalInstitutionUpdateByFilterInput;
  filter?: InputMaybe<EducationalInstitutionFilter>;
};

export type MutationEnvironmentVariableCreateArgs = {
  data: EnvironmentVariableCreateInput;
};

export type MutationEnvironmentVariableCreateManyArgs = {
  data: Array<InputMaybe<EnvironmentVariableCreateManyInput>>;
};

export type MutationEnvironmentVariableDeleteArgs = {
  data?: InputMaybe<EnvironmentVariableDeleteInput>;
  filter?: InputMaybe<EnvironmentVariableKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableDeleteByFilterArgs = {
  filter: EnvironmentVariableFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableDestroyArgs = {
  filter?: InputMaybe<EnvironmentVariableKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableDestroyByFilterArgs = {
  filter: EnvironmentVariableFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableRestoreArgs = {
  id: Scalars['String'];
};

export type MutationEnvironmentVariableUpdateArgs = {
  data: EnvironmentVariableUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EnvironmentVariableKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableUpdateByFilterArgs = {
  data: EnvironmentVariableUpdateByFilterInput;
  filter?: InputMaybe<EnvironmentVariableFilter>;
};

export type MutationEventCreateArgs = {
  data: EventCreateInput;
};

export type MutationEventCreateManyArgs = {
  data: Array<InputMaybe<EventCreateManyInput>>;
};

export type MutationEventDeleteArgs = {
  data?: InputMaybe<EventDeleteInput>;
  filter?: InputMaybe<EventKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEventDeleteByFilterArgs = {
  filter: EventFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEventDestroyArgs = {
  filter?: InputMaybe<EventKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEventDestroyByFilterArgs = {
  filter: EventFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEventRestoreArgs = {
  id: Scalars['String'];
};

export type MutationEventSponsorCreateRequestArgs = {
  business: OrganizationKeyFilter;
  event: EventKeyFilter;
};

export type MutationEventSponsorRequestCreateArgs = {
  data: EventSponsorRequestCreateInput;
};

export type MutationEventSponsorRequestCreateManyArgs = {
  data: Array<InputMaybe<EventSponsorRequestCreateManyInput>>;
};

export type MutationEventSponsorRequestDeleteArgs = {
  data?: InputMaybe<EventSponsorRequestDeleteInput>;
  filter?: InputMaybe<EventSponsorRequestKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEventSponsorRequestDeleteByFilterArgs = {
  filter: EventSponsorRequestFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEventSponsorRequestDestroyArgs = {
  filter?: InputMaybe<EventSponsorRequestKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEventSponsorRequestDestroyByFilterArgs = {
  filter: EventSponsorRequestFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEventSponsorRequestRestoreArgs = {
  id: Scalars['String'];
};

export type MutationEventSponsorRequestUpdateArgs = {
  data: EventSponsorRequestUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventSponsorRequestKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEventSponsorRequestUpdateByFilterArgs = {
  data: EventSponsorRequestUpdateByFilterInput;
  filter?: InputMaybe<EventSponsorRequestFilter>;
};

export type MutationEventStatusUpdateRequestArgs = {
  data: EventStatusUpdateRequestInput;
};

export type MutationEventUpdateArgs = {
  data: EventUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationEventUpdateByFilterArgs = {
  data: EventUpdateByFilterInput;
  filter?: InputMaybe<EventFilter>;
};

export type MutationFieldCreateArgs = {
  data: TableFieldCreateInput;
};

export type MutationFieldDeleteArgs = {
  data: TableFieldDeleteInput;
};

export type MutationFieldUpdateArgs = {
  data: TableFieldUpdateInput;
};

export type MutationFieldUpdatePositionArgs = {
  data: TableFieldPositionUpdateInput;
};

export type MutationFileCreateArgs = {
  data: FileCreateInput;
};

export type MutationFileCreateManyArgs = {
  data: Array<InputMaybe<FileCreateManyInput>>;
};

export type MutationFileDeleteArgs = {
  data?: InputMaybe<FileDeleteInput>;
  filter?: InputMaybe<FileKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationFileDeleteByFilterArgs = {
  filter: FileFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationFileDestroyArgs = {
  filter?: InputMaybe<FileKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationFileDestroyByFilterArgs = {
  filter: FileFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationFileRestoreArgs = {
  id: Scalars['String'];
};

export type MutationFileUpdateArgs = {
  data: FileUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FileKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationFileUpdateByFilterArgs = {
  data: FileUpdateByFilterInput;
  filter?: InputMaybe<FileFilter>;
};

export type MutationFilesSettingCreateArgs = {
  data: FilesSettingCreateInput;
};

export type MutationFilesSettingCreateManyArgs = {
  data: Array<InputMaybe<FilesSettingCreateManyInput>>;
};

export type MutationFilesSettingDeleteArgs = {
  data?: InputMaybe<FilesSettingDeleteInput>;
  filter?: InputMaybe<FilesSettingKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationFilesSettingDeleteByFilterArgs = {
  filter: FilesSettingFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationFilesSettingDestroyArgs = {
  filter?: InputMaybe<FilesSettingKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationFilesSettingDestroyByFilterArgs = {
  filter: FilesSettingFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationFilesSettingRestoreArgs = {
  id: Scalars['String'];
};

export type MutationFilesSettingUpdateArgs = {
  data: FilesSettingUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FilesSettingKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationFilesSettingUpdateByFilterArgs = {
  data: FilesSettingUpdateByFilterInput;
  filter?: InputMaybe<FilesSettingFilter>;
};

export type MutationFlagPostRequestArgs = {
  data: FlagPostRequestInput;
};

export type MutationFlaggedPostApproveRequestArgs = {
  data: FlaggedPostApproveRequestInput;
};

export type MutationFlaggedPostDeleteRequestArgs = {
  data: FlaggedPostDeleteRequestInput;
};

export type MutationGraduatingYearCreateArgs = {
  data: GraduatingYearCreateInput;
};

export type MutationGraduatingYearCreateManyArgs = {
  data: Array<InputMaybe<GraduatingYearCreateManyInput>>;
};

export type MutationGraduatingYearDeleteArgs = {
  data?: InputMaybe<GraduatingYearDeleteInput>;
  filter?: InputMaybe<GraduatingYearKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGraduatingYearDeleteByFilterArgs = {
  filter: GraduatingYearFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGraduatingYearDestroyArgs = {
  filter?: InputMaybe<GraduatingYearKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGraduatingYearDestroyByFilterArgs = {
  filter: GraduatingYearFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGraduatingYearRestoreArgs = {
  id: Scalars['String'];
};

export type MutationGraduatingYearUpdateArgs = {
  data: GraduatingYearUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GraduatingYearKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGraduatingYearUpdateByFilterArgs = {
  data: GraduatingYearUpdateByFilterInput;
  filter?: InputMaybe<GraduatingYearFilter>;
};

export type MutationGraduatingYearsMetricUpdateByFilterArgs = {
  data: GraduatingYearsMetricUpdateByFilterInput;
  filter?: InputMaybe<GraduatingYearsMetricFilter>;
};

export type MutationGroupAdminPreferenceCreateArgs = {
  data: GroupAdminPreferenceCreateInput;
};

export type MutationGroupAdminPreferenceCreateManyArgs = {
  data: Array<InputMaybe<GroupAdminPreferenceCreateManyInput>>;
};

export type MutationGroupAdminPreferenceDeleteArgs = {
  data?: InputMaybe<GroupAdminPreferenceDeleteInput>;
  filter?: InputMaybe<GroupAdminPreferenceKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupAdminPreferenceDeleteByFilterArgs = {
  filter: GroupAdminPreferenceFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupAdminPreferenceDestroyArgs = {
  filter?: InputMaybe<GroupAdminPreferenceKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupAdminPreferenceDestroyByFilterArgs = {
  filter: GroupAdminPreferenceFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupAdminPreferenceRestoreArgs = {
  id: Scalars['String'];
};

export type MutationGroupAdminPreferenceUpdateArgs = {
  data: GroupAdminPreferenceUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GroupAdminPreferenceKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupAdminPreferenceUpdateByFilterArgs = {
  data: GroupAdminPreferenceUpdateByFilterInput;
  filter?: InputMaybe<GroupAdminPreferenceFilter>;
};

export type MutationGroupCreateArgs = {
  data: GroupCreateInput;
};

export type MutationGroupCreateManyArgs = {
  data: Array<InputMaybe<GroupCreateManyInput>>;
};

export type MutationGroupCreateRequestArgs = {
  data: GroupCreateInput;
};

export type MutationGroupDeleteArgs = {
  data?: InputMaybe<GroupDeleteInput>;
  filter?: InputMaybe<GroupKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupDeleteByFilterArgs = {
  filter: GroupFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupDestroyArgs = {
  filter?: InputMaybe<GroupKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupDestroyByFilterArgs = {
  filter: GroupFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupDetailsUpdateRequestArgs = {
  data: GroupUpdateInput;
};

export type MutationGroupMemberDemoteArgs = {
  group: GroupKeyFilter;
  user: UserKeyFilter;
};

export type MutationGroupMemberPromoteArgs = {
  group: GroupKeyFilter;
  user: UserKeyFilter;
};

export type MutationGroupMemberRemoveArgs = {
  group: GroupKeyFilter;
  user: UserKeyFilter;
};

export type MutationGroupMembersRequestUpsertArgs = {
  groupId: Scalars['ID'];
  status: Scalars['String'];
  userId: Scalars['ID'];
};

export type MutationGroupPostCreateArgs = {
  data: GroupPostCreateInput;
};

export type MutationGroupPostCreateManyArgs = {
  data: Array<InputMaybe<GroupPostCreateManyInput>>;
};

export type MutationGroupPostDeleteArgs = {
  data?: InputMaybe<GroupPostDeleteInput>;
  filter?: InputMaybe<GroupPostKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupPostDeleteByFilterArgs = {
  filter: GroupPostFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupPostDestroyArgs = {
  filter?: InputMaybe<GroupPostKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupPostDestroyByFilterArgs = {
  filter: GroupPostFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupPostRestoreArgs = {
  id: Scalars['String'];
};

export type MutationGroupPostUpdateArgs = {
  data: GroupPostUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GroupPostKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupPostUpdateByFilterArgs = {
  data: GroupPostUpdateByFilterInput;
  filter?: InputMaybe<GroupPostFilter>;
};

export type MutationGroupRejectRequestArgs = {
  groupId: Scalars['ID'];
};

export type MutationGroupRestoreArgs = {
  id: Scalars['String'];
};

export type MutationGroupUpdateArgs = {
  data: GroupUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GroupKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupUpdateByFilterArgs = {
  data: GroupUpdateByFilterInput;
  filter?: InputMaybe<GroupFilter>;
};

export type MutationGroupsMembersRequestCreateArgs = {
  data: GroupsMembersRequestCreateInput;
};

export type MutationGroupsMembersRequestCreateManyArgs = {
  data: Array<InputMaybe<GroupsMembersRequestCreateManyInput>>;
};

export type MutationGroupsMembersRequestDeleteArgs = {
  data?: InputMaybe<GroupsMembersRequestDeleteInput>;
  filter?: InputMaybe<GroupsMembersRequestKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupsMembersRequestDeleteByFilterArgs = {
  filter: GroupsMembersRequestFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupsMembersRequestDestroyArgs = {
  filter?: InputMaybe<GroupsMembersRequestKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupsMembersRequestDestroyByFilterArgs = {
  filter: GroupsMembersRequestFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupsMembersRequestRestoreArgs = {
  id: Scalars['String'];
};

export type MutationGroupsMembersRequestUpdateArgs = {
  data: GroupsMembersRequestUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GroupsMembersRequestKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupsMembersRequestUpdateByFilterArgs = {
  data: GroupsMembersRequestUpdateByFilterInput;
  filter?: InputMaybe<GroupsMembersRequestFilter>;
};

export type MutationGroupsStatCreateArgs = {
  data: GroupsStatCreateInput;
};

export type MutationGroupsStatCreateManyArgs = {
  data: Array<InputMaybe<GroupsStatCreateManyInput>>;
};

export type MutationGroupsStatDeleteArgs = {
  data?: InputMaybe<GroupsStatDeleteInput>;
  filter?: InputMaybe<GroupsStatKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupsStatDeleteByFilterArgs = {
  filter: GroupsStatFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupsStatDestroyArgs = {
  filter?: InputMaybe<GroupsStatKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupsStatDestroyByFilterArgs = {
  filter: GroupsStatFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupsStatRestoreArgs = {
  id: Scalars['String'];
};

export type MutationGroupsStatUpdateArgs = {
  data: GroupsStatUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GroupsStatKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationGroupsStatUpdateByFilterArgs = {
  data: GroupsStatUpdateByFilterInput;
  filter?: InputMaybe<GroupsStatFilter>;
};

export type MutationHighSchoolLocationCreateArgs = {
  data: HighSchoolLocationCreateInput;
};

export type MutationHighSchoolLocationCreateManyArgs = {
  data: Array<InputMaybe<HighSchoolLocationCreateManyInput>>;
};

export type MutationHighSchoolLocationDeleteArgs = {
  data?: InputMaybe<HighSchoolLocationDeleteInput>;
  filter?: InputMaybe<HighSchoolLocationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationHighSchoolLocationDeleteByFilterArgs = {
  filter: HighSchoolLocationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationHighSchoolLocationDestroyArgs = {
  filter?: InputMaybe<HighSchoolLocationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationHighSchoolLocationDestroyByFilterArgs = {
  filter: HighSchoolLocationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationHighSchoolLocationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationHighSchoolLocationUpdateArgs = {
  data: HighSchoolLocationUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<HighSchoolLocationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationHighSchoolLocationUpdateByFilterArgs = {
  data: HighSchoolLocationUpdateByFilterInput;
  filter?: InputMaybe<HighSchoolLocationFilter>;
};

export type MutationHomeFeedItemUpdateByFilterArgs = {
  data: HomeFeedItemUpdateByFilterInput;
  filter?: InputMaybe<HomeFeedItemFilter>;
};

export type MutationHomeFeedPostCreateArgs = {
  data: HomeFeedPostCreateInput;
};

export type MutationHomeFeedPostCreateManyArgs = {
  data: Array<InputMaybe<HomeFeedPostCreateManyInput>>;
};

export type MutationHomeFeedPostDeleteArgs = {
  data?: InputMaybe<HomeFeedPostDeleteInput>;
  filter?: InputMaybe<HomeFeedPostKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationHomeFeedPostDeleteByFilterArgs = {
  filter: HomeFeedPostFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationHomeFeedPostDestroyArgs = {
  filter?: InputMaybe<HomeFeedPostKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationHomeFeedPostDestroyByFilterArgs = {
  filter: HomeFeedPostFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationHomeFeedPostRestoreArgs = {
  id: Scalars['String'];
};

export type MutationHomeFeedPostUpdateArgs = {
  data: HomeFeedPostUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<HomeFeedPostKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationHomeFeedPostUpdateByFilterArgs = {
  data: HomeFeedPostUpdateByFilterInput;
  filter?: InputMaybe<HomeFeedPostFilter>;
};

export type MutationInactivateSchoolMembersArgs = {
  filter: UserFilter;
  school: SchoolConfigurationKeyFilter;
};

export type MutationInboxCreateArgs = {
  data: InboxCreateInput;
};

export type MutationInboxCreateManyArgs = {
  data: Array<InputMaybe<InboxCreateManyInput>>;
};

export type MutationInboxDeleteArgs = {
  data?: InputMaybe<InboxDeleteInput>;
  filter?: InputMaybe<InboxKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxDeleteByFilterArgs = {
  filter: InboxFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxDestroyArgs = {
  filter?: InputMaybe<InboxKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxDestroyByFilterArgs = {
  filter: InboxFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxMessageCreateArgs = {
  data: InboxMessageCreateInput;
};

export type MutationInboxMessageCreateManyArgs = {
  data: Array<InputMaybe<InboxMessageCreateManyInput>>;
};

export type MutationInboxMessageDeleteArgs = {
  data?: InputMaybe<InboxMessageDeleteInput>;
  filter?: InputMaybe<InboxMessageKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxMessageDeleteByFilterArgs = {
  filter: InboxMessageFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxMessageDestroyArgs = {
  filter?: InputMaybe<InboxMessageKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxMessageDestroyByFilterArgs = {
  filter: InboxMessageFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxMessageMediaCreateArgs = {
  data: InboxMessageMediaCreateInput;
};

export type MutationInboxMessageMediaCreateManyArgs = {
  data: Array<InputMaybe<InboxMessageMediaCreateManyInput>>;
};

export type MutationInboxMessageMediaDeleteArgs = {
  data?: InputMaybe<InboxMessageMediaDeleteInput>;
  filter?: InputMaybe<InboxMessageMediaKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxMessageMediaDeleteByFilterArgs = {
  filter: InboxMessageMediaFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxMessageMediaDestroyArgs = {
  filter?: InputMaybe<InboxMessageMediaKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxMessageMediaDestroyByFilterArgs = {
  filter: InboxMessageMediaFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxMessageMediaRestoreArgs = {
  id: Scalars['String'];
};

export type MutationInboxMessageMediaUpdateArgs = {
  data: InboxMessageMediaUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InboxMessageMediaKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxMessageRestoreArgs = {
  id: Scalars['String'];
};

export type MutationInboxMessageUpdateArgs = {
  data: InboxMessageUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InboxMessageKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxMessageUpdateByFilterArgs = {
  data: InboxMessageUpdateByFilterInput;
  filter?: InputMaybe<InboxMessageFilter>;
};

export type MutationInboxRestoreArgs = {
  id: Scalars['String'];
};

export type MutationInboxUpdateArgs = {
  data: InboxUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InboxKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationInboxUpdateByFilterArgs = {
  data: InboxUpdateByFilterInput;
  filter?: InputMaybe<InboxFilter>;
};

export type MutationIndexCreateArgs = {
  data: IndexCreateInput;
};

export type MutationIndexDeleteArgs = {
  data: IndexDeleteInput;
};

export type MutationIndexUpdateArgs = {
  data: IndexUpdateInput;
};

export type MutationInvokeArgs = {
  data?: InputMaybe<InvokeData>;
};

export type MutationJobEmployeeCreateArgs = {
  data: JobEmployeeCreateInput;
};

export type MutationJobEmployeeCreateManyArgs = {
  data: Array<InputMaybe<JobEmployeeCreateManyInput>>;
};

export type MutationJobEmployeeDeleteArgs = {
  data?: InputMaybe<JobEmployeeDeleteInput>;
  filter?: InputMaybe<JobEmployeeKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationJobEmployeeDeleteByFilterArgs = {
  filter: JobEmployeeFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationJobEmployeeDestroyArgs = {
  filter?: InputMaybe<JobEmployeeKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationJobEmployeeDestroyByFilterArgs = {
  filter: JobEmployeeFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationJobEmployeeRestoreArgs = {
  id: Scalars['String'];
};

export type MutationJobEmployeeUpdateArgs = {
  data: JobEmployeeUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<JobEmployeeKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationJobEmployeeUpdateByFilterArgs = {
  data: JobEmployeeUpdateByFilterInput;
  filter?: InputMaybe<JobEmployeeFilter>;
};

export type MutationJobOfferCreateArgs = {
  data: JobOfferCreateInput;
};

export type MutationJobOfferCreateManyArgs = {
  data: Array<InputMaybe<JobOfferCreateManyInput>>;
};

export type MutationJobOfferDeleteArgs = {
  data?: InputMaybe<JobOfferDeleteInput>;
  filter?: InputMaybe<JobOfferKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationJobOfferDeleteByFilterArgs = {
  filter: JobOfferFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationJobOfferDestroyArgs = {
  filter?: InputMaybe<JobOfferKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationJobOfferDestroyByFilterArgs = {
  filter: JobOfferFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationJobOfferRestoreArgs = {
  id: Scalars['String'];
};

export type MutationJobOfferUpdateArgs = {
  data: JobOfferUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<JobOfferKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationJobOfferUpdateByFilterArgs = {
  data: JobOfferUpdateByFilterInput;
  filter?: InputMaybe<JobOfferFilter>;
};

export type MutationLegacyFeedMonthCreateArgs = {
  data: LegacyFeedMonthCreateInput;
};

export type MutationLegacyFeedMonthCreateManyArgs = {
  data: Array<InputMaybe<LegacyFeedMonthCreateManyInput>>;
};

export type MutationLegacyFeedMonthDeleteArgs = {
  data?: InputMaybe<LegacyFeedMonthDeleteInput>;
  filter?: InputMaybe<LegacyFeedMonthKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedMonthDeleteByFilterArgs = {
  filter: LegacyFeedMonthFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedMonthDestroyArgs = {
  filter?: InputMaybe<LegacyFeedMonthKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedMonthDestroyByFilterArgs = {
  filter: LegacyFeedMonthFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedMonthRestoreArgs = {
  id: Scalars['String'];
};

export type MutationLegacyFeedMonthUpdateArgs = {
  data: LegacyFeedMonthUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LegacyFeedMonthKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedMonthUpdateByFilterArgs = {
  data: LegacyFeedMonthUpdateByFilterInput;
  filter?: InputMaybe<LegacyFeedMonthFilter>;
};

export type MutationLegacyFeedPostCreateArgs = {
  data: LegacyFeedPostCreateInput;
};

export type MutationLegacyFeedPostCreateManyArgs = {
  data: Array<InputMaybe<LegacyFeedPostCreateManyInput>>;
};

export type MutationLegacyFeedPostDeleteArgs = {
  data?: InputMaybe<LegacyFeedPostDeleteInput>;
  filter?: InputMaybe<LegacyFeedPostKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedPostDeleteByFilterArgs = {
  filter: LegacyFeedPostFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedPostDestroyArgs = {
  filter?: InputMaybe<LegacyFeedPostKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedPostDestroyByFilterArgs = {
  filter: LegacyFeedPostFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedPostRestoreArgs = {
  id: Scalars['String'];
};

export type MutationLegacyFeedPostUpdateArgs = {
  data: LegacyFeedPostUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LegacyFeedPostKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedPostUpdateByFilterArgs = {
  data: LegacyFeedPostUpdateByFilterInput;
  filter?: InputMaybe<LegacyFeedPostFilter>;
};

export type MutationLegacyFeedYearCreateArgs = {
  data: LegacyFeedYearCreateInput;
};

export type MutationLegacyFeedYearCreateManyArgs = {
  data: Array<InputMaybe<LegacyFeedYearCreateManyInput>>;
};

export type MutationLegacyFeedYearDeleteArgs = {
  data?: InputMaybe<LegacyFeedYearDeleteInput>;
  filter?: InputMaybe<LegacyFeedYearKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedYearDeleteByFilterArgs = {
  filter: LegacyFeedYearFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedYearDestroyArgs = {
  filter?: InputMaybe<LegacyFeedYearKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedYearDestroyByFilterArgs = {
  filter: LegacyFeedYearFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedYearRestoreArgs = {
  id: Scalars['String'];
};

export type MutationLegacyFeedYearUpdateArgs = {
  data: LegacyFeedYearUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LegacyFeedYearKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLegacyFeedYearUpdateByFilterArgs = {
  data: LegacyFeedYearUpdateByFilterInput;
  filter?: InputMaybe<LegacyFeedYearFilter>;
};

export type MutationManagerAcceptRequestArgs = {
  data: ManagerAcceptRequestInput;
};

export type MutationMessageSendRequestArgs = {
  destinationGroups?: InputMaybe<Scalars['JSON']>;
  media?: InputMaybe<Scalars['String']>;
  subject: Scalars['JSON'];
  text: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationNeedHelpSendRequestArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  message: Scalars['String'];
  subject: Scalars['String'];
};

export type MutationNotificationTemplateCreateArgs = {
  data: NotificationTemplateCreateInput;
};

export type MutationNotificationTemplateCreateManyArgs = {
  data: Array<InputMaybe<NotificationTemplateCreateManyInput>>;
};

export type MutationNotificationTemplateDeleteArgs = {
  data?: InputMaybe<NotificationTemplateDeleteInput>;
  filter?: InputMaybe<NotificationTemplateKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationNotificationTemplateDeleteByFilterArgs = {
  filter: NotificationTemplateFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationNotificationTemplateDestroyArgs = {
  filter?: InputMaybe<NotificationTemplateKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationNotificationTemplateDestroyByFilterArgs = {
  filter: NotificationTemplateFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationNotificationTemplateRestoreArgs = {
  id: Scalars['String'];
};

export type MutationNotificationTemplateUpdateArgs = {
  data: NotificationTemplateUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<NotificationTemplateKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationNotificationTemplateUpdateByFilterArgs = {
  data: NotificationTemplateUpdateByFilterInput;
  filter?: InputMaybe<NotificationTemplateFilter>;
};

export type MutationOrganizationCreateArgs = {
  data: OrganizationCreateInput;
};

export type MutationOrganizationCreateManyArgs = {
  data: Array<InputMaybe<OrganizationCreateManyInput>>;
};

export type MutationOrganizationDeleteArgs = {
  data?: InputMaybe<OrganizationDeleteInput>;
  filter?: InputMaybe<OrganizationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationDeleteByFilterArgs = {
  filter: OrganizationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationDestroyArgs = {
  filter?: InputMaybe<OrganizationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationDestroyByFilterArgs = {
  filter: OrganizationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationLikeCreateArgs = {
  data: OrganizationLikeCreateInput;
};

export type MutationOrganizationLikeCreateManyArgs = {
  data: Array<InputMaybe<OrganizationLikeCreateManyInput>>;
};

export type MutationOrganizationLikeDeleteArgs = {
  data?: InputMaybe<OrganizationLikeDeleteInput>;
  filter?: InputMaybe<OrganizationLikeKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationLikeDeleteByFilterArgs = {
  filter: OrganizationLikeFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationLikeDestroyArgs = {
  filter?: InputMaybe<OrganizationLikeKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationLikeDestroyByFilterArgs = {
  filter: OrganizationLikeFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationLikeRestoreArgs = {
  id: Scalars['String'];
};

export type MutationOrganizationLikeUpdateArgs = {
  data: OrganizationLikeUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OrganizationLikeKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationLocationCreateArgs = {
  data: OrganizationLocationCreateInput;
};

export type MutationOrganizationLocationCreateManyArgs = {
  data: Array<InputMaybe<OrganizationLocationCreateManyInput>>;
};

export type MutationOrganizationLocationDeleteArgs = {
  data?: InputMaybe<OrganizationLocationDeleteInput>;
  filter?: InputMaybe<OrganizationLocationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationLocationDeleteByFilterArgs = {
  filter: OrganizationLocationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationLocationDestroyArgs = {
  filter?: InputMaybe<OrganizationLocationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationLocationDestroyByFilterArgs = {
  filter: OrganizationLocationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationLocationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationOrganizationLocationUpdateArgs = {
  data: OrganizationLocationUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OrganizationLocationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationLocationUpdateByFilterArgs = {
  data: OrganizationLocationUpdateByFilterInput;
  filter?: InputMaybe<OrganizationLocationFilter>;
};

export type MutationOrganizationManagerPreferenceCreateArgs = {
  data: OrganizationManagerPreferenceCreateInput;
};

export type MutationOrganizationManagerPreferenceCreateManyArgs = {
  data: Array<InputMaybe<OrganizationManagerPreferenceCreateManyInput>>;
};

export type MutationOrganizationManagerPreferenceDeleteArgs = {
  data?: InputMaybe<OrganizationManagerPreferenceDeleteInput>;
  filter?: InputMaybe<OrganizationManagerPreferenceKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationManagerPreferenceDeleteByFilterArgs = {
  filter: OrganizationManagerPreferenceFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationManagerPreferenceDestroyArgs = {
  filter?: InputMaybe<OrganizationManagerPreferenceKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationManagerPreferenceDestroyByFilterArgs = {
  filter: OrganizationManagerPreferenceFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationManagerPreferenceRestoreArgs = {
  id: Scalars['String'];
};

export type MutationOrganizationManagerPreferenceUpdateArgs = {
  data: OrganizationManagerPreferenceUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OrganizationManagerPreferenceKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationManagerPreferenceUpdateByFilterArgs = {
  data: OrganizationManagerPreferenceUpdateByFilterInput;
  filter?: InputMaybe<OrganizationManagerPreferenceFilter>;
};

export type MutationOrganizationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationOrganizationUpdateArgs = {
  data: OrganizationUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OrganizationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationOrganizationUpdateByFilterArgs = {
  data: OrganizationUpdateByFilterInput;
  filter?: InputMaybe<OrganizationFilter>;
};

export type MutationPaymentTransactionCreateArgs = {
  data: PaymentTransactionCreateInput;
};

export type MutationPaymentTransactionCreateManyArgs = {
  data: Array<InputMaybe<PaymentTransactionCreateManyInput>>;
};

export type MutationPaymentTransactionDeleteArgs = {
  data?: InputMaybe<PaymentTransactionDeleteInput>;
  filter?: InputMaybe<PaymentTransactionKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPaymentTransactionDeleteByFilterArgs = {
  filter: PaymentTransactionFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPaymentTransactionDestroyArgs = {
  filter?: InputMaybe<PaymentTransactionKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPaymentTransactionDestroyByFilterArgs = {
  filter: PaymentTransactionFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPaymentTransactionRestoreArgs = {
  id: Scalars['String'];
};

export type MutationPaymentTransactionUpdateArgs = {
  data: PaymentTransactionUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PaymentTransactionKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPaymentTransactionUpdateByFilterArgs = {
  data: PaymentTransactionUpdateByFilterInput;
  filter?: InputMaybe<PaymentTransactionFilter>;
};

export type MutationPostMentionCreateArgs = {
  data: PostMentionCreateInput;
};

export type MutationPostMentionCreateManyArgs = {
  data: Array<InputMaybe<PostMentionCreateManyInput>>;
};

export type MutationPostMentionDeleteArgs = {
  data?: InputMaybe<PostMentionDeleteInput>;
  filter?: InputMaybe<PostMentionKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPostMentionDeleteByFilterArgs = {
  filter: PostMentionFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPostMentionDestroyArgs = {
  filter?: InputMaybe<PostMentionKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPostMentionDestroyByFilterArgs = {
  filter: PostMentionFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPostMentionRestoreArgs = {
  id: Scalars['String'];
};

export type MutationPostMentionUpdateArgs = {
  data: PostMentionUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PostMentionKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPostMentionUpdateByFilterArgs = {
  data: PostMentionUpdateByFilterInput;
  filter?: InputMaybe<PostMentionFilter>;
};

export type MutationPromoteSchoolMemberArgs = {
  school: SchoolConfigurationKeyFilter;
  user: UserKeyFilter;
};

export type MutationRedeemDiscountArgs = {
  discount: DiscountKeyFilter;
  school: SchoolConfigurationKeyFilter;
};

export type MutationRejectSchoolMembersArgs = {
  filter: UserFilter;
  school: SchoolConfigurationKeyFilter;
};

export type MutationRemoteVariableCreateArgs = {
  data: RemoteVariableCreateInput;
};

export type MutationRemoteVariableCreateManyArgs = {
  data: Array<InputMaybe<RemoteVariableCreateManyInput>>;
};

export type MutationRemoteVariableDeleteArgs = {
  data?: InputMaybe<RemoteVariableDeleteInput>;
  filter?: InputMaybe<RemoteVariableKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRemoteVariableDeleteByFilterArgs = {
  filter: RemoteVariableFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRemoteVariableDestroyArgs = {
  filter?: InputMaybe<RemoteVariableKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRemoteVariableDestroyByFilterArgs = {
  filter: RemoteVariableFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRemoteVariableRestoreArgs = {
  id: Scalars['String'];
};

export type MutationRemoteVariableUpdateArgs = {
  data: RemoteVariableUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RemoteVariableKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRemoteVariableUpdateByFilterArgs = {
  data: RemoteVariableUpdateByFilterInput;
  filter?: InputMaybe<RemoteVariableFilter>;
};

export type MutationRenewJobOfferArgs = {
  id: Scalars['ID'];
};

export type MutationRequestBusinessAccessArgs = {
  contactEmail: Scalars['String'];
  contactFirstName: Scalars['String'];
  contactLastName: Scalars['String'];
  contactPassword: Scalars['String'];
  description: Scalars['String'];
  industry: Scalars['String'];
  name: Scalars['String'];
};

export type MutationRequestCommunityAccessArgs = {
  affiliation?: InputMaybe<Scalars['String']>;
  affiliationDescription?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstname?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  school: SchoolConfigurationKeyFilter;
};

export type MutationResendSchoolApprovalsArgs = {
  filter: UserFilter;
  school: SchoolConfigurationKeyFilter;
};

export type MutationResetUserCredentialsArgs = {
  user: UserKeyFilter;
};

export type MutationRoleAddonCreateArgs = {
  data: RoleAddonCreateInput;
};

export type MutationRoleAddonCreateManyArgs = {
  data: Array<InputMaybe<RoleAddonCreateManyInput>>;
};

export type MutationRoleAddonDeleteArgs = {
  data?: InputMaybe<RoleAddonDeleteInput>;
  filter?: InputMaybe<RoleAddonKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRoleAddonDeleteByFilterArgs = {
  filter: RoleAddonFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRoleAddonDestroyArgs = {
  filter?: InputMaybe<RoleAddonKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRoleAddonDestroyByFilterArgs = {
  filter: RoleAddonFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRoleAddonRestoreArgs = {
  id: Scalars['String'];
};

export type MutationRoleAddonUpdateArgs = {
  data: RoleAddonUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RoleAddonKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRoleAddonUpdateByFilterArgs = {
  data: RoleAddonUpdateByFilterInput;
  filter?: InputMaybe<RoleAddonFilter>;
};

export type MutationRoleCreateArgs = {
  data: RoleCreateInput;
};

export type MutationRoleCreateManyArgs = {
  data: Array<InputMaybe<RoleCreateManyInput>>;
};

export type MutationRoleDeleteArgs = {
  data?: InputMaybe<RoleDeleteInput>;
  filter?: InputMaybe<RoleKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRoleDeleteByFilterArgs = {
  filter: RoleFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRoleDestroyArgs = {
  filter?: InputMaybe<RoleKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRoleDestroyByFilterArgs = {
  filter: RoleFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRoleRestoreArgs = {
  id: Scalars['String'];
};

export type MutationRoleUpdateArgs = {
  data: RoleUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RoleKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRoleUpdateByFilterArgs = {
  data: RoleUpdateByFilterInput;
  filter?: InputMaybe<RoleFilter>;
};

export type MutationSchoolConfigurationColorSchemeCreateArgs = {
  data: SchoolConfigurationColorSchemeCreateInput;
};

export type MutationSchoolConfigurationColorSchemeCreateManyArgs = {
  data: Array<InputMaybe<SchoolConfigurationColorSchemeCreateManyInput>>;
};

export type MutationSchoolConfigurationColorSchemeDeleteArgs = {
  data?: InputMaybe<SchoolConfigurationColorSchemeDeleteInput>;
  filter?: InputMaybe<SchoolConfigurationColorSchemeKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationColorSchemeDeleteByFilterArgs = {
  filter: SchoolConfigurationColorSchemeFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationColorSchemeDestroyArgs = {
  filter?: InputMaybe<SchoolConfigurationColorSchemeKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationColorSchemeDestroyByFilterArgs = {
  filter: SchoolConfigurationColorSchemeFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationColorSchemeRestoreArgs = {
  id: Scalars['String'];
};

export type MutationSchoolConfigurationColorSchemeUpdateArgs = {
  data: SchoolConfigurationColorSchemeUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SchoolConfigurationColorSchemeKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationColorSchemeUpdateByFilterArgs = {
  data: SchoolConfigurationColorSchemeUpdateByFilterInput;
  filter?: InputMaybe<SchoolConfigurationColorSchemeFilter>;
};

export type MutationSchoolConfigurationCreateArgs = {
  data: SchoolConfigurationCreateInput;
};

export type MutationSchoolConfigurationCreateManyArgs = {
  data: Array<InputMaybe<SchoolConfigurationCreateManyInput>>;
};

export type MutationSchoolConfigurationDeleteArgs = {
  data?: InputMaybe<SchoolConfigurationDeleteInput>;
  filter?: InputMaybe<SchoolConfigurationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationDeleteByFilterArgs = {
  filter: SchoolConfigurationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationDestroyArgs = {
  filter?: InputMaybe<SchoolConfigurationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationDestroyByFilterArgs = {
  filter: SchoolConfigurationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationExternalLinkCreateArgs = {
  data: SchoolConfigurationExternalLinkCreateInput;
};

export type MutationSchoolConfigurationExternalLinkCreateManyArgs = {
  data: Array<InputMaybe<SchoolConfigurationExternalLinkCreateManyInput>>;
};

export type MutationSchoolConfigurationExternalLinkDeleteArgs = {
  data?: InputMaybe<SchoolConfigurationExternalLinkDeleteInput>;
  filter?: InputMaybe<SchoolConfigurationExternalLinkKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationExternalLinkDeleteByFilterArgs = {
  filter: SchoolConfigurationExternalLinkFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationExternalLinkDestroyArgs = {
  filter?: InputMaybe<SchoolConfigurationExternalLinkKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationExternalLinkDestroyByFilterArgs = {
  filter: SchoolConfigurationExternalLinkFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationExternalLinkRestoreArgs = {
  id: Scalars['String'];
};

export type MutationSchoolConfigurationExternalLinkUpdateArgs = {
  data: SchoolConfigurationExternalLinkUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SchoolConfigurationExternalLinkKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationExternalLinkUpdateByFilterArgs = {
  data: SchoolConfigurationExternalLinkUpdateByFilterInput;
  filter?: InputMaybe<SchoolConfigurationExternalLinkFilter>;
};

export type MutationSchoolConfigurationImageCreateArgs = {
  data: SchoolConfigurationImageCreateInput;
};

export type MutationSchoolConfigurationImageCreateManyArgs = {
  data: Array<InputMaybe<SchoolConfigurationImageCreateManyInput>>;
};

export type MutationSchoolConfigurationImageDeleteArgs = {
  data?: InputMaybe<SchoolConfigurationImageDeleteInput>;
  filter?: InputMaybe<SchoolConfigurationImageKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationImageDeleteByFilterArgs = {
  filter: SchoolConfigurationImageFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationImageDestroyArgs = {
  filter?: InputMaybe<SchoolConfigurationImageKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationImageDestroyByFilterArgs = {
  filter: SchoolConfigurationImageFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationImageRestoreArgs = {
  id: Scalars['String'];
};

export type MutationSchoolConfigurationImageUpdateArgs = {
  data: SchoolConfigurationImageUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SchoolConfigurationImageKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationImageUpdateByFilterArgs = {
  data: SchoolConfigurationImageUpdateByFilterInput;
  filter?: InputMaybe<SchoolConfigurationImageFilter>;
};

export type MutationSchoolConfigurationLayoutCreateArgs = {
  data: SchoolConfigurationLayoutCreateInput;
};

export type MutationSchoolConfigurationLayoutCreateManyArgs = {
  data: Array<InputMaybe<SchoolConfigurationLayoutCreateManyInput>>;
};

export type MutationSchoolConfigurationLayoutDeleteArgs = {
  data?: InputMaybe<SchoolConfigurationLayoutDeleteInput>;
  filter?: InputMaybe<SchoolConfigurationLayoutKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationLayoutDeleteByFilterArgs = {
  filter: SchoolConfigurationLayoutFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationLayoutDestroyArgs = {
  filter?: InputMaybe<SchoolConfigurationLayoutKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationLayoutDestroyByFilterArgs = {
  filter: SchoolConfigurationLayoutFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationLayoutRestoreArgs = {
  id: Scalars['String'];
};

export type MutationSchoolConfigurationLayoutUpdateArgs = {
  data: SchoolConfigurationLayoutUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SchoolConfigurationLayoutKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationLayoutUpdateByFilterArgs = {
  data: SchoolConfigurationLayoutUpdateByFilterInput;
  filter?: InputMaybe<SchoolConfigurationLayoutFilter>;
};

export type MutationSchoolConfigurationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationSchoolConfigurationTopBarCreateArgs = {
  data: SchoolConfigurationTopBarCreateInput;
};

export type MutationSchoolConfigurationTopBarCreateManyArgs = {
  data: Array<InputMaybe<SchoolConfigurationTopBarCreateManyInput>>;
};

export type MutationSchoolConfigurationTopBarDeleteArgs = {
  data?: InputMaybe<SchoolConfigurationTopBarDeleteInput>;
  filter?: InputMaybe<SchoolConfigurationTopBarKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationTopBarDeleteByFilterArgs = {
  filter: SchoolConfigurationTopBarFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationTopBarDestroyArgs = {
  filter?: InputMaybe<SchoolConfigurationTopBarKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationTopBarDestroyByFilterArgs = {
  filter: SchoolConfigurationTopBarFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationTopBarRestoreArgs = {
  id: Scalars['String'];
};

export type MutationSchoolConfigurationTopBarUpdateArgs = {
  data: SchoolConfigurationTopBarUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SchoolConfigurationTopBarKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationTopBarUpdateByFilterArgs = {
  data: SchoolConfigurationTopBarUpdateByFilterInput;
  filter?: InputMaybe<SchoolConfigurationTopBarFilter>;
};

export type MutationSchoolConfigurationUpdateArgs = {
  data: SchoolConfigurationUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SchoolConfigurationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationUpdateByFilterArgs = {
  data: SchoolConfigurationUpdateByFilterInput;
  filter?: InputMaybe<SchoolConfigurationFilter>;
};

export type MutationSchoolConfigurationWordsBlacklistCreateArgs = {
  data: SchoolConfigurationWordsBlacklistCreateInput;
};

export type MutationSchoolConfigurationWordsBlacklistCreateManyArgs = {
  data: Array<InputMaybe<SchoolConfigurationWordsBlacklistCreateManyInput>>;
};

export type MutationSchoolConfigurationWordsBlacklistDeleteArgs = {
  data?: InputMaybe<SchoolConfigurationWordsBlacklistDeleteInput>;
  filter?: InputMaybe<SchoolConfigurationWordsBlacklistKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationWordsBlacklistDeleteByFilterArgs = {
  filter: SchoolConfigurationWordsBlacklistFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationWordsBlacklistDestroyArgs = {
  filter?: InputMaybe<SchoolConfigurationWordsBlacklistKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationWordsBlacklistDestroyByFilterArgs = {
  filter: SchoolConfigurationWordsBlacklistFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationWordsBlacklistRestoreArgs = {
  id: Scalars['String'];
};

export type MutationSchoolConfigurationWordsBlacklistUpdateArgs = {
  data: SchoolConfigurationWordsBlacklistUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SchoolConfigurationWordsBlacklistKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolConfigurationWordsBlacklistUpdateByFilterArgs = {
  data: SchoolConfigurationWordsBlacklistUpdateByFilterInput;
  filter?: InputMaybe<SchoolConfigurationWordsBlacklistFilter>;
};

export type MutationSchoolNewsPostCreateArgs = {
  data: SchoolNewsPostCreateInput;
};

export type MutationSchoolNewsPostCreateManyArgs = {
  data: Array<InputMaybe<SchoolNewsPostCreateManyInput>>;
};

export type MutationSchoolNewsPostDeleteArgs = {
  data?: InputMaybe<SchoolNewsPostDeleteInput>;
  filter?: InputMaybe<SchoolNewsPostKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolNewsPostDeleteByFilterArgs = {
  filter: SchoolNewsPostFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolNewsPostDestroyArgs = {
  filter?: InputMaybe<SchoolNewsPostKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolNewsPostDestroyByFilterArgs = {
  filter: SchoolNewsPostFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolNewsPostRestoreArgs = {
  id: Scalars['String'];
};

export type MutationSchoolNewsPostUpdateArgs = {
  data: SchoolNewsPostUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SchoolNewsPostKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolNewsPostUpdateByFilterArgs = {
  data: SchoolNewsPostUpdateByFilterInput;
  filter?: InputMaybe<SchoolNewsPostFilter>;
};

export type MutationSchoolSupporterClickCreateArgs = {
  data: SchoolSupporterClickCreateInput;
};

export type MutationSchoolSupporterClickCreateManyArgs = {
  data: Array<InputMaybe<SchoolSupporterClickCreateManyInput>>;
};

export type MutationSchoolSupporterClickDeleteArgs = {
  data?: InputMaybe<SchoolSupporterClickDeleteInput>;
  filter?: InputMaybe<SchoolSupporterClickKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterClickDeleteByFilterArgs = {
  filter: SchoolSupporterClickFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterClickDestroyArgs = {
  filter?: InputMaybe<SchoolSupporterClickKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterClickDestroyByFilterArgs = {
  filter: SchoolSupporterClickFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterClickRestoreArgs = {
  id: Scalars['String'];
};

export type MutationSchoolSupporterClickUpdateArgs = {
  data: SchoolSupporterClickUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SchoolSupporterClickKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterCreateArgs = {
  data: SchoolSupporterCreateInput;
};

export type MutationSchoolSupporterCreateManyArgs = {
  data: Array<InputMaybe<SchoolSupporterCreateManyInput>>;
};

export type MutationSchoolSupporterDeleteArgs = {
  data?: InputMaybe<SchoolSupporterDeleteInput>;
  filter?: InputMaybe<SchoolSupporterKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterDeleteByFilterArgs = {
  filter: SchoolSupporterFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterDestroyArgs = {
  filter?: InputMaybe<SchoolSupporterKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterDestroyByFilterArgs = {
  filter: SchoolSupporterFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterRestoreArgs = {
  id: Scalars['String'];
};

export type MutationSchoolSupporterSearchCreateArgs = {
  data: SchoolSupporterSearchCreateInput;
};

export type MutationSchoolSupporterSearchCreateManyArgs = {
  data: Array<InputMaybe<SchoolSupporterSearchCreateManyInput>>;
};

export type MutationSchoolSupporterSearchDeleteArgs = {
  data?: InputMaybe<SchoolSupporterSearchDeleteInput>;
  filter?: InputMaybe<SchoolSupporterSearchKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterSearchDeleteByFilterArgs = {
  filter: SchoolSupporterSearchFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterSearchDestroyArgs = {
  filter?: InputMaybe<SchoolSupporterSearchKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterSearchDestroyByFilterArgs = {
  filter: SchoolSupporterSearchFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterSearchRestoreArgs = {
  id: Scalars['String'];
};

export type MutationSchoolSupporterSearchUpdateArgs = {
  data: SchoolSupporterSearchUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SchoolSupporterSearchKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterUpdateArgs = {
  data: SchoolSupporterUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SchoolSupporterKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchoolSupporterUpdateByFilterArgs = {
  data: SchoolSupporterUpdateByFilterInput;
  filter?: InputMaybe<SchoolSupporterFilter>;
};

export type MutationSendBusinessAdministratorInvitesArgs = {
  invites: Array<SendBusinessAdministratorInviteInput>;
  school: SchoolConfigurationKeyFilter;
};

export type MutationSendBusinessManagerInvitesArgs = {
  invites: Array<SendBusinessManagerInviteInput>;
  organization: OrganizationKeyFilter;
  school?: InputMaybe<SchoolConfigurationKeyFilter>;
};

export type MutationSendInboxMessageNotificationRequestArgs = {
  data: SendInboxMessageNotificationRequestInput;
};

export type MutationSendInvitationTo8baseArgs = {
  inviteEmail: Scalars['String'];
};

export type MutationSendPostReactionNotificationRequestArgs = {
  data: SendPostReactionNotificationRequestInput;
};

export type MutationSendSchoolAdministratorInvitesArgs = {
  invites: Array<SendSchoolAdministratorInviteInput>;
  school: SchoolConfigurationKeyFilter;
};

export type MutationSendSchoolMemberInvitesArgs = {
  invites: Array<SendSchoolMemberInviteInput>;
  isSchoolAdministratorInviting?: InputMaybe<Scalars['Boolean']>;
  school: SchoolConfigurationKeyFilter;
};

export type MutationSettingsUpdateArgs = {
  data: SettingUpdateInput;
};

export type MutationStripeCreatePaymentIntentArgs = {
  amount: Scalars['Float'];
  metadata?: InputMaybe<Scalars['JSON']>;
  organizationId: Scalars['ID'];
};

export type MutationStripeDetachPaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};

export type MutationStripeInformationCreateArgs = {
  data: StripeInformationCreateInput;
};

export type MutationStripeInformationCreateManyArgs = {
  data: Array<InputMaybe<StripeInformationCreateManyInput>>;
};

export type MutationStripeInformationDeleteArgs = {
  data?: InputMaybe<StripeInformationDeleteInput>;
  filter?: InputMaybe<StripeInformationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationStripeInformationDeleteByFilterArgs = {
  filter: StripeInformationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationStripeInformationDestroyArgs = {
  filter?: InputMaybe<StripeInformationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationStripeInformationDestroyByFilterArgs = {
  filter: StripeInformationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationStripeInformationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationStripeInformationUpdateArgs = {
  data: StripeInformationUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StripeInformationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationStripeInformationUpdateByFilterArgs = {
  data: StripeInformationUpdateByFilterInput;
  filter?: InputMaybe<StripeInformationFilter>;
};

export type MutationStripeUpdateCustomerDefaultPaymentMethodArgs = {
  customer: Scalars['String'];
  defaultPaymentMethodId: Scalars['String'];
};

export type MutationTableCreateArgs = {
  data: TableCreateInput;
};

export type MutationTableDeleteArgs = {
  data: TableDeleteInput;
};

export type MutationTableUpdateArgs = {
  data: TableUpdateInput;
};

export type MutationTeamInvitationDeleteArgs = {
  data?: InputMaybe<TeamInvitationDeleteInput>;
  filter?: InputMaybe<TeamInvitationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTeamInvitationDeleteByFilterArgs = {
  filter: TeamInvitationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTeamInvitationDestroyArgs = {
  filter?: InputMaybe<TeamInvitationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTeamInvitationDestroyByFilterArgs = {
  filter: TeamInvitationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTeamInvitationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationTeamMemberDeleteArgs = {
  data?: InputMaybe<TeamMemberDeleteInput>;
  filter?: InputMaybe<TeamMemberKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTeamMemberDeleteByFilterArgs = {
  filter: TeamMemberFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTeamMemberDestroyArgs = {
  filter?: InputMaybe<TeamMemberKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTeamMemberDestroyByFilterArgs = {
  filter: TeamMemberFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTeamMemberRestoreArgs = {
  id: Scalars['String'];
};

export type MutationTeamMemberUpdateArgs = {
  data: TeamMemberUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TeamMemberKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTeamMemberUpdateByFilterArgs = {
  data: TeamMemberUpdateByFilterInput;
  filter?: InputMaybe<TeamMemberFilter>;
};

export type MutationTrendingSearchCreateArgs = {
  data: TrendingSearchCreateInput;
};

export type MutationTrendingSearchCreateManyArgs = {
  data: Array<InputMaybe<TrendingSearchCreateManyInput>>;
};

export type MutationTrendingSearchDeleteArgs = {
  data?: InputMaybe<TrendingSearchDeleteInput>;
  filter?: InputMaybe<TrendingSearchKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTrendingSearchDeleteByFilterArgs = {
  filter: TrendingSearchFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTrendingSearchDestroyArgs = {
  filter?: InputMaybe<TrendingSearchKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTrendingSearchDestroyByFilterArgs = {
  filter: TrendingSearchFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTrendingSearchRestoreArgs = {
  id: Scalars['String'];
};

export type MutationTrendingSearchUpdateArgs = {
  data: TrendingSearchUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TrendingSearchKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTrendingSearchUpdateByFilterArgs = {
  data: TrendingSearchUpdateByFilterInput;
  filter?: InputMaybe<TrendingSearchFilter>;
};

export type MutationUpdateHomeFeedPostRequestArgs = {
  data: UpdateHomeFeedPostRequestInput;
};

export type MutationUpdatePostArgs = {
  postData: Scalars['JSON'];
  postId: Scalars['ID'];
  postType: Scalars['String'];
};

export type MutationUserAuthorizeArgs = {
  data: UserAuthorizeInput;
};

export type MutationUserChangePasswordArgs = {
  data: ChangePasswordInput;
};

export type MutationUserCompleteRegistrationArgs = {
  data: UserCompleteRegistrationInput;
  school?: InputMaybe<SchoolConfigurationKeyFilter>;
};

export type MutationUserConfigurationCreateArgs = {
  data: UserConfigurationCreateInput;
};

export type MutationUserConfigurationCreateManyArgs = {
  data: Array<InputMaybe<UserConfigurationCreateManyInput>>;
};

export type MutationUserConfigurationDeleteArgs = {
  data?: InputMaybe<UserConfigurationDeleteInput>;
  filter?: InputMaybe<UserConfigurationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserConfigurationDeleteByFilterArgs = {
  filter: UserConfigurationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserConfigurationDestroyArgs = {
  filter?: InputMaybe<UserConfigurationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserConfigurationDestroyByFilterArgs = {
  filter: UserConfigurationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserConfigurationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationUserConfigurationUpdateArgs = {
  data: UserConfigurationUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserConfigurationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserConfigurationUpdateByFilterArgs = {
  data: UserConfigurationUpdateByFilterInput;
  filter?: InputMaybe<UserConfigurationFilter>;
};

export type MutationUserCreateArgs = {
  data: UserCreateInput;
};

export type MutationUserCreateManyArgs = {
  data: Array<InputMaybe<UserCreateManyInput>>;
};

export type MutationUserDeleteArgs = {
  data?: InputMaybe<UserDeleteInput>;
  filter?: InputMaybe<UserKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserDeleteByFilterArgs = {
  filter: UserFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserDestroyArgs = {
  filter?: InputMaybe<UserKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserDestroyByFilterArgs = {
  filter: UserFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserEmailChangeRequestArgs = {
  data: UserEmailChangeInput;
};

export type MutationUserForgotPasswordChangeArgs = {
  data: UserForgotPasswordChangeInput;
};

export type MutationUserForgotPasswordEmailSendArgs = {
  data: UserForgotPasswordEmailSendInput;
};

export type MutationUserInviteMembersToGroupArgs = {
  data: UserInviteMembersToGroupInput;
};

export type MutationUserLoginArgs = {
  data: UserLoginInput;
};

export type MutationUserNotificationCreateArgs = {
  data: UserNotificationCreateInput;
};

export type MutationUserNotificationCreateManyArgs = {
  data: Array<InputMaybe<UserNotificationCreateManyInput>>;
};

export type MutationUserNotificationDeleteArgs = {
  data?: InputMaybe<UserNotificationDeleteInput>;
  filter?: InputMaybe<UserNotificationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserNotificationDeleteByFilterArgs = {
  filter: UserNotificationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserNotificationDestroyArgs = {
  filter?: InputMaybe<UserNotificationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserNotificationDestroyByFilterArgs = {
  filter: UserNotificationFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserNotificationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationUserNotificationUpdateArgs = {
  data: UserNotificationUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserNotificationKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserNotificationUpdateByFilterArgs = {
  data: UserNotificationUpdateByFilterInput;
  filter?: InputMaybe<UserNotificationFilter>;
};

export type MutationUserNotificationsByUserCreateArgs = {
  data: UserNotificationsByUserCreateInput;
};

export type MutationUserNotificationsByUserCreateManyArgs = {
  data: Array<InputMaybe<UserNotificationsByUserCreateManyInput>>;
};

export type MutationUserNotificationsByUserDeleteArgs = {
  data?: InputMaybe<UserNotificationsByUserDeleteInput>;
  filter?: InputMaybe<UserNotificationsByUserKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserNotificationsByUserDeleteByFilterArgs = {
  filter: UserNotificationsByUserFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserNotificationsByUserDestroyArgs = {
  filter?: InputMaybe<UserNotificationsByUserKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserNotificationsByUserDestroyByFilterArgs = {
  filter: UserNotificationsByUserFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserNotificationsByUserRestoreArgs = {
  id: Scalars['String'];
};

export type MutationUserNotificationsByUserUpdateArgs = {
  data: UserNotificationsByUserUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserNotificationsByUserKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserNotificationsByUserUpdateByFilterArgs = {
  data: UserNotificationsByUserUpdateByFilterInput;
  filter?: InputMaybe<UserNotificationsByUserFilter>;
};

export type MutationUserPasswordChangeRequestArgs = {
  data: UserPasswordChangeInput;
};

export type MutationUserPasswordForgotArgs = {
  data: PasswordForgotInput;
};

export type MutationUserPasswordForgotConfirmArgs = {
  data: PasswordForgotConfirmInput;
};

export type MutationUserPreferenceCreateArgs = {
  data: UserPreferenceCreateInput;
};

export type MutationUserPreferenceCreateManyArgs = {
  data: Array<InputMaybe<UserPreferenceCreateManyInput>>;
};

export type MutationUserPreferenceDeleteArgs = {
  data?: InputMaybe<UserPreferenceDeleteInput>;
  filter?: InputMaybe<UserPreferenceKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserPreferenceDeleteByFilterArgs = {
  filter: UserPreferenceFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserPreferenceDestroyArgs = {
  filter?: InputMaybe<UserPreferenceKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserPreferenceDestroyByFilterArgs = {
  filter: UserPreferenceFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserPreferenceRestoreArgs = {
  id: Scalars['String'];
};

export type MutationUserPreferenceUpdateArgs = {
  data: UserPreferenceUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserPreferenceKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserPreferenceUpdateByFilterArgs = {
  data: UserPreferenceUpdateByFilterInput;
  filter?: InputMaybe<UserPreferenceFilter>;
};

export type MutationUserRefreshTokenArgs = {
  data: RefreshTokenInput;
};

export type MutationUserRestoreArgs = {
  id: Scalars['String'];
};

export type MutationUserSchoolMembershipCreateArgs = {
  data: UserSchoolMembershipCreateInput;
};

export type MutationUserSchoolMembershipCreateManyArgs = {
  data: Array<InputMaybe<UserSchoolMembershipCreateManyInput>>;
};

export type MutationUserSchoolMembershipDeleteArgs = {
  data?: InputMaybe<UserSchoolMembershipDeleteInput>;
  filter?: InputMaybe<UserSchoolMembershipKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserSchoolMembershipDeleteByFilterArgs = {
  filter: UserSchoolMembershipFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserSchoolMembershipDestroyArgs = {
  filter?: InputMaybe<UserSchoolMembershipKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserSchoolMembershipDestroyByFilterArgs = {
  filter: UserSchoolMembershipFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserSchoolMembershipRestoreArgs = {
  id: Scalars['String'];
};

export type MutationUserSchoolMembershipUpdateArgs = {
  data: UserSchoolMembershipUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserSchoolMembershipKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserSchoolMembershipUpdateByFilterArgs = {
  data: UserSchoolMembershipUpdateByFilterInput;
  filter?: InputMaybe<UserSchoolMembershipFilter>;
};

export type MutationUserSignUpArgs = {
  authProfileId?: InputMaybe<Scalars['ID']>;
  user: UserCreateInput;
};

export type MutationUserSignUpResendArgs = {
  data: SignUpResendInput;
};

export type MutationUserSignUpWithPasswordArgs = {
  authProfileId?: InputMaybe<Scalars['ID']>;
  password: Scalars['String'];
  user: UserCreateInput;
};

export type MutationUserSignUpWithTokenArgs = {
  authProfileId?: InputMaybe<Scalars['ID']>;
  user: UserCreateInput;
};

export type MutationUserSocialCompleteRegistrationArgs = {
  data: UserSocialCompleteRegistrationInput;
};

export type MutationUserStatusUpdateByFilterArgs = {
  data: UserStatusUpdateByFilterInput;
  filter?: InputMaybe<UserStatusFilter>;
};

export type MutationUserSupportersOnlyUpdateByFilterArgs = {
  data: UserSupportersOnlyUpdateByFilterInput;
  filter?: InputMaybe<UserSupportersOnlyFilter>;
};

export type MutationUserUpdateArgs = {
  data: UserUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserUpdateByFilterArgs = {
  data: UserUpdateByFilterInput;
  filter?: InputMaybe<UserFilter>;
};

export type MutationUserUpdateRolesArgs = {
  input: UserUpdateRolesInput;
};

export type MutationUserVerificationEmailResendArgs = {
  authProfileId?: InputMaybe<Scalars['ID']>;
  data: VerificationEmailResendInput;
};

export type MutationUsersCountCreateArgs = {
  data: UsersCountCreateInput;
};

export type MutationUsersCountCreateManyArgs = {
  data: Array<InputMaybe<UsersCountCreateManyInput>>;
};

export type MutationUsersCountDeleteArgs = {
  data?: InputMaybe<UsersCountDeleteInput>;
  filter?: InputMaybe<UsersCountKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUsersCountDeleteByFilterArgs = {
  filter: UsersCountFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUsersCountDestroyArgs = {
  filter?: InputMaybe<UsersCountKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUsersCountDestroyByFilterArgs = {
  filter: UsersCountFilter;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUsersCountRestoreArgs = {
  id: Scalars['String'];
};

export type MutationUsersCountUpdateArgs = {
  data: UsersCountUpdateInput;
  destroyDetached?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UsersCountKeyFilter>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUsersCountUpdateByFilterArgs = {
  data: UsersCountUpdateByFilterInput;
  filter?: InputMaybe<UsersCountFilter>;
};

export type MutationViewCreateArgs = {
  data: ViewCreateInput;
};

export type MutationViewUpdateArgs = {
  data: ViewUpdateInput;
};

export type MutationWorkspaceCreateArgs = {
  data: WorkspaceCreateMutationInput;
};

export type MutationWorkspaceCreateAsyncArgs = {
  data: WorkspaceCreateMutationInput;
};

export type MutationWorkspaceDeleteArgs = {
  data: WorkspaceDeleteMutationInput;
};

export type MutationWorkspaceLeaveArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
};

export type MutationWorkspaceUpdateArgs = {
  data: WorkspaceUpdateMutationInput;
};

export type MutationType = 'create' | 'delete' | 'destroy' | 'update';

export type NotificationStatusType = 'done';

export type NotificationTemplate = {
  __typename?: 'NotificationTemplate';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<RoleListResponse>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userNotifications?: Maybe<UserNotificationListResponse>;
};

export type NotificationTemplateRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleSort>>;
};

export type NotificationTemplateUserNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserNotificationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserNotificationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserNotificationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserNotificationSort>>;
};

/** NotificationTemplates create input */
export type NotificationTemplateCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<NotificationTemplatesRolesRelationInput>;
  title?: InputMaybe<Scalars['String']>;
  userNotifications?: InputMaybe<NotificationTemplatesUserNotificationsRelationInput>;
};

/** NotificationTemplates create many input */
export type NotificationTemplateCreateManyInput = {
  description?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<NotificationTemplatesRolesManyRelationInput>;
  title?: InputMaybe<Scalars['String']>;
  userNotifications?: InputMaybe<NotificationTemplatesUserNotificationsManyRelationInput>;
};

/** NotificationTemplates delete input */
export type NotificationTemplateDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** NotificationTemplateFieldsPermissions create input */
export type NotificationTemplateFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationTemplateFilter = {
  AND?: InputMaybe<Array<NotificationTemplateFilter>>;
  OR?: InputMaybe<Array<NotificationTemplateFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  key?: InputMaybe<StringPredicate>;
  name?: InputMaybe<StringPredicate>;
  roles?: InputMaybe<RoleRelationFilter>;
  title?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userNotifications?: InputMaybe<UserNotificationRelationFilter>;
};

export type NotificationTemplateGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: NotificationTemplateGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type NotificationTemplateGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  key?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  roles?: InputMaybe<RoleGroupByQuery>;
  title?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  userNotifications?: InputMaybe<UserNotificationGroupByQuery>;
};

export type NotificationTemplateKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

/** NotificationTemplateListResponse output */
export type NotificationTemplateListResponse = {
  __typename?: 'NotificationTemplateListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<NotificationTemplate>;
};

/** NotificationTemplateManyResponse output */
export type NotificationTemplateManyResponse = {
  __typename?: 'NotificationTemplateManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<NotificationTemplate>;
};

/** No longer supported. Use `sort` instead. */
export type NotificationTemplateOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'description_ASC'
  | 'description_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'key_ASC'
  | 'key_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'title_ASC'
  | 'title_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** NotificationTemplates subscription payload */
export type NotificationTemplatePayload = {
  __typename?: 'NotificationTemplatePayload';
  mutation: MutationType;
  node?: Maybe<NotificationTemplate>;
  previousValues?: Maybe<NotificationTemplate>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NotificationTemplateRelationFilter = {
  every?: InputMaybe<NotificationTemplateFilter>;
  none?: InputMaybe<NotificationTemplateFilter>;
  some?: InputMaybe<NotificationTemplateFilter>;
};

export type NotificationTemplateSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** NotificationTemplates subscription filter */
export type NotificationTemplateSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<NotificationTemplateFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** NotificationTemplates update input */
export type NotificationTemplateUpdateByFilterInput = {
  description?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  key?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  title?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** NotificationTemplates update input */
export type NotificationTemplateUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<NotificationTemplatesRolesUpdateRelationInput>;
  title?: InputMaybe<Scalars['String']>;
  userNotifications?: InputMaybe<NotificationTemplatesUserNotificationsUpdateRelationInput>;
};

export type NotificationTemplate_PermissionFilter = {
  AND?: InputMaybe<Array<NotificationTemplate_PermissionFilter>>;
  OR?: InputMaybe<Array<NotificationTemplate_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  key?: InputMaybe<StringPredicate>;
  name?: InputMaybe<StringPredicate>;
  roles?: InputMaybe<Role_PermissionRelationFilter>;
  title?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userNotifications?: InputMaybe<UserNotification_PermissionRelationFilter>;
};

export type NotificationTemplate_PermissionRelationFilter = {
  every?: InputMaybe<NotificationTemplate_PermissionFilter>;
  none?: InputMaybe<NotificationTemplate_PermissionFilter>;
  some?: InputMaybe<NotificationTemplate_PermissionFilter>;
};

/** NotificationTemplates relation input */
export type NotificationTemplatesRolesManyRelationInput = {
  connect?: InputMaybe<Array<RoleKeyFilter>>;
};

/** NotificationTemplates relation input */
export type NotificationTemplatesRolesRelationInput = {
  connect?: InputMaybe<Array<RoleKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<NotificationTemplates_RoleCreateInput>>>;
};

/** NotificationTemplates relation input */
export type NotificationTemplatesRolesUpdateRelationInput = {
  connect?: InputMaybe<Array<RoleKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<NotificationTemplates_RoleCreateInput>>>;
  disconnect?: InputMaybe<Array<RoleKeyFilter>>;
  reconnect?: InputMaybe<Array<RoleKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<NotificationTemplates_RoleUpdateInput>>>;
};

/** NotificationTemplates relation input */
export type NotificationTemplatesUserNotificationsManyRelationInput = {
  connect?: InputMaybe<Array<UserNotificationKeyFilter>>;
};

/** NotificationTemplates relation input */
export type NotificationTemplatesUserNotificationsRelationInput = {
  connect?: InputMaybe<Array<UserNotificationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Template_UserNotificationCreateInput>>>;
};

/** NotificationTemplates relation input */
export type NotificationTemplatesUserNotificationsUpdateRelationInput = {
  connect?: InputMaybe<Array<UserNotificationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Template_UserNotificationCreateInput>>>;
  disconnect?: InputMaybe<Array<UserNotificationKeyFilter>>;
  reconnect?: InputMaybe<Array<UserNotificationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Template_UserNotificationUpdateInput>>>;
};

/** Roles create input from notificationTemplates */
export type NotificationTemplates_RoleCreateInput = {
  addon?: InputMaybe<RolesAddonRelationInput>;
  apiTokens?: InputMaybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: InputMaybe<RolesAuthenticationProfilesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notificationTemplates?: InputMaybe<RolesNotificationTemplatesRelationInput>;
  permissions?: InputMaybe<PermissionsInput>;
  teamMembers?: InputMaybe<RolesTeamMembersRelationInput>;
  users?: InputMaybe<RolesUsersRelationInput>;
};

/** Roles update input from notificationTemplates */
export type NotificationTemplates_RoleUpdateInput = {
  data: RoleUpdateInput;
  filter?: InputMaybe<RoleKeyFilter>;
};

/** UserNotificationsByUser create input from notification */
export type Notification_UserNotificationsByUserCreateInput = {
  isRead?: InputMaybe<Scalars['Boolean']>;
  notification?: InputMaybe<UserNotificationsByUserNotificationRelationInput>;
  receiver?: InputMaybe<UserNotificationsByUserReceiverRelationInput>;
};

/** UserNotificationsByUser update input from notification */
export type Notification_UserNotificationsByUserUpdateInput = {
  data: UserNotificationsByUserUpdateInput;
  filter?: InputMaybe<UserNotificationsByUserKeyFilter>;
};

/** Number Field Attributes */
export type NumberFieldTypeAttributes = {
  __typename?: 'NumberFieldTypeAttributes';
  autoIncrement?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  isBigInt?: Maybe<Scalars['Boolean']>;
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Int']>;
};

/** Number Type Format Enum */
export type NumberTypeFormatEnum = 'CURRENCY' | 'FRACTION' | 'NUMBER' | 'PERCENTAGE' | 'SCIENTIFIC';

/** SchoolConfiguration create input from offers */
export type Offers_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from offers */
export type Offers_SchoolConfigurationUpdateInput = {
  data: SchoolConfigurationUpdateInput;
  filter?: InputMaybe<SchoolConfigurationKeyFilter>;
};

export type Organization = {
  __typename?: 'Organization';
  _description?: Maybe<Scalars['String']>;
  activePaymentMethod?: Maybe<Scalars['Boolean']>;
  advertising?: Maybe<Advertising>;
  audiences?: Maybe<AudienceListResponse>;
  billingAds?: Maybe<BillingsAd>;
  billingSchoolSupporter?: Maybe<BillingSchoolSupporter>;
  billingSpecialOffers?: Maybe<BillingSpecialOffer>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  debt?: Maybe<Debt>;
  deletedAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  discounts?: Maybe<DiscountListResponse>;
  eventOtherSponsored?: Maybe<EventListResponse>;
  eventTitleSponsored?: Maybe<EventListResponse>;
  homeFeedViews?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  industry?: Maybe<Scalars['String']>;
  isExperience?: Maybe<Scalars['Boolean']>;
  jobOffers?: Maybe<JobOfferListResponse>;
  likesCount?: Maybe<Scalars['Int']>;
  location?: Maybe<OrganizationLocationListResponse>;
  /** Organization Logo */
  logo?: Maybe<File>;
  managers?: Maybe<OrganizationManagerPreferenceListResponse>;
  /** Pictures of the organization */
  media?: Maybe<FileListResponse>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  paymentErrorStatus?: Maybe<Scalars['Boolean']>;
  schoolSupporter?: Maybe<SchoolSupporterListResponse>;
  sponsorRequests?: Maybe<EventSponsorRequestListResponse>;
  status?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userLikes?: Maybe<OrganizationLikeListResponse>;
};

export type OrganizationAudiencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AudienceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AudienceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AudienceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AudienceSort>>;
};

export type OrganizationDiscountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DiscountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DiscountGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DiscountOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DiscountSort>>;
};

export type OrganizationEventOtherSponsoredArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EventGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EventOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EventSort>>;
};

export type OrganizationEventTitleSponsoredArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EventGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EventOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EventSort>>;
};

export type OrganizationJobOffersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<JobOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<JobOfferGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<JobOfferOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<JobOfferSort>>;
};

export type OrganizationLocationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationLocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationLocationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationLocationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationLocationSort>>;
};

export type OrganizationManagersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationManagerPreferenceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationManagerPreferenceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationManagerPreferenceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationManagerPreferenceSort>>;
};

export type OrganizationMediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FileSort>>;
};

export type OrganizationSchoolSupporterArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolSupporterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolSupporterGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolSupporterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolSupporterSort>>;
};

export type OrganizationSponsorRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EventSponsorRequestFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EventSponsorRequestGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EventSponsorRequestOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EventSponsorRequestSort>>;
};

export type OrganizationUserLikesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationLikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationLikeGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationLikeOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationLikeSort>>;
};

/** Organizations create input */
export type OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations create many input */
export type OrganizationCreateManyInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingManyRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesManyRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsManyRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterManyRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersManyRelationInput>;
  debt?: InputMaybe<OrganizationsDebtManyRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsManyRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredManyRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredManyRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersManyRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationManyRelationInput>;
  logo?: InputMaybe<OrganizationsLogoManyRelationInput>;
  managers?: InputMaybe<OrganizationsManagersManyRelationInput>;
  media?: InputMaybe<OrganizationsMediaManyRelationInput>;
  name: Scalars['String'];
  owner: OrganizationsOwnerManyRelationInput;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterManyRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsManyRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesManyRelationInput>;
};

/** Organizations delete input */
export type OrganizationDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** OrganizationFieldsPermissions create input */
export type OrganizationFieldsPermissions = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  homeFeedViews?: InputMaybe<Scalars['Boolean']>;
  industry?: InputMaybe<Scalars['Boolean']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  likesCount?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  stripeCustomerId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationFilter = {
  AND?: InputMaybe<Array<OrganizationFilter>>;
  OR?: InputMaybe<Array<OrganizationFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  activePaymentMethod?: InputMaybe<BoolPredicate>;
  advertising?: InputMaybe<AdvertisingFilter>;
  audiences?: InputMaybe<AudienceRelationFilter>;
  billingAds?: InputMaybe<BillingsAdFilter>;
  billingSchoolSupporter?: InputMaybe<BillingSchoolSupporterFilter>;
  billingSpecialOffers?: InputMaybe<BillingSpecialOfferFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  debt?: InputMaybe<DebtFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  discounts?: InputMaybe<DiscountRelationFilter>;
  eventOtherSponsored?: InputMaybe<EventRelationFilter>;
  eventTitleSponsored?: InputMaybe<EventRelationFilter>;
  homeFeedViews?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  industry?: InputMaybe<StringPredicate>;
  isExperience?: InputMaybe<BoolPredicate>;
  jobOffers?: InputMaybe<JobOfferRelationFilter>;
  likesCount?: InputMaybe<IntPredicate>;
  location?: InputMaybe<OrganizationLocationRelationFilter>;
  logo?: InputMaybe<FileFilter>;
  managers?: InputMaybe<OrganizationManagerPreferenceRelationFilter>;
  media?: InputMaybe<FileRelationFilter>;
  name?: InputMaybe<StringPredicate>;
  owner?: InputMaybe<UserFilter>;
  paymentErrorStatus?: InputMaybe<BoolPredicate>;
  schoolSupporter?: InputMaybe<SchoolSupporterRelationFilter>;
  sponsorRequests?: InputMaybe<EventSponsorRequestRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  stripeCustomerId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userLikes?: InputMaybe<OrganizationLikeRelationFilter>;
};

export type OrganizationGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: OrganizationGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type OrganizationGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  activePaymentMethod?: InputMaybe<Array<GroupByField>>;
  advertising?: InputMaybe<AdvertisingGroupByQuery>;
  audiences?: InputMaybe<AudienceGroupByQuery>;
  billingAds?: InputMaybe<BillingsAdGroupByQuery>;
  billingSchoolSupporter?: InputMaybe<BillingSchoolSupporterGroupByQuery>;
  billingSpecialOffers?: InputMaybe<BillingSpecialOfferGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  debt?: InputMaybe<DebtGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  discounts?: InputMaybe<DiscountGroupByQuery>;
  eventOtherSponsored?: InputMaybe<EventGroupByQuery>;
  eventTitleSponsored?: InputMaybe<EventGroupByQuery>;
  homeFeedViews?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  industry?: InputMaybe<Array<GroupByField>>;
  isExperience?: InputMaybe<Array<GroupByField>>;
  jobOffers?: InputMaybe<JobOfferGroupByQuery>;
  likesCount?: InputMaybe<Array<GroupByField>>;
  location?: InputMaybe<OrganizationLocationGroupByQuery>;
  logo?: InputMaybe<FileGroupByQuery>;
  managers?: InputMaybe<OrganizationManagerPreferenceGroupByQuery>;
  media?: InputMaybe<FileGroupByQuery>;
  name?: InputMaybe<Array<GroupByField>>;
  owner?: InputMaybe<UserGroupByQuery>;
  paymentErrorStatus?: InputMaybe<Array<GroupByField>>;
  schoolSupporter?: InputMaybe<SchoolSupporterGroupByQuery>;
  sponsorRequests?: InputMaybe<EventSponsorRequestGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  stripeCustomerId?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  userLikes?: InputMaybe<OrganizationLikeGroupByQuery>;
};

export type OrganizationKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

export type OrganizationLike = {
  __typename?: 'OrganizationLike';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  organization?: Maybe<Organization>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

/** OrganizationLikes create input */
export type OrganizationLikeCreateInput = {
  organization?: InputMaybe<OrganizationLikesOrganizationRelationInput>;
  user?: InputMaybe<OrganizationLikesUserRelationInput>;
};

/** OrganizationLikes create many input */
export type OrganizationLikeCreateManyInput = {
  organization?: InputMaybe<OrganizationLikesOrganizationManyRelationInput>;
  user?: InputMaybe<OrganizationLikesUserManyRelationInput>;
};

/** OrganizationLikes delete input */
export type OrganizationLikeDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** OrganizationLikeFieldsPermissions create input */
export type OrganizationLikeFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationLikeFilter = {
  AND?: InputMaybe<Array<OrganizationLikeFilter>>;
  OR?: InputMaybe<Array<OrganizationLikeFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  organization?: InputMaybe<OrganizationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type OrganizationLikeGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: OrganizationLikeGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type OrganizationLikeGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  organization?: InputMaybe<OrganizationGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type OrganizationLikeKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** OrganizationLikeListResponse output */
export type OrganizationLikeListResponse = {
  __typename?: 'OrganizationLikeListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<OrganizationLike>;
};

/** OrganizationLikeManyResponse output */
export type OrganizationLikeManyResponse = {
  __typename?: 'OrganizationLikeManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<OrganizationLike>;
};

/** No longer supported. Use `sort` instead. */
export type OrganizationLikeOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** OrganizationLikes subscription payload */
export type OrganizationLikePayload = {
  __typename?: 'OrganizationLikePayload';
  mutation: MutationType;
  node?: Maybe<OrganizationLike>;
  previousValues?: Maybe<OrganizationLike>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationLikeRelationFilter = {
  every?: InputMaybe<OrganizationLikeFilter>;
  none?: InputMaybe<OrganizationLikeFilter>;
  some?: InputMaybe<OrganizationLikeFilter>;
};

export type OrganizationLikeSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationSort>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

/** OrganizationLikes subscription filter */
export type OrganizationLikeSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<OrganizationLikeFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** OrganizationLikes update input */
export type OrganizationLikeUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  organization?: InputMaybe<OrganizationLikesOrganizationUpdateRelationInput>;
  user?: InputMaybe<OrganizationLikesUserUpdateRelationInput>;
};

export type OrganizationLike_PermissionFilter = {
  AND?: InputMaybe<Array<OrganizationLike_PermissionFilter>>;
  OR?: InputMaybe<Array<OrganizationLike_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  organization?: InputMaybe<Organization_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
};

export type OrganizationLike_PermissionRelationFilter = {
  every?: InputMaybe<OrganizationLike_PermissionFilter>;
  none?: InputMaybe<OrganizationLike_PermissionFilter>;
  some?: InputMaybe<OrganizationLike_PermissionFilter>;
};

/** OrganizationLikes relation input */
export type OrganizationLikesOrganizationManyRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
};

/** OrganizationLikes relation input */
export type OrganizationLikesOrganizationRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<UserLikes_OrganizationCreateInput>;
};

/** OrganizationLikes relation input */
export type OrganizationLikesOrganizationUpdateRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<UserLikes_OrganizationCreateInput>;
  disconnect?: InputMaybe<OrganizationKeyFilter>;
  reconnect?: InputMaybe<OrganizationKeyFilter>;
  update?: InputMaybe<UserLikes_OrganizationUpdateInput>;
};

/** OrganizationLikes relation input */
export type OrganizationLikesUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** OrganizationLikes relation input */
export type OrganizationLikesUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<LikedOrganizations_UserCreateInput>;
};

/** OrganizationLikes relation input */
export type OrganizationLikesUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<LikedOrganizations_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<LikedOrganizations_UserUpdateInput>;
};

/** OrganizationListResponse output */
export type OrganizationListResponse = {
  __typename?: 'OrganizationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Organization>;
};

export type OrganizationLocation = {
  __typename?: 'OrganizationLocation';
  _description?: Maybe<Scalars['String']>;
  address?: Maybe<AddressListResponse>;
  contactName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  operationalHours?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationLocationAddressArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AddressFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AddressGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AddressOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AddressSort>>;
};

/** OrganizationLocations create input */
export type OrganizationLocationCreateInput = {
  address?: InputMaybe<OrganizationLocationsAddressRelationInput>;
  contactName: Scalars['String'];
  email: Scalars['String'];
  link: Scalars['String'];
  locationName: Scalars['String'];
  operationalHours: Scalars['String'];
  organization?: InputMaybe<OrganizationLocationsOrganizationRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** OrganizationLocations create many input */
export type OrganizationLocationCreateManyInput = {
  address?: InputMaybe<OrganizationLocationsAddressManyRelationInput>;
  contactName: Scalars['String'];
  email: Scalars['String'];
  link: Scalars['String'];
  locationName: Scalars['String'];
  operationalHours: Scalars['String'];
  organization: OrganizationLocationsOrganizationManyRelationInput;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** OrganizationLocations delete input */
export type OrganizationLocationDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** OrganizationLocationFieldsPermissions create input */
export type OrganizationLocationFieldsPermissions = {
  contactName?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['Boolean']>;
  locationName?: InputMaybe<Scalars['Boolean']>;
  operationalHours?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationLocationFilter = {
  AND?: InputMaybe<Array<OrganizationLocationFilter>>;
  OR?: InputMaybe<Array<OrganizationLocationFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressRelationFilter>;
  contactName?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  email?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  link?: InputMaybe<StringPredicate>;
  locationName?: InputMaybe<StringPredicate>;
  operationalHours?: InputMaybe<StringPredicate>;
  organization?: InputMaybe<OrganizationFilter>;
  phoneNumber?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type OrganizationLocationGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: OrganizationLocationGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type OrganizationLocationGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  address?: InputMaybe<AddressGroupByQuery>;
  contactName?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  email?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  link?: InputMaybe<Array<GroupByField>>;
  locationName?: InputMaybe<Array<GroupByField>>;
  operationalHours?: InputMaybe<Array<GroupByField>>;
  organization?: InputMaybe<OrganizationGroupByQuery>;
  phoneNumber?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type OrganizationLocationKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** OrganizationLocationListResponse output */
export type OrganizationLocationListResponse = {
  __typename?: 'OrganizationLocationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<OrganizationLocation>;
};

/** OrganizationLocationManyResponse output */
export type OrganizationLocationManyResponse = {
  __typename?: 'OrganizationLocationManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<OrganizationLocation>;
};

/** No longer supported. Use `sort` instead. */
export type OrganizationLocationOrderBy =
  | 'contactName_ASC'
  | 'contactName_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'email_ASC'
  | 'email_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'link_ASC'
  | 'link_DESC'
  | 'locationName_ASC'
  | 'locationName_DESC'
  | 'operationalHours_ASC'
  | 'operationalHours_DESC'
  | 'phoneNumber_ASC'
  | 'phoneNumber_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** OrganizationLocations subscription payload */
export type OrganizationLocationPayload = {
  __typename?: 'OrganizationLocationPayload';
  mutation: MutationType;
  node?: Maybe<OrganizationLocation>;
  previousValues?: Maybe<OrganizationLocation>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationLocationRelationFilter = {
  every?: InputMaybe<OrganizationLocationFilter>;
  none?: InputMaybe<OrganizationLocationFilter>;
  some?: InputMaybe<OrganizationLocationFilter>;
};

export type OrganizationLocationSort = {
  contactName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  locationName?: InputMaybe<SortOrder>;
  operationalHours?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationSort>;
  phoneNumber?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** OrganizationLocations subscription filter */
export type OrganizationLocationSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<OrganizationLocationFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** OrganizationLocations update input */
export type OrganizationLocationUpdateByFilterInput = {
  contactName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  email?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  link?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  locationName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  operationalHours?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  phoneNumber?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** OrganizationLocations update input */
export type OrganizationLocationUpdateInput = {
  address?: InputMaybe<OrganizationLocationsAddressUpdateRelationInput>;
  contactName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<Scalars['String']>;
  locationName?: InputMaybe<Scalars['String']>;
  operationalHours?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationLocationsOrganizationUpdateRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type OrganizationLocation_PermissionFilter = {
  AND?: InputMaybe<Array<OrganizationLocation_PermissionFilter>>;
  OR?: InputMaybe<Array<OrganizationLocation_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Address_PermissionRelationFilter>;
  contactName?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  email?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  link?: InputMaybe<StringPredicate>;
  locationName?: InputMaybe<StringPredicate>;
  operationalHours?: InputMaybe<StringPredicate>;
  organization?: InputMaybe<Organization_PermissionFilter>;
  phoneNumber?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type OrganizationLocation_PermissionRelationFilter = {
  every?: InputMaybe<OrganizationLocation_PermissionFilter>;
  none?: InputMaybe<OrganizationLocation_PermissionFilter>;
  some?: InputMaybe<OrganizationLocation_PermissionFilter>;
};

/** OrganizationLocations relation input */
export type OrganizationLocationsAddressManyRelationInput = {
  connect?: InputMaybe<Array<AddressKeyFilter>>;
};

/** OrganizationLocations relation input */
export type OrganizationLocationsAddressRelationInput = {
  connect?: InputMaybe<Array<AddressKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organizations_AddressCreateInput>>>;
};

/** OrganizationLocations relation input */
export type OrganizationLocationsAddressUpdateRelationInput = {
  connect?: InputMaybe<Array<AddressKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organizations_AddressCreateInput>>>;
  disconnect?: InputMaybe<Array<AddressKeyFilter>>;
  reconnect?: InputMaybe<Array<AddressKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Organizations_AddressUpdateInput>>>;
};

/** OrganizationLocations relation input */
export type OrganizationLocationsOrganizationManyRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
};

/** OrganizationLocations relation input */
export type OrganizationLocationsOrganizationRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<Location_OrganizationCreateInput>;
};

/** OrganizationLocations relation input */
export type OrganizationLocationsOrganizationUpdateRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<Location_OrganizationCreateInput>;
  disconnect?: InputMaybe<OrganizationKeyFilter>;
  reconnect?: InputMaybe<OrganizationKeyFilter>;
  update?: InputMaybe<Location_OrganizationUpdateInput>;
};

export type OrganizationManagerPreference = {
  __typename?: 'OrganizationManagerPreference';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  organizations?: Maybe<OrganizationListResponse>;
  postedJobOffers?: Maybe<JobOfferListResponse>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type OrganizationManagerPreferenceOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationSort>>;
};

export type OrganizationManagerPreferencePostedJobOffersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<JobOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<JobOfferGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<JobOfferOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<JobOfferSort>>;
};

/** OrganizationManagerPreferences create input */
export type OrganizationManagerPreferenceCreateInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  organizations?: InputMaybe<OrganizationManagerPreferencesOrganizationsRelationInput>;
  postedJobOffers?: InputMaybe<OrganizationManagerPreferencesPostedJobOffersRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<OrganizationManagerPreferencesUserRelationInput>;
};

/** OrganizationManagerPreferences create many input */
export type OrganizationManagerPreferenceCreateManyInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  organizations?: InputMaybe<OrganizationManagerPreferencesOrganizationsManyRelationInput>;
  postedJobOffers?: InputMaybe<OrganizationManagerPreferencesPostedJobOffersManyRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user: OrganizationManagerPreferencesUserManyRelationInput;
};

/** OrganizationManagerPreferences delete input */
export type OrganizationManagerPreferenceDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** OrganizationManagerPreferenceFieldsPermissions create input */
export type OrganizationManagerPreferenceFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationManagerPreferenceFilter = {
  AND?: InputMaybe<Array<OrganizationManagerPreferenceFilter>>;
  OR?: InputMaybe<Array<OrganizationManagerPreferenceFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isAdmin?: InputMaybe<BoolPredicate>;
  organizations?: InputMaybe<OrganizationRelationFilter>;
  postedJobOffers?: InputMaybe<JobOfferRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type OrganizationManagerPreferenceGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: OrganizationManagerPreferenceGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type OrganizationManagerPreferenceGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isAdmin?: InputMaybe<Array<GroupByField>>;
  organizations?: InputMaybe<OrganizationGroupByQuery>;
  postedJobOffers?: InputMaybe<JobOfferGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type OrganizationManagerPreferenceKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** OrganizationManagerPreferenceListResponse output */
export type OrganizationManagerPreferenceListResponse = {
  __typename?: 'OrganizationManagerPreferenceListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<OrganizationManagerPreference>;
};

/** OrganizationManagerPreferenceManyResponse output */
export type OrganizationManagerPreferenceManyResponse = {
  __typename?: 'OrganizationManagerPreferenceManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<OrganizationManagerPreference>;
};

/** No longer supported. Use `sort` instead. */
export type OrganizationManagerPreferenceOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isAdmin_ASC'
  | 'isAdmin_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** OrganizationManagerPreferences subscription payload */
export type OrganizationManagerPreferencePayload = {
  __typename?: 'OrganizationManagerPreferencePayload';
  mutation: MutationType;
  node?: Maybe<OrganizationManagerPreference>;
  previousValues?: Maybe<OrganizationManagerPreference>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationManagerPreferenceRelationFilter = {
  every?: InputMaybe<OrganizationManagerPreferenceFilter>;
  none?: InputMaybe<OrganizationManagerPreferenceFilter>;
  some?: InputMaybe<OrganizationManagerPreferenceFilter>;
};

export type OrganizationManagerPreferenceSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

/** OrganizationManagerPreferences subscription filter */
export type OrganizationManagerPreferenceSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<OrganizationManagerPreferenceFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** OrganizationManagerPreferences update input */
export type OrganizationManagerPreferenceUpdateByFilterInput = {
  isAdmin?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
};

/** OrganizationManagerPreferences update input */
export type OrganizationManagerPreferenceUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  organizations?: InputMaybe<OrganizationManagerPreferencesOrganizationsUpdateRelationInput>;
  postedJobOffers?: InputMaybe<OrganizationManagerPreferencesPostedJobOffersUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<OrganizationManagerPreferencesUserUpdateRelationInput>;
};

export type OrganizationManagerPreference_PermissionFilter = {
  AND?: InputMaybe<Array<OrganizationManagerPreference_PermissionFilter>>;
  OR?: InputMaybe<Array<OrganizationManagerPreference_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isAdmin?: InputMaybe<BoolPredicate>;
  organizations?: InputMaybe<Organization_PermissionRelationFilter>;
  postedJobOffers?: InputMaybe<JobOffer_PermissionRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
};

export type OrganizationManagerPreference_PermissionRelationFilter = {
  every?: InputMaybe<OrganizationManagerPreference_PermissionFilter>;
  none?: InputMaybe<OrganizationManagerPreference_PermissionFilter>;
  some?: InputMaybe<OrganizationManagerPreference_PermissionFilter>;
};

/** OrganizationManagerPreferences relation input */
export type OrganizationManagerPreferencesOrganizationsManyRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
};

/** OrganizationManagerPreferences relation input */
export type OrganizationManagerPreferencesOrganizationsRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Managers_OrganizationCreateInput>>>;
};

/** OrganizationManagerPreferences relation input */
export type OrganizationManagerPreferencesOrganizationsUpdateRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Managers_OrganizationCreateInput>>>;
  disconnect?: InputMaybe<Array<OrganizationKeyFilter>>;
  reconnect?: InputMaybe<Array<OrganizationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Managers_OrganizationUpdateInput>>>;
};

/** OrganizationManagerPreferences relation input */
export type OrganizationManagerPreferencesPostedJobOffersManyRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
};

/** OrganizationManagerPreferences relation input */
export type OrganizationManagerPreferencesPostedJobOffersRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<ManagerCreator_JobOfferCreateInput>>>;
};

/** OrganizationManagerPreferences relation input */
export type OrganizationManagerPreferencesPostedJobOffersUpdateRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<ManagerCreator_JobOfferCreateInput>>>;
  disconnect?: InputMaybe<Array<JobOfferKeyFilter>>;
  reconnect?: InputMaybe<Array<JobOfferKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<ManagerCreator_JobOfferUpdateInput>>>;
};

/** OrganizationManagerPreferences relation input */
export type OrganizationManagerPreferencesUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** OrganizationManagerPreferences relation input */
export type OrganizationManagerPreferencesUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<ManagerPreferences_UserCreateInput>;
};

/** OrganizationManagerPreferences relation input */
export type OrganizationManagerPreferencesUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<ManagerPreferences_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<ManagerPreferences_UserUpdateInput>;
};

/** OrganizationManyResponse output */
export type OrganizationManyResponse = {
  __typename?: 'OrganizationManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Organization>;
};

/** No longer supported. Use `sort` instead. */
export type OrganizationOrderBy =
  | 'activePaymentMethod_ASC'
  | 'activePaymentMethod_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'description_ASC'
  | 'description_DESC'
  | 'homeFeedViews_ASC'
  | 'homeFeedViews_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'industry_ASC'
  | 'industry_DESC'
  | 'isExperience_ASC'
  | 'isExperience_DESC'
  | 'likesCount_ASC'
  | 'likesCount_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'paymentErrorStatus_ASC'
  | 'paymentErrorStatus_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'stripeCustomerId_ASC'
  | 'stripeCustomerId_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** Organizations subscription payload */
export type OrganizationPayload = {
  __typename?: 'OrganizationPayload';
  mutation: MutationType;
  node?: Maybe<Organization>;
  previousValues?: Maybe<Organization>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationRelationFilter = {
  every?: InputMaybe<OrganizationFilter>;
  none?: InputMaybe<OrganizationFilter>;
  some?: InputMaybe<OrganizationFilter>;
};

export type OrganizationSort = {
  activePaymentMethod?: InputMaybe<SortOrder>;
  advertising?: InputMaybe<AdvertisingSort>;
  billingAds?: InputMaybe<BillingsAdSort>;
  billingSchoolSupporter?: InputMaybe<BillingSchoolSupporterSort>;
  billingSpecialOffers?: InputMaybe<BillingSpecialOfferSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  debt?: InputMaybe<DebtSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  homeFeedViews?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  industry?: InputMaybe<SortOrder>;
  isExperience?: InputMaybe<SortOrder>;
  likesCount?: InputMaybe<SortOrder>;
  logo?: InputMaybe<FileSort>;
  name?: InputMaybe<SortOrder>;
  owner?: InputMaybe<UserSort>;
  paymentErrorStatus?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripeCustomerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Organizations subscription filter */
export type OrganizationSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<OrganizationFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** Organizations update input */
export type OrganizationUpdateByFilterInput = {
  activePaymentMethod?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  description?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  homeFeedViews?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  industry?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  isExperience?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  likesCount?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  paymentErrorStatus?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  stripeCustomerId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** Organizations update input */
export type OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

export type OrganizationUpgradeResponse = {
  __typename?: 'OrganizationUpgradeResponse';
  organizationId: Scalars['String'];
};

export type OrganizationUserInvitationResponse = {
  __typename?: 'OrganizationUserInvitationResponse';
  invitationId: Scalars['String'];
};

/** Advertising create input from organization */
export type Organization_AdvertisingCreateInput = {
  adsCampaigns?: InputMaybe<AdvertisingAdsCampaignsRelationInput>;
  advertisingSpecialOffers?: InputMaybe<AdvertisingAdvertisingSpecialOffersRelationInput>;
  organization?: InputMaybe<AdvertisingOrganizationRelationInput>;
  supporter?: InputMaybe<AdvertisingSupporterRelationInput>;
};

/** Advertising update input from organization */
export type Organization_AdvertisingUpdateInput = {
  adsCampaigns?: InputMaybe<AdvertisingAdsCampaignsUpdateRelationInput>;
  advertisingSpecialOffers?: InputMaybe<AdvertisingAdvertisingSpecialOffersUpdateRelationInput>;
  organization?: InputMaybe<AdvertisingOrganizationUpdateRelationInput>;
  supporter?: InputMaybe<AdvertisingSupporterUpdateRelationInput>;
};

/** Audiences create input from organization */
export type Organization_AudienceCreateInput = {
  ad?: InputMaybe<AudiencesAdRelationInput>;
  affiliation?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  distanceFromZip?: InputMaybe<Scalars['Int']>;
  endAge?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  groups?: InputMaybe<AudiencesGroupsRelationInput>;
  highSchools?: InputMaybe<AudiencesHighSchoolsRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  organization?: InputMaybe<AudiencesOrganizationRelationInput>;
  schoolCommunities?: InputMaybe<AudiencesSchoolCommunitiesRelationInput>;
  size?: InputMaybe<Scalars['Int']>;
  startAge?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zipCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Audiences update input from organization */
export type Organization_AudienceUpdateInput = {
  data: AudienceUpdateInput;
  filter?: InputMaybe<AudienceKeyFilter>;
};

/** BillingSpecialOffers create input from organization */
export type Organization_BillingSpecialOfferCreateInput = {
  organization?: InputMaybe<BillingSpecialOffersOrganizationRelationInput>;
  registeredSpecialOffers?: InputMaybe<BillingSpecialOffersRegisteredSpecialOffersRelationInput>;
};

/** BillingSpecialOffers update input from organization */
export type Organization_BillingSpecialOfferUpdateInput = {
  organization?: InputMaybe<BillingSpecialOffersOrganizationUpdateRelationInput>;
  registeredSpecialOffers?: InputMaybe<BillingSpecialOffersRegisteredSpecialOffersUpdateRelationInput>;
};

/** BillingsAds create input from organization */
export type Organization_BillingsAdCreateInput = {
  ads?: InputMaybe<BillingsAdsAdsRelationInput>;
  organization?: InputMaybe<BillingsAdsOrganizationRelationInput>;
  registeredBillingAds?: InputMaybe<BillingsAdsRegisteredBillingAdsRelationInput>;
};

/** BillingsAds update input from organization */
export type Organization_BillingsAdUpdateInput = {
  ads?: InputMaybe<BillingsAdsAdsUpdateRelationInput>;
  organization?: InputMaybe<BillingsAdsOrganizationUpdateRelationInput>;
  registeredBillingAds?: InputMaybe<BillingsAdsRegisteredBillingAdsUpdateRelationInput>;
};

/** Debts create input from organization */
export type Organization_DebtCreateInput = {
  amount?: InputMaybe<Scalars['Int']>;
  debtOperationHistories?: InputMaybe<DebtsDebtOperationHistoriesRelationInput>;
  organization?: InputMaybe<DebtsOrganizationRelationInput>;
};

/** Debts update input from organization */
export type Organization_DebtUpdateInput = {
  amount?: InputMaybe<Scalars['Int']>;
  debtOperationHistories?: InputMaybe<DebtsDebtOperationHistoriesUpdateRelationInput>;
  organization?: InputMaybe<DebtsOrganizationUpdateRelationInput>;
};

/** Discounts create input from organization */
export type Organization_DiscountCreateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingRelationInput>;
  amountPercent: Scalars['Int'];
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostRelationInput>;
  expirationDate: Scalars['DateTime'];
  images?: InputMaybe<DiscountsImagesRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationRelationInput>;
  payment?: InputMaybe<DiscountsPaymentRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsRelationInput>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  terms: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

/** Discounts update input from organization */
export type Organization_DiscountUpdateInput = {
  data: DiscountUpdateInput;
  filter?: InputMaybe<DiscountKeyFilter>;
};

/** JobOffers create input from organization */
export type Organization_JobOfferCreateInput = {
  creator?: InputMaybe<JobOffersCreatorRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  engagementCount?: InputMaybe<Scalars['Int']>;
  interestedUsers?: InputMaybe<JobOffersInterestedUsersRelationInput>;
  managerCreator?: InputMaybe<JobOffersManagerCreatorRelationInput>;
  organization?: InputMaybe<JobOffersOrganizationRelationInput>;
  position: Scalars['String'];
  postDate?: InputMaybe<Scalars['DateTime']>;
  schools?: InputMaybe<JobOffersSchoolsRelationInput>;
};

/** JobOffers update input from organization */
export type Organization_JobOfferUpdateInput = {
  data: JobOfferUpdateInput;
  filter?: InputMaybe<JobOfferKeyFilter>;
};

/** OrganizationLikes create input from organization */
export type Organization_OrganizationLikeCreateInput = {
  organization?: InputMaybe<OrganizationLikesOrganizationRelationInput>;
  user?: InputMaybe<OrganizationLikesUserRelationInput>;
};

/** OrganizationLikes update input from organization */
export type Organization_OrganizationLikeUpdateInput = {
  data: OrganizationLikeUpdateInput;
  filter?: InputMaybe<OrganizationLikeKeyFilter>;
};

/** OrganizationLocations create input from organization */
export type Organization_OrganizationLocationCreateInput = {
  address?: InputMaybe<OrganizationLocationsAddressRelationInput>;
  contactName: Scalars['String'];
  email: Scalars['String'];
  link: Scalars['String'];
  locationName: Scalars['String'];
  operationalHours: Scalars['String'];
  organization?: InputMaybe<OrganizationLocationsOrganizationRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** OrganizationLocations update input from organization */
export type Organization_OrganizationLocationUpdateInput = {
  data: OrganizationLocationUpdateInput;
  filter?: InputMaybe<OrganizationLocationKeyFilter>;
};

export type Organization_PermissionFilter = {
  AND?: InputMaybe<Array<Organization_PermissionFilter>>;
  OR?: InputMaybe<Array<Organization_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  activePaymentMethod?: InputMaybe<BoolPredicate>;
  advertising?: InputMaybe<Advertising_PermissionFilter>;
  audiences?: InputMaybe<Audience_PermissionRelationFilter>;
  billingAds?: InputMaybe<BillingsAd_PermissionFilter>;
  billingSchoolSupporter?: InputMaybe<BillingSchoolSupporter_PermissionFilter>;
  billingSpecialOffers?: InputMaybe<BillingSpecialOffer_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  debt?: InputMaybe<Debt_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  discounts?: InputMaybe<Discount_PermissionRelationFilter>;
  eventOtherSponsored?: InputMaybe<Event_PermissionRelationFilter>;
  eventTitleSponsored?: InputMaybe<Event_PermissionRelationFilter>;
  homeFeedViews?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  industry?: InputMaybe<StringPredicate>;
  isExperience?: InputMaybe<BoolPredicate>;
  jobOffers?: InputMaybe<JobOffer_PermissionRelationFilter>;
  likesCount?: InputMaybe<IntPredicate>;
  location?: InputMaybe<OrganizationLocation_PermissionRelationFilter>;
  logo?: InputMaybe<File_PermissionFilter>;
  managers?: InputMaybe<OrganizationManagerPreference_PermissionRelationFilter>;
  media?: InputMaybe<File_PermissionRelationFilter>;
  name?: InputMaybe<StringPredicate>;
  owner?: InputMaybe<User_PermissionFilter>;
  paymentErrorStatus?: InputMaybe<BoolPredicate>;
  schoolSupporter?: InputMaybe<SchoolSupporter_PermissionRelationFilter>;
  sponsorRequests?: InputMaybe<EventSponsorRequest_PermissionRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  stripeCustomerId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userLikes?: InputMaybe<OrganizationLike_PermissionRelationFilter>;
};

export type Organization_PermissionRelationFilter = {
  every?: InputMaybe<Organization_PermissionFilter>;
  none?: InputMaybe<Organization_PermissionFilter>;
  some?: InputMaybe<Organization_PermissionFilter>;
};

/** Organizations relation input */
export type OrganizationsAdvertisingManyRelationInput = {
  connect?: InputMaybe<AdvertisingKeyFilter>;
};

/** Organizations relation input */
export type OrganizationsAdvertisingRelationInput = {
  connect?: InputMaybe<AdvertisingKeyFilter>;
  create?: InputMaybe<Organization_AdvertisingCreateInput>;
};

/** Organizations relation input */
export type OrganizationsAdvertisingUpdateRelationInput = {
  connect?: InputMaybe<AdvertisingKeyFilter>;
  create?: InputMaybe<Organization_AdvertisingCreateInput>;
  disconnect?: InputMaybe<AdvertisingKeyFilter>;
  reconnect?: InputMaybe<AdvertisingKeyFilter>;
  update?: InputMaybe<Organization_AdvertisingUpdateInput>;
};

/** Organizations relation input */
export type OrganizationsAudiencesManyRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
};

/** Organizations relation input */
export type OrganizationsAudiencesRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organization_AudienceCreateInput>>>;
};

/** Organizations relation input */
export type OrganizationsAudiencesUpdateRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organization_AudienceCreateInput>>>;
  disconnect?: InputMaybe<Array<AudienceKeyFilter>>;
  reconnect?: InputMaybe<Array<AudienceKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Organization_AudienceUpdateInput>>>;
};

/** Organizations relation input */
export type OrganizationsBillingAdsManyRelationInput = {
  connect?: InputMaybe<BillingsAdKeyFilter>;
};

/** Organizations relation input */
export type OrganizationsBillingAdsRelationInput = {
  connect?: InputMaybe<BillingsAdKeyFilter>;
  create?: InputMaybe<Organization_BillingsAdCreateInput>;
};

/** Organizations relation input */
export type OrganizationsBillingAdsUpdateRelationInput = {
  connect?: InputMaybe<BillingsAdKeyFilter>;
  create?: InputMaybe<Organization_BillingsAdCreateInput>;
  disconnect?: InputMaybe<BillingsAdKeyFilter>;
  reconnect?: InputMaybe<BillingsAdKeyFilter>;
  update?: InputMaybe<Organization_BillingsAdUpdateInput>;
};

/** Organizations relation input */
export type OrganizationsBillingSchoolSupporterManyRelationInput = {
  connect?: InputMaybe<BillingSchoolSupporterKeyFilter>;
};

/** Organizations relation input */
export type OrganizationsBillingSchoolSupporterRelationInput = {
  connect?: InputMaybe<BillingSchoolSupporterKeyFilter>;
  create?: InputMaybe<Supporter_BillingSchoolSupporterCreateInput>;
};

/** Organizations relation input */
export type OrganizationsBillingSchoolSupporterUpdateRelationInput = {
  connect?: InputMaybe<BillingSchoolSupporterKeyFilter>;
  create?: InputMaybe<Supporter_BillingSchoolSupporterCreateInput>;
  disconnect?: InputMaybe<BillingSchoolSupporterKeyFilter>;
  reconnect?: InputMaybe<BillingSchoolSupporterKeyFilter>;
  update?: InputMaybe<Supporter_BillingSchoolSupporterUpdateInput>;
};

/** Organizations relation input */
export type OrganizationsBillingSpecialOffersManyRelationInput = {
  connect?: InputMaybe<BillingSpecialOfferKeyFilter>;
};

/** Organizations relation input */
export type OrganizationsBillingSpecialOffersRelationInput = {
  connect?: InputMaybe<BillingSpecialOfferKeyFilter>;
  create?: InputMaybe<Organization_BillingSpecialOfferCreateInput>;
};

/** Organizations relation input */
export type OrganizationsBillingSpecialOffersUpdateRelationInput = {
  connect?: InputMaybe<BillingSpecialOfferKeyFilter>;
  create?: InputMaybe<Organization_BillingSpecialOfferCreateInput>;
  disconnect?: InputMaybe<BillingSpecialOfferKeyFilter>;
  reconnect?: InputMaybe<BillingSpecialOfferKeyFilter>;
  update?: InputMaybe<Organization_BillingSpecialOfferUpdateInput>;
};

/** Organizations relation input */
export type OrganizationsDebtManyRelationInput = {
  connect?: InputMaybe<DebtKeyFilter>;
};

/** Organizations relation input */
export type OrganizationsDebtRelationInput = {
  connect?: InputMaybe<DebtKeyFilter>;
  create?: InputMaybe<Organization_DebtCreateInput>;
};

/** Organizations relation input */
export type OrganizationsDebtUpdateRelationInput = {
  connect?: InputMaybe<DebtKeyFilter>;
  create?: InputMaybe<Organization_DebtCreateInput>;
  disconnect?: InputMaybe<DebtKeyFilter>;
  reconnect?: InputMaybe<DebtKeyFilter>;
  update?: InputMaybe<Organization_DebtUpdateInput>;
};

/** Organizations relation input */
export type OrganizationsDiscountsManyRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
};

/** Organizations relation input */
export type OrganizationsDiscountsRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organization_DiscountCreateInput>>>;
};

/** Organizations relation input */
export type OrganizationsDiscountsUpdateRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organization_DiscountCreateInput>>>;
  disconnect?: InputMaybe<Array<DiscountKeyFilter>>;
  reconnect?: InputMaybe<Array<DiscountKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Organization_DiscountUpdateInput>>>;
};

/** Organizations relation input */
export type OrganizationsEventOtherSponsoredManyRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
};

/** Organizations relation input */
export type OrganizationsEventOtherSponsoredRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<OtherSponsors_EventCreateInput>>>;
};

/** Organizations relation input */
export type OrganizationsEventOtherSponsoredUpdateRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<OtherSponsors_EventCreateInput>>>;
  disconnect?: InputMaybe<Array<EventKeyFilter>>;
  reconnect?: InputMaybe<Array<EventKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<OtherSponsors_EventUpdateInput>>>;
};

/** Organizations relation input */
export type OrganizationsEventTitleSponsoredManyRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
};

/** Organizations relation input */
export type OrganizationsEventTitleSponsoredRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<TitleSponsor_EventCreateInput>>>;
};

/** Organizations relation input */
export type OrganizationsEventTitleSponsoredUpdateRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<TitleSponsor_EventCreateInput>>>;
  disconnect?: InputMaybe<Array<EventKeyFilter>>;
  reconnect?: InputMaybe<Array<EventKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<TitleSponsor_EventUpdateInput>>>;
};

/** Organizations relation input */
export type OrganizationsJobOffersManyRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
};

/** Organizations relation input */
export type OrganizationsJobOffersRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organization_JobOfferCreateInput>>>;
};

/** Organizations relation input */
export type OrganizationsJobOffersUpdateRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organization_JobOfferCreateInput>>>;
  disconnect?: InputMaybe<Array<JobOfferKeyFilter>>;
  reconnect?: InputMaybe<Array<JobOfferKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Organization_JobOfferUpdateInput>>>;
};

/** Organizations relation input */
export type OrganizationsLocationManyRelationInput = {
  connect?: InputMaybe<Array<OrganizationLocationKeyFilter>>;
};

/** Organizations relation input */
export type OrganizationsLocationRelationInput = {
  connect?: InputMaybe<Array<OrganizationLocationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organization_OrganizationLocationCreateInput>>>;
};

/** Organizations relation input */
export type OrganizationsLocationUpdateRelationInput = {
  connect?: InputMaybe<Array<OrganizationLocationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organization_OrganizationLocationCreateInput>>>;
  disconnect?: InputMaybe<Array<OrganizationLocationKeyFilter>>;
  reconnect?: InputMaybe<Array<OrganizationLocationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Organization_OrganizationLocationUpdateInput>>>;
};

/** Organizations relation input */
export type OrganizationsLogoManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** Organizations relation input */
export type OrganizationsLogoRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<Organizations_Logo_FileCreateInput>;
};

/** Organizations relation input */
export type OrganizationsLogoUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<Organizations_Logo_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<Organizations_Logo_FileUpdateInput>;
};

/** Organizations relation input */
export type OrganizationsManagersManyRelationInput = {
  connect?: InputMaybe<Array<OrganizationManagerPreferenceKeyFilter>>;
};

/** Organizations relation input */
export type OrganizationsManagersRelationInput = {
  connect?: InputMaybe<Array<OrganizationManagerPreferenceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organizations_OrganizationManagerPreferenceCreateInput>>>;
};

/** Organizations relation input */
export type OrganizationsManagersUpdateRelationInput = {
  connect?: InputMaybe<Array<OrganizationManagerPreferenceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organizations_OrganizationManagerPreferenceCreateInput>>>;
  disconnect?: InputMaybe<Array<OrganizationManagerPreferenceKeyFilter>>;
  reconnect?: InputMaybe<Array<OrganizationManagerPreferenceKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Organizations_OrganizationManagerPreferenceUpdateInput>>>;
};

/** Organizations relation input */
export type OrganizationsMediaManyRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
};

/** Organizations relation input */
export type OrganizationsMediaRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organizations_Media_FileCreateInput>>>;
};

/** Organizations relation input */
export type OrganizationsMediaUpdateRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organizations_Media_FileCreateInput>>>;
  disconnect?: InputMaybe<Array<FileKeyFilter>>;
  reconnect?: InputMaybe<Array<FileKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Organizations_Media_FileUpdateInput>>>;
};

/** Organizations relation input */
export type OrganizationsOwnerManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** Organizations relation input */
export type OrganizationsOwnerRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<OwnedOrganizations_UserCreateInput>;
};

/** Organizations relation input */
export type OrganizationsOwnerUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<OwnedOrganizations_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<OwnedOrganizations_UserUpdateInput>;
};

/** Organizations relation input */
export type OrganizationsSchoolSupporterManyRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterKeyFilter>>;
};

/** Organizations relation input */
export type OrganizationsSchoolSupporterRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Supporter_SchoolSupporterCreateInput>>>;
};

/** Organizations relation input */
export type OrganizationsSchoolSupporterUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Supporter_SchoolSupporterCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolSupporterKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolSupporterKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Supporter_SchoolSupporterUpdateInput>>>;
};

/** Organizations relation input */
export type OrganizationsSponsorRequestsManyRelationInput = {
  connect?: InputMaybe<Array<EventSponsorRequestKeyFilter>>;
};

/** Organizations relation input */
export type OrganizationsSponsorRequestsRelationInput = {
  connect?: InputMaybe<Array<EventSponsorRequestKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Sponsor_EventSponsorRequestCreateInput>>>;
};

/** Organizations relation input */
export type OrganizationsSponsorRequestsUpdateRelationInput = {
  connect?: InputMaybe<Array<EventSponsorRequestKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Sponsor_EventSponsorRequestCreateInput>>>;
  disconnect?: InputMaybe<Array<EventSponsorRequestKeyFilter>>;
  reconnect?: InputMaybe<Array<EventSponsorRequestKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Sponsor_EventSponsorRequestUpdateInput>>>;
};

/** Organizations relation input */
export type OrganizationsUserLikesManyRelationInput = {
  connect?: InputMaybe<Array<OrganizationLikeKeyFilter>>;
};

/** Organizations relation input */
export type OrganizationsUserLikesRelationInput = {
  connect?: InputMaybe<Array<OrganizationLikeKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organization_OrganizationLikeCreateInput>>>;
};

/** Organizations relation input */
export type OrganizationsUserLikesUpdateRelationInput = {
  connect?: InputMaybe<Array<OrganizationLikeKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Organization_OrganizationLikeCreateInput>>>;
  disconnect?: InputMaybe<Array<OrganizationLikeKeyFilter>>;
  reconnect?: InputMaybe<Array<OrganizationLikeKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Organization_OrganizationLikeUpdateInput>>>;
};

/** Addresses create input from organizations */
export type Organizations_AddressCreateInput = {
  city?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<AddressesConfigurationRelationInput>;
  educationalInstitutions?: InputMaybe<AddressesEducationalInstitutionsRelationInput>;
  events?: InputMaybe<AddressesEventsRelationInput>;
  organizations?: InputMaybe<AddressesOrganizationsRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  usersAdresses?: InputMaybe<AddressesUsersAdressesRelationInput>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Addresses update input from organizations */
export type Organizations_AddressUpdateInput = {
  data: AddressUpdateInput;
  filter?: InputMaybe<AddressKeyFilter>;
};

/** OrganizationManagerPreferences create input from organizations */
export type Organizations_OrganizationManagerPreferenceCreateInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  organizations?: InputMaybe<OrganizationManagerPreferencesOrganizationsRelationInput>;
  postedJobOffers?: InputMaybe<OrganizationManagerPreferencesPostedJobOffersRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<OrganizationManagerPreferencesUserRelationInput>;
};

/** OrganizationManagerPreferences update input from organizations */
export type Organizations_OrganizationManagerPreferenceUpdateInput = {
  data: OrganizationManagerPreferenceUpdateInput;
  filter?: InputMaybe<OrganizationManagerPreferenceKeyFilter>;
};

/** Files create input from organizations_logo */
export type Organizations_Logo_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from organizations_logo */
export type Organizations_Logo_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** Files create input from organizations_media */
export type Organizations_Media_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from organizations_media */
export type Organizations_Media_FileUpdateInput = {
  data: FileUpdateInput;
  filter?: InputMaybe<FileKeyFilter>;
};

/** Events create input from otherSponsors */
export type OtherSponsors_EventCreateInput = {
  author?: InputMaybe<EventsAuthorRelationInput>;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<EventsImagesRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<EventsLocationRelationInput>;
  otherSponsors?: InputMaybe<EventsOtherSponsorsRelationInput>;
  school?: InputMaybe<EventsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  sponsorRequests?: InputMaybe<EventsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleSponsor?: InputMaybe<EventsTitleSponsorRelationInput>;
};

/** Events update input from otherSponsors */
export type OtherSponsors_EventUpdateInput = {
  data: EventUpdateInput;
  filter?: InputMaybe<EventKeyFilter>;
};

/** Users create input from ownedOrganizations */
export type OwnedOrganizations_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from ownedOrganizations */
export type OwnedOrganizations_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Organizations create input from owner */
export type Owner_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from owner */
export type Owner_OrganizationUpdateInput = {
  data: OrganizationUpdateInput;
  filter?: InputMaybe<OrganizationKeyFilter>;
};

/** PasswordForgotConfirmInput */
export type PasswordForgotConfirmInput = {
  authProfileId: Scalars['ID'];
  code: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
};

/** PasswordForgotInput */
export type PasswordForgotInput = {
  authProfileId: Scalars['ID'];
  email: Scalars['String'];
};

export type PatternFunctionArguments = {
  escape?: InputMaybe<Scalars['String']>;
  pattern: Scalars['String'];
};

export type PaymentTransaction = {
  __typename?: 'PaymentTransaction';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  discounts?: Maybe<Discount>;
  id?: Maybe<Scalars['ID']>;
  invoiceId?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  /** Stripe method id */
  paymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** PaymentTransactions create input */
export type PaymentTransactionCreateInput = {
  discounts?: InputMaybe<PaymentTransactionsDiscountsRelationInput>;
  invoiceId?: InputMaybe<Scalars['String']>;
  invoiceStatus?: InputMaybe<Scalars['String']>;
  /** Stripe method id */
  paymentMethodId: Scalars['String'];
};

/** PaymentTransactions create many input */
export type PaymentTransactionCreateManyInput = {
  discounts?: InputMaybe<PaymentTransactionsDiscountsManyRelationInput>;
  invoiceId?: InputMaybe<Scalars['String']>;
  invoiceStatus?: InputMaybe<Scalars['String']>;
  /** Stripe method id */
  paymentMethodId: Scalars['String'];
};

/** PaymentTransactions delete input */
export type PaymentTransactionDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** PaymentTransactionFieldsPermissions create input */
export type PaymentTransactionFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  invoiceId?: InputMaybe<Scalars['Boolean']>;
  invoiceStatus?: InputMaybe<Scalars['Boolean']>;
  paymentMethodId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentTransactionFilter = {
  AND?: InputMaybe<Array<PaymentTransactionFilter>>;
  OR?: InputMaybe<Array<PaymentTransactionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  discounts?: InputMaybe<DiscountFilter>;
  id?: InputMaybe<IdPredicate>;
  invoiceId?: InputMaybe<StringPredicate>;
  invoiceStatus?: InputMaybe<StringPredicate>;
  paymentMethodId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type PaymentTransactionGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: PaymentTransactionGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type PaymentTransactionGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  discounts?: InputMaybe<DiscountGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  invoiceId?: InputMaybe<Array<GroupByField>>;
  invoiceStatus?: InputMaybe<Array<GroupByField>>;
  paymentMethodId?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type PaymentTransactionKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** PaymentTransactionListResponse output */
export type PaymentTransactionListResponse = {
  __typename?: 'PaymentTransactionListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<PaymentTransaction>;
};

/** PaymentTransactionManyResponse output */
export type PaymentTransactionManyResponse = {
  __typename?: 'PaymentTransactionManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<PaymentTransaction>;
};

/** No longer supported. Use `sort` instead. */
export type PaymentTransactionOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'invoiceId_ASC'
  | 'invoiceId_DESC'
  | 'invoiceStatus_ASC'
  | 'invoiceStatus_DESC'
  | 'paymentMethodId_ASC'
  | 'paymentMethodId_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** PaymentTransactions subscription payload */
export type PaymentTransactionPayload = {
  __typename?: 'PaymentTransactionPayload';
  mutation: MutationType;
  node?: Maybe<PaymentTransaction>;
  previousValues?: Maybe<PaymentTransaction>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaymentTransactionSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  discounts?: InputMaybe<DiscountSort>;
  id?: InputMaybe<SortOrder>;
  invoiceId?: InputMaybe<SortOrder>;
  invoiceStatus?: InputMaybe<SortOrder>;
  paymentMethodId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** PaymentTransactions subscription filter */
export type PaymentTransactionSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<PaymentTransactionFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** PaymentTransactions update input */
export type PaymentTransactionUpdateByFilterInput = {
  invoiceId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  invoiceStatus?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  paymentMethodId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** PaymentTransactions update input */
export type PaymentTransactionUpdateInput = {
  discounts?: InputMaybe<PaymentTransactionsDiscountsUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  invoiceStatus?: InputMaybe<Scalars['String']>;
  /** Stripe method id */
  paymentMethodId?: InputMaybe<Scalars['String']>;
};

export type PaymentTransaction_PermissionFilter = {
  AND?: InputMaybe<Array<PaymentTransaction_PermissionFilter>>;
  OR?: InputMaybe<Array<PaymentTransaction_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  discounts?: InputMaybe<Discount_PermissionFilter>;
  id?: InputMaybe<IdPredicate>;
  invoiceId?: InputMaybe<StringPredicate>;
  invoiceStatus?: InputMaybe<StringPredicate>;
  paymentMethodId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

/** PaymentTransactions relation input */
export type PaymentTransactionsDiscountsManyRelationInput = {
  connect?: InputMaybe<DiscountKeyFilter>;
};

/** PaymentTransactions relation input */
export type PaymentTransactionsDiscountsRelationInput = {
  connect?: InputMaybe<DiscountKeyFilter>;
  create?: InputMaybe<Payment_DiscountCreateInput>;
};

/** PaymentTransactions relation input */
export type PaymentTransactionsDiscountsUpdateRelationInput = {
  connect?: InputMaybe<DiscountKeyFilter>;
  create?: InputMaybe<Payment_DiscountCreateInput>;
  disconnect?: InputMaybe<DiscountKeyFilter>;
  reconnect?: InputMaybe<DiscountKeyFilter>;
  update?: InputMaybe<Payment_DiscountUpdateInput>;
};

/** Discounts create input from payment */
export type Payment_DiscountCreateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingRelationInput>;
  amountPercent: Scalars['Int'];
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostRelationInput>;
  expirationDate: Scalars['DateTime'];
  images?: InputMaybe<DiscountsImagesRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationRelationInput>;
  payment?: InputMaybe<DiscountsPaymentRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsRelationInput>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  terms: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

/** Discounts update input from payment */
export type Payment_DiscountUpdateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingUpdateRelationInput>;
  amountPercent?: InputMaybe<Scalars['Int']>;
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsUpdateRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostUpdateRelationInput>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<DiscountsImagesUpdateRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationUpdateRelationInput>;
  payment?: InputMaybe<DiscountsPaymentUpdateRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsUpdateRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsUpdateRelationInput>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  terms?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedUpdateRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

export type Permission = {
  __typename?: 'Permission';
  _description?: Maybe<Scalars['String']>;
  appId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  permission?: Maybe<Scalars['JSON']>;
  resource?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionFilter = {
  AND?: InputMaybe<Array<PermissionFilter>>;
  OR?: InputMaybe<Array<PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  appId?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  resource?: InputMaybe<StringPredicate>;
  resourceType?: InputMaybe<StringPredicate>;
  role?: InputMaybe<RoleFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type PermissionGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  appId?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  permission?: InputMaybe<Array<GroupByField>>;
  resource?: InputMaybe<Array<GroupByField>>;
  resourceType?: InputMaybe<Array<GroupByField>>;
  role?: InputMaybe<RoleGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

/** Permission Filter */
export type PermissionInputFilter = {
  action?: InputMaybe<Scalars['String']>;
  applicationName?: InputMaybe<Scalars['String']>;
  resource?: InputMaybe<Scalars['String']>;
  resourceType?: InputMaybe<PermissionResourceTypeEnum>;
};

/** PermissionListResponse output */
export type PermissionListResponse = {
  __typename?: 'PermissionListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Permission>;
};

/** Permissions subscription payload */
export type PermissionPayload = {
  __typename?: 'PermissionPayload';
  mutation: MutationType;
  node?: Maybe<Permission>;
  previousValues?: Maybe<Permission>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PermissionRelationFilter = {
  every?: InputMaybe<PermissionFilter>;
  none?: InputMaybe<PermissionFilter>;
  some?: InputMaybe<PermissionFilter>;
};

export type PermissionResourceTypeEnum = 'custom' | 'data';

/** Permissions subscription filter */
export type PermissionSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<PermissionFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

export type Permission_PermissionFilter = {
  AND?: InputMaybe<Array<Permission_PermissionFilter>>;
  OR?: InputMaybe<Array<Permission_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  appId?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  resource?: InputMaybe<StringPredicate>;
  resourceType?: InputMaybe<StringPredicate>;
  role?: InputMaybe<Role_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type Permission_PermissionRelationFilter = {
  every?: InputMaybe<Permission_PermissionFilter>;
  none?: InputMaybe<Permission_PermissionFilter>;
  some?: InputMaybe<Permission_PermissionFilter>;
};

/** custom permissions input */
export type PermissionsCustom = {
  api?: InputMaybe<PermissionsCustomApi>;
  data?: InputMaybe<PermissionsCustomData>;
  logic?: InputMaybe<PermissionsCustomLogic>;
  settings?: InputMaybe<PermissionsCustomSettings>;
  users?: InputMaybe<PermissionsCustomUsers>;
};

export type PermissionsCustomApi = {
  acceptSchoolMembers?: InputMaybe<PermissionsCustomApiResolver>;
  activateSchoolMembers?: InputMaybe<PermissionsCustomApiResolver>;
  businessFilterByZipRequest?: InputMaybe<PermissionsCustomApiResolver>;
  careersFilterByZipRequest?: InputMaybe<PermissionsCustomApiResolver>;
  checkResetPasswordCode?: InputMaybe<PermissionsCustomApiResolver>;
  claimOrganization?: InputMaybe<PermissionsCustomApiResolver>;
  contactOrganizationRequest?: InputMaybe<PermissionsCustomApiResolver>;
  contentEngagementReportsList?: InputMaybe<PermissionsCustomApiResolver>;
  currentLocationZipRequest?: InputMaybe<PermissionsCustomApiResolver>;
  discountCreateRequest?: InputMaybe<PermissionsCustomApiResolver>;
  discountUpdateStatusRequest?: InputMaybe<PermissionsCustomApiResolver>;
  eventSponsorCreateRequest?: InputMaybe<PermissionsCustomApiResolver>;
  eventStatusUpdateRequest?: InputMaybe<PermissionsCustomApiResolver>;
  eventsFilterByZipRequest?: InputMaybe<PermissionsCustomApiResolver>;
  flagPostRequest?: InputMaybe<PermissionsCustomApiResolver>;
  flaggedPostApproveRequest?: InputMaybe<PermissionsCustomApiResolver>;
  flaggedPostDeleteRequest?: InputMaybe<PermissionsCustomApiResolver>;
  generateLinkPreview?: InputMaybe<PermissionsCustomApiResolver>;
  groupCreateRequest?: InputMaybe<PermissionsCustomApiResolver>;
  groupDetailsUpdateRequest?: InputMaybe<PermissionsCustomApiResolver>;
  groupMemberDemote?: InputMaybe<PermissionsCustomApiResolver>;
  groupMemberPromote?: InputMaybe<PermissionsCustomApiResolver>;
  groupMemberRemove?: InputMaybe<PermissionsCustomApiResolver>;
  groupMembersRequestUpsert?: InputMaybe<PermissionsCustomApiResolver>;
  groupRejectRequest?: InputMaybe<PermissionsCustomApiResolver>;
  inactivateSchoolMembers?: InputMaybe<PermissionsCustomApiResolver>;
  managerAcceptRequest?: InputMaybe<PermissionsCustomApiResolver>;
  messageSendRequest?: InputMaybe<PermissionsCustomApiResolver>;
  needHelpSendRequest?: InputMaybe<PermissionsCustomApiResolver>;
  postModerateRequest?: InputMaybe<PermissionsCustomApiResolver>;
  promoteSchoolMember?: InputMaybe<PermissionsCustomApiResolver>;
  redeemDiscount?: InputMaybe<PermissionsCustomApiResolver>;
  rejectSchoolMembers?: InputMaybe<PermissionsCustomApiResolver>;
  renewJobOffer?: InputMaybe<PermissionsCustomApiResolver>;
  requestBusinessAccess?: InputMaybe<PermissionsCustomApiResolver>;
  requestCommunityAccess?: InputMaybe<PermissionsCustomApiResolver>;
  resendSchoolApprovals?: InputMaybe<PermissionsCustomApiResolver>;
  resetUserCredentials?: InputMaybe<PermissionsCustomApiResolver>;
  schoolMembersReport?: InputMaybe<PermissionsCustomApiResolver>;
  sendBusinessAdministratorInvites?: InputMaybe<PermissionsCustomApiResolver>;
  sendBusinessManagerInvites?: InputMaybe<PermissionsCustomApiResolver>;
  sendInboxMessageNotificationRequest?: InputMaybe<PermissionsCustomApiResolver>;
  sendPostReactionNotificationRequest?: InputMaybe<PermissionsCustomApiResolver>;
  sendSchoolAdministratorInvites?: InputMaybe<PermissionsCustomApiResolver>;
  sendSchoolMemberInvites?: InputMaybe<PermissionsCustomApiResolver>;
  streamChatAuthorize?: InputMaybe<PermissionsCustomApiResolver>;
  streamChatGetReactions?: InputMaybe<PermissionsCustomApiResolver>;
  stripeCreatePaymentIntent?: InputMaybe<PermissionsCustomApiResolver>;
  stripeDetachPaymentMethod?: InputMaybe<PermissionsCustomApiResolver>;
  stripeGetBillingTransactionsList?: InputMaybe<PermissionsCustomApiResolver>;
  stripeGetCustomerPaymentMethods?: InputMaybe<PermissionsCustomApiResolver>;
  stripeUpdateCustomerDefaultPaymentMethod?: InputMaybe<PermissionsCustomApiResolver>;
  updateHomeFeedPostRequest?: InputMaybe<PermissionsCustomApiResolver>;
  updatePost?: InputMaybe<PermissionsCustomApiResolver>;
  userAuthorize?: InputMaybe<PermissionsCustomApiResolver>;
  userCompleteRegistration?: InputMaybe<PermissionsCustomApiResolver>;
  userEmailChangeRequest?: InputMaybe<PermissionsCustomApiResolver>;
  userForgotPasswordChange?: InputMaybe<PermissionsCustomApiResolver>;
  userForgotPasswordEmailSend?: InputMaybe<PermissionsCustomApiResolver>;
  userInviteMembersToGroup?: InputMaybe<PermissionsCustomApiResolver>;
  userPasswordChangeRequest?: InputMaybe<PermissionsCustomApiResolver>;
  userSocialCompleteRegistration?: InputMaybe<PermissionsCustomApiResolver>;
  userUpdateRoles?: InputMaybe<PermissionsCustomApiResolver>;
};

export type PermissionsCustomApiResolver = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomData = {
  schemaManagement?: InputMaybe<PermissionsCustomDataSchemaManagement>;
  viewerAccess?: InputMaybe<PermissionsCustomDataViewerAccess>;
};

export type PermissionsCustomDataSchemaManagement = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomDataViewerAccess = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomLogic = {
  deploy?: InputMaybe<PermissionsCustomLogicDeploy>;
  invoke?: InputMaybe<PermissionsCustomLogicInvoke>;
  logs?: InputMaybe<PermissionsCustomLogicLogs>;
  view?: InputMaybe<PermissionsCustomLogicView>;
};

export type PermissionsCustomLogicDeploy = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomLogicInvoke = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomLogicLogs = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomLogicView = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomSettings = {
  workspaceAdministration?: InputMaybe<PermissionsCustomSettingsWorkspaceAdministration>;
};

export type PermissionsCustomSettingsWorkspaceAdministration = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomUsers = {
  teamAdministration?: InputMaybe<PermissionsCustomUsersTeamAdministration>;
};

export type PermissionsCustomUsersTeamAdministration = {
  allow: Scalars['Boolean'];
};

/** Schema tables permissions input */
export type PermissionsData = {
  AdClicks?: InputMaybe<PermissionsDataAdClicks>;
  AdViews?: InputMaybe<PermissionsDataAdViews>;
  Addresses?: InputMaybe<PermissionsDataAddresses>;
  Advertising?: InputMaybe<PermissionsDataAdvertising>;
  AdvertisingCampaignAds?: InputMaybe<PermissionsDataAdvertisingCampaignAds>;
  AdvertisingSampaigns?: InputMaybe<PermissionsDataAdvertisingSampaigns>;
  AdvertisingSupporter?: InputMaybe<PermissionsDataAdvertisingSupporter>;
  Audiences?: InputMaybe<PermissionsDataAudiences>;
  BillingRegisteredAds?: InputMaybe<PermissionsDataBillingRegisteredAds>;
  BillingRegisteredSchoolSupporters?: InputMaybe<PermissionsDataBillingRegisteredSchoolSupporters>;
  BillingRegisteredSpecialOffers?: InputMaybe<PermissionsDataBillingRegisteredSpecialOffers>;
  BillingSchoolSupporters?: InputMaybe<PermissionsDataBillingSchoolSupporters>;
  BillingSpecialOffers?: InputMaybe<PermissionsDataBillingSpecialOffers>;
  BillingsAds?: InputMaybe<PermissionsDataBillingsAds>;
  BusinessReport?: InputMaybe<PermissionsDataBusinessReport>;
  CiCdMigrations?: InputMaybe<PermissionsDataCiCdMigrations>;
  CollectiveIntelligenceThreads?: InputMaybe<PermissionsDataCollectiveIntelligenceThreads>;
  ContentReport?: InputMaybe<PermissionsDataContentReport>;
  Dashboard?: InputMaybe<PermissionsDataDashboard>;
  DebtOperationHistories?: InputMaybe<PermissionsDataDebtOperationHistories>;
  Debts?: InputMaybe<PermissionsDataDebts>;
  DiscountRedemptions?: InputMaybe<PermissionsDataDiscountRedemptions>;
  Discounts?: InputMaybe<PermissionsDataDiscounts>;
  EducationalInstitutions?: InputMaybe<PermissionsDataEducationalInstitutions>;
  EventSponsorRequests?: InputMaybe<PermissionsDataEventSponsorRequests>;
  Events?: InputMaybe<PermissionsDataEvents>;
  Files?: InputMaybe<PermissionsDataFiles>;
  FilesSettings?: InputMaybe<PermissionsDataFilesSettings>;
  GraduatingYears?: InputMaybe<PermissionsDataGraduatingYears>;
  GraduatingYearsMetrics?: InputMaybe<PermissionsDataGraduatingYearsMetrics>;
  GroupAdminPreferences?: InputMaybe<PermissionsDataGroupAdminPreferences>;
  GroupPosts?: InputMaybe<PermissionsDataGroupPosts>;
  Groups?: InputMaybe<PermissionsDataGroups>;
  GroupsMembersRequests?: InputMaybe<PermissionsDataGroupsMembersRequests>;
  GroupsStats?: InputMaybe<PermissionsDataGroupsStats>;
  HighSchoolLocations?: InputMaybe<PermissionsDataHighSchoolLocations>;
  HomeFeedItems?: InputMaybe<PermissionsDataHomeFeedItems>;
  HomeFeedPosts?: InputMaybe<PermissionsDataHomeFeedPosts>;
  Inbox?: InputMaybe<PermissionsDataInbox>;
  InboxMessageMedia?: InputMaybe<PermissionsDataInboxMessageMedia>;
  InboxMessages?: InputMaybe<PermissionsDataInboxMessages>;
  JobEmployees?: InputMaybe<PermissionsDataJobEmployees>;
  JobOffers?: InputMaybe<PermissionsDataJobOffers>;
  LegacyFeedMonths?: InputMaybe<PermissionsDataLegacyFeedMonths>;
  LegacyFeedPosts?: InputMaybe<PermissionsDataLegacyFeedPosts>;
  LegacyFeedYears?: InputMaybe<PermissionsDataLegacyFeedYears>;
  NotificationTemplates?: InputMaybe<PermissionsDataNotificationTemplates>;
  OrganizationLikes?: InputMaybe<PermissionsDataOrganizationLikes>;
  OrganizationLocations?: InputMaybe<PermissionsDataOrganizationLocations>;
  OrganizationManagerPreferences?: InputMaybe<PermissionsDataOrganizationManagerPreferences>;
  Organizations?: InputMaybe<PermissionsDataOrganizations>;
  PaymentTransactions?: InputMaybe<PermissionsDataPaymentTransactions>;
  PostMentions?: InputMaybe<PermissionsDataPostMentions>;
  RemoteVariables?: InputMaybe<PermissionsDataRemoteVariables>;
  RoleAddon?: InputMaybe<PermissionsDataRoleAddon>;
  Roles?: InputMaybe<PermissionsDataRoles>;
  SchoolConfiguration?: InputMaybe<PermissionsDataSchoolConfiguration>;
  SchoolConfigurationColorSchemes?: InputMaybe<PermissionsDataSchoolConfigurationColorSchemes>;
  SchoolConfigurationExternalLinks?: InputMaybe<PermissionsDataSchoolConfigurationExternalLinks>;
  SchoolConfigurationImages?: InputMaybe<PermissionsDataSchoolConfigurationImages>;
  SchoolConfigurationLayouts?: InputMaybe<PermissionsDataSchoolConfigurationLayouts>;
  SchoolConfigurationTopBar?: InputMaybe<PermissionsDataSchoolConfigurationTopBar>;
  SchoolConfigurationWordsBlacklist?: InputMaybe<PermissionsDataSchoolConfigurationWordsBlacklist>;
  SchoolNewsPosts?: InputMaybe<PermissionsDataSchoolNewsPosts>;
  SchoolSupporter?: InputMaybe<PermissionsDataSchoolSupporter>;
  SchoolSupporterClicks?: InputMaybe<PermissionsDataSchoolSupporterClicks>;
  SchoolSupporterSearches?: InputMaybe<PermissionsDataSchoolSupporterSearches>;
  StripeInformations?: InputMaybe<PermissionsDataStripeInformations>;
  TeamInvitations?: InputMaybe<PermissionsDataTeamInvitations>;
  TeamMembers?: InputMaybe<PermissionsDataTeamMembers>;
  TrendingSearches?: InputMaybe<PermissionsDataTrendingSearches>;
  UserConfiguration?: InputMaybe<PermissionsDataUserConfiguration>;
  UserNotifications?: InputMaybe<PermissionsDataUserNotifications>;
  UserNotificationsByUser?: InputMaybe<PermissionsDataUserNotificationsByUser>;
  UserPreferences?: InputMaybe<PermissionsDataUserPreferences>;
  UserSchoolMembership?: InputMaybe<PermissionsDataUserSchoolMembership>;
  UserStatus?: InputMaybe<PermissionsDataUserStatus>;
  UserSupportersOnly?: InputMaybe<PermissionsDataUserSupportersOnly>;
  Users?: InputMaybe<PermissionsDataUsers>;
  UsersCount?: InputMaybe<PermissionsDataUsersCount>;
};

export type PermissionsDataAdClicks = {
  create?: InputMaybe<PermissionsDataAdClicksCreate>;
  delete?: InputMaybe<PermissionsDataAdClicksDelete>;
  destroy?: InputMaybe<PermissionsDataAdClicksDestroy>;
  read?: InputMaybe<PermissionsDataAdClicksRead>;
  update?: InputMaybe<PermissionsDataAdClicksUpdate>;
};

export type PermissionsDataAdClicksCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAdClicksDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataAdClicksDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAdClicksRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AdClickFieldsPermissions>;
  filter?: InputMaybe<AdClick_PermissionFilter>;
};

export type PermissionsDataAdClicksUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AdClickFieldsPermissions>;
  filter?: InputMaybe<AdClick_PermissionFilter>;
};

export type PermissionsDataAdViews = {
  create?: InputMaybe<PermissionsDataAdViewsCreate>;
  delete?: InputMaybe<PermissionsDataAdViewsDelete>;
  destroy?: InputMaybe<PermissionsDataAdViewsDestroy>;
  read?: InputMaybe<PermissionsDataAdViewsRead>;
  update?: InputMaybe<PermissionsDataAdViewsUpdate>;
};

export type PermissionsDataAdViewsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAdViewsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataAdViewsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAdViewsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AdViewFieldsPermissions>;
  filter?: InputMaybe<AdView_PermissionFilter>;
};

export type PermissionsDataAdViewsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AdViewFieldsPermissions>;
  filter?: InputMaybe<AdView_PermissionFilter>;
};

export type PermissionsDataAddresses = {
  create?: InputMaybe<PermissionsDataAddressesCreate>;
  delete?: InputMaybe<PermissionsDataAddressesDelete>;
  destroy?: InputMaybe<PermissionsDataAddressesDestroy>;
  read?: InputMaybe<PermissionsDataAddressesRead>;
  update?: InputMaybe<PermissionsDataAddressesUpdate>;
};

export type PermissionsDataAddressesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAddressesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataAddressesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAddressesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AddressFieldsPermissions>;
  filter?: InputMaybe<Address_PermissionFilter>;
};

export type PermissionsDataAddressesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AddressFieldsPermissions>;
  filter?: InputMaybe<Address_PermissionFilter>;
};

export type PermissionsDataAdvertising = {
  create?: InputMaybe<PermissionsDataAdvertisingCreate>;
  delete?: InputMaybe<PermissionsDataAdvertisingDelete>;
  destroy?: InputMaybe<PermissionsDataAdvertisingDestroy>;
  read?: InputMaybe<PermissionsDataAdvertisingRead>;
  update?: InputMaybe<PermissionsDataAdvertisingUpdate>;
};

export type PermissionsDataAdvertisingCampaignAds = {
  create?: InputMaybe<PermissionsDataAdvertisingCampaignAdsCreate>;
  delete?: InputMaybe<PermissionsDataAdvertisingCampaignAdsDelete>;
  destroy?: InputMaybe<PermissionsDataAdvertisingCampaignAdsDestroy>;
  read?: InputMaybe<PermissionsDataAdvertisingCampaignAdsRead>;
  update?: InputMaybe<PermissionsDataAdvertisingCampaignAdsUpdate>;
};

export type PermissionsDataAdvertisingCampaignAdsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAdvertisingCampaignAdsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataAdvertisingCampaignAdsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAdvertisingCampaignAdsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AdvertisingCampaignAdFieldsPermissions>;
  filter?: InputMaybe<AdvertisingCampaignAd_PermissionFilter>;
};

export type PermissionsDataAdvertisingCampaignAdsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AdvertisingCampaignAdFieldsPermissions>;
  filter?: InputMaybe<AdvertisingCampaignAd_PermissionFilter>;
};

export type PermissionsDataAdvertisingCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAdvertisingDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataAdvertisingDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAdvertisingRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AdvertisingFieldsPermissions>;
  filter?: InputMaybe<Advertising_PermissionFilter>;
};

export type PermissionsDataAdvertisingSampaigns = {
  create?: InputMaybe<PermissionsDataAdvertisingSampaignsCreate>;
  delete?: InputMaybe<PermissionsDataAdvertisingSampaignsDelete>;
  destroy?: InputMaybe<PermissionsDataAdvertisingSampaignsDestroy>;
  read?: InputMaybe<PermissionsDataAdvertisingSampaignsRead>;
  update?: InputMaybe<PermissionsDataAdvertisingSampaignsUpdate>;
};

export type PermissionsDataAdvertisingSampaignsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAdvertisingSampaignsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataAdvertisingSampaignsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAdvertisingSampaignsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AdvertisingSampaignFieldsPermissions>;
  filter?: InputMaybe<AdvertisingSampaign_PermissionFilter>;
};

export type PermissionsDataAdvertisingSampaignsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AdvertisingSampaignFieldsPermissions>;
  filter?: InputMaybe<AdvertisingSampaign_PermissionFilter>;
};

export type PermissionsDataAdvertisingSupporter = {
  create?: InputMaybe<PermissionsDataAdvertisingSupporterCreate>;
  delete?: InputMaybe<PermissionsDataAdvertisingSupporterDelete>;
  destroy?: InputMaybe<PermissionsDataAdvertisingSupporterDestroy>;
  read?: InputMaybe<PermissionsDataAdvertisingSupporterRead>;
  update?: InputMaybe<PermissionsDataAdvertisingSupporterUpdate>;
};

export type PermissionsDataAdvertisingSupporterCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAdvertisingSupporterDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataAdvertisingSupporterDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAdvertisingSupporterRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AdvertisingSupporterFieldsPermissions>;
  filter?: InputMaybe<AdvertisingSupporter_PermissionFilter>;
};

export type PermissionsDataAdvertisingSupporterUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AdvertisingSupporterFieldsPermissions>;
  filter?: InputMaybe<AdvertisingSupporter_PermissionFilter>;
};

export type PermissionsDataAdvertisingUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AdvertisingFieldsPermissions>;
  filter?: InputMaybe<Advertising_PermissionFilter>;
};

export type PermissionsDataAudiences = {
  create?: InputMaybe<PermissionsDataAudiencesCreate>;
  delete?: InputMaybe<PermissionsDataAudiencesDelete>;
  destroy?: InputMaybe<PermissionsDataAudiencesDestroy>;
  read?: InputMaybe<PermissionsDataAudiencesRead>;
  update?: InputMaybe<PermissionsDataAudiencesUpdate>;
};

export type PermissionsDataAudiencesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAudiencesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataAudiencesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAudiencesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AudienceFieldsPermissions>;
  filter?: InputMaybe<Audience_PermissionFilter>;
};

export type PermissionsDataAudiencesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<AudienceFieldsPermissions>;
  filter?: InputMaybe<Audience_PermissionFilter>;
};

export type PermissionsDataBillingRegisteredAds = {
  create?: InputMaybe<PermissionsDataBillingRegisteredAdsCreate>;
  delete?: InputMaybe<PermissionsDataBillingRegisteredAdsDelete>;
  destroy?: InputMaybe<PermissionsDataBillingRegisteredAdsDestroy>;
  read?: InputMaybe<PermissionsDataBillingRegisteredAdsRead>;
  update?: InputMaybe<PermissionsDataBillingRegisteredAdsUpdate>;
};

export type PermissionsDataBillingRegisteredAdsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBillingRegisteredAdsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataBillingRegisteredAdsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBillingRegisteredAdsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BillingRegisteredAdFieldsPermissions>;
  filter?: InputMaybe<BillingRegisteredAd_PermissionFilter>;
};

export type PermissionsDataBillingRegisteredAdsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BillingRegisteredAdFieldsPermissions>;
  filter?: InputMaybe<BillingRegisteredAd_PermissionFilter>;
};

export type PermissionsDataBillingRegisteredSchoolSupporters = {
  create?: InputMaybe<PermissionsDataBillingRegisteredSchoolSupportersCreate>;
  delete?: InputMaybe<PermissionsDataBillingRegisteredSchoolSupportersDelete>;
  destroy?: InputMaybe<PermissionsDataBillingRegisteredSchoolSupportersDestroy>;
  read?: InputMaybe<PermissionsDataBillingRegisteredSchoolSupportersRead>;
  update?: InputMaybe<PermissionsDataBillingRegisteredSchoolSupportersUpdate>;
};

export type PermissionsDataBillingRegisteredSchoolSupportersCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBillingRegisteredSchoolSupportersDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataBillingRegisteredSchoolSupportersDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBillingRegisteredSchoolSupportersRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BillingRegisteredSchoolSupporterFieldsPermissions>;
  filter?: InputMaybe<BillingRegisteredSchoolSupporter_PermissionFilter>;
};

export type PermissionsDataBillingRegisteredSchoolSupportersUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BillingRegisteredSchoolSupporterFieldsPermissions>;
  filter?: InputMaybe<BillingRegisteredSchoolSupporter_PermissionFilter>;
};

export type PermissionsDataBillingRegisteredSpecialOffers = {
  create?: InputMaybe<PermissionsDataBillingRegisteredSpecialOffersCreate>;
  delete?: InputMaybe<PermissionsDataBillingRegisteredSpecialOffersDelete>;
  destroy?: InputMaybe<PermissionsDataBillingRegisteredSpecialOffersDestroy>;
  read?: InputMaybe<PermissionsDataBillingRegisteredSpecialOffersRead>;
  update?: InputMaybe<PermissionsDataBillingRegisteredSpecialOffersUpdate>;
};

export type PermissionsDataBillingRegisteredSpecialOffersCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBillingRegisteredSpecialOffersDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataBillingRegisteredSpecialOffersDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBillingRegisteredSpecialOffersRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BillingRegisteredSpecialOfferFieldsPermissions>;
  filter?: InputMaybe<BillingRegisteredSpecialOffer_PermissionFilter>;
};

export type PermissionsDataBillingRegisteredSpecialOffersUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BillingRegisteredSpecialOfferFieldsPermissions>;
  filter?: InputMaybe<BillingRegisteredSpecialOffer_PermissionFilter>;
};

export type PermissionsDataBillingSchoolSupporters = {
  create?: InputMaybe<PermissionsDataBillingSchoolSupportersCreate>;
  delete?: InputMaybe<PermissionsDataBillingSchoolSupportersDelete>;
  destroy?: InputMaybe<PermissionsDataBillingSchoolSupportersDestroy>;
  read?: InputMaybe<PermissionsDataBillingSchoolSupportersRead>;
  update?: InputMaybe<PermissionsDataBillingSchoolSupportersUpdate>;
};

export type PermissionsDataBillingSchoolSupportersCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBillingSchoolSupportersDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataBillingSchoolSupportersDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBillingSchoolSupportersRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BillingSchoolSupporterFieldsPermissions>;
  filter?: InputMaybe<BillingSchoolSupporter_PermissionFilter>;
};

export type PermissionsDataBillingSchoolSupportersUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BillingSchoolSupporterFieldsPermissions>;
  filter?: InputMaybe<BillingSchoolSupporter_PermissionFilter>;
};

export type PermissionsDataBillingSpecialOffers = {
  create?: InputMaybe<PermissionsDataBillingSpecialOffersCreate>;
  delete?: InputMaybe<PermissionsDataBillingSpecialOffersDelete>;
  destroy?: InputMaybe<PermissionsDataBillingSpecialOffersDestroy>;
  read?: InputMaybe<PermissionsDataBillingSpecialOffersRead>;
  update?: InputMaybe<PermissionsDataBillingSpecialOffersUpdate>;
};

export type PermissionsDataBillingSpecialOffersCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBillingSpecialOffersDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataBillingSpecialOffersDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBillingSpecialOffersRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BillingSpecialOfferFieldsPermissions>;
  filter?: InputMaybe<BillingSpecialOffer_PermissionFilter>;
};

export type PermissionsDataBillingSpecialOffersUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BillingSpecialOfferFieldsPermissions>;
  filter?: InputMaybe<BillingSpecialOffer_PermissionFilter>;
};

export type PermissionsDataBillingsAds = {
  create?: InputMaybe<PermissionsDataBillingsAdsCreate>;
  delete?: InputMaybe<PermissionsDataBillingsAdsDelete>;
  destroy?: InputMaybe<PermissionsDataBillingsAdsDestroy>;
  read?: InputMaybe<PermissionsDataBillingsAdsRead>;
  update?: InputMaybe<PermissionsDataBillingsAdsUpdate>;
};

export type PermissionsDataBillingsAdsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBillingsAdsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataBillingsAdsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBillingsAdsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BillingsAdFieldsPermissions>;
  filter?: InputMaybe<BillingsAd_PermissionFilter>;
};

export type PermissionsDataBillingsAdsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BillingsAdFieldsPermissions>;
  filter?: InputMaybe<BillingsAd_PermissionFilter>;
};

export type PermissionsDataBusinessReport = {
  create?: InputMaybe<PermissionsDataBusinessReportCreate>;
  delete?: InputMaybe<PermissionsDataBusinessReportDelete>;
  destroy?: InputMaybe<PermissionsDataBusinessReportDestroy>;
  read?: InputMaybe<PermissionsDataBusinessReportRead>;
  update?: InputMaybe<PermissionsDataBusinessReportUpdate>;
};

export type PermissionsDataBusinessReportCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBusinessReportDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataBusinessReportDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataBusinessReportRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BusinessReportFieldsPermissions>;
  filter?: InputMaybe<BusinessReport_PermissionFilter>;
};

export type PermissionsDataBusinessReportUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<BusinessReportFieldsPermissions>;
  filter?: InputMaybe<BusinessReport_PermissionFilter>;
};

export type PermissionsDataCiCdMigrations = {
  create?: InputMaybe<PermissionsDataCiCdMigrationsCreate>;
  delete?: InputMaybe<PermissionsDataCiCdMigrationsDelete>;
  destroy?: InputMaybe<PermissionsDataCiCdMigrationsDestroy>;
  read?: InputMaybe<PermissionsDataCiCdMigrationsRead>;
  update?: InputMaybe<PermissionsDataCiCdMigrationsUpdate>;
};

export type PermissionsDataCiCdMigrationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCiCdMigrationsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataCiCdMigrationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCiCdMigrationsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<CiCdMigrationFieldsPermissions>;
  filter?: InputMaybe<CiCdMigration_PermissionFilter>;
};

export type PermissionsDataCiCdMigrationsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<CiCdMigrationFieldsPermissions>;
  filter?: InputMaybe<CiCdMigration_PermissionFilter>;
};

export type PermissionsDataCollectiveIntelligenceThreads = {
  create?: InputMaybe<PermissionsDataCollectiveIntelligenceThreadsCreate>;
  delete?: InputMaybe<PermissionsDataCollectiveIntelligenceThreadsDelete>;
  destroy?: InputMaybe<PermissionsDataCollectiveIntelligenceThreadsDestroy>;
  read?: InputMaybe<PermissionsDataCollectiveIntelligenceThreadsRead>;
  update?: InputMaybe<PermissionsDataCollectiveIntelligenceThreadsUpdate>;
};

export type PermissionsDataCollectiveIntelligenceThreadsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCollectiveIntelligenceThreadsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataCollectiveIntelligenceThreadsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCollectiveIntelligenceThreadsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<CollectiveIntelligenceThreadFieldsPermissions>;
  filter?: InputMaybe<CollectiveIntelligenceThread_PermissionFilter>;
};

export type PermissionsDataCollectiveIntelligenceThreadsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<CollectiveIntelligenceThreadFieldsPermissions>;
  filter?: InputMaybe<CollectiveIntelligenceThread_PermissionFilter>;
};

export type PermissionsDataContentReport = {
  create?: InputMaybe<PermissionsDataContentReportCreate>;
  delete?: InputMaybe<PermissionsDataContentReportDelete>;
  destroy?: InputMaybe<PermissionsDataContentReportDestroy>;
  read?: InputMaybe<PermissionsDataContentReportRead>;
  update?: InputMaybe<PermissionsDataContentReportUpdate>;
};

export type PermissionsDataContentReportCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataContentReportDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataContentReportDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataContentReportRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<ContentReportFieldsPermissions>;
  filter?: InputMaybe<ContentReport_PermissionFilter>;
};

export type PermissionsDataContentReportUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<ContentReportFieldsPermissions>;
  filter?: InputMaybe<ContentReport_PermissionFilter>;
};

export type PermissionsDataDashboard = {
  create?: InputMaybe<PermissionsDataDashboardCreate>;
  delete?: InputMaybe<PermissionsDataDashboardDelete>;
  destroy?: InputMaybe<PermissionsDataDashboardDestroy>;
  read?: InputMaybe<PermissionsDataDashboardRead>;
  update?: InputMaybe<PermissionsDataDashboardUpdate>;
};

export type PermissionsDataDashboardCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDashboardDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataDashboardDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDashboardRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<DashboardFieldsPermissions>;
  filter?: InputMaybe<Dashboard_PermissionFilter>;
};

export type PermissionsDataDashboardUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<DashboardFieldsPermissions>;
  filter?: InputMaybe<Dashboard_PermissionFilter>;
};

export type PermissionsDataDebtOperationHistories = {
  create?: InputMaybe<PermissionsDataDebtOperationHistoriesCreate>;
  delete?: InputMaybe<PermissionsDataDebtOperationHistoriesDelete>;
  destroy?: InputMaybe<PermissionsDataDebtOperationHistoriesDestroy>;
  read?: InputMaybe<PermissionsDataDebtOperationHistoriesRead>;
  update?: InputMaybe<PermissionsDataDebtOperationHistoriesUpdate>;
};

export type PermissionsDataDebtOperationHistoriesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDebtOperationHistoriesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataDebtOperationHistoriesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDebtOperationHistoriesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<DebtOperationHistoryFieldsPermissions>;
  filter?: InputMaybe<DebtOperationHistory_PermissionFilter>;
};

export type PermissionsDataDebtOperationHistoriesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<DebtOperationHistoryFieldsPermissions>;
  filter?: InputMaybe<DebtOperationHistory_PermissionFilter>;
};

export type PermissionsDataDebts = {
  create?: InputMaybe<PermissionsDataDebtsCreate>;
  delete?: InputMaybe<PermissionsDataDebtsDelete>;
  destroy?: InputMaybe<PermissionsDataDebtsDestroy>;
  read?: InputMaybe<PermissionsDataDebtsRead>;
  update?: InputMaybe<PermissionsDataDebtsUpdate>;
};

export type PermissionsDataDebtsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDebtsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataDebtsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDebtsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<DebtFieldsPermissions>;
  filter?: InputMaybe<Debt_PermissionFilter>;
};

export type PermissionsDataDebtsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<DebtFieldsPermissions>;
  filter?: InputMaybe<Debt_PermissionFilter>;
};

export type PermissionsDataDiscountRedemptions = {
  create?: InputMaybe<PermissionsDataDiscountRedemptionsCreate>;
  delete?: InputMaybe<PermissionsDataDiscountRedemptionsDelete>;
  destroy?: InputMaybe<PermissionsDataDiscountRedemptionsDestroy>;
  read?: InputMaybe<PermissionsDataDiscountRedemptionsRead>;
  update?: InputMaybe<PermissionsDataDiscountRedemptionsUpdate>;
};

export type PermissionsDataDiscountRedemptionsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDiscountRedemptionsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataDiscountRedemptionsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDiscountRedemptionsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<DiscountRedemptionFieldsPermissions>;
  filter?: InputMaybe<DiscountRedemption_PermissionFilter>;
};

export type PermissionsDataDiscountRedemptionsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<DiscountRedemptionFieldsPermissions>;
  filter?: InputMaybe<DiscountRedemption_PermissionFilter>;
};

export type PermissionsDataDiscounts = {
  create?: InputMaybe<PermissionsDataDiscountsCreate>;
  delete?: InputMaybe<PermissionsDataDiscountsDelete>;
  destroy?: InputMaybe<PermissionsDataDiscountsDestroy>;
  read?: InputMaybe<PermissionsDataDiscountsRead>;
  update?: InputMaybe<PermissionsDataDiscountsUpdate>;
};

export type PermissionsDataDiscountsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDiscountsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataDiscountsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDiscountsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<DiscountFieldsPermissions>;
  filter?: InputMaybe<Discount_PermissionFilter>;
};

export type PermissionsDataDiscountsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<DiscountFieldsPermissions>;
  filter?: InputMaybe<Discount_PermissionFilter>;
};

export type PermissionsDataEducationalInstitutions = {
  create?: InputMaybe<PermissionsDataEducationalInstitutionsCreate>;
  delete?: InputMaybe<PermissionsDataEducationalInstitutionsDelete>;
  destroy?: InputMaybe<PermissionsDataEducationalInstitutionsDestroy>;
  read?: InputMaybe<PermissionsDataEducationalInstitutionsRead>;
  update?: InputMaybe<PermissionsDataEducationalInstitutionsUpdate>;
};

export type PermissionsDataEducationalInstitutionsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataEducationalInstitutionsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataEducationalInstitutionsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataEducationalInstitutionsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<EducationalInstitutionFieldsPermissions>;
  filter?: InputMaybe<EducationalInstitution_PermissionFilter>;
};

export type PermissionsDataEducationalInstitutionsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<EducationalInstitutionFieldsPermissions>;
  filter?: InputMaybe<EducationalInstitution_PermissionFilter>;
};

export type PermissionsDataEventSponsorRequests = {
  create?: InputMaybe<PermissionsDataEventSponsorRequestsCreate>;
  delete?: InputMaybe<PermissionsDataEventSponsorRequestsDelete>;
  destroy?: InputMaybe<PermissionsDataEventSponsorRequestsDestroy>;
  read?: InputMaybe<PermissionsDataEventSponsorRequestsRead>;
  update?: InputMaybe<PermissionsDataEventSponsorRequestsUpdate>;
};

export type PermissionsDataEventSponsorRequestsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataEventSponsorRequestsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataEventSponsorRequestsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataEventSponsorRequestsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<EventSponsorRequestFieldsPermissions>;
  filter?: InputMaybe<EventSponsorRequest_PermissionFilter>;
};

export type PermissionsDataEventSponsorRequestsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<EventSponsorRequestFieldsPermissions>;
  filter?: InputMaybe<EventSponsorRequest_PermissionFilter>;
};

export type PermissionsDataEvents = {
  create?: InputMaybe<PermissionsDataEventsCreate>;
  delete?: InputMaybe<PermissionsDataEventsDelete>;
  destroy?: InputMaybe<PermissionsDataEventsDestroy>;
  read?: InputMaybe<PermissionsDataEventsRead>;
  update?: InputMaybe<PermissionsDataEventsUpdate>;
};

export type PermissionsDataEventsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataEventsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataEventsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataEventsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<EventFieldsPermissions>;
  filter?: InputMaybe<Event_PermissionFilter>;
};

export type PermissionsDataEventsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<EventFieldsPermissions>;
  filter?: InputMaybe<Event_PermissionFilter>;
};

export type PermissionsDataFiles = {
  create?: InputMaybe<PermissionsDataFilesCreate>;
  delete?: InputMaybe<PermissionsDataFilesDelete>;
  destroy?: InputMaybe<PermissionsDataFilesDestroy>;
  read?: InputMaybe<PermissionsDataFilesRead>;
  update?: InputMaybe<PermissionsDataFilesUpdate>;
};

export type PermissionsDataFilesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFilesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataFilesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFilesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<FileFieldsPermissions>;
  filter?: InputMaybe<File_PermissionFilter>;
};

export type PermissionsDataFilesSettings = {
  create?: InputMaybe<PermissionsDataFilesSettingsCreate>;
  delete?: InputMaybe<PermissionsDataFilesSettingsDelete>;
  destroy?: InputMaybe<PermissionsDataFilesSettingsDestroy>;
  read?: InputMaybe<PermissionsDataFilesSettingsRead>;
  update?: InputMaybe<PermissionsDataFilesSettingsUpdate>;
};

export type PermissionsDataFilesSettingsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFilesSettingsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataFilesSettingsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFilesSettingsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<FilesSettingFieldsPermissions>;
  filter?: InputMaybe<FilesSetting_PermissionFilter>;
};

export type PermissionsDataFilesSettingsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<FilesSettingFieldsPermissions>;
  filter?: InputMaybe<FilesSetting_PermissionFilter>;
};

export type PermissionsDataFilesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<FileFieldsPermissions>;
  filter?: InputMaybe<File_PermissionFilter>;
};

export type PermissionsDataGraduatingYears = {
  create?: InputMaybe<PermissionsDataGraduatingYearsCreate>;
  delete?: InputMaybe<PermissionsDataGraduatingYearsDelete>;
  destroy?: InputMaybe<PermissionsDataGraduatingYearsDestroy>;
  read?: InputMaybe<PermissionsDataGraduatingYearsRead>;
  update?: InputMaybe<PermissionsDataGraduatingYearsUpdate>;
};

export type PermissionsDataGraduatingYearsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGraduatingYearsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataGraduatingYearsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGraduatingYearsMetrics = {
  create?: InputMaybe<PermissionsDataGraduatingYearsMetricsCreate>;
  delete?: InputMaybe<PermissionsDataGraduatingYearsMetricsDelete>;
  destroy?: InputMaybe<PermissionsDataGraduatingYearsMetricsDestroy>;
  read?: InputMaybe<PermissionsDataGraduatingYearsMetricsRead>;
  update?: InputMaybe<PermissionsDataGraduatingYearsMetricsUpdate>;
};

export type PermissionsDataGraduatingYearsMetricsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGraduatingYearsMetricsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataGraduatingYearsMetricsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGraduatingYearsMetricsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GraduatingYearsMetricFieldsPermissions>;
  filter?: InputMaybe<GraduatingYearsMetric_PermissionFilter>;
};

export type PermissionsDataGraduatingYearsMetricsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GraduatingYearsMetricFieldsPermissions>;
  filter?: InputMaybe<GraduatingYearsMetric_PermissionFilter>;
};

export type PermissionsDataGraduatingYearsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GraduatingYearFieldsPermissions>;
  filter?: InputMaybe<GraduatingYear_PermissionFilter>;
};

export type PermissionsDataGraduatingYearsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GraduatingYearFieldsPermissions>;
  filter?: InputMaybe<GraduatingYear_PermissionFilter>;
};

export type PermissionsDataGroupAdminPreferences = {
  create?: InputMaybe<PermissionsDataGroupAdminPreferencesCreate>;
  delete?: InputMaybe<PermissionsDataGroupAdminPreferencesDelete>;
  destroy?: InputMaybe<PermissionsDataGroupAdminPreferencesDestroy>;
  read?: InputMaybe<PermissionsDataGroupAdminPreferencesRead>;
  update?: InputMaybe<PermissionsDataGroupAdminPreferencesUpdate>;
};

export type PermissionsDataGroupAdminPreferencesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGroupAdminPreferencesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataGroupAdminPreferencesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGroupAdminPreferencesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GroupAdminPreferenceFieldsPermissions>;
  filter?: InputMaybe<GroupAdminPreference_PermissionFilter>;
};

export type PermissionsDataGroupAdminPreferencesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GroupAdminPreferenceFieldsPermissions>;
  filter?: InputMaybe<GroupAdminPreference_PermissionFilter>;
};

export type PermissionsDataGroupPosts = {
  create?: InputMaybe<PermissionsDataGroupPostsCreate>;
  delete?: InputMaybe<PermissionsDataGroupPostsDelete>;
  destroy?: InputMaybe<PermissionsDataGroupPostsDestroy>;
  read?: InputMaybe<PermissionsDataGroupPostsRead>;
  update?: InputMaybe<PermissionsDataGroupPostsUpdate>;
};

export type PermissionsDataGroupPostsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGroupPostsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataGroupPostsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGroupPostsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GroupPostFieldsPermissions>;
  filter?: InputMaybe<GroupPost_PermissionFilter>;
};

export type PermissionsDataGroupPostsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GroupPostFieldsPermissions>;
  filter?: InputMaybe<GroupPost_PermissionFilter>;
};

export type PermissionsDataGroups = {
  create?: InputMaybe<PermissionsDataGroupsCreate>;
  delete?: InputMaybe<PermissionsDataGroupsDelete>;
  destroy?: InputMaybe<PermissionsDataGroupsDestroy>;
  read?: InputMaybe<PermissionsDataGroupsRead>;
  update?: InputMaybe<PermissionsDataGroupsUpdate>;
};

export type PermissionsDataGroupsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGroupsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataGroupsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGroupsMembersRequests = {
  create?: InputMaybe<PermissionsDataGroupsMembersRequestsCreate>;
  delete?: InputMaybe<PermissionsDataGroupsMembersRequestsDelete>;
  destroy?: InputMaybe<PermissionsDataGroupsMembersRequestsDestroy>;
  read?: InputMaybe<PermissionsDataGroupsMembersRequestsRead>;
  update?: InputMaybe<PermissionsDataGroupsMembersRequestsUpdate>;
};

export type PermissionsDataGroupsMembersRequestsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGroupsMembersRequestsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataGroupsMembersRequestsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGroupsMembersRequestsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GroupsMembersRequestFieldsPermissions>;
  filter?: InputMaybe<GroupsMembersRequest_PermissionFilter>;
};

export type PermissionsDataGroupsMembersRequestsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GroupsMembersRequestFieldsPermissions>;
  filter?: InputMaybe<GroupsMembersRequest_PermissionFilter>;
};

export type PermissionsDataGroupsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GroupFieldsPermissions>;
  filter?: InputMaybe<Group_PermissionFilter>;
};

export type PermissionsDataGroupsStats = {
  create?: InputMaybe<PermissionsDataGroupsStatsCreate>;
  delete?: InputMaybe<PermissionsDataGroupsStatsDelete>;
  destroy?: InputMaybe<PermissionsDataGroupsStatsDestroy>;
  read?: InputMaybe<PermissionsDataGroupsStatsRead>;
  update?: InputMaybe<PermissionsDataGroupsStatsUpdate>;
};

export type PermissionsDataGroupsStatsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGroupsStatsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataGroupsStatsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataGroupsStatsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GroupsStatFieldsPermissions>;
  filter?: InputMaybe<GroupsStat_PermissionFilter>;
};

export type PermissionsDataGroupsStatsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GroupsStatFieldsPermissions>;
  filter?: InputMaybe<GroupsStat_PermissionFilter>;
};

export type PermissionsDataGroupsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<GroupFieldsPermissions>;
  filter?: InputMaybe<Group_PermissionFilter>;
};

export type PermissionsDataHighSchoolLocations = {
  create?: InputMaybe<PermissionsDataHighSchoolLocationsCreate>;
  delete?: InputMaybe<PermissionsDataHighSchoolLocationsDelete>;
  destroy?: InputMaybe<PermissionsDataHighSchoolLocationsDestroy>;
  read?: InputMaybe<PermissionsDataHighSchoolLocationsRead>;
  update?: InputMaybe<PermissionsDataHighSchoolLocationsUpdate>;
};

export type PermissionsDataHighSchoolLocationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataHighSchoolLocationsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataHighSchoolLocationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataHighSchoolLocationsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<HighSchoolLocationFieldsPermissions>;
  filter?: InputMaybe<HighSchoolLocation_PermissionFilter>;
};

export type PermissionsDataHighSchoolLocationsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<HighSchoolLocationFieldsPermissions>;
  filter?: InputMaybe<HighSchoolLocation_PermissionFilter>;
};

export type PermissionsDataHomeFeedItems = {
  create?: InputMaybe<PermissionsDataHomeFeedItemsCreate>;
  delete?: InputMaybe<PermissionsDataHomeFeedItemsDelete>;
  destroy?: InputMaybe<PermissionsDataHomeFeedItemsDestroy>;
  read?: InputMaybe<PermissionsDataHomeFeedItemsRead>;
  update?: InputMaybe<PermissionsDataHomeFeedItemsUpdate>;
};

export type PermissionsDataHomeFeedItemsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataHomeFeedItemsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataHomeFeedItemsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataHomeFeedItemsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<HomeFeedItemFieldsPermissions>;
  filter?: InputMaybe<HomeFeedItem_PermissionFilter>;
};

export type PermissionsDataHomeFeedItemsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<HomeFeedItemFieldsPermissions>;
  filter?: InputMaybe<HomeFeedItem_PermissionFilter>;
};

export type PermissionsDataHomeFeedPosts = {
  create?: InputMaybe<PermissionsDataHomeFeedPostsCreate>;
  delete?: InputMaybe<PermissionsDataHomeFeedPostsDelete>;
  destroy?: InputMaybe<PermissionsDataHomeFeedPostsDestroy>;
  read?: InputMaybe<PermissionsDataHomeFeedPostsRead>;
  update?: InputMaybe<PermissionsDataHomeFeedPostsUpdate>;
};

export type PermissionsDataHomeFeedPostsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataHomeFeedPostsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataHomeFeedPostsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataHomeFeedPostsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<HomeFeedPostFieldsPermissions>;
  filter?: InputMaybe<HomeFeedPost_PermissionFilter>;
};

export type PermissionsDataHomeFeedPostsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<HomeFeedPostFieldsPermissions>;
  filter?: InputMaybe<HomeFeedPost_PermissionFilter>;
};

export type PermissionsDataInbox = {
  create?: InputMaybe<PermissionsDataInboxCreate>;
  delete?: InputMaybe<PermissionsDataInboxDelete>;
  destroy?: InputMaybe<PermissionsDataInboxDestroy>;
  read?: InputMaybe<PermissionsDataInboxRead>;
  update?: InputMaybe<PermissionsDataInboxUpdate>;
};

export type PermissionsDataInboxCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataInboxDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataInboxDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataInboxMessageMedia = {
  create?: InputMaybe<PermissionsDataInboxMessageMediaCreate>;
  delete?: InputMaybe<PermissionsDataInboxMessageMediaDelete>;
  destroy?: InputMaybe<PermissionsDataInboxMessageMediaDestroy>;
  read?: InputMaybe<PermissionsDataInboxMessageMediaRead>;
  update?: InputMaybe<PermissionsDataInboxMessageMediaUpdate>;
};

export type PermissionsDataInboxMessageMediaCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataInboxMessageMediaDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataInboxMessageMediaDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataInboxMessageMediaRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<InboxMessageMediaFieldsPermissions>;
  filter?: InputMaybe<InboxMessageMedia_PermissionFilter>;
};

export type PermissionsDataInboxMessageMediaUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<InboxMessageMediaFieldsPermissions>;
  filter?: InputMaybe<InboxMessageMedia_PermissionFilter>;
};

export type PermissionsDataInboxMessages = {
  create?: InputMaybe<PermissionsDataInboxMessagesCreate>;
  delete?: InputMaybe<PermissionsDataInboxMessagesDelete>;
  destroy?: InputMaybe<PermissionsDataInboxMessagesDestroy>;
  read?: InputMaybe<PermissionsDataInboxMessagesRead>;
  update?: InputMaybe<PermissionsDataInboxMessagesUpdate>;
};

export type PermissionsDataInboxMessagesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataInboxMessagesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataInboxMessagesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataInboxMessagesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<InboxMessageFieldsPermissions>;
  filter?: InputMaybe<InboxMessage_PermissionFilter>;
};

export type PermissionsDataInboxMessagesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<InboxMessageFieldsPermissions>;
  filter?: InputMaybe<InboxMessage_PermissionFilter>;
};

export type PermissionsDataInboxRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<InboxFieldsPermissions>;
  filter?: InputMaybe<Inbox_PermissionFilter>;
};

export type PermissionsDataInboxUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<InboxFieldsPermissions>;
  filter?: InputMaybe<Inbox_PermissionFilter>;
};

export type PermissionsDataJobEmployees = {
  create?: InputMaybe<PermissionsDataJobEmployeesCreate>;
  delete?: InputMaybe<PermissionsDataJobEmployeesDelete>;
  destroy?: InputMaybe<PermissionsDataJobEmployeesDestroy>;
  read?: InputMaybe<PermissionsDataJobEmployeesRead>;
  update?: InputMaybe<PermissionsDataJobEmployeesUpdate>;
};

export type PermissionsDataJobEmployeesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataJobEmployeesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataJobEmployeesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataJobEmployeesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<JobEmployeeFieldsPermissions>;
  filter?: InputMaybe<JobEmployee_PermissionFilter>;
};

export type PermissionsDataJobEmployeesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<JobEmployeeFieldsPermissions>;
  filter?: InputMaybe<JobEmployee_PermissionFilter>;
};

export type PermissionsDataJobOffers = {
  create?: InputMaybe<PermissionsDataJobOffersCreate>;
  delete?: InputMaybe<PermissionsDataJobOffersDelete>;
  destroy?: InputMaybe<PermissionsDataJobOffersDestroy>;
  read?: InputMaybe<PermissionsDataJobOffersRead>;
  update?: InputMaybe<PermissionsDataJobOffersUpdate>;
};

export type PermissionsDataJobOffersCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataJobOffersDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataJobOffersDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataJobOffersRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<JobOfferFieldsPermissions>;
  filter?: InputMaybe<JobOffer_PermissionFilter>;
};

export type PermissionsDataJobOffersUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<JobOfferFieldsPermissions>;
  filter?: InputMaybe<JobOffer_PermissionFilter>;
};

export type PermissionsDataLegacyFeedMonths = {
  create?: InputMaybe<PermissionsDataLegacyFeedMonthsCreate>;
  delete?: InputMaybe<PermissionsDataLegacyFeedMonthsDelete>;
  destroy?: InputMaybe<PermissionsDataLegacyFeedMonthsDestroy>;
  read?: InputMaybe<PermissionsDataLegacyFeedMonthsRead>;
  update?: InputMaybe<PermissionsDataLegacyFeedMonthsUpdate>;
};

export type PermissionsDataLegacyFeedMonthsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataLegacyFeedMonthsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataLegacyFeedMonthsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataLegacyFeedMonthsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<LegacyFeedMonthFieldsPermissions>;
  filter?: InputMaybe<LegacyFeedMonth_PermissionFilter>;
};

export type PermissionsDataLegacyFeedMonthsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<LegacyFeedMonthFieldsPermissions>;
  filter?: InputMaybe<LegacyFeedMonth_PermissionFilter>;
};

export type PermissionsDataLegacyFeedPosts = {
  create?: InputMaybe<PermissionsDataLegacyFeedPostsCreate>;
  delete?: InputMaybe<PermissionsDataLegacyFeedPostsDelete>;
  destroy?: InputMaybe<PermissionsDataLegacyFeedPostsDestroy>;
  read?: InputMaybe<PermissionsDataLegacyFeedPostsRead>;
  update?: InputMaybe<PermissionsDataLegacyFeedPostsUpdate>;
};

export type PermissionsDataLegacyFeedPostsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataLegacyFeedPostsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataLegacyFeedPostsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataLegacyFeedPostsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<LegacyFeedPostFieldsPermissions>;
  filter?: InputMaybe<LegacyFeedPost_PermissionFilter>;
};

export type PermissionsDataLegacyFeedPostsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<LegacyFeedPostFieldsPermissions>;
  filter?: InputMaybe<LegacyFeedPost_PermissionFilter>;
};

export type PermissionsDataLegacyFeedYears = {
  create?: InputMaybe<PermissionsDataLegacyFeedYearsCreate>;
  delete?: InputMaybe<PermissionsDataLegacyFeedYearsDelete>;
  destroy?: InputMaybe<PermissionsDataLegacyFeedYearsDestroy>;
  read?: InputMaybe<PermissionsDataLegacyFeedYearsRead>;
  update?: InputMaybe<PermissionsDataLegacyFeedYearsUpdate>;
};

export type PermissionsDataLegacyFeedYearsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataLegacyFeedYearsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataLegacyFeedYearsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataLegacyFeedYearsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<LegacyFeedYearFieldsPermissions>;
  filter?: InputMaybe<LegacyFeedYear_PermissionFilter>;
};

export type PermissionsDataLegacyFeedYearsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<LegacyFeedYearFieldsPermissions>;
  filter?: InputMaybe<LegacyFeedYear_PermissionFilter>;
};

export type PermissionsDataNotificationTemplates = {
  create?: InputMaybe<PermissionsDataNotificationTemplatesCreate>;
  delete?: InputMaybe<PermissionsDataNotificationTemplatesDelete>;
  destroy?: InputMaybe<PermissionsDataNotificationTemplatesDestroy>;
  read?: InputMaybe<PermissionsDataNotificationTemplatesRead>;
  update?: InputMaybe<PermissionsDataNotificationTemplatesUpdate>;
};

export type PermissionsDataNotificationTemplatesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataNotificationTemplatesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataNotificationTemplatesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataNotificationTemplatesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<NotificationTemplateFieldsPermissions>;
  filter?: InputMaybe<NotificationTemplate_PermissionFilter>;
};

export type PermissionsDataNotificationTemplatesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<NotificationTemplateFieldsPermissions>;
  filter?: InputMaybe<NotificationTemplate_PermissionFilter>;
};

export type PermissionsDataOrganizationLikes = {
  create?: InputMaybe<PermissionsDataOrganizationLikesCreate>;
  delete?: InputMaybe<PermissionsDataOrganizationLikesDelete>;
  destroy?: InputMaybe<PermissionsDataOrganizationLikesDestroy>;
  read?: InputMaybe<PermissionsDataOrganizationLikesRead>;
  update?: InputMaybe<PermissionsDataOrganizationLikesUpdate>;
};

export type PermissionsDataOrganizationLikesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataOrganizationLikesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataOrganizationLikesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataOrganizationLikesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<OrganizationLikeFieldsPermissions>;
  filter?: InputMaybe<OrganizationLike_PermissionFilter>;
};

export type PermissionsDataOrganizationLikesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<OrganizationLikeFieldsPermissions>;
  filter?: InputMaybe<OrganizationLike_PermissionFilter>;
};

export type PermissionsDataOrganizationLocations = {
  create?: InputMaybe<PermissionsDataOrganizationLocationsCreate>;
  delete?: InputMaybe<PermissionsDataOrganizationLocationsDelete>;
  destroy?: InputMaybe<PermissionsDataOrganizationLocationsDestroy>;
  read?: InputMaybe<PermissionsDataOrganizationLocationsRead>;
  update?: InputMaybe<PermissionsDataOrganizationLocationsUpdate>;
};

export type PermissionsDataOrganizationLocationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataOrganizationLocationsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataOrganizationLocationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataOrganizationLocationsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<OrganizationLocationFieldsPermissions>;
  filter?: InputMaybe<OrganizationLocation_PermissionFilter>;
};

export type PermissionsDataOrganizationLocationsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<OrganizationLocationFieldsPermissions>;
  filter?: InputMaybe<OrganizationLocation_PermissionFilter>;
};

export type PermissionsDataOrganizationManagerPreferences = {
  create?: InputMaybe<PermissionsDataOrganizationManagerPreferencesCreate>;
  delete?: InputMaybe<PermissionsDataOrganizationManagerPreferencesDelete>;
  destroy?: InputMaybe<PermissionsDataOrganizationManagerPreferencesDestroy>;
  read?: InputMaybe<PermissionsDataOrganizationManagerPreferencesRead>;
  update?: InputMaybe<PermissionsDataOrganizationManagerPreferencesUpdate>;
};

export type PermissionsDataOrganizationManagerPreferencesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataOrganizationManagerPreferencesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataOrganizationManagerPreferencesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataOrganizationManagerPreferencesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<OrganizationManagerPreferenceFieldsPermissions>;
  filter?: InputMaybe<OrganizationManagerPreference_PermissionFilter>;
};

export type PermissionsDataOrganizationManagerPreferencesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<OrganizationManagerPreferenceFieldsPermissions>;
  filter?: InputMaybe<OrganizationManagerPreference_PermissionFilter>;
};

export type PermissionsDataOrganizations = {
  create?: InputMaybe<PermissionsDataOrganizationsCreate>;
  delete?: InputMaybe<PermissionsDataOrganizationsDelete>;
  destroy?: InputMaybe<PermissionsDataOrganizationsDestroy>;
  read?: InputMaybe<PermissionsDataOrganizationsRead>;
  update?: InputMaybe<PermissionsDataOrganizationsUpdate>;
};

export type PermissionsDataOrganizationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataOrganizationsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataOrganizationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataOrganizationsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<OrganizationFieldsPermissions>;
  filter?: InputMaybe<Organization_PermissionFilter>;
};

export type PermissionsDataOrganizationsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<OrganizationFieldsPermissions>;
  filter?: InputMaybe<Organization_PermissionFilter>;
};

export type PermissionsDataPaymentTransactions = {
  create?: InputMaybe<PermissionsDataPaymentTransactionsCreate>;
  delete?: InputMaybe<PermissionsDataPaymentTransactionsDelete>;
  destroy?: InputMaybe<PermissionsDataPaymentTransactionsDestroy>;
  read?: InputMaybe<PermissionsDataPaymentTransactionsRead>;
  update?: InputMaybe<PermissionsDataPaymentTransactionsUpdate>;
};

export type PermissionsDataPaymentTransactionsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataPaymentTransactionsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataPaymentTransactionsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataPaymentTransactionsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<PaymentTransactionFieldsPermissions>;
  filter?: InputMaybe<PaymentTransaction_PermissionFilter>;
};

export type PermissionsDataPaymentTransactionsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<PaymentTransactionFieldsPermissions>;
  filter?: InputMaybe<PaymentTransaction_PermissionFilter>;
};

export type PermissionsDataPostMentions = {
  create?: InputMaybe<PermissionsDataPostMentionsCreate>;
  delete?: InputMaybe<PermissionsDataPostMentionsDelete>;
  destroy?: InputMaybe<PermissionsDataPostMentionsDestroy>;
  read?: InputMaybe<PermissionsDataPostMentionsRead>;
  update?: InputMaybe<PermissionsDataPostMentionsUpdate>;
};

export type PermissionsDataPostMentionsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataPostMentionsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataPostMentionsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataPostMentionsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<PostMentionFieldsPermissions>;
  filter?: InputMaybe<PostMention_PermissionFilter>;
};

export type PermissionsDataPostMentionsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<PostMentionFieldsPermissions>;
  filter?: InputMaybe<PostMention_PermissionFilter>;
};

export type PermissionsDataRemoteVariables = {
  create?: InputMaybe<PermissionsDataRemoteVariablesCreate>;
  delete?: InputMaybe<PermissionsDataRemoteVariablesDelete>;
  destroy?: InputMaybe<PermissionsDataRemoteVariablesDestroy>;
  read?: InputMaybe<PermissionsDataRemoteVariablesRead>;
  update?: InputMaybe<PermissionsDataRemoteVariablesUpdate>;
};

export type PermissionsDataRemoteVariablesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataRemoteVariablesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataRemoteVariablesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataRemoteVariablesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<RemoteVariableFieldsPermissions>;
  filter?: InputMaybe<RemoteVariable_PermissionFilter>;
};

export type PermissionsDataRemoteVariablesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<RemoteVariableFieldsPermissions>;
  filter?: InputMaybe<RemoteVariable_PermissionFilter>;
};

export type PermissionsDataRoleAddon = {
  create?: InputMaybe<PermissionsDataRoleAddonCreate>;
  delete?: InputMaybe<PermissionsDataRoleAddonDelete>;
  destroy?: InputMaybe<PermissionsDataRoleAddonDestroy>;
  read?: InputMaybe<PermissionsDataRoleAddonRead>;
  update?: InputMaybe<PermissionsDataRoleAddonUpdate>;
};

export type PermissionsDataRoleAddonCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataRoleAddonDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataRoleAddonDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataRoleAddonRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<RoleAddonFieldsPermissions>;
  filter?: InputMaybe<RoleAddon_PermissionFilter>;
};

export type PermissionsDataRoleAddonUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<RoleAddonFieldsPermissions>;
  filter?: InputMaybe<RoleAddon_PermissionFilter>;
};

export type PermissionsDataRoles = {
  create?: InputMaybe<PermissionsDataRolesCreate>;
  delete?: InputMaybe<PermissionsDataRolesDelete>;
  destroy?: InputMaybe<PermissionsDataRolesDestroy>;
  read?: InputMaybe<PermissionsDataRolesRead>;
  update?: InputMaybe<PermissionsDataRolesUpdate>;
};

export type PermissionsDataRolesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataRolesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataRolesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataRolesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<RoleFieldsPermissions>;
  filter?: InputMaybe<Role_PermissionFilter>;
};

export type PermissionsDataRolesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<RoleFieldsPermissions>;
  filter?: InputMaybe<Role_PermissionFilter>;
};

export type PermissionsDataSchoolConfiguration = {
  create?: InputMaybe<PermissionsDataSchoolConfigurationCreate>;
  delete?: InputMaybe<PermissionsDataSchoolConfigurationDelete>;
  destroy?: InputMaybe<PermissionsDataSchoolConfigurationDestroy>;
  read?: InputMaybe<PermissionsDataSchoolConfigurationRead>;
  update?: InputMaybe<PermissionsDataSchoolConfigurationUpdate>;
};

export type PermissionsDataSchoolConfigurationColorSchemes = {
  create?: InputMaybe<PermissionsDataSchoolConfigurationColorSchemesCreate>;
  delete?: InputMaybe<PermissionsDataSchoolConfigurationColorSchemesDelete>;
  destroy?: InputMaybe<PermissionsDataSchoolConfigurationColorSchemesDestroy>;
  read?: InputMaybe<PermissionsDataSchoolConfigurationColorSchemesRead>;
  update?: InputMaybe<PermissionsDataSchoolConfigurationColorSchemesUpdate>;
};

export type PermissionsDataSchoolConfigurationColorSchemesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationColorSchemesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataSchoolConfigurationColorSchemesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationColorSchemesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationColorSchemeFieldsPermissions>;
  filter?: InputMaybe<SchoolConfigurationColorScheme_PermissionFilter>;
};

export type PermissionsDataSchoolConfigurationColorSchemesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationColorSchemeFieldsPermissions>;
  filter?: InputMaybe<SchoolConfigurationColorScheme_PermissionFilter>;
};

export type PermissionsDataSchoolConfigurationCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataSchoolConfigurationDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationExternalLinks = {
  create?: InputMaybe<PermissionsDataSchoolConfigurationExternalLinksCreate>;
  delete?: InputMaybe<PermissionsDataSchoolConfigurationExternalLinksDelete>;
  destroy?: InputMaybe<PermissionsDataSchoolConfigurationExternalLinksDestroy>;
  read?: InputMaybe<PermissionsDataSchoolConfigurationExternalLinksRead>;
  update?: InputMaybe<PermissionsDataSchoolConfigurationExternalLinksUpdate>;
};

export type PermissionsDataSchoolConfigurationExternalLinksCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationExternalLinksDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataSchoolConfigurationExternalLinksDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationExternalLinksRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationExternalLinkFieldsPermissions>;
  filter?: InputMaybe<SchoolConfigurationExternalLink_PermissionFilter>;
};

export type PermissionsDataSchoolConfigurationExternalLinksUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationExternalLinkFieldsPermissions>;
  filter?: InputMaybe<SchoolConfigurationExternalLink_PermissionFilter>;
};

export type PermissionsDataSchoolConfigurationImages = {
  create?: InputMaybe<PermissionsDataSchoolConfigurationImagesCreate>;
  delete?: InputMaybe<PermissionsDataSchoolConfigurationImagesDelete>;
  destroy?: InputMaybe<PermissionsDataSchoolConfigurationImagesDestroy>;
  read?: InputMaybe<PermissionsDataSchoolConfigurationImagesRead>;
  update?: InputMaybe<PermissionsDataSchoolConfigurationImagesUpdate>;
};

export type PermissionsDataSchoolConfigurationImagesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationImagesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataSchoolConfigurationImagesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationImagesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationImageFieldsPermissions>;
  filter?: InputMaybe<SchoolConfigurationImage_PermissionFilter>;
};

export type PermissionsDataSchoolConfigurationImagesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationImageFieldsPermissions>;
  filter?: InputMaybe<SchoolConfigurationImage_PermissionFilter>;
};

export type PermissionsDataSchoolConfigurationLayouts = {
  create?: InputMaybe<PermissionsDataSchoolConfigurationLayoutsCreate>;
  delete?: InputMaybe<PermissionsDataSchoolConfigurationLayoutsDelete>;
  destroy?: InputMaybe<PermissionsDataSchoolConfigurationLayoutsDestroy>;
  read?: InputMaybe<PermissionsDataSchoolConfigurationLayoutsRead>;
  update?: InputMaybe<PermissionsDataSchoolConfigurationLayoutsUpdate>;
};

export type PermissionsDataSchoolConfigurationLayoutsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationLayoutsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataSchoolConfigurationLayoutsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationLayoutsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationLayoutFieldsPermissions>;
  filter?: InputMaybe<SchoolConfigurationLayout_PermissionFilter>;
};

export type PermissionsDataSchoolConfigurationLayoutsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationLayoutFieldsPermissions>;
  filter?: InputMaybe<SchoolConfigurationLayout_PermissionFilter>;
};

export type PermissionsDataSchoolConfigurationRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationFieldsPermissions>;
  filter?: InputMaybe<SchoolConfiguration_PermissionFilter>;
};

export type PermissionsDataSchoolConfigurationTopBar = {
  create?: InputMaybe<PermissionsDataSchoolConfigurationTopBarCreate>;
  delete?: InputMaybe<PermissionsDataSchoolConfigurationTopBarDelete>;
  destroy?: InputMaybe<PermissionsDataSchoolConfigurationTopBarDestroy>;
  read?: InputMaybe<PermissionsDataSchoolConfigurationTopBarRead>;
  update?: InputMaybe<PermissionsDataSchoolConfigurationTopBarUpdate>;
};

export type PermissionsDataSchoolConfigurationTopBarCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationTopBarDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataSchoolConfigurationTopBarDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationTopBarRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationTopBarFieldsPermissions>;
  filter?: InputMaybe<SchoolConfigurationTopBar_PermissionFilter>;
};

export type PermissionsDataSchoolConfigurationTopBarUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationTopBarFieldsPermissions>;
  filter?: InputMaybe<SchoolConfigurationTopBar_PermissionFilter>;
};

export type PermissionsDataSchoolConfigurationUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationFieldsPermissions>;
  filter?: InputMaybe<SchoolConfiguration_PermissionFilter>;
};

export type PermissionsDataSchoolConfigurationWordsBlacklist = {
  create?: InputMaybe<PermissionsDataSchoolConfigurationWordsBlacklistCreate>;
  delete?: InputMaybe<PermissionsDataSchoolConfigurationWordsBlacklistDelete>;
  destroy?: InputMaybe<PermissionsDataSchoolConfigurationWordsBlacklistDestroy>;
  read?: InputMaybe<PermissionsDataSchoolConfigurationWordsBlacklistRead>;
  update?: InputMaybe<PermissionsDataSchoolConfigurationWordsBlacklistUpdate>;
};

export type PermissionsDataSchoolConfigurationWordsBlacklistCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationWordsBlacklistDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataSchoolConfigurationWordsBlacklistDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolConfigurationWordsBlacklistRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationWordsBlacklistFieldsPermissions>;
  filter?: InputMaybe<SchoolConfigurationWordsBlacklist_PermissionFilter>;
};

export type PermissionsDataSchoolConfigurationWordsBlacklistUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolConfigurationWordsBlacklistFieldsPermissions>;
  filter?: InputMaybe<SchoolConfigurationWordsBlacklist_PermissionFilter>;
};

export type PermissionsDataSchoolNewsPosts = {
  create?: InputMaybe<PermissionsDataSchoolNewsPostsCreate>;
  delete?: InputMaybe<PermissionsDataSchoolNewsPostsDelete>;
  destroy?: InputMaybe<PermissionsDataSchoolNewsPostsDestroy>;
  read?: InputMaybe<PermissionsDataSchoolNewsPostsRead>;
  update?: InputMaybe<PermissionsDataSchoolNewsPostsUpdate>;
};

export type PermissionsDataSchoolNewsPostsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolNewsPostsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataSchoolNewsPostsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolNewsPostsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolNewsPostFieldsPermissions>;
  filter?: InputMaybe<SchoolNewsPost_PermissionFilter>;
};

export type PermissionsDataSchoolNewsPostsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolNewsPostFieldsPermissions>;
  filter?: InputMaybe<SchoolNewsPost_PermissionFilter>;
};

export type PermissionsDataSchoolSupporter = {
  create?: InputMaybe<PermissionsDataSchoolSupporterCreate>;
  delete?: InputMaybe<PermissionsDataSchoolSupporterDelete>;
  destroy?: InputMaybe<PermissionsDataSchoolSupporterDestroy>;
  read?: InputMaybe<PermissionsDataSchoolSupporterRead>;
  update?: InputMaybe<PermissionsDataSchoolSupporterUpdate>;
};

export type PermissionsDataSchoolSupporterClicks = {
  create?: InputMaybe<PermissionsDataSchoolSupporterClicksCreate>;
  delete?: InputMaybe<PermissionsDataSchoolSupporterClicksDelete>;
  destroy?: InputMaybe<PermissionsDataSchoolSupporterClicksDestroy>;
  read?: InputMaybe<PermissionsDataSchoolSupporterClicksRead>;
  update?: InputMaybe<PermissionsDataSchoolSupporterClicksUpdate>;
};

export type PermissionsDataSchoolSupporterClicksCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolSupporterClicksDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataSchoolSupporterClicksDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolSupporterClicksRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolSupporterClickFieldsPermissions>;
  filter?: InputMaybe<SchoolSupporterClick_PermissionFilter>;
};

export type PermissionsDataSchoolSupporterClicksUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolSupporterClickFieldsPermissions>;
  filter?: InputMaybe<SchoolSupporterClick_PermissionFilter>;
};

export type PermissionsDataSchoolSupporterCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolSupporterDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataSchoolSupporterDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolSupporterRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolSupporterFieldsPermissions>;
  filter?: InputMaybe<SchoolSupporter_PermissionFilter>;
};

export type PermissionsDataSchoolSupporterSearches = {
  create?: InputMaybe<PermissionsDataSchoolSupporterSearchesCreate>;
  delete?: InputMaybe<PermissionsDataSchoolSupporterSearchesDelete>;
  destroy?: InputMaybe<PermissionsDataSchoolSupporterSearchesDestroy>;
  read?: InputMaybe<PermissionsDataSchoolSupporterSearchesRead>;
  update?: InputMaybe<PermissionsDataSchoolSupporterSearchesUpdate>;
};

export type PermissionsDataSchoolSupporterSearchesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolSupporterSearchesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataSchoolSupporterSearchesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSchoolSupporterSearchesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolSupporterSearchFieldsPermissions>;
  filter?: InputMaybe<SchoolSupporterSearch_PermissionFilter>;
};

export type PermissionsDataSchoolSupporterSearchesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolSupporterSearchFieldsPermissions>;
  filter?: InputMaybe<SchoolSupporterSearch_PermissionFilter>;
};

export type PermissionsDataSchoolSupporterUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<SchoolSupporterFieldsPermissions>;
  filter?: InputMaybe<SchoolSupporter_PermissionFilter>;
};

export type PermissionsDataStripeInformations = {
  create?: InputMaybe<PermissionsDataStripeInformationsCreate>;
  delete?: InputMaybe<PermissionsDataStripeInformationsDelete>;
  destroy?: InputMaybe<PermissionsDataStripeInformationsDestroy>;
  read?: InputMaybe<PermissionsDataStripeInformationsRead>;
  update?: InputMaybe<PermissionsDataStripeInformationsUpdate>;
};

export type PermissionsDataStripeInformationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataStripeInformationsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataStripeInformationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataStripeInformationsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<StripeInformationFieldsPermissions>;
  filter?: InputMaybe<StripeInformation_PermissionFilter>;
};

export type PermissionsDataStripeInformationsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<StripeInformationFieldsPermissions>;
  filter?: InputMaybe<StripeInformation_PermissionFilter>;
};

export type PermissionsDataTeamInvitations = {
  create?: InputMaybe<PermissionsDataTeamInvitationsCreate>;
  delete?: InputMaybe<PermissionsDataTeamInvitationsDelete>;
  destroy?: InputMaybe<PermissionsDataTeamInvitationsDestroy>;
  read?: InputMaybe<PermissionsDataTeamInvitationsRead>;
  update?: InputMaybe<PermissionsDataTeamInvitationsUpdate>;
};

export type PermissionsDataTeamInvitationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataTeamInvitationsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataTeamInvitationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataTeamInvitationsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<TeamInvitationFieldsPermissions>;
  filter?: InputMaybe<TeamInvitation_PermissionFilter>;
};

export type PermissionsDataTeamInvitationsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<TeamInvitationFieldsPermissions>;
  filter?: InputMaybe<TeamInvitation_PermissionFilter>;
};

export type PermissionsDataTeamMembers = {
  create?: InputMaybe<PermissionsDataTeamMembersCreate>;
  delete?: InputMaybe<PermissionsDataTeamMembersDelete>;
  destroy?: InputMaybe<PermissionsDataTeamMembersDestroy>;
  read?: InputMaybe<PermissionsDataTeamMembersRead>;
  update?: InputMaybe<PermissionsDataTeamMembersUpdate>;
};

export type PermissionsDataTeamMembersCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataTeamMembersDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataTeamMembersDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataTeamMembersRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<TeamMemberFieldsPermissions>;
  filter?: InputMaybe<TeamMember_PermissionFilter>;
};

export type PermissionsDataTeamMembersUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<TeamMemberFieldsPermissions>;
  filter?: InputMaybe<TeamMember_PermissionFilter>;
};

export type PermissionsDataTrendingSearches = {
  create?: InputMaybe<PermissionsDataTrendingSearchesCreate>;
  delete?: InputMaybe<PermissionsDataTrendingSearchesDelete>;
  destroy?: InputMaybe<PermissionsDataTrendingSearchesDestroy>;
  read?: InputMaybe<PermissionsDataTrendingSearchesRead>;
  update?: InputMaybe<PermissionsDataTrendingSearchesUpdate>;
};

export type PermissionsDataTrendingSearchesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataTrendingSearchesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataTrendingSearchesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataTrendingSearchesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<TrendingSearchFieldsPermissions>;
  filter?: InputMaybe<TrendingSearch_PermissionFilter>;
};

export type PermissionsDataTrendingSearchesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<TrendingSearchFieldsPermissions>;
  filter?: InputMaybe<TrendingSearch_PermissionFilter>;
};

export type PermissionsDataUserConfiguration = {
  create?: InputMaybe<PermissionsDataUserConfigurationCreate>;
  delete?: InputMaybe<PermissionsDataUserConfigurationDelete>;
  destroy?: InputMaybe<PermissionsDataUserConfigurationDestroy>;
  read?: InputMaybe<PermissionsDataUserConfigurationRead>;
  update?: InputMaybe<PermissionsDataUserConfigurationUpdate>;
};

export type PermissionsDataUserConfigurationCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserConfigurationDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataUserConfigurationDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserConfigurationRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserConfigurationFieldsPermissions>;
  filter?: InputMaybe<UserConfiguration_PermissionFilter>;
};

export type PermissionsDataUserConfigurationUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserConfigurationFieldsPermissions>;
  filter?: InputMaybe<UserConfiguration_PermissionFilter>;
};

export type PermissionsDataUserNotifications = {
  create?: InputMaybe<PermissionsDataUserNotificationsCreate>;
  delete?: InputMaybe<PermissionsDataUserNotificationsDelete>;
  destroy?: InputMaybe<PermissionsDataUserNotificationsDestroy>;
  read?: InputMaybe<PermissionsDataUserNotificationsRead>;
  update?: InputMaybe<PermissionsDataUserNotificationsUpdate>;
};

export type PermissionsDataUserNotificationsByUser = {
  create?: InputMaybe<PermissionsDataUserNotificationsByUserCreate>;
  delete?: InputMaybe<PermissionsDataUserNotificationsByUserDelete>;
  destroy?: InputMaybe<PermissionsDataUserNotificationsByUserDestroy>;
  read?: InputMaybe<PermissionsDataUserNotificationsByUserRead>;
  update?: InputMaybe<PermissionsDataUserNotificationsByUserUpdate>;
};

export type PermissionsDataUserNotificationsByUserCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserNotificationsByUserDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataUserNotificationsByUserDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserNotificationsByUserRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserNotificationsByUserFieldsPermissions>;
  filter?: InputMaybe<UserNotificationsByUser_PermissionFilter>;
};

export type PermissionsDataUserNotificationsByUserUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserNotificationsByUserFieldsPermissions>;
  filter?: InputMaybe<UserNotificationsByUser_PermissionFilter>;
};

export type PermissionsDataUserNotificationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserNotificationsDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataUserNotificationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserNotificationsRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserNotificationFieldsPermissions>;
  filter?: InputMaybe<UserNotification_PermissionFilter>;
};

export type PermissionsDataUserNotificationsUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserNotificationFieldsPermissions>;
  filter?: InputMaybe<UserNotification_PermissionFilter>;
};

export type PermissionsDataUserPreferences = {
  create?: InputMaybe<PermissionsDataUserPreferencesCreate>;
  delete?: InputMaybe<PermissionsDataUserPreferencesDelete>;
  destroy?: InputMaybe<PermissionsDataUserPreferencesDestroy>;
  read?: InputMaybe<PermissionsDataUserPreferencesRead>;
  update?: InputMaybe<PermissionsDataUserPreferencesUpdate>;
};

export type PermissionsDataUserPreferencesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserPreferencesDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataUserPreferencesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserPreferencesRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserPreferenceFieldsPermissions>;
  filter?: InputMaybe<UserPreference_PermissionFilter>;
};

export type PermissionsDataUserPreferencesUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserPreferenceFieldsPermissions>;
  filter?: InputMaybe<UserPreference_PermissionFilter>;
};

export type PermissionsDataUserSchoolMembership = {
  create?: InputMaybe<PermissionsDataUserSchoolMembershipCreate>;
  delete?: InputMaybe<PermissionsDataUserSchoolMembershipDelete>;
  destroy?: InputMaybe<PermissionsDataUserSchoolMembershipDestroy>;
  read?: InputMaybe<PermissionsDataUserSchoolMembershipRead>;
  update?: InputMaybe<PermissionsDataUserSchoolMembershipUpdate>;
};

export type PermissionsDataUserSchoolMembershipCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserSchoolMembershipDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataUserSchoolMembershipDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserSchoolMembershipRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserSchoolMembershipFieldsPermissions>;
  filter?: InputMaybe<UserSchoolMembership_PermissionFilter>;
};

export type PermissionsDataUserSchoolMembershipUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserSchoolMembershipFieldsPermissions>;
  filter?: InputMaybe<UserSchoolMembership_PermissionFilter>;
};

export type PermissionsDataUserStatus = {
  create?: InputMaybe<PermissionsDataUserStatusCreate>;
  delete?: InputMaybe<PermissionsDataUserStatusDelete>;
  destroy?: InputMaybe<PermissionsDataUserStatusDestroy>;
  read?: InputMaybe<PermissionsDataUserStatusRead>;
  update?: InputMaybe<PermissionsDataUserStatusUpdate>;
};

export type PermissionsDataUserStatusCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserStatusDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataUserStatusDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserStatusRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserStatusFieldsPermissions>;
  filter?: InputMaybe<UserStatus_PermissionFilter>;
};

export type PermissionsDataUserStatusUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserStatusFieldsPermissions>;
  filter?: InputMaybe<UserStatus_PermissionFilter>;
};

export type PermissionsDataUserSupportersOnly = {
  create?: InputMaybe<PermissionsDataUserSupportersOnlyCreate>;
  delete?: InputMaybe<PermissionsDataUserSupportersOnlyDelete>;
  destroy?: InputMaybe<PermissionsDataUserSupportersOnlyDestroy>;
  read?: InputMaybe<PermissionsDataUserSupportersOnlyRead>;
  update?: InputMaybe<PermissionsDataUserSupportersOnlyUpdate>;
};

export type PermissionsDataUserSupportersOnlyCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserSupportersOnlyDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataUserSupportersOnlyDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUserSupportersOnlyRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserSupportersOnlyFieldsPermissions>;
  filter?: InputMaybe<UserSupportersOnly_PermissionFilter>;
};

export type PermissionsDataUserSupportersOnlyUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserSupportersOnlyFieldsPermissions>;
  filter?: InputMaybe<UserSupportersOnly_PermissionFilter>;
};

export type PermissionsDataUsers = {
  create?: InputMaybe<PermissionsDataUsersCreate>;
  delete?: InputMaybe<PermissionsDataUsersDelete>;
  destroy?: InputMaybe<PermissionsDataUsersDestroy>;
  read?: InputMaybe<PermissionsDataUsersRead>;
  update?: InputMaybe<PermissionsDataUsersUpdate>;
};

export type PermissionsDataUsersCount = {
  create?: InputMaybe<PermissionsDataUsersCountCreate>;
  delete?: InputMaybe<PermissionsDataUsersCountDelete>;
  destroy?: InputMaybe<PermissionsDataUsersCountDestroy>;
  read?: InputMaybe<PermissionsDataUsersCountRead>;
  update?: InputMaybe<PermissionsDataUsersCountUpdate>;
};

export type PermissionsDataUsersCountCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUsersCountDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataUsersCountDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUsersCountRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UsersCountFieldsPermissions>;
  filter?: InputMaybe<UsersCount_PermissionFilter>;
};

export type PermissionsDataUsersCountUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UsersCountFieldsPermissions>;
  filter?: InputMaybe<UsersCount_PermissionFilter>;
};

export type PermissionsDataUsersCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUsersDelete = {
  allow: Scalars['Boolean'];
  restore?: InputMaybe<Scalars['Boolean']>;
  review?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionsDataUsersDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUsersRead = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserFieldsPermissions>;
  filter?: InputMaybe<User_PermissionFilter>;
};

export type PermissionsDataUsersUpdate = {
  allow: Scalars['Boolean'];
  fields?: InputMaybe<UserFieldsPermissions>;
  filter?: InputMaybe<User_PermissionFilter>;
};

/** PermissionsInput create input */
export type PermissionsInput = {
  custom?: InputMaybe<PermissionsCustom>;
  data?: InputMaybe<PermissionsData>;
};

export type PostMention = {
  __typename?: 'PostMention';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  groupPost?: Maybe<GroupPost>;
  homePost?: Maybe<HomeFeedPost>;
  id?: Maybe<Scalars['ID']>;
  legacyPost?: Maybe<LegacyFeedPost>;
  legacyPostId?: Maybe<Scalars['String']>;
  mentionId?: Maybe<Scalars['String']>;
  mentionText?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  schoolPost?: Maybe<SchoolNewsPost>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** PostMentions create input */
export type PostMentionCreateInput = {
  groupPost?: InputMaybe<PostMentionsGroupPostRelationInput>;
  homePost?: InputMaybe<PostMentionsHomePostRelationInput>;
  legacyPost?: InputMaybe<PostMentionsLegacyPostRelationInput>;
  legacyPostId?: InputMaybe<Scalars['String']>;
  mentionId?: InputMaybe<Scalars['String']>;
  mentionText?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  schoolPost?: InputMaybe<PostMentionsSchoolPostRelationInput>;
  type?: InputMaybe<Scalars['String']>;
};

/** PostMentions create many input */
export type PostMentionCreateManyInput = {
  groupPost?: InputMaybe<PostMentionsGroupPostManyRelationInput>;
  homePost?: InputMaybe<PostMentionsHomePostManyRelationInput>;
  legacyPost?: InputMaybe<PostMentionsLegacyPostManyRelationInput>;
  legacyPostId?: InputMaybe<Scalars['String']>;
  mentionId?: InputMaybe<Scalars['String']>;
  mentionText?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  schoolPost?: InputMaybe<PostMentionsSchoolPostManyRelationInput>;
  type?: InputMaybe<Scalars['String']>;
};

/** PostMentions delete input */
export type PostMentionDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** PostMentionFieldsPermissions create input */
export type PostMentionFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  legacyPostId?: InputMaybe<Scalars['Boolean']>;
  mentionId?: InputMaybe<Scalars['Boolean']>;
  mentionText?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PostMentionFilter = {
  AND?: InputMaybe<Array<PostMentionFilter>>;
  OR?: InputMaybe<Array<PostMentionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  groupPost?: InputMaybe<GroupPostFilter>;
  homePost?: InputMaybe<HomeFeedPostFilter>;
  id?: InputMaybe<IdPredicate>;
  legacyPost?: InputMaybe<LegacyFeedPostFilter>;
  legacyPostId?: InputMaybe<StringPredicate>;
  mentionId?: InputMaybe<StringPredicate>;
  mentionText?: InputMaybe<StringPredicate>;
  position?: InputMaybe<IntPredicate>;
  schoolPost?: InputMaybe<SchoolNewsPostFilter>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type PostMentionGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: PostMentionGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type PostMentionGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  groupPost?: InputMaybe<GroupPostGroupByQuery>;
  homePost?: InputMaybe<HomeFeedPostGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  legacyPost?: InputMaybe<LegacyFeedPostGroupByQuery>;
  legacyPostId?: InputMaybe<Array<GroupByField>>;
  mentionId?: InputMaybe<Array<GroupByField>>;
  mentionText?: InputMaybe<Array<GroupByField>>;
  position?: InputMaybe<Array<GroupByField>>;
  schoolPost?: InputMaybe<SchoolNewsPostGroupByQuery>;
  type?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type PostMentionKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** PostMentionListResponse output */
export type PostMentionListResponse = {
  __typename?: 'PostMentionListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<PostMention>;
};

/** PostMentionManyResponse output */
export type PostMentionManyResponse = {
  __typename?: 'PostMentionManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<PostMention>;
};

/** No longer supported. Use `sort` instead. */
export type PostMentionOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'legacyPostId_ASC'
  | 'legacyPostId_DESC'
  | 'mentionId_ASC'
  | 'mentionId_DESC'
  | 'mentionText_ASC'
  | 'mentionText_DESC'
  | 'position_ASC'
  | 'position_DESC'
  | 'type_ASC'
  | 'type_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** PostMentions subscription payload */
export type PostMentionPayload = {
  __typename?: 'PostMentionPayload';
  mutation: MutationType;
  node?: Maybe<PostMention>;
  previousValues?: Maybe<PostMention>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PostMentionRelationFilter = {
  every?: InputMaybe<PostMentionFilter>;
  none?: InputMaybe<PostMentionFilter>;
  some?: InputMaybe<PostMentionFilter>;
};

export type PostMentionSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  groupPost?: InputMaybe<GroupPostSort>;
  homePost?: InputMaybe<HomeFeedPostSort>;
  id?: InputMaybe<SortOrder>;
  legacyPost?: InputMaybe<LegacyFeedPostSort>;
  legacyPostId?: InputMaybe<SortOrder>;
  mentionId?: InputMaybe<SortOrder>;
  mentionText?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  schoolPost?: InputMaybe<SchoolNewsPostSort>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** PostMentions subscription filter */
export type PostMentionSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<PostMentionFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** PostMentions update input */
export type PostMentionUpdateByFilterInput = {
  legacyPostId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  mentionId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  mentionText?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  position?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  type?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
};

/** PostMentions update input */
export type PostMentionUpdateInput = {
  groupPost?: InputMaybe<PostMentionsGroupPostUpdateRelationInput>;
  homePost?: InputMaybe<PostMentionsHomePostUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  legacyPost?: InputMaybe<PostMentionsLegacyPostUpdateRelationInput>;
  legacyPostId?: InputMaybe<Scalars['String']>;
  mentionId?: InputMaybe<Scalars['String']>;
  mentionText?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  schoolPost?: InputMaybe<PostMentionsSchoolPostUpdateRelationInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type PostMention_PermissionFilter = {
  AND?: InputMaybe<Array<PostMention_PermissionFilter>>;
  OR?: InputMaybe<Array<PostMention_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  groupPost?: InputMaybe<GroupPost_PermissionFilter>;
  homePost?: InputMaybe<HomeFeedPost_PermissionFilter>;
  id?: InputMaybe<IdPredicate>;
  legacyPost?: InputMaybe<LegacyFeedPost_PermissionFilter>;
  legacyPostId?: InputMaybe<StringPredicate>;
  mentionId?: InputMaybe<StringPredicate>;
  mentionText?: InputMaybe<StringPredicate>;
  position?: InputMaybe<IntPredicate>;
  schoolPost?: InputMaybe<SchoolNewsPost_PermissionFilter>;
  type?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type PostMention_PermissionRelationFilter = {
  every?: InputMaybe<PostMention_PermissionFilter>;
  none?: InputMaybe<PostMention_PermissionFilter>;
  some?: InputMaybe<PostMention_PermissionFilter>;
};

/** PostMentions relation input */
export type PostMentionsGroupPostManyRelationInput = {
  connect?: InputMaybe<GroupPostKeyFilter>;
};

/** PostMentions relation input */
export type PostMentionsGroupPostRelationInput = {
  connect?: InputMaybe<GroupPostKeyFilter>;
  create?: InputMaybe<Mentions_GroupPostCreateInput>;
};

/** PostMentions relation input */
export type PostMentionsGroupPostUpdateRelationInput = {
  connect?: InputMaybe<GroupPostKeyFilter>;
  create?: InputMaybe<Mentions_GroupPostCreateInput>;
  disconnect?: InputMaybe<GroupPostKeyFilter>;
  reconnect?: InputMaybe<GroupPostKeyFilter>;
  update?: InputMaybe<Mentions_GroupPostUpdateInput>;
};

/** PostMentions relation input */
export type PostMentionsHomePostManyRelationInput = {
  connect?: InputMaybe<HomeFeedPostKeyFilter>;
};

/** PostMentions relation input */
export type PostMentionsHomePostRelationInput = {
  connect?: InputMaybe<HomeFeedPostKeyFilter>;
  create?: InputMaybe<Mentions_HomeFeedPostCreateInput>;
};

/** PostMentions relation input */
export type PostMentionsHomePostUpdateRelationInput = {
  connect?: InputMaybe<HomeFeedPostKeyFilter>;
  create?: InputMaybe<Mentions_HomeFeedPostCreateInput>;
  disconnect?: InputMaybe<HomeFeedPostKeyFilter>;
  reconnect?: InputMaybe<HomeFeedPostKeyFilter>;
  update?: InputMaybe<Mentions_HomeFeedPostUpdateInput>;
};

/** PostMentions relation input */
export type PostMentionsLegacyPostManyRelationInput = {
  connect?: InputMaybe<LegacyFeedPostKeyFilter>;
};

/** PostMentions relation input */
export type PostMentionsLegacyPostRelationInput = {
  connect?: InputMaybe<LegacyFeedPostKeyFilter>;
  create?: InputMaybe<Mentions_LegacyFeedPostCreateInput>;
};

/** PostMentions relation input */
export type PostMentionsLegacyPostUpdateRelationInput = {
  connect?: InputMaybe<LegacyFeedPostKeyFilter>;
  create?: InputMaybe<Mentions_LegacyFeedPostCreateInput>;
  disconnect?: InputMaybe<LegacyFeedPostKeyFilter>;
  reconnect?: InputMaybe<LegacyFeedPostKeyFilter>;
  update?: InputMaybe<Mentions_LegacyFeedPostUpdateInput>;
};

/** PostMentions relation input */
export type PostMentionsSchoolPostManyRelationInput = {
  connect?: InputMaybe<SchoolNewsPostKeyFilter>;
};

/** PostMentions relation input */
export type PostMentionsSchoolPostRelationInput = {
  connect?: InputMaybe<SchoolNewsPostKeyFilter>;
  create?: InputMaybe<Mentions_SchoolNewsPostCreateInput>;
};

/** PostMentions relation input */
export type PostMentionsSchoolPostUpdateRelationInput = {
  connect?: InputMaybe<SchoolNewsPostKeyFilter>;
  create?: InputMaybe<Mentions_SchoolNewsPostCreateInput>;
  disconnect?: InputMaybe<SchoolNewsPostKeyFilter>;
  reconnect?: InputMaybe<SchoolNewsPostKeyFilter>;
  update?: InputMaybe<Mentions_SchoolNewsPostUpdateInput>;
};

export type PostModerateRequestSuccess = {
  __typename?: 'PostModerateRequestSuccess';
  verified?: Maybe<Scalars['Boolean']>;
};

/** Users create input from postedEvents */
export type PostedEvents_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from postedEvents */
export type PostedEvents_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** OrganizationManagerPreferences create input from postedJobOffers */
export type PostedJobOffers_OrganizationManagerPreferenceCreateInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  organizations?: InputMaybe<OrganizationManagerPreferencesOrganizationsRelationInput>;
  postedJobOffers?: InputMaybe<OrganizationManagerPreferencesPostedJobOffersRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<OrganizationManagerPreferencesUserRelationInput>;
};

/** OrganizationManagerPreferences update input from postedJobOffers */
export type PostedJobOffers_OrganizationManagerPreferenceUpdateInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  organizations?: InputMaybe<OrganizationManagerPreferencesOrganizationsUpdateRelationInput>;
  postedJobOffers?: InputMaybe<OrganizationManagerPreferencesPostedJobOffersUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<OrganizationManagerPreferencesUserUpdateRelationInput>;
};

/** Groups create input from posts */
export type Posts_GroupCreateInput = {
  audiences?: InputMaybe<GroupsAudiencesRelationInput>;
  creator?: InputMaybe<GroupsCreatorRelationInput>;
  description: Scalars['String'];
  groupAdminPreferences?: InputMaybe<GroupsGroupAdminPreferencesRelationInput>;
  groupToJoin?: InputMaybe<GroupsGroupToJoinRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<GroupsLogoRelationInput>;
  members?: InputMaybe<GroupsMembersRelationInput>;
  membersCount?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<GroupsPostsRelationInput>;
  postsCount?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<GroupsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Groups update input from posts */
export type Posts_GroupUpdateInput = {
  audiences?: InputMaybe<GroupsAudiencesUpdateRelationInput>;
  creator?: InputMaybe<GroupsCreatorUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  groupAdminPreferences?: InputMaybe<GroupsGroupAdminPreferencesUpdateRelationInput>;
  groupToJoin?: InputMaybe<GroupsGroupToJoinUpdateRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<GroupsLogoUpdateRelationInput>;
  members?: InputMaybe<GroupsMembersUpdateRelationInput>;
  membersCount?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<GroupsPostsUpdateRelationInput>;
  postsCount?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<GroupsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ProjectItemWs = {
  __typename?: 'ProjectItemWS';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<WorkspaceImage>;
  name: Scalars['String'];
  region?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  adClick?: Maybe<AdClick>;
  adClicksList: AdClickListResponse;
  adView?: Maybe<AdView>;
  adViewsList: AdViewListResponse;
  address?: Maybe<Address>;
  addressesList: AddressListResponse;
  advertising?: Maybe<Advertising>;
  advertisingCampaignAd?: Maybe<AdvertisingCampaignAd>;
  advertisingCampaignAdsList: AdvertisingCampaignAdListResponse;
  advertisingSampaign?: Maybe<AdvertisingSampaign>;
  advertisingSampaignsList: AdvertisingSampaignListResponse;
  advertisingSupporter?: Maybe<AdvertisingSupporter>;
  advertisingSupportersList: AdvertisingSupporterListResponse;
  advertisingsList: AdvertisingListResponse;
  apiToken?: Maybe<ApiToken>;
  apiTokensList: ApiTokenListResponse;
  /** @deprecated No longer supported. Use `system.application` instead. */
  application?: Maybe<Application>;
  /** @deprecated No longer supported. Use `system.applicationsList` instead. */
  applicationsList?: Maybe<ApplicationListResponse>;
  /** @deprecated No longer supported. Use `system.asyncSessionStatus` instead. */
  asyncSessionStatus?: Maybe<AsyncSessionStatusResponse>;
  audience?: Maybe<Audience>;
  audiencesList: AudienceListResponse;
  authenticationProfile?: Maybe<AuthenticationProfile>;
  authenticationProfilesList: AuthenticationProfileListResponse;
  authenticationSettings?: Maybe<AuthenticationSetting>;
  /** @deprecated No longer supported. Use `system.billingCurrentPlan` instead. */
  billingCurrentPlan?: Maybe<BillingCurrentPlanResponse>;
  /** @deprecated No longer supported. Use `system.memberPaymentDetails, system.organizationPaymentDetails or system.workspacePaymentDetails` instead. */
  billingDetails?: Maybe<BillingDetailsResponse>;
  /** @deprecated No longer supported. Use `system.memberBillingHistory, system.organizationBillingHistory or system.workspaceBillingHistory` instead. */
  billingInvoicesList: BillingInvoicesListResponse;
  /** @deprecated No longer supported. Use `system.billingMetricUsageQuotasList` instead. */
  billingMetricUsageQuotasList: BillingMetricUsageQuotasListResponse;
  /** @deprecated No longer supported. Use `system.billingMetricUsagesList` instead. */
  billingMetricUsagesList: BillingMetricUsagesListResponse;
  billingRegisteredAd?: Maybe<BillingRegisteredAd>;
  billingRegisteredAdsList: BillingRegisteredAdListResponse;
  billingRegisteredSchoolSupporter?: Maybe<BillingRegisteredSchoolSupporter>;
  billingRegisteredSchoolSupportersList: BillingRegisteredSchoolSupporterListResponse;
  billingRegisteredSpecialOffer?: Maybe<BillingRegisteredSpecialOffer>;
  billingRegisteredSpecialOffersList: BillingRegisteredSpecialOfferListResponse;
  billingSchoolSupporter?: Maybe<BillingSchoolSupporter>;
  billingSchoolSupportersList: BillingSchoolSupporterListResponse;
  billingSpecialOffer?: Maybe<BillingSpecialOffer>;
  billingSpecialOffersList: BillingSpecialOfferListResponse;
  billingsAd?: Maybe<BillingsAd>;
  billingsAdsList: BillingsAdListResponse;
  businessFilterByZipRequest: BusinessFilterByZipRequestResponse;
  businessReport?: Maybe<BusinessReport>;
  businessReportsList: BusinessReportListResponse;
  careersFilterByZipRequest: CareersFilterByZipRequestResponse;
  checkResetPasswordCode: CheckResetPasswordCodeSuccess;
  ciCdMigration?: Maybe<CiCdMigration>;
  ciCdMigrationsList: CiCdMigrationListResponse;
  collectiveIntelligenceThread?: Maybe<CollectiveIntelligenceThread>;
  collectiveIntelligenceThreadsList: CollectiveIntelligenceThreadListResponse;
  companyName?: Maybe<Scalars['String']>;
  /**
   * Wrapper of `contentReportsList` to add the reactions.
   * https://8base-dev.atlassian.net/browse/JEB-1485
   */
  contentEngagementReportsList: ContentEngagementReportListResponse;
  contentReport?: Maybe<ContentReport>;
  contentReportsList: ContentReportListResponse;
  currentLocationZipRequest: CurrentLocationZipRequestSuccess;
  dashboard?: Maybe<Dashboard>;
  dashboardsList: DashboardListResponse;
  debt?: Maybe<Debt>;
  debtOperationHistoriesList: DebtOperationHistoryListResponse;
  debtOperationHistory?: Maybe<DebtOperationHistory>;
  debtsList: DebtListResponse;
  /** @deprecated No longer supported. Use `system.deployStatus` instead. */
  deployStatus: DeployStatusResult;
  discount?: Maybe<Discount>;
  discountRedemption?: Maybe<DiscountRedemption>;
  discountRedemptionsList: DiscountRedemptionListResponse;
  discountsList: DiscountListResponse;
  educationalInstitution?: Maybe<EducationalInstitution>;
  educationalInstitutionsList: EducationalInstitutionListResponse;
  environmentVariable?: Maybe<EnvironmentVariable>;
  environmentVariablesList: EnvironmentVariableListResponse;
  event?: Maybe<Event>;
  eventSponsorRequest?: Maybe<EventSponsorRequest>;
  eventSponsorRequestsList: EventSponsorRequestListResponse;
  eventsFilterByZipRequest: EventsFilterByZipRequestResponse;
  eventsList: EventListResponse;
  file?: Maybe<File>;
  /** @deprecated No longer supported. Use `fileUploadSignInfo` instead. */
  fileUploadInfo?: Maybe<FileUploadInfoResponse>;
  filesList: FileListResponse;
  filesSetting?: Maybe<FilesSetting>;
  filesSettingsList: FilesSettingListResponse;
  /** @deprecated No longer supported. Use `system.functionsList` instead. */
  functionsList?: Maybe<FunctionListResponse>;
  functionsVersionCheck?: Maybe<FunctionInfoCheck>;
  /** Generate a link preview for a given URL. */
  generateLinkPreview: GeneratedLinkPreview;
  graduatingYear?: Maybe<GraduatingYear>;
  graduatingYearsList: GraduatingYearListResponse;
  graduatingYearsMetric?: Maybe<GraduatingYearsMetric>;
  graduatingYearsMetricsList: GraduatingYearsMetricListResponse;
  group?: Maybe<Group>;
  groupAdminPreference?: Maybe<GroupAdminPreference>;
  groupAdminPreferencesList: GroupAdminPreferenceListResponse;
  groupPost?: Maybe<GroupPost>;
  groupPostsList: GroupPostListResponse;
  groupsList: GroupListResponse;
  groupsMembersRequest?: Maybe<GroupsMembersRequest>;
  groupsMembersRequestsList: GroupsMembersRequestListResponse;
  groupsStat?: Maybe<GroupsStat>;
  groupsStatsList: GroupsStatListResponse;
  highSchoolLocation?: Maybe<HighSchoolLocation>;
  highSchoolLocationsList: HighSchoolLocationListResponse;
  homeFeedItem?: Maybe<HomeFeedItem>;
  homeFeedItemsList: HomeFeedItemListResponse;
  homeFeedPost?: Maybe<HomeFeedPost>;
  homeFeedPostsList: HomeFeedPostListResponse;
  inbox?: Maybe<Inbox>;
  inboxMessage?: Maybe<InboxMessage>;
  inboxMessageMedia?: Maybe<InboxMessageMedia>;
  inboxMessageMediasList: InboxMessageMediaListResponse;
  inboxMessagesList: InboxMessageListResponse;
  inboxesList: InboxListResponse;
  isAllowedCallbackURL?: Maybe<IsAllowedCallbackUrlQueryResponse>;
  jobEmployee?: Maybe<JobEmployee>;
  jobEmployeesList: JobEmployeeListResponse;
  jobOffer?: Maybe<JobOffer>;
  jobOffersList: JobOfferListResponse;
  legacyFeedMonth?: Maybe<LegacyFeedMonth>;
  legacyFeedMonthsList: LegacyFeedMonthListResponse;
  legacyFeedPost?: Maybe<LegacyFeedPost>;
  legacyFeedPostsList: LegacyFeedPostListResponse;
  legacyFeedYear?: Maybe<LegacyFeedYear>;
  legacyFeedYearsList: LegacyFeedYearListResponse;
  /** @deprecated No longer supported. Use `system.logsList` instead. */
  logs?: Maybe<Array<Maybe<Scalars['String']>>>;
  notificationTemplate?: Maybe<NotificationTemplate>;
  notificationTemplatesList: NotificationTemplateListResponse;
  organization?: Maybe<Organization>;
  organizationLike?: Maybe<OrganizationLike>;
  organizationLikesList: OrganizationLikeListResponse;
  organizationLocation?: Maybe<OrganizationLocation>;
  organizationLocationsList: OrganizationLocationListResponse;
  organizationManagerPreference?: Maybe<OrganizationManagerPreference>;
  organizationManagerPreferencesList: OrganizationManagerPreferenceListResponse;
  organizationsList: OrganizationListResponse;
  paymentTransaction?: Maybe<PaymentTransaction>;
  paymentTransactionsList: PaymentTransactionListResponse;
  postMention?: Maybe<PostMention>;
  postMentionsList: PostMentionListResponse;
  /**
   * DEPRECATED: Use server-side automatic moderation instead, reserved for compatibility purposes.
   * @deprecated Use server-side automatic moderation instead, reserved for compatibility purposes.
   */
  postModerateRequest: PostModerateRequestSuccess;
  remoteVariable?: Maybe<RemoteVariable>;
  remoteVariablesList: RemoteVariableListResponse;
  role?: Maybe<Role>;
  roleAddon?: Maybe<RoleAddon>;
  roleAddonsList: RoleAddonListResponse;
  rolesList: RoleListResponse;
  schoolConfiguration?: Maybe<SchoolConfiguration>;
  schoolConfigurationColorScheme?: Maybe<SchoolConfigurationColorScheme>;
  schoolConfigurationColorSchemesList: SchoolConfigurationColorSchemeListResponse;
  schoolConfigurationExternalLink?: Maybe<SchoolConfigurationExternalLink>;
  schoolConfigurationExternalLinksList: SchoolConfigurationExternalLinkListResponse;
  schoolConfigurationImage?: Maybe<SchoolConfigurationImage>;
  schoolConfigurationImagesList: SchoolConfigurationImageListResponse;
  schoolConfigurationLayout?: Maybe<SchoolConfigurationLayout>;
  schoolConfigurationLayoutsList: SchoolConfigurationLayoutListResponse;
  schoolConfigurationTopBar?: Maybe<SchoolConfigurationTopBar>;
  schoolConfigurationTopBarsList: SchoolConfigurationTopBarListResponse;
  schoolConfigurationWordsBlacklist?: Maybe<SchoolConfigurationWordsBlacklist>;
  schoolConfigurationWordsBlacklistsList: SchoolConfigurationWordsBlacklistListResponse;
  schoolConfigurationsList: SchoolConfigurationListResponse;
  schoolMembersReport: SchoolMembersReport;
  schoolNewsPost?: Maybe<SchoolNewsPost>;
  schoolNewsPostsList: SchoolNewsPostListResponse;
  schoolSupporter?: Maybe<SchoolSupporter>;
  schoolSupporterClick?: Maybe<SchoolSupporterClick>;
  schoolSupporterClicksList: SchoolSupporterClickListResponse;
  schoolSupporterSearch?: Maybe<SchoolSupporterSearch>;
  schoolSupporterSearchesList: SchoolSupporterSearchListResponse;
  schoolSupportersList: SchoolSupporterListResponse;
  settings?: Maybe<Setting>;
  streamChatAuthorize: StreamChatAuthorizeResponse;
  streamChatGetReactions: StreamChatGetReactionsResponse;
  stripeGetBillingTransactionsList?: Maybe<StripeGetBillingTransactionsListResponse>;
  stripeGetCustomerPaymentMethods?: Maybe<StripeGetCustomerPaymentMethodsResponse>;
  stripeInformation?: Maybe<StripeInformation>;
  stripeInformationsList: StripeInformationListResponse;
  system?: Maybe<SystemQuery>;
  /** @deprecated No longer supported. Use `system.table` instead. */
  table?: Maybe<Table>;
  /** @deprecated No longer supported. Use `system.tableField` instead. */
  tableField?: Maybe<TableField>;
  /** @deprecated No longer supported. Use `system.tablesList` instead. */
  tablesList: TableListResponse;
  teamMember?: Maybe<TeamMember>;
  teamMembersList: TeamMemberListResponse;
  trendingSearch?: Maybe<TrendingSearch>;
  trendingSearchesList: TrendingSearchListResponse;
  user?: Maybe<User>;
  userBillingConfiguration: UserBillingConfigurationResponse;
  userBillingConfigurationArchie: UserBillingConfigurationResponse;
  userConfiguration?: Maybe<UserConfiguration>;
  userConfigurationsList: UserConfigurationListResponse;
  /** @deprecated No longer supported. Use `system.userInvitationsList` instead. */
  userInvitationsList?: Maybe<UserInvitationList>;
  userNotification?: Maybe<UserNotification>;
  userNotificationsByUser?: Maybe<UserNotificationsByUser>;
  userNotificationsByUsersList: UserNotificationsByUserListResponse;
  userNotificationsList: UserNotificationListResponse;
  userPreference?: Maybe<UserPreference>;
  userPreferencesList: UserPreferenceListResponse;
  userSchoolMembership?: Maybe<UserSchoolMembership>;
  userSchoolMembershipsList: UserSchoolMembershipListResponse;
  userStatus?: Maybe<UserStatus>;
  userStatusesList: UserStatusListResponse;
  userSupportersOnliesList: UserSupportersOnlyListResponse;
  userSupportersOnly?: Maybe<UserSupportersOnly>;
  usersCount?: Maybe<UsersCount>;
  usersCountsList: UsersCountListResponse;
  usersList: UserListResponse;
  viewDryRun?: Maybe<ViewDryRunOutput>;
  /** @deprecated No longer supported. Use `system.workspacesList` instead. */
  workspacesList?: Maybe<WorkspaceListResponse>;
};

export type QueryAdClickArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAdClicksListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdClickFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdClickGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdClickOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdClickSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAdViewArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAdViewsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdViewFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdViewGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdViewOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdViewSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAddressArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAddressesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AddressFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AddressGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AddressOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AddressSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAdvertisingArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAdvertisingCampaignAdArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAdvertisingCampaignAdsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingCampaignAdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingCampaignAdGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingCampaignAdOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingCampaignAdSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAdvertisingSampaignArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAdvertisingSampaignsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingSampaignFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingSampaignGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingSampaignOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingSampaignSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAdvertisingSupporterArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAdvertisingSupportersListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingSupporterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingSupporterGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingSupporterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingSupporterSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAdvertisingsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryApiTokenArgs = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryApiTokensListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ApiTokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<ApiTokenGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<ApiTokenOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ApiTokenSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryApplicationArgs = {
  id: Scalars['String'];
};

export type QueryAsyncSessionStatusArgs = {
  sessionId: Scalars['String'];
};

export type QueryAudienceArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAudiencesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AudienceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AudienceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AudienceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AudienceSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAuthenticationProfileArgs = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAuthenticationProfilesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AuthenticationProfileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AuthenticationProfileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AuthenticationProfileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AuthenticationProfileSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBillingCurrentPlanArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type QueryBillingInvoicesListArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  by?: InputMaybe<BillingInvoicesListFilterType>;
  limit?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['ID']>;
};

export type QueryBillingMetricUsageQuotasListArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
};

export type QueryBillingMetricUsagesListArgs = {
  filter?: InputMaybe<BillingMetricUsagesListFilter>;
  organizationId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
};

export type QueryBillingRegisteredAdArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBillingRegisteredAdsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingRegisteredAdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingRegisteredAdGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingRegisteredAdOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingRegisteredAdSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBillingRegisteredSchoolSupporterArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBillingRegisteredSchoolSupportersListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingRegisteredSchoolSupporterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingRegisteredSchoolSupporterGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingRegisteredSchoolSupporterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingRegisteredSchoolSupporterSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBillingRegisteredSpecialOfferArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBillingRegisteredSpecialOffersListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingRegisteredSpecialOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingRegisteredSpecialOfferGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingRegisteredSpecialOfferOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingRegisteredSpecialOfferSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBillingSchoolSupporterArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBillingSchoolSupportersListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingSchoolSupporterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingSchoolSupporterGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingSchoolSupporterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingSchoolSupporterSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBillingSpecialOfferArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBillingSpecialOffersListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingSpecialOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingSpecialOfferGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingSpecialOfferOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingSpecialOfferSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBillingsAdArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBillingsAdsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingsAdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingsAdGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingsAdOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingsAdSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBusinessFilterByZipRequestArgs = {
  filter?: InputMaybe<OrganizationFilter>;
  radius: Scalars['String'];
  startPointZip: Scalars['String'];
};

export type QueryBusinessReportArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBusinessReportsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BusinessReportFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BusinessReportGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BusinessReportOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BusinessReportSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCareersFilterByZipRequestArgs = {
  careerFilter?: InputMaybe<JobOfferFilter>;
  careersFirst?: InputMaybe<Scalars['Int']>;
  careersSkip?: InputMaybe<Scalars['Int']>;
  careersSort?: InputMaybe<Array<JobOfferSort>>;
  radius: Scalars['String'];
  startPointZip: Scalars['String'];
};

export type QueryCheckResetPasswordCodeArgs = {
  data: CheckResetPasswordCodeInput;
};

export type QueryCiCdMigrationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCiCdMigrationsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CiCdMigrationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<CiCdMigrationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<CiCdMigrationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<CiCdMigrationSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCollectiveIntelligenceThreadArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCollectiveIntelligenceThreadsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CollectiveIntelligenceThreadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<CollectiveIntelligenceThreadGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<CollectiveIntelligenceThreadOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<CollectiveIntelligenceThreadSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryContentEngagementReportsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ContentReportFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContentReportOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ContentReportSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryContentReportArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryContentReportsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ContentReportFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<ContentReportGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContentReportOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ContentReportSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCurrentLocationZipRequestArgs = {
  data?: InputMaybe<CurrentLocationZipRequestInput>;
};

export type QueryDashboardArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDashboardsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DashboardFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DashboardGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DashboardOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DashboardSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDebtArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDebtOperationHistoriesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DebtOperationHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DebtOperationHistoryGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DebtOperationHistoryOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DebtOperationHistorySort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDebtOperationHistoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDebtsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DebtFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DebtGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DebtOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DebtSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDeployStatusArgs = {
  buildName: Scalars['String'];
};

export type QueryDiscountArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDiscountRedemptionArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDiscountRedemptionsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DiscountRedemptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DiscountRedemptionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DiscountRedemptionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DiscountRedemptionSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDiscountsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DiscountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DiscountGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DiscountOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DiscountSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEducationalInstitutionArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEducationalInstitutionsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EducationalInstitutionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EducationalInstitutionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EducationalInstitutionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EducationalInstitutionSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEnvironmentVariableArgs = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEnvironmentVariablesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EnvironmentVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EnvironmentVariableGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EnvironmentVariableOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EnvironmentVariableSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEventArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEventSponsorRequestArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEventSponsorRequestsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EventSponsorRequestFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EventSponsorRequestGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EventSponsorRequestOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EventSponsorRequestSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEventsFilterByZipRequestArgs = {
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  proximitySort?: InputMaybe<Scalars['String']>;
  radius: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EventSort>>;
  startPointZip: Scalars['String'];
};

export type QueryEventsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EventGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EventOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EventSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFileArgs = {
  fileId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFilesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FileSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFilesSettingArgs = {
  filemanagername?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFilesSettingsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilesSettingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FilesSettingGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FilesSettingOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FilesSettingSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFunctionsListArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FunctionInfoFilter>;
  orderBy?: InputMaybe<Array<InputMaybe<FunctionInfoOrderBy>>>;
};

export type QueryGenerateLinkPreviewArgs = {
  url: Scalars['String'];
};

export type QueryGraduatingYearArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type QueryGraduatingYearsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GraduatingYearFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GraduatingYearGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GraduatingYearOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GraduatingYearSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGraduatingYearsMetricArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGraduatingYearsMetricsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GraduatingYearsMetricFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GraduatingYearsMetricGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GraduatingYearsMetricOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GraduatingYearsMetricSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGroupAdminPreferenceArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGroupAdminPreferencesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupAdminPreferenceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupAdminPreferenceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupAdminPreferenceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupAdminPreferenceSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGroupPostArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGroupPostsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupPostSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGroupsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGroupsMembersRequestArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGroupsMembersRequestsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupsMembersRequestFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupsMembersRequestGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupsMembersRequestOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupsMembersRequestSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGroupsStatArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGroupsStatsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupsStatFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupsStatGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupsStatOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupsStatSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryHighSchoolLocationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryHighSchoolLocationsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HighSchoolLocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<HighSchoolLocationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<HighSchoolLocationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HighSchoolLocationSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryHomeFeedItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryHomeFeedItemsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HomeFeedItemFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<HomeFeedItemGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<HomeFeedItemOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HomeFeedItemSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryHomeFeedPostArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryHomeFeedPostsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HomeFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<HomeFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<HomeFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HomeFeedPostSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryInboxArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryInboxMessageArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryInboxMessageMediaArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryInboxMessageMediasListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxMessageMediaFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxMessageMediaGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxMessageMediaOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxMessageMediaSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryInboxMessagesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxMessageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxMessageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxMessageSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryInboxesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryIsAllowedCallbackUrlArgs = {
  callbackURL: Scalars['String'];
};

export type QueryJobEmployeeArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryJobEmployeesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<JobEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<JobEmployeeGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<JobEmployeeOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<JobEmployeeSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryJobOfferArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryJobOffersListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<JobOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<JobOfferGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<JobOfferOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<JobOfferSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLegacyFeedMonthArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLegacyFeedMonthsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedMonthFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedMonthGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedMonthOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedMonthSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLegacyFeedPostArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLegacyFeedPostsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedPostSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLegacyFeedYearArgs = {
  id?: InputMaybe<Scalars['ID']>;
  legacyYear?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLegacyFeedYearsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedYearFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedYearGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedYearOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedYearSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLogsArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  functionName: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type QueryNotificationTemplateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryNotificationTemplatesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<NotificationTemplateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<NotificationTemplateGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<NotificationTemplateOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<NotificationTemplateSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOrganizationLikeArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOrganizationLikesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationLikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationLikeGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationLikeOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationLikeSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOrganizationLocationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOrganizationLocationsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationLocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationLocationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationLocationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationLocationSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOrganizationManagerPreferenceArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOrganizationManagerPreferencesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationManagerPreferenceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationManagerPreferenceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationManagerPreferenceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationManagerPreferenceSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOrganizationsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPaymentTransactionArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPaymentTransactionsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PaymentTransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<PaymentTransactionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<PaymentTransactionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PaymentTransactionSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPostMentionArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPostMentionsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PostMentionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<PostMentionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<PostMentionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PostMentionSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPostModerateRequestArgs = {
  blacklistId: Scalars['String'];
  media?: InputMaybe<Array<FileKeyFilter>>;
  postId?: InputMaybe<Scalars['String']>;
  postType?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type QueryRemoteVariableArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRemoteVariablesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RemoteVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RemoteVariableGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RemoteVariableOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RemoteVariableSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRoleAddonArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRoleAddonsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleAddonFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleAddonGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleAddonOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleAddonSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRolesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationColorSchemeArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationColorSchemesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationColorSchemeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationColorSchemeGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationColorSchemeOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationColorSchemeSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationExternalLinkArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationExternalLinksListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationExternalLinkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationExternalLinkGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationExternalLinkOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationExternalLinkSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationImageArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationImagesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationImageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationImageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationImageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationImageSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationLayoutArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationLayoutsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationLayoutFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationLayoutGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationLayoutOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationLayoutSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationTopBarArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationTopBarsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationTopBarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationTopBarGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationTopBarOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationTopBarSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationWordsBlacklistArgs = {
  blacklistId?: InputMaybe<Scalars['String']>;
  blacklistLabel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationWordsBlacklistsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationWordsBlacklistFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationWordsBlacklistGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationWordsBlacklistOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationWordsBlacklistSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolConfigurationsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolMembersReportArgs = {
  filter?: InputMaybe<UserSchoolMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  radius?: InputMaybe<Scalars['String']>;
  school: SchoolConfigurationKeyFilter;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSchoolMembershipSort>>;
  startPointZip?: InputMaybe<Scalars['String']>;
};

export type QuerySchoolNewsPostArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolNewsPostsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolNewsPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolNewsPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolNewsPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolNewsPostSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolSupporterArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolSupporterClickArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolSupporterClicksListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolSupporterClickFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolSupporterClickGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolSupporterClickOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolSupporterClickSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolSupporterSearchArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolSupporterSearchesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolSupporterSearchFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolSupporterSearchGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolSupporterSearchOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolSupporterSearchSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySchoolSupportersListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolSupporterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolSupporterGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolSupporterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolSupporterSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryStripeGetBillingTransactionsListArgs = {
  customer: Scalars['String'];
  endDate?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  pageId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Int']>;
};

export type QueryStripeGetCustomerPaymentMethodsArgs = {
  customer: Scalars['String'];
};

export type QueryStripeInformationArgs = {
  connectedAccountId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryStripeInformationsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<StripeInformationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<StripeInformationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<StripeInformationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<StripeInformationSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTableArgs = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type QueryTableFieldArgs = {
  id: Scalars['ID'];
};

export type QueryTablesListArgs = {
  filter?: InputMaybe<TableListFilter>;
};

export type QueryTeamMemberArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTeamMembersListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TeamMemberFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<TeamMemberGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TeamMemberSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTrendingSearchArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
  wordOrPhrase?: InputMaybe<Scalars['String']>;
};

export type QueryTrendingSearchesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TrendingSearchFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<TrendingSearchGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<TrendingSearchOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TrendingSearchSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserBillingConfigurationArgs = {
  filterPlanProjects?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  region?: InputMaybe<Scalars['String']>;
};

export type QueryUserBillingConfigurationArchieArgs = {
  ideaId?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  region?: InputMaybe<Scalars['String']>;
};

export type QueryUserConfigurationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserConfigurationsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserConfigurationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserConfigurationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserConfigurationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserConfigurationSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserNotificationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserNotificationsByUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserNotificationsByUsersListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserNotificationsByUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserNotificationsByUserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserNotificationsByUserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserNotificationsByUserSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserNotificationsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserNotificationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserNotificationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserNotificationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserNotificationSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserPreferenceArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserPreferencesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserPreferenceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserPreferenceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserPreferenceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserPreferenceSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserSchoolMembershipArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserSchoolMembershipsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserSchoolMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserSchoolMembershipGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserSchoolMembershipOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSchoolMembershipSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserStatusArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserStatusesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserStatusFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserStatusGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserStatusOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserStatusSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserSupportersOnliesListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserSupportersOnlyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserSupportersOnlyGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserSupportersOnlyOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSupportersOnlySort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserSupportersOnlyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUsersCountArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUsersCountsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UsersCountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UsersCountGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UsersCountOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UsersCountSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUsersListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryViewDryRunArgs = {
  sql: Scalars['String'];
};

/** UserNotificationsByUser create input from receiver */
export type Receiver_UserNotificationsByUserCreateInput = {
  isRead?: InputMaybe<Scalars['Boolean']>;
  notification: UserNotificationsByUserNotificationRelationInput;
  receiver?: InputMaybe<UserNotificationsByUserReceiverRelationInput>;
};

/** UserNotificationsByUser update input from receiver */
export type Receiver_UserNotificationsByUserUpdateInput = {
  data: UserNotificationsByUserUpdateInput;
  filter?: InputMaybe<UserNotificationsByUserKeyFilter>;
};

/** Users create input from redeemedDiscounts */
export type RedeemedDiscounts_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from redeemedDiscounts */
export type RedeemedDiscounts_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

/** Discounts create input from redemptions */
export type Redemptions_DiscountCreateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingRelationInput>;
  amountPercent: Scalars['Int'];
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostRelationInput>;
  expirationDate: Scalars['DateTime'];
  images?: InputMaybe<DiscountsImagesRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationRelationInput>;
  payment?: InputMaybe<DiscountsPaymentRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsRelationInput>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  terms: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

/** Discounts update input from redemptions */
export type Redemptions_DiscountUpdateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingUpdateRelationInput>;
  amountPercent?: InputMaybe<Scalars['Int']>;
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsUpdateRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostUpdateRelationInput>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<DiscountsImagesUpdateRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationUpdateRelationInput>;
  payment?: InputMaybe<DiscountsPaymentUpdateRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsUpdateRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsUpdateRelationInput>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  terms?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedUpdateRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

/** RefreshTokenInput */
export type RefreshTokenInput = {
  authProfileId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  refreshToken: Scalars['String'];
};

export type RegisterBusinessUserResponse = {
  __typename?: 'RegisterBusinessUserResponse';
  /** The session token to be used to authenticate the user. */
  session: Scalars['String'];
};

/** BillingsAds create input from registeredBillingAds */
export type RegisteredBillingAds_BillingsAdCreateInput = {
  ads?: InputMaybe<BillingsAdsAdsRelationInput>;
  organization?: InputMaybe<BillingsAdsOrganizationRelationInput>;
  registeredBillingAds?: InputMaybe<BillingsAdsRegisteredBillingAdsRelationInput>;
};

/** BillingsAds update input from registeredBillingAds */
export type RegisteredBillingAds_BillingsAdUpdateInput = {
  ads?: InputMaybe<BillingsAdsAdsUpdateRelationInput>;
  organization?: InputMaybe<BillingsAdsOrganizationUpdateRelationInput>;
  registeredBillingAds?: InputMaybe<BillingsAdsRegisteredBillingAdsUpdateRelationInput>;
};

/** BillingSpecialOffers create input from registeredSpecialOffers */
export type RegisteredSpecialOffers_BillingSpecialOfferCreateInput = {
  organization?: InputMaybe<BillingSpecialOffersOrganizationRelationInput>;
  registeredSpecialOffers?: InputMaybe<BillingSpecialOffersRegisteredSpecialOffersRelationInput>;
};

/** BillingSpecialOffers update input from registeredSpecialOffers */
export type RegisteredSpecialOffers_BillingSpecialOfferUpdateInput = {
  organization?: InputMaybe<BillingSpecialOffersOrganizationUpdateRelationInput>;
  registeredSpecialOffers?: InputMaybe<BillingSpecialOffersRegisteredSpecialOffersUpdateRelationInput>;
};

/** Relation */
export type Relation = {
  __typename?: 'Relation';
  refField?: Maybe<TableField>;
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList?: Maybe<Scalars['Boolean']>;
  refFieldIsRequired?: Maybe<Scalars['Boolean']>;
  refFieldName?: Maybe<Scalars['String']>;
  refTable: Table;
  relationFieldName?: Maybe<Scalars['String']>;
  relationTableName?: Maybe<Scalars['String']>;
};

/** Relation Create Input */
export type RelationCreateInput = {
  refFieldDisplayName?: InputMaybe<Scalars['String']>;
  refFieldIsList: Scalars['Boolean'];
  refFieldIsRequired: Scalars['Boolean'];
  refFieldName?: InputMaybe<Scalars['String']>;
  refTableId: Scalars['ID'];
};

/** Relation Update Input */
export type RelationUpdateInput = {
  refFieldDisplayName?: InputMaybe<Scalars['String']>;
  refFieldIsList?: InputMaybe<Scalars['Boolean']>;
  refFieldIsRequired?: InputMaybe<Scalars['Boolean']>;
  refFieldName?: InputMaybe<Scalars['String']>;
  refTableId?: InputMaybe<Scalars['ID']>;
};

/** Relative Date Predicate Operation Enum */
export type RelativePredicateOpEnum = 'ADD' | 'SUB';

/** Relative Date Predicate Unit Enum */
export type RelativePredicateUnitEnum =
  | 'DAY'
  | 'DAY_HOUR'
  | 'DAY_MICROSECOND'
  | 'DAY_MINUTE'
  | 'DAY_SECOND'
  | 'HOUR'
  | 'HOUR_MICROSECOND'
  | 'HOUR_MINUTE'
  | 'HOUR_SECOND'
  | 'MICROSECOND'
  | 'MINUTE'
  | 'MINUTE_MICROSECOND'
  | 'MINUTE_SECOND'
  | 'MONTH'
  | 'QUARTER'
  | 'SECOND'
  | 'SECOND_MICROSECOND'
  | 'WEEK'
  | 'YEAR'
  | 'YEAR_MONTH';

/** Used to store all the configurations available for the application. Should only be one record. */
export type RemoteVariable = {
  __typename?: 'RemoteVariable';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  /** Maximum number of days that a job offer can be active. */
  jobOfferExpirationDays?: Maybe<Scalars['Int']>;
  /** Sets the maximum debt amount allowed on USD dollars. */
  maxDebtAmount?: Maybe<Scalars['Float']>;
  /** Sets the minimal amount of USD dollars to charge on a bill. */
  minChargeAmount?: Maybe<Scalars['Float']>;
  /** Sets the maximum debt amount allowed on USD dollars. */
  minDebtAmount?: Maybe<Scalars['Float']>;
  /** Sets the maximum of USD dollars amount on a debt to be classified as overflow. */
  overflowDebtAmount?: Maybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the organization for each school support. */
  schoolSupportAmount?: Maybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the client per each advertising impression for school supporters */
  serveAdvertisingSupportUnitAmount?: Maybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the client per each advertising impression */
  serveAdvertisingUnitAmount?: Maybe<Scalars['Float']>;
  /** Toggle the balance indicator on the advertising client. */
  showOrganizationBalance?: Maybe<Scalars['Boolean']>;
  /** Amount on USD dollars charge to the client per each day that a special offer is been shown */
  specialOfferDailyAmount?: Maybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the client per each day that a special offer is been shown for supporters of that school */
  specialOfferDailySupporterAmount?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** RemoteVariables create input */
export type RemoteVariableCreateInput = {
  /** Maximum number of days that a job offer can be active. */
  jobOfferExpirationDays?: InputMaybe<Scalars['Int']>;
  /** Sets the maximum debt amount allowed on USD dollars. */
  maxDebtAmount?: InputMaybe<Scalars['Float']>;
  /** Sets the minimal amount of USD dollars to charge on a bill. */
  minChargeAmount?: InputMaybe<Scalars['Float']>;
  /** Sets the maximum debt amount allowed on USD dollars. */
  minDebtAmount?: InputMaybe<Scalars['Float']>;
  /** Sets the maximum of USD dollars amount on a debt to be classified as overflow. */
  overflowDebtAmount?: InputMaybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the organization for each school support. */
  schoolSupportAmount?: InputMaybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the client per each advertising impression for school supporters */
  serveAdvertisingSupportUnitAmount?: InputMaybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the client per each advertising impression */
  serveAdvertisingUnitAmount?: InputMaybe<Scalars['Float']>;
  /** Toggle the balance indicator on the advertising client. */
  showOrganizationBalance?: InputMaybe<Scalars['Boolean']>;
  /** Amount on USD dollars charge to the client per each day that a special offer is been shown */
  specialOfferDailyAmount?: InputMaybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the client per each day that a special offer is been shown for supporters of that school */
  specialOfferDailySupporterAmount?: InputMaybe<Scalars['Float']>;
};

/** RemoteVariables create many input */
export type RemoteVariableCreateManyInput = {
  /** Maximum number of days that a job offer can be active. */
  jobOfferExpirationDays?: InputMaybe<Scalars['Int']>;
  /** Sets the maximum debt amount allowed on USD dollars. */
  maxDebtAmount?: InputMaybe<Scalars['Float']>;
  /** Sets the minimal amount of USD dollars to charge on a bill. */
  minChargeAmount?: InputMaybe<Scalars['Float']>;
  /** Sets the maximum debt amount allowed on USD dollars. */
  minDebtAmount?: InputMaybe<Scalars['Float']>;
  /** Sets the maximum of USD dollars amount on a debt to be classified as overflow. */
  overflowDebtAmount?: InputMaybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the organization for each school support. */
  schoolSupportAmount?: InputMaybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the client per each advertising impression for school supporters */
  serveAdvertisingSupportUnitAmount?: InputMaybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the client per each advertising impression */
  serveAdvertisingUnitAmount?: InputMaybe<Scalars['Float']>;
  /** Toggle the balance indicator on the advertising client. */
  showOrganizationBalance?: InputMaybe<Scalars['Boolean']>;
  /** Amount on USD dollars charge to the client per each day that a special offer is been shown */
  specialOfferDailyAmount?: InputMaybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the client per each day that a special offer is been shown for supporters of that school */
  specialOfferDailySupporterAmount?: InputMaybe<Scalars['Float']>;
};

/** RemoteVariables delete input */
export type RemoteVariableDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** RemoteVariableFieldsPermissions create input */
export type RemoteVariableFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  jobOfferExpirationDays?: InputMaybe<Scalars['Boolean']>;
  maxDebtAmount?: InputMaybe<Scalars['Boolean']>;
  minChargeAmount?: InputMaybe<Scalars['Boolean']>;
  minDebtAmount?: InputMaybe<Scalars['Boolean']>;
  overflowDebtAmount?: InputMaybe<Scalars['Boolean']>;
  schoolSupportAmount?: InputMaybe<Scalars['Boolean']>;
  serveAdvertisingSupportUnitAmount?: InputMaybe<Scalars['Boolean']>;
  serveAdvertisingUnitAmount?: InputMaybe<Scalars['Boolean']>;
  showOrganizationBalance?: InputMaybe<Scalars['Boolean']>;
  specialOfferDailyAmount?: InputMaybe<Scalars['Boolean']>;
  specialOfferDailySupporterAmount?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type RemoteVariableFilter = {
  AND?: InputMaybe<Array<RemoteVariableFilter>>;
  OR?: InputMaybe<Array<RemoteVariableFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  jobOfferExpirationDays?: InputMaybe<IntPredicate>;
  maxDebtAmount?: InputMaybe<FloatPredicate>;
  minChargeAmount?: InputMaybe<FloatPredicate>;
  minDebtAmount?: InputMaybe<FloatPredicate>;
  overflowDebtAmount?: InputMaybe<FloatPredicate>;
  schoolSupportAmount?: InputMaybe<FloatPredicate>;
  serveAdvertisingSupportUnitAmount?: InputMaybe<FloatPredicate>;
  serveAdvertisingUnitAmount?: InputMaybe<FloatPredicate>;
  showOrganizationBalance?: InputMaybe<BoolPredicate>;
  specialOfferDailyAmount?: InputMaybe<FloatPredicate>;
  specialOfferDailySupporterAmount?: InputMaybe<FloatPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type RemoteVariableGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: RemoteVariableGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type RemoteVariableGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  jobOfferExpirationDays?: InputMaybe<Array<GroupByField>>;
  maxDebtAmount?: InputMaybe<Array<GroupByField>>;
  minChargeAmount?: InputMaybe<Array<GroupByField>>;
  minDebtAmount?: InputMaybe<Array<GroupByField>>;
  overflowDebtAmount?: InputMaybe<Array<GroupByField>>;
  schoolSupportAmount?: InputMaybe<Array<GroupByField>>;
  serveAdvertisingSupportUnitAmount?: InputMaybe<Array<GroupByField>>;
  serveAdvertisingUnitAmount?: InputMaybe<Array<GroupByField>>;
  showOrganizationBalance?: InputMaybe<Array<GroupByField>>;
  specialOfferDailyAmount?: InputMaybe<Array<GroupByField>>;
  specialOfferDailySupporterAmount?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type RemoteVariableKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** RemoteVariableListResponse output */
export type RemoteVariableListResponse = {
  __typename?: 'RemoteVariableListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<RemoteVariable>;
};

/** RemoteVariableManyResponse output */
export type RemoteVariableManyResponse = {
  __typename?: 'RemoteVariableManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<RemoteVariable>;
};

/** No longer supported. Use `sort` instead. */
export type RemoteVariableOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'jobOfferExpirationDays_ASC'
  | 'jobOfferExpirationDays_DESC'
  | 'maxDebtAmount_ASC'
  | 'maxDebtAmount_DESC'
  | 'minChargeAmount_ASC'
  | 'minChargeAmount_DESC'
  | 'minDebtAmount_ASC'
  | 'minDebtAmount_DESC'
  | 'overflowDebtAmount_ASC'
  | 'overflowDebtAmount_DESC'
  | 'schoolSupportAmount_ASC'
  | 'schoolSupportAmount_DESC'
  | 'serveAdvertisingSupportUnitAmount_ASC'
  | 'serveAdvertisingSupportUnitAmount_DESC'
  | 'serveAdvertisingUnitAmount_ASC'
  | 'serveAdvertisingUnitAmount_DESC'
  | 'showOrganizationBalance_ASC'
  | 'showOrganizationBalance_DESC'
  | 'specialOfferDailyAmount_ASC'
  | 'specialOfferDailyAmount_DESC'
  | 'specialOfferDailySupporterAmount_ASC'
  | 'specialOfferDailySupporterAmount_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** RemoteVariables subscription payload */
export type RemoteVariablePayload = {
  __typename?: 'RemoteVariablePayload';
  mutation: MutationType;
  node?: Maybe<RemoteVariable>;
  previousValues?: Maybe<RemoteVariable>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RemoteVariableSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jobOfferExpirationDays?: InputMaybe<SortOrder>;
  maxDebtAmount?: InputMaybe<SortOrder>;
  minChargeAmount?: InputMaybe<SortOrder>;
  minDebtAmount?: InputMaybe<SortOrder>;
  overflowDebtAmount?: InputMaybe<SortOrder>;
  schoolSupportAmount?: InputMaybe<SortOrder>;
  serveAdvertisingSupportUnitAmount?: InputMaybe<SortOrder>;
  serveAdvertisingUnitAmount?: InputMaybe<SortOrder>;
  showOrganizationBalance?: InputMaybe<SortOrder>;
  specialOfferDailyAmount?: InputMaybe<SortOrder>;
  specialOfferDailySupporterAmount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** RemoteVariables subscription filter */
export type RemoteVariableSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<RemoteVariableFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** RemoteVariables update input */
export type RemoteVariableUpdateByFilterInput = {
  jobOfferExpirationDays?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  maxDebtAmount?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  minChargeAmount?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  minDebtAmount?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  overflowDebtAmount?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  schoolSupportAmount?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  serveAdvertisingSupportUnitAmount?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  serveAdvertisingUnitAmount?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  showOrganizationBalance?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  specialOfferDailyAmount?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  specialOfferDailySupporterAmount?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
};

/** RemoteVariables update input */
export type RemoteVariableUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  /** Maximum number of days that a job offer can be active. */
  jobOfferExpirationDays?: InputMaybe<Scalars['Int']>;
  /** Sets the maximum debt amount allowed on USD dollars. */
  maxDebtAmount?: InputMaybe<Scalars['Float']>;
  /** Sets the minimal amount of USD dollars to charge on a bill. */
  minChargeAmount?: InputMaybe<Scalars['Float']>;
  /** Sets the maximum debt amount allowed on USD dollars. */
  minDebtAmount?: InputMaybe<Scalars['Float']>;
  /** Sets the maximum of USD dollars amount on a debt to be classified as overflow. */
  overflowDebtAmount?: InputMaybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the organization for each school support. */
  schoolSupportAmount?: InputMaybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the client per each advertising impression for school supporters */
  serveAdvertisingSupportUnitAmount?: InputMaybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the client per each advertising impression */
  serveAdvertisingUnitAmount?: InputMaybe<Scalars['Float']>;
  /** Toggle the balance indicator on the advertising client. */
  showOrganizationBalance?: InputMaybe<Scalars['Boolean']>;
  /** Amount on USD dollars charge to the client per each day that a special offer is been shown */
  specialOfferDailyAmount?: InputMaybe<Scalars['Float']>;
  /** Amount on USD dollars charge to the client per each day that a special offer is been shown for supporters of that school */
  specialOfferDailySupporterAmount?: InputMaybe<Scalars['Float']>;
};

export type RemoteVariable_PermissionFilter = {
  AND?: InputMaybe<Array<RemoteVariable_PermissionFilter>>;
  OR?: InputMaybe<Array<RemoteVariable_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  jobOfferExpirationDays?: InputMaybe<IntPredicate>;
  maxDebtAmount?: InputMaybe<FloatPredicate>;
  minChargeAmount?: InputMaybe<FloatPredicate>;
  minDebtAmount?: InputMaybe<FloatPredicate>;
  overflowDebtAmount?: InputMaybe<FloatPredicate>;
  schoolSupportAmount?: InputMaybe<FloatPredicate>;
  serveAdvertisingSupportUnitAmount?: InputMaybe<FloatPredicate>;
  serveAdvertisingUnitAmount?: InputMaybe<FloatPredicate>;
  showOrganizationBalance?: InputMaybe<BoolPredicate>;
  specialOfferDailyAmount?: InputMaybe<FloatPredicate>;
  specialOfferDailySupporterAmount?: InputMaybe<FloatPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type ReplaceFunctionArguments = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type ResizeImageDirectiveCropOptions = {
  height: Scalars['Int'];
  offsetX?: InputMaybe<Scalars['Int']>;
  offsetY?: InputMaybe<Scalars['Int']>;
  width: Scalars['Int'];
};

export type ResizeImageDirectiveFlipOptions = {
  horizontally?: InputMaybe<Scalars['Boolean']>;
  vertically?: InputMaybe<Scalars['Boolean']>;
};

export type ResizeImageDirectiveResizeOptions = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type Role = {
  __typename?: 'Role';
  _description?: Maybe<Scalars['String']>;
  addon?: Maybe<RoleAddonListResponse>;
  apiTokens?: Maybe<ApiTokenListResponse>;
  authenticationProfiles?: Maybe<AuthenticationProfileListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  membersCount: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  notificationTemplates?: Maybe<NotificationTemplateListResponse>;
  permissions?: Maybe<PermissionListResponse>;
  teamMembers?: Maybe<TeamMemberListResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserListResponse>;
};

export type RoleAddonArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleAddonFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleAddonGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleAddonOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleAddonSort>>;
};

export type RoleApiTokensArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ApiTokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<ApiTokenGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<ApiTokenOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ApiTokenSort>>;
};

export type RoleAuthenticationProfilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AuthenticationProfileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AuthenticationProfileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AuthenticationProfileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AuthenticationProfileSort>>;
};

export type RoleNotificationTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<NotificationTemplateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<NotificationTemplateGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<NotificationTemplateOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<NotificationTemplateSort>>;
};

export type RolePermissionsArgs = {
  filter?: InputMaybe<PermissionInputFilter>;
};

export type RoleTeamMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TeamMemberFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<TeamMemberGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TeamMemberSort>>;
};

export type RoleUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

export type RoleAddon = {
  __typename?: 'RoleAddon';
  _description?: Maybe<Scalars['String']>;
  accessibleLayouts?: Maybe<SchoolConfigurationLayoutListResponse>;
  businessPermissions?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  forumPermissions?: Maybe<Scalars['JSON']>;
  groupsPermissions?: Maybe<Scalars['JSON']>;
  /** Define the permissions within the "Home Posts". */
  homePostsPermissions?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  /** Assigning this role to a user at registration */
  isDefault?: Maybe<Scalars['Boolean']>;
  isSystemRole?: Maybe<Scalars['Boolean']>;
  membersPermissions?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  schoolPostsPermissions?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserListResponse>;
};

export type RoleAddonAccessibleLayoutsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationLayoutFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationLayoutGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationLayoutOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationLayoutSort>>;
};

export type RoleAddonUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

/** RoleAddon relation input */
export type RoleAddonAccessibleLayoutsManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationLayoutKeyFilter>>;
};

/** RoleAddon relation input */
export type RoleAddonAccessibleLayoutsRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationLayoutKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<RoleAddon_SchoolConfigurationLayoutCreateInput>>>;
};

/** RoleAddon relation input */
export type RoleAddonAccessibleLayoutsUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationLayoutKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<RoleAddon_SchoolConfigurationLayoutCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationLayoutKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationLayoutKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<RoleAddon_SchoolConfigurationLayoutUpdateInput>>>;
};

/** RoleAddon create input */
export type RoleAddonCreateInput = {
  accessibleLayouts?: InputMaybe<RoleAddonAccessibleLayoutsRelationInput>;
  businessPermissions?: InputMaybe<Scalars['JSON']>;
  forumPermissions?: InputMaybe<Scalars['JSON']>;
  groupsPermissions?: InputMaybe<Scalars['JSON']>;
  /** Define the permissions within the "Home Posts". */
  homePostsPermissions?: InputMaybe<Scalars['JSON']>;
  /** Assigning this role to a user at registration */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isSystemRole?: InputMaybe<Scalars['Boolean']>;
  membersPermissions?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<RoleAddonRoleRelationInput>;
  school?: InputMaybe<RoleAddonSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  schoolPostsPermissions?: InputMaybe<Scalars['JSON']>;
  users?: InputMaybe<RoleAddonUsersRelationInput>;
};

/** RoleAddon create many input */
export type RoleAddonCreateManyInput = {
  accessibleLayouts?: InputMaybe<RoleAddonAccessibleLayoutsManyRelationInput>;
  businessPermissions?: InputMaybe<Scalars['JSON']>;
  forumPermissions?: InputMaybe<Scalars['JSON']>;
  groupsPermissions?: InputMaybe<Scalars['JSON']>;
  /** Define the permissions within the "Home Posts". */
  homePostsPermissions?: InputMaybe<Scalars['JSON']>;
  /** Assigning this role to a user at registration */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isSystemRole?: InputMaybe<Scalars['Boolean']>;
  membersPermissions?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  role: RoleAddonRoleManyRelationInput;
  school?: InputMaybe<RoleAddonSchoolManyRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  schoolPostsPermissions?: InputMaybe<Scalars['JSON']>;
  users?: InputMaybe<RoleAddonUsersManyRelationInput>;
};

/** RoleAddon delete input */
export type RoleAddonDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** RoleAddonFieldsPermissions create input */
export type RoleAddonFieldsPermissions = {
  businessPermissions?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  forumPermissions?: InputMaybe<Scalars['Boolean']>;
  groupsPermissions?: InputMaybe<Scalars['Boolean']>;
  homePostsPermissions?: InputMaybe<Scalars['Boolean']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isSystemRole?: InputMaybe<Scalars['Boolean']>;
  membersPermissions?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  schoolPostsPermissions?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type RoleAddonFilter = {
  AND?: InputMaybe<Array<RoleAddonFilter>>;
  OR?: InputMaybe<Array<RoleAddonFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  accessibleLayouts?: InputMaybe<SchoolConfigurationLayoutRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isDefault?: InputMaybe<BoolPredicate>;
  isSystemRole?: InputMaybe<BoolPredicate>;
  name?: InputMaybe<StringPredicate>;
  role?: InputMaybe<RoleFilter>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  users?: InputMaybe<UserRelationFilter>;
};

export type RoleAddonGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: RoleAddonGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type RoleAddonGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  accessibleLayouts?: InputMaybe<SchoolConfigurationLayoutGroupByQuery>;
  businessPermissions?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  forumPermissions?: InputMaybe<Array<GroupByField>>;
  groupsPermissions?: InputMaybe<Array<GroupByField>>;
  homePostsPermissions?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  isDefault?: InputMaybe<Array<GroupByField>>;
  isSystemRole?: InputMaybe<Array<GroupByField>>;
  membersPermissions?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  role?: InputMaybe<RoleGroupByQuery>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  schoolPostsPermissions?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  users?: InputMaybe<UserGroupByQuery>;
};

export type RoleAddonKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** RoleAddonListResponse output */
export type RoleAddonListResponse = {
  __typename?: 'RoleAddonListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<RoleAddon>;
};

/** RoleAddonManyResponse output */
export type RoleAddonManyResponse = {
  __typename?: 'RoleAddonManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<RoleAddon>;
};

/** No longer supported. Use `sort` instead. */
export type RoleAddonOrderBy =
  | 'businessPermissions_ASC'
  | 'businessPermissions_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'forumPermissions_ASC'
  | 'forumPermissions_DESC'
  | 'groupsPermissions_ASC'
  | 'groupsPermissions_DESC'
  | 'homePostsPermissions_ASC'
  | 'homePostsPermissions_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isDefault_ASC'
  | 'isDefault_DESC'
  | 'isSystemRole_ASC'
  | 'isSystemRole_DESC'
  | 'membersPermissions_ASC'
  | 'membersPermissions_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'schoolPostsPermissions_ASC'
  | 'schoolPostsPermissions_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** RoleAddon subscription payload */
export type RoleAddonPayload = {
  __typename?: 'RoleAddonPayload';
  mutation: MutationType;
  node?: Maybe<RoleAddon>;
  previousValues?: Maybe<RoleAddon>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RoleAddonRelationFilter = {
  every?: InputMaybe<RoleAddonFilter>;
  none?: InputMaybe<RoleAddonFilter>;
  some?: InputMaybe<RoleAddonFilter>;
};

/** RoleAddon relation input */
export type RoleAddonRoleManyRelationInput = {
  connect?: InputMaybe<RoleKeyFilter>;
};

/** RoleAddon relation input */
export type RoleAddonRoleRelationInput = {
  connect?: InputMaybe<RoleKeyFilter>;
  create?: InputMaybe<Addon_RoleCreateInput>;
};

/** RoleAddon relation input */
export type RoleAddonRoleUpdateRelationInput = {
  connect?: InputMaybe<RoleKeyFilter>;
  create?: InputMaybe<Addon_RoleCreateInput>;
  disconnect?: InputMaybe<RoleKeyFilter>;
  reconnect?: InputMaybe<RoleKeyFilter>;
  update?: InputMaybe<Addon_RoleUpdateInput>;
};

/** RoleAddon relation input */
export type RoleAddonSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** RoleAddon relation input */
export type RoleAddonSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<RoleAddons_SchoolConfigurationCreateInput>;
};

/** RoleAddon relation input */
export type RoleAddonSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<RoleAddons_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<RoleAddons_SchoolConfigurationUpdateInput>;
};

export type RoleAddonSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  isSystemRole?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  role?: InputMaybe<RoleSort>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** RoleAddon subscription filter */
export type RoleAddonSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<RoleAddonFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** RoleAddon update input */
export type RoleAddonUpdateByFilterInput = {
  businessPermissions?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  forumPermissions?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  groupsPermissions?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  homePostsPermissions?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  isDefault?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isSystemRole?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  membersPermissions?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  schoolPostsPermissions?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
};

/** RoleAddon update input */
export type RoleAddonUpdateInput = {
  accessibleLayouts?: InputMaybe<RoleAddonAccessibleLayoutsUpdateRelationInput>;
  businessPermissions?: InputMaybe<Scalars['JSON']>;
  forumPermissions?: InputMaybe<Scalars['JSON']>;
  groupsPermissions?: InputMaybe<Scalars['JSON']>;
  /** Define the permissions within the "Home Posts". */
  homePostsPermissions?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  /** Assigning this role to a user at registration */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isSystemRole?: InputMaybe<Scalars['Boolean']>;
  membersPermissions?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<RoleAddonRoleUpdateRelationInput>;
  school?: InputMaybe<RoleAddonSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  schoolPostsPermissions?: InputMaybe<Scalars['JSON']>;
  users?: InputMaybe<RoleAddonUsersUpdateRelationInput>;
};

/** RoleAddon relation input */
export type RoleAddonUsersManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** RoleAddon relation input */
export type RoleAddonUsersRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<RolesAddons_UserCreateInput>>>;
};

/** RoleAddon relation input */
export type RoleAddonUsersUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<RolesAddons_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<RolesAddons_UserUpdateInput>>>;
};

export type RoleAddon_PermissionFilter = {
  AND?: InputMaybe<Array<RoleAddon_PermissionFilter>>;
  OR?: InputMaybe<Array<RoleAddon_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  accessibleLayouts?: InputMaybe<SchoolConfigurationLayout_PermissionRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isDefault?: InputMaybe<BoolPredicate>;
  isSystemRole?: InputMaybe<BoolPredicate>;
  name?: InputMaybe<StringPredicate>;
  role?: InputMaybe<Role_PermissionFilter>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  users?: InputMaybe<User_PermissionRelationFilter>;
};

export type RoleAddon_PermissionRelationFilter = {
  every?: InputMaybe<RoleAddon_PermissionFilter>;
  none?: InputMaybe<RoleAddon_PermissionFilter>;
  some?: InputMaybe<RoleAddon_PermissionFilter>;
};

/** SchoolConfigurationLayouts create input from roleAddon */
export type RoleAddon_SchoolConfigurationLayoutCreateInput = {
  configuration: SchoolConfigurationLayoutsConfigurationRelationInput;
  icon?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAlwaysShown?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  navbarOrderNumber?: InputMaybe<Scalars['Int']>;
  roleAddon?: InputMaybe<SchoolConfigurationLayoutsRoleAddonRelationInput>;
  route?: InputMaybe<Scalars['String']>;
  sidebarOrderNumber: Scalars['Int'];
};

/** SchoolConfigurationLayouts update input from roleAddon */
export type RoleAddon_SchoolConfigurationLayoutUpdateInput = {
  data: SchoolConfigurationLayoutUpdateInput;
  filter?: InputMaybe<SchoolConfigurationLayoutKeyFilter>;
};

/** SchoolConfiguration create input from roleAddons */
export type RoleAddons_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from roleAddons */
export type RoleAddons_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** Roles create input */
export type RoleCreateInput = {
  addon?: InputMaybe<RolesAddonRelationInput>;
  apiTokens?: InputMaybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: InputMaybe<RolesAuthenticationProfilesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notificationTemplates?: InputMaybe<RolesNotificationTemplatesRelationInput>;
  permissions?: InputMaybe<PermissionsInput>;
  teamMembers?: InputMaybe<RolesTeamMembersRelationInput>;
  users?: InputMaybe<RolesUsersRelationInput>;
};

/** Roles create many input */
export type RoleCreateManyInput = {
  addon?: InputMaybe<RolesAddonManyRelationInput>;
  apiTokens?: InputMaybe<RolesApiTokensManyRelationInput>;
  authenticationProfiles?: InputMaybe<RolesAuthenticationProfilesManyRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notificationTemplates?: InputMaybe<RolesNotificationTemplatesManyRelationInput>;
  teamMembers?: InputMaybe<RolesTeamMembersManyRelationInput>;
  users?: InputMaybe<RolesUsersManyRelationInput>;
};

/** Roles delete input */
export type RoleDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type RoleEnum = 'ADMIN' | 'EDITOR' | 'VIEWER';

/** RoleFieldsPermissions create input */
export type RoleFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type RoleFilter = {
  AND?: InputMaybe<Array<RoleFilter>>;
  OR?: InputMaybe<Array<RoleFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  addon?: InputMaybe<RoleAddonRelationFilter>;
  apiTokens?: InputMaybe<ApiTokenRelationFilter>;
  authenticationProfiles?: InputMaybe<AuthenticationProfileRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  name?: InputMaybe<StringPredicate>;
  notificationTemplates?: InputMaybe<NotificationTemplateRelationFilter>;
  permissions?: InputMaybe<PermissionRelationFilter>;
  teamMembers?: InputMaybe<TeamMemberRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  users?: InputMaybe<UserRelationFilter>;
};

export type RoleGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: RoleGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type RoleGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  addon?: InputMaybe<RoleAddonGroupByQuery>;
  apiTokens?: InputMaybe<ApiTokenGroupByQuery>;
  authenticationProfiles?: InputMaybe<AuthenticationProfileGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  notificationTemplates?: InputMaybe<NotificationTemplateGroupByQuery>;
  permissions?: InputMaybe<PermissionGroupByQuery>;
  teamMembers?: InputMaybe<TeamMemberGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  users?: InputMaybe<UserGroupByQuery>;
};

export type RoleKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** RoleListResponse output */
export type RoleListResponse = {
  __typename?: 'RoleListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Role>;
};

/** RoleManyResponse output */
export type RoleManyResponse = {
  __typename?: 'RoleManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Role>;
};

/** No longer supported. Use `sort` instead. */
export type RoleOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'description_ASC'
  | 'description_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'systemType_ASC'
  | 'systemType_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** Roles subscription payload */
export type RolePayload = {
  __typename?: 'RolePayload';
  mutation: MutationType;
  node?: Maybe<Role>;
  previousValues?: Maybe<Role>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RoleRelationFilter = {
  every?: InputMaybe<RoleFilter>;
  none?: InputMaybe<RoleFilter>;
  some?: InputMaybe<RoleFilter>;
};

export type RoleSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Roles subscription filter */
export type RoleSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<RoleFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** Roles update input */
export type RoleUpdateByFilterInput = {
  description?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  systemType?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** Roles update input */
export type RoleUpdateInput = {
  addon?: InputMaybe<RolesAddonUpdateRelationInput>;
  apiTokens?: InputMaybe<RolesApiTokensUpdateRelationInput>;
  authenticationProfiles?: InputMaybe<RolesAuthenticationProfilesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  notificationTemplates?: InputMaybe<RolesNotificationTemplatesUpdateRelationInput>;
  permissions?: InputMaybe<PermissionsInput>;
  teamMembers?: InputMaybe<RolesTeamMembersUpdateRelationInput>;
  users?: InputMaybe<RolesUsersUpdateRelationInput>;
};

export type Role_PermissionFilter = {
  AND?: InputMaybe<Array<Role_PermissionFilter>>;
  OR?: InputMaybe<Array<Role_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  addon?: InputMaybe<RoleAddon_PermissionRelationFilter>;
  apiTokens?: InputMaybe<ApiToken_PermissionRelationFilter>;
  authenticationProfiles?: InputMaybe<AuthenticationProfile_PermissionRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  name?: InputMaybe<StringPredicate>;
  notificationTemplates?: InputMaybe<NotificationTemplate_PermissionRelationFilter>;
  permissions?: InputMaybe<Permission_PermissionRelationFilter>;
  teamMembers?: InputMaybe<TeamMember_PermissionRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  users?: InputMaybe<User_PermissionRelationFilter>;
};

export type Role_PermissionRelationFilter = {
  every?: InputMaybe<Role_PermissionFilter>;
  none?: InputMaybe<Role_PermissionFilter>;
  some?: InputMaybe<Role_PermissionFilter>;
};

/** RoleAddon create input from role */
export type Role_RoleAddonCreateInput = {
  accessibleLayouts?: InputMaybe<RoleAddonAccessibleLayoutsRelationInput>;
  businessPermissions?: InputMaybe<Scalars['JSON']>;
  forumPermissions?: InputMaybe<Scalars['JSON']>;
  groupsPermissions?: InputMaybe<Scalars['JSON']>;
  /** Define the permissions within the "Home Posts". */
  homePostsPermissions?: InputMaybe<Scalars['JSON']>;
  /** Assigning this role to a user at registration */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isSystemRole?: InputMaybe<Scalars['Boolean']>;
  membersPermissions?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<RoleAddonRoleRelationInput>;
  school?: InputMaybe<RoleAddonSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  schoolPostsPermissions?: InputMaybe<Scalars['JSON']>;
  users?: InputMaybe<RoleAddonUsersRelationInput>;
};

/** RoleAddon update input from role */
export type Role_RoleAddonUpdateInput = {
  data: RoleAddonUpdateInput;
  filter?: InputMaybe<RoleAddonKeyFilter>;
};

/** Roles relation input */
export type RolesAddonManyRelationInput = {
  connect?: InputMaybe<Array<RoleAddonKeyFilter>>;
};

/** Roles relation input */
export type RolesAddonRelationInput = {
  connect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Role_RoleAddonCreateInput>>>;
};

/** Roles relation input */
export type RolesAddonUpdateRelationInput = {
  connect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Role_RoleAddonCreateInput>>>;
  disconnect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  reconnect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Role_RoleAddonUpdateInput>>>;
};

/** Users create input from rolesAddons */
export type RolesAddons_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from rolesAddons */
export type RolesAddons_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

/** Roles relation input */
export type RolesApiTokensManyRelationInput = {
  connect?: InputMaybe<Array<ApiTokenKeyFilter>>;
};

/** Roles relation input */
export type RolesApiTokensRelationInput = {
  connect?: InputMaybe<Array<ApiTokenKeyFilter>>;
};

/** Roles relation input */
export type RolesApiTokensUpdateRelationInput = {
  connect?: InputMaybe<Array<ApiTokenKeyFilter>>;
  disconnect?: InputMaybe<Array<ApiTokenKeyFilter>>;
  reconnect?: InputMaybe<Array<ApiTokenKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Roles_ApiTokenUpdateInput>>>;
};

/** Roles relation input */
export type RolesAuthenticationProfilesManyRelationInput = {
  connect?: InputMaybe<Array<AuthenticationProfileKeyFilter>>;
};

/** Roles relation input */
export type RolesAuthenticationProfilesRelationInput = {
  connect?: InputMaybe<Array<AuthenticationProfileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Roles_AuthenticationProfileCreateInput>>>;
};

/** Roles relation input */
export type RolesAuthenticationProfilesUpdateRelationInput = {
  connect?: InputMaybe<Array<AuthenticationProfileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Roles_AuthenticationProfileCreateInput>>>;
  disconnect?: InputMaybe<Array<AuthenticationProfileKeyFilter>>;
  reconnect?: InputMaybe<Array<AuthenticationProfileKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Roles_AuthenticationProfileUpdateInput>>>;
};

/** Roles relation input */
export type RolesNotificationTemplatesManyRelationInput = {
  connect?: InputMaybe<Array<NotificationTemplateKeyFilter>>;
};

/** Roles relation input */
export type RolesNotificationTemplatesRelationInput = {
  connect?: InputMaybe<Array<NotificationTemplateKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Roles_NotificationTemplateCreateInput>>>;
};

/** Roles relation input */
export type RolesNotificationTemplatesUpdateRelationInput = {
  connect?: InputMaybe<Array<NotificationTemplateKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Roles_NotificationTemplateCreateInput>>>;
  disconnect?: InputMaybe<Array<NotificationTemplateKeyFilter>>;
  reconnect?: InputMaybe<Array<NotificationTemplateKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Roles_NotificationTemplateUpdateInput>>>;
};

/** Roles relation input */
export type RolesTeamMembersManyRelationInput = {
  connect?: InputMaybe<Array<TeamMemberKeyFilter>>;
};

/** Roles relation input */
export type RolesTeamMembersRelationInput = {
  connect?: InputMaybe<Array<TeamMemberKeyFilter>>;
};

/** Roles relation input */
export type RolesTeamMembersUpdateRelationInput = {
  connect?: InputMaybe<Array<TeamMemberKeyFilter>>;
  disconnect?: InputMaybe<Array<TeamMemberKeyFilter>>;
  reconnect?: InputMaybe<Array<TeamMemberKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Roles_TeamMemberUpdateInput>>>;
};

/** Roles relation input */
export type RolesUsersManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** Roles relation input */
export type RolesUsersRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Roles_UserCreateInput>>>;
};

/** Roles relation input */
export type RolesUsersUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Roles_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Roles_UserUpdateInput>>>;
};

/** ApiTokens update input from roles */
export type Roles_ApiTokenUpdateInput = {
  data: ApiTokenUpdateInput;
  filter?: InputMaybe<ApiTokenKeyFilter>;
};

/** AuthenticationProfiles create input from roles */
export type Roles_AuthenticationProfileCreateInput = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clientId?: InputMaybe<Scalars['String']>;
  databaseName?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  managementDomain?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  roles?: InputMaybe<AuthenticationProfilesRolesRelationInput>;
  secret?: InputMaybe<Scalars['String']>;
  selfSignUpEmailDomains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selfSignUpEnabled?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

/** AuthenticationProfiles update input from roles */
export type Roles_AuthenticationProfileUpdateInput = {
  data: AuthenticationProfileUpdateInput;
  filter?: InputMaybe<AuthenticationProfileKeyFilter>;
};

/** NotificationTemplates create input from roles */
export type Roles_NotificationTemplateCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<NotificationTemplatesRolesRelationInput>;
  title?: InputMaybe<Scalars['String']>;
  userNotifications?: InputMaybe<NotificationTemplatesUserNotificationsRelationInput>;
};

/** NotificationTemplates update input from roles */
export type Roles_NotificationTemplateUpdateInput = {
  data: NotificationTemplateUpdateInput;
  filter?: InputMaybe<NotificationTemplateKeyFilter>;
};

/** TeamMembers update input from roles */
export type Roles_TeamMemberUpdateInput = {
  data: TeamMemberUpdateInput;
  filter?: InputMaybe<TeamMemberKeyFilter>;
};

/** Users create input from roles */
export type Roles_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from roles */
export type Roles_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

/** AdvertisingCampaignAds create input from sampaign */
export type Sampaign_AdvertisingCampaignAdCreateInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdRelationInput>;
  callToAction: Scalars['String'];
  callToActionLink: Scalars['String'];
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  primaryText: Scalars['String'];
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign?: InputMaybe<AdvertisingCampaignAdsSampaignRelationInput>;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget: Scalars['Float'];
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

/** AdvertisingCampaignAds update input from sampaign */
export type Sampaign_AdvertisingCampaignAdUpdateInput = {
  data: AdvertisingCampaignAdUpdateInput;
  filter?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
};

/** Schema Origin */
export type SchemaOrigin = {
  __typename?: 'SchemaOrigin';
  provider?: Maybe<Scalars['String']>;
  type: SchemaOriginType;
};

/** Schema Origin Type Enum */
export type SchemaOriginType = 'LOCAL' | 'REMOTE' | 'VIEW';

/** Audiences create input from schoolCommunities */
export type SchoolCommunities_AudienceCreateInput = {
  ad?: InputMaybe<AudiencesAdRelationInput>;
  affiliation?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  distanceFromZip?: InputMaybe<Scalars['Int']>;
  endAge?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  groups?: InputMaybe<AudiencesGroupsRelationInput>;
  highSchools?: InputMaybe<AudiencesHighSchoolsRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  organization?: InputMaybe<AudiencesOrganizationRelationInput>;
  schoolCommunities?: InputMaybe<AudiencesSchoolCommunitiesRelationInput>;
  size?: InputMaybe<Scalars['Int']>;
  startAge?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zipCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Audiences update input from schoolCommunities */
export type SchoolCommunities_AudienceUpdateInput = {
  data: AudienceUpdateInput;
  filter?: InputMaybe<AudienceKeyFilter>;
};

export type SchoolConfiguration = {
  __typename?: 'SchoolConfiguration';
  _description?: Maybe<Scalars['String']>;
  adClicks?: Maybe<AdClickListResponse>;
  adViews?: Maybe<AdViewListResponse>;
  address?: Maybe<Address>;
  audiences?: Maybe<AudienceListResponse>;
  classScorecard?: Maybe<GraduatingYearListResponse>;
  colorSchema?: Maybe<SchoolConfigurationColorScheme>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  dashboardMetrics?: Maybe<DashboardListResponse>;
  debtOperations?: Maybe<DebtOperationHistoryListResponse>;
  deletedAt?: Maybe<Scalars['Int']>;
  discountRedemptions?: Maybe<DiscountRedemptionListResponse>;
  email?: Maybe<Scalars['String']>;
  events?: Maybe<EventListResponse>;
  externalLinks?: Maybe<SchoolConfigurationExternalLinkListResponse>;
  forumThreads?: Maybe<CollectiveIntelligenceThreadListResponse>;
  fullName?: Maybe<Scalars['String']>;
  groups?: Maybe<GroupListResponse>;
  homePosts?: Maybe<HomeFeedPostListResponse>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<SchoolConfigurationImage>;
  jobOffers?: Maybe<JobOfferListResponse>;
  layout?: Maybe<SchoolConfigurationLayoutListResponse>;
  legacyFeedMonths?: Maybe<LegacyFeedMonthListResponse>;
  legacyFeedYears?: Maybe<LegacyFeedYearListResponse>;
  legacyPosts?: Maybe<LegacyFeedPostListResponse>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: Maybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: Maybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: Maybe<Scalars['Float']>;
  /** Display name for school */
  name?: Maybe<Scalars['String']>;
  offers?: Maybe<DiscountListResponse>;
  roleAddons?: Maybe<RoleAddonListResponse>;
  schoolPosts?: Maybe<SchoolNewsPostListResponse>;
  schoolSupporter?: Maybe<SchoolSupporterListResponse>;
  /** TODO */
  timezone?: Maybe<Scalars['String']>;
  topBar?: Maybe<SchoolConfigurationTopBar>;
  trendingSearches?: Maybe<TrendingSearchListResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userMemberships?: Maybe<UserSchoolMembershipListResponse>;
  usersCount?: Maybe<UsersCount>;
  wordsBlacklist?: Maybe<SchoolConfigurationWordsBlacklist>;
};

export type SchoolConfigurationAdClicksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdClickFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdClickGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdClickOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdClickSort>>;
};

export type SchoolConfigurationAdViewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdViewFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdViewGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdViewOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdViewSort>>;
};

export type SchoolConfigurationAudiencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AudienceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AudienceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AudienceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AudienceSort>>;
};

export type SchoolConfigurationClassScorecardArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GraduatingYearFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GraduatingYearGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GraduatingYearOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GraduatingYearSort>>;
};

export type SchoolConfigurationDashboardMetricsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DashboardFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DashboardGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DashboardOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DashboardSort>>;
};

export type SchoolConfigurationDebtOperationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DebtOperationHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DebtOperationHistoryGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DebtOperationHistoryOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DebtOperationHistorySort>>;
};

export type SchoolConfigurationDiscountRedemptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DiscountRedemptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DiscountRedemptionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DiscountRedemptionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DiscountRedemptionSort>>;
};

export type SchoolConfigurationEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EventGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EventOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EventSort>>;
};

export type SchoolConfigurationExternalLinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationExternalLinkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationExternalLinkGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationExternalLinkOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationExternalLinkSort>>;
};

export type SchoolConfigurationForumThreadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CollectiveIntelligenceThreadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<CollectiveIntelligenceThreadGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<CollectiveIntelligenceThreadOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<CollectiveIntelligenceThreadSort>>;
};

export type SchoolConfigurationGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupSort>>;
};

export type SchoolConfigurationHomePostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HomeFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<HomeFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<HomeFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HomeFeedPostSort>>;
};

export type SchoolConfigurationJobOffersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<JobOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<JobOfferGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<JobOfferOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<JobOfferSort>>;
};

export type SchoolConfigurationLayoutArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolConfigurationLayoutFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolConfigurationLayoutGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolConfigurationLayoutOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolConfigurationLayoutSort>>;
};

export type SchoolConfigurationLegacyFeedMonthsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedMonthFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedMonthGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedMonthOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedMonthSort>>;
};

export type SchoolConfigurationLegacyFeedYearsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedYearFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedYearGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedYearOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedYearSort>>;
};

export type SchoolConfigurationLegacyPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedPostSort>>;
};

export type SchoolConfigurationOffersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DiscountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DiscountGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DiscountOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DiscountSort>>;
};

export type SchoolConfigurationRoleAddonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleAddonFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleAddonGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleAddonOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleAddonSort>>;
};

export type SchoolConfigurationSchoolPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolNewsPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolNewsPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolNewsPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolNewsPostSort>>;
};

export type SchoolConfigurationSchoolSupporterArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolSupporterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolSupporterGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolSupporterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolSupporterSort>>;
};

export type SchoolConfigurationTrendingSearchesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TrendingSearchFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<TrendingSearchGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<TrendingSearchOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TrendingSearchSort>>;
};

export type SchoolConfigurationUserMembershipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserSchoolMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserSchoolMembershipGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserSchoolMembershipOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSchoolMembershipSort>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationAdClicksManyRelationInput = {
  connect?: InputMaybe<Array<AdClickKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationAdClicksRelationInput = {
  connect?: InputMaybe<Array<AdClickKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_AdClickCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationAdClicksUpdateRelationInput = {
  connect?: InputMaybe<Array<AdClickKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_AdClickCreateInput>>>;
  disconnect?: InputMaybe<Array<AdClickKeyFilter>>;
  reconnect?: InputMaybe<Array<AdClickKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_AdClickUpdateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationAdViewsManyRelationInput = {
  connect?: InputMaybe<Array<AdViewKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationAdViewsRelationInput = {
  connect?: InputMaybe<Array<AdViewKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_AdViewCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationAdViewsUpdateRelationInput = {
  connect?: InputMaybe<Array<AdViewKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_AdViewCreateInput>>>;
  disconnect?: InputMaybe<Array<AdViewKeyFilter>>;
  reconnect?: InputMaybe<Array<AdViewKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_AdViewUpdateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationAddressManyRelationInput = {
  connect?: InputMaybe<AddressKeyFilter>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationAddressRelationInput = {
  connect?: InputMaybe<AddressKeyFilter>;
  create?: InputMaybe<Configuration_AddressCreateInput>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationAddressUpdateRelationInput = {
  connect?: InputMaybe<AddressKeyFilter>;
  create?: InputMaybe<Configuration_AddressCreateInput>;
  disconnect?: InputMaybe<AddressKeyFilter>;
  reconnect?: InputMaybe<AddressKeyFilter>;
  update?: InputMaybe<Configuration_AddressUpdateInput>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationAudiencesManyRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationAudiencesRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<SchoolCommunities_AudienceCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationAudiencesUpdateRelationInput = {
  connect?: InputMaybe<Array<AudienceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<SchoolCommunities_AudienceCreateInput>>>;
  disconnect?: InputMaybe<Array<AudienceKeyFilter>>;
  reconnect?: InputMaybe<Array<AudienceKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<SchoolCommunities_AudienceUpdateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationClassScorecardManyRelationInput = {
  connect?: InputMaybe<Array<GraduatingYearKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationClassScorecardRelationInput = {
  connect?: InputMaybe<Array<GraduatingYearKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_GraduatingYearCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationClassScorecardUpdateRelationInput = {
  connect?: InputMaybe<Array<GraduatingYearKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_GraduatingYearCreateInput>>>;
  disconnect?: InputMaybe<Array<GraduatingYearKeyFilter>>;
  reconnect?: InputMaybe<Array<GraduatingYearKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_GraduatingYearUpdateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationColorSchemaManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationColorSchemeKeyFilter>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationColorSchemaRelationInput = {
  connect?: InputMaybe<SchoolConfigurationColorSchemeKeyFilter>;
  create?: InputMaybe<Configuration_SchoolConfigurationColorSchemeCreateInput>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationColorSchemaUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationColorSchemeKeyFilter>;
  create?: InputMaybe<Configuration_SchoolConfigurationColorSchemeCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationColorSchemeKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationColorSchemeKeyFilter>;
  update?: InputMaybe<Configuration_SchoolConfigurationColorSchemeUpdateInput>;
};

export type SchoolConfigurationColorScheme = {
  __typename?: 'SchoolConfigurationColorScheme';
  _description?: Maybe<Scalars['String']>;
  accent1?: Maybe<Scalars['String']>;
  accent2?: Maybe<Scalars['String']>;
  configuration?: Maybe<SchoolConfiguration>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  primary?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  sidebar?: Maybe<Scalars['String']>;
  sidebarFont?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** SchoolConfigurationColorSchemes create input */
export type SchoolConfigurationColorSchemeCreateInput = {
  accent1?: InputMaybe<Scalars['String']>;
  accent2?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfigurationColorSchemesConfigurationRelationInput>;
  primary?: InputMaybe<Scalars['String']>;
  secondary?: InputMaybe<Scalars['String']>;
  sidebar?: InputMaybe<Scalars['String']>;
  sidebarFont?: InputMaybe<Scalars['String']>;
};

/** SchoolConfigurationColorSchemes create many input */
export type SchoolConfigurationColorSchemeCreateManyInput = {
  accent1?: InputMaybe<Scalars['String']>;
  accent2?: InputMaybe<Scalars['String']>;
  configuration: SchoolConfigurationColorSchemesConfigurationManyRelationInput;
  primary?: InputMaybe<Scalars['String']>;
  secondary?: InputMaybe<Scalars['String']>;
  sidebar?: InputMaybe<Scalars['String']>;
  sidebarFont?: InputMaybe<Scalars['String']>;
};

/** SchoolConfigurationColorSchemes delete input */
export type SchoolConfigurationColorSchemeDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolConfigurationColorSchemeFieldsPermissions create input */
export type SchoolConfigurationColorSchemeFieldsPermissions = {
  accent1?: InputMaybe<Scalars['Boolean']>;
  accent2?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  secondary?: InputMaybe<Scalars['Boolean']>;
  sidebar?: InputMaybe<Scalars['Boolean']>;
  sidebarFont?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolConfigurationColorSchemeFilter = {
  AND?: InputMaybe<Array<SchoolConfigurationColorSchemeFilter>>;
  OR?: InputMaybe<Array<SchoolConfigurationColorSchemeFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  accent1?: InputMaybe<StringPredicate>;
  accent2?: InputMaybe<StringPredicate>;
  configuration?: InputMaybe<SchoolConfigurationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  primary?: InputMaybe<StringPredicate>;
  secondary?: InputMaybe<StringPredicate>;
  sidebar?: InputMaybe<StringPredicate>;
  sidebarFont?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SchoolConfigurationColorSchemeGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: SchoolConfigurationColorSchemeGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SchoolConfigurationColorSchemeGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  accent1?: InputMaybe<Array<GroupByField>>;
  accent2?: InputMaybe<Array<GroupByField>>;
  configuration?: InputMaybe<SchoolConfigurationGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  primary?: InputMaybe<Array<GroupByField>>;
  secondary?: InputMaybe<Array<GroupByField>>;
  sidebar?: InputMaybe<Array<GroupByField>>;
  sidebarFont?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type SchoolConfigurationColorSchemeKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolConfigurationColorSchemeListResponse output */
export type SchoolConfigurationColorSchemeListResponse = {
  __typename?: 'SchoolConfigurationColorSchemeListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<SchoolConfigurationColorScheme>;
};

/** SchoolConfigurationColorSchemeManyResponse output */
export type SchoolConfigurationColorSchemeManyResponse = {
  __typename?: 'SchoolConfigurationColorSchemeManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SchoolConfigurationColorScheme>;
};

/** No longer supported. Use `sort` instead. */
export type SchoolConfigurationColorSchemeOrderBy =
  | 'accent1_ASC'
  | 'accent1_DESC'
  | 'accent2_ASC'
  | 'accent2_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'primary_ASC'
  | 'primary_DESC'
  | 'secondary_ASC'
  | 'secondary_DESC'
  | 'sidebarFont_ASC'
  | 'sidebarFont_DESC'
  | 'sidebar_ASC'
  | 'sidebar_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** SchoolConfigurationColorSchemes subscription payload */
export type SchoolConfigurationColorSchemePayload = {
  __typename?: 'SchoolConfigurationColorSchemePayload';
  mutation: MutationType;
  node?: Maybe<SchoolConfigurationColorScheme>;
  previousValues?: Maybe<SchoolConfigurationColorScheme>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SchoolConfigurationColorSchemeSort = {
  accent1?: InputMaybe<SortOrder>;
  accent2?: InputMaybe<SortOrder>;
  configuration?: InputMaybe<SchoolConfigurationSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  primary?: InputMaybe<SortOrder>;
  secondary?: InputMaybe<SortOrder>;
  sidebar?: InputMaybe<SortOrder>;
  sidebarFont?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** SchoolConfigurationColorSchemes subscription filter */
export type SchoolConfigurationColorSchemeSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<SchoolConfigurationColorSchemeFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** SchoolConfigurationColorSchemes update input */
export type SchoolConfigurationColorSchemeUpdateByFilterInput = {
  accent1?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  accent2?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  primary?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  secondary?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  sidebar?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  sidebarFont?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** SchoolConfigurationColorSchemes update input */
export type SchoolConfigurationColorSchemeUpdateInput = {
  accent1?: InputMaybe<Scalars['String']>;
  accent2?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfigurationColorSchemesConfigurationUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  primary?: InputMaybe<Scalars['String']>;
  secondary?: InputMaybe<Scalars['String']>;
  sidebar?: InputMaybe<Scalars['String']>;
  sidebarFont?: InputMaybe<Scalars['String']>;
};

export type SchoolConfigurationColorScheme_PermissionFilter = {
  AND?: InputMaybe<Array<SchoolConfigurationColorScheme_PermissionFilter>>;
  OR?: InputMaybe<Array<SchoolConfigurationColorScheme_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  accent1?: InputMaybe<StringPredicate>;
  accent2?: InputMaybe<StringPredicate>;
  configuration?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  primary?: InputMaybe<StringPredicate>;
  secondary?: InputMaybe<StringPredicate>;
  sidebar?: InputMaybe<StringPredicate>;
  sidebarFont?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

/** SchoolConfigurationColorSchemes relation input */
export type SchoolConfigurationColorSchemesConfigurationManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** SchoolConfigurationColorSchemes relation input */
export type SchoolConfigurationColorSchemesConfigurationRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<ColorSchema_SchoolConfigurationCreateInput>;
};

/** SchoolConfigurationColorSchemes relation input */
export type SchoolConfigurationColorSchemesConfigurationUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<ColorSchema_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<ColorSchema_SchoolConfigurationUpdateInput>;
};

/** SchoolConfiguration create input */
export type SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration create many input */
export type SchoolConfigurationCreateManyInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksManyRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsManyRelationInput>;
  address: SchoolConfigurationAddressManyRelationInput;
  audiences?: InputMaybe<SchoolConfigurationAudiencesManyRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardManyRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaManyRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsManyRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsManyRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsManyRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsManyRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksManyRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsManyRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsManyRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsManyRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesManyRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersManyRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutManyRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsManyRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsManyRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsManyRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersManyRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsManyRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsManyRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterManyRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarManyRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesManyRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsManyRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountManyRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistManyRelationInput>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationDashboardMetricsManyRelationInput = {
  connect?: InputMaybe<Array<DashboardKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationDashboardMetricsRelationInput = {
  connect?: InputMaybe<Array<DashboardKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_DashboardCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationDashboardMetricsUpdateRelationInput = {
  connect?: InputMaybe<Array<DashboardKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_DashboardCreateInput>>>;
  disconnect?: InputMaybe<Array<DashboardKeyFilter>>;
  reconnect?: InputMaybe<Array<DashboardKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_DashboardUpdateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationDebtOperationsManyRelationInput = {
  connect?: InputMaybe<Array<DebtOperationHistoryKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationDebtOperationsRelationInput = {
  connect?: InputMaybe<Array<DebtOperationHistoryKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Schools_DebtOperationHistoryCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationDebtOperationsUpdateRelationInput = {
  connect?: InputMaybe<Array<DebtOperationHistoryKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Schools_DebtOperationHistoryCreateInput>>>;
  disconnect?: InputMaybe<Array<DebtOperationHistoryKeyFilter>>;
  reconnect?: InputMaybe<Array<DebtOperationHistoryKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Schools_DebtOperationHistoryUpdateInput>>>;
};

/** SchoolConfiguration delete input */
export type SchoolConfigurationDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationDiscountRedemptionsManyRelationInput = {
  connect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationDiscountRedemptionsRelationInput = {
  connect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_DiscountRedemptionCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationDiscountRedemptionsUpdateRelationInput = {
  connect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_DiscountRedemptionCreateInput>>>;
  disconnect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
  reconnect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_DiscountRedemptionUpdateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationEventsManyRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationEventsRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_EventCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationEventsUpdateRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_EventCreateInput>>>;
  disconnect?: InputMaybe<Array<EventKeyFilter>>;
  reconnect?: InputMaybe<Array<EventKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_EventUpdateInput>>>;
};

export type SchoolConfigurationExternalLink = {
  __typename?: 'SchoolConfigurationExternalLink';
  _description?: Maybe<Scalars['String']>;
  configuration?: Maybe<SchoolConfiguration>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** SchoolConfigurationExternalLinks create input */
export type SchoolConfigurationExternalLinkCreateInput = {
  configuration?: InputMaybe<SchoolConfigurationExternalLinksConfigurationRelationInput>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  link: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

/** SchoolConfigurationExternalLinks create many input */
export type SchoolConfigurationExternalLinkCreateManyInput = {
  configuration: SchoolConfigurationExternalLinksConfigurationManyRelationInput;
  isActive?: InputMaybe<Scalars['Boolean']>;
  link: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

/** SchoolConfigurationExternalLinks delete input */
export type SchoolConfigurationExternalLinkDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolConfigurationExternalLinkFieldsPermissions create input */
export type SchoolConfigurationExternalLinkFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolConfigurationExternalLinkFilter = {
  AND?: InputMaybe<Array<SchoolConfigurationExternalLinkFilter>>;
  OR?: InputMaybe<Array<SchoolConfigurationExternalLinkFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfigurationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isActive?: InputMaybe<BoolPredicate>;
  link?: InputMaybe<StringPredicate>;
  name?: InputMaybe<StringPredicate>;
  order?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SchoolConfigurationExternalLinkGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: SchoolConfigurationExternalLinkGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SchoolConfigurationExternalLinkGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  configuration?: InputMaybe<SchoolConfigurationGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isActive?: InputMaybe<Array<GroupByField>>;
  link?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  order?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type SchoolConfigurationExternalLinkKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolConfigurationExternalLinkListResponse output */
export type SchoolConfigurationExternalLinkListResponse = {
  __typename?: 'SchoolConfigurationExternalLinkListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<SchoolConfigurationExternalLink>;
};

/** SchoolConfigurationExternalLinkManyResponse output */
export type SchoolConfigurationExternalLinkManyResponse = {
  __typename?: 'SchoolConfigurationExternalLinkManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SchoolConfigurationExternalLink>;
};

/** No longer supported. Use `sort` instead. */
export type SchoolConfigurationExternalLinkOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isActive_ASC'
  | 'isActive_DESC'
  | 'link_ASC'
  | 'link_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'order_ASC'
  | 'order_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** SchoolConfigurationExternalLinks subscription payload */
export type SchoolConfigurationExternalLinkPayload = {
  __typename?: 'SchoolConfigurationExternalLinkPayload';
  mutation: MutationType;
  node?: Maybe<SchoolConfigurationExternalLink>;
  previousValues?: Maybe<SchoolConfigurationExternalLink>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SchoolConfigurationExternalLinkRelationFilter = {
  every?: InputMaybe<SchoolConfigurationExternalLinkFilter>;
  none?: InputMaybe<SchoolConfigurationExternalLinkFilter>;
  some?: InputMaybe<SchoolConfigurationExternalLinkFilter>;
};

export type SchoolConfigurationExternalLinkSort = {
  configuration?: InputMaybe<SchoolConfigurationSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** SchoolConfigurationExternalLinks subscription filter */
export type SchoolConfigurationExternalLinkSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<SchoolConfigurationExternalLinkFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** SchoolConfigurationExternalLinks update input */
export type SchoolConfigurationExternalLinkUpdateByFilterInput = {
  isActive?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  link?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  order?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
};

/** SchoolConfigurationExternalLinks update input */
export type SchoolConfigurationExternalLinkUpdateInput = {
  configuration?: InputMaybe<SchoolConfigurationExternalLinksConfigurationUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

export type SchoolConfigurationExternalLink_PermissionFilter = {
  AND?: InputMaybe<Array<SchoolConfigurationExternalLink_PermissionFilter>>;
  OR?: InputMaybe<Array<SchoolConfigurationExternalLink_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isActive?: InputMaybe<BoolPredicate>;
  link?: InputMaybe<StringPredicate>;
  name?: InputMaybe<StringPredicate>;
  order?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SchoolConfigurationExternalLink_PermissionRelationFilter = {
  every?: InputMaybe<SchoolConfigurationExternalLink_PermissionFilter>;
  none?: InputMaybe<SchoolConfigurationExternalLink_PermissionFilter>;
  some?: InputMaybe<SchoolConfigurationExternalLink_PermissionFilter>;
};

/** SchoolConfigurationExternalLinks relation input */
export type SchoolConfigurationExternalLinksConfigurationManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** SchoolConfigurationExternalLinks relation input */
export type SchoolConfigurationExternalLinksConfigurationRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<ExternalLinks_SchoolConfigurationCreateInput>;
};

/** SchoolConfigurationExternalLinks relation input */
export type SchoolConfigurationExternalLinksConfigurationUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<ExternalLinks_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<ExternalLinks_SchoolConfigurationUpdateInput>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationExternalLinksManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationExternalLinkKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationExternalLinksRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationExternalLinkKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Configuration_SchoolConfigurationExternalLinkCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationExternalLinksUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationExternalLinkKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Configuration_SchoolConfigurationExternalLinkCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationExternalLinkKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationExternalLinkKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Configuration_SchoolConfigurationExternalLinkUpdateInput>>>;
};

/** SchoolConfigurationFieldsPermissions create input */
export type SchoolConfigurationFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['Boolean']>;
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  moderationThreshold?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolConfigurationFilter = {
  AND?: InputMaybe<Array<SchoolConfigurationFilter>>;
  OR?: InputMaybe<Array<SchoolConfigurationFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  adClicks?: InputMaybe<AdClickRelationFilter>;
  adViews?: InputMaybe<AdViewRelationFilter>;
  address?: InputMaybe<AddressFilter>;
  audiences?: InputMaybe<AudienceRelationFilter>;
  classScorecard?: InputMaybe<GraduatingYearRelationFilter>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemeFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  dashboardMetrics?: InputMaybe<DashboardRelationFilter>;
  debtOperations?: InputMaybe<DebtOperationHistoryRelationFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  discountRedemptions?: InputMaybe<DiscountRedemptionRelationFilter>;
  email?: InputMaybe<StringPredicate>;
  events?: InputMaybe<EventRelationFilter>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinkRelationFilter>;
  forumThreads?: InputMaybe<CollectiveIntelligenceThreadRelationFilter>;
  fullName?: InputMaybe<StringPredicate>;
  groups?: InputMaybe<GroupRelationFilter>;
  homePosts?: InputMaybe<HomeFeedPostRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  images?: InputMaybe<SchoolConfigurationImageFilter>;
  jobOffers?: InputMaybe<JobOfferRelationFilter>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationFilter>;
  legacyFeedMonths?: InputMaybe<LegacyFeedMonthRelationFilter>;
  legacyFeedYears?: InputMaybe<LegacyFeedYearRelationFilter>;
  legacyPosts?: InputMaybe<LegacyFeedPostRelationFilter>;
  moderateLegacyPosts?: InputMaybe<BoolPredicate>;
  moderateSchoolPosts?: InputMaybe<BoolPredicate>;
  moderationThreshold?: InputMaybe<FloatPredicate>;
  name?: InputMaybe<StringPredicate>;
  offers?: InputMaybe<DiscountRelationFilter>;
  roleAddons?: InputMaybe<RoleAddonRelationFilter>;
  schoolPosts?: InputMaybe<SchoolNewsPostRelationFilter>;
  schoolSupporter?: InputMaybe<SchoolSupporterRelationFilter>;
  timezone?: InputMaybe<StringPredicate>;
  topBar?: InputMaybe<SchoolConfigurationTopBarFilter>;
  trendingSearches?: InputMaybe<TrendingSearchRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userMemberships?: InputMaybe<UserSchoolMembershipRelationFilter>;
  usersCount?: InputMaybe<UsersCountFilter>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistFilter>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationForumThreadsManyRelationInput = {
  connect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationForumThreadsRelationInput = {
  connect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Schools_CollectiveIntelligenceThreadCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationForumThreadsUpdateRelationInput = {
  connect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Schools_CollectiveIntelligenceThreadCreateInput>>>;
  disconnect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  reconnect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Schools_CollectiveIntelligenceThreadUpdateInput>>>;
};

export type SchoolConfigurationGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: SchoolConfigurationGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SchoolConfigurationGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  adClicks?: InputMaybe<AdClickGroupByQuery>;
  adViews?: InputMaybe<AdViewGroupByQuery>;
  address?: InputMaybe<AddressGroupByQuery>;
  audiences?: InputMaybe<AudienceGroupByQuery>;
  classScorecard?: InputMaybe<GraduatingYearGroupByQuery>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemeGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  dashboardMetrics?: InputMaybe<DashboardGroupByQuery>;
  debtOperations?: InputMaybe<DebtOperationHistoryGroupByQuery>;
  discountRedemptions?: InputMaybe<DiscountRedemptionGroupByQuery>;
  email?: InputMaybe<Array<GroupByField>>;
  events?: InputMaybe<EventGroupByQuery>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinkGroupByQuery>;
  forumThreads?: InputMaybe<CollectiveIntelligenceThreadGroupByQuery>;
  fullName?: InputMaybe<Array<GroupByField>>;
  groups?: InputMaybe<GroupGroupByQuery>;
  homePosts?: InputMaybe<HomeFeedPostGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  images?: InputMaybe<SchoolConfigurationImageGroupByQuery>;
  jobOffers?: InputMaybe<JobOfferGroupByQuery>;
  layout?: InputMaybe<SchoolConfigurationLayoutGroupByQuery>;
  legacyFeedMonths?: InputMaybe<LegacyFeedMonthGroupByQuery>;
  legacyFeedYears?: InputMaybe<LegacyFeedYearGroupByQuery>;
  legacyPosts?: InputMaybe<LegacyFeedPostGroupByQuery>;
  moderateLegacyPosts?: InputMaybe<Array<GroupByField>>;
  moderateSchoolPosts?: InputMaybe<Array<GroupByField>>;
  moderationThreshold?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  offers?: InputMaybe<DiscountGroupByQuery>;
  roleAddons?: InputMaybe<RoleAddonGroupByQuery>;
  schoolPosts?: InputMaybe<SchoolNewsPostGroupByQuery>;
  schoolSupporter?: InputMaybe<SchoolSupporterGroupByQuery>;
  timezone?: InputMaybe<Array<GroupByField>>;
  topBar?: InputMaybe<SchoolConfigurationTopBarGroupByQuery>;
  trendingSearches?: InputMaybe<TrendingSearchGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  userMemberships?: InputMaybe<UserSchoolMembershipGroupByQuery>;
  usersCount?: InputMaybe<UsersCountGroupByQuery>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistGroupByQuery>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationGroupsManyRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationGroupsRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_GroupCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationGroupsUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_GroupCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_GroupUpdateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationHomePostsManyRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationHomePostsRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Schools_HomeFeedPostCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationHomePostsUpdateRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Schools_HomeFeedPostCreateInput>>>;
  disconnect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  reconnect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Schools_HomeFeedPostUpdateInput>>>;
};

export type SchoolConfigurationImage = {
  __typename?: 'SchoolConfigurationImage';
  _description?: Maybe<Scalars['String']>;
  /** Used when creating a shortcut on a mobile device. */
  applicationLogo?: Maybe<File>;
  applicationLogoId?: Maybe<Scalars['String']>;
  /** Used in web browsers and their tabs. */
  browserLogo?: Maybe<File>;
  browserLogoId?: Maybe<Scalars['String']>;
  configuration?: Maybe<SchoolConfiguration>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  emailBanner?: Maybe<File>;
  emailLogo?: Maybe<File>;
  id?: Maybe<Scalars['ID']>;
  logo?: Maybe<File>;
  splashImage?: Maybe<File>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** SchoolConfigurationImages create input */
export type SchoolConfigurationImageCreateInput = {
  applicationLogo?: InputMaybe<SchoolConfigurationImagesApplicationLogoRelationInput>;
  applicationLogoId?: InputMaybe<Scalars['String']>;
  browserLogo?: InputMaybe<SchoolConfigurationImagesBrowserLogoRelationInput>;
  browserLogoId?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfigurationImagesConfigurationRelationInput>;
  emailBanner?: InputMaybe<SchoolConfigurationImagesEmailBannerRelationInput>;
  emailLogo?: InputMaybe<SchoolConfigurationImagesEmailLogoRelationInput>;
  logo?: InputMaybe<SchoolConfigurationImagesLogoRelationInput>;
  splashImage?: InputMaybe<SchoolConfigurationImagesSplashImageRelationInput>;
};

/** SchoolConfigurationImages create many input */
export type SchoolConfigurationImageCreateManyInput = {
  applicationLogo?: InputMaybe<SchoolConfigurationImagesApplicationLogoManyRelationInput>;
  applicationLogoId?: InputMaybe<Scalars['String']>;
  browserLogo?: InputMaybe<SchoolConfigurationImagesBrowserLogoManyRelationInput>;
  browserLogoId?: InputMaybe<Scalars['String']>;
  configuration: SchoolConfigurationImagesConfigurationManyRelationInput;
  emailBanner?: InputMaybe<SchoolConfigurationImagesEmailBannerManyRelationInput>;
  emailLogo?: InputMaybe<SchoolConfigurationImagesEmailLogoManyRelationInput>;
  logo?: InputMaybe<SchoolConfigurationImagesLogoManyRelationInput>;
  splashImage?: InputMaybe<SchoolConfigurationImagesSplashImageManyRelationInput>;
};

/** SchoolConfigurationImages delete input */
export type SchoolConfigurationImageDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolConfigurationImageFieldsPermissions create input */
export type SchoolConfigurationImageFieldsPermissions = {
  applicationLogoId?: InputMaybe<Scalars['Boolean']>;
  browserLogoId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolConfigurationImageFilter = {
  AND?: InputMaybe<Array<SchoolConfigurationImageFilter>>;
  OR?: InputMaybe<Array<SchoolConfigurationImageFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  applicationLogo?: InputMaybe<FileFilter>;
  applicationLogoId?: InputMaybe<StringPredicate>;
  browserLogo?: InputMaybe<FileFilter>;
  browserLogoId?: InputMaybe<StringPredicate>;
  configuration?: InputMaybe<SchoolConfigurationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  emailBanner?: InputMaybe<FileFilter>;
  emailLogo?: InputMaybe<FileFilter>;
  id?: InputMaybe<IdPredicate>;
  logo?: InputMaybe<FileFilter>;
  splashImage?: InputMaybe<FileFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SchoolConfigurationImageGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: SchoolConfigurationImageGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SchoolConfigurationImageGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  applicationLogo?: InputMaybe<FileGroupByQuery>;
  applicationLogoId?: InputMaybe<Array<GroupByField>>;
  browserLogo?: InputMaybe<FileGroupByQuery>;
  browserLogoId?: InputMaybe<Array<GroupByField>>;
  configuration?: InputMaybe<SchoolConfigurationGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  emailBanner?: InputMaybe<FileGroupByQuery>;
  emailLogo?: InputMaybe<FileGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  logo?: InputMaybe<FileGroupByQuery>;
  splashImage?: InputMaybe<FileGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type SchoolConfigurationImageKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolConfigurationImageListResponse output */
export type SchoolConfigurationImageListResponse = {
  __typename?: 'SchoolConfigurationImageListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<SchoolConfigurationImage>;
};

/** SchoolConfigurationImageManyResponse output */
export type SchoolConfigurationImageManyResponse = {
  __typename?: 'SchoolConfigurationImageManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SchoolConfigurationImage>;
};

/** No longer supported. Use `sort` instead. */
export type SchoolConfigurationImageOrderBy =
  | 'applicationLogoId_ASC'
  | 'applicationLogoId_DESC'
  | 'browserLogoId_ASC'
  | 'browserLogoId_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** SchoolConfigurationImages subscription payload */
export type SchoolConfigurationImagePayload = {
  __typename?: 'SchoolConfigurationImagePayload';
  mutation: MutationType;
  node?: Maybe<SchoolConfigurationImage>;
  previousValues?: Maybe<SchoolConfigurationImage>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SchoolConfigurationImageRelationFilter = {
  every?: InputMaybe<SchoolConfigurationImageFilter>;
  none?: InputMaybe<SchoolConfigurationImageFilter>;
  some?: InputMaybe<SchoolConfigurationImageFilter>;
};

export type SchoolConfigurationImageSort = {
  applicationLogo?: InputMaybe<FileSort>;
  applicationLogoId?: InputMaybe<SortOrder>;
  browserLogo?: InputMaybe<FileSort>;
  browserLogoId?: InputMaybe<SortOrder>;
  configuration?: InputMaybe<SchoolConfigurationSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  emailBanner?: InputMaybe<FileSort>;
  emailLogo?: InputMaybe<FileSort>;
  id?: InputMaybe<SortOrder>;
  logo?: InputMaybe<FileSort>;
  splashImage?: InputMaybe<FileSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** SchoolConfigurationImages subscription filter */
export type SchoolConfigurationImageSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<SchoolConfigurationImageFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** SchoolConfigurationImages update input */
export type SchoolConfigurationImageUpdateByFilterInput = {
  applicationLogoId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  browserLogoId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** SchoolConfigurationImages update input */
export type SchoolConfigurationImageUpdateInput = {
  applicationLogo?: InputMaybe<SchoolConfigurationImagesApplicationLogoUpdateRelationInput>;
  applicationLogoId?: InputMaybe<Scalars['String']>;
  browserLogo?: InputMaybe<SchoolConfigurationImagesBrowserLogoUpdateRelationInput>;
  browserLogoId?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfigurationImagesConfigurationUpdateRelationInput>;
  emailBanner?: InputMaybe<SchoolConfigurationImagesEmailBannerUpdateRelationInput>;
  emailLogo?: InputMaybe<SchoolConfigurationImagesEmailLogoUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  logo?: InputMaybe<SchoolConfigurationImagesLogoUpdateRelationInput>;
  splashImage?: InputMaybe<SchoolConfigurationImagesSplashImageUpdateRelationInput>;
};

export type SchoolConfigurationImage_PermissionFilter = {
  AND?: InputMaybe<Array<SchoolConfigurationImage_PermissionFilter>>;
  OR?: InputMaybe<Array<SchoolConfigurationImage_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  applicationLogo?: InputMaybe<File_PermissionFilter>;
  applicationLogoId?: InputMaybe<StringPredicate>;
  browserLogo?: InputMaybe<File_PermissionFilter>;
  browserLogoId?: InputMaybe<StringPredicate>;
  configuration?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  emailBanner?: InputMaybe<File_PermissionFilter>;
  emailLogo?: InputMaybe<File_PermissionFilter>;
  id?: InputMaybe<IdPredicate>;
  logo?: InputMaybe<File_PermissionFilter>;
  splashImage?: InputMaybe<File_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SchoolConfigurationImage_PermissionRelationFilter = {
  every?: InputMaybe<SchoolConfigurationImage_PermissionFilter>;
  none?: InputMaybe<SchoolConfigurationImage_PermissionFilter>;
  some?: InputMaybe<SchoolConfigurationImage_PermissionFilter>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesApplicationLogoManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesApplicationLogoRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<SchoolConfigurationImages_ApplicationLogo_FileCreateInput>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesApplicationLogoUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<SchoolConfigurationImages_ApplicationLogo_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<SchoolConfigurationImages_ApplicationLogo_FileUpdateInput>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesBrowserLogoManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesBrowserLogoRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<SchoolConfigurationImages_BrowserLogo_FileCreateInput>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesBrowserLogoUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<SchoolConfigurationImages_BrowserLogo_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<SchoolConfigurationImages_BrowserLogo_FileUpdateInput>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesConfigurationManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesConfigurationRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<Images_SchoolConfigurationCreateInput>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesConfigurationUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<Images_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<Images_SchoolConfigurationUpdateInput>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesEmailBannerManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesEmailBannerRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<SchoolConfigurationImages_EmailBanner_FileCreateInput>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesEmailBannerUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<SchoolConfigurationImages_EmailBanner_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<SchoolConfigurationImages_EmailBanner_FileUpdateInput>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesEmailLogoManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesEmailLogoRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<SchoolConfigurationImages_EmailLogo_FileCreateInput>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesEmailLogoUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<SchoolConfigurationImages_EmailLogo_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<SchoolConfigurationImages_EmailLogo_FileUpdateInput>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesLogoManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesLogoRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<SchoolConfigurationImages_Logo_FileCreateInput>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesLogoUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<SchoolConfigurationImages_Logo_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<SchoolConfigurationImages_Logo_FileUpdateInput>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationImagesManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationImageKeyFilter>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationImagesRelationInput = {
  connect?: InputMaybe<SchoolConfigurationImageKeyFilter>;
  create?: InputMaybe<Configuration_SchoolConfigurationImageCreateInput>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesSplashImageManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesSplashImageRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<SchoolConfigurationImages_SplashImage_FileCreateInput>;
};

/** SchoolConfigurationImages relation input */
export type SchoolConfigurationImagesSplashImageUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<SchoolConfigurationImages_SplashImage_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<SchoolConfigurationImages_SplashImage_FileUpdateInput>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationImagesUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationImageKeyFilter>;
  create?: InputMaybe<Configuration_SchoolConfigurationImageCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationImageKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationImageKeyFilter>;
  update?: InputMaybe<Configuration_SchoolConfigurationImageUpdateInput>;
};

/** Files create input from schoolConfigurationImages_applicationLogo */
export type SchoolConfigurationImages_ApplicationLogo_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from schoolConfigurationImages_applicationLogo */
export type SchoolConfigurationImages_ApplicationLogo_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** Files create input from schoolConfigurationImages_browserLogo */
export type SchoolConfigurationImages_BrowserLogo_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from schoolConfigurationImages_browserLogo */
export type SchoolConfigurationImages_BrowserLogo_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** Files create input from schoolConfigurationImages_emailBanner */
export type SchoolConfigurationImages_EmailBanner_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from schoolConfigurationImages_emailBanner */
export type SchoolConfigurationImages_EmailBanner_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** Files create input from schoolConfigurationImages_emailLogo */
export type SchoolConfigurationImages_EmailLogo_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from schoolConfigurationImages_emailLogo */
export type SchoolConfigurationImages_EmailLogo_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** Files create input from schoolConfigurationImages_logo */
export type SchoolConfigurationImages_Logo_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from schoolConfigurationImages_logo */
export type SchoolConfigurationImages_Logo_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** Files create input from schoolConfigurationImages_splashImage */
export type SchoolConfigurationImages_SplashImage_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from schoolConfigurationImages_splashImage */
export type SchoolConfigurationImages_SplashImage_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationJobOffersManyRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationJobOffersRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Schools_JobOfferCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationJobOffersUpdateRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Schools_JobOfferCreateInput>>>;
  disconnect?: InputMaybe<Array<JobOfferKeyFilter>>;
  reconnect?: InputMaybe<Array<JobOfferKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Schools_JobOfferUpdateInput>>>;
};

export type SchoolConfigurationKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SchoolConfigurationLayout = {
  __typename?: 'SchoolConfigurationLayout';
  _description?: Maybe<Scalars['String']>;
  configuration?: Maybe<SchoolConfiguration>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAlwaysShown?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  navbarOrderNumber?: Maybe<Scalars['Int']>;
  roleAddon?: Maybe<RoleAddonListResponse>;
  route?: Maybe<Scalars['String']>;
  sidebarOrderNumber?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SchoolConfigurationLayoutRoleAddonArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleAddonFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleAddonGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleAddonOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleAddonSort>>;
};

/** SchoolConfigurationLayouts create input */
export type SchoolConfigurationLayoutCreateInput = {
  configuration?: InputMaybe<SchoolConfigurationLayoutsConfigurationRelationInput>;
  icon?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAlwaysShown?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  navbarOrderNumber?: InputMaybe<Scalars['Int']>;
  roleAddon?: InputMaybe<SchoolConfigurationLayoutsRoleAddonRelationInput>;
  route?: InputMaybe<Scalars['String']>;
  sidebarOrderNumber: Scalars['Int'];
};

/** SchoolConfigurationLayouts create many input */
export type SchoolConfigurationLayoutCreateManyInput = {
  configuration: SchoolConfigurationLayoutsConfigurationManyRelationInput;
  icon?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAlwaysShown?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  navbarOrderNumber?: InputMaybe<Scalars['Int']>;
  roleAddon?: InputMaybe<SchoolConfigurationLayoutsRoleAddonManyRelationInput>;
  route?: InputMaybe<Scalars['String']>;
  sidebarOrderNumber: Scalars['Int'];
};

/** SchoolConfigurationLayouts delete input */
export type SchoolConfigurationLayoutDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolConfigurationLayoutFieldsPermissions create input */
export type SchoolConfigurationLayoutFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  icon?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAlwaysShown?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  navbarOrderNumber?: InputMaybe<Scalars['Boolean']>;
  route?: InputMaybe<Scalars['Boolean']>;
  sidebarOrderNumber?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolConfigurationLayoutFilter = {
  AND?: InputMaybe<Array<SchoolConfigurationLayoutFilter>>;
  OR?: InputMaybe<Array<SchoolConfigurationLayoutFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfigurationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  icon?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  isActive?: InputMaybe<BoolPredicate>;
  isAlwaysShown?: InputMaybe<BoolPredicate>;
  name?: InputMaybe<StringPredicate>;
  navbarOrderNumber?: InputMaybe<IntPredicate>;
  roleAddon?: InputMaybe<RoleAddonRelationFilter>;
  route?: InputMaybe<StringPredicate>;
  sidebarOrderNumber?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SchoolConfigurationLayoutGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: SchoolConfigurationLayoutGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SchoolConfigurationLayoutGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  configuration?: InputMaybe<SchoolConfigurationGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  icon?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  isActive?: InputMaybe<Array<GroupByField>>;
  isAlwaysShown?: InputMaybe<Array<GroupByField>>;
  name?: InputMaybe<Array<GroupByField>>;
  navbarOrderNumber?: InputMaybe<Array<GroupByField>>;
  roleAddon?: InputMaybe<RoleAddonGroupByQuery>;
  route?: InputMaybe<Array<GroupByField>>;
  sidebarOrderNumber?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type SchoolConfigurationLayoutKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolConfigurationLayoutListResponse output */
export type SchoolConfigurationLayoutListResponse = {
  __typename?: 'SchoolConfigurationLayoutListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<SchoolConfigurationLayout>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationLayoutManyRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationLayoutKeyFilter>>;
};

/** SchoolConfigurationLayoutManyResponse output */
export type SchoolConfigurationLayoutManyResponse = {
  __typename?: 'SchoolConfigurationLayoutManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SchoolConfigurationLayout>;
};

/** No longer supported. Use `sort` instead. */
export type SchoolConfigurationLayoutOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'icon_ASC'
  | 'icon_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isActive_ASC'
  | 'isActive_DESC'
  | 'isAlwaysShown_ASC'
  | 'isAlwaysShown_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'navbarOrderNumber_ASC'
  | 'navbarOrderNumber_DESC'
  | 'route_ASC'
  | 'route_DESC'
  | 'sidebarOrderNumber_ASC'
  | 'sidebarOrderNumber_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** SchoolConfigurationLayouts subscription payload */
export type SchoolConfigurationLayoutPayload = {
  __typename?: 'SchoolConfigurationLayoutPayload';
  mutation: MutationType;
  node?: Maybe<SchoolConfigurationLayout>;
  previousValues?: Maybe<SchoolConfigurationLayout>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SchoolConfigurationLayoutRelationFilter = {
  every?: InputMaybe<SchoolConfigurationLayoutFilter>;
  none?: InputMaybe<SchoolConfigurationLayoutFilter>;
  some?: InputMaybe<SchoolConfigurationLayoutFilter>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationLayoutRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationLayoutKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Configuration_SchoolConfigurationLayoutCreateInput>>>;
};

export type SchoolConfigurationLayoutSort = {
  configuration?: InputMaybe<SchoolConfigurationSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  icon?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  isAlwaysShown?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  navbarOrderNumber?: InputMaybe<SortOrder>;
  route?: InputMaybe<SortOrder>;
  sidebarOrderNumber?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** SchoolConfigurationLayouts subscription filter */
export type SchoolConfigurationLayoutSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<SchoolConfigurationLayoutFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** SchoolConfigurationLayouts update input */
export type SchoolConfigurationLayoutUpdateByFilterInput = {
  icon?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  isActive?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isAlwaysShown?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  navbarOrderNumber?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  route?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  sidebarOrderNumber?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
};

/** SchoolConfigurationLayouts update input */
export type SchoolConfigurationLayoutUpdateInput = {
  configuration?: InputMaybe<SchoolConfigurationLayoutsConfigurationUpdateRelationInput>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAlwaysShown?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  navbarOrderNumber?: InputMaybe<Scalars['Int']>;
  roleAddon?: InputMaybe<SchoolConfigurationLayoutsRoleAddonUpdateRelationInput>;
  route?: InputMaybe<Scalars['String']>;
  sidebarOrderNumber?: InputMaybe<Scalars['Int']>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationLayoutUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolConfigurationLayoutKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Configuration_SchoolConfigurationLayoutCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolConfigurationLayoutKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolConfigurationLayoutKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Configuration_SchoolConfigurationLayoutUpdateInput>>>;
};

export type SchoolConfigurationLayout_PermissionFilter = {
  AND?: InputMaybe<Array<SchoolConfigurationLayout_PermissionFilter>>;
  OR?: InputMaybe<Array<SchoolConfigurationLayout_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  icon?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  isActive?: InputMaybe<BoolPredicate>;
  isAlwaysShown?: InputMaybe<BoolPredicate>;
  name?: InputMaybe<StringPredicate>;
  navbarOrderNumber?: InputMaybe<IntPredicate>;
  roleAddon?: InputMaybe<RoleAddon_PermissionRelationFilter>;
  route?: InputMaybe<StringPredicate>;
  sidebarOrderNumber?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SchoolConfigurationLayout_PermissionRelationFilter = {
  every?: InputMaybe<SchoolConfigurationLayout_PermissionFilter>;
  none?: InputMaybe<SchoolConfigurationLayout_PermissionFilter>;
  some?: InputMaybe<SchoolConfigurationLayout_PermissionFilter>;
};

/** SchoolConfigurationLayouts relation input */
export type SchoolConfigurationLayoutsConfigurationManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** SchoolConfigurationLayouts relation input */
export type SchoolConfigurationLayoutsConfigurationRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<Layout_SchoolConfigurationCreateInput>;
};

/** SchoolConfigurationLayouts relation input */
export type SchoolConfigurationLayoutsConfigurationUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<Layout_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<Layout_SchoolConfigurationUpdateInput>;
};

/** SchoolConfigurationLayouts relation input */
export type SchoolConfigurationLayoutsRoleAddonManyRelationInput = {
  connect?: InputMaybe<Array<RoleAddonKeyFilter>>;
};

/** SchoolConfigurationLayouts relation input */
export type SchoolConfigurationLayoutsRoleAddonRelationInput = {
  connect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<AccessibleLayouts_RoleAddonCreateInput>>>;
};

/** SchoolConfigurationLayouts relation input */
export type SchoolConfigurationLayoutsRoleAddonUpdateRelationInput = {
  connect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<AccessibleLayouts_RoleAddonCreateInput>>>;
  disconnect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  reconnect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<AccessibleLayouts_RoleAddonUpdateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationLegacyFeedMonthsManyRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedMonthKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationLegacyFeedMonthsRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedMonthKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_LegacyFeedMonthCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationLegacyFeedMonthsUpdateRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedMonthKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_LegacyFeedMonthCreateInput>>>;
  disconnect?: InputMaybe<Array<LegacyFeedMonthKeyFilter>>;
  reconnect?: InputMaybe<Array<LegacyFeedMonthKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_LegacyFeedMonthUpdateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationLegacyFeedYearsManyRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedYearKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationLegacyFeedYearsRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedYearKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_LegacyFeedYearCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationLegacyFeedYearsUpdateRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedYearKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_LegacyFeedYearCreateInput>>>;
  disconnect?: InputMaybe<Array<LegacyFeedYearKeyFilter>>;
  reconnect?: InputMaybe<Array<LegacyFeedYearKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_LegacyFeedYearUpdateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationLegacyPostsManyRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationLegacyPostsRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_LegacyFeedPostCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationLegacyPostsUpdateRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_LegacyFeedPostCreateInput>>>;
  disconnect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  reconnect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_LegacyFeedPostUpdateInput>>>;
};

/** SchoolConfigurationListResponse output */
export type SchoolConfigurationListResponse = {
  __typename?: 'SchoolConfigurationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<SchoolConfiguration>;
};

/** SchoolConfigurationManyResponse output */
export type SchoolConfigurationManyResponse = {
  __typename?: 'SchoolConfigurationManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SchoolConfiguration>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationOffersManyRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationOffersRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Schools_DiscountCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationOffersUpdateRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Schools_DiscountCreateInput>>>;
  disconnect?: InputMaybe<Array<DiscountKeyFilter>>;
  reconnect?: InputMaybe<Array<DiscountKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Schools_DiscountUpdateInput>>>;
};

/** No longer supported. Use `sort` instead. */
export type SchoolConfigurationOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'email_ASC'
  | 'email_DESC'
  | 'fullName_ASC'
  | 'fullName_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'moderateLegacyPosts_ASC'
  | 'moderateLegacyPosts_DESC'
  | 'moderateSchoolPosts_ASC'
  | 'moderateSchoolPosts_DESC'
  | 'moderationThreshold_ASC'
  | 'moderationThreshold_DESC'
  | 'name_ASC'
  | 'name_DESC'
  | 'timezone_ASC'
  | 'timezone_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** SchoolConfiguration subscription payload */
export type SchoolConfigurationPayload = {
  __typename?: 'SchoolConfigurationPayload';
  mutation: MutationType;
  node?: Maybe<SchoolConfiguration>;
  previousValues?: Maybe<SchoolConfiguration>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SchoolConfigurationRelationFilter = {
  every?: InputMaybe<SchoolConfigurationFilter>;
  none?: InputMaybe<SchoolConfigurationFilter>;
  some?: InputMaybe<SchoolConfigurationFilter>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationRoleAddonsManyRelationInput = {
  connect?: InputMaybe<Array<RoleAddonKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationRoleAddonsRelationInput = {
  connect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_RoleAddonCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationRoleAddonsUpdateRelationInput = {
  connect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_RoleAddonCreateInput>>>;
  disconnect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  reconnect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_RoleAddonUpdateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationSchoolPostsManyRelationInput = {
  connect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationSchoolPostsRelationInput = {
  connect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_SchoolNewsPostCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationSchoolPostsUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_SchoolNewsPostCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_SchoolNewsPostUpdateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationSchoolSupporterManyRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationSchoolSupporterRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_SchoolSupporterCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationSchoolSupporterUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_SchoolSupporterCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolSupporterKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolSupporterKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_SchoolSupporterUpdateInput>>>;
};

export type SchoolConfigurationSort = {
  address?: InputMaybe<AddressSort>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemeSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SchoolConfigurationImageSort>;
  moderateLegacyPosts?: InputMaybe<SortOrder>;
  moderateSchoolPosts?: InputMaybe<SortOrder>;
  moderationThreshold?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  topBar?: InputMaybe<SchoolConfigurationTopBarSort>;
  updatedAt?: InputMaybe<SortOrder>;
  usersCount?: InputMaybe<UsersCountSort>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistSort>;
};

/** SchoolConfiguration subscription filter */
export type SchoolConfigurationSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<SchoolConfigurationFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

export type SchoolConfigurationTopBar = {
  __typename?: 'SchoolConfigurationTopBar';
  _description?: Maybe<Scalars['String']>;
  configuration?: Maybe<SchoolConfiguration>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** SchoolConfigurationTopBar relation input */
export type SchoolConfigurationTopBarConfigurationManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** SchoolConfigurationTopBar relation input */
export type SchoolConfigurationTopBarConfigurationRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<TopBar_SchoolConfigurationCreateInput>;
};

/** SchoolConfigurationTopBar relation input */
export type SchoolConfigurationTopBarConfigurationUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<TopBar_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<TopBar_SchoolConfigurationUpdateInput>;
};

/** SchoolConfigurationTopBar create input */
export type SchoolConfigurationTopBarCreateInput = {
  configuration?: InputMaybe<SchoolConfigurationTopBarConfigurationRelationInput>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  label?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  text: Scalars['String'];
  title: Scalars['String'];
};

/** SchoolConfigurationTopBar create many input */
export type SchoolConfigurationTopBarCreateManyInput = {
  configuration: SchoolConfigurationTopBarConfigurationManyRelationInput;
  endDate?: InputMaybe<Scalars['DateTime']>;
  label?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  text: Scalars['String'];
  title: Scalars['String'];
};

/** SchoolConfigurationTopBar delete input */
export type SchoolConfigurationTopBarDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolConfigurationTopBarFieldsPermissions create input */
export type SchoolConfigurationTopBarFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolConfigurationTopBarFilter = {
  AND?: InputMaybe<Array<SchoolConfigurationTopBarFilter>>;
  OR?: InputMaybe<Array<SchoolConfigurationTopBarFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfigurationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  endDate?: InputMaybe<DateTimePredicate>;
  id?: InputMaybe<IdPredicate>;
  label?: InputMaybe<StringPredicate>;
  link?: InputMaybe<StringPredicate>;
  startDate?: InputMaybe<DateTimePredicate>;
  text?: InputMaybe<StringPredicate>;
  title?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SchoolConfigurationTopBarGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: SchoolConfigurationTopBarGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SchoolConfigurationTopBarGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  configuration?: InputMaybe<SchoolConfigurationGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  endDate?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  label?: InputMaybe<Array<GroupByField>>;
  link?: InputMaybe<Array<GroupByField>>;
  startDate?: InputMaybe<Array<GroupByField>>;
  text?: InputMaybe<Array<GroupByField>>;
  title?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type SchoolConfigurationTopBarKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolConfigurationTopBarListResponse output */
export type SchoolConfigurationTopBarListResponse = {
  __typename?: 'SchoolConfigurationTopBarListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<SchoolConfigurationTopBar>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationTopBarManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationTopBarKeyFilter>;
};

/** SchoolConfigurationTopBarManyResponse output */
export type SchoolConfigurationTopBarManyResponse = {
  __typename?: 'SchoolConfigurationTopBarManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SchoolConfigurationTopBar>;
};

/** No longer supported. Use `sort` instead. */
export type SchoolConfigurationTopBarOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'endDate_ASC'
  | 'endDate_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'label_ASC'
  | 'label_DESC'
  | 'link_ASC'
  | 'link_DESC'
  | 'startDate_ASC'
  | 'startDate_DESC'
  | 'text_ASC'
  | 'text_DESC'
  | 'title_ASC'
  | 'title_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** SchoolConfigurationTopBar subscription payload */
export type SchoolConfigurationTopBarPayload = {
  __typename?: 'SchoolConfigurationTopBarPayload';
  mutation: MutationType;
  node?: Maybe<SchoolConfigurationTopBar>;
  previousValues?: Maybe<SchoolConfigurationTopBar>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationTopBarRelationInput = {
  connect?: InputMaybe<SchoolConfigurationTopBarKeyFilter>;
  create?: InputMaybe<Configuration_SchoolConfigurationTopBarCreateInput>;
};

export type SchoolConfigurationTopBarSort = {
  configuration?: InputMaybe<SchoolConfigurationSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** SchoolConfigurationTopBar subscription filter */
export type SchoolConfigurationTopBarSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<SchoolConfigurationTopBarFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** SchoolConfigurationTopBar update input */
export type SchoolConfigurationTopBarUpdateByFilterInput = {
  endDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  label?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  link?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  startDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  text?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  title?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** SchoolConfigurationTopBar update input */
export type SchoolConfigurationTopBarUpdateInput = {
  configuration?: InputMaybe<SchoolConfigurationTopBarConfigurationUpdateRelationInput>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationTopBarUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationTopBarKeyFilter>;
  create?: InputMaybe<Configuration_SchoolConfigurationTopBarCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationTopBarKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationTopBarKeyFilter>;
  update?: InputMaybe<Configuration_SchoolConfigurationTopBarUpdateInput>;
};

export type SchoolConfigurationTopBar_PermissionFilter = {
  AND?: InputMaybe<Array<SchoolConfigurationTopBar_PermissionFilter>>;
  OR?: InputMaybe<Array<SchoolConfigurationTopBar_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  endDate?: InputMaybe<DateTimePredicate>;
  id?: InputMaybe<IdPredicate>;
  label?: InputMaybe<StringPredicate>;
  link?: InputMaybe<StringPredicate>;
  startDate?: InputMaybe<DateTimePredicate>;
  text?: InputMaybe<StringPredicate>;
  title?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationTrendingSearchesManyRelationInput = {
  connect?: InputMaybe<Array<TrendingSearchKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationTrendingSearchesRelationInput = {
  connect?: InputMaybe<Array<TrendingSearchKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_TrendingSearchCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationTrendingSearchesUpdateRelationInput = {
  connect?: InputMaybe<Array<TrendingSearchKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_TrendingSearchCreateInput>>>;
  disconnect?: InputMaybe<Array<TrendingSearchKeyFilter>>;
  reconnect?: InputMaybe<Array<TrendingSearchKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_TrendingSearchUpdateInput>>>;
};

/** SchoolConfiguration update input */
export type SchoolConfigurationUpdateByFilterInput = {
  email?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  fullName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  moderateLegacyPosts?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  moderateSchoolPosts?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  moderationThreshold?: InputMaybe<Array<InputMaybe<UpdateByFilterFloatInput>>>;
  name?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  timezone?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** SchoolConfiguration update input */
export type SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationUserMembershipsManyRelationInput = {
  connect?: InputMaybe<Array<UserSchoolMembershipKeyFilter>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationUserMembershipsRelationInput = {
  connect?: InputMaybe<Array<UserSchoolMembershipKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_UserSchoolMembershipCreateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationUserMembershipsUpdateRelationInput = {
  connect?: InputMaybe<Array<UserSchoolMembershipKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<School_UserSchoolMembershipCreateInput>>>;
  disconnect?: InputMaybe<Array<UserSchoolMembershipKeyFilter>>;
  reconnect?: InputMaybe<Array<UserSchoolMembershipKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<School_UserSchoolMembershipUpdateInput>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationUsersCountManyRelationInput = {
  connect?: InputMaybe<UsersCountKeyFilter>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationUsersCountRelationInput = {
  connect?: InputMaybe<UsersCountKeyFilter>;
  create?: InputMaybe<UsersCount_UsersCountCreateInput>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationUsersCountUpdateRelationInput = {
  connect?: InputMaybe<UsersCountKeyFilter>;
  create?: InputMaybe<UsersCount_UsersCountCreateInput>;
  disconnect?: InputMaybe<UsersCountKeyFilter>;
  reconnect?: InputMaybe<UsersCountKeyFilter>;
  update?: InputMaybe<UsersCount_UsersCountUpdateInput>;
};

export type SchoolConfigurationWordsBlacklist = {
  __typename?: 'SchoolConfigurationWordsBlacklist';
  _description?: Maybe<Scalars['String']>;
  blacklistId?: Maybe<Scalars['String']>;
  blacklistLabel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  schoolConfiguration?: Maybe<SchoolConfiguration>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** SchoolConfigurationWordsBlacklist create input */
export type SchoolConfigurationWordsBlacklistCreateInput = {
  blacklistId: Scalars['String'];
  blacklistLabel: Scalars['String'];
  schoolConfiguration?: InputMaybe<SchoolConfigurationWordsBlacklistSchoolConfigurationRelationInput>;
};

/** SchoolConfigurationWordsBlacklist create many input */
export type SchoolConfigurationWordsBlacklistCreateManyInput = {
  blacklistId: Scalars['String'];
  blacklistLabel: Scalars['String'];
  schoolConfiguration?: InputMaybe<SchoolConfigurationWordsBlacklistSchoolConfigurationManyRelationInput>;
};

/** SchoolConfigurationWordsBlacklist delete input */
export type SchoolConfigurationWordsBlacklistDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolConfigurationWordsBlacklistFieldsPermissions create input */
export type SchoolConfigurationWordsBlacklistFieldsPermissions = {
  blacklistId?: InputMaybe<Scalars['Boolean']>;
  blacklistLabel?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolConfigurationWordsBlacklistFilter = {
  AND?: InputMaybe<Array<SchoolConfigurationWordsBlacklistFilter>>;
  OR?: InputMaybe<Array<SchoolConfigurationWordsBlacklistFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  blacklistId?: InputMaybe<StringPredicate>;
  blacklistLabel?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  schoolConfiguration?: InputMaybe<SchoolConfigurationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SchoolConfigurationWordsBlacklistGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: SchoolConfigurationWordsBlacklistGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SchoolConfigurationWordsBlacklistGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  blacklistId?: InputMaybe<Array<GroupByField>>;
  blacklistLabel?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  schoolConfiguration?: InputMaybe<SchoolConfigurationGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type SchoolConfigurationWordsBlacklistKeyFilter = {
  blacklistId?: InputMaybe<Scalars['String']>;
  blacklistLabel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolConfigurationWordsBlacklistListResponse output */
export type SchoolConfigurationWordsBlacklistListResponse = {
  __typename?: 'SchoolConfigurationWordsBlacklistListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<SchoolConfigurationWordsBlacklist>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationWordsBlacklistManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationWordsBlacklistKeyFilter>;
};

/** SchoolConfigurationWordsBlacklistManyResponse output */
export type SchoolConfigurationWordsBlacklistManyResponse = {
  __typename?: 'SchoolConfigurationWordsBlacklistManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SchoolConfigurationWordsBlacklist>;
};

/** No longer supported. Use `sort` instead. */
export type SchoolConfigurationWordsBlacklistOrderBy =
  | 'blacklistId_ASC'
  | 'blacklistId_DESC'
  | 'blacklistLabel_ASC'
  | 'blacklistLabel_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** SchoolConfigurationWordsBlacklist subscription payload */
export type SchoolConfigurationWordsBlacklistPayload = {
  __typename?: 'SchoolConfigurationWordsBlacklistPayload';
  mutation: MutationType;
  node?: Maybe<SchoolConfigurationWordsBlacklist>;
  previousValues?: Maybe<SchoolConfigurationWordsBlacklist>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationWordsBlacklistRelationInput = {
  connect?: InputMaybe<SchoolConfigurationWordsBlacklistKeyFilter>;
  create?: InputMaybe<SchoolConfiguration_SchoolConfigurationWordsBlacklistCreateInput>;
};

/** SchoolConfigurationWordsBlacklist relation input */
export type SchoolConfigurationWordsBlacklistSchoolConfigurationManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** SchoolConfigurationWordsBlacklist relation input */
export type SchoolConfigurationWordsBlacklistSchoolConfigurationRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<WordsBlacklist_SchoolConfigurationCreateInput>;
};

/** SchoolConfigurationWordsBlacklist relation input */
export type SchoolConfigurationWordsBlacklistSchoolConfigurationUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<WordsBlacklist_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<WordsBlacklist_SchoolConfigurationUpdateInput>;
};

export type SchoolConfigurationWordsBlacklistSort = {
  blacklistId?: InputMaybe<SortOrder>;
  blacklistLabel?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  schoolConfiguration?: InputMaybe<SchoolConfigurationSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** SchoolConfigurationWordsBlacklist subscription filter */
export type SchoolConfigurationWordsBlacklistSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<SchoolConfigurationWordsBlacklistFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** SchoolConfigurationWordsBlacklist update input */
export type SchoolConfigurationWordsBlacklistUpdateByFilterInput = {
  blacklistId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  blacklistLabel?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** SchoolConfigurationWordsBlacklist update input */
export type SchoolConfigurationWordsBlacklistUpdateInput = {
  blacklistId?: InputMaybe<Scalars['String']>;
  blacklistLabel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  schoolConfiguration?: InputMaybe<SchoolConfigurationWordsBlacklistSchoolConfigurationUpdateRelationInput>;
};

/** SchoolConfiguration relation input */
export type SchoolConfigurationWordsBlacklistUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationWordsBlacklistKeyFilter>;
  create?: InputMaybe<SchoolConfiguration_SchoolConfigurationWordsBlacklistCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationWordsBlacklistKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationWordsBlacklistKeyFilter>;
  update?: InputMaybe<SchoolConfiguration_SchoolConfigurationWordsBlacklistUpdateInput>;
};

export type SchoolConfigurationWordsBlacklist_PermissionFilter = {
  AND?: InputMaybe<Array<SchoolConfigurationWordsBlacklist_PermissionFilter>>;
  OR?: InputMaybe<Array<SchoolConfigurationWordsBlacklist_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  blacklistId?: InputMaybe<StringPredicate>;
  blacklistLabel?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  schoolConfiguration?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SchoolConfiguration_PermissionFilter = {
  AND?: InputMaybe<Array<SchoolConfiguration_PermissionFilter>>;
  OR?: InputMaybe<Array<SchoolConfiguration_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  adClicks?: InputMaybe<AdClick_PermissionRelationFilter>;
  adViews?: InputMaybe<AdView_PermissionRelationFilter>;
  address?: InputMaybe<Address_PermissionFilter>;
  audiences?: InputMaybe<Audience_PermissionRelationFilter>;
  classScorecard?: InputMaybe<GraduatingYear_PermissionRelationFilter>;
  colorSchema?: InputMaybe<SchoolConfigurationColorScheme_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  dashboardMetrics?: InputMaybe<Dashboard_PermissionRelationFilter>;
  debtOperations?: InputMaybe<DebtOperationHistory_PermissionRelationFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  discountRedemptions?: InputMaybe<DiscountRedemption_PermissionRelationFilter>;
  email?: InputMaybe<StringPredicate>;
  events?: InputMaybe<Event_PermissionRelationFilter>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLink_PermissionRelationFilter>;
  forumThreads?: InputMaybe<CollectiveIntelligenceThread_PermissionRelationFilter>;
  fullName?: InputMaybe<StringPredicate>;
  groups?: InputMaybe<Group_PermissionRelationFilter>;
  homePosts?: InputMaybe<HomeFeedPost_PermissionRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  images?: InputMaybe<SchoolConfigurationImage_PermissionFilter>;
  jobOffers?: InputMaybe<JobOffer_PermissionRelationFilter>;
  layout?: InputMaybe<SchoolConfigurationLayout_PermissionRelationFilter>;
  legacyFeedMonths?: InputMaybe<LegacyFeedMonth_PermissionRelationFilter>;
  legacyFeedYears?: InputMaybe<LegacyFeedYear_PermissionRelationFilter>;
  legacyPosts?: InputMaybe<LegacyFeedPost_PermissionRelationFilter>;
  moderateLegacyPosts?: InputMaybe<BoolPredicate>;
  moderateSchoolPosts?: InputMaybe<BoolPredicate>;
  moderationThreshold?: InputMaybe<FloatPredicate>;
  name?: InputMaybe<StringPredicate>;
  offers?: InputMaybe<Discount_PermissionRelationFilter>;
  roleAddons?: InputMaybe<RoleAddon_PermissionRelationFilter>;
  schoolPosts?: InputMaybe<SchoolNewsPost_PermissionRelationFilter>;
  schoolSupporter?: InputMaybe<SchoolSupporter_PermissionRelationFilter>;
  timezone?: InputMaybe<StringPredicate>;
  topBar?: InputMaybe<SchoolConfigurationTopBar_PermissionFilter>;
  trendingSearches?: InputMaybe<TrendingSearch_PermissionRelationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userMemberships?: InputMaybe<UserSchoolMembership_PermissionRelationFilter>;
  usersCount?: InputMaybe<UsersCount_PermissionFilter>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklist_PermissionFilter>;
};

export type SchoolConfiguration_PermissionRelationFilter = {
  every?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  none?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  some?: InputMaybe<SchoolConfiguration_PermissionFilter>;
};

/** SchoolConfigurationWordsBlacklist create input from schoolConfiguration */
export type SchoolConfiguration_SchoolConfigurationWordsBlacklistCreateInput = {
  blacklistId: Scalars['String'];
  blacklistLabel: Scalars['String'];
  schoolConfiguration?: InputMaybe<SchoolConfigurationWordsBlacklistSchoolConfigurationRelationInput>;
};

/** SchoolConfigurationWordsBlacklist update input from schoolConfiguration */
export type SchoolConfiguration_SchoolConfigurationWordsBlacklistUpdateInput = {
  blacklistId?: InputMaybe<Scalars['String']>;
  blacklistLabel?: InputMaybe<Scalars['String']>;
  schoolConfiguration?: InputMaybe<SchoolConfigurationWordsBlacklistSchoolConfigurationUpdateRelationInput>;
};

export type SchoolMember = {
  __typename?: 'SchoolMember';
  activityClubs: Array<Scalars['String']>;
  affiliation?: Maybe<Scalars['String']>;
  /** Avatar of the user. */
  avatar?: Maybe<File>;
  birthDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['DateTime'];
  /** Distance in miles from the given `startPointZip`. */
  distance: Scalars['Float'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  graduatingYear?: Maybe<Scalars['Date']>;
  /** Number of posts created by the user. */
  groupPostsCount: Scalars['Int'];
  hobbies: Array<Scalars['String']>;
  /** Number of posts created by the user in the user's home group. */
  homePostsCount: Scalars['Int'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  professionalDesignations?: Maybe<Scalars['String']>;
  registrationCompletedAt?: Maybe<Scalars['DateTime']>;
  /** Roles assigned to the user by the school. */
  roleAddons: Array<SchoolMemberRole>;
  status: SchoolMemberStatus;
  /** Roles assigned to the user by the platform. */
  systemRoles: Array<SchoolMemberRole>;
};

export type SchoolMemberRole = {
  __typename?: 'SchoolMemberRole';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SchoolMemberStatus = 'active' | 'inactive' | 'invitationSent' | 'pending' | 'rejected';

export type SchoolMembersReport = {
  __typename?: 'SchoolMembersReport';
  count?: Maybe<Scalars['Int']>;
  items: Array<SchoolMember>;
};

/** Users create input from schoolMemberships */
export type SchoolMemberships_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from schoolMemberships */
export type SchoolMemberships_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

export type SchoolNewsPost = {
  __typename?: 'SchoolNewsPost';
  _description?: Maybe<Scalars['String']>;
  author?: Maybe<User>;
  commentsAllowed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  flagReason?: Maybe<Scalars['String']>;
  flaggedAt?: Maybe<Scalars['DateTime']>;
  flaggedBy?: Maybe<UserListResponse>;
  id?: Maybe<Scalars['ID']>;
  isFlagged?: Maybe<Scalars['Boolean']>;
  isPinned?: Maybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: Maybe<Scalars['Boolean']>;
  lastFlaggedBy?: Maybe<User>;
  /** Media files: [video, image] */
  media?: Maybe<FileListResponse>;
  mentions?: Maybe<PostMentionListResponse>;
  postDate?: Maybe<Scalars['DateTime']>;
  reportStatus?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SchoolNewsPostFlaggedByArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort>>;
};

export type SchoolNewsPostMediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<FileGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<FileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<FileSort>>;
};

export type SchoolNewsPostMentionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PostMentionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<PostMentionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<PostMentionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PostMentionSort>>;
};

/** SchoolNewsPosts create input */
export type SchoolNewsPostCreateInput = {
  author?: InputMaybe<SchoolNewsPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<SchoolNewsPostsFlaggedByRelationInput>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned: Scalars['Boolean'];
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<SchoolNewsPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<SchoolNewsPostsMediaRelationInput>;
  mentions?: InputMaybe<SchoolNewsPostsMentionsRelationInput>;
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<SchoolNewsPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** SchoolNewsPosts create many input */
export type SchoolNewsPostCreateManyInput = {
  author?: InputMaybe<SchoolNewsPostsAuthorManyRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<SchoolNewsPostsFlaggedByManyRelationInput>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned: Scalars['Boolean'];
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<SchoolNewsPostsLastFlaggedByManyRelationInput>;
  media?: InputMaybe<SchoolNewsPostsMediaManyRelationInput>;
  mentions?: InputMaybe<SchoolNewsPostsMentionsManyRelationInput>;
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<SchoolNewsPostsSchoolManyRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** SchoolNewsPosts delete input */
export type SchoolNewsPostDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolNewsPostFieldsPermissions create input */
export type SchoolNewsPostFieldsPermissions = {
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['Boolean']>;
  flaggedAt?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  postDate?: InputMaybe<Scalars['Boolean']>;
  reportStatus?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolNewsPostFilter = {
  AND?: InputMaybe<Array<SchoolNewsPostFilter>>;
  OR?: InputMaybe<Array<SchoolNewsPostFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<UserFilter>;
  commentsAllowed?: InputMaybe<BoolPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  flagReason?: InputMaybe<StringPredicate>;
  flaggedAt?: InputMaybe<DateTimePredicate>;
  flaggedBy?: InputMaybe<UserRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  isFlagged?: InputMaybe<BoolPredicate>;
  isPinned?: InputMaybe<BoolPredicate>;
  isReviewed?: InputMaybe<BoolPredicate>;
  lastFlaggedBy?: InputMaybe<UserFilter>;
  media?: InputMaybe<FileRelationFilter>;
  mentions?: InputMaybe<PostMentionRelationFilter>;
  postDate?: InputMaybe<DateTimePredicate>;
  reportStatus?: InputMaybe<StringPredicate>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SchoolNewsPostGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: SchoolNewsPostGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SchoolNewsPostGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  author?: InputMaybe<UserGroupByQuery>;
  commentsAllowed?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  flagReason?: InputMaybe<Array<GroupByField>>;
  flaggedAt?: InputMaybe<Array<GroupByField>>;
  flaggedBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isFlagged?: InputMaybe<Array<GroupByField>>;
  isPinned?: InputMaybe<Array<GroupByField>>;
  isReviewed?: InputMaybe<Array<GroupByField>>;
  lastFlaggedBy?: InputMaybe<UserGroupByQuery>;
  media?: InputMaybe<FileGroupByQuery>;
  mentions?: InputMaybe<PostMentionGroupByQuery>;
  postDate?: InputMaybe<Array<GroupByField>>;
  reportStatus?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  text?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type SchoolNewsPostKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolNewsPostListResponse output */
export type SchoolNewsPostListResponse = {
  __typename?: 'SchoolNewsPostListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<SchoolNewsPost>;
};

/** SchoolNewsPostManyResponse output */
export type SchoolNewsPostManyResponse = {
  __typename?: 'SchoolNewsPostManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SchoolNewsPost>;
};

/** No longer supported. Use `sort` instead. */
export type SchoolNewsPostOrderBy =
  | 'commentsAllowed_ASC'
  | 'commentsAllowed_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'flagReason_ASC'
  | 'flagReason_DESC'
  | 'flaggedAt_ASC'
  | 'flaggedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isFlagged_ASC'
  | 'isFlagged_DESC'
  | 'isPinned_ASC'
  | 'isPinned_DESC'
  | 'isReviewed_ASC'
  | 'isReviewed_DESC'
  | 'postDate_ASC'
  | 'postDate_DESC'
  | 'reportStatus_ASC'
  | 'reportStatus_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'text_ASC'
  | 'text_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** SchoolNewsPosts subscription payload */
export type SchoolNewsPostPayload = {
  __typename?: 'SchoolNewsPostPayload';
  mutation: MutationType;
  node?: Maybe<SchoolNewsPost>;
  previousValues?: Maybe<SchoolNewsPost>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SchoolNewsPostRelationFilter = {
  every?: InputMaybe<SchoolNewsPostFilter>;
  none?: InputMaybe<SchoolNewsPostFilter>;
  some?: InputMaybe<SchoolNewsPostFilter>;
};

export type SchoolNewsPostSort = {
  author?: InputMaybe<UserSort>;
  commentsAllowed?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  flagReason?: InputMaybe<SortOrder>;
  flaggedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isFlagged?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isReviewed?: InputMaybe<SortOrder>;
  lastFlaggedBy?: InputMaybe<UserSort>;
  postDate?: InputMaybe<SortOrder>;
  reportStatus?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** SchoolNewsPosts subscription filter */
export type SchoolNewsPostSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<SchoolNewsPostFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** SchoolNewsPosts update input */
export type SchoolNewsPostUpdateByFilterInput = {
  commentsAllowed?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  flagReason?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  flaggedAt?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  isFlagged?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isPinned?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isReviewed?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  postDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  reportStatus?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  text?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** SchoolNewsPosts update input */
export type SchoolNewsPostUpdateInput = {
  author?: InputMaybe<SchoolNewsPostsAuthorUpdateRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<SchoolNewsPostsFlaggedByUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<SchoolNewsPostsLastFlaggedByUpdateRelationInput>;
  media?: InputMaybe<SchoolNewsPostsMediaUpdateRelationInput>;
  mentions?: InputMaybe<SchoolNewsPostsMentionsUpdateRelationInput>;
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<SchoolNewsPostsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type SchoolNewsPost_PermissionFilter = {
  AND?: InputMaybe<Array<SchoolNewsPost_PermissionFilter>>;
  OR?: InputMaybe<Array<SchoolNewsPost_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<User_PermissionFilter>;
  commentsAllowed?: InputMaybe<BoolPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  flagReason?: InputMaybe<StringPredicate>;
  flaggedAt?: InputMaybe<DateTimePredicate>;
  flaggedBy?: InputMaybe<User_PermissionRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  isFlagged?: InputMaybe<BoolPredicate>;
  isPinned?: InputMaybe<BoolPredicate>;
  isReviewed?: InputMaybe<BoolPredicate>;
  lastFlaggedBy?: InputMaybe<User_PermissionFilter>;
  media?: InputMaybe<File_PermissionRelationFilter>;
  mentions?: InputMaybe<PostMention_PermissionRelationFilter>;
  postDate?: InputMaybe<DateTimePredicate>;
  reportStatus?: InputMaybe<StringPredicate>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  text?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type SchoolNewsPost_PermissionRelationFilter = {
  every?: InputMaybe<SchoolNewsPost_PermissionFilter>;
  none?: InputMaybe<SchoolNewsPost_PermissionFilter>;
  some?: InputMaybe<SchoolNewsPost_PermissionFilter>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsAuthorManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsAuthorRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<CreatedSchoolNewsPosts_UserCreateInput>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsAuthorUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<CreatedSchoolNewsPosts_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<CreatedSchoolNewsPosts_UserUpdateInput>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsFlaggedByManyRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsFlaggedByRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedSchoolNewsPosts_UserCreateInput>>>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsFlaggedByUpdateRelationInput = {
  connect?: InputMaybe<Array<UserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedSchoolNewsPosts_UserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<FlaggedSchoolNewsPosts_UserUpdateInput>>>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsLastFlaggedByManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsLastFlaggedByRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<LastFlaggedSchoolPost_UserCreateInput>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsLastFlaggedByUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<LastFlaggedSchoolPost_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<LastFlaggedSchoolPost_UserUpdateInput>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsMediaManyRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsMediaRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<SchoolNewsPosts_Media_FileCreateInput>>>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsMediaUpdateRelationInput = {
  connect?: InputMaybe<Array<FileKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<SchoolNewsPosts_Media_FileCreateInput>>>;
  disconnect?: InputMaybe<Array<FileKeyFilter>>;
  reconnect?: InputMaybe<Array<FileKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<SchoolNewsPosts_Media_FileUpdateInput>>>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsMentionsManyRelationInput = {
  connect?: InputMaybe<Array<PostMentionKeyFilter>>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsMentionsRelationInput = {
  connect?: InputMaybe<Array<PostMentionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<SchoolPost_PostMentionCreateInput>>>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsMentionsUpdateRelationInput = {
  connect?: InputMaybe<Array<PostMentionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<SchoolPost_PostMentionCreateInput>>>;
  disconnect?: InputMaybe<Array<PostMentionKeyFilter>>;
  reconnect?: InputMaybe<Array<PostMentionKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<SchoolPost_PostMentionUpdateInput>>>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<SchoolPosts_SchoolConfigurationCreateInput>;
};

/** SchoolNewsPosts relation input */
export type SchoolNewsPostsSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<SchoolPosts_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<SchoolPosts_SchoolConfigurationUpdateInput>;
};

/** Files create input from schoolNewsPosts_media */
export type SchoolNewsPosts_Media_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from schoolNewsPosts_media */
export type SchoolNewsPosts_Media_FileUpdateInput = {
  data: FileUpdateInput;
  filter?: InputMaybe<FileKeyFilter>;
};

/** PostMentions create input from schoolPost */
export type SchoolPost_PostMentionCreateInput = {
  groupPost?: InputMaybe<PostMentionsGroupPostRelationInput>;
  homePost?: InputMaybe<PostMentionsHomePostRelationInput>;
  legacyPost?: InputMaybe<PostMentionsLegacyPostRelationInput>;
  legacyPostId?: InputMaybe<Scalars['String']>;
  mentionId?: InputMaybe<Scalars['String']>;
  mentionText?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  schoolPost?: InputMaybe<PostMentionsSchoolPostRelationInput>;
  type?: InputMaybe<Scalars['String']>;
};

/** PostMentions update input from schoolPost */
export type SchoolPost_PostMentionUpdateInput = {
  data: PostMentionUpdateInput;
  filter?: InputMaybe<PostMentionKeyFilter>;
};

/** SchoolConfiguration create input from schoolPosts */
export type SchoolPosts_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from schoolPosts */
export type SchoolPosts_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

export type SchoolSupporter = {
  __typename?: 'SchoolSupporter';
  _description?: Maybe<Scalars['String']>;
  billingRegisteredSchoolSupporter?: Maybe<BillingRegisteredSchoolSupporterListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  school?: Maybe<SchoolConfiguration>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  supporter?: Maybe<Organization>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userClicks?: Maybe<SchoolSupporterClickListResponse>;
  userSearches?: Maybe<SchoolSupporterSearchListResponse>;
};

export type SchoolSupporterBillingRegisteredSchoolSupporterArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BillingRegisteredSchoolSupporterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BillingRegisteredSchoolSupporterGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<BillingRegisteredSchoolSupporterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BillingRegisteredSchoolSupporterSort>>;
};

export type SchoolSupporterUserClicksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolSupporterClickFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolSupporterClickGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolSupporterClickOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolSupporterClickSort>>;
};

export type SchoolSupporterUserSearchesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolSupporterSearchFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolSupporterSearchGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolSupporterSearchOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolSupporterSearchSort>>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterBillingRegisteredSchoolSupporterManyRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredSchoolSupporterKeyFilter>>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterBillingRegisteredSchoolSupporterRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredSchoolSupporterKeyFilter>>;
  create?: InputMaybe<
    Array<InputMaybe<SchoolSupporter_BillingRegisteredSchoolSupporterCreateInput>>
  >;
};

/** SchoolSupporter relation input */
export type SchoolSupporterBillingRegisteredSchoolSupporterUpdateRelationInput = {
  connect?: InputMaybe<Array<BillingRegisteredSchoolSupporterKeyFilter>>;
  create?: InputMaybe<
    Array<InputMaybe<SchoolSupporter_BillingRegisteredSchoolSupporterCreateInput>>
  >;
  disconnect?: InputMaybe<Array<BillingRegisteredSchoolSupporterKeyFilter>>;
  reconnect?: InputMaybe<Array<BillingRegisteredSchoolSupporterKeyFilter>>;
  update?: InputMaybe<
    Array<InputMaybe<SchoolSupporter_BillingRegisteredSchoolSupporterUpdateInput>>
  >;
};

export type SchoolSupporterClick = {
  __typename?: 'SchoolSupporterClick';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  supporter?: Maybe<SchoolSupporter>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

/** SchoolSupporterClicks create input */
export type SchoolSupporterClickCreateInput = {
  supporter?: InputMaybe<SchoolSupporterClicksSupporterRelationInput>;
  user?: InputMaybe<SchoolSupporterClicksUserRelationInput>;
};

/** SchoolSupporterClicks create many input */
export type SchoolSupporterClickCreateManyInput = {
  supporter?: InputMaybe<SchoolSupporterClicksSupporterManyRelationInput>;
  user?: InputMaybe<SchoolSupporterClicksUserManyRelationInput>;
};

/** SchoolSupporterClicks delete input */
export type SchoolSupporterClickDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolSupporterClickFieldsPermissions create input */
export type SchoolSupporterClickFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolSupporterClickFilter = {
  AND?: InputMaybe<Array<SchoolSupporterClickFilter>>;
  OR?: InputMaybe<Array<SchoolSupporterClickFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  supporter?: InputMaybe<SchoolSupporterFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type SchoolSupporterClickGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: SchoolSupporterClickGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SchoolSupporterClickGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  supporter?: InputMaybe<SchoolSupporterGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type SchoolSupporterClickKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolSupporterClickListResponse output */
export type SchoolSupporterClickListResponse = {
  __typename?: 'SchoolSupporterClickListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<SchoolSupporterClick>;
};

/** SchoolSupporterClickManyResponse output */
export type SchoolSupporterClickManyResponse = {
  __typename?: 'SchoolSupporterClickManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SchoolSupporterClick>;
};

/** No longer supported. Use `sort` instead. */
export type SchoolSupporterClickOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** SchoolSupporterClicks subscription payload */
export type SchoolSupporterClickPayload = {
  __typename?: 'SchoolSupporterClickPayload';
  mutation: MutationType;
  node?: Maybe<SchoolSupporterClick>;
  previousValues?: Maybe<SchoolSupporterClick>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SchoolSupporterClickRelationFilter = {
  every?: InputMaybe<SchoolSupporterClickFilter>;
  none?: InputMaybe<SchoolSupporterClickFilter>;
  some?: InputMaybe<SchoolSupporterClickFilter>;
};

export type SchoolSupporterClickSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  supporter?: InputMaybe<SchoolSupporterSort>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

/** SchoolSupporterClicks subscription filter */
export type SchoolSupporterClickSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<SchoolSupporterClickFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** SchoolSupporterClicks update input */
export type SchoolSupporterClickUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  supporter?: InputMaybe<SchoolSupporterClicksSupporterUpdateRelationInput>;
  user?: InputMaybe<SchoolSupporterClicksUserUpdateRelationInput>;
};

export type SchoolSupporterClick_PermissionFilter = {
  AND?: InputMaybe<Array<SchoolSupporterClick_PermissionFilter>>;
  OR?: InputMaybe<Array<SchoolSupporterClick_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  supporter?: InputMaybe<SchoolSupporter_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
};

export type SchoolSupporterClick_PermissionRelationFilter = {
  every?: InputMaybe<SchoolSupporterClick_PermissionFilter>;
  none?: InputMaybe<SchoolSupporterClick_PermissionFilter>;
  some?: InputMaybe<SchoolSupporterClick_PermissionFilter>;
};

/** SchoolSupporterClicks relation input */
export type SchoolSupporterClicksSupporterManyRelationInput = {
  connect?: InputMaybe<SchoolSupporterKeyFilter>;
};

/** SchoolSupporterClicks relation input */
export type SchoolSupporterClicksSupporterRelationInput = {
  connect?: InputMaybe<SchoolSupporterKeyFilter>;
  create?: InputMaybe<UserClicks_SchoolSupporterCreateInput>;
};

/** SchoolSupporterClicks relation input */
export type SchoolSupporterClicksSupporterUpdateRelationInput = {
  connect?: InputMaybe<SchoolSupporterKeyFilter>;
  create?: InputMaybe<UserClicks_SchoolSupporterCreateInput>;
  disconnect?: InputMaybe<SchoolSupporterKeyFilter>;
  reconnect?: InputMaybe<SchoolSupporterKeyFilter>;
  update?: InputMaybe<UserClicks_SchoolSupporterUpdateInput>;
};

/** SchoolSupporterClicks relation input */
export type SchoolSupporterClicksUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** SchoolSupporterClicks relation input */
export type SchoolSupporterClicksUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<SupporterClicks_UserCreateInput>;
};

/** SchoolSupporterClicks relation input */
export type SchoolSupporterClicksUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<SupporterClicks_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<SupporterClicks_UserUpdateInput>;
};

/** SchoolSupporter create input */
export type SchoolSupporterCreateInput = {
  billingRegisteredSchoolSupporter?: InputMaybe<SchoolSupporterBillingRegisteredSchoolSupporterRelationInput>;
  school?: InputMaybe<SchoolSupporterSchoolRelationInput>;
  startDate: Scalars['Date'];
  status: Scalars['String'];
  supporter?: InputMaybe<SchoolSupporterSupporterRelationInput>;
  userClicks?: InputMaybe<SchoolSupporterUserClicksRelationInput>;
  userSearches?: InputMaybe<SchoolSupporterUserSearchesRelationInput>;
};

/** SchoolSupporter create many input */
export type SchoolSupporterCreateManyInput = {
  billingRegisteredSchoolSupporter?: InputMaybe<SchoolSupporterBillingRegisteredSchoolSupporterManyRelationInput>;
  school: SchoolSupporterSchoolManyRelationInput;
  startDate: Scalars['Date'];
  status: Scalars['String'];
  supporter: SchoolSupporterSupporterManyRelationInput;
  userClicks?: InputMaybe<SchoolSupporterUserClicksManyRelationInput>;
  userSearches?: InputMaybe<SchoolSupporterUserSearchesManyRelationInput>;
};

/** SchoolSupporter delete input */
export type SchoolSupporterDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolSupporterFieldsPermissions create input */
export type SchoolSupporterFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolSupporterFilter = {
  AND?: InputMaybe<Array<SchoolSupporterFilter>>;
  OR?: InputMaybe<Array<SchoolSupporterFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  billingRegisteredSchoolSupporter?: InputMaybe<BillingRegisteredSchoolSupporterRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  startDate?: InputMaybe<DatePredicate>;
  status?: InputMaybe<StringPredicate>;
  supporter?: InputMaybe<OrganizationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userClicks?: InputMaybe<SchoolSupporterClickRelationFilter>;
  userSearches?: InputMaybe<SchoolSupporterSearchRelationFilter>;
};

export type SchoolSupporterGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: SchoolSupporterGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SchoolSupporterGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  billingRegisteredSchoolSupporter?: InputMaybe<BillingRegisteredSchoolSupporterGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  startDate?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  supporter?: InputMaybe<OrganizationGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  userClicks?: InputMaybe<SchoolSupporterClickGroupByQuery>;
  userSearches?: InputMaybe<SchoolSupporterSearchGroupByQuery>;
};

export type SchoolSupporterKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolSupporterListResponse output */
export type SchoolSupporterListResponse = {
  __typename?: 'SchoolSupporterListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<SchoolSupporter>;
};

/** SchoolSupporterManyResponse output */
export type SchoolSupporterManyResponse = {
  __typename?: 'SchoolSupporterManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SchoolSupporter>;
};

/** No longer supported. Use `sort` instead. */
export type SchoolSupporterOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'startDate_ASC'
  | 'startDate_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** SchoolSupporter subscription payload */
export type SchoolSupporterPayload = {
  __typename?: 'SchoolSupporterPayload';
  mutation: MutationType;
  node?: Maybe<SchoolSupporter>;
  previousValues?: Maybe<SchoolSupporter>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SchoolSupporterRelationFilter = {
  every?: InputMaybe<SchoolSupporterFilter>;
  none?: InputMaybe<SchoolSupporterFilter>;
  some?: InputMaybe<SchoolSupporterFilter>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<SchoolSupporter_SchoolConfigurationCreateInput>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<SchoolSupporter_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<SchoolSupporter_SchoolConfigurationUpdateInput>;
};

export type SchoolSupporterSearch = {
  __typename?: 'SchoolSupporterSearch';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  supporter?: Maybe<SchoolSupporter>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

/** SchoolSupporterSearches create input */
export type SchoolSupporterSearchCreateInput = {
  supporter?: InputMaybe<SchoolSupporterSearchesSupporterRelationInput>;
  user?: InputMaybe<SchoolSupporterSearchesUserRelationInput>;
};

/** SchoolSupporterSearches create many input */
export type SchoolSupporterSearchCreateManyInput = {
  supporter?: InputMaybe<SchoolSupporterSearchesSupporterManyRelationInput>;
  user?: InputMaybe<SchoolSupporterSearchesUserManyRelationInput>;
};

/** SchoolSupporterSearches delete input */
export type SchoolSupporterSearchDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolSupporterSearchFieldsPermissions create input */
export type SchoolSupporterSearchFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolSupporterSearchFilter = {
  AND?: InputMaybe<Array<SchoolSupporterSearchFilter>>;
  OR?: InputMaybe<Array<SchoolSupporterSearchFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  supporter?: InputMaybe<SchoolSupporterFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type SchoolSupporterSearchGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: SchoolSupporterSearchGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SchoolSupporterSearchGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  supporter?: InputMaybe<SchoolSupporterGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type SchoolSupporterSearchKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** SchoolSupporterSearchListResponse output */
export type SchoolSupporterSearchListResponse = {
  __typename?: 'SchoolSupporterSearchListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<SchoolSupporterSearch>;
};

/** SchoolSupporterSearchManyResponse output */
export type SchoolSupporterSearchManyResponse = {
  __typename?: 'SchoolSupporterSearchManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SchoolSupporterSearch>;
};

/** No longer supported. Use `sort` instead. */
export type SchoolSupporterSearchOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** SchoolSupporterSearches subscription payload */
export type SchoolSupporterSearchPayload = {
  __typename?: 'SchoolSupporterSearchPayload';
  mutation: MutationType;
  node?: Maybe<SchoolSupporterSearch>;
  previousValues?: Maybe<SchoolSupporterSearch>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SchoolSupporterSearchRelationFilter = {
  every?: InputMaybe<SchoolSupporterSearchFilter>;
  none?: InputMaybe<SchoolSupporterSearchFilter>;
  some?: InputMaybe<SchoolSupporterSearchFilter>;
};

export type SchoolSupporterSearchSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  supporter?: InputMaybe<SchoolSupporterSort>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

/** SchoolSupporterSearches subscription filter */
export type SchoolSupporterSearchSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<SchoolSupporterSearchFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** SchoolSupporterSearches update input */
export type SchoolSupporterSearchUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  supporter?: InputMaybe<SchoolSupporterSearchesSupporterUpdateRelationInput>;
  user?: InputMaybe<SchoolSupporterSearchesUserUpdateRelationInput>;
};

export type SchoolSupporterSearch_PermissionFilter = {
  AND?: InputMaybe<Array<SchoolSupporterSearch_PermissionFilter>>;
  OR?: InputMaybe<Array<SchoolSupporterSearch_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  supporter?: InputMaybe<SchoolSupporter_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
};

export type SchoolSupporterSearch_PermissionRelationFilter = {
  every?: InputMaybe<SchoolSupporterSearch_PermissionFilter>;
  none?: InputMaybe<SchoolSupporterSearch_PermissionFilter>;
  some?: InputMaybe<SchoolSupporterSearch_PermissionFilter>;
};

/** SchoolSupporterSearches relation input */
export type SchoolSupporterSearchesSupporterManyRelationInput = {
  connect?: InputMaybe<SchoolSupporterKeyFilter>;
};

/** SchoolSupporterSearches relation input */
export type SchoolSupporterSearchesSupporterRelationInput = {
  connect?: InputMaybe<SchoolSupporterKeyFilter>;
  create?: InputMaybe<UserSearches_SchoolSupporterCreateInput>;
};

/** SchoolSupporterSearches relation input */
export type SchoolSupporterSearchesSupporterUpdateRelationInput = {
  connect?: InputMaybe<SchoolSupporterKeyFilter>;
  create?: InputMaybe<UserSearches_SchoolSupporterCreateInput>;
  disconnect?: InputMaybe<SchoolSupporterKeyFilter>;
  reconnect?: InputMaybe<SchoolSupporterKeyFilter>;
  update?: InputMaybe<UserSearches_SchoolSupporterUpdateInput>;
};

/** SchoolSupporterSearches relation input */
export type SchoolSupporterSearchesUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** SchoolSupporterSearches relation input */
export type SchoolSupporterSearchesUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<SupporterSearches_UserCreateInput>;
};

/** SchoolSupporterSearches relation input */
export type SchoolSupporterSearchesUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<SupporterSearches_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<SupporterSearches_UserUpdateInput>;
};

export type SchoolSupporterSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolConfigurationSort>;
  startDate?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  supporter?: InputMaybe<OrganizationSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** SchoolSupporter subscription filter */
export type SchoolSupporterSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<SchoolSupporterFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterSupporterManyRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterSupporterRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<SchoolSupporter_OrganizationCreateInput>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterSupporterUpdateRelationInput = {
  connect?: InputMaybe<OrganizationKeyFilter>;
  create?: InputMaybe<SchoolSupporter_OrganizationCreateInput>;
  disconnect?: InputMaybe<OrganizationKeyFilter>;
  reconnect?: InputMaybe<OrganizationKeyFilter>;
  update?: InputMaybe<SchoolSupporter_OrganizationUpdateInput>;
};

/** SchoolSupporter update input */
export type SchoolSupporterUpdateByFilterInput = {
  startDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
};

/** SchoolSupporter update input */
export type SchoolSupporterUpdateInput = {
  billingRegisteredSchoolSupporter?: InputMaybe<SchoolSupporterBillingRegisteredSchoolSupporterUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  school?: InputMaybe<SchoolSupporterSchoolUpdateRelationInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Scalars['String']>;
  supporter?: InputMaybe<SchoolSupporterSupporterUpdateRelationInput>;
  userClicks?: InputMaybe<SchoolSupporterUserClicksUpdateRelationInput>;
  userSearches?: InputMaybe<SchoolSupporterUserSearchesUpdateRelationInput>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterUserClicksManyRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterClickKeyFilter>>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterUserClicksRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterClickKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Supporter_SchoolSupporterClickCreateInput>>>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterUserClicksUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterClickKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Supporter_SchoolSupporterClickCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolSupporterClickKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolSupporterClickKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Supporter_SchoolSupporterClickUpdateInput>>>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterUserSearchesManyRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterSearchKeyFilter>>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterUserSearchesRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterSearchKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Supporter_SchoolSupporterSearchCreateInput>>>;
};

/** SchoolSupporter relation input */
export type SchoolSupporterUserSearchesUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterSearchKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Supporter_SchoolSupporterSearchCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolSupporterSearchKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolSupporterSearchKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Supporter_SchoolSupporterSearchUpdateInput>>>;
};

/** BillingRegisteredSchoolSupporters create input from schoolSupporter */
export type SchoolSupporter_BillingRegisteredSchoolSupporterCreateInput = {
  billingSchoolSupporters?: InputMaybe<BillingRegisteredSchoolSupportersBillingSchoolSupportersRelationInput>;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  planPayDate?: InputMaybe<Scalars['DateTime']>;
  price?: InputMaybe<Scalars['Int']>;
  schoolSupporter?: InputMaybe<BillingRegisteredSchoolSupportersSchoolSupporterRelationInput>;
  status?: InputMaybe<Scalars['String']>;
};

/** BillingRegisteredSchoolSupporters update input from schoolSupporter */
export type SchoolSupporter_BillingRegisteredSchoolSupporterUpdateInput = {
  data: BillingRegisteredSchoolSupporterUpdateInput;
  filter?: InputMaybe<BillingRegisteredSchoolSupporterKeyFilter>;
};

/** Organizations create input from schoolSupporter */
export type SchoolSupporter_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from schoolSupporter */
export type SchoolSupporter_OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

export type SchoolSupporter_PermissionFilter = {
  AND?: InputMaybe<Array<SchoolSupporter_PermissionFilter>>;
  OR?: InputMaybe<Array<SchoolSupporter_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  billingRegisteredSchoolSupporter?: InputMaybe<BillingRegisteredSchoolSupporter_PermissionRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  startDate?: InputMaybe<DatePredicate>;
  status?: InputMaybe<StringPredicate>;
  supporter?: InputMaybe<Organization_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userClicks?: InputMaybe<SchoolSupporterClick_PermissionRelationFilter>;
  userSearches?: InputMaybe<SchoolSupporterSearch_PermissionRelationFilter>;
};

export type SchoolSupporter_PermissionRelationFilter = {
  every?: InputMaybe<SchoolSupporter_PermissionFilter>;
  none?: InputMaybe<SchoolSupporter_PermissionFilter>;
  some?: InputMaybe<SchoolSupporter_PermissionFilter>;
};

/** SchoolConfiguration create input from schoolSupporter */
export type SchoolSupporter_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address: SchoolConfigurationAddressRelationInput;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from schoolSupporter */
export type SchoolSupporter_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** AdClicks create input from school */
export type School_AdClickCreateInput = {
  ad?: InputMaybe<AdClicksAdRelationInput>;
  /** Cost of this impression expressed on USD dollars. */
  cost?: InputMaybe<Scalars['Float']>;
  school?: InputMaybe<AdClicksSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<AdClicksUserRelationInput>;
};

/** AdClicks update input from school */
export type School_AdClickUpdateInput = {
  data: AdClickUpdateInput;
  filter?: InputMaybe<AdClickKeyFilter>;
};

/** AdViews create input from school */
export type School_AdViewCreateInput = {
  ad?: InputMaybe<AdViewsAdRelationInput>;
  /** Cost of this impression expressed on USD dollars. */
  cost?: InputMaybe<Scalars['Float']>;
  school?: InputMaybe<AdViewsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<AdViewsUserRelationInput>;
};

/** AdViews update input from school */
export type School_AdViewUpdateInput = {
  data: AdViewUpdateInput;
  filter?: InputMaybe<AdViewKeyFilter>;
};

/** Dashboard create input from school */
export type School_DashboardCreateInput = {
  school?: InputMaybe<DashboardSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  totalAdRevenue?: InputMaybe<Scalars['Float']>;
  totalAdvertisements?: InputMaybe<Scalars['Int']>;
  totalAllRevenue?: InputMaybe<Scalars['Float']>;
  totalDiscounts?: InputMaybe<Scalars['Int']>;
  totalDiscountsRevenue?: InputMaybe<Scalars['Float']>;
  totalMembers?: InputMaybe<Scalars['Int']>;
  totalSupporters?: InputMaybe<Scalars['Int']>;
  totalSupportersRevenue?: InputMaybe<Scalars['Float']>;
  totalVolume?: InputMaybe<Scalars['Int']>;
};

/** Dashboard update input from school */
export type School_DashboardUpdateInput = {
  data: DashboardUpdateInput;
  filter?: InputMaybe<DashboardKeyFilter>;
};

/** DiscountRedemptions create input from school */
export type School_DiscountRedemptionCreateInput = {
  discount: DiscountRedemptionsDiscountRelationInput;
  discountId?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<DiscountRedemptionsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user: DiscountRedemptionsUserRelationInput;
  userId?: InputMaybe<Scalars['String']>;
};

/** DiscountRedemptions update input from school */
export type School_DiscountRedemptionUpdateInput = {
  data: DiscountRedemptionUpdateInput;
  filter?: InputMaybe<DiscountRedemptionKeyFilter>;
};

/** Events create input from school */
export type School_EventCreateInput = {
  author?: InputMaybe<EventsAuthorRelationInput>;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<EventsImagesRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<EventsLocationRelationInput>;
  otherSponsors?: InputMaybe<EventsOtherSponsorsRelationInput>;
  school?: InputMaybe<EventsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  sponsorRequests?: InputMaybe<EventsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleSponsor?: InputMaybe<EventsTitleSponsorRelationInput>;
};

/** Events update input from school */
export type School_EventUpdateInput = {
  data: EventUpdateInput;
  filter?: InputMaybe<EventKeyFilter>;
};

/** GraduatingYears create input from school */
export type School_GraduatingYearCreateInput = {
  graduateCount: Scalars['Int'];
  school?: InputMaybe<GraduatingYearsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** GraduatingYears update input from school */
export type School_GraduatingYearUpdateInput = {
  data: GraduatingYearUpdateInput;
  filter?: InputMaybe<GraduatingYearKeyFilter>;
};

/** Groups create input from school */
export type School_GroupCreateInput = {
  audiences?: InputMaybe<GroupsAudiencesRelationInput>;
  creator?: InputMaybe<GroupsCreatorRelationInput>;
  description: Scalars['String'];
  groupAdminPreferences?: InputMaybe<GroupsGroupAdminPreferencesRelationInput>;
  groupToJoin?: InputMaybe<GroupsGroupToJoinRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<GroupsLogoRelationInput>;
  members?: InputMaybe<GroupsMembersRelationInput>;
  membersCount?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<GroupsPostsRelationInput>;
  postsCount?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<GroupsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Groups update input from school */
export type School_GroupUpdateInput = {
  data: GroupUpdateInput;
  filter?: InputMaybe<GroupKeyFilter>;
};

/** LegacyFeedMonths create input from school */
export type School_LegacyFeedMonthCreateInput = {
  legacyPosts?: InputMaybe<LegacyFeedMonthsLegacyPostsRelationInput>;
  legacyYears?: InputMaybe<LegacyFeedMonthsLegacyYearsRelationInput>;
  month?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<LegacyFeedMonthsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

/** LegacyFeedMonths update input from school */
export type School_LegacyFeedMonthUpdateInput = {
  data: LegacyFeedMonthUpdateInput;
  filter?: InputMaybe<LegacyFeedMonthKeyFilter>;
};

/** LegacyFeedPosts create input from school */
export type School_LegacyFeedPostCreateInput = {
  author?: InputMaybe<LegacyFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<LegacyFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<LegacyFeedPostsLastFlaggedByRelationInput>;
  legacyDate?: InputMaybe<Scalars['Date']>;
  media?: InputMaybe<LegacyFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<LegacyFeedPostsMentionsRelationInput>;
  month?: InputMaybe<LegacyFeedPostsMonthRelationInput>;
  monthId?: InputMaybe<Scalars['String']>;
  /** Date where the post will be available on the feed. */
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<LegacyFeedPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** LegacyFeedPosts update input from school */
export type School_LegacyFeedPostUpdateInput = {
  data: LegacyFeedPostUpdateInput;
  filter?: InputMaybe<LegacyFeedPostKeyFilter>;
};

/** LegacyFeedYears create input from school */
export type School_LegacyFeedYearCreateInput = {
  legacyMonths?: InputMaybe<LegacyFeedYearsLegacyMonthsRelationInput>;
  legacyYear?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<LegacyFeedYearsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
};

/** LegacyFeedYears update input from school */
export type School_LegacyFeedYearUpdateInput = {
  data: LegacyFeedYearUpdateInput;
  filter?: InputMaybe<LegacyFeedYearKeyFilter>;
};

/** RoleAddon create input from school */
export type School_RoleAddonCreateInput = {
  accessibleLayouts?: InputMaybe<RoleAddonAccessibleLayoutsRelationInput>;
  businessPermissions?: InputMaybe<Scalars['JSON']>;
  forumPermissions?: InputMaybe<Scalars['JSON']>;
  groupsPermissions?: InputMaybe<Scalars['JSON']>;
  /** Define the permissions within the "Home Posts". */
  homePostsPermissions?: InputMaybe<Scalars['JSON']>;
  /** Assigning this role to a user at registration */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isSystemRole?: InputMaybe<Scalars['Boolean']>;
  membersPermissions?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<RoleAddonRoleRelationInput>;
  school?: InputMaybe<RoleAddonSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  schoolPostsPermissions?: InputMaybe<Scalars['JSON']>;
  users?: InputMaybe<RoleAddonUsersRelationInput>;
};

/** RoleAddon update input from school */
export type School_RoleAddonUpdateInput = {
  data: RoleAddonUpdateInput;
  filter?: InputMaybe<RoleAddonKeyFilter>;
};

/** SchoolNewsPosts create input from school */
export type School_SchoolNewsPostCreateInput = {
  author?: InputMaybe<SchoolNewsPostsAuthorRelationInput>;
  commentsAllowed: Scalars['Boolean'];
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<SchoolNewsPostsFlaggedByRelationInput>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned: Scalars['Boolean'];
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<SchoolNewsPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<SchoolNewsPostsMediaRelationInput>;
  mentions?: InputMaybe<SchoolNewsPostsMentionsRelationInput>;
  postDate?: InputMaybe<Scalars['DateTime']>;
  reportStatus?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<SchoolNewsPostsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** SchoolNewsPosts update input from school */
export type School_SchoolNewsPostUpdateInput = {
  data: SchoolNewsPostUpdateInput;
  filter?: InputMaybe<SchoolNewsPostKeyFilter>;
};

/** SchoolSupporter create input from school */
export type School_SchoolSupporterCreateInput = {
  billingRegisteredSchoolSupporter?: InputMaybe<SchoolSupporterBillingRegisteredSchoolSupporterRelationInput>;
  school?: InputMaybe<SchoolSupporterSchoolRelationInput>;
  startDate: Scalars['Date'];
  status: Scalars['String'];
  supporter: SchoolSupporterSupporterRelationInput;
  userClicks?: InputMaybe<SchoolSupporterUserClicksRelationInput>;
  userSearches?: InputMaybe<SchoolSupporterUserSearchesRelationInput>;
};

/** SchoolSupporter update input from school */
export type School_SchoolSupporterUpdateInput = {
  data: SchoolSupporterUpdateInput;
  filter?: InputMaybe<SchoolSupporterKeyFilter>;
};

/** TrendingSearches create input from school */
export type School_TrendingSearchCreateInput = {
  school?: InputMaybe<TrendingSearchesSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  searchesCount?: InputMaybe<Scalars['Int']>;
  wordOrPhrase: Scalars['String'];
};

/** TrendingSearches update input from school */
export type School_TrendingSearchUpdateInput = {
  data: TrendingSearchUpdateInput;
  filter?: InputMaybe<TrendingSearchKeyFilter>;
};

/** UserSchoolMembership create input from school */
export type School_UserSchoolMembershipCreateInput = {
  school?: InputMaybe<UserSchoolMembershipSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  /** Status of the current membership of the user. */
  status?: InputMaybe<Scalars['String']>;
  user: UserSchoolMembershipUserRelationInput;
  userId?: InputMaybe<Scalars['String']>;
};

/** UserSchoolMembership update input from school */
export type School_UserSchoolMembershipUpdateInput = {
  data: UserSchoolMembershipUpdateInput;
  filter?: InputMaybe<UserSchoolMembershipKeyFilter>;
};

/** CollectiveIntelligenceThreads create input from schools */
export type Schools_CollectiveIntelligenceThreadCreateInput = {
  creator?: InputMaybe<CollectiveIntelligenceThreadsCreatorRelationInput>;
  images?: InputMaybe<CollectiveIntelligenceThreadsImagesRelationInput>;
  schools?: InputMaybe<CollectiveIntelligenceThreadsSchoolsRelationInput>;
  text: Scalars['String'];
  title: Scalars['String'];
  views?: InputMaybe<CollectiveIntelligenceThreadsViewsRelationInput>;
};

/** CollectiveIntelligenceThreads update input from schools */
export type Schools_CollectiveIntelligenceThreadUpdateInput = {
  data: CollectiveIntelligenceThreadUpdateInput;
  filter?: InputMaybe<CollectiveIntelligenceThreadKeyFilter>;
};

/** DebtOperationHistories create input from schools */
export type Schools_DebtOperationHistoryCreateInput = {
  amount?: InputMaybe<Scalars['Int']>;
  billingType?: InputMaybe<Scalars['String']>;
  dateTimeOperation: Scalars['DateTime'];
  debt?: InputMaybe<DebtOperationHistoriesDebtRelationInput>;
  operation: Scalars['String'];
  schools?: InputMaybe<DebtOperationHistoriesSchoolsRelationInput>;
};

/** DebtOperationHistories update input from schools */
export type Schools_DebtOperationHistoryUpdateInput = {
  data: DebtOperationHistoryUpdateInput;
  filter?: InputMaybe<DebtOperationHistoryKeyFilter>;
};

/** Discounts create input from schools */
export type Schools_DiscountCreateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingRelationInput>;
  amountPercent: Scalars['Int'];
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostRelationInput>;
  expirationDate: Scalars['DateTime'];
  images?: InputMaybe<DiscountsImagesRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationRelationInput>;
  payment?: InputMaybe<DiscountsPaymentRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsRelationInput>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  terms: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

/** Discounts update input from schools */
export type Schools_DiscountUpdateInput = {
  data: DiscountUpdateInput;
  filter?: InputMaybe<DiscountKeyFilter>;
};

/** HomeFeedPosts create input from schools */
export type Schools_HomeFeedPostCreateInput = {
  author?: InputMaybe<HomeFeedPostsAuthorRelationInput>;
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<HomeFeedPostsDiscountRelationInput>;
  flagReason?: InputMaybe<Scalars['String']>;
  flaggedAt?: InputMaybe<Scalars['DateTime']>;
  flaggedBy?: InputMaybe<HomeFeedPostsFlaggedByRelationInput>;
  isAutoReported?: InputMaybe<Scalars['Boolean']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Being set to TRUE, if you saw this post in Content Curation page */
  isReviewed?: InputMaybe<Scalars['Boolean']>;
  lastFlaggedBy?: InputMaybe<HomeFeedPostsLastFlaggedByRelationInput>;
  media?: InputMaybe<HomeFeedPostsMediaRelationInput>;
  mentions?: InputMaybe<HomeFeedPostsMentionsRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<HomeFeedPostsSchoolsRelationInput>;
  /**
   * Approved - post is displayed
   * Pending - default status, awaits auto-approval/approved by admins. Not displayed
   * Rejected - auto-rejected/rejected by admins
   * reportReviewPending - post got flagged, pending review
   */
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** HomeFeedPosts update input from schools */
export type Schools_HomeFeedPostUpdateInput = {
  data: HomeFeedPostUpdateInput;
  filter?: InputMaybe<HomeFeedPostKeyFilter>;
};

/** JobOffers create input from schools */
export type Schools_JobOfferCreateInput = {
  creator?: InputMaybe<JobOffersCreatorRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  engagementCount?: InputMaybe<Scalars['Int']>;
  interestedUsers?: InputMaybe<JobOffersInterestedUsersRelationInput>;
  managerCreator?: InputMaybe<JobOffersManagerCreatorRelationInput>;
  organization?: InputMaybe<JobOffersOrganizationRelationInput>;
  position: Scalars['String'];
  postDate?: InputMaybe<Scalars['DateTime']>;
  schools?: InputMaybe<JobOffersSchoolsRelationInput>;
};

/** JobOffers update input from schools */
export type Schools_JobOfferUpdateInput = {
  data: JobOfferUpdateInput;
  filter?: InputMaybe<JobOfferKeyFilter>;
};

export type SendBusinessAdministratorInviteInput = {
  /** Email address of the business to invite. */
  email: Scalars['String'];
};

export type SendBusinessManagerInviteInput = {
  /** Email of the user. */
  email: Scalars['String'];
};

export type SendInboxMessageNotificationRequestInput = {
  authorId: Scalars['String'];
  inboxId: Scalars['String'];
  subject: Scalars['JSON'];
  userIds: Array<InputMaybe<Scalars['String']>>;
};

export type SendPostReactionNotificationRequestInput = {
  authorId: Scalars['String'];
  postId: Scalars['String'];
  type: Scalars['String'];
};

export type SendSchoolAdministratorInviteInput = {
  /** Affiliation of the member to invite. */
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description of the affiliation of the member to invite. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  /** Email address of the member to invite. */
  email: Scalars['String'];
  /** First name of the member to invite. */
  firstname?: InputMaybe<Scalars['String']>;
  /** Last name of the member to invite. */
  lastname?: InputMaybe<Scalars['String']>;
};

export type SendSchoolMemberInviteInput = {
  /** Affiliation of the member to invite. */
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description of the affiliation of the member to invite. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  /** Email address of the member to invite. */
  email: Scalars['String'];
  /** First name of the member to invite. */
  firstname?: InputMaybe<Scalars['String']>;
  /**
   * Graduating year of the member to invite.
   * Should be formatted as https://en.wikipedia.org/wiki/ISO_8601 date.
   */
  graduatingYear?: InputMaybe<Scalars['String']>;
  /** Last name of the member to invite. */
  lastname?: InputMaybe<Scalars['String']>;
  /** Phone number of the member to invite. */
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** UserNotifications create input from sender */
export type Sender_UserNotificationCreateInput = {
  /**
   * Set "true" for notifications that should be deleted (e.g. on trade confirm)
   * Need this to get rid of templateKey filter
   */
  deletable?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<UserNotificationsImageRelationInput>;
  /**
   * Optional notification params
   *
   * params: Record<string, any>
   */
  infoJSON?: InputMaybe<Scalars['JSON']>;
  /** Contains the related item id, used to group notifications, could be post id, group id, profile id, etc */
  relatedItemID?: InputMaybe<Scalars['String']>;
  sender?: InputMaybe<UserNotificationsSenderRelationInput>;
  template: UserNotificationsTemplateRelationInput;
  userNotificationsByUsers?: InputMaybe<UserNotificationsUserNotificationsByUsersRelationInput>;
};

/** UserNotifications update input from sender */
export type Sender_UserNotificationUpdateInput = {
  data: UserNotificationUpdateInput;
  filter?: InputMaybe<UserNotificationKeyFilter>;
};

/** Users create input from sentMessages */
export type SentMessages_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from sentMessages */
export type SentMessages_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Users create input from sentNotifications */
export type SentNotifications_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from sentNotifications */
export type SentNotifications_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

export type Setting = {
  __typename?: 'Setting';
  _description?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  buttonLinkColor?: Maybe<Scalars['String']>;
  containerColor?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  currency?: Maybe<Scalars['String']>;
  dateFormat?: Maybe<Scalars['String']>;
  landingPageImage?: Maybe<File>;
  language?: Maybe<Scalars['String']>;
  leftNavColor?: Maybe<Scalars['String']>;
  menuBarBGColor?: Maybe<Scalars['String']>;
  menuBarIconsColor?: Maybe<Scalars['String']>;
  menuBarLogo?: Maybe<File>;
  passwordMinLength?: Maybe<Scalars['Int']>;
  passwordRequireLowercase?: Maybe<Scalars['Boolean']>;
  passwordRequireNumbers?: Maybe<Scalars['Boolean']>;
  passwordRequireSpecial?: Maybe<Scalars['Boolean']>;
  passwordRequireUppercase?: Maybe<Scalars['Boolean']>;
  passwordUpdateInterval?: Maybe<Scalars['Int']>;
  rememberDevice?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userInterfaceStyle?: Maybe<Scalars['String']>;
  vanityUrl?: Maybe<Scalars['String']>;
};

export type SettingFilter = {
  AND?: InputMaybe<Array<SettingFilter>>;
  OR?: InputMaybe<Array<SettingFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  bgColor?: InputMaybe<StringPredicate>;
  buttonLinkColor?: InputMaybe<StringPredicate>;
  containerColor?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  currency?: InputMaybe<StringPredicate>;
  dateFormat?: InputMaybe<StringPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  landingPageImage?: InputMaybe<FileFilter>;
  language?: InputMaybe<StringPredicate>;
  leftNavColor?: InputMaybe<StringPredicate>;
  menuBarBGColor?: InputMaybe<StringPredicate>;
  menuBarIconsColor?: InputMaybe<StringPredicate>;
  menuBarLogo?: InputMaybe<FileFilter>;
  passwordMinLength?: InputMaybe<IntPredicate>;
  passwordRequireLowercase?: InputMaybe<BoolPredicate>;
  passwordRequireNumbers?: InputMaybe<BoolPredicate>;
  passwordRequireSpecial?: InputMaybe<BoolPredicate>;
  passwordRequireUppercase?: InputMaybe<BoolPredicate>;
  passwordUpdateInterval?: InputMaybe<IntPredicate>;
  rememberDevice?: InputMaybe<StringPredicate>;
  timezone?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userInterfaceStyle?: InputMaybe<StringPredicate>;
  vanityUrl?: InputMaybe<StringPredicate>;
};

export type SettingGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: SettingGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type SettingGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  bgColor?: InputMaybe<Array<GroupByField>>;
  buttonLinkColor?: InputMaybe<Array<GroupByField>>;
  containerColor?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  currency?: InputMaybe<Array<GroupByField>>;
  dateFormat?: InputMaybe<Array<GroupByField>>;
  landingPageImage?: InputMaybe<FileGroupByQuery>;
  language?: InputMaybe<Array<GroupByField>>;
  leftNavColor?: InputMaybe<Array<GroupByField>>;
  menuBarBGColor?: InputMaybe<Array<GroupByField>>;
  menuBarIconsColor?: InputMaybe<Array<GroupByField>>;
  menuBarLogo?: InputMaybe<FileGroupByQuery>;
  passwordMinLength?: InputMaybe<Array<GroupByField>>;
  passwordRequireLowercase?: InputMaybe<Array<GroupByField>>;
  passwordRequireNumbers?: InputMaybe<Array<GroupByField>>;
  passwordRequireSpecial?: InputMaybe<Array<GroupByField>>;
  passwordRequireUppercase?: InputMaybe<Array<GroupByField>>;
  passwordUpdateInterval?: InputMaybe<Array<GroupByField>>;
  rememberDevice?: InputMaybe<Array<GroupByField>>;
  timezone?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  userInterfaceStyle?: InputMaybe<Array<GroupByField>>;
  vanityUrl?: InputMaybe<Array<GroupByField>>;
};

/** SettingListResponse output */
export type SettingListResponse = {
  __typename?: 'SettingListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Setting>;
};

/** No longer supported. Use `sort` instead. */
export type SettingOrderBy =
  | 'bgColor_ASC'
  | 'bgColor_DESC'
  | 'buttonLinkColor_ASC'
  | 'buttonLinkColor_DESC'
  | 'containerColor_ASC'
  | 'containerColor_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'currency_ASC'
  | 'currency_DESC'
  | 'dateFormat_ASC'
  | 'dateFormat_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'language_ASC'
  | 'language_DESC'
  | 'leftNavColor_ASC'
  | 'leftNavColor_DESC'
  | 'menuBarBGColor_ASC'
  | 'menuBarBGColor_DESC'
  | 'menuBarIconsColor_ASC'
  | 'menuBarIconsColor_DESC'
  | 'passwordMinLength_ASC'
  | 'passwordMinLength_DESC'
  | 'passwordRequireLowercase_ASC'
  | 'passwordRequireLowercase_DESC'
  | 'passwordRequireNumbers_ASC'
  | 'passwordRequireNumbers_DESC'
  | 'passwordRequireSpecial_ASC'
  | 'passwordRequireSpecial_DESC'
  | 'passwordRequireUppercase_ASC'
  | 'passwordRequireUppercase_DESC'
  | 'passwordUpdateInterval_ASC'
  | 'passwordUpdateInterval_DESC'
  | 'rememberDevice_ASC'
  | 'rememberDevice_DESC'
  | 'timezone_ASC'
  | 'timezone_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'userInterfaceStyle_ASC'
  | 'userInterfaceStyle_DESC'
  | 'vanityUrl_ASC'
  | 'vanityUrl_DESC';

/** Settings subscription payload */
export type SettingPayload = {
  __typename?: 'SettingPayload';
  mutation: MutationType;
  node?: Maybe<Setting>;
  previousValues?: Maybe<Setting>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SettingRelationFilter = {
  every?: InputMaybe<SettingFilter>;
  none?: InputMaybe<SettingFilter>;
  some?: InputMaybe<SettingFilter>;
};

export type SettingSort = {
  bgColor?: InputMaybe<SortOrder>;
  buttonLinkColor?: InputMaybe<SortOrder>;
  containerColor?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  currency?: InputMaybe<SortOrder>;
  dateFormat?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  landingPageImage?: InputMaybe<FileSort>;
  language?: InputMaybe<SortOrder>;
  leftNavColor?: InputMaybe<SortOrder>;
  menuBarBGColor?: InputMaybe<SortOrder>;
  menuBarIconsColor?: InputMaybe<SortOrder>;
  menuBarLogo?: InputMaybe<FileSort>;
  passwordMinLength?: InputMaybe<SortOrder>;
  passwordRequireLowercase?: InputMaybe<SortOrder>;
  passwordRequireNumbers?: InputMaybe<SortOrder>;
  passwordRequireSpecial?: InputMaybe<SortOrder>;
  passwordRequireUppercase?: InputMaybe<SortOrder>;
  passwordUpdateInterval?: InputMaybe<SortOrder>;
  rememberDevice?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userInterfaceStyle?: InputMaybe<SortOrder>;
  vanityUrl?: InputMaybe<SortOrder>;
};

/** Settings subscription filter */
export type SettingSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<SettingFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** Settings update input */
export type SettingUpdateInput = {
  bgColor?: InputMaybe<Scalars['String']>;
  buttonLinkColor?: InputMaybe<Scalars['String']>;
  containerColor?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  dateFormat?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  leftNavColor?: InputMaybe<Scalars['String']>;
  menuBarBGColor?: InputMaybe<Scalars['String']>;
  menuBarIconsColor?: InputMaybe<Scalars['String']>;
  passwordMinLength?: InputMaybe<Scalars['Int']>;
  passwordRequireLowercase?: InputMaybe<Scalars['Boolean']>;
  passwordRequireNumbers?: InputMaybe<Scalars['Boolean']>;
  passwordRequireSpecial?: InputMaybe<Scalars['Boolean']>;
  passwordRequireUppercase?: InputMaybe<Scalars['Boolean']>;
  passwordUpdateInterval?: InputMaybe<Scalars['Int']>;
  rememberDevice?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  userInterfaceStyle?: InputMaybe<Scalars['String']>;
  vanityUrl?: InputMaybe<Scalars['String']>;
};

export type Setting_PermissionFilter = {
  AND?: InputMaybe<Array<Setting_PermissionFilter>>;
  OR?: InputMaybe<Array<Setting_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  bgColor?: InputMaybe<StringPredicate>;
  buttonLinkColor?: InputMaybe<StringPredicate>;
  containerColor?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  currency?: InputMaybe<StringPredicate>;
  dateFormat?: InputMaybe<StringPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  landingPageImage?: InputMaybe<File_PermissionFilter>;
  language?: InputMaybe<StringPredicate>;
  leftNavColor?: InputMaybe<StringPredicate>;
  menuBarBGColor?: InputMaybe<StringPredicate>;
  menuBarIconsColor?: InputMaybe<StringPredicate>;
  menuBarLogo?: InputMaybe<File_PermissionFilter>;
  passwordMinLength?: InputMaybe<IntPredicate>;
  passwordRequireLowercase?: InputMaybe<BoolPredicate>;
  passwordRequireNumbers?: InputMaybe<BoolPredicate>;
  passwordRequireSpecial?: InputMaybe<BoolPredicate>;
  passwordRequireUppercase?: InputMaybe<BoolPredicate>;
  passwordUpdateInterval?: InputMaybe<IntPredicate>;
  rememberDevice?: InputMaybe<StringPredicate>;
  timezone?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userInterfaceStyle?: InputMaybe<StringPredicate>;
  vanityUrl?: InputMaybe<StringPredicate>;
};

export type Setting_PermissionRelationFilter = {
  every?: InputMaybe<Setting_PermissionFilter>;
  none?: InputMaybe<Setting_PermissionFilter>;
  some?: InputMaybe<Setting_PermissionFilter>;
};

/** SignUpResendInput */
export type SignUpResendInput = {
  email: Scalars['String'];
};

export type SmartAddress = {
  __typename?: 'SmartAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type SmartAddressGroupByQuery = {
  city?: InputMaybe<Array<GroupByField>>;
  country?: InputMaybe<Array<GroupByField>>;
  state?: InputMaybe<Array<GroupByField>>;
  street1?: InputMaybe<Array<GroupByField>>;
  street2?: InputMaybe<Array<GroupByField>>;
  zip?: InputMaybe<Array<GroupByField>>;
};

/** SmartAddressInput create input */
export type SmartAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type SmartAddressPredicate = {
  city?: InputMaybe<StringPredicate>;
  country?: InputMaybe<StringPredicate>;
  state?: InputMaybe<StringPredicate>;
  street1?: InputMaybe<StringPredicate>;
  street2?: InputMaybe<StringPredicate>;
  zip?: InputMaybe<StringPredicate>;
};

export type SmartAddressSort = {
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  street1?: InputMaybe<SortOrder>;
  street2?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

/** Smart Field Attributes */
export type SmartFieldTypeAttributes = {
  __typename?: 'SmartFieldTypeAttributes';
  format: Scalars['String'];
  innerFields?: Maybe<Array<Maybe<CustomTableField>>>;
};

/** Smart Type Format Enum */
export type SmartTypeFormatEnum = 'ADDRESS' | 'PHONE';

/** SortOrder */
export type SortOrder = 'ASC' | 'DESC';

/** BillingRegisteredSpecialOffers create input from specialOffer */
export type SpecialOffer_BillingRegisteredSpecialOfferCreateInput = {
  billingSpecialOffers: BillingRegisteredSpecialOffersBillingSpecialOffersRelationInput;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  planPayDate: Scalars['DateTime'];
  price?: InputMaybe<Scalars['Int']>;
  specialOffer?: InputMaybe<BillingRegisteredSpecialOffersSpecialOfferRelationInput>;
  status?: InputMaybe<Scalars['String']>;
};

/** BillingRegisteredSpecialOffers update input from specialOffer */
export type SpecialOffer_BillingRegisteredSpecialOfferUpdateInput = {
  data: BillingRegisteredSpecialOfferUpdateInput;
  filter?: InputMaybe<BillingRegisteredSpecialOfferKeyFilter>;
};

/** SchoolConfigurationImages create input from splashImage */
export type SplashImage_SchoolConfigurationImageCreateInput = {
  applicationLogo?: InputMaybe<SchoolConfigurationImagesApplicationLogoRelationInput>;
  applicationLogoId?: InputMaybe<Scalars['String']>;
  browserLogo?: InputMaybe<SchoolConfigurationImagesBrowserLogoRelationInput>;
  browserLogoId?: InputMaybe<Scalars['String']>;
  configuration: SchoolConfigurationImagesConfigurationRelationInput;
  emailBanner?: InputMaybe<SchoolConfigurationImagesEmailBannerRelationInput>;
  emailLogo?: InputMaybe<SchoolConfigurationImagesEmailLogoRelationInput>;
  logo?: InputMaybe<SchoolConfigurationImagesLogoRelationInput>;
  splashImage?: InputMaybe<SchoolConfigurationImagesSplashImageRelationInput>;
};

/** SchoolConfigurationImages update input from splashImage */
export type SplashImage_SchoolConfigurationImageUpdateInput = {
  data: SchoolConfigurationImageUpdateInput;
  filter?: InputMaybe<SchoolConfigurationImageKeyFilter>;
};

/** Events create input from sponsorRequests */
export type SponsorRequests_EventCreateInput = {
  author?: InputMaybe<EventsAuthorRelationInput>;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<EventsImagesRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<EventsLocationRelationInput>;
  otherSponsors?: InputMaybe<EventsOtherSponsorsRelationInput>;
  school?: InputMaybe<EventsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  sponsorRequests?: InputMaybe<EventsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleSponsor?: InputMaybe<EventsTitleSponsorRelationInput>;
};

/** Events update input from sponsorRequests */
export type SponsorRequests_EventUpdateInput = {
  author?: InputMaybe<EventsAuthorUpdateRelationInput>;
  date?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<EventsImagesUpdateRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<EventsLocationUpdateRelationInput>;
  otherSponsors?: InputMaybe<EventsOtherSponsorsUpdateRelationInput>;
  school?: InputMaybe<EventsSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  sponsorRequests?: InputMaybe<EventsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleSponsor?: InputMaybe<EventsTitleSponsorUpdateRelationInput>;
};

/** Organizations create input from sponsorRequests */
export type SponsorRequests_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from sponsorRequests */
export type SponsorRequests_OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

/** EventSponsorRequests create input from sponsor */
export type Sponsor_EventSponsorRequestCreateInput = {
  event: EventSponsorRequestsEventRelationInput;
  sponsor?: InputMaybe<EventSponsorRequestsSponsorRelationInput>;
  status: Scalars['String'];
};

/** EventSponsorRequests update input from sponsor */
export type Sponsor_EventSponsorRequestUpdateInput = {
  data: EventSponsorRequestUpdateInput;
  filter?: InputMaybe<EventSponsorRequestKeyFilter>;
};

export type StreamChatAuthorizeResponse = {
  __typename?: 'StreamChatAuthorizeResponse';
  idToken: Scalars['String'];
};

export type StringPadFunctionArguments = {
  len: Scalars['Int'];
  str: Scalars['String'];
};

export type StringPredicate = {
  contains?: InputMaybe<Scalars['String']>;
  ends_with?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  not_contains?: InputMaybe<Scalars['String']>;
  not_ends_with?: InputMaybe<Scalars['String']>;
  not_equals?: InputMaybe<Scalars['String']>;
  not_in?: InputMaybe<Array<Scalars['String']>>;
  not_starts_with?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
};

export type StringPredicateHaving = {
  AND?: InputMaybe<Array<StringPredicateHaving>>;
  OR?: InputMaybe<Array<StringPredicateHaving>>;
  contains?: InputMaybe<Scalars['String']>;
  ends_with?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  is_empty?: InputMaybe<Scalars['Boolean']>;
  is_not_empty?: InputMaybe<Scalars['Boolean']>;
  not_contains?: InputMaybe<Scalars['String']>;
  not_ends_with?: InputMaybe<Scalars['String']>;
  not_equals?: InputMaybe<Scalars['String']>;
  not_in?: InputMaybe<Array<Scalars['String']>>;
  not_starts_with?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
};

export type StringTrimMode = 'BOTH' | 'LEADING' | 'TRAILING';

export type StripeInformation = {
  __typename?: 'StripeInformation';
  _description?: Maybe<Scalars['String']>;
  connectedAccountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customerId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userPreference?: Maybe<UserPreference>;
};

/** StripeInformations create input */
export type StripeInformationCreateInput = {
  connectedAccountId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  userPreference?: InputMaybe<StripeInformationsUserPreferenceRelationInput>;
};

/** StripeInformations create many input */
export type StripeInformationCreateManyInput = {
  connectedAccountId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  userPreference: StripeInformationsUserPreferenceManyRelationInput;
};

/** StripeInformations delete input */
export type StripeInformationDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** StripeInformationFieldsPermissions create input */
export type StripeInformationFieldsPermissions = {
  connectedAccountId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type StripeInformationFilter = {
  AND?: InputMaybe<Array<StripeInformationFilter>>;
  OR?: InputMaybe<Array<StripeInformationFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  connectedAccountId?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  customerId?: InputMaybe<StringPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userPreference?: InputMaybe<UserPreferenceFilter>;
};

export type StripeInformationGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: StripeInformationGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type StripeInformationGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  connectedAccountId?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  customerId?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  userPreference?: InputMaybe<UserPreferenceGroupByQuery>;
};

export type StripeInformationKeyFilter = {
  connectedAccountId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** StripeInformationListResponse output */
export type StripeInformationListResponse = {
  __typename?: 'StripeInformationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<StripeInformation>;
};

/** StripeInformationManyResponse output */
export type StripeInformationManyResponse = {
  __typename?: 'StripeInformationManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<StripeInformation>;
};

/** No longer supported. Use `sort` instead. */
export type StripeInformationOrderBy =
  | 'connectedAccountId_ASC'
  | 'connectedAccountId_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'customerId_ASC'
  | 'customerId_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** StripeInformations subscription payload */
export type StripeInformationPayload = {
  __typename?: 'StripeInformationPayload';
  mutation: MutationType;
  node?: Maybe<StripeInformation>;
  previousValues?: Maybe<StripeInformation>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StripeInformationSort = {
  connectedAccountId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  customerId?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userPreference?: InputMaybe<UserPreferenceSort>;
};

/** StripeInformations subscription filter */
export type StripeInformationSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<StripeInformationFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** StripeInformations update input */
export type StripeInformationUpdateByFilterInput = {
  connectedAccountId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  customerId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** StripeInformations update input */
export type StripeInformationUpdateInput = {
  connectedAccountId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  userPreference?: InputMaybe<StripeInformationsUserPreferenceUpdateRelationInput>;
};

export type StripeInformation_PermissionFilter = {
  AND?: InputMaybe<Array<StripeInformation_PermissionFilter>>;
  OR?: InputMaybe<Array<StripeInformation_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  connectedAccountId?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  customerId?: InputMaybe<StringPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userPreference?: InputMaybe<UserPreference_PermissionFilter>;
};

/** UserPreferences create input from stripeInformation */
export type StripeInformation_UserPreferenceCreateInput = {
  address?: InputMaybe<UserPreferencesAddressRelationInput>;
  jobsOfInterest?: InputMaybe<UserPreferencesJobsOfInterestRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  secondaryPhoneNumber?: InputMaybe<Scalars['String']>;
  stripeInformation?: InputMaybe<UserPreferencesStripeInformationRelationInput>;
  user?: InputMaybe<UserPreferencesUserRelationInput>;
};

/** UserPreferences update input from stripeInformation */
export type StripeInformation_UserPreferenceUpdateInput = {
  address?: InputMaybe<UserPreferencesAddressUpdateRelationInput>;
  jobsOfInterest?: InputMaybe<UserPreferencesJobsOfInterestUpdateRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  secondaryPhoneNumber?: InputMaybe<Scalars['String']>;
  stripeInformation?: InputMaybe<UserPreferencesStripeInformationUpdateRelationInput>;
  user?: InputMaybe<UserPreferencesUserUpdateRelationInput>;
};

/** StripeInformations relation input */
export type StripeInformationsUserPreferenceManyRelationInput = {
  connect?: InputMaybe<UserPreferenceKeyFilter>;
};

/** StripeInformations relation input */
export type StripeInformationsUserPreferenceRelationInput = {
  connect?: InputMaybe<UserPreferenceKeyFilter>;
  create?: InputMaybe<StripeInformation_UserPreferenceCreateInput>;
};

/** StripeInformations relation input */
export type StripeInformationsUserPreferenceUpdateRelationInput = {
  connect?: InputMaybe<UserPreferenceKeyFilter>;
  create?: InputMaybe<StripeInformation_UserPreferenceCreateInput>;
  disconnect?: InputMaybe<UserPreferenceKeyFilter>;
  reconnect?: InputMaybe<UserPreferenceKeyFilter>;
  update?: InputMaybe<StripeInformation_UserPreferenceUpdateInput>;
};

export type Subscription = {
  __typename?: 'Subscription';
  AdClicks?: Maybe<AdClickPayload>;
  AdViews?: Maybe<AdViewPayload>;
  Addresses?: Maybe<AddressPayload>;
  Advertising?: Maybe<AdvertisingPayload>;
  AdvertisingCampaignAds?: Maybe<AdvertisingCampaignAdPayload>;
  AdvertisingSampaigns?: Maybe<AdvertisingSampaignPayload>;
  AdvertisingSupporter?: Maybe<AdvertisingSupporterPayload>;
  ApiTokens?: Maybe<ApiTokenPayload>;
  Audiences?: Maybe<AudiencePayload>;
  AuthenticationProfiles?: Maybe<AuthenticationProfilePayload>;
  AuthenticationSettings?: Maybe<AuthenticationSettingPayload>;
  BillingRegisteredAds?: Maybe<BillingRegisteredAdPayload>;
  BillingRegisteredSchoolSupporters?: Maybe<BillingRegisteredSchoolSupporterPayload>;
  BillingRegisteredSpecialOffers?: Maybe<BillingRegisteredSpecialOfferPayload>;
  BillingSchoolSupporters?: Maybe<BillingSchoolSupporterPayload>;
  BillingSpecialOffers?: Maybe<BillingSpecialOfferPayload>;
  BillingsAds?: Maybe<BillingsAdPayload>;
  BusinessReport?: Maybe<BusinessReportPayload>;
  CiCdMigrations?: Maybe<CiCdMigrationPayload>;
  CollectiveIntelligenceThreads?: Maybe<CollectiveIntelligenceThreadPayload>;
  ContentReport?: Maybe<ContentReportPayload>;
  Dashboard?: Maybe<DashboardPayload>;
  DebtOperationHistories?: Maybe<DebtOperationHistoryPayload>;
  Debts?: Maybe<DebtPayload>;
  DiscountRedemptions?: Maybe<DiscountRedemptionPayload>;
  Discounts?: Maybe<DiscountPayload>;
  EducationalInstitutions?: Maybe<EducationalInstitutionPayload>;
  EnvironmentVariables?: Maybe<EnvironmentVariablePayload>;
  EventSponsorRequests?: Maybe<EventSponsorRequestPayload>;
  Events?: Maybe<EventPayload>;
  Files?: Maybe<FilePayload>;
  FilesSettings?: Maybe<FilesSettingPayload>;
  GraduatingYears?: Maybe<GraduatingYearPayload>;
  GraduatingYearsMetrics?: Maybe<GraduatingYearsMetricPayload>;
  GroupAdminPreferences?: Maybe<GroupAdminPreferencePayload>;
  GroupPosts?: Maybe<GroupPostPayload>;
  Groups?: Maybe<GroupPayload>;
  GroupsMembersRequests?: Maybe<GroupsMembersRequestPayload>;
  GroupsStats?: Maybe<GroupsStatPayload>;
  HighSchoolLocations?: Maybe<HighSchoolLocationPayload>;
  HomeFeedItems?: Maybe<HomeFeedItemPayload>;
  HomeFeedPosts?: Maybe<HomeFeedPostPayload>;
  Inbox?: Maybe<InboxPayload>;
  InboxMessageMedia?: Maybe<InboxMessageMediaPayload>;
  InboxMessages?: Maybe<InboxMessagePayload>;
  JobEmployees?: Maybe<JobEmployeePayload>;
  JobOffers?: Maybe<JobOfferPayload>;
  LegacyFeedMonths?: Maybe<LegacyFeedMonthPayload>;
  LegacyFeedPosts?: Maybe<LegacyFeedPostPayload>;
  LegacyFeedYears?: Maybe<LegacyFeedYearPayload>;
  NotificationTemplates?: Maybe<NotificationTemplatePayload>;
  OrganizationLikes?: Maybe<OrganizationLikePayload>;
  OrganizationLocations?: Maybe<OrganizationLocationPayload>;
  OrganizationManagerPreferences?: Maybe<OrganizationManagerPreferencePayload>;
  Organizations?: Maybe<OrganizationPayload>;
  PaymentTransactions?: Maybe<PaymentTransactionPayload>;
  Permissions?: Maybe<PermissionPayload>;
  PostMentions?: Maybe<PostMentionPayload>;
  RemoteVariables?: Maybe<RemoteVariablePayload>;
  RoleAddon?: Maybe<RoleAddonPayload>;
  Roles?: Maybe<RolePayload>;
  SchoolConfiguration?: Maybe<SchoolConfigurationPayload>;
  SchoolConfigurationColorSchemes?: Maybe<SchoolConfigurationColorSchemePayload>;
  SchoolConfigurationExternalLinks?: Maybe<SchoolConfigurationExternalLinkPayload>;
  SchoolConfigurationImages?: Maybe<SchoolConfigurationImagePayload>;
  SchoolConfigurationLayouts?: Maybe<SchoolConfigurationLayoutPayload>;
  SchoolConfigurationTopBar?: Maybe<SchoolConfigurationTopBarPayload>;
  SchoolConfigurationWordsBlacklist?: Maybe<SchoolConfigurationWordsBlacklistPayload>;
  SchoolNewsPosts?: Maybe<SchoolNewsPostPayload>;
  SchoolSupporter?: Maybe<SchoolSupporterPayload>;
  SchoolSupporterClicks?: Maybe<SchoolSupporterClickPayload>;
  SchoolSupporterSearches?: Maybe<SchoolSupporterSearchPayload>;
  Settings?: Maybe<SettingPayload>;
  StripeInformations?: Maybe<StripeInformationPayload>;
  TeamInvitations?: Maybe<TeamInvitationPayload>;
  TeamMembers?: Maybe<TeamMemberPayload>;
  TrendingSearches?: Maybe<TrendingSearchPayload>;
  UserConfiguration?: Maybe<UserConfigurationPayload>;
  UserNotifications?: Maybe<UserNotificationPayload>;
  UserNotificationsByUser?: Maybe<UserNotificationsByUserPayload>;
  UserPreferences?: Maybe<UserPreferencePayload>;
  UserSchoolMembership?: Maybe<UserSchoolMembershipPayload>;
  UserStatus?: Maybe<UserStatusPayload>;
  UserSupportersOnly?: Maybe<UserSupportersOnlyPayload>;
  Users?: Maybe<UserPayload>;
  UsersCount?: Maybe<UsersCountPayload>;
};

export type SubscriptionAdClicksArgs = {
  filter?: InputMaybe<AdClickSubscriptionFilter>;
};

export type SubscriptionAdViewsArgs = {
  filter?: InputMaybe<AdViewSubscriptionFilter>;
};

export type SubscriptionAddressesArgs = {
  filter?: InputMaybe<AddressSubscriptionFilter>;
};

export type SubscriptionAdvertisingArgs = {
  filter?: InputMaybe<AdvertisingSubscriptionFilter>;
};

export type SubscriptionAdvertisingCampaignAdsArgs = {
  filter?: InputMaybe<AdvertisingCampaignAdSubscriptionFilter>;
};

export type SubscriptionAdvertisingSampaignsArgs = {
  filter?: InputMaybe<AdvertisingSampaignSubscriptionFilter>;
};

export type SubscriptionAdvertisingSupporterArgs = {
  filter?: InputMaybe<AdvertisingSupporterSubscriptionFilter>;
};

export type SubscriptionApiTokensArgs = {
  filter?: InputMaybe<ApiTokenSubscriptionFilter>;
};

export type SubscriptionAudiencesArgs = {
  filter?: InputMaybe<AudienceSubscriptionFilter>;
};

export type SubscriptionAuthenticationProfilesArgs = {
  filter?: InputMaybe<AuthenticationProfileSubscriptionFilter>;
};

export type SubscriptionAuthenticationSettingsArgs = {
  filter?: InputMaybe<AuthenticationSettingSubscriptionFilter>;
};

export type SubscriptionBillingRegisteredAdsArgs = {
  filter?: InputMaybe<BillingRegisteredAdSubscriptionFilter>;
};

export type SubscriptionBillingRegisteredSchoolSupportersArgs = {
  filter?: InputMaybe<BillingRegisteredSchoolSupporterSubscriptionFilter>;
};

export type SubscriptionBillingRegisteredSpecialOffersArgs = {
  filter?: InputMaybe<BillingRegisteredSpecialOfferSubscriptionFilter>;
};

export type SubscriptionBillingSchoolSupportersArgs = {
  filter?: InputMaybe<BillingSchoolSupporterSubscriptionFilter>;
};

export type SubscriptionBillingSpecialOffersArgs = {
  filter?: InputMaybe<BillingSpecialOfferSubscriptionFilter>;
};

export type SubscriptionBillingsAdsArgs = {
  filter?: InputMaybe<BillingsAdSubscriptionFilter>;
};

export type SubscriptionBusinessReportArgs = {
  filter?: InputMaybe<BusinessReportSubscriptionFilter>;
};

export type SubscriptionCiCdMigrationsArgs = {
  filter?: InputMaybe<CiCdMigrationSubscriptionFilter>;
};

export type SubscriptionCollectiveIntelligenceThreadsArgs = {
  filter?: InputMaybe<CollectiveIntelligenceThreadSubscriptionFilter>;
};

export type SubscriptionContentReportArgs = {
  filter?: InputMaybe<ContentReportSubscriptionFilter>;
};

export type SubscriptionDashboardArgs = {
  filter?: InputMaybe<DashboardSubscriptionFilter>;
};

export type SubscriptionDebtOperationHistoriesArgs = {
  filter?: InputMaybe<DebtOperationHistorySubscriptionFilter>;
};

export type SubscriptionDebtsArgs = {
  filter?: InputMaybe<DebtSubscriptionFilter>;
};

export type SubscriptionDiscountRedemptionsArgs = {
  filter?: InputMaybe<DiscountRedemptionSubscriptionFilter>;
};

export type SubscriptionDiscountsArgs = {
  filter?: InputMaybe<DiscountSubscriptionFilter>;
};

export type SubscriptionEducationalInstitutionsArgs = {
  filter?: InputMaybe<EducationalInstitutionSubscriptionFilter>;
};

export type SubscriptionEnvironmentVariablesArgs = {
  filter?: InputMaybe<EnvironmentVariableSubscriptionFilter>;
};

export type SubscriptionEventSponsorRequestsArgs = {
  filter?: InputMaybe<EventSponsorRequestSubscriptionFilter>;
};

export type SubscriptionEventsArgs = {
  filter?: InputMaybe<EventSubscriptionFilter>;
};

export type SubscriptionFilesArgs = {
  filter?: InputMaybe<FileSubscriptionFilter>;
};

export type SubscriptionFilesSettingsArgs = {
  filter?: InputMaybe<FilesSettingSubscriptionFilter>;
};

export type SubscriptionGraduatingYearsArgs = {
  filter?: InputMaybe<GraduatingYearSubscriptionFilter>;
};

export type SubscriptionGraduatingYearsMetricsArgs = {
  filter?: InputMaybe<GraduatingYearsMetricSubscriptionFilter>;
};

export type SubscriptionGroupAdminPreferencesArgs = {
  filter?: InputMaybe<GroupAdminPreferenceSubscriptionFilter>;
};

export type SubscriptionGroupPostsArgs = {
  filter?: InputMaybe<GroupPostSubscriptionFilter>;
};

export type SubscriptionGroupsArgs = {
  filter?: InputMaybe<GroupSubscriptionFilter>;
};

export type SubscriptionGroupsMembersRequestsArgs = {
  filter?: InputMaybe<GroupsMembersRequestSubscriptionFilter>;
};

export type SubscriptionGroupsStatsArgs = {
  filter?: InputMaybe<GroupsStatSubscriptionFilter>;
};

export type SubscriptionHighSchoolLocationsArgs = {
  filter?: InputMaybe<HighSchoolLocationSubscriptionFilter>;
};

export type SubscriptionHomeFeedItemsArgs = {
  filter?: InputMaybe<HomeFeedItemSubscriptionFilter>;
};

export type SubscriptionHomeFeedPostsArgs = {
  filter?: InputMaybe<HomeFeedPostSubscriptionFilter>;
};

export type SubscriptionInboxArgs = {
  filter?: InputMaybe<InboxSubscriptionFilter>;
};

export type SubscriptionInboxMessageMediaArgs = {
  filter?: InputMaybe<InboxMessageMediaSubscriptionFilter>;
};

export type SubscriptionInboxMessagesArgs = {
  filter?: InputMaybe<InboxMessageSubscriptionFilter>;
};

export type SubscriptionJobEmployeesArgs = {
  filter?: InputMaybe<JobEmployeeSubscriptionFilter>;
};

export type SubscriptionJobOffersArgs = {
  filter?: InputMaybe<JobOfferSubscriptionFilter>;
};

export type SubscriptionLegacyFeedMonthsArgs = {
  filter?: InputMaybe<LegacyFeedMonthSubscriptionFilter>;
};

export type SubscriptionLegacyFeedPostsArgs = {
  filter?: InputMaybe<LegacyFeedPostSubscriptionFilter>;
};

export type SubscriptionLegacyFeedYearsArgs = {
  filter?: InputMaybe<LegacyFeedYearSubscriptionFilter>;
};

export type SubscriptionNotificationTemplatesArgs = {
  filter?: InputMaybe<NotificationTemplateSubscriptionFilter>;
};

export type SubscriptionOrganizationLikesArgs = {
  filter?: InputMaybe<OrganizationLikeSubscriptionFilter>;
};

export type SubscriptionOrganizationLocationsArgs = {
  filter?: InputMaybe<OrganizationLocationSubscriptionFilter>;
};

export type SubscriptionOrganizationManagerPreferencesArgs = {
  filter?: InputMaybe<OrganizationManagerPreferenceSubscriptionFilter>;
};

export type SubscriptionOrganizationsArgs = {
  filter?: InputMaybe<OrganizationSubscriptionFilter>;
};

export type SubscriptionPaymentTransactionsArgs = {
  filter?: InputMaybe<PaymentTransactionSubscriptionFilter>;
};

export type SubscriptionPermissionsArgs = {
  filter?: InputMaybe<PermissionSubscriptionFilter>;
};

export type SubscriptionPostMentionsArgs = {
  filter?: InputMaybe<PostMentionSubscriptionFilter>;
};

export type SubscriptionRemoteVariablesArgs = {
  filter?: InputMaybe<RemoteVariableSubscriptionFilter>;
};

export type SubscriptionRoleAddonArgs = {
  filter?: InputMaybe<RoleAddonSubscriptionFilter>;
};

export type SubscriptionRolesArgs = {
  filter?: InputMaybe<RoleSubscriptionFilter>;
};

export type SubscriptionSchoolConfigurationArgs = {
  filter?: InputMaybe<SchoolConfigurationSubscriptionFilter>;
};

export type SubscriptionSchoolConfigurationColorSchemesArgs = {
  filter?: InputMaybe<SchoolConfigurationColorSchemeSubscriptionFilter>;
};

export type SubscriptionSchoolConfigurationExternalLinksArgs = {
  filter?: InputMaybe<SchoolConfigurationExternalLinkSubscriptionFilter>;
};

export type SubscriptionSchoolConfigurationImagesArgs = {
  filter?: InputMaybe<SchoolConfigurationImageSubscriptionFilter>;
};

export type SubscriptionSchoolConfigurationLayoutsArgs = {
  filter?: InputMaybe<SchoolConfigurationLayoutSubscriptionFilter>;
};

export type SubscriptionSchoolConfigurationTopBarArgs = {
  filter?: InputMaybe<SchoolConfigurationTopBarSubscriptionFilter>;
};

export type SubscriptionSchoolConfigurationWordsBlacklistArgs = {
  filter?: InputMaybe<SchoolConfigurationWordsBlacklistSubscriptionFilter>;
};

export type SubscriptionSchoolNewsPostsArgs = {
  filter?: InputMaybe<SchoolNewsPostSubscriptionFilter>;
};

export type SubscriptionSchoolSupporterArgs = {
  filter?: InputMaybe<SchoolSupporterSubscriptionFilter>;
};

export type SubscriptionSchoolSupporterClicksArgs = {
  filter?: InputMaybe<SchoolSupporterClickSubscriptionFilter>;
};

export type SubscriptionSchoolSupporterSearchesArgs = {
  filter?: InputMaybe<SchoolSupporterSearchSubscriptionFilter>;
};

export type SubscriptionSettingsArgs = {
  filter?: InputMaybe<SettingSubscriptionFilter>;
};

export type SubscriptionStripeInformationsArgs = {
  filter?: InputMaybe<StripeInformationSubscriptionFilter>;
};

export type SubscriptionTeamInvitationsArgs = {
  filter?: InputMaybe<TeamInvitationSubscriptionFilter>;
};

export type SubscriptionTeamMembersArgs = {
  filter?: InputMaybe<TeamMemberSubscriptionFilter>;
};

export type SubscriptionTrendingSearchesArgs = {
  filter?: InputMaybe<TrendingSearchSubscriptionFilter>;
};

export type SubscriptionUserConfigurationArgs = {
  filter?: InputMaybe<UserConfigurationSubscriptionFilter>;
};

export type SubscriptionUserNotificationsArgs = {
  filter?: InputMaybe<UserNotificationSubscriptionFilter>;
};

export type SubscriptionUserNotificationsByUserArgs = {
  filter?: InputMaybe<UserNotificationsByUserSubscriptionFilter>;
};

export type SubscriptionUserPreferencesArgs = {
  filter?: InputMaybe<UserPreferenceSubscriptionFilter>;
};

export type SubscriptionUserSchoolMembershipArgs = {
  filter?: InputMaybe<UserSchoolMembershipSubscriptionFilter>;
};

export type SubscriptionUserStatusArgs = {
  filter?: InputMaybe<UserStatusSubscriptionFilter>;
};

export type SubscriptionUserSupportersOnlyArgs = {
  filter?: InputMaybe<UserSupportersOnlySubscriptionFilter>;
};

export type SubscriptionUsersArgs = {
  filter?: InputMaybe<UserSubscriptionFilter>;
};

export type SubscriptionUsersCountArgs = {
  filter?: InputMaybe<UsersCountSubscriptionFilter>;
};

export type SubstringFunctionArguments = {
  len?: InputMaybe<Scalars['Int']>;
  pos: Scalars['Int'];
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type SuccessWithMessageResponse = {
  __typename?: 'SuccessWithMessageResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Users create input from supporterClicks */
export type SupporterClicks_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from supporterClicks */
export type SupporterClicks_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Users create input from supporterSearches */
export type SupporterSearches_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from supporterSearches */
export type SupporterSearches_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Advertising create input from supporter */
export type Supporter_AdvertisingCreateInput = {
  adsCampaigns?: InputMaybe<AdvertisingAdsCampaignsRelationInput>;
  advertisingSpecialOffers?: InputMaybe<AdvertisingAdvertisingSpecialOffersRelationInput>;
  organization?: InputMaybe<AdvertisingOrganizationRelationInput>;
  supporter?: InputMaybe<AdvertisingSupporterRelationInput>;
};

/** Advertising update input from supporter */
export type Supporter_AdvertisingUpdateInput = {
  adsCampaigns?: InputMaybe<AdvertisingAdsCampaignsUpdateRelationInput>;
  advertisingSpecialOffers?: InputMaybe<AdvertisingAdvertisingSpecialOffersUpdateRelationInput>;
  organization?: InputMaybe<AdvertisingOrganizationUpdateRelationInput>;
  supporter?: InputMaybe<AdvertisingSupporterUpdateRelationInput>;
};

/** BillingSchoolSupporters create input from supporter */
export type Supporter_BillingSchoolSupporterCreateInput = {
  billingRegisteredSchoolSupporters?: InputMaybe<BillingSchoolSupportersBillingRegisteredSchoolSupportersRelationInput>;
  supporter?: InputMaybe<BillingSchoolSupportersSupporterRelationInput>;
};

/** BillingSchoolSupporters update input from supporter */
export type Supporter_BillingSchoolSupporterUpdateInput = {
  billingRegisteredSchoolSupporters?: InputMaybe<BillingSchoolSupportersBillingRegisteredSchoolSupportersUpdateRelationInput>;
  supporter?: InputMaybe<BillingSchoolSupportersSupporterUpdateRelationInput>;
};

/** SchoolSupporterClicks create input from supporter */
export type Supporter_SchoolSupporterClickCreateInput = {
  supporter?: InputMaybe<SchoolSupporterClicksSupporterRelationInput>;
  user?: InputMaybe<SchoolSupporterClicksUserRelationInput>;
};

/** SchoolSupporterClicks update input from supporter */
export type Supporter_SchoolSupporterClickUpdateInput = {
  data: SchoolSupporterClickUpdateInput;
  filter?: InputMaybe<SchoolSupporterClickKeyFilter>;
};

/** SchoolSupporter create input from supporter */
export type Supporter_SchoolSupporterCreateInput = {
  billingRegisteredSchoolSupporter?: InputMaybe<SchoolSupporterBillingRegisteredSchoolSupporterRelationInput>;
  school?: InputMaybe<SchoolSupporterSchoolRelationInput>;
  startDate: Scalars['Date'];
  status: Scalars['String'];
  supporter?: InputMaybe<SchoolSupporterSupporterRelationInput>;
  userClicks?: InputMaybe<SchoolSupporterUserClicksRelationInput>;
  userSearches?: InputMaybe<SchoolSupporterUserSearchesRelationInput>;
};

/** SchoolSupporterSearches create input from supporter */
export type Supporter_SchoolSupporterSearchCreateInput = {
  supporter?: InputMaybe<SchoolSupporterSearchesSupporterRelationInput>;
  user?: InputMaybe<SchoolSupporterSearchesUserRelationInput>;
};

/** SchoolSupporterSearches update input from supporter */
export type Supporter_SchoolSupporterSearchUpdateInput = {
  data: SchoolSupporterSearchUpdateInput;
  filter?: InputMaybe<SchoolSupporterSearchKeyFilter>;
};

/** SchoolSupporter update input from supporter */
export type Supporter_SchoolSupporterUpdateInput = {
  data: SchoolSupporterUpdateInput;
  filter?: InputMaybe<SchoolSupporterKeyFilter>;
};

/** Switch Field Attributes */
export type SwitchFieldTypeAttributes = {
  __typename?: 'SwitchFieldTypeAttributes';
  format: Scalars['String'];
  listOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Switch Type Format Enum */
export type SwitchTypeFormatEnum =
  | 'ACTIVE_INACTIVE'
  | 'CUSTOM'
  | 'HIGH_LOW'
  | 'ON_OFF'
  | 'TRUE_FALSE'
  | 'YES_NO';

export type SystemAiIdeasInput = {
  examples?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
};

/** Application */
export type SystemApplication = {
  __typename?: 'SystemApplication';
  appType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: SystemApplicationStatusEnum;
};

/** ApplicationDeleteMutationInput */
export type SystemApplicationDeleteMutationInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

/** Application install input */
export type SystemApplicationInstallInput = {
  appType: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status?: InputMaybe<SystemApplicationStatusEnum>;
};

/** SystemApplicationListResponse output */
export type SystemApplicationListResponse = {
  __typename?: 'SystemApplicationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemApplication>;
};

/** Application Status Enum */
export type SystemApplicationStatusEnum = 'ACTIVE' | 'INACTIVE';

/** Application update input */
export type SystemApplicationUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<SystemApplicationStatusEnum>;
};

export type SystemAsyncSessionStatusResponse = {
  __typename?: 'SystemAsyncSessionStatusResponse';
  result?: Maybe<Scalars['JSON']>;
  status: Scalars['String'];
};

export type SystemAuthProfile = {
  __typename?: 'SystemAuthProfile';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SystemAuthenticationInfo = {
  __typename?: 'SystemAuthenticationInfo';
  authProfile?: Maybe<Array<Maybe<SystemAuthProfile>>>;
  environmentId?: Maybe<Scalars['String']>;
  environmentName?: Maybe<Scalars['String']>;
};

export type SystemBackendUtilizationResponse = {
  __typename?: 'SystemBackendUtilizationResponse';
  authProfiles?: Maybe<Scalars['Int']>;
  databaseRows?: Maybe<Scalars['Int']>;
  field?: Maybe<Scalars['Int']>;
  fileStorageSize?: Maybe<Scalars['Int']>;
  functions?: Maybe<Scalars['Int']>;
  roles?: Maybe<Scalars['Int']>;
  tables?: Maybe<Scalars['Int']>;
};

export type SystemBasicSectionConfigurationForPromptExecutionResponse = {
  __typename?: 'SystemBasicSectionConfigurationForPromptExecutionResponse';
  content?: Maybe<Scalars['JSON']>;
};

export type SystemBasicSectionConfigurationItem = {
  __typename?: 'SystemBasicSectionConfigurationItem';
  criteria: Scalars['String'];
  description: Scalars['String'];
  enabledWith8base?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SystemBasicSectionConfigurationResponse = {
  __typename?: 'SystemBasicSectionConfigurationResponse';
  application_classification?: Maybe<Array<Maybe<SystemBasicSectionConfigurationItem>>>;
  building_blocks?: Maybe<Array<Maybe<SystemBasicSectionConfigurationItem>>>;
  commercialization_model?: Maybe<Array<Maybe<SystemBasicSectionConfigurationItem>>>;
};

export type SystemBillingCurrentPlanResponse = {
  __typename?: 'SystemBillingCurrentPlanResponse';
  customerId?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<SystemBillingPlanFeature>>>;
  id?: Maybe<Scalars['ID']>;
  limitMetrics?: Maybe<Array<Maybe<SystemBillingPlanLimitMetrics>>>;
  name?: Maybe<Scalars['String']>;
  nextPlan?: Maybe<SystemBillingNextPlanResponse>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentDetails?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  status?: Maybe<SystemWorkspaceStatus>;
  subscriptionId?: Maybe<Scalars['String']>;
  trialEnd?: Maybe<Scalars['DateTime']>;
};

export type SystemBillingDetailsOrigin = 'member' | 'organization' | 'workspace';

export type SystemBillingDetailsResponse = {
  __typename?: 'SystemBillingDetailsResponse';
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  last4?: Maybe<Scalars['String']>;
  origin: SystemBillingDetailsOrigin;
};

export type SystemBillingInvoiceItem = {
  __typename?: 'SystemBillingInvoiceItem';
  amountDue?: Maybe<Scalars['Float']>;
  amountPaid?: Maybe<Scalars['Float']>;
  amountRemaining?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  endingBalance?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoicePdf?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  organization?: Maybe<SystemBillingInvoiceItemOrganizationInfo>;
  paid?: Maybe<Scalars['Boolean']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<SystemBillingInvoiceItemPlanInfo>;
  project?: Maybe<SystemBillingInvoiceItemProjectInfo>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type SystemBillingInvoiceItemOrganizationInfo = {
  __typename?: 'SystemBillingInvoiceItemOrganizationInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SystemBillingInvoiceItemPlanInfo = {
  __typename?: 'SystemBillingInvoiceItemPlanInfo';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SystemBillingInvoiceItemProjectInfo = {
  __typename?: 'SystemBillingInvoiceItemProjectInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SystemBillingInvoicesListFilterType = 'CUSTOMER' | 'PROJECT';

/** SystemBillingInvoicesListResponse output */
export type SystemBillingInvoicesListResponse = {
  __typename?: 'SystemBillingInvoicesListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemBillingInvoiceItem>;
};

export type SystemBillingLimitMetricItem = {
  __typename?: 'SystemBillingLimitMetricItem';
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  showPriority?: Maybe<Scalars['Int']>;
  tooltip?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type SystemBillingMetricOverageItem = {
  __typename?: 'SystemBillingMetricOverageItem';
  value?: Maybe<Scalars['Float']>;
  warning?: Maybe<Scalars['String']>;
};

export type SystemBillingMetricUsageItem = {
  __typename?: 'SystemBillingMetricUsageItem';
  limitMetric?: Maybe<SystemBillingLimitMetricItem>;
  overage?: Maybe<SystemBillingMetricOverageItem>;
  value?: Maybe<Scalars['Float']>;
};

export type SystemBillingMetricUsageQuotaItem = {
  __typename?: 'SystemBillingMetricUsageQuotaItem';
  limitMetric?: Maybe<SystemBillingLimitMetricItem>;
  value?: Maybe<Scalars['Float']>;
};

/** SystemBillingMetricUsageQuotasListResponse output */
export type SystemBillingMetricUsageQuotasListResponse = {
  __typename?: 'SystemBillingMetricUsageQuotasListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemBillingMetricUsageQuotaItem>;
};

export type SystemBillingMetricUsagesListFilter = {
  entryDate: DateTimePredicate;
};

/** SystemBillingMetricUsagesListResponse output */
export type SystemBillingMetricUsagesListResponse = {
  __typename?: 'SystemBillingMetricUsagesListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemBillingMetricUsageItem>;
};

export type SystemBillingNextPlanResponse = {
  __typename?: 'SystemBillingNextPlanResponse';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export type SystemBillingPlanBaseInfo = {
  __typename?: 'SystemBillingPlanBaseInfo';
  capacity?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  featuresTitle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isLegacy?: Maybe<Scalars['Boolean']>;
  limitMetrics?: Maybe<Array<Maybe<SystemBillingPlanLimitMetricItem>>>;
  name?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type SystemBillingPlanFeature = {
  __typename?: 'SystemBillingPlanFeature';
  displayName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type SystemBillingPlanLimitMetricItem = {
  __typename?: 'SystemBillingPlanLimitMetricItem';
  displayName?: Maybe<Scalars['String']>;
  hardLimit?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  overagePrice?: Maybe<Scalars['Int']>;
  softLimit?: Maybe<Scalars['Float']>;
};

export type SystemBillingPlanLimitMetrics = {
  __typename?: 'SystemBillingPlanLimitMetrics';
  hardLimit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overagePrice?: Maybe<Scalars['String']>;
  softLimit?: Maybe<Scalars['String']>;
};

/** BillingPlanUpdateMutationInput */
export type SystemBillingPlanUpdateMutationInput = {
  planId: Scalars['ID'];
  projectID: Scalars['ID'];
};

export type SystemBranchEnvironmentMode = 'FULL' | 'SYSTEM';

export type SystemCacheEvictResponse = {
  __typename?: 'SystemCacheEvictResponse';
  evicted: Array<Maybe<Scalars['String']>>;
};

export type SystemChangePlanIdeaMutationInput = {
  archieId: Scalars['ID'];
  couponId?: InputMaybe<Scalars['String']>;
  couponName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  newPlan: Scalars['String'];
};

export type SystemChangeRoleIdeaMemberMutationInput = {
  email: Scalars['String'];
  ideaId: Scalars['ID'];
  role: InvitationRoleEnum;
};

export type SystemChangeRoleIdeaMutationInputList = {
  recipients: Array<SystemChangeRoleIdeaMemberMutationInput>;
};

/** Ci Commit Mode */
export type SystemCiCommitMode = 'FULL' | 'ONLY_MIGRATIONS' | 'ONLY_PROJECT';

/** Ci Status */
export type SystemCiStatusOutput = {
  __typename?: 'SystemCiStatusOutput';
  migrations?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
};

/** CloudLogs Entry */
export type SystemCloudLogsEntry = {
  __typename?: 'SystemCloudLogsEntry';
  message?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

/** Computed field mode */
export type SystemComputedFieldMode = 'STORED' | 'VIRTUAL';

/** Custom Table Field Type */
export type SystemCustomTableField = {
  __typename?: 'SystemCustomTableField';
  computedMode?: Maybe<SystemComputedFieldMode>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  fieldType?: Maybe<SystemFieldType>;
  fieldTypeAttributes?: Maybe<SystemFieldTypeAttributes>;
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Date Field Attributes */
export type SystemDateFieldTypeAttributes = {
  __typename?: 'SystemDateFieldTypeAttributes';
  format: Scalars['String'];
};

/** Date Type Format Enum */
export type SystemDateTypeFormatEnum = 'DATE' | 'DATETIME';

/** Delete Member From Organization Response */
export type SystemDeleteArchieMemberFromOrganizationResponse = {
  __typename?: 'SystemDeleteArchieMemberFromOrganizationResponse';
  success: Scalars['Boolean'];
};

export type SystemDeleteIdeaMutationInput = {
  id: Scalars['ID'];
};

export type SystemDeleteMemberIdeaMutationInput = {
  email: Scalars['String'];
  ideaId: Scalars['ID'];
};

export type SystemDeleteMemberIdeaMutationInputList = {
  members: Array<SystemDeleteMemberIdeaMutationInput>;
};

/** DeployDataResponse */
export type SystemDeployDataResponse = {
  __typename?: 'SystemDeployDataResponse';
  buildName: Scalars['String'];
  uploadBuildUrl: Scalars['String'];
  uploadMetaDataUrl: Scalars['String'];
};

export type SystemDeployModeEnum =
  | 'FULL'
  | 'FUNCTIONS'
  | 'MIGRATIONS'
  | 'ONLY_PLUGINS'
  | 'ONLY_PROJECT';

/** DeployOptions */
export type SystemDeployOptions = {
  extensionNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mode?: InputMaybe<SystemDeployModeEnum>;
  nodeVersion?: InputMaybe<Scalars['String']>;
  pluginNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SystemDeployStatusEnum =
  | 'compiling'
  | 'complete_error'
  | 'complete_success'
  | 'deploying'
  | 'initialize'
  | 'preparing';

/** SystemDeployStatusResult */
export type SystemDeployStatusResult = {
  __typename?: 'SystemDeployStatusResult';
  message?: Maybe<Scalars['String']>;
  status: SystemDeployStatusEnum;
};

/** DeployingBuildInput */
export type SystemDeployingBuildInput = {
  buildName: Scalars['String'];
  options?: InputMaybe<SystemDeployOptions>;
};

export type SystemDeploymentAbItemResponse = {
  __typename?: 'SystemDeploymentABItemResponse';
  dateDeploy?: Maybe<Scalars['DateTime']>;
  statusDeploy?: Maybe<Scalars['String']>;
  urlDeploy?: Maybe<Scalars['String']>;
  userDeploy?: Maybe<SystemMemberAccountInfo>;
  versionDeploy?: Maybe<Scalars['String']>;
  versionFrontEnd?: Maybe<Scalars['String']>;
};

export type SystemDeploymentProjectItemResponse = {
  __typename?: 'SystemDeploymentProjectItemResponse';
  dateDeploy?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  statusDeploy?: Maybe<Scalars['String']>;
  urlDeploy?: Maybe<Scalars['String']>;
  versionDeploy?: Maybe<Scalars['String']>;
  versionFrontEnd?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['ID']>;
};

/** SystemDeploymentProjectListResponse output */
export type SystemDeploymentProjectListResponse = {
  __typename?: 'SystemDeploymentProjectListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemDeploymentProjectItemResponse>;
};

/** SystemEnvironmentBackupListResponse output */
export type SystemEnvironmentBackupListResponse = {
  __typename?: 'SystemEnvironmentBackupListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<EnvironmentBackupItem>;
};

export type SystemEnvironmentMember = {
  __typename?: 'SystemEnvironmentMember';
  avatar?: Maybe<SystemEnvironmentMemberAvatar>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  permissions?: Maybe<SystemEnvironmentMemberPermissionsList>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<SystemEnvironmentMemberRolesList>;
  status: Scalars['String'];
};

export type SystemEnvironmentMemberAvatar = {
  __typename?: 'SystemEnvironmentMemberAvatar';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type SystemEnvironmentMemberFilter = {
  email?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type SystemEnvironmentMemberPermission = {
  __typename?: 'SystemEnvironmentMemberPermission';
  permission?: Maybe<Scalars['JSON']>;
  resource?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
};

export type SystemEnvironmentMemberPermissionsList = {
  __typename?: 'SystemEnvironmentMemberPermissionsList';
  count: Scalars['Int'];
  items?: Maybe<Array<SystemEnvironmentMemberPermission>>;
};

export type SystemEnvironmentMemberRole = {
  __typename?: 'SystemEnvironmentMemberRole';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SystemEnvironmentMemberRoleIdFilterPredicate = {
  equals?: InputMaybe<Scalars['ID']>;
  not_equals?: InputMaybe<Scalars['ID']>;
};

export type SystemEnvironmentMemberRolesList = {
  __typename?: 'SystemEnvironmentMemberRolesList';
  count: Scalars['Int'];
  items?: Maybe<Array<SystemEnvironmentMemberRole>>;
};

export type SystemEnvironmentMemberStatusFilterPredicate = {
  equals?: InputMaybe<Scalars['String']>;
  not_equals?: InputMaybe<Scalars['String']>;
};

export type SystemEnvironmentMemberUpdateData = {
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SystemEnvironmentMembersFilter = {
  email: Scalars['String'];
};

export type SystemEnvironmentMembersListFilter = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<SystemEnvironmentMemberRoleIdFilterPredicate>;
  status?: InputMaybe<SystemEnvironmentMemberStatusFilterPredicate>;
  workspaceId?: InputMaybe<Scalars['ID']>;
};

/** SystemEnvironmentMembersListResponse output */
export type SystemEnvironmentMembersListResponse = {
  __typename?: 'SystemEnvironmentMembersListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemEnvironmentMember>;
};

export type SystemEnvironmentMembersListSort = {
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  isOwner?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type SystemEnvironmentProjectItem = {
  __typename?: 'SystemEnvironmentProjectItem';
  fields?: Maybe<Scalars['String']>;
  functions?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  tables?: Maybe<Scalars['String']>;
  worksApiHost?: Maybe<Scalars['String']>;
  workspaceId: Scalars['ID'];
  workspaceName: Scalars['String'];
};

export type SystemEnvironmentRelationModeEnum = 'ForeignKey' | 'Pivot';

export type SystemEnvironmentRoleBaseInfo = {
  __typename?: 'SystemEnvironmentRoleBaseInfo';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SystemEnvironmentRoleList = {
  __typename?: 'SystemEnvironmentRoleList';
  assignedRoles?: Maybe<Array<Maybe<SystemEnvironmentRoleBaseInfo>>>;
  environmentId: Scalars['String'];
  environmentName: Scalars['String'];
  exists?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Array<Maybe<SystemEnvironmentRoleBaseInfo>>>;
};

export type SystemEnvironmentSettings = {
  __typename?: 'SystemEnvironmentSettings';
  deleteLock?: Maybe<Scalars['Boolean']>;
  fileManagementProvider?: Maybe<SystemFileManagerProviderTypeEnum>;
  introspection?: Maybe<Scalars['Boolean']>;
  relationMode?: Maybe<SystemEnvironmentRelationModeEnum>;
};

/** SystemEnvironmentsListResponse output */
export type SystemEnvironmentsListResponse = {
  __typename?: 'SystemEnvironmentsListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<EnvironmentItem>;
};

/** SystemEnvironmentsProjectListResponse output */
export type SystemEnvironmentsProjectListResponse = {
  __typename?: 'SystemEnvironmentsProjectListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemEnvironmentProjectItem>;
};

export type SystemExportIdeaToDocumentInput = {
  advancedConsiderations?: InputMaybe<Scalars['Boolean']>;
  applicationServices?: InputMaybe<Scalars['Boolean']>;
  applicationServicesDetails?: InputMaybe<Scalars['Boolean']>;
  designGeneral?: InputMaybe<Scalars['Boolean']>;
  ideaId: Scalars['ID'];
  includeAll?: InputMaybe<Scalars['Boolean']>;
  modules?: InputMaybe<Scalars['Boolean']>;
  modulesDetails?: InputMaybe<Scalars['Boolean']>;
  output?: InputMaybe<Scalars['String']>;
  overview?: InputMaybe<Scalars['Boolean']>;
  screens?: InputMaybe<Scalars['Boolean']>;
  siteMap?: InputMaybe<Scalars['Boolean']>;
  userTypes?: InputMaybe<Scalars['Boolean']>;
};

export type SystemExportIdeaToDocumentResponse = {
  __typename?: 'SystemExportIdeaToDocumentResponse';
  content?: Maybe<Scalars['JSON']>;
  documentType: Scalars['String'];
};

/** Field Data Features */
export type SystemFieldDataFeatures = {
  __typename?: 'SystemFieldDataFeatures';
  create: Scalars['Boolean'];
  sort: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** Field Schema Features */
export type SystemFieldSchemaFeatures = {
  __typename?: 'SystemFieldSchemaFeatures';
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** Field types */
export type SystemFieldType =
  | 'DATE'
  | 'FILE'
  | 'GEO'
  | 'ID'
  | 'JSON'
  | 'MISSING_RELATION'
  | 'NUMBER'
  | 'ONE_WAY_RELATION'
  | 'RELATION'
  | 'SMART'
  | 'SWITCH'
  | 'TEXT'
  | 'UUID';

/** Field Type Attributes */
export type SystemFieldTypeAttributes =
  | SystemDateFieldTypeAttributes
  | SystemFileFieldTypeAttributes
  | SystemGeoFieldTypeAttributes
  | SystemMissingRelationFieldTypeAttributes
  | SystemNumberFieldTypeAttributes
  | SystemSmartFieldTypeAttributes
  | SystemSwitchFieldTypeAttributes
  | SystemTextFieldTypeAttributes
  | SystemUuidFieldTypeAttributes;

/** Field Type Attributes Input */
export type SystemFieldTypeAttributesInput = {
  autoIncrement?: InputMaybe<Scalars['Boolean']>;
  currency?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['Int']>;
  fieldSize?: InputMaybe<Scalars['Int']>;
  format?: InputMaybe<Scalars['String']>;
  isBigInt?: InputMaybe<Scalars['Boolean']>;
  listOptions?: InputMaybe<Array<Scalars['String']>>;
  maxSize?: InputMaybe<Scalars['Int']>;
  maxValue?: InputMaybe<Scalars['Float']>;
  minValue?: InputMaybe<Scalars['Float']>;
  precision?: InputMaybe<Scalars['Int']>;
  srid?: InputMaybe<Scalars['Int']>;
  typeRestrictions?: InputMaybe<Array<Scalars['String']>>;
};

export type SystemFileBaseInfo = {
  __typename?: 'SystemFileBaseInfo';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** File Field Attributes */
export type SystemFileFieldTypeAttributes = {
  __typename?: 'SystemFileFieldTypeAttributes';
  expiration?: Maybe<Scalars['Int']>;
  format: Scalars['String'];
  maxSize?: Maybe<Scalars['Int']>;
  /** @deprecated Field is deprecated */
  showTitle?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field is deprecated */
  showUrl?: Maybe<Scalars['Boolean']>;
  typeRestrictions?: Maybe<Array<Scalars['String']>>;
};

export type SystemFileManagerProviderTypeEnum = 'aws' | 'filestack' | 'uploadcare';

/** File Type Format Enum */
export type SystemFileTypeFormatEnum = 'FILE' | 'IMAGE';

/** FileUploadByUrlInput */
export type SystemFileUploadByUrlInput = {
  url: Scalars['String'];
};

/** FileUploadByUrlResponse */
export type SystemFileUploadByUrlResponse = {
  __typename?: 'SystemFileUploadByUrlResponse';
  fileId: Scalars['String'];
  meta: Scalars['JSON'];
};

export type SystemFileUploadSignInfo =
  | AwsSignInfoResponse
  | FileStackSignInfoResponse
  | UploadcareSignInfoResponse;

export type SystemFrontendUtilizationAbResponse = {
  __typename?: 'SystemFrontendUtilizationABResponse';
  CDN?: Maybe<Scalars['Boolean']>;
  assets?: Maybe<Scalars['Int']>;
  customStates?: Maybe<Scalars['Int']>;
  functions?: Maybe<Scalars['Int']>;
  layouts?: Maybe<Scalars['Int']>;
  libraries?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  requests?: Maybe<Scalars['Int']>;
  resources?: Maybe<Scalars['Int']>;
};

export type SystemFrontendUtilizationResponse = {
  __typename?: 'SystemFrontendUtilizationResponse';
  assets?: Maybe<Scalars['Int']>;
  customStates?: Maybe<Scalars['Int']>;
  functions?: Maybe<Scalars['Int']>;
  layouts?: Maybe<Scalars['Int']>;
  libraries?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  requests?: Maybe<Scalars['Int']>;
  resources?: Maybe<Scalars['Int']>;
};

/** FunctionInfo */
export type SystemFunctionInfo = {
  application?: Maybe<SystemApplication>;
  description?: Maybe<Scalars['String']>;
  functionType: SystemFunctionType;
  name: Scalars['String'];
};

/** FunctionInfoCheck */
export type SystemFunctionInfoCheck = {
  __typename?: 'SystemFunctionInfoCheck';
  version?: Maybe<Scalars['String']>;
};

/** FunctionInfoFilter */
export type SystemFunctionInfoFilter = {
  description?: InputMaybe<Scalars['String']>;
  functionType?: InputMaybe<SystemFunctionType>;
  name?: InputMaybe<Scalars['String']>;
};

/** FunctionInfoOrderBy */
export type SystemFunctionInfoOrderBy =
  | 'description_ASC'
  | 'description_DESC'
  | 'functionType_ASC'
  | 'functionType_DESC'
  | 'name_ASC'
  | 'name_DESC';

/** SystemFunctionListResponse output */
export type SystemFunctionListResponse = {
  __typename?: 'SystemFunctionListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemFunctionInfo>;
};

/** FunctionResolverInfo */
export type SystemFunctionResolverInfo = SystemFunctionInfo & {
  __typename?: 'SystemFunctionResolverInfo';
  application?: Maybe<SystemApplication>;
  description?: Maybe<Scalars['String']>;
  functionType: SystemFunctionType;
  gqlType: Scalars['String'];
  name: Scalars['String'];
};

/** FunctionTaskInfo */
export type SystemFunctionTaskInfo = SystemFunctionInfo & {
  __typename?: 'SystemFunctionTaskInfo';
  application?: Maybe<SystemApplication>;
  description?: Maybe<Scalars['String']>;
  functionType: SystemFunctionType;
  name: Scalars['String'];
  scheduleExpression?: Maybe<Scalars['String']>;
};

/** FunctionTriggerInfo */
export type SystemFunctionTriggerInfo = SystemFunctionInfo & {
  __typename?: 'SystemFunctionTriggerInfo';
  application?: Maybe<SystemApplication>;
  description?: Maybe<Scalars['String']>;
  functionType: SystemFunctionType;
  name: Scalars['String'];
  operation: Scalars['String'];
  tableName: Scalars['String'];
  type: Scalars['String'];
};

/** FunctionType */
export type SystemFunctionType = 'resolver' | 'schedule' | 'task' | 'trigger' | 'webhook';

/** FunctionWebhookInfo */
export type SystemFunctionWebhookInfo = SystemFunctionInfo & {
  __typename?: 'SystemFunctionWebhookInfo';
  application?: Maybe<SystemApplication>;
  description?: Maybe<Scalars['String']>;
  functionType: SystemFunctionType;
  httpMethod: Scalars['String'];
  name: Scalars['String'];
  workspaceFullPath: Scalars['String'];
  workspaceRelativePath: Scalars['String'];
};

/** Diff Environment Input */
export type SystemGenerateEnvironmentOutput = {
  __typename?: 'SystemGenerateEnvironmentOutput';
  url?: Maybe<Scalars['String']>;
};

/** Geo Field Attributes */
export type SystemGeoFieldTypeAttributes = {
  __typename?: 'SystemGeoFieldTypeAttributes';
  format: Scalars['String'];
  srid?: Maybe<Scalars['Int']>;
};

export type SystemGetCouponResponse = {
  __typename?: 'SystemGetCouponResponse';
  amount_off?: Maybe<Scalars['Float']>;
  duration: Scalars['String'];
  duration_in_months: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  percent_off?: Maybe<Scalars['Float']>;
  valid: Scalars['Boolean'];
};

export type SystemGetInvitationIdeaResponse = {
  __typename?: 'SystemGetInvitationIdeaResponse';
  archieId: Scalars['ID'];
  ideaId: Scalars['ID'];
  invitationId: Scalars['ID'];
  nickName: Scalars['String'];
  role: InvitationRoleEnum;
  status: Scalars['String'];
};

export type SystemGraphQlArchitecturesResponse = {
  __typename?: 'SystemGraphQLArchitecturesResponse';
  enabling_technologies?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  infrastructure_diagram?: Maybe<Scalars['JSON']>;
  integrations?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlBlueprintResponse = {
  __typename?: 'SystemGraphQLBlueprintResponse';
  ai_enablement_ideas?: Maybe<Scalars['JSON']>;
  application_classification?: Maybe<Scalars['JSON']>;
  building_blocks?: Maybe<Scalars['JSON']>;
  commercialization_model?: Maybe<Scalars['JSON']>;
  design_preview?: Maybe<Scalars['JSON']>;
  design_principles?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  insights?: Maybe<Scalars['JSON']>;
  modules?: Maybe<Scalars['JSON']>;
  originalPrompt?: Maybe<Scalars['JSON']>;
  problem_statement?: Maybe<Scalars['JSON']>;
  solution_statement?: Maybe<Scalars['JSON']>;
  user_types?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlBuildingBlocksRequirementsResponse = {
  __typename?: 'SystemGraphQLBuildingBlocksRequirementsResponse';
  business_rules?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stories?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlBuildingBlocksResponse = {
  __typename?: 'SystemGraphQLBuildingBlocksResponse';
  buildingBlocksRequirements?: Maybe<Array<Maybe<SystemGraphQlBuildingBlocksRequirementsResponse>>>;
  checked?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type SystemGraphQlBuildingBlocksScreensResponse = {
  __typename?: 'SystemGraphQLBuildingBlocksScreensResponse';
  designSpecifications?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  purpose?: Maybe<Scalars['String']>;
  screensWireframes?: Maybe<Array<Maybe<SystemGraphQlScreensWireFramesResponse>>>;
  status?: Maybe<Scalars['String']>;
  urlPath?: Maybe<Scalars['String']>;
};

export type SystemGraphQlFeaturesBreakdownResponse = {
  __typename?: 'SystemGraphQLFeaturesBreakdownResponse';
  functionality?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  technicalRequirements?: Maybe<Scalars['JSON']>;
  userInterfaces?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlModulesRequirementsResponse = {
  __typename?: 'SystemGraphQLModulesRequirementsResponse';
  business_rules?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stories?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlModulesResponse = {
  __typename?: 'SystemGraphQLModulesResponse';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modulesRequirements?: Maybe<Array<Maybe<SystemGraphQlModulesRequirementsResponse>>>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  uiPattern?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlOverviewResponse = {
  __typename?: 'SystemGraphQLOverviewResponse';
  accessibility_requirements?: Maybe<Scalars['JSON']>;
  application_classification?: Maybe<Scalars['JSON']>;
  commercialization_model?: Maybe<Scalars['JSON']>;
  compliance_requirements?: Maybe<Scalars['JSON']>;
  design_principles?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  performance_requirements?: Maybe<Scalars['JSON']>;
  problem_statement?: Maybe<Scalars['JSON']>;
  security_requirements?: Maybe<Scalars['JSON']>;
  solution_statement?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
};

export type SystemGraphQlScreensWireFramesResponse = {
  __typename?: 'SystemGraphQLScreensWireFramesResponse';
  html?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type SystemGraphQlSectionResultRegenerateMutationInput = {
  archieId: Scalars['ID'];
  idea: Scalars['ID'];
  instructions?: InputMaybe<Scalars['String']>;
  package: Scalars['String'];
  provisionalData?: InputMaybe<Scalars['Boolean']>;
  requirementsDescription?: InputMaybe<Scalars['String']>;
  sectionCategoriesWithIds?: InputMaybe<Scalars['JSON']>;
};

export type SystemGraphQlUserAccountsResponse = {
  __typename?: 'SystemGraphQLUserAccountsResponse';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
};

export type SystemGraphQlUserInterfacesResponse = {
  __typename?: 'SystemGraphQLUserInterfacesResponse';
  requirements_design_guidelines?: Maybe<Scalars['JSON']>;
  requirements_site_map?: Maybe<Scalars['JSON']>;
  requirements_ui_ux_general?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  wireframe_layout?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlUserTypeDetailsResponse = {
  __typename?: 'SystemGraphQLUserTypeDetailsResponse';
  comprehensive_needs?: Maybe<Scalars['JSON']>;
  comprehensive_painpoints?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
};

export type SystemGraphQlUserTypeUseCaseResponse = {
  __typename?: 'SystemGraphQLUserTypeUseCaseResponse';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type SystemGraphQlUserTypesResponse = {
  __typename?: 'SystemGraphQLUserTypesResponse';
  description?: Maybe<Scalars['String']>;
  deviceusages?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  softwareproficiency?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userTypesDetails?: Maybe<Array<Maybe<SystemGraphQlUserTypeDetailsResponse>>>;
  userTypesUseCases?: Maybe<Array<Maybe<SystemGraphQlUserTypeUseCaseResponse>>>;
};

export type SystemIdeaDetailsResponse = {
  __typename?: 'SystemIdeaDetailsResponse';
  archieId: Scalars['ID'];
  architectures?: Maybe<Array<Maybe<SystemGraphQlArchitecturesResponse>>>;
  blueprints?: Maybe<Array<Maybe<SystemGraphQlBlueprintResponse>>>;
  buildingBlocks?: Maybe<Array<Maybe<SystemGraphQlBuildingBlocksResponse>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  detailedProgress?: Maybe<Scalars['JSON']>;
  elevatorDescription?: Maybe<Scalars['String']>;
  executedDesign?: Maybe<Scalars['Boolean']>;
  featuresBreakdown?: Maybe<Array<Maybe<SystemGraphQlFeaturesBreakdownResponse>>>;
  id: Scalars['ID'];
  invitations?: Maybe<Array<Maybe<SystemIdeaInvitationBaseResponse>>>;
  isDemoProject?: Maybe<Scalars['Boolean']>;
  members?: Maybe<Array<Maybe<SystemIdeaMemberBaseResponse>>>;
  modules?: Maybe<Array<Maybe<SystemGraphQlModulesResponse>>>;
  nextStep: Scalars['String'];
  nickName: Scalars['String'];
  overviews?: Maybe<Array<Maybe<SystemGraphQlOverviewResponse>>>;
  owner?: Maybe<SystemMemberAccountInfo>;
  ownerType?: Maybe<Scalars['String']>;
  plan?: Maybe<SystemPlanInfoIdea>;
  progress: Scalars['Float'];
  requirementsDescription?: Maybe<Scalars['String']>;
  screens?: Maybe<Array<Maybe<SystemGraphQlBuildingBlocksScreensResponse>>>;
  technicalDesign?: Maybe<SystemTechnicalDesignResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userInterfaces?: Maybe<Array<Maybe<SystemGraphQlUserInterfacesResponse>>>;
  userTypes?: Maybe<Array<Maybe<SystemGraphQlUserTypesResponse>>>;
};

export type SystemIdeaGeneralCreateMutationInput = {
  description: Scalars['String'];
  nickName?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
};

export type SystemIdeaGeneralResponse = {
  __typename?: 'SystemIdeaGeneralResponse';
  archieId: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  nickName: Scalars['String'];
};

export type SystemIdeaGeneralUpdateMutationInput = {
  description?: InputMaybe<Scalars['String']>;
  elevatorDescription?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  nickName?: InputMaybe<Scalars['String']>;
  requirementsDescription?: InputMaybe<Scalars['String']>;
};

export type SystemIdeaGraphQlSchema = {
  __typename?: 'SystemIdeaGraphQLSchema';
  schema: Scalars['String'];
};

export type SystemIdeaImproveMutationInput = {
  description?: InputMaybe<Scalars['String']>;
};

export type SystemIdeaImproveResponse = {
  __typename?: 'SystemIdeaImproveResponse';
  suggestion: Scalars['String'];
};

export type SystemIdeaImproveWithAiMutationInput = {
  aiIdeas?: InputMaybe<Array<InputMaybe<SystemAiIdeasInput>>>;
  archieId: Scalars['ID'];
};

export type SystemIdeaImproveWithAiResponse = {
  __typename?: 'SystemIdeaImproveWithAIResponse';
  expectedBenefits?: Maybe<Array<Maybe<Scalars['String']>>>;
  kpis?: Maybe<Array<Maybe<Scalars['String']>>>;
  statement: Scalars['String'];
};

export type SystemIdeaInvitationBaseResponse = {
  __typename?: 'SystemIdeaInvitationBaseResponse';
  email: Scalars['String'];
  id: Scalars['ID'];
  role: InvitationRoleEnum;
};

/** Idea Invitation Member */
export type SystemIdeaInvitationMember = {
  __typename?: 'SystemIdeaInvitationMember';
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userAccounts?: Maybe<SystemGraphQlUserAccountsResponse>;
};

export type SystemIdeaItemOrganizationMember = {
  __typename?: 'SystemIdeaItemOrganizationMember';
  archieId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  detailedProgress?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  nextStep?: Maybe<Scalars['String']>;
  nickName: Scalars['String'];
  progress: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SystemIdeaItemRespSingle = {
  __typename?: 'SystemIdeaItemRespSingle';
  archieId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  detailedProgress?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  lastActivityAt?: Maybe<Scalars['DateTime']>;
  members?: Maybe<Array<Maybe<SystemMemberAccountInfo>>>;
  nextStep: Scalars['String'];
  nickName: Scalars['String'];
  owner?: Maybe<SystemMemberAccountInfo>;
  progress: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** SystemIdeaItemResponse output */
export type SystemIdeaItemResponse = {
  __typename?: 'SystemIdeaItemResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemIdeaItemRespSingle>;
};

export type SystemIdeaMemberBaseResponse = {
  __typename?: 'SystemIdeaMemberBaseResponse';
  avatar?: Maybe<GraphQlFileItemResponse>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  onboardingStatus: Scalars['JSON'];
  organizationId: Scalars['ID'];
  role: InvitationRoleEnum;
};

export type SystemIdeaUserResponse = {
  __typename?: 'SystemIdeaUserResponse';
  ideas: SystemIdeaItemResponse;
};

export type SystemInboxEventDetailsUnion =
  | SystemInboxEventEnvironmentInvitationDetails
  | SystemInboxEventNotificationDetailsType
  | SystemInboxEventOrganizationInvitationDetails;

export type SystemInboxEventEnvironmentInvitationDetails = {
  __typename?: 'SystemInboxEventEnvironmentInvitationDetails';
  environmentName?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<SystemInboxEventInvitedBy>;
  project?: Maybe<SystemInboxEventProject>;
  status?: Maybe<SystemInboxEventStatusEnum>;
  uuid?: Maybe<Scalars['String']>;
  workspace?: Maybe<SystemInboxEventWorkspace>;
};

export type SystemInboxEventInvitedBy = {
  __typename?: 'SystemInboxEventInvitedBy';
  avatar?: Maybe<GraphQlFileItemResponse>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type SystemInboxEventItem = {
  __typename?: 'SystemInboxEventItem';
  createdAt: Scalars['DateTime'];
  details?: Maybe<SystemInboxEventDetailsUnion>;
  id: Scalars['ID'];
  isCompleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SystemInboxEventTypeEnum>;
};

export type SystemInboxEventNotificationDetailsType = {
  __typename?: 'SystemInboxEventNotificationDetailsType';
  details?: Maybe<Scalars['JSON']>;
  kind?: Maybe<Scalars['String']>;
};

export type SystemInboxEventOrganization = {
  __typename?: 'SystemInboxEventOrganization';
  avatar?: Maybe<GraphQlFileItemResponse>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type SystemInboxEventOrganizationInvitationDetails = {
  __typename?: 'SystemInboxEventOrganizationInvitationDetails';
  invitedBy?: Maybe<SystemInboxEventInvitedBy>;
  organization?: Maybe<SystemInboxEventOrganization>;
  status?: Maybe<SystemInboxEventStatusEnum>;
  uuid?: Maybe<Scalars['String']>;
};

export type SystemInboxEventProject = {
  __typename?: 'SystemInboxEventProject';
  apiHost?: Maybe<Scalars['String']>;
  avatar?: Maybe<GraphQlFileItemResponse>;
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SystemInboxEventStatusEnum = 'accepted' | 'declined' | 'sent';

export type SystemInboxEventTypeEnum =
  | 'EnvironmentInvitation'
  | 'Notification'
  | 'OrganizationInvitation';

export type SystemInboxEventWorkspace = {
  __typename?: 'SystemInboxEventWorkspace';
  apiHost?: Maybe<Scalars['String']>;
  avatar?: Maybe<GraphQlFileItemResponse>;
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** SystemInboxEventsListResponse output */
export type SystemInboxEventsListResponse = {
  __typename?: 'SystemInboxEventsListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemInboxEventItem>;
};

/** Table Create Index Input */
export type SystemIndexCreateInput = {
  columns: Array<SystemTableIndexColumnInput>;
  force?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  tableId: Scalars['ID'];
  type: TableIndexType;
};

/** Table Delete Index Input */
export type SystemIndexDeleteInput = {
  id: Scalars['ID'];
};

/** Table Update Index Input */
export type SystemIndexUpdateInput = {
  columns?: InputMaybe<Array<SystemTableIndexColumnInput>>;
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TableIndexType>;
};

/** Idea Invitation Accept/Cancel Response */
export type SystemInvitationAcceptCancelResponse = {
  __typename?: 'SystemInvitationAcceptCancelResponse';
  success: Scalars['Boolean'];
};

/** Idea invitation accept/Cancel input */
export type SystemInvitationIdeaAcceptCancelInput = {
  accepted: Scalars['Boolean'];
  id: Scalars['String'];
};

/** Idea Invitation Cancel Response */
export type SystemInvitationIdeaResendResponse = {
  __typename?: 'SystemInvitationIdeaResendResponse';
  success: Scalars['Boolean'];
};

/** Idea invitation cancel input */
export type SystemInvitationIdeaResendlInput = {
  id: Scalars['String'];
};

/** Invite recipient input */
export type SystemInviteIdeaRecipientInput = {
  email: Scalars['String'];
  ideaId: Scalars['String'];
  organizationUserId?: InputMaybe<Scalars['String']>;
  role: RoleEnum;
};

/** Invite idea input */
export type SystemInviteMembersIdeaInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  recipients: Array<SystemInviteIdeaRecipientInput>;
};

/** Invite members input */
export type SystemInviteMembersInput = {
  recipients: Array<SystemInviteRecipientInput>;
};

/** Invite recipient input */
export type SystemInviteRecipientInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['ID']>>;
};

/** Invited By Name */
export type SystemInvitedByName = {
  __typename?: 'SystemInvitedByName';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  workspaceName?: Maybe<Scalars['String']>;
};

/** InvokeData */
export type SystemInvokeData = {
  functionName: Scalars['String'];
  inputArgs?: InputMaybe<Scalars['String']>;
  nodeVersion?: InputMaybe<Scalars['String']>;
};

/** InvokeFunctionResponse */
export type SystemInvokeFunctionResponse = {
  __typename?: 'SystemInvokeFunctionResponse';
  responseData: Scalars['String'];
};

/** LogsListFiltered Response */
export type SystemLogsListFilteredResponse = {
  __typename?: 'SystemLogsListFilteredResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<SystemCloudLogsEntry>>>;
  nextToken?: Maybe<Scalars['String']>;
};

/**
 * Member - list of workspaces you are member of
 * Owner - list of workspaces you are owner of
 * OrganizationUser - list of organizations you are part of
 */
export type SystemMemberAccountDeleteDetails = {
  __typename?: 'SystemMemberAccountDeleteDetails';
  member?: Maybe<Array<Maybe<SystemOrganizationWorkspaceItem>>>;
  organizationUser?: Maybe<Array<Maybe<SystemOrganizationBaseItem>>>;
  owner?: Maybe<Array<Maybe<SystemOrganizationWorkspaceItem>>>;
};

export type SystemMemberAccountDeleteResponse = {
  __typename?: 'SystemMemberAccountDeleteResponse';
  details?: Maybe<SystemMemberAccountDeleteDetails>;
  success: Scalars['Boolean'];
};

export type SystemMemberAccountInfo = {
  __typename?: 'SystemMemberAccountInfo';
  aboutMe?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  archieRole?: Maybe<Scalars['String']>;
  authCreateMethod?: Maybe<Scalars['String']>;
  avatar?: Maybe<GraphQlFileItemResponse>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  githubUsername?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  intendedUse?: Maybe<Scalars['String']>;
  isADemoUser?: Maybe<Scalars['Boolean']>;
  isArchieAdmin?: Maybe<Scalars['Boolean']>;
  isDeveloper?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isOrganizationOwner?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  learningMode?: Maybe<Scalars['Boolean']>;
  linkedInUsername?: Maybe<Scalars['String']>;
  onboardingStatus?: Maybe<Scalars['JSON']>;
  projectDescription?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  sourceAccount?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  type?: Maybe<SystemUserType>;
  website?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type SystemMemberAccountUpsertDataInput = {
  aboutMe?: InputMaybe<Scalars['String']>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  archieRole?: InputMaybe<Scalars['String']>;
  authCreateMethod?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<GraphQlCreateFileItemInput>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  githubUsername?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  intendedUse?: InputMaybe<Scalars['String']>;
  isADemoUser?: InputMaybe<Scalars['Boolean']>;
  isArchieAdmin?: InputMaybe<Scalars['Boolean']>;
  isDeveloper?: InputMaybe<Scalars['Boolean']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  isOrganizationOwner?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  learningMode?: InputMaybe<Scalars['Boolean']>;
  linkedInUsername?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<Scalars['JSON']>;
  organizationCreatorRole?: InputMaybe<Scalars['String']>;
  organizationKind?: InputMaybe<Scalars['String']>;
  organizationNeed?: InputMaybe<Scalars['String']>;
  organizationSize?: InputMaybe<Scalars['String']>;
  projectDescription?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  sourceAccount?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  twitterUsername?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SystemUserType>;
  website?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type SystemMemberArchieInviteOrganizationInput = {
  email: Scalars['String'];
  role: Scalars['String'];
};

/** Member Change Organization Role Input */
export type SystemMemberChangeOrganizationRoleInput = {
  id: Scalars['String'];
  role: Scalars['String'];
};

/** Member Change Organization Role Response */
export type SystemMemberChangeOrganizationRoleResponse = {
  __typename?: 'SystemMemberChangeOrganizationRoleResponse';
  success: Scalars['Boolean'];
};

/** Member Invitation */
export type SystemMemberInvitation = {
  __typename?: 'SystemMemberInvitation';
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  apiHost?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<SystemInvitedByName>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  resentOn?: Maybe<Scalars['DateTime']>;
};

/** Member invitation accept input */
export type SystemMemberInvitationAcceptInput = {
  accepted: Scalars['Boolean'];
  id: Scalars['String'];
};

/** Member Invitation Accept Response */
export type SystemMemberInvitationAcceptResponse = {
  __typename?: 'SystemMemberInvitationAcceptResponse';
  success: Scalars['Boolean'];
};

/** Cancel members invitations input */
export type SystemMemberInvitationCancelInput = {
  email: Scalars['String'];
};

/** Resend member invitation input */
export type SystemMemberInvitationResendInput = {
  email: Scalars['ID'];
};

export type SystemMemberInvitationsList = {
  __typename?: 'SystemMemberInvitationsList';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<SystemMemberInvitation>>>;
};

export type SystemMemberOrganizationAccountInfo = {
  __typename?: 'SystemMemberOrganizationAccountInfo';
  aboutMe?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  archieRole?: Maybe<Scalars['String']>;
  authCreateMethod?: Maybe<Scalars['String']>;
  avatar?: Maybe<GraphQlFileItemResponse>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  githubUsername?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ideas?: Maybe<Array<Maybe<SystemIdeaItemOrganizationMember>>>;
  intendedUse?: Maybe<Scalars['String']>;
  isADemoUser?: Maybe<Scalars['Boolean']>;
  isArchieAdmin?: Maybe<Scalars['Boolean']>;
  isDeveloper?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isOrganizationOwner?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  learningMode?: Maybe<Scalars['Boolean']>;
  linkedInUsername?: Maybe<Scalars['String']>;
  onboardingStatus?: Maybe<Scalars['JSON']>;
  organization?: Maybe<SystemOrganizationBaseItem>;
  projectDescription?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  sourceAccount?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  type?: Maybe<SystemUserType>;
  website?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Member Change Organization Role Input */
export type SystemMemberOrganizationInviteMutationInput = {
  ideaId?: InputMaybe<Scalars['String']>;
  ideaRole?: InputMaybe<Scalars['String']>;
  invitedByName: Scalars['String'];
  members?: InputMaybe<Array<InputMaybe<SystemMemberArchieInviteOrganizationInput>>>;
  organizationId: Scalars['ID'];
  organizationName: Scalars['String'];
};

/** Member Change Organization Role Input */
export type SystemMemberOrganizationResendInviteMutationInput = {
  email: Scalars['String'];
  invitedByName: Scalars['String'];
  organizationId: Scalars['ID'];
  organizationName: Scalars['String'];
};

/** MemberPaymentDetailsUpdateMutationInput */
export type SystemMemberPaymentDetailsUpdateMutationInput = {
  cardToken?: InputMaybe<Scalars['String']>;
};

/** Member Resend Verify Account Email Response */
export type SystemMemberResendVerifyAccountEmailResponse = {
  __typename?: 'SystemMemberResendVerifyAccountEmailResponse';
  success: Scalars['Boolean'];
};

/** Member Resend Verify Account Email input */
export type SystemMemberResendVerifyAccountEmaillInput = {
  email: Scalars['String'];
};

export type SystemMembersAccountList = {
  __typename?: 'SystemMembersAccountList';
  items?: Maybe<Array<Maybe<SystemMemberOrganizationAccountInfo>>>;
};

export type SystemMembersIdeaList = {
  __typename?: 'SystemMembersIdeaList';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<SystemIdeaInvitationMember>>>;
};

/** MissingRelation Field Attributes */
export type SystemMissingRelationFieldTypeAttributes = {
  __typename?: 'SystemMissingRelationFieldTypeAttributes';
  missingTable: Scalars['String'];
};

export type SystemMutation = {
  __typename?: 'SystemMutation';
  ExportIdeaToDocument?: Maybe<SystemExportIdeaToDocumentResponse>;
  applicationDelete?: Maybe<SuccessResponse>;
  applicationInstall?: Maybe<SystemApplication>;
  applicationUpdate?: Maybe<SystemApplication>;
  billingContextCacheEvict?: Maybe<SystemCacheEvictResponse>;
  billingPlanUpdate?: Maybe<SystemBillingCurrentPlanResponse>;
  changePlanIdea?: Maybe<SystemBillingCurrentPlanResponse>;
  changeRoleIdeaInvitation?: Maybe<SuccessResponse>;
  changeRoleIdeaMember?: Maybe<SuccessResponse>;
  ciCommit?: Maybe<AsyncSession>;
  ciInstall?: Maybe<SuccessResponse>;
  createOrganizationSettingsTech: SystemOrganizationSettingsTech;
  deleteArchieMemberFromOrganization: SystemDeleteArchieMemberFromOrganizationResponse;
  deleteIdea?: Maybe<SuccessResponse>;
  deleteMemberIdea?: Maybe<SuccessResponse>;
  deploy?: Maybe<Scalars['Boolean']>;
  environmentBackup?: Maybe<AsyncSession>;
  environmentBranch?: Maybe<AsyncSession>;
  environmentDelete?: Maybe<SuccessResponse>;
  environmentDeleteAsync?: Maybe<AsyncSession>;
  environmentMemberDelete: SuccessResponse;
  environmentMemberUpdate?: Maybe<SystemEnvironmentMember>;
  environmentRestore?: Maybe<AsyncSession>;
  environmentSetup?: Maybe<SuccessResponse>;
  fieldCreate: SystemTableField;
  fieldDelete: SuccessResponse;
  fieldUpdate: SystemTableField;
  fieldUpdatePosition: SuccessResponse;
  fileUploadByUrl: SystemFileUploadByUrlResponse;
  ideaImprove?: Maybe<SystemIdeaImproveResponse>;
  ideaImproveWithAI?: Maybe<SystemIdeaImproveWithAiResponse>;
  ideaTransferOwner?: Maybe<SuccessResponse>;
  ideaUserCreate?: Maybe<SystemIdeaGeneralResponse>;
  ideaUserUpdate?: Maybe<SystemIdeaGeneralResponse>;
  indexCreate: SystemTableIndex;
  indexDelete?: Maybe<SuccessResponse>;
  indexUpdate: SystemTableIndex;
  invitationIdeaAcceptCancel: SystemInvitationAcceptCancelResponse;
  invitationIdeaResend: SystemInvitationIdeaResendResponse;
  inviteMembers: Array<Maybe<SystemTeamInvitationDetails>>;
  inviteToIdea?: Maybe<SuccessResponse>;
  invoke?: Maybe<SystemInvokeFunctionResponse>;
  memberAccountDelete?: Maybe<SystemMemberAccountDeleteResponse>;
  memberAccountUpsert?: Maybe<SystemMemberAccountInfo>;
  memberChangeOrganizationRole: SystemMemberChangeOrganizationRoleResponse;
  memberInvitationAccept: SystemMemberInvitationAcceptResponse;
  memberInvitationCancel?: Maybe<SuccessResponse>;
  memberInvitationResend?: Maybe<SuccessResponse>;
  memberPaymentDetailsUpdate?: Maybe<SystemPaymentDetailsResponse>;
  memberResendVerifyAccountEmail: SystemMemberResendVerifyAccountEmailResponse;
  notificationUpdate?: Maybe<SuccessResponse>;
  onboardingUpdate?: Maybe<SystemMemberAccountInfo>;
  organizationArchieUpgrade?: Maybe<OrganizationUpgradeResponse>;
  organizationInviteUser?: Maybe<OrganizationUserInvitationResponse>;
  organizationInviteUserAccept?: Maybe<SuccessResponse>;
  organizationInviteUserCancel?: Maybe<SuccessResponse>;
  organizationMemberInvite?: Maybe<SuccessResponse>;
  organizationMemberResendInvite?: Maybe<SuccessResponse>;
  organizationPaymentDetailsUpdate?: Maybe<SystemPaymentDetailsResponse>;
  organizationProjectUserRemove?: Maybe<SuccessResponse>;
  organizationProjectUserShare?: Maybe<SuccessResponse>;
  organizationUpdate?: Maybe<SystemOrganizationItem>;
  organizationUserRemove?: Maybe<SuccessResponse>;
  organizationUserUpdate?: Maybe<SystemOrganizationUserInfo>;
  partnerCreate?: Maybe<SystemPartnerGeneralResponse>;
  postMessageToQueue?: Maybe<SuccessResponse>;
  prepareDeploy: SystemDeployDataResponse;
  projectCreate?: Maybe<AsyncSession>;
  projectDelete?: Maybe<AsyncSession>;
  projectLeave?: Maybe<SuccessResponse>;
  projectUpdate?: Maybe<SystemProjectUpdateResponse>;
  removeOrganizationSettingsTech: SuccessResponse;
  rolePermissionsCacheEvict?: Maybe<SystemCacheEvictResponse>;
  sectionResultCreate?: Maybe<SystemSectionResultResponse>;
  sectionResultGenerate?: Maybe<SuccessResponse>;
  sectionResultPropagate?: Maybe<SuccessResponse>;
  sectionResultRegenerate?: Maybe<SuccessResponse>;
  sectionResultUpdate?: Maybe<SuccessResponse>;
  tableCreate: SystemTable;
  tableDelete: SuccessResponse;
  tableUpdate: SystemTable;
  updateOrganizationSettings: SystemOrganizationSettings;
  viewCreate: SystemTable;
  viewUpdate: SystemTable;
  workspaceCreate?: Maybe<SystemWorkspaceCreateResponse>;
  workspaceCreateAsync?: Maybe<SystemWorkspaceCreateResponse>;
  workspaceDelete?: Maybe<SuccessResponse>;
  workspaceLeave?: Maybe<SuccessResponse>;
  workspaceUpdate?: Maybe<SystemWorkspaceUpdateResponse>;
};

export type SystemMutationExportIdeaToDocumentArgs = {
  data: SystemExportIdeaToDocumentInput;
};

export type SystemMutationApplicationDeleteArgs = {
  data: SystemApplicationDeleteMutationInput;
};

export type SystemMutationApplicationInstallArgs = {
  data: SystemApplicationInstallInput;
};

export type SystemMutationApplicationUpdateArgs = {
  data: SystemApplicationUpdateInput;
};

export type SystemMutationBillingContextCacheEvictArgs = {
  keys: Array<Scalars['String']>;
  projectKeys: Array<Scalars['String']>;
};

export type SystemMutationBillingPlanUpdateArgs = {
  data: SystemBillingPlanUpdateMutationInput;
};

export type SystemMutationChangePlanIdeaArgs = {
  data: SystemChangePlanIdeaMutationInput;
};

export type SystemMutationChangeRoleIdeaInvitationArgs = {
  data: SystemChangeRoleIdeaMutationInputList;
};

export type SystemMutationChangeRoleIdeaMemberArgs = {
  data: SystemChangeRoleIdeaMutationInputList;
};

export type SystemMutationCiCommitArgs = {
  build?: InputMaybe<Scalars['String']>;
  migrationNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mode?: InputMaybe<SystemCiCommitMode>;
  nodeVersion?: InputMaybe<Scalars['String']>;
};

export type SystemMutationCreateOrganizationSettingsTechArgs = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationSettingsId: Scalars['ID'];
};

export type SystemMutationDeleteArchieMemberFromOrganizationArgs = {
  email: Scalars['String'];
  organizationId: Scalars['String'];
};

export type SystemMutationDeleteIdeaArgs = {
  data: SystemDeleteIdeaMutationInput;
};

export type SystemMutationDeleteMemberIdeaArgs = {
  data: SystemDeleteMemberIdeaMutationInputList;
};

export type SystemMutationDeployArgs = {
  data?: InputMaybe<SystemDeployingBuildInput>;
};

export type SystemMutationEnvironmentBackupArgs = {
  environmentName: Scalars['String'];
};

export type SystemMutationEnvironmentBranchArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  mode?: InputMaybe<SystemBranchEnvironmentMode>;
  name: Scalars['String'];
};

export type SystemMutationEnvironmentDeleteArgs = {
  environmentName: Scalars['String'];
};

export type SystemMutationEnvironmentDeleteAsyncArgs = {
  environmentName: Scalars['String'];
};

export type SystemMutationEnvironmentMemberDeleteArgs = {
  filter?: InputMaybe<SystemEnvironmentMembersFilter>;
};

export type SystemMutationEnvironmentMemberUpdateArgs = {
  data?: InputMaybe<SystemEnvironmentMemberUpdateData>;
  filter?: InputMaybe<SystemEnvironmentMembersFilter>;
};

export type SystemMutationEnvironmentRestoreArgs = {
  backup: Scalars['String'];
  environmentName: Scalars['String'];
};

export type SystemMutationEnvironmentSetupArgs = {
  data?: InputMaybe<EnvironmentSetupInput>;
};

export type SystemMutationFieldCreateArgs = {
  data: SystemTableFieldCreateInput;
};

export type SystemMutationFieldDeleteArgs = {
  data: SystemTableFieldDeleteInput;
};

export type SystemMutationFieldUpdateArgs = {
  data: SystemTableFieldUpdateInput;
};

export type SystemMutationFieldUpdatePositionArgs = {
  data: SystemTableFieldPositionUpdateInput;
};

export type SystemMutationFileUploadByUrlArgs = {
  data: SystemFileUploadByUrlInput;
};

export type SystemMutationIdeaImproveArgs = {
  data: SystemIdeaImproveMutationInput;
};

export type SystemMutationIdeaImproveWithAiArgs = {
  data: SystemIdeaImproveWithAiMutationInput;
};

export type SystemMutationIdeaTransferOwnerArgs = {
  data: SystemTransferIdeaOwnerMutationInput;
};

export type SystemMutationIdeaUserCreateArgs = {
  data: SystemIdeaGeneralCreateMutationInput;
};

export type SystemMutationIdeaUserUpdateArgs = {
  data: SystemIdeaGeneralUpdateMutationInput;
};

export type SystemMutationIndexCreateArgs = {
  data: SystemIndexCreateInput;
};

export type SystemMutationIndexDeleteArgs = {
  data: SystemIndexDeleteInput;
};

export type SystemMutationIndexUpdateArgs = {
  data: SystemIndexUpdateInput;
};

export type SystemMutationInvitationIdeaAcceptCancelArgs = {
  data: SystemInvitationIdeaAcceptCancelInput;
};

export type SystemMutationInvitationIdeaResendArgs = {
  data: SystemInvitationIdeaResendlInput;
};

export type SystemMutationInviteMembersArgs = {
  data: SystemInviteMembersInput;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type SystemMutationInviteToIdeaArgs = {
  data: SystemInviteMembersIdeaInput;
};

export type SystemMutationInvokeArgs = {
  data?: InputMaybe<SystemInvokeData>;
};

export type SystemMutationMemberAccountUpsertArgs = {
  data?: InputMaybe<SystemMemberAccountUpsertDataInput>;
};

export type SystemMutationMemberChangeOrganizationRoleArgs = {
  data: SystemMemberChangeOrganizationRoleInput;
};

export type SystemMutationMemberInvitationAcceptArgs = {
  data: SystemMemberInvitationAcceptInput;
};

export type SystemMutationMemberInvitationCancelArgs = {
  data: SystemMemberInvitationCancelInput;
};

export type SystemMutationMemberInvitationResendArgs = {
  data: SystemMemberInvitationResendInput;
};

export type SystemMutationMemberPaymentDetailsUpdateArgs = {
  data: SystemMemberPaymentDetailsUpdateMutationInput;
};

export type SystemMutationMemberResendVerifyAccountEmailArgs = {
  data: SystemMemberResendVerifyAccountEmaillInput;
};

export type SystemMutationNotificationUpdateArgs = {
  id: Scalars['String'];
  status: NotificationStatusType;
};

export type SystemMutationOnboardingUpdateArgs = {
  data?: InputMaybe<SystemMemberAccountUpsertDataInput>;
};

export type SystemMutationOrganizationArchieUpgradeArgs = {
  data: SystemOrganizationUpgradeMutationInput;
};

export type SystemMutationOrganizationInviteUserArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  projectRoles?: InputMaybe<Array<InputMaybe<SystemOrganizationProjectWithEnvironmentRolesInput>>>;
  role: Scalars['String'];
};

export type SystemMutationOrganizationInviteUserAcceptArgs = {
  invitationId: Scalars['String'];
};

export type SystemMutationOrganizationInviteUserCancelArgs = {
  invitationId: Scalars['String'];
};

export type SystemMutationOrganizationMemberInviteArgs = {
  data: SystemMemberOrganizationInviteMutationInput;
};

export type SystemMutationOrganizationMemberResendInviteArgs = {
  data: SystemMemberOrganizationResendInviteMutationInput;
};

export type SystemMutationOrganizationPaymentDetailsUpdateArgs = {
  data: SystemOrganizationPaymentDetailsUpdateMutationInput;
};

export type SystemMutationOrganizationProjectUserRemoveArgs = {
  email: Scalars['String'];
  projectId: Scalars['ID'];
};

export type SystemMutationOrganizationProjectUserShareArgs = {
  email: Scalars['String'];
  environmentRoles?: InputMaybe<Array<InputMaybe<SystemOrganizationProjectEnvironmentRolesInput>>>;
  projectId: Scalars['ID'];
};

export type SystemMutationOrganizationUpdateArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  image?: InputMaybe<GraphQlCreateFileItemInput>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SystemOrganizationTypeEnum>;
};

export type SystemMutationOrganizationUserRemoveArgs = {
  email: Scalars['String'];
  organizationId: Scalars['String'];
};

export type SystemMutationOrganizationUserUpdateArgs = {
  email: Scalars['String'];
  organizationId: Scalars['ID'];
  role: Scalars['String'];
};

export type SystemMutationPartnerCreateArgs = {
  data: SystemPartnerGeneralCreateMutationInput;
};

export type SystemMutationPostMessageToQueueArgs = {
  data: SystemPostMessageToQueueInput;
};

export type SystemMutationProjectCreateArgs = {
  data: SystemProjectCreateMutationInput;
};

export type SystemMutationProjectDeleteArgs = {
  projectId: Scalars['ID'];
};

export type SystemMutationProjectLeaveArgs = {
  projectId: Scalars['ID'];
};

export type SystemMutationProjectUpdateArgs = {
  data: SystemProjectUpdateMutationInput;
};

export type SystemMutationRemoveOrganizationSettingsTechArgs = {
  organizationSettingsTechId: Scalars['ID'];
};

export type SystemMutationRolePermissionsCacheEvictArgs = {
  keys: Array<Scalars['String']>;
};

export type SystemMutationSectionResultCreateArgs = {
  data: SystemSectionResultCreateMutationInput;
};

export type SystemMutationSectionResultGenerateArgs = {
  data: SystemSectionResultGenerateMutationInput;
};

export type SystemMutationSectionResultPropagateArgs = {
  data: SystemSectionResultPropagateUpdateMutationInput;
};

export type SystemMutationSectionResultRegenerateArgs = {
  data: SystemGraphQlSectionResultRegenerateMutationInput;
};

export type SystemMutationSectionResultUpdateArgs = {
  data: SystemSectionResultUpdateMutationInput;
};

export type SystemMutationTableCreateArgs = {
  data: SystemTableCreateInput;
};

export type SystemMutationTableDeleteArgs = {
  data: SystemTableDeleteInput;
};

export type SystemMutationTableUpdateArgs = {
  data: SystemTableUpdateInput;
};

export type SystemMutationUpdateOrganizationSettingsArgs = {
  fileId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isWhiteLabelActive?: InputMaybe<Scalars['Boolean']>;
  statementOfWork?: InputMaybe<Scalars['String']>;
};

export type SystemMutationViewCreateArgs = {
  data: SystemViewCreateInput;
};

export type SystemMutationViewUpdateArgs = {
  data: SystemViewUpdateInput;
};

export type SystemMutationWorkspaceCreateArgs = {
  data: SystemWorkspaceCreateMutationInput;
};

export type SystemMutationWorkspaceCreateAsyncArgs = {
  data: SystemWorkspaceCreateMutationInput;
};

export type SystemMutationWorkspaceDeleteArgs = {
  data: SystemWorkspaceDeleteMutationInput;
};

export type SystemMutationWorkspaceLeaveArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
};

export type SystemMutationWorkspaceUpdateArgs = {
  data: SystemWorkspaceUpdateMutationInput;
};

/** Number Field Attributes */
export type SystemNumberFieldTypeAttributes = {
  __typename?: 'SystemNumberFieldTypeAttributes';
  autoIncrement?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  isBigInt?: Maybe<Scalars['Boolean']>;
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Int']>;
};

/** Number Type Format Enum */
export type SystemNumberTypeFormatEnum =
  | 'CURRENCY'
  | 'FRACTION'
  | 'NUMBER'
  | 'PERCENTAGE'
  | 'SCIENTIFIC';

export type SystemOrganizationArchieBaseItem = {
  __typename?: 'SystemOrganizationArchieBaseItem';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemFileBaseInfo>;
  isOrganizationOwner: Scalars['Boolean'];
  name: Scalars['String'];
  organizationCreatorRole?: Maybe<Scalars['String']>;
  organizationKind?: Maybe<Scalars['String']>;
  organizationSize?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  type?: Maybe<SystemOrganizationTypeEnum>;
};

export type SystemOrganizationBaseItem = {
  __typename?: 'SystemOrganizationBaseItem';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemFileBaseInfo>;
  name: Scalars['String'];
  type?: Maybe<SystemOrganizationTypeEnum>;
};

export type SystemOrganizationInvitation = {
  __typename?: 'SystemOrganizationInvitation';
  accepted?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  emailFrom: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  firstNameFrom?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  lastNameFrom?: Maybe<Scalars['String']>;
  organization: SystemOrganizationBaseItem;
  role: Scalars['String'];
};

export type SystemOrganizationItem = {
  __typename?: 'SystemOrganizationItem';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemOrganizationItemImage>;
  name: Scalars['String'];
  type?: Maybe<SystemOrganizationTypeEnum>;
  users?: Maybe<Array<Maybe<SystemOrganizationUserInfo>>>;
};

export type SystemOrganizationItemImage = {
  __typename?: 'SystemOrganizationItemImage';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** OrganizationPaymentDetailsUpdateMutationInput */
export type SystemOrganizationPaymentDetailsUpdateMutationInput = {
  cardToken: Scalars['String'];
  organizationId: Scalars['ID'];
};

/** environmentId to add to with roles (array of ids). */
export type SystemOrganizationProjectEnvironmentRolesInput = {
  environmentId: Scalars['String'];
  roles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type SystemOrganizationProjectItem = {
  __typename?: 'SystemOrganizationProjectItem';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemFileBaseInfo>;
  kind: Scalars['String'];
  name: Scalars['String'];
  organization?: Maybe<SystemOrganizationBaseItem>;
};

export type SystemOrganizationProjectWithEnvironmentRolesInput = {
  environmentRoles?: InputMaybe<Array<InputMaybe<SystemOrganizationProjectEnvironmentRolesInput>>>;
  projectId: Scalars['ID'];
};

/** Representation of a organization settings. */
export type SystemOrganizationSettings = {
  __typename?: 'SystemOrganizationSettings';
  id: Scalars['ID'];
  /** Flag to indicate if white label is active. */
  isWhiteLabelActive?: Maybe<Scalars['Boolean']>;
  /** Statement of work defined by the organization. */
  statementOfWork?: Maybe<Scalars['String']>;
  /** Stack of technologies defined by the organization. */
  techStack: Array<SystemOrganizationSettingsTech>;
  /** White label image defined by the organization. */
  whiteLabelImage?: Maybe<SystemFileBaseInfo>;
};

/** Representation of a organization settings technology. */
export type SystemOrganizationSettingsTech = {
  __typename?: 'SystemOrganizationSettingsTech';
  /** Description of the tech stack. */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Name of the given tech stack, example "React". */
  name: Scalars['String'];
};

export type SystemOrganizationTypeEnum = 'agency' | 'community' | 'company' | 'individual';

/** Organization Upgrade Input */
export type SystemOrganizationUpgradeMutationInput = {
  description?: InputMaybe<Scalars['String']>;
  licenseId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationCreatorRole: Scalars['String'];
  organizationKind: Scalars['String'];
  organizationSize: Scalars['String'];
  owner: Scalars['String'];
  type: Scalars['String'];
};

export type SystemOrganizationUserInfo = {
  __typename?: 'SystemOrganizationUserInfo';
  avatar?: Maybe<GraphQlFileItemResponse>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Maybe<SystemOrganizationProjectItem>>>;
  role: Scalars['String'];
  status: Scalars['String'];
};

export type SystemOrganizationWorkspaceItem = {
  __typename?: 'SystemOrganizationWorkspaceItem';
  apiHost?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemFileBaseInfo>;
  kind: Scalars['String'];
  name: Scalars['String'];
  organization?: Maybe<SystemOrganizationBaseItem>;
};

/** SystemOrganizationsListArchieResponse output */
export type SystemOrganizationsListArchieResponse = {
  __typename?: 'SystemOrganizationsListArchieResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemOrganizationArchieBaseItem>;
};

/** SystemOrganizationsListResponse output */
export type SystemOrganizationsListResponse = {
  __typename?: 'SystemOrganizationsListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemOrganizationBaseItem>;
};

export type SystemPartnerGeneralCreateMutationInput = {
  anythingElse?: InputMaybe<Scalars['String']>;
  blueprintUrl: Scalars['String'];
  companyName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  location: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type SystemPartnerGeneralResponse = {
  __typename?: 'SystemPartnerGeneralResponse';
  AccountId: Scalars['String'];
  anythingElse?: Maybe<Scalars['String']>;
  blueprintUrl: Scalars['String'];
  companyName: Scalars['String'];
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  location: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type SystemPartnerItemRespSingle = {
  __typename?: 'SystemPartnerItemRespSingle';
  AccountId: Scalars['String'];
  anythingElse?: Maybe<Scalars['String']>;
  blueprintUrl: Scalars['String'];
  companyName: Scalars['String'];
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  location: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

/** SystemPartnerItemResponse output */
export type SystemPartnerItemResponse = {
  __typename?: 'SystemPartnerItemResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemPartnerItemRespSingle>;
};

export type SystemPartnerResponse = {
  __typename?: 'SystemPartnerResponse';
  partners: SystemPartnerItemResponse;
};

export type SystemPaymentDetailsOrigin = 'member' | 'organization' | 'workspace';

export type SystemPaymentDetailsOriginProject = 'member' | 'organization' | 'workspace';

export type SystemPaymentDetailsProjectResponse = {
  __typename?: 'SystemPaymentDetailsProjectResponse';
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  last4?: Maybe<Scalars['String']>;
  origin?: Maybe<SystemPaymentDetailsOriginProject>;
};

export type SystemPaymentDetailsResponse = {
  __typename?: 'SystemPaymentDetailsResponse';
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  last4?: Maybe<Scalars['String']>;
  origin: SystemPaymentDetailsOrigin;
};

/** Diff Environment Input */
export type SystemPlanEnvironmentOutput = {
  __typename?: 'SystemPlanEnvironmentOutput';
  url?: Maybe<Scalars['String']>;
};

export type SystemPlanForIdeaQueryResponse = {
  __typename?: 'SystemPlanForIdeaQueryResponse';
  id?: Maybe<Scalars['ID']>;
};

export type SystemPlanInfoIdea = {
  __typename?: 'SystemPlanInfoIdea';
  features?: Maybe<Array<Maybe<Scalars['String']>>>;
  planName?: Maybe<Scalars['String']>;
};

export type SystemPostMessageToQueueInput = {
  message?: InputMaybe<Scalars['JSON']>;
  routingKey?: InputMaybe<Scalars['String']>;
};

/** ProjectCreateMutationInput */
export type SystemProjectCreateMutationInput = {
  authType?: InputMaybe<Scalars['String']>;
  billingPlanId?: InputMaybe<Scalars['ID']>;
  cardToken?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  howStart?: InputMaybe<Scalars['String']>;
  ideaId?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<GraphQlCreateFileItemInput>;
  kind?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type SystemProjectDeploymentsResponse = {
  __typename?: 'SystemProjectDeploymentsResponse';
  deploymentsProject?: Maybe<SystemDeploymentProjectListResponse>;
};

export type SystemProjectFrontendResponse = {
  __typename?: 'SystemProjectFrontendResponse';
  frontendUtilization?: Maybe<SystemFrontendUtilizationResponse>;
  frontendWorkspaces?: Maybe<SystemWorkspaceListResponse>;
};

export type SystemProjectImage = {
  __typename?: 'SystemProjectImage';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type SystemProjectItemRespSingle = {
  __typename?: 'SystemProjectItemRespSingle';
  apiHost?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemProjectImage>;
  kind?: Maybe<Scalars['String']>;
  lastAccess?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  nextPlan?: Maybe<SystemBillingNextPlanResponse>;
  organization?: Maybe<SystemOrganizationBaseItem>;
  owner?: Maybe<SystemMemberAccountInfo>;
  plan?: Maybe<SystemBillingCurrentPlanResponse>;
  region?: Maybe<Scalars['String']>;
  usersCount?: Maybe<Scalars['Int']>;
};

/** SystemProjectItemResponse output */
export type SystemProjectItemResponse = {
  __typename?: 'SystemProjectItemResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemProjectItemRespSingle>;
};

export type SystemProjectItemWs = {
  __typename?: 'SystemProjectItemWS';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemWorkspaceImage>;
  name: Scalars['String'];
  region?: Maybe<Scalars['String']>;
};

export type SystemProjectPlanResponse = {
  __typename?: 'SystemProjectPlanResponse';
  overagesPrice?: Maybe<Scalars['Float']>;
  paymentDetail?: Maybe<SystemPaymentDetailsProjectResponse>;
  plan?: Maybe<SystemBillingCurrentPlanResponse>;
};

export type SystemProjectQuotasResponse = {
  __typename?: 'SystemProjectQuotasResponse';
  metricUsages?: Maybe<SystemBillingMetricUsagesListResponse>;
  metricUsagesQuotas?: Maybe<SystemBillingMetricUsageQuotasListResponse>;
};

/** ProjectUpdateMutationInput */
export type SystemProjectUpdateMutationInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: InputMaybe<GraphQlCreateFileItemInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type SystemProjectUpdateResponse = {
  __typename?: 'SystemProjectUpdateResponse';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<GraphQlFileItemResponse>;
  name?: Maybe<Scalars['String']>;
};

export type SystemProjectUserDetailsResponse = {
  __typename?: 'SystemProjectUserDetailsResponse';
  apiHost: Scalars['String'];
  authenticationInfo?: Maybe<Array<Maybe<SystemAuthenticationInfo>>>;
  backendUtilization?: Maybe<SystemBackendUtilizationResponse>;
  backendWorkspaces?: Maybe<SystemWorkspaceListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  environmentsProject?: Maybe<SystemEnvironmentsProjectListResponse>;
  id: Scalars['ID'];
  image?: Maybe<SystemProjectImage>;
  kind: Scalars['String'];
  lastAccess?: Maybe<Scalars['DateTime']>;
  metricUsages?: Maybe<SystemBillingMetricUsagesListResponse>;
  metricUsagesQuotas?: Maybe<SystemBillingMetricUsageQuotasListResponse>;
  name: Scalars['String'];
  nextPlan?: Maybe<SystemBillingNextPlanResponse>;
  organization?: Maybe<SystemOrganizationBaseItem>;
  overagesPrice?: Maybe<Scalars['Float']>;
  owner?: Maybe<SystemMemberAccountInfo>;
  paymentDetail?: Maybe<SystemPaymentDetailsProjectResponse>;
  plan?: Maybe<SystemBillingCurrentPlanResponse>;
  region?: Maybe<Scalars['String']>;
  teamMemberCount?: Maybe<Scalars['Int']>;
};

export type SystemProjectUserResponse = {
  __typename?: 'SystemProjectUserResponse';
  myProjects: SystemProjectItemResponse;
  sharedProjects: SystemProjectItemResponse;
};

export type SystemPromptItemRespSingle = {
  __typename?: 'SystemPromptItemRespSingle';
  basicSectionConfiguration?: Maybe<Scalars['JSON']>;
  dependsOn?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  inputVariables: Scalars['JSON'];
  iterableConfig?: Maybe<Scalars['JSON']>;
  modelId: Scalars['ID'];
  modelName: Scalars['String'];
  name: Scalars['String'];
  outputSchema: Scalars['JSON'];
  package: Scalars['String'];
  sectionCategory?: Maybe<Scalars['String']>;
  templateBody: Scalars['String'];
};

/** SystemPromptItemResponse output */
export type SystemPromptItemResponse = {
  __typename?: 'SystemPromptItemResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemPromptItemRespSingle>;
};

export type SystemPromptsResponse = {
  __typename?: 'SystemPromptsResponse';
  prompts: SystemPromptItemResponse;
};

export type SystemQuery = {
  __typename?: 'SystemQuery';
  application?: Maybe<SystemApplication>;
  applicationsList?: Maybe<SystemApplicationListResponse>;
  asyncSessionStatus?: Maybe<SystemAsyncSessionStatusResponse>;
  basicSectionConfigurationForPromptQuery?: Maybe<SystemBasicSectionConfigurationForPromptExecutionResponse>;
  basicSectionConfigurationQuery?: Maybe<SystemBasicSectionConfigurationResponse>;
  billingCurrentPlan?: Maybe<SystemBillingCurrentPlanResponse>;
  /** @deprecated No longer supported. Use `system.memberPaymentDetails, system.organizationPaymentDetails or system.workspacePaymentDetails` instead. */
  billingDetails?: Maybe<SystemBillingDetailsResponse>;
  /** @deprecated No longer supported. Use `system.memberBillingHistory, system.organizationBillingHistory or system.workspaceBillingHistory` instead. */
  billingInvoicesList: SystemBillingInvoicesListResponse;
  billingMetricUsageQuotasList: SystemBillingMetricUsageQuotasListResponse;
  billingMetricUsagesList: SystemBillingMetricUsagesListResponse;
  ciGenerate?: Maybe<SystemGenerateEnvironmentOutput>;
  ciGenerateAsync?: Maybe<AsyncSession>;
  /** @deprecated No longer supported. Use `ciGenerate` instead. */
  ciPlan?: Maybe<SystemPlanEnvironmentOutput>;
  ciStatus?: Maybe<SystemCiStatusOutput>;
  deployStatus: SystemDeployStatusResult;
  ensureOrganizationSettings: SystemOrganizationSettings;
  environmentBackupsList?: Maybe<SystemEnvironmentBackupListResponse>;
  environmentMember?: Maybe<SystemEnvironmentMember>;
  environmentMembersList?: Maybe<SystemEnvironmentMembersListResponse>;
  environmentSettings?: Maybe<SystemEnvironmentSettings>;
  environmentsList?: Maybe<SystemEnvironmentsListResponse>;
  fileUploadSignInfo?: Maybe<SystemFileUploadSignInfo>;
  functionsList?: Maybe<SystemFunctionListResponse>;
  functionsVersionCheck?: Maybe<SystemFunctionInfoCheck>;
  getCouponQuery?: Maybe<SystemGetCouponResponse>;
  getCurrentPlanForIdeaQuery?: Maybe<SystemPlanForIdeaQueryResponse>;
  getEnvironmentRoles?: Maybe<Array<Maybe<SystemEnvironmentRoleList>>>;
  getInvitationIdeaQuery?: Maybe<SystemGetInvitationIdeaResponse>;
  ideaDetails?: Maybe<SystemIdeaDetailsResponse>;
  ideaGraphQLSchemaQuery: SystemIdeaGraphQlSchema;
  ideaMembers?: Maybe<SystemMembersIdeaList>;
  ideaSectionDetails?: Maybe<SystemIdeaDetailsResponse>;
  ideasDemoListQuery?: Maybe<SystemIdeaUserResponse>;
  ideasListQuery?: Maybe<SystemIdeaUserResponse>;
  ideasUserListQuery?: Maybe<SystemIdeaUserResponse>;
  inboxEventsList?: Maybe<SystemInboxEventsListResponse>;
  introspection?: Maybe<IntrospectionQueryResponse>;
  /** @deprecated No longer supported. Use `system.logsList` instead. */
  logs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated No longer supported. Use `system.logsListFiltered` instead. */
  logsList?: Maybe<Array<Maybe<SystemCloudLogsEntry>>>;
  logsListFiltered?: Maybe<SystemLogsListFilteredResponse>;
  memberAccount?: Maybe<SystemMemberAccountInfo>;
  memberAccountFromEmail?: Maybe<SystemMemberAccountInfo>;
  memberBillingHistory: SystemBillingInvoicesListResponse;
  memberFromOrganization?: Maybe<SystemMemberOrganizationAccountInfo>;
  memberInvitation?: Maybe<SystemMemberInvitation>;
  memberInvitationsList?: Maybe<SystemMemberInvitationsList>;
  memberPaymentDetails?: Maybe<SystemPaymentDetailsResponse>;
  membersByOrganization?: Maybe<SystemMembersAccountList>;
  organizationBillingHistory: SystemBillingInvoicesListResponse;
  organizationBillingPlan?: Maybe<SystemBillingCurrentPlanResponse>;
  organizationById?: Maybe<SystemOrganizationItem>;
  organizationInvitationById?: Maybe<SystemOrganizationInvitation>;
  organizationPaymentDetails?: Maybe<SystemPaymentDetailsResponse>;
  organizationsListByArchieUser?: Maybe<SystemOrganizationsListArchieResponse>;
  organizationsListByUser?: Maybe<SystemOrganizationsListResponse>;
  partnerListQuery?: Maybe<SystemPartnerResponse>;
  projectDeployments?: Maybe<SystemProjectDeploymentsResponse>;
  projectFrontend?: Maybe<SystemProjectFrontendResponse>;
  projectInfoDetails?: Maybe<SystemProjectUserDetailsResponse>;
  projectPlan?: Maybe<SystemProjectPlanResponse>;
  projectQuotas?: Maybe<SystemProjectQuotasResponse>;
  projectsUserListQuery?: Maybe<SystemProjectUserResponse>;
  promptListQuery?: Maybe<SystemPromptsResponse>;
  table?: Maybe<SystemTable>;
  tableField?: Maybe<SystemTableField>;
  tablesList: SystemTableListResponse;
  userBillingConfiguration: SystemUserBillingConfigurationResponse;
  userBillingConfigurationArchie: SystemUserBillingConfigurationResponse;
  userInvitationsList?: Maybe<SystemUserInvitationList>;
  viewDryRun?: Maybe<SystemViewDryRunOutput>;
  workspaceName?: Maybe<Scalars['String']>;
  workspacePaymentDetails?: Maybe<SystemPaymentDetailsResponse>;
  workspacesFrontendList?: Maybe<SystemWorkspaceListResponse>;
  workspacesList?: Maybe<SystemWorkspaceListResponse>;
};

export type SystemQueryApplicationArgs = {
  id: Scalars['String'];
};

export type SystemQueryAsyncSessionStatusArgs = {
  sessionId: Scalars['String'];
};

export type SystemQueryBillingCurrentPlanArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type SystemQueryBillingInvoicesListArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  by?: InputMaybe<SystemBillingInvoicesListFilterType>;
  limit?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['ID']>;
};

export type SystemQueryBillingMetricUsageQuotasListArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
};

export type SystemQueryBillingMetricUsagesListArgs = {
  filter?: InputMaybe<SystemBillingMetricUsagesListFilter>;
  organizationId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
};

export type SystemQueryCiGenerateArgs = {
  sourceEnvironmentId?: InputMaybe<Scalars['String']>;
  tables?: InputMaybe<Array<Scalars['String']>>;
  targetEnvironmentId?: InputMaybe<Scalars['String']>;
};

export type SystemQueryCiGenerateAsyncArgs = {
  sourceEnvironmentId?: InputMaybe<Scalars['String']>;
  tables?: InputMaybe<Array<Scalars['String']>>;
  targetEnvironmentId?: InputMaybe<Scalars['String']>;
};

export type SystemQueryCiPlanArgs = {
  sourceEnvironmentId?: InputMaybe<Scalars['String']>;
  tables?: InputMaybe<Array<Scalars['String']>>;
  targetEnvironmentId?: InputMaybe<Scalars['String']>;
};

export type SystemQueryDeployStatusArgs = {
  buildName: Scalars['String'];
};

export type SystemQueryEnsureOrganizationSettingsArgs = {
  organizationId: Scalars['ID'];
};

export type SystemQueryEnvironmentBackupsListArgs = {
  environmentName?: InputMaybe<Scalars['String']>;
};

export type SystemQueryEnvironmentMemberArgs = {
  filter?: InputMaybe<SystemEnvironmentMemberFilter>;
};

export type SystemQueryEnvironmentMembersListArgs = {
  environmentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<SystemEnvironmentMembersListFilter>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SystemEnvironmentMembersListSort>>;
};

export type SystemQueryEnvironmentsListArgs = {
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type SystemQueryFunctionsListArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SystemFunctionInfoFilter>;
  orderBy?: InputMaybe<Array<InputMaybe<SystemFunctionInfoOrderBy>>>;
};

export type SystemQueryGetCouponQueryArgs = {
  couponName: Scalars['String'];
};

export type SystemQueryGetEnvironmentRolesArgs = {
  email?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['ID']>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type SystemQueryGetInvitationIdeaQueryArgs = {
  id: Scalars['ID'];
};

export type SystemQueryIdeaDetailsArgs = {
  demoMode?: InputMaybe<Scalars['Boolean']>;
  ideaId: Scalars['ID'];
};

export type SystemQueryIdeaGraphQlSchemaQueryArgs = {
  ideaId: Scalars['ID'];
};

export type SystemQueryIdeaMembersArgs = {
  id: Scalars['String'];
};

export type SystemQueryIdeaSectionDetailsArgs = {
  ideaId: Scalars['ID'];
  packageIdea: Scalars['ID'];
  sectionCategory: Scalars['ID'];
};

export type SystemQueryIdeasUserListQueryArgs = {
  organizationId?: InputMaybe<Scalars['String']>;
};

export type SystemQueryInboxEventsListArgs = {
  filter?: InputMaybe<InboxEventsListFilter>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SystemQueryLogsArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  functionName: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type SystemQueryLogsListArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  functionName: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type SystemQueryLogsListFilteredArgs = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  filter?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  resource?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  startToken?: InputMaybe<Scalars['String']>;
};

export type SystemQueryMemberAccountFromEmailArgs = {
  email: Scalars['String'];
};

export type SystemQueryMemberBillingHistoryArgs = {
  first?: InputMaybe<Scalars['Int']>;
  hideOrganizationInvoices?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SystemQueryMemberFromOrganizationArgs = {
  id: Scalars['String'];
};

export type SystemQueryMemberInvitationArgs = {
  id: Scalars['String'];
};

export type SystemQueryMembersByOrganizationArgs = {
  organizationId: Scalars['String'];
};

export type SystemQueryOrganizationBillingHistoryArgs = {
  first?: InputMaybe<Scalars['Int']>;
  hideWorkspaceInvoices?: InputMaybe<Scalars['Boolean']>;
  organizationId: Scalars['ID'];
  skip?: InputMaybe<Scalars['Int']>;
};

export type SystemQueryOrganizationBillingPlanArgs = {
  organizationId: Scalars['ID'];
};

export type SystemQueryOrganizationByIdArgs = {
  organizationId: Scalars['String'];
};

export type SystemQueryOrganizationInvitationByIdArgs = {
  invitationId: Scalars['String'];
};

export type SystemQueryOrganizationPaymentDetailsArgs = {
  organizationId: Scalars['ID'];
};

export type SystemQueryProjectDeploymentsArgs = {
  projectId: Scalars['ID'];
};

export type SystemQueryProjectFrontendArgs = {
  projectId: Scalars['ID'];
};

export type SystemQueryProjectInfoDetailsArgs = {
  projectId: Scalars['ID'];
};

export type SystemQueryProjectPlanArgs = {
  projectId: Scalars['ID'];
};

export type SystemQueryProjectQuotasArgs = {
  projectId: Scalars['ID'];
};

export type SystemQueryProjectsUserListQueryArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type SystemQueryTableArgs = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type SystemQueryTableFieldArgs = {
  id: Scalars['ID'];
};

export type SystemQueryTablesListArgs = {
  filter?: InputMaybe<SystemTableListFilter>;
};

export type SystemQueryUserBillingConfigurationArgs = {
  filterPlanProjects?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  region?: InputMaybe<Scalars['String']>;
};

export type SystemQueryUserBillingConfigurationArchieArgs = {
  ideaId?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  region?: InputMaybe<Scalars['String']>;
};

export type SystemQueryViewDryRunArgs = {
  sql: Scalars['String'];
};

export type SystemQueryWorkspacePaymentDetailsArgs = {
  projectId?: InputMaybe<Scalars['ID']>;
};

/** Relation */
export type SystemRelation = {
  __typename?: 'SystemRelation';
  refField?: Maybe<SystemTableField>;
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList?: Maybe<Scalars['Boolean']>;
  refFieldIsRequired?: Maybe<Scalars['Boolean']>;
  refFieldName?: Maybe<Scalars['String']>;
  refTable: SystemTable;
  relationFieldName?: Maybe<Scalars['String']>;
  relationTableName?: Maybe<Scalars['String']>;
};

/** Relation Create Input */
export type SystemRelationCreateInput = {
  refFieldDisplayName?: InputMaybe<Scalars['String']>;
  refFieldIsList: Scalars['Boolean'];
  refFieldIsRequired: Scalars['Boolean'];
  refFieldName?: InputMaybe<Scalars['String']>;
  refTableId: Scalars['ID'];
};

/** Relation Update Input */
export type SystemRelationUpdateInput = {
  refFieldDisplayName?: InputMaybe<Scalars['String']>;
  refFieldIsList?: InputMaybe<Scalars['Boolean']>;
  refFieldIsRequired?: InputMaybe<Scalars['Boolean']>;
  refFieldName?: InputMaybe<Scalars['String']>;
  refTableId?: InputMaybe<Scalars['ID']>;
};

/** Schema Origin */
export type SystemSchemaOrigin = {
  __typename?: 'SystemSchemaOrigin';
  provider?: Maybe<Scalars['String']>;
  type: SystemSchemaOriginType;
};

/** Schema Origin Type Enum */
export type SystemSchemaOriginType = 'LOCAL' | 'REMOTE' | 'VIEW';

export type SystemSectionResultCreateMutationInput = {
  archieId: Scalars['ID'];
  composedPrompt: Scalars['JSON'];
  content: Scalars['JSON'];
  cost?: InputMaybe<Scalars['Float']>;
  custom?: InputMaybe<Scalars['Boolean']>;
  executionTime?: InputMaybe<Scalars['Int']>;
  failureReason?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  isRegenerate?: InputMaybe<Scalars['Boolean']>;
  lLMApiCalls?: InputMaybe<Scalars['Int']>;
  modelId: Scalars['ID'];
  package: Scalars['String'];
  prompt: Scalars['ID'];
  provisionalData?: InputMaybe<Scalars['Boolean']>;
  sectionCategory: Scalars['String'];
  status?: InputMaybe<SystemStatusIdea>;
  tokensInput?: InputMaybe<Scalars['Int']>;
  tokensOutput?: InputMaybe<Scalars['Int']>;
};

export type SystemSectionResultGenerateMutationInput = {
  archieId: Scalars['ID'];
  contextData?: InputMaybe<Scalars['JSON']>;
  idea: Scalars['ID'];
  iterableID?: InputMaybe<Scalars['String']>;
  package: Scalars['String'];
  provisionalData?: InputMaybe<Scalars['Boolean']>;
  sectionCategories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SystemSectionResultPropagateUpdateMutationInput = {
  archieId: Scalars['ID'];
  content: Scalars['JSON'];
  package: Scalars['String'];
  sectionCategory: Scalars['String'];
};

export type SystemSectionResultResponse = {
  __typename?: 'SystemSectionResultResponse';
  sectionId: Scalars['ID'];
  sectionResultId: Scalars['ID'];
};

export type SystemSectionResultUpdateMutationInput = {
  archieId: Scalars['ID'];
  overviewId?: InputMaybe<Scalars['String']>;
  package: Scalars['String'];
  sectionCategories?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
};

/** Smart Field Attributes */
export type SystemSmartFieldTypeAttributes = {
  __typename?: 'SystemSmartFieldTypeAttributes';
  format: Scalars['String'];
  innerFields?: Maybe<Array<Maybe<SystemCustomTableField>>>;
};

/** Smart Type Format Enum */
export type SystemSmartTypeFormatEnum = 'ADDRESS' | 'PHONE';

/** StatusIdea */
export type SystemStatusIdea = 'Completed' | 'Locked' | 'NeedsAttention' | 'Open';

/** Switch Field Attributes */
export type SystemSwitchFieldTypeAttributes = {
  __typename?: 'SystemSwitchFieldTypeAttributes';
  format: Scalars['String'];
  listOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Switch Type Format Enum */
export type SystemSwitchTypeFormatEnum =
  | 'ACTIVE_INACTIVE'
  | 'CUSTOM'
  | 'HIGH_LOW'
  | 'ON_OFF'
  | 'TRUE_FALSE'
  | 'YES_NO';

/** Table */
export type SystemTable = {
  __typename?: 'SystemTable';
  application?: Maybe<SystemApplication>;
  attributes?: Maybe<SystemTableAttributes>;
  dataFeatures: SystemTableDataFeatures;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<SystemTableField>>;
  fieldsForIndexing?: Maybe<Array<Maybe<SystemTableField>>>;
  id: Scalars['ID'];
  indexes?: Maybe<Array<SystemTableIndex>>;
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  origin: SystemSchemaOrigin;
  schemaFeatures: SystemTableSchemaFeatures;
};

/** Table Attributes */
export type SystemTableAttributes = SystemViewAttributes;

/** Table Create Input */
export type SystemTableCreateInput = {
  applicationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Table Data Features */
export type SystemTableDataFeatures = {
  __typename?: 'SystemTableDataFeatures';
  create: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** Table Delete Input */
export type SystemTableDeleteInput = {
  id: Scalars['ID'];
};

/** TableField */
export type SystemTableField = {
  __typename?: 'SystemTableField';
  computedMode?: Maybe<SystemComputedFieldMode>;
  dataFeatures: SystemFieldDataFeatures;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  fieldType: SystemFieldType;
  fieldTypeAttributes?: Maybe<SystemFieldTypeAttributes>;
  id: Scalars['ID'];
  isList: Scalars['Boolean'];
  isMeta: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isSystem: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  origin: SystemSchemaOrigin;
  relation?: Maybe<SystemRelation>;
  schemaFeatures: SystemFieldSchemaFeatures;
  table: SystemTable;
};

/** Table Field Create Input */
export type SystemTableFieldCreateInput = {
  computedMode?: InputMaybe<SystemComputedFieldMode>;
  defaultValue?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<Scalars['String']>;
  fieldType: SystemFieldType;
  fieldTypeAttributes?: InputMaybe<SystemFieldTypeAttributesInput>;
  force?: InputMaybe<Scalars['Boolean']>;
  initialValue?: InputMaybe<Scalars['String']>;
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  position?: InputMaybe<Scalars['Int']>;
  relation?: InputMaybe<SystemRelationCreateInput>;
  tableId: Scalars['ID'];
};

/** Table Field Delete Input */
export type SystemTableFieldDeleteInput = {
  id: Scalars['ID'];
};

/** Table Field Position Update Input */
export type SystemTableFieldPositionUpdateInput = {
  id: Scalars['ID'];
  newPosition: Scalars['Int'];
};

/** Table Field Update Input */
export type SystemTableFieldUpdateInput = {
  computedMode?: InputMaybe<SystemComputedFieldMode>;
  defaultValue?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<Scalars['String']>;
  fieldType?: InputMaybe<SystemFieldType>;
  fieldTypeAttributes?: InputMaybe<SystemFieldTypeAttributesInput>;
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  initialValue?: InputMaybe<Scalars['String']>;
  isList?: InputMaybe<Scalars['Boolean']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  isUnique?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  relation?: InputMaybe<SystemRelationUpdateInput>;
};

/** Table Index */
export type SystemTableIndex = {
  __typename?: 'SystemTableIndex';
  columns?: Maybe<Array<SystemTableIndexColumn>>;
  id: Scalars['ID'];
  isSystem: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  table: SystemTable;
  type: Scalars['String'];
};

/** Table Index Column */
export type SystemTableIndexColumn = {
  __typename?: 'SystemTableIndexColumn';
  name: Scalars['String'];
};

/** Table Index Column Input */
export type SystemTableIndexColumnInput = {
  name: Scalars['String'];
};

/** Table List Application Filter */
export type SystemTableListApplicationFilter = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Table List Filter */
export type SystemTableListFilter = {
  applications?: InputMaybe<Array<InputMaybe<SystemTableListApplicationFilter>>>;
  onlyUserTables?: InputMaybe<Scalars['Boolean']>;
  tableNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Table List Response */
export type SystemTableListResponse = {
  __typename?: 'SystemTableListResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<SystemTable>>>;
};

/** Table Schema Create Features */
export type SystemTableSchemaCreateFeatures = {
  __typename?: 'SystemTableSchemaCreateFeatures';
  DATE: Scalars['Boolean'];
  FILE: Scalars['Boolean'];
  GEO: Scalars['Boolean'];
  ID: Scalars['Boolean'];
  JSON: Scalars['Boolean'];
  MISSING_RELATION: Scalars['Boolean'];
  NUMBER: Scalars['Boolean'];
  ONE_WAY_RELATION: Scalars['Boolean'];
  RELATION: Scalars['Boolean'];
  SMART: Scalars['Boolean'];
  SWITCH: Scalars['Boolean'];
  TEXT: Scalars['Boolean'];
  UUID: Scalars['Boolean'];
};

/** Table Schema Features */
export type SystemTableSchemaFeatures = {
  __typename?: 'SystemTableSchemaFeatures';
  computedFields: Scalars['Boolean'];
  create: SystemTableSchemaCreateFeatures;
  update?: Maybe<SystemTableSchemaMetaFieldFeatures>;
};

/** Table Schema Meta Field Features */
export type SystemTableSchemaMetaFieldFeatures = {
  __typename?: 'SystemTableSchemaMetaFieldFeatures';
  displayName: Scalars['Boolean'];
  name: Scalars['Boolean'];
};

/** Table Update Input */
export type SystemTableUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

/** Team Invitation Details */
export type SystemTeamInvitationDetails = {
  __typename?: 'SystemTeamInvitationDetails';
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  apiHost?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<SystemInvitedByName>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  resentOn?: Maybe<Scalars['DateTime']>;
};

export type SystemTechnicalDesignResponse = {
  __typename?: 'SystemTechnicalDesignResponse';
  baaSProjectId?: Maybe<Scalars['String']>;
  customFunctions?: Maybe<Scalars['JSON']>;
  dataModel?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
};

/** Text Field Attributes */
export type SystemTextFieldTypeAttributes = {
  __typename?: 'SystemTextFieldTypeAttributes';
  fieldSize?: Maybe<Scalars['Int']>;
  format: Scalars['String'];
};

/** Text Type Format Enum */
export type SystemTextTypeFormatEnum =
  | 'EIN'
  | 'EMAIL'
  | 'HTML'
  | 'MARKDOWN'
  | 'NAME'
  | 'UNFORMATTED';

export type SystemTransferIdeaOwnerMutationInput = {
  email: Scalars['String'];
  ideaId: Scalars['ID'];
};

/** UUID Field Attributes */
export type SystemUuidFieldTypeAttributes = {
  __typename?: 'SystemUUIDFieldTypeAttributes';
  fieldSize?: Maybe<Scalars['Int']>;
};

export type SystemUserBillingConfigurationResponse = {
  __typename?: 'SystemUserBillingConfigurationResponse';
  availablePlans: Array<SystemBillingPlanBaseInfo>;
  /** @deprecated Flag is deprecated */
  isCancelSubscriptionAvailable: Scalars['Boolean'];
  /** @deprecated Flag is deprecated */
  isFreePlanAvailable: Scalars['Boolean'];
};

/** User Invitation Details */
export type SystemUserInvitationDetails = {
  __typename?: 'SystemUserInvitationDetails';
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  apiHost?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<SystemInvitedByName>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  resentOn?: Maybe<Scalars['DateTime']>;
};

/** User Invitation List */
export type SystemUserInvitationList = {
  __typename?: 'SystemUserInvitationList';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<SystemUserInvitationDetails>>>;
};

/** User Type */
export type SystemUserType = 'Agency' | 'Corporation' | 'MyOwn' | 'Team';

/** View Attributes */
export type SystemViewAttributes = {
  __typename?: 'SystemViewAttributes';
  query?: Maybe<Scalars['String']>;
};

/** View Create Input */
export type SystemViewCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  query: Scalars['String'];
};

/** Dry Run Response for previewing SQL Views */
export type SystemViewDryRunOutput = {
  __typename?: 'SystemViewDryRunOutput';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Scalars['JSON']>;
  timeMs?: Maybe<Scalars['Int']>;
};

/** View Update Input */
export type SystemViewUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};

/** WorkspaceCreateMutationInput */
export type SystemWorkspaceCreateMutationInput = {
  authType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  howStart?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<GraphQlCreateFileItemInput>;
  kind?: InputMaybe<SystemWorkspaceKind>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
  profileId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['ID']>;
};

export type SystemWorkspaceCreateResponse = {
  __typename?: 'SystemWorkspaceCreateResponse';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** WorkspaceDeleteMutationInput */
export type SystemWorkspaceDeleteMutationInput = {
  workspaceId: Scalars['ID'];
};

export type SystemWorkspaceImage = {
  __typename?: 'SystemWorkspaceImage';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type SystemWorkspaceItem = {
  __typename?: 'SystemWorkspaceItem';
  apiHost?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemWorkspaceImage>;
  is8BaseAuthEnabled?: Maybe<Scalars['Boolean']>;
  isCiCdEnabled?: Maybe<Scalars['Boolean']>;
  isOwner: Scalars['Boolean'];
  kind?: Maybe<Scalars['String']>;
  lastAccess?: Maybe<Scalars['DateTime']>;
  lastDeployABInfo?: Maybe<SystemDeploymentAbItemResponse>;
  name: Scalars['String'];
  nextPlan?: Maybe<SystemBillingNextPlanResponse>;
  organization?: Maybe<SystemOrganizationBaseItem>;
  owner?: Maybe<SystemMemberAccountInfo>;
  plan?: Maybe<SystemBillingCurrentPlanResponse>;
  project?: Maybe<SystemProjectItemWs>;
  region?: Maybe<Scalars['String']>;
  summaryABInfo?: Maybe<SystemFrontendUtilizationAbResponse>;
  teamMemberCount?: Maybe<Scalars['Int']>;
  webSocket?: Maybe<Scalars['String']>;
};

/** Workspace Kind */
export type SystemWorkspaceKind = 'frontend' | 'general';

/** SystemWorkspaceListResponse output */
export type SystemWorkspaceListResponse = {
  __typename?: 'SystemWorkspaceListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemWorkspaceItem>;
};

export type SystemWorkspaceStatus =
  | 'active'
  | 'blocked'
  | 'canceled'
  | 'canceling'
  | 'pending'
  | 'suspended';

/** WorkspaceUpdateMutationInput */
export type SystemWorkspaceUpdateMutationInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: InputMaybe<GraphQlCreateFileItemInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type SystemWorkspaceUpdateResponse = {
  __typename?: 'SystemWorkspaceUpdateResponse';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<GraphQlFileItemResponse>;
  name?: Maybe<Scalars['String']>;
};

/** Table */
export type Table = {
  __typename?: 'Table';
  application?: Maybe<Application>;
  attributes?: Maybe<TableAttributes>;
  dataFeatures: TableDataFeatures;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<TableField>>;
  fieldsForIndexing?: Maybe<Array<Maybe<TableField>>>;
  id: Scalars['ID'];
  indexes?: Maybe<Array<TableIndex>>;
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  origin: SchemaOrigin;
  schemaFeatures: TableSchemaFeatures;
};

/** Table Attributes */
export type TableAttributes = ViewAttributes;

/** Table Create Input */
export type TableCreateInput = {
  applicationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Table Data Features */
export type TableDataFeatures = {
  __typename?: 'TableDataFeatures';
  create: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** Table Delete Input */
export type TableDeleteInput = {
  id: Scalars['ID'];
};

/** TableField */
export type TableField = {
  __typename?: 'TableField';
  computedMode?: Maybe<ComputedFieldMode>;
  dataFeatures: FieldDataFeatures;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  fieldType: FieldType;
  fieldTypeAttributes?: Maybe<FieldTypeAttributes>;
  id: Scalars['ID'];
  isList: Scalars['Boolean'];
  isMeta: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isSystem: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  origin: SchemaOrigin;
  relation?: Maybe<Relation>;
  schemaFeatures: FieldSchemaFeatures;
  table: Table;
};

/** Table Field Create Input */
export type TableFieldCreateInput = {
  computedMode?: InputMaybe<ComputedFieldMode>;
  defaultValue?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<Scalars['String']>;
  fieldType: FieldType;
  fieldTypeAttributes?: InputMaybe<FieldTypeAttributesInput>;
  force?: InputMaybe<Scalars['Boolean']>;
  initialValue?: InputMaybe<Scalars['String']>;
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  position?: InputMaybe<Scalars['Int']>;
  relation?: InputMaybe<RelationCreateInput>;
  tableId: Scalars['ID'];
};

/** Table Field Delete Input */
export type TableFieldDeleteInput = {
  id: Scalars['ID'];
};

/** Table Field Position Update Input */
export type TableFieldPositionUpdateInput = {
  id: Scalars['ID'];
  newPosition: Scalars['Int'];
};

/** Table Field Update Input */
export type TableFieldUpdateInput = {
  computedMode?: InputMaybe<ComputedFieldMode>;
  defaultValue?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<Scalars['String']>;
  fieldType?: InputMaybe<FieldType>;
  fieldTypeAttributes?: InputMaybe<FieldTypeAttributesInput>;
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  initialValue?: InputMaybe<Scalars['String']>;
  isList?: InputMaybe<Scalars['Boolean']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  isUnique?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  relation?: InputMaybe<RelationUpdateInput>;
};

/** Table Index */
export type TableIndex = {
  __typename?: 'TableIndex';
  columns?: Maybe<Array<TableIndexColumn>>;
  id: Scalars['ID'];
  isSystem: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  table: Table;
  type: Scalars['String'];
};

/** Table Index Column */
export type TableIndexColumn = {
  __typename?: 'TableIndexColumn';
  name: Scalars['String'];
};

/** Table Index Column Input */
export type TableIndexColumnInput = {
  name: Scalars['String'];
};

export type TableIndexType = 'INDEX' | 'UNIQUE';

/** Table List Application Filter */
export type TableListApplicationFilter = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Table List Filter */
export type TableListFilter = {
  applications?: InputMaybe<Array<InputMaybe<TableListApplicationFilter>>>;
  onlyUserTables?: InputMaybe<Scalars['Boolean']>;
  tableNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Table List Response */
export type TableListResponse = {
  __typename?: 'TableListResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Table>>>;
};

/** Table Schema Create Features */
export type TableSchemaCreateFeatures = {
  __typename?: 'TableSchemaCreateFeatures';
  DATE: Scalars['Boolean'];
  FILE: Scalars['Boolean'];
  GEO: Scalars['Boolean'];
  ID: Scalars['Boolean'];
  JSON: Scalars['Boolean'];
  MISSING_RELATION: Scalars['Boolean'];
  NUMBER: Scalars['Boolean'];
  ONE_WAY_RELATION: Scalars['Boolean'];
  RELATION: Scalars['Boolean'];
  SMART: Scalars['Boolean'];
  SWITCH: Scalars['Boolean'];
  TEXT: Scalars['Boolean'];
  UUID: Scalars['Boolean'];
};

/** Table Schema Features */
export type TableSchemaFeatures = {
  __typename?: 'TableSchemaFeatures';
  computedFields: Scalars['Boolean'];
  create: TableSchemaCreateFeatures;
  update?: Maybe<TableSchemaMetaFieldFeatures>;
};

/** Table Schema Meta Field Features */
export type TableSchemaMetaFieldFeatures = {
  __typename?: 'TableSchemaMetaFieldFeatures';
  displayName: Scalars['Boolean'];
  name: Scalars['Boolean'];
};

/** Table Update Input */
export type TableUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type TeamInvitation = {
  __typename?: 'TeamInvitation';
  _description?: Maybe<Scalars['String']>;
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  invitedBy?: Maybe<TeamMember>;
  invitee?: Maybe<TeamMember>;
  lastName?: Maybe<Scalars['String']>;
  resentOn?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uuid?: Maybe<Scalars['String']>;
};

/** TeamInvitations delete input */
export type TeamInvitationDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** TeamInvitationFieldsPermissions create input */
export type TeamInvitationFieldsPermissions = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  acceptedOn?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['Boolean']>;
  resentOn?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  uuid?: InputMaybe<Scalars['Boolean']>;
};

export type TeamInvitationFilter = {
  AND?: InputMaybe<Array<TeamInvitationFilter>>;
  OR?: InputMaybe<Array<TeamInvitationFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  accepted?: InputMaybe<BoolPredicate>;
  acceptedOn?: InputMaybe<DateTimePredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  email?: InputMaybe<StringPredicate>;
  firstName?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  invitedBy?: InputMaybe<TeamMemberFilter>;
  invitee?: InputMaybe<TeamMemberFilter>;
  lastName?: InputMaybe<StringPredicate>;
  resentOn?: InputMaybe<DateTimePredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  uuid?: InputMaybe<StringPredicate>;
};

export type TeamInvitationGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: TeamInvitationGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type TeamInvitationGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  accepted?: InputMaybe<Array<GroupByField>>;
  acceptedOn?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  email?: InputMaybe<Array<GroupByField>>;
  firstName?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  invitedBy?: InputMaybe<TeamMemberGroupByQuery>;
  invitee?: InputMaybe<TeamMemberGroupByQuery>;
  lastName?: InputMaybe<Array<GroupByField>>;
  resentOn?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  uuid?: InputMaybe<Array<GroupByField>>;
};

export type TeamInvitationKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/** TeamInvitationListResponse output */
export type TeamInvitationListResponse = {
  __typename?: 'TeamInvitationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<TeamInvitation>;
};

/** No longer supported. Use `sort` instead. */
export type TeamInvitationOrderBy =
  | 'acceptedOn_ASC'
  | 'acceptedOn_DESC'
  | 'accepted_ASC'
  | 'accepted_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'email_ASC'
  | 'email_DESC'
  | 'firstName_ASC'
  | 'firstName_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'lastName_ASC'
  | 'lastName_DESC'
  | 'resentOn_ASC'
  | 'resentOn_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'uuid_ASC'
  | 'uuid_DESC';

/** TeamInvitations subscription payload */
export type TeamInvitationPayload = {
  __typename?: 'TeamInvitationPayload';
  mutation: MutationType;
  node?: Maybe<TeamInvitation>;
  previousValues?: Maybe<TeamInvitation>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TeamInvitationRelationFilter = {
  every?: InputMaybe<TeamInvitationFilter>;
  none?: InputMaybe<TeamInvitationFilter>;
  some?: InputMaybe<TeamInvitationFilter>;
};

export type TeamInvitationSort = {
  accepted?: InputMaybe<SortOrder>;
  acceptedOn?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invitedBy?: InputMaybe<TeamMemberSort>;
  invitee?: InputMaybe<TeamMemberSort>;
  lastName?: InputMaybe<SortOrder>;
  resentOn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
};

/** TeamInvitations subscription filter */
export type TeamInvitationSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<TeamInvitationFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

export type TeamInvitation_PermissionFilter = {
  AND?: InputMaybe<Array<TeamInvitation_PermissionFilter>>;
  OR?: InputMaybe<Array<TeamInvitation_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  accepted?: InputMaybe<BoolPredicate>;
  acceptedOn?: InputMaybe<DateTimePredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  email?: InputMaybe<StringPredicate>;
  firstName?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  invitedBy?: InputMaybe<TeamMember_PermissionFilter>;
  invitee?: InputMaybe<TeamMember_PermissionFilter>;
  lastName?: InputMaybe<StringPredicate>;
  resentOn?: InputMaybe<DateTimePredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  uuid?: InputMaybe<StringPredicate>;
};

export type TeamInvitation_PermissionRelationFilter = {
  every?: InputMaybe<TeamInvitation_PermissionFilter>;
  none?: InputMaybe<TeamInvitation_PermissionFilter>;
  some?: InputMaybe<TeamInvitation_PermissionFilter>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  _description?: Maybe<Scalars['String']>;
  avatar?: Maybe<File>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  receivedTeamInvitations?: Maybe<TeamInvitationListResponse>;
  roles?: Maybe<RoleListResponse>;
  sentTeamInvitations?: Maybe<TeamInvitationListResponse>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type TeamMemberReceivedTeamInvitationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TeamInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<TeamInvitationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamInvitationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TeamInvitationSort>>;
};

export type TeamMemberRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleSort>>;
};

export type TeamMemberSentTeamInvitationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TeamInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<TeamInvitationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamInvitationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TeamInvitationSort>>;
};

/** TeamMembers delete input */
export type TeamMemberDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** TeamMemberFieldsPermissions create input */
export type TeamMemberFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type TeamMemberFilter = {
  AND?: InputMaybe<Array<TeamMemberFilter>>;
  OR?: InputMaybe<Array<TeamMemberFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<FileFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isOwner?: InputMaybe<BoolPredicate>;
  receivedTeamInvitations?: InputMaybe<TeamInvitationRelationFilter>;
  roles?: InputMaybe<RoleRelationFilter>;
  sentTeamInvitations?: InputMaybe<TeamInvitationRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type TeamMemberGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: TeamMemberGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type TeamMemberGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  avatar?: InputMaybe<FileGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isOwner?: InputMaybe<Array<GroupByField>>;
  receivedTeamInvitations?: InputMaybe<TeamInvitationGroupByQuery>;
  roles?: InputMaybe<RoleGroupByQuery>;
  sentTeamInvitations?: InputMaybe<TeamInvitationGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type TeamMemberKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** TeamMemberListResponse output */
export type TeamMemberListResponse = {
  __typename?: 'TeamMemberListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<TeamMember>;
};

/** TeamMemberManyResponse output */
export type TeamMemberManyResponse = {
  __typename?: 'TeamMemberManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<TeamMember>;
};

/** No longer supported. Use `sort` instead. */
export type TeamMemberOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isOwner_ASC'
  | 'isOwner_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** TeamMembers subscription payload */
export type TeamMemberPayload = {
  __typename?: 'TeamMemberPayload';
  mutation: MutationType;
  node?: Maybe<TeamMember>;
  previousValues?: Maybe<TeamMember>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TeamMemberRelationFilter = {
  every?: InputMaybe<TeamMemberFilter>;
  none?: InputMaybe<TeamMemberFilter>;
  some?: InputMaybe<TeamMemberFilter>;
};

export type TeamMemberSort = {
  avatar?: InputMaybe<FileSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isOwner?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

/** TeamMembers subscription filter */
export type TeamMemberSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<TeamMemberFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** TeamMembers update input */
export type TeamMemberUpdateByFilterInput = {
  isOwner?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
};

/** TeamMembers update input */
export type TeamMemberUpdateInput = {
  avatar?: InputMaybe<TeamMembersAvatarUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  receivedTeamInvitations?: InputMaybe<TeamMembersReceivedTeamInvitationsUpdateRelationInput>;
  roles?: InputMaybe<TeamMembersRolesUpdateRelationInput>;
  sentTeamInvitations?: InputMaybe<TeamMembersSentTeamInvitationsUpdateRelationInput>;
};

export type TeamMember_PermissionFilter = {
  AND?: InputMaybe<Array<TeamMember_PermissionFilter>>;
  OR?: InputMaybe<Array<TeamMember_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<File_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isOwner?: InputMaybe<BoolPredicate>;
  receivedTeamInvitations?: InputMaybe<TeamInvitation_PermissionRelationFilter>;
  roles?: InputMaybe<Role_PermissionRelationFilter>;
  sentTeamInvitations?: InputMaybe<TeamInvitation_PermissionRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
};

export type TeamMember_PermissionRelationFilter = {
  every?: InputMaybe<TeamMember_PermissionFilter>;
  none?: InputMaybe<TeamMember_PermissionFilter>;
  some?: InputMaybe<TeamMember_PermissionFilter>;
};

/** TeamMembers relation input */
export type TeamMembersAvatarUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<TeamMembers_Avatar_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<TeamMembers_Avatar_FileUpdateInput>;
};

/** TeamMembers relation input */
export type TeamMembersReceivedTeamInvitationsUpdateRelationInput = {
  connect?: InputMaybe<Array<TeamInvitationKeyFilter>>;
  disconnect?: InputMaybe<Array<TeamInvitationKeyFilter>>;
  reconnect?: InputMaybe<Array<TeamInvitationKeyFilter>>;
};

/** TeamMembers relation input */
export type TeamMembersRolesUpdateRelationInput = {
  connect?: InputMaybe<Array<RoleKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<TeamMembers_RoleCreateInput>>>;
  disconnect?: InputMaybe<Array<RoleKeyFilter>>;
  reconnect?: InputMaybe<Array<RoleKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<TeamMembers_RoleUpdateInput>>>;
};

/** TeamMembers relation input */
export type TeamMembersSentTeamInvitationsUpdateRelationInput = {
  connect?: InputMaybe<Array<TeamInvitationKeyFilter>>;
  disconnect?: InputMaybe<Array<TeamInvitationKeyFilter>>;
  reconnect?: InputMaybe<Array<TeamInvitationKeyFilter>>;
};

/** Roles create input from teamMembers */
export type TeamMembers_RoleCreateInput = {
  addon?: InputMaybe<RolesAddonRelationInput>;
  apiTokens?: InputMaybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: InputMaybe<RolesAuthenticationProfilesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notificationTemplates?: InputMaybe<RolesNotificationTemplatesRelationInput>;
  permissions?: InputMaybe<PermissionsInput>;
  teamMembers?: InputMaybe<RolesTeamMembersRelationInput>;
  users?: InputMaybe<RolesUsersRelationInput>;
};

/** Roles update input from teamMembers */
export type TeamMembers_RoleUpdateInput = {
  data: RoleUpdateInput;
  filter?: InputMaybe<RoleKeyFilter>;
};

/** Files create input from teamMembers_avatar */
export type TeamMembers_Avatar_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from teamMembers_avatar */
export type TeamMembers_Avatar_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** UserNotifications create input from template */
export type Template_UserNotificationCreateInput = {
  /**
   * Set "true" for notifications that should be deleted (e.g. on trade confirm)
   * Need this to get rid of templateKey filter
   */
  deletable?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<UserNotificationsImageRelationInput>;
  /**
   * Optional notification params
   *
   * params: Record<string, any>
   */
  infoJSON?: InputMaybe<Scalars['JSON']>;
  /** Contains the related item id, used to group notifications, could be post id, group id, profile id, etc */
  relatedItemID?: InputMaybe<Scalars['String']>;
  sender?: InputMaybe<UserNotificationsSenderRelationInput>;
  template?: InputMaybe<UserNotificationsTemplateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UserNotificationsUserNotificationsByUsersRelationInput>;
};

/** UserNotifications update input from template */
export type Template_UserNotificationUpdateInput = {
  data: UserNotificationUpdateInput;
  filter?: InputMaybe<UserNotificationKeyFilter>;
};

/** Text Field Attributes */
export type TextFieldTypeAttributes = {
  __typename?: 'TextFieldTypeAttributes';
  fieldSize?: Maybe<Scalars['Int']>;
  format: Scalars['String'];
};

/** Text Type Format Enum */
export type TextTypeFormatEnum = 'EIN' | 'EMAIL' | 'HTML' | 'MARKDOWN' | 'NAME' | 'UNFORMATTED';

/** Events create input from titleSponsor */
export type TitleSponsor_EventCreateInput = {
  author?: InputMaybe<EventsAuthorRelationInput>;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<EventsImagesRelationInput>;
  /**
   * Determines whether to show the event to Community Members
   * Changes via a trigger on Status field
   */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<EventsLocationRelationInput>;
  otherSponsors?: InputMaybe<EventsOtherSponsorsRelationInput>;
  school?: InputMaybe<EventsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  sponsorRequests?: InputMaybe<EventsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleSponsor?: InputMaybe<EventsTitleSponsorRelationInput>;
};

/** Events update input from titleSponsor */
export type TitleSponsor_EventUpdateInput = {
  data: EventUpdateInput;
  filter?: InputMaybe<EventKeyFilter>;
};

/** SchoolConfiguration create input from topBar */
export type TopBar_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address: SchoolConfigurationAddressRelationInput;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from topBar */
export type TopBar_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

export type TrendingSearch = {
  __typename?: 'TrendingSearch';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  searchesCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wordOrPhrase?: Maybe<Scalars['String']>;
};

/** TrendingSearches create input */
export type TrendingSearchCreateInput = {
  school?: InputMaybe<TrendingSearchesSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  searchesCount?: InputMaybe<Scalars['Int']>;
  wordOrPhrase: Scalars['String'];
};

/** TrendingSearches create many input */
export type TrendingSearchCreateManyInput = {
  school?: InputMaybe<TrendingSearchesSchoolManyRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  searchesCount?: InputMaybe<Scalars['Int']>;
  wordOrPhrase: Scalars['String'];
};

/** TrendingSearches delete input */
export type TrendingSearchDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** TrendingSearchFieldsPermissions create input */
export type TrendingSearchFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  searchesCount?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  wordOrPhrase?: InputMaybe<Scalars['Boolean']>;
};

export type TrendingSearchFilter = {
  AND?: InputMaybe<Array<TrendingSearchFilter>>;
  OR?: InputMaybe<Array<TrendingSearchFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  searchesCount?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  wordOrPhrase?: InputMaybe<StringPredicate>;
};

export type TrendingSearchGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: TrendingSearchGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type TrendingSearchGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  searchesCount?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  wordOrPhrase?: InputMaybe<Array<GroupByField>>;
};

export type TrendingSearchKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
  wordOrPhrase?: InputMaybe<Scalars['String']>;
};

/** TrendingSearchListResponse output */
export type TrendingSearchListResponse = {
  __typename?: 'TrendingSearchListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<TrendingSearch>;
};

/** TrendingSearchManyResponse output */
export type TrendingSearchManyResponse = {
  __typename?: 'TrendingSearchManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<TrendingSearch>;
};

/** No longer supported. Use `sort` instead. */
export type TrendingSearchOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'searchesCount_ASC'
  | 'searchesCount_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'wordOrPhrase_ASC'
  | 'wordOrPhrase_DESC';

/** TrendingSearches subscription payload */
export type TrendingSearchPayload = {
  __typename?: 'TrendingSearchPayload';
  mutation: MutationType;
  node?: Maybe<TrendingSearch>;
  previousValues?: Maybe<TrendingSearch>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TrendingSearchRelationFilter = {
  every?: InputMaybe<TrendingSearchFilter>;
  none?: InputMaybe<TrendingSearchFilter>;
  some?: InputMaybe<TrendingSearchFilter>;
};

export type TrendingSearchSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  searchesCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  wordOrPhrase?: InputMaybe<SortOrder>;
};

/** TrendingSearches subscription filter */
export type TrendingSearchSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<TrendingSearchFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** TrendingSearches update input */
export type TrendingSearchUpdateByFilterInput = {
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  searchesCount?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  wordOrPhrase?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** TrendingSearches update input */
export type TrendingSearchUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  school?: InputMaybe<TrendingSearchesSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  searchesCount?: InputMaybe<Scalars['Int']>;
  wordOrPhrase?: InputMaybe<Scalars['String']>;
};

export type TrendingSearch_PermissionFilter = {
  AND?: InputMaybe<Array<TrendingSearch_PermissionFilter>>;
  OR?: InputMaybe<Array<TrendingSearch_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  searchesCount?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  wordOrPhrase?: InputMaybe<StringPredicate>;
};

export type TrendingSearch_PermissionRelationFilter = {
  every?: InputMaybe<TrendingSearch_PermissionFilter>;
  none?: InputMaybe<TrendingSearch_PermissionFilter>;
  some?: InputMaybe<TrendingSearch_PermissionFilter>;
};

/** TrendingSearches relation input */
export type TrendingSearchesSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** TrendingSearches relation input */
export type TrendingSearchesSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<TrendingSearches_SchoolConfigurationCreateInput>;
};

/** TrendingSearches relation input */
export type TrendingSearchesSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<TrendingSearches_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<TrendingSearches_SchoolConfigurationUpdateInput>;
};

/** SchoolConfiguration create input from trendingSearches */
export type TrendingSearches_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from trendingSearches */
export type TrendingSearches_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

export type TrimFunctionArguments = {
  mode?: InputMaybe<StringTrimMode>;
  str: Scalars['String'];
};

/** UUID Field Attributes */
export type UuidFieldTypeAttributes = {
  __typename?: 'UUIDFieldTypeAttributes';
  fieldSize?: Maybe<Scalars['Int']>;
};

export type UpdateByFilterBooleanSwitchInput = {
  invert?: InputMaybe<Scalars['Boolean']>;
  set?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateByFilterDateInput = {
  add?: InputMaybe<UpdateByFilterDatePartsInput>;
  set?: InputMaybe<Scalars['String']>;
  sub?: InputMaybe<UpdateByFilterDatePartsInput>;
};

export type UpdateByFilterDatePartsInput = {
  days?: InputMaybe<Scalars['Int']>;
  months?: InputMaybe<Scalars['Int']>;
  years?: InputMaybe<Scalars['Int']>;
};

export type UpdateByFilterDateTimeInput = {
  add?: InputMaybe<UpdateByFilterDateTimePartsInput>;
  set?: InputMaybe<Scalars['String']>;
  sub?: InputMaybe<UpdateByFilterDateTimePartsInput>;
};

export type UpdateByFilterDateTimePartsInput = {
  days?: InputMaybe<Scalars['Int']>;
  hours?: InputMaybe<Scalars['Int']>;
  microseconds?: InputMaybe<Scalars['Int']>;
  minutes?: InputMaybe<Scalars['Int']>;
  months?: InputMaybe<Scalars['Int']>;
  seconds?: InputMaybe<Scalars['Int']>;
  years?: InputMaybe<Scalars['Int']>;
};

export type UpdateByFilterFloatInput = {
  add?: InputMaybe<Scalars['Float']>;
  div?: InputMaybe<Scalars['Float']>;
  mod?: InputMaybe<Scalars['Float']>;
  mult?: InputMaybe<Scalars['Float']>;
  pow?: InputMaybe<Scalars['Float']>;
  prec?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Float']>;
  sqrt?: InputMaybe<Scalars['Boolean']>;
  sub?: InputMaybe<Scalars['Float']>;
};

export type UpdateByFilterIntInput = {
  add?: InputMaybe<Scalars['Int']>;
  div?: InputMaybe<Scalars['Int']>;
  mod?: InputMaybe<Scalars['Int']>;
  mult?: InputMaybe<Scalars['Int']>;
  pow?: InputMaybe<Scalars['Int']>;
  prec?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
  sqrt?: InputMaybe<Scalars['Boolean']>;
  sub?: InputMaybe<Scalars['Int']>;
};

export type UpdateByFilterJsonInput = {
  set?: InputMaybe<Scalars['JSON']>;
};

export type UpdateByFilterListIntInput = {
  insert?: InputMaybe<UpdateByFilterListIntInsertOperationInput>;
  push?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  remove?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  set?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  swap?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  unshift?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type UpdateByFilterListIntInsertOperationInput = {
  start: Scalars['Int'];
  values: Array<Scalars['Int']>;
};

export type UpdateByFilterListStringInput = {
  insert?: InputMaybe<UpdateByFilterListStringInsertOperationInput>;
  push?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  remove?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  removeValue?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  swap?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  unshift?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateByFilterListStringInsertOperationInput = {
  start: Scalars['Int'];
  values: Array<Scalars['String']>;
};

export type UpdateByFilterStringInput = {
  postfix?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Scalars['String']>;
};

export type UpdateByFilterStringSwitchInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type UpdateHomeFeedPostRequestInput = {
  commentsAllowed?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isPinned?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<HomeFeedPostsMediaUpdateRelationInput>;
  mentions?: InputMaybe<HomeFeedPostsMentionsUpdateRelationInput>;
  reportStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

export type UpdateHomeFeedPostResponse = {
  __typename?: 'UpdateHomeFeedPostResponse';
  success: Scalars['Boolean'];
  verified: Scalars['Boolean'];
};

export type UpdatePostResponse = {
  __typename?: 'UpdatePostResponse';
  data?: Maybe<Scalars['JSON']>;
  successful: Scalars['Boolean'];
};

/** UpdatedFieldsFilter */
export type UpdatedFieldsFilter = {
  contains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  every?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadcareSignInfoResponse = {
  __typename?: 'UploadcareSignInfoResponse';
  expire: Scalars['String'];
  publicKey: Scalars['String'];
  signature: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _description?: Maybe<Scalars['String']>;
  activityClubs?: Maybe<Array<Maybe<Scalars['String']>>>;
  adClicks?: Maybe<AdClickListResponse>;
  adLikes?: Maybe<AdvertisingCampaignAdListResponse>;
  adViews?: Maybe<AdViewListResponse>;
  affiliation?: Maybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: Maybe<Scalars['String']>;
  avatar?: Maybe<File>;
  birthDate?: Maybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: Maybe<CollectiveIntelligenceThreadListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdGroups?: Maybe<GroupListResponse>;
  createdJobOffers?: Maybe<JobOfferListResponse>;
  createdSchoolNewsPosts?: Maybe<SchoolNewsPostListResponse>;
  currentIndustry?: Maybe<Scalars['String']>;
  deleteInboxMessages?: Maybe<InboxMessageListResponse>;
  deletedAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  discountRedemptions?: Maybe<DiscountRedemptionListResponse>;
  education?: Maybe<EducationalInstitutionListResponse>;
  email?: Maybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  flaggedGroupPosts?: Maybe<GroupPostListResponse>;
  flaggedHomeFeedPosts?: Maybe<HomeFeedPostListResponse>;
  flaggedLegacyFeedPosts?: Maybe<LegacyFeedPostListResponse>;
  flaggedSchoolNewsPosts?: Maybe<SchoolNewsPostListResponse>;
  /** Concat between "firstName" and "lastName" */
  fullName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  graduatingYear?: Maybe<Scalars['Date']>;
  groupAdminPreferences?: Maybe<GroupAdminPreference>;
  groupPosts?: Maybe<GroupPostListResponse>;
  hiddenInboxMessages?: Maybe<InboxMessageListResponse>;
  hiddenInboxes?: Maybe<InboxListResponse>;
  hobbies?: Maybe<Array<Maybe<Scalars['String']>>>;
  homeFeedPosts?: Maybe<HomeFeedPostListResponse>;
  id?: Maybe<Scalars['ID']>;
  inboxes?: Maybe<InboxListResponse>;
  /** Instagram URL of the user. */
  instagramLink?: Maybe<Scalars['JSON']>;
  is8base?: Maybe<Scalars['Boolean']>;
  isFirstAdLogin?: Maybe<Scalars['Boolean']>;
  isFirstSwitching?: Maybe<Scalars['Boolean']>;
  isNewToAdvertising?: Maybe<Scalars['Boolean']>;
  isSeekingEmployment?: Maybe<Scalars['Boolean']>;
  jobExperience?: Maybe<JobEmployeeListResponse>;
  joinedGroups?: Maybe<GroupListResponse>;
  lastFlaggedGroupPost?: Maybe<GroupPost>;
  lastFlaggedHomePost?: Maybe<HomeFeedPost>;
  lastFlaggedLegacyPost?: Maybe<LegacyFeedPost>;
  lastFlaggedSchoolPost?: Maybe<SchoolNewsPost>;
  lastName?: Maybe<Scalars['String']>;
  legacyFeedPosts?: Maybe<LegacyFeedPostListResponse>;
  likedOrganizations?: Maybe<OrganizationLikeListResponse>;
  /** LinkedIn URL of the user. */
  linkedInLink?: Maybe<Scalars['JSON']>;
  managerPreferences?: Maybe<OrganizationManagerPreferenceListResponse>;
  origin?: Maybe<Scalars['String']>;
  ownedOrganizations?: Maybe<OrganizationListResponse>;
  parents?: Maybe<Scalars['JSON']>;
  permissions?: Maybe<UserPermissionList>;
  postedEvents?: Maybe<EventListResponse>;
  professionalDesignations?: Maybe<Scalars['String']>;
  redeemedDiscounts?: Maybe<DiscountListResponse>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<RoleListResponse>;
  rolesAddons?: Maybe<RoleAddonListResponse>;
  schoolMemberships?: Maybe<UserSchoolMembershipListResponse>;
  sentMessages?: Maybe<InboxMessageListResponse>;
  sentNotifications?: Maybe<UserNotificationListResponse>;
  status?: Maybe<Scalars['String']>;
  supporterClicks?: Maybe<SchoolSupporterClickListResponse>;
  supporterSearches?: Maybe<SchoolSupporterSearchListResponse>;
  timezone?: Maybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userConfiguration?: Maybe<UserConfiguration>;
  userJoining?: Maybe<GroupsMembersRequestListResponse>;
  userNotificationsByUsers?: Maybe<UserNotificationsByUserListResponse>;
  userPreferences?: Maybe<UserPreference>;
  viewedThreads?: Maybe<CollectiveIntelligenceThreadListResponse>;
};

export type UserAdClicksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdClickFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdClickGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdClickOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdClickSort>>;
};

export type UserAdLikesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdvertisingCampaignAdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdvertisingCampaignAdGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdvertisingCampaignAdOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdvertisingCampaignAdSort>>;
};

export type UserAdViewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AdViewFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<AdViewGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdViewOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AdViewSort>>;
};

export type UserCollectiveIntelligenceThreadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CollectiveIntelligenceThreadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<CollectiveIntelligenceThreadGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<CollectiveIntelligenceThreadOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<CollectiveIntelligenceThreadSort>>;
};

export type UserCreatedGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupSort>>;
};

export type UserCreatedJobOffersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<JobOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<JobOfferGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<JobOfferOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<JobOfferSort>>;
};

export type UserCreatedSchoolNewsPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolNewsPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolNewsPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolNewsPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolNewsPostSort>>;
};

export type UserDeleteInboxMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxMessageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxMessageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxMessageSort>>;
};

export type UserDiscountRedemptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DiscountRedemptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DiscountRedemptionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DiscountRedemptionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DiscountRedemptionSort>>;
};

export type UserEducationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EducationalInstitutionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EducationalInstitutionGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EducationalInstitutionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EducationalInstitutionSort>>;
};

export type UserFlaggedGroupPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupPostSort>>;
};

export type UserFlaggedHomeFeedPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HomeFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<HomeFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<HomeFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HomeFeedPostSort>>;
};

export type UserFlaggedLegacyFeedPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedPostSort>>;
};

export type UserFlaggedSchoolNewsPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolNewsPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolNewsPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolNewsPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolNewsPostSort>>;
};

export type UserGroupPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupPostSort>>;
};

export type UserHiddenInboxMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxMessageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxMessageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxMessageSort>>;
};

export type UserHiddenInboxesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxSort>>;
};

export type UserHomeFeedPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HomeFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<HomeFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<HomeFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HomeFeedPostSort>>;
};

export type UserInboxesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxSort>>;
};

export type UserJobExperienceArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<JobEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<JobEmployeeGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<JobEmployeeOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<JobEmployeeSort>>;
};

export type UserJoinedGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupSort>>;
};

export type UserLegacyFeedPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LegacyFeedPostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<LegacyFeedPostGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<LegacyFeedPostOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedPostSort>>;
};

export type UserLikedOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationLikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationLikeGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationLikeOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationLikeSort>>;
};

export type UserManagerPreferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationManagerPreferenceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationManagerPreferenceGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationManagerPreferenceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationManagerPreferenceSort>>;
};

export type UserOwnedOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<OrganizationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationSort>>;
};

export type UserPermissionsArgs = {
  filter?: InputMaybe<PermissionInputFilter>;
};

export type UserPostedEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<EventGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<EventOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<EventSort>>;
};

export type UserRedeemedDiscountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DiscountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<DiscountGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<DiscountOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DiscountSort>>;
};

export type UserRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleSort>>;
};

export type UserRolesAddonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RoleAddonFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<RoleAddonGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<RoleAddonOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RoleAddonSort>>;
};

export type UserSchoolMembershipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserSchoolMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserSchoolMembershipGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserSchoolMembershipOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSchoolMembershipSort>>;
};

export type UserSentMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InboxMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<InboxMessageGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<InboxMessageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InboxMessageSort>>;
};

export type UserSentNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserNotificationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserNotificationGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserNotificationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserNotificationSort>>;
};

export type UserSupporterClicksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolSupporterClickFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolSupporterClickGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolSupporterClickOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolSupporterClickSort>>;
};

export type UserSupporterSearchesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SchoolSupporterSearchFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<SchoolSupporterSearchGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<SchoolSupporterSearchOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolSupporterSearchSort>>;
};

export type UserUserJoiningArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GroupsMembersRequestFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<GroupsMembersRequestGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<GroupsMembersRequestOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupsMembersRequestSort>>;
};

export type UserUserNotificationsByUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserNotificationsByUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserNotificationsByUserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserNotificationsByUserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserNotificationsByUserSort>>;
};

export type UserViewedThreadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CollectiveIntelligenceThreadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<CollectiveIntelligenceThreadGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<CollectiveIntelligenceThreadOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<CollectiveIntelligenceThreadSort>>;
};

export type UserAuthorizeInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UserAuthorizeResponse = {
  __typename?: 'UserAuthorizeResponse';
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type UserBillingConfigurationResponse = {
  __typename?: 'UserBillingConfigurationResponse';
  availablePlans: Array<BillingPlanBaseInfo>;
  /** @deprecated Flag is deprecated */
  isCancelSubscriptionAvailable: Scalars['Boolean'];
  /** @deprecated Flag is deprecated */
  isFreePlanAvailable: Scalars['Boolean'];
};

/** AdvertisingCampaignAds create input from userClicks */
export type UserClicks_AdvertisingCampaignAdCreateInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdRelationInput>;
  callToAction: Scalars['String'];
  callToActionLink: Scalars['String'];
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  primaryText: Scalars['String'];
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign?: InputMaybe<AdvertisingCampaignAdsSampaignRelationInput>;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget: Scalars['Float'];
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

/** AdvertisingCampaignAds update input from userClicks */
export type UserClicks_AdvertisingCampaignAdUpdateInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsUpdateRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdUpdateRelationInput>;
  callToAction?: InputMaybe<Scalars['String']>;
  callToActionLink?: InputMaybe<Scalars['String']>;
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageUpdateRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryText?: InputMaybe<Scalars['String']>;
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign?: InputMaybe<AdvertisingCampaignAdsSampaignUpdateRelationInput>;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget?: InputMaybe<Scalars['Float']>;
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksUpdateRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesUpdateRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsUpdateRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

/** SchoolSupporter create input from userClicks */
export type UserClicks_SchoolSupporterCreateInput = {
  billingRegisteredSchoolSupporter?: InputMaybe<SchoolSupporterBillingRegisteredSchoolSupporterRelationInput>;
  school?: InputMaybe<SchoolSupporterSchoolRelationInput>;
  startDate: Scalars['Date'];
  status: Scalars['String'];
  supporter?: InputMaybe<SchoolSupporterSupporterRelationInput>;
  userClicks?: InputMaybe<SchoolSupporterUserClicksRelationInput>;
  userSearches?: InputMaybe<SchoolSupporterUserSearchesRelationInput>;
};

/** SchoolSupporter update input from userClicks */
export type UserClicks_SchoolSupporterUpdateInput = {
  billingRegisteredSchoolSupporter?: InputMaybe<SchoolSupporterBillingRegisteredSchoolSupporterUpdateRelationInput>;
  school?: InputMaybe<SchoolSupporterSchoolUpdateRelationInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Scalars['String']>;
  supporter?: InputMaybe<SchoolSupporterSupporterUpdateRelationInput>;
  userClicks?: InputMaybe<SchoolSupporterUserClicksUpdateRelationInput>;
  userSearches?: InputMaybe<SchoolSupporterUserSearchesUpdateRelationInput>;
};

export type UserCompleteRegistrationInput = {
  accessCodeHash: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UserCompleteRegistrationResponse = {
  __typename?: 'UserCompleteRegistrationResponse';
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type UserConfiguration = {
  __typename?: 'UserConfiguration';
  _description?: Maybe<Scalars['String']>;
  /** Used when user registers */
  accessCodeHash?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  passwordResetCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

/** UserConfiguration create input */
export type UserConfigurationCreateInput = {
  /** Used when user registers */
  accessCodeHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserConfigurationUserRelationInput>;
};

/** UserConfiguration create many input */
export type UserConfigurationCreateManyInput = {
  /** Used when user registers */
  accessCodeHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserConfigurationUserManyRelationInput>;
};

/** UserConfiguration delete input */
export type UserConfigurationDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** UserConfigurationFieldsPermissions create input */
export type UserConfigurationFieldsPermissions = {
  accessCodeHash?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  passwordResetCode?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type UserConfigurationFilter = {
  AND?: InputMaybe<Array<UserConfigurationFilter>>;
  OR?: InputMaybe<Array<UserConfigurationFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  accessCodeHash?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  passwordResetCode?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type UserConfigurationGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: UserConfigurationGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type UserConfigurationGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  accessCodeHash?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  passwordResetCode?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type UserConfigurationKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** UserConfigurationListResponse output */
export type UserConfigurationListResponse = {
  __typename?: 'UserConfigurationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<UserConfiguration>;
};

/** UserConfigurationManyResponse output */
export type UserConfigurationManyResponse = {
  __typename?: 'UserConfigurationManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<UserConfiguration>;
};

/** No longer supported. Use `sort` instead. */
export type UserConfigurationOrderBy =
  | 'accessCodeHash_ASC'
  | 'accessCodeHash_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'passwordResetCode_ASC'
  | 'passwordResetCode_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** UserConfiguration subscription payload */
export type UserConfigurationPayload = {
  __typename?: 'UserConfigurationPayload';
  mutation: MutationType;
  node?: Maybe<UserConfiguration>;
  previousValues?: Maybe<UserConfiguration>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserConfigurationSort = {
  accessCodeHash?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  passwordResetCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

/** UserConfiguration subscription filter */
export type UserConfigurationSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<UserConfigurationFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** UserConfiguration update input */
export type UserConfigurationUpdateByFilterInput = {
  accessCodeHash?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  passwordResetCode?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** UserConfiguration update input */
export type UserConfigurationUpdateInput = {
  /** Used when user registers */
  accessCodeHash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserConfigurationUserUpdateRelationInput>;
};

/** UserConfiguration relation input */
export type UserConfigurationUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** UserConfiguration relation input */
export type UserConfigurationUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<UserConfiguration_UserCreateInput>;
};

/** UserConfiguration relation input */
export type UserConfigurationUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<UserConfiguration_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<UserConfiguration_UserUpdateInput>;
};

export type UserConfiguration_PermissionFilter = {
  AND?: InputMaybe<Array<UserConfiguration_PermissionFilter>>;
  OR?: InputMaybe<Array<UserConfiguration_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  accessCodeHash?: InputMaybe<StringPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  passwordResetCode?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
};

/** Users create input from userConfiguration */
export type UserConfiguration_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from userConfiguration */
export type UserConfiguration_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** Users create input */
export type UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users create many input */
export type UserCreateManyInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksManyRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesManyRelationInput>;
  adViews?: InputMaybe<UsersAdViewsManyRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarManyRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsManyRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsManyRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersManyRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsManyRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesManyRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsManyRelationInput>;
  education?: InputMaybe<UsersEducationManyRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsManyRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsManyRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsManyRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsManyRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesManyRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsManyRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesManyRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesManyRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsManyRelationInput>;
  inboxes?: InputMaybe<UsersInboxesManyRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceManyRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsManyRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostManyRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostManyRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostManyRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostManyRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsManyRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsManyRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesManyRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsManyRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsManyRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsManyRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesManyRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsManyRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsManyRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesManyRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsManyRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksManyRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesManyRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationManyRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningManyRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersManyRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesManyRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsManyRelationInput>;
};

/** Users delete input */
export type UserDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type UserEmailChangeInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  proposedEmail: Scalars['String'];
};

/** UserFieldsPermissions create input */
export type UserFieldsPermissions = {
  activityClubs?: InputMaybe<Scalars['Boolean']>;
  affiliation?: InputMaybe<Scalars['Boolean']>;
  affiliationDescription?: InputMaybe<Scalars['Boolean']>;
  birthDate?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentIndustry?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  facebookLink?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['Boolean']>;
  graduatingYear?: InputMaybe<Scalars['Boolean']>;
  hobbies?: InputMaybe<Scalars['Boolean']>;
  instagramLink?: InputMaybe<Scalars['Boolean']>;
  is8base?: InputMaybe<Scalars['Boolean']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['Boolean']>;
  linkedInLink?: InputMaybe<Scalars['Boolean']>;
  origin?: InputMaybe<Scalars['Boolean']>;
  parents?: InputMaybe<Scalars['Boolean']>;
  professionalDesignations?: InputMaybe<Scalars['Boolean']>;
  registrationCompletedAt?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['Boolean']>;
  twitterLink?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type UserFilter = {
  AND?: InputMaybe<Array<UserFilter>>;
  OR?: InputMaybe<Array<UserFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  adClicks?: InputMaybe<AdClickRelationFilter>;
  adLikes?: InputMaybe<AdvertisingCampaignAdRelationFilter>;
  adViews?: InputMaybe<AdViewRelationFilter>;
  affiliation?: InputMaybe<StringPredicate>;
  affiliationDescription?: InputMaybe<StringPredicate>;
  avatar?: InputMaybe<FileFilter>;
  birthDate?: InputMaybe<DatePredicate>;
  collectiveIntelligenceThreads?: InputMaybe<CollectiveIntelligenceThreadRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  createdGroups?: InputMaybe<GroupRelationFilter>;
  createdJobOffers?: InputMaybe<JobOfferRelationFilter>;
  createdSchoolNewsPosts?: InputMaybe<SchoolNewsPostRelationFilter>;
  currentIndustry?: InputMaybe<StringPredicate>;
  deleteInboxMessages?: InputMaybe<InboxMessageRelationFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  discountRedemptions?: InputMaybe<DiscountRedemptionRelationFilter>;
  education?: InputMaybe<EducationalInstitutionRelationFilter>;
  email?: InputMaybe<StringPredicate>;
  firstName?: InputMaybe<StringPredicate>;
  flaggedGroupPosts?: InputMaybe<GroupPostRelationFilter>;
  flaggedHomeFeedPosts?: InputMaybe<HomeFeedPostRelationFilter>;
  flaggedLegacyFeedPosts?: InputMaybe<LegacyFeedPostRelationFilter>;
  flaggedSchoolNewsPosts?: InputMaybe<SchoolNewsPostRelationFilter>;
  fullName?: InputMaybe<StringPredicate>;
  gender?: InputMaybe<StringPredicate>;
  graduatingYear?: InputMaybe<DatePredicate>;
  groupAdminPreferences?: InputMaybe<GroupAdminPreferenceFilter>;
  groupPosts?: InputMaybe<GroupPostRelationFilter>;
  hiddenInboxMessages?: InputMaybe<InboxMessageRelationFilter>;
  hiddenInboxes?: InputMaybe<InboxRelationFilter>;
  homeFeedPosts?: InputMaybe<HomeFeedPostRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  inboxes?: InputMaybe<InboxRelationFilter>;
  is8base?: InputMaybe<BoolPredicate>;
  isFirstAdLogin?: InputMaybe<BoolPredicate>;
  isFirstSwitching?: InputMaybe<BoolPredicate>;
  isNewToAdvertising?: InputMaybe<BoolPredicate>;
  isSeekingEmployment?: InputMaybe<BoolPredicate>;
  is_self?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<JobEmployeeRelationFilter>;
  joinedGroups?: InputMaybe<GroupRelationFilter>;
  lastFlaggedGroupPost?: InputMaybe<GroupPostFilter>;
  lastFlaggedHomePost?: InputMaybe<HomeFeedPostFilter>;
  lastFlaggedLegacyPost?: InputMaybe<LegacyFeedPostFilter>;
  lastFlaggedSchoolPost?: InputMaybe<SchoolNewsPostFilter>;
  lastName?: InputMaybe<StringPredicate>;
  legacyFeedPosts?: InputMaybe<LegacyFeedPostRelationFilter>;
  likedOrganizations?: InputMaybe<OrganizationLikeRelationFilter>;
  managerPreferences?: InputMaybe<OrganizationManagerPreferenceRelationFilter>;
  not_self?: InputMaybe<Scalars['Boolean']>;
  origin?: InputMaybe<StringPredicate>;
  ownedOrganizations?: InputMaybe<OrganizationRelationFilter>;
  postedEvents?: InputMaybe<EventRelationFilter>;
  professionalDesignations?: InputMaybe<StringPredicate>;
  redeemedDiscounts?: InputMaybe<DiscountRelationFilter>;
  registrationCompletedAt?: InputMaybe<DateTimePredicate>;
  roles?: InputMaybe<RoleRelationFilter>;
  rolesAddons?: InputMaybe<RoleAddonRelationFilter>;
  schoolMemberships?: InputMaybe<UserSchoolMembershipRelationFilter>;
  sentMessages?: InputMaybe<InboxMessageRelationFilter>;
  sentNotifications?: InputMaybe<UserNotificationRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  supporterClicks?: InputMaybe<SchoolSupporterClickRelationFilter>;
  supporterSearches?: InputMaybe<SchoolSupporterSearchRelationFilter>;
  timezone?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userConfiguration?: InputMaybe<UserConfigurationFilter>;
  userJoining?: InputMaybe<GroupsMembersRequestRelationFilter>;
  userNotificationsByUsers?: InputMaybe<UserNotificationsByUserRelationFilter>;
  userPreferences?: InputMaybe<UserPreferenceFilter>;
  viewedThreads?: InputMaybe<CollectiveIntelligenceThreadRelationFilter>;
};

export type UserForgotPasswordChangeInput = {
  confirmProposedPassword: Scalars['String'];
  email: Scalars['String'];
  proposedPassword: Scalars['String'];
};

export type UserForgotPasswordEmailSendInput = {
  email: Scalars['String'];
  isFromAdvertising?: InputMaybe<Scalars['Boolean']>;
};

export type UserGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: UserGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type UserGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  activityClubs?: InputMaybe<Array<GroupByField>>;
  adClicks?: InputMaybe<AdClickGroupByQuery>;
  adLikes?: InputMaybe<AdvertisingCampaignAdGroupByQuery>;
  adViews?: InputMaybe<AdViewGroupByQuery>;
  affiliation?: InputMaybe<Array<GroupByField>>;
  affiliationDescription?: InputMaybe<Array<GroupByField>>;
  avatar?: InputMaybe<FileGroupByQuery>;
  birthDate?: InputMaybe<Array<GroupByField>>;
  collectiveIntelligenceThreads?: InputMaybe<CollectiveIntelligenceThreadGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  createdGroups?: InputMaybe<GroupGroupByQuery>;
  createdJobOffers?: InputMaybe<JobOfferGroupByQuery>;
  createdSchoolNewsPosts?: InputMaybe<SchoolNewsPostGroupByQuery>;
  currentIndustry?: InputMaybe<Array<GroupByField>>;
  deleteInboxMessages?: InputMaybe<InboxMessageGroupByQuery>;
  description?: InputMaybe<Array<GroupByField>>;
  discountRedemptions?: InputMaybe<DiscountRedemptionGroupByQuery>;
  education?: InputMaybe<EducationalInstitutionGroupByQuery>;
  email?: InputMaybe<Array<GroupByField>>;
  facebookLink?: InputMaybe<Array<GroupByField>>;
  firstName?: InputMaybe<Array<GroupByField>>;
  flaggedGroupPosts?: InputMaybe<GroupPostGroupByQuery>;
  flaggedHomeFeedPosts?: InputMaybe<HomeFeedPostGroupByQuery>;
  flaggedLegacyFeedPosts?: InputMaybe<LegacyFeedPostGroupByQuery>;
  flaggedSchoolNewsPosts?: InputMaybe<SchoolNewsPostGroupByQuery>;
  fullName?: InputMaybe<Array<GroupByField>>;
  gender?: InputMaybe<Array<GroupByField>>;
  graduatingYear?: InputMaybe<Array<GroupByField>>;
  groupAdminPreferences?: InputMaybe<GroupAdminPreferenceGroupByQuery>;
  groupPosts?: InputMaybe<GroupPostGroupByQuery>;
  hiddenInboxMessages?: InputMaybe<InboxMessageGroupByQuery>;
  hiddenInboxes?: InputMaybe<InboxGroupByQuery>;
  hobbies?: InputMaybe<Array<GroupByField>>;
  homeFeedPosts?: InputMaybe<HomeFeedPostGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  inboxes?: InputMaybe<InboxGroupByQuery>;
  instagramLink?: InputMaybe<Array<GroupByField>>;
  is8base?: InputMaybe<Array<GroupByField>>;
  isFirstAdLogin?: InputMaybe<Array<GroupByField>>;
  isFirstSwitching?: InputMaybe<Array<GroupByField>>;
  isNewToAdvertising?: InputMaybe<Array<GroupByField>>;
  isSeekingEmployment?: InputMaybe<Array<GroupByField>>;
  jobExperience?: InputMaybe<JobEmployeeGroupByQuery>;
  joinedGroups?: InputMaybe<GroupGroupByQuery>;
  lastFlaggedGroupPost?: InputMaybe<GroupPostGroupByQuery>;
  lastFlaggedHomePost?: InputMaybe<HomeFeedPostGroupByQuery>;
  lastFlaggedLegacyPost?: InputMaybe<LegacyFeedPostGroupByQuery>;
  lastFlaggedSchoolPost?: InputMaybe<SchoolNewsPostGroupByQuery>;
  lastName?: InputMaybe<Array<GroupByField>>;
  legacyFeedPosts?: InputMaybe<LegacyFeedPostGroupByQuery>;
  likedOrganizations?: InputMaybe<OrganizationLikeGroupByQuery>;
  linkedInLink?: InputMaybe<Array<GroupByField>>;
  managerPreferences?: InputMaybe<OrganizationManagerPreferenceGroupByQuery>;
  origin?: InputMaybe<Array<GroupByField>>;
  ownedOrganizations?: InputMaybe<OrganizationGroupByQuery>;
  parents?: InputMaybe<Array<GroupByField>>;
  postedEvents?: InputMaybe<EventGroupByQuery>;
  professionalDesignations?: InputMaybe<Array<GroupByField>>;
  redeemedDiscounts?: InputMaybe<DiscountGroupByQuery>;
  registrationCompletedAt?: InputMaybe<Array<GroupByField>>;
  roles?: InputMaybe<RoleGroupByQuery>;
  rolesAddons?: InputMaybe<RoleAddonGroupByQuery>;
  schoolMemberships?: InputMaybe<UserSchoolMembershipGroupByQuery>;
  sentMessages?: InputMaybe<InboxMessageGroupByQuery>;
  sentNotifications?: InputMaybe<UserNotificationGroupByQuery>;
  status?: InputMaybe<Array<GroupByField>>;
  supporterClicks?: InputMaybe<SchoolSupporterClickGroupByQuery>;
  supporterSearches?: InputMaybe<SchoolSupporterSearchGroupByQuery>;
  timezone?: InputMaybe<Array<GroupByField>>;
  twitterLink?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  userConfiguration?: InputMaybe<UserConfigurationGroupByQuery>;
  userJoining?: InputMaybe<GroupsMembersRequestGroupByQuery>;
  userNotificationsByUsers?: InputMaybe<UserNotificationsByUserGroupByQuery>;
  userPreferences?: InputMaybe<UserPreferenceGroupByQuery>;
  viewedThreads?: InputMaybe<CollectiveIntelligenceThreadGroupByQuery>;
};

/** User Invitation Details */
export type UserInvitationDetails = {
  __typename?: 'UserInvitationDetails';
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  apiHost?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<InvitedByName>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  resentOn?: Maybe<Scalars['DateTime']>;
};

/** User Invitation List */
export type UserInvitationList = {
  __typename?: 'UserInvitationList';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<UserInvitationDetails>>>;
};

export type UserInviteMembersToGroupInput = {
  id: Scalars['ID'];
  members: GroupsMembersUpdateRelationInput;
};

/** Users create input from userJoining */
export type UserJoining_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from userJoining */
export type UserJoining_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

export type UserKeyFilter = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** AdvertisingCampaignAds create input from userLikes */
export type UserLikes_AdvertisingCampaignAdCreateInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdRelationInput>;
  callToAction: Scalars['String'];
  callToActionLink: Scalars['String'];
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  primaryText: Scalars['String'];
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign?: InputMaybe<AdvertisingCampaignAdsSampaignRelationInput>;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget: Scalars['Float'];
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

/** AdvertisingCampaignAds update input from userLikes */
export type UserLikes_AdvertisingCampaignAdUpdateInput = {
  data: AdvertisingCampaignAdUpdateInput;
  filter?: InputMaybe<AdvertisingCampaignAdKeyFilter>;
};

/** Organizations create input from userLikes */
export type UserLikes_OrganizationCreateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersRelationInput>;
  debt?: InputMaybe<OrganizationsDebtRelationInput>;
  description: Scalars['String'];
  discounts?: InputMaybe<OrganizationsDiscountsRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry: Scalars['String'];
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationRelationInput>;
  logo?: InputMaybe<OrganizationsLogoRelationInput>;
  managers?: InputMaybe<OrganizationsManagersRelationInput>;
  media?: InputMaybe<OrganizationsMediaRelationInput>;
  name: Scalars['String'];
  owner?: InputMaybe<OrganizationsOwnerRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesRelationInput>;
};

/** Organizations update input from userLikes */
export type UserLikes_OrganizationUpdateInput = {
  activePaymentMethod?: InputMaybe<Scalars['Boolean']>;
  advertising?: InputMaybe<OrganizationsAdvertisingUpdateRelationInput>;
  audiences?: InputMaybe<OrganizationsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<OrganizationsBillingAdsUpdateRelationInput>;
  billingSchoolSupporter?: InputMaybe<OrganizationsBillingSchoolSupporterUpdateRelationInput>;
  billingSpecialOffers?: InputMaybe<OrganizationsBillingSpecialOffersUpdateRelationInput>;
  debt?: InputMaybe<OrganizationsDebtUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<OrganizationsDiscountsUpdateRelationInput>;
  eventOtherSponsored?: InputMaybe<OrganizationsEventOtherSponsoredUpdateRelationInput>;
  eventTitleSponsored?: InputMaybe<OrganizationsEventTitleSponsoredUpdateRelationInput>;
  homeFeedViews?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  isExperience?: InputMaybe<Scalars['Boolean']>;
  jobOffers?: InputMaybe<OrganizationsJobOffersUpdateRelationInput>;
  likesCount?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<OrganizationsLocationUpdateRelationInput>;
  logo?: InputMaybe<OrganizationsLogoUpdateRelationInput>;
  managers?: InputMaybe<OrganizationsManagersUpdateRelationInput>;
  media?: InputMaybe<OrganizationsMediaUpdateRelationInput>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<OrganizationsOwnerUpdateRelationInput>;
  paymentErrorStatus?: InputMaybe<Scalars['Boolean']>;
  schoolSupporter?: InputMaybe<OrganizationsSchoolSupporterUpdateRelationInput>;
  sponsorRequests?: InputMaybe<OrganizationsSponsorRequestsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  userLikes?: InputMaybe<OrganizationsUserLikesUpdateRelationInput>;
};

/** UserListResponse output */
export type UserListResponse = {
  __typename?: 'UserListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<User>;
};

/** UserLoginInput */
export type UserLoginInput = {
  authProfileId?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  fromInvitation?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

/** UserManyResponse output */
export type UserManyResponse = {
  __typename?: 'UserManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<User>;
};

/** SchoolConfiguration create input from userMemberships */
export type UserMemberships_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from userMemberships */
export type UserMemberships_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

export type UserNotification = {
  __typename?: 'UserNotification';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  /**
   * Set "true" for notifications that should be deleted (e.g. on trade confirm)
   * Need this to get rid of templateKey filter
   */
  deletable?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<File>;
  /**
   * Optional notification params
   *
   * params: Record<string, any>
   */
  infoJSON?: Maybe<Scalars['JSON']>;
  /** Contains the related item id, used to group notifications, could be post id, group id, profile id, etc */
  relatedItemID?: Maybe<Scalars['String']>;
  sender?: Maybe<User>;
  template?: Maybe<NotificationTemplate>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userNotificationsByUsers?: Maybe<UserNotificationsByUserListResponse>;
};

export type UserNotificationUserNotificationsByUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserNotificationsByUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<UserNotificationsByUserGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserNotificationsByUserOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserNotificationsByUserSort>>;
};

/** UserNotifications create input */
export type UserNotificationCreateInput = {
  /**
   * Set "true" for notifications that should be deleted (e.g. on trade confirm)
   * Need this to get rid of templateKey filter
   */
  deletable?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<UserNotificationsImageRelationInput>;
  /**
   * Optional notification params
   *
   * params: Record<string, any>
   */
  infoJSON?: InputMaybe<Scalars['JSON']>;
  /** Contains the related item id, used to group notifications, could be post id, group id, profile id, etc */
  relatedItemID?: InputMaybe<Scalars['String']>;
  sender?: InputMaybe<UserNotificationsSenderRelationInput>;
  template?: InputMaybe<UserNotificationsTemplateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UserNotificationsUserNotificationsByUsersRelationInput>;
};

/** UserNotifications create many input */
export type UserNotificationCreateManyInput = {
  /**
   * Set "true" for notifications that should be deleted (e.g. on trade confirm)
   * Need this to get rid of templateKey filter
   */
  deletable?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<UserNotificationsImageManyRelationInput>;
  /**
   * Optional notification params
   *
   * params: Record<string, any>
   */
  infoJSON?: InputMaybe<Scalars['JSON']>;
  /** Contains the related item id, used to group notifications, could be post id, group id, profile id, etc */
  relatedItemID?: InputMaybe<Scalars['String']>;
  sender?: InputMaybe<UserNotificationsSenderManyRelationInput>;
  template: UserNotificationsTemplateManyRelationInput;
  userNotificationsByUsers?: InputMaybe<UserNotificationsUserNotificationsByUsersManyRelationInput>;
};

/** UserNotifications delete input */
export type UserNotificationDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** UserNotificationFieldsPermissions create input */
export type UserNotificationFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deletable?: InputMaybe<Scalars['Boolean']>;
  infoJSON?: InputMaybe<Scalars['Boolean']>;
  relatedItemID?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type UserNotificationFilter = {
  AND?: InputMaybe<Array<UserNotificationFilter>>;
  OR?: InputMaybe<Array<UserNotificationFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletable?: InputMaybe<BoolPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  image?: InputMaybe<FileFilter>;
  relatedItemID?: InputMaybe<StringPredicate>;
  sender?: InputMaybe<UserFilter>;
  template?: InputMaybe<NotificationTemplateFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userNotificationsByUsers?: InputMaybe<UserNotificationsByUserRelationFilter>;
};

export type UserNotificationGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: UserNotificationGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type UserNotificationGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  deletable?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  image?: InputMaybe<FileGroupByQuery>;
  infoJSON?: InputMaybe<Array<GroupByField>>;
  relatedItemID?: InputMaybe<Array<GroupByField>>;
  sender?: InputMaybe<UserGroupByQuery>;
  template?: InputMaybe<NotificationTemplateGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  userNotificationsByUsers?: InputMaybe<UserNotificationsByUserGroupByQuery>;
};

export type UserNotificationKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** UserNotificationListResponse output */
export type UserNotificationListResponse = {
  __typename?: 'UserNotificationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<UserNotification>;
};

/** UserNotificationManyResponse output */
export type UserNotificationManyResponse = {
  __typename?: 'UserNotificationManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<UserNotification>;
};

/** No longer supported. Use `sort` instead. */
export type UserNotificationOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletable_ASC'
  | 'deletable_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'infoJSON_ASC'
  | 'infoJSON_DESC'
  | 'relatedItemID_ASC'
  | 'relatedItemID_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** UserNotifications subscription payload */
export type UserNotificationPayload = {
  __typename?: 'UserNotificationPayload';
  mutation: MutationType;
  node?: Maybe<UserNotification>;
  previousValues?: Maybe<UserNotification>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserNotificationRelationFilter = {
  every?: InputMaybe<UserNotificationFilter>;
  none?: InputMaybe<UserNotificationFilter>;
  some?: InputMaybe<UserNotificationFilter>;
};

export type UserNotificationSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletable?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<FileSort>;
  relatedItemID?: InputMaybe<SortOrder>;
  sender?: InputMaybe<UserSort>;
  template?: InputMaybe<NotificationTemplateSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** UserNotifications subscription filter */
export type UserNotificationSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<UserNotificationFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** UserNotifications update input */
export type UserNotificationUpdateByFilterInput = {
  deletable?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  infoJSON?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  relatedItemID?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** UserNotifications update input */
export type UserNotificationUpdateInput = {
  /**
   * Set "true" for notifications that should be deleted (e.g. on trade confirm)
   * Need this to get rid of templateKey filter
   */
  deletable?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<UserNotificationsImageUpdateRelationInput>;
  /**
   * Optional notification params
   *
   * params: Record<string, any>
   */
  infoJSON?: InputMaybe<Scalars['JSON']>;
  /** Contains the related item id, used to group notifications, could be post id, group id, profile id, etc */
  relatedItemID?: InputMaybe<Scalars['String']>;
  sender?: InputMaybe<UserNotificationsSenderUpdateRelationInput>;
  template?: InputMaybe<UserNotificationsTemplateUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UserNotificationsUserNotificationsByUsersUpdateRelationInput>;
};

export type UserNotification_PermissionFilter = {
  AND?: InputMaybe<Array<UserNotification_PermissionFilter>>;
  OR?: InputMaybe<Array<UserNotification_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletable?: InputMaybe<BoolPredicate>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  image?: InputMaybe<File_PermissionFilter>;
  relatedItemID?: InputMaybe<StringPredicate>;
  sender?: InputMaybe<User_PermissionFilter>;
  template?: InputMaybe<NotificationTemplate_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userNotificationsByUsers?: InputMaybe<UserNotificationsByUser_PermissionRelationFilter>;
};

export type UserNotification_PermissionRelationFilter = {
  every?: InputMaybe<UserNotification_PermissionFilter>;
  none?: InputMaybe<UserNotification_PermissionFilter>;
  some?: InputMaybe<UserNotification_PermissionFilter>;
};

export type UserNotificationsByUser = {
  __typename?: 'UserNotificationsByUser';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  isRead?: Maybe<Scalars['Boolean']>;
  notification?: Maybe<UserNotification>;
  receiver?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** UserNotificationsByUser create input */
export type UserNotificationsByUserCreateInput = {
  isRead?: InputMaybe<Scalars['Boolean']>;
  notification?: InputMaybe<UserNotificationsByUserNotificationRelationInput>;
  receiver?: InputMaybe<UserNotificationsByUserReceiverRelationInput>;
};

/** UserNotificationsByUser create many input */
export type UserNotificationsByUserCreateManyInput = {
  isRead?: InputMaybe<Scalars['Boolean']>;
  notification: UserNotificationsByUserNotificationManyRelationInput;
  receiver: UserNotificationsByUserReceiverManyRelationInput;
};

/** UserNotificationsByUser delete input */
export type UserNotificationsByUserDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** UserNotificationsByUserFieldsPermissions create input */
export type UserNotificationsByUserFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type UserNotificationsByUserFilter = {
  AND?: InputMaybe<Array<UserNotificationsByUserFilter>>;
  OR?: InputMaybe<Array<UserNotificationsByUserFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isRead?: InputMaybe<BoolPredicate>;
  notification?: InputMaybe<UserNotificationFilter>;
  receiver?: InputMaybe<UserFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type UserNotificationsByUserGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: UserNotificationsByUserGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type UserNotificationsByUserGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  isRead?: InputMaybe<Array<GroupByField>>;
  notification?: InputMaybe<UserNotificationGroupByQuery>;
  receiver?: InputMaybe<UserGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
};

export type UserNotificationsByUserKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** UserNotificationsByUserListResponse output */
export type UserNotificationsByUserListResponse = {
  __typename?: 'UserNotificationsByUserListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<UserNotificationsByUser>;
};

/** UserNotificationsByUserManyResponse output */
export type UserNotificationsByUserManyResponse = {
  __typename?: 'UserNotificationsByUserManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<UserNotificationsByUser>;
};

/** UserNotificationsByUser relation input */
export type UserNotificationsByUserNotificationManyRelationInput = {
  connect?: InputMaybe<UserNotificationKeyFilter>;
};

/** UserNotificationsByUser relation input */
export type UserNotificationsByUserNotificationRelationInput = {
  connect?: InputMaybe<UserNotificationKeyFilter>;
  create?: InputMaybe<UserNotificationsByUsers_UserNotificationCreateInput>;
};

/** UserNotificationsByUser relation input */
export type UserNotificationsByUserNotificationUpdateRelationInput = {
  connect?: InputMaybe<UserNotificationKeyFilter>;
  create?: InputMaybe<UserNotificationsByUsers_UserNotificationCreateInput>;
  disconnect?: InputMaybe<UserNotificationKeyFilter>;
  reconnect?: InputMaybe<UserNotificationKeyFilter>;
  update?: InputMaybe<UserNotificationsByUsers_UserNotificationUpdateInput>;
};

/** No longer supported. Use `sort` instead. */
export type UserNotificationsByUserOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isRead_ASC'
  | 'isRead_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** UserNotificationsByUser subscription payload */
export type UserNotificationsByUserPayload = {
  __typename?: 'UserNotificationsByUserPayload';
  mutation: MutationType;
  node?: Maybe<UserNotificationsByUser>;
  previousValues?: Maybe<UserNotificationsByUser>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** UserNotificationsByUser relation input */
export type UserNotificationsByUserReceiverManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** UserNotificationsByUser relation input */
export type UserNotificationsByUserReceiverRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<UserNotificationsByUsers_UserCreateInput>;
};

/** UserNotificationsByUser relation input */
export type UserNotificationsByUserReceiverUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<UserNotificationsByUsers_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<UserNotificationsByUsers_UserUpdateInput>;
};

export type UserNotificationsByUserRelationFilter = {
  every?: InputMaybe<UserNotificationsByUserFilter>;
  none?: InputMaybe<UserNotificationsByUserFilter>;
  some?: InputMaybe<UserNotificationsByUserFilter>;
};

export type UserNotificationsByUserSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isRead?: InputMaybe<SortOrder>;
  notification?: InputMaybe<UserNotificationSort>;
  receiver?: InputMaybe<UserSort>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** UserNotificationsByUser subscription filter */
export type UserNotificationsByUserSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<UserNotificationsByUserFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** UserNotificationsByUser update input */
export type UserNotificationsByUserUpdateByFilterInput = {
  isRead?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
};

/** UserNotificationsByUser update input */
export type UserNotificationsByUserUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  notification?: InputMaybe<UserNotificationsByUserNotificationUpdateRelationInput>;
  receiver?: InputMaybe<UserNotificationsByUserReceiverUpdateRelationInput>;
};

export type UserNotificationsByUser_PermissionFilter = {
  AND?: InputMaybe<Array<UserNotificationsByUser_PermissionFilter>>;
  OR?: InputMaybe<Array<UserNotificationsByUser_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  isRead?: InputMaybe<BoolPredicate>;
  notification?: InputMaybe<UserNotification_PermissionFilter>;
  receiver?: InputMaybe<User_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
};

export type UserNotificationsByUser_PermissionRelationFilter = {
  every?: InputMaybe<UserNotificationsByUser_PermissionFilter>;
  none?: InputMaybe<UserNotificationsByUser_PermissionFilter>;
  some?: InputMaybe<UserNotificationsByUser_PermissionFilter>;
};

/** Users create input from userNotificationsByUsers */
export type UserNotificationsByUsers_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** UserNotifications create input from userNotificationsByUsers */
export type UserNotificationsByUsers_UserNotificationCreateInput = {
  /**
   * Set "true" for notifications that should be deleted (e.g. on trade confirm)
   * Need this to get rid of templateKey filter
   */
  deletable?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<UserNotificationsImageRelationInput>;
  /**
   * Optional notification params
   *
   * params: Record<string, any>
   */
  infoJSON?: InputMaybe<Scalars['JSON']>;
  /** Contains the related item id, used to group notifications, could be post id, group id, profile id, etc */
  relatedItemID?: InputMaybe<Scalars['String']>;
  sender?: InputMaybe<UserNotificationsSenderRelationInput>;
  template?: InputMaybe<UserNotificationsTemplateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UserNotificationsUserNotificationsByUsersRelationInput>;
};

/** UserNotifications update input from userNotificationsByUsers */
export type UserNotificationsByUsers_UserNotificationUpdateInput = {
  /**
   * Set "true" for notifications that should be deleted (e.g. on trade confirm)
   * Need this to get rid of templateKey filter
   */
  deletable?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<UserNotificationsImageUpdateRelationInput>;
  /**
   * Optional notification params
   *
   * params: Record<string, any>
   */
  infoJSON?: InputMaybe<Scalars['JSON']>;
  /** Contains the related item id, used to group notifications, could be post id, group id, profile id, etc */
  relatedItemID?: InputMaybe<Scalars['String']>;
  sender?: InputMaybe<UserNotificationsSenderUpdateRelationInput>;
  template?: InputMaybe<UserNotificationsTemplateUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UserNotificationsUserNotificationsByUsersUpdateRelationInput>;
};

/** Users update input from userNotificationsByUsers */
export type UserNotificationsByUsers_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

/** UserNotifications relation input */
export type UserNotificationsImageManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** UserNotifications relation input */
export type UserNotificationsImageRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<UserNotifications_Image_FileCreateInput>;
};

/** UserNotifications relation input */
export type UserNotificationsImageUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<UserNotifications_Image_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<UserNotifications_Image_FileUpdateInput>;
};

/** UserNotifications relation input */
export type UserNotificationsSenderManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** UserNotifications relation input */
export type UserNotificationsSenderRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<SentNotifications_UserCreateInput>;
};

/** UserNotifications relation input */
export type UserNotificationsSenderUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<SentNotifications_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<SentNotifications_UserUpdateInput>;
};

/** UserNotifications relation input */
export type UserNotificationsTemplateManyRelationInput = {
  connect?: InputMaybe<NotificationTemplateKeyFilter>;
};

/** UserNotifications relation input */
export type UserNotificationsTemplateRelationInput = {
  connect?: InputMaybe<NotificationTemplateKeyFilter>;
  create?: InputMaybe<UserNotifications_NotificationTemplateCreateInput>;
};

/** UserNotifications relation input */
export type UserNotificationsTemplateUpdateRelationInput = {
  connect?: InputMaybe<NotificationTemplateKeyFilter>;
  create?: InputMaybe<UserNotifications_NotificationTemplateCreateInput>;
  disconnect?: InputMaybe<NotificationTemplateKeyFilter>;
  reconnect?: InputMaybe<NotificationTemplateKeyFilter>;
  update?: InputMaybe<UserNotifications_NotificationTemplateUpdateInput>;
};

/** UserNotifications relation input */
export type UserNotificationsUserNotificationsByUsersManyRelationInput = {
  connect?: InputMaybe<Array<UserNotificationsByUserKeyFilter>>;
};

/** UserNotifications relation input */
export type UserNotificationsUserNotificationsByUsersRelationInput = {
  connect?: InputMaybe<Array<UserNotificationsByUserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Notification_UserNotificationsByUserCreateInput>>>;
};

/** UserNotifications relation input */
export type UserNotificationsUserNotificationsByUsersUpdateRelationInput = {
  connect?: InputMaybe<Array<UserNotificationsByUserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Notification_UserNotificationsByUserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserNotificationsByUserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserNotificationsByUserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Notification_UserNotificationsByUserUpdateInput>>>;
};

/** NotificationTemplates create input from userNotifications */
export type UserNotifications_NotificationTemplateCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<NotificationTemplatesRolesRelationInput>;
  title?: InputMaybe<Scalars['String']>;
  userNotifications?: InputMaybe<NotificationTemplatesUserNotificationsRelationInput>;
};

/** NotificationTemplates update input from userNotifications */
export type UserNotifications_NotificationTemplateUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<NotificationTemplatesRolesUpdateRelationInput>;
  title?: InputMaybe<Scalars['String']>;
  userNotifications?: InputMaybe<NotificationTemplatesUserNotificationsUpdateRelationInput>;
};

/** Files create input from userNotifications_image */
export type UserNotifications_Image_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from userNotifications_image */
export type UserNotifications_Image_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** No longer supported. Use `sort` instead. */
export type UserOrderBy =
  | 'affiliationDescription_ASC'
  | 'affiliationDescription_DESC'
  | 'affiliation_ASC'
  | 'affiliation_DESC'
  | 'birthDate_ASC'
  | 'birthDate_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'currentIndustry_ASC'
  | 'currentIndustry_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'description_ASC'
  | 'description_DESC'
  | 'email_ASC'
  | 'email_DESC'
  | 'facebookLink_ASC'
  | 'facebookLink_DESC'
  | 'firstName_ASC'
  | 'firstName_DESC'
  | 'fullName_ASC'
  | 'fullName_DESC'
  | 'gender_ASC'
  | 'gender_DESC'
  | 'graduatingYear_ASC'
  | 'graduatingYear_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'instagramLink_ASC'
  | 'instagramLink_DESC'
  | 'is8base_ASC'
  | 'is8base_DESC'
  | 'isFirstAdLogin_ASC'
  | 'isFirstAdLogin_DESC'
  | 'isFirstSwitching_ASC'
  | 'isFirstSwitching_DESC'
  | 'isNewToAdvertising_ASC'
  | 'isNewToAdvertising_DESC'
  | 'isOwner_ASC'
  | 'isOwner_DESC'
  | 'isSeekingEmployment_ASC'
  | 'isSeekingEmployment_DESC'
  | 'lastName_ASC'
  | 'lastName_DESC'
  | 'linkedInLink_ASC'
  | 'linkedInLink_DESC'
  | 'origin_ASC'
  | 'origin_DESC'
  | 'parents_ASC'
  | 'parents_DESC'
  | 'professionalDesignations_ASC'
  | 'professionalDesignations_DESC'
  | 'registrationCompletedAt_ASC'
  | 'registrationCompletedAt_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'timezone_ASC'
  | 'timezone_DESC'
  | 'twitterLink_ASC'
  | 'twitterLink_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

export type UserPasswordChangeInput = {
  confirmProposedPassword: Scalars['String'];
  email: Scalars['String'];
  previousPassword: Scalars['String'];
  proposedPassword: Scalars['String'];
};

/** Users subscription payload */
export type UserPayload = {
  __typename?: 'UserPayload';
  mutation: MutationType;
  node?: Maybe<User>;
  previousValues?: Maybe<User>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** User Permission */
export type UserPermission = {
  __typename?: 'UserPermission';
  permission?: Maybe<Scalars['JSON']>;
  resource?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
};

/** User Permission List */
export type UserPermissionList = {
  __typename?: 'UserPermissionList';
  count: Scalars['Int'];
  items?: Maybe<Array<UserPermission>>;
};

export type UserPreference = {
  __typename?: 'UserPreference';
  _description?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  jobsOfInterest?: Maybe<JobOfferListResponse>;
  phoneNumber?: Maybe<Scalars['String']>;
  secondaryPhoneNumber?: Maybe<Scalars['String']>;
  stripeInformation?: Maybe<StripeInformation>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type UserPreferenceJobsOfInterestArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<JobOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<JobOfferGroupBy>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<JobOfferOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<JobOfferSort>>;
};

/** UserPreferences create input */
export type UserPreferenceCreateInput = {
  address?: InputMaybe<UserPreferencesAddressRelationInput>;
  jobsOfInterest?: InputMaybe<UserPreferencesJobsOfInterestRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  secondaryPhoneNumber?: InputMaybe<Scalars['String']>;
  stripeInformation?: InputMaybe<UserPreferencesStripeInformationRelationInput>;
  user?: InputMaybe<UserPreferencesUserRelationInput>;
};

/** UserPreferences create many input */
export type UserPreferenceCreateManyInput = {
  address?: InputMaybe<UserPreferencesAddressManyRelationInput>;
  jobsOfInterest?: InputMaybe<UserPreferencesJobsOfInterestManyRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  secondaryPhoneNumber?: InputMaybe<Scalars['String']>;
  stripeInformation?: InputMaybe<UserPreferencesStripeInformationManyRelationInput>;
  user?: InputMaybe<UserPreferencesUserManyRelationInput>;
};

/** UserPreferences delete input */
export type UserPreferenceDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** UserPreferenceFieldsPermissions create input */
export type UserPreferenceFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']>;
  secondaryPhoneNumber?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type UserPreferenceFilter = {
  AND?: InputMaybe<Array<UserPreferenceFilter>>;
  OR?: InputMaybe<Array<UserPreferenceFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  jobsOfInterest?: InputMaybe<JobOfferRelationFilter>;
  phoneNumber?: InputMaybe<StringPredicate>;
  secondaryPhoneNumber?: InputMaybe<StringPredicate>;
  stripeInformation?: InputMaybe<StripeInformationFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
};

export type UserPreferenceGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: UserPreferenceGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type UserPreferenceGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  address?: InputMaybe<AddressGroupByQuery>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  jobsOfInterest?: InputMaybe<JobOfferGroupByQuery>;
  phoneNumber?: InputMaybe<Array<GroupByField>>;
  secondaryPhoneNumber?: InputMaybe<Array<GroupByField>>;
  stripeInformation?: InputMaybe<StripeInformationGroupByQuery>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
};

export type UserPreferenceKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** UserPreferenceListResponse output */
export type UserPreferenceListResponse = {
  __typename?: 'UserPreferenceListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<UserPreference>;
};

/** UserPreferenceManyResponse output */
export type UserPreferenceManyResponse = {
  __typename?: 'UserPreferenceManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<UserPreference>;
};

/** No longer supported. Use `sort` instead. */
export type UserPreferenceOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'phoneNumber_ASC'
  | 'phoneNumber_DESC'
  | 'secondaryPhoneNumber_ASC'
  | 'secondaryPhoneNumber_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** UserPreferences subscription payload */
export type UserPreferencePayload = {
  __typename?: 'UserPreferencePayload';
  mutation: MutationType;
  node?: Maybe<UserPreference>;
  previousValues?: Maybe<UserPreference>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserPreferenceRelationFilter = {
  every?: InputMaybe<UserPreferenceFilter>;
  none?: InputMaybe<UserPreferenceFilter>;
  some?: InputMaybe<UserPreferenceFilter>;
};

export type UserPreferenceSort = {
  address?: InputMaybe<AddressSort>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  secondaryPhoneNumber?: InputMaybe<SortOrder>;
  stripeInformation?: InputMaybe<StripeInformationSort>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
};

/** UserPreferences subscription filter */
export type UserPreferenceSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<UserPreferenceFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** UserPreferences update input */
export type UserPreferenceUpdateByFilterInput = {
  phoneNumber?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  secondaryPhoneNumber?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** UserPreferences update input */
export type UserPreferenceUpdateInput = {
  address?: InputMaybe<UserPreferencesAddressUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  jobsOfInterest?: InputMaybe<UserPreferencesJobsOfInterestUpdateRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  secondaryPhoneNumber?: InputMaybe<Scalars['String']>;
  stripeInformation?: InputMaybe<UserPreferencesStripeInformationUpdateRelationInput>;
  user?: InputMaybe<UserPreferencesUserUpdateRelationInput>;
};

export type UserPreference_PermissionFilter = {
  AND?: InputMaybe<Array<UserPreference_PermissionFilter>>;
  OR?: InputMaybe<Array<UserPreference_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Address_PermissionFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  jobsOfInterest?: InputMaybe<JobOffer_PermissionRelationFilter>;
  phoneNumber?: InputMaybe<StringPredicate>;
  secondaryPhoneNumber?: InputMaybe<StringPredicate>;
  stripeInformation?: InputMaybe<StripeInformation_PermissionFilter>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
};

export type UserPreference_PermissionRelationFilter = {
  every?: InputMaybe<UserPreference_PermissionFilter>;
  none?: InputMaybe<UserPreference_PermissionFilter>;
  some?: InputMaybe<UserPreference_PermissionFilter>;
};

/** StripeInformations create input from userPreference */
export type UserPreference_StripeInformationCreateInput = {
  connectedAccountId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  userPreference?: InputMaybe<StripeInformationsUserPreferenceRelationInput>;
};

/** StripeInformations update input from userPreference */
export type UserPreference_StripeInformationUpdateInput = {
  connectedAccountId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  userPreference?: InputMaybe<StripeInformationsUserPreferenceUpdateRelationInput>;
};

/** UserPreferences relation input */
export type UserPreferencesAddressManyRelationInput = {
  connect?: InputMaybe<AddressKeyFilter>;
};

/** UserPreferences relation input */
export type UserPreferencesAddressRelationInput = {
  connect?: InputMaybe<AddressKeyFilter>;
  create?: InputMaybe<UsersAdresses_AddressCreateInput>;
};

/** UserPreferences relation input */
export type UserPreferencesAddressUpdateRelationInput = {
  connect?: InputMaybe<AddressKeyFilter>;
  create?: InputMaybe<UsersAdresses_AddressCreateInput>;
  disconnect?: InputMaybe<AddressKeyFilter>;
  reconnect?: InputMaybe<AddressKeyFilter>;
  update?: InputMaybe<UsersAdresses_AddressUpdateInput>;
};

/** UserPreferences relation input */
export type UserPreferencesJobsOfInterestManyRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
};

/** UserPreferences relation input */
export type UserPreferencesJobsOfInterestRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<InterestedUsers_JobOfferCreateInput>>>;
};

/** UserPreferences relation input */
export type UserPreferencesJobsOfInterestUpdateRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<InterestedUsers_JobOfferCreateInput>>>;
  disconnect?: InputMaybe<Array<JobOfferKeyFilter>>;
  reconnect?: InputMaybe<Array<JobOfferKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<InterestedUsers_JobOfferUpdateInput>>>;
};

/** UserPreferences relation input */
export type UserPreferencesStripeInformationManyRelationInput = {
  connect?: InputMaybe<StripeInformationKeyFilter>;
};

/** UserPreferences relation input */
export type UserPreferencesStripeInformationRelationInput = {
  connect?: InputMaybe<StripeInformationKeyFilter>;
  create?: InputMaybe<UserPreference_StripeInformationCreateInput>;
};

/** UserPreferences relation input */
export type UserPreferencesStripeInformationUpdateRelationInput = {
  connect?: InputMaybe<StripeInformationKeyFilter>;
  create?: InputMaybe<UserPreference_StripeInformationCreateInput>;
  disconnect?: InputMaybe<StripeInformationKeyFilter>;
  reconnect?: InputMaybe<StripeInformationKeyFilter>;
  update?: InputMaybe<UserPreference_StripeInformationUpdateInput>;
};

/** UserPreferences relation input */
export type UserPreferencesUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** UserPreferences relation input */
export type UserPreferencesUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<UserPreferences_UserCreateInput>;
};

/** UserPreferences relation input */
export type UserPreferencesUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<UserPreferences_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<UserPreferences_UserUpdateInput>;
};

/** Users create input from userPreferences */
export type UserPreferences_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from userPreferences */
export type UserPreferences_UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

export type UserRelationFilter = {
  every?: InputMaybe<UserFilter>;
  none?: InputMaybe<UserFilter>;
  some?: InputMaybe<UserFilter>;
};

export type UserSchoolMembership = {
  __typename?: 'UserSchoolMembership';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  school?: Maybe<SchoolConfiguration>;
  schoolId?: Maybe<Scalars['String']>;
  /** Status of the current membership of the user. */
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

/** UserSchoolMembership create input */
export type UserSchoolMembershipCreateInput = {
  school?: InputMaybe<UserSchoolMembershipSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  /** Status of the current membership of the user. */
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserSchoolMembershipUserRelationInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** UserSchoolMembership create many input */
export type UserSchoolMembershipCreateManyInput = {
  school?: InputMaybe<UserSchoolMembershipSchoolManyRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  /** Status of the current membership of the user. */
  status?: InputMaybe<Scalars['String']>;
  user: UserSchoolMembershipUserManyRelationInput;
  userId?: InputMaybe<Scalars['String']>;
};

/** UserSchoolMembership delete input */
export type UserSchoolMembershipDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** UserSchoolMembershipFieldsPermissions create input */
export type UserSchoolMembershipFieldsPermissions = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
};

export type UserSchoolMembershipFilter = {
  AND?: InputMaybe<Array<UserSchoolMembershipFilter>>;
  OR?: InputMaybe<Array<UserSchoolMembershipFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfigurationFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<UserFilter>;
  userId?: InputMaybe<StringPredicate>;
};

export type UserSchoolMembershipGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: UserSchoolMembershipGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type UserSchoolMembershipGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  school?: InputMaybe<SchoolConfigurationGroupByQuery>;
  schoolId?: InputMaybe<Array<GroupByField>>;
  status?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  user?: InputMaybe<UserGroupByQuery>;
  userId?: InputMaybe<Array<GroupByField>>;
};

export type UserSchoolMembershipKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** UserSchoolMembershipListResponse output */
export type UserSchoolMembershipListResponse = {
  __typename?: 'UserSchoolMembershipListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<UserSchoolMembership>;
};

/** UserSchoolMembershipManyResponse output */
export type UserSchoolMembershipManyResponse = {
  __typename?: 'UserSchoolMembershipManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<UserSchoolMembership>;
};

/** No longer supported. Use `sort` instead. */
export type UserSchoolMembershipOrderBy =
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'schoolId_ASC'
  | 'schoolId_DESC'
  | 'status_ASC'
  | 'status_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC'
  | 'userId_ASC'
  | 'userId_DESC';

/** UserSchoolMembership subscription payload */
export type UserSchoolMembershipPayload = {
  __typename?: 'UserSchoolMembershipPayload';
  mutation: MutationType;
  node?: Maybe<UserSchoolMembership>;
  previousValues?: Maybe<UserSchoolMembership>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserSchoolMembershipRelationFilter = {
  every?: InputMaybe<UserSchoolMembershipFilter>;
  none?: InputMaybe<UserSchoolMembershipFilter>;
  some?: InputMaybe<UserSchoolMembershipFilter>;
};

/** UserSchoolMembership relation input */
export type UserSchoolMembershipSchoolManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** UserSchoolMembership relation input */
export type UserSchoolMembershipSchoolRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<UserMemberships_SchoolConfigurationCreateInput>;
};

/** UserSchoolMembership relation input */
export type UserSchoolMembershipSchoolUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<UserMemberships_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<UserMemberships_SchoolConfigurationUpdateInput>;
};

export type UserSchoolMembershipSort = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolConfigurationSort>;
  schoolId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserSort>;
  userId?: InputMaybe<SortOrder>;
};

/** UserSchoolMembership subscription filter */
export type UserSchoolMembershipSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<UserSchoolMembershipFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** UserSchoolMembership update input */
export type UserSchoolMembershipUpdateByFilterInput = {
  schoolId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  userId?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

/** UserSchoolMembership update input */
export type UserSchoolMembershipUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  school?: InputMaybe<UserSchoolMembershipSchoolUpdateRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  /** Status of the current membership of the user. */
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserSchoolMembershipUserUpdateRelationInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** UserSchoolMembership relation input */
export type UserSchoolMembershipUserManyRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
};

/** UserSchoolMembership relation input */
export type UserSchoolMembershipUserRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<SchoolMemberships_UserCreateInput>;
};

/** UserSchoolMembership relation input */
export type UserSchoolMembershipUserUpdateRelationInput = {
  connect?: InputMaybe<UserKeyFilter>;
  create?: InputMaybe<SchoolMemberships_UserCreateInput>;
  disconnect?: InputMaybe<UserKeyFilter>;
  reconnect?: InputMaybe<UserKeyFilter>;
  update?: InputMaybe<SchoolMemberships_UserUpdateInput>;
};

export type UserSchoolMembership_PermissionFilter = {
  AND?: InputMaybe<Array<UserSchoolMembership_PermissionFilter>>;
  OR?: InputMaybe<Array<UserSchoolMembership_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  school?: InputMaybe<SchoolConfiguration_PermissionFilter>;
  schoolId?: InputMaybe<StringPredicate>;
  status?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  user?: InputMaybe<User_PermissionFilter>;
  userId?: InputMaybe<StringPredicate>;
};

export type UserSchoolMembership_PermissionRelationFilter = {
  every?: InputMaybe<UserSchoolMembership_PermissionFilter>;
  none?: InputMaybe<UserSchoolMembership_PermissionFilter>;
  some?: InputMaybe<UserSchoolMembership_PermissionFilter>;
};

/** SchoolSupporter create input from userSearches */
export type UserSearches_SchoolSupporterCreateInput = {
  billingRegisteredSchoolSupporter?: InputMaybe<SchoolSupporterBillingRegisteredSchoolSupporterRelationInput>;
  school?: InputMaybe<SchoolSupporterSchoolRelationInput>;
  startDate: Scalars['Date'];
  status: Scalars['String'];
  supporter?: InputMaybe<SchoolSupporterSupporterRelationInput>;
  userClicks?: InputMaybe<SchoolSupporterUserClicksRelationInput>;
  userSearches?: InputMaybe<SchoolSupporterUserSearchesRelationInput>;
};

/** SchoolSupporter update input from userSearches */
export type UserSearches_SchoolSupporterUpdateInput = {
  billingRegisteredSchoolSupporter?: InputMaybe<SchoolSupporterBillingRegisteredSchoolSupporterUpdateRelationInput>;
  school?: InputMaybe<SchoolSupporterSchoolUpdateRelationInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Scalars['String']>;
  supporter?: InputMaybe<SchoolSupporterSupporterUpdateRelationInput>;
  userClicks?: InputMaybe<SchoolSupporterUserClicksUpdateRelationInput>;
  userSearches?: InputMaybe<SchoolSupporterUserSearchesUpdateRelationInput>;
};

export type UserSocialCompleteRegistrationInput = {
  email: Scalars['String'];
};

export type UserSort = {
  affiliation?: InputMaybe<SortOrder>;
  affiliationDescription?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<FileSort>;
  birthDate?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  currentIndustry?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  graduatingYear?: InputMaybe<SortOrder>;
  groupAdminPreferences?: InputMaybe<GroupAdminPreferenceSort>;
  id?: InputMaybe<SortOrder>;
  is8base?: InputMaybe<SortOrder>;
  isFirstAdLogin?: InputMaybe<SortOrder>;
  isFirstSwitching?: InputMaybe<SortOrder>;
  isNewToAdvertising?: InputMaybe<SortOrder>;
  isSeekingEmployment?: InputMaybe<SortOrder>;
  lastFlaggedGroupPost?: InputMaybe<GroupPostSort>;
  lastFlaggedHomePost?: InputMaybe<HomeFeedPostSort>;
  lastFlaggedLegacyPost?: InputMaybe<LegacyFeedPostSort>;
  lastFlaggedSchoolPost?: InputMaybe<SchoolNewsPostSort>;
  lastName?: InputMaybe<SortOrder>;
  origin?: InputMaybe<SortOrder>;
  professionalDesignations?: InputMaybe<SortOrder>;
  registrationCompletedAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userConfiguration?: InputMaybe<UserConfigurationSort>;
  userPreferences?: InputMaybe<UserPreferenceSort>;
};

export type UserStatus = {
  __typename?: 'UserStatus';
  _description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isSupporter?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
};

/** UserStatusFieldsPermissions create input */
export type UserStatusFieldsPermissions = {
  email?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['Boolean']>;
  isSupporter?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['Boolean']>;
};

export type UserStatusFilter = {
  AND?: InputMaybe<Array<UserStatusFilter>>;
  OR?: InputMaybe<Array<UserStatusFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<IntPredicate>;
  email?: InputMaybe<StringPredicate>;
  firstName?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  isSupporter?: InputMaybe<IntPredicate>;
  lastName?: InputMaybe<StringPredicate>;
};

export type UserStatusGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: UserStatusGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type UserStatusGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  email?: InputMaybe<Array<GroupByField>>;
  firstName?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  isSupporter?: InputMaybe<Array<GroupByField>>;
  lastName?: InputMaybe<Array<GroupByField>>;
};

/** UserStatusListResponse output */
export type UserStatusListResponse = {
  __typename?: 'UserStatusListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<UserStatus>;
};

/** UserStatusManyResponse output */
export type UserStatusManyResponse = {
  __typename?: 'UserStatusManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<UserStatus>;
};

/** No longer supported. Use `sort` instead. */
export type UserStatusOrderBy =
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'email_ASC'
  | 'email_DESC'
  | 'firstName_ASC'
  | 'firstName_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isSupporter_ASC'
  | 'isSupporter_DESC'
  | 'lastName_ASC'
  | 'lastName_DESC';

/** UserStatus subscription payload */
export type UserStatusPayload = {
  __typename?: 'UserStatusPayload';
  mutation: MutationType;
  node?: Maybe<UserStatus>;
  previousValues?: Maybe<UserStatus>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserStatusSort = {
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isSupporter?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
};

/** UserStatus subscription filter */
export type UserStatusSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<UserStatusFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** UserStatus update input */
export type UserStatusUpdateByFilterInput = {
  email?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  firstName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  isSupporter?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  lastName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
};

export type UserStatus_PermissionFilter = {
  AND?: InputMaybe<Array<UserStatus_PermissionFilter>>;
  OR?: InputMaybe<Array<UserStatus_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<IntPredicate>;
  email?: InputMaybe<StringPredicate>;
  firstName?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  isSupporter?: InputMaybe<IntPredicate>;
  lastName?: InputMaybe<StringPredicate>;
};

/** Users subscription filter */
export type UserSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<UserFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

export type UserSupportersOnly = {
  __typename?: 'UserSupportersOnly';
  _description?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isSupporterIfExists?: Maybe<Scalars['Int']>;
};

/** UserSupportersOnlyFieldsPermissions create input */
export type UserSupportersOnlyFieldsPermissions = {
  firstName?: InputMaybe<Scalars['Boolean']>;
  isSupporterIfExists?: InputMaybe<Scalars['Boolean']>;
};

export type UserSupportersOnlyFilter = {
  AND?: InputMaybe<Array<UserSupportersOnlyFilter>>;
  OR?: InputMaybe<Array<UserSupportersOnlyFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<IntPredicate>;
  firstName?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  isSupporterIfExists?: InputMaybe<IntPredicate>;
};

export type UserSupportersOnlyGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: UserSupportersOnlyGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type UserSupportersOnlyGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  firstName?: InputMaybe<Array<GroupByField>>;
  id?: InputMaybe<Array<GroupByField>>;
  isSupporterIfExists?: InputMaybe<Array<GroupByField>>;
};

/** UserSupportersOnlyListResponse output */
export type UserSupportersOnlyListResponse = {
  __typename?: 'UserSupportersOnlyListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<UserSupportersOnly>;
};

/** UserSupportersOnlyManyResponse output */
export type UserSupportersOnlyManyResponse = {
  __typename?: 'UserSupportersOnlyManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<UserSupportersOnly>;
};

/** No longer supported. Use `sort` instead. */
export type UserSupportersOnlyOrderBy =
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'firstName_ASC'
  | 'firstName_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'isSupporterIfExists_ASC'
  | 'isSupporterIfExists_DESC';

/** UserSupportersOnly subscription payload */
export type UserSupportersOnlyPayload = {
  __typename?: 'UserSupportersOnlyPayload';
  mutation: MutationType;
  node?: Maybe<UserSupportersOnly>;
  previousValues?: Maybe<UserSupportersOnly>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserSupportersOnlySort = {
  deletedAt?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isSupporterIfExists?: InputMaybe<SortOrder>;
};

/** UserSupportersOnly subscription filter */
export type UserSupportersOnlySubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<UserSupportersOnlyFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** UserSupportersOnly update input */
export type UserSupportersOnlyUpdateByFilterInput = {
  firstName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  isSupporterIfExists?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
};

export type UserSupportersOnly_PermissionFilter = {
  AND?: InputMaybe<Array<UserSupportersOnly_PermissionFilter>>;
  OR?: InputMaybe<Array<UserSupportersOnly_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<IntPredicate>;
  firstName?: InputMaybe<StringPredicate>;
  id?: InputMaybe<IdPredicate>;
  isSupporterIfExists?: InputMaybe<IntPredicate>;
};

/** Users update input */
export type UserUpdateByFilterInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<UpdateByFilterListStringInput>>>;
  affiliation?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  affiliationDescription?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  birthDate?: InputMaybe<Array<InputMaybe<UpdateByFilterDateInput>>>;
  currentIndustry?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  description?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  email?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  facebookLink?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  firstName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  fullName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  gender?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  graduatingYear?: InputMaybe<Array<InputMaybe<UpdateByFilterDateInput>>>;
  hobbies?: InputMaybe<Array<InputMaybe<UpdateByFilterListStringInput>>>;
  instagramLink?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  is8base?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isFirstAdLogin?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isFirstSwitching?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isNewToAdvertising?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isOwner?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  isSeekingEmployment?: InputMaybe<Array<InputMaybe<UpdateByFilterBooleanSwitchInput>>>;
  lastName?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  linkedInLink?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  origin?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  parents?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
  professionalDesignations?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  registrationCompletedAt?: InputMaybe<Array<InputMaybe<UpdateByFilterDateTimeInput>>>;
  status?: InputMaybe<Array<InputMaybe<UpdateByFilterStringSwitchInput>>>;
  timezone?: InputMaybe<Array<InputMaybe<UpdateByFilterStringInput>>>;
  twitterLink?: InputMaybe<Array<InputMaybe<UpdateByFilterJsonInput>>>;
};

/** Users update input */
export type UserUpdateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksUpdateRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesUpdateRelationInput>;
  adViews?: InputMaybe<UsersAdViewsUpdateRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarUpdateRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsUpdateRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsUpdateRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersUpdateRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsUpdateRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesUpdateRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsUpdateRelationInput>;
  education?: InputMaybe<UsersEducationUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsUpdateRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsUpdateRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsUpdateRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsUpdateRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesUpdateRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsUpdateRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesUpdateRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesUpdateRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsUpdateRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  inboxes?: InputMaybe<UsersInboxesUpdateRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceUpdateRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsUpdateRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostUpdateRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostUpdateRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostUpdateRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostUpdateRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsUpdateRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsUpdateRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesUpdateRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsUpdateRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsUpdateRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsUpdateRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesUpdateRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsUpdateRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsUpdateRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesUpdateRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksUpdateRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesUpdateRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationUpdateRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningUpdateRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersUpdateRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesUpdateRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsUpdateRelationInput>;
};

export type UserUpdateRolesInput = {
  id: Scalars['ID'];
  rolesAddonsIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** AdvertisingCampaignAds create input from userViews */
export type UserViews_AdvertisingCampaignAdCreateInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdRelationInput>;
  callToAction: Scalars['String'];
  callToActionLink: Scalars['String'];
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  primaryText: Scalars['String'];
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign?: InputMaybe<AdvertisingCampaignAdsSampaignRelationInput>;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget: Scalars['Float'];
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

/** AdvertisingCampaignAds update input from userViews */
export type UserViews_AdvertisingCampaignAdUpdateInput = {
  audiences?: InputMaybe<AdvertisingCampaignAdsAudiencesUpdateRelationInput>;
  billingAds?: InputMaybe<AdvertisingCampaignAdsBillingAdsUpdateRelationInput>;
  billingRegisteredAd?: InputMaybe<AdvertisingCampaignAdsBillingRegisteredAdUpdateRelationInput>;
  callToAction?: InputMaybe<Scalars['String']>;
  callToActionLink?: InputMaybe<Scalars['String']>;
  callToActionText?: InputMaybe<Scalars['String']>;
  dailyBudget?: InputMaybe<Scalars['Float']>;
  dailyBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEnabled?: InputMaybe<Scalars['Boolean']>;
  fridayEndDate?: InputMaybe<Scalars['Int']>;
  fridayStartDate?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AdvertisingCampaignAdsImageUpdateRelationInput>;
  mondayEnabled?: InputMaybe<Scalars['Boolean']>;
  mondayEndDate?: InputMaybe<Scalars['Int']>;
  mondayStartDate?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryText?: InputMaybe<Scalars['String']>;
  /** Run the campaign continuously or permanently */
  runConstantly?: InputMaybe<Scalars['Boolean']>;
  sampaign?: InputMaybe<AdvertisingCampaignAdsSampaignUpdateRelationInput>;
  saturdayEnabled?: InputMaybe<Scalars['Boolean']>;
  saturdayEndDate?: InputMaybe<Scalars['Int']>;
  saturdayStartDate?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  sundayEnabled?: InputMaybe<Scalars['Boolean']>;
  sundayEndDate?: InputMaybe<Scalars['Int']>;
  sundayStartDate?: InputMaybe<Scalars['Int']>;
  thursdayEnabled?: InputMaybe<Scalars['Boolean']>;
  thursdayEndDate?: InputMaybe<Scalars['Int']>;
  thursdayStartDate?: InputMaybe<Scalars['Int']>;
  todaySpend?: InputMaybe<Scalars['Float']>;
  totalBudget?: InputMaybe<Scalars['Float']>;
  totalBudgetEnabled?: InputMaybe<Scalars['Boolean']>;
  totalSpend?: InputMaybe<Scalars['Float']>;
  tuesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  tuesdayEndDate?: InputMaybe<Scalars['Int']>;
  tuesdayStartDate?: InputMaybe<Scalars['Int']>;
  userClicks?: InputMaybe<AdvertisingCampaignAdsUserClicksUpdateRelationInput>;
  userLikes?: InputMaybe<AdvertisingCampaignAdsUserLikesUpdateRelationInput>;
  userViews?: InputMaybe<AdvertisingCampaignAdsUserViewsUpdateRelationInput>;
  wednesdayEnabled?: InputMaybe<Scalars['Boolean']>;
  wednesdayEndDate?: InputMaybe<Scalars['Int']>;
  wednesdayStartDate?: InputMaybe<Scalars['Int']>;
};

/** AdClicks create input from user */
export type User_AdClickCreateInput = {
  ad: AdClicksAdRelationInput;
  /** Cost of this impression expressed on USD dollars. */
  cost?: InputMaybe<Scalars['Float']>;
  school?: InputMaybe<AdClicksSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<AdClicksUserRelationInput>;
};

/** AdClicks update input from user */
export type User_AdClickUpdateInput = {
  data: AdClickUpdateInput;
  filter?: InputMaybe<AdClickKeyFilter>;
};

/** AdViews create input from user */
export type User_AdViewCreateInput = {
  ad: AdViewsAdRelationInput;
  /** Cost of this impression expressed on USD dollars. */
  cost?: InputMaybe<Scalars['Float']>;
  school?: InputMaybe<AdViewsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<AdViewsUserRelationInput>;
};

/** AdViews update input from user */
export type User_AdViewUpdateInput = {
  data: AdViewUpdateInput;
  filter?: InputMaybe<AdViewKeyFilter>;
};

/** DiscountRedemptions create input from user */
export type User_DiscountRedemptionCreateInput = {
  discount: DiscountRedemptionsDiscountRelationInput;
  discountId?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<DiscountRedemptionsSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<DiscountRedemptionsUserRelationInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** DiscountRedemptions update input from user */
export type User_DiscountRedemptionUpdateInput = {
  data: DiscountRedemptionUpdateInput;
  filter?: InputMaybe<DiscountRedemptionKeyFilter>;
};

/** EducationalInstitutions create input from user */
export type User_EducationalInstitutionCreateInput = {
  activities?: InputMaybe<Scalars['String']>;
  address: EducationalInstitutionsAddressRelationInput;
  degree?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  fieldOfStudy?: InputMaybe<Scalars['String']>;
  gpa?: InputMaybe<Scalars['Float']>;
  highSchool?: InputMaybe<EducationalInstitutionsHighSchoolRelationInput>;
  highSchoolId?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<EducationalInstitutionsImageRelationInput>;
  imageId?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
  user?: InputMaybe<EducationalInstitutionsUserRelationInput>;
};

/** EducationalInstitutions update input from user */
export type User_EducationalInstitutionUpdateInput = {
  data: EducationalInstitutionUpdateInput;
  filter?: InputMaybe<EducationalInstitutionKeyFilter>;
};

/** GroupAdminPreferences create input from user */
export type User_GroupAdminPreferenceCreateInput = {
  group?: InputMaybe<GroupAdminPreferencesGroupRelationInput>;
  groupPosts?: InputMaybe<GroupAdminPreferencesGroupPostsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<GroupAdminPreferencesUserRelationInput>;
};

/** GroupAdminPreferences update input from user */
export type User_GroupAdminPreferenceUpdateInput = {
  group?: InputMaybe<GroupAdminPreferencesGroupUpdateRelationInput>;
  groupPosts?: InputMaybe<GroupAdminPreferencesGroupPostsUpdateRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<GroupAdminPreferencesUserUpdateRelationInput>;
};

/** GroupsMembersRequests create input from user */
export type User_GroupsMembersRequestCreateInput = {
  group?: InputMaybe<GroupsMembersRequestsGroupRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<GroupsMembersRequestsUserRelationInput>;
};

/** GroupsMembersRequests update input from user */
export type User_GroupsMembersRequestUpdateInput = {
  data: GroupsMembersRequestUpdateInput;
  filter?: InputMaybe<GroupsMembersRequestKeyFilter>;
};

/** JobEmployees create input from user */
export type User_JobEmployeeCreateInput = {
  companyImage?: InputMaybe<JobEmployeesCompanyImageRelationInput>;
  /** Store the company location like address */
  companyLocation?: InputMaybe<SmartAddressInput>;
  /** Store the name of the company without having a real relationship */
  companyName?: InputMaybe<Scalars['String']>;
  employmentType: Scalars['String'];
  endDate?: InputMaybe<Scalars['Date']>;
  isCurrentlyWorking: Scalars['Boolean'];
  positionDescription?: InputMaybe<Scalars['String']>;
  positionTitle: Scalars['String'];
  startDate: Scalars['Date'];
  user?: InputMaybe<JobEmployeesUserRelationInput>;
};

/** JobEmployees update input from user */
export type User_JobEmployeeUpdateInput = {
  data: JobEmployeeUpdateInput;
  filter?: InputMaybe<JobEmployeeKeyFilter>;
};

/** OrganizationLikes create input from user */
export type User_OrganizationLikeCreateInput = {
  organization?: InputMaybe<OrganizationLikesOrganizationRelationInput>;
  user?: InputMaybe<OrganizationLikesUserRelationInput>;
};

/** OrganizationLikes update input from user */
export type User_OrganizationLikeUpdateInput = {
  data: OrganizationLikeUpdateInput;
  filter?: InputMaybe<OrganizationLikeKeyFilter>;
};

/** OrganizationManagerPreferences create input from user */
export type User_OrganizationManagerPreferenceCreateInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  organizations?: InputMaybe<OrganizationManagerPreferencesOrganizationsRelationInput>;
  postedJobOffers?: InputMaybe<OrganizationManagerPreferencesPostedJobOffersRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<OrganizationManagerPreferencesUserRelationInput>;
};

/** OrganizationManagerPreferences update input from user */
export type User_OrganizationManagerPreferenceUpdateInput = {
  data: OrganizationManagerPreferenceUpdateInput;
  filter?: InputMaybe<OrganizationManagerPreferenceKeyFilter>;
};

export type User_PermissionFilter = {
  AND?: InputMaybe<Array<User_PermissionFilter>>;
  OR?: InputMaybe<Array<User_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  adClicks?: InputMaybe<AdClick_PermissionRelationFilter>;
  adLikes?: InputMaybe<AdvertisingCampaignAd_PermissionRelationFilter>;
  adViews?: InputMaybe<AdView_PermissionRelationFilter>;
  affiliation?: InputMaybe<StringPredicate>;
  affiliationDescription?: InputMaybe<StringPredicate>;
  avatar?: InputMaybe<File_PermissionFilter>;
  birthDate?: InputMaybe<DatePredicate>;
  collectiveIntelligenceThreads?: InputMaybe<CollectiveIntelligenceThread_PermissionRelationFilter>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  createdGroups?: InputMaybe<Group_PermissionRelationFilter>;
  createdJobOffers?: InputMaybe<JobOffer_PermissionRelationFilter>;
  createdSchoolNewsPosts?: InputMaybe<SchoolNewsPost_PermissionRelationFilter>;
  currentIndustry?: InputMaybe<StringPredicate>;
  deleteInboxMessages?: InputMaybe<InboxMessage_PermissionRelationFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  description?: InputMaybe<StringPredicate>;
  discountRedemptions?: InputMaybe<DiscountRedemption_PermissionRelationFilter>;
  education?: InputMaybe<EducationalInstitution_PermissionRelationFilter>;
  email?: InputMaybe<StringPredicate>;
  firstName?: InputMaybe<StringPredicate>;
  flaggedGroupPosts?: InputMaybe<GroupPost_PermissionRelationFilter>;
  flaggedHomeFeedPosts?: InputMaybe<HomeFeedPost_PermissionRelationFilter>;
  flaggedLegacyFeedPosts?: InputMaybe<LegacyFeedPost_PermissionRelationFilter>;
  flaggedSchoolNewsPosts?: InputMaybe<SchoolNewsPost_PermissionRelationFilter>;
  fullName?: InputMaybe<StringPredicate>;
  gender?: InputMaybe<StringPredicate>;
  graduatingYear?: InputMaybe<DatePredicate>;
  groupAdminPreferences?: InputMaybe<GroupAdminPreference_PermissionFilter>;
  groupPosts?: InputMaybe<GroupPost_PermissionRelationFilter>;
  hiddenInboxMessages?: InputMaybe<InboxMessage_PermissionRelationFilter>;
  hiddenInboxes?: InputMaybe<Inbox_PermissionRelationFilter>;
  homeFeedPosts?: InputMaybe<HomeFeedPost_PermissionRelationFilter>;
  id?: InputMaybe<IdPredicate>;
  inboxes?: InputMaybe<Inbox_PermissionRelationFilter>;
  is8base?: InputMaybe<BoolPredicate>;
  isFirstAdLogin?: InputMaybe<BoolPredicate>;
  isFirstSwitching?: InputMaybe<BoolPredicate>;
  isNewToAdvertising?: InputMaybe<BoolPredicate>;
  isSeekingEmployment?: InputMaybe<BoolPredicate>;
  is_self?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<JobEmployee_PermissionRelationFilter>;
  joinedGroups?: InputMaybe<Group_PermissionRelationFilter>;
  lastFlaggedGroupPost?: InputMaybe<GroupPost_PermissionFilter>;
  lastFlaggedHomePost?: InputMaybe<HomeFeedPost_PermissionFilter>;
  lastFlaggedLegacyPost?: InputMaybe<LegacyFeedPost_PermissionFilter>;
  lastFlaggedSchoolPost?: InputMaybe<SchoolNewsPost_PermissionFilter>;
  lastName?: InputMaybe<StringPredicate>;
  legacyFeedPosts?: InputMaybe<LegacyFeedPost_PermissionRelationFilter>;
  likedOrganizations?: InputMaybe<OrganizationLike_PermissionRelationFilter>;
  managerPreferences?: InputMaybe<OrganizationManagerPreference_PermissionRelationFilter>;
  not_self?: InputMaybe<Scalars['Boolean']>;
  origin?: InputMaybe<StringPredicate>;
  ownedOrganizations?: InputMaybe<Organization_PermissionRelationFilter>;
  postedEvents?: InputMaybe<Event_PermissionRelationFilter>;
  professionalDesignations?: InputMaybe<StringPredicate>;
  redeemedDiscounts?: InputMaybe<Discount_PermissionRelationFilter>;
  registrationCompletedAt?: InputMaybe<DateTimePredicate>;
  roles?: InputMaybe<Role_PermissionRelationFilter>;
  rolesAddons?: InputMaybe<RoleAddon_PermissionRelationFilter>;
  schoolMemberships?: InputMaybe<UserSchoolMembership_PermissionRelationFilter>;
  sentMessages?: InputMaybe<InboxMessage_PermissionRelationFilter>;
  sentNotifications?: InputMaybe<UserNotification_PermissionRelationFilter>;
  status?: InputMaybe<StringPredicate>;
  supporterClicks?: InputMaybe<SchoolSupporterClick_PermissionRelationFilter>;
  supporterSearches?: InputMaybe<SchoolSupporterSearch_PermissionRelationFilter>;
  timezone?: InputMaybe<StringPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  userConfiguration?: InputMaybe<UserConfiguration_PermissionFilter>;
  userJoining?: InputMaybe<GroupsMembersRequest_PermissionRelationFilter>;
  userNotificationsByUsers?: InputMaybe<UserNotificationsByUser_PermissionRelationFilter>;
  userPreferences?: InputMaybe<UserPreference_PermissionFilter>;
  viewedThreads?: InputMaybe<CollectiveIntelligenceThread_PermissionRelationFilter>;
};

export type User_PermissionRelationFilter = {
  every?: InputMaybe<User_PermissionFilter>;
  none?: InputMaybe<User_PermissionFilter>;
  some?: InputMaybe<User_PermissionFilter>;
};

/** SchoolSupporterClicks create input from user */
export type User_SchoolSupporterClickCreateInput = {
  supporter?: InputMaybe<SchoolSupporterClicksSupporterRelationInput>;
  user?: InputMaybe<SchoolSupporterClicksUserRelationInput>;
};

/** SchoolSupporterClicks update input from user */
export type User_SchoolSupporterClickUpdateInput = {
  data: SchoolSupporterClickUpdateInput;
  filter?: InputMaybe<SchoolSupporterClickKeyFilter>;
};

/** SchoolSupporterSearches create input from user */
export type User_SchoolSupporterSearchCreateInput = {
  supporter?: InputMaybe<SchoolSupporterSearchesSupporterRelationInput>;
  user?: InputMaybe<SchoolSupporterSearchesUserRelationInput>;
};

/** SchoolSupporterSearches update input from user */
export type User_SchoolSupporterSearchUpdateInput = {
  data: SchoolSupporterSearchUpdateInput;
  filter?: InputMaybe<SchoolSupporterSearchKeyFilter>;
};

/** UserConfiguration create input from user */
export type User_UserConfigurationCreateInput = {
  /** Used when user registers */
  accessCodeHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserConfigurationUserRelationInput>;
};

/** UserConfiguration update input from user */
export type User_UserConfigurationUpdateInput = {
  /** Used when user registers */
  accessCodeHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserConfigurationUserUpdateRelationInput>;
};

/** UserPreferences create input from user */
export type User_UserPreferenceCreateInput = {
  address?: InputMaybe<UserPreferencesAddressRelationInput>;
  jobsOfInterest?: InputMaybe<UserPreferencesJobsOfInterestRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  secondaryPhoneNumber?: InputMaybe<Scalars['String']>;
  stripeInformation?: InputMaybe<UserPreferencesStripeInformationRelationInput>;
  user?: InputMaybe<UserPreferencesUserRelationInput>;
};

/** UserPreferences update input from user */
export type User_UserPreferenceUpdateInput = {
  address?: InputMaybe<UserPreferencesAddressUpdateRelationInput>;
  jobsOfInterest?: InputMaybe<UserPreferencesJobsOfInterestUpdateRelationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  secondaryPhoneNumber?: InputMaybe<Scalars['String']>;
  stripeInformation?: InputMaybe<UserPreferencesStripeInformationUpdateRelationInput>;
  user?: InputMaybe<UserPreferencesUserUpdateRelationInput>;
};

/** UserSchoolMembership create input from user */
export type User_UserSchoolMembershipCreateInput = {
  school?: InputMaybe<UserSchoolMembershipSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  /** Status of the current membership of the user. */
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserSchoolMembershipUserRelationInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** UserSchoolMembership update input from user */
export type User_UserSchoolMembershipUpdateInput = {
  data: UserSchoolMembershipUpdateInput;
  filter?: InputMaybe<UserSchoolMembershipKeyFilter>;
};

/** Users relation input */
export type UsersAdClicksManyRelationInput = {
  connect?: InputMaybe<Array<AdClickKeyFilter>>;
};

/** Users relation input */
export type UsersAdClicksRelationInput = {
  connect?: InputMaybe<Array<AdClickKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_AdClickCreateInput>>>;
};

/** Users relation input */
export type UsersAdClicksUpdateRelationInput = {
  connect?: InputMaybe<Array<AdClickKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_AdClickCreateInput>>>;
  disconnect?: InputMaybe<Array<AdClickKeyFilter>>;
  reconnect?: InputMaybe<Array<AdClickKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<User_AdClickUpdateInput>>>;
};

/** Users relation input */
export type UsersAdLikesManyRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
};

/** Users relation input */
export type UsersAdLikesRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<UserLikes_AdvertisingCampaignAdCreateInput>>>;
};

/** Users relation input */
export type UsersAdLikesUpdateRelationInput = {
  connect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<UserLikes_AdvertisingCampaignAdCreateInput>>>;
  disconnect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  reconnect?: InputMaybe<Array<AdvertisingCampaignAdKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<UserLikes_AdvertisingCampaignAdUpdateInput>>>;
};

/** Users relation input */
export type UsersAdViewsManyRelationInput = {
  connect?: InputMaybe<Array<AdViewKeyFilter>>;
};

/** Users relation input */
export type UsersAdViewsRelationInput = {
  connect?: InputMaybe<Array<AdViewKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_AdViewCreateInput>>>;
};

/** Users relation input */
export type UsersAdViewsUpdateRelationInput = {
  connect?: InputMaybe<Array<AdViewKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_AdViewCreateInput>>>;
  disconnect?: InputMaybe<Array<AdViewKeyFilter>>;
  reconnect?: InputMaybe<Array<AdViewKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<User_AdViewUpdateInput>>>;
};

/** Addresses create input from usersAdresses */
export type UsersAdresses_AddressCreateInput = {
  city?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<AddressesConfigurationRelationInput>;
  educationalInstitutions?: InputMaybe<AddressesEducationalInstitutionsRelationInput>;
  events?: InputMaybe<AddressesEventsRelationInput>;
  organizations?: InputMaybe<AddressesOrganizationsRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  usersAdresses?: InputMaybe<AddressesUsersAdressesRelationInput>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Addresses update input from usersAdresses */
export type UsersAdresses_AddressUpdateInput = {
  city?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<AddressesConfigurationUpdateRelationInput>;
  educationalInstitutions?: InputMaybe<AddressesEducationalInstitutionsUpdateRelationInput>;
  events?: InputMaybe<AddressesEventsUpdateRelationInput>;
  organizations?: InputMaybe<AddressesOrganizationsUpdateRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  usersAdresses?: InputMaybe<AddressesUsersAdressesUpdateRelationInput>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Users relation input */
export type UsersAvatarManyRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
};

/** Users relation input */
export type UsersAvatarRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<Users_Avatar_FileCreateInput>;
};

/** Users relation input */
export type UsersAvatarUpdateRelationInput = {
  connect?: InputMaybe<FileKeyFilter>;
  create?: InputMaybe<Users_Avatar_FileCreateInput>;
  disconnect?: InputMaybe<FileKeyFilter>;
  reconnect?: InputMaybe<FileKeyFilter>;
  update?: InputMaybe<Users_Avatar_FileUpdateInput>;
};

/** Users relation input */
export type UsersCollectiveIntelligenceThreadsManyRelationInput = {
  connect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
};

/** Users relation input */
export type UsersCollectiveIntelligenceThreadsRelationInput = {
  connect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Creator_CollectiveIntelligenceThreadCreateInput>>>;
};

/** Users relation input */
export type UsersCollectiveIntelligenceThreadsUpdateRelationInput = {
  connect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Creator_CollectiveIntelligenceThreadCreateInput>>>;
  disconnect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  reconnect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Creator_CollectiveIntelligenceThreadUpdateInput>>>;
};

export type UsersCount = {
  __typename?: 'UsersCount';
  _description?: Maybe<Scalars['String']>;
  alumni?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  parents?: Maybe<Scalars['Int']>;
  pending?: Maybe<Scalars['Int']>;
  staff?: Maybe<Scalars['Int']>;
  students?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usersCount?: Maybe<SchoolConfiguration>;
};

/** UsersCount create input */
export type UsersCountCreateInput = {
  alumni?: InputMaybe<Scalars['Int']>;
  parents?: InputMaybe<Scalars['Int']>;
  pending?: InputMaybe<Scalars['Int']>;
  staff?: InputMaybe<Scalars['Int']>;
  students?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
  usersCount?: InputMaybe<UsersCountUsersCountRelationInput>;
};

/** UsersCount create many input */
export type UsersCountCreateManyInput = {
  alumni?: InputMaybe<Scalars['Int']>;
  parents?: InputMaybe<Scalars['Int']>;
  pending?: InputMaybe<Scalars['Int']>;
  staff?: InputMaybe<Scalars['Int']>;
  students?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
  usersCount?: InputMaybe<UsersCountUsersCountManyRelationInput>;
};

/** UsersCount delete input */
export type UsersCountDeleteInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** UsersCountFieldsPermissions create input */
export type UsersCountFieldsPermissions = {
  alumni?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  parents?: InputMaybe<Scalars['Boolean']>;
  pending?: InputMaybe<Scalars['Boolean']>;
  staff?: InputMaybe<Scalars['Boolean']>;
  students?: InputMaybe<Scalars['Boolean']>;
  total?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type UsersCountFilter = {
  AND?: InputMaybe<Array<UsersCountFilter>>;
  OR?: InputMaybe<Array<UsersCountFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  alumni?: InputMaybe<IntPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<UserFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  parents?: InputMaybe<IntPredicate>;
  pending?: InputMaybe<IntPredicate>;
  staff?: InputMaybe<IntPredicate>;
  students?: InputMaybe<IntPredicate>;
  total?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  usersCount?: InputMaybe<SchoolConfigurationFilter>;
};

export type UsersCountGroupBy = {
  first?: InputMaybe<Scalars['Int']>;
  having?: InputMaybe<Having>;
  last?: InputMaybe<Scalars['Int']>;
  query: UsersCountGroupByQuery;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupBySort>>;
};

export type UsersCountGroupByQuery = {
  _group?: InputMaybe<Array<GroupIdentifiersGroupByField>>;
  alumni?: InputMaybe<Array<GroupByField>>;
  createdAt?: InputMaybe<Array<GroupByField>>;
  createdBy?: InputMaybe<UserGroupByQuery>;
  id?: InputMaybe<Array<GroupByField>>;
  parents?: InputMaybe<Array<GroupByField>>;
  pending?: InputMaybe<Array<GroupByField>>;
  staff?: InputMaybe<Array<GroupByField>>;
  students?: InputMaybe<Array<GroupByField>>;
  total?: InputMaybe<Array<GroupByField>>;
  updatedAt?: InputMaybe<Array<GroupByField>>;
  usersCount?: InputMaybe<SchoolConfigurationGroupByQuery>;
};

export type UsersCountKeyFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

/** UsersCountListResponse output */
export type UsersCountListResponse = {
  __typename?: 'UsersCountListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<UsersCount>;
};

/** UsersCountManyResponse output */
export type UsersCountManyResponse = {
  __typename?: 'UsersCountManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<UsersCount>;
};

/** No longer supported. Use `sort` instead. */
export type UsersCountOrderBy =
  | 'alumni_ASC'
  | 'alumni_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC'
  | 'deletedAt_ASC'
  | 'deletedAt_DESC'
  | 'id_ASC'
  | 'id_DESC'
  | 'parents_ASC'
  | 'parents_DESC'
  | 'pending_ASC'
  | 'pending_DESC'
  | 'staff_ASC'
  | 'staff_DESC'
  | 'students_ASC'
  | 'students_DESC'
  | 'total_ASC'
  | 'total_DESC'
  | 'updatedAt_ASC'
  | 'updatedAt_DESC';

/** UsersCount subscription payload */
export type UsersCountPayload = {
  __typename?: 'UsersCountPayload';
  mutation: MutationType;
  node?: Maybe<UsersCount>;
  previousValues?: Maybe<UsersCount>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UsersCountSort = {
  alumni?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserSort>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parents?: InputMaybe<SortOrder>;
  pending?: InputMaybe<SortOrder>;
  staff?: InputMaybe<SortOrder>;
  students?: InputMaybe<SortOrder>;
  total?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  usersCount?: InputMaybe<SchoolConfigurationSort>;
};

/** UsersCount subscription filter */
export type UsersCountSubscriptionFilter = {
  mutation_in?: InputMaybe<Array<InputMaybe<MutationType>>>;
  node?: InputMaybe<UsersCountFilter>;
  updatedFields?: InputMaybe<UpdatedFieldsFilter>;
};

/** UsersCount update input */
export type UsersCountUpdateByFilterInput = {
  alumni?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  parents?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  pending?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  staff?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  students?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
  total?: InputMaybe<Array<InputMaybe<UpdateByFilterIntInput>>>;
};

/** UsersCount update input */
export type UsersCountUpdateInput = {
  alumni?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  parents?: InputMaybe<Scalars['Int']>;
  pending?: InputMaybe<Scalars['Int']>;
  staff?: InputMaybe<Scalars['Int']>;
  students?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
  usersCount?: InputMaybe<UsersCountUsersCountUpdateRelationInput>;
};

/** UsersCount relation input */
export type UsersCountUsersCountManyRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
};

/** UsersCount relation input */
export type UsersCountUsersCountRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<UsersCount_SchoolConfigurationCreateInput>;
};

/** UsersCount relation input */
export type UsersCountUsersCountUpdateRelationInput = {
  connect?: InputMaybe<SchoolConfigurationKeyFilter>;
  create?: InputMaybe<UsersCount_SchoolConfigurationCreateInput>;
  disconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  reconnect?: InputMaybe<SchoolConfigurationKeyFilter>;
  update?: InputMaybe<UsersCount_SchoolConfigurationUpdateInput>;
};

export type UsersCount_PermissionFilter = {
  AND?: InputMaybe<Array<UsersCount_PermissionFilter>>;
  OR?: InputMaybe<Array<UsersCount_PermissionFilter>>;
  _fullText?: InputMaybe<Scalars['String']>;
  alumni?: InputMaybe<IntPredicate>;
  createdAt?: InputMaybe<DateTimePredicate>;
  createdBy?: InputMaybe<User_PermissionFilter>;
  deletedAt?: InputMaybe<IntPredicate>;
  id?: InputMaybe<IdPredicate>;
  parents?: InputMaybe<IntPredicate>;
  pending?: InputMaybe<IntPredicate>;
  staff?: InputMaybe<IntPredicate>;
  students?: InputMaybe<IntPredicate>;
  total?: InputMaybe<IntPredicate>;
  updatedAt?: InputMaybe<DateTimePredicate>;
  usersCount?: InputMaybe<SchoolConfiguration_PermissionFilter>;
};

/** SchoolConfiguration create input from usersCount */
export type UsersCount_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from usersCount */
export type UsersCount_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** UsersCount create input from usersCount */
export type UsersCount_UsersCountCreateInput = {
  alumni?: InputMaybe<Scalars['Int']>;
  parents?: InputMaybe<Scalars['Int']>;
  pending?: InputMaybe<Scalars['Int']>;
  staff?: InputMaybe<Scalars['Int']>;
  students?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
  usersCount?: InputMaybe<UsersCountUsersCountRelationInput>;
};

/** UsersCount update input from usersCount */
export type UsersCount_UsersCountUpdateInput = {
  alumni?: InputMaybe<Scalars['Int']>;
  parents?: InputMaybe<Scalars['Int']>;
  pending?: InputMaybe<Scalars['Int']>;
  staff?: InputMaybe<Scalars['Int']>;
  students?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
  usersCount?: InputMaybe<UsersCountUsersCountUpdateRelationInput>;
};

/** Users relation input */
export type UsersCreatedGroupsManyRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
};

/** Users relation input */
export type UsersCreatedGroupsRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Creator_GroupCreateInput>>>;
};

/** Users relation input */
export type UsersCreatedGroupsUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Creator_GroupCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Creator_GroupUpdateInput>>>;
};

/** Users relation input */
export type UsersCreatedJobOffersManyRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
};

/** Users relation input */
export type UsersCreatedJobOffersRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Creator_JobOfferCreateInput>>>;
};

/** Users relation input */
export type UsersCreatedJobOffersUpdateRelationInput = {
  connect?: InputMaybe<Array<JobOfferKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Creator_JobOfferCreateInput>>>;
  disconnect?: InputMaybe<Array<JobOfferKeyFilter>>;
  reconnect?: InputMaybe<Array<JobOfferKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Creator_JobOfferUpdateInput>>>;
};

/** Users relation input */
export type UsersCreatedSchoolNewsPostsManyRelationInput = {
  connect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
};

/** Users relation input */
export type UsersCreatedSchoolNewsPostsRelationInput = {
  connect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Author_SchoolNewsPostCreateInput>>>;
};

/** Users relation input */
export type UsersCreatedSchoolNewsPostsUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Author_SchoolNewsPostCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Author_SchoolNewsPostUpdateInput>>>;
};

/** Users relation input */
export type UsersDeleteInboxMessagesManyRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
};

/** Users relation input */
export type UsersDeleteInboxMessagesRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<DeletedBy_InboxMessageCreateInput>>>;
};

/** Users relation input */
export type UsersDeleteInboxMessagesUpdateRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<DeletedBy_InboxMessageCreateInput>>>;
  disconnect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  reconnect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<DeletedBy_InboxMessageUpdateInput>>>;
};

/** Users relation input */
export type UsersDiscountRedemptionsManyRelationInput = {
  connect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
};

/** Users relation input */
export type UsersDiscountRedemptionsRelationInput = {
  connect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_DiscountRedemptionCreateInput>>>;
};

/** Users relation input */
export type UsersDiscountRedemptionsUpdateRelationInput = {
  connect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_DiscountRedemptionCreateInput>>>;
  disconnect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
  reconnect?: InputMaybe<Array<DiscountRedemptionKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<User_DiscountRedemptionUpdateInput>>>;
};

/** Users relation input */
export type UsersEducationManyRelationInput = {
  connect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
};

/** Users relation input */
export type UsersEducationRelationInput = {
  connect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_EducationalInstitutionCreateInput>>>;
};

/** Users relation input */
export type UsersEducationUpdateRelationInput = {
  connect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_EducationalInstitutionCreateInput>>>;
  disconnect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
  reconnect?: InputMaybe<Array<EducationalInstitutionKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<User_EducationalInstitutionUpdateInput>>>;
};

/** Users relation input */
export type UsersFlaggedGroupPostsManyRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
};

/** Users relation input */
export type UsersFlaggedGroupPostsRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedBy_GroupPostCreateInput>>>;
};

/** Users relation input */
export type UsersFlaggedGroupPostsUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedBy_GroupPostCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupPostKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<FlaggedBy_GroupPostUpdateInput>>>;
};

/** Users relation input */
export type UsersFlaggedHomeFeedPostsManyRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
};

/** Users relation input */
export type UsersFlaggedHomeFeedPostsRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedBy_HomeFeedPostCreateInput>>>;
};

/** Users relation input */
export type UsersFlaggedHomeFeedPostsUpdateRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedBy_HomeFeedPostCreateInput>>>;
  disconnect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  reconnect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<FlaggedBy_HomeFeedPostUpdateInput>>>;
};

/** Users relation input */
export type UsersFlaggedLegacyFeedPostsManyRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
};

/** Users relation input */
export type UsersFlaggedLegacyFeedPostsRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedBy_LegacyFeedPostCreateInput>>>;
};

/** Users relation input */
export type UsersFlaggedLegacyFeedPostsUpdateRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedBy_LegacyFeedPostCreateInput>>>;
  disconnect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  reconnect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<FlaggedBy_LegacyFeedPostUpdateInput>>>;
};

/** Users relation input */
export type UsersFlaggedSchoolNewsPostsManyRelationInput = {
  connect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
};

/** Users relation input */
export type UsersFlaggedSchoolNewsPostsRelationInput = {
  connect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedBy_SchoolNewsPostCreateInput>>>;
};

/** Users relation input */
export type UsersFlaggedSchoolNewsPostsUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<FlaggedBy_SchoolNewsPostCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolNewsPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<FlaggedBy_SchoolNewsPostUpdateInput>>>;
};

/** Users relation input */
export type UsersGroupAdminPreferencesManyRelationInput = {
  connect?: InputMaybe<GroupAdminPreferenceKeyFilter>;
};

/** Users relation input */
export type UsersGroupAdminPreferencesRelationInput = {
  connect?: InputMaybe<GroupAdminPreferenceKeyFilter>;
  create?: InputMaybe<User_GroupAdminPreferenceCreateInput>;
};

/** Users relation input */
export type UsersGroupAdminPreferencesUpdateRelationInput = {
  connect?: InputMaybe<GroupAdminPreferenceKeyFilter>;
  create?: InputMaybe<User_GroupAdminPreferenceCreateInput>;
  disconnect?: InputMaybe<GroupAdminPreferenceKeyFilter>;
  reconnect?: InputMaybe<GroupAdminPreferenceKeyFilter>;
  update?: InputMaybe<User_GroupAdminPreferenceUpdateInput>;
};

/** Users relation input */
export type UsersGroupPostsManyRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
};

/** Users relation input */
export type UsersGroupPostsRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Author_GroupPostCreateInput>>>;
};

/** Users relation input */
export type UsersGroupPostsUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Author_GroupPostCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupPostKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Author_GroupPostUpdateInput>>>;
};

/** Users relation input */
export type UsersHiddenInboxMessagesManyRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
};

/** Users relation input */
export type UsersHiddenInboxMessagesRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<IsHide_InboxMessageCreateInput>>>;
};

/** Users relation input */
export type UsersHiddenInboxMessagesUpdateRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<IsHide_InboxMessageCreateInput>>>;
  disconnect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  reconnect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<IsHide_InboxMessageUpdateInput>>>;
};

/** Users relation input */
export type UsersHiddenInboxesManyRelationInput = {
  connect?: InputMaybe<Array<InboxKeyFilter>>;
};

/** Users relation input */
export type UsersHiddenInboxesRelationInput = {
  connect?: InputMaybe<Array<InboxKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<IsHide_InboxCreateInput>>>;
};

/** Users relation input */
export type UsersHiddenInboxesUpdateRelationInput = {
  connect?: InputMaybe<Array<InboxKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<IsHide_InboxCreateInput>>>;
  disconnect?: InputMaybe<Array<InboxKeyFilter>>;
  reconnect?: InputMaybe<Array<InboxKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<IsHide_InboxUpdateInput>>>;
};

/** Users relation input */
export type UsersHomeFeedPostsManyRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
};

/** Users relation input */
export type UsersHomeFeedPostsRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Author_HomeFeedPostCreateInput>>>;
};

/** Users relation input */
export type UsersHomeFeedPostsUpdateRelationInput = {
  connect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Author_HomeFeedPostCreateInput>>>;
  disconnect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  reconnect?: InputMaybe<Array<HomeFeedPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Author_HomeFeedPostUpdateInput>>>;
};

/** Users relation input */
export type UsersInboxesManyRelationInput = {
  connect?: InputMaybe<Array<InboxKeyFilter>>;
};

/** Users relation input */
export type UsersInboxesRelationInput = {
  connect?: InputMaybe<Array<InboxKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Users_InboxCreateInput>>>;
};

/** Users relation input */
export type UsersInboxesUpdateRelationInput = {
  connect?: InputMaybe<Array<InboxKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Users_InboxCreateInput>>>;
  disconnect?: InputMaybe<Array<InboxKeyFilter>>;
  reconnect?: InputMaybe<Array<InboxKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Users_InboxUpdateInput>>>;
};

/** Users relation input */
export type UsersJobExperienceManyRelationInput = {
  connect?: InputMaybe<Array<JobEmployeeKeyFilter>>;
};

/** Users relation input */
export type UsersJobExperienceRelationInput = {
  connect?: InputMaybe<Array<JobEmployeeKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_JobEmployeeCreateInput>>>;
};

/** Users relation input */
export type UsersJobExperienceUpdateRelationInput = {
  connect?: InputMaybe<Array<JobEmployeeKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_JobEmployeeCreateInput>>>;
  disconnect?: InputMaybe<Array<JobEmployeeKeyFilter>>;
  reconnect?: InputMaybe<Array<JobEmployeeKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<User_JobEmployeeUpdateInput>>>;
};

/** Users relation input */
export type UsersJoinedGroupsManyRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
};

/** Users relation input */
export type UsersJoinedGroupsRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Members_GroupCreateInput>>>;
};

/** Users relation input */
export type UsersJoinedGroupsUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Members_GroupCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Members_GroupUpdateInput>>>;
};

/** Users relation input */
export type UsersLastFlaggedGroupPostManyRelationInput = {
  connect?: InputMaybe<GroupPostKeyFilter>;
};

/** Users relation input */
export type UsersLastFlaggedGroupPostRelationInput = {
  connect?: InputMaybe<GroupPostKeyFilter>;
  create?: InputMaybe<LastFlaggedBy_GroupPostCreateInput>;
};

/** Users relation input */
export type UsersLastFlaggedGroupPostUpdateRelationInput = {
  connect?: InputMaybe<GroupPostKeyFilter>;
  create?: InputMaybe<LastFlaggedBy_GroupPostCreateInput>;
  disconnect?: InputMaybe<GroupPostKeyFilter>;
  reconnect?: InputMaybe<GroupPostKeyFilter>;
  update?: InputMaybe<LastFlaggedBy_GroupPostUpdateInput>;
};

/** Users relation input */
export type UsersLastFlaggedHomePostManyRelationInput = {
  connect?: InputMaybe<HomeFeedPostKeyFilter>;
};

/** Users relation input */
export type UsersLastFlaggedHomePostRelationInput = {
  connect?: InputMaybe<HomeFeedPostKeyFilter>;
  create?: InputMaybe<LastFlaggedBy_HomeFeedPostCreateInput>;
};

/** Users relation input */
export type UsersLastFlaggedHomePostUpdateRelationInput = {
  connect?: InputMaybe<HomeFeedPostKeyFilter>;
  create?: InputMaybe<LastFlaggedBy_HomeFeedPostCreateInput>;
  disconnect?: InputMaybe<HomeFeedPostKeyFilter>;
  reconnect?: InputMaybe<HomeFeedPostKeyFilter>;
  update?: InputMaybe<LastFlaggedBy_HomeFeedPostUpdateInput>;
};

/** Users relation input */
export type UsersLastFlaggedLegacyPostManyRelationInput = {
  connect?: InputMaybe<LegacyFeedPostKeyFilter>;
};

/** Users relation input */
export type UsersLastFlaggedLegacyPostRelationInput = {
  connect?: InputMaybe<LegacyFeedPostKeyFilter>;
  create?: InputMaybe<LastFlaggedBy_LegacyFeedPostCreateInput>;
};

/** Users relation input */
export type UsersLastFlaggedLegacyPostUpdateRelationInput = {
  connect?: InputMaybe<LegacyFeedPostKeyFilter>;
  create?: InputMaybe<LastFlaggedBy_LegacyFeedPostCreateInput>;
  disconnect?: InputMaybe<LegacyFeedPostKeyFilter>;
  reconnect?: InputMaybe<LegacyFeedPostKeyFilter>;
  update?: InputMaybe<LastFlaggedBy_LegacyFeedPostUpdateInput>;
};

/** Users relation input */
export type UsersLastFlaggedSchoolPostManyRelationInput = {
  connect?: InputMaybe<SchoolNewsPostKeyFilter>;
};

/** Users relation input */
export type UsersLastFlaggedSchoolPostRelationInput = {
  connect?: InputMaybe<SchoolNewsPostKeyFilter>;
  create?: InputMaybe<LastFlaggedBy_SchoolNewsPostCreateInput>;
};

/** Users relation input */
export type UsersLastFlaggedSchoolPostUpdateRelationInput = {
  connect?: InputMaybe<SchoolNewsPostKeyFilter>;
  create?: InputMaybe<LastFlaggedBy_SchoolNewsPostCreateInput>;
  disconnect?: InputMaybe<SchoolNewsPostKeyFilter>;
  reconnect?: InputMaybe<SchoolNewsPostKeyFilter>;
  update?: InputMaybe<LastFlaggedBy_SchoolNewsPostUpdateInput>;
};

/** Users relation input */
export type UsersLegacyFeedPostsManyRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
};

/** Users relation input */
export type UsersLegacyFeedPostsRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Author_LegacyFeedPostCreateInput>>>;
};

/** Users relation input */
export type UsersLegacyFeedPostsUpdateRelationInput = {
  connect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Author_LegacyFeedPostCreateInput>>>;
  disconnect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  reconnect?: InputMaybe<Array<LegacyFeedPostKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Author_LegacyFeedPostUpdateInput>>>;
};

/** Users relation input */
export type UsersLikedOrganizationsManyRelationInput = {
  connect?: InputMaybe<Array<OrganizationLikeKeyFilter>>;
};

/** Users relation input */
export type UsersLikedOrganizationsRelationInput = {
  connect?: InputMaybe<Array<OrganizationLikeKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_OrganizationLikeCreateInput>>>;
};

/** Users relation input */
export type UsersLikedOrganizationsUpdateRelationInput = {
  connect?: InputMaybe<Array<OrganizationLikeKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_OrganizationLikeCreateInput>>>;
  disconnect?: InputMaybe<Array<OrganizationLikeKeyFilter>>;
  reconnect?: InputMaybe<Array<OrganizationLikeKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<User_OrganizationLikeUpdateInput>>>;
};

/** Users relation input */
export type UsersManagerPreferencesManyRelationInput = {
  connect?: InputMaybe<Array<OrganizationManagerPreferenceKeyFilter>>;
};

/** Users relation input */
export type UsersManagerPreferencesRelationInput = {
  connect?: InputMaybe<Array<OrganizationManagerPreferenceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_OrganizationManagerPreferenceCreateInput>>>;
};

/** Users relation input */
export type UsersManagerPreferencesUpdateRelationInput = {
  connect?: InputMaybe<Array<OrganizationManagerPreferenceKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_OrganizationManagerPreferenceCreateInput>>>;
  disconnect?: InputMaybe<Array<OrganizationManagerPreferenceKeyFilter>>;
  reconnect?: InputMaybe<Array<OrganizationManagerPreferenceKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<User_OrganizationManagerPreferenceUpdateInput>>>;
};

/** Users relation input */
export type UsersOwnedOrganizationsManyRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
};

/** Users relation input */
export type UsersOwnedOrganizationsRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Owner_OrganizationCreateInput>>>;
};

/** Users relation input */
export type UsersOwnedOrganizationsUpdateRelationInput = {
  connect?: InputMaybe<Array<OrganizationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Owner_OrganizationCreateInput>>>;
  disconnect?: InputMaybe<Array<OrganizationKeyFilter>>;
  reconnect?: InputMaybe<Array<OrganizationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Owner_OrganizationUpdateInput>>>;
};

/** Users relation input */
export type UsersPostedEventsManyRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
};

/** Users relation input */
export type UsersPostedEventsRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Author_EventCreateInput>>>;
};

/** Users relation input */
export type UsersPostedEventsUpdateRelationInput = {
  connect?: InputMaybe<Array<EventKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Author_EventCreateInput>>>;
  disconnect?: InputMaybe<Array<EventKeyFilter>>;
  reconnect?: InputMaybe<Array<EventKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Author_EventUpdateInput>>>;
};

/** Users relation input */
export type UsersRedeemedDiscountsManyRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
};

/** Users relation input */
export type UsersRedeemedDiscountsRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<UsersRedeemed_DiscountCreateInput>>>;
};

/** Users relation input */
export type UsersRedeemedDiscountsUpdateRelationInput = {
  connect?: InputMaybe<Array<DiscountKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<UsersRedeemed_DiscountCreateInput>>>;
  disconnect?: InputMaybe<Array<DiscountKeyFilter>>;
  reconnect?: InputMaybe<Array<DiscountKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<UsersRedeemed_DiscountUpdateInput>>>;
};

/** Discounts create input from usersRedeemed */
export type UsersRedeemed_DiscountCreateInput = {
  advertising?: InputMaybe<DiscountsAdvertisingRelationInput>;
  amountPercent: Scalars['Int'];
  billingRegisteredDiscounts?: InputMaybe<DiscountsBillingRegisteredDiscountsRelationInput>;
  category?: InputMaybe<Scalars['String']>;
  discountRedeemPost?: InputMaybe<DiscountsDiscountRedeemPostRelationInput>;
  expirationDate: Scalars['DateTime'];
  images?: InputMaybe<DiscountsImagesRelationInput>;
  /**
   * true - created by admin
   * false - created by organization
   */
  isSchoolDiscount?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<DiscountsOrganizationRelationInput>;
  payment?: InputMaybe<DiscountsPaymentRelationInput>;
  redemptions?: InputMaybe<DiscountsRedemptionsRelationInput>;
  schools?: InputMaybe<DiscountsSchoolsRelationInput>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  terms: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalSpent?: InputMaybe<Scalars['Int']>;
  totalViews?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  usersRedeemed?: InputMaybe<DiscountsUsersRedeemedRelationInput>;
  usersRedeemedCount?: InputMaybe<Scalars['Int']>;
};

/** Discounts update input from usersRedeemed */
export type UsersRedeemed_DiscountUpdateInput = {
  data: DiscountUpdateInput;
  filter?: InputMaybe<DiscountKeyFilter>;
};

/** Users relation input */
export type UsersRolesAddonsManyRelationInput = {
  connect?: InputMaybe<Array<RoleAddonKeyFilter>>;
};

/** Users relation input */
export type UsersRolesAddonsRelationInput = {
  connect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Users_RoleAddonCreateInput>>>;
};

/** Users relation input */
export type UsersRolesAddonsUpdateRelationInput = {
  connect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Users_RoleAddonCreateInput>>>;
  disconnect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  reconnect?: InputMaybe<Array<RoleAddonKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Users_RoleAddonUpdateInput>>>;
};

/** Users relation input */
export type UsersRolesManyRelationInput = {
  connect?: InputMaybe<Array<RoleKeyFilter>>;
};

/** Users relation input */
export type UsersRolesRelationInput = {
  connect?: InputMaybe<Array<RoleKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Users_RoleCreateInput>>>;
};

/** Users relation input */
export type UsersRolesUpdateRelationInput = {
  connect?: InputMaybe<Array<RoleKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Users_RoleCreateInput>>>;
  disconnect?: InputMaybe<Array<RoleKeyFilter>>;
  reconnect?: InputMaybe<Array<RoleKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Users_RoleUpdateInput>>>;
};

/** Users relation input */
export type UsersSchoolMembershipsManyRelationInput = {
  connect?: InputMaybe<Array<UserSchoolMembershipKeyFilter>>;
};

/** Users relation input */
export type UsersSchoolMembershipsRelationInput = {
  connect?: InputMaybe<Array<UserSchoolMembershipKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_UserSchoolMembershipCreateInput>>>;
};

/** Users relation input */
export type UsersSchoolMembershipsUpdateRelationInput = {
  connect?: InputMaybe<Array<UserSchoolMembershipKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_UserSchoolMembershipCreateInput>>>;
  disconnect?: InputMaybe<Array<UserSchoolMembershipKeyFilter>>;
  reconnect?: InputMaybe<Array<UserSchoolMembershipKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<User_UserSchoolMembershipUpdateInput>>>;
};

/** Users relation input */
export type UsersSentMessagesManyRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
};

/** Users relation input */
export type UsersSentMessagesRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Author_InboxMessageCreateInput>>>;
};

/** Users relation input */
export type UsersSentMessagesUpdateRelationInput = {
  connect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Author_InboxMessageCreateInput>>>;
  disconnect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  reconnect?: InputMaybe<Array<InboxMessageKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Author_InboxMessageUpdateInput>>>;
};

/** Users relation input */
export type UsersSentNotificationsManyRelationInput = {
  connect?: InputMaybe<Array<UserNotificationKeyFilter>>;
};

/** Users relation input */
export type UsersSentNotificationsRelationInput = {
  connect?: InputMaybe<Array<UserNotificationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Sender_UserNotificationCreateInput>>>;
};

/** Users relation input */
export type UsersSentNotificationsUpdateRelationInput = {
  connect?: InputMaybe<Array<UserNotificationKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Sender_UserNotificationCreateInput>>>;
  disconnect?: InputMaybe<Array<UserNotificationKeyFilter>>;
  reconnect?: InputMaybe<Array<UserNotificationKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Sender_UserNotificationUpdateInput>>>;
};

/** Users relation input */
export type UsersSupporterClicksManyRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterClickKeyFilter>>;
};

/** Users relation input */
export type UsersSupporterClicksRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterClickKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_SchoolSupporterClickCreateInput>>>;
};

/** Users relation input */
export type UsersSupporterClicksUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterClickKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_SchoolSupporterClickCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolSupporterClickKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolSupporterClickKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<User_SchoolSupporterClickUpdateInput>>>;
};

/** Users relation input */
export type UsersSupporterSearchesManyRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterSearchKeyFilter>>;
};

/** Users relation input */
export type UsersSupporterSearchesRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterSearchKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_SchoolSupporterSearchCreateInput>>>;
};

/** Users relation input */
export type UsersSupporterSearchesUpdateRelationInput = {
  connect?: InputMaybe<Array<SchoolSupporterSearchKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_SchoolSupporterSearchCreateInput>>>;
  disconnect?: InputMaybe<Array<SchoolSupporterSearchKeyFilter>>;
  reconnect?: InputMaybe<Array<SchoolSupporterSearchKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<User_SchoolSupporterSearchUpdateInput>>>;
};

/** Users relation input */
export type UsersUserConfigurationManyRelationInput = {
  connect?: InputMaybe<UserConfigurationKeyFilter>;
};

/** Users relation input */
export type UsersUserConfigurationRelationInput = {
  connect?: InputMaybe<UserConfigurationKeyFilter>;
  create?: InputMaybe<User_UserConfigurationCreateInput>;
};

/** Users relation input */
export type UsersUserConfigurationUpdateRelationInput = {
  connect?: InputMaybe<UserConfigurationKeyFilter>;
  create?: InputMaybe<User_UserConfigurationCreateInput>;
  disconnect?: InputMaybe<UserConfigurationKeyFilter>;
  reconnect?: InputMaybe<UserConfigurationKeyFilter>;
  update?: InputMaybe<User_UserConfigurationUpdateInput>;
};

/** Users relation input */
export type UsersUserJoiningManyRelationInput = {
  connect?: InputMaybe<Array<GroupsMembersRequestKeyFilter>>;
};

/** Users relation input */
export type UsersUserJoiningRelationInput = {
  connect?: InputMaybe<Array<GroupsMembersRequestKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_GroupsMembersRequestCreateInput>>>;
};

/** Users relation input */
export type UsersUserJoiningUpdateRelationInput = {
  connect?: InputMaybe<Array<GroupsMembersRequestKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<User_GroupsMembersRequestCreateInput>>>;
  disconnect?: InputMaybe<Array<GroupsMembersRequestKeyFilter>>;
  reconnect?: InputMaybe<Array<GroupsMembersRequestKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<User_GroupsMembersRequestUpdateInput>>>;
};

/** Users relation input */
export type UsersUserNotificationsByUsersManyRelationInput = {
  connect?: InputMaybe<Array<UserNotificationsByUserKeyFilter>>;
};

/** Users relation input */
export type UsersUserNotificationsByUsersRelationInput = {
  connect?: InputMaybe<Array<UserNotificationsByUserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Receiver_UserNotificationsByUserCreateInput>>>;
};

/** Users relation input */
export type UsersUserNotificationsByUsersUpdateRelationInput = {
  connect?: InputMaybe<Array<UserNotificationsByUserKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Receiver_UserNotificationsByUserCreateInput>>>;
  disconnect?: InputMaybe<Array<UserNotificationsByUserKeyFilter>>;
  reconnect?: InputMaybe<Array<UserNotificationsByUserKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Receiver_UserNotificationsByUserUpdateInput>>>;
};

/** Users relation input */
export type UsersUserPreferencesManyRelationInput = {
  connect?: InputMaybe<UserPreferenceKeyFilter>;
};

/** Users relation input */
export type UsersUserPreferencesRelationInput = {
  connect?: InputMaybe<UserPreferenceKeyFilter>;
  create?: InputMaybe<User_UserPreferenceCreateInput>;
};

/** Users relation input */
export type UsersUserPreferencesUpdateRelationInput = {
  connect?: InputMaybe<UserPreferenceKeyFilter>;
  create?: InputMaybe<User_UserPreferenceCreateInput>;
  disconnect?: InputMaybe<UserPreferenceKeyFilter>;
  reconnect?: InputMaybe<UserPreferenceKeyFilter>;
  update?: InputMaybe<User_UserPreferenceUpdateInput>;
};

/** Users relation input */
export type UsersViewedThreadsManyRelationInput = {
  connect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
};

/** Users relation input */
export type UsersViewedThreadsRelationInput = {
  connect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Views_CollectiveIntelligenceThreadCreateInput>>>;
};

/** Users relation input */
export type UsersViewedThreadsUpdateRelationInput = {
  connect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  create?: InputMaybe<Array<InputMaybe<Views_CollectiveIntelligenceThreadCreateInput>>>;
  disconnect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  reconnect?: InputMaybe<Array<CollectiveIntelligenceThreadKeyFilter>>;
  update?: InputMaybe<Array<InputMaybe<Views_CollectiveIntelligenceThreadUpdateInput>>>;
};

/** Inbox create input from users */
export type Users_InboxCreateInput = {
  /** Groups to which the message was sent */
  destinationGroups?: InputMaybe<Scalars['JSON']>;
  isHide?: InputMaybe<InboxIsHideRelationInput>;
  messages?: InputMaybe<InboxMessagesRelationInput>;
  /** Save either the content of the subject or the structure to get more details. */
  subject?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<InboxUsersRelationInput>;
};

/** Inbox update input from users */
export type Users_InboxUpdateInput = {
  data: InboxUpdateInput;
  filter?: InputMaybe<InboxKeyFilter>;
};

/** RoleAddon create input from users */
export type Users_RoleAddonCreateInput = {
  accessibleLayouts?: InputMaybe<RoleAddonAccessibleLayoutsRelationInput>;
  businessPermissions?: InputMaybe<Scalars['JSON']>;
  forumPermissions?: InputMaybe<Scalars['JSON']>;
  groupsPermissions?: InputMaybe<Scalars['JSON']>;
  /** Define the permissions within the "Home Posts". */
  homePostsPermissions?: InputMaybe<Scalars['JSON']>;
  /** Assigning this role to a user at registration */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isSystemRole?: InputMaybe<Scalars['Boolean']>;
  membersPermissions?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<RoleAddonRoleRelationInput>;
  school?: InputMaybe<RoleAddonSchoolRelationInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  schoolPostsPermissions?: InputMaybe<Scalars['JSON']>;
  users?: InputMaybe<RoleAddonUsersRelationInput>;
};

/** RoleAddon update input from users */
export type Users_RoleAddonUpdateInput = {
  data: RoleAddonUpdateInput;
  filter?: InputMaybe<RoleAddonKeyFilter>;
};

/** Roles create input from users */
export type Users_RoleCreateInput = {
  addon?: InputMaybe<RolesAddonRelationInput>;
  apiTokens?: InputMaybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: InputMaybe<RolesAuthenticationProfilesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notificationTemplates?: InputMaybe<RolesNotificationTemplatesRelationInput>;
  permissions?: InputMaybe<PermissionsInput>;
  teamMembers?: InputMaybe<RolesTeamMembersRelationInput>;
  users?: InputMaybe<RolesUsersRelationInput>;
};

/** Roles update input from users */
export type Users_RoleUpdateInput = {
  data: RoleUpdateInput;
  filter?: InputMaybe<RoleKeyFilter>;
};

/** Files create input from users_avatar */
export type Users_Avatar_FileCreateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from users_avatar */
export type Users_Avatar_FileUpdateInput = {
  advertisingCampaignAds_image?: InputMaybe<FilesAdvertisingCampaignAds_ImageUpdateRelationInput>;
  collectiveIntelligenceThreads_images?: InputMaybe<FilesCollectiveIntelligenceThreads_ImagesUpdateRelationInput>;
  discounts_images?: InputMaybe<FilesDiscounts_ImagesUpdateRelationInput>;
  educationalInstitutions_image?: InputMaybe<FilesEducationalInstitutions_ImageUpdateRelationInput>;
  events_images?: InputMaybe<FilesEvents_ImagesUpdateRelationInput>;
  fileId?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  groupPosts_media?: InputMaybe<FilesGroupPosts_MediaUpdateRelationInput>;
  groups_logo?: InputMaybe<FilesGroups_LogoUpdateRelationInput>;
  highSchoolLocations_logo?: InputMaybe<FilesHighSchoolLocations_LogoUpdateRelationInput>;
  homeFeedPosts_media?: InputMaybe<FilesHomeFeedPosts_MediaUpdateRelationInput>;
  inboxMessageMedia_media?: InputMaybe<FilesInboxMessageMedia_MediaUpdateRelationInput>;
  inboxMessages_media?: InputMaybe<FilesInboxMessages_MediaUpdateRelationInput>;
  jobEmployees_companyImage?: InputMaybe<FilesJobEmployees_CompanyImageUpdateRelationInput>;
  legacyFeedPosts_media?: InputMaybe<FilesLegacyFeedPosts_MediaUpdateRelationInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  mods?: InputMaybe<Scalars['JSON']>;
  organizations_logo?: InputMaybe<FilesOrganizations_LogoUpdateRelationInput>;
  organizations_media?: InputMaybe<FilesOrganizations_MediaUpdateRelationInput>;
  public?: InputMaybe<Scalars['Boolean']>;
  schoolConfigurationImages_applicationLogo?: InputMaybe<FilesSchoolConfigurationImages_ApplicationLogoUpdateRelationInput>;
  schoolConfigurationImages_browserLogo?: InputMaybe<FilesSchoolConfigurationImages_BrowserLogoUpdateRelationInput>;
  schoolConfigurationImages_emailBanner?: InputMaybe<FilesSchoolConfigurationImages_EmailBannerUpdateRelationInput>;
  schoolConfigurationImages_emailLogo?: InputMaybe<FilesSchoolConfigurationImages_EmailLogoUpdateRelationInput>;
  schoolConfigurationImages_logo?: InputMaybe<FilesSchoolConfigurationImages_LogoUpdateRelationInput>;
  schoolConfigurationImages_splashImage?: InputMaybe<FilesSchoolConfigurationImages_SplashImageUpdateRelationInput>;
  schoolNewsPosts_media?: InputMaybe<FilesSchoolNewsPosts_MediaUpdateRelationInput>;
  teamMembers_avatar?: InputMaybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  userNotifications_image?: InputMaybe<FilesUserNotifications_ImageUpdateRelationInput>;
  users_avatar?: InputMaybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** VerificationEmailResendInput */
export type VerificationEmailResendInput = {
  email: Scalars['String'];
};

/** View Attributes */
export type ViewAttributes = {
  __typename?: 'ViewAttributes';
  query?: Maybe<Scalars['String']>;
};

/** View Create Input */
export type ViewCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  query: Scalars['String'];
};

/** Dry Run Response for previewing SQL Views */
export type ViewDryRunOutput = {
  __typename?: 'ViewDryRunOutput';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Scalars['JSON']>;
  timeMs?: Maybe<Scalars['Int']>;
};

/** View Update Input */
export type ViewUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};

/** Users create input from viewedThreads */
export type ViewedThreads_UserCreateInput = {
  activityClubs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adClicks?: InputMaybe<UsersAdClicksRelationInput>;
  adLikes?: InputMaybe<UsersAdLikesRelationInput>;
  adViews?: InputMaybe<UsersAdViewsRelationInput>;
  affiliation?: InputMaybe<Scalars['String']>;
  /** Description for the "other affiliation" option. */
  affiliationDescription?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<UsersAvatarRelationInput>;
  birthDate?: InputMaybe<Scalars['Date']>;
  collectiveIntelligenceThreads?: InputMaybe<UsersCollectiveIntelligenceThreadsRelationInput>;
  createdGroups?: InputMaybe<UsersCreatedGroupsRelationInput>;
  createdJobOffers?: InputMaybe<UsersCreatedJobOffersRelationInput>;
  createdSchoolNewsPosts?: InputMaybe<UsersCreatedSchoolNewsPostsRelationInput>;
  currentIndustry?: InputMaybe<Scalars['String']>;
  deleteInboxMessages?: InputMaybe<UsersDeleteInboxMessagesRelationInput>;
  description?: InputMaybe<Scalars['String']>;
  discountRedemptions?: InputMaybe<UsersDiscountRedemptionsRelationInput>;
  education?: InputMaybe<UsersEducationRelationInput>;
  email: Scalars['String'];
  /** Facebook URL of the user. */
  facebookLink?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  flaggedGroupPosts?: InputMaybe<UsersFlaggedGroupPostsRelationInput>;
  flaggedHomeFeedPosts?: InputMaybe<UsersFlaggedHomeFeedPostsRelationInput>;
  flaggedLegacyFeedPosts?: InputMaybe<UsersFlaggedLegacyFeedPostsRelationInput>;
  flaggedSchoolNewsPosts?: InputMaybe<UsersFlaggedSchoolNewsPostsRelationInput>;
  gender?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['Date']>;
  groupAdminPreferences?: InputMaybe<UsersGroupAdminPreferencesRelationInput>;
  groupPosts?: InputMaybe<UsersGroupPostsRelationInput>;
  hiddenInboxMessages?: InputMaybe<UsersHiddenInboxMessagesRelationInput>;
  hiddenInboxes?: InputMaybe<UsersHiddenInboxesRelationInput>;
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeFeedPosts?: InputMaybe<UsersHomeFeedPostsRelationInput>;
  inboxes?: InputMaybe<UsersInboxesRelationInput>;
  /** Instagram URL of the user. */
  instagramLink?: InputMaybe<Scalars['JSON']>;
  isFirstAdLogin?: InputMaybe<Scalars['Boolean']>;
  isFirstSwitching?: InputMaybe<Scalars['Boolean']>;
  isNewToAdvertising?: InputMaybe<Scalars['Boolean']>;
  isSeekingEmployment?: InputMaybe<Scalars['Boolean']>;
  jobExperience?: InputMaybe<UsersJobExperienceRelationInput>;
  joinedGroups?: InputMaybe<UsersJoinedGroupsRelationInput>;
  lastFlaggedGroupPost?: InputMaybe<UsersLastFlaggedGroupPostRelationInput>;
  lastFlaggedHomePost?: InputMaybe<UsersLastFlaggedHomePostRelationInput>;
  lastFlaggedLegacyPost?: InputMaybe<UsersLastFlaggedLegacyPostRelationInput>;
  lastFlaggedSchoolPost?: InputMaybe<UsersLastFlaggedSchoolPostRelationInput>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyFeedPosts?: InputMaybe<UsersLegacyFeedPostsRelationInput>;
  likedOrganizations?: InputMaybe<UsersLikedOrganizationsRelationInput>;
  /** LinkedIn URL of the user. */
  linkedInLink?: InputMaybe<Scalars['JSON']>;
  managerPreferences?: InputMaybe<UsersManagerPreferencesRelationInput>;
  ownedOrganizations?: InputMaybe<UsersOwnedOrganizationsRelationInput>;
  parents?: InputMaybe<Scalars['JSON']>;
  postedEvents?: InputMaybe<UsersPostedEventsRelationInput>;
  professionalDesignations?: InputMaybe<Scalars['String']>;
  redeemedDiscounts?: InputMaybe<UsersRedeemedDiscountsRelationInput>;
  /** Date when the user was completely registered on the platform. */
  registrationCompletedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<UsersRolesRelationInput>;
  rolesAddons?: InputMaybe<UsersRolesAddonsRelationInput>;
  schoolMemberships?: InputMaybe<UsersSchoolMembershipsRelationInput>;
  sentMessages?: InputMaybe<UsersSentMessagesRelationInput>;
  sentNotifications?: InputMaybe<UsersSentNotificationsRelationInput>;
  status?: InputMaybe<Scalars['String']>;
  supporterClicks?: InputMaybe<UsersSupporterClicksRelationInput>;
  supporterSearches?: InputMaybe<UsersSupporterSearchesRelationInput>;
  timezone?: InputMaybe<Scalars['String']>;
  /** X (formerly Twitter) URL of the user. */
  twitterLink?: InputMaybe<Scalars['JSON']>;
  userConfiguration?: InputMaybe<UsersUserConfigurationRelationInput>;
  userJoining?: InputMaybe<UsersUserJoiningRelationInput>;
  userNotificationsByUsers?: InputMaybe<UsersUserNotificationsByUsersRelationInput>;
  userPreferences?: InputMaybe<UsersUserPreferencesRelationInput>;
  viewedThreads?: InputMaybe<UsersViewedThreadsRelationInput>;
};

/** Users update input from viewedThreads */
export type ViewedThreads_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: InputMaybe<UserKeyFilter>;
};

/** CollectiveIntelligenceThreads create input from views */
export type Views_CollectiveIntelligenceThreadCreateInput = {
  creator?: InputMaybe<CollectiveIntelligenceThreadsCreatorRelationInput>;
  images?: InputMaybe<CollectiveIntelligenceThreadsImagesRelationInput>;
  schools?: InputMaybe<CollectiveIntelligenceThreadsSchoolsRelationInput>;
  text: Scalars['String'];
  title: Scalars['String'];
  views?: InputMaybe<CollectiveIntelligenceThreadsViewsRelationInput>;
};

/** CollectiveIntelligenceThreads update input from views */
export type Views_CollectiveIntelligenceThreadUpdateInput = {
  data: CollectiveIntelligenceThreadUpdateInput;
  filter?: InputMaybe<CollectiveIntelligenceThreadKeyFilter>;
};

/** SchoolConfiguration create input from wordsBlacklist */
export type WordsBlacklist_SchoolConfigurationCreateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsRelationInput>;
  address: SchoolConfigurationAddressRelationInput;
  audiences?: InputMaybe<SchoolConfigurationAudiencesRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name: Scalars['String'];
  offers?: InputMaybe<SchoolConfigurationOffersRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistRelationInput>;
};

/** SchoolConfiguration update input from wordsBlacklist */
export type WordsBlacklist_SchoolConfigurationUpdateInput = {
  adClicks?: InputMaybe<SchoolConfigurationAdClicksUpdateRelationInput>;
  adViews?: InputMaybe<SchoolConfigurationAdViewsUpdateRelationInput>;
  address?: InputMaybe<SchoolConfigurationAddressUpdateRelationInput>;
  audiences?: InputMaybe<SchoolConfigurationAudiencesUpdateRelationInput>;
  classScorecard?: InputMaybe<SchoolConfigurationClassScorecardUpdateRelationInput>;
  colorSchema?: InputMaybe<SchoolConfigurationColorSchemaUpdateRelationInput>;
  dashboardMetrics?: InputMaybe<SchoolConfigurationDashboardMetricsUpdateRelationInput>;
  debtOperations?: InputMaybe<SchoolConfigurationDebtOperationsUpdateRelationInput>;
  discountRedemptions?: InputMaybe<SchoolConfigurationDiscountRedemptionsUpdateRelationInput>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<SchoolConfigurationEventsUpdateRelationInput>;
  externalLinks?: InputMaybe<SchoolConfigurationExternalLinksUpdateRelationInput>;
  forumThreads?: InputMaybe<SchoolConfigurationForumThreadsUpdateRelationInput>;
  fullName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<SchoolConfigurationGroupsUpdateRelationInput>;
  homePosts?: InputMaybe<SchoolConfigurationHomePostsUpdateRelationInput>;
  images?: InputMaybe<SchoolConfigurationImagesUpdateRelationInput>;
  jobOffers?: InputMaybe<SchoolConfigurationJobOffersUpdateRelationInput>;
  layout?: InputMaybe<SchoolConfigurationLayoutUpdateRelationInput>;
  legacyFeedMonths?: InputMaybe<SchoolConfigurationLegacyFeedMonthsUpdateRelationInput>;
  legacyFeedYears?: InputMaybe<SchoolConfigurationLegacyFeedYearsUpdateRelationInput>;
  legacyPosts?: InputMaybe<SchoolConfigurationLegacyPostsUpdateRelationInput>;
  /** Whether to auto-moderate the content posted as legacy posts. */
  moderateLegacyPosts?: InputMaybe<Scalars['Boolean']>;
  /** Whether to auto-moderate the content posted as school posts. */
  moderateSchoolPosts?: InputMaybe<Scalars['Boolean']>;
  /** Moderation threshold setup by the organization */
  moderationThreshold?: InputMaybe<Scalars['Float']>;
  /** Display name for school */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<SchoolConfigurationOffersUpdateRelationInput>;
  roleAddons?: InputMaybe<SchoolConfigurationRoleAddonsUpdateRelationInput>;
  schoolPosts?: InputMaybe<SchoolConfigurationSchoolPostsUpdateRelationInput>;
  schoolSupporter?: InputMaybe<SchoolConfigurationSchoolSupporterUpdateRelationInput>;
  /** TODO */
  timezone?: InputMaybe<Scalars['String']>;
  topBar?: InputMaybe<SchoolConfigurationTopBarUpdateRelationInput>;
  trendingSearches?: InputMaybe<SchoolConfigurationTrendingSearchesUpdateRelationInput>;
  userMemberships?: InputMaybe<SchoolConfigurationUserMembershipsUpdateRelationInput>;
  usersCount?: InputMaybe<SchoolConfigurationUsersCountUpdateRelationInput>;
  wordsBlacklist?: InputMaybe<SchoolConfigurationWordsBlacklistUpdateRelationInput>;
};

/** WorkspaceCreateMutationInput */
export type WorkspaceCreateMutationInput = {
  authType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  howStart?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<GraphQlCreateFileItemInput>;
  kind?: InputMaybe<WorkspaceKind>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
  profileId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['ID']>;
};

export type WorkspaceCreateResponse = {
  __typename?: 'WorkspaceCreateResponse';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** WorkspaceDeleteMutationInput */
export type WorkspaceDeleteMutationInput = {
  workspaceId: Scalars['ID'];
};

export type WorkspaceImage = {
  __typename?: 'WorkspaceImage';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** LoginResponseWorkspace name and id */
export type WorkspaceInfo = {
  __typename?: 'WorkspaceInfo';
  name?: Maybe<Scalars['String']>;
  workspace?: Maybe<Scalars['ID']>;
};

export type WorkspaceItem = {
  __typename?: 'WorkspaceItem';
  apiHost?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<WorkspaceImage>;
  is8BaseAuthEnabled?: Maybe<Scalars['Boolean']>;
  isCiCdEnabled?: Maybe<Scalars['Boolean']>;
  isOwner: Scalars['Boolean'];
  kind?: Maybe<Scalars['String']>;
  lastAccess?: Maybe<Scalars['DateTime']>;
  lastDeployABInfo?: Maybe<DeploymentAbItemResponse>;
  name: Scalars['String'];
  nextPlan?: Maybe<BillingNextPlanResponse>;
  organization?: Maybe<SystemOrganizationBaseItem>;
  owner?: Maybe<SystemMemberAccountInfo>;
  plan?: Maybe<BillingCurrentPlanResponse>;
  project?: Maybe<ProjectItemWs>;
  region?: Maybe<Scalars['String']>;
  summaryABInfo?: Maybe<FrontendUtilizationAbResponse>;
  teamMemberCount?: Maybe<Scalars['Int']>;
  webSocket?: Maybe<Scalars['String']>;
};

/** Workspace Kind */
export type WorkspaceKind = 'frontend' | 'general';

/** WorkspaceListResponse output */
export type WorkspaceListResponse = {
  __typename?: 'WorkspaceListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<WorkspaceItem>;
};

export type WorkspaceStatus =
  | 'active'
  | 'blocked'
  | 'canceled'
  | 'canceling'
  | 'pending'
  | 'suspended';

/** WorkspaceUpdateMutationInput */
export type WorkspaceUpdateMutationInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: InputMaybe<GraphQlCreateFileItemInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type WorkspaceUpdateResponse = {
  __typename?: 'WorkspaceUpdateResponse';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<GraphQlFileItemResponse>;
  name?: Maybe<Scalars['String']>;
};

export type IsAllowedCallbackUrlQueryResponse = {
  __typename?: 'isAllowedCallbackURLQueryResponse';
  isEnabled: Scalars['Boolean'];
};

export type StreamChatGetReactionsResponse = {
  __typename?: 'streamChatGetReactionsResponse';
  postCount: Scalars['Int'];
  reactionCount: Scalars['Int'];
};

export type StripeCreatePaymentIntentResponse = {
  __typename?: 'stripeCreatePaymentIntentResponse';
  customerId?: Maybe<Scalars['String']>;
  paymentIntent?: Maybe<Scalars['JSON']>;
};

export type StripeDetachPaymentMethodResponse = {
  __typename?: 'stripeDetachPaymentMethodResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type StripeGetBillingTransactionsListResponse = {
  __typename?: 'stripeGetBillingTransactionsListResponse';
  billingTransactionsList: Array<Maybe<BillingTransaction>>;
  nextPageId?: Maybe<Scalars['String']>;
};

export type StripeGetCustomerPaymentMethodsResponse = {
  __typename?: 'stripeGetCustomerPaymentMethodsResponse';
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Scalars['JSON']>;
};

export type StripeUpdateCustomerDefaultPaymentMethodResponse = {
  __typename?: 'stripeUpdateCustomerDefaultPaymentMethodResponse';
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UserEmailChangeRequestResponse = {
  __typename?: 'userEmailChangeRequestResponse';
  newEmail?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type BusinessSearchQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilter>;
  sort?: InputMaybe<Array<OrganizationSort> | OrganizationSort>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;

export type BusinessSearchQuery = {
  __typename?: 'Query';
  organizationsList: {
    __typename?: 'OrganizationListResponse';
    items: Array<{
      __typename?: 'Organization';
      id?: string | null;
      homeFeedViews?: number | null;
      description?: string | null;
      industry?: string | null;
      name?: string | null;
      likesCount?: number | null;
      status?: string | null;
      createdAt?: any | null;
      userLikes?: {
        __typename?: 'OrganizationLikeListResponse';
        items: Array<{
          __typename?: 'OrganizationLike';
          id?: string | null;
          user?: { __typename?: 'User'; id?: string | null } | null;
          organization?: { __typename?: 'Organization'; id?: string | null } | null;
        }>;
      } | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      } | null;
      schoolSupporter?: {
        __typename?: 'SchoolSupporterListResponse';
        items: Array<{
          __typename?: 'SchoolSupporter';
          id?: string | null;
          school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
        }>;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          downloadUrl?: string | null;
          meta?: any | null;
          filename?: string | null;
          fileId?: string | null;
          id?: string | null;
        }>;
      } | null;
      advertising?: {
        __typename?: 'Advertising';
        id?: string | null;
        supporter?: {
          __typename?: 'AdvertisingSupporter';
          id?: string | null;
          isSupporter?: boolean | null;
        } | null;
      } | null;
      location?: {
        __typename?: 'OrganizationLocationListResponse';
        items: Array<{
          __typename?: 'OrganizationLocation';
          id?: string | null;
          locationName?: string | null;
          operationalHours?: string | null;
          contactName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          link?: string | null;
          address?: {
            __typename?: 'AddressListResponse';
            items: Array<{
              __typename?: 'Address';
              id?: string | null;
              state?: string | null;
              zip?: string | null;
              street1?: string | null;
              city?: string | null;
            }>;
          } | null;
        }>;
      } | null;
      managers?: {
        __typename?: 'OrganizationManagerPreferenceListResponse';
        items: Array<{
          __typename?: 'OrganizationManagerPreference';
          id?: string | null;
          status?: string | null;
          isAdmin?: boolean | null;
          user?: {
            __typename?: 'User';
            id?: string | null;
            email?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            affiliation?: string | null;
            rolesAddons?: {
              __typename?: 'RoleAddonListResponse';
              items: Array<{ __typename?: 'RoleAddon'; id?: string | null; name?: string | null }>;
            } | null;
          } | null;
        }>;
      } | null;
      owner?: {
        __typename?: 'User';
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      jobOffers?: {
        __typename?: 'JobOfferListResponse';
        items: Array<{
          __typename?: 'JobOffer';
          id?: string | null;
          description?: string | null;
          position?: string | null;
          createdAt?: any | null;
          createdBy?: {
            __typename?: 'User';
            firstName?: string | null;
            lastName?: string | null;
            id?: string | null;
            avatar?: {
              __typename?: 'File';
              id?: string | null;
              downloadUrl?: string | null;
            } | null;
          } | null;
          organization?: {
            __typename?: 'Organization';
            id?: string | null;
            name?: string | null;
          } | null;
        }>;
      } | null;
      discounts?: {
        __typename?: 'DiscountListResponse';
        items: Array<{
          __typename?: 'Discount';
          id?: string | null;
          title?: string | null;
          terms?: string | null;
          amountPercent?: number | null;
          type?: string | null;
          startDate?: any | null;
          expirationDate?: any | null;
          category?: string | null;
          organization?: {
            __typename?: 'Organization';
            id?: string | null;
            name?: string | null;
            logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
          } | null;
          images?: {
            __typename?: 'FileListResponse';
            items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
          } | null;
        }>;
      } | null;
    }>;
  };
};

export type BusinessesListCountQueryVariables = Exact<{
  filter?: InputMaybe<BusinessReportFilter>;
}>;

export type BusinessesListCountQuery = {
  __typename?: 'Query';
  businessReportsList: { __typename?: 'BusinessReportListResponse'; count: number };
};

export type BusinessEditMutationVariables = Exact<{
  data: OrganizationUpdateInput;
}>;

export type BusinessEditMutation = {
  __typename?: 'Mutation';
  organizationUpdate: {
    __typename?: 'Organization';
    id?: string | null;
    homeFeedViews?: number | null;
    description?: string | null;
    industry?: string | null;
    name?: string | null;
    likesCount?: number | null;
    status?: string | null;
    createdAt?: any | null;
    userLikes?: {
      __typename?: 'OrganizationLikeListResponse';
      items: Array<{
        __typename?: 'OrganizationLike';
        id?: string | null;
        user?: { __typename?: 'User'; id?: string | null } | null;
        organization?: { __typename?: 'Organization'; id?: string | null } | null;
      }>;
    } | null;
    logo?: {
      __typename?: 'File';
      id?: string | null;
      downloadUrl?: string | null;
      fileId?: string | null;
      filename?: string | null;
    } | null;
    schoolSupporter?: {
      __typename?: 'SchoolSupporterListResponse';
      items: Array<{
        __typename?: 'SchoolSupporter';
        id?: string | null;
        school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
      }>;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        downloadUrl?: string | null;
        meta?: any | null;
        filename?: string | null;
        fileId?: string | null;
        id?: string | null;
      }>;
    } | null;
    advertising?: {
      __typename?: 'Advertising';
      id?: string | null;
      supporter?: {
        __typename?: 'AdvertisingSupporter';
        id?: string | null;
        isSupporter?: boolean | null;
      } | null;
    } | null;
    location?: {
      __typename?: 'OrganizationLocationListResponse';
      items: Array<{
        __typename?: 'OrganizationLocation';
        id?: string | null;
        locationName?: string | null;
        operationalHours?: string | null;
        contactName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        link?: string | null;
        address?: {
          __typename?: 'AddressListResponse';
          items: Array<{
            __typename?: 'Address';
            id?: string | null;
            state?: string | null;
            zip?: string | null;
            street1?: string | null;
            city?: string | null;
          }>;
        } | null;
      }>;
    } | null;
    managers?: {
      __typename?: 'OrganizationManagerPreferenceListResponse';
      items: Array<{
        __typename?: 'OrganizationManagerPreference';
        id?: string | null;
        status?: string | null;
        isAdmin?: boolean | null;
        user?: {
          __typename?: 'User';
          id?: string | null;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          affiliation?: string | null;
          rolesAddons?: {
            __typename?: 'RoleAddonListResponse';
            items: Array<{ __typename?: 'RoleAddon'; id?: string | null; name?: string | null }>;
          } | null;
        } | null;
      }>;
    } | null;
    owner?: {
      __typename?: 'User';
      id?: string | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    jobOffers?: {
      __typename?: 'JobOfferListResponse';
      items: Array<{
        __typename?: 'JobOffer';
        id?: string | null;
        description?: string | null;
        position?: string | null;
        createdAt?: any | null;
        createdBy?: {
          __typename?: 'User';
          firstName?: string | null;
          lastName?: string | null;
          id?: string | null;
          avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        } | null;
        organization?: {
          __typename?: 'Organization';
          id?: string | null;
          name?: string | null;
        } | null;
      }>;
    } | null;
    discounts?: {
      __typename?: 'DiscountListResponse';
      items: Array<{
        __typename?: 'Discount';
        id?: string | null;
        title?: string | null;
        terms?: string | null;
        amountPercent?: number | null;
        type?: string | null;
        startDate?: any | null;
        expirationDate?: any | null;
        category?: string | null;
        organization?: {
          __typename?: 'Organization';
          id?: string | null;
          name?: string | null;
          logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
        } | null;
        images?: {
          __typename?: 'FileListResponse';
          items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
        } | null;
      }>;
    } | null;
  };
};

export type RegisteredUsersSpreadsheetCountQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<UserFilter>;
  sort?: InputMaybe<Array<UserSort> | UserSort>;
}>;

export type RegisteredUsersSpreadsheetCountQuery = {
  __typename?: 'Query';
  usersList: {
    __typename?: 'UserListResponse';
    groups: Array<{
      __typename?: 'GroupByResponse';
      year?: any | null;
      usersCount?: number | null;
    }>;
  };
};

export type FullContentReportsListItemFragment = {
  __typename?: 'ContentReport';
  id?: string | null;
  text?: string | null;
  category?: string | null;
  legacyDate?: any | null;
  authorId?: string | null;
  authorName?: string | null;
  groupId?: string | null;
  reportStatus?: string | null;
  flagReason?: string | null;
  lastFlaggedByName?: string | null;
  isFlagged?: boolean | null;
  isAutoReported?: boolean | null;
  isSupporter?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  flaggedAt?: any | null;
};

export type FullContentReportsListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ContentReportSort> | ContentReportSort>;
  filter?: InputMaybe<ContentReportFilter>;
}>;

export type FullContentReportsListQuery = {
  __typename?: 'Query';
  contentReportsList: {
    __typename?: 'ContentReportListResponse';
    count: number;
    items: Array<{
      __typename?: 'ContentReport';
      id?: string | null;
      text?: string | null;
      category?: string | null;
      legacyDate?: any | null;
      authorId?: string | null;
      authorName?: string | null;
      groupId?: string | null;
      reportStatus?: string | null;
      flagReason?: string | null;
      lastFlaggedByName?: string | null;
      isFlagged?: boolean | null;
      isAutoReported?: boolean | null;
      isSupporter?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      flaggedAt?: any | null;
    }>;
  };
};

export type ContentCurationFlaggedGroupPostsListQueryVariables = Exact<{
  filter?: InputMaybe<GroupPostFilter>;
  sort?: InputMaybe<Array<GroupPostSort> | GroupPostSort>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;

export type ContentCurationFlaggedGroupPostsListQuery = {
  __typename?: 'Query';
  reportedGroupPostsList: {
    __typename?: 'GroupPostListResponse';
    items: Array<{
      __typename?: 'GroupPost';
      id?: string | null;
      createdAt?: any | null;
      text?: string | null;
      isPinned?: boolean | null;
      commentsAllowed?: boolean | null;
      status?: string | null;
      reportStatus?: string | null;
      flaggedAt?: any | null;
      flagReason?: string | null;
      updatedAt?: any | null;
      isAutoReported?: boolean | null;
      author?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        ownedOrganizations?: {
          __typename?: 'OrganizationListResponse';
          items: Array<{
            __typename?: 'Organization';
            id?: string | null;
            advertising?: {
              __typename?: 'Advertising';
              id?: string | null;
              supporter?: {
                __typename?: 'AdvertisingSupporter';
                id?: string | null;
                isSupporter?: boolean | null;
              } | null;
            } | null;
          }>;
        } | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          meta?: any | null;
          fileId?: string | null;
          filename?: string | null;
        }>;
      } | null;
      group?: { __typename?: 'Group'; id?: string | null } | null;
      lastFlaggedBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      mentions?: {
        __typename?: 'PostMentionListResponse';
        items: Array<{
          __typename?: 'PostMention';
          id?: string | null;
          mentionId?: string | null;
          mentionText?: string | null;
          position?: number | null;
          type?: string | null;
        }>;
      } | null;
    }>;
  };
};

export type GroupPostDeleteMutationVariables = Exact<{
  data?: InputMaybe<GroupPostDeleteInput>;
}>;

export type GroupPostDeleteMutation = {
  __typename?: 'Mutation';
  groupPostDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type ContentCurationPostDeleteMutationVariables = Exact<{
  data: FlaggedPostDeleteRequestInput;
}>;

export type ContentCurationPostDeleteMutation = {
  __typename?: 'Mutation';
  flaggedPostDeleteRequest: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type ContentCurationGroupPostStatusUpdateMutationVariables = Exact<{
  data: GroupPostUpdateInput;
}>;

export type ContentCurationGroupPostStatusUpdateMutation = {
  __typename?: 'Mutation';
  groupPostUpdate: {
    __typename?: 'GroupPost';
    id?: string | null;
    status?: string | null;
    reportStatus?: string | null;
  };
};

export type ContentCurationHomeFeedPostStatusUpdateMutationVariables = Exact<{
  data: HomeFeedPostUpdateInput;
  id: Scalars['ID'];
}>;

export type ContentCurationHomeFeedPostStatusUpdateMutation = {
  __typename?: 'Mutation';
  homeFeedPostUpdate: {
    __typename?: 'HomeFeedPost';
    id?: string | null;
    status?: string | null;
    reportStatus?: string | null;
    text?: string | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        meta?: any | null;
      }>;
    } | null;
  };
};

export type ContentCurationSchoolNewsPostStatusUpdateMutationVariables = Exact<{
  data: SchoolNewsPostUpdateInput;
  id: Scalars['ID'];
}>;

export type ContentCurationSchoolNewsPostStatusUpdateMutation = {
  __typename?: 'Mutation';
  schoolNewsPostUpdate: {
    __typename?: 'SchoolNewsPost';
    id?: string | null;
    reportStatus?: string | null;
    text?: string | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        meta?: any | null;
      }>;
    } | null;
  };
};

export type ContentCurationLegacyFeedPostStatusUpdateMutationVariables = Exact<{
  data: LegacyFeedPostUpdateInput;
}>;

export type ContentCurationLegacyFeedPostStatusUpdateMutation = {
  __typename?: 'Mutation';
  legacyFeedPostUpdate: {
    __typename?: 'LegacyFeedPost';
    id?: string | null;
    reportStatus?: string | null;
    text?: string | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        meta?: any | null;
      }>;
    } | null;
  };
};

export type GroupPostsListItemQueryVariables = Exact<{
  filter?: InputMaybe<GroupPostFilter>;
}>;

export type GroupPostsListItemQuery = {
  __typename?: 'Query';
  groupPostsList: {
    __typename?: 'GroupPostListResponse';
    items: Array<{
      __typename?: 'GroupPost';
      id?: string | null;
      createdAt?: any | null;
      text?: string | null;
      isPinned?: boolean | null;
      commentsAllowed?: boolean | null;
      status?: string | null;
      reportStatus?: string | null;
      flaggedAt?: any | null;
      flagReason?: string | null;
      updatedAt?: any | null;
      isAutoReported?: boolean | null;
      flaggedBy?: {
        __typename?: 'UserListResponse';
        items: Array<{
          __typename?: 'User';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        }>;
      } | null;
      author?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        ownedOrganizations?: {
          __typename?: 'OrganizationListResponse';
          items: Array<{
            __typename?: 'Organization';
            id?: string | null;
            advertising?: {
              __typename?: 'Advertising';
              id?: string | null;
              supporter?: {
                __typename?: 'AdvertisingSupporter';
                id?: string | null;
                isSupporter?: boolean | null;
              } | null;
            } | null;
          }>;
        } | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          meta?: any | null;
          fileId?: string | null;
          filename?: string | null;
        }>;
      } | null;
      group?: { __typename?: 'Group'; id?: string | null } | null;
      lastFlaggedBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      mentions?: {
        __typename?: 'PostMentionListResponse';
        items: Array<{
          __typename?: 'PostMention';
          id?: string | null;
          mentionId?: string | null;
          mentionText?: string | null;
          position?: number | null;
          type?: string | null;
        }>;
      } | null;
    }>;
  };
};

export type HomeFeedPostsListItemQueryVariables = Exact<{
  filter?: InputMaybe<HomeFeedPostFilter>;
}>;

export type HomeFeedPostsListItemQuery = {
  __typename?: 'Query';
  homeFeedPostsList: {
    __typename?: 'HomeFeedPostListResponse';
    items: Array<{
      __typename?: 'HomeFeedPost';
      id?: string | null;
      createdAt?: any | null;
      text?: string | null;
      isPinned?: boolean | null;
      commentsAllowed?: boolean | null;
      status?: string | null;
      reportStatus?: string | null;
      flaggedAt?: any | null;
      flagReason?: string | null;
      updatedAt?: any | null;
      isAutoReported?: boolean | null;
      flaggedBy?: {
        __typename?: 'UserListResponse';
        items: Array<{
          __typename?: 'User';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        }>;
      } | null;
      discount?: {
        __typename?: 'Discount';
        id?: string | null;
        title?: string | null;
        terms?: string | null;
        amountPercent?: number | null;
        type?: string | null;
        startDate?: any | null;
        expirationDate?: any | null;
        category?: string | null;
        organization?: {
          __typename?: 'Organization';
          id?: string | null;
          name?: string | null;
          logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
        } | null;
        images?: {
          __typename?: 'FileListResponse';
          items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
        } | null;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          meta?: any | null;
          fileId?: string | null;
          filename?: string | null;
        }>;
      } | null;
      author?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        ownedOrganizations?: {
          __typename?: 'OrganizationListResponse';
          items: Array<{
            __typename?: 'Organization';
            id?: string | null;
            advertising?: {
              __typename?: 'Advertising';
              id?: string | null;
              supporter?: {
                __typename?: 'AdvertisingSupporter';
                id?: string | null;
                isSupporter?: boolean | null;
              } | null;
            } | null;
          }>;
        } | null;
      } | null;
      createdBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      } | null;
      lastFlaggedBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      mentions?: {
        __typename?: 'PostMentionListResponse';
        items: Array<{
          __typename?: 'PostMention';
          id?: string | null;
          mentionId?: string | null;
          mentionText?: string | null;
          position?: number | null;
          type?: string | null;
        }>;
      } | null;
    }>;
  };
};

export type SchoolNewsPostsListItemQueryVariables = Exact<{
  filter?: InputMaybe<SchoolNewsPostFilter>;
}>;

export type SchoolNewsPostsListItemQuery = {
  __typename?: 'Query';
  schoolNewsPostsList: {
    __typename?: 'SchoolNewsPostListResponse';
    items: Array<{
      __typename?: 'SchoolNewsPost';
      id?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      text?: string | null;
      isPinned?: boolean | null;
      postDate?: any | null;
      commentsAllowed?: boolean | null;
      flagReason?: string | null;
      flaggedAt?: any | null;
      reportStatus?: string | null;
      flaggedBy?: {
        __typename?: 'UserListResponse';
        items: Array<{
          __typename?: 'User';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        }>;
      } | null;
      mentions?: {
        __typename?: 'PostMentionListResponse';
        items: Array<{
          __typename?: 'PostMention';
          id?: string | null;
          mentionId?: string | null;
          mentionText?: string | null;
          position?: number | null;
          type?: string | null;
        }>;
      } | null;
      lastFlaggedBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          meta?: any | null;
          fileId?: string | null;
          filename?: string | null;
        }>;
      } | null;
      author?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        ownedOrganizations?: {
          __typename?: 'OrganizationListResponse';
          items: Array<{
            __typename?: 'Organization';
            id?: string | null;
            advertising?: {
              __typename?: 'Advertising';
              id?: string | null;
              supporter?: {
                __typename?: 'AdvertisingSupporter';
                id?: string | null;
                isSupporter?: boolean | null;
              } | null;
            } | null;
          }>;
        } | null;
      } | null;
    }>;
  };
};

export type LegacyFeedPostsListItemQueryVariables = Exact<{
  filter?: InputMaybe<LegacyFeedPostFilter>;
}>;

export type LegacyFeedPostsListItemQuery = {
  __typename?: 'Query';
  legacyFeedPostsList: {
    __typename?: 'LegacyFeedPostListResponse';
    items: Array<{
      __typename?: 'LegacyFeedPost';
      id?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      text?: string | null;
      isPinned?: boolean | null;
      commentsAllowed?: boolean | null;
      postDate?: any | null;
      legacyDate?: any | null;
      reportStatus?: string | null;
      flaggedAt?: any | null;
      flagReason?: string | null;
      isAutoReported?: boolean | null;
      flaggedBy?: {
        __typename?: 'UserListResponse';
        items: Array<{
          __typename?: 'User';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        }>;
      } | null;
      lastFlaggedBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          meta?: any | null;
          downloadUrl?: string | null;
          fileId?: string | null;
          filename?: string | null;
        }>;
      } | null;
      createdBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      } | null;
      author?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        ownedOrganizations?: {
          __typename?: 'OrganizationListResponse';
          items: Array<{
            __typename?: 'Organization';
            id?: string | null;
            advertising?: {
              __typename?: 'Advertising';
              id?: string | null;
              supporter?: {
                __typename?: 'AdvertisingSupporter';
                id?: string | null;
                isSupporter?: boolean | null;
              } | null;
            } | null;
          }>;
        } | null;
      } | null;
    }>;
  };
};

export type FlaggedPostApproveMutationVariables = Exact<{
  data: FlaggedPostApproveRequestInput;
}>;

export type FlaggedPostApproveMutation = {
  __typename?: 'Mutation';
  flaggedPostApproveRequest: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type PostsCountQueryVariables = Exact<{
  jobOffersFilter?: InputMaybe<JobOfferFilter>;
  eventsFilter?: InputMaybe<EventFilter>;
}>;

export type PostsCountQuery = {
  __typename?: 'Query';
  jobPostsCount: {
    __typename?: 'JobOfferListResponse';
    count: number;
    items: Array<{
      __typename?: 'JobOffer';
      id?: string | null;
      createdAt?: any | null;
      engagementCount?: number | null;
    }>;
  };
  eventPostsCount: {
    __typename?: 'EventListResponse';
    count: number;
    items: Array<{ __typename?: 'Event'; id?: string | null; createdAt?: any | null }>;
  };
};

export type SchoolAdministrationBusinessCountQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilter>;
  startPointZip: Scalars['String'];
  radius: Scalars['String'];
  supporterFilter?: InputMaybe<OrganizationFilter>;
}>;

export type SchoolAdministrationBusinessCountQuery = {
  __typename?: 'Query';
  businesses: { __typename?: 'BusinessFilterByZipRequestResponse'; count?: number | null };
  supporters: { __typename?: 'BusinessFilterByZipRequestResponse'; count?: number | null };
};

export type SpecialOffersCountQueryVariables = Exact<{
  activeDiscountFilter?: InputMaybe<DiscountFilter>;
  redemptionFilter?: InputMaybe<DiscountRedemptionFilter>;
}>;

export type SpecialOffersCountQuery = {
  __typename?: 'Query';
  activeOffers: { __typename?: 'DiscountListResponse'; count: number };
  redemptions: { __typename?: 'DiscountRedemptionListResponse'; count: number };
};

export type MembersCountByAffiliationQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
}>;

export type MembersCountByAffiliationQuery = {
  __typename?: 'Query';
  alumni: { __typename?: 'UserListResponse'; count: number };
  student: { __typename?: 'UserListResponse'; count: number };
  staff: { __typename?: 'UserListResponse'; count: number };
  parent: { __typename?: 'UserListResponse'; count: number };
  other: { __typename?: 'UserListResponse'; count: number };
};

export type DashboardsMembersListQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilter>;
}>;

export type DashboardsMembersListQuery = {
  __typename?: 'Query';
  dashboardsList: {
    __typename?: 'DashboardListResponse';
    items: Array<{
      __typename?: 'Dashboard';
      id?: string | null;
      createdAt?: any | null;
      totalMembers?: number | null;
    }>;
  };
};

export type DashboardsAdvertisementsListQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilter>;
}>;

export type DashboardsAdvertisementsListQuery = {
  __typename?: 'Query';
  dashboardsList: {
    __typename?: 'DashboardListResponse';
    items: Array<{
      __typename?: 'Dashboard';
      id?: string | null;
      createdAt?: any | null;
      totalAdvertisements?: number | null;
      totalAdRevenue?: number | null;
      totalSupporters?: number | null;
      totalDiscounts?: number | null;
      totalAllRevenue?: number | null;
      totalDiscountsRevenue?: number | null;
      totalSupportersRevenue?: number | null;
      totalVolume?: number | null;
    }>;
  };
};

export type DiscountsStatsQueryVariables = Exact<{
  school: Scalars['ID'];
}>;

export type DiscountsStatsQuery = {
  __typename?: 'Query';
  activeDiscounts: { __typename?: 'DiscountListResponse'; count: number };
  totalDiscounts: {
    __typename?: 'DiscountRedemptionListResponse';
    items: Array<{ __typename?: 'GroupByResponse'; id?: string | null; count?: number | null }>;
  };
};

export type AdminDiscountFragment = {
  __typename?: 'Discount';
  id?: string | null;
  createdAt?: any | null;
  title?: string | null;
  type?: string | null;
  amountPercent?: number | null;
  startDate?: any | null;
  expirationDate?: any | null;
  timezone?: string | null;
  category?: string | null;
  status?: string | null;
  isSchoolDiscount?: boolean | null;
  terms?: string | null;
  images?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      public?: boolean | null;
      downloadUrl?: string | null;
    }>;
  } | null;
  organization?: { __typename?: 'Organization'; id?: string | null; name?: string | null } | null;
  redemptions?: { __typename?: 'DiscountRedemptionListResponse'; count: number } | null;
};

export type AdminDiscountsSpreadsheetListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<DiscountFilter>;
  sort?: InputMaybe<Array<DiscountSort> | DiscountSort>;
}>;

export type AdminDiscountsSpreadsheetListQuery = {
  __typename?: 'Query';
  discounts: {
    __typename?: 'DiscountListResponse';
    count: number;
    items: Array<{
      __typename?: 'Discount';
      id?: string | null;
      createdAt?: any | null;
      title?: string | null;
      type?: string | null;
      amountPercent?: number | null;
      startDate?: any | null;
      expirationDate?: any | null;
      timezone?: string | null;
      category?: string | null;
      status?: string | null;
      isSchoolDiscount?: boolean | null;
      terms?: string | null;
      images?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          filename?: string | null;
          public?: boolean | null;
          downloadUrl?: string | null;
        }>;
      } | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
      } | null;
      redemptions?: { __typename?: 'DiscountRedemptionListResponse'; count: number } | null;
    }>;
  };
};

export type AdminDiscountsSpreadsheetListCountQueryVariables = Exact<{
  filter?: InputMaybe<DiscountFilter>;
}>;

export type AdminDiscountsSpreadsheetListCountQuery = {
  __typename?: 'Query';
  discountsList: { __typename?: 'DiscountListResponse'; count: number };
};

export type DiscountCreateRequestMutationVariables = Exact<{
  data: DiscountCreateRequestInput;
}>;

export type DiscountCreateRequestMutation = {
  __typename?: 'Mutation';
  discountCreateRequest: { __typename?: 'DiscountCreateRequestResponse'; id: string };
};

export type AdminUpdateDiscountMutationVariables = Exact<{
  data: DiscountUpdateInput;
}>;

export type AdminUpdateDiscountMutation = {
  __typename?: 'Mutation';
  discountUpdate: {
    __typename?: 'Discount';
    id?: string | null;
    createdAt?: any | null;
    title?: string | null;
    type?: string | null;
    amountPercent?: number | null;
    startDate?: any | null;
    expirationDate?: any | null;
    timezone?: string | null;
    category?: string | null;
    status?: string | null;
    isSchoolDiscount?: boolean | null;
    terms?: string | null;
    images?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        public?: boolean | null;
        downloadUrl?: string | null;
      }>;
    } | null;
    organization?: { __typename?: 'Organization'; id?: string | null; name?: string | null } | null;
    redemptions?: { __typename?: 'DiscountRedemptionListResponse'; count: number } | null;
  };
};

export type DiscountUpdateStatusRequestMutationVariables = Exact<{
  data: DiscountUpdateStatusRequestInput;
}>;

export type DiscountUpdateStatusRequestMutation = {
  __typename?: 'Mutation';
  discountUpdateStatusRequest: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type EventsCountQueryVariables = Exact<{
  filter: EventFilter;
}>;

export type EventsCountQuery = {
  __typename?: 'Query';
  total: { __typename?: 'EventListResponse'; count: number };
  upcoming: { __typename?: 'EventListResponse'; count: number };
  pending: { __typename?: 'EventListResponse'; count: number };
};

export type EventStatusUpdateMutationVariables = Exact<{
  data: EventStatusUpdateRequestInput;
}>;

export type EventStatusUpdateMutation = {
  __typename?: 'Mutation';
  eventStatusUpdateRequest: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type EventDeleteMutationVariables = Exact<{
  data: EventDeleteInput;
}>;

export type EventDeleteMutation = {
  __typename?: 'Mutation';
  eventDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type UserInviteMembersToGroupMutationVariables = Exact<{
  data: UserInviteMembersToGroupInput;
}>;

export type UserInviteMembersToGroupMutation = {
  __typename?: 'Mutation';
  userInviteMembersToGroup: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type GroupMemberFragment = {
  __typename?: 'User';
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  affiliation?: string | null;
  avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
  groupAdminPreferences?: {
    __typename?: 'GroupAdminPreference';
    id?: string | null;
    group?: {
      __typename?: 'GroupListResponse';
      items: Array<{ __typename?: 'Group'; id?: string | null }>;
    } | null;
  } | null;
};

export type GroupsItemFragment = {
  __typename?: 'Group';
  createdAt?: any | null;
  description?: string | null;
  id?: string | null;
  isApproved?: boolean | null;
  membersCount?: number | null;
  status?: string | null;
  title?: string | null;
  postsCount?: number | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    lastName?: string | null;
    firstName?: string | null;
  } | null;
  members?: {
    __typename?: 'UserListResponse';
    items: Array<{
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      affiliation?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      groupAdminPreferences?: {
        __typename?: 'GroupAdminPreference';
        id?: string | null;
        group?: {
          __typename?: 'GroupListResponse';
          items: Array<{ __typename?: 'Group'; id?: string | null }>;
        } | null;
      } | null;
    }>;
  } | null;
  posts?: { __typename?: 'GroupPostListResponse'; count: number } | null;
  logo?: {
    __typename?: 'File';
    id?: string | null;
    fileId?: string | null;
    downloadUrl?: string | null;
    filename?: string | null;
  } | null;
  groupAdminPreferences?: {
    __typename?: 'GroupAdminPreferenceListResponse';
    items: Array<{
      __typename?: 'GroupAdminPreference';
      status?: string | null;
      id?: string | null;
      user?: {
        __typename?: 'User';
        email?: string | null;
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        affiliation?: string | null;
      } | null;
    }>;
  } | null;
};

export type AdminGroupDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AdminGroupDetailsQuery = {
  __typename?: 'Query';
  group?: {
    __typename?: 'Group';
    createdAt?: any | null;
    description?: string | null;
    id?: string | null;
    isApproved?: boolean | null;
    membersCount?: number | null;
    status?: string | null;
    title?: string | null;
    postsCount?: number | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      lastName?: string | null;
      firstName?: string | null;
    } | null;
    members?: {
      __typename?: 'UserListResponse';
      items: Array<{
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        affiliation?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        groupAdminPreferences?: {
          __typename?: 'GroupAdminPreference';
          id?: string | null;
          group?: {
            __typename?: 'GroupListResponse';
            items: Array<{ __typename?: 'Group'; id?: string | null }>;
          } | null;
        } | null;
      }>;
    } | null;
    posts?: { __typename?: 'GroupPostListResponse'; count: number } | null;
    logo?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      downloadUrl?: string | null;
      filename?: string | null;
    } | null;
    groupAdminPreferences?: {
      __typename?: 'GroupAdminPreferenceListResponse';
      items: Array<{
        __typename?: 'GroupAdminPreference';
        status?: string | null;
        id?: string | null;
        user?: {
          __typename?: 'User';
          email?: string | null;
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          affiliation?: string | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type GroupMembersListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<UserFilter>;
  sort?: InputMaybe<Array<UserSort> | UserSort>;
}>;

export type GroupMembersListQuery = {
  __typename?: 'Query';
  group?: {
    __typename?: 'Group';
    members?: {
      __typename?: 'UserListResponse';
      items: Array<{
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        affiliation?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        groupAdminPreferences?: {
          __typename?: 'GroupAdminPreference';
          id?: string | null;
          group?: {
            __typename?: 'GroupListResponse';
            items: Array<{ __typename?: 'Group'; id?: string | null }>;
          } | null;
        } | null;
      }>;
    } | null;
    posts?: { __typename?: 'GroupPostListResponse'; count: number } | null;
    groupAdminPreferences?: {
      __typename?: 'GroupAdminPreferenceListResponse';
      items: Array<{
        __typename?: 'GroupAdminPreference';
        status?: string | null;
        id?: string | null;
        user?: {
          __typename?: 'User';
          email?: string | null;
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          affiliation?: string | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type GroupMembersStatsQueryVariables = Exact<{
  filter: GroupFilter;
}>;

export type GroupMembersStatsQuery = {
  __typename?: 'Query';
  groupsCount: {
    __typename?: 'GroupListResponse';
    count: number;
    metrics: Array<{ __typename?: 'GroupByResponse'; totalMembersCount?: number | null }>;
  };
  groupsPending: { __typename?: 'GroupListResponse'; count: number };
};

export type GroupMembersListCountQueryVariables = Exact<{
  groupId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<UserFilter>;
}>;

export type GroupMembersListCountQuery = {
  __typename?: 'Query';
  group?: {
    __typename?: 'Group';
    members?: { __typename?: 'UserListResponse'; count: number } | null;
  } | null;
};

export type GroupUpdateMutationVariables = Exact<{
  data: GroupUpdateInput;
}>;

export type GroupUpdateMutation = {
  __typename?: 'Mutation';
  groupUpdate: {
    __typename?: 'Group';
    createdAt?: any | null;
    description?: string | null;
    id?: string | null;
    isApproved?: boolean | null;
    membersCount?: number | null;
    status?: string | null;
    title?: string | null;
    postsCount?: number | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      lastName?: string | null;
      firstName?: string | null;
    } | null;
    members?: {
      __typename?: 'UserListResponse';
      items: Array<{
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        affiliation?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        groupAdminPreferences?: {
          __typename?: 'GroupAdminPreference';
          id?: string | null;
          group?: {
            __typename?: 'GroupListResponse';
            items: Array<{ __typename?: 'Group'; id?: string | null }>;
          } | null;
        } | null;
      }>;
    } | null;
    posts?: { __typename?: 'GroupPostListResponse'; count: number } | null;
    logo?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      downloadUrl?: string | null;
      filename?: string | null;
    } | null;
    groupAdminPreferences?: {
      __typename?: 'GroupAdminPreferenceListResponse';
      items: Array<{
        __typename?: 'GroupAdminPreference';
        status?: string | null;
        id?: string | null;
        user?: {
          __typename?: 'User';
          email?: string | null;
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          affiliation?: string | null;
        } | null;
      }>;
    } | null;
  };
};

export type GroupApproveMutationVariables = Exact<{
  groupId: Scalars['ID'];
}>;

export type GroupApproveMutation = {
  __typename?: 'Mutation';
  groupUpdate: { __typename?: 'Group'; id?: string | null };
};

export type GroupRejectMutationVariables = Exact<{
  groupId: Scalars['ID'];
}>;

export type GroupRejectMutation = {
  __typename?: 'Mutation';
  groupRejectRequest: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type GroupAdminPreferenceDeleteMutationVariables = Exact<{
  data?: InputMaybe<GroupAdminPreferenceDeleteInput>;
}>;

export type GroupAdminPreferenceDeleteMutation = {
  __typename?: 'Mutation';
  groupAdminPreferenceDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type AdminGroupDeleteMutationVariables = Exact<{
  data: GroupDeleteInput;
}>;

export type AdminGroupDeleteMutation = {
  __typename?: 'Mutation';
  groupDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type AdminPreferencesDeleteMutationVariables = Exact<{
  data: GroupAdminPreferenceDeleteInput;
}>;

export type AdminPreferencesDeleteMutation = {
  __typename?: 'Mutation';
  groupAdminPreferenceDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type GroupAdminPreferencesListQueryVariables = Exact<{
  filter: GroupAdminPreferenceFilter;
}>;

export type GroupAdminPreferencesListQuery = {
  __typename?: 'Query';
  groupAdminPreferencesList: {
    __typename?: 'GroupAdminPreferenceListResponse';
    items: Array<{ __typename?: 'GroupAdminPreference'; id?: string | null }>;
  };
};

export type DeleteAllLegacyPostsMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteAllLegacyPostsMutation = {
  __typename?: 'Mutation';
  legacyFeedPostDeleteByFilter?: {
    __typename?: 'SuccessResponse';
    success?: boolean | null;
  } | null;
};

export type MemberStatsQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
}>;

export type MemberStatsQuery = {
  __typename?: 'Query';
  affiliations: {
    __typename?: 'UserListResponse';
    totalCount: number;
    groups: Array<{
      __typename?: 'GroupByResponse';
      affiliation?: string | null;
      count?: number | null;
    }>;
  };
};

export type SchoolMemberFragment = {
  __typename?: 'SchoolMember';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  affiliation?: string | null;
  status: SchoolMemberStatus;
  hobbies: Array<string>;
  activityClubs: Array<string>;
  birthDate?: any | null;
  gender?: string | null;
  graduatingYear?: any | null;
  phoneNumber?: string | null;
  professionalDesignations?: string | null;
  registrationCompletedAt?: any | null;
  createdAt: any;
  distance: number;
  groupPostsCount: number;
  homePostsCount: number;
  avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
  roleAddons: Array<{ __typename?: 'SchoolMemberRole'; id: string; name: string }>;
  systemRoles: Array<{ __typename?: 'SchoolMemberRole'; id: string; name: string }>;
};

export type SchoolMembersReportQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  startPointZip?: InputMaybe<Scalars['String']>;
  radius?: InputMaybe<Scalars['String']>;
  school: SchoolConfigurationKeyFilter;
  filter?: InputMaybe<UserSchoolMembershipFilter>;
  sort?: InputMaybe<Array<UserSchoolMembershipSort> | UserSchoolMembershipSort>;
}>;

export type SchoolMembersReportQuery = {
  __typename?: 'Query';
  members: {
    __typename?: 'SchoolMembersReport';
    count?: number | null;
    items: Array<{
      __typename?: 'SchoolMember';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      affiliation?: string | null;
      status: SchoolMemberStatus;
      hobbies: Array<string>;
      activityClubs: Array<string>;
      birthDate?: any | null;
      gender?: string | null;
      graduatingYear?: any | null;
      phoneNumber?: string | null;
      professionalDesignations?: string | null;
      registrationCompletedAt?: any | null;
      createdAt: any;
      distance: number;
      groupPostsCount: number;
      homePostsCount: number;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      roleAddons: Array<{ __typename?: 'SchoolMemberRole'; id: string; name: string }>;
      systemRoles: Array<{ __typename?: 'SchoolMemberRole'; id: string; name: string }>;
    }>;
  };
};

export type SchoolMembersReportCountQueryVariables = Exact<{
  school: SchoolConfigurationKeyFilter;
  filter?: InputMaybe<UserSchoolMembershipFilter>;
  startPointZip?: InputMaybe<Scalars['String']>;
  radius?: InputMaybe<Scalars['String']>;
}>;

export type SchoolMembersReportCountQuery = {
  __typename?: 'Query';
  report: { __typename?: 'SchoolMembersReport'; count?: number | null };
};

export type AcceptSchoolMemberMutationVariables = Exact<{
  filter: UserFilter;
  school: SchoolConfigurationKeyFilter;
}>;

export type AcceptSchoolMemberMutation = {
  __typename?: 'Mutation';
  acceptSchoolMembers: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type ActivateSchoolMembersMutationVariables = Exact<{
  filter: UserFilter;
  school: SchoolConfigurationKeyFilter;
}>;

export type ActivateSchoolMembersMutation = {
  __typename?: 'Mutation';
  activateSchoolMembers: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type InactivateSchoolMembersMutationVariables = Exact<{
  filter: UserFilter;
  school: SchoolConfigurationKeyFilter;
}>;

export type InactivateSchoolMembersMutation = {
  __typename?: 'Mutation';
  inactivateSchoolMembers: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type RejectSchoolMemberMutationVariables = Exact<{
  filter: UserFilter;
  school: SchoolConfigurationKeyFilter;
}>;

export type RejectSchoolMemberMutation = {
  __typename?: 'Mutation';
  rejectSchoolMembers: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type ResendSchoolApprovalMutationVariables = Exact<{
  filter: UserFilter;
  school: SchoolConfigurationKeyFilter;
}>;

export type ResendSchoolApprovalMutation = {
  __typename?: 'Mutation';
  resendSchoolApprovals: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type ContentReportsListItemFragment = {
  __typename?: 'ContentReport';
  id?: string | null;
  authorId?: string | null;
  authorName?: string | null;
  text?: string | null;
  createdAt?: any | null;
  category?: string | null;
  isSupporter?: number | null;
};

export type ContentReportsListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ContentReportSort> | ContentReportSort>;
  filter?: InputMaybe<ContentReportFilter>;
}>;

export type ContentReportsListQuery = {
  __typename?: 'Query';
  contentReportsList: {
    __typename?: 'ContentReportListResponse';
    items: Array<{
      __typename?: 'ContentReport';
      id?: string | null;
      authorId?: string | null;
      authorName?: string | null;
      text?: string | null;
      createdAt?: any | null;
      category?: string | null;
      isSupporter?: number | null;
    }>;
  };
};

export type ContentEngagementReportFragment = {
  __typename?: 'ContentEngagementReport';
  id?: string | null;
  text?: string | null;
  category?: string | null;
  legacyDate?: any | null;
  authorId?: string | null;
  authorName?: string | null;
  groupId?: string | null;
  likesCount?: number | null;
  commentsCount?: number | null;
  reactionsCount?: number | null;
  reportStatus?: string | null;
  flagReason?: string | null;
  lastFlaggedByName?: string | null;
  isFlagged?: string | null;
  isAutoReported?: boolean | null;
  isSupporter?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  flaggedAt?: any | null;
};

export type ContentEngagementReportQueryVariables = Exact<{
  filter?: InputMaybe<ContentReportFilter>;
  orderBy?: InputMaybe<Array<InputMaybe<ContentReportOrderBy>> | InputMaybe<ContentReportOrderBy>>;
  sort?: InputMaybe<Array<ContentReportSort> | ContentReportSort>;
  skip?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
}>;

export type ContentEngagementReportQuery = {
  __typename?: 'Query';
  report: {
    __typename?: 'ContentEngagementReportListResponse';
    count: number;
    items: Array<{
      __typename?: 'ContentEngagementReport';
      id?: string | null;
      text?: string | null;
      category?: string | null;
      legacyDate?: any | null;
      authorId?: string | null;
      authorName?: string | null;
      groupId?: string | null;
      likesCount?: number | null;
      commentsCount?: number | null;
      reactionsCount?: number | null;
      reportStatus?: string | null;
      flagReason?: string | null;
      lastFlaggedByName?: string | null;
      isFlagged?: string | null;
      isAutoReported?: boolean | null;
      isSupporter?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      flaggedAt?: any | null;
    }>;
  };
};

export type RevenueReportQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilter>;
  debtFilter?: InputMaybe<DebtOperationHistoryFilter>;
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type RevenueReportQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'OrganizationListResponse';
    count: number;
    items: Array<{
      __typename?: 'Organization';
      id?: string | null;
      name?: string | null;
      sponsorRequests?: { __typename?: 'EventSponsorRequestListResponse'; count: number } | null;
      debt?: {
        __typename?: 'Debt';
        balance?: {
          __typename?: 'DebtOperationHistoryListResponse';
          metrics: Array<{ __typename?: 'GroupByResponse'; revenue?: number | null }>;
        } | null;
      } | null;
    }>;
  };
};

export type OrganizationInfoFragment = {
  __typename?: 'Organization';
  id?: string | null;
  name?: string | null;
  createdBy?: { __typename?: 'User'; id?: string | null } | null;
  managers?: {
    __typename?: 'OrganizationManagerPreferenceListResponse';
    items: Array<{
      __typename?: 'OrganizationManagerPreference';
      id?: string | null;
      user?: { __typename?: 'User'; id?: string | null } | null;
    }>;
  } | null;
};

export type AdItemFragment = {
  __typename?: 'AdvertisingCampaignAd';
  id?: string | null;
  name?: string | null;
  primaryText?: string | null;
  callToActionText?: string | null;
  callToActionLink?: string | null;
  totalSpend?: number | null;
  todaySpend?: number | null;
  dailyBudget?: number | null;
  dailyBudgetEnabled?: boolean | null;
  totalBudget?: number | null;
  totalBudgetEnabled?: boolean | null;
  createdAt?: any | null;
  audiences?: {
    __typename?: 'AudienceListResponse';
    items: Array<{
      __typename?: 'Audience';
      id?: string | null;
      industry?: Array<string | null> | null;
      affiliation?: Array<string | null> | null;
      graduatingYear?: Array<number | null> | null;
      zipCode?: Array<string | null> | null;
      city?: Array<string | null> | null;
      state?: Array<string | null> | null;
      hobbies?: Array<string | null> | null;
      clubs?: Array<string | null> | null;
    }>;
  } | null;
  sampaign?: {
    __typename?: 'AdvertisingSampaign';
    id?: string | null;
    name?: string | null;
    advertising?: {
      __typename?: 'Advertising';
      id?: string | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        homeFeedViews?: number | null;
        description?: string | null;
        industry?: string | null;
        name?: string | null;
        likesCount?: number | null;
        status?: string | null;
        createdAt?: any | null;
        userLikes?: {
          __typename?: 'OrganizationLikeListResponse';
          items: Array<{
            __typename?: 'OrganizationLike';
            id?: string | null;
            user?: { __typename?: 'User'; id?: string | null } | null;
            organization?: { __typename?: 'Organization'; id?: string | null } | null;
          }>;
        } | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          fileId?: string | null;
          filename?: string | null;
        } | null;
        schoolSupporter?: {
          __typename?: 'SchoolSupporterListResponse';
          items: Array<{
            __typename?: 'SchoolSupporter';
            id?: string | null;
            school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
          }>;
        } | null;
        media?: {
          __typename?: 'FileListResponse';
          items: Array<{
            __typename?: 'File';
            downloadUrl?: string | null;
            meta?: any | null;
            filename?: string | null;
            fileId?: string | null;
            id?: string | null;
          }>;
        } | null;
        advertising?: {
          __typename?: 'Advertising';
          id?: string | null;
          supporter?: {
            __typename?: 'AdvertisingSupporter';
            id?: string | null;
            isSupporter?: boolean | null;
          } | null;
        } | null;
        location?: {
          __typename?: 'OrganizationLocationListResponse';
          items: Array<{
            __typename?: 'OrganizationLocation';
            id?: string | null;
            locationName?: string | null;
            operationalHours?: string | null;
            contactName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            link?: string | null;
            address?: {
              __typename?: 'AddressListResponse';
              items: Array<{
                __typename?: 'Address';
                id?: string | null;
                state?: string | null;
                zip?: string | null;
                street1?: string | null;
                city?: string | null;
              }>;
            } | null;
          }>;
        } | null;
        managers?: {
          __typename?: 'OrganizationManagerPreferenceListResponse';
          items: Array<{
            __typename?: 'OrganizationManagerPreference';
            id?: string | null;
            status?: string | null;
            isAdmin?: boolean | null;
            user?: {
              __typename?: 'User';
              id?: string | null;
              email?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              affiliation?: string | null;
              rolesAddons?: {
                __typename?: 'RoleAddonListResponse';
                items: Array<{
                  __typename?: 'RoleAddon';
                  id?: string | null;
                  name?: string | null;
                }>;
              } | null;
            } | null;
          }>;
        } | null;
        owner?: {
          __typename?: 'User';
          id?: string | null;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        jobOffers?: {
          __typename?: 'JobOfferListResponse';
          items: Array<{
            __typename?: 'JobOffer';
            id?: string | null;
            description?: string | null;
            position?: string | null;
            createdAt?: any | null;
            createdBy?: {
              __typename?: 'User';
              firstName?: string | null;
              lastName?: string | null;
              id?: string | null;
              avatar?: {
                __typename?: 'File';
                id?: string | null;
                downloadUrl?: string | null;
              } | null;
            } | null;
            organization?: {
              __typename?: 'Organization';
              id?: string | null;
              name?: string | null;
            } | null;
          }>;
        } | null;
        discounts?: {
          __typename?: 'DiscountListResponse';
          items: Array<{
            __typename?: 'Discount';
            id?: string | null;
            title?: string | null;
            terms?: string | null;
            amountPercent?: number | null;
            type?: string | null;
            startDate?: any | null;
            expirationDate?: any | null;
            category?: string | null;
            organization?: {
              __typename?: 'Organization';
              id?: string | null;
              name?: string | null;
              logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
            } | null;
            images?: {
              __typename?: 'FileListResponse';
              items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
            } | null;
          }>;
        } | null;
      } | null;
      supporter?: { __typename?: 'AdvertisingSupporter'; isSupporter?: boolean | null } | null;
    } | null;
  } | null;
  image?: { __typename?: 'File'; downloadUrl?: string | null; id?: string | null } | null;
  userViews?: { __typename?: 'AdViewListResponse'; count: number } | null;
};

export type CampaignAdsListQueryVariables = Exact<{
  filter?: InputMaybe<AdvertisingCampaignAdFilter>;
}>;

export type CampaignAdsListQuery = {
  __typename?: 'Query';
  advertisingCampaignAdsList: {
    __typename?: 'AdvertisingCampaignAdListResponse';
    items: Array<{
      __typename?: 'AdvertisingCampaignAd';
      id?: string | null;
      name?: string | null;
      primaryText?: string | null;
      callToActionText?: string | null;
      callToActionLink?: string | null;
      totalSpend?: number | null;
      todaySpend?: number | null;
      dailyBudget?: number | null;
      dailyBudgetEnabled?: boolean | null;
      totalBudget?: number | null;
      totalBudgetEnabled?: boolean | null;
      createdAt?: any | null;
      audiences?: {
        __typename?: 'AudienceListResponse';
        items: Array<{
          __typename?: 'Audience';
          id?: string | null;
          industry?: Array<string | null> | null;
          affiliation?: Array<string | null> | null;
          graduatingYear?: Array<number | null> | null;
          zipCode?: Array<string | null> | null;
          city?: Array<string | null> | null;
          state?: Array<string | null> | null;
          hobbies?: Array<string | null> | null;
          clubs?: Array<string | null> | null;
        }>;
      } | null;
      sampaign?: {
        __typename?: 'AdvertisingSampaign';
        id?: string | null;
        name?: string | null;
        advertising?: {
          __typename?: 'Advertising';
          id?: string | null;
          organization?: {
            __typename?: 'Organization';
            id?: string | null;
            homeFeedViews?: number | null;
            description?: string | null;
            industry?: string | null;
            name?: string | null;
            likesCount?: number | null;
            status?: string | null;
            createdAt?: any | null;
            userLikes?: {
              __typename?: 'OrganizationLikeListResponse';
              items: Array<{
                __typename?: 'OrganizationLike';
                id?: string | null;
                user?: { __typename?: 'User'; id?: string | null } | null;
                organization?: { __typename?: 'Organization'; id?: string | null } | null;
              }>;
            } | null;
            logo?: {
              __typename?: 'File';
              id?: string | null;
              downloadUrl?: string | null;
              fileId?: string | null;
              filename?: string | null;
            } | null;
            schoolSupporter?: {
              __typename?: 'SchoolSupporterListResponse';
              items: Array<{
                __typename?: 'SchoolSupporter';
                id?: string | null;
                school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
              }>;
            } | null;
            media?: {
              __typename?: 'FileListResponse';
              items: Array<{
                __typename?: 'File';
                downloadUrl?: string | null;
                meta?: any | null;
                filename?: string | null;
                fileId?: string | null;
                id?: string | null;
              }>;
            } | null;
            advertising?: {
              __typename?: 'Advertising';
              id?: string | null;
              supporter?: {
                __typename?: 'AdvertisingSupporter';
                id?: string | null;
                isSupporter?: boolean | null;
              } | null;
            } | null;
            location?: {
              __typename?: 'OrganizationLocationListResponse';
              items: Array<{
                __typename?: 'OrganizationLocation';
                id?: string | null;
                locationName?: string | null;
                operationalHours?: string | null;
                contactName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                link?: string | null;
                address?: {
                  __typename?: 'AddressListResponse';
                  items: Array<{
                    __typename?: 'Address';
                    id?: string | null;
                    state?: string | null;
                    zip?: string | null;
                    street1?: string | null;
                    city?: string | null;
                  }>;
                } | null;
              }>;
            } | null;
            managers?: {
              __typename?: 'OrganizationManagerPreferenceListResponse';
              items: Array<{
                __typename?: 'OrganizationManagerPreference';
                id?: string | null;
                status?: string | null;
                isAdmin?: boolean | null;
                user?: {
                  __typename?: 'User';
                  id?: string | null;
                  email?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  affiliation?: string | null;
                  rolesAddons?: {
                    __typename?: 'RoleAddonListResponse';
                    items: Array<{
                      __typename?: 'RoleAddon';
                      id?: string | null;
                      name?: string | null;
                    }>;
                  } | null;
                } | null;
              }>;
            } | null;
            owner?: {
              __typename?: 'User';
              id?: string | null;
              email?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
            jobOffers?: {
              __typename?: 'JobOfferListResponse';
              items: Array<{
                __typename?: 'JobOffer';
                id?: string | null;
                description?: string | null;
                position?: string | null;
                createdAt?: any | null;
                createdBy?: {
                  __typename?: 'User';
                  firstName?: string | null;
                  lastName?: string | null;
                  id?: string | null;
                  avatar?: {
                    __typename?: 'File';
                    id?: string | null;
                    downloadUrl?: string | null;
                  } | null;
                } | null;
                organization?: {
                  __typename?: 'Organization';
                  id?: string | null;
                  name?: string | null;
                } | null;
              }>;
            } | null;
            discounts?: {
              __typename?: 'DiscountListResponse';
              items: Array<{
                __typename?: 'Discount';
                id?: string | null;
                title?: string | null;
                terms?: string | null;
                amountPercent?: number | null;
                type?: string | null;
                startDate?: any | null;
                expirationDate?: any | null;
                category?: string | null;
                organization?: {
                  __typename?: 'Organization';
                  id?: string | null;
                  name?: string | null;
                  logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
                } | null;
                images?: {
                  __typename?: 'FileListResponse';
                  items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
                } | null;
              }>;
            } | null;
          } | null;
          supporter?: { __typename?: 'AdvertisingSupporter'; isSupporter?: boolean | null } | null;
        } | null;
      } | null;
      image?: { __typename?: 'File'; downloadUrl?: string | null; id?: string | null } | null;
      userViews?: { __typename?: 'AdViewListResponse'; count: number } | null;
    }>;
  };
};

export type UpdateCampaignAdMutationVariables = Exact<{
  data: AdvertisingCampaignAdUpdateInput;
  id?: InputMaybe<Scalars['ID']>;
}>;

export type UpdateCampaignAdMutation = {
  __typename?: 'Mutation';
  advertisingCampaignAdUpdate: { __typename?: 'AdvertisingCampaignAd'; id?: string | null };
};

export type AdViewCreateMutationVariables = Exact<{
  user: Scalars['ID'];
  ad: Scalars['ID'];
  school: Scalars['ID'];
}>;

export type AdViewCreateMutation = {
  __typename?: 'Mutation';
  view: { __typename?: 'AdView'; id?: string | null };
};

export type AdClickCreateMutationVariables = Exact<{
  user: Scalars['ID'];
  ad: Scalars['ID'];
  school: Scalars['ID'];
}>;

export type AdClickCreateMutation = {
  __typename?: 'Mutation';
  view: { __typename?: 'AdClick'; id?: string | null };
};

export type AdLikesQueryVariables = Exact<{
  adId: Scalars['ID'];
}>;

export type AdLikesQuery = {
  __typename?: 'Query';
  advertisingCampaignAd?: {
    __typename?: 'AdvertisingCampaignAd';
    userLikes?: {
      __typename?: 'UserListResponse';
      count: number;
      items: Array<{ __typename?: 'User'; id?: string | null }>;
    } | null;
  } | null;
};

export type UserCompleteRegistrationMutationVariables = Exact<{
  school: SchoolConfigurationKeyFilter;
  data: UserCompleteRegistrationInput;
}>;

export type UserCompleteRegistrationMutation = {
  __typename?: 'Mutation';
  userCompleteRegistration: {
    __typename?: 'UserCompleteRegistrationResponse';
    idToken: string;
    refreshToken: string;
  };
};

export type UserSocialCompleteRegistrationMutationVariables = Exact<{
  data: UserSocialCompleteRegistrationInput;
}>;

export type UserSocialCompleteRegistrationMutation = {
  __typename?: 'Mutation';
  userSocialCompleteRegistration: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type JobOfferUpdateMutationVariables = Exact<{
  data: JobOfferUpdateInput;
}>;

export type JobOfferUpdateMutation = {
  __typename?: 'Mutation';
  jobOfferUpdate: {
    __typename?: 'JobOffer';
    id?: string | null;
    position?: string | null;
    description?: string | null;
    employmentType?: string | null;
    organization?: { __typename?: 'Organization'; id?: string | null; name?: string | null } | null;
    interestedUsers?: {
      __typename?: 'UserPreferenceListResponse';
      items: Array<{
        __typename?: 'UserPreference';
        id?: string | null;
        jobsOfInterest?: {
          __typename?: 'JobOfferListResponse';
          items: Array<{
            __typename?: 'JobOffer';
            id?: string | null;
            description?: string | null;
            position?: string | null;
            postDate?: any | null;
            employmentType?: string | null;
            createdAt?: any | null;
            createdBy?: {
              __typename?: 'User';
              id?: string | null;
              email?: string | null;
              fullName?: string | null;
              graduatingYear?: any | null;
              professionalDesignations?: string | null;
              currentIndustry?: string | null;
              isSeekingEmployment?: boolean | null;
              affiliation?: string | null;
              jobExperience?: {
                __typename?: 'JobEmployeeListResponse';
                items: Array<{
                  __typename?: 'JobEmployee';
                  id?: string | null;
                  companyName?: string | null;
                  positionTitle?: string | null;
                  isCurrentlyWorking?: boolean | null;
                  companyImage?: {
                    __typename?: 'File';
                    id?: string | null;
                    fileId?: string | null;
                    downloadUrl?: string | null;
                  } | null;
                }>;
              } | null;
              avatar?: {
                __typename?: 'File';
                id?: string | null;
                fileId?: string | null;
                downloadUrl?: string | null;
              } | null;
            } | null;
            organization?: {
              __typename?: 'Organization';
              id?: string | null;
              name?: string | null;
              owner?: { __typename?: 'User'; id?: string | null } | null;
              managers?: {
                __typename?: 'OrganizationManagerPreferenceListResponse';
                items: Array<{
                  __typename?: 'OrganizationManagerPreference';
                  id?: string | null;
                  user?: { __typename?: 'User'; id?: string | null } | null;
                }>;
              } | null;
              location?: {
                __typename?: 'OrganizationLocationListResponse';
                items: Array<{
                  __typename?: 'OrganizationLocation';
                  id?: string | null;
                  address?: {
                    __typename?: 'AddressListResponse';
                    items: Array<{
                      __typename?: 'Address';
                      id?: string | null;
                      zip?: string | null;
                      street1?: string | null;
                      state?: string | null;
                      city?: string | null;
                    }>;
                  } | null;
                }>;
              } | null;
              logo?: {
                __typename?: 'File';
                id?: string | null;
                fileId?: string | null;
                downloadUrl?: string | null;
              } | null;
            } | null;
          }>;
        } | null;
      }>;
    } | null;
  };
};

export type JobOfferCreateMutationVariables = Exact<{
  data: JobOfferCreateInput;
}>;

export type JobOfferCreateMutation = {
  __typename?: 'Mutation';
  jobOfferCreate: { __typename?: 'JobOffer'; id?: string | null };
};

export type OrganizationSearchQueryVariables = Exact<{
  filter: OrganizationFilter;
}>;

export type OrganizationSearchQuery = {
  __typename?: 'Query';
  organizationsList: {
    __typename?: 'OrganizationListResponse';
    items: Array<{ __typename?: 'Organization'; id?: string | null }>;
  };
};

export type JobOfferDeleteMutationVariables = Exact<{
  filter: JobOfferKeyFilter;
}>;

export type JobOfferDeleteMutation = {
  __typename?: 'Mutation';
  jobOfferDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type JobOfferRenewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type JobOfferRenewMutation = {
  __typename?: 'Mutation';
  renewJobOffer: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type CollectiveIntelligenceItemFragment = {
  __typename?: 'CollectiveIntelligenceThread';
  id?: string | null;
  createdAt?: any | null;
  text?: string | null;
  title?: string | null;
  views?: {
    __typename?: 'UserListResponse';
    items: Array<{ __typename?: 'User'; id?: string | null }>;
  } | null;
  creator?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
  } | null;
  images?: {
    __typename?: 'FileListResponse';
    items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
  } | null;
};

export type CollectiveIntelligenceThreadCreateMutationVariables = Exact<{
  data: CollectiveIntelligenceThreadCreateInput;
}>;

export type CollectiveIntelligenceThreadCreateMutation = {
  __typename?: 'Mutation';
  collectiveIntelligenceThreadCreate: {
    __typename?: 'CollectiveIntelligenceThread';
    id?: string | null;
    createdAt?: any | null;
    text?: string | null;
    title?: string | null;
    views?: {
      __typename?: 'UserListResponse';
      items: Array<{ __typename?: 'User'; id?: string | null }>;
    } | null;
    creator?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    } | null;
    images?: {
      __typename?: 'FileListResponse';
      items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
    } | null;
  };
};

export type CollectiveIntelligenceThreadUpdateMutationVariables = Exact<{
  data: CollectiveIntelligenceThreadUpdateInput;
}>;

export type CollectiveIntelligenceThreadUpdateMutation = {
  __typename?: 'Mutation';
  collectiveIntelligenceThreadUpdate: {
    __typename?: 'CollectiveIntelligenceThread';
    id?: string | null;
    createdAt?: any | null;
    text?: string | null;
    title?: string | null;
    views?: {
      __typename?: 'UserListResponse';
      items: Array<{ __typename?: 'User'; id?: string | null }>;
    } | null;
    creator?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    } | null;
    images?: {
      __typename?: 'FileListResponse';
      items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
    } | null;
  };
};

export type CollectiveIntelligenceThreadsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<CollectiveIntelligenceThreadSort> | CollectiveIntelligenceThreadSort>;
  filter?: InputMaybe<CollectiveIntelligenceThreadFilter>;
}>;

export type CollectiveIntelligenceThreadsQuery = {
  __typename?: 'Query';
  threads: {
    __typename?: 'CollectiveIntelligenceThreadListResponse';
    count: number;
    items: Array<{
      __typename?: 'CollectiveIntelligenceThread';
      id?: string | null;
      createdAt?: any | null;
      text?: string | null;
      title?: string | null;
      views?: {
        __typename?: 'UserListResponse';
        items: Array<{ __typename?: 'User'; id?: string | null }>;
      } | null;
      creator?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      } | null;
      images?: {
        __typename?: 'FileListResponse';
        items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
      } | null;
    }>;
  };
};

export type CollectiveIntelligenceThreadQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CollectiveIntelligenceThreadQuery = {
  __typename?: 'Query';
  thread?: {
    __typename?: 'CollectiveIntelligenceThread';
    id?: string | null;
    createdAt?: any | null;
    text?: string | null;
    title?: string | null;
    views?: {
      __typename?: 'UserListResponse';
      items: Array<{ __typename?: 'User'; id?: string | null }>;
    } | null;
    creator?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    } | null;
    images?: {
      __typename?: 'FileListResponse';
      items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
    } | null;
  } | null;
};

export type DiscountListItemFragment = {
  __typename?: 'Discount';
  id?: string | null;
  title?: string | null;
  amountPercent?: number | null;
  startDate?: any | null;
  expirationDate?: any | null;
  timezone?: string | null;
  category?: string | null;
  status?: string | null;
  type?: string | null;
  createdAt?: any | null;
  terms?: string | null;
  usersRedeemedCount?: number | null;
  organization?: {
    __typename?: 'Organization';
    id?: string | null;
    name?: string | null;
    logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
  } | null;
  images?: {
    __typename?: 'FileListResponse';
    items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
  } | null;
};

export type DiscountsListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<DiscountFilter>;
  sort?: InputMaybe<Array<DiscountSort> | DiscountSort>;
}>;

export type DiscountsListQuery = {
  __typename?: 'Query';
  discounts: {
    __typename?: 'DiscountListResponse';
    count: number;
    items: Array<{
      __typename?: 'Discount';
      id?: string | null;
      title?: string | null;
      amountPercent?: number | null;
      startDate?: any | null;
      expirationDate?: any | null;
      timezone?: string | null;
      category?: string | null;
      status?: string | null;
      type?: string | null;
      createdAt?: any | null;
      terms?: string | null;
      usersRedeemedCount?: number | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
      } | null;
      images?: {
        __typename?: 'FileListResponse';
        items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
      } | null;
    }>;
  };
};

export type DiscountsCountQueryVariables = Exact<{
  filter?: InputMaybe<DiscountFilter>;
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DiscountSort> | DiscountSort>;
}>;

export type DiscountsCountQuery = {
  __typename?: 'Query';
  discounts: { __typename?: 'DiscountListResponse'; count: number };
};

export type IncreaseDiscountViewsRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IncreaseDiscountViewsRequestMutation = {
  __typename?: 'Mutation';
  discountUpdateByFilter: { __typename?: 'DiscountManyResponse'; count: number };
};

export type IncreaseDiscountClicksRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IncreaseDiscountClicksRequestMutation = {
  __typename?: 'Mutation';
  discountUpdateByFilter: { __typename?: 'DiscountManyResponse'; count: number };
};

export type RedeemDiscountMutationVariables = Exact<{
  discount: DiscountKeyFilter;
  school: SchoolConfigurationKeyFilter;
}>;

export type RedeemDiscountMutation = {
  __typename?: 'Mutation';
  redeemDiscount: { __typename?: 'SuccessWithMessageResponse'; success?: boolean | null };
};

export type Get_Group_Members_RequestQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type Get_Group_Members_RequestQuery = {
  __typename?: 'Query';
  groupsList: {
    __typename?: 'GroupListResponse';
    count: number;
    items: Array<{ __typename?: 'Group'; id?: string | null }>;
  };
};

export type GroupAdminsQueryVariables = Exact<{
  groupIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GroupAdminsQuery = {
  __typename?: 'Query';
  groupAdminPreferencesList: {
    __typename?: 'GroupAdminPreferenceListResponse';
    count: number;
    items: Array<{
      __typename?: 'GroupAdminPreference';
      id?: string | null;
      user?: { __typename?: 'User'; id?: string | null } | null;
    }>;
  };
};

export type GroupMembersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GroupMembersQuery = {
  __typename?: 'Query';
  group?: {
    __typename?: 'Group';
    creator?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      status?: string | null;
      avatar?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null;
    invitedUsers?: {
      __typename?: 'GroupsMembersRequestListResponse';
      items: Array<{
        __typename?: 'GroupsMembersRequest';
        id?: string | null;
        status?: string | null;
        user?: {
          __typename?: 'User';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          status?: string | null;
          avatar?: {
            __typename?: 'File';
            id?: string | null;
            fileId?: string | null;
            filename?: string | null;
            downloadUrl?: string | null;
          } | null;
        } | null;
      }>;
    } | null;
    adminUsers?: {
      __typename?: 'GroupAdminPreferenceListResponse';
      items: Array<{
        __typename?: 'GroupAdminPreference';
        id?: string | null;
        status?: string | null;
        user?: {
          __typename?: 'User';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          status?: string | null;
          avatar?: {
            __typename?: 'File';
            id?: string | null;
            fileId?: string | null;
            filename?: string | null;
            downloadUrl?: string | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type MembersListItemFragment = {
  __typename?: 'User';
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  affiliation?: string | null;
  graduatingYear?: any | null;
  avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
  jobExperience?: {
    __typename?: 'JobEmployeeListResponse';
    items: Array<{
      __typename?: 'JobEmployee';
      id?: string | null;
      positionDescription?: string | null;
      positionTitle?: string | null;
      companyName?: string | null;
      companyLocation?: {
        __typename?: 'SmartAddress';
        state?: string | null;
        city?: string | null;
        street1?: string | null;
        zip?: string | null;
      } | null;
      companyImage?: { __typename?: 'File'; downloadUrl?: string | null } | null;
    }>;
  } | null;
};

export type GroupsListItemFragment = {
  __typename?: 'Group';
  id?: string | null;
  title?: string | null;
  membersCount?: number | null;
  description?: string | null;
  status?: string | null;
  isApproved?: boolean | null;
  createdAt?: any | null;
  logo?: {
    __typename?: 'File';
    id?: string | null;
    downloadUrl?: string | null;
    fileId?: string | null;
    filename?: string | null;
  } | null;
  members?: {
    __typename?: 'UserListResponse';
    count: number;
    items: Array<{
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      affiliation?: string | null;
      graduatingYear?: any | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      jobExperience?: {
        __typename?: 'JobEmployeeListResponse';
        items: Array<{
          __typename?: 'JobEmployee';
          id?: string | null;
          positionDescription?: string | null;
          positionTitle?: string | null;
          companyName?: string | null;
          companyLocation?: {
            __typename?: 'SmartAddress';
            state?: string | null;
            city?: string | null;
            street1?: string | null;
            zip?: string | null;
          } | null;
          companyImage?: { __typename?: 'File'; downloadUrl?: string | null } | null;
        }>;
      } | null;
    }>;
  } | null;
  creator?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  posts?: { __typename?: 'GroupPostListResponse'; count: number } | null;
  groupAdminPreferences?: {
    __typename?: 'GroupAdminPreferenceListResponse';
    count: number;
    items: Array<{
      __typename?: 'GroupAdminPreference';
      id?: string | null;
      status?: string | null;
      user?: { __typename?: 'User'; id?: string | null } | null;
    }>;
  } | null;
};

export type GroupsListQueryVariables = Exact<{
  filter?: InputMaybe<GroupFilter>;
  sort?: InputMaybe<Array<GroupSort> | GroupSort>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;

export type GroupsListQuery = {
  __typename?: 'Query';
  groupsList: {
    __typename?: 'GroupListResponse';
    count: number;
    items: Array<{
      __typename?: 'Group';
      id?: string | null;
      title?: string | null;
      membersCount?: number | null;
      description?: string | null;
      status?: string | null;
      isApproved?: boolean | null;
      createdAt?: any | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      } | null;
      members?: {
        __typename?: 'UserListResponse';
        count: number;
        items: Array<{
          __typename?: 'User';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          affiliation?: string | null;
          graduatingYear?: any | null;
          avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
          jobExperience?: {
            __typename?: 'JobEmployeeListResponse';
            items: Array<{
              __typename?: 'JobEmployee';
              id?: string | null;
              positionDescription?: string | null;
              positionTitle?: string | null;
              companyName?: string | null;
              companyLocation?: {
                __typename?: 'SmartAddress';
                state?: string | null;
                city?: string | null;
                street1?: string | null;
                zip?: string | null;
              } | null;
              companyImage?: { __typename?: 'File'; downloadUrl?: string | null } | null;
            }>;
          } | null;
        }>;
      } | null;
      creator?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      posts?: { __typename?: 'GroupPostListResponse'; count: number } | null;
      groupAdminPreferences?: {
        __typename?: 'GroupAdminPreferenceListResponse';
        count: number;
        items: Array<{
          __typename?: 'GroupAdminPreference';
          id?: string | null;
          status?: string | null;
          user?: { __typename?: 'User'; id?: string | null } | null;
        }>;
      } | null;
    }>;
  };
};

export type GroupPostsListItemFragment = {
  __typename?: 'GroupPost';
  id?: string | null;
  createdAt?: any | null;
  text?: string | null;
  isPinned?: boolean | null;
  commentsAllowed?: boolean | null;
  status?: string | null;
  reportStatus?: string | null;
  flaggedAt?: any | null;
  flagReason?: string | null;
  updatedAt?: any | null;
  isAutoReported?: boolean | null;
  author?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    ownedOrganizations?: {
      __typename?: 'OrganizationListResponse';
      items: Array<{
        __typename?: 'Organization';
        id?: string | null;
        advertising?: {
          __typename?: 'Advertising';
          id?: string | null;
          supporter?: {
            __typename?: 'AdvertisingSupporter';
            id?: string | null;
            isSupporter?: boolean | null;
          } | null;
        } | null;
      }>;
    } | null;
    avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
  } | null;
  media?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      id?: string | null;
      downloadUrl?: string | null;
      meta?: any | null;
      fileId?: string | null;
      filename?: string | null;
    }>;
  } | null;
  group?: { __typename?: 'Group'; id?: string | null } | null;
  lastFlaggedBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  mentions?: {
    __typename?: 'PostMentionListResponse';
    items: Array<{
      __typename?: 'PostMention';
      id?: string | null;
      mentionId?: string | null;
      mentionText?: string | null;
      position?: number | null;
      type?: string | null;
    }>;
  } | null;
};

export type GroupPostCreateMutationVariables = Exact<{
  data: GroupPostCreateInput;
}>;

export type GroupPostCreateMutation = {
  __typename?: 'Mutation';
  groupPostCreate: {
    __typename?: 'GroupPost';
    id?: string | null;
    createdAt?: any | null;
    text?: string | null;
    isPinned?: boolean | null;
    commentsAllowed?: boolean | null;
    status?: string | null;
    reportStatus?: string | null;
    flaggedAt?: any | null;
    flagReason?: string | null;
    updatedAt?: any | null;
    isAutoReported?: boolean | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
        }>;
      } | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        meta?: any | null;
        fileId?: string | null;
        filename?: string | null;
      }>;
    } | null;
    group?: { __typename?: 'Group'; id?: string | null } | null;
    lastFlaggedBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    mentions?: {
      __typename?: 'PostMentionListResponse';
      items: Array<{
        __typename?: 'PostMention';
        id?: string | null;
        mentionId?: string | null;
        mentionText?: string | null;
        position?: number | null;
        type?: string | null;
      }>;
    } | null;
  };
};

export type GroupPostUpdateMutationVariables = Exact<{
  data: GroupPostUpdateInput;
}>;

export type GroupPostUpdateMutation = {
  __typename?: 'Mutation';
  groupPostUpdate: {
    __typename?: 'GroupPost';
    id?: string | null;
    createdAt?: any | null;
    text?: string | null;
    isPinned?: boolean | null;
    commentsAllowed?: boolean | null;
    status?: string | null;
    reportStatus?: string | null;
    flaggedAt?: any | null;
    flagReason?: string | null;
    updatedAt?: any | null;
    isAutoReported?: boolean | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
        }>;
      } | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        meta?: any | null;
        fileId?: string | null;
        filename?: string | null;
      }>;
    } | null;
    group?: { __typename?: 'Group'; id?: string | null } | null;
    lastFlaggedBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    mentions?: {
      __typename?: 'PostMentionListResponse';
      items: Array<{
        __typename?: 'PostMention';
        id?: string | null;
        mentionId?: string | null;
        mentionText?: string | null;
        position?: number | null;
        type?: string | null;
      }>;
    } | null;
  };
};

export type GroupPostsListQueryVariables = Exact<{
  first: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<GroupPostSort> | GroupPostSort>;
  filter?: InputMaybe<GroupPostFilter>;
  pinnedFilter: GroupPostFilter;
}>;

export type GroupPostsListQuery = {
  __typename?: 'Query';
  groupPostsList: {
    __typename?: 'GroupPostListResponse';
    items: Array<{
      __typename?: 'GroupPost';
      id?: string | null;
      createdAt?: any | null;
      text?: string | null;
      isPinned?: boolean | null;
      commentsAllowed?: boolean | null;
      status?: string | null;
      reportStatus?: string | null;
      flaggedAt?: any | null;
      flagReason?: string | null;
      updatedAt?: any | null;
      isAutoReported?: boolean | null;
      author?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        ownedOrganizations?: {
          __typename?: 'OrganizationListResponse';
          items: Array<{
            __typename?: 'Organization';
            id?: string | null;
            advertising?: {
              __typename?: 'Advertising';
              id?: string | null;
              supporter?: {
                __typename?: 'AdvertisingSupporter';
                id?: string | null;
                isSupporter?: boolean | null;
              } | null;
            } | null;
          }>;
        } | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          meta?: any | null;
          fileId?: string | null;
          filename?: string | null;
        }>;
      } | null;
      group?: { __typename?: 'Group'; id?: string | null } | null;
      lastFlaggedBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      mentions?: {
        __typename?: 'PostMentionListResponse';
        items: Array<{
          __typename?: 'PostMention';
          id?: string | null;
          mentionId?: string | null;
          mentionText?: string | null;
          position?: number | null;
          type?: string | null;
        }>;
      } | null;
    }>;
  };
  pinnedPostsList: {
    __typename?: 'GroupPostListResponse';
    items: Array<{
      __typename?: 'GroupPost';
      id?: string | null;
      createdAt?: any | null;
      text?: string | null;
      isPinned?: boolean | null;
      commentsAllowed?: boolean | null;
      status?: string | null;
      reportStatus?: string | null;
      flaggedAt?: any | null;
      flagReason?: string | null;
      updatedAt?: any | null;
      isAutoReported?: boolean | null;
      author?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        ownedOrganizations?: {
          __typename?: 'OrganizationListResponse';
          items: Array<{
            __typename?: 'Organization';
            id?: string | null;
            advertising?: {
              __typename?: 'Advertising';
              id?: string | null;
              supporter?: {
                __typename?: 'AdvertisingSupporter';
                id?: string | null;
                isSupporter?: boolean | null;
              } | null;
            } | null;
          }>;
        } | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          meta?: any | null;
          fileId?: string | null;
          filename?: string | null;
        }>;
      } | null;
      group?: { __typename?: 'Group'; id?: string | null } | null;
      lastFlaggedBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      mentions?: {
        __typename?: 'PostMentionListResponse';
        items: Array<{
          __typename?: 'PostMention';
          id?: string | null;
          mentionId?: string | null;
          mentionText?: string | null;
          position?: number | null;
          type?: string | null;
        }>;
      } | null;
    }>;
  };
};

export type GroupPostsCountQueryVariables = Exact<{
  filter?: InputMaybe<GroupPostFilter>;
}>;

export type GroupPostsCountQuery = {
  __typename?: 'Query';
  groupPostsList: { __typename?: 'GroupPostListResponse'; count: number };
};

export type GroupDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GroupDetailsQuery = {
  __typename?: 'Query';
  group?: {
    __typename?: 'Group';
    id?: string | null;
    title?: string | null;
    membersCount?: number | null;
    description?: string | null;
    status?: string | null;
    isApproved?: boolean | null;
    createdAt?: any | null;
    logo?: {
      __typename?: 'File';
      id?: string | null;
      downloadUrl?: string | null;
      fileId?: string | null;
      filename?: string | null;
    } | null;
    members?: {
      __typename?: 'UserListResponse';
      count: number;
      items: Array<{
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        affiliation?: string | null;
        graduatingYear?: any | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        jobExperience?: {
          __typename?: 'JobEmployeeListResponse';
          items: Array<{
            __typename?: 'JobEmployee';
            id?: string | null;
            positionDescription?: string | null;
            positionTitle?: string | null;
            companyName?: string | null;
            companyLocation?: {
              __typename?: 'SmartAddress';
              state?: string | null;
              city?: string | null;
              street1?: string | null;
              zip?: string | null;
            } | null;
            companyImage?: { __typename?: 'File'; downloadUrl?: string | null } | null;
          }>;
        } | null;
      }>;
    } | null;
    creator?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    posts?: { __typename?: 'GroupPostListResponse'; count: number } | null;
    groupAdminPreferences?: {
      __typename?: 'GroupAdminPreferenceListResponse';
      count: number;
      items: Array<{
        __typename?: 'GroupAdminPreference';
        id?: string | null;
        status?: string | null;
        user?: { __typename?: 'User'; id?: string | null } | null;
      }>;
    } | null;
  } | null;
};

export type GroupCreateRequestMutationVariables = Exact<{
  data: GroupCreateInput;
}>;

export type GroupCreateRequestMutation = {
  __typename?: 'Mutation';
  groupCreateRequest: { __typename?: 'GroupCreateRequestSuccess'; groupId?: string | null };
};

export type UsersIdsSearchQueryVariables = Exact<{
  filter: UserFilter;
}>;

export type UsersIdsSearchQuery = {
  __typename?: 'Query';
  usersList: {
    __typename?: 'UserListResponse';
    items: Array<{ __typename?: 'User'; id?: string | null }>;
  };
};

export type GroupDetailsFragment = {
  __typename?: 'Group';
  id?: string | null;
  title?: string | null;
  description?: string | null;
  membersCount?: number | null;
  createdAt?: any | null;
  isApproved?: boolean | null;
  members?: {
    __typename?: 'UserListResponse';
    items: Array<{ __typename?: 'User'; id?: string | null }>;
  } | null;
  logo?: {
    __typename?: 'File';
    id?: string | null;
    fileId?: string | null;
    downloadUrl?: string | null;
    filename?: string | null;
  } | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    lastName?: string | null;
    firstName?: string | null;
  } | null;
  groupAdminPreferences?: {
    __typename?: 'GroupAdminPreferenceListResponse';
    items: Array<{
      __typename?: 'GroupAdminPreference';
      status?: string | null;
      id?: string | null;
      user?: {
        __typename?: 'User';
        email?: string | null;
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        affiliation?: string | null;
      } | null;
    }>;
  } | null;
};

export type GroupsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<GroupFilter>;
  sort?: InputMaybe<Array<GroupSort> | GroupSort>;
}>;

export type GroupsQuery = {
  __typename?: 'Query';
  groupsList: {
    __typename?: 'GroupListResponse';
    count: number;
    items: Array<{
      __typename?: 'Group';
      id?: string | null;
      title?: string | null;
      description?: string | null;
      membersCount?: number | null;
      createdAt?: any | null;
      isApproved?: boolean | null;
      members?: {
        __typename?: 'UserListResponse';
        items: Array<{ __typename?: 'User'; id?: string | null }>;
      } | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        downloadUrl?: string | null;
        filename?: string | null;
      } | null;
      createdBy?: {
        __typename?: 'User';
        id?: string | null;
        lastName?: string | null;
        firstName?: string | null;
      } | null;
      groupAdminPreferences?: {
        __typename?: 'GroupAdminPreferenceListResponse';
        items: Array<{
          __typename?: 'GroupAdminPreference';
          status?: string | null;
          id?: string | null;
          user?: {
            __typename?: 'User';
            email?: string | null;
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            affiliation?: string | null;
          } | null;
        }>;
      } | null;
    }>;
  };
};

export type DiscountByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DiscountByIdQuery = {
  __typename?: 'Query';
  discount?: {
    __typename?: 'Discount';
    id?: string | null;
    title?: string | null;
    terms?: string | null;
    amountPercent?: number | null;
    type?: string | null;
    startDate?: any | null;
    expirationDate?: any | null;
    category?: string | null;
    organization?: {
      __typename?: 'Organization';
      id?: string | null;
      name?: string | null;
      logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
    } | null;
    images?: {
      __typename?: 'FileListResponse';
      items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
    } | null;
  } | null;
};

export type EventByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type EventByIdQuery = {
  __typename?: 'Query';
  event?: {
    __typename?: 'Event';
    id?: string | null;
    title?: string | null;
    date?: any | null;
    link?: string | null;
    status?: string | null;
    description?: string | null;
    createdAt?: any | null;
    timezone?: string | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
    location?: {
      __typename?: 'Address';
      id?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      street1?: string | null;
    } | null;
    titleSponsor?: {
      __typename?: 'Organization';
      id?: string | null;
      industry?: string | null;
      name?: string | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
      } | null;
    } | null;
    images?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      }>;
    } | null;
    otherSponsors?: {
      __typename?: 'OrganizationListResponse';
      items: Array<{
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          fileId?: string | null;
          filename?: string | null;
        } | null;
      }>;
    } | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
};

export type JobOfferByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type JobOfferByIdQuery = {
  __typename?: 'Query';
  jobOffer?: {
    __typename?: 'JobOffer';
    id?: string | null;
    description?: string | null;
    position?: string | null;
    postDate?: any | null;
    employmentType?: string | null;
    createdAt?: any | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      email?: string | null;
      fullName?: string | null;
      graduatingYear?: any | null;
      professionalDesignations?: string | null;
      currentIndustry?: string | null;
      isSeekingEmployment?: boolean | null;
      affiliation?: string | null;
      jobExperience?: {
        __typename?: 'JobEmployeeListResponse';
        items: Array<{
          __typename?: 'JobEmployee';
          id?: string | null;
          companyName?: string | null;
          positionTitle?: string | null;
          isCurrentlyWorking?: boolean | null;
          companyImage?: {
            __typename?: 'File';
            id?: string | null;
            fileId?: string | null;
            downloadUrl?: string | null;
          } | null;
        }>;
      } | null;
      avatar?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null;
    organization?: {
      __typename?: 'Organization';
      id?: string | null;
      name?: string | null;
      owner?: { __typename?: 'User'; id?: string | null } | null;
      managers?: {
        __typename?: 'OrganizationManagerPreferenceListResponse';
        items: Array<{
          __typename?: 'OrganizationManagerPreference';
          id?: string | null;
          user?: { __typename?: 'User'; id?: string | null } | null;
        }>;
      } | null;
      location?: {
        __typename?: 'OrganizationLocationListResponse';
        items: Array<{
          __typename?: 'OrganizationLocation';
          id?: string | null;
          address?: {
            __typename?: 'AddressListResponse';
            items: Array<{
              __typename?: 'Address';
              id?: string | null;
              zip?: string | null;
              street1?: string | null;
              state?: string | null;
              city?: string | null;
            }>;
          } | null;
        }>;
      } | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null;
  } | null;
};

export type HomeFeedPostsListItemFragment = {
  __typename?: 'HomeFeedPost';
  id?: string | null;
  createdAt?: any | null;
  text?: string | null;
  isPinned?: boolean | null;
  commentsAllowed?: boolean | null;
  status?: string | null;
  reportStatus?: string | null;
  flaggedAt?: any | null;
  flagReason?: string | null;
  updatedAt?: any | null;
  isAutoReported?: boolean | null;
  discount?: {
    __typename?: 'Discount';
    id?: string | null;
    title?: string | null;
    terms?: string | null;
    amountPercent?: number | null;
    type?: string | null;
    startDate?: any | null;
    expirationDate?: any | null;
    category?: string | null;
    organization?: {
      __typename?: 'Organization';
      id?: string | null;
      name?: string | null;
      logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
    } | null;
    images?: {
      __typename?: 'FileListResponse';
      items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
    } | null;
  } | null;
  media?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      id?: string | null;
      downloadUrl?: string | null;
      meta?: any | null;
      fileId?: string | null;
      filename?: string | null;
    }>;
  } | null;
  author?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    ownedOrganizations?: {
      __typename?: 'OrganizationListResponse';
      items: Array<{
        __typename?: 'Organization';
        id?: string | null;
        advertising?: {
          __typename?: 'Advertising';
          id?: string | null;
          supporter?: {
            __typename?: 'AdvertisingSupporter';
            id?: string | null;
            isSupporter?: boolean | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
  } | null;
  lastFlaggedBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  mentions?: {
    __typename?: 'PostMentionListResponse';
    items: Array<{
      __typename?: 'PostMention';
      id?: string | null;
      mentionId?: string | null;
      mentionText?: string | null;
      position?: number | null;
      type?: string | null;
    }>;
  } | null;
};

export type HomeFeedPostCreateMutationVariables = Exact<{
  data: HomeFeedPostCreateInput;
}>;

export type HomeFeedPostCreateMutation = {
  __typename?: 'Mutation';
  homeFeedPostCreate: {
    __typename?: 'HomeFeedPost';
    id?: string | null;
    createdAt?: any | null;
    text?: string | null;
    isPinned?: boolean | null;
    commentsAllowed?: boolean | null;
    status?: string | null;
    reportStatus?: string | null;
    flaggedAt?: any | null;
    flagReason?: string | null;
    updatedAt?: any | null;
    isAutoReported?: boolean | null;
    discount?: {
      __typename?: 'Discount';
      id?: string | null;
      title?: string | null;
      terms?: string | null;
      amountPercent?: number | null;
      type?: string | null;
      startDate?: any | null;
      expirationDate?: any | null;
      category?: string | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
      } | null;
      images?: {
        __typename?: 'FileListResponse';
        items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
      } | null;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        meta?: any | null;
        fileId?: string | null;
        filename?: string | null;
      }>;
    } | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    } | null;
    lastFlaggedBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    mentions?: {
      __typename?: 'PostMentionListResponse';
      items: Array<{
        __typename?: 'PostMention';
        id?: string | null;
        mentionId?: string | null;
        mentionText?: string | null;
        position?: number | null;
        type?: string | null;
      }>;
    } | null;
  };
};

export type HomeFeedPostUpdateMutationVariables = Exact<{
  data: UpdateHomeFeedPostRequestInput;
}>;

export type HomeFeedPostUpdateMutation = {
  __typename?: 'Mutation';
  updateHomeFeedPostRequest: {
    __typename?: 'UpdateHomeFeedPostResponse';
    success: boolean;
    verified: boolean;
  };
};

export type HomeFeedSinglePostQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type HomeFeedSinglePostQuery = {
  __typename?: 'Query';
  homeFeedPost?: {
    __typename?: 'HomeFeedPost';
    id?: string | null;
    createdAt?: any | null;
    text?: string | null;
    isPinned?: boolean | null;
    commentsAllowed?: boolean | null;
    status?: string | null;
    reportStatus?: string | null;
    flaggedAt?: any | null;
    flagReason?: string | null;
    updatedAt?: any | null;
    isAutoReported?: boolean | null;
    discount?: {
      __typename?: 'Discount';
      id?: string | null;
      title?: string | null;
      terms?: string | null;
      amountPercent?: number | null;
      type?: string | null;
      startDate?: any | null;
      expirationDate?: any | null;
      category?: string | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
      } | null;
      images?: {
        __typename?: 'FileListResponse';
        items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
      } | null;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        meta?: any | null;
        fileId?: string | null;
        filename?: string | null;
      }>;
    } | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    } | null;
    lastFlaggedBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    mentions?: {
      __typename?: 'PostMentionListResponse';
      items: Array<{
        __typename?: 'PostMention';
        id?: string | null;
        mentionId?: string | null;
        mentionText?: string | null;
        position?: number | null;
        type?: string | null;
      }>;
    } | null;
  } | null;
};

export type OrganizationContactMutationVariables = Exact<{
  subject: Scalars['String'];
  text: Scalars['String'];
  userIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type OrganizationContactMutation = {
  __typename?: 'Mutation';
  contactOrganizationRequest: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type Job_Offer_UpdateMutationVariables = Exact<{
  data: JobOfferUpdateInput;
}>;

export type Job_Offer_UpdateMutation = {
  __typename?: 'Mutation';
  jobOfferUpdate: { __typename?: 'JobOffer'; id?: string | null; engagementCount?: number | null };
};

export type Job_QueryQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type Job_QueryQuery = {
  __typename?: 'Query';
  jobOffer?: {
    __typename?: 'JobOffer';
    id?: string | null;
    engagementCount?: number | null;
  } | null;
};

export type HomeFeedJobOffersListQueryVariables = Exact<{ [key: string]: never }>;

export type HomeFeedJobOffersListQuery = {
  __typename?: 'Query';
  jobOffersList: {
    __typename?: 'JobOfferListResponse';
    items: Array<{
      __typename?: 'JobOffer';
      id?: string | null;
      description?: string | null;
      position?: string | null;
      postDate?: any | null;
      employmentType?: string | null;
      createdAt?: any | null;
      createdBy?: {
        __typename?: 'User';
        id?: string | null;
        email?: string | null;
        fullName?: string | null;
        graduatingYear?: any | null;
        professionalDesignations?: string | null;
        currentIndustry?: string | null;
        isSeekingEmployment?: boolean | null;
        affiliation?: string | null;
        jobExperience?: {
          __typename?: 'JobEmployeeListResponse';
          items: Array<{
            __typename?: 'JobEmployee';
            id?: string | null;
            companyName?: string | null;
            positionTitle?: string | null;
            isCurrentlyWorking?: boolean | null;
            companyImage?: {
              __typename?: 'File';
              id?: string | null;
              fileId?: string | null;
              downloadUrl?: string | null;
            } | null;
          }>;
        } | null;
        avatar?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      } | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        owner?: { __typename?: 'User'; id?: string | null } | null;
        managers?: {
          __typename?: 'OrganizationManagerPreferenceListResponse';
          items: Array<{
            __typename?: 'OrganizationManagerPreference';
            id?: string | null;
            user?: { __typename?: 'User'; id?: string | null } | null;
          }>;
        } | null;
        location?: {
          __typename?: 'OrganizationLocationListResponse';
          items: Array<{
            __typename?: 'OrganizationLocation';
            id?: string | null;
            address?: {
              __typename?: 'AddressListResponse';
              items: Array<{
                __typename?: 'Address';
                id?: string | null;
                zip?: string | null;
                street1?: string | null;
                state?: string | null;
                city?: string | null;
              }>;
            } | null;
          }>;
        } | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      } | null;
    }>;
  };
};

export type HomeFeedUsersBirthdayListQueryVariables = Exact<{
  filter: UserFilter;
}>;

export type HomeFeedUsersBirthdayListQuery = {
  __typename?: 'Query';
  usersList: {
    __typename?: 'UserListResponse';
    items: Array<{
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      birthDate?: any | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    }>;
  };
};

export type HomeFeedDiscountsListQueryVariables = Exact<{
  dateNow: Scalars['DateTime'];
  first?: InputMaybe<Scalars['Int']>;
}>;

export type HomeFeedDiscountsListQuery = {
  __typename?: 'Query';
  discountsList: {
    __typename?: 'DiscountListResponse';
    items: Array<{
      __typename?: 'Discount';
      id?: string | null;
      title?: string | null;
      amountPercent?: number | null;
      startDate?: any | null;
      expirationDate?: any | null;
      timezone?: string | null;
      category?: string | null;
      status?: string | null;
      type?: string | null;
      createdAt?: any | null;
      terms?: string | null;
      usersRedeemedCount?: number | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
      } | null;
      images?: {
        __typename?: 'FileListResponse';
        items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
      } | null;
    }>;
  };
};

export type HomeFeedItemFragment = {
  __typename?: 'HomeFeedItem';
  id?: string | null;
  text?: string | null;
  status?: string | null;
  reportStatus?: string | null;
  postType?: string | null;
  mentions?: any | null;
  media?: any | null;
  location?: any | null;
  groupId?: string | null;
  isPinned?: number | null;
  isFuture?: number | null;
  commentsAllowed?: number | null;
  authorId?: string | null;
  authorFirstName?: string | null;
  authorLastName?: string | null;
  authorAvatar?: any | null;
  discount?: string | null;
  amountPercent?: number | null;
  postDate?: any | null;
  startDate?: any | null;
  expirationDate?: any | null;
  legacyDate?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type?: string | null;
};

export type HomeFeedItemsQueryVariables = Exact<{
  filter?: InputMaybe<HomeFeedItemFilter>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HomeFeedItemSort> | HomeFeedItemSort>;
}>;

export type HomeFeedItemsQuery = {
  __typename?: 'Query';
  feed: {
    __typename?: 'HomeFeedItemListResponse';
    count: number;
    items: Array<{
      __typename?: 'HomeFeedItem';
      id?: string | null;
      text?: string | null;
      status?: string | null;
      reportStatus?: string | null;
      postType?: string | null;
      mentions?: any | null;
      media?: any | null;
      location?: any | null;
      groupId?: string | null;
      isPinned?: number | null;
      isFuture?: number | null;
      commentsAllowed?: number | null;
      authorId?: string | null;
      authorFirstName?: string | null;
      authorLastName?: string | null;
      authorAvatar?: any | null;
      discount?: string | null;
      amountPercent?: number | null;
      postDate?: any | null;
      startDate?: any | null;
      expirationDate?: any | null;
      legacyDate?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      type?: string | null;
    }>;
  };
};

export type HomeFeedItemsCountQueryVariables = Exact<{
  filter?: InputMaybe<HomeFeedItemFilter>;
}>;

export type HomeFeedItemsCountQuery = {
  __typename?: 'Query';
  feed: { __typename?: 'HomeFeedItemListResponse'; count: number };
};

export type HomeFeedSingleItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type HomeFeedSingleItemQuery = {
  __typename?: 'Query';
  homeFeedItem?: {
    __typename?: 'HomeFeedItem';
    updatedAt?: any | null;
    text?: string | null;
    status?: string | null;
    startDate?: any | null;
    reportStatus?: string | null;
    postType?: string | null;
    postDate?: any | null;
    groupId?: string | null;
    legacyDate?: any | null;
    mentions?: any | null;
    discount?: string | null;
    media?: any | null;
    location?: any | null;
    isPinned?: number | null;
    id?: string | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    commentsAllowed?: number | null;
    authorId?: string | null;
    authorFirstName?: string | null;
    authorLastName?: string | null;
    authorAvatar?: any | null;
    amountPercent?: number | null;
  } | null;
};

export type SendInboxMessageNotificationRequestMutationVariables = Exact<{
  data: SendInboxMessageNotificationRequestInput;
}>;

export type SendInboxMessageNotificationRequestMutation = {
  __typename?: 'Mutation';
  sendInboxMessageNotificationRequest: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type InboxFragment = {
  __typename?: 'Inbox';
  id?: string | null;
  subject?: any | null;
  type?: string | null;
  destinationGroups?: any | null;
  users?: {
    __typename?: 'UserListResponse';
    items: Array<{
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    }>;
  } | null;
  messages?: {
    __typename?: 'InboxMessageListResponse';
    items: Array<{
      __typename?: 'InboxMessage';
      id?: string | null;
      createdAt?: any | null;
      text?: string | null;
      reply?: boolean | null;
      isRead?: boolean | null;
      isDeleted?: boolean | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          meta?: any | null;
          downloadUrl?: string | null;
        }>;
      } | null;
      author?: { __typename?: 'User'; id?: string | null } | null;
      inbox?: {
        __typename?: 'Inbox';
        id?: string | null;
        type?: string | null;
        destinationGroups?: any | null;
      } | null;
      messageMedia?: {
        __typename?: 'InboxMessageMedia';
        id?: string | null;
        media?: {
          __typename?: 'FileListResponse';
          items: Array<{
            __typename?: 'File';
            id?: string | null;
            meta?: any | null;
            downloadUrl?: string | null;
            filename?: string | null;
          }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type InboxUserFragment = {
  __typename?: 'User';
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
};

export type InboxMessageFragment = {
  __typename?: 'InboxMessage';
  id?: string | null;
  createdAt?: any | null;
  text?: string | null;
  reply?: boolean | null;
  isRead?: boolean | null;
  isDeleted?: boolean | null;
  media?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      id?: string | null;
      meta?: any | null;
      downloadUrl?: string | null;
    }>;
  } | null;
  author?: { __typename?: 'User'; id?: string | null } | null;
  inbox?: {
    __typename?: 'Inbox';
    id?: string | null;
    type?: string | null;
    destinationGroups?: any | null;
  } | null;
  messageMedia?: {
    __typename?: 'InboxMessageMedia';
    id?: string | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        meta?: any | null;
        downloadUrl?: string | null;
        filename?: string | null;
      }>;
    } | null;
  } | null;
};

export type InboxChatsListQueryVariables = Exact<{
  filter?: InputMaybe<InboxFilter>;
  sort: Array<InboxSort> | InboxSort;
  first: Scalars['Int'];
  firstUsers?: InputMaybe<Scalars['Int']>;
  firstMessages?: InputMaybe<Scalars['Int']>;
  sortMessages?: InputMaybe<Array<InboxMessageSort> | InboxMessageSort>;
  filterMessages?: InputMaybe<InboxMessageFilter>;
}>;

export type InboxChatsListQuery = {
  __typename?: 'Query';
  inboxes: {
    __typename?: 'InboxListResponse';
    count: number;
    items: Array<{
      __typename?: 'Inbox';
      id?: string | null;
      subject?: any | null;
      type?: string | null;
      destinationGroups?: any | null;
      users?: {
        __typename?: 'UserListResponse';
        items: Array<{
          __typename?: 'User';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        }>;
      } | null;
      messages?: {
        __typename?: 'InboxMessageListResponse';
        items: Array<{
          __typename?: 'InboxMessage';
          id?: string | null;
          createdAt?: any | null;
          text?: string | null;
          reply?: boolean | null;
          isRead?: boolean | null;
          isDeleted?: boolean | null;
          media?: {
            __typename?: 'FileListResponse';
            items: Array<{
              __typename?: 'File';
              id?: string | null;
              meta?: any | null;
              downloadUrl?: string | null;
            }>;
          } | null;
          author?: { __typename?: 'User'; id?: string | null } | null;
          inbox?: {
            __typename?: 'Inbox';
            id?: string | null;
            type?: string | null;
            destinationGroups?: any | null;
          } | null;
          messageMedia?: {
            __typename?: 'InboxMessageMedia';
            id?: string | null;
            media?: {
              __typename?: 'FileListResponse';
              items: Array<{
                __typename?: 'File';
                id?: string | null;
                meta?: any | null;
                downloadUrl?: string | null;
                filename?: string | null;
              }>;
            } | null;
          } | null;
        }>;
      } | null;
    }>;
  };
};

export type InboxMessagesListQueryVariables = Exact<{
  inboxId: Scalars['ID'];
  firstUsers?: InputMaybe<Scalars['Int']>;
  firstMessages: Scalars['Int'];
  sortMessages?: InputMaybe<Array<InboxMessageSort> | InboxMessageSort>;
  filterMessages: InboxMessageFilter;
}>;

export type InboxMessagesListQuery = {
  __typename?: 'Query';
  inbox?: {
    __typename?: 'Inbox';
    id?: string | null;
    subject?: any | null;
    type?: string | null;
    destinationGroups?: any | null;
    users?: {
      __typename?: 'UserListResponse';
      items: Array<{
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      }>;
    } | null;
    messages?: {
      __typename?: 'InboxMessageListResponse';
      items: Array<{
        __typename?: 'InboxMessage';
        id?: string | null;
        createdAt?: any | null;
        text?: string | null;
        reply?: boolean | null;
        isRead?: boolean | null;
        isDeleted?: boolean | null;
        media?: {
          __typename?: 'FileListResponse';
          items: Array<{
            __typename?: 'File';
            id?: string | null;
            meta?: any | null;
            downloadUrl?: string | null;
          }>;
        } | null;
        author?: { __typename?: 'User'; id?: string | null } | null;
        inbox?: {
          __typename?: 'Inbox';
          id?: string | null;
          type?: string | null;
          destinationGroups?: any | null;
        } | null;
        messageMedia?: {
          __typename?: 'InboxMessageMedia';
          id?: string | null;
          media?: {
            __typename?: 'FileListResponse';
            items: Array<{
              __typename?: 'File';
              id?: string | null;
              meta?: any | null;
              downloadUrl?: string | null;
              filename?: string | null;
            }>;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type InboxMessagesCountQueryVariables = Exact<{
  inboxId: Scalars['ID'];
  sort?: InputMaybe<Array<InboxMessageSort> | InboxMessageSort>;
  filter: InboxMessageFilter;
}>;

export type InboxMessagesCountQuery = {
  __typename?: 'Query';
  inbox?: {
    __typename?: 'Inbox';
    id?: string | null;
    messages?: { __typename?: 'InboxMessageListResponse'; count: number } | null;
  } | null;
};

export type InboxMessageCreateMutationVariables = Exact<{
  data: InboxMessageCreateInput;
}>;

export type InboxMessageCreateMutation = {
  __typename?: 'Mutation';
  inboxMessageCreate: {
    __typename?: 'InboxMessage';
    id?: string | null;
    createdAt?: any | null;
    text?: string | null;
    isRead?: boolean | null;
    isDeleted?: boolean | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
    } | null;
    author?: { __typename?: 'User'; id?: string | null } | null;
    inbox?: {
      __typename?: 'Inbox';
      id?: string | null;
      type?: string | null;
      destinationGroups?: any | null;
      users?: {
        __typename?: 'UserListResponse';
        items: Array<{ __typename?: 'User'; id?: string | null }>;
      } | null;
    } | null;
  };
};

export type InboxCreateMutationVariables = Exact<{
  data: InboxCreateInput;
}>;

export type InboxCreateMutation = {
  __typename?: 'Mutation';
  inboxCreate: { __typename?: 'Inbox'; id?: string | null };
};

export type InboxDeleteMutationVariables = Exact<{
  data: InboxDeleteInput;
  force?: InputMaybe<Scalars['Boolean']>;
}>;

export type InboxDeleteMutation = {
  __typename?: 'Mutation';
  inboxDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type InboxMessageUpdateMutationVariables = Exact<{
  data: InboxMessageUpdateInput;
}>;

export type InboxMessageUpdateMutation = {
  __typename?: 'Mutation';
  inboxMessageUpdate: {
    __typename?: 'InboxMessage';
    id?: string | null;
    isDeleted?: boolean | null;
    isRead?: boolean | null;
  };
};

export type InboxMessageDeleteMutationVariables = Exact<{
  data: InboxMessageDeleteInput;
}>;

export type InboxMessageDeleteMutation = {
  __typename?: 'Mutation';
  inboxMessageDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type InboxMessageClearTrashMutationVariables = Exact<{ [key: string]: never }>;

export type InboxMessageClearTrashMutation = {
  __typename?: 'Mutation';
  inboxMessageDeleteByFilter?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type InboxMessageMediaCreateMutationVariables = Exact<{
  data: InboxMessageMediaCreateInput;
}>;

export type InboxMessageMediaCreateMutation = {
  __typename?: 'Mutation';
  inboxMessageMediaCreate: { __typename?: 'InboxMessageMedia'; id?: string | null };
};

export type UserTrashMessagesQueryVariables = Exact<{ [key: string]: never }>;

export type UserTrashMessagesQuery = {
  __typename?: 'Query';
  inboxMessagesList: {
    __typename?: 'InboxMessageListResponse';
    items: Array<{ __typename?: 'InboxMessage'; id?: string | null }>;
  };
};

export type InboxUpdateMutationVariables = Exact<{
  data: InboxUpdateInput;
}>;

export type InboxUpdateMutation = {
  __typename?: 'Mutation';
  inboxUpdate: { __typename?: 'Inbox'; id?: string | null };
};

export type ReadAllInboxMessagesMutationVariables = Exact<{
  inboxId: Scalars['ID'];
}>;

export type ReadAllInboxMessagesMutation = {
  __typename?: 'Mutation';
  inboxMessageUpdateByFilter: { __typename?: 'InboxMessageManyResponse'; count: number };
};

export type InboxUnreadMessagesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InboxUnreadMessagesQuery = {
  __typename?: 'Query';
  inboxMessagesList: { __typename?: 'InboxMessageListResponse'; count: number };
};

export type LegacyFeedPostsListItemFragment = {
  __typename?: 'LegacyFeedPost';
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  text?: string | null;
  isPinned?: boolean | null;
  commentsAllowed?: boolean | null;
  postDate?: any | null;
  legacyDate?: any | null;
  reportStatus?: string | null;
  flaggedAt?: any | null;
  flagReason?: string | null;
  isAutoReported?: boolean | null;
  lastFlaggedBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  media?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      id?: string | null;
      meta?: any | null;
      downloadUrl?: string | null;
      fileId?: string | null;
      filename?: string | null;
    }>;
  } | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
  } | null;
  author?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    ownedOrganizations?: {
      __typename?: 'OrganizationListResponse';
      items: Array<{
        __typename?: 'Organization';
        id?: string | null;
        advertising?: {
          __typename?: 'Advertising';
          id?: string | null;
          supporter?: {
            __typename?: 'AdvertisingSupporter';
            id?: string | null;
            isSupporter?: boolean | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type LegacyFeedMonthsInfoFragment = {
  __typename?: 'LegacyFeedMonth';
  id?: string | null;
  month?: number | null;
  legacyYears?: {
    __typename?: 'LegacyFeedYear';
    id?: string | null;
    legacyYear?: number | null;
  } | null;
};

export type LegacyFeedYearsInfoFragment = {
  __typename?: 'LegacyFeedYear';
  id?: string | null;
  legacyYear?: number | null;
  legacyMonths?: {
    __typename?: 'LegacyFeedMonthListResponse';
    items: Array<{ __typename?: 'LegacyFeedMonth'; id?: string | null; month?: number | null }>;
  } | null;
};

export type LegacyFeedYearsListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  filter: LegacyFeedPostFilter;
  sort?: InputMaybe<Array<LegacyFeedPostSort> | LegacyFeedPostSort>;
  yearsSort?: InputMaybe<Array<LegacyFeedYearSort> | LegacyFeedYearSort>;
  monthsSort?: InputMaybe<Array<LegacyFeedMonthSort> | LegacyFeedMonthSort>;
}>;

export type LegacyFeedYearsListQuery = {
  __typename?: 'Query';
  legacyFeedYearsList: {
    __typename?: 'LegacyFeedYearListResponse';
    items: Array<{
      __typename?: 'LegacyFeedYear';
      id?: string | null;
      legacyYear?: number | null;
      legacyMonths?: {
        __typename?: 'LegacyFeedMonthListResponse';
        items: Array<{
          __typename?: 'LegacyFeedMonth';
          id?: string | null;
          month?: number | null;
          posts?: {
            __typename?: 'LegacyFeedPostListResponse';
            items: Array<{
              __typename?: 'LegacyFeedPost';
              id?: string | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              text?: string | null;
              isPinned?: boolean | null;
              commentsAllowed?: boolean | null;
              postDate?: any | null;
              legacyDate?: any | null;
              reportStatus?: string | null;
              flaggedAt?: any | null;
              flagReason?: string | null;
              isAutoReported?: boolean | null;
              lastFlaggedBy?: {
                __typename?: 'User';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
              media?: {
                __typename?: 'FileListResponse';
                items: Array<{
                  __typename?: 'File';
                  id?: string | null;
                  meta?: any | null;
                  downloadUrl?: string | null;
                  fileId?: string | null;
                  filename?: string | null;
                }>;
              } | null;
              createdBy?: {
                __typename?: 'User';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id?: string | null;
                  downloadUrl?: string | null;
                } | null;
              } | null;
              author?: {
                __typename?: 'User';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id?: string | null;
                  downloadUrl?: string | null;
                } | null;
                ownedOrganizations?: {
                  __typename?: 'OrganizationListResponse';
                  items: Array<{
                    __typename?: 'Organization';
                    id?: string | null;
                    advertising?: {
                      __typename?: 'Advertising';
                      id?: string | null;
                      supporter?: {
                        __typename?: 'AdvertisingSupporter';
                        id?: string | null;
                        isSupporter?: boolean | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  };
};

export type LegacyTimelineYearsQueryVariables = Exact<{
  filter?: InputMaybe<LegacyFeedYearFilter>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedYearSort> | LegacyFeedYearSort>;
}>;

export type LegacyTimelineYearsQuery = {
  __typename?: 'Query';
  years: {
    __typename?: 'LegacyFeedYearListResponse';
    count: number;
    items: Array<{ __typename?: 'LegacyFeedYear'; year?: number | null }>;
  };
};

export type LegacyTimelineMonthsQueryVariables = Exact<{
  filter?: InputMaybe<LegacyFeedMonthFilter>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedMonthSort> | LegacyFeedMonthSort>;
}>;

export type LegacyTimelineMonthsQuery = {
  __typename?: 'Query';
  months: {
    __typename?: 'LegacyFeedMonthListResponse';
    count: number;
    items: Array<{ __typename?: 'LegacyFeedMonth'; month?: number | null }>;
  };
};

export type LegacyTimelinePostFragment = {
  __typename?: 'LegacyFeedPost';
  id?: string | null;
  text?: string | null;
  legacyDate?: any | null;
  postDate?: any | null;
  media?: {
    __typename?: 'FileListResponse';
    items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
  } | null;
};

export type LegacyTimelinePostsQueryVariables = Exact<{
  filter: LegacyFeedPostFilter;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedPostSort> | LegacyFeedPostSort>;
}>;

export type LegacyTimelinePostsQuery = {
  __typename?: 'Query';
  feed: {
    __typename?: 'LegacyFeedPostListResponse';
    count: number;
    items: Array<{
      __typename?: 'LegacyFeedPost';
      id?: string | null;
      text?: string | null;
      legacyDate?: any | null;
      postDate?: any | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
      } | null;
    }>;
  };
};

export type LegacyFeedPostCreateMutationVariables = Exact<{
  data: LegacyFeedPostCreateInput;
}>;

export type LegacyFeedPostCreateMutation = {
  __typename?: 'Mutation';
  legacyFeedPostCreate: {
    __typename?: 'LegacyFeedPost';
    id?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    text?: string | null;
    isPinned?: boolean | null;
    commentsAllowed?: boolean | null;
    postDate?: any | null;
    legacyDate?: any | null;
    reportStatus?: string | null;
    flaggedAt?: any | null;
    flagReason?: string | null;
    isAutoReported?: boolean | null;
    lastFlaggedBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        meta?: any | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      }>;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    } | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
  };
};

export type LegacyTimeStampsQueryVariables = Exact<{ [key: string]: never }>;

export type LegacyTimeStampsQuery = {
  __typename?: 'Query';
  years: {
    __typename?: 'LegacyFeedYearListResponse';
    items: Array<{
      __typename?: 'LegacyFeedYear';
      id?: string | null;
      year?: number | null;
      months?: {
        __typename?: 'LegacyFeedMonthListResponse';
        items: Array<{ __typename?: 'LegacyFeedMonth'; id?: string | null; month?: number | null }>;
      } | null;
    }>;
  };
};

export type LegacyFeedMonthsListQueryVariables = Exact<{
  first: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
  yearFilter: LegacyFeedYearFilter;
  postFilter: LegacyFeedPostFilter;
  sort?: InputMaybe<Array<LegacyFeedPostSort> | LegacyFeedPostSort>;
  monthsSort?: InputMaybe<Array<LegacyFeedMonthSort> | LegacyFeedMonthSort>;
}>;

export type LegacyFeedMonthsListQuery = {
  __typename?: 'Query';
  legacyFeedYearsList: {
    __typename?: 'LegacyFeedYearListResponse';
    items: Array<{
      __typename?: 'LegacyFeedYear';
      id?: string | null;
      legacyYear?: number | null;
      legacyMonths?: {
        __typename?: 'LegacyFeedMonthListResponse';
        items: Array<{
          __typename?: 'LegacyFeedMonth';
          id?: string | null;
          month?: number | null;
          posts?: {
            __typename?: 'LegacyFeedPostListResponse';
            items: Array<{
              __typename?: 'LegacyFeedPost';
              id?: string | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              text?: string | null;
              isPinned?: boolean | null;
              commentsAllowed?: boolean | null;
              postDate?: any | null;
              legacyDate?: any | null;
              reportStatus?: string | null;
              flaggedAt?: any | null;
              flagReason?: string | null;
              isAutoReported?: boolean | null;
              lastFlaggedBy?: {
                __typename?: 'User';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
              media?: {
                __typename?: 'FileListResponse';
                items: Array<{
                  __typename?: 'File';
                  id?: string | null;
                  meta?: any | null;
                  downloadUrl?: string | null;
                  fileId?: string | null;
                  filename?: string | null;
                }>;
              } | null;
              createdBy?: {
                __typename?: 'User';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id?: string | null;
                  downloadUrl?: string | null;
                } | null;
              } | null;
              author?: {
                __typename?: 'User';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id?: string | null;
                  downloadUrl?: string | null;
                } | null;
                ownedOrganizations?: {
                  __typename?: 'OrganizationListResponse';
                  items: Array<{
                    __typename?: 'Organization';
                    id?: string | null;
                    advertising?: {
                      __typename?: 'Advertising';
                      id?: string | null;
                      supporter?: {
                        __typename?: 'AdvertisingSupporter';
                        id?: string | null;
                        isSupporter?: boolean | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  };
};

export type LegacyFeedPostUpdateMutationVariables = Exact<{
  data: LegacyFeedPostUpdateInput;
  id: Scalars['ID'];
}>;

export type LegacyFeedPostUpdateMutation = {
  __typename?: 'Mutation';
  legacyFeedPostUpdate: {
    __typename?: 'LegacyFeedPost';
    id?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    text?: string | null;
    isPinned?: boolean | null;
    commentsAllowed?: boolean | null;
    postDate?: any | null;
    legacyDate?: any | null;
    reportStatus?: string | null;
    flaggedAt?: any | null;
    flagReason?: string | null;
    isAutoReported?: boolean | null;
    lastFlaggedBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        meta?: any | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      }>;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    } | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
  };
};

export type LegacyFeedPostsListQueryVariables = Exact<{
  first: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LegacyFeedPostSort> | LegacyFeedPostSort>;
  postsFilter: LegacyFeedPostFilter;
}>;

export type LegacyFeedPostsListQuery = {
  __typename?: 'Query';
  legacyFeedPostsList: {
    __typename?: 'LegacyFeedPostListResponse';
    items: Array<{
      __typename?: 'LegacyFeedPost';
      id?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      text?: string | null;
      isPinned?: boolean | null;
      commentsAllowed?: boolean | null;
      postDate?: any | null;
      legacyDate?: any | null;
      reportStatus?: string | null;
      flaggedAt?: any | null;
      flagReason?: string | null;
      isAutoReported?: boolean | null;
      lastFlaggedBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          meta?: any | null;
          downloadUrl?: string | null;
          fileId?: string | null;
          filename?: string | null;
        }>;
      } | null;
      createdBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      } | null;
      author?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        ownedOrganizations?: {
          __typename?: 'OrganizationListResponse';
          items: Array<{
            __typename?: 'Organization';
            id?: string | null;
            advertising?: {
              __typename?: 'Advertising';
              id?: string | null;
              supporter?: {
                __typename?: 'AdvertisingSupporter';
                id?: string | null;
                isSupporter?: boolean | null;
              } | null;
            } | null;
          }>;
        } | null;
      } | null;
    }>;
  };
};

export type LegacyFeedPostsListCountQueryVariables = Exact<{
  postsFilter: LegacyFeedPostFilter;
}>;

export type LegacyFeedPostsListCountQuery = {
  __typename?: 'Query';
  legacyFeedPostsList: { __typename?: 'LegacyFeedPostListResponse'; count: number };
};

export type SchoolNewsPostsListItemFragment = {
  __typename?: 'SchoolNewsPost';
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  text?: string | null;
  isPinned?: boolean | null;
  postDate?: any | null;
  commentsAllowed?: boolean | null;
  flagReason?: string | null;
  flaggedAt?: any | null;
  reportStatus?: string | null;
  mentions?: {
    __typename?: 'PostMentionListResponse';
    items: Array<{
      __typename?: 'PostMention';
      id?: string | null;
      mentionId?: string | null;
      mentionText?: string | null;
      position?: number | null;
      type?: string | null;
    }>;
  } | null;
  lastFlaggedBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  media?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      id?: string | null;
      downloadUrl?: string | null;
      meta?: any | null;
      fileId?: string | null;
      filename?: string | null;
    }>;
  } | null;
  author?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    ownedOrganizations?: {
      __typename?: 'OrganizationListResponse';
      items: Array<{
        __typename?: 'Organization';
        id?: string | null;
        advertising?: {
          __typename?: 'Advertising';
          id?: string | null;
          supporter?: {
            __typename?: 'AdvertisingSupporter';
            id?: string | null;
            isSupporter?: boolean | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type SchoolNewsPostCreateMutationVariables = Exact<{
  data: SchoolNewsPostCreateInput;
}>;

export type SchoolNewsPostCreateMutation = {
  __typename?: 'Mutation';
  schoolNewsPostCreate: {
    __typename?: 'SchoolNewsPost';
    id?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    text?: string | null;
    isPinned?: boolean | null;
    postDate?: any | null;
    commentsAllowed?: boolean | null;
    flagReason?: string | null;
    flaggedAt?: any | null;
    reportStatus?: string | null;
    mentions?: {
      __typename?: 'PostMentionListResponse';
      items: Array<{
        __typename?: 'PostMention';
        id?: string | null;
        mentionId?: string | null;
        mentionText?: string | null;
        position?: number | null;
        type?: string | null;
      }>;
    } | null;
    lastFlaggedBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        meta?: any | null;
        fileId?: string | null;
        filename?: string | null;
      }>;
    } | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
  };
};

export type SchoolNewsPostUpdateMutationVariables = Exact<{
  data: SchoolNewsPostUpdateInput;
  id: Scalars['ID'];
}>;

export type SchoolNewsPostUpdateMutation = {
  __typename?: 'Mutation';
  schoolNewsPostUpdate: {
    __typename?: 'SchoolNewsPost';
    id?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    text?: string | null;
    isPinned?: boolean | null;
    postDate?: any | null;
    commentsAllowed?: boolean | null;
    flagReason?: string | null;
    flaggedAt?: any | null;
    reportStatus?: string | null;
    mentions?: {
      __typename?: 'PostMentionListResponse';
      items: Array<{
        __typename?: 'PostMention';
        id?: string | null;
        mentionId?: string | null;
        mentionText?: string | null;
        position?: number | null;
        type?: string | null;
      }>;
    } | null;
    lastFlaggedBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        meta?: any | null;
        fileId?: string | null;
        filename?: string | null;
      }>;
    } | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
  };
};

export type School_News_Update_Post_MutationMutationVariables = Exact<{
  data: Scalars['JSON'];
  id: Scalars['ID'];
}>;

export type School_News_Update_Post_MutationMutation = {
  __typename?: 'Mutation';
  updatePost: { __typename?: 'UpdatePostResponse'; successful: boolean };
};

export type SchoolNewsPostsListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SchoolNewsPostSort> | SchoolNewsPostSort>;
  filter?: InputMaybe<SchoolNewsPostFilter>;
  pinnedFilter: SchoolNewsPostFilter;
}>;

export type SchoolNewsPostsListQuery = {
  __typename?: 'Query';
  schoolNewsPostsList: {
    __typename?: 'SchoolNewsPostListResponse';
    items: Array<{
      __typename?: 'SchoolNewsPost';
      id?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      text?: string | null;
      isPinned?: boolean | null;
      postDate?: any | null;
      commentsAllowed?: boolean | null;
      flagReason?: string | null;
      flaggedAt?: any | null;
      reportStatus?: string | null;
      mentions?: {
        __typename?: 'PostMentionListResponse';
        items: Array<{
          __typename?: 'PostMention';
          id?: string | null;
          mentionId?: string | null;
          mentionText?: string | null;
          position?: number | null;
          type?: string | null;
        }>;
      } | null;
      lastFlaggedBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          meta?: any | null;
          fileId?: string | null;
          filename?: string | null;
        }>;
      } | null;
      author?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        ownedOrganizations?: {
          __typename?: 'OrganizationListResponse';
          items: Array<{
            __typename?: 'Organization';
            id?: string | null;
            advertising?: {
              __typename?: 'Advertising';
              id?: string | null;
              supporter?: {
                __typename?: 'AdvertisingSupporter';
                id?: string | null;
                isSupporter?: boolean | null;
              } | null;
            } | null;
          }>;
        } | null;
      } | null;
    }>;
  };
  pinnedPostsList: {
    __typename?: 'SchoolNewsPostListResponse';
    items: Array<{
      __typename?: 'SchoolNewsPost';
      id?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      text?: string | null;
      isPinned?: boolean | null;
      postDate?: any | null;
      commentsAllowed?: boolean | null;
      flagReason?: string | null;
      flaggedAt?: any | null;
      reportStatus?: string | null;
      mentions?: {
        __typename?: 'PostMentionListResponse';
        items: Array<{
          __typename?: 'PostMention';
          id?: string | null;
          mentionId?: string | null;
          mentionText?: string | null;
          position?: number | null;
          type?: string | null;
        }>;
      } | null;
      lastFlaggedBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          meta?: any | null;
          fileId?: string | null;
          filename?: string | null;
        }>;
      } | null;
      author?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        ownedOrganizations?: {
          __typename?: 'OrganizationListResponse';
          items: Array<{
            __typename?: 'Organization';
            id?: string | null;
            advertising?: {
              __typename?: 'Advertising';
              id?: string | null;
              supporter?: {
                __typename?: 'AdvertisingSupporter';
                id?: string | null;
                isSupporter?: boolean | null;
              } | null;
            } | null;
          }>;
        } | null;
      } | null;
    }>;
  };
};

export type SchoolNewsPostsCountQueryVariables = Exact<{
  filter?: InputMaybe<SchoolNewsPostFilter>;
}>;

export type SchoolNewsPostsCountQuery = {
  __typename?: 'Query';
  schoolNewsPostsList: { __typename?: 'SchoolNewsPostListResponse'; count: number };
};

export type CheckResetPasswordCodeQueryVariables = Exact<{
  data: CheckResetPasswordCodeInput;
}>;

export type CheckResetPasswordCodeQuery = {
  __typename?: 'Query';
  checkResetPasswordCode: { __typename?: 'CheckResetPasswordCodeSuccess'; email: string };
};

export type UserChangePasswordMutationVariables = Exact<{
  data: UserForgotPasswordChangeInput;
}>;

export type UserChangePasswordMutation = {
  __typename?: 'Mutation';
  userForgotPasswordChange: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type OrganizationCreateMutationVariables = Exact<{
  data: OrganizationCreateInput;
}>;

export type OrganizationCreateMutation = {
  __typename?: 'Mutation';
  claimOrganization: { __typename?: 'ClaimOrganizationResponse'; id: string };
};

export type OrganizationUpdateMutationVariables = Exact<{
  data: OrganizationUpdateInput;
}>;

export type OrganizationUpdateMutation = {
  __typename?: 'Mutation';
  organizationUpdate: {
    __typename?: 'Organization';
    id?: string | null;
    homeFeedViews?: number | null;
    description?: string | null;
    industry?: string | null;
    name?: string | null;
    likesCount?: number | null;
    status?: string | null;
    createdAt?: any | null;
    userLikes?: {
      __typename?: 'OrganizationLikeListResponse';
      items: Array<{
        __typename?: 'OrganizationLike';
        id?: string | null;
        user?: { __typename?: 'User'; id?: string | null } | null;
        organization?: { __typename?: 'Organization'; id?: string | null } | null;
      }>;
    } | null;
    logo?: {
      __typename?: 'File';
      id?: string | null;
      downloadUrl?: string | null;
      fileId?: string | null;
      filename?: string | null;
    } | null;
    schoolSupporter?: {
      __typename?: 'SchoolSupporterListResponse';
      items: Array<{
        __typename?: 'SchoolSupporter';
        id?: string | null;
        school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
      }>;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        downloadUrl?: string | null;
        meta?: any | null;
        filename?: string | null;
        fileId?: string | null;
        id?: string | null;
      }>;
    } | null;
    advertising?: {
      __typename?: 'Advertising';
      id?: string | null;
      supporter?: {
        __typename?: 'AdvertisingSupporter';
        id?: string | null;
        isSupporter?: boolean | null;
      } | null;
    } | null;
    location?: {
      __typename?: 'OrganizationLocationListResponse';
      items: Array<{
        __typename?: 'OrganizationLocation';
        id?: string | null;
        locationName?: string | null;
        operationalHours?: string | null;
        contactName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        link?: string | null;
        address?: {
          __typename?: 'AddressListResponse';
          items: Array<{
            __typename?: 'Address';
            id?: string | null;
            state?: string | null;
            zip?: string | null;
            street1?: string | null;
            city?: string | null;
          }>;
        } | null;
      }>;
    } | null;
    managers?: {
      __typename?: 'OrganizationManagerPreferenceListResponse';
      items: Array<{
        __typename?: 'OrganizationManagerPreference';
        id?: string | null;
        status?: string | null;
        isAdmin?: boolean | null;
        user?: {
          __typename?: 'User';
          id?: string | null;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          affiliation?: string | null;
          rolesAddons?: {
            __typename?: 'RoleAddonListResponse';
            items: Array<{ __typename?: 'RoleAddon'; id?: string | null; name?: string | null }>;
          } | null;
        } | null;
      }>;
    } | null;
    owner?: {
      __typename?: 'User';
      id?: string | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    jobOffers?: {
      __typename?: 'JobOfferListResponse';
      items: Array<{
        __typename?: 'JobOffer';
        id?: string | null;
        description?: string | null;
        position?: string | null;
        createdAt?: any | null;
        createdBy?: {
          __typename?: 'User';
          firstName?: string | null;
          lastName?: string | null;
          id?: string | null;
          avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        } | null;
        organization?: {
          __typename?: 'Organization';
          id?: string | null;
          name?: string | null;
        } | null;
      }>;
    } | null;
    discounts?: {
      __typename?: 'DiscountListResponse';
      items: Array<{
        __typename?: 'Discount';
        id?: string | null;
        title?: string | null;
        terms?: string | null;
        amountPercent?: number | null;
        type?: string | null;
        startDate?: any | null;
        expirationDate?: any | null;
        category?: string | null;
        organization?: {
          __typename?: 'Organization';
          id?: string | null;
          name?: string | null;
          logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
        } | null;
        images?: {
          __typename?: 'FileListResponse';
          items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
        } | null;
      }>;
    } | null;
  };
};

export type Organization_Update_By_Filter_MutationMutationVariables = Exact<{
  data: OrganizationUpdateByFilterInput;
  filter?: InputMaybe<OrganizationFilter>;
}>;

export type Organization_Update_By_Filter_MutationMutation = {
  __typename?: 'Mutation';
  organizationUpdateByFilter: {
    __typename?: 'OrganizationManyResponse';
    items: Array<{
      __typename?: 'Organization';
      id?: string | null;
      name?: string | null;
      homeFeedViews?: number | null;
    }>;
  };
};

export type OrganizationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrganizationQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id?: string | null;
    homeFeedViews?: number | null;
    description?: string | null;
    industry?: string | null;
    name?: string | null;
    likesCount?: number | null;
    status?: string | null;
    createdAt?: any | null;
    userLikes?: {
      __typename?: 'OrganizationLikeListResponse';
      items: Array<{
        __typename?: 'OrganizationLike';
        id?: string | null;
        user?: { __typename?: 'User'; id?: string | null } | null;
        organization?: { __typename?: 'Organization'; id?: string | null } | null;
      }>;
    } | null;
    logo?: {
      __typename?: 'File';
      id?: string | null;
      downloadUrl?: string | null;
      fileId?: string | null;
      filename?: string | null;
    } | null;
    schoolSupporter?: {
      __typename?: 'SchoolSupporterListResponse';
      items: Array<{
        __typename?: 'SchoolSupporter';
        id?: string | null;
        school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
      }>;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        downloadUrl?: string | null;
        meta?: any | null;
        filename?: string | null;
        fileId?: string | null;
        id?: string | null;
      }>;
    } | null;
    advertising?: {
      __typename?: 'Advertising';
      id?: string | null;
      supporter?: {
        __typename?: 'AdvertisingSupporter';
        id?: string | null;
        isSupporter?: boolean | null;
      } | null;
    } | null;
    location?: {
      __typename?: 'OrganizationLocationListResponse';
      items: Array<{
        __typename?: 'OrganizationLocation';
        id?: string | null;
        locationName?: string | null;
        operationalHours?: string | null;
        contactName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        link?: string | null;
        address?: {
          __typename?: 'AddressListResponse';
          items: Array<{
            __typename?: 'Address';
            id?: string | null;
            state?: string | null;
            zip?: string | null;
            street1?: string | null;
            city?: string | null;
          }>;
        } | null;
      }>;
    } | null;
    managers?: {
      __typename?: 'OrganizationManagerPreferenceListResponse';
      items: Array<{
        __typename?: 'OrganizationManagerPreference';
        id?: string | null;
        status?: string | null;
        isAdmin?: boolean | null;
        user?: {
          __typename?: 'User';
          id?: string | null;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          affiliation?: string | null;
          rolesAddons?: {
            __typename?: 'RoleAddonListResponse';
            items: Array<{ __typename?: 'RoleAddon'; id?: string | null; name?: string | null }>;
          } | null;
        } | null;
      }>;
    } | null;
    owner?: {
      __typename?: 'User';
      id?: string | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    jobOffers?: {
      __typename?: 'JobOfferListResponse';
      items: Array<{
        __typename?: 'JobOffer';
        id?: string | null;
        description?: string | null;
        position?: string | null;
        createdAt?: any | null;
        createdBy?: {
          __typename?: 'User';
          firstName?: string | null;
          lastName?: string | null;
          id?: string | null;
          avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        } | null;
        organization?: {
          __typename?: 'Organization';
          id?: string | null;
          name?: string | null;
        } | null;
      }>;
    } | null;
    discounts?: {
      __typename?: 'DiscountListResponse';
      items: Array<{
        __typename?: 'Discount';
        id?: string | null;
        title?: string | null;
        terms?: string | null;
        amountPercent?: number | null;
        type?: string | null;
        startDate?: any | null;
        expirationDate?: any | null;
        category?: string | null;
        organization?: {
          __typename?: 'Organization';
          id?: string | null;
          name?: string | null;
          logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
        } | null;
        images?: {
          __typename?: 'FileListResponse';
          items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
        } | null;
      }>;
    } | null;
  } | null;
};

export type OrganizationProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrganizationProfileQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id?: string | null;
    name?: string | null;
    industry?: string | null;
    description?: string | null;
    createdAt?: any | null;
    logo?: {
      __typename?: 'File';
      id?: string | null;
      downloadUrl?: string | null;
      fileId?: string | null;
      filename?: string | null;
    } | null;
    userLikes?: {
      __typename?: 'OrganizationLikeListResponse';
      count: number;
      items: Array<{
        __typename?: 'OrganizationLike';
        id?: string | null;
        user?: { __typename?: 'User'; id?: string | null } | null;
        organization?: { __typename?: 'Organization'; id?: string | null } | null;
      }>;
    } | null;
    schoolSupporter?: {
      __typename?: 'SchoolSupporterListResponse';
      count: number;
      items: Array<{ __typename?: 'SchoolSupporter'; id?: string | null }>;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        downloadUrl?: string | null;
        meta?: any | null;
        filename?: string | null;
        fileId?: string | null;
        id?: string | null;
      }>;
    } | null;
    location?: {
      __typename?: 'OrganizationLocationListResponse';
      items: Array<{
        __typename?: 'OrganizationLocation';
        id?: string | null;
        locationName?: string | null;
        operationalHours?: string | null;
        contactName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        link?: string | null;
        address?: {
          __typename?: 'AddressListResponse';
          items: Array<{
            __typename?: 'Address';
            id?: string | null;
            state?: string | null;
            zip?: string | null;
            street1?: string | null;
            city?: string | null;
          }>;
        } | null;
      }>;
    } | null;
    owner?: { __typename?: 'User'; id?: string | null } | null;
    managers?: {
      __typename?: 'OrganizationManagerPreferenceListResponse';
      items: Array<{
        __typename?: 'OrganizationManagerPreference';
        id?: string | null;
        status?: string | null;
        isAdmin?: boolean | null;
        user?: {
          __typename?: 'User';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
        } | null;
      }>;
    } | null;
    jobOffers?: {
      __typename?: 'JobOfferListResponse';
      items: Array<{
        __typename?: 'JobOffer';
        id?: string | null;
        description?: string | null;
        position?: string | null;
        postDate?: any | null;
        employmentType?: string | null;
        createdAt?: any | null;
        createdBy?: {
          __typename?: 'User';
          id?: string | null;
          email?: string | null;
          fullName?: string | null;
          graduatingYear?: any | null;
          professionalDesignations?: string | null;
          currentIndustry?: string | null;
          isSeekingEmployment?: boolean | null;
          affiliation?: string | null;
          jobExperience?: {
            __typename?: 'JobEmployeeListResponse';
            items: Array<{
              __typename?: 'JobEmployee';
              id?: string | null;
              companyName?: string | null;
              positionTitle?: string | null;
              isCurrentlyWorking?: boolean | null;
              companyImage?: {
                __typename?: 'File';
                id?: string | null;
                fileId?: string | null;
                downloadUrl?: string | null;
              } | null;
            }>;
          } | null;
          avatar?: {
            __typename?: 'File';
            id?: string | null;
            fileId?: string | null;
            downloadUrl?: string | null;
          } | null;
        } | null;
        organization?: {
          __typename?: 'Organization';
          id?: string | null;
          name?: string | null;
          owner?: { __typename?: 'User'; id?: string | null } | null;
          managers?: {
            __typename?: 'OrganizationManagerPreferenceListResponse';
            items: Array<{
              __typename?: 'OrganizationManagerPreference';
              id?: string | null;
              user?: { __typename?: 'User'; id?: string | null } | null;
            }>;
          } | null;
          location?: {
            __typename?: 'OrganizationLocationListResponse';
            items: Array<{
              __typename?: 'OrganizationLocation';
              id?: string | null;
              address?: {
                __typename?: 'AddressListResponse';
                items: Array<{
                  __typename?: 'Address';
                  id?: string | null;
                  zip?: string | null;
                  street1?: string | null;
                  state?: string | null;
                  city?: string | null;
                }>;
              } | null;
            }>;
          } | null;
          logo?: {
            __typename?: 'File';
            id?: string | null;
            fileId?: string | null;
            downloadUrl?: string | null;
          } | null;
        } | null;
      }>;
    } | null;
    discounts?: {
      __typename?: 'DiscountListResponse';
      items: Array<{
        __typename?: 'Discount';
        id?: string | null;
        title?: string | null;
        terms?: string | null;
        amountPercent?: number | null;
        type?: string | null;
        startDate?: any | null;
        expirationDate?: any | null;
        category?: string | null;
        organization?: {
          __typename?: 'Organization';
          id?: string | null;
          name?: string | null;
          logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
        } | null;
        images?: {
          __typename?: 'FileListResponse';
          items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
        } | null;
      }>;
    } | null;
  } | null;
};

export type OrganizationsListQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilter>;
  sort?: InputMaybe<Array<OrganizationSort> | OrganizationSort>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;

export type OrganizationsListQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'OrganizationListResponse';
    count: number;
    items: Array<{
      __typename?: 'Organization';
      id?: string | null;
      homeFeedViews?: number | null;
      description?: string | null;
      industry?: string | null;
      name?: string | null;
      likesCount?: number | null;
      status?: string | null;
      createdAt?: any | null;
      userLikes?: {
        __typename?: 'OrganizationLikeListResponse';
        items: Array<{
          __typename?: 'OrganizationLike';
          id?: string | null;
          user?: { __typename?: 'User'; id?: string | null } | null;
          organization?: { __typename?: 'Organization'; id?: string | null } | null;
        }>;
      } | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      } | null;
      schoolSupporter?: {
        __typename?: 'SchoolSupporterListResponse';
        items: Array<{
          __typename?: 'SchoolSupporter';
          id?: string | null;
          school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
        }>;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          downloadUrl?: string | null;
          meta?: any | null;
          filename?: string | null;
          fileId?: string | null;
          id?: string | null;
        }>;
      } | null;
      advertising?: {
        __typename?: 'Advertising';
        id?: string | null;
        supporter?: {
          __typename?: 'AdvertisingSupporter';
          id?: string | null;
          isSupporter?: boolean | null;
        } | null;
      } | null;
      location?: {
        __typename?: 'OrganizationLocationListResponse';
        items: Array<{
          __typename?: 'OrganizationLocation';
          id?: string | null;
          locationName?: string | null;
          operationalHours?: string | null;
          contactName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          link?: string | null;
          address?: {
            __typename?: 'AddressListResponse';
            items: Array<{
              __typename?: 'Address';
              id?: string | null;
              state?: string | null;
              zip?: string | null;
              street1?: string | null;
              city?: string | null;
            }>;
          } | null;
        }>;
      } | null;
      managers?: {
        __typename?: 'OrganizationManagerPreferenceListResponse';
        items: Array<{
          __typename?: 'OrganizationManagerPreference';
          id?: string | null;
          status?: string | null;
          isAdmin?: boolean | null;
          user?: {
            __typename?: 'User';
            id?: string | null;
            email?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            affiliation?: string | null;
            rolesAddons?: {
              __typename?: 'RoleAddonListResponse';
              items: Array<{ __typename?: 'RoleAddon'; id?: string | null; name?: string | null }>;
            } | null;
          } | null;
        }>;
      } | null;
      owner?: {
        __typename?: 'User';
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      jobOffers?: {
        __typename?: 'JobOfferListResponse';
        items: Array<{
          __typename?: 'JobOffer';
          id?: string | null;
          description?: string | null;
          position?: string | null;
          createdAt?: any | null;
          createdBy?: {
            __typename?: 'User';
            firstName?: string | null;
            lastName?: string | null;
            id?: string | null;
            avatar?: {
              __typename?: 'File';
              id?: string | null;
              downloadUrl?: string | null;
            } | null;
          } | null;
          organization?: {
            __typename?: 'Organization';
            id?: string | null;
            name?: string | null;
          } | null;
        }>;
      } | null;
      discounts?: {
        __typename?: 'DiscountListResponse';
        items: Array<{
          __typename?: 'Discount';
          id?: string | null;
          title?: string | null;
          terms?: string | null;
          amountPercent?: number | null;
          type?: string | null;
          startDate?: any | null;
          expirationDate?: any | null;
          category?: string | null;
          organization?: {
            __typename?: 'Organization';
            id?: string | null;
            name?: string | null;
            logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
          } | null;
          images?: {
            __typename?: 'FileListResponse';
            items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
          } | null;
        }>;
      } | null;
    }>;
  };
};

export type BusinessRegistryListQueryVariables = Exact<{
  school: Scalars['ID'];
  filter?: InputMaybe<OrganizationLocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrganizationLocationSort> | OrganizationLocationSort>;
}>;

export type BusinessRegistryListQuery = {
  __typename?: 'Query';
  locations: {
    __typename?: 'OrganizationLocationListResponse';
    count: number;
    items: Array<{
      __typename?: 'OrganizationLocation';
      id?: string | null;
      createdAt?: any | null;
      name?: string | null;
      address?: {
        __typename?: 'AddressListResponse';
        items: Array<{
          __typename?: 'Address';
          state?: string | null;
          city?: string | null;
          street1?: string | null;
          zip?: string | null;
        }>;
      } | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        industry?: string | null;
        createdAt?: any | null;
        logo?: { __typename?: 'File'; fileId?: string | null; downloadUrl?: string | null } | null;
        managers?: {
          __typename?: 'OrganizationManagerPreferenceListResponse';
          items: Array<{
            __typename?: 'OrganizationManagerPreference';
            status?: string | null;
            user?: {
              __typename?: 'User';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              email?: string | null;
            } | null;
          }>;
        } | null;
        currentSchoolSupport?: { __typename?: 'SchoolSupporterListResponse'; count: number } | null;
        createdBy?: {
          __typename?: 'User';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
        } | null;
      } | null;
    }>;
  };
};

export type BusinessCountQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilter>;
  startPointZip: Scalars['String'];
  radius: Scalars['String'];
  supporterFilter?: InputMaybe<OrganizationFilter>;
}>;

export type BusinessCountQuery = {
  __typename?: 'Query';
  businesses: { __typename?: 'BusinessFilterByZipRequestResponse'; count?: number | null };
  supporters: { __typename?: 'BusinessFilterByZipRequestResponse'; count?: number | null };
};

export type OrganizationsCountQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilter>;
}>;

export type OrganizationsCountQuery = {
  __typename?: 'Query';
  organizationsList: { __typename?: 'OrganizationListResponse'; count: number };
};

export type OrganizationCreateLikeMutationVariables = Exact<{
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
}>;

export type OrganizationCreateLikeMutation = {
  __typename?: 'Mutation';
  organizationLikeCreate: {
    __typename?: 'OrganizationLike';
    id?: string | null;
    user?: { __typename?: 'User'; id?: string | null } | null;
    organization?: { __typename?: 'Organization'; id?: string | null } | null;
  };
};

export type OrganizationDeleteLikeMutationVariables = Exact<{
  likeId: Scalars['ID'];
}>;

export type OrganizationDeleteLikeMutation = {
  __typename?: 'Mutation';
  organizationLikeDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type SchoolSupporterSearchCreateMutationVariables = Exact<{
  userId: Scalars['ID'];
  supporterId: Scalars['ID'];
}>;

export type SchoolSupporterSearchCreateMutation = {
  __typename?: 'Mutation';
  schoolSupporterSearchCreate: {
    __typename?: 'SchoolSupporterSearch';
    id?: string | null;
    user?: { __typename?: 'User'; id?: string | null } | null;
    supporter?: { __typename?: 'SchoolSupporter'; id?: string | null } | null;
  };
};

export type SchoolSupporterClickCreateMutationVariables = Exact<{
  userId: Scalars['ID'];
  supporterId: Scalars['ID'];
}>;

export type SchoolSupporterClickCreateMutation = {
  __typename?: 'Mutation';
  schoolSupporterClickCreate: {
    __typename?: 'SchoolSupporterClick';
    id?: string | null;
    user?: { __typename?: 'User'; id?: string | null } | null;
    supporter?: { __typename?: 'SchoolSupporter'; id?: string | null } | null;
  };
};

export type GraduatingYearMetricFragment = {
  __typename?: 'GraduatingYearsMetric';
  id?: string | null;
  year?: string | null;
  expectedUsersCount?: number | null;
  registeredUsersCount?: number | null;
  registeredUsersPercentage?: number | null;
  missingUsersCount?: number | null;
  missingUsersPercentage?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    status?: string | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
};

export type GraduatingYearsMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<GraduatingYearsMetricFilter>;
  sort?: InputMaybe<Array<GraduatingYearsMetricSort> | GraduatingYearsMetricSort>;
}>;

export type GraduatingYearsMetricsQuery = {
  __typename?: 'Query';
  metrics: {
    __typename?: 'GraduatingYearsMetricListResponse';
    count: number;
    items: Array<{
      __typename?: 'GraduatingYearsMetric';
      id?: string | null;
      year?: string | null;
      expectedUsersCount?: number | null;
      registeredUsersCount?: number | null;
      registeredUsersPercentage?: number | null;
      missingUsersCount?: number | null;
      missingUsersPercentage?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      createdBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        status?: string | null;
        avatar?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          filename?: string | null;
          downloadUrl?: string | null;
        } | null;
      } | null;
    }>;
  };
};

export type GraduatingYearsRankingQueryVariables = Exact<{
  filter?: InputMaybe<GraduatingYearsMetricFilter>;
}>;

export type GraduatingYearsRankingQuery = {
  __typename?: 'Query';
  metrics: {
    __typename?: 'GraduatingYearsMetricListResponse';
    count: number;
    items: Array<{
      __typename?: 'GraduatingYearsMetric';
      id?: string | null;
      missingUsersPercentage?: number | null;
    }>;
  };
};

export type GroupRegisteredUsersCountFragment = {
  __typename?: 'GroupByResponse';
  year?: any | null;
  usersCount?: number | null;
};

export type RegisteredUsersCountQueryVariables = Exact<{
  first: Scalars['Int'];
}>;

export type RegisteredUsersCountQuery = {
  __typename?: 'Query';
  usersList: {
    __typename?: 'UserListResponse';
    groups: Array<{
      __typename?: 'GroupByResponse';
      year?: any | null;
      usersCount?: number | null;
    }>;
  };
};

export type GraduatedUsersListPerYearQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<UserFilter>;
  sort?: InputMaybe<Array<UserSort> | UserSort>;
}>;

export type GraduatedUsersListPerYearQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserListResponse';
    count: number;
    items: Array<{
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      status?: string | null;
      avatar?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    }>;
  };
};

export type HobbiesUpdateMutationVariables = Exact<{
  data: UserUpdateInput;
}>;

export type HobbiesUpdateMutation = {
  __typename?: 'Mutation';
  userUpdate: { __typename?: 'User'; id?: string | null; hobbies?: Array<string | null> | null };
};

export type ProfessionalProfileFragment = {
  __typename?: 'JobEmployee';
  id?: string | null;
  employmentType?: string | null;
  positionDescription?: string | null;
  positionTitle?: string | null;
  isCurrentlyWorking?: boolean | null;
  startDate?: any | null;
  endDate?: any | null;
  companyName?: string | null;
  companyLocation?: {
    __typename?: 'SmartAddress';
    state?: string | null;
    city?: string | null;
    street1?: string | null;
    zip?: string | null;
  } | null;
  companyImage?: {
    __typename?: 'File';
    fileId?: string | null;
    downloadUrl?: string | null;
  } | null;
};

export type EducationProfileFragment = {
  __typename?: 'EducationalInstitution';
  id?: string | null;
  degree?: string | null;
  fieldOfStudy?: string | null;
  gpa?: number | null;
  activities?: string | null;
  description?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  address?: {
    __typename?: 'Address';
    id?: string | null;
    street1?: string | null;
    zip?: string | null;
    city?: string | null;
    state?: string | null;
  } | null;
  image?: { __typename?: 'File'; fileId?: string | null; downloadUrl?: string | null } | null;
  highSchool?: {
    __typename?: 'HighSchoolLocation';
    id?: string | null;
    name?: string | null;
    logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
  } | null;
};

export type EducationProfileCreateMutationVariables = Exact<{
  data: EducationalInstitutionCreateInput;
}>;

export type EducationProfileCreateMutation = {
  __typename?: 'Mutation';
  educationProfile: {
    __typename?: 'EducationalInstitution';
    id?: string | null;
    degree?: string | null;
    fieldOfStudy?: string | null;
    gpa?: number | null;
    activities?: string | null;
    description?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    address?: {
      __typename?: 'Address';
      id?: string | null;
      street1?: string | null;
      zip?: string | null;
      city?: string | null;
      state?: string | null;
    } | null;
    image?: { __typename?: 'File'; fileId?: string | null; downloadUrl?: string | null } | null;
    highSchool?: {
      __typename?: 'HighSchoolLocation';
      id?: string | null;
      name?: string | null;
      logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
    } | null;
  };
};

export type EducationProfileUpdateMutationVariables = Exact<{
  data: EducationalInstitutionUpdateInput;
}>;

export type EducationProfileUpdateMutation = {
  __typename?: 'Mutation';
  educationProfile: {
    __typename?: 'EducationalInstitution';
    id?: string | null;
    degree?: string | null;
    fieldOfStudy?: string | null;
    gpa?: number | null;
    activities?: string | null;
    description?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    address?: {
      __typename?: 'Address';
      id?: string | null;
      street1?: string | null;
      zip?: string | null;
      city?: string | null;
      state?: string | null;
    } | null;
    image?: { __typename?: 'File'; fileId?: string | null; downloadUrl?: string | null } | null;
    highSchool?: {
      __typename?: 'HighSchoolLocation';
      id?: string | null;
      name?: string | null;
      logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
    } | null;
  };
};

export type EducationProfileRemoveMutationVariables = Exact<{
  filter: EducationalInstitutionKeyFilter;
}>;

export type EducationProfileRemoveMutation = {
  __typename?: 'Mutation';
  commit?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type ActivityClubsUpdateMutationVariables = Exact<{
  data: UserUpdateInput;
}>;

export type ActivityClubsUpdateMutation = {
  __typename?: 'Mutation';
  userUpdate: {
    __typename?: 'User';
    id?: string | null;
    activityClubs?: Array<string | null> | null;
  };
};

export type UserPublicProfileFragment = {
  __typename?: 'User';
  facebookLink?: any | null;
  instagramLink?: any | null;
  linkedInLink?: any | null;
  twitterLink?: any | null;
  id?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  status?: string | null;
  affiliation?: string | null;
  affiliationDescription?: string | null;
  activityClubs?: Array<string | null> | null;
  description?: string | null;
  gender?: string | null;
  graduatingYear?: any | null;
  hobbies?: Array<string | null> | null;
  isSeekingEmployment?: boolean | null;
  birthDate?: any | null;
  parents?: any | null;
  currentIndustry?: string | null;
  professionalDesignations?: string | null;
  groupAdminPreferences?: { __typename?: 'GroupAdminPreference'; id?: string | null } | null;
  avatar?: {
    __typename?: 'File';
    id?: string | null;
    fileId?: string | null;
    filename?: string | null;
    downloadUrl?: string | null;
  } | null;
  managerPreferences?: {
    __typename?: 'OrganizationManagerPreferenceListResponse';
    items: Array<{
      __typename?: 'OrganizationManagerPreference';
      id?: string | null;
      status?: string | null;
      isAdmin?: boolean | null;
    }>;
  } | null;
  viewedThreads?: {
    __typename?: 'CollectiveIntelligenceThreadListResponse';
    items: Array<{ __typename?: 'CollectiveIntelligenceThread'; id?: string | null }>;
  } | null;
  createdGroups?: {
    __typename?: 'GroupListResponse';
    items: Array<{
      __typename?: 'Group';
      id?: string | null;
      title?: string | null;
      membersCount?: number | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    }>;
  } | null;
  ownedOrganizations?: {
    __typename?: 'OrganizationListResponse';
    items: Array<{
      __typename?: 'Organization';
      id?: string | null;
      name?: string | null;
      description?: string | null;
      advertising?: {
        __typename?: 'Advertising';
        id?: string | null;
        supporter?: {
          __typename?: 'AdvertisingSupporter';
          id?: string | null;
          isSupporter?: boolean | null;
        } | null;
      } | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        downloadUrl?: string | null;
      } | null;
    }>;
  } | null;
  userPreferences?: {
    __typename?: 'UserPreference';
    id?: string | null;
    phoneNumber?: string | null;
    secondaryPhoneNumber?: string | null;
    address?: {
      __typename?: 'Address';
      id?: string | null;
      city?: string | null;
      state?: string | null;
      street1?: string | null;
      zip?: string | null;
    } | null;
  } | null;
  schoolMemberships?: {
    __typename?: 'UserSchoolMembershipListResponse';
    items: Array<{
      __typename?: 'UserSchoolMembership';
      id?: string | null;
      school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
    }>;
  } | null;
  education?: {
    __typename?: 'EducationalInstitutionListResponse';
    items: Array<{
      __typename?: 'EducationalInstitution';
      id?: string | null;
      highSchool?: { __typename?: 'HighSchoolLocation'; id?: string | null } | null;
    }>;
  } | null;
};

export type UserPublicProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserPublicProfileQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    facebookLink?: any | null;
    instagramLink?: any | null;
    linkedInLink?: any | null;
    twitterLink?: any | null;
    id?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    status?: string | null;
    affiliation?: string | null;
    affiliationDescription?: string | null;
    activityClubs?: Array<string | null> | null;
    description?: string | null;
    gender?: string | null;
    graduatingYear?: any | null;
    hobbies?: Array<string | null> | null;
    isSeekingEmployment?: boolean | null;
    birthDate?: any | null;
    parents?: any | null;
    currentIndustry?: string | null;
    professionalDesignations?: string | null;
    groupAdminPreferences?: { __typename?: 'GroupAdminPreference'; id?: string | null } | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
    managerPreferences?: {
      __typename?: 'OrganizationManagerPreferenceListResponse';
      items: Array<{
        __typename?: 'OrganizationManagerPreference';
        id?: string | null;
        status?: string | null;
        isAdmin?: boolean | null;
      }>;
    } | null;
    viewedThreads?: {
      __typename?: 'CollectiveIntelligenceThreadListResponse';
      items: Array<{ __typename?: 'CollectiveIntelligenceThread'; id?: string | null }>;
    } | null;
    createdGroups?: {
      __typename?: 'GroupListResponse';
      items: Array<{
        __typename?: 'Group';
        id?: string | null;
        title?: string | null;
        membersCount?: number | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          filename?: string | null;
          downloadUrl?: string | null;
        } | null;
      }>;
    } | null;
    ownedOrganizations?: {
      __typename?: 'OrganizationListResponse';
      items: Array<{
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        description?: string | null;
        advertising?: {
          __typename?: 'Advertising';
          id?: string | null;
          supporter?: {
            __typename?: 'AdvertisingSupporter';
            id?: string | null;
            isSupporter?: boolean | null;
          } | null;
        } | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      }>;
    } | null;
    userPreferences?: {
      __typename?: 'UserPreference';
      id?: string | null;
      phoneNumber?: string | null;
      secondaryPhoneNumber?: string | null;
      address?: {
        __typename?: 'Address';
        id?: string | null;
        city?: string | null;
        state?: string | null;
        street1?: string | null;
        zip?: string | null;
      } | null;
    } | null;
    schoolMemberships?: {
      __typename?: 'UserSchoolMembershipListResponse';
      items: Array<{
        __typename?: 'UserSchoolMembership';
        id?: string | null;
        school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
      }>;
    } | null;
    education?: {
      __typename?: 'EducationalInstitutionListResponse';
      items: Array<{
        __typename?: 'EducationalInstitution';
        id?: string | null;
        highSchool?: { __typename?: 'HighSchoolLocation'; id?: string | null } | null;
      }>;
    } | null;
  } | null;
  professionalExperience: {
    __typename?: 'JobEmployeeListResponse';
    count: number;
    items: Array<{
      __typename?: 'JobEmployee';
      id?: string | null;
      employmentType?: string | null;
      positionDescription?: string | null;
      positionTitle?: string | null;
      isCurrentlyWorking?: boolean | null;
      startDate?: any | null;
      endDate?: any | null;
      companyName?: string | null;
      companyLocation?: {
        __typename?: 'SmartAddress';
        state?: string | null;
        city?: string | null;
        street1?: string | null;
        zip?: string | null;
      } | null;
      companyImage?: {
        __typename?: 'File';
        fileId?: string | null;
        downloadUrl?: string | null;
      } | null;
    }>;
  };
  professionalEducation: {
    __typename?: 'EducationalInstitutionListResponse';
    count: number;
    items: Array<{
      __typename?: 'EducationalInstitution';
      id?: string | null;
      degree?: string | null;
      fieldOfStudy?: string | null;
      gpa?: number | null;
      activities?: string | null;
      description?: string | null;
      startDate?: any | null;
      endDate?: any | null;
      address?: {
        __typename?: 'Address';
        id?: string | null;
        street1?: string | null;
        zip?: string | null;
        city?: string | null;
        state?: string | null;
      } | null;
      image?: { __typename?: 'File'; fileId?: string | null; downloadUrl?: string | null } | null;
      highSchool?: {
        __typename?: 'HighSchoolLocation';
        id?: string | null;
        name?: string | null;
        logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
      } | null;
    }>;
  };
};

export type GroupInfoFragment = {
  __typename?: 'Group';
  id?: string | null;
  title?: string | null;
  membersCount?: number | null;
  description?: string | null;
  status?: string | null;
  creator?: { __typename?: 'User'; id?: string | null } | null;
  logo?: {
    __typename?: 'File';
    id?: string | null;
    downloadUrl?: string | null;
    fileId?: string | null;
    filename?: string | null;
  } | null;
  members?: {
    __typename?: 'UserListResponse';
    items: Array<{
      __typename?: 'User';
      id?: string | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    }>;
  } | null;
  groupAdminPreferences?: {
    __typename?: 'GroupAdminPreferenceListResponse';
    items: Array<{
      __typename?: 'GroupAdminPreference';
      id?: string | null;
      user?: { __typename?: 'User'; id?: string | null } | null;
    }>;
  } | null;
};

export type GroupSearchQueryVariables = Exact<{
  filter?: InputMaybe<GroupFilter>;
  first: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
}>;

export type GroupSearchQuery = {
  __typename?: 'Query';
  groupsList: {
    __typename?: 'GroupListResponse';
    items: Array<{
      __typename?: 'Group';
      id?: string | null;
      title?: string | null;
      membersCount?: number | null;
      description?: string | null;
      status?: string | null;
      creator?: { __typename?: 'User'; id?: string | null } | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      } | null;
      members?: {
        __typename?: 'UserListResponse';
        items: Array<{
          __typename?: 'User';
          id?: string | null;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        }>;
      } | null;
      groupAdminPreferences?: {
        __typename?: 'GroupAdminPreferenceListResponse';
        items: Array<{
          __typename?: 'GroupAdminPreference';
          id?: string | null;
          user?: { __typename?: 'User'; id?: string | null } | null;
        }>;
      } | null;
    }>;
  };
};

export type GroupLeaveMutationVariables = Exact<{
  data: GroupUpdateInput;
}>;

export type GroupLeaveMutation = {
  __typename?: 'Mutation';
  groupUpdate: {
    __typename?: 'Group';
    id?: string | null;
    members?: {
      __typename?: 'UserListResponse';
      items: Array<{ __typename?: 'User'; id?: string | null; email?: string | null }>;
    } | null;
  };
};

export type GroupDeleteMutationVariables = Exact<{
  filter: GroupKeyFilter;
}>;

export type GroupDeleteMutation = {
  __typename?: 'Mutation';
  groupDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type UserUpdatePasswordMutationVariables = Exact<{
  data: UserPasswordChangeInput;
}>;

export type UserUpdatePasswordMutation = {
  __typename?: 'Mutation';
  userPasswordChangeRequest: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type GroupDetailsUpdateMutationVariables = Exact<{
  data: GroupUpdateInput;
}>;

export type GroupDetailsUpdateMutation = {
  __typename?: 'Mutation';
  groupDetailsUpdateRequest: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type UserUpdateEmailMutationVariables = Exact<{
  data: UserEmailChangeInput;
}>;

export type UserUpdateEmailMutation = {
  __typename?: 'Mutation';
  userEmailChangeRequest: {
    __typename?: 'userEmailChangeRequestResponse';
    success?: boolean | null;
  };
};

export type GroupUsersListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<UserFilter>;
  sort?: InputMaybe<Array<UserSort> | UserSort>;
}>;

export type GroupUsersListQuery = {
  __typename?: 'Query';
  usersList: {
    __typename?: 'UserListResponse';
    items: Array<{
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      affiliation?: string | null;
      groupAdminPreferences?: {
        __typename?: 'GroupAdminPreference';
        id?: string | null;
        group?: {
          __typename?: 'GroupListResponse';
          items: Array<{ __typename?: 'Group'; id?: string | null }>;
        } | null;
      } | null;
      groupPosts?: { __typename?: 'GroupPostListResponse'; count: number } | null;
    }>;
  };
};

export type GroupMemberRemoveMutationVariables = Exact<{
  group: GroupKeyFilter;
  user: UserKeyFilter;
}>;

export type GroupMemberRemoveMutation = {
  __typename?: 'Mutation';
  groupMemberRemove: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type GroupMemberPromoteMutationVariables = Exact<{
  group: GroupKeyFilter;
  user: UserKeyFilter;
}>;

export type GroupMemberPromoteMutation = {
  __typename?: 'Mutation';
  groupMemberPromote: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type GroupMemberDemoteMutationVariables = Exact<{
  group: GroupKeyFilter;
  user: UserKeyFilter;
}>;

export type GroupMemberDemoteMutation = {
  __typename?: 'Mutation';
  groupMemberDemote: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type JobEmployeeCreateMutationVariables = Exact<{
  data: JobEmployeeCreateInput;
}>;

export type JobEmployeeCreateMutation = {
  __typename?: 'Mutation';
  jobEmployeeCreate: {
    __typename?: 'JobEmployee';
    id?: string | null;
    employmentType?: string | null;
    positionDescription?: string | null;
    positionTitle?: string | null;
    isCurrentlyWorking?: boolean | null;
    startDate?: any | null;
    endDate?: any | null;
    companyName?: string | null;
    companyLocation?: {
      __typename?: 'SmartAddress';
      state?: string | null;
      city?: string | null;
      street1?: string | null;
      zip?: string | null;
    } | null;
    companyImage?: {
      __typename?: 'File';
      fileId?: string | null;
      downloadUrl?: string | null;
    } | null;
  };
};

export type JobEmployeeDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type JobEmployeeDeleteMutation = {
  __typename?: 'Mutation';
  jobEmployeeDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type JobEmployeeUpdateMutationVariables = Exact<{
  data: JobEmployeeUpdateInput;
}>;

export type JobEmployeeUpdateMutation = {
  __typename?: 'Mutation';
  jobEmployeeUpdate: {
    __typename?: 'JobEmployee';
    id?: string | null;
    employmentType?: string | null;
    positionDescription?: string | null;
    positionTitle?: string | null;
    isCurrentlyWorking?: boolean | null;
    startDate?: any | null;
    endDate?: any | null;
    companyName?: string | null;
    companyLocation?: {
      __typename?: 'SmartAddress';
      state?: string | null;
      city?: string | null;
      street1?: string | null;
      zip?: string | null;
    } | null;
    companyImage?: {
      __typename?: 'File';
      fileId?: string | null;
      downloadUrl?: string | null;
    } | null;
  };
};

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type UserByEmailQuery = {
  __typename?: 'Query';
  user?: { __typename?: 'User'; id?: string | null; email?: string | null } | null;
};

export type UserSendEmailMutationVariables = Exact<{
  data: UserForgotPasswordEmailSendInput;
}>;

export type UserSendEmailMutation = {
  __typename?: 'Mutation';
  userForgotPasswordEmailSend: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type UserListItemFragment = {
  __typename?: 'User';
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  birthDate?: any | null;
  avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
};

export type MemberDiscountItemFragment = {
  __typename?: 'Discount';
  id?: string | null;
  createdAt?: any | null;
  title?: string | null;
  amountPercent?: number | null;
  type?: string | null;
  startDate?: any | null;
  expirationDate?: any | null;
  timezone?: string | null;
  category?: string | null;
  status?: string | null;
  usersRedeemedCount?: number | null;
  terms?: string | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
  } | null;
  organization?: {
    __typename?: 'Organization';
    id?: string | null;
    name?: string | null;
    logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
  } | null;
  images?: {
    __typename?: 'FileListResponse';
    items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
  } | null;
};

export type MemberCommunitySidebarItemsQueryVariables = Exact<{
  dateNow: Scalars['DateTime'];
  birthdayFilter: UserFilter;
}>;

export type MemberCommunitySidebarItemsQuery = {
  __typename?: 'Query';
  eventsList: {
    __typename?: 'EventListResponse';
    items: Array<{
      __typename?: 'Event';
      id?: string | null;
      title?: string | null;
      date?: any | null;
      link?: string | null;
      status?: string | null;
      description?: string | null;
      createdAt?: any | null;
      timezone?: string | null;
      createdBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        ownedOrganizations?: {
          __typename?: 'OrganizationListResponse';
          items: Array<{
            __typename?: 'Organization';
            id?: string | null;
            advertising?: {
              __typename?: 'Advertising';
              id?: string | null;
              supporter?: {
                __typename?: 'AdvertisingSupporter';
                id?: string | null;
                isSupporter?: boolean | null;
              } | null;
            } | null;
          }>;
        } | null;
      } | null;
      location?: {
        __typename?: 'Address';
        id?: string | null;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        street1?: string | null;
      } | null;
      titleSponsor?: {
        __typename?: 'Organization';
        id?: string | null;
        industry?: string | null;
        name?: string | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          fileId?: string | null;
          filename?: string | null;
        } | null;
        media?: {
          __typename?: 'FileListResponse';
          items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
        } | null;
      } | null;
      images?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          fileId?: string | null;
          filename?: string | null;
        }>;
      } | null;
      otherSponsors?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          name?: string | null;
          logo?: {
            __typename?: 'File';
            id?: string | null;
            downloadUrl?: string | null;
            fileId?: string | null;
            filename?: string | null;
          } | null;
        }>;
      } | null;
      author?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
  };
  usersList: {
    __typename?: 'UserListResponse';
    items: Array<{
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      birthDate?: any | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    }>;
  };
};

export type MentionsListQueryVariables = Exact<{
  searchText: Scalars['String'];
  first: Scalars['Int'];
}>;

export type MentionsListQuery = {
  __typename?: 'Query';
  usersList: {
    __typename?: 'UserListResponse';
    items: Array<{
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    }>;
  };
  groupsList: {
    __typename?: 'GroupListResponse';
    items: Array<{ __typename?: 'Group'; id?: string | null; title?: string | null }>;
  };
  organizationsList: {
    __typename?: 'OrganizationListResponse';
    items: Array<{ __typename?: 'Organization'; id?: string | null; name?: string | null }>;
  };
};

export type SendPostReactionNotificationMutationVariables = Exact<{
  data: SendPostReactionNotificationRequestInput;
}>;

export type SendPostReactionNotificationMutation = {
  __typename?: 'Mutation';
  sendPostReactionNotificationRequest: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type CurrentUserNotificationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;

export type CurrentUserNotificationsQuery = {
  __typename?: 'Query';
  notifications: {
    __typename?: 'UserNotificationsByUserListResponse';
    count: number;
    items: Array<{
      __typename?: 'UserNotificationsByUser';
      id?: string | null;
      isRead?: boolean | null;
      createdAt?: any | null;
      notification?: {
        __typename?: 'UserNotification';
        id?: string | null;
        relatedItemID?: string | null;
        createdAt?: any | null;
        deletable?: boolean | null;
        infoJSON?: any | null;
        image?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        template?: {
          __typename?: 'NotificationTemplate';
          id?: string | null;
          title?: string | null;
          key?: string | null;
        } | null;
        sender?: {
          __typename?: 'User';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    }>;
  };
};

export type CurrentUserNotificationsCountQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserNotificationsCountQuery = {
  __typename?: 'Query';
  read: { __typename?: 'UserNotificationsByUserListResponse'; count: number };
  unread: { __typename?: 'UserNotificationsByUserListResponse'; count: number };
};

export type MarkNotificationsAsReadMutationVariables = Exact<{
  filter: UserNotificationsByUserFilter;
}>;

export type MarkNotificationsAsReadMutation = {
  __typename?: 'Mutation';
  notifications: {
    __typename?: 'UserNotificationsByUserManyResponse';
    count: number;
    items: Array<{
      __typename?: 'UserNotificationsByUser';
      id?: string | null;
      isRead?: boolean | null;
      createdAt?: any | null;
      notification?: {
        __typename?: 'UserNotification';
        id?: string | null;
        relatedItemID?: string | null;
        createdAt?: any | null;
        deletable?: boolean | null;
        infoJSON?: any | null;
        image?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        template?: {
          __typename?: 'NotificationTemplate';
          id?: string | null;
          title?: string | null;
          key?: string | null;
        } | null;
        sender?: {
          __typename?: 'User';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    }>;
  };
};

export type CurrentUserNotificationCreatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type CurrentUserNotificationCreatedSubscription = {
  __typename?: 'Subscription';
  UserNotificationsByUser?: {
    __typename?: 'UserNotificationsByUserPayload';
    node?: {
      __typename?: 'UserNotificationsByUser';
      id?: string | null;
      isRead?: boolean | null;
      createdAt?: any | null;
      notification?: {
        __typename?: 'UserNotification';
        id?: string | null;
        relatedItemID?: string | null;
        createdAt?: any | null;
        deletable?: boolean | null;
        infoJSON?: any | null;
        image?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
        template?: {
          __typename?: 'NotificationTemplate';
          id?: string | null;
          title?: string | null;
          key?: string | null;
        } | null;
        sender?: {
          __typename?: 'User';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type UserNotificationFragment = {
  __typename?: 'UserNotification';
  id?: string | null;
  relatedItemID?: string | null;
  createdAt?: any | null;
  deletable?: boolean | null;
  infoJSON?: any | null;
  image?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
  template?: {
    __typename?: 'NotificationTemplate';
    id?: string | null;
    title?: string | null;
    key?: string | null;
  } | null;
  sender?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type UserNotificationReadableFragment = {
  __typename?: 'UserNotificationsByUser';
  id?: string | null;
  isRead?: boolean | null;
  createdAt?: any | null;
  notification?: {
    __typename?: 'UserNotification';
    id?: string | null;
    relatedItemID?: string | null;
    createdAt?: any | null;
    deletable?: boolean | null;
    infoJSON?: any | null;
    image?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
    template?: {
      __typename?: 'NotificationTemplate';
      id?: string | null;
      title?: string | null;
      key?: string | null;
    } | null;
    sender?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
};

export type PostsFlaggedByUserListQueryVariables = Exact<{
  homePostsFilter: HomeFeedPostFilter;
  groupPostsFilter: GroupPostFilter;
  homePosts: Scalars['Boolean'];
  groupPosts: Scalars['Boolean'];
}>;

export type PostsFlaggedByUserListQuery = {
  __typename?: 'Query';
  homeFeedPostsList?: {
    __typename?: 'HomeFeedPostListResponse';
    items: Array<{ __typename?: 'HomeFeedPost'; id?: string | null }>;
  };
  groupPostsList?: {
    __typename?: 'GroupPostListResponse';
    items: Array<{ __typename?: 'GroupPost'; id?: string | null }>;
  };
};

export type PostDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
  isHomeFeedPost: Scalars['Boolean'];
  isSchoolNewsPost: Scalars['Boolean'];
  isLegacyFeedPost: Scalars['Boolean'];
  isGroupPost: Scalars['Boolean'];
}>;

export type PostDeleteMutation = {
  __typename?: 'Mutation';
  homeFeedPostDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
  legacyFeedPostDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
  schoolNewsPostDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
  groupPostDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type FlagPostMutationVariables = Exact<{
  data: FlagPostRequestInput;
}>;

export type FlagPostMutation = {
  __typename?: 'Mutation';
  flagPostRequest: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type ContentCurationCountQueryVariables = Exact<{ [key: string]: never }>;

export type ContentCurationCountQuery = {
  __typename?: 'Query';
  pending: {
    __typename?: 'ContentReportListResponse';
    count: number;
    items: Array<{
      __typename?: 'ContentReport';
      text?: string | null;
      reportStatus?: string | null;
    }>;
  };
};

export type UserInfoFragment = {
  __typename?: 'User';
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  affiliation?: string | null;
  graduatingYear?: any | null;
  professionalDesignations?: string | null;
  avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
  ownedOrganizations?: {
    __typename?: 'OrganizationListResponse';
    items: Array<{
      __typename?: 'Organization';
      id?: string | null;
      advertising?: {
        __typename?: 'Advertising';
        id?: string | null;
        supporter?: {
          __typename?: 'AdvertisingSupporter';
          id?: string | null;
          isSupporter?: boolean | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type CareerInfoFragment = {
  __typename?: 'JobOffer';
  id?: string | null;
  description?: string | null;
  position?: string | null;
  postDate?: any | null;
  employmentType?: string | null;
  createdAt?: any | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    email?: string | null;
    fullName?: string | null;
    graduatingYear?: any | null;
    professionalDesignations?: string | null;
    currentIndustry?: string | null;
    isSeekingEmployment?: boolean | null;
    affiliation?: string | null;
    jobExperience?: {
      __typename?: 'JobEmployeeListResponse';
      items: Array<{
        __typename?: 'JobEmployee';
        id?: string | null;
        companyName?: string | null;
        positionTitle?: string | null;
        isCurrentlyWorking?: boolean | null;
        companyImage?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      }>;
    } | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
  organization?: {
    __typename?: 'Organization';
    id?: string | null;
    name?: string | null;
    owner?: { __typename?: 'User'; id?: string | null } | null;
    managers?: {
      __typename?: 'OrganizationManagerPreferenceListResponse';
      items: Array<{
        __typename?: 'OrganizationManagerPreference';
        id?: string | null;
        user?: { __typename?: 'User'; id?: string | null } | null;
      }>;
    } | null;
    location?: {
      __typename?: 'OrganizationLocationListResponse';
      items: Array<{
        __typename?: 'OrganizationLocation';
        id?: string | null;
        address?: {
          __typename?: 'AddressListResponse';
          items: Array<{
            __typename?: 'Address';
            id?: string | null;
            zip?: string | null;
            street1?: string | null;
            state?: string | null;
            city?: string | null;
          }>;
        } | null;
      }>;
    } | null;
    logo?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
};

export type CareersPageSearchQueryVariables = Exact<{
  startPointZip: Scalars['String'];
  radius: Scalars['String'];
  careerFilter?: InputMaybe<JobOfferFilter>;
  careersSort?: InputMaybe<Array<JobOfferSort> | JobOfferSort>;
  careersFirst?: InputMaybe<Scalars['Int']>;
  careersSkip?: InputMaybe<Scalars['Int']>;
}>;

export type CareersPageSearchQuery = {
  __typename?: 'Query';
  page: {
    __typename?: 'CareersFilterByZipRequestResponse';
    count?: number | null;
    items: Array<{
      __typename?: 'JobOffer';
      id?: string | null;
      description?: string | null;
      position?: string | null;
      postDate?: any | null;
      employmentType?: string | null;
      createdAt?: any | null;
      createdBy?: {
        __typename?: 'User';
        id?: string | null;
        email?: string | null;
        fullName?: string | null;
        graduatingYear?: any | null;
        professionalDesignations?: string | null;
        currentIndustry?: string | null;
        isSeekingEmployment?: boolean | null;
        affiliation?: string | null;
        jobExperience?: {
          __typename?: 'JobEmployeeListResponse';
          items: Array<{
            __typename?: 'JobEmployee';
            id?: string | null;
            companyName?: string | null;
            positionTitle?: string | null;
            isCurrentlyWorking?: boolean | null;
            companyImage?: {
              __typename?: 'File';
              id?: string | null;
              fileId?: string | null;
              downloadUrl?: string | null;
            } | null;
          }>;
        } | null;
        avatar?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      } | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        owner?: { __typename?: 'User'; id?: string | null } | null;
        managers?: {
          __typename?: 'OrganizationManagerPreferenceListResponse';
          items: Array<{
            __typename?: 'OrganizationManagerPreference';
            id?: string | null;
            user?: { __typename?: 'User'; id?: string | null } | null;
          }>;
        } | null;
        location?: {
          __typename?: 'OrganizationLocationListResponse';
          items: Array<{
            __typename?: 'OrganizationLocation';
            id?: string | null;
            address?: {
              __typename?: 'AddressListResponse';
              items: Array<{
                __typename?: 'Address';
                id?: string | null;
                zip?: string | null;
                street1?: string | null;
                state?: string | null;
                city?: string | null;
              }>;
            } | null;
          }>;
        } | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      } | null;
    }>;
  };
};

export type CareersListCountQueryVariables = Exact<{
  filter?: InputMaybe<JobOfferFilter>;
}>;

export type CareersListCountQuery = {
  __typename?: 'Query';
  jobOffersList: { __typename?: 'JobOfferListResponse'; count: number };
};

export type PreviewSearchQueryVariables = Exact<{
  userFilter?: InputMaybe<UserFilter>;
  internshipsFilter?: InputMaybe<JobOfferFilter>;
  first: Scalars['Int'];
  jobOfferFilter?: InputMaybe<JobOfferFilter>;
}>;

export type PreviewSearchQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserListResponse';
    items: Array<{
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      affiliation?: string | null;
      graduatingYear?: any | null;
      professionalDesignations?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
        }>;
      } | null;
    }>;
  };
  jobOffers: {
    __typename?: 'JobOfferListResponse';
    items: Array<{
      __typename?: 'JobOffer';
      id?: string | null;
      description?: string | null;
      position?: string | null;
      postDate?: any | null;
      employmentType?: string | null;
      createdAt?: any | null;
      createdBy?: {
        __typename?: 'User';
        id?: string | null;
        email?: string | null;
        fullName?: string | null;
        graduatingYear?: any | null;
        professionalDesignations?: string | null;
        currentIndustry?: string | null;
        isSeekingEmployment?: boolean | null;
        affiliation?: string | null;
        jobExperience?: {
          __typename?: 'JobEmployeeListResponse';
          items: Array<{
            __typename?: 'JobEmployee';
            id?: string | null;
            companyName?: string | null;
            positionTitle?: string | null;
            isCurrentlyWorking?: boolean | null;
            companyImage?: {
              __typename?: 'File';
              id?: string | null;
              fileId?: string | null;
              downloadUrl?: string | null;
            } | null;
          }>;
        } | null;
        avatar?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      } | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        owner?: { __typename?: 'User'; id?: string | null } | null;
        managers?: {
          __typename?: 'OrganizationManagerPreferenceListResponse';
          items: Array<{
            __typename?: 'OrganizationManagerPreference';
            id?: string | null;
            user?: { __typename?: 'User'; id?: string | null } | null;
          }>;
        } | null;
        location?: {
          __typename?: 'OrganizationLocationListResponse';
          items: Array<{
            __typename?: 'OrganizationLocation';
            id?: string | null;
            address?: {
              __typename?: 'AddressListResponse';
              items: Array<{
                __typename?: 'Address';
                id?: string | null;
                zip?: string | null;
                street1?: string | null;
                state?: string | null;
                city?: string | null;
              }>;
            } | null;
          }>;
        } | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      } | null;
    }>;
  };
  internships: {
    __typename?: 'JobOfferListResponse';
    items: Array<{
      __typename?: 'JobOffer';
      id?: string | null;
      description?: string | null;
      position?: string | null;
      postDate?: any | null;
      employmentType?: string | null;
      createdAt?: any | null;
      createdBy?: {
        __typename?: 'User';
        id?: string | null;
        email?: string | null;
        fullName?: string | null;
        graduatingYear?: any | null;
        professionalDesignations?: string | null;
        currentIndustry?: string | null;
        isSeekingEmployment?: boolean | null;
        affiliation?: string | null;
        jobExperience?: {
          __typename?: 'JobEmployeeListResponse';
          items: Array<{
            __typename?: 'JobEmployee';
            id?: string | null;
            companyName?: string | null;
            positionTitle?: string | null;
            isCurrentlyWorking?: boolean | null;
            companyImage?: {
              __typename?: 'File';
              id?: string | null;
              fileId?: string | null;
              downloadUrl?: string | null;
            } | null;
          }>;
        } | null;
        avatar?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      } | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        owner?: { __typename?: 'User'; id?: string | null } | null;
        managers?: {
          __typename?: 'OrganizationManagerPreferenceListResponse';
          items: Array<{
            __typename?: 'OrganizationManagerPreference';
            id?: string | null;
            user?: { __typename?: 'User'; id?: string | null } | null;
          }>;
        } | null;
        location?: {
          __typename?: 'OrganizationLocationListResponse';
          items: Array<{
            __typename?: 'OrganizationLocation';
            id?: string | null;
            address?: {
              __typename?: 'AddressListResponse';
              items: Array<{
                __typename?: 'Address';
                id?: string | null;
                zip?: string | null;
                street1?: string | null;
                state?: string | null;
                city?: string | null;
              }>;
            } | null;
          }>;
        } | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      } | null;
    }>;
  };
};

export type UserSearchQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  sort?: InputMaybe<Array<UserSort> | UserSort>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;

export type UserSearchQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserListResponse';
    count: number;
    items: Array<{
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      affiliation?: string | null;
      graduatingYear?: any | null;
      professionalDesignations?: string | null;
      avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
        }>;
      } | null;
    }>;
  };
};

export type TrendingSearchesListItemFragment = {
  __typename?: 'TrendingSearch';
  id?: string | null;
  wordOrPhrase?: string | null;
  searchesCount?: number | null;
};

export type TrendingSearchesListQueryVariables = Exact<{
  filter?: InputMaybe<TrendingSearchFilter>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TrendingSearchSort> | TrendingSearchSort>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type TrendingSearchesListQuery = {
  __typename?: 'Query';
  trendingSearchesList: {
    __typename?: 'TrendingSearchListResponse';
    count: number;
    items: Array<{
      __typename?: 'TrendingSearch';
      id?: string | null;
      wordOrPhrase?: string | null;
      searchesCount?: number | null;
    }>;
  };
};

export type TrendingSearchesListCountQueryVariables = Exact<{
  filter?: InputMaybe<TrendingSearchFilter>;
}>;

export type TrendingSearchesListCountQuery = {
  __typename?: 'Query';
  trendingSearchesList: { __typename?: 'TrendingSearchListResponse'; count: number };
};

export type TrendingSearchesAddWordMutationVariables = Exact<{
  data: TrendingSearchCreateInput;
}>;

export type TrendingSearchesAddWordMutation = {
  __typename?: 'Mutation';
  trendingSearchCreate: {
    __typename?: 'TrendingSearch';
    id?: string | null;
    wordOrPhrase?: string | null;
    searchesCount?: number | null;
  };
};

export type TrendingSearchesUpdateWordCountMutationVariables = Exact<{
  data: TrendingSearchUpdateInput;
}>;

export type TrendingSearchesUpdateWordCountMutation = {
  __typename?: 'Mutation';
  trendingSearchUpdate: {
    __typename?: 'TrendingSearch';
    id?: string | null;
    wordOrPhrase?: string | null;
    searchesCount?: number | null;
  };
};

export type AudienceGraduatingYearsOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type AudienceGraduatingYearsOptionsQuery = {
  __typename?: 'Query';
  options: {
    __typename?: 'UserListResponse';
    groups: Array<{ __typename?: 'GroupByResponse'; year?: string | null; count?: number | null }>;
  };
};

export type DiscountPresentationFragment = {
  __typename?: 'Discount';
  id?: string | null;
  title?: string | null;
  terms?: string | null;
  amountPercent?: number | null;
  type?: string | null;
  startDate?: any | null;
  expirationDate?: any | null;
  category?: string | null;
  organization?: {
    __typename?: 'Organization';
    id?: string | null;
    name?: string | null;
    logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
  } | null;
  images?: {
    __typename?: 'FileListResponse';
    items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
  } | null;
};

export type EventInfoFragment = {
  __typename?: 'Event';
  id?: string | null;
  title?: string | null;
  date?: any | null;
  link?: string | null;
  status?: string | null;
  description?: string | null;
  createdAt?: any | null;
  timezone?: string | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    ownedOrganizations?: {
      __typename?: 'OrganizationListResponse';
      items: Array<{
        __typename?: 'Organization';
        id?: string | null;
        advertising?: {
          __typename?: 'Advertising';
          id?: string | null;
          supporter?: {
            __typename?: 'AdvertisingSupporter';
            id?: string | null;
            isSupporter?: boolean | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
  location?: {
    __typename?: 'Address';
    id?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    street1?: string | null;
  } | null;
  titleSponsor?: {
    __typename?: 'Organization';
    id?: string | null;
    industry?: string | null;
    name?: string | null;
    logo?: {
      __typename?: 'File';
      id?: string | null;
      downloadUrl?: string | null;
      fileId?: string | null;
      filename?: string | null;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
    } | null;
  } | null;
  images?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      id?: string | null;
      downloadUrl?: string | null;
      fileId?: string | null;
      filename?: string | null;
    }>;
  } | null;
  otherSponsors?: {
    __typename?: 'OrganizationListResponse';
    items: Array<{
      __typename?: 'Organization';
      id?: string | null;
      name?: string | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      } | null;
    }>;
  } | null;
  author?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type EventsListQueryVariables = Exact<{
  filter?: InputMaybe<EventFilter>;
  sort?: InputMaybe<Array<EventSort> | EventSort>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;

export type EventsListQuery = {
  __typename?: 'Query';
  eventsList: {
    __typename?: 'EventListResponse';
    count: number;
    items: Array<{
      __typename?: 'Event';
      id?: string | null;
      title?: string | null;
      date?: any | null;
      link?: string | null;
      status?: string | null;
      description?: string | null;
      createdAt?: any | null;
      timezone?: string | null;
      createdBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        ownedOrganizations?: {
          __typename?: 'OrganizationListResponse';
          items: Array<{
            __typename?: 'Organization';
            id?: string | null;
            advertising?: {
              __typename?: 'Advertising';
              id?: string | null;
              supporter?: {
                __typename?: 'AdvertisingSupporter';
                id?: string | null;
                isSupporter?: boolean | null;
              } | null;
            } | null;
          }>;
        } | null;
      } | null;
      location?: {
        __typename?: 'Address';
        id?: string | null;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        street1?: string | null;
      } | null;
      titleSponsor?: {
        __typename?: 'Organization';
        id?: string | null;
        industry?: string | null;
        name?: string | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          fileId?: string | null;
          filename?: string | null;
        } | null;
        media?: {
          __typename?: 'FileListResponse';
          items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
        } | null;
      } | null;
      images?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          fileId?: string | null;
          filename?: string | null;
        }>;
      } | null;
      otherSponsors?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          name?: string | null;
          logo?: {
            __typename?: 'File';
            id?: string | null;
            downloadUrl?: string | null;
            fileId?: string | null;
            filename?: string | null;
          } | null;
        }>;
      } | null;
      author?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
  };
};

export type EventSearchFragment = {
  __typename?: 'Event';
  id?: string | null;
  title?: string | null;
  date?: any | null;
  link?: string | null;
  status?: string | null;
  description?: string | null;
  timezone?: string | null;
  createdAt?: any | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  images?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      id?: string | null;
      downloadUrl?: string | null;
      fileId?: string | null;
    }>;
  } | null;
  location?: {
    __typename?: 'Address';
    id?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    street1?: string | null;
  } | null;
  titleSponsor?: {
    __typename?: 'Organization';
    id?: string | null;
    industry?: string | null;
    name?: string | null;
    logo?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
  otherSponsors?: {
    __typename?: 'OrganizationListResponse';
    items: Array<{
      __typename?: 'Organization';
      id?: string | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        downloadUrl?: string | null;
      } | null;
    }>;
  } | null;
};

export type EventSearchQueryVariables = Exact<{
  filter?: InputMaybe<EventFilter>;
  sort?: InputMaybe<Array<EventSort> | EventSort>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  startPointZip: Scalars['String'];
  radius: Scalars['String'];
  proximitySort?: InputMaybe<Scalars['String']>;
}>;

export type EventSearchQuery = {
  __typename?: 'Query';
  events: {
    __typename?: 'EventsFilterByZipRequestResponse';
    count?: number | null;
    items: Array<{
      __typename?: 'Event';
      id?: string | null;
      title?: string | null;
      date?: any | null;
      link?: string | null;
      status?: string | null;
      description?: string | null;
      timezone?: string | null;
      createdAt?: any | null;
      createdBy?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      images?: {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          fileId?: string | null;
        }>;
      } | null;
      location?: {
        __typename?: 'Address';
        id?: string | null;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        street1?: string | null;
      } | null;
      titleSponsor?: {
        __typename?: 'Organization';
        id?: string | null;
        industry?: string | null;
        name?: string | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      } | null;
      otherSponsors?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          logo?: {
            __typename?: 'File';
            id?: string | null;
            fileId?: string | null;
            downloadUrl?: string | null;
          } | null;
        }>;
      } | null;
    }>;
  };
};

export type EventCreateMutationVariables = Exact<{
  data: EventCreateInput;
}>;

export type EventCreateMutation = {
  __typename?: 'Mutation';
  eventCreate: {
    __typename?: 'Event';
    id?: string | null;
    title?: string | null;
    date?: any | null;
    link?: string | null;
    status?: string | null;
    description?: string | null;
    createdAt?: any | null;
    timezone?: string | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
    location?: {
      __typename?: 'Address';
      id?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      street1?: string | null;
    } | null;
    titleSponsor?: {
      __typename?: 'Organization';
      id?: string | null;
      industry?: string | null;
      name?: string | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
      } | null;
    } | null;
    images?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      }>;
    } | null;
    otherSponsors?: {
      __typename?: 'OrganizationListResponse';
      items: Array<{
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          fileId?: string | null;
          filename?: string | null;
        } | null;
      }>;
    } | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

export type EventUpdateMutationVariables = Exact<{
  data: EventUpdateInput;
}>;

export type EventUpdateMutation = {
  __typename?: 'Mutation';
  eventUpdate: {
    __typename?: 'Event';
    id?: string | null;
    title?: string | null;
    date?: any | null;
    link?: string | null;
    status?: string | null;
    description?: string | null;
    createdAt?: any | null;
    timezone?: string | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
    location?: {
      __typename?: 'Address';
      id?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      street1?: string | null;
    } | null;
    titleSponsor?: {
      __typename?: 'Organization';
      id?: string | null;
      industry?: string | null;
      name?: string | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      } | null;
      media?: {
        __typename?: 'FileListResponse';
        items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
      } | null;
    } | null;
    images?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      }>;
    } | null;
    otherSponsors?: {
      __typename?: 'OrganizationListResponse';
      items: Array<{
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          fileId?: string | null;
          filename?: string | null;
        } | null;
      }>;
    } | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

export type FileFragment = {
  __typename?: 'File';
  id?: string | null;
  fileId?: string | null;
  filename?: string | null;
  downloadUrl?: string | null;
};

export type MediaFragment = {
  __typename?: 'FileListResponse';
  items: Array<{
    __typename?: 'File';
    id?: string | null;
    fileId?: string | null;
    filename?: string | null;
    downloadUrl?: string | null;
  }>;
};

export type UploadFileByUrlMutationVariables = Exact<{
  url: Scalars['String'];
}>;

export type UploadFileByUrlMutation = {
  __typename?: 'Mutation';
  system?: {
    __typename?: 'SystemMutation';
    fileUploadByUrl: { __typename?: 'SystemFileUploadByUrlResponse'; fileId: string; meta: any };
  } | null;
};

export type FilesListQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type FilesListQuery = {
  __typename?: 'Query';
  filesList: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      fileId?: string | null;
      id?: string | null;
      filename?: string | null;
      public?: boolean | null;
      downloadUrl?: string | null;
    }>;
  };
};

export type HighSchoolFragment = {
  __typename?: 'HighSchoolLocation';
  id?: string | null;
  name?: string | null;
  zip?: string | null;
  state?: string | null;
  city?: string | null;
  street?: string | null;
};

export type RemoteVariablesQueryVariables = Exact<{ [key: string]: never }>;

export type RemoteVariablesQuery = {
  __typename?: 'Query';
  variables: {
    __typename?: 'RemoteVariableListResponse';
    count: number;
    items: Array<{
      __typename?: 'RemoteVariable';
      minChargeAmount?: number | null;
      overflowDebtAmount?: number | null;
      maxDebtAmount?: number | null;
      minDebtAmount?: number | null;
      serveAdvertisingUnitAmount?: number | null;
      serveAdvertisingSupportUnitAmount?: number | null;
      jobOfferExpirationDays?: number | null;
      specialOfferDailyAmount?: number | null;
      specialOfferDailySupporterAmount?: number | null;
      showOrganizationBalance?: boolean | null;
      schoolSupportAmount?: number | null;
    }>;
  };
};

export type GenerateLinkPreviewQueryVariables = Exact<{
  url: Scalars['String'];
}>;

export type GenerateLinkPreviewQuery = {
  __typename?: 'Query';
  preview: {
    __typename?: 'GeneratedLinkPreview';
    title: string;
    description?: string | null;
    imageURL?: string | null;
  };
};

export type GraduatingYearInfoFragment = {
  __typename?: 'GraduatingYear';
  id?: string | null;
  year?: number | null;
  graduateCount?: number | null;
};

export type GraduatingYearDetailedInfoFragment = {
  __typename?: 'GraduatingYear';
  id?: string | null;
  year?: number | null;
  graduateCount?: number | null;
  createdAt?: any | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type GraduatingYearInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GraduatingYearInfoQuery = {
  __typename?: 'Query';
  graduatingYear?: {
    __typename?: 'GraduatingYear';
    id?: string | null;
    year?: number | null;
    graduateCount?: number | null;
  } | null;
};

export type GraduatingYearsListQueryVariables = Exact<{
  filter?: InputMaybe<GraduatingYearFilter>;
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type GraduatingYearsListQuery = {
  __typename?: 'Query';
  graduatingYearsList: {
    __typename?: 'GraduatingYearListResponse';
    count: number;
    items: Array<{
      __typename?: 'GraduatingYear';
      id?: string | null;
      year?: number | null;
      graduateCount?: number | null;
    }>;
  };
};

export type CreateGraduatingYearMutationVariables = Exact<{
  data: GraduatingYearCreateInput;
}>;

export type CreateGraduatingYearMutation = {
  __typename?: 'Mutation';
  graduatingYearCreate: { __typename?: 'GraduatingYear'; id?: string | null };
};

export type EditGraduatingYearMutationVariables = Exact<{
  id: Scalars['ID'];
  data: GraduatingYearUpdateInput;
}>;

export type EditGraduatingYearMutation = {
  __typename?: 'Mutation';
  graduatingYearUpdate: { __typename?: 'GraduatingYear'; id?: string | null };
};

export type DeleteGraduatingYearMutationVariables = Exact<{
  data: GraduatingYearDeleteInput;
}>;

export type DeleteGraduatingYearMutation = {
  __typename?: 'Mutation';
  graduatingYearDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type Get_User_Joined_GroupsQueryVariables = Exact<{
  ID: Scalars['ID'];
}>;

export type Get_User_Joined_GroupsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    joinedGroups?: {
      __typename?: 'GroupListResponse';
      items: Array<{ __typename?: 'Group'; id?: string | null }>;
    } | null;
  } | null;
};

export type GroupMemberRequestUpsertMutationVariables = Exact<{
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
  status: Scalars['String'];
}>;

export type GroupMemberRequestUpsertMutation = {
  __typename?: 'Mutation';
  transaction: { __typename?: 'GroupsMembersUpsertResponse'; successful: boolean; message: string };
};

export type GroupMemberRequestsQueryVariables = Exact<{
  userId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;

export type GroupMemberRequestsQuery = {
  __typename?: 'Query';
  requests: {
    __typename?: 'GroupsMembersRequestListResponse';
    count: number;
    items: Array<{ __typename?: 'GroupsMembersRequest'; id?: string | null }>;
  };
};

export type HighSchoolsQueryVariables = Exact<{
  filter?: InputMaybe<HighSchoolLocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<HighSchoolLocationSort> | HighSchoolLocationSort>;
}>;

export type HighSchoolsQuery = {
  __typename?: 'Query';
  schools: {
    __typename?: 'HighSchoolLocationListResponse';
    count: number;
    items: Array<{
      __typename?: 'HighSchoolLocation';
      id?: string | null;
      name?: string | null;
      zip?: string | null;
      state?: string | null;
      city?: string | null;
      street?: string | null;
    }>;
  };
};

export type MessageSendRequestMutationVariables = Exact<{
  subject: Scalars['JSON'];
  text: Scalars['String'];
  userIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  media?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  destinationGroups?: InputMaybe<Scalars['JSON']>;
}>;

export type MessageSendRequestMutation = {
  __typename?: 'Mutation';
  messageSendRequest: {
    __typename?: 'Inbox';
    id?: string | null;
    subject?: any | null;
    users?: {
      __typename?: 'UserListResponse';
      items: Array<{
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      }>;
    } | null;
    messages?: {
      __typename?: 'InboxMessageListResponse';
      items: Array<{
        __typename?: 'InboxMessage';
        id?: string | null;
        createdAt?: any | null;
        text?: string | null;
        isRead?: boolean | null;
        isDeleted?: boolean | null;
        media?: {
          __typename?: 'FileListResponse';
          items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
        } | null;
        author?: { __typename?: 'User'; id?: string | null } | null;
        inbox?: { __typename?: 'Inbox'; id?: string | null } | null;
      }>;
    } | null;
  };
};

export type JobOfferPresentationFragment = {
  __typename?: 'JobOffer';
  id?: string | null;
  position?: string | null;
  description?: string | null;
  employmentType?: string | null;
  postDate?: any | null;
  createdBy?: { __typename?: 'User'; id?: string | null } | null;
  organization?: {
    __typename?: 'Organization';
    id?: string | null;
    name?: string | null;
    logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
  } | null;
};

export type CurrentLocationZipQueryVariables = Exact<{
  data: CurrentLocationZipRequestInput;
}>;

export type CurrentLocationZipQuery = {
  __typename?: 'Query';
  currentLocationZipRequest: { __typename?: 'CurrentLocationZipRequestSuccess'; zip: string };
};

export type NeedHelpMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  subject: Scalars['String'];
  message: Scalars['String'];
}>;

export type NeedHelpMutation = {
  __typename?: 'Mutation';
  request: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type OrganizationLocationItemFragment = {
  __typename?: 'OrganizationLocation';
  id?: string | null;
  locationName?: string | null;
  operationalHours?: string | null;
  contactName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  link?: string | null;
  address?: {
    __typename?: 'AddressListResponse';
    items: Array<{
      __typename?: 'Address';
      id?: string | null;
      state?: string | null;
      zip?: string | null;
      street1?: string | null;
      city?: string | null;
    }>;
  } | null;
};

export type OrganizationItemFragment = {
  __typename?: 'Organization';
  id?: string | null;
  homeFeedViews?: number | null;
  description?: string | null;
  industry?: string | null;
  name?: string | null;
  likesCount?: number | null;
  status?: string | null;
  createdAt?: any | null;
  userLikes?: {
    __typename?: 'OrganizationLikeListResponse';
    items: Array<{
      __typename?: 'OrganizationLike';
      id?: string | null;
      user?: { __typename?: 'User'; id?: string | null } | null;
      organization?: { __typename?: 'Organization'; id?: string | null } | null;
    }>;
  } | null;
  logo?: {
    __typename?: 'File';
    id?: string | null;
    downloadUrl?: string | null;
    fileId?: string | null;
    filename?: string | null;
  } | null;
  schoolSupporter?: {
    __typename?: 'SchoolSupporterListResponse';
    items: Array<{
      __typename?: 'SchoolSupporter';
      id?: string | null;
      school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
    }>;
  } | null;
  media?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      downloadUrl?: string | null;
      meta?: any | null;
      filename?: string | null;
      fileId?: string | null;
      id?: string | null;
    }>;
  } | null;
  advertising?: {
    __typename?: 'Advertising';
    id?: string | null;
    supporter?: {
      __typename?: 'AdvertisingSupporter';
      id?: string | null;
      isSupporter?: boolean | null;
    } | null;
  } | null;
  location?: {
    __typename?: 'OrganizationLocationListResponse';
    items: Array<{
      __typename?: 'OrganizationLocation';
      id?: string | null;
      locationName?: string | null;
      operationalHours?: string | null;
      contactName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      link?: string | null;
      address?: {
        __typename?: 'AddressListResponse';
        items: Array<{
          __typename?: 'Address';
          id?: string | null;
          state?: string | null;
          zip?: string | null;
          street1?: string | null;
          city?: string | null;
        }>;
      } | null;
    }>;
  } | null;
  managers?: {
    __typename?: 'OrganizationManagerPreferenceListResponse';
    items: Array<{
      __typename?: 'OrganizationManagerPreference';
      id?: string | null;
      status?: string | null;
      isAdmin?: boolean | null;
      user?: {
        __typename?: 'User';
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        affiliation?: string | null;
        rolesAddons?: {
          __typename?: 'RoleAddonListResponse';
          items: Array<{ __typename?: 'RoleAddon'; id?: string | null; name?: string | null }>;
        } | null;
      } | null;
    }>;
  } | null;
  owner?: {
    __typename?: 'User';
    id?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  jobOffers?: {
    __typename?: 'JobOfferListResponse';
    items: Array<{
      __typename?: 'JobOffer';
      id?: string | null;
      description?: string | null;
      position?: string | null;
      createdAt?: any | null;
      createdBy?: {
        __typename?: 'User';
        firstName?: string | null;
        lastName?: string | null;
        id?: string | null;
        avatar?: { __typename?: 'File'; id?: string | null; downloadUrl?: string | null } | null;
      } | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
      } | null;
    }>;
  } | null;
  discounts?: {
    __typename?: 'DiscountListResponse';
    items: Array<{
      __typename?: 'Discount';
      id?: string | null;
      title?: string | null;
      terms?: string | null;
      amountPercent?: number | null;
      type?: string | null;
      startDate?: any | null;
      expirationDate?: any | null;
      category?: string | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
      } | null;
      images?: {
        __typename?: 'FileListResponse';
        items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
      } | null;
    }>;
  } | null;
};

export type OrganizationProfileFragment = {
  __typename?: 'Organization';
  id?: string | null;
  name?: string | null;
  industry?: string | null;
  description?: string | null;
  createdAt?: any | null;
  logo?: {
    __typename?: 'File';
    id?: string | null;
    downloadUrl?: string | null;
    fileId?: string | null;
    filename?: string | null;
  } | null;
  userLikes?: {
    __typename?: 'OrganizationLikeListResponse';
    count: number;
    items: Array<{
      __typename?: 'OrganizationLike';
      id?: string | null;
      user?: { __typename?: 'User'; id?: string | null } | null;
      organization?: { __typename?: 'Organization'; id?: string | null } | null;
    }>;
  } | null;
  schoolSupporter?: {
    __typename?: 'SchoolSupporterListResponse';
    count: number;
    items: Array<{ __typename?: 'SchoolSupporter'; id?: string | null }>;
  } | null;
  media?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      downloadUrl?: string | null;
      meta?: any | null;
      filename?: string | null;
      fileId?: string | null;
      id?: string | null;
    }>;
  } | null;
  location?: {
    __typename?: 'OrganizationLocationListResponse';
    items: Array<{
      __typename?: 'OrganizationLocation';
      id?: string | null;
      locationName?: string | null;
      operationalHours?: string | null;
      contactName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      link?: string | null;
      address?: {
        __typename?: 'AddressListResponse';
        items: Array<{
          __typename?: 'Address';
          id?: string | null;
          state?: string | null;
          zip?: string | null;
          street1?: string | null;
          city?: string | null;
        }>;
      } | null;
    }>;
  } | null;
  owner?: { __typename?: 'User'; id?: string | null } | null;
  managers?: {
    __typename?: 'OrganizationManagerPreferenceListResponse';
    items: Array<{
      __typename?: 'OrganizationManagerPreference';
      id?: string | null;
      status?: string | null;
      isAdmin?: boolean | null;
      user?: {
        __typename?: 'User';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
      } | null;
    }>;
  } | null;
  jobOffers?: {
    __typename?: 'JobOfferListResponse';
    items: Array<{
      __typename?: 'JobOffer';
      id?: string | null;
      description?: string | null;
      position?: string | null;
      postDate?: any | null;
      employmentType?: string | null;
      createdAt?: any | null;
      createdBy?: {
        __typename?: 'User';
        id?: string | null;
        email?: string | null;
        fullName?: string | null;
        graduatingYear?: any | null;
        professionalDesignations?: string | null;
        currentIndustry?: string | null;
        isSeekingEmployment?: boolean | null;
        affiliation?: string | null;
        jobExperience?: {
          __typename?: 'JobEmployeeListResponse';
          items: Array<{
            __typename?: 'JobEmployee';
            id?: string | null;
            companyName?: string | null;
            positionTitle?: string | null;
            isCurrentlyWorking?: boolean | null;
            companyImage?: {
              __typename?: 'File';
              id?: string | null;
              fileId?: string | null;
              downloadUrl?: string | null;
            } | null;
          }>;
        } | null;
        avatar?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      } | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        owner?: { __typename?: 'User'; id?: string | null } | null;
        managers?: {
          __typename?: 'OrganizationManagerPreferenceListResponse';
          items: Array<{
            __typename?: 'OrganizationManagerPreference';
            id?: string | null;
            user?: { __typename?: 'User'; id?: string | null } | null;
          }>;
        } | null;
        location?: {
          __typename?: 'OrganizationLocationListResponse';
          items: Array<{
            __typename?: 'OrganizationLocation';
            id?: string | null;
            address?: {
              __typename?: 'AddressListResponse';
              items: Array<{
                __typename?: 'Address';
                id?: string | null;
                zip?: string | null;
                street1?: string | null;
                state?: string | null;
                city?: string | null;
              }>;
            } | null;
          }>;
        } | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      } | null;
    }>;
  } | null;
  discounts?: {
    __typename?: 'DiscountListResponse';
    items: Array<{
      __typename?: 'Discount';
      id?: string | null;
      title?: string | null;
      terms?: string | null;
      amountPercent?: number | null;
      type?: string | null;
      startDate?: any | null;
      expirationDate?: any | null;
      category?: string | null;
      organization?: {
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
      } | null;
      images?: {
        __typename?: 'FileListResponse';
        items: Array<{ __typename?: 'File'; downloadUrl?: string | null }>;
      } | null;
    }>;
  } | null;
};

export type SendBusinessManagerInvitesMutationVariables = Exact<{
  school?: InputMaybe<SchoolConfigurationKeyFilter>;
  organization: OrganizationKeyFilter;
  invites: Array<SendBusinessManagerInviteInput> | SendBusinessManagerInviteInput;
}>;

export type SendBusinessManagerInvitesMutation = {
  __typename?: 'Mutation';
  commit: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type PostCheckQueryVariables = Exact<{
  text?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<Array<FileKeyFilter> | FileKeyFilter>;
  blacklistId: Scalars['String'];
  postId?: InputMaybe<Scalars['String']>;
  postType?: InputMaybe<Scalars['String']>;
}>;

export type PostCheckQuery = {
  __typename?: 'Query';
  postModerateRequest: { __typename?: 'PostModerateRequestSuccess'; verified?: boolean | null };
};

export type UpdatePostMutationVariables = Exact<{
  postId: Scalars['ID'];
  postData: Scalars['JSON'];
  postType: Scalars['String'];
}>;

export type UpdatePostMutation = {
  __typename?: 'Mutation';
  updatePost: { __typename?: 'UpdatePostResponse'; successful: boolean; data?: any | null };
};

export type MinimalLegacyFeedPostFragment = {
  __typename?: 'LegacyFeedPost';
  id?: string | null;
  text?: string | null;
  createdAt?: any | null;
  legacyDate?: any | null;
  author?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    status?: string | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    status?: string | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
  media?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    }>;
  } | null;
};

export type MinimalGroupPostFragment = {
  __typename?: 'GroupPost';
  id?: string | null;
  text?: string | null;
  createdAt?: any | null;
  author?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    status?: string | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    status?: string | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
  media?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    }>;
  } | null;
};

export type MinimalHomeFeedPostFragment = {
  __typename?: 'HomeFeedPost';
  id?: string | null;
  text?: string | null;
  createdAt?: any | null;
  author?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    status?: string | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    status?: string | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
  media?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    }>;
  } | null;
};

export type MinimalSchoolNewsPostFragment = {
  __typename?: 'SchoolNewsPost';
  id?: string | null;
  text?: string | null;
  createdAt?: any | null;
  postDate?: any | null;
  author?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    status?: string | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
  createdBy?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    status?: string | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
  media?: {
    __typename?: 'FileListResponse';
    items: Array<{
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    }>;
  } | null;
};

export type FindPostQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FindPostQuery = {
  __typename?: 'Query';
  homeFeedPost?: {
    __typename?: 'HomeFeedPost';
    id?: string | null;
    text?: string | null;
    createdAt?: any | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      status?: string | null;
      avatar?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      status?: string | null;
      avatar?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      }>;
    } | null;
  } | null;
  schoolNewsPost?: {
    __typename?: 'SchoolNewsPost';
    id?: string | null;
    text?: string | null;
    createdAt?: any | null;
    postDate?: any | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      status?: string | null;
      avatar?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      status?: string | null;
      avatar?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      }>;
    } | null;
  } | null;
  legacyFeedPost?: {
    __typename?: 'LegacyFeedPost';
    id?: string | null;
    text?: string | null;
    createdAt?: any | null;
    legacyDate?: any | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      status?: string | null;
      avatar?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      status?: string | null;
      avatar?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      }>;
    } | null;
  } | null;
  groupPost?: {
    __typename?: 'GroupPost';
    id?: string | null;
    text?: string | null;
    createdAt?: any | null;
    author?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      status?: string | null;
      avatar?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      status?: string | null;
      avatar?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null;
    media?: {
      __typename?: 'FileListResponse';
      items: Array<{
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      }>;
    } | null;
  } | null;
};

export type PromoteSchoolMemberMutationVariables = Exact<{
  user: UserKeyFilter;
  school: SchoolConfigurationKeyFilter;
}>;

export type PromoteSchoolMemberMutation = {
  __typename?: 'Mutation';
  commit: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type RemoteVariableFragment = {
  __typename?: 'RemoteVariable';
  minChargeAmount?: number | null;
  overflowDebtAmount?: number | null;
  maxDebtAmount?: number | null;
  minDebtAmount?: number | null;
  serveAdvertisingUnitAmount?: number | null;
  serveAdvertisingSupportUnitAmount?: number | null;
  jobOfferExpirationDays?: number | null;
  specialOfferDailyAmount?: number | null;
  specialOfferDailySupporterAmount?: number | null;
  showOrganizationBalance?: boolean | null;
  schoolSupportAmount?: number | null;
};

export type RequestCommunityAccessMutationVariables = Exact<{
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  affiliation: Scalars['String'];
  affiliationDescription?: InputMaybe<Scalars['String']>;
  graduatingYear?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  school: SchoolConfigurationKeyFilter;
}>;

export type RequestCommunityAccessMutation = {
  __typename?: 'Mutation';
  commit: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type RoleAddonFragment = {
  __typename?: 'RoleAddon';
  id?: string | null;
  name?: string | null;
  homePostsPermissions?: any | null;
  schoolPostsPermissions?: any | null;
  membersPermissions?: any | null;
  forumPermissions?: any | null;
  groupsPermissions?: any | null;
  businessPermissions?: any | null;
  role?: { __typename?: 'Role'; id?: string | null; name?: string | null } | null;
};

export type CurrentUserRoleAddonsQueryVariables = Exact<{
  school: Scalars['ID'];
}>;

export type CurrentUserRoleAddonsQuery = {
  __typename?: 'Query';
  rolesAddons: {
    __typename?: 'RoleAddonListResponse';
    items: Array<{
      __typename?: 'RoleAddon';
      id?: string | null;
      name?: string | null;
      homePostsPermissions?: any | null;
      schoolPostsPermissions?: any | null;
      membersPermissions?: any | null;
      forumPermissions?: any | null;
      groupsPermissions?: any | null;
      businessPermissions?: any | null;
      role?: { __typename?: 'Role'; id?: string | null; name?: string | null } | null;
    }>;
  };
};

export type RoleInfoFragment = { __typename?: 'Role'; id?: string | null; name?: string | null };

export type RoleAddonInfoFragment = {
  __typename?: 'RoleAddon';
  id?: string | null;
  name?: string | null;
  isDefault?: boolean | null;
  homePostsPermissions?: any | null;
  schoolPostsPermissions?: any | null;
  membersPermissions?: any | null;
  businessPermissions?: any | null;
  forumPermissions?: any | null;
  groupsPermissions?: any | null;
  role?: { __typename?: 'Role'; id?: string | null; name?: string | null } | null;
  accessibleLayouts?: {
    __typename?: 'SchoolConfigurationLayoutListResponse';
    items: Array<{
      __typename?: 'SchoolConfigurationLayout';
      id?: string | null;
      name?: string | null;
      route?: string | null;
      navbarOrderNumber?: number | null;
    }>;
  } | null;
};

export type SystemRolesQueryVariables = Exact<{
  filter?: InputMaybe<RoleFilter>;
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type SystemRolesQuery = {
  __typename?: 'Query';
  rolesList: {
    __typename?: 'RoleListResponse';
    items: Array<{ __typename?: 'Role'; id?: string | null; name?: string | null }>;
  };
};

export type RoleAddonsQueryVariables = Exact<{
  filter?: InputMaybe<RoleAddonFilter>;
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type RoleAddonsQuery = {
  __typename?: 'Query';
  roleAddonsList: {
    __typename?: 'RoleAddonListResponse';
    items: Array<{
      __typename?: 'RoleAddon';
      id?: string | null;
      name?: string | null;
      isDefault?: boolean | null;
      homePostsPermissions?: any | null;
      schoolPostsPermissions?: any | null;
      membersPermissions?: any | null;
      businessPermissions?: any | null;
      forumPermissions?: any | null;
      groupsPermissions?: any | null;
      role?: { __typename?: 'Role'; id?: string | null; name?: string | null } | null;
      accessibleLayouts?: {
        __typename?: 'SchoolConfigurationLayoutListResponse';
        items: Array<{
          __typename?: 'SchoolConfigurationLayout';
          id?: string | null;
          name?: string | null;
          route?: string | null;
          navbarOrderNumber?: number | null;
        }>;
      } | null;
    }>;
  };
};

export type RoleAddonCreateMutationVariables = Exact<{
  input: RoleAddonCreateInput;
}>;

export type RoleAddonCreateMutation = {
  __typename?: 'Mutation';
  roleAddonCreate: {
    __typename?: 'RoleAddon';
    id?: string | null;
    name?: string | null;
    isDefault?: boolean | null;
    homePostsPermissions?: any | null;
    schoolPostsPermissions?: any | null;
    membersPermissions?: any | null;
    businessPermissions?: any | null;
    forumPermissions?: any | null;
    groupsPermissions?: any | null;
    role?: { __typename?: 'Role'; id?: string | null; name?: string | null } | null;
    accessibleLayouts?: {
      __typename?: 'SchoolConfigurationLayoutListResponse';
      items: Array<{
        __typename?: 'SchoolConfigurationLayout';
        id?: string | null;
        name?: string | null;
        route?: string | null;
        navbarOrderNumber?: number | null;
      }>;
    } | null;
  };
};

export type RoleAddonUpdateMutationVariables = Exact<{
  roleId: Scalars['ID'];
  input: RoleAddonUpdateInput;
}>;

export type RoleAddonUpdateMutation = {
  __typename?: 'Mutation';
  roleAddonUpdate: {
    __typename?: 'RoleAddon';
    id?: string | null;
    name?: string | null;
    isDefault?: boolean | null;
    homePostsPermissions?: any | null;
    schoolPostsPermissions?: any | null;
    membersPermissions?: any | null;
    businessPermissions?: any | null;
    forumPermissions?: any | null;
    groupsPermissions?: any | null;
    role?: { __typename?: 'Role'; id?: string | null; name?: string | null } | null;
    accessibleLayouts?: {
      __typename?: 'SchoolConfigurationLayoutListResponse';
      items: Array<{
        __typename?: 'SchoolConfigurationLayout';
        id?: string | null;
        name?: string | null;
        route?: string | null;
        navbarOrderNumber?: number | null;
      }>;
    } | null;
  };
};

export type UserUpdateRolesMutationVariables = Exact<{
  input: UserUpdateRolesInput;
}>;

export type UserUpdateRolesMutation = {
  __typename?: 'Mutation';
  userUpdateRoles: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type SchoolConfigurationFragment = {
  __typename?: 'SchoolConfiguration';
  id?: string | null;
  name?: string | null;
  fullName?: string | null;
  timezone?: string | null;
  images?: {
    __typename?: 'SchoolConfigurationImage';
    logo?: { __typename?: 'File'; fileId?: string | null; downloadUrl?: string | null } | null;
  } | null;
  colorSchema?: {
    __typename?: 'SchoolConfigurationColorScheme';
    id?: string | null;
    accent1?: string | null;
    accent2?: string | null;
    primary?: string | null;
    secondary?: string | null;
    sidebar?: string | null;
    sidebarFont?: string | null;
  } | null;
};

export type CurrentSchoolConfigurationFragment = {
  __typename?: 'SchoolConfiguration';
  id?: string | null;
  timezone?: string | null;
  fullName?: string | null;
  name?: string | null;
  email?: string | null;
  moderateSchoolPosts?: boolean | null;
  moderateLegacyPosts?: boolean | null;
  moderationThreshold?: number | null;
  images?: {
    __typename?: 'SchoolConfigurationImage';
    id?: string | null;
    logo?: {
      __typename?: 'File';
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
    splashImage?: {
      __typename?: 'File';
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
    emailLogo?: {
      __typename?: 'File';
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
    emailBanner?: {
      __typename?: 'File';
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
    browserLogo?: {
      __typename?: 'File';
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
    applicationLogo?: {
      __typename?: 'File';
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
  colorSchema?: {
    __typename?: 'SchoolConfigurationColorScheme';
    id?: string | null;
    accent1?: string | null;
    accent2?: string | null;
    primary?: string | null;
    secondary?: string | null;
    sidebar?: string | null;
    sidebarFont?: string | null;
  } | null;
  topBar?: {
    __typename?: 'SchoolConfigurationTopBar';
    id?: string | null;
    title?: string | null;
    text?: string | null;
    startDate?: any | null;
    link?: string | null;
    label?: string | null;
    endDate?: any | null;
  } | null;
  layout?: {
    __typename?: 'SchoolConfigurationLayoutListResponse';
    items: Array<{
      __typename?: 'SchoolConfigurationLayout';
      id?: string | null;
      name?: string | null;
      icon?: string | null;
      route?: string | null;
      navbarOrderNumber?: number | null;
      sidebarOrderNumber?: number | null;
      isActive?: boolean | null;
      isAlwaysShown?: boolean | null;
    }>;
  } | null;
  externalLinks?: {
    __typename?: 'SchoolConfigurationExternalLinkListResponse';
    items: Array<{
      __typename?: 'SchoolConfigurationExternalLink';
      id?: string | null;
      name?: string | null;
      order?: number | null;
      link?: string | null;
      isActive?: boolean | null;
    }>;
  } | null;
  wordsBlacklist?: {
    __typename?: 'SchoolConfigurationWordsBlacklist';
    id?: string | null;
    blacklistId?: string | null;
    blacklistLabel?: string | null;
  } | null;
};

export type SchoolConfigurationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SchoolConfigurationQuery = {
  __typename?: 'Query';
  school?: {
    __typename?: 'SchoolConfiguration';
    id?: string | null;
    name?: string | null;
    fullName?: string | null;
    timezone?: string | null;
    images?: {
      __typename?: 'SchoolConfigurationImage';
      logo?: { __typename?: 'File'; fileId?: string | null; downloadUrl?: string | null } | null;
    } | null;
    colorSchema?: {
      __typename?: 'SchoolConfigurationColorScheme';
      id?: string | null;
      accent1?: string | null;
      accent2?: string | null;
      primary?: string | null;
      secondary?: string | null;
      sidebar?: string | null;
      sidebarFont?: string | null;
    } | null;
  } | null;
};

export type CurrentSchoolConfigurationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CurrentSchoolConfigurationQuery = {
  __typename?: 'Query';
  school?: {
    __typename?: 'SchoolConfiguration';
    id?: string | null;
    timezone?: string | null;
    fullName?: string | null;
    name?: string | null;
    email?: string | null;
    moderateSchoolPosts?: boolean | null;
    moderateLegacyPosts?: boolean | null;
    moderationThreshold?: number | null;
    images?: {
      __typename?: 'SchoolConfigurationImage';
      id?: string | null;
      logo?: {
        __typename?: 'File';
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
      splashImage?: {
        __typename?: 'File';
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
      emailLogo?: {
        __typename?: 'File';
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
      emailBanner?: {
        __typename?: 'File';
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
      browserLogo?: {
        __typename?: 'File';
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
      applicationLogo?: {
        __typename?: 'File';
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null;
    colorSchema?: {
      __typename?: 'SchoolConfigurationColorScheme';
      id?: string | null;
      accent1?: string | null;
      accent2?: string | null;
      primary?: string | null;
      secondary?: string | null;
      sidebar?: string | null;
      sidebarFont?: string | null;
    } | null;
    topBar?: {
      __typename?: 'SchoolConfigurationTopBar';
      id?: string | null;
      title?: string | null;
      text?: string | null;
      startDate?: any | null;
      link?: string | null;
      label?: string | null;
      endDate?: any | null;
    } | null;
    layout?: {
      __typename?: 'SchoolConfigurationLayoutListResponse';
      items: Array<{
        __typename?: 'SchoolConfigurationLayout';
        id?: string | null;
        name?: string | null;
        icon?: string | null;
        route?: string | null;
        navbarOrderNumber?: number | null;
        sidebarOrderNumber?: number | null;
        isActive?: boolean | null;
        isAlwaysShown?: boolean | null;
      }>;
    } | null;
    externalLinks?: {
      __typename?: 'SchoolConfigurationExternalLinkListResponse';
      items: Array<{
        __typename?: 'SchoolConfigurationExternalLink';
        id?: string | null;
        name?: string | null;
        order?: number | null;
        link?: string | null;
        isActive?: boolean | null;
      }>;
    } | null;
    wordsBlacklist?: {
      __typename?: 'SchoolConfigurationWordsBlacklist';
      id?: string | null;
      blacklistId?: string | null;
      blacklistLabel?: string | null;
    } | null;
  } | null;
};

export type SchoolConfigurationsQueryVariables = Exact<{
  filter?: InputMaybe<SchoolConfigurationFilter>;
}>;

export type SchoolConfigurationsQuery = {
  __typename?: 'Query';
  schools: {
    __typename?: 'SchoolConfigurationListResponse';
    items: Array<{
      __typename?: 'SchoolConfiguration';
      id?: string | null;
      name?: string | null;
      fullName?: string | null;
      timezone?: string | null;
      images?: {
        __typename?: 'SchoolConfigurationImage';
        logo?: { __typename?: 'File'; fileId?: string | null; downloadUrl?: string | null } | null;
      } | null;
      colorSchema?: {
        __typename?: 'SchoolConfigurationColorScheme';
        id?: string | null;
        accent1?: string | null;
        accent2?: string | null;
        primary?: string | null;
        secondary?: string | null;
        sidebar?: string | null;
        sidebarFont?: string | null;
      } | null;
    }>;
  };
};

export type SendSchoolMemberInvitesMutationVariables = Exact<{
  school: SchoolConfigurationKeyFilter;
  isSchoolAdministratorInviting?: InputMaybe<Scalars['Boolean']>;
  invites: Array<SendSchoolMemberInviteInput> | SendSchoolMemberInviteInput;
}>;

export type SendSchoolMemberInvitesMutation = {
  __typename?: 'Mutation';
  commit: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type SendSchoolAdministratorInvitesMutationVariables = Exact<{
  school: SchoolConfigurationKeyFilter;
  invites: Array<SendSchoolAdministratorInviteInput> | SendSchoolAdministratorInviteInput;
}>;

export type SendSchoolAdministratorInvitesMutation = {
  __typename?: 'Mutation';
  commit: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type SendBusinessAdministratorInvitesMutationVariables = Exact<{
  school: SchoolConfigurationKeyFilter;
  invites: Array<SendBusinessAdministratorInviteInput> | SendBusinessAdministratorInviteInput;
}>;

export type SendBusinessAdministratorInvitesMutation = {
  __typename?: 'Mutation';
  commit: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type StreamChatAuthorizationQueryVariables = Exact<{ [key: string]: never }>;

export type StreamChatAuthorizationQuery = {
  __typename?: 'Query';
  authorization: { __typename?: 'StreamChatAuthorizeResponse'; token: string };
};

export type SupporterInfoFragment = {
  __typename?: 'Organization';
  id?: string | null;
  name?: string | null;
  homeFeedViews?: number | null;
  logo?: {
    __typename?: 'File';
    id?: string | null;
    downloadUrl?: string | null;
    fileId?: string | null;
    filename?: string | null;
  } | null;
  location?: {
    __typename?: 'OrganizationLocationListResponse';
    items: Array<{
      __typename?: 'OrganizationLocation';
      id?: string | null;
      locationName?: string | null;
    }>;
  } | null;
  owner?: {
    __typename?: 'User';
    email?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    id?: string | null;
  } | null;
};

export type SupportedSchoolsCountQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type SupportedSchoolsCountQuery = {
  __typename?: 'Query';
  schoolSupportersList: { __typename?: 'SchoolSupporterListResponse'; count: number };
};

export type SupporterSearchQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilter>;
  first: Scalars['Int'];
  sort?: InputMaybe<Array<OrganizationSort> | OrganizationSort>;
}>;

export type SupporterSearchQuery = {
  __typename?: 'Query';
  organizationsList: {
    __typename?: 'OrganizationListResponse';
    items: Array<{
      __typename?: 'Organization';
      id?: string | null;
      name?: string | null;
      homeFeedViews?: number | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
        fileId?: string | null;
        filename?: string | null;
      } | null;
      location?: {
        __typename?: 'OrganizationLocationListResponse';
        items: Array<{
          __typename?: 'OrganizationLocation';
          id?: string | null;
          locationName?: string | null;
        }>;
      } | null;
      owner?: {
        __typename?: 'User';
        email?: string | null;
        lastName?: string | null;
        firstName?: string | null;
        id?: string | null;
      } | null;
    }>;
  };
};

export type MinimalUserFragment = {
  __typename?: 'User';
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  status?: string | null;
  avatar?: {
    __typename?: 'File';
    id?: string | null;
    fileId?: string | null;
    filename?: string | null;
    downloadUrl?: string | null;
  } | null;
};

export type OwnedOrganizationFragment = {
  __typename?: 'Organization';
  id?: string | null;
  name?: string | null;
  description?: string | null;
  advertising?: {
    __typename?: 'Advertising';
    id?: string | null;
    supporter?: {
      __typename?: 'AdvertisingSupporter';
      id?: string | null;
      isSupporter?: boolean | null;
    } | null;
  } | null;
  logo?: {
    __typename?: 'File';
    id?: string | null;
    fileId?: string | null;
    downloadUrl?: string | null;
  } | null;
};

export type CurrentUserFragment = {
  __typename?: 'User';
  id?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  status?: string | null;
  affiliation?: string | null;
  affiliationDescription?: string | null;
  activityClubs?: Array<string | null> | null;
  description?: string | null;
  gender?: string | null;
  graduatingYear?: any | null;
  hobbies?: Array<string | null> | null;
  isSeekingEmployment?: boolean | null;
  birthDate?: any | null;
  parents?: any | null;
  currentIndustry?: string | null;
  professionalDesignations?: string | null;
  groupAdminPreferences?: { __typename?: 'GroupAdminPreference'; id?: string | null } | null;
  avatar?: {
    __typename?: 'File';
    id?: string | null;
    fileId?: string | null;
    filename?: string | null;
    downloadUrl?: string | null;
  } | null;
  managerPreferences?: {
    __typename?: 'OrganizationManagerPreferenceListResponse';
    items: Array<{
      __typename?: 'OrganizationManagerPreference';
      id?: string | null;
      status?: string | null;
      isAdmin?: boolean | null;
    }>;
  } | null;
  viewedThreads?: {
    __typename?: 'CollectiveIntelligenceThreadListResponse';
    items: Array<{ __typename?: 'CollectiveIntelligenceThread'; id?: string | null }>;
  } | null;
  createdGroups?: {
    __typename?: 'GroupListResponse';
    items: Array<{
      __typename?: 'Group';
      id?: string | null;
      title?: string | null;
      membersCount?: number | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    }>;
  } | null;
  ownedOrganizations?: {
    __typename?: 'OrganizationListResponse';
    items: Array<{
      __typename?: 'Organization';
      id?: string | null;
      name?: string | null;
      description?: string | null;
      advertising?: {
        __typename?: 'Advertising';
        id?: string | null;
        supporter?: {
          __typename?: 'AdvertisingSupporter';
          id?: string | null;
          isSupporter?: boolean | null;
        } | null;
      } | null;
      logo?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        downloadUrl?: string | null;
      } | null;
    }>;
  } | null;
  userPreferences?: {
    __typename?: 'UserPreference';
    id?: string | null;
    phoneNumber?: string | null;
    secondaryPhoneNumber?: string | null;
    address?: {
      __typename?: 'Address';
      id?: string | null;
      city?: string | null;
      state?: string | null;
      street1?: string | null;
      zip?: string | null;
    } | null;
  } | null;
  schoolMemberships?: {
    __typename?: 'UserSchoolMembershipListResponse';
    items: Array<{
      __typename?: 'UserSchoolMembership';
      id?: string | null;
      school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
    }>;
  } | null;
  education?: {
    __typename?: 'EducationalInstitutionListResponse';
    items: Array<{
      __typename?: 'EducationalInstitution';
      id?: string | null;
      highSchool?: { __typename?: 'HighSchoolLocation'; id?: string | null } | null;
    }>;
  } | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    status?: string | null;
    affiliation?: string | null;
    affiliationDescription?: string | null;
    activityClubs?: Array<string | null> | null;
    description?: string | null;
    gender?: string | null;
    graduatingYear?: any | null;
    hobbies?: Array<string | null> | null;
    isSeekingEmployment?: boolean | null;
    birthDate?: any | null;
    parents?: any | null;
    currentIndustry?: string | null;
    professionalDesignations?: string | null;
    groupAdminPreferences?: { __typename?: 'GroupAdminPreference'; id?: string | null } | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
    managerPreferences?: {
      __typename?: 'OrganizationManagerPreferenceListResponse';
      items: Array<{
        __typename?: 'OrganizationManagerPreference';
        id?: string | null;
        status?: string | null;
        isAdmin?: boolean | null;
      }>;
    } | null;
    viewedThreads?: {
      __typename?: 'CollectiveIntelligenceThreadListResponse';
      items: Array<{ __typename?: 'CollectiveIntelligenceThread'; id?: string | null }>;
    } | null;
    createdGroups?: {
      __typename?: 'GroupListResponse';
      items: Array<{
        __typename?: 'Group';
        id?: string | null;
        title?: string | null;
        membersCount?: number | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          filename?: string | null;
          downloadUrl?: string | null;
        } | null;
      }>;
    } | null;
    ownedOrganizations?: {
      __typename?: 'OrganizationListResponse';
      items: Array<{
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        description?: string | null;
        advertising?: {
          __typename?: 'Advertising';
          id?: string | null;
          supporter?: {
            __typename?: 'AdvertisingSupporter';
            id?: string | null;
            isSupporter?: boolean | null;
          } | null;
        } | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      }>;
    } | null;
    userPreferences?: {
      __typename?: 'UserPreference';
      id?: string | null;
      phoneNumber?: string | null;
      secondaryPhoneNumber?: string | null;
      address?: {
        __typename?: 'Address';
        id?: string | null;
        city?: string | null;
        state?: string | null;
        street1?: string | null;
        zip?: string | null;
      } | null;
    } | null;
    schoolMemberships?: {
      __typename?: 'UserSchoolMembershipListResponse';
      items: Array<{
        __typename?: 'UserSchoolMembership';
        id?: string | null;
        school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
      }>;
    } | null;
    education?: {
      __typename?: 'EducationalInstitutionListResponse';
      items: Array<{
        __typename?: 'EducationalInstitution';
        id?: string | null;
        highSchool?: { __typename?: 'HighSchoolLocation'; id?: string | null } | null;
      }>;
    } | null;
  } | null;
};

export type CurrentUserSavedJobOffersQueryVariables = Exact<{
  filter?: InputMaybe<JobOfferFilter>;
}>;

export type CurrentUserSavedJobOffersQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    preferences?: {
      __typename?: 'UserPreference';
      saved?: {
        __typename?: 'JobOfferListResponse';
        items: Array<{
          __typename?: 'JobOffer';
          id?: string | null;
          position?: string | null;
          description?: string | null;
          employmentType?: string | null;
          postDate?: any | null;
          createdBy?: { __typename?: 'User'; id?: string | null } | null;
          organization?: {
            __typename?: 'Organization';
            id?: string | null;
            name?: string | null;
            logo?: { __typename?: 'File'; downloadUrl?: string | null } | null;
          } | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type UserDetailedFragment = {
  __typename?: 'User';
  id?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  status?: string | null;
  affiliation?: string | null;
  affiliationDescription?: string | null;
  activityClubs?: Array<string | null> | null;
  description?: string | null;
  gender?: string | null;
  graduatingYear?: any | null;
  hobbies?: Array<string | null> | null;
  isSeekingEmployment?: boolean | null;
  birthDate?: any | null;
  parents?: any | null;
  currentIndustry?: string | null;
  professionalDesignations?: string | null;
  avatar?: {
    __typename?: 'File';
    id?: string | null;
    fileId?: string | null;
    filename?: string | null;
    downloadUrl?: string | null;
  } | null;
  roles?: {
    __typename?: 'RoleListResponse';
    items: Array<{ __typename?: 'Role'; id?: string | null }>;
  } | null;
  rolesAddons?: {
    __typename?: 'RoleAddonListResponse';
    items: Array<{
      __typename?: 'RoleAddon';
      id?: string | null;
      school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
    }>;
  } | null;
  userPreferences?: {
    __typename?: 'UserPreference';
    id?: string | null;
    phoneNumber?: string | null;
    secondaryPhoneNumber?: string | null;
    address?: {
      __typename?: 'Address';
      id?: string | null;
      city?: string | null;
      state?: string | null;
      street1?: string | null;
      zip?: string | null;
    } | null;
  } | null;
};

export type UserByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type UserByIdQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    status?: string | null;
    affiliation?: string | null;
    affiliationDescription?: string | null;
    activityClubs?: Array<string | null> | null;
    description?: string | null;
    gender?: string | null;
    graduatingYear?: any | null;
    hobbies?: Array<string | null> | null;
    isSeekingEmployment?: boolean | null;
    birthDate?: any | null;
    parents?: any | null;
    currentIndustry?: string | null;
    professionalDesignations?: string | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
    roles?: {
      __typename?: 'RoleListResponse';
      items: Array<{ __typename?: 'Role'; id?: string | null }>;
    } | null;
    rolesAddons?: {
      __typename?: 'RoleAddonListResponse';
      items: Array<{
        __typename?: 'RoleAddon';
        id?: string | null;
        school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
      }>;
    } | null;
    userPreferences?: {
      __typename?: 'UserPreference';
      id?: string | null;
      phoneNumber?: string | null;
      secondaryPhoneNumber?: string | null;
      address?: {
        __typename?: 'Address';
        id?: string | null;
        city?: string | null;
        state?: string | null;
        street1?: string | null;
        zip?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UserUpdateMutationVariables = Exact<{
  data: UserUpdateInput;
}>;

export type UserUpdateMutation = {
  __typename?: 'Mutation';
  userUpdate: {
    __typename?: 'User';
    id?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    status?: string | null;
    affiliation?: string | null;
    affiliationDescription?: string | null;
    activityClubs?: Array<string | null> | null;
    description?: string | null;
    gender?: string | null;
    graduatingYear?: any | null;
    hobbies?: Array<string | null> | null;
    isSeekingEmployment?: boolean | null;
    birthDate?: any | null;
    parents?: any | null;
    currentIndustry?: string | null;
    professionalDesignations?: string | null;
    groupAdminPreferences?: { __typename?: 'GroupAdminPreference'; id?: string | null } | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
    managerPreferences?: {
      __typename?: 'OrganizationManagerPreferenceListResponse';
      items: Array<{
        __typename?: 'OrganizationManagerPreference';
        id?: string | null;
        status?: string | null;
        isAdmin?: boolean | null;
      }>;
    } | null;
    viewedThreads?: {
      __typename?: 'CollectiveIntelligenceThreadListResponse';
      items: Array<{ __typename?: 'CollectiveIntelligenceThread'; id?: string | null }>;
    } | null;
    createdGroups?: {
      __typename?: 'GroupListResponse';
      items: Array<{
        __typename?: 'Group';
        id?: string | null;
        title?: string | null;
        membersCount?: number | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          filename?: string | null;
          downloadUrl?: string | null;
        } | null;
      }>;
    } | null;
    ownedOrganizations?: {
      __typename?: 'OrganizationListResponse';
      items: Array<{
        __typename?: 'Organization';
        id?: string | null;
        name?: string | null;
        description?: string | null;
        advertising?: {
          __typename?: 'Advertising';
          id?: string | null;
          supporter?: {
            __typename?: 'AdvertisingSupporter';
            id?: string | null;
            isSupporter?: boolean | null;
          } | null;
        } | null;
        logo?: {
          __typename?: 'File';
          id?: string | null;
          fileId?: string | null;
          downloadUrl?: string | null;
        } | null;
      }>;
    } | null;
    userPreferences?: {
      __typename?: 'UserPreference';
      id?: string | null;
      phoneNumber?: string | null;
      secondaryPhoneNumber?: string | null;
      address?: {
        __typename?: 'Address';
        id?: string | null;
        city?: string | null;
        state?: string | null;
        street1?: string | null;
        zip?: string | null;
      } | null;
    } | null;
    schoolMemberships?: {
      __typename?: 'UserSchoolMembershipListResponse';
      items: Array<{
        __typename?: 'UserSchoolMembership';
        id?: string | null;
        school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
      }>;
    } | null;
    education?: {
      __typename?: 'EducationalInstitutionListResponse';
      items: Array<{
        __typename?: 'EducationalInstitution';
        id?: string | null;
        highSchool?: { __typename?: 'HighSchoolLocation'; id?: string | null } | null;
      }>;
    } | null;
  };
};

export type UserDeleteMutationVariables = Exact<{
  data: UserDeleteInput;
}>;

export type UserDeleteMutation = {
  __typename?: 'Mutation';
  userDelete?: { __typename?: 'SuccessResponse'; success?: boolean | null } | null;
};

export type UsersListCountQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
}>;

export type UsersListCountQuery = {
  __typename?: 'Query';
  users: { __typename?: 'UserListResponse'; count: number };
};

export type UsersListQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort> | UserSort>;
}>;

export type UsersListQuery = {
  __typename?: 'Query';
  usersList: {
    __typename?: 'UserListResponse';
    count: number;
    items: Array<{
      __typename?: 'User';
      id?: string | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
      status?: string | null;
      affiliation?: string | null;
      affiliationDescription?: string | null;
      activityClubs?: Array<string | null> | null;
      description?: string | null;
      gender?: string | null;
      graduatingYear?: any | null;
      hobbies?: Array<string | null> | null;
      isSeekingEmployment?: boolean | null;
      birthDate?: any | null;
      parents?: any | null;
      currentIndustry?: string | null;
      professionalDesignations?: string | null;
      groupAdminPreferences?: { __typename?: 'GroupAdminPreference'; id?: string | null } | null;
      avatar?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
      managerPreferences?: {
        __typename?: 'OrganizationManagerPreferenceListResponse';
        items: Array<{
          __typename?: 'OrganizationManagerPreference';
          id?: string | null;
          status?: string | null;
          isAdmin?: boolean | null;
        }>;
      } | null;
      viewedThreads?: {
        __typename?: 'CollectiveIntelligenceThreadListResponse';
        items: Array<{ __typename?: 'CollectiveIntelligenceThread'; id?: string | null }>;
      } | null;
      createdGroups?: {
        __typename?: 'GroupListResponse';
        items: Array<{
          __typename?: 'Group';
          id?: string | null;
          title?: string | null;
          membersCount?: number | null;
          logo?: {
            __typename?: 'File';
            id?: string | null;
            fileId?: string | null;
            filename?: string | null;
            downloadUrl?: string | null;
          } | null;
        }>;
      } | null;
      ownedOrganizations?: {
        __typename?: 'OrganizationListResponse';
        items: Array<{
          __typename?: 'Organization';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          advertising?: {
            __typename?: 'Advertising';
            id?: string | null;
            supporter?: {
              __typename?: 'AdvertisingSupporter';
              id?: string | null;
              isSupporter?: boolean | null;
            } | null;
          } | null;
          logo?: {
            __typename?: 'File';
            id?: string | null;
            fileId?: string | null;
            downloadUrl?: string | null;
          } | null;
        }>;
      } | null;
      userPreferences?: {
        __typename?: 'UserPreference';
        id?: string | null;
        phoneNumber?: string | null;
        secondaryPhoneNumber?: string | null;
        address?: {
          __typename?: 'Address';
          id?: string | null;
          city?: string | null;
          state?: string | null;
          street1?: string | null;
          zip?: string | null;
        } | null;
      } | null;
      schoolMemberships?: {
        __typename?: 'UserSchoolMembershipListResponse';
        items: Array<{
          __typename?: 'UserSchoolMembership';
          id?: string | null;
          school?: { __typename?: 'SchoolConfiguration'; id?: string | null } | null;
        }>;
      } | null;
      education?: {
        __typename?: 'EducationalInstitutionListResponse';
        items: Array<{
          __typename?: 'EducationalInstitution';
          id?: string | null;
          highSchool?: { __typename?: 'HighSchoolLocation'; id?: string | null } | null;
        }>;
      } | null;
    }>;
  };
};

export type MinimalUsersListQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserSort> | UserSort>;
}>;

export type MinimalUsersListQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserListResponse';
    count: number;
    items: Array<{
      __typename?: 'User';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      status?: string | null;
      avatar?: {
        __typename?: 'File';
        id?: string | null;
        fileId?: string | null;
        filename?: string | null;
        downloadUrl?: string | null;
      } | null;
    }>;
  };
};

export type CommentAuthorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CommentAuthorQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    status?: string | null;
    avatar?: {
      __typename?: 'File';
      id?: string | null;
      fileId?: string | null;
      filename?: string | null;
      downloadUrl?: string | null;
    } | null;
  } | null;
};

export type ResetUserCredentialsMutationVariables = Exact<{
  user: UserKeyFilter;
}>;

export type ResetUserCredentialsMutation = {
  __typename?: 'Mutation';
  resetUserCredentials: { __typename?: 'SuccessResponse'; success?: boolean | null };
};

export type RolesListQueryQueryVariables = Exact<{ [key: string]: never }>;

export type RolesListQueryQuery = {
  __typename?: 'Query';
  rolesList: {
    __typename?: 'RoleListResponse';
    items: Array<{ __typename?: 'Role'; id?: string | null; name?: string | null }>;
  };
};

export type UserRolesQueryQueryVariables = Exact<{ [key: string]: never }>;

export type UserRolesQueryQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id?: string | null;
    roles?: {
      __typename?: 'RoleListResponse';
      items: Array<{ __typename?: 'Role'; id?: string | null; name?: string | null }>;
    } | null;
  } | null;
};

export type SchoolConfigurationExternalLinkItemFragment = {
  __typename?: 'SchoolConfigurationExternalLink';
  id?: string | null;
  name?: string | null;
  link?: string | null;
  isActive?: boolean | null;
  order?: number | null;
};

export type SchoolConfigurationExternalLinkCreateMutationVariables = Exact<{
  data: SchoolConfigurationExternalLinkCreateInput;
}>;

export type SchoolConfigurationExternalLinkCreateMutation = {
  __typename?: 'Mutation';
  schoolConfigurationExternalLinkCreate: {
    __typename?: 'SchoolConfigurationExternalLink';
    id?: string | null;
    name?: string | null;
    link?: string | null;
    isActive?: boolean | null;
    order?: number | null;
  };
};

export type SchoolConfigurationExternalLinkUpdateMutationVariables = Exact<{
  data: SchoolConfigurationExternalLinkUpdateInput;
}>;

export type SchoolConfigurationExternalLinkUpdateMutation = {
  __typename?: 'Mutation';
  schoolConfigurationExternalLinkUpdate: {
    __typename?: 'SchoolConfigurationExternalLink';
    id?: string | null;
    name?: string | null;
    link?: string | null;
    isActive?: boolean | null;
    order?: number | null;
  };
};

export type SchoolConfigurationLayoutItemFragment = {
  __typename?: 'SchoolConfigurationLayout';
  id?: string | null;
  name?: string | null;
  icon?: string | null;
  isActive?: boolean | null;
  navbarOrderNumber?: number | null;
  sidebarOrderNumber?: number | null;
};

export type SchoolConfigurationLayoutUpdateMutationVariables = Exact<{
  data: SchoolConfigurationLayoutUpdateInput;
}>;

export type SchoolConfigurationLayoutUpdateMutation = {
  __typename?: 'Mutation';
  schoolConfigurationLayoutUpdate: {
    __typename?: 'SchoolConfigurationLayout';
    id?: string | null;
    name?: string | null;
    icon?: string | null;
    isActive?: boolean | null;
    navbarOrderNumber?: number | null;
    sidebarOrderNumber?: number | null;
  };
};

export type SchoolConfigurationTopBarCreateMutationVariables = Exact<{
  data: SchoolConfigurationTopBarCreateInput;
}>;

export type SchoolConfigurationTopBarCreateMutation = {
  __typename?: 'Mutation';
  schoolConfigurationTopBarCreate: { __typename?: 'SchoolConfigurationTopBar'; id?: string | null };
};

export type SchoolConfigurationTopBarUpdateMutationVariables = Exact<{
  data: SchoolConfigurationTopBarUpdateInput;
}>;

export type SchoolConfigurationTopBarUpdateMutation = {
  __typename?: 'Mutation';
  schoolConfigurationTopBarUpdate: { __typename?: 'SchoolConfigurationTopBar'; id?: string | null };
};

export type SchoolConfigurationTopBarDeleteMutationVariables = Exact<{
  data: SchoolConfigurationTopBarDeleteInput;
}>;

export type SchoolConfigurationTopBarDeleteMutation = {
  __typename?: 'Mutation';
  schoolConfigurationTopBarDelete?: {
    __typename?: 'SuccessResponse';
    success?: boolean | null;
  } | null;
};

export type SchoolConfigurationUpdateMutationVariables = Exact<{
  data: SchoolConfigurationUpdateInput;
}>;

export type SchoolConfigurationUpdateMutation = {
  __typename?: 'Mutation';
  schoolConfigurationUpdate: { __typename?: 'SchoolConfiguration'; id?: string | null };
};

export type Get_Discount_DataQueryVariables = Exact<{
  discountIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type Get_Discount_DataQuery = {
  __typename?: 'Query';
  discountsList: {
    __typename?: 'DiscountListResponse';
    items: Array<{
      __typename?: 'Discount';
      title?: string | null;
      amountPercent?: number | null;
      id?: string | null;
      type?: string | null;
      images?: {
        __typename?: 'FileListResponse';
        count: number;
        items: Array<{ __typename?: 'File'; id?: string | null; downloadUrl?: string | null }>;
      } | null;
    }>;
  };
};

export type GetFilesListQueryVariables = Exact<{
  fileIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetFilesListQuery = {
  __typename?: 'Query';
  filesList: {
    __typename?: 'FileListResponse';
    items: Array<{ __typename?: 'File'; downloadUrl?: string | null; id?: string | null }>;
  };
};

export const FullContentReportsListItemFragmentDoc = gql`
  fragment FullContentReportsListItem on ContentReport {
    id
    text
    category
    legacyDate
    authorId
    authorName
    groupId
    reportStatus
    flagReason
    lastFlaggedByName
    isFlagged
    isAutoReported
    isSupporter
    createdAt
    updatedAt
    flaggedAt
  }
`;
export const AdminDiscountFragmentDoc = gql`
  fragment AdminDiscount on Discount {
    id
    createdAt
    title
    type
    amountPercent
    startDate
    expirationDate
    timezone
    category
    status
    isSchoolDiscount
    terms
    images {
      items {
        id
        fileId
        filename
        public
        downloadUrl
      }
    }
    organization {
      id
      name
    }
    redemptions {
      count
    }
  }
`;
export const GroupMemberFragmentDoc = gql`
  fragment GroupMember on User {
    id
    firstName
    lastName
    email
    affiliation
    avatar {
      id
      downloadUrl
    }
    groupAdminPreferences {
      id
      group {
        items {
          id
        }
      }
    }
  }
`;
export const GroupsItemFragmentDoc = gql`
  fragment GroupsItem on Group {
    createdAt
    description
    id
    isApproved
    membersCount
    status
    title
    createdBy {
      id
      lastName
      firstName
    }
    members {
      items {
        ...GroupMember
      }
    }
    posts {
      count
    }
    postsCount
    logo {
      id
      fileId
      downloadUrl
      filename
    }
    groupAdminPreferences {
      items {
        status
        id
        user {
          email
          id
          firstName
          lastName
          affiliation
        }
      }
    }
  }
  ${GroupMemberFragmentDoc}
`;
export const SchoolMemberFragmentDoc = gql`
  fragment SchoolMember on SchoolMember {
    id
    firstName
    lastName
    email
    affiliation
    status
    hobbies
    activityClubs
    birthDate
    gender
    graduatingYear
    phoneNumber
    professionalDesignations
    registrationCompletedAt
    createdAt
    distance
    groupPostsCount
    homePostsCount
    avatar {
      id
      downloadUrl
    }
    roleAddons {
      id
      name
    }
    systemRoles {
      id
      name
    }
  }
`;
export const ContentReportsListItemFragmentDoc = gql`
  fragment ContentReportsListItem on ContentReport {
    id
    authorId
    authorName
    text
    createdAt
    category
    isSupporter
  }
`;
export const ContentEngagementReportFragmentDoc = gql`
  fragment ContentEngagementReport on ContentEngagementReport {
    id
    text
    category
    legacyDate
    authorId
    authorName
    groupId
    likesCount
    commentsCount
    reactionsCount
    reportStatus
    flagReason
    lastFlaggedByName
    isFlagged
    isAutoReported
    isSupporter
    createdAt
    updatedAt
    flaggedAt
  }
`;
export const OrganizationInfoFragmentDoc = gql`
  fragment OrganizationInfo on Organization {
    id
    name
    createdBy {
      id
    }
    managers {
      items {
        id
        user {
          id
        }
      }
    }
  }
`;
export const OrganizationLocationItemFragmentDoc = gql`
  fragment OrganizationLocationItem on OrganizationLocation {
    id
    locationName
    operationalHours
    contactName
    email
    phoneNumber
    link
    address {
      items {
        id
        state
        zip
        street1
        city
      }
    }
  }
`;
export const DiscountPresentationFragmentDoc = gql`
  fragment DiscountPresentation on Discount {
    id
    title
    terms
    amountPercent
    type
    startDate
    expirationDate
    category
    organization {
      id
      name
      logo {
        downloadUrl
      }
    }
    images {
      items {
        downloadUrl
      }
    }
  }
`;
export const OrganizationItemFragmentDoc = gql`
  fragment OrganizationItem on Organization {
    id
    homeFeedViews
    description
    industry
    name
    likesCount
    status
    createdAt
    userLikes {
      items {
        id
        user {
          id
        }
        organization {
          id
        }
      }
    }
    logo {
      id
      downloadUrl
      fileId
      filename
    }
    schoolSupporter {
      items {
        id
        school {
          id
        }
      }
    }
    media {
      items {
        downloadUrl
        meta
        filename
        fileId
        id
      }
    }
    advertising {
      id
      supporter {
        id
        isSupporter
      }
    }
    location {
      items {
        ...OrganizationLocationItem
      }
    }
    managers {
      items {
        id
        status
        isAdmin
        user {
          id
          email
          firstName
          lastName
          affiliation
          rolesAddons {
            items {
              id
              name
            }
          }
        }
      }
    }
    owner {
      id
      email
      firstName
      lastName
    }
    jobOffers {
      items {
        id
        description
        position
        createdAt
        createdBy {
          firstName
          lastName
          id
          avatar {
            id
            downloadUrl
          }
        }
        organization {
          id
          name
        }
      }
    }
    discounts {
      items {
        ...DiscountPresentation
      }
    }
  }
  ${OrganizationLocationItemFragmentDoc}
  ${DiscountPresentationFragmentDoc}
`;
export const AdItemFragmentDoc = gql`
  fragment AdItem on AdvertisingCampaignAd {
    id
    name
    primaryText
    callToActionText
    callToActionLink
    totalSpend
    todaySpend
    dailyBudget
    dailyBudgetEnabled
    totalBudget
    totalBudgetEnabled
    createdAt
    audiences {
      items {
        id
        industry
        affiliation
        graduatingYear
        zipCode
        city
        state
        hobbies
        clubs
      }
    }
    sampaign {
      id
      name
      advertising {
        id
        organization {
          ...OrganizationItem
        }
        supporter {
          isSupporter
        }
      }
    }
    image {
      downloadUrl
      id
    }
    userViews {
      count
    }
  }
  ${OrganizationItemFragmentDoc}
`;
export const CollectiveIntelligenceItemFragmentDoc = gql`
  fragment CollectiveIntelligenceItem on CollectiveIntelligenceThread {
    id
    createdAt
    text
    title
    views {
      items {
        id
      }
    }
    creator {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
    images {
      items {
        id
        downloadUrl
      }
    }
  }
`;
export const DiscountListItemFragmentDoc = gql`
  fragment DiscountListItem on Discount {
    id
    title
    amountPercent
    startDate
    expirationDate
    timezone
    category
    status
    type
    createdAt
    terms
    usersRedeemedCount
    organization {
      id
      name
      logo {
        downloadUrl
      }
    }
    images {
      items {
        id
        downloadUrl
      }
    }
  }
`;
export const MembersListItemFragmentDoc = gql`
  fragment MembersListItem on User {
    id
    firstName
    lastName
    affiliation
    graduatingYear
    avatar {
      id
      downloadUrl
    }
    jobExperience {
      items {
        id
        positionDescription
        positionTitle
        companyName
        companyLocation {
          state
          city
          street1
          zip
        }
        companyImage {
          downloadUrl
        }
      }
    }
  }
`;
export const GroupsListItemFragmentDoc = gql`
  fragment GroupsListItem on Group {
    id
    title
    membersCount
    description
    status
    isApproved
    createdAt
    logo {
      id
      downloadUrl
      fileId
      filename
    }
    members {
      count
      items {
        ...MembersListItem
      }
    }
    creator {
      id
      firstName
      lastName
    }
    posts {
      count
    }
    groupAdminPreferences {
      count
      items {
        id
        status
        user {
          id
        }
      }
    }
  }
  ${MembersListItemFragmentDoc}
`;
export const GroupPostsListItemFragmentDoc = gql`
  fragment GroupPostsListItem on GroupPost {
    id
    createdAt
    text
    isPinned
    commentsAllowed
    author {
      id
      firstName
      lastName
      ownedOrganizations {
        items {
          id
          advertising {
            id
            supporter {
              id
              isSupporter
            }
          }
        }
      }
      avatar {
        id
        downloadUrl
      }
    }
    media {
      items {
        id
        downloadUrl
        meta
        fileId
        filename
      }
    }
    group {
      id
    }
    status
    reportStatus
    flaggedAt
    flagReason
    updatedAt
    isAutoReported
    lastFlaggedBy {
      id
      firstName
      lastName
    }
    mentions {
      items {
        id
        mentionId
        mentionText
        position
        type
      }
    }
  }
`;
export const GroupDetailsFragmentDoc = gql`
  fragment GroupDetails on Group {
    id
    title
    description
    membersCount
    createdAt
    isApproved
    members {
      items {
        id
      }
    }
    logo {
      id
      fileId
      downloadUrl
      filename
    }
    createdBy {
      id
      lastName
      firstName
    }
    groupAdminPreferences {
      items {
        status
        id
        user {
          email
          id
          firstName
          lastName
          affiliation
        }
      }
    }
  }
`;
export const HomeFeedPostsListItemFragmentDoc = gql`
  fragment HomeFeedPostsListItem on HomeFeedPost {
    id
    createdAt
    text
    isPinned
    commentsAllowed
    discount {
      ...DiscountPresentation
    }
    media {
      items {
        id
        downloadUrl
        meta
        fileId
        filename
      }
    }
    author {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
      ownedOrganizations {
        items {
          id
          advertising {
            id
            supporter {
              id
              isSupporter
            }
          }
        }
      }
    }
    createdBy {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
    status
    reportStatus
    flaggedAt
    flagReason
    updatedAt
    isAutoReported
    lastFlaggedBy {
      id
      firstName
      lastName
    }
    mentions {
      items {
        id
        mentionId
        mentionText
        position
        type
      }
    }
  }
  ${DiscountPresentationFragmentDoc}
`;
export const HomeFeedItemFragmentDoc = gql`
  fragment HomeFeedItem on HomeFeedItem {
    id
    text
    status
    reportStatus
    postType
    type: postType
    mentions
    media
    location
    groupId
    isPinned
    isFuture
    commentsAllowed
    authorId
    authorFirstName
    authorLastName
    authorAvatar
    discount
    amountPercent
    postDate
    startDate
    expirationDate
    legacyDate
    createdAt
    updatedAt
  }
`;
export const InboxUserFragmentDoc = gql`
  fragment InboxUser on User {
    id
    firstName
    lastName
    avatar {
      id
      downloadUrl
    }
  }
`;
export const InboxMessageFragmentDoc = gql`
  fragment InboxMessage on InboxMessage {
    id
    createdAt
    text
    reply
    isRead
    isDeleted
    media {
      items {
        id
        meta
        downloadUrl
      }
    }
    author {
      id
    }
    inbox {
      id
      type
      destinationGroups
    }
    messageMedia {
      id
      media {
        items {
          id
          meta
          downloadUrl
          filename
        }
      }
    }
  }
`;
export const InboxFragmentDoc = gql`
  fragment Inbox on Inbox {
    id
    subject
    type
    destinationGroups
    users(first: $firstUsers) {
      items {
        ...InboxUser
      }
    }
    messages(first: $firstMessages, sort: $sortMessages, filter: $filterMessages) {
      items {
        ...InboxMessage
      }
    }
  }
  ${InboxUserFragmentDoc}
  ${InboxMessageFragmentDoc}
`;
export const LegacyFeedPostsListItemFragmentDoc = gql`
  fragment LegacyFeedPostsListItem on LegacyFeedPost {
    id
    createdAt
    updatedAt
    text
    isPinned
    commentsAllowed
    postDate
    legacyDate
    reportStatus
    flaggedAt
    flagReason
    updatedAt
    isAutoReported
    lastFlaggedBy {
      id
      firstName
      lastName
    }
    media {
      items {
        id
        meta
        downloadUrl
        fileId
        filename
      }
    }
    createdBy {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
    author {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
      ownedOrganizations {
        items {
          id
          advertising {
            id
            supporter {
              id
              isSupporter
            }
          }
        }
      }
    }
  }
`;
export const LegacyFeedMonthsInfoFragmentDoc = gql`
  fragment LegacyFeedMonthsInfo on LegacyFeedMonth {
    id
    month
    legacyYears {
      id
      legacyYear
    }
  }
`;
export const LegacyFeedYearsInfoFragmentDoc = gql`
  fragment LegacyFeedYearsInfo on LegacyFeedYear {
    id
    legacyYear
    legacyMonths {
      items {
        id
        month
      }
    }
  }
`;
export const LegacyTimelinePostFragmentDoc = gql`
  fragment LegacyTimelinePost on LegacyFeedPost {
    id
    text
    legacyDate
    postDate: createdAt
    media {
      items {
        downloadUrl
      }
    }
  }
`;
export const SchoolNewsPostsListItemFragmentDoc = gql`
  fragment SchoolNewsPostsListItem on SchoolNewsPost {
    id
    createdAt
    updatedAt
    text
    isPinned
    postDate
    commentsAllowed
    mentions {
      items {
        id
        mentionId
        mentionText
        position
        type
      }
    }
    flagReason
    flaggedAt
    lastFlaggedBy {
      id
      firstName
      lastName
    }
    media {
      items {
        id
        downloadUrl
        meta
        fileId
        filename
      }
    }
    reportStatus
    author {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
      ownedOrganizations {
        items {
          id
          advertising {
            id
            supporter {
              id
              isSupporter
            }
          }
        }
      }
    }
  }
`;
export const FileFragmentDoc = gql`
  fragment File on File {
    id
    fileId
    filename
    downloadUrl
  }
`;
export const MinimalUserFragmentDoc = gql`
  fragment MinimalUser on User {
    id
    firstName
    lastName
    email
    status
    avatar {
      ...File
    }
  }
  ${FileFragmentDoc}
`;
export const GraduatingYearMetricFragmentDoc = gql`
  fragment GraduatingYearMetric on GraduatingYearsMetric {
    id
    year
    expectedUsersCount
    registeredUsersCount
    registeredUsersPercentage
    missingUsersCount
    missingUsersPercentage
    createdAt
    updatedAt
    createdBy {
      ...MinimalUser
    }
  }
  ${MinimalUserFragmentDoc}
`;
export const GroupRegisteredUsersCountFragmentDoc = gql`
  fragment GroupRegisteredUsersCount on GroupByResponse {
    year: Date
    usersCount: Int
  }
`;
export const ProfessionalProfileFragmentDoc = gql`
  fragment ProfessionalProfile on JobEmployee {
    id
    employmentType
    positionDescription
    positionTitle
    isCurrentlyWorking
    startDate
    endDate
    companyName
    companyLocation {
      state
      city
      street1
      zip
    }
    companyImage {
      fileId
      downloadUrl
    }
  }
`;
export const EducationProfileFragmentDoc = gql`
  fragment EducationProfile on EducationalInstitution {
    id
    degree
    fieldOfStudy
    gpa
    activities
    description
    startDate
    endDate
    address {
      id
      street1
      zip
      city
      state
    }
    image {
      fileId
      downloadUrl
    }
    highSchool {
      id
      name
      logo {
        downloadUrl
      }
    }
  }
`;
export const OwnedOrganizationFragmentDoc = gql`
  fragment OwnedOrganization on Organization {
    id
    name
    description
    advertising {
      id
      supporter {
        id
        isSupporter
      }
    }
    logo {
      id
      fileId
      downloadUrl
    }
  }
`;
export const CurrentUserFragmentDoc = gql`
  fragment CurrentUser on User {
    id
    email
    firstName
    lastName
    fullName
    status
    affiliation
    affiliationDescription
    activityClubs
    description
    gender
    graduatingYear
    hobbies
    isSeekingEmployment
    birthDate
    parents
    currentIndustry
    professionalDesignations
    groupAdminPreferences {
      id
    }
    avatar {
      ...File
    }
    managerPreferences {
      items {
        id
        status
        isAdmin
      }
    }
    viewedThreads {
      items {
        id
      }
    }
    createdGroups {
      items {
        id
        title
        membersCount
        logo {
          ...File
        }
      }
    }
    ownedOrganizations {
      items {
        ...OwnedOrganization
      }
    }
    userPreferences {
      id
      phoneNumber
      secondaryPhoneNumber
      address {
        id
        city
        state
        street1
        zip
      }
    }
    schoolMemberships {
      items {
        id
        school {
          id
        }
      }
    }
    education {
      items {
        id
        highSchool {
          id
        }
      }
    }
  }
  ${FileFragmentDoc}
  ${OwnedOrganizationFragmentDoc}
`;
export const UserPublicProfileFragmentDoc = gql`
  fragment UserPublicProfile on User {
    ...CurrentUser
    facebookLink
    instagramLink
    linkedInLink
    twitterLink
  }
  ${CurrentUserFragmentDoc}
`;
export const GroupInfoFragmentDoc = gql`
  fragment GroupInfo on Group {
    id
    creator {
      id
    }
    title
    membersCount
    description
    logo {
      id
      downloadUrl
      fileId
      filename
    }
    status
    members {
      items {
        id
        email
        firstName
        lastName
      }
    }
    groupAdminPreferences {
      items {
        id
        user {
          id
        }
      }
    }
  }
`;
export const UserListItemFragmentDoc = gql`
  fragment UserListItem on User {
    id
    firstName
    lastName
    birthDate
    avatar {
      id
      downloadUrl
    }
  }
`;
export const MemberDiscountItemFragmentDoc = gql`
  fragment MemberDiscountItem on Discount {
    id
    createdAt
    createdBy {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
    title
    amountPercent
    type
    startDate
    expirationDate
    timezone
    category
    status
    organization {
      id
      name
      logo {
        downloadUrl
      }
    }
    images {
      items {
        id
        downloadUrl
      }
    }
    usersRedeemedCount
    terms
  }
`;
export const UserNotificationFragmentDoc = gql`
  fragment UserNotification on UserNotification {
    id
    relatedItemID
    createdAt
    image {
      id
      downloadUrl
    }
    template {
      id
      title
      key
    }
    createdAt
    deletable
    infoJSON
    sender {
      id
      firstName
      lastName
    }
  }
`;
export const UserNotificationReadableFragmentDoc = gql`
  fragment UserNotificationReadable on UserNotificationsByUser {
    id
    isRead
    createdAt
    notification {
      ...UserNotification
    }
  }
  ${UserNotificationFragmentDoc}
`;
export const UserInfoFragmentDoc = gql`
  fragment UserInfo on User {
    id
    firstName
    lastName
    avatar {
      id
      downloadUrl
    }
    email
    affiliation
    graduatingYear
    professionalDesignations
    ownedOrganizations {
      items {
        id
        advertising {
          id
          supporter {
            id
            isSupporter
          }
        }
      }
    }
  }
`;
export const TrendingSearchesListItemFragmentDoc = gql`
  fragment TrendingSearchesListItem on TrendingSearch {
    id
    wordOrPhrase
    searchesCount
  }
`;
export const EventInfoFragmentDoc = gql`
  fragment EventInfo on Event {
    id
    title
    date
    link
    status
    description
    createdBy {
      id
      firstName
      lastName
      ownedOrganizations {
        items {
          id
          advertising {
            id
            supporter {
              id
              isSupporter
            }
          }
        }
      }
    }
    createdAt
    location {
      id
      city
      state
      zip
      street1
    }
    titleSponsor {
      id
      industry
      name
      logo {
        id
        downloadUrl
        fileId
        filename
      }
      media {
        items {
          id
          downloadUrl
        }
      }
    }
    images {
      items {
        id
        downloadUrl
        fileId
        filename
      }
    }
    otherSponsors {
      items {
        id
        logo {
          id
          downloadUrl
          fileId
          filename
        }
        name
      }
    }
    author {
      id
      firstName
      lastName
    }
    timezone
  }
`;
export const EventSearchFragmentDoc = gql`
  fragment EventSearch on Event {
    id
    title
    date
    link
    status
    description
    timezone
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    images {
      items {
        id
        downloadUrl
        fileId
      }
    }
    location {
      id
      city
      state
      zip
      street1
    }
    titleSponsor {
      id
      industry
      name
      logo {
        id
        fileId
        downloadUrl
      }
    }
    otherSponsors {
      items {
        id
        logo {
          id
          fileId
          downloadUrl
        }
      }
    }
  }
`;
export const HighSchoolFragmentDoc = gql`
  fragment HighSchool on HighSchoolLocation {
    id
    name
    zip
    state
    city
    street
  }
`;
export const GraduatingYearInfoFragmentDoc = gql`
  fragment GraduatingYearInfo on GraduatingYear {
    id
    year
    graduateCount
  }
`;
export const GraduatingYearDetailedInfoFragmentDoc = gql`
  fragment GraduatingYearDetailedInfo on GraduatingYear {
    id
    year
    graduateCount
    createdBy {
      id
      firstName
      lastName
    }
    createdAt
  }
`;
export const JobOfferPresentationFragmentDoc = gql`
  fragment JobOfferPresentation on JobOffer {
    id
    position
    description
    employmentType
    postDate
    createdBy {
      id
    }
    organization {
      id
      name
      logo {
        downloadUrl
      }
    }
  }
`;
export const CareerInfoFragmentDoc = gql`
  fragment CareerInfo on JobOffer {
    id
    description
    position
    postDate
    employmentType
    createdAt
    createdBy {
      id
      email
      fullName
      graduatingYear
      professionalDesignations
      currentIndustry
      isSeekingEmployment
      affiliation
      jobExperience(first: 1, orderBy: createdAt_DESC) {
        items {
          id
          companyName
          positionTitle
          isCurrentlyWorking
          companyImage {
            id
            fileId
            downloadUrl
          }
        }
      }
      avatar {
        id
        fileId
        downloadUrl
      }
    }
    organization {
      id
      name
      owner {
        id
      }
      managers {
        items {
          id
          user {
            id
          }
        }
      }
      location {
        items {
          id
          address {
            items {
              id
              zip
              street1
              state
              city
            }
          }
        }
      }
      logo {
        id
        fileId
        downloadUrl
      }
    }
  }
`;
export const OrganizationProfileFragmentDoc = gql`
  fragment OrganizationProfile on Organization {
    id
    name
    industry
    description
    createdAt
    logo {
      id
      downloadUrl
      fileId
      filename
    }
    userLikes {
      count
      items {
        id
        user {
          id
        }
        organization {
          id
        }
      }
    }
    schoolSupporter {
      items {
        id
      }
      count
    }
    media {
      items {
        downloadUrl
        meta
        filename
        fileId
        id
      }
    }
    location {
      items {
        ...OrganizationLocationItem
      }
    }
    owner {
      id
    }
    managers {
      items {
        id
        status
        isAdmin
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
    jobOffers {
      items {
        ...CareerInfo
      }
    }
    discounts(filter: { status: { equals: "active" } }) {
      items {
        ...DiscountPresentation
      }
    }
  }
  ${OrganizationLocationItemFragmentDoc}
  ${CareerInfoFragmentDoc}
  ${DiscountPresentationFragmentDoc}
`;
export const MediaFragmentDoc = gql`
  fragment Media on FileListResponse {
    items {
      ...File
    }
  }
  ${FileFragmentDoc}
`;
export const MinimalLegacyFeedPostFragmentDoc = gql`
  fragment MinimalLegacyFeedPost on LegacyFeedPost {
    id
    text
    createdAt
    legacyDate
    author {
      ...MinimalUser
    }
    createdBy {
      ...MinimalUser
    }
    media {
      ...Media
    }
  }
  ${MinimalUserFragmentDoc}
  ${MediaFragmentDoc}
`;
export const MinimalGroupPostFragmentDoc = gql`
  fragment MinimalGroupPost on GroupPost {
    id
    text
    createdAt
    author {
      ...MinimalUser
    }
    createdBy {
      ...MinimalUser
    }
    media {
      ...Media
    }
  }
  ${MinimalUserFragmentDoc}
  ${MediaFragmentDoc}
`;
export const MinimalHomeFeedPostFragmentDoc = gql`
  fragment MinimalHomeFeedPost on HomeFeedPost {
    id
    text
    createdAt
    author {
      ...MinimalUser
    }
    createdBy {
      ...MinimalUser
    }
    media {
      ...Media
    }
  }
  ${MinimalUserFragmentDoc}
  ${MediaFragmentDoc}
`;
export const MinimalSchoolNewsPostFragmentDoc = gql`
  fragment MinimalSchoolNewsPost on SchoolNewsPost {
    id
    text
    createdAt
    postDate
    author {
      ...MinimalUser
    }
    createdBy {
      ...MinimalUser
    }
    media {
      ...Media
    }
  }
  ${MinimalUserFragmentDoc}
  ${MediaFragmentDoc}
`;
export const RemoteVariableFragmentDoc = gql`
  fragment RemoteVariable on RemoteVariable {
    minChargeAmount
    overflowDebtAmount
    maxDebtAmount
    minDebtAmount
    serveAdvertisingUnitAmount
    serveAdvertisingSupportUnitAmount
    jobOfferExpirationDays
    specialOfferDailyAmount
    specialOfferDailySupporterAmount
    showOrganizationBalance
    schoolSupportAmount
  }
`;
export const RoleAddonFragmentDoc = gql`
  fragment RoleAddon on RoleAddon {
    id
    name
    homePostsPermissions
    schoolPostsPermissions
    membersPermissions
    forumPermissions
    groupsPermissions
    businessPermissions
    role {
      id
      name
    }
  }
`;
export const RoleInfoFragmentDoc = gql`
  fragment RoleInfo on Role {
    id
    name
  }
`;
export const RoleAddonInfoFragmentDoc = gql`
  fragment RoleAddonInfo on RoleAddon {
    id
    name
    isDefault
    role {
      ...RoleInfo
    }
    accessibleLayouts {
      items {
        id
        name
        route
        navbarOrderNumber
      }
    }
    homePostsPermissions
    schoolPostsPermissions
    membersPermissions
    businessPermissions
    forumPermissions
    groupsPermissions
  }
  ${RoleInfoFragmentDoc}
`;
export const SchoolConfigurationFragmentDoc = gql`
  fragment SchoolConfiguration on SchoolConfiguration {
    id
    name
    fullName
    timezone
    images {
      logo {
        fileId
        downloadUrl
      }
    }
    colorSchema {
      id
      accent1
      accent2
      primary
      secondary
      sidebar
      sidebarFont
    }
  }
`;
export const CurrentSchoolConfigurationFragmentDoc = gql`
  fragment CurrentSchoolConfiguration on SchoolConfiguration {
    id
    timezone
    fullName
    name
    email
    moderateSchoolPosts
    moderateLegacyPosts
    moderationThreshold
    images {
      id
      logo {
        fileId
        filename
        downloadUrl
      }
      splashImage {
        fileId
        filename
        downloadUrl
      }
      emailLogo {
        fileId
        filename
        downloadUrl
      }
      emailBanner {
        fileId
        filename
        downloadUrl
      }
      browserLogo {
        fileId
        filename
        downloadUrl
      }
      applicationLogo {
        fileId
        filename
        downloadUrl
      }
    }
    colorSchema {
      id
      accent1
      accent2
      primary
      secondary
      sidebar
      sidebarFont
    }
    topBar {
      id
      title
      text
      startDate
      link
      label
      endDate
    }
    layout {
      items {
        id
        name
        icon
        route
        navbarOrderNumber
        sidebarOrderNumber
        isActive
        isAlwaysShown
      }
    }
    externalLinks {
      items {
        id
        name
        order
        link
        isActive
      }
    }
    wordsBlacklist {
      id
      blacklistId
      blacklistLabel
    }
  }
`;
export const SupporterInfoFragmentDoc = gql`
  fragment SupporterInfo on Organization {
    id
    name
    homeFeedViews
    logo {
      id
      downloadUrl
      fileId
      filename
    }
    location {
      items {
        id
        locationName
      }
    }
    owner {
      email
      lastName
      firstName
      id
    }
  }
`;
export const UserDetailedFragmentDoc = gql`
  fragment UserDetailed on User {
    id
    email
    firstName
    lastName
    fullName
    status
    affiliation
    affiliationDescription
    activityClubs
    description
    gender
    graduatingYear
    hobbies
    isSeekingEmployment
    birthDate
    parents
    currentIndustry
    professionalDesignations
    avatar {
      ...File
    }
    roles {
      items {
        id
      }
    }
    rolesAddons {
      items {
        id
        school {
          id
        }
      }
    }
    userPreferences {
      id
      phoneNumber
      secondaryPhoneNumber
      address {
        id
        city
        state
        street1
        zip
      }
    }
  }
  ${FileFragmentDoc}
`;
export const SchoolConfigurationExternalLinkItemFragmentDoc = gql`
  fragment SchoolConfigurationExternalLinkItem on SchoolConfigurationExternalLink {
    id
    name
    link
    isActive
    order
  }
`;
export const SchoolConfigurationLayoutItemFragmentDoc = gql`
  fragment SchoolConfigurationLayoutItem on SchoolConfigurationLayout {
    id
    name
    icon
    isActive
    navbarOrderNumber
    sidebarOrderNumber
  }
`;
export const BusinessSearchDocument = gql`
  query BusinessSearch(
    $filter: OrganizationFilter
    $sort: [OrganizationSort!]
    $first: Int
    $skip: Int
  ) {
    organizationsList(filter: $filter, sort: $sort, first: $first, skip: $skip) {
      items {
        ...OrganizationItem
      }
    }
  }
  ${OrganizationItemFragmentDoc}
`;

/**
 * __useBusinessSearchQuery__
 *
 * To run a query within a React component, call `useBusinessSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useBusinessSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<BusinessSearchQuery, BusinessSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessSearchQuery, BusinessSearchQueryVariables>(
    BusinessSearchDocument,
    options,
  );
}
export function useBusinessSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessSearchQuery, BusinessSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessSearchQuery, BusinessSearchQueryVariables>(
    BusinessSearchDocument,
    options,
  );
}
export type BusinessSearchQueryHookResult = ReturnType<typeof useBusinessSearchQuery>;
export type BusinessSearchLazyQueryHookResult = ReturnType<typeof useBusinessSearchLazyQuery>;
export type BusinessSearchQueryResult = Apollo.QueryResult<
  BusinessSearchQuery,
  BusinessSearchQueryVariables
>;
export const BusinessesListCountDocument = gql`
  query BusinessesListCount($filter: BusinessReportFilter) {
    businessReportsList(filter: $filter) {
      count
    }
  }
`;

/**
 * __useBusinessesListCountQuery__
 *
 * To run a query within a React component, call `useBusinessesListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessesListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessesListCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBusinessesListCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BusinessesListCountQuery,
    BusinessesListCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessesListCountQuery, BusinessesListCountQueryVariables>(
    BusinessesListCountDocument,
    options,
  );
}
export function useBusinessesListCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessesListCountQuery,
    BusinessesListCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessesListCountQuery, BusinessesListCountQueryVariables>(
    BusinessesListCountDocument,
    options,
  );
}
export type BusinessesListCountQueryHookResult = ReturnType<typeof useBusinessesListCountQuery>;
export type BusinessesListCountLazyQueryHookResult = ReturnType<
  typeof useBusinessesListCountLazyQuery
>;
export type BusinessesListCountQueryResult = Apollo.QueryResult<
  BusinessesListCountQuery,
  BusinessesListCountQueryVariables
>;
export const BusinessEditDocument = gql`
  mutation BusinessEdit($data: OrganizationUpdateInput!) {
    organizationUpdate(data: $data, force: true, destroyDetached: true) {
      ...OrganizationItem
    }
  }
  ${OrganizationItemFragmentDoc}
`;
export type BusinessEditMutationFn = Apollo.MutationFunction<
  BusinessEditMutation,
  BusinessEditMutationVariables
>;

/**
 * __useBusinessEditMutation__
 *
 * To run a mutation, you first call `useBusinessEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusinessEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [businessEditMutation, { data, loading, error }] = useBusinessEditMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBusinessEditMutation(
  baseOptions?: Apollo.MutationHookOptions<BusinessEditMutation, BusinessEditMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BusinessEditMutation, BusinessEditMutationVariables>(
    BusinessEditDocument,
    options,
  );
}
export type BusinessEditMutationHookResult = ReturnType<typeof useBusinessEditMutation>;
export type BusinessEditMutationResult = Apollo.MutationResult<BusinessEditMutation>;
export type BusinessEditMutationOptions = Apollo.BaseMutationOptions<
  BusinessEditMutation,
  BusinessEditMutationVariables
>;
export const RegisteredUsersSpreadsheetCountDocument = gql`
  query RegisteredUsersSpreadsheetCount(
    $first: Int
    $skip: Int
    $filter: UserFilter
    $sort: [UserSort!]
  ) {
    usersList(
      first: $first
      skip: $skip
      filter: $filter
      sort: $sort
      groupBy: {
        query: {
          id: { as: "usersCount", fn: { aggregate: COUNT } }
          graduatingYear: { as: "year" }
        }
      }
    ) {
      groups {
        year: Date
        usersCount: Int
      }
    }
  }
`;

/**
 * __useRegisteredUsersSpreadsheetCountQuery__
 *
 * To run a query within a React component, call `useRegisteredUsersSpreadsheetCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredUsersSpreadsheetCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredUsersSpreadsheetCountQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useRegisteredUsersSpreadsheetCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegisteredUsersSpreadsheetCountQuery,
    RegisteredUsersSpreadsheetCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegisteredUsersSpreadsheetCountQuery,
    RegisteredUsersSpreadsheetCountQueryVariables
  >(RegisteredUsersSpreadsheetCountDocument, options);
}
export function useRegisteredUsersSpreadsheetCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisteredUsersSpreadsheetCountQuery,
    RegisteredUsersSpreadsheetCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegisteredUsersSpreadsheetCountQuery,
    RegisteredUsersSpreadsheetCountQueryVariables
  >(RegisteredUsersSpreadsheetCountDocument, options);
}
export type RegisteredUsersSpreadsheetCountQueryHookResult = ReturnType<
  typeof useRegisteredUsersSpreadsheetCountQuery
>;
export type RegisteredUsersSpreadsheetCountLazyQueryHookResult = ReturnType<
  typeof useRegisteredUsersSpreadsheetCountLazyQuery
>;
export type RegisteredUsersSpreadsheetCountQueryResult = Apollo.QueryResult<
  RegisteredUsersSpreadsheetCountQuery,
  RegisteredUsersSpreadsheetCountQueryVariables
>;
export const FullContentReportsListDocument = gql`
  query FullContentReportsList(
    $first: Int
    $skip: Int
    $sort: [ContentReportSort!]
    $filter: ContentReportFilter
  ) {
    contentReportsList(first: $first, skip: $skip, sort: $sort, filter: $filter) {
      count
      items {
        ...FullContentReportsListItem
      }
    }
  }
  ${FullContentReportsListItemFragmentDoc}
`;

/**
 * __useFullContentReportsListQuery__
 *
 * To run a query within a React component, call `useFullContentReportsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullContentReportsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullContentReportsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFullContentReportsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FullContentReportsListQuery,
    FullContentReportsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FullContentReportsListQuery, FullContentReportsListQueryVariables>(
    FullContentReportsListDocument,
    options,
  );
}
export function useFullContentReportsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FullContentReportsListQuery,
    FullContentReportsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FullContentReportsListQuery, FullContentReportsListQueryVariables>(
    FullContentReportsListDocument,
    options,
  );
}
export type FullContentReportsListQueryHookResult = ReturnType<
  typeof useFullContentReportsListQuery
>;
export type FullContentReportsListLazyQueryHookResult = ReturnType<
  typeof useFullContentReportsListLazyQuery
>;
export type FullContentReportsListQueryResult = Apollo.QueryResult<
  FullContentReportsListQuery,
  FullContentReportsListQueryVariables
>;
export const ContentCurationFlaggedGroupPostsListDocument = gql`
  query ContentCurationFlaggedGroupPostsList(
    $filter: GroupPostFilter
    $sort: [GroupPostSort!]
    $first: Int
    $skip: Int
  ) {
    reportedGroupPostsList: groupPostsList(
      filter: $filter
      skip: $skip
      sort: $sort
      first: $first
    ) {
      items {
        ...GroupPostsListItem
      }
    }
  }
  ${GroupPostsListItemFragmentDoc}
`;

/**
 * __useContentCurationFlaggedGroupPostsListQuery__
 *
 * To run a query within a React component, call `useContentCurationFlaggedGroupPostsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentCurationFlaggedGroupPostsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentCurationFlaggedGroupPostsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useContentCurationFlaggedGroupPostsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ContentCurationFlaggedGroupPostsListQuery,
    ContentCurationFlaggedGroupPostsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ContentCurationFlaggedGroupPostsListQuery,
    ContentCurationFlaggedGroupPostsListQueryVariables
  >(ContentCurationFlaggedGroupPostsListDocument, options);
}
export function useContentCurationFlaggedGroupPostsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContentCurationFlaggedGroupPostsListQuery,
    ContentCurationFlaggedGroupPostsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContentCurationFlaggedGroupPostsListQuery,
    ContentCurationFlaggedGroupPostsListQueryVariables
  >(ContentCurationFlaggedGroupPostsListDocument, options);
}
export type ContentCurationFlaggedGroupPostsListQueryHookResult = ReturnType<
  typeof useContentCurationFlaggedGroupPostsListQuery
>;
export type ContentCurationFlaggedGroupPostsListLazyQueryHookResult = ReturnType<
  typeof useContentCurationFlaggedGroupPostsListLazyQuery
>;
export type ContentCurationFlaggedGroupPostsListQueryResult = Apollo.QueryResult<
  ContentCurationFlaggedGroupPostsListQuery,
  ContentCurationFlaggedGroupPostsListQueryVariables
>;
export const GroupPostDeleteDocument = gql`
  mutation GroupPostDelete($data: GroupPostDeleteInput) {
    groupPostDelete(data: $data) {
      success
    }
  }
`;
export type GroupPostDeleteMutationFn = Apollo.MutationFunction<
  GroupPostDeleteMutation,
  GroupPostDeleteMutationVariables
>;

/**
 * __useGroupPostDeleteMutation__
 *
 * To run a mutation, you first call `useGroupPostDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupPostDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupPostDeleteMutation, { data, loading, error }] = useGroupPostDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGroupPostDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupPostDeleteMutation,
    GroupPostDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupPostDeleteMutation, GroupPostDeleteMutationVariables>(
    GroupPostDeleteDocument,
    options,
  );
}
export type GroupPostDeleteMutationHookResult = ReturnType<typeof useGroupPostDeleteMutation>;
export type GroupPostDeleteMutationResult = Apollo.MutationResult<GroupPostDeleteMutation>;
export type GroupPostDeleteMutationOptions = Apollo.BaseMutationOptions<
  GroupPostDeleteMutation,
  GroupPostDeleteMutationVariables
>;
export const ContentCurationPostDeleteDocument = gql`
  mutation ContentCurationPostDelete($data: FlaggedPostDeleteRequestInput!) {
    flaggedPostDeleteRequest(data: $data) {
      success
    }
  }
`;
export type ContentCurationPostDeleteMutationFn = Apollo.MutationFunction<
  ContentCurationPostDeleteMutation,
  ContentCurationPostDeleteMutationVariables
>;

/**
 * __useContentCurationPostDeleteMutation__
 *
 * To run a mutation, you first call `useContentCurationPostDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentCurationPostDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentCurationPostDeleteMutation, { data, loading, error }] = useContentCurationPostDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useContentCurationPostDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContentCurationPostDeleteMutation,
    ContentCurationPostDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContentCurationPostDeleteMutation,
    ContentCurationPostDeleteMutationVariables
  >(ContentCurationPostDeleteDocument, options);
}
export type ContentCurationPostDeleteMutationHookResult = ReturnType<
  typeof useContentCurationPostDeleteMutation
>;
export type ContentCurationPostDeleteMutationResult =
  Apollo.MutationResult<ContentCurationPostDeleteMutation>;
export type ContentCurationPostDeleteMutationOptions = Apollo.BaseMutationOptions<
  ContentCurationPostDeleteMutation,
  ContentCurationPostDeleteMutationVariables
>;
export const ContentCurationGroupPostStatusUpdateDocument = gql`
  mutation ContentCurationGroupPostStatusUpdate($data: GroupPostUpdateInput!) {
    groupPostUpdate(data: $data) {
      id
      status
      reportStatus
    }
  }
`;
export type ContentCurationGroupPostStatusUpdateMutationFn = Apollo.MutationFunction<
  ContentCurationGroupPostStatusUpdateMutation,
  ContentCurationGroupPostStatusUpdateMutationVariables
>;

/**
 * __useContentCurationGroupPostStatusUpdateMutation__
 *
 * To run a mutation, you first call `useContentCurationGroupPostStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentCurationGroupPostStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentCurationGroupPostStatusUpdateMutation, { data, loading, error }] = useContentCurationGroupPostStatusUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useContentCurationGroupPostStatusUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContentCurationGroupPostStatusUpdateMutation,
    ContentCurationGroupPostStatusUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContentCurationGroupPostStatusUpdateMutation,
    ContentCurationGroupPostStatusUpdateMutationVariables
  >(ContentCurationGroupPostStatusUpdateDocument, options);
}
export type ContentCurationGroupPostStatusUpdateMutationHookResult = ReturnType<
  typeof useContentCurationGroupPostStatusUpdateMutation
>;
export type ContentCurationGroupPostStatusUpdateMutationResult =
  Apollo.MutationResult<ContentCurationGroupPostStatusUpdateMutation>;
export type ContentCurationGroupPostStatusUpdateMutationOptions = Apollo.BaseMutationOptions<
  ContentCurationGroupPostStatusUpdateMutation,
  ContentCurationGroupPostStatusUpdateMutationVariables
>;
export const ContentCurationHomeFeedPostStatusUpdateDocument = gql`
  mutation ContentCurationHomeFeedPostStatusUpdate($data: HomeFeedPostUpdateInput!, $id: ID!) {
    homeFeedPostUpdate(data: $data, filter: { id: $id }) {
      id
      status
      reportStatus
      text
      media {
        items {
          id
          downloadUrl
          meta
        }
      }
    }
  }
`;
export type ContentCurationHomeFeedPostStatusUpdateMutationFn = Apollo.MutationFunction<
  ContentCurationHomeFeedPostStatusUpdateMutation,
  ContentCurationHomeFeedPostStatusUpdateMutationVariables
>;

/**
 * __useContentCurationHomeFeedPostStatusUpdateMutation__
 *
 * To run a mutation, you first call `useContentCurationHomeFeedPostStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentCurationHomeFeedPostStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentCurationHomeFeedPostStatusUpdateMutation, { data, loading, error }] = useContentCurationHomeFeedPostStatusUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContentCurationHomeFeedPostStatusUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContentCurationHomeFeedPostStatusUpdateMutation,
    ContentCurationHomeFeedPostStatusUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContentCurationHomeFeedPostStatusUpdateMutation,
    ContentCurationHomeFeedPostStatusUpdateMutationVariables
  >(ContentCurationHomeFeedPostStatusUpdateDocument, options);
}
export type ContentCurationHomeFeedPostStatusUpdateMutationHookResult = ReturnType<
  typeof useContentCurationHomeFeedPostStatusUpdateMutation
>;
export type ContentCurationHomeFeedPostStatusUpdateMutationResult =
  Apollo.MutationResult<ContentCurationHomeFeedPostStatusUpdateMutation>;
export type ContentCurationHomeFeedPostStatusUpdateMutationOptions = Apollo.BaseMutationOptions<
  ContentCurationHomeFeedPostStatusUpdateMutation,
  ContentCurationHomeFeedPostStatusUpdateMutationVariables
>;
export const ContentCurationSchoolNewsPostStatusUpdateDocument = gql`
  mutation ContentCurationSchoolNewsPostStatusUpdate($data: SchoolNewsPostUpdateInput!, $id: ID!) {
    schoolNewsPostUpdate(data: $data, filter: { id: $id }) {
      id
      reportStatus
      text
      media {
        items {
          id
          downloadUrl
          meta
        }
      }
    }
  }
`;
export type ContentCurationSchoolNewsPostStatusUpdateMutationFn = Apollo.MutationFunction<
  ContentCurationSchoolNewsPostStatusUpdateMutation,
  ContentCurationSchoolNewsPostStatusUpdateMutationVariables
>;

/**
 * __useContentCurationSchoolNewsPostStatusUpdateMutation__
 *
 * To run a mutation, you first call `useContentCurationSchoolNewsPostStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentCurationSchoolNewsPostStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentCurationSchoolNewsPostStatusUpdateMutation, { data, loading, error }] = useContentCurationSchoolNewsPostStatusUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContentCurationSchoolNewsPostStatusUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContentCurationSchoolNewsPostStatusUpdateMutation,
    ContentCurationSchoolNewsPostStatusUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContentCurationSchoolNewsPostStatusUpdateMutation,
    ContentCurationSchoolNewsPostStatusUpdateMutationVariables
  >(ContentCurationSchoolNewsPostStatusUpdateDocument, options);
}
export type ContentCurationSchoolNewsPostStatusUpdateMutationHookResult = ReturnType<
  typeof useContentCurationSchoolNewsPostStatusUpdateMutation
>;
export type ContentCurationSchoolNewsPostStatusUpdateMutationResult =
  Apollo.MutationResult<ContentCurationSchoolNewsPostStatusUpdateMutation>;
export type ContentCurationSchoolNewsPostStatusUpdateMutationOptions = Apollo.BaseMutationOptions<
  ContentCurationSchoolNewsPostStatusUpdateMutation,
  ContentCurationSchoolNewsPostStatusUpdateMutationVariables
>;
export const ContentCurationLegacyFeedPostStatusUpdateDocument = gql`
  mutation ContentCurationLegacyFeedPostStatusUpdate($data: LegacyFeedPostUpdateInput!) {
    legacyFeedPostUpdate(data: $data) {
      id
      reportStatus
      text
      media {
        items {
          id
          downloadUrl
          meta
        }
      }
    }
  }
`;
export type ContentCurationLegacyFeedPostStatusUpdateMutationFn = Apollo.MutationFunction<
  ContentCurationLegacyFeedPostStatusUpdateMutation,
  ContentCurationLegacyFeedPostStatusUpdateMutationVariables
>;

/**
 * __useContentCurationLegacyFeedPostStatusUpdateMutation__
 *
 * To run a mutation, you first call `useContentCurationLegacyFeedPostStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentCurationLegacyFeedPostStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentCurationLegacyFeedPostStatusUpdateMutation, { data, loading, error }] = useContentCurationLegacyFeedPostStatusUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useContentCurationLegacyFeedPostStatusUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContentCurationLegacyFeedPostStatusUpdateMutation,
    ContentCurationLegacyFeedPostStatusUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContentCurationLegacyFeedPostStatusUpdateMutation,
    ContentCurationLegacyFeedPostStatusUpdateMutationVariables
  >(ContentCurationLegacyFeedPostStatusUpdateDocument, options);
}
export type ContentCurationLegacyFeedPostStatusUpdateMutationHookResult = ReturnType<
  typeof useContentCurationLegacyFeedPostStatusUpdateMutation
>;
export type ContentCurationLegacyFeedPostStatusUpdateMutationResult =
  Apollo.MutationResult<ContentCurationLegacyFeedPostStatusUpdateMutation>;
export type ContentCurationLegacyFeedPostStatusUpdateMutationOptions = Apollo.BaseMutationOptions<
  ContentCurationLegacyFeedPostStatusUpdateMutation,
  ContentCurationLegacyFeedPostStatusUpdateMutationVariables
>;
export const GroupPostsListItemDocument = gql`
  query GroupPostsListItem($filter: GroupPostFilter) {
    groupPostsList(filter: $filter) {
      items {
        ...GroupPostsListItem
        flaggedBy(sort: { updatedAt: DESC }) {
          items {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
  ${GroupPostsListItemFragmentDoc}
`;

/**
 * __useGroupPostsListItemQuery__
 *
 * To run a query within a React component, call `useGroupPostsListItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupPostsListItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupPostsListItemQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGroupPostsListItemQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupPostsListItemQuery, GroupPostsListItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupPostsListItemQuery, GroupPostsListItemQueryVariables>(
    GroupPostsListItemDocument,
    options,
  );
}
export function useGroupPostsListItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupPostsListItemQuery,
    GroupPostsListItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupPostsListItemQuery, GroupPostsListItemQueryVariables>(
    GroupPostsListItemDocument,
    options,
  );
}
export type GroupPostsListItemQueryHookResult = ReturnType<typeof useGroupPostsListItemQuery>;
export type GroupPostsListItemLazyQueryHookResult = ReturnType<
  typeof useGroupPostsListItemLazyQuery
>;
export type GroupPostsListItemQueryResult = Apollo.QueryResult<
  GroupPostsListItemQuery,
  GroupPostsListItemQueryVariables
>;
export const HomeFeedPostsListItemDocument = gql`
  query HomeFeedPostsListItem($filter: HomeFeedPostFilter) {
    homeFeedPostsList(filter: $filter) {
      items {
        ...HomeFeedPostsListItem
        flaggedBy(sort: { updatedAt: DESC }) {
          items {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
  ${HomeFeedPostsListItemFragmentDoc}
`;

/**
 * __useHomeFeedPostsListItemQuery__
 *
 * To run a query within a React component, call `useHomeFeedPostsListItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeFeedPostsListItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeFeedPostsListItemQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHomeFeedPostsListItemQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeFeedPostsListItemQuery,
    HomeFeedPostsListItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeFeedPostsListItemQuery, HomeFeedPostsListItemQueryVariables>(
    HomeFeedPostsListItemDocument,
    options,
  );
}
export function useHomeFeedPostsListItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeFeedPostsListItemQuery,
    HomeFeedPostsListItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeFeedPostsListItemQuery, HomeFeedPostsListItemQueryVariables>(
    HomeFeedPostsListItemDocument,
    options,
  );
}
export type HomeFeedPostsListItemQueryHookResult = ReturnType<typeof useHomeFeedPostsListItemQuery>;
export type HomeFeedPostsListItemLazyQueryHookResult = ReturnType<
  typeof useHomeFeedPostsListItemLazyQuery
>;
export type HomeFeedPostsListItemQueryResult = Apollo.QueryResult<
  HomeFeedPostsListItemQuery,
  HomeFeedPostsListItemQueryVariables
>;
export const SchoolNewsPostsListItemDocument = gql`
  query SchoolNewsPostsListItem($filter: SchoolNewsPostFilter) {
    schoolNewsPostsList(filter: $filter) {
      items {
        ...SchoolNewsPostsListItem
        flaggedBy(sort: { updatedAt: DESC }) {
          items {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
  ${SchoolNewsPostsListItemFragmentDoc}
`;

/**
 * __useSchoolNewsPostsListItemQuery__
 *
 * To run a query within a React component, call `useSchoolNewsPostsListItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolNewsPostsListItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolNewsPostsListItemQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSchoolNewsPostsListItemQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SchoolNewsPostsListItemQuery,
    SchoolNewsPostsListItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchoolNewsPostsListItemQuery, SchoolNewsPostsListItemQueryVariables>(
    SchoolNewsPostsListItemDocument,
    options,
  );
}
export function useSchoolNewsPostsListItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolNewsPostsListItemQuery,
    SchoolNewsPostsListItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchoolNewsPostsListItemQuery, SchoolNewsPostsListItemQueryVariables>(
    SchoolNewsPostsListItemDocument,
    options,
  );
}
export type SchoolNewsPostsListItemQueryHookResult = ReturnType<
  typeof useSchoolNewsPostsListItemQuery
>;
export type SchoolNewsPostsListItemLazyQueryHookResult = ReturnType<
  typeof useSchoolNewsPostsListItemLazyQuery
>;
export type SchoolNewsPostsListItemQueryResult = Apollo.QueryResult<
  SchoolNewsPostsListItemQuery,
  SchoolNewsPostsListItemQueryVariables
>;
export const LegacyFeedPostsListItemDocument = gql`
  query LegacyFeedPostsListItem($filter: LegacyFeedPostFilter) {
    legacyFeedPostsList(filter: $filter) {
      items {
        ...LegacyFeedPostsListItem
        flaggedBy(sort: { updatedAt: DESC }) {
          items {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
  ${LegacyFeedPostsListItemFragmentDoc}
`;

/**
 * __useLegacyFeedPostsListItemQuery__
 *
 * To run a query within a React component, call `useLegacyFeedPostsListItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyFeedPostsListItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyFeedPostsListItemQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLegacyFeedPostsListItemQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LegacyFeedPostsListItemQuery,
    LegacyFeedPostsListItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegacyFeedPostsListItemQuery, LegacyFeedPostsListItemQueryVariables>(
    LegacyFeedPostsListItemDocument,
    options,
  );
}
export function useLegacyFeedPostsListItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegacyFeedPostsListItemQuery,
    LegacyFeedPostsListItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegacyFeedPostsListItemQuery, LegacyFeedPostsListItemQueryVariables>(
    LegacyFeedPostsListItemDocument,
    options,
  );
}
export type LegacyFeedPostsListItemQueryHookResult = ReturnType<
  typeof useLegacyFeedPostsListItemQuery
>;
export type LegacyFeedPostsListItemLazyQueryHookResult = ReturnType<
  typeof useLegacyFeedPostsListItemLazyQuery
>;
export type LegacyFeedPostsListItemQueryResult = Apollo.QueryResult<
  LegacyFeedPostsListItemQuery,
  LegacyFeedPostsListItemQueryVariables
>;
export const FlaggedPostApproveDocument = gql`
  mutation FlaggedPostApprove($data: FlaggedPostApproveRequestInput!) {
    flaggedPostApproveRequest(data: $data) {
      success
    }
  }
`;
export type FlaggedPostApproveMutationFn = Apollo.MutationFunction<
  FlaggedPostApproveMutation,
  FlaggedPostApproveMutationVariables
>;

/**
 * __useFlaggedPostApproveMutation__
 *
 * To run a mutation, you first call `useFlaggedPostApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlaggedPostApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flaggedPostApproveMutation, { data, loading, error }] = useFlaggedPostApproveMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFlaggedPostApproveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FlaggedPostApproveMutation,
    FlaggedPostApproveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FlaggedPostApproveMutation, FlaggedPostApproveMutationVariables>(
    FlaggedPostApproveDocument,
    options,
  );
}
export type FlaggedPostApproveMutationHookResult = ReturnType<typeof useFlaggedPostApproveMutation>;
export type FlaggedPostApproveMutationResult = Apollo.MutationResult<FlaggedPostApproveMutation>;
export type FlaggedPostApproveMutationOptions = Apollo.BaseMutationOptions<
  FlaggedPostApproveMutation,
  FlaggedPostApproveMutationVariables
>;
export const PostsCountDocument = gql`
  query PostsCount($jobOffersFilter: JobOfferFilter, $eventsFilter: EventFilter) {
    jobPostsCount: jobOffersList(filter: $jobOffersFilter) {
      items {
        id
        createdAt
        engagementCount
      }
      count
    }
    eventPostsCount: eventsList(filter: $eventsFilter) {
      items {
        id
        createdAt
      }
      count
    }
  }
`;

/**
 * __usePostsCountQuery__
 *
 * To run a query within a React component, call `usePostsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsCountQuery({
 *   variables: {
 *      jobOffersFilter: // value for 'jobOffersFilter'
 *      eventsFilter: // value for 'eventsFilter'
 *   },
 * });
 */
export function usePostsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<PostsCountQuery, PostsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PostsCountQuery, PostsCountQueryVariables>(PostsCountDocument, options);
}
export function usePostsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PostsCountQuery, PostsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PostsCountQuery, PostsCountQueryVariables>(
    PostsCountDocument,
    options,
  );
}
export type PostsCountQueryHookResult = ReturnType<typeof usePostsCountQuery>;
export type PostsCountLazyQueryHookResult = ReturnType<typeof usePostsCountLazyQuery>;
export type PostsCountQueryResult = Apollo.QueryResult<PostsCountQuery, PostsCountQueryVariables>;
export const SchoolAdministrationBusinessCountDocument = gql`
  query SchoolAdministrationBusinessCount(
    $filter: OrganizationFilter
    $startPointZip: String!
    $radius: String!
    $supporterFilter: OrganizationFilter
  ) {
    businesses: businessFilterByZipRequest(
      filter: $filter
      startPointZip: $startPointZip
      radius: $radius
    ) {
      count
    }
    supporters: businessFilterByZipRequest(
      filter: $supporterFilter
      startPointZip: $startPointZip
      radius: $radius
    ) {
      count
    }
  }
`;

/**
 * __useSchoolAdministrationBusinessCountQuery__
 *
 * To run a query within a React component, call `useSchoolAdministrationBusinessCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAdministrationBusinessCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAdministrationBusinessCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      startPointZip: // value for 'startPointZip'
 *      radius: // value for 'radius'
 *      supporterFilter: // value for 'supporterFilter'
 *   },
 * });
 */
export function useSchoolAdministrationBusinessCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    SchoolAdministrationBusinessCountQuery,
    SchoolAdministrationBusinessCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SchoolAdministrationBusinessCountQuery,
    SchoolAdministrationBusinessCountQueryVariables
  >(SchoolAdministrationBusinessCountDocument, options);
}
export function useSchoolAdministrationBusinessCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolAdministrationBusinessCountQuery,
    SchoolAdministrationBusinessCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SchoolAdministrationBusinessCountQuery,
    SchoolAdministrationBusinessCountQueryVariables
  >(SchoolAdministrationBusinessCountDocument, options);
}
export type SchoolAdministrationBusinessCountQueryHookResult = ReturnType<
  typeof useSchoolAdministrationBusinessCountQuery
>;
export type SchoolAdministrationBusinessCountLazyQueryHookResult = ReturnType<
  typeof useSchoolAdministrationBusinessCountLazyQuery
>;
export type SchoolAdministrationBusinessCountQueryResult = Apollo.QueryResult<
  SchoolAdministrationBusinessCountQuery,
  SchoolAdministrationBusinessCountQueryVariables
>;
export const SpecialOffersCountDocument = gql`
  query SpecialOffersCount(
    $activeDiscountFilter: DiscountFilter = {}
    $redemptionFilter: DiscountRedemptionFilter
  ) {
    activeOffers: discountsList(
      filter: { AND: [$activeDiscountFilter, { status: { in: ["active", "expired"] } }] }
    ) {
      count
    }
    redemptions: discountRedemptionsList(filter: $redemptionFilter) {
      count
    }
  }
`;

/**
 * __useSpecialOffersCountQuery__
 *
 * To run a query within a React component, call `useSpecialOffersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialOffersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialOffersCountQuery({
 *   variables: {
 *      activeDiscountFilter: // value for 'activeDiscountFilter'
 *      redemptionFilter: // value for 'redemptionFilter'
 *   },
 * });
 */
export function useSpecialOffersCountQuery(
  baseOptions?: Apollo.QueryHookOptions<SpecialOffersCountQuery, SpecialOffersCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpecialOffersCountQuery, SpecialOffersCountQueryVariables>(
    SpecialOffersCountDocument,
    options,
  );
}
export function useSpecialOffersCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpecialOffersCountQuery,
    SpecialOffersCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpecialOffersCountQuery, SpecialOffersCountQueryVariables>(
    SpecialOffersCountDocument,
    options,
  );
}
export type SpecialOffersCountQueryHookResult = ReturnType<typeof useSpecialOffersCountQuery>;
export type SpecialOffersCountLazyQueryHookResult = ReturnType<
  typeof useSpecialOffersCountLazyQuery
>;
export type SpecialOffersCountQueryResult = Apollo.QueryResult<
  SpecialOffersCountQuery,
  SpecialOffersCountQueryVariables
>;
export const MembersCountByAffiliationDocument = gql`
  query MembersCountByAffiliation($filter: UserFilter = {}) {
    alumni: usersList(filter: { AND: [$filter, { affiliation: { equals: "alumni" } }] }) {
      count
    }
    student: usersList(filter: { AND: [$filter, { affiliation: { equals: "student" } }] }) {
      count
    }
    staff: usersList(filter: { AND: [$filter, { affiliation: { equals: "staff" } }] }) {
      count
    }
    parent: usersList(filter: { AND: [$filter, { affiliation: { equals: "parent" } }] }) {
      count
    }
    other: usersList(
      filter: {
        AND: [
          $filter
          { OR: [{ affiliation: { equals: "other" } }, { affiliation: { is_empty: true } }] }
        ]
      }
    ) {
      count
    }
  }
`;

/**
 * __useMembersCountByAffiliationQuery__
 *
 * To run a query within a React component, call `useMembersCountByAffiliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersCountByAffiliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersCountByAffiliationQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMembersCountByAffiliationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MembersCountByAffiliationQuery,
    MembersCountByAffiliationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MembersCountByAffiliationQuery, MembersCountByAffiliationQueryVariables>(
    MembersCountByAffiliationDocument,
    options,
  );
}
export function useMembersCountByAffiliationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MembersCountByAffiliationQuery,
    MembersCountByAffiliationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MembersCountByAffiliationQuery,
    MembersCountByAffiliationQueryVariables
  >(MembersCountByAffiliationDocument, options);
}
export type MembersCountByAffiliationQueryHookResult = ReturnType<
  typeof useMembersCountByAffiliationQuery
>;
export type MembersCountByAffiliationLazyQueryHookResult = ReturnType<
  typeof useMembersCountByAffiliationLazyQuery
>;
export type MembersCountByAffiliationQueryResult = Apollo.QueryResult<
  MembersCountByAffiliationQuery,
  MembersCountByAffiliationQueryVariables
>;
export const DashboardsMembersListDocument = gql`
  query DashboardsMembersList($filter: DashboardFilter) {
    dashboardsList(filter: $filter, sort: { createdAt: ASC }) {
      items {
        id
        createdAt
        totalMembers
      }
    }
  }
`;

/**
 * __useDashboardsMembersListQuery__
 *
 * To run a query within a React component, call `useDashboardsMembersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardsMembersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardsMembersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDashboardsMembersListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardsMembersListQuery,
    DashboardsMembersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardsMembersListQuery, DashboardsMembersListQueryVariables>(
    DashboardsMembersListDocument,
    options,
  );
}
export function useDashboardsMembersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardsMembersListQuery,
    DashboardsMembersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardsMembersListQuery, DashboardsMembersListQueryVariables>(
    DashboardsMembersListDocument,
    options,
  );
}
export type DashboardsMembersListQueryHookResult = ReturnType<typeof useDashboardsMembersListQuery>;
export type DashboardsMembersListLazyQueryHookResult = ReturnType<
  typeof useDashboardsMembersListLazyQuery
>;
export type DashboardsMembersListQueryResult = Apollo.QueryResult<
  DashboardsMembersListQuery,
  DashboardsMembersListQueryVariables
>;
export const DashboardsAdvertisementsListDocument = gql`
  query DashboardsAdvertisementsList($filter: DashboardFilter) {
    dashboardsList(filter: $filter, sort: { createdAt: ASC }) {
      items {
        id
        createdAt
        totalAdvertisements
        totalAdRevenue
        totalSupporters
        totalDiscounts
        totalAllRevenue
        totalDiscountsRevenue
        totalSupportersRevenue
        totalVolume
      }
    }
  }
`;

/**
 * __useDashboardsAdvertisementsListQuery__
 *
 * To run a query within a React component, call `useDashboardsAdvertisementsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardsAdvertisementsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardsAdvertisementsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDashboardsAdvertisementsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardsAdvertisementsListQuery,
    DashboardsAdvertisementsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardsAdvertisementsListQuery,
    DashboardsAdvertisementsListQueryVariables
  >(DashboardsAdvertisementsListDocument, options);
}
export function useDashboardsAdvertisementsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardsAdvertisementsListQuery,
    DashboardsAdvertisementsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardsAdvertisementsListQuery,
    DashboardsAdvertisementsListQueryVariables
  >(DashboardsAdvertisementsListDocument, options);
}
export type DashboardsAdvertisementsListQueryHookResult = ReturnType<
  typeof useDashboardsAdvertisementsListQuery
>;
export type DashboardsAdvertisementsListLazyQueryHookResult = ReturnType<
  typeof useDashboardsAdvertisementsListLazyQuery
>;
export type DashboardsAdvertisementsListQueryResult = Apollo.QueryResult<
  DashboardsAdvertisementsListQuery,
  DashboardsAdvertisementsListQueryVariables
>;
export const DiscountsStatsDocument = gql`
  query DiscountsStats($school: ID!) {
    activeDiscounts: discountsList(
      filter: { schools: { some: { id: { equals: $school } } }, status: { equals: "active" } }
    ) {
      count
    }
    totalDiscounts: discountRedemptionsList(
      filter: {
        school: { id: { equals: $school } }
        createdAt: { relative: { gte: { op: SUB, unit: DAY, value: "30" } } }
      }
      groupBy: {
        query: {
          id: { as: "count", fn: { aggregate: COUNT } }
          discount: { id: { as: "id" } }
          createdAt: { as: "date", fn: { datePart: DATE } }
        }
      }
    ) {
      items: groups {
        id: ID
        count: Int
      }
    }
  }
`;

/**
 * __useDiscountsStatsQuery__
 *
 * To run a query within a React component, call `useDiscountsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountsStatsQuery({
 *   variables: {
 *      school: // value for 'school'
 *   },
 * });
 */
export function useDiscountsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<DiscountsStatsQuery, DiscountsStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DiscountsStatsQuery, DiscountsStatsQueryVariables>(
    DiscountsStatsDocument,
    options,
  );
}
export function useDiscountsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DiscountsStatsQuery, DiscountsStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DiscountsStatsQuery, DiscountsStatsQueryVariables>(
    DiscountsStatsDocument,
    options,
  );
}
export type DiscountsStatsQueryHookResult = ReturnType<typeof useDiscountsStatsQuery>;
export type DiscountsStatsLazyQueryHookResult = ReturnType<typeof useDiscountsStatsLazyQuery>;
export type DiscountsStatsQueryResult = Apollo.QueryResult<
  DiscountsStatsQuery,
  DiscountsStatsQueryVariables
>;
export const AdminDiscountsSpreadsheetListDocument = gql`
  query AdminDiscountsSpreadsheetList(
    $first: Int
    $skip: Int
    $filter: DiscountFilter
    $sort: [DiscountSort!]
  ) {
    discounts: discountsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        ...AdminDiscount
      }
    }
  }
  ${AdminDiscountFragmentDoc}
`;

/**
 * __useAdminDiscountsSpreadsheetListQuery__
 *
 * To run a query within a React component, call `useAdminDiscountsSpreadsheetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDiscountsSpreadsheetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDiscountsSpreadsheetListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdminDiscountsSpreadsheetListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminDiscountsSpreadsheetListQuery,
    AdminDiscountsSpreadsheetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminDiscountsSpreadsheetListQuery,
    AdminDiscountsSpreadsheetListQueryVariables
  >(AdminDiscountsSpreadsheetListDocument, options);
}
export function useAdminDiscountsSpreadsheetListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminDiscountsSpreadsheetListQuery,
    AdminDiscountsSpreadsheetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminDiscountsSpreadsheetListQuery,
    AdminDiscountsSpreadsheetListQueryVariables
  >(AdminDiscountsSpreadsheetListDocument, options);
}
export type AdminDiscountsSpreadsheetListQueryHookResult = ReturnType<
  typeof useAdminDiscountsSpreadsheetListQuery
>;
export type AdminDiscountsSpreadsheetListLazyQueryHookResult = ReturnType<
  typeof useAdminDiscountsSpreadsheetListLazyQuery
>;
export type AdminDiscountsSpreadsheetListQueryResult = Apollo.QueryResult<
  AdminDiscountsSpreadsheetListQuery,
  AdminDiscountsSpreadsheetListQueryVariables
>;
export const AdminDiscountsSpreadsheetListCountDocument = gql`
  query AdminDiscountsSpreadsheetListCount($filter: DiscountFilter) {
    discountsList(filter: $filter) {
      count
    }
  }
`;

/**
 * __useAdminDiscountsSpreadsheetListCountQuery__
 *
 * To run a query within a React component, call `useAdminDiscountsSpreadsheetListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDiscountsSpreadsheetListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDiscountsSpreadsheetListCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminDiscountsSpreadsheetListCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminDiscountsSpreadsheetListCountQuery,
    AdminDiscountsSpreadsheetListCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminDiscountsSpreadsheetListCountQuery,
    AdminDiscountsSpreadsheetListCountQueryVariables
  >(AdminDiscountsSpreadsheetListCountDocument, options);
}
export function useAdminDiscountsSpreadsheetListCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminDiscountsSpreadsheetListCountQuery,
    AdminDiscountsSpreadsheetListCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminDiscountsSpreadsheetListCountQuery,
    AdminDiscountsSpreadsheetListCountQueryVariables
  >(AdminDiscountsSpreadsheetListCountDocument, options);
}
export type AdminDiscountsSpreadsheetListCountQueryHookResult = ReturnType<
  typeof useAdminDiscountsSpreadsheetListCountQuery
>;
export type AdminDiscountsSpreadsheetListCountLazyQueryHookResult = ReturnType<
  typeof useAdminDiscountsSpreadsheetListCountLazyQuery
>;
export type AdminDiscountsSpreadsheetListCountQueryResult = Apollo.QueryResult<
  AdminDiscountsSpreadsheetListCountQuery,
  AdminDiscountsSpreadsheetListCountQueryVariables
>;
export const DiscountCreateRequestDocument = gql`
  mutation DiscountCreateRequest($data: DiscountCreateRequestInput!) {
    discountCreateRequest(data: $data) {
      id
    }
  }
`;
export type DiscountCreateRequestMutationFn = Apollo.MutationFunction<
  DiscountCreateRequestMutation,
  DiscountCreateRequestMutationVariables
>;

/**
 * __useDiscountCreateRequestMutation__
 *
 * To run a mutation, you first call `useDiscountCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscountCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discountCreateRequestMutation, { data, loading, error }] = useDiscountCreateRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDiscountCreateRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DiscountCreateRequestMutation,
    DiscountCreateRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DiscountCreateRequestMutation, DiscountCreateRequestMutationVariables>(
    DiscountCreateRequestDocument,
    options,
  );
}
export type DiscountCreateRequestMutationHookResult = ReturnType<
  typeof useDiscountCreateRequestMutation
>;
export type DiscountCreateRequestMutationResult =
  Apollo.MutationResult<DiscountCreateRequestMutation>;
export type DiscountCreateRequestMutationOptions = Apollo.BaseMutationOptions<
  DiscountCreateRequestMutation,
  DiscountCreateRequestMutationVariables
>;
export const AdminUpdateDiscountDocument = gql`
  mutation AdminUpdateDiscount($data: DiscountUpdateInput!) {
    discountUpdate(data: $data) {
      ...AdminDiscount
    }
  }
  ${AdminDiscountFragmentDoc}
`;
export type AdminUpdateDiscountMutationFn = Apollo.MutationFunction<
  AdminUpdateDiscountMutation,
  AdminUpdateDiscountMutationVariables
>;

/**
 * __useAdminUpdateDiscountMutation__
 *
 * To run a mutation, you first call `useAdminUpdateDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateDiscountMutation, { data, loading, error }] = useAdminUpdateDiscountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateDiscountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateDiscountMutation,
    AdminUpdateDiscountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateDiscountMutation, AdminUpdateDiscountMutationVariables>(
    AdminUpdateDiscountDocument,
    options,
  );
}
export type AdminUpdateDiscountMutationHookResult = ReturnType<
  typeof useAdminUpdateDiscountMutation
>;
export type AdminUpdateDiscountMutationResult = Apollo.MutationResult<AdminUpdateDiscountMutation>;
export type AdminUpdateDiscountMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateDiscountMutation,
  AdminUpdateDiscountMutationVariables
>;
export const DiscountUpdateStatusRequestDocument = gql`
  mutation DiscountUpdateStatusRequest($data: DiscountUpdateStatusRequestInput!) {
    discountUpdateStatusRequest(data: $data) {
      success
    }
  }
`;
export type DiscountUpdateStatusRequestMutationFn = Apollo.MutationFunction<
  DiscountUpdateStatusRequestMutation,
  DiscountUpdateStatusRequestMutationVariables
>;

/**
 * __useDiscountUpdateStatusRequestMutation__
 *
 * To run a mutation, you first call `useDiscountUpdateStatusRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscountUpdateStatusRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discountUpdateStatusRequestMutation, { data, loading, error }] = useDiscountUpdateStatusRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDiscountUpdateStatusRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DiscountUpdateStatusRequestMutation,
    DiscountUpdateStatusRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DiscountUpdateStatusRequestMutation,
    DiscountUpdateStatusRequestMutationVariables
  >(DiscountUpdateStatusRequestDocument, options);
}
export type DiscountUpdateStatusRequestMutationHookResult = ReturnType<
  typeof useDiscountUpdateStatusRequestMutation
>;
export type DiscountUpdateStatusRequestMutationResult =
  Apollo.MutationResult<DiscountUpdateStatusRequestMutation>;
export type DiscountUpdateStatusRequestMutationOptions = Apollo.BaseMutationOptions<
  DiscountUpdateStatusRequestMutation,
  DiscountUpdateStatusRequestMutationVariables
>;
export const EventsCountDocument = gql`
  query EventsCount($filter: EventFilter!) {
    total: eventsList(filter: $filter) {
      count
    }
    upcoming: eventsList(
      filter: {
        AND: [
          $filter
          {
            status: { equals: "active" }
            isApproved: { equals: true }
            date: { relative: { gte: { op: ADD, value: "1", unit: SECOND } } }
          }
        ]
      }
    ) {
      count
    }
    pending: eventsList(filter: { AND: [$filter, { status: { equals: "pendingApproval" } }] }) {
      count
    }
  }
`;

/**
 * __useEventsCountQuery__
 *
 * To run a query within a React component, call `useEventsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEventsCountQuery(
  baseOptions: Apollo.QueryHookOptions<EventsCountQuery, EventsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventsCountQuery, EventsCountQueryVariables>(EventsCountDocument, options);
}
export function useEventsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventsCountQuery, EventsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventsCountQuery, EventsCountQueryVariables>(
    EventsCountDocument,
    options,
  );
}
export type EventsCountQueryHookResult = ReturnType<typeof useEventsCountQuery>;
export type EventsCountLazyQueryHookResult = ReturnType<typeof useEventsCountLazyQuery>;
export type EventsCountQueryResult = Apollo.QueryResult<
  EventsCountQuery,
  EventsCountQueryVariables
>;
export const EventStatusUpdateDocument = gql`
  mutation EventStatusUpdate($data: EventStatusUpdateRequestInput!) {
    eventStatusUpdateRequest(data: $data) {
      success
    }
  }
`;
export type EventStatusUpdateMutationFn = Apollo.MutationFunction<
  EventStatusUpdateMutation,
  EventStatusUpdateMutationVariables
>;

/**
 * __useEventStatusUpdateMutation__
 *
 * To run a mutation, you first call `useEventStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventStatusUpdateMutation, { data, loading, error }] = useEventStatusUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEventStatusUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventStatusUpdateMutation,
    EventStatusUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EventStatusUpdateMutation, EventStatusUpdateMutationVariables>(
    EventStatusUpdateDocument,
    options,
  );
}
export type EventStatusUpdateMutationHookResult = ReturnType<typeof useEventStatusUpdateMutation>;
export type EventStatusUpdateMutationResult = Apollo.MutationResult<EventStatusUpdateMutation>;
export type EventStatusUpdateMutationOptions = Apollo.BaseMutationOptions<
  EventStatusUpdateMutation,
  EventStatusUpdateMutationVariables
>;
export const EventDeleteDocument = gql`
  mutation EventDelete($data: EventDeleteInput!) {
    eventDelete(data: $data) {
      success
    }
  }
`;
export type EventDeleteMutationFn = Apollo.MutationFunction<
  EventDeleteMutation,
  EventDeleteMutationVariables
>;

/**
 * __useEventDeleteMutation__
 *
 * To run a mutation, you first call `useEventDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventDeleteMutation, { data, loading, error }] = useEventDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEventDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<EventDeleteMutation, EventDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EventDeleteMutation, EventDeleteMutationVariables>(
    EventDeleteDocument,
    options,
  );
}
export type EventDeleteMutationHookResult = ReturnType<typeof useEventDeleteMutation>;
export type EventDeleteMutationResult = Apollo.MutationResult<EventDeleteMutation>;
export type EventDeleteMutationOptions = Apollo.BaseMutationOptions<
  EventDeleteMutation,
  EventDeleteMutationVariables
>;
export const UserInviteMembersToGroupDocument = gql`
  mutation UserInviteMembersToGroup($data: UserInviteMembersToGroupInput!) {
    userInviteMembersToGroup(data: $data) {
      success
    }
  }
`;
export type UserInviteMembersToGroupMutationFn = Apollo.MutationFunction<
  UserInviteMembersToGroupMutation,
  UserInviteMembersToGroupMutationVariables
>;

/**
 * __useUserInviteMembersToGroupMutation__
 *
 * To run a mutation, you first call `useUserInviteMembersToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserInviteMembersToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userInviteMembersToGroupMutation, { data, loading, error }] = useUserInviteMembersToGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserInviteMembersToGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserInviteMembersToGroupMutation,
    UserInviteMembersToGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserInviteMembersToGroupMutation,
    UserInviteMembersToGroupMutationVariables
  >(UserInviteMembersToGroupDocument, options);
}
export type UserInviteMembersToGroupMutationHookResult = ReturnType<
  typeof useUserInviteMembersToGroupMutation
>;
export type UserInviteMembersToGroupMutationResult =
  Apollo.MutationResult<UserInviteMembersToGroupMutation>;
export type UserInviteMembersToGroupMutationOptions = Apollo.BaseMutationOptions<
  UserInviteMembersToGroupMutation,
  UserInviteMembersToGroupMutationVariables
>;
export const AdminGroupDetailsDocument = gql`
  query AdminGroupDetails($id: ID!) {
    group(id: $id) {
      ...GroupsItem
    }
  }
  ${GroupsItemFragmentDoc}
`;

/**
 * __useAdminGroupDetailsQuery__
 *
 * To run a query within a React component, call `useAdminGroupDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGroupDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGroupDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGroupDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGroupDetailsQuery, AdminGroupDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGroupDetailsQuery, AdminGroupDetailsQueryVariables>(
    AdminGroupDetailsDocument,
    options,
  );
}
export function useAdminGroupDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGroupDetailsQuery,
    AdminGroupDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGroupDetailsQuery, AdminGroupDetailsQueryVariables>(
    AdminGroupDetailsDocument,
    options,
  );
}
export type AdminGroupDetailsQueryHookResult = ReturnType<typeof useAdminGroupDetailsQuery>;
export type AdminGroupDetailsLazyQueryHookResult = ReturnType<typeof useAdminGroupDetailsLazyQuery>;
export type AdminGroupDetailsQueryResult = Apollo.QueryResult<
  AdminGroupDetailsQuery,
  AdminGroupDetailsQueryVariables
>;
export const GroupMembersListDocument = gql`
  query GroupMembersList(
    $first: Int
    $skip: Int
    $id: ID
    $filter: UserFilter
    $sort: [UserSort!]
  ) {
    group(id: $id) {
      members(first: $first, skip: $skip, filter: $filter, sort: $sort) {
        items {
          ...GroupMember
        }
      }
      posts {
        count
      }
      groupAdminPreferences {
        items {
          status
          id
          user {
            email
            id
            firstName
            lastName
            affiliation
          }
        }
      }
    }
  }
  ${GroupMemberFragmentDoc}
`;

/**
 * __useGroupMembersListQuery__
 *
 * To run a query within a React component, call `useGroupMembersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupMembersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupMembersListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGroupMembersListQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupMembersListQuery, GroupMembersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupMembersListQuery, GroupMembersListQueryVariables>(
    GroupMembersListDocument,
    options,
  );
}
export function useGroupMembersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupMembersListQuery, GroupMembersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupMembersListQuery, GroupMembersListQueryVariables>(
    GroupMembersListDocument,
    options,
  );
}
export type GroupMembersListQueryHookResult = ReturnType<typeof useGroupMembersListQuery>;
export type GroupMembersListLazyQueryHookResult = ReturnType<typeof useGroupMembersListLazyQuery>;
export type GroupMembersListQueryResult = Apollo.QueryResult<
  GroupMembersListQuery,
  GroupMembersListQueryVariables
>;
export const GroupMembersStatsDocument = gql`
  query GroupMembersStats($filter: GroupFilter!) {
    groupsCount: groupsList(
      filter: $filter
      groupBy: { query: { members: { id: { as: "totalMembersCount", fn: { aggregate: COUNT } } } } }
    ) {
      count
      metrics: groups {
        totalMembersCount: Int
      }
    }
    groupsPending: groupsList(filter: { AND: [$filter, { status: { equals: "pending" } }] }) {
      count
    }
  }
`;

/**
 * __useGroupMembersStatsQuery__
 *
 * To run a query within a React component, call `useGroupMembersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupMembersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupMembersStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGroupMembersStatsQuery(
  baseOptions: Apollo.QueryHookOptions<GroupMembersStatsQuery, GroupMembersStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupMembersStatsQuery, GroupMembersStatsQueryVariables>(
    GroupMembersStatsDocument,
    options,
  );
}
export function useGroupMembersStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupMembersStatsQuery,
    GroupMembersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupMembersStatsQuery, GroupMembersStatsQueryVariables>(
    GroupMembersStatsDocument,
    options,
  );
}
export type GroupMembersStatsQueryHookResult = ReturnType<typeof useGroupMembersStatsQuery>;
export type GroupMembersStatsLazyQueryHookResult = ReturnType<typeof useGroupMembersStatsLazyQuery>;
export type GroupMembersStatsQueryResult = Apollo.QueryResult<
  GroupMembersStatsQuery,
  GroupMembersStatsQueryVariables
>;
export const GroupMembersListCountDocument = gql`
  query GroupMembersListCount($groupId: ID, $filter: UserFilter) {
    group(id: $groupId) {
      members(filter: $filter) {
        count
      }
    }
  }
`;

/**
 * __useGroupMembersListCountQuery__
 *
 * To run a query within a React component, call `useGroupMembersListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupMembersListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupMembersListCountQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGroupMembersListCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GroupMembersListCountQuery,
    GroupMembersListCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupMembersListCountQuery, GroupMembersListCountQueryVariables>(
    GroupMembersListCountDocument,
    options,
  );
}
export function useGroupMembersListCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupMembersListCountQuery,
    GroupMembersListCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupMembersListCountQuery, GroupMembersListCountQueryVariables>(
    GroupMembersListCountDocument,
    options,
  );
}
export type GroupMembersListCountQueryHookResult = ReturnType<typeof useGroupMembersListCountQuery>;
export type GroupMembersListCountLazyQueryHookResult = ReturnType<
  typeof useGroupMembersListCountLazyQuery
>;
export type GroupMembersListCountQueryResult = Apollo.QueryResult<
  GroupMembersListCountQuery,
  GroupMembersListCountQueryVariables
>;
export const GroupUpdateDocument = gql`
  mutation GroupUpdate($data: GroupUpdateInput!) {
    groupUpdate(data: $data) {
      ...GroupsItem
    }
  }
  ${GroupsItemFragmentDoc}
`;
export type GroupUpdateMutationFn = Apollo.MutationFunction<
  GroupUpdateMutation,
  GroupUpdateMutationVariables
>;

/**
 * __useGroupUpdateMutation__
 *
 * To run a mutation, you first call `useGroupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupUpdateMutation, { data, loading, error }] = useGroupUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGroupUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<GroupUpdateMutation, GroupUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupUpdateMutation, GroupUpdateMutationVariables>(
    GroupUpdateDocument,
    options,
  );
}
export type GroupUpdateMutationHookResult = ReturnType<typeof useGroupUpdateMutation>;
export type GroupUpdateMutationResult = Apollo.MutationResult<GroupUpdateMutation>;
export type GroupUpdateMutationOptions = Apollo.BaseMutationOptions<
  GroupUpdateMutation,
  GroupUpdateMutationVariables
>;
export const GroupApproveDocument = gql`
  mutation GroupApprove($groupId: ID!) {
    groupUpdate(data: { id: $groupId, status: "" }) {
      id
    }
  }
`;
export type GroupApproveMutationFn = Apollo.MutationFunction<
  GroupApproveMutation,
  GroupApproveMutationVariables
>;

/**
 * __useGroupApproveMutation__
 *
 * To run a mutation, you first call `useGroupApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupApproveMutation, { data, loading, error }] = useGroupApproveMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupApproveMutation(
  baseOptions?: Apollo.MutationHookOptions<GroupApproveMutation, GroupApproveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupApproveMutation, GroupApproveMutationVariables>(
    GroupApproveDocument,
    options,
  );
}
export type GroupApproveMutationHookResult = ReturnType<typeof useGroupApproveMutation>;
export type GroupApproveMutationResult = Apollo.MutationResult<GroupApproveMutation>;
export type GroupApproveMutationOptions = Apollo.BaseMutationOptions<
  GroupApproveMutation,
  GroupApproveMutationVariables
>;
export const GroupRejectDocument = gql`
  mutation GroupReject($groupId: ID!) {
    groupRejectRequest(groupId: $groupId) {
      success
    }
  }
`;
export type GroupRejectMutationFn = Apollo.MutationFunction<
  GroupRejectMutation,
  GroupRejectMutationVariables
>;

/**
 * __useGroupRejectMutation__
 *
 * To run a mutation, you first call `useGroupRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupRejectMutation, { data, loading, error }] = useGroupRejectMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupRejectMutation(
  baseOptions?: Apollo.MutationHookOptions<GroupRejectMutation, GroupRejectMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupRejectMutation, GroupRejectMutationVariables>(
    GroupRejectDocument,
    options,
  );
}
export type GroupRejectMutationHookResult = ReturnType<typeof useGroupRejectMutation>;
export type GroupRejectMutationResult = Apollo.MutationResult<GroupRejectMutation>;
export type GroupRejectMutationOptions = Apollo.BaseMutationOptions<
  GroupRejectMutation,
  GroupRejectMutationVariables
>;
export const GroupAdminPreferenceDeleteDocument = gql`
  mutation GroupAdminPreferenceDelete($data: GroupAdminPreferenceDeleteInput) {
    groupAdminPreferenceDelete(data: $data) {
      success
    }
  }
`;
export type GroupAdminPreferenceDeleteMutationFn = Apollo.MutationFunction<
  GroupAdminPreferenceDeleteMutation,
  GroupAdminPreferenceDeleteMutationVariables
>;

/**
 * __useGroupAdminPreferenceDeleteMutation__
 *
 * To run a mutation, you first call `useGroupAdminPreferenceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupAdminPreferenceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupAdminPreferenceDeleteMutation, { data, loading, error }] = useGroupAdminPreferenceDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGroupAdminPreferenceDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupAdminPreferenceDeleteMutation,
    GroupAdminPreferenceDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GroupAdminPreferenceDeleteMutation,
    GroupAdminPreferenceDeleteMutationVariables
  >(GroupAdminPreferenceDeleteDocument, options);
}
export type GroupAdminPreferenceDeleteMutationHookResult = ReturnType<
  typeof useGroupAdminPreferenceDeleteMutation
>;
export type GroupAdminPreferenceDeleteMutationResult =
  Apollo.MutationResult<GroupAdminPreferenceDeleteMutation>;
export type GroupAdminPreferenceDeleteMutationOptions = Apollo.BaseMutationOptions<
  GroupAdminPreferenceDeleteMutation,
  GroupAdminPreferenceDeleteMutationVariables
>;
export const AdminGroupDeleteDocument = gql`
  mutation AdminGroupDelete($data: GroupDeleteInput!) {
    groupDelete(data: $data) {
      success
    }
  }
`;
export type AdminGroupDeleteMutationFn = Apollo.MutationFunction<
  AdminGroupDeleteMutation,
  AdminGroupDeleteMutationVariables
>;

/**
 * __useAdminGroupDeleteMutation__
 *
 * To run a mutation, you first call `useAdminGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminGroupDeleteMutation, { data, loading, error }] = useAdminGroupDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGroupDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminGroupDeleteMutation,
    AdminGroupDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminGroupDeleteMutation, AdminGroupDeleteMutationVariables>(
    AdminGroupDeleteDocument,
    options,
  );
}
export type AdminGroupDeleteMutationHookResult = ReturnType<typeof useAdminGroupDeleteMutation>;
export type AdminGroupDeleteMutationResult = Apollo.MutationResult<AdminGroupDeleteMutation>;
export type AdminGroupDeleteMutationOptions = Apollo.BaseMutationOptions<
  AdminGroupDeleteMutation,
  AdminGroupDeleteMutationVariables
>;
export const AdminPreferencesDeleteDocument = gql`
  mutation AdminPreferencesDelete($data: GroupAdminPreferenceDeleteInput!) {
    groupAdminPreferenceDelete(force: true, data: $data) {
      success
    }
  }
`;
export type AdminPreferencesDeleteMutationFn = Apollo.MutationFunction<
  AdminPreferencesDeleteMutation,
  AdminPreferencesDeleteMutationVariables
>;

/**
 * __useAdminPreferencesDeleteMutation__
 *
 * To run a mutation, you first call `useAdminPreferencesDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPreferencesDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPreferencesDeleteMutation, { data, loading, error }] = useAdminPreferencesDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminPreferencesDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminPreferencesDeleteMutation,
    AdminPreferencesDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminPreferencesDeleteMutation,
    AdminPreferencesDeleteMutationVariables
  >(AdminPreferencesDeleteDocument, options);
}
export type AdminPreferencesDeleteMutationHookResult = ReturnType<
  typeof useAdminPreferencesDeleteMutation
>;
export type AdminPreferencesDeleteMutationResult =
  Apollo.MutationResult<AdminPreferencesDeleteMutation>;
export type AdminPreferencesDeleteMutationOptions = Apollo.BaseMutationOptions<
  AdminPreferencesDeleteMutation,
  AdminPreferencesDeleteMutationVariables
>;
export const GroupAdminPreferencesListDocument = gql`
  query GroupAdminPreferencesList($filter: GroupAdminPreferenceFilter!) {
    groupAdminPreferencesList(filter: $filter) {
      items {
        id
      }
    }
  }
`;

/**
 * __useGroupAdminPreferencesListQuery__
 *
 * To run a query within a React component, call `useGroupAdminPreferencesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupAdminPreferencesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupAdminPreferencesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGroupAdminPreferencesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupAdminPreferencesListQuery,
    GroupAdminPreferencesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupAdminPreferencesListQuery, GroupAdminPreferencesListQueryVariables>(
    GroupAdminPreferencesListDocument,
    options,
  );
}
export function useGroupAdminPreferencesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupAdminPreferencesListQuery,
    GroupAdminPreferencesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupAdminPreferencesListQuery,
    GroupAdminPreferencesListQueryVariables
  >(GroupAdminPreferencesListDocument, options);
}
export type GroupAdminPreferencesListQueryHookResult = ReturnType<
  typeof useGroupAdminPreferencesListQuery
>;
export type GroupAdminPreferencesListLazyQueryHookResult = ReturnType<
  typeof useGroupAdminPreferencesListLazyQuery
>;
export type GroupAdminPreferencesListQueryResult = Apollo.QueryResult<
  GroupAdminPreferencesListQuery,
  GroupAdminPreferencesListQueryVariables
>;
export const DeleteAllLegacyPostsDocument = gql`
  mutation DeleteAllLegacyPosts {
    legacyFeedPostDeleteByFilter(filter: { id: { is_not_empty: true } }) {
      success
    }
  }
`;
export type DeleteAllLegacyPostsMutationFn = Apollo.MutationFunction<
  DeleteAllLegacyPostsMutation,
  DeleteAllLegacyPostsMutationVariables
>;

/**
 * __useDeleteAllLegacyPostsMutation__
 *
 * To run a mutation, you first call `useDeleteAllLegacyPostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllLegacyPostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllLegacyPostsMutation, { data, loading, error }] = useDeleteAllLegacyPostsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAllLegacyPostsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAllLegacyPostsMutation,
    DeleteAllLegacyPostsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAllLegacyPostsMutation, DeleteAllLegacyPostsMutationVariables>(
    DeleteAllLegacyPostsDocument,
    options,
  );
}
export type DeleteAllLegacyPostsMutationHookResult = ReturnType<
  typeof useDeleteAllLegacyPostsMutation
>;
export type DeleteAllLegacyPostsMutationResult =
  Apollo.MutationResult<DeleteAllLegacyPostsMutation>;
export type DeleteAllLegacyPostsMutationOptions = Apollo.BaseMutationOptions<
  DeleteAllLegacyPostsMutation,
  DeleteAllLegacyPostsMutationVariables
>;
export const MemberStatsDocument = gql`
  query MemberStats($filter: UserFilter) {
    affiliations: usersList(
      filter: $filter
      groupBy: {
        query: { id: { as: "count", fn: { aggregate: COUNT } }, affiliation: { as: "affiliation" } }
      }
    ) {
      totalCount: count
      groups {
        affiliation: String
        count: Int
      }
    }
  }
`;

/**
 * __useMemberStatsQuery__
 *
 * To run a query within a React component, call `useMemberStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMemberStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<MemberStatsQuery, MemberStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberStatsQuery, MemberStatsQueryVariables>(MemberStatsDocument, options);
}
export function useMemberStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberStatsQuery, MemberStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberStatsQuery, MemberStatsQueryVariables>(
    MemberStatsDocument,
    options,
  );
}
export type MemberStatsQueryHookResult = ReturnType<typeof useMemberStatsQuery>;
export type MemberStatsLazyQueryHookResult = ReturnType<typeof useMemberStatsLazyQuery>;
export type MemberStatsQueryResult = Apollo.QueryResult<
  MemberStatsQuery,
  MemberStatsQueryVariables
>;
export const SchoolMembersReportDocument = gql`
  query SchoolMembersReport(
    $first: Int
    $skip: Int
    $startPointZip: String
    $radius: String
    $school: SchoolConfigurationKeyFilter!
    $filter: UserSchoolMembershipFilter
    $sort: [UserSchoolMembershipSort!]
  ) {
    members: schoolMembersReport(
      first: $first
      skip: $skip
      school: $school
      filter: $filter
      sort: $sort
      startPointZip: $startPointZip
      radius: $radius
    ) {
      count
      items {
        ...SchoolMember
      }
    }
  }
  ${SchoolMemberFragmentDoc}
`;

/**
 * __useSchoolMembersReportQuery__
 *
 * To run a query within a React component, call `useSchoolMembersReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolMembersReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolMembersReportQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      startPointZip: // value for 'startPointZip'
 *      radius: // value for 'radius'
 *      school: // value for 'school'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSchoolMembersReportQuery(
  baseOptions: Apollo.QueryHookOptions<SchoolMembersReportQuery, SchoolMembersReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchoolMembersReportQuery, SchoolMembersReportQueryVariables>(
    SchoolMembersReportDocument,
    options,
  );
}
export function useSchoolMembersReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolMembersReportQuery,
    SchoolMembersReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchoolMembersReportQuery, SchoolMembersReportQueryVariables>(
    SchoolMembersReportDocument,
    options,
  );
}
export type SchoolMembersReportQueryHookResult = ReturnType<typeof useSchoolMembersReportQuery>;
export type SchoolMembersReportLazyQueryHookResult = ReturnType<
  typeof useSchoolMembersReportLazyQuery
>;
export type SchoolMembersReportQueryResult = Apollo.QueryResult<
  SchoolMembersReportQuery,
  SchoolMembersReportQueryVariables
>;
export const SchoolMembersReportCountDocument = gql`
  query SchoolMembersReportCount(
    $school: SchoolConfigurationKeyFilter!
    $filter: UserSchoolMembershipFilter
    $startPointZip: String
    $radius: String
  ) {
    report: schoolMembersReport(
      school: $school
      filter: $filter
      startPointZip: $startPointZip
      radius: $radius
    ) {
      count
    }
  }
`;

/**
 * __useSchoolMembersReportCountQuery__
 *
 * To run a query within a React component, call `useSchoolMembersReportCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolMembersReportCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolMembersReportCountQuery({
 *   variables: {
 *      school: // value for 'school'
 *      filter: // value for 'filter'
 *      startPointZip: // value for 'startPointZip'
 *      radius: // value for 'radius'
 *   },
 * });
 */
export function useSchoolMembersReportCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    SchoolMembersReportCountQuery,
    SchoolMembersReportCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchoolMembersReportCountQuery, SchoolMembersReportCountQueryVariables>(
    SchoolMembersReportCountDocument,
    options,
  );
}
export function useSchoolMembersReportCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolMembersReportCountQuery,
    SchoolMembersReportCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchoolMembersReportCountQuery, SchoolMembersReportCountQueryVariables>(
    SchoolMembersReportCountDocument,
    options,
  );
}
export type SchoolMembersReportCountQueryHookResult = ReturnType<
  typeof useSchoolMembersReportCountQuery
>;
export type SchoolMembersReportCountLazyQueryHookResult = ReturnType<
  typeof useSchoolMembersReportCountLazyQuery
>;
export type SchoolMembersReportCountQueryResult = Apollo.QueryResult<
  SchoolMembersReportCountQuery,
  SchoolMembersReportCountQueryVariables
>;
export const AcceptSchoolMemberDocument = gql`
  mutation AcceptSchoolMember($filter: UserFilter!, $school: SchoolConfigurationKeyFilter!) {
    acceptSchoolMembers(filter: $filter, school: $school) {
      success
    }
  }
`;
export type AcceptSchoolMemberMutationFn = Apollo.MutationFunction<
  AcceptSchoolMemberMutation,
  AcceptSchoolMemberMutationVariables
>;

/**
 * __useAcceptSchoolMemberMutation__
 *
 * To run a mutation, you first call `useAcceptSchoolMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptSchoolMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptSchoolMemberMutation, { data, loading, error }] = useAcceptSchoolMemberMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      school: // value for 'school'
 *   },
 * });
 */
export function useAcceptSchoolMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptSchoolMemberMutation,
    AcceptSchoolMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptSchoolMemberMutation, AcceptSchoolMemberMutationVariables>(
    AcceptSchoolMemberDocument,
    options,
  );
}
export type AcceptSchoolMemberMutationHookResult = ReturnType<typeof useAcceptSchoolMemberMutation>;
export type AcceptSchoolMemberMutationResult = Apollo.MutationResult<AcceptSchoolMemberMutation>;
export type AcceptSchoolMemberMutationOptions = Apollo.BaseMutationOptions<
  AcceptSchoolMemberMutation,
  AcceptSchoolMemberMutationVariables
>;
export const ActivateSchoolMembersDocument = gql`
  mutation ActivateSchoolMembers($filter: UserFilter!, $school: SchoolConfigurationKeyFilter!) {
    activateSchoolMembers(filter: $filter, school: $school) {
      success
    }
  }
`;
export type ActivateSchoolMembersMutationFn = Apollo.MutationFunction<
  ActivateSchoolMembersMutation,
  ActivateSchoolMembersMutationVariables
>;

/**
 * __useActivateSchoolMembersMutation__
 *
 * To run a mutation, you first call `useActivateSchoolMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSchoolMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSchoolMembersMutation, { data, loading, error }] = useActivateSchoolMembersMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      school: // value for 'school'
 *   },
 * });
 */
export function useActivateSchoolMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateSchoolMembersMutation,
    ActivateSchoolMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateSchoolMembersMutation, ActivateSchoolMembersMutationVariables>(
    ActivateSchoolMembersDocument,
    options,
  );
}
export type ActivateSchoolMembersMutationHookResult = ReturnType<
  typeof useActivateSchoolMembersMutation
>;
export type ActivateSchoolMembersMutationResult =
  Apollo.MutationResult<ActivateSchoolMembersMutation>;
export type ActivateSchoolMembersMutationOptions = Apollo.BaseMutationOptions<
  ActivateSchoolMembersMutation,
  ActivateSchoolMembersMutationVariables
>;
export const InactivateSchoolMembersDocument = gql`
  mutation InactivateSchoolMembers($filter: UserFilter!, $school: SchoolConfigurationKeyFilter!) {
    inactivateSchoolMembers(filter: $filter, school: $school) {
      success
    }
  }
`;
export type InactivateSchoolMembersMutationFn = Apollo.MutationFunction<
  InactivateSchoolMembersMutation,
  InactivateSchoolMembersMutationVariables
>;

/**
 * __useInactivateSchoolMembersMutation__
 *
 * To run a mutation, you first call `useInactivateSchoolMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInactivateSchoolMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inactivateSchoolMembersMutation, { data, loading, error }] = useInactivateSchoolMembersMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      school: // value for 'school'
 *   },
 * });
 */
export function useInactivateSchoolMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InactivateSchoolMembersMutation,
    InactivateSchoolMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InactivateSchoolMembersMutation,
    InactivateSchoolMembersMutationVariables
  >(InactivateSchoolMembersDocument, options);
}
export type InactivateSchoolMembersMutationHookResult = ReturnType<
  typeof useInactivateSchoolMembersMutation
>;
export type InactivateSchoolMembersMutationResult =
  Apollo.MutationResult<InactivateSchoolMembersMutation>;
export type InactivateSchoolMembersMutationOptions = Apollo.BaseMutationOptions<
  InactivateSchoolMembersMutation,
  InactivateSchoolMembersMutationVariables
>;
export const RejectSchoolMemberDocument = gql`
  mutation RejectSchoolMember($filter: UserFilter!, $school: SchoolConfigurationKeyFilter!) {
    rejectSchoolMembers(filter: $filter, school: $school) {
      success
    }
  }
`;
export type RejectSchoolMemberMutationFn = Apollo.MutationFunction<
  RejectSchoolMemberMutation,
  RejectSchoolMemberMutationVariables
>;

/**
 * __useRejectSchoolMemberMutation__
 *
 * To run a mutation, you first call `useRejectSchoolMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectSchoolMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectSchoolMemberMutation, { data, loading, error }] = useRejectSchoolMemberMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      school: // value for 'school'
 *   },
 * });
 */
export function useRejectSchoolMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectSchoolMemberMutation,
    RejectSchoolMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RejectSchoolMemberMutation, RejectSchoolMemberMutationVariables>(
    RejectSchoolMemberDocument,
    options,
  );
}
export type RejectSchoolMemberMutationHookResult = ReturnType<typeof useRejectSchoolMemberMutation>;
export type RejectSchoolMemberMutationResult = Apollo.MutationResult<RejectSchoolMemberMutation>;
export type RejectSchoolMemberMutationOptions = Apollo.BaseMutationOptions<
  RejectSchoolMemberMutation,
  RejectSchoolMemberMutationVariables
>;
export const ResendSchoolApprovalDocument = gql`
  mutation ResendSchoolApproval($filter: UserFilter!, $school: SchoolConfigurationKeyFilter!) {
    resendSchoolApprovals(filter: $filter, school: $school) {
      success
    }
  }
`;
export type ResendSchoolApprovalMutationFn = Apollo.MutationFunction<
  ResendSchoolApprovalMutation,
  ResendSchoolApprovalMutationVariables
>;

/**
 * __useResendSchoolApprovalMutation__
 *
 * To run a mutation, you first call `useResendSchoolApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendSchoolApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendSchoolApprovalMutation, { data, loading, error }] = useResendSchoolApprovalMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      school: // value for 'school'
 *   },
 * });
 */
export function useResendSchoolApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendSchoolApprovalMutation,
    ResendSchoolApprovalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendSchoolApprovalMutation, ResendSchoolApprovalMutationVariables>(
    ResendSchoolApprovalDocument,
    options,
  );
}
export type ResendSchoolApprovalMutationHookResult = ReturnType<
  typeof useResendSchoolApprovalMutation
>;
export type ResendSchoolApprovalMutationResult =
  Apollo.MutationResult<ResendSchoolApprovalMutation>;
export type ResendSchoolApprovalMutationOptions = Apollo.BaseMutationOptions<
  ResendSchoolApprovalMutation,
  ResendSchoolApprovalMutationVariables
>;
export const ContentReportsListDocument = gql`
  query ContentReportsList(
    $first: Int
    $skip: Int
    $sort: [ContentReportSort!]
    $filter: ContentReportFilter
  ) {
    contentReportsList(first: $first, skip: $skip, sort: $sort, filter: $filter) {
      items {
        ...ContentReportsListItem
      }
    }
  }
  ${ContentReportsListItemFragmentDoc}
`;

/**
 * __useContentReportsListQuery__
 *
 * To run a query within a React component, call `useContentReportsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentReportsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentReportsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useContentReportsListQuery(
  baseOptions?: Apollo.QueryHookOptions<ContentReportsListQuery, ContentReportsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContentReportsListQuery, ContentReportsListQueryVariables>(
    ContentReportsListDocument,
    options,
  );
}
export function useContentReportsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContentReportsListQuery,
    ContentReportsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContentReportsListQuery, ContentReportsListQueryVariables>(
    ContentReportsListDocument,
    options,
  );
}
export type ContentReportsListQueryHookResult = ReturnType<typeof useContentReportsListQuery>;
export type ContentReportsListLazyQueryHookResult = ReturnType<
  typeof useContentReportsListLazyQuery
>;
export type ContentReportsListQueryResult = Apollo.QueryResult<
  ContentReportsListQuery,
  ContentReportsListQueryVariables
>;
export const ContentEngagementReportDocument = gql`
  query ContentEngagementReport(
    $filter: ContentReportFilter
    $orderBy: [ContentReportOrderBy]
    $sort: [ContentReportSort!]
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $withDeleted: Boolean
  ) {
    report: contentEngagementReportsList(
      filter: $filter
      orderBy: $orderBy
      sort: $sort
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
      withDeleted: $withDeleted
    ) {
      count
      items {
        ...ContentEngagementReport
      }
    }
  }
  ${ContentEngagementReportFragmentDoc}
`;

/**
 * __useContentEngagementReportQuery__
 *
 * To run a query within a React component, call `useContentEngagementReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentEngagementReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentEngagementReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *      sort: // value for 'sort'
 *      skip: // value for 'skip'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      withDeleted: // value for 'withDeleted'
 *   },
 * });
 */
export function useContentEngagementReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ContentEngagementReportQuery,
    ContentEngagementReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContentEngagementReportQuery, ContentEngagementReportQueryVariables>(
    ContentEngagementReportDocument,
    options,
  );
}
export function useContentEngagementReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContentEngagementReportQuery,
    ContentEngagementReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContentEngagementReportQuery, ContentEngagementReportQueryVariables>(
    ContentEngagementReportDocument,
    options,
  );
}
export type ContentEngagementReportQueryHookResult = ReturnType<
  typeof useContentEngagementReportQuery
>;
export type ContentEngagementReportLazyQueryHookResult = ReturnType<
  typeof useContentEngagementReportLazyQuery
>;
export type ContentEngagementReportQueryResult = Apollo.QueryResult<
  ContentEngagementReportQuery,
  ContentEngagementReportQueryVariables
>;
export const RevenueReportDocument = gql`
  query RevenueReport(
    $filter: OrganizationFilter
    $debtFilter: DebtOperationHistoryFilter
    $skip: Int
    $first: Int
  ) {
    organizations: organizationsList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        name
        sponsorRequests {
          count
        }
        debt {
          balance: debtOperationHistories(
            filter: $debtFilter
            groupBy: { query: { amount: { as: "revenue", fn: { aggregate: SUM } } } }
          ) {
            metrics: groups {
              revenue: Float
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRevenueReportQuery__
 *
 * To run a query within a React component, call `useRevenueReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenueReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenueReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      debtFilter: // value for 'debtFilter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useRevenueReportQuery(
  baseOptions?: Apollo.QueryHookOptions<RevenueReportQuery, RevenueReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RevenueReportQuery, RevenueReportQueryVariables>(
    RevenueReportDocument,
    options,
  );
}
export function useRevenueReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RevenueReportQuery, RevenueReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RevenueReportQuery, RevenueReportQueryVariables>(
    RevenueReportDocument,
    options,
  );
}
export type RevenueReportQueryHookResult = ReturnType<typeof useRevenueReportQuery>;
export type RevenueReportLazyQueryHookResult = ReturnType<typeof useRevenueReportLazyQuery>;
export type RevenueReportQueryResult = Apollo.QueryResult<
  RevenueReportQuery,
  RevenueReportQueryVariables
>;
export const CampaignAdsListDocument = gql`
  query CampaignAdsList($filter: AdvertisingCampaignAdFilter) {
    advertisingCampaignAdsList(filter: $filter) {
      items {
        ...AdItem
      }
    }
  }
  ${AdItemFragmentDoc}
`;

/**
 * __useCampaignAdsListQuery__
 *
 * To run a query within a React component, call `useCampaignAdsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignAdsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignAdsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignAdsListQuery(
  baseOptions?: Apollo.QueryHookOptions<CampaignAdsListQuery, CampaignAdsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CampaignAdsListQuery, CampaignAdsListQueryVariables>(
    CampaignAdsListDocument,
    options,
  );
}
export function useCampaignAdsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CampaignAdsListQuery, CampaignAdsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CampaignAdsListQuery, CampaignAdsListQueryVariables>(
    CampaignAdsListDocument,
    options,
  );
}
export type CampaignAdsListQueryHookResult = ReturnType<typeof useCampaignAdsListQuery>;
export type CampaignAdsListLazyQueryHookResult = ReturnType<typeof useCampaignAdsListLazyQuery>;
export type CampaignAdsListQueryResult = Apollo.QueryResult<
  CampaignAdsListQuery,
  CampaignAdsListQueryVariables
>;
export const UpdateCampaignAdDocument = gql`
  mutation UpdateCampaignAd($data: AdvertisingCampaignAdUpdateInput!, $id: ID) {
    advertisingCampaignAdUpdate(data: $data, filter: { id: $id }) {
      id
    }
  }
`;
export type UpdateCampaignAdMutationFn = Apollo.MutationFunction<
  UpdateCampaignAdMutation,
  UpdateCampaignAdMutationVariables
>;

/**
 * __useUpdateCampaignAdMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignAdMutation, { data, loading, error }] = useUpdateCampaignAdMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCampaignAdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCampaignAdMutation,
    UpdateCampaignAdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCampaignAdMutation, UpdateCampaignAdMutationVariables>(
    UpdateCampaignAdDocument,
    options,
  );
}
export type UpdateCampaignAdMutationHookResult = ReturnType<typeof useUpdateCampaignAdMutation>;
export type UpdateCampaignAdMutationResult = Apollo.MutationResult<UpdateCampaignAdMutation>;
export type UpdateCampaignAdMutationOptions = Apollo.BaseMutationOptions<
  UpdateCampaignAdMutation,
  UpdateCampaignAdMutationVariables
>;
export const AdViewCreateDocument = gql`
  mutation AdViewCreate($user: ID!, $ad: ID!, $school: ID!) {
    view: adViewCreate(
      data: {
        user: { connect: { id: $user } }
        ad: { connect: { id: $ad } }
        school: { connect: { id: $school } }
      }
    ) {
      id
    }
  }
`;
export type AdViewCreateMutationFn = Apollo.MutationFunction<
  AdViewCreateMutation,
  AdViewCreateMutationVariables
>;

/**
 * __useAdViewCreateMutation__
 *
 * To run a mutation, you first call `useAdViewCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdViewCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adViewCreateMutation, { data, loading, error }] = useAdViewCreateMutation({
 *   variables: {
 *      user: // value for 'user'
 *      ad: // value for 'ad'
 *      school: // value for 'school'
 *   },
 * });
 */
export function useAdViewCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<AdViewCreateMutation, AdViewCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdViewCreateMutation, AdViewCreateMutationVariables>(
    AdViewCreateDocument,
    options,
  );
}
export type AdViewCreateMutationHookResult = ReturnType<typeof useAdViewCreateMutation>;
export type AdViewCreateMutationResult = Apollo.MutationResult<AdViewCreateMutation>;
export type AdViewCreateMutationOptions = Apollo.BaseMutationOptions<
  AdViewCreateMutation,
  AdViewCreateMutationVariables
>;
export const AdClickCreateDocument = gql`
  mutation AdClickCreate($user: ID!, $ad: ID!, $school: ID!) {
    view: adClickCreate(
      data: {
        user: { connect: { id: $user } }
        ad: { connect: { id: $ad } }
        school: { connect: { id: $school } }
      }
    ) {
      id
    }
  }
`;
export type AdClickCreateMutationFn = Apollo.MutationFunction<
  AdClickCreateMutation,
  AdClickCreateMutationVariables
>;

/**
 * __useAdClickCreateMutation__
 *
 * To run a mutation, you first call `useAdClickCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdClickCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adClickCreateMutation, { data, loading, error }] = useAdClickCreateMutation({
 *   variables: {
 *      user: // value for 'user'
 *      ad: // value for 'ad'
 *      school: // value for 'school'
 *   },
 * });
 */
export function useAdClickCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<AdClickCreateMutation, AdClickCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdClickCreateMutation, AdClickCreateMutationVariables>(
    AdClickCreateDocument,
    options,
  );
}
export type AdClickCreateMutationHookResult = ReturnType<typeof useAdClickCreateMutation>;
export type AdClickCreateMutationResult = Apollo.MutationResult<AdClickCreateMutation>;
export type AdClickCreateMutationOptions = Apollo.BaseMutationOptions<
  AdClickCreateMutation,
  AdClickCreateMutationVariables
>;
export const AdLikesDocument = gql`
  query AdLikes($adId: ID!) {
    advertisingCampaignAd(id: $adId) {
      userLikes {
        count
        items {
          id
        }
      }
    }
  }
`;

/**
 * __useAdLikesQuery__
 *
 * To run a query within a React component, call `useAdLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdLikesQuery({
 *   variables: {
 *      adId: // value for 'adId'
 *   },
 * });
 */
export function useAdLikesQuery(
  baseOptions: Apollo.QueryHookOptions<AdLikesQuery, AdLikesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdLikesQuery, AdLikesQueryVariables>(AdLikesDocument, options);
}
export function useAdLikesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdLikesQuery, AdLikesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdLikesQuery, AdLikesQueryVariables>(AdLikesDocument, options);
}
export type AdLikesQueryHookResult = ReturnType<typeof useAdLikesQuery>;
export type AdLikesLazyQueryHookResult = ReturnType<typeof useAdLikesLazyQuery>;
export type AdLikesQueryResult = Apollo.QueryResult<AdLikesQuery, AdLikesQueryVariables>;
export const UserCompleteRegistrationDocument = gql`
  mutation UserCompleteRegistration(
    $school: SchoolConfigurationKeyFilter!
    $data: UserCompleteRegistrationInput!
  ) {
    userCompleteRegistration(school: $school, data: $data) {
      idToken
      refreshToken
    }
  }
`;
export type UserCompleteRegistrationMutationFn = Apollo.MutationFunction<
  UserCompleteRegistrationMutation,
  UserCompleteRegistrationMutationVariables
>;

/**
 * __useUserCompleteRegistrationMutation__
 *
 * To run a mutation, you first call `useUserCompleteRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCompleteRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCompleteRegistrationMutation, { data, loading, error }] = useUserCompleteRegistrationMutation({
 *   variables: {
 *      school: // value for 'school'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserCompleteRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserCompleteRegistrationMutation,
    UserCompleteRegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserCompleteRegistrationMutation,
    UserCompleteRegistrationMutationVariables
  >(UserCompleteRegistrationDocument, options);
}
export type UserCompleteRegistrationMutationHookResult = ReturnType<
  typeof useUserCompleteRegistrationMutation
>;
export type UserCompleteRegistrationMutationResult =
  Apollo.MutationResult<UserCompleteRegistrationMutation>;
export type UserCompleteRegistrationMutationOptions = Apollo.BaseMutationOptions<
  UserCompleteRegistrationMutation,
  UserCompleteRegistrationMutationVariables
>;
export const UserSocialCompleteRegistrationDocument = gql`
  mutation UserSocialCompleteRegistration($data: UserSocialCompleteRegistrationInput!) {
    userSocialCompleteRegistration(data: $data) {
      success
    }
  }
`;
export type UserSocialCompleteRegistrationMutationFn = Apollo.MutationFunction<
  UserSocialCompleteRegistrationMutation,
  UserSocialCompleteRegistrationMutationVariables
>;

/**
 * __useUserSocialCompleteRegistrationMutation__
 *
 * To run a mutation, you first call `useUserSocialCompleteRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSocialCompleteRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSocialCompleteRegistrationMutation, { data, loading, error }] = useUserSocialCompleteRegistrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserSocialCompleteRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserSocialCompleteRegistrationMutation,
    UserSocialCompleteRegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserSocialCompleteRegistrationMutation,
    UserSocialCompleteRegistrationMutationVariables
  >(UserSocialCompleteRegistrationDocument, options);
}
export type UserSocialCompleteRegistrationMutationHookResult = ReturnType<
  typeof useUserSocialCompleteRegistrationMutation
>;
export type UserSocialCompleteRegistrationMutationResult =
  Apollo.MutationResult<UserSocialCompleteRegistrationMutation>;
export type UserSocialCompleteRegistrationMutationOptions = Apollo.BaseMutationOptions<
  UserSocialCompleteRegistrationMutation,
  UserSocialCompleteRegistrationMutationVariables
>;
export const JobOfferUpdateDocument = gql`
  mutation JobOfferUpdate($data: JobOfferUpdateInput!) {
    jobOfferUpdate(data: $data) {
      id
      organization {
        id
        name
      }
      position
      description
      employmentType
      interestedUsers {
        items {
          id
          jobsOfInterest {
            items {
              ...CareerInfo
            }
          }
        }
      }
    }
  }
  ${CareerInfoFragmentDoc}
`;
export type JobOfferUpdateMutationFn = Apollo.MutationFunction<
  JobOfferUpdateMutation,
  JobOfferUpdateMutationVariables
>;

/**
 * __useJobOfferUpdateMutation__
 *
 * To run a mutation, you first call `useJobOfferUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobOfferUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobOfferUpdateMutation, { data, loading, error }] = useJobOfferUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useJobOfferUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<JobOfferUpdateMutation, JobOfferUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobOfferUpdateMutation, JobOfferUpdateMutationVariables>(
    JobOfferUpdateDocument,
    options,
  );
}
export type JobOfferUpdateMutationHookResult = ReturnType<typeof useJobOfferUpdateMutation>;
export type JobOfferUpdateMutationResult = Apollo.MutationResult<JobOfferUpdateMutation>;
export type JobOfferUpdateMutationOptions = Apollo.BaseMutationOptions<
  JobOfferUpdateMutation,
  JobOfferUpdateMutationVariables
>;
export const JobOfferCreateDocument = gql`
  mutation JobOfferCreate($data: JobOfferCreateInput!) {
    jobOfferCreate(data: $data) {
      id
    }
  }
`;
export type JobOfferCreateMutationFn = Apollo.MutationFunction<
  JobOfferCreateMutation,
  JobOfferCreateMutationVariables
>;

/**
 * __useJobOfferCreateMutation__
 *
 * To run a mutation, you first call `useJobOfferCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobOfferCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobOfferCreateMutation, { data, loading, error }] = useJobOfferCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useJobOfferCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<JobOfferCreateMutation, JobOfferCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobOfferCreateMutation, JobOfferCreateMutationVariables>(
    JobOfferCreateDocument,
    options,
  );
}
export type JobOfferCreateMutationHookResult = ReturnType<typeof useJobOfferCreateMutation>;
export type JobOfferCreateMutationResult = Apollo.MutationResult<JobOfferCreateMutation>;
export type JobOfferCreateMutationOptions = Apollo.BaseMutationOptions<
  JobOfferCreateMutation,
  JobOfferCreateMutationVariables
>;
export const OrganizationSearchDocument = gql`
  query OrganizationSearch($filter: OrganizationFilter!) {
    organizationsList(filter: $filter) {
      items {
        id
      }
    }
  }
`;

/**
 * __useOrganizationSearchQuery__
 *
 * To run a query within a React component, call `useOrganizationSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationSearchQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationSearchQuery, OrganizationSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationSearchQuery, OrganizationSearchQueryVariables>(
    OrganizationSearchDocument,
    options,
  );
}
export function useOrganizationSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationSearchQuery,
    OrganizationSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationSearchQuery, OrganizationSearchQueryVariables>(
    OrganizationSearchDocument,
    options,
  );
}
export type OrganizationSearchQueryHookResult = ReturnType<typeof useOrganizationSearchQuery>;
export type OrganizationSearchLazyQueryHookResult = ReturnType<
  typeof useOrganizationSearchLazyQuery
>;
export type OrganizationSearchQueryResult = Apollo.QueryResult<
  OrganizationSearchQuery,
  OrganizationSearchQueryVariables
>;
export const JobOfferDeleteDocument = gql`
  mutation JobOfferDelete($filter: JobOfferKeyFilter!) {
    jobOfferDelete(filter: $filter) {
      success
    }
  }
`;
export type JobOfferDeleteMutationFn = Apollo.MutationFunction<
  JobOfferDeleteMutation,
  JobOfferDeleteMutationVariables
>;

/**
 * __useJobOfferDeleteMutation__
 *
 * To run a mutation, you first call `useJobOfferDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobOfferDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobOfferDeleteMutation, { data, loading, error }] = useJobOfferDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useJobOfferDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<JobOfferDeleteMutation, JobOfferDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobOfferDeleteMutation, JobOfferDeleteMutationVariables>(
    JobOfferDeleteDocument,
    options,
  );
}
export type JobOfferDeleteMutationHookResult = ReturnType<typeof useJobOfferDeleteMutation>;
export type JobOfferDeleteMutationResult = Apollo.MutationResult<JobOfferDeleteMutation>;
export type JobOfferDeleteMutationOptions = Apollo.BaseMutationOptions<
  JobOfferDeleteMutation,
  JobOfferDeleteMutationVariables
>;
export const JobOfferRenewDocument = gql`
  mutation JobOfferRenew($id: ID!) {
    renewJobOffer(id: $id) {
      success
    }
  }
`;
export type JobOfferRenewMutationFn = Apollo.MutationFunction<
  JobOfferRenewMutation,
  JobOfferRenewMutationVariables
>;

/**
 * __useJobOfferRenewMutation__
 *
 * To run a mutation, you first call `useJobOfferRenewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobOfferRenewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobOfferRenewMutation, { data, loading, error }] = useJobOfferRenewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobOfferRenewMutation(
  baseOptions?: Apollo.MutationHookOptions<JobOfferRenewMutation, JobOfferRenewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobOfferRenewMutation, JobOfferRenewMutationVariables>(
    JobOfferRenewDocument,
    options,
  );
}
export type JobOfferRenewMutationHookResult = ReturnType<typeof useJobOfferRenewMutation>;
export type JobOfferRenewMutationResult = Apollo.MutationResult<JobOfferRenewMutation>;
export type JobOfferRenewMutationOptions = Apollo.BaseMutationOptions<
  JobOfferRenewMutation,
  JobOfferRenewMutationVariables
>;
export const CollectiveIntelligenceThreadCreateDocument = gql`
  mutation CollectiveIntelligenceThreadCreate($data: CollectiveIntelligenceThreadCreateInput!) {
    collectiveIntelligenceThreadCreate(data: $data) {
      ...CollectiveIntelligenceItem
    }
  }
  ${CollectiveIntelligenceItemFragmentDoc}
`;
export type CollectiveIntelligenceThreadCreateMutationFn = Apollo.MutationFunction<
  CollectiveIntelligenceThreadCreateMutation,
  CollectiveIntelligenceThreadCreateMutationVariables
>;

/**
 * __useCollectiveIntelligenceThreadCreateMutation__
 *
 * To run a mutation, you first call `useCollectiveIntelligenceThreadCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectiveIntelligenceThreadCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectiveIntelligenceThreadCreateMutation, { data, loading, error }] = useCollectiveIntelligenceThreadCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCollectiveIntelligenceThreadCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CollectiveIntelligenceThreadCreateMutation,
    CollectiveIntelligenceThreadCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CollectiveIntelligenceThreadCreateMutation,
    CollectiveIntelligenceThreadCreateMutationVariables
  >(CollectiveIntelligenceThreadCreateDocument, options);
}
export type CollectiveIntelligenceThreadCreateMutationHookResult = ReturnType<
  typeof useCollectiveIntelligenceThreadCreateMutation
>;
export type CollectiveIntelligenceThreadCreateMutationResult =
  Apollo.MutationResult<CollectiveIntelligenceThreadCreateMutation>;
export type CollectiveIntelligenceThreadCreateMutationOptions = Apollo.BaseMutationOptions<
  CollectiveIntelligenceThreadCreateMutation,
  CollectiveIntelligenceThreadCreateMutationVariables
>;
export const CollectiveIntelligenceThreadUpdateDocument = gql`
  mutation CollectiveIntelligenceThreadUpdate($data: CollectiveIntelligenceThreadUpdateInput!) {
    collectiveIntelligenceThreadUpdate(data: $data) {
      ...CollectiveIntelligenceItem
    }
  }
  ${CollectiveIntelligenceItemFragmentDoc}
`;
export type CollectiveIntelligenceThreadUpdateMutationFn = Apollo.MutationFunction<
  CollectiveIntelligenceThreadUpdateMutation,
  CollectiveIntelligenceThreadUpdateMutationVariables
>;

/**
 * __useCollectiveIntelligenceThreadUpdateMutation__
 *
 * To run a mutation, you first call `useCollectiveIntelligenceThreadUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectiveIntelligenceThreadUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectiveIntelligenceThreadUpdateMutation, { data, loading, error }] = useCollectiveIntelligenceThreadUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCollectiveIntelligenceThreadUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CollectiveIntelligenceThreadUpdateMutation,
    CollectiveIntelligenceThreadUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CollectiveIntelligenceThreadUpdateMutation,
    CollectiveIntelligenceThreadUpdateMutationVariables
  >(CollectiveIntelligenceThreadUpdateDocument, options);
}
export type CollectiveIntelligenceThreadUpdateMutationHookResult = ReturnType<
  typeof useCollectiveIntelligenceThreadUpdateMutation
>;
export type CollectiveIntelligenceThreadUpdateMutationResult =
  Apollo.MutationResult<CollectiveIntelligenceThreadUpdateMutation>;
export type CollectiveIntelligenceThreadUpdateMutationOptions = Apollo.BaseMutationOptions<
  CollectiveIntelligenceThreadUpdateMutation,
  CollectiveIntelligenceThreadUpdateMutationVariables
>;
export const CollectiveIntelligenceThreadsDocument = gql`
  query CollectiveIntelligenceThreads(
    $first: Int!
    $skip: Int
    $sort: [CollectiveIntelligenceThreadSort!]
    $filter: CollectiveIntelligenceThreadFilter
  ) {
    threads: collectiveIntelligenceThreadsList(
      first: $first
      skip: $skip
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        ...CollectiveIntelligenceItem
      }
    }
  }
  ${CollectiveIntelligenceItemFragmentDoc}
`;

/**
 * __useCollectiveIntelligenceThreadsQuery__
 *
 * To run a query within a React component, call `useCollectiveIntelligenceThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveIntelligenceThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveIntelligenceThreadsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCollectiveIntelligenceThreadsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveIntelligenceThreadsQuery,
    CollectiveIntelligenceThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CollectiveIntelligenceThreadsQuery,
    CollectiveIntelligenceThreadsQueryVariables
  >(CollectiveIntelligenceThreadsDocument, options);
}
export function useCollectiveIntelligenceThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveIntelligenceThreadsQuery,
    CollectiveIntelligenceThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CollectiveIntelligenceThreadsQuery,
    CollectiveIntelligenceThreadsQueryVariables
  >(CollectiveIntelligenceThreadsDocument, options);
}
export type CollectiveIntelligenceThreadsQueryHookResult = ReturnType<
  typeof useCollectiveIntelligenceThreadsQuery
>;
export type CollectiveIntelligenceThreadsLazyQueryHookResult = ReturnType<
  typeof useCollectiveIntelligenceThreadsLazyQuery
>;
export type CollectiveIntelligenceThreadsQueryResult = Apollo.QueryResult<
  CollectiveIntelligenceThreadsQuery,
  CollectiveIntelligenceThreadsQueryVariables
>;
export const CollectiveIntelligenceThreadDocument = gql`
  query CollectiveIntelligenceThread($id: ID!) {
    thread: collectiveIntelligenceThread(id: $id) {
      ...CollectiveIntelligenceItem
    }
  }
  ${CollectiveIntelligenceItemFragmentDoc}
`;

/**
 * __useCollectiveIntelligenceThreadQuery__
 *
 * To run a query within a React component, call `useCollectiveIntelligenceThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveIntelligenceThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveIntelligenceThreadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCollectiveIntelligenceThreadQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveIntelligenceThreadQuery,
    CollectiveIntelligenceThreadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CollectiveIntelligenceThreadQuery,
    CollectiveIntelligenceThreadQueryVariables
  >(CollectiveIntelligenceThreadDocument, options);
}
export function useCollectiveIntelligenceThreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveIntelligenceThreadQuery,
    CollectiveIntelligenceThreadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CollectiveIntelligenceThreadQuery,
    CollectiveIntelligenceThreadQueryVariables
  >(CollectiveIntelligenceThreadDocument, options);
}
export type CollectiveIntelligenceThreadQueryHookResult = ReturnType<
  typeof useCollectiveIntelligenceThreadQuery
>;
export type CollectiveIntelligenceThreadLazyQueryHookResult = ReturnType<
  typeof useCollectiveIntelligenceThreadLazyQuery
>;
export type CollectiveIntelligenceThreadQueryResult = Apollo.QueryResult<
  CollectiveIntelligenceThreadQuery,
  CollectiveIntelligenceThreadQueryVariables
>;
export const DiscountsListDocument = gql`
  query DiscountsList($first: Int, $skip: Int, $filter: DiscountFilter, $sort: [DiscountSort!]) {
    discounts: discountsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        ...DiscountListItem
      }
    }
  }
  ${DiscountListItemFragmentDoc}
`;

/**
 * __useDiscountsListQuery__
 *
 * To run a query within a React component, call `useDiscountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useDiscountsListQuery(
  baseOptions?: Apollo.QueryHookOptions<DiscountsListQuery, DiscountsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DiscountsListQuery, DiscountsListQueryVariables>(
    DiscountsListDocument,
    options,
  );
}
export function useDiscountsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DiscountsListQuery, DiscountsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DiscountsListQuery, DiscountsListQueryVariables>(
    DiscountsListDocument,
    options,
  );
}
export type DiscountsListQueryHookResult = ReturnType<typeof useDiscountsListQuery>;
export type DiscountsListLazyQueryHookResult = ReturnType<typeof useDiscountsListLazyQuery>;
export type DiscountsListQueryResult = Apollo.QueryResult<
  DiscountsListQuery,
  DiscountsListQueryVariables
>;
export const DiscountsCountDocument = gql`
  query DiscountsCount($filter: DiscountFilter, $skip: Int, $first: Int, $sort: [DiscountSort!]) {
    discounts: discountsList(filter: $filter, skip: $skip, first: $first, sort: $sort) {
      count
    }
  }
`;

/**
 * __useDiscountsCountQuery__
 *
 * To run a query within a React component, call `useDiscountsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useDiscountsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<DiscountsCountQuery, DiscountsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DiscountsCountQuery, DiscountsCountQueryVariables>(
    DiscountsCountDocument,
    options,
  );
}
export function useDiscountsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DiscountsCountQuery, DiscountsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DiscountsCountQuery, DiscountsCountQueryVariables>(
    DiscountsCountDocument,
    options,
  );
}
export type DiscountsCountQueryHookResult = ReturnType<typeof useDiscountsCountQuery>;
export type DiscountsCountLazyQueryHookResult = ReturnType<typeof useDiscountsCountLazyQuery>;
export type DiscountsCountQueryResult = Apollo.QueryResult<
  DiscountsCountQuery,
  DiscountsCountQueryVariables
>;
export const IncreaseDiscountViewsRequestDocument = gql`
  mutation IncreaseDiscountViewsRequest($id: ID!) {
    discountUpdateByFilter(data: { totalViews: { add: 1 } }, filter: { id: { equals: $id } }) {
      count
    }
  }
`;
export type IncreaseDiscountViewsRequestMutationFn = Apollo.MutationFunction<
  IncreaseDiscountViewsRequestMutation,
  IncreaseDiscountViewsRequestMutationVariables
>;

/**
 * __useIncreaseDiscountViewsRequestMutation__
 *
 * To run a mutation, you first call `useIncreaseDiscountViewsRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncreaseDiscountViewsRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [increaseDiscountViewsRequestMutation, { data, loading, error }] = useIncreaseDiscountViewsRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIncreaseDiscountViewsRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncreaseDiscountViewsRequestMutation,
    IncreaseDiscountViewsRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IncreaseDiscountViewsRequestMutation,
    IncreaseDiscountViewsRequestMutationVariables
  >(IncreaseDiscountViewsRequestDocument, options);
}
export type IncreaseDiscountViewsRequestMutationHookResult = ReturnType<
  typeof useIncreaseDiscountViewsRequestMutation
>;
export type IncreaseDiscountViewsRequestMutationResult =
  Apollo.MutationResult<IncreaseDiscountViewsRequestMutation>;
export type IncreaseDiscountViewsRequestMutationOptions = Apollo.BaseMutationOptions<
  IncreaseDiscountViewsRequestMutation,
  IncreaseDiscountViewsRequestMutationVariables
>;
export const IncreaseDiscountClicksRequestDocument = gql`
  mutation IncreaseDiscountClicksRequest($id: ID!) {
    discountUpdateByFilter(data: { totalClicks: { add: 1 } }, filter: { id: { equals: $id } }) {
      count
    }
  }
`;
export type IncreaseDiscountClicksRequestMutationFn = Apollo.MutationFunction<
  IncreaseDiscountClicksRequestMutation,
  IncreaseDiscountClicksRequestMutationVariables
>;

/**
 * __useIncreaseDiscountClicksRequestMutation__
 *
 * To run a mutation, you first call `useIncreaseDiscountClicksRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncreaseDiscountClicksRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [increaseDiscountClicksRequestMutation, { data, loading, error }] = useIncreaseDiscountClicksRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIncreaseDiscountClicksRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncreaseDiscountClicksRequestMutation,
    IncreaseDiscountClicksRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IncreaseDiscountClicksRequestMutation,
    IncreaseDiscountClicksRequestMutationVariables
  >(IncreaseDiscountClicksRequestDocument, options);
}
export type IncreaseDiscountClicksRequestMutationHookResult = ReturnType<
  typeof useIncreaseDiscountClicksRequestMutation
>;
export type IncreaseDiscountClicksRequestMutationResult =
  Apollo.MutationResult<IncreaseDiscountClicksRequestMutation>;
export type IncreaseDiscountClicksRequestMutationOptions = Apollo.BaseMutationOptions<
  IncreaseDiscountClicksRequestMutation,
  IncreaseDiscountClicksRequestMutationVariables
>;
export const RedeemDiscountDocument = gql`
  mutation RedeemDiscount($discount: DiscountKeyFilter!, $school: SchoolConfigurationKeyFilter!) {
    redeemDiscount(discount: $discount, school: $school) {
      success
    }
  }
`;
export type RedeemDiscountMutationFn = Apollo.MutationFunction<
  RedeemDiscountMutation,
  RedeemDiscountMutationVariables
>;

/**
 * __useRedeemDiscountMutation__
 *
 * To run a mutation, you first call `useRedeemDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemDiscountMutation, { data, loading, error }] = useRedeemDiscountMutation({
 *   variables: {
 *      discount: // value for 'discount'
 *      school: // value for 'school'
 *   },
 * });
 */
export function useRedeemDiscountMutation(
  baseOptions?: Apollo.MutationHookOptions<RedeemDiscountMutation, RedeemDiscountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RedeemDiscountMutation, RedeemDiscountMutationVariables>(
    RedeemDiscountDocument,
    options,
  );
}
export type RedeemDiscountMutationHookResult = ReturnType<typeof useRedeemDiscountMutation>;
export type RedeemDiscountMutationResult = Apollo.MutationResult<RedeemDiscountMutation>;
export type RedeemDiscountMutationOptions = Apollo.BaseMutationOptions<
  RedeemDiscountMutation,
  RedeemDiscountMutationVariables
>;
export const Get_Group_Members_RequestDocument = gql`
  query GET_GROUP_MEMBERS_REQUEST($userId: ID!) {
    groupsList(filter: { members: { some: { id: { equals: $userId } } } }) {
      items {
        id
      }
      count
    }
  }
`;

/**
 * __useGet_Group_Members_RequestQuery__
 *
 * To run a query within a React component, call `useGet_Group_Members_RequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Group_Members_RequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Group_Members_RequestQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGet_Group_Members_RequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    Get_Group_Members_RequestQuery,
    Get_Group_Members_RequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Group_Members_RequestQuery, Get_Group_Members_RequestQueryVariables>(
    Get_Group_Members_RequestDocument,
    options,
  );
}
export function useGet_Group_Members_RequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Group_Members_RequestQuery,
    Get_Group_Members_RequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Group_Members_RequestQuery,
    Get_Group_Members_RequestQueryVariables
  >(Get_Group_Members_RequestDocument, options);
}
export type Get_Group_Members_RequestQueryHookResult = ReturnType<
  typeof useGet_Group_Members_RequestQuery
>;
export type Get_Group_Members_RequestLazyQueryHookResult = ReturnType<
  typeof useGet_Group_Members_RequestLazyQuery
>;
export type Get_Group_Members_RequestQueryResult = Apollo.QueryResult<
  Get_Group_Members_RequestQuery,
  Get_Group_Members_RequestQueryVariables
>;
export const GroupAdminsDocument = gql`
  query groupAdmins($groupIds: [ID!]) {
    groupAdminPreferencesList(filter: { group: { some: { id: { in: $groupIds } } } }) {
      items {
        id
        user {
          id
        }
      }
      count
    }
  }
`;

/**
 * __useGroupAdminsQuery__
 *
 * To run a query within a React component, call `useGroupAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupAdminsQuery({
 *   variables: {
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useGroupAdminsQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupAdminsQuery, GroupAdminsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupAdminsQuery, GroupAdminsQueryVariables>(GroupAdminsDocument, options);
}
export function useGroupAdminsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupAdminsQuery, GroupAdminsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupAdminsQuery, GroupAdminsQueryVariables>(
    GroupAdminsDocument,
    options,
  );
}
export type GroupAdminsQueryHookResult = ReturnType<typeof useGroupAdminsQuery>;
export type GroupAdminsLazyQueryHookResult = ReturnType<typeof useGroupAdminsLazyQuery>;
export type GroupAdminsQueryResult = Apollo.QueryResult<
  GroupAdminsQuery,
  GroupAdminsQueryVariables
>;
export const GroupMembersDocument = gql`
  query GroupMembers($id: ID!) {
    group(id: $id) {
      creator: createdBy {
        ...MinimalUser
      }
      invitedUsers: groupToJoin {
        items {
          id
          status
          user {
            ...MinimalUser
          }
        }
      }
      adminUsers: groupAdminPreferences {
        items {
          id
          status
          user {
            ...MinimalUser
          }
        }
      }
    }
  }
  ${MinimalUserFragmentDoc}
`;

/**
 * __useGroupMembersQuery__
 *
 * To run a query within a React component, call `useGroupMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupMembersQuery(
  baseOptions: Apollo.QueryHookOptions<GroupMembersQuery, GroupMembersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupMembersQuery, GroupMembersQueryVariables>(
    GroupMembersDocument,
    options,
  );
}
export function useGroupMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupMembersQuery, GroupMembersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupMembersQuery, GroupMembersQueryVariables>(
    GroupMembersDocument,
    options,
  );
}
export type GroupMembersQueryHookResult = ReturnType<typeof useGroupMembersQuery>;
export type GroupMembersLazyQueryHookResult = ReturnType<typeof useGroupMembersLazyQuery>;
export type GroupMembersQueryResult = Apollo.QueryResult<
  GroupMembersQuery,
  GroupMembersQueryVariables
>;
export const GroupsListDocument = gql`
  query GroupsList($filter: GroupFilter, $sort: [GroupSort!], $first: Int, $skip: Int) {
    groupsList(filter: $filter, sort: $sort, first: $first, skip: $skip) {
      count
      items {
        ...GroupsListItem
      }
    }
  }
  ${GroupsListItemFragmentDoc}
`;

/**
 * __useGroupsListQuery__
 *
 * To run a query within a React component, call `useGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGroupsListQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupsListQuery, GroupsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupsListQuery, GroupsListQueryVariables>(GroupsListDocument, options);
}
export function useGroupsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupsListQuery, GroupsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupsListQuery, GroupsListQueryVariables>(
    GroupsListDocument,
    options,
  );
}
export type GroupsListQueryHookResult = ReturnType<typeof useGroupsListQuery>;
export type GroupsListLazyQueryHookResult = ReturnType<typeof useGroupsListLazyQuery>;
export type GroupsListQueryResult = Apollo.QueryResult<GroupsListQuery, GroupsListQueryVariables>;
export const GroupPostCreateDocument = gql`
  mutation GroupPostCreate($data: GroupPostCreateInput!) {
    groupPostCreate(data: $data) {
      ...GroupPostsListItem
    }
  }
  ${GroupPostsListItemFragmentDoc}
`;
export type GroupPostCreateMutationFn = Apollo.MutationFunction<
  GroupPostCreateMutation,
  GroupPostCreateMutationVariables
>;

/**
 * __useGroupPostCreateMutation__
 *
 * To run a mutation, you first call `useGroupPostCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupPostCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupPostCreateMutation, { data, loading, error }] = useGroupPostCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGroupPostCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupPostCreateMutation,
    GroupPostCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupPostCreateMutation, GroupPostCreateMutationVariables>(
    GroupPostCreateDocument,
    options,
  );
}
export type GroupPostCreateMutationHookResult = ReturnType<typeof useGroupPostCreateMutation>;
export type GroupPostCreateMutationResult = Apollo.MutationResult<GroupPostCreateMutation>;
export type GroupPostCreateMutationOptions = Apollo.BaseMutationOptions<
  GroupPostCreateMutation,
  GroupPostCreateMutationVariables
>;
export const GroupPostUpdateDocument = gql`
  mutation GroupPostUpdate($data: GroupPostUpdateInput!) {
    groupPostUpdate(data: $data) {
      ...GroupPostsListItem
    }
  }
  ${GroupPostsListItemFragmentDoc}
`;
export type GroupPostUpdateMutationFn = Apollo.MutationFunction<
  GroupPostUpdateMutation,
  GroupPostUpdateMutationVariables
>;

/**
 * __useGroupPostUpdateMutation__
 *
 * To run a mutation, you first call `useGroupPostUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupPostUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupPostUpdateMutation, { data, loading, error }] = useGroupPostUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGroupPostUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupPostUpdateMutation,
    GroupPostUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupPostUpdateMutation, GroupPostUpdateMutationVariables>(
    GroupPostUpdateDocument,
    options,
  );
}
export type GroupPostUpdateMutationHookResult = ReturnType<typeof useGroupPostUpdateMutation>;
export type GroupPostUpdateMutationResult = Apollo.MutationResult<GroupPostUpdateMutation>;
export type GroupPostUpdateMutationOptions = Apollo.BaseMutationOptions<
  GroupPostUpdateMutation,
  GroupPostUpdateMutationVariables
>;
export const GroupPostsListDocument = gql`
  query GroupPostsList(
    $first: Int!
    $skip: Int
    $sort: [GroupPostSort!]
    $filter: GroupPostFilter
    $pinnedFilter: GroupPostFilter!
  ) {
    groupPostsList(first: $first, skip: $skip, sort: $sort, filter: $filter) {
      items {
        ...GroupPostsListItem
      }
    }
    pinnedPostsList: groupPostsList(filter: $pinnedFilter, sort: $sort) {
      items {
        ...GroupPostsListItem
      }
    }
  }
  ${GroupPostsListItemFragmentDoc}
`;

/**
 * __useGroupPostsListQuery__
 *
 * To run a query within a React component, call `useGroupPostsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupPostsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupPostsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      pinnedFilter: // value for 'pinnedFilter'
 *   },
 * });
 */
export function useGroupPostsListQuery(
  baseOptions: Apollo.QueryHookOptions<GroupPostsListQuery, GroupPostsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupPostsListQuery, GroupPostsListQueryVariables>(
    GroupPostsListDocument,
    options,
  );
}
export function useGroupPostsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupPostsListQuery, GroupPostsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupPostsListQuery, GroupPostsListQueryVariables>(
    GroupPostsListDocument,
    options,
  );
}
export type GroupPostsListQueryHookResult = ReturnType<typeof useGroupPostsListQuery>;
export type GroupPostsListLazyQueryHookResult = ReturnType<typeof useGroupPostsListLazyQuery>;
export type GroupPostsListQueryResult = Apollo.QueryResult<
  GroupPostsListQuery,
  GroupPostsListQueryVariables
>;
export const GroupPostsCountDocument = gql`
  query GroupPostsCount($filter: GroupPostFilter) {
    groupPostsList(filter: $filter) {
      count
    }
  }
`;

/**
 * __useGroupPostsCountQuery__
 *
 * To run a query within a React component, call `useGroupPostsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupPostsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupPostsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGroupPostsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupPostsCountQuery, GroupPostsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupPostsCountQuery, GroupPostsCountQueryVariables>(
    GroupPostsCountDocument,
    options,
  );
}
export function useGroupPostsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupPostsCountQuery, GroupPostsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupPostsCountQuery, GroupPostsCountQueryVariables>(
    GroupPostsCountDocument,
    options,
  );
}
export type GroupPostsCountQueryHookResult = ReturnType<typeof useGroupPostsCountQuery>;
export type GroupPostsCountLazyQueryHookResult = ReturnType<typeof useGroupPostsCountLazyQuery>;
export type GroupPostsCountQueryResult = Apollo.QueryResult<
  GroupPostsCountQuery,
  GroupPostsCountQueryVariables
>;
export const GroupDetailsDocument = gql`
  query GroupDetails($id: ID) {
    group(id: $id) {
      ...GroupsListItem
    }
  }
  ${GroupsListItemFragmentDoc}
`;

/**
 * __useGroupDetailsQuery__
 *
 * To run a query within a React component, call `useGroupDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupDetailsQuery, GroupDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupDetailsQuery, GroupDetailsQueryVariables>(
    GroupDetailsDocument,
    options,
  );
}
export function useGroupDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupDetailsQuery, GroupDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupDetailsQuery, GroupDetailsQueryVariables>(
    GroupDetailsDocument,
    options,
  );
}
export type GroupDetailsQueryHookResult = ReturnType<typeof useGroupDetailsQuery>;
export type GroupDetailsLazyQueryHookResult = ReturnType<typeof useGroupDetailsLazyQuery>;
export type GroupDetailsQueryResult = Apollo.QueryResult<
  GroupDetailsQuery,
  GroupDetailsQueryVariables
>;
export const GroupCreateRequestDocument = gql`
  mutation GroupCreateRequest($data: GroupCreateInput!) {
    groupCreateRequest(data: $data) {
      groupId
    }
  }
`;
export type GroupCreateRequestMutationFn = Apollo.MutationFunction<
  GroupCreateRequestMutation,
  GroupCreateRequestMutationVariables
>;

/**
 * __useGroupCreateRequestMutation__
 *
 * To run a mutation, you first call `useGroupCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupCreateRequestMutation, { data, loading, error }] = useGroupCreateRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGroupCreateRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupCreateRequestMutation,
    GroupCreateRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupCreateRequestMutation, GroupCreateRequestMutationVariables>(
    GroupCreateRequestDocument,
    options,
  );
}
export type GroupCreateRequestMutationHookResult = ReturnType<typeof useGroupCreateRequestMutation>;
export type GroupCreateRequestMutationResult = Apollo.MutationResult<GroupCreateRequestMutation>;
export type GroupCreateRequestMutationOptions = Apollo.BaseMutationOptions<
  GroupCreateRequestMutation,
  GroupCreateRequestMutationVariables
>;
export const UsersIdsSearchDocument = gql`
  query UsersIdsSearch($filter: UserFilter!) {
    usersList(filter: $filter) {
      items {
        id
      }
    }
  }
`;

/**
 * __useUsersIdsSearchQuery__
 *
 * To run a query within a React component, call `useUsersIdsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersIdsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersIdsSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUsersIdsSearchQuery(
  baseOptions: Apollo.QueryHookOptions<UsersIdsSearchQuery, UsersIdsSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersIdsSearchQuery, UsersIdsSearchQueryVariables>(
    UsersIdsSearchDocument,
    options,
  );
}
export function useUsersIdsSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersIdsSearchQuery, UsersIdsSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersIdsSearchQuery, UsersIdsSearchQueryVariables>(
    UsersIdsSearchDocument,
    options,
  );
}
export type UsersIdsSearchQueryHookResult = ReturnType<typeof useUsersIdsSearchQuery>;
export type UsersIdsSearchLazyQueryHookResult = ReturnType<typeof useUsersIdsSearchLazyQuery>;
export type UsersIdsSearchQueryResult = Apollo.QueryResult<
  UsersIdsSearchQuery,
  UsersIdsSearchQueryVariables
>;
export const GroupsDocument = gql`
  query Groups($first: Int, $skip: Int, $filter: GroupFilter, $sort: [GroupSort!]) {
    groupsList(filter: $filter, first: $first, skip: $skip, sort: $sort) {
      count
      items {
        ...GroupDetails
      }
    }
  }
  ${GroupDetailsFragmentDoc}
`;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
}
export function useGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
}
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const DiscountByIdDocument = gql`
  query DiscountById($id: ID!) {
    discount(id: $id) {
      ...DiscountPresentation
    }
  }
  ${DiscountPresentationFragmentDoc}
`;

/**
 * __useDiscountByIdQuery__
 *
 * To run a query within a React component, call `useDiscountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscountByIdQuery(
  baseOptions: Apollo.QueryHookOptions<DiscountByIdQuery, DiscountByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DiscountByIdQuery, DiscountByIdQueryVariables>(
    DiscountByIdDocument,
    options,
  );
}
export function useDiscountByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DiscountByIdQuery, DiscountByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DiscountByIdQuery, DiscountByIdQueryVariables>(
    DiscountByIdDocument,
    options,
  );
}
export type DiscountByIdQueryHookResult = ReturnType<typeof useDiscountByIdQuery>;
export type DiscountByIdLazyQueryHookResult = ReturnType<typeof useDiscountByIdLazyQuery>;
export type DiscountByIdQueryResult = Apollo.QueryResult<
  DiscountByIdQuery,
  DiscountByIdQueryVariables
>;
export const EventByIdDocument = gql`
  query EventById($id: ID!) {
    event(id: $id) {
      ...EventInfo
    }
  }
  ${EventInfoFragmentDoc}
`;

/**
 * __useEventByIdQuery__
 *
 * To run a query within a React component, call `useEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventByIdQuery(
  baseOptions: Apollo.QueryHookOptions<EventByIdQuery, EventByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventByIdQuery, EventByIdQueryVariables>(EventByIdDocument, options);
}
export function useEventByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventByIdQuery, EventByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventByIdQuery, EventByIdQueryVariables>(EventByIdDocument, options);
}
export type EventByIdQueryHookResult = ReturnType<typeof useEventByIdQuery>;
export type EventByIdLazyQueryHookResult = ReturnType<typeof useEventByIdLazyQuery>;
export type EventByIdQueryResult = Apollo.QueryResult<EventByIdQuery, EventByIdQueryVariables>;
export const JobOfferByIdDocument = gql`
  query JobOfferById($id: ID!) {
    jobOffer(id: $id) {
      ...CareerInfo
    }
  }
  ${CareerInfoFragmentDoc}
`;

/**
 * __useJobOfferByIdQuery__
 *
 * To run a query within a React component, call `useJobOfferByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobOfferByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobOfferByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobOfferByIdQuery(
  baseOptions: Apollo.QueryHookOptions<JobOfferByIdQuery, JobOfferByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobOfferByIdQuery, JobOfferByIdQueryVariables>(
    JobOfferByIdDocument,
    options,
  );
}
export function useJobOfferByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobOfferByIdQuery, JobOfferByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobOfferByIdQuery, JobOfferByIdQueryVariables>(
    JobOfferByIdDocument,
    options,
  );
}
export type JobOfferByIdQueryHookResult = ReturnType<typeof useJobOfferByIdQuery>;
export type JobOfferByIdLazyQueryHookResult = ReturnType<typeof useJobOfferByIdLazyQuery>;
export type JobOfferByIdQueryResult = Apollo.QueryResult<
  JobOfferByIdQuery,
  JobOfferByIdQueryVariables
>;
export const HomeFeedPostCreateDocument = gql`
  mutation HomeFeedPostCreate($data: HomeFeedPostCreateInput!) {
    homeFeedPostCreate(data: $data) {
      ...HomeFeedPostsListItem
    }
  }
  ${HomeFeedPostsListItemFragmentDoc}
`;
export type HomeFeedPostCreateMutationFn = Apollo.MutationFunction<
  HomeFeedPostCreateMutation,
  HomeFeedPostCreateMutationVariables
>;

/**
 * __useHomeFeedPostCreateMutation__
 *
 * To run a mutation, you first call `useHomeFeedPostCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHomeFeedPostCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [homeFeedPostCreateMutation, { data, loading, error }] = useHomeFeedPostCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useHomeFeedPostCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HomeFeedPostCreateMutation,
    HomeFeedPostCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HomeFeedPostCreateMutation, HomeFeedPostCreateMutationVariables>(
    HomeFeedPostCreateDocument,
    options,
  );
}
export type HomeFeedPostCreateMutationHookResult = ReturnType<typeof useHomeFeedPostCreateMutation>;
export type HomeFeedPostCreateMutationResult = Apollo.MutationResult<HomeFeedPostCreateMutation>;
export type HomeFeedPostCreateMutationOptions = Apollo.BaseMutationOptions<
  HomeFeedPostCreateMutation,
  HomeFeedPostCreateMutationVariables
>;
export const HomeFeedPostUpdateDocument = gql`
  mutation HomeFeedPostUpdate($data: UpdateHomeFeedPostRequestInput!) {
    updateHomeFeedPostRequest(data: $data) {
      success
      verified
    }
  }
`;
export type HomeFeedPostUpdateMutationFn = Apollo.MutationFunction<
  HomeFeedPostUpdateMutation,
  HomeFeedPostUpdateMutationVariables
>;

/**
 * __useHomeFeedPostUpdateMutation__
 *
 * To run a mutation, you first call `useHomeFeedPostUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHomeFeedPostUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [homeFeedPostUpdateMutation, { data, loading, error }] = useHomeFeedPostUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useHomeFeedPostUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HomeFeedPostUpdateMutation,
    HomeFeedPostUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HomeFeedPostUpdateMutation, HomeFeedPostUpdateMutationVariables>(
    HomeFeedPostUpdateDocument,
    options,
  );
}
export type HomeFeedPostUpdateMutationHookResult = ReturnType<typeof useHomeFeedPostUpdateMutation>;
export type HomeFeedPostUpdateMutationResult = Apollo.MutationResult<HomeFeedPostUpdateMutation>;
export type HomeFeedPostUpdateMutationOptions = Apollo.BaseMutationOptions<
  HomeFeedPostUpdateMutation,
  HomeFeedPostUpdateMutationVariables
>;
export const HomeFeedSinglePostDocument = gql`
  query HomeFeedSinglePost($id: ID!) {
    homeFeedPost(id: $id) {
      ...HomeFeedPostsListItem
    }
  }
  ${HomeFeedPostsListItemFragmentDoc}
`;

/**
 * __useHomeFeedSinglePostQuery__
 *
 * To run a query within a React component, call `useHomeFeedSinglePostQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeFeedSinglePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeFeedSinglePostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHomeFeedSinglePostQuery(
  baseOptions: Apollo.QueryHookOptions<HomeFeedSinglePostQuery, HomeFeedSinglePostQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeFeedSinglePostQuery, HomeFeedSinglePostQueryVariables>(
    HomeFeedSinglePostDocument,
    options,
  );
}
export function useHomeFeedSinglePostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeFeedSinglePostQuery,
    HomeFeedSinglePostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeFeedSinglePostQuery, HomeFeedSinglePostQueryVariables>(
    HomeFeedSinglePostDocument,
    options,
  );
}
export type HomeFeedSinglePostQueryHookResult = ReturnType<typeof useHomeFeedSinglePostQuery>;
export type HomeFeedSinglePostLazyQueryHookResult = ReturnType<
  typeof useHomeFeedSinglePostLazyQuery
>;
export type HomeFeedSinglePostQueryResult = Apollo.QueryResult<
  HomeFeedSinglePostQuery,
  HomeFeedSinglePostQueryVariables
>;
export const OrganizationContactDocument = gql`
  mutation OrganizationContact($subject: String!, $text: String!, $userIds: [String!]) {
    contactOrganizationRequest(subject: $subject, text: $text, userIds: $userIds) {
      success
    }
  }
`;
export type OrganizationContactMutationFn = Apollo.MutationFunction<
  OrganizationContactMutation,
  OrganizationContactMutationVariables
>;

/**
 * __useOrganizationContactMutation__
 *
 * To run a mutation, you first call `useOrganizationContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationContactMutation, { data, loading, error }] = useOrganizationContactMutation({
 *   variables: {
 *      subject: // value for 'subject'
 *      text: // value for 'text'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useOrganizationContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrganizationContactMutation,
    OrganizationContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrganizationContactMutation, OrganizationContactMutationVariables>(
    OrganizationContactDocument,
    options,
  );
}
export type OrganizationContactMutationHookResult = ReturnType<
  typeof useOrganizationContactMutation
>;
export type OrganizationContactMutationResult = Apollo.MutationResult<OrganizationContactMutation>;
export type OrganizationContactMutationOptions = Apollo.BaseMutationOptions<
  OrganizationContactMutation,
  OrganizationContactMutationVariables
>;
export const Job_Offer_UpdateDocument = gql`
  mutation JOB_OFFER_UPDATE($data: JobOfferUpdateInput!) {
    jobOfferUpdate(data: $data) {
      id
      engagementCount
    }
  }
`;
export type Job_Offer_UpdateMutationFn = Apollo.MutationFunction<
  Job_Offer_UpdateMutation,
  Job_Offer_UpdateMutationVariables
>;

/**
 * __useJob_Offer_UpdateMutation__
 *
 * To run a mutation, you first call `useJob_Offer_UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJob_Offer_UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobOfferUpdateMutation, { data, loading, error }] = useJob_Offer_UpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useJob_Offer_UpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Job_Offer_UpdateMutation,
    Job_Offer_UpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Job_Offer_UpdateMutation, Job_Offer_UpdateMutationVariables>(
    Job_Offer_UpdateDocument,
    options,
  );
}
export type Job_Offer_UpdateMutationHookResult = ReturnType<typeof useJob_Offer_UpdateMutation>;
export type Job_Offer_UpdateMutationResult = Apollo.MutationResult<Job_Offer_UpdateMutation>;
export type Job_Offer_UpdateMutationOptions = Apollo.BaseMutationOptions<
  Job_Offer_UpdateMutation,
  Job_Offer_UpdateMutationVariables
>;
export const Job_QueryDocument = gql`
  query JOB_QUERY($id: ID) {
    jobOffer(id: $id) {
      id
      engagementCount
    }
  }
`;

/**
 * __useJob_QueryQuery__
 *
 * To run a query within a React component, call `useJob_QueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useJob_QueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJob_QueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJob_QueryQuery(
  baseOptions?: Apollo.QueryHookOptions<Job_QueryQuery, Job_QueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Job_QueryQuery, Job_QueryQueryVariables>(Job_QueryDocument, options);
}
export function useJob_QueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Job_QueryQuery, Job_QueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Job_QueryQuery, Job_QueryQueryVariables>(Job_QueryDocument, options);
}
export type Job_QueryQueryHookResult = ReturnType<typeof useJob_QueryQuery>;
export type Job_QueryLazyQueryHookResult = ReturnType<typeof useJob_QueryLazyQuery>;
export type Job_QueryQueryResult = Apollo.QueryResult<Job_QueryQuery, Job_QueryQueryVariables>;
export const HomeFeedJobOffersListDocument = gql`
  query HomeFeedJobOffersList {
    jobOffersList {
      items {
        ...CareerInfo
      }
    }
  }
  ${CareerInfoFragmentDoc}
`;

/**
 * __useHomeFeedJobOffersListQuery__
 *
 * To run a query within a React component, call `useHomeFeedJobOffersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeFeedJobOffersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeFeedJobOffersListQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeFeedJobOffersListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeFeedJobOffersListQuery,
    HomeFeedJobOffersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeFeedJobOffersListQuery, HomeFeedJobOffersListQueryVariables>(
    HomeFeedJobOffersListDocument,
    options,
  );
}
export function useHomeFeedJobOffersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeFeedJobOffersListQuery,
    HomeFeedJobOffersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeFeedJobOffersListQuery, HomeFeedJobOffersListQueryVariables>(
    HomeFeedJobOffersListDocument,
    options,
  );
}
export type HomeFeedJobOffersListQueryHookResult = ReturnType<typeof useHomeFeedJobOffersListQuery>;
export type HomeFeedJobOffersListLazyQueryHookResult = ReturnType<
  typeof useHomeFeedJobOffersListLazyQuery
>;
export type HomeFeedJobOffersListQueryResult = Apollo.QueryResult<
  HomeFeedJobOffersListQuery,
  HomeFeedJobOffersListQueryVariables
>;
export const HomeFeedUsersBirthdayListDocument = gql`
  query HomeFeedUsersBirthdayList($filter: UserFilter!) {
    usersList(sort: { birthDate: ASC }, filter: $filter) {
      items {
        ...UserListItem
      }
    }
  }
  ${UserListItemFragmentDoc}
`;

/**
 * __useHomeFeedUsersBirthdayListQuery__
 *
 * To run a query within a React component, call `useHomeFeedUsersBirthdayListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeFeedUsersBirthdayListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeFeedUsersBirthdayListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHomeFeedUsersBirthdayListQuery(
  baseOptions: Apollo.QueryHookOptions<
    HomeFeedUsersBirthdayListQuery,
    HomeFeedUsersBirthdayListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeFeedUsersBirthdayListQuery, HomeFeedUsersBirthdayListQueryVariables>(
    HomeFeedUsersBirthdayListDocument,
    options,
  );
}
export function useHomeFeedUsersBirthdayListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeFeedUsersBirthdayListQuery,
    HomeFeedUsersBirthdayListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HomeFeedUsersBirthdayListQuery,
    HomeFeedUsersBirthdayListQueryVariables
  >(HomeFeedUsersBirthdayListDocument, options);
}
export type HomeFeedUsersBirthdayListQueryHookResult = ReturnType<
  typeof useHomeFeedUsersBirthdayListQuery
>;
export type HomeFeedUsersBirthdayListLazyQueryHookResult = ReturnType<
  typeof useHomeFeedUsersBirthdayListLazyQuery
>;
export type HomeFeedUsersBirthdayListQueryResult = Apollo.QueryResult<
  HomeFeedUsersBirthdayListQuery,
  HomeFeedUsersBirthdayListQueryVariables
>;
export const HomeFeedDiscountsListDocument = gql`
  query HomeFeedDiscountsList($dateNow: DateTime!, $first: Int) {
    discountsList(
      filter: {
        status: { equals: "active" }
        startDate: { lte: $dateNow }
        expirationDate: { gte: $dateNow }
      }
      first: $first
    ) {
      items {
        ...DiscountListItem
      }
    }
  }
  ${DiscountListItemFragmentDoc}
`;

/**
 * __useHomeFeedDiscountsListQuery__
 *
 * To run a query within a React component, call `useHomeFeedDiscountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeFeedDiscountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeFeedDiscountsListQuery({
 *   variables: {
 *      dateNow: // value for 'dateNow'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useHomeFeedDiscountsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    HomeFeedDiscountsListQuery,
    HomeFeedDiscountsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeFeedDiscountsListQuery, HomeFeedDiscountsListQueryVariables>(
    HomeFeedDiscountsListDocument,
    options,
  );
}
export function useHomeFeedDiscountsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeFeedDiscountsListQuery,
    HomeFeedDiscountsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeFeedDiscountsListQuery, HomeFeedDiscountsListQueryVariables>(
    HomeFeedDiscountsListDocument,
    options,
  );
}
export type HomeFeedDiscountsListQueryHookResult = ReturnType<typeof useHomeFeedDiscountsListQuery>;
export type HomeFeedDiscountsListLazyQueryHookResult = ReturnType<
  typeof useHomeFeedDiscountsListLazyQuery
>;
export type HomeFeedDiscountsListQueryResult = Apollo.QueryResult<
  HomeFeedDiscountsListQuery,
  HomeFeedDiscountsListQueryVariables
>;
export const HomeFeedItemsDocument = gql`
  query HomeFeedItems(
    $filter: HomeFeedItemFilter
    $first: Int
    $skip: Int
    $sort: [HomeFeedItemSort!]
  ) {
    feed: homeFeedItemsList(filter: $filter, first: $first, skip: $skip, sort: $sort) {
      count
      items {
        ...HomeFeedItem
      }
    }
  }
  ${HomeFeedItemFragmentDoc}
`;

/**
 * __useHomeFeedItemsQuery__
 *
 * To run a query within a React component, call `useHomeFeedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeFeedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeFeedItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useHomeFeedItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<HomeFeedItemsQuery, HomeFeedItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeFeedItemsQuery, HomeFeedItemsQueryVariables>(
    HomeFeedItemsDocument,
    options,
  );
}
export function useHomeFeedItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HomeFeedItemsQuery, HomeFeedItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeFeedItemsQuery, HomeFeedItemsQueryVariables>(
    HomeFeedItemsDocument,
    options,
  );
}
export type HomeFeedItemsQueryHookResult = ReturnType<typeof useHomeFeedItemsQuery>;
export type HomeFeedItemsLazyQueryHookResult = ReturnType<typeof useHomeFeedItemsLazyQuery>;
export type HomeFeedItemsQueryResult = Apollo.QueryResult<
  HomeFeedItemsQuery,
  HomeFeedItemsQueryVariables
>;
export const HomeFeedItemsCountDocument = gql`
  query HomeFeedItemsCount($filter: HomeFeedItemFilter) {
    feed: homeFeedItemsList(filter: $filter) {
      count
    }
  }
`;

/**
 * __useHomeFeedItemsCountQuery__
 *
 * To run a query within a React component, call `useHomeFeedItemsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeFeedItemsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeFeedItemsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHomeFeedItemsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<HomeFeedItemsCountQuery, HomeFeedItemsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeFeedItemsCountQuery, HomeFeedItemsCountQueryVariables>(
    HomeFeedItemsCountDocument,
    options,
  );
}
export function useHomeFeedItemsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeFeedItemsCountQuery,
    HomeFeedItemsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeFeedItemsCountQuery, HomeFeedItemsCountQueryVariables>(
    HomeFeedItemsCountDocument,
    options,
  );
}
export type HomeFeedItemsCountQueryHookResult = ReturnType<typeof useHomeFeedItemsCountQuery>;
export type HomeFeedItemsCountLazyQueryHookResult = ReturnType<
  typeof useHomeFeedItemsCountLazyQuery
>;
export type HomeFeedItemsCountQueryResult = Apollo.QueryResult<
  HomeFeedItemsCountQuery,
  HomeFeedItemsCountQueryVariables
>;
export const HomeFeedSingleItemDocument = gql`
  query HomeFeedSingleItem($id: ID!) {
    homeFeedItem(id: $id) {
      updatedAt
      text
      status
      startDate
      reportStatus
      postType
      postDate
      groupId
      legacyDate
      mentions
      discount
      media
      location
      isPinned
      id
      expirationDate
      createdAt
      commentsAllowed
      authorId
      authorFirstName
      authorLastName
      authorAvatar
      amountPercent
    }
  }
`;

/**
 * __useHomeFeedSingleItemQuery__
 *
 * To run a query within a React component, call `useHomeFeedSingleItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeFeedSingleItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeFeedSingleItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHomeFeedSingleItemQuery(
  baseOptions: Apollo.QueryHookOptions<HomeFeedSingleItemQuery, HomeFeedSingleItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeFeedSingleItemQuery, HomeFeedSingleItemQueryVariables>(
    HomeFeedSingleItemDocument,
    options,
  );
}
export function useHomeFeedSingleItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeFeedSingleItemQuery,
    HomeFeedSingleItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeFeedSingleItemQuery, HomeFeedSingleItemQueryVariables>(
    HomeFeedSingleItemDocument,
    options,
  );
}
export type HomeFeedSingleItemQueryHookResult = ReturnType<typeof useHomeFeedSingleItemQuery>;
export type HomeFeedSingleItemLazyQueryHookResult = ReturnType<
  typeof useHomeFeedSingleItemLazyQuery
>;
export type HomeFeedSingleItemQueryResult = Apollo.QueryResult<
  HomeFeedSingleItemQuery,
  HomeFeedSingleItemQueryVariables
>;
export const SendInboxMessageNotificationRequestDocument = gql`
  mutation SendInboxMessageNotificationRequest($data: SendInboxMessageNotificationRequestInput!) {
    sendInboxMessageNotificationRequest(data: $data) {
      success
    }
  }
`;
export type SendInboxMessageNotificationRequestMutationFn = Apollo.MutationFunction<
  SendInboxMessageNotificationRequestMutation,
  SendInboxMessageNotificationRequestMutationVariables
>;

/**
 * __useSendInboxMessageNotificationRequestMutation__
 *
 * To run a mutation, you first call `useSendInboxMessageNotificationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInboxMessageNotificationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInboxMessageNotificationRequestMutation, { data, loading, error }] = useSendInboxMessageNotificationRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendInboxMessageNotificationRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendInboxMessageNotificationRequestMutation,
    SendInboxMessageNotificationRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendInboxMessageNotificationRequestMutation,
    SendInboxMessageNotificationRequestMutationVariables
  >(SendInboxMessageNotificationRequestDocument, options);
}
export type SendInboxMessageNotificationRequestMutationHookResult = ReturnType<
  typeof useSendInboxMessageNotificationRequestMutation
>;
export type SendInboxMessageNotificationRequestMutationResult =
  Apollo.MutationResult<SendInboxMessageNotificationRequestMutation>;
export type SendInboxMessageNotificationRequestMutationOptions = Apollo.BaseMutationOptions<
  SendInboxMessageNotificationRequestMutation,
  SendInboxMessageNotificationRequestMutationVariables
>;
export const InboxChatsListDocument = gql`
  query InboxChatsList(
    $filter: InboxFilter
    $sort: [InboxSort!]!
    $first: Int!
    $firstUsers: Int = 10
    $firstMessages: Int = 5
    $sortMessages: [InboxMessageSort!]
    $filterMessages: InboxMessageFilter
  ) {
    inboxes: inboxesList(first: $first, filter: $filter, sort: $sort) {
      count
      items {
        ...Inbox
      }
    }
  }
  ${InboxFragmentDoc}
`;

/**
 * __useInboxChatsListQuery__
 *
 * To run a query within a React component, call `useInboxChatsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxChatsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxChatsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      firstUsers: // value for 'firstUsers'
 *      firstMessages: // value for 'firstMessages'
 *      sortMessages: // value for 'sortMessages'
 *      filterMessages: // value for 'filterMessages'
 *   },
 * });
 */
export function useInboxChatsListQuery(
  baseOptions: Apollo.QueryHookOptions<InboxChatsListQuery, InboxChatsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InboxChatsListQuery, InboxChatsListQueryVariables>(
    InboxChatsListDocument,
    options,
  );
}
export function useInboxChatsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InboxChatsListQuery, InboxChatsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InboxChatsListQuery, InboxChatsListQueryVariables>(
    InboxChatsListDocument,
    options,
  );
}
export type InboxChatsListQueryHookResult = ReturnType<typeof useInboxChatsListQuery>;
export type InboxChatsListLazyQueryHookResult = ReturnType<typeof useInboxChatsListLazyQuery>;
export type InboxChatsListQueryResult = Apollo.QueryResult<
  InboxChatsListQuery,
  InboxChatsListQueryVariables
>;
export const InboxMessagesListDocument = gql`
  query InboxMessagesList(
    $inboxId: ID!
    $firstUsers: Int = 10
    $firstMessages: Int!
    $sortMessages: [InboxMessageSort!]
    $filterMessages: InboxMessageFilter!
  ) {
    inbox(id: $inboxId) {
      ...Inbox
    }
  }
  ${InboxFragmentDoc}
`;

/**
 * __useInboxMessagesListQuery__
 *
 * To run a query within a React component, call `useInboxMessagesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxMessagesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxMessagesListQuery({
 *   variables: {
 *      inboxId: // value for 'inboxId'
 *      firstUsers: // value for 'firstUsers'
 *      firstMessages: // value for 'firstMessages'
 *      sortMessages: // value for 'sortMessages'
 *      filterMessages: // value for 'filterMessages'
 *   },
 * });
 */
export function useInboxMessagesListQuery(
  baseOptions: Apollo.QueryHookOptions<InboxMessagesListQuery, InboxMessagesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InboxMessagesListQuery, InboxMessagesListQueryVariables>(
    InboxMessagesListDocument,
    options,
  );
}
export function useInboxMessagesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InboxMessagesListQuery,
    InboxMessagesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InboxMessagesListQuery, InboxMessagesListQueryVariables>(
    InboxMessagesListDocument,
    options,
  );
}
export type InboxMessagesListQueryHookResult = ReturnType<typeof useInboxMessagesListQuery>;
export type InboxMessagesListLazyQueryHookResult = ReturnType<typeof useInboxMessagesListLazyQuery>;
export type InboxMessagesListQueryResult = Apollo.QueryResult<
  InboxMessagesListQuery,
  InboxMessagesListQueryVariables
>;
export const InboxMessagesCountDocument = gql`
  query InboxMessagesCount(
    $inboxId: ID!
    $sort: [InboxMessageSort!]
    $filter: InboxMessageFilter!
  ) {
    inbox(id: $inboxId) {
      id
      messages(sort: $sort, filter: $filter) {
        count
      }
    }
  }
`;

/**
 * __useInboxMessagesCountQuery__
 *
 * To run a query within a React component, call `useInboxMessagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxMessagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxMessagesCountQuery({
 *   variables: {
 *      inboxId: // value for 'inboxId'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInboxMessagesCountQuery(
  baseOptions: Apollo.QueryHookOptions<InboxMessagesCountQuery, InboxMessagesCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InboxMessagesCountQuery, InboxMessagesCountQueryVariables>(
    InboxMessagesCountDocument,
    options,
  );
}
export function useInboxMessagesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InboxMessagesCountQuery,
    InboxMessagesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InboxMessagesCountQuery, InboxMessagesCountQueryVariables>(
    InboxMessagesCountDocument,
    options,
  );
}
export type InboxMessagesCountQueryHookResult = ReturnType<typeof useInboxMessagesCountQuery>;
export type InboxMessagesCountLazyQueryHookResult = ReturnType<
  typeof useInboxMessagesCountLazyQuery
>;
export type InboxMessagesCountQueryResult = Apollo.QueryResult<
  InboxMessagesCountQuery,
  InboxMessagesCountQueryVariables
>;
export const InboxMessageCreateDocument = gql`
  mutation InboxMessageCreate($data: InboxMessageCreateInput!) {
    inboxMessageCreate(data: $data) {
      id
      createdAt
      text
      isRead
      isDeleted
      media {
        items {
          id
          downloadUrl
        }
      }
      author {
        id
      }
      inbox {
        id
        type
        destinationGroups
        users {
          items {
            id
          }
        }
      }
    }
  }
`;
export type InboxMessageCreateMutationFn = Apollo.MutationFunction<
  InboxMessageCreateMutation,
  InboxMessageCreateMutationVariables
>;

/**
 * __useInboxMessageCreateMutation__
 *
 * To run a mutation, you first call `useInboxMessageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxMessageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxMessageCreateMutation, { data, loading, error }] = useInboxMessageCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInboxMessageCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InboxMessageCreateMutation,
    InboxMessageCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InboxMessageCreateMutation, InboxMessageCreateMutationVariables>(
    InboxMessageCreateDocument,
    options,
  );
}
export type InboxMessageCreateMutationHookResult = ReturnType<typeof useInboxMessageCreateMutation>;
export type InboxMessageCreateMutationResult = Apollo.MutationResult<InboxMessageCreateMutation>;
export type InboxMessageCreateMutationOptions = Apollo.BaseMutationOptions<
  InboxMessageCreateMutation,
  InboxMessageCreateMutationVariables
>;
export const InboxCreateDocument = gql`
  mutation InboxCreate($data: InboxCreateInput!) {
    inboxCreate(data: $data) {
      id
    }
  }
`;
export type InboxCreateMutationFn = Apollo.MutationFunction<
  InboxCreateMutation,
  InboxCreateMutationVariables
>;

/**
 * __useInboxCreateMutation__
 *
 * To run a mutation, you first call `useInboxCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxCreateMutation, { data, loading, error }] = useInboxCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInboxCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<InboxCreateMutation, InboxCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InboxCreateMutation, InboxCreateMutationVariables>(
    InboxCreateDocument,
    options,
  );
}
export type InboxCreateMutationHookResult = ReturnType<typeof useInboxCreateMutation>;
export type InboxCreateMutationResult = Apollo.MutationResult<InboxCreateMutation>;
export type InboxCreateMutationOptions = Apollo.BaseMutationOptions<
  InboxCreateMutation,
  InboxCreateMutationVariables
>;
export const InboxDeleteDocument = gql`
  mutation InboxDelete($data: InboxDeleteInput!, $force: Boolean) {
    inboxDelete(data: $data, force: $force) {
      success
    }
  }
`;
export type InboxDeleteMutationFn = Apollo.MutationFunction<
  InboxDeleteMutation,
  InboxDeleteMutationVariables
>;

/**
 * __useInboxDeleteMutation__
 *
 * To run a mutation, you first call `useInboxDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxDeleteMutation, { data, loading, error }] = useInboxDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useInboxDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<InboxDeleteMutation, InboxDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InboxDeleteMutation, InboxDeleteMutationVariables>(
    InboxDeleteDocument,
    options,
  );
}
export type InboxDeleteMutationHookResult = ReturnType<typeof useInboxDeleteMutation>;
export type InboxDeleteMutationResult = Apollo.MutationResult<InboxDeleteMutation>;
export type InboxDeleteMutationOptions = Apollo.BaseMutationOptions<
  InboxDeleteMutation,
  InboxDeleteMutationVariables
>;
export const InboxMessageUpdateDocument = gql`
  mutation InboxMessageUpdate($data: InboxMessageUpdateInput!) {
    inboxMessageUpdate(data: $data) {
      id
      isDeleted
      isRead
    }
  }
`;
export type InboxMessageUpdateMutationFn = Apollo.MutationFunction<
  InboxMessageUpdateMutation,
  InboxMessageUpdateMutationVariables
>;

/**
 * __useInboxMessageUpdateMutation__
 *
 * To run a mutation, you first call `useInboxMessageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxMessageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxMessageUpdateMutation, { data, loading, error }] = useInboxMessageUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInboxMessageUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InboxMessageUpdateMutation,
    InboxMessageUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InboxMessageUpdateMutation, InboxMessageUpdateMutationVariables>(
    InboxMessageUpdateDocument,
    options,
  );
}
export type InboxMessageUpdateMutationHookResult = ReturnType<typeof useInboxMessageUpdateMutation>;
export type InboxMessageUpdateMutationResult = Apollo.MutationResult<InboxMessageUpdateMutation>;
export type InboxMessageUpdateMutationOptions = Apollo.BaseMutationOptions<
  InboxMessageUpdateMutation,
  InboxMessageUpdateMutationVariables
>;
export const InboxMessageDeleteDocument = gql`
  mutation InboxMessageDelete($data: InboxMessageDeleteInput!) {
    inboxMessageDelete(data: $data) {
      success
    }
  }
`;
export type InboxMessageDeleteMutationFn = Apollo.MutationFunction<
  InboxMessageDeleteMutation,
  InboxMessageDeleteMutationVariables
>;

/**
 * __useInboxMessageDeleteMutation__
 *
 * To run a mutation, you first call `useInboxMessageDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxMessageDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxMessageDeleteMutation, { data, loading, error }] = useInboxMessageDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInboxMessageDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InboxMessageDeleteMutation,
    InboxMessageDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InboxMessageDeleteMutation, InboxMessageDeleteMutationVariables>(
    InboxMessageDeleteDocument,
    options,
  );
}
export type InboxMessageDeleteMutationHookResult = ReturnType<typeof useInboxMessageDeleteMutation>;
export type InboxMessageDeleteMutationResult = Apollo.MutationResult<InboxMessageDeleteMutation>;
export type InboxMessageDeleteMutationOptions = Apollo.BaseMutationOptions<
  InboxMessageDeleteMutation,
  InboxMessageDeleteMutationVariables
>;
export const InboxMessageClearTrashDocument = gql`
  mutation InboxMessageClearTrash {
    inboxMessageDeleteByFilter(filter: { isDeleted: { equals: true } }, force: true) {
      success
    }
  }
`;
export type InboxMessageClearTrashMutationFn = Apollo.MutationFunction<
  InboxMessageClearTrashMutation,
  InboxMessageClearTrashMutationVariables
>;

/**
 * __useInboxMessageClearTrashMutation__
 *
 * To run a mutation, you first call `useInboxMessageClearTrashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxMessageClearTrashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxMessageClearTrashMutation, { data, loading, error }] = useInboxMessageClearTrashMutation({
 *   variables: {
 *   },
 * });
 */
export function useInboxMessageClearTrashMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InboxMessageClearTrashMutation,
    InboxMessageClearTrashMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InboxMessageClearTrashMutation,
    InboxMessageClearTrashMutationVariables
  >(InboxMessageClearTrashDocument, options);
}
export type InboxMessageClearTrashMutationHookResult = ReturnType<
  typeof useInboxMessageClearTrashMutation
>;
export type InboxMessageClearTrashMutationResult =
  Apollo.MutationResult<InboxMessageClearTrashMutation>;
export type InboxMessageClearTrashMutationOptions = Apollo.BaseMutationOptions<
  InboxMessageClearTrashMutation,
  InboxMessageClearTrashMutationVariables
>;
export const InboxMessageMediaCreateDocument = gql`
  mutation InboxMessageMediaCreate($data: InboxMessageMediaCreateInput!) {
    inboxMessageMediaCreate(data: $data) {
      id
    }
  }
`;
export type InboxMessageMediaCreateMutationFn = Apollo.MutationFunction<
  InboxMessageMediaCreateMutation,
  InboxMessageMediaCreateMutationVariables
>;

/**
 * __useInboxMessageMediaCreateMutation__
 *
 * To run a mutation, you first call `useInboxMessageMediaCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxMessageMediaCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxMessageMediaCreateMutation, { data, loading, error }] = useInboxMessageMediaCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInboxMessageMediaCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InboxMessageMediaCreateMutation,
    InboxMessageMediaCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InboxMessageMediaCreateMutation,
    InboxMessageMediaCreateMutationVariables
  >(InboxMessageMediaCreateDocument, options);
}
export type InboxMessageMediaCreateMutationHookResult = ReturnType<
  typeof useInboxMessageMediaCreateMutation
>;
export type InboxMessageMediaCreateMutationResult =
  Apollo.MutationResult<InboxMessageMediaCreateMutation>;
export type InboxMessageMediaCreateMutationOptions = Apollo.BaseMutationOptions<
  InboxMessageMediaCreateMutation,
  InboxMessageMediaCreateMutationVariables
>;
export const UserTrashMessagesDocument = gql`
  query UserTrashMessages {
    inboxMessagesList(
      filter: { deletedBy: { some: { is_self: true } }, isHide: { every: { is_self: false } } }
    ) {
      items {
        id
      }
    }
  }
`;

/**
 * __useUserTrashMessagesQuery__
 *
 * To run a query within a React component, call `useUserTrashMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTrashMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTrashMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTrashMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<UserTrashMessagesQuery, UserTrashMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserTrashMessagesQuery, UserTrashMessagesQueryVariables>(
    UserTrashMessagesDocument,
    options,
  );
}
export function useUserTrashMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserTrashMessagesQuery,
    UserTrashMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserTrashMessagesQuery, UserTrashMessagesQueryVariables>(
    UserTrashMessagesDocument,
    options,
  );
}
export type UserTrashMessagesQueryHookResult = ReturnType<typeof useUserTrashMessagesQuery>;
export type UserTrashMessagesLazyQueryHookResult = ReturnType<typeof useUserTrashMessagesLazyQuery>;
export type UserTrashMessagesQueryResult = Apollo.QueryResult<
  UserTrashMessagesQuery,
  UserTrashMessagesQueryVariables
>;
export const InboxUpdateDocument = gql`
  mutation InboxUpdate($data: InboxUpdateInput!) {
    inboxUpdate(data: $data, force: true) {
      id
    }
  }
`;
export type InboxUpdateMutationFn = Apollo.MutationFunction<
  InboxUpdateMutation,
  InboxUpdateMutationVariables
>;

/**
 * __useInboxUpdateMutation__
 *
 * To run a mutation, you first call `useInboxUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxUpdateMutation, { data, loading, error }] = useInboxUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInboxUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<InboxUpdateMutation, InboxUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InboxUpdateMutation, InboxUpdateMutationVariables>(
    InboxUpdateDocument,
    options,
  );
}
export type InboxUpdateMutationHookResult = ReturnType<typeof useInboxUpdateMutation>;
export type InboxUpdateMutationResult = Apollo.MutationResult<InboxUpdateMutation>;
export type InboxUpdateMutationOptions = Apollo.BaseMutationOptions<
  InboxUpdateMutation,
  InboxUpdateMutationVariables
>;
export const ReadAllInboxMessagesDocument = gql`
  mutation ReadAllInboxMessages($inboxId: ID!) {
    inboxMessageUpdateByFilter(
      data: { isRead: { set: true } }
      filter: {
        inbox: { id: { equals: $inboxId } }
        author: { is_self: false }
        isRead: { equals: false }
      }
    ) {
      count
    }
  }
`;
export type ReadAllInboxMessagesMutationFn = Apollo.MutationFunction<
  ReadAllInboxMessagesMutation,
  ReadAllInboxMessagesMutationVariables
>;

/**
 * __useReadAllInboxMessagesMutation__
 *
 * To run a mutation, you first call `useReadAllInboxMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllInboxMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllInboxMessagesMutation, { data, loading, error }] = useReadAllInboxMessagesMutation({
 *   variables: {
 *      inboxId: // value for 'inboxId'
 *   },
 * });
 */
export function useReadAllInboxMessagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadAllInboxMessagesMutation,
    ReadAllInboxMessagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReadAllInboxMessagesMutation, ReadAllInboxMessagesMutationVariables>(
    ReadAllInboxMessagesDocument,
    options,
  );
}
export type ReadAllInboxMessagesMutationHookResult = ReturnType<
  typeof useReadAllInboxMessagesMutation
>;
export type ReadAllInboxMessagesMutationResult =
  Apollo.MutationResult<ReadAllInboxMessagesMutation>;
export type ReadAllInboxMessagesMutationOptions = Apollo.BaseMutationOptions<
  ReadAllInboxMessagesMutation,
  ReadAllInboxMessagesMutationVariables
>;
export const InboxUnreadMessagesDocument = gql`
  query InboxUnreadMessages($id: ID!) {
    inboxMessagesList(
      filter: {
        author: { is_self: false }
        isRead: { equals: false }
        inbox: { users: { some: { id: { equals: $id } } } }
      }
      first: 100
    ) {
      count
    }
  }
`;

/**
 * __useInboxUnreadMessagesQuery__
 *
 * To run a query within a React component, call `useInboxUnreadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxUnreadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxUnreadMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInboxUnreadMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<InboxUnreadMessagesQuery, InboxUnreadMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InboxUnreadMessagesQuery, InboxUnreadMessagesQueryVariables>(
    InboxUnreadMessagesDocument,
    options,
  );
}
export function useInboxUnreadMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InboxUnreadMessagesQuery,
    InboxUnreadMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InboxUnreadMessagesQuery, InboxUnreadMessagesQueryVariables>(
    InboxUnreadMessagesDocument,
    options,
  );
}
export type InboxUnreadMessagesQueryHookResult = ReturnType<typeof useInboxUnreadMessagesQuery>;
export type InboxUnreadMessagesLazyQueryHookResult = ReturnType<
  typeof useInboxUnreadMessagesLazyQuery
>;
export type InboxUnreadMessagesQueryResult = Apollo.QueryResult<
  InboxUnreadMessagesQuery,
  InboxUnreadMessagesQueryVariables
>;
export const LegacyFeedYearsListDocument = gql`
  query LegacyFeedYearsList(
    $first: Int
    $skip: Int
    $filter: LegacyFeedPostFilter!
    $sort: [LegacyFeedPostSort!]
    $yearsSort: [LegacyFeedYearSort!]
    $monthsSort: [LegacyFeedMonthSort!]
  ) {
    legacyFeedYearsList(sort: $yearsSort) {
      items {
        id
        legacyYear
        legacyMonths(sort: $monthsSort) {
          items {
            id
            month
            posts: legacyPosts(first: $first, filter: $filter, sort: $sort, skip: $skip) {
              items {
                ...LegacyFeedPostsListItem
              }
            }
          }
        }
      }
    }
  }
  ${LegacyFeedPostsListItemFragmentDoc}
`;

/**
 * __useLegacyFeedYearsListQuery__
 *
 * To run a query within a React component, call `useLegacyFeedYearsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyFeedYearsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyFeedYearsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      yearsSort: // value for 'yearsSort'
 *      monthsSort: // value for 'monthsSort'
 *   },
 * });
 */
export function useLegacyFeedYearsListQuery(
  baseOptions: Apollo.QueryHookOptions<LegacyFeedYearsListQuery, LegacyFeedYearsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegacyFeedYearsListQuery, LegacyFeedYearsListQueryVariables>(
    LegacyFeedYearsListDocument,
    options,
  );
}
export function useLegacyFeedYearsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegacyFeedYearsListQuery,
    LegacyFeedYearsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegacyFeedYearsListQuery, LegacyFeedYearsListQueryVariables>(
    LegacyFeedYearsListDocument,
    options,
  );
}
export type LegacyFeedYearsListQueryHookResult = ReturnType<typeof useLegacyFeedYearsListQuery>;
export type LegacyFeedYearsListLazyQueryHookResult = ReturnType<
  typeof useLegacyFeedYearsListLazyQuery
>;
export type LegacyFeedYearsListQueryResult = Apollo.QueryResult<
  LegacyFeedYearsListQuery,
  LegacyFeedYearsListQueryVariables
>;
export const LegacyTimelineYearsDocument = gql`
  query LegacyTimelineYears(
    $filter: LegacyFeedYearFilter
    $first: Int
    $skip: Int
    $sort: [LegacyFeedYearSort!]
  ) {
    years: legacyFeedYearsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        year: legacyYear
      }
    }
  }
`;

/**
 * __useLegacyTimelineYearsQuery__
 *
 * To run a query within a React component, call `useLegacyTimelineYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyTimelineYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyTimelineYearsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useLegacyTimelineYearsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LegacyTimelineYearsQuery,
    LegacyTimelineYearsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegacyTimelineYearsQuery, LegacyTimelineYearsQueryVariables>(
    LegacyTimelineYearsDocument,
    options,
  );
}
export function useLegacyTimelineYearsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegacyTimelineYearsQuery,
    LegacyTimelineYearsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegacyTimelineYearsQuery, LegacyTimelineYearsQueryVariables>(
    LegacyTimelineYearsDocument,
    options,
  );
}
export type LegacyTimelineYearsQueryHookResult = ReturnType<typeof useLegacyTimelineYearsQuery>;
export type LegacyTimelineYearsLazyQueryHookResult = ReturnType<
  typeof useLegacyTimelineYearsLazyQuery
>;
export type LegacyTimelineYearsQueryResult = Apollo.QueryResult<
  LegacyTimelineYearsQuery,
  LegacyTimelineYearsQueryVariables
>;
export const LegacyTimelineMonthsDocument = gql`
  query LegacyTimelineMonths(
    $filter: LegacyFeedMonthFilter
    $first: Int
    $skip: Int
    $sort: [LegacyFeedMonthSort!]
  ) {
    months: legacyFeedMonthsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        month
      }
    }
  }
`;

/**
 * __useLegacyTimelineMonthsQuery__
 *
 * To run a query within a React component, call `useLegacyTimelineMonthsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyTimelineMonthsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyTimelineMonthsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useLegacyTimelineMonthsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LegacyTimelineMonthsQuery,
    LegacyTimelineMonthsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegacyTimelineMonthsQuery, LegacyTimelineMonthsQueryVariables>(
    LegacyTimelineMonthsDocument,
    options,
  );
}
export function useLegacyTimelineMonthsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegacyTimelineMonthsQuery,
    LegacyTimelineMonthsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegacyTimelineMonthsQuery, LegacyTimelineMonthsQueryVariables>(
    LegacyTimelineMonthsDocument,
    options,
  );
}
export type LegacyTimelineMonthsQueryHookResult = ReturnType<typeof useLegacyTimelineMonthsQuery>;
export type LegacyTimelineMonthsLazyQueryHookResult = ReturnType<
  typeof useLegacyTimelineMonthsLazyQuery
>;
export type LegacyTimelineMonthsQueryResult = Apollo.QueryResult<
  LegacyTimelineMonthsQuery,
  LegacyTimelineMonthsQueryVariables
>;
export const LegacyTimelinePostsDocument = gql`
  query LegacyTimelinePosts(
    $filter: LegacyFeedPostFilter!
    $first: Int
    $skip: Int
    $sort: [LegacyFeedPostSort!]
  ) {
    feed: legacyFeedPostsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        ...LegacyTimelinePost
      }
    }
  }
  ${LegacyTimelinePostFragmentDoc}
`;

/**
 * __useLegacyTimelinePostsQuery__
 *
 * To run a query within a React component, call `useLegacyTimelinePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyTimelinePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyTimelinePostsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useLegacyTimelinePostsQuery(
  baseOptions: Apollo.QueryHookOptions<LegacyTimelinePostsQuery, LegacyTimelinePostsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegacyTimelinePostsQuery, LegacyTimelinePostsQueryVariables>(
    LegacyTimelinePostsDocument,
    options,
  );
}
export function useLegacyTimelinePostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegacyTimelinePostsQuery,
    LegacyTimelinePostsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegacyTimelinePostsQuery, LegacyTimelinePostsQueryVariables>(
    LegacyTimelinePostsDocument,
    options,
  );
}
export type LegacyTimelinePostsQueryHookResult = ReturnType<typeof useLegacyTimelinePostsQuery>;
export type LegacyTimelinePostsLazyQueryHookResult = ReturnType<
  typeof useLegacyTimelinePostsLazyQuery
>;
export type LegacyTimelinePostsQueryResult = Apollo.QueryResult<
  LegacyTimelinePostsQuery,
  LegacyTimelinePostsQueryVariables
>;
export const LegacyFeedPostCreateDocument = gql`
  mutation LegacyFeedPostCreate($data: LegacyFeedPostCreateInput!) {
    legacyFeedPostCreate(data: $data) {
      ...LegacyFeedPostsListItem
    }
  }
  ${LegacyFeedPostsListItemFragmentDoc}
`;
export type LegacyFeedPostCreateMutationFn = Apollo.MutationFunction<
  LegacyFeedPostCreateMutation,
  LegacyFeedPostCreateMutationVariables
>;

/**
 * __useLegacyFeedPostCreateMutation__
 *
 * To run a mutation, you first call `useLegacyFeedPostCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLegacyFeedPostCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [legacyFeedPostCreateMutation, { data, loading, error }] = useLegacyFeedPostCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLegacyFeedPostCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LegacyFeedPostCreateMutation,
    LegacyFeedPostCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LegacyFeedPostCreateMutation, LegacyFeedPostCreateMutationVariables>(
    LegacyFeedPostCreateDocument,
    options,
  );
}
export type LegacyFeedPostCreateMutationHookResult = ReturnType<
  typeof useLegacyFeedPostCreateMutation
>;
export type LegacyFeedPostCreateMutationResult =
  Apollo.MutationResult<LegacyFeedPostCreateMutation>;
export type LegacyFeedPostCreateMutationOptions = Apollo.BaseMutationOptions<
  LegacyFeedPostCreateMutation,
  LegacyFeedPostCreateMutationVariables
>;
export const LegacyTimeStampsDocument = gql`
  query LegacyTimeStamps {
    years: legacyFeedYearsList {
      items {
        id
        year: legacyYear
        months: legacyMonths {
          items {
            id
            month
          }
        }
      }
    }
  }
`;

/**
 * __useLegacyTimeStampsQuery__
 *
 * To run a query within a React component, call `useLegacyTimeStampsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyTimeStampsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyTimeStampsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLegacyTimeStampsQuery(
  baseOptions?: Apollo.QueryHookOptions<LegacyTimeStampsQuery, LegacyTimeStampsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegacyTimeStampsQuery, LegacyTimeStampsQueryVariables>(
    LegacyTimeStampsDocument,
    options,
  );
}
export function useLegacyTimeStampsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LegacyTimeStampsQuery, LegacyTimeStampsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegacyTimeStampsQuery, LegacyTimeStampsQueryVariables>(
    LegacyTimeStampsDocument,
    options,
  );
}
export type LegacyTimeStampsQueryHookResult = ReturnType<typeof useLegacyTimeStampsQuery>;
export type LegacyTimeStampsLazyQueryHookResult = ReturnType<typeof useLegacyTimeStampsLazyQuery>;
export type LegacyTimeStampsQueryResult = Apollo.QueryResult<
  LegacyTimeStampsQuery,
  LegacyTimeStampsQueryVariables
>;
export const LegacyFeedMonthsListDocument = gql`
  query LegacyFeedMonthsList(
    $first: Int!
    $skip: Int
    $yearFilter: LegacyFeedYearFilter!
    $postFilter: LegacyFeedPostFilter!
    $sort: [LegacyFeedPostSort!]
    $monthsSort: [LegacyFeedMonthSort!]
  ) {
    legacyFeedYearsList(filter: $yearFilter) {
      items {
        id
        legacyYear
        legacyMonths(sort: $monthsSort) {
          items {
            id
            month
            posts: legacyPosts(first: $first, filter: $postFilter, sort: $sort, skip: $skip) {
              items {
                ...LegacyFeedPostsListItem
              }
            }
          }
        }
      }
    }
  }
  ${LegacyFeedPostsListItemFragmentDoc}
`;

/**
 * __useLegacyFeedMonthsListQuery__
 *
 * To run a query within a React component, call `useLegacyFeedMonthsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyFeedMonthsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyFeedMonthsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      yearFilter: // value for 'yearFilter'
 *      postFilter: // value for 'postFilter'
 *      sort: // value for 'sort'
 *      monthsSort: // value for 'monthsSort'
 *   },
 * });
 */
export function useLegacyFeedMonthsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    LegacyFeedMonthsListQuery,
    LegacyFeedMonthsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegacyFeedMonthsListQuery, LegacyFeedMonthsListQueryVariables>(
    LegacyFeedMonthsListDocument,
    options,
  );
}
export function useLegacyFeedMonthsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegacyFeedMonthsListQuery,
    LegacyFeedMonthsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegacyFeedMonthsListQuery, LegacyFeedMonthsListQueryVariables>(
    LegacyFeedMonthsListDocument,
    options,
  );
}
export type LegacyFeedMonthsListQueryHookResult = ReturnType<typeof useLegacyFeedMonthsListQuery>;
export type LegacyFeedMonthsListLazyQueryHookResult = ReturnType<
  typeof useLegacyFeedMonthsListLazyQuery
>;
export type LegacyFeedMonthsListQueryResult = Apollo.QueryResult<
  LegacyFeedMonthsListQuery,
  LegacyFeedMonthsListQueryVariables
>;
export const LegacyFeedPostUpdateDocument = gql`
  mutation LegacyFeedPostUpdate($data: LegacyFeedPostUpdateInput!, $id: ID!) {
    legacyFeedPostUpdate(data: $data, filter: { id: $id }) {
      ...LegacyFeedPostsListItem
    }
  }
  ${LegacyFeedPostsListItemFragmentDoc}
`;
export type LegacyFeedPostUpdateMutationFn = Apollo.MutationFunction<
  LegacyFeedPostUpdateMutation,
  LegacyFeedPostUpdateMutationVariables
>;

/**
 * __useLegacyFeedPostUpdateMutation__
 *
 * To run a mutation, you first call `useLegacyFeedPostUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLegacyFeedPostUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [legacyFeedPostUpdateMutation, { data, loading, error }] = useLegacyFeedPostUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLegacyFeedPostUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LegacyFeedPostUpdateMutation,
    LegacyFeedPostUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LegacyFeedPostUpdateMutation, LegacyFeedPostUpdateMutationVariables>(
    LegacyFeedPostUpdateDocument,
    options,
  );
}
export type LegacyFeedPostUpdateMutationHookResult = ReturnType<
  typeof useLegacyFeedPostUpdateMutation
>;
export type LegacyFeedPostUpdateMutationResult =
  Apollo.MutationResult<LegacyFeedPostUpdateMutation>;
export type LegacyFeedPostUpdateMutationOptions = Apollo.BaseMutationOptions<
  LegacyFeedPostUpdateMutation,
  LegacyFeedPostUpdateMutationVariables
>;
export const LegacyFeedPostsListDocument = gql`
  query LegacyFeedPostsList(
    $first: Int!
    $skip: Int
    $sort: [LegacyFeedPostSort!]
    $postsFilter: LegacyFeedPostFilter!
  ) {
    legacyFeedPostsList(first: $first, skip: $skip, sort: $sort, filter: $postsFilter) {
      items {
        ...LegacyFeedPostsListItem
      }
    }
  }
  ${LegacyFeedPostsListItemFragmentDoc}
`;

/**
 * __useLegacyFeedPostsListQuery__
 *
 * To run a query within a React component, call `useLegacyFeedPostsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyFeedPostsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyFeedPostsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      postsFilter: // value for 'postsFilter'
 *   },
 * });
 */
export function useLegacyFeedPostsListQuery(
  baseOptions: Apollo.QueryHookOptions<LegacyFeedPostsListQuery, LegacyFeedPostsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegacyFeedPostsListQuery, LegacyFeedPostsListQueryVariables>(
    LegacyFeedPostsListDocument,
    options,
  );
}
export function useLegacyFeedPostsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegacyFeedPostsListQuery,
    LegacyFeedPostsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegacyFeedPostsListQuery, LegacyFeedPostsListQueryVariables>(
    LegacyFeedPostsListDocument,
    options,
  );
}
export type LegacyFeedPostsListQueryHookResult = ReturnType<typeof useLegacyFeedPostsListQuery>;
export type LegacyFeedPostsListLazyQueryHookResult = ReturnType<
  typeof useLegacyFeedPostsListLazyQuery
>;
export type LegacyFeedPostsListQueryResult = Apollo.QueryResult<
  LegacyFeedPostsListQuery,
  LegacyFeedPostsListQueryVariables
>;
export const LegacyFeedPostsListCountDocument = gql`
  query LegacyFeedPostsListCount($postsFilter: LegacyFeedPostFilter!) {
    legacyFeedPostsList(filter: $postsFilter) {
      count
    }
  }
`;

/**
 * __useLegacyFeedPostsListCountQuery__
 *
 * To run a query within a React component, call `useLegacyFeedPostsListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyFeedPostsListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyFeedPostsListCountQuery({
 *   variables: {
 *      postsFilter: // value for 'postsFilter'
 *   },
 * });
 */
export function useLegacyFeedPostsListCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    LegacyFeedPostsListCountQuery,
    LegacyFeedPostsListCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegacyFeedPostsListCountQuery, LegacyFeedPostsListCountQueryVariables>(
    LegacyFeedPostsListCountDocument,
    options,
  );
}
export function useLegacyFeedPostsListCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegacyFeedPostsListCountQuery,
    LegacyFeedPostsListCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegacyFeedPostsListCountQuery, LegacyFeedPostsListCountQueryVariables>(
    LegacyFeedPostsListCountDocument,
    options,
  );
}
export type LegacyFeedPostsListCountQueryHookResult = ReturnType<
  typeof useLegacyFeedPostsListCountQuery
>;
export type LegacyFeedPostsListCountLazyQueryHookResult = ReturnType<
  typeof useLegacyFeedPostsListCountLazyQuery
>;
export type LegacyFeedPostsListCountQueryResult = Apollo.QueryResult<
  LegacyFeedPostsListCountQuery,
  LegacyFeedPostsListCountQueryVariables
>;
export const SchoolNewsPostCreateDocument = gql`
  mutation SchoolNewsPostCreate($data: SchoolNewsPostCreateInput!) {
    schoolNewsPostCreate(data: $data) {
      ...SchoolNewsPostsListItem
    }
  }
  ${SchoolNewsPostsListItemFragmentDoc}
`;
export type SchoolNewsPostCreateMutationFn = Apollo.MutationFunction<
  SchoolNewsPostCreateMutation,
  SchoolNewsPostCreateMutationVariables
>;

/**
 * __useSchoolNewsPostCreateMutation__
 *
 * To run a mutation, you first call `useSchoolNewsPostCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolNewsPostCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolNewsPostCreateMutation, { data, loading, error }] = useSchoolNewsPostCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSchoolNewsPostCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolNewsPostCreateMutation,
    SchoolNewsPostCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SchoolNewsPostCreateMutation, SchoolNewsPostCreateMutationVariables>(
    SchoolNewsPostCreateDocument,
    options,
  );
}
export type SchoolNewsPostCreateMutationHookResult = ReturnType<
  typeof useSchoolNewsPostCreateMutation
>;
export type SchoolNewsPostCreateMutationResult =
  Apollo.MutationResult<SchoolNewsPostCreateMutation>;
export type SchoolNewsPostCreateMutationOptions = Apollo.BaseMutationOptions<
  SchoolNewsPostCreateMutation,
  SchoolNewsPostCreateMutationVariables
>;
export const SchoolNewsPostUpdateDocument = gql`
  mutation SchoolNewsPostUpdate($data: SchoolNewsPostUpdateInput!, $id: ID!) {
    schoolNewsPostUpdate(data: $data, filter: { id: $id }) {
      ...SchoolNewsPostsListItem
    }
  }
  ${SchoolNewsPostsListItemFragmentDoc}
`;
export type SchoolNewsPostUpdateMutationFn = Apollo.MutationFunction<
  SchoolNewsPostUpdateMutation,
  SchoolNewsPostUpdateMutationVariables
>;

/**
 * __useSchoolNewsPostUpdateMutation__
 *
 * To run a mutation, you first call `useSchoolNewsPostUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolNewsPostUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolNewsPostUpdateMutation, { data, loading, error }] = useSchoolNewsPostUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolNewsPostUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolNewsPostUpdateMutation,
    SchoolNewsPostUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SchoolNewsPostUpdateMutation, SchoolNewsPostUpdateMutationVariables>(
    SchoolNewsPostUpdateDocument,
    options,
  );
}
export type SchoolNewsPostUpdateMutationHookResult = ReturnType<
  typeof useSchoolNewsPostUpdateMutation
>;
export type SchoolNewsPostUpdateMutationResult =
  Apollo.MutationResult<SchoolNewsPostUpdateMutation>;
export type SchoolNewsPostUpdateMutationOptions = Apollo.BaseMutationOptions<
  SchoolNewsPostUpdateMutation,
  SchoolNewsPostUpdateMutationVariables
>;
export const School_News_Update_Post_MutationDocument = gql`
  mutation SCHOOL_NEWS_UPDATE_POST_MUTATION($data: JSON!, $id: ID!) {
    updatePost(postData: $data, postId: $id, postType: "schoolPost") {
      successful
    }
  }
`;
export type School_News_Update_Post_MutationMutationFn = Apollo.MutationFunction<
  School_News_Update_Post_MutationMutation,
  School_News_Update_Post_MutationMutationVariables
>;

/**
 * __useSchool_News_Update_Post_MutationMutation__
 *
 * To run a mutation, you first call `useSchool_News_Update_Post_MutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchool_News_Update_Post_MutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolNewsUpdatePostMutationMutation, { data, loading, error }] = useSchool_News_Update_Post_MutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchool_News_Update_Post_MutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    School_News_Update_Post_MutationMutation,
    School_News_Update_Post_MutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    School_News_Update_Post_MutationMutation,
    School_News_Update_Post_MutationMutationVariables
  >(School_News_Update_Post_MutationDocument, options);
}
export type School_News_Update_Post_MutationMutationHookResult = ReturnType<
  typeof useSchool_News_Update_Post_MutationMutation
>;
export type School_News_Update_Post_MutationMutationResult =
  Apollo.MutationResult<School_News_Update_Post_MutationMutation>;
export type School_News_Update_Post_MutationMutationOptions = Apollo.BaseMutationOptions<
  School_News_Update_Post_MutationMutation,
  School_News_Update_Post_MutationMutationVariables
>;
export const SchoolNewsPostsListDocument = gql`
  query SchoolNewsPostsList(
    $first: Int
    $skip: Int
    $sort: [SchoolNewsPostSort!]
    $filter: SchoolNewsPostFilter
    $pinnedFilter: SchoolNewsPostFilter!
  ) {
    schoolNewsPostsList(first: $first, skip: $skip, sort: $sort, filter: $filter) {
      items {
        ...SchoolNewsPostsListItem
      }
    }
    pinnedPostsList: schoolNewsPostsList(filter: $pinnedFilter, sort: $sort) {
      items {
        ...SchoolNewsPostsListItem
      }
    }
  }
  ${SchoolNewsPostsListItemFragmentDoc}
`;

/**
 * __useSchoolNewsPostsListQuery__
 *
 * To run a query within a React component, call `useSchoolNewsPostsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolNewsPostsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolNewsPostsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      pinnedFilter: // value for 'pinnedFilter'
 *   },
 * });
 */
export function useSchoolNewsPostsListQuery(
  baseOptions: Apollo.QueryHookOptions<SchoolNewsPostsListQuery, SchoolNewsPostsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchoolNewsPostsListQuery, SchoolNewsPostsListQueryVariables>(
    SchoolNewsPostsListDocument,
    options,
  );
}
export function useSchoolNewsPostsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolNewsPostsListQuery,
    SchoolNewsPostsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchoolNewsPostsListQuery, SchoolNewsPostsListQueryVariables>(
    SchoolNewsPostsListDocument,
    options,
  );
}
export type SchoolNewsPostsListQueryHookResult = ReturnType<typeof useSchoolNewsPostsListQuery>;
export type SchoolNewsPostsListLazyQueryHookResult = ReturnType<
  typeof useSchoolNewsPostsListLazyQuery
>;
export type SchoolNewsPostsListQueryResult = Apollo.QueryResult<
  SchoolNewsPostsListQuery,
  SchoolNewsPostsListQueryVariables
>;
export const SchoolNewsPostsCountDocument = gql`
  query SchoolNewsPostsCount($filter: SchoolNewsPostFilter) {
    schoolNewsPostsList(filter: $filter) {
      count
    }
  }
`;

/**
 * __useSchoolNewsPostsCountQuery__
 *
 * To run a query within a React component, call `useSchoolNewsPostsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolNewsPostsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolNewsPostsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSchoolNewsPostsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SchoolNewsPostsCountQuery,
    SchoolNewsPostsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchoolNewsPostsCountQuery, SchoolNewsPostsCountQueryVariables>(
    SchoolNewsPostsCountDocument,
    options,
  );
}
export function useSchoolNewsPostsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolNewsPostsCountQuery,
    SchoolNewsPostsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchoolNewsPostsCountQuery, SchoolNewsPostsCountQueryVariables>(
    SchoolNewsPostsCountDocument,
    options,
  );
}
export type SchoolNewsPostsCountQueryHookResult = ReturnType<typeof useSchoolNewsPostsCountQuery>;
export type SchoolNewsPostsCountLazyQueryHookResult = ReturnType<
  typeof useSchoolNewsPostsCountLazyQuery
>;
export type SchoolNewsPostsCountQueryResult = Apollo.QueryResult<
  SchoolNewsPostsCountQuery,
  SchoolNewsPostsCountQueryVariables
>;
export const CheckResetPasswordCodeDocument = gql`
  query CheckResetPasswordCode($data: CheckResetPasswordCodeInput!) {
    checkResetPasswordCode(data: $data) {
      email
    }
  }
`;

/**
 * __useCheckResetPasswordCodeQuery__
 *
 * To run a query within a React component, call `useCheckResetPasswordCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckResetPasswordCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckResetPasswordCodeQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckResetPasswordCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckResetPasswordCodeQuery,
    CheckResetPasswordCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckResetPasswordCodeQuery, CheckResetPasswordCodeQueryVariables>(
    CheckResetPasswordCodeDocument,
    options,
  );
}
export function useCheckResetPasswordCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckResetPasswordCodeQuery,
    CheckResetPasswordCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckResetPasswordCodeQuery, CheckResetPasswordCodeQueryVariables>(
    CheckResetPasswordCodeDocument,
    options,
  );
}
export type CheckResetPasswordCodeQueryHookResult = ReturnType<
  typeof useCheckResetPasswordCodeQuery
>;
export type CheckResetPasswordCodeLazyQueryHookResult = ReturnType<
  typeof useCheckResetPasswordCodeLazyQuery
>;
export type CheckResetPasswordCodeQueryResult = Apollo.QueryResult<
  CheckResetPasswordCodeQuery,
  CheckResetPasswordCodeQueryVariables
>;
export const UserChangePasswordDocument = gql`
  mutation UserChangePassword($data: UserForgotPasswordChangeInput!) {
    userForgotPasswordChange(data: $data) {
      success
    }
  }
`;
export type UserChangePasswordMutationFn = Apollo.MutationFunction<
  UserChangePasswordMutation,
  UserChangePasswordMutationVariables
>;

/**
 * __useUserChangePasswordMutation__
 *
 * To run a mutation, you first call `useUserChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userChangePasswordMutation, { data, loading, error }] = useUserChangePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserChangePasswordMutation,
    UserChangePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserChangePasswordMutation, UserChangePasswordMutationVariables>(
    UserChangePasswordDocument,
    options,
  );
}
export type UserChangePasswordMutationHookResult = ReturnType<typeof useUserChangePasswordMutation>;
export type UserChangePasswordMutationResult = Apollo.MutationResult<UserChangePasswordMutation>;
export type UserChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  UserChangePasswordMutation,
  UserChangePasswordMutationVariables
>;
export const OrganizationCreateDocument = gql`
  mutation OrganizationCreate($data: OrganizationCreateInput!) {
    claimOrganization(data: $data) {
      id
    }
  }
`;
export type OrganizationCreateMutationFn = Apollo.MutationFunction<
  OrganizationCreateMutation,
  OrganizationCreateMutationVariables
>;

/**
 * __useOrganizationCreateMutation__
 *
 * To run a mutation, you first call `useOrganizationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreateMutation, { data, loading, error }] = useOrganizationCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrganizationCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrganizationCreateMutation,
    OrganizationCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrganizationCreateMutation, OrganizationCreateMutationVariables>(
    OrganizationCreateDocument,
    options,
  );
}
export type OrganizationCreateMutationHookResult = ReturnType<typeof useOrganizationCreateMutation>;
export type OrganizationCreateMutationResult = Apollo.MutationResult<OrganizationCreateMutation>;
export type OrganizationCreateMutationOptions = Apollo.BaseMutationOptions<
  OrganizationCreateMutation,
  OrganizationCreateMutationVariables
>;
export const OrganizationUpdateDocument = gql`
  mutation OrganizationUpdate($data: OrganizationUpdateInput!) {
    organizationUpdate(data: $data) {
      ...OrganizationItem
    }
  }
  ${OrganizationItemFragmentDoc}
`;
export type OrganizationUpdateMutationFn = Apollo.MutationFunction<
  OrganizationUpdateMutation,
  OrganizationUpdateMutationVariables
>;

/**
 * __useOrganizationUpdateMutation__
 *
 * To run a mutation, you first call `useOrganizationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationUpdateMutation, { data, loading, error }] = useOrganizationUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrganizationUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrganizationUpdateMutation,
    OrganizationUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrganizationUpdateMutation, OrganizationUpdateMutationVariables>(
    OrganizationUpdateDocument,
    options,
  );
}
export type OrganizationUpdateMutationHookResult = ReturnType<typeof useOrganizationUpdateMutation>;
export type OrganizationUpdateMutationResult = Apollo.MutationResult<OrganizationUpdateMutation>;
export type OrganizationUpdateMutationOptions = Apollo.BaseMutationOptions<
  OrganizationUpdateMutation,
  OrganizationUpdateMutationVariables
>;
export const Organization_Update_By_Filter_MutationDocument = gql`
  mutation ORGANIZATION_UPDATE_BY_FILTER_MUTATION(
    $data: OrganizationUpdateByFilterInput!
    $filter: OrganizationFilter
  ) {
    organizationUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
        name
        homeFeedViews
      }
    }
  }
`;
export type Organization_Update_By_Filter_MutationMutationFn = Apollo.MutationFunction<
  Organization_Update_By_Filter_MutationMutation,
  Organization_Update_By_Filter_MutationMutationVariables
>;

/**
 * __useOrganization_Update_By_Filter_MutationMutation__
 *
 * To run a mutation, you first call `useOrganization_Update_By_Filter_MutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganization_Update_By_Filter_MutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationUpdateByFilterMutationMutation, { data, loading, error }] = useOrganization_Update_By_Filter_MutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganization_Update_By_Filter_MutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Organization_Update_By_Filter_MutationMutation,
    Organization_Update_By_Filter_MutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Organization_Update_By_Filter_MutationMutation,
    Organization_Update_By_Filter_MutationMutationVariables
  >(Organization_Update_By_Filter_MutationDocument, options);
}
export type Organization_Update_By_Filter_MutationMutationHookResult = ReturnType<
  typeof useOrganization_Update_By_Filter_MutationMutation
>;
export type Organization_Update_By_Filter_MutationMutationResult =
  Apollo.MutationResult<Organization_Update_By_Filter_MutationMutation>;
export type Organization_Update_By_Filter_MutationMutationOptions = Apollo.BaseMutationOptions<
  Organization_Update_By_Filter_MutationMutation,
  Organization_Update_By_Filter_MutationMutationVariables
>;
export const OrganizationDocument = gql`
  query Organization($id: ID!) {
    organization(id: $id) {
      ...OrganizationItem
    }
  }
  ${OrganizationItemFragmentDoc}
`;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    options,
  );
}
export function useOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    options,
  );
}
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<
  OrganizationQuery,
  OrganizationQueryVariables
>;
export const OrganizationProfileDocument = gql`
  query OrganizationProfile($id: ID!) {
    organization(id: $id) {
      ...OrganizationProfile
    }
  }
  ${OrganizationProfileFragmentDoc}
`;

/**
 * __useOrganizationProfileQuery__
 *
 * To run a query within a React component, call `useOrganizationProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationProfileQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationProfileQuery, OrganizationProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationProfileQuery, OrganizationProfileQueryVariables>(
    OrganizationProfileDocument,
    options,
  );
}
export function useOrganizationProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationProfileQuery,
    OrganizationProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationProfileQuery, OrganizationProfileQueryVariables>(
    OrganizationProfileDocument,
    options,
  );
}
export type OrganizationProfileQueryHookResult = ReturnType<typeof useOrganizationProfileQuery>;
export type OrganizationProfileLazyQueryHookResult = ReturnType<
  typeof useOrganizationProfileLazyQuery
>;
export type OrganizationProfileQueryResult = Apollo.QueryResult<
  OrganizationProfileQuery,
  OrganizationProfileQueryVariables
>;
export const OrganizationsListDocument = gql`
  query OrganizationsList(
    $filter: OrganizationFilter
    $sort: [OrganizationSort!]
    $first: Int
    $skip: Int
  ) {
    organizations: organizationsList(filter: $filter, sort: $sort, first: $first, skip: $skip) {
      count
      items {
        ...OrganizationItem
      }
    }
  }
  ${OrganizationItemFragmentDoc}
`;

/**
 * __useOrganizationsListQuery__
 *
 * To run a query within a React component, call `useOrganizationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useOrganizationsListQuery(
  baseOptions?: Apollo.QueryHookOptions<OrganizationsListQuery, OrganizationsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationsListQuery, OrganizationsListQueryVariables>(
    OrganizationsListDocument,
    options,
  );
}
export function useOrganizationsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationsListQuery,
    OrganizationsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationsListQuery, OrganizationsListQueryVariables>(
    OrganizationsListDocument,
    options,
  );
}
export type OrganizationsListQueryHookResult = ReturnType<typeof useOrganizationsListQuery>;
export type OrganizationsListLazyQueryHookResult = ReturnType<typeof useOrganizationsListLazyQuery>;
export type OrganizationsListQueryResult = Apollo.QueryResult<
  OrganizationsListQuery,
  OrganizationsListQueryVariables
>;
export const BusinessRegistryListDocument = gql`
  query BusinessRegistryList(
    $school: ID!
    $filter: OrganizationLocationFilter
    $first: Int
    $skip: Int
    $sort: [OrganizationLocationSort!]
  ) {
    locations: organizationLocationsList(first: $first, skip: $skip, sort: $sort, filter: $filter) {
      count
      items {
        id
        name: locationName
        createdAt
        address {
          items {
            state
            city
            street1
            zip
          }
        }
        organization {
          id
          name
          industry
          createdAt
          logo {
            fileId
            downloadUrl
          }
          managers {
            items {
              status
              user {
                id
                firstName
                lastName
                email
              }
            }
          }
          currentSchoolSupport: schoolSupporter(filter: { school: { id: { equals: $school } } }) {
            count
          }
          createdBy {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

/**
 * __useBusinessRegistryListQuery__
 *
 * To run a query within a React component, call `useBusinessRegistryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessRegistryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessRegistryListQuery({
 *   variables: {
 *      school: // value for 'school'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useBusinessRegistryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    BusinessRegistryListQuery,
    BusinessRegistryListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessRegistryListQuery, BusinessRegistryListQueryVariables>(
    BusinessRegistryListDocument,
    options,
  );
}
export function useBusinessRegistryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessRegistryListQuery,
    BusinessRegistryListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessRegistryListQuery, BusinessRegistryListQueryVariables>(
    BusinessRegistryListDocument,
    options,
  );
}
export type BusinessRegistryListQueryHookResult = ReturnType<typeof useBusinessRegistryListQuery>;
export type BusinessRegistryListLazyQueryHookResult = ReturnType<
  typeof useBusinessRegistryListLazyQuery
>;
export type BusinessRegistryListQueryResult = Apollo.QueryResult<
  BusinessRegistryListQuery,
  BusinessRegistryListQueryVariables
>;
export const BusinessCountDocument = gql`
  query BusinessCount(
    $filter: OrganizationFilter
    $startPointZip: String!
    $radius: String!
    $supporterFilter: OrganizationFilter
  ) {
    businesses: businessFilterByZipRequest(
      filter: $filter
      startPointZip: $startPointZip
      radius: $radius
    ) {
      count
    }
    supporters: businessFilterByZipRequest(
      filter: $supporterFilter
      startPointZip: $startPointZip
      radius: $radius
    ) {
      count
    }
  }
`;

/**
 * __useBusinessCountQuery__
 *
 * To run a query within a React component, call `useBusinessCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      startPointZip: // value for 'startPointZip'
 *      radius: // value for 'radius'
 *      supporterFilter: // value for 'supporterFilter'
 *   },
 * });
 */
export function useBusinessCountQuery(
  baseOptions: Apollo.QueryHookOptions<BusinessCountQuery, BusinessCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessCountQuery, BusinessCountQueryVariables>(
    BusinessCountDocument,
    options,
  );
}
export function useBusinessCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessCountQuery, BusinessCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessCountQuery, BusinessCountQueryVariables>(
    BusinessCountDocument,
    options,
  );
}
export type BusinessCountQueryHookResult = ReturnType<typeof useBusinessCountQuery>;
export type BusinessCountLazyQueryHookResult = ReturnType<typeof useBusinessCountLazyQuery>;
export type BusinessCountQueryResult = Apollo.QueryResult<
  BusinessCountQuery,
  BusinessCountQueryVariables
>;
export const OrganizationsCountDocument = gql`
  query OrganizationsCount($filter: OrganizationFilter) {
    organizationsList(filter: $filter) {
      count
    }
  }
`;

/**
 * __useOrganizationsCountQuery__
 *
 * To run a query within a React component, call `useOrganizationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<OrganizationsCountQuery, OrganizationsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationsCountQuery, OrganizationsCountQueryVariables>(
    OrganizationsCountDocument,
    options,
  );
}
export function useOrganizationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationsCountQuery,
    OrganizationsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationsCountQuery, OrganizationsCountQueryVariables>(
    OrganizationsCountDocument,
    options,
  );
}
export type OrganizationsCountQueryHookResult = ReturnType<typeof useOrganizationsCountQuery>;
export type OrganizationsCountLazyQueryHookResult = ReturnType<
  typeof useOrganizationsCountLazyQuery
>;
export type OrganizationsCountQueryResult = Apollo.QueryResult<
  OrganizationsCountQuery,
  OrganizationsCountQueryVariables
>;
export const OrganizationCreateLikeDocument = gql`
  mutation OrganizationCreateLike($userId: ID!, $organizationId: ID!) {
    organizationLikeCreate(
      data: {
        user: { connect: { id: $userId } }
        organization: { connect: { id: $organizationId } }
      }
    ) {
      id
      user {
        id
      }
      organization {
        id
      }
    }
  }
`;
export type OrganizationCreateLikeMutationFn = Apollo.MutationFunction<
  OrganizationCreateLikeMutation,
  OrganizationCreateLikeMutationVariables
>;

/**
 * __useOrganizationCreateLikeMutation__
 *
 * To run a mutation, you first call `useOrganizationCreateLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreateLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreateLikeMutation, { data, loading, error }] = useOrganizationCreateLikeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationCreateLikeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrganizationCreateLikeMutation,
    OrganizationCreateLikeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrganizationCreateLikeMutation,
    OrganizationCreateLikeMutationVariables
  >(OrganizationCreateLikeDocument, options);
}
export type OrganizationCreateLikeMutationHookResult = ReturnType<
  typeof useOrganizationCreateLikeMutation
>;
export type OrganizationCreateLikeMutationResult =
  Apollo.MutationResult<OrganizationCreateLikeMutation>;
export type OrganizationCreateLikeMutationOptions = Apollo.BaseMutationOptions<
  OrganizationCreateLikeMutation,
  OrganizationCreateLikeMutationVariables
>;
export const OrganizationDeleteLikeDocument = gql`
  mutation OrganizationDeleteLike($likeId: ID!) {
    organizationLikeDelete(data: { id: $likeId }) {
      success
    }
  }
`;
export type OrganizationDeleteLikeMutationFn = Apollo.MutationFunction<
  OrganizationDeleteLikeMutation,
  OrganizationDeleteLikeMutationVariables
>;

/**
 * __useOrganizationDeleteLikeMutation__
 *
 * To run a mutation, you first call `useOrganizationDeleteLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDeleteLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationDeleteLikeMutation, { data, loading, error }] = useOrganizationDeleteLikeMutation({
 *   variables: {
 *      likeId: // value for 'likeId'
 *   },
 * });
 */
export function useOrganizationDeleteLikeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrganizationDeleteLikeMutation,
    OrganizationDeleteLikeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrganizationDeleteLikeMutation,
    OrganizationDeleteLikeMutationVariables
  >(OrganizationDeleteLikeDocument, options);
}
export type OrganizationDeleteLikeMutationHookResult = ReturnType<
  typeof useOrganizationDeleteLikeMutation
>;
export type OrganizationDeleteLikeMutationResult =
  Apollo.MutationResult<OrganizationDeleteLikeMutation>;
export type OrganizationDeleteLikeMutationOptions = Apollo.BaseMutationOptions<
  OrganizationDeleteLikeMutation,
  OrganizationDeleteLikeMutationVariables
>;
export const SchoolSupporterSearchCreateDocument = gql`
  mutation SchoolSupporterSearchCreate($userId: ID!, $supporterId: ID!) {
    schoolSupporterSearchCreate(
      data: { user: { connect: { id: $userId } }, supporter: { connect: { id: $supporterId } } }
    ) {
      id
      user {
        id
      }
      supporter {
        id
      }
    }
  }
`;
export type SchoolSupporterSearchCreateMutationFn = Apollo.MutationFunction<
  SchoolSupporterSearchCreateMutation,
  SchoolSupporterSearchCreateMutationVariables
>;

/**
 * __useSchoolSupporterSearchCreateMutation__
 *
 * To run a mutation, you first call `useSchoolSupporterSearchCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSupporterSearchCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSupporterSearchCreateMutation, { data, loading, error }] = useSchoolSupporterSearchCreateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      supporterId: // value for 'supporterId'
 *   },
 * });
 */
export function useSchoolSupporterSearchCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSupporterSearchCreateMutation,
    SchoolSupporterSearchCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SchoolSupporterSearchCreateMutation,
    SchoolSupporterSearchCreateMutationVariables
  >(SchoolSupporterSearchCreateDocument, options);
}
export type SchoolSupporterSearchCreateMutationHookResult = ReturnType<
  typeof useSchoolSupporterSearchCreateMutation
>;
export type SchoolSupporterSearchCreateMutationResult =
  Apollo.MutationResult<SchoolSupporterSearchCreateMutation>;
export type SchoolSupporterSearchCreateMutationOptions = Apollo.BaseMutationOptions<
  SchoolSupporterSearchCreateMutation,
  SchoolSupporterSearchCreateMutationVariables
>;
export const SchoolSupporterClickCreateDocument = gql`
  mutation SchoolSupporterClickCreate($userId: ID!, $supporterId: ID!) {
    schoolSupporterClickCreate(
      data: { user: { connect: { id: $userId } }, supporter: { connect: { id: $supporterId } } }
    ) {
      id
      user {
        id
      }
      supporter {
        id
      }
    }
  }
`;
export type SchoolSupporterClickCreateMutationFn = Apollo.MutationFunction<
  SchoolSupporterClickCreateMutation,
  SchoolSupporterClickCreateMutationVariables
>;

/**
 * __useSchoolSupporterClickCreateMutation__
 *
 * To run a mutation, you first call `useSchoolSupporterClickCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSupporterClickCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSupporterClickCreateMutation, { data, loading, error }] = useSchoolSupporterClickCreateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      supporterId: // value for 'supporterId'
 *   },
 * });
 */
export function useSchoolSupporterClickCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSupporterClickCreateMutation,
    SchoolSupporterClickCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SchoolSupporterClickCreateMutation,
    SchoolSupporterClickCreateMutationVariables
  >(SchoolSupporterClickCreateDocument, options);
}
export type SchoolSupporterClickCreateMutationHookResult = ReturnType<
  typeof useSchoolSupporterClickCreateMutation
>;
export type SchoolSupporterClickCreateMutationResult =
  Apollo.MutationResult<SchoolSupporterClickCreateMutation>;
export type SchoolSupporterClickCreateMutationOptions = Apollo.BaseMutationOptions<
  SchoolSupporterClickCreateMutation,
  SchoolSupporterClickCreateMutationVariables
>;
export const GraduatingYearsMetricsDocument = gql`
  query GraduatingYearsMetrics(
    $first: Int
    $skip: Int
    $filter: GraduatingYearsMetricFilter
    $sort: [GraduatingYearsMetricSort!]
  ) {
    metrics: graduatingYearsMetricsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        ...GraduatingYearMetric
      }
    }
  }
  ${GraduatingYearMetricFragmentDoc}
`;

/**
 * __useGraduatingYearsMetricsQuery__
 *
 * To run a query within a React component, call `useGraduatingYearsMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraduatingYearsMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraduatingYearsMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGraduatingYearsMetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GraduatingYearsMetricsQuery,
    GraduatingYearsMetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraduatingYearsMetricsQuery, GraduatingYearsMetricsQueryVariables>(
    GraduatingYearsMetricsDocument,
    options,
  );
}
export function useGraduatingYearsMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraduatingYearsMetricsQuery,
    GraduatingYearsMetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraduatingYearsMetricsQuery, GraduatingYearsMetricsQueryVariables>(
    GraduatingYearsMetricsDocument,
    options,
  );
}
export type GraduatingYearsMetricsQueryHookResult = ReturnType<
  typeof useGraduatingYearsMetricsQuery
>;
export type GraduatingYearsMetricsLazyQueryHookResult = ReturnType<
  typeof useGraduatingYearsMetricsLazyQuery
>;
export type GraduatingYearsMetricsQueryResult = Apollo.QueryResult<
  GraduatingYearsMetricsQuery,
  GraduatingYearsMetricsQueryVariables
>;
export const GraduatingYearsRankingDocument = gql`
  query GraduatingYearsRanking($filter: GraduatingYearsMetricFilter) {
    metrics: graduatingYearsMetricsList(filter: $filter, sort: { missingUsersPercentage: ASC }) {
      count
      items {
        id
        missingUsersPercentage
      }
    }
  }
`;

/**
 * __useGraduatingYearsRankingQuery__
 *
 * To run a query within a React component, call `useGraduatingYearsRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraduatingYearsRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraduatingYearsRankingQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGraduatingYearsRankingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GraduatingYearsRankingQuery,
    GraduatingYearsRankingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraduatingYearsRankingQuery, GraduatingYearsRankingQueryVariables>(
    GraduatingYearsRankingDocument,
    options,
  );
}
export function useGraduatingYearsRankingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraduatingYearsRankingQuery,
    GraduatingYearsRankingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraduatingYearsRankingQuery, GraduatingYearsRankingQueryVariables>(
    GraduatingYearsRankingDocument,
    options,
  );
}
export type GraduatingYearsRankingQueryHookResult = ReturnType<
  typeof useGraduatingYearsRankingQuery
>;
export type GraduatingYearsRankingLazyQueryHookResult = ReturnType<
  typeof useGraduatingYearsRankingLazyQuery
>;
export type GraduatingYearsRankingQueryResult = Apollo.QueryResult<
  GraduatingYearsRankingQuery,
  GraduatingYearsRankingQueryVariables
>;
export const RegisteredUsersCountDocument = gql`
  query RegisteredUsersCount($first: Int!) {
    usersList(
      first: $first
      groupBy: {
        query: {
          id: { as: "usersCount", fn: { aggregate: COUNT } }
          graduatingYear: { as: "year" }
        }
      }
    ) {
      groups {
        ...GroupRegisteredUsersCount
      }
    }
  }
  ${GroupRegisteredUsersCountFragmentDoc}
`;

/**
 * __useRegisteredUsersCountQuery__
 *
 * To run a query within a React component, call `useRegisteredUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredUsersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredUsersCountQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useRegisteredUsersCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegisteredUsersCountQuery,
    RegisteredUsersCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegisteredUsersCountQuery, RegisteredUsersCountQueryVariables>(
    RegisteredUsersCountDocument,
    options,
  );
}
export function useRegisteredUsersCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisteredUsersCountQuery,
    RegisteredUsersCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegisteredUsersCountQuery, RegisteredUsersCountQueryVariables>(
    RegisteredUsersCountDocument,
    options,
  );
}
export type RegisteredUsersCountQueryHookResult = ReturnType<typeof useRegisteredUsersCountQuery>;
export type RegisteredUsersCountLazyQueryHookResult = ReturnType<
  typeof useRegisteredUsersCountLazyQuery
>;
export type RegisteredUsersCountQueryResult = Apollo.QueryResult<
  RegisteredUsersCountQuery,
  RegisteredUsersCountQueryVariables
>;
export const GraduatedUsersListPerYearDocument = gql`
  query GraduatedUsersListPerYear(
    $first: Int
    $skip: Int
    $filter: UserFilter
    $sort: [UserSort!]
  ) {
    users: usersList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        ...MinimalUser
      }
    }
  }
  ${MinimalUserFragmentDoc}
`;

/**
 * __useGraduatedUsersListPerYearQuery__
 *
 * To run a query within a React component, call `useGraduatedUsersListPerYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraduatedUsersListPerYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraduatedUsersListPerYearQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGraduatedUsersListPerYearQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GraduatedUsersListPerYearQuery,
    GraduatedUsersListPerYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraduatedUsersListPerYearQuery, GraduatedUsersListPerYearQueryVariables>(
    GraduatedUsersListPerYearDocument,
    options,
  );
}
export function useGraduatedUsersListPerYearLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraduatedUsersListPerYearQuery,
    GraduatedUsersListPerYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GraduatedUsersListPerYearQuery,
    GraduatedUsersListPerYearQueryVariables
  >(GraduatedUsersListPerYearDocument, options);
}
export type GraduatedUsersListPerYearQueryHookResult = ReturnType<
  typeof useGraduatedUsersListPerYearQuery
>;
export type GraduatedUsersListPerYearLazyQueryHookResult = ReturnType<
  typeof useGraduatedUsersListPerYearLazyQuery
>;
export type GraduatedUsersListPerYearQueryResult = Apollo.QueryResult<
  GraduatedUsersListPerYearQuery,
  GraduatedUsersListPerYearQueryVariables
>;
export const HobbiesUpdateDocument = gql`
  mutation HobbiesUpdate($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      id
      hobbies
    }
  }
`;
export type HobbiesUpdateMutationFn = Apollo.MutationFunction<
  HobbiesUpdateMutation,
  HobbiesUpdateMutationVariables
>;

/**
 * __useHobbiesUpdateMutation__
 *
 * To run a mutation, you first call `useHobbiesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHobbiesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hobbiesUpdateMutation, { data, loading, error }] = useHobbiesUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useHobbiesUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<HobbiesUpdateMutation, HobbiesUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HobbiesUpdateMutation, HobbiesUpdateMutationVariables>(
    HobbiesUpdateDocument,
    options,
  );
}
export type HobbiesUpdateMutationHookResult = ReturnType<typeof useHobbiesUpdateMutation>;
export type HobbiesUpdateMutationResult = Apollo.MutationResult<HobbiesUpdateMutation>;
export type HobbiesUpdateMutationOptions = Apollo.BaseMutationOptions<
  HobbiesUpdateMutation,
  HobbiesUpdateMutationVariables
>;
export const EducationProfileCreateDocument = gql`
  mutation EducationProfileCreate($data: EducationalInstitutionCreateInput!) {
    educationProfile: educationalInstitutionCreate(data: $data) {
      ...EducationProfile
    }
  }
  ${EducationProfileFragmentDoc}
`;
export type EducationProfileCreateMutationFn = Apollo.MutationFunction<
  EducationProfileCreateMutation,
  EducationProfileCreateMutationVariables
>;

/**
 * __useEducationProfileCreateMutation__
 *
 * To run a mutation, you first call `useEducationProfileCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEducationProfileCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [educationProfileCreateMutation, { data, loading, error }] = useEducationProfileCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEducationProfileCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EducationProfileCreateMutation,
    EducationProfileCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EducationProfileCreateMutation,
    EducationProfileCreateMutationVariables
  >(EducationProfileCreateDocument, options);
}
export type EducationProfileCreateMutationHookResult = ReturnType<
  typeof useEducationProfileCreateMutation
>;
export type EducationProfileCreateMutationResult =
  Apollo.MutationResult<EducationProfileCreateMutation>;
export type EducationProfileCreateMutationOptions = Apollo.BaseMutationOptions<
  EducationProfileCreateMutation,
  EducationProfileCreateMutationVariables
>;
export const EducationProfileUpdateDocument = gql`
  mutation EducationProfileUpdate($data: EducationalInstitutionUpdateInput!) {
    educationProfile: educationalInstitutionUpdate(data: $data, destroyDetached: true) {
      ...EducationProfile
    }
  }
  ${EducationProfileFragmentDoc}
`;
export type EducationProfileUpdateMutationFn = Apollo.MutationFunction<
  EducationProfileUpdateMutation,
  EducationProfileUpdateMutationVariables
>;

/**
 * __useEducationProfileUpdateMutation__
 *
 * To run a mutation, you first call `useEducationProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEducationProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [educationProfileUpdateMutation, { data, loading, error }] = useEducationProfileUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEducationProfileUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EducationProfileUpdateMutation,
    EducationProfileUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EducationProfileUpdateMutation,
    EducationProfileUpdateMutationVariables
  >(EducationProfileUpdateDocument, options);
}
export type EducationProfileUpdateMutationHookResult = ReturnType<
  typeof useEducationProfileUpdateMutation
>;
export type EducationProfileUpdateMutationResult =
  Apollo.MutationResult<EducationProfileUpdateMutation>;
export type EducationProfileUpdateMutationOptions = Apollo.BaseMutationOptions<
  EducationProfileUpdateMutation,
  EducationProfileUpdateMutationVariables
>;
export const EducationProfileRemoveDocument = gql`
  mutation EducationProfileRemove($filter: EducationalInstitutionKeyFilter!) {
    commit: educationalInstitutionDelete(filter: $filter) {
      success
    }
  }
`;
export type EducationProfileRemoveMutationFn = Apollo.MutationFunction<
  EducationProfileRemoveMutation,
  EducationProfileRemoveMutationVariables
>;

/**
 * __useEducationProfileRemoveMutation__
 *
 * To run a mutation, you first call `useEducationProfileRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEducationProfileRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [educationProfileRemoveMutation, { data, loading, error }] = useEducationProfileRemoveMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEducationProfileRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EducationProfileRemoveMutation,
    EducationProfileRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EducationProfileRemoveMutation,
    EducationProfileRemoveMutationVariables
  >(EducationProfileRemoveDocument, options);
}
export type EducationProfileRemoveMutationHookResult = ReturnType<
  typeof useEducationProfileRemoveMutation
>;
export type EducationProfileRemoveMutationResult =
  Apollo.MutationResult<EducationProfileRemoveMutation>;
export type EducationProfileRemoveMutationOptions = Apollo.BaseMutationOptions<
  EducationProfileRemoveMutation,
  EducationProfileRemoveMutationVariables
>;
export const ActivityClubsUpdateDocument = gql`
  mutation ActivityClubsUpdate($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      id
      activityClubs
    }
  }
`;
export type ActivityClubsUpdateMutationFn = Apollo.MutationFunction<
  ActivityClubsUpdateMutation,
  ActivityClubsUpdateMutationVariables
>;

/**
 * __useActivityClubsUpdateMutation__
 *
 * To run a mutation, you first call `useActivityClubsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivityClubsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activityClubsUpdateMutation, { data, loading, error }] = useActivityClubsUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useActivityClubsUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivityClubsUpdateMutation,
    ActivityClubsUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivityClubsUpdateMutation, ActivityClubsUpdateMutationVariables>(
    ActivityClubsUpdateDocument,
    options,
  );
}
export type ActivityClubsUpdateMutationHookResult = ReturnType<
  typeof useActivityClubsUpdateMutation
>;
export type ActivityClubsUpdateMutationResult = Apollo.MutationResult<ActivityClubsUpdateMutation>;
export type ActivityClubsUpdateMutationOptions = Apollo.BaseMutationOptions<
  ActivityClubsUpdateMutation,
  ActivityClubsUpdateMutationVariables
>;
export const UserPublicProfileDocument = gql`
  query UserPublicProfile($id: ID!) {
    user(id: $id) {
      ...UserPublicProfile
    }
    professionalExperience: jobEmployeesList(filter: { user: { id: { equals: $id } } }) {
      count
      items {
        ...ProfessionalProfile
      }
    }
    professionalEducation: educationalInstitutionsList(filter: { user: { id: { equals: $id } } }) {
      count
      items {
        ...EducationProfile
      }
    }
  }
  ${UserPublicProfileFragmentDoc}
  ${ProfessionalProfileFragmentDoc}
  ${EducationProfileFragmentDoc}
`;

/**
 * __useUserPublicProfileQuery__
 *
 * To run a query within a React component, call `useUserPublicProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPublicProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPublicProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserPublicProfileQuery(
  baseOptions: Apollo.QueryHookOptions<UserPublicProfileQuery, UserPublicProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserPublicProfileQuery, UserPublicProfileQueryVariables>(
    UserPublicProfileDocument,
    options,
  );
}
export function useUserPublicProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserPublicProfileQuery,
    UserPublicProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserPublicProfileQuery, UserPublicProfileQueryVariables>(
    UserPublicProfileDocument,
    options,
  );
}
export type UserPublicProfileQueryHookResult = ReturnType<typeof useUserPublicProfileQuery>;
export type UserPublicProfileLazyQueryHookResult = ReturnType<typeof useUserPublicProfileLazyQuery>;
export type UserPublicProfileQueryResult = Apollo.QueryResult<
  UserPublicProfileQuery,
  UserPublicProfileQueryVariables
>;
export const GroupSearchDocument = gql`
  query GroupSearch($filter: GroupFilter, $first: Int!, $skip: Int) {
    groupsList(filter: $filter, first: $first, skip: $skip) {
      items {
        ...GroupInfo
      }
    }
  }
  ${GroupInfoFragmentDoc}
`;

/**
 * __useGroupSearchQuery__
 *
 * To run a query within a React component, call `useGroupSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGroupSearchQuery(
  baseOptions: Apollo.QueryHookOptions<GroupSearchQuery, GroupSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupSearchQuery, GroupSearchQueryVariables>(GroupSearchDocument, options);
}
export function useGroupSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupSearchQuery, GroupSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupSearchQuery, GroupSearchQueryVariables>(
    GroupSearchDocument,
    options,
  );
}
export type GroupSearchQueryHookResult = ReturnType<typeof useGroupSearchQuery>;
export type GroupSearchLazyQueryHookResult = ReturnType<typeof useGroupSearchLazyQuery>;
export type GroupSearchQueryResult = Apollo.QueryResult<
  GroupSearchQuery,
  GroupSearchQueryVariables
>;
export const GroupLeaveDocument = gql`
  mutation GroupLeave($data: GroupUpdateInput!) {
    groupUpdate(data: $data) {
      id
      members {
        items {
          id
          email
        }
      }
    }
  }
`;
export type GroupLeaveMutationFn = Apollo.MutationFunction<
  GroupLeaveMutation,
  GroupLeaveMutationVariables
>;

/**
 * __useGroupLeaveMutation__
 *
 * To run a mutation, you first call `useGroupLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupLeaveMutation, { data, loading, error }] = useGroupLeaveMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGroupLeaveMutation(
  baseOptions?: Apollo.MutationHookOptions<GroupLeaveMutation, GroupLeaveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupLeaveMutation, GroupLeaveMutationVariables>(
    GroupLeaveDocument,
    options,
  );
}
export type GroupLeaveMutationHookResult = ReturnType<typeof useGroupLeaveMutation>;
export type GroupLeaveMutationResult = Apollo.MutationResult<GroupLeaveMutation>;
export type GroupLeaveMutationOptions = Apollo.BaseMutationOptions<
  GroupLeaveMutation,
  GroupLeaveMutationVariables
>;
export const GroupDeleteDocument = gql`
  mutation GroupDelete($filter: GroupKeyFilter!) {
    groupDelete(filter: $filter) {
      success
    }
  }
`;
export type GroupDeleteMutationFn = Apollo.MutationFunction<
  GroupDeleteMutation,
  GroupDeleteMutationVariables
>;

/**
 * __useGroupDeleteMutation__
 *
 * To run a mutation, you first call `useGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupDeleteMutation, { data, loading, error }] = useGroupDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGroupDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<GroupDeleteMutation, GroupDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupDeleteMutation, GroupDeleteMutationVariables>(
    GroupDeleteDocument,
    options,
  );
}
export type GroupDeleteMutationHookResult = ReturnType<typeof useGroupDeleteMutation>;
export type GroupDeleteMutationResult = Apollo.MutationResult<GroupDeleteMutation>;
export type GroupDeleteMutationOptions = Apollo.BaseMutationOptions<
  GroupDeleteMutation,
  GroupDeleteMutationVariables
>;
export const UserUpdatePasswordDocument = gql`
  mutation UserUpdatePassword($data: UserPasswordChangeInput!) {
    userPasswordChangeRequest(data: $data) {
      success
    }
  }
`;
export type UserUpdatePasswordMutationFn = Apollo.MutationFunction<
  UserUpdatePasswordMutation,
  UserUpdatePasswordMutationVariables
>;

/**
 * __useUserUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUserUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdatePasswordMutation, { data, loading, error }] = useUserUpdatePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserUpdatePasswordMutation,
    UserUpdatePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>(
    UserUpdatePasswordDocument,
    options,
  );
}
export type UserUpdatePasswordMutationHookResult = ReturnType<typeof useUserUpdatePasswordMutation>;
export type UserUpdatePasswordMutationResult = Apollo.MutationResult<UserUpdatePasswordMutation>;
export type UserUpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  UserUpdatePasswordMutation,
  UserUpdatePasswordMutationVariables
>;
export const GroupDetailsUpdateDocument = gql`
  mutation GroupDetailsUpdate($data: GroupUpdateInput!) {
    groupDetailsUpdateRequest(data: $data) {
      success
    }
  }
`;
export type GroupDetailsUpdateMutationFn = Apollo.MutationFunction<
  GroupDetailsUpdateMutation,
  GroupDetailsUpdateMutationVariables
>;

/**
 * __useGroupDetailsUpdateMutation__
 *
 * To run a mutation, you first call `useGroupDetailsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupDetailsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupDetailsUpdateMutation, { data, loading, error }] = useGroupDetailsUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGroupDetailsUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupDetailsUpdateMutation,
    GroupDetailsUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupDetailsUpdateMutation, GroupDetailsUpdateMutationVariables>(
    GroupDetailsUpdateDocument,
    options,
  );
}
export type GroupDetailsUpdateMutationHookResult = ReturnType<typeof useGroupDetailsUpdateMutation>;
export type GroupDetailsUpdateMutationResult = Apollo.MutationResult<GroupDetailsUpdateMutation>;
export type GroupDetailsUpdateMutationOptions = Apollo.BaseMutationOptions<
  GroupDetailsUpdateMutation,
  GroupDetailsUpdateMutationVariables
>;
export const UserUpdateEmailDocument = gql`
  mutation UserUpdateEmail($data: UserEmailChangeInput!) {
    userEmailChangeRequest(data: $data) {
      success
    }
  }
`;
export type UserUpdateEmailMutationFn = Apollo.MutationFunction<
  UserUpdateEmailMutation,
  UserUpdateEmailMutationVariables
>;

/**
 * __useUserUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUserUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateEmailMutation, { data, loading, error }] = useUserUpdateEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserUpdateEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserUpdateEmailMutation,
    UserUpdateEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserUpdateEmailMutation, UserUpdateEmailMutationVariables>(
    UserUpdateEmailDocument,
    options,
  );
}
export type UserUpdateEmailMutationHookResult = ReturnType<typeof useUserUpdateEmailMutation>;
export type UserUpdateEmailMutationResult = Apollo.MutationResult<UserUpdateEmailMutation>;
export type UserUpdateEmailMutationOptions = Apollo.BaseMutationOptions<
  UserUpdateEmailMutation,
  UserUpdateEmailMutationVariables
>;
export const GroupUsersListDocument = gql`
  query GroupUsersList($first: Int, $skip: Int, $filter: UserFilter, $sort: [UserSort!]) {
    usersList(filter: $filter, first: $first, skip: $skip, sort: $sort) {
      items {
        id
        firstName
        lastName
        affiliation
        groupAdminPreferences {
          id
          group {
            items {
              id
            }
          }
        }
        groupPosts {
          count
        }
      }
    }
  }
`;

/**
 * __useGroupUsersListQuery__
 *
 * To run a query within a React component, call `useGroupUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupUsersListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGroupUsersListQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupUsersListQuery, GroupUsersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupUsersListQuery, GroupUsersListQueryVariables>(
    GroupUsersListDocument,
    options,
  );
}
export function useGroupUsersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupUsersListQuery, GroupUsersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupUsersListQuery, GroupUsersListQueryVariables>(
    GroupUsersListDocument,
    options,
  );
}
export type GroupUsersListQueryHookResult = ReturnType<typeof useGroupUsersListQuery>;
export type GroupUsersListLazyQueryHookResult = ReturnType<typeof useGroupUsersListLazyQuery>;
export type GroupUsersListQueryResult = Apollo.QueryResult<
  GroupUsersListQuery,
  GroupUsersListQueryVariables
>;
export const GroupMemberRemoveDocument = gql`
  mutation GroupMemberRemove($group: GroupKeyFilter!, $user: UserKeyFilter!) {
    groupMemberRemove(group: $group, user: $user) {
      success
    }
  }
`;
export type GroupMemberRemoveMutationFn = Apollo.MutationFunction<
  GroupMemberRemoveMutation,
  GroupMemberRemoveMutationVariables
>;

/**
 * __useGroupMemberRemoveMutation__
 *
 * To run a mutation, you first call `useGroupMemberRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupMemberRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupMemberRemoveMutation, { data, loading, error }] = useGroupMemberRemoveMutation({
 *   variables: {
 *      group: // value for 'group'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGroupMemberRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupMemberRemoveMutation,
    GroupMemberRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupMemberRemoveMutation, GroupMemberRemoveMutationVariables>(
    GroupMemberRemoveDocument,
    options,
  );
}
export type GroupMemberRemoveMutationHookResult = ReturnType<typeof useGroupMemberRemoveMutation>;
export type GroupMemberRemoveMutationResult = Apollo.MutationResult<GroupMemberRemoveMutation>;
export type GroupMemberRemoveMutationOptions = Apollo.BaseMutationOptions<
  GroupMemberRemoveMutation,
  GroupMemberRemoveMutationVariables
>;
export const GroupMemberPromoteDocument = gql`
  mutation GroupMemberPromote($group: GroupKeyFilter!, $user: UserKeyFilter!) {
    groupMemberPromote(group: $group, user: $user) {
      success
    }
  }
`;
export type GroupMemberPromoteMutationFn = Apollo.MutationFunction<
  GroupMemberPromoteMutation,
  GroupMemberPromoteMutationVariables
>;

/**
 * __useGroupMemberPromoteMutation__
 *
 * To run a mutation, you first call `useGroupMemberPromoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupMemberPromoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupMemberPromoteMutation, { data, loading, error }] = useGroupMemberPromoteMutation({
 *   variables: {
 *      group: // value for 'group'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGroupMemberPromoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupMemberPromoteMutation,
    GroupMemberPromoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupMemberPromoteMutation, GroupMemberPromoteMutationVariables>(
    GroupMemberPromoteDocument,
    options,
  );
}
export type GroupMemberPromoteMutationHookResult = ReturnType<typeof useGroupMemberPromoteMutation>;
export type GroupMemberPromoteMutationResult = Apollo.MutationResult<GroupMemberPromoteMutation>;
export type GroupMemberPromoteMutationOptions = Apollo.BaseMutationOptions<
  GroupMemberPromoteMutation,
  GroupMemberPromoteMutationVariables
>;
export const GroupMemberDemoteDocument = gql`
  mutation GroupMemberDemote($group: GroupKeyFilter!, $user: UserKeyFilter!) {
    groupMemberDemote(group: $group, user: $user) {
      success
    }
  }
`;
export type GroupMemberDemoteMutationFn = Apollo.MutationFunction<
  GroupMemberDemoteMutation,
  GroupMemberDemoteMutationVariables
>;

/**
 * __useGroupMemberDemoteMutation__
 *
 * To run a mutation, you first call `useGroupMemberDemoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupMemberDemoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupMemberDemoteMutation, { data, loading, error }] = useGroupMemberDemoteMutation({
 *   variables: {
 *      group: // value for 'group'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGroupMemberDemoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupMemberDemoteMutation,
    GroupMemberDemoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupMemberDemoteMutation, GroupMemberDemoteMutationVariables>(
    GroupMemberDemoteDocument,
    options,
  );
}
export type GroupMemberDemoteMutationHookResult = ReturnType<typeof useGroupMemberDemoteMutation>;
export type GroupMemberDemoteMutationResult = Apollo.MutationResult<GroupMemberDemoteMutation>;
export type GroupMemberDemoteMutationOptions = Apollo.BaseMutationOptions<
  GroupMemberDemoteMutation,
  GroupMemberDemoteMutationVariables
>;
export const JobEmployeeCreateDocument = gql`
  mutation JobEmployeeCreate($data: JobEmployeeCreateInput!) {
    jobEmployeeCreate(data: $data) {
      ...ProfessionalProfile
    }
  }
  ${ProfessionalProfileFragmentDoc}
`;
export type JobEmployeeCreateMutationFn = Apollo.MutationFunction<
  JobEmployeeCreateMutation,
  JobEmployeeCreateMutationVariables
>;

/**
 * __useJobEmployeeCreateMutation__
 *
 * To run a mutation, you first call `useJobEmployeeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobEmployeeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobEmployeeCreateMutation, { data, loading, error }] = useJobEmployeeCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useJobEmployeeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobEmployeeCreateMutation,
    JobEmployeeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobEmployeeCreateMutation, JobEmployeeCreateMutationVariables>(
    JobEmployeeCreateDocument,
    options,
  );
}
export type JobEmployeeCreateMutationHookResult = ReturnType<typeof useJobEmployeeCreateMutation>;
export type JobEmployeeCreateMutationResult = Apollo.MutationResult<JobEmployeeCreateMutation>;
export type JobEmployeeCreateMutationOptions = Apollo.BaseMutationOptions<
  JobEmployeeCreateMutation,
  JobEmployeeCreateMutationVariables
>;
export const JobEmployeeDeleteDocument = gql`
  mutation JobEmployeeDelete($id: ID!) {
    jobEmployeeDelete(data: { id: $id }) {
      success
    }
  }
`;
export type JobEmployeeDeleteMutationFn = Apollo.MutationFunction<
  JobEmployeeDeleteMutation,
  JobEmployeeDeleteMutationVariables
>;

/**
 * __useJobEmployeeDeleteMutation__
 *
 * To run a mutation, you first call `useJobEmployeeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobEmployeeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobEmployeeDeleteMutation, { data, loading, error }] = useJobEmployeeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobEmployeeDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobEmployeeDeleteMutation,
    JobEmployeeDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobEmployeeDeleteMutation, JobEmployeeDeleteMutationVariables>(
    JobEmployeeDeleteDocument,
    options,
  );
}
export type JobEmployeeDeleteMutationHookResult = ReturnType<typeof useJobEmployeeDeleteMutation>;
export type JobEmployeeDeleteMutationResult = Apollo.MutationResult<JobEmployeeDeleteMutation>;
export type JobEmployeeDeleteMutationOptions = Apollo.BaseMutationOptions<
  JobEmployeeDeleteMutation,
  JobEmployeeDeleteMutationVariables
>;
export const JobEmployeeUpdateDocument = gql`
  mutation JobEmployeeUpdate($data: JobEmployeeUpdateInput!) {
    jobEmployeeUpdate(data: $data) {
      ...ProfessionalProfile
    }
  }
  ${ProfessionalProfileFragmentDoc}
`;
export type JobEmployeeUpdateMutationFn = Apollo.MutationFunction<
  JobEmployeeUpdateMutation,
  JobEmployeeUpdateMutationVariables
>;

/**
 * __useJobEmployeeUpdateMutation__
 *
 * To run a mutation, you first call `useJobEmployeeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobEmployeeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobEmployeeUpdateMutation, { data, loading, error }] = useJobEmployeeUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useJobEmployeeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobEmployeeUpdateMutation,
    JobEmployeeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobEmployeeUpdateMutation, JobEmployeeUpdateMutationVariables>(
    JobEmployeeUpdateDocument,
    options,
  );
}
export type JobEmployeeUpdateMutationHookResult = ReturnType<typeof useJobEmployeeUpdateMutation>;
export type JobEmployeeUpdateMutationResult = Apollo.MutationResult<JobEmployeeUpdateMutation>;
export type JobEmployeeUpdateMutationOptions = Apollo.BaseMutationOptions<
  JobEmployeeUpdateMutation,
  JobEmployeeUpdateMutationVariables
>;
export const UserByEmailDocument = gql`
  query UserByEmail($email: String!) {
    user(email: $email) {
      id
      email
    }
  }
`;

/**
 * __useUserByEmailQuery__
 *
 * To run a query within a React component, call `useUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options);
}
export function useUserByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserByEmailQuery, UserByEmailQueryVariables>(
    UserByEmailDocument,
    options,
  );
}
export type UserByEmailQueryHookResult = ReturnType<typeof useUserByEmailQuery>;
export type UserByEmailLazyQueryHookResult = ReturnType<typeof useUserByEmailLazyQuery>;
export type UserByEmailQueryResult = Apollo.QueryResult<
  UserByEmailQuery,
  UserByEmailQueryVariables
>;
export const UserSendEmailDocument = gql`
  mutation UserSendEmail($data: UserForgotPasswordEmailSendInput!) {
    userForgotPasswordEmailSend(data: $data) {
      success
    }
  }
`;
export type UserSendEmailMutationFn = Apollo.MutationFunction<
  UserSendEmailMutation,
  UserSendEmailMutationVariables
>;

/**
 * __useUserSendEmailMutation__
 *
 * To run a mutation, you first call `useUserSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSendEmailMutation, { data, loading, error }] = useUserSendEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserSendEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<UserSendEmailMutation, UserSendEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserSendEmailMutation, UserSendEmailMutationVariables>(
    UserSendEmailDocument,
    options,
  );
}
export type UserSendEmailMutationHookResult = ReturnType<typeof useUserSendEmailMutation>;
export type UserSendEmailMutationResult = Apollo.MutationResult<UserSendEmailMutation>;
export type UserSendEmailMutationOptions = Apollo.BaseMutationOptions<
  UserSendEmailMutation,
  UserSendEmailMutationVariables
>;
export const MemberCommunitySidebarItemsDocument = gql`
  query MemberCommunitySidebarItems($dateNow: DateTime!, $birthdayFilter: UserFilter!) {
    eventsList(
      sort: { date: ASC }
      first: 3
      filter: {
        status: { equals: "active" }
        date: { gte: $dateNow }
        isApproved: { equals: true }
      }
    ) {
      items {
        ...EventInfo
      }
    }
    usersList(sort: { birthDate: ASC }, filter: $birthdayFilter) {
      items {
        ...UserListItem
      }
    }
  }
  ${EventInfoFragmentDoc}
  ${UserListItemFragmentDoc}
`;

/**
 * __useMemberCommunitySidebarItemsQuery__
 *
 * To run a query within a React component, call `useMemberCommunitySidebarItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberCommunitySidebarItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberCommunitySidebarItemsQuery({
 *   variables: {
 *      dateNow: // value for 'dateNow'
 *      birthdayFilter: // value for 'birthdayFilter'
 *   },
 * });
 */
export function useMemberCommunitySidebarItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberCommunitySidebarItemsQuery,
    MemberCommunitySidebarItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberCommunitySidebarItemsQuery,
    MemberCommunitySidebarItemsQueryVariables
  >(MemberCommunitySidebarItemsDocument, options);
}
export function useMemberCommunitySidebarItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberCommunitySidebarItemsQuery,
    MemberCommunitySidebarItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberCommunitySidebarItemsQuery,
    MemberCommunitySidebarItemsQueryVariables
  >(MemberCommunitySidebarItemsDocument, options);
}
export type MemberCommunitySidebarItemsQueryHookResult = ReturnType<
  typeof useMemberCommunitySidebarItemsQuery
>;
export type MemberCommunitySidebarItemsLazyQueryHookResult = ReturnType<
  typeof useMemberCommunitySidebarItemsLazyQuery
>;
export type MemberCommunitySidebarItemsQueryResult = Apollo.QueryResult<
  MemberCommunitySidebarItemsQuery,
  MemberCommunitySidebarItemsQueryVariables
>;
export const MentionsListDocument = gql`
  query MentionsList($searchText: String!, $first: Int!) {
    usersList(filter: { firstName: { starts_with: $searchText } }, first: $first) {
      items {
        id
        firstName
        lastName
      }
    }
    groupsList(filter: { title: { starts_with: $searchText } }, first: $first) {
      items {
        id
        title
      }
    }
    organizationsList(filter: { name: { starts_with: $searchText } }, first: $first) {
      items {
        id
        name
      }
    }
  }
`;

/**
 * __useMentionsListQuery__
 *
 * To run a query within a React component, call `useMentionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMentionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMentionsListQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useMentionsListQuery(
  baseOptions: Apollo.QueryHookOptions<MentionsListQuery, MentionsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MentionsListQuery, MentionsListQueryVariables>(
    MentionsListDocument,
    options,
  );
}
export function useMentionsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MentionsListQuery, MentionsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MentionsListQuery, MentionsListQueryVariables>(
    MentionsListDocument,
    options,
  );
}
export type MentionsListQueryHookResult = ReturnType<typeof useMentionsListQuery>;
export type MentionsListLazyQueryHookResult = ReturnType<typeof useMentionsListLazyQuery>;
export type MentionsListQueryResult = Apollo.QueryResult<
  MentionsListQuery,
  MentionsListQueryVariables
>;
export const SendPostReactionNotificationDocument = gql`
  mutation SendPostReactionNotification($data: SendPostReactionNotificationRequestInput!) {
    sendPostReactionNotificationRequest(data: $data) {
      success
    }
  }
`;
export type SendPostReactionNotificationMutationFn = Apollo.MutationFunction<
  SendPostReactionNotificationMutation,
  SendPostReactionNotificationMutationVariables
>;

/**
 * __useSendPostReactionNotificationMutation__
 *
 * To run a mutation, you first call `useSendPostReactionNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPostReactionNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPostReactionNotificationMutation, { data, loading, error }] = useSendPostReactionNotificationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendPostReactionNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPostReactionNotificationMutation,
    SendPostReactionNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPostReactionNotificationMutation,
    SendPostReactionNotificationMutationVariables
  >(SendPostReactionNotificationDocument, options);
}
export type SendPostReactionNotificationMutationHookResult = ReturnType<
  typeof useSendPostReactionNotificationMutation
>;
export type SendPostReactionNotificationMutationResult =
  Apollo.MutationResult<SendPostReactionNotificationMutation>;
export type SendPostReactionNotificationMutationOptions = Apollo.BaseMutationOptions<
  SendPostReactionNotificationMutation,
  SendPostReactionNotificationMutationVariables
>;
export const CurrentUserNotificationsDocument = gql`
  query CurrentUserNotifications($first: Int, $skip: Int) {
    notifications: userNotificationsByUsersList(
      filter: { receiver: { is_self: true } }
      sort: { createdAt: DESC }
      first: $first
      skip: $skip
    ) {
      count
      items {
        ...UserNotificationReadable
      }
    }
  }
  ${UserNotificationReadableFragmentDoc}
`;

/**
 * __useCurrentUserNotificationsQuery__
 *
 * To run a query within a React component, call `useCurrentUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserNotificationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useCurrentUserNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserNotificationsQuery,
    CurrentUserNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserNotificationsQuery, CurrentUserNotificationsQueryVariables>(
    CurrentUserNotificationsDocument,
    options,
  );
}
export function useCurrentUserNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserNotificationsQuery,
    CurrentUserNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserNotificationsQuery, CurrentUserNotificationsQueryVariables>(
    CurrentUserNotificationsDocument,
    options,
  );
}
export type CurrentUserNotificationsQueryHookResult = ReturnType<
  typeof useCurrentUserNotificationsQuery
>;
export type CurrentUserNotificationsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserNotificationsLazyQuery
>;
export type CurrentUserNotificationsQueryResult = Apollo.QueryResult<
  CurrentUserNotificationsQuery,
  CurrentUserNotificationsQueryVariables
>;
export const CurrentUserNotificationsCountDocument = gql`
  query CurrentUserNotificationsCount {
    read: userNotificationsByUsersList(
      filter: { receiver: { is_self: true }, isRead: { equals: true } }
    ) {
      count
    }
    unread: userNotificationsByUsersList(
      filter: { receiver: { is_self: true }, isRead: { equals: false } }
    ) {
      count
    }
  }
`;

/**
 * __useCurrentUserNotificationsCountQuery__
 *
 * To run a query within a React component, call `useCurrentUserNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserNotificationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserNotificationsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserNotificationsCountQuery,
    CurrentUserNotificationsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserNotificationsCountQuery,
    CurrentUserNotificationsCountQueryVariables
  >(CurrentUserNotificationsCountDocument, options);
}
export function useCurrentUserNotificationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserNotificationsCountQuery,
    CurrentUserNotificationsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserNotificationsCountQuery,
    CurrentUserNotificationsCountQueryVariables
  >(CurrentUserNotificationsCountDocument, options);
}
export type CurrentUserNotificationsCountQueryHookResult = ReturnType<
  typeof useCurrentUserNotificationsCountQuery
>;
export type CurrentUserNotificationsCountLazyQueryHookResult = ReturnType<
  typeof useCurrentUserNotificationsCountLazyQuery
>;
export type CurrentUserNotificationsCountQueryResult = Apollo.QueryResult<
  CurrentUserNotificationsCountQuery,
  CurrentUserNotificationsCountQueryVariables
>;
export const MarkNotificationsAsReadDocument = gql`
  mutation MarkNotificationsAsRead($filter: UserNotificationsByUserFilter!) {
    notifications: userNotificationsByUserUpdateByFilter(
      filter: $filter
      data: { isRead: { set: true } }
    ) {
      count
      items {
        ...UserNotificationReadable
      }
    }
  }
  ${UserNotificationReadableFragmentDoc}
`;
export type MarkNotificationsAsReadMutationFn = Apollo.MutationFunction<
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables
>;

/**
 * __useMarkNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsAsReadMutation, { data, loading, error }] = useMarkNotificationsAsReadMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMarkNotificationsAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationsAsReadMutation,
    MarkNotificationsAsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkNotificationsAsReadMutation,
    MarkNotificationsAsReadMutationVariables
  >(MarkNotificationsAsReadDocument, options);
}
export type MarkNotificationsAsReadMutationHookResult = ReturnType<
  typeof useMarkNotificationsAsReadMutation
>;
export type MarkNotificationsAsReadMutationResult =
  Apollo.MutationResult<MarkNotificationsAsReadMutation>;
export type MarkNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables
>;
export const CurrentUserNotificationCreatedDocument = gql`
  subscription CurrentUserNotificationCreated {
    UserNotificationsByUser(
      filter: { mutation_in: [create], node: { receiver: { is_self: true } } }
    ) {
      node {
        ...UserNotificationReadable
      }
    }
  }
  ${UserNotificationReadableFragmentDoc}
`;

/**
 * __useCurrentUserNotificationCreatedSubscription__
 *
 * To run a query within a React component, call `useCurrentUserNotificationCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserNotificationCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserNotificationCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserNotificationCreatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CurrentUserNotificationCreatedSubscription,
    CurrentUserNotificationCreatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CurrentUserNotificationCreatedSubscription,
    CurrentUserNotificationCreatedSubscriptionVariables
  >(CurrentUserNotificationCreatedDocument, options);
}
export type CurrentUserNotificationCreatedSubscriptionHookResult = ReturnType<
  typeof useCurrentUserNotificationCreatedSubscription
>;
export type CurrentUserNotificationCreatedSubscriptionResult =
  Apollo.SubscriptionResult<CurrentUserNotificationCreatedSubscription>;
export const PostsFlaggedByUserListDocument = gql`
  query PostsFlaggedByUserList(
    $homePostsFilter: HomeFeedPostFilter!
    $groupPostsFilter: GroupPostFilter!
    $homePosts: Boolean!
    $groupPosts: Boolean!
  ) {
    homeFeedPostsList(filter: $homePostsFilter) @include(if: $homePosts) {
      items {
        id
      }
    }
    groupPostsList(filter: $groupPostsFilter) @include(if: $groupPosts) {
      items {
        id
      }
    }
  }
`;

/**
 * __usePostsFlaggedByUserListQuery__
 *
 * To run a query within a React component, call `usePostsFlaggedByUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsFlaggedByUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsFlaggedByUserListQuery({
 *   variables: {
 *      homePostsFilter: // value for 'homePostsFilter'
 *      groupPostsFilter: // value for 'groupPostsFilter'
 *      homePosts: // value for 'homePosts'
 *      groupPosts: // value for 'groupPosts'
 *   },
 * });
 */
export function usePostsFlaggedByUserListQuery(
  baseOptions: Apollo.QueryHookOptions<
    PostsFlaggedByUserListQuery,
    PostsFlaggedByUserListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PostsFlaggedByUserListQuery, PostsFlaggedByUserListQueryVariables>(
    PostsFlaggedByUserListDocument,
    options,
  );
}
export function usePostsFlaggedByUserListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PostsFlaggedByUserListQuery,
    PostsFlaggedByUserListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PostsFlaggedByUserListQuery, PostsFlaggedByUserListQueryVariables>(
    PostsFlaggedByUserListDocument,
    options,
  );
}
export type PostsFlaggedByUserListQueryHookResult = ReturnType<
  typeof usePostsFlaggedByUserListQuery
>;
export type PostsFlaggedByUserListLazyQueryHookResult = ReturnType<
  typeof usePostsFlaggedByUserListLazyQuery
>;
export type PostsFlaggedByUserListQueryResult = Apollo.QueryResult<
  PostsFlaggedByUserListQuery,
  PostsFlaggedByUserListQueryVariables
>;
export const PostDeleteDocument = gql`
  mutation PostDelete(
    $id: ID!
    $isHomeFeedPost: Boolean!
    $isSchoolNewsPost: Boolean!
    $isLegacyFeedPost: Boolean!
    $isGroupPost: Boolean!
  ) {
    homeFeedPostDelete(data: { id: $id }) @include(if: $isHomeFeedPost) {
      success
    }
    legacyFeedPostDelete(data: { id: $id }) @include(if: $isLegacyFeedPost) {
      success
    }
    schoolNewsPostDelete(data: { id: $id }) @include(if: $isSchoolNewsPost) {
      success
    }
    groupPostDelete(data: { id: $id }) @include(if: $isGroupPost) {
      success
    }
  }
`;
export type PostDeleteMutationFn = Apollo.MutationFunction<
  PostDeleteMutation,
  PostDeleteMutationVariables
>;

/**
 * __usePostDeleteMutation__
 *
 * To run a mutation, you first call `usePostDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postDeleteMutation, { data, loading, error }] = usePostDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isHomeFeedPost: // value for 'isHomeFeedPost'
 *      isSchoolNewsPost: // value for 'isSchoolNewsPost'
 *      isLegacyFeedPost: // value for 'isLegacyFeedPost'
 *      isGroupPost: // value for 'isGroupPost'
 *   },
 * });
 */
export function usePostDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<PostDeleteMutation, PostDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PostDeleteMutation, PostDeleteMutationVariables>(
    PostDeleteDocument,
    options,
  );
}
export type PostDeleteMutationHookResult = ReturnType<typeof usePostDeleteMutation>;
export type PostDeleteMutationResult = Apollo.MutationResult<PostDeleteMutation>;
export type PostDeleteMutationOptions = Apollo.BaseMutationOptions<
  PostDeleteMutation,
  PostDeleteMutationVariables
>;
export const FlagPostDocument = gql`
  mutation FlagPost($data: FlagPostRequestInput!) {
    flagPostRequest(data: $data) {
      success
    }
  }
`;
export type FlagPostMutationFn = Apollo.MutationFunction<
  FlagPostMutation,
  FlagPostMutationVariables
>;

/**
 * __useFlagPostMutation__
 *
 * To run a mutation, you first call `useFlagPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlagPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flagPostMutation, { data, loading, error }] = useFlagPostMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFlagPostMutation(
  baseOptions?: Apollo.MutationHookOptions<FlagPostMutation, FlagPostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FlagPostMutation, FlagPostMutationVariables>(FlagPostDocument, options);
}
export type FlagPostMutationHookResult = ReturnType<typeof useFlagPostMutation>;
export type FlagPostMutationResult = Apollo.MutationResult<FlagPostMutation>;
export type FlagPostMutationOptions = Apollo.BaseMutationOptions<
  FlagPostMutation,
  FlagPostMutationVariables
>;
export const ContentCurationCountDocument = gql`
  query ContentCurationCount {
    pending: contentReportsList(
      filter: {
        AND: [
          { isFlagged: { not_equals: false } }
          {
            OR: [
              { reportStatus: { equals: "pendingReview" } }
              { reportStatus: { is_empty: true } }
            ]
          }
        ]
      }
    ) {
      count
      items {
        text
        reportStatus
      }
    }
  }
`;

/**
 * __useContentCurationCountQuery__
 *
 * To run a query within a React component, call `useContentCurationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentCurationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentCurationCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useContentCurationCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ContentCurationCountQuery,
    ContentCurationCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContentCurationCountQuery, ContentCurationCountQueryVariables>(
    ContentCurationCountDocument,
    options,
  );
}
export function useContentCurationCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContentCurationCountQuery,
    ContentCurationCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContentCurationCountQuery, ContentCurationCountQueryVariables>(
    ContentCurationCountDocument,
    options,
  );
}
export type ContentCurationCountQueryHookResult = ReturnType<typeof useContentCurationCountQuery>;
export type ContentCurationCountLazyQueryHookResult = ReturnType<
  typeof useContentCurationCountLazyQuery
>;
export type ContentCurationCountQueryResult = Apollo.QueryResult<
  ContentCurationCountQuery,
  ContentCurationCountQueryVariables
>;
export const CareersPageSearchDocument = gql`
  query CareersPageSearch(
    $startPointZip: String!
    $radius: String!
    $careerFilter: JobOfferFilter
    $careersSort: [JobOfferSort!]
    $careersFirst: Int
    $careersSkip: Int
  ) {
    page: careersFilterByZipRequest(
      startPointZip: $startPointZip
      radius: $radius
      careerFilter: $careerFilter
      careersSort: $careersSort
      careersFirst: $careersFirst
      careersSkip: $careersSkip
    ) {
      count
      items {
        ...CareerInfo
      }
    }
  }
  ${CareerInfoFragmentDoc}
`;

/**
 * __useCareersPageSearchQuery__
 *
 * To run a query within a React component, call `useCareersPageSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareersPageSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareersPageSearchQuery({
 *   variables: {
 *      startPointZip: // value for 'startPointZip'
 *      radius: // value for 'radius'
 *      careerFilter: // value for 'careerFilter'
 *      careersSort: // value for 'careersSort'
 *      careersFirst: // value for 'careersFirst'
 *      careersSkip: // value for 'careersSkip'
 *   },
 * });
 */
export function useCareersPageSearchQuery(
  baseOptions: Apollo.QueryHookOptions<CareersPageSearchQuery, CareersPageSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareersPageSearchQuery, CareersPageSearchQueryVariables>(
    CareersPageSearchDocument,
    options,
  );
}
export function useCareersPageSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareersPageSearchQuery,
    CareersPageSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareersPageSearchQuery, CareersPageSearchQueryVariables>(
    CareersPageSearchDocument,
    options,
  );
}
export type CareersPageSearchQueryHookResult = ReturnType<typeof useCareersPageSearchQuery>;
export type CareersPageSearchLazyQueryHookResult = ReturnType<typeof useCareersPageSearchLazyQuery>;
export type CareersPageSearchQueryResult = Apollo.QueryResult<
  CareersPageSearchQuery,
  CareersPageSearchQueryVariables
>;
export const CareersListCountDocument = gql`
  query CareersListCount($filter: JobOfferFilter) {
    jobOffersList(filter: $filter) {
      count
    }
  }
`;

/**
 * __useCareersListCountQuery__
 *
 * To run a query within a React component, call `useCareersListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareersListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareersListCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCareersListCountQuery(
  baseOptions?: Apollo.QueryHookOptions<CareersListCountQuery, CareersListCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareersListCountQuery, CareersListCountQueryVariables>(
    CareersListCountDocument,
    options,
  );
}
export function useCareersListCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareersListCountQuery, CareersListCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareersListCountQuery, CareersListCountQueryVariables>(
    CareersListCountDocument,
    options,
  );
}
export type CareersListCountQueryHookResult = ReturnType<typeof useCareersListCountQuery>;
export type CareersListCountLazyQueryHookResult = ReturnType<typeof useCareersListCountLazyQuery>;
export type CareersListCountQueryResult = Apollo.QueryResult<
  CareersListCountQuery,
  CareersListCountQueryVariables
>;
export const PreviewSearchDocument = gql`
  query PreviewSearch(
    $userFilter: UserFilter
    $internshipsFilter: JobOfferFilter
    $first: Int!
    $jobOfferFilter: JobOfferFilter
  ) {
    users: usersList(first: $first, filter: $userFilter) {
      items {
        ...UserInfo
      }
    }
    jobOffers: jobOffersList(first: $first, filter: $jobOfferFilter) {
      items {
        ...CareerInfo
      }
    }
    internships: jobOffersList(first: $first, filter: $internshipsFilter) {
      items {
        ...CareerInfo
      }
    }
  }
  ${UserInfoFragmentDoc}
  ${CareerInfoFragmentDoc}
`;

/**
 * __usePreviewSearchQuery__
 *
 * To run a query within a React component, call `usePreviewSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewSearchQuery({
 *   variables: {
 *      userFilter: // value for 'userFilter'
 *      internshipsFilter: // value for 'internshipsFilter'
 *      first: // value for 'first'
 *      jobOfferFilter: // value for 'jobOfferFilter'
 *   },
 * });
 */
export function usePreviewSearchQuery(
  baseOptions: Apollo.QueryHookOptions<PreviewSearchQuery, PreviewSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PreviewSearchQuery, PreviewSearchQueryVariables>(
    PreviewSearchDocument,
    options,
  );
}
export function usePreviewSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PreviewSearchQuery, PreviewSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PreviewSearchQuery, PreviewSearchQueryVariables>(
    PreviewSearchDocument,
    options,
  );
}
export type PreviewSearchQueryHookResult = ReturnType<typeof usePreviewSearchQuery>;
export type PreviewSearchLazyQueryHookResult = ReturnType<typeof usePreviewSearchLazyQuery>;
export type PreviewSearchQueryResult = Apollo.QueryResult<
  PreviewSearchQuery,
  PreviewSearchQueryVariables
>;
export const UserSearchDocument = gql`
  query UserSearch($filter: UserFilter, $sort: [UserSort!], $first: Int, $skip: Int) {
    users: usersList(filter: $filter, sort: $sort, first: $first, skip: $skip) {
      count
      items {
        ...UserInfo
      }
    }
  }
  ${UserInfoFragmentDoc}
`;

/**
 * __useUserSearchQuery__
 *
 * To run a query within a React component, call `useUserSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useUserSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<UserSearchQuery, UserSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSearchQuery, UserSearchQueryVariables>(UserSearchDocument, options);
}
export function useUserSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserSearchQuery, UserSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSearchQuery, UserSearchQueryVariables>(
    UserSearchDocument,
    options,
  );
}
export type UserSearchQueryHookResult = ReturnType<typeof useUserSearchQuery>;
export type UserSearchLazyQueryHookResult = ReturnType<typeof useUserSearchLazyQuery>;
export type UserSearchQueryResult = Apollo.QueryResult<UserSearchQuery, UserSearchQueryVariables>;
export const TrendingSearchesListDocument = gql`
  query TrendingSearchesList(
    $filter: TrendingSearchFilter
    $skip: Int
    $sort: [TrendingSearchSort!]
    $first: Int
  ) {
    trendingSearchesList(first: $first, sort: $sort, filter: $filter, skip: $skip) {
      count
      items {
        ...TrendingSearchesListItem
      }
    }
  }
  ${TrendingSearchesListItemFragmentDoc}
`;

/**
 * __useTrendingSearchesListQuery__
 *
 * To run a query within a React component, call `useTrendingSearchesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrendingSearchesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrendingSearchesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTrendingSearchesListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TrendingSearchesListQuery,
    TrendingSearchesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TrendingSearchesListQuery, TrendingSearchesListQueryVariables>(
    TrendingSearchesListDocument,
    options,
  );
}
export function useTrendingSearchesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrendingSearchesListQuery,
    TrendingSearchesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TrendingSearchesListQuery, TrendingSearchesListQueryVariables>(
    TrendingSearchesListDocument,
    options,
  );
}
export type TrendingSearchesListQueryHookResult = ReturnType<typeof useTrendingSearchesListQuery>;
export type TrendingSearchesListLazyQueryHookResult = ReturnType<
  typeof useTrendingSearchesListLazyQuery
>;
export type TrendingSearchesListQueryResult = Apollo.QueryResult<
  TrendingSearchesListQuery,
  TrendingSearchesListQueryVariables
>;
export const TrendingSearchesListCountDocument = gql`
  query TrendingSearchesListCount($filter: TrendingSearchFilter) {
    trendingSearchesList(filter: $filter) {
      count
    }
  }
`;

/**
 * __useTrendingSearchesListCountQuery__
 *
 * To run a query within a React component, call `useTrendingSearchesListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrendingSearchesListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrendingSearchesListCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTrendingSearchesListCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TrendingSearchesListCountQuery,
    TrendingSearchesListCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TrendingSearchesListCountQuery, TrendingSearchesListCountQueryVariables>(
    TrendingSearchesListCountDocument,
    options,
  );
}
export function useTrendingSearchesListCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrendingSearchesListCountQuery,
    TrendingSearchesListCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrendingSearchesListCountQuery,
    TrendingSearchesListCountQueryVariables
  >(TrendingSearchesListCountDocument, options);
}
export type TrendingSearchesListCountQueryHookResult = ReturnType<
  typeof useTrendingSearchesListCountQuery
>;
export type TrendingSearchesListCountLazyQueryHookResult = ReturnType<
  typeof useTrendingSearchesListCountLazyQuery
>;
export type TrendingSearchesListCountQueryResult = Apollo.QueryResult<
  TrendingSearchesListCountQuery,
  TrendingSearchesListCountQueryVariables
>;
export const TrendingSearchesAddWordDocument = gql`
  mutation TrendingSearchesAddWord($data: TrendingSearchCreateInput!) {
    trendingSearchCreate(data: $data) {
      ...TrendingSearchesListItem
    }
  }
  ${TrendingSearchesListItemFragmentDoc}
`;
export type TrendingSearchesAddWordMutationFn = Apollo.MutationFunction<
  TrendingSearchesAddWordMutation,
  TrendingSearchesAddWordMutationVariables
>;

/**
 * __useTrendingSearchesAddWordMutation__
 *
 * To run a mutation, you first call `useTrendingSearchesAddWordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrendingSearchesAddWordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trendingSearchesAddWordMutation, { data, loading, error }] = useTrendingSearchesAddWordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTrendingSearchesAddWordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrendingSearchesAddWordMutation,
    TrendingSearchesAddWordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TrendingSearchesAddWordMutation,
    TrendingSearchesAddWordMutationVariables
  >(TrendingSearchesAddWordDocument, options);
}
export type TrendingSearchesAddWordMutationHookResult = ReturnType<
  typeof useTrendingSearchesAddWordMutation
>;
export type TrendingSearchesAddWordMutationResult =
  Apollo.MutationResult<TrendingSearchesAddWordMutation>;
export type TrendingSearchesAddWordMutationOptions = Apollo.BaseMutationOptions<
  TrendingSearchesAddWordMutation,
  TrendingSearchesAddWordMutationVariables
>;
export const TrendingSearchesUpdateWordCountDocument = gql`
  mutation TrendingSearchesUpdateWordCount($data: TrendingSearchUpdateInput!) {
    trendingSearchUpdate(data: $data) {
      ...TrendingSearchesListItem
    }
  }
  ${TrendingSearchesListItemFragmentDoc}
`;
export type TrendingSearchesUpdateWordCountMutationFn = Apollo.MutationFunction<
  TrendingSearchesUpdateWordCountMutation,
  TrendingSearchesUpdateWordCountMutationVariables
>;

/**
 * __useTrendingSearchesUpdateWordCountMutation__
 *
 * To run a mutation, you first call `useTrendingSearchesUpdateWordCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrendingSearchesUpdateWordCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trendingSearchesUpdateWordCountMutation, { data, loading, error }] = useTrendingSearchesUpdateWordCountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTrendingSearchesUpdateWordCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrendingSearchesUpdateWordCountMutation,
    TrendingSearchesUpdateWordCountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TrendingSearchesUpdateWordCountMutation,
    TrendingSearchesUpdateWordCountMutationVariables
  >(TrendingSearchesUpdateWordCountDocument, options);
}
export type TrendingSearchesUpdateWordCountMutationHookResult = ReturnType<
  typeof useTrendingSearchesUpdateWordCountMutation
>;
export type TrendingSearchesUpdateWordCountMutationResult =
  Apollo.MutationResult<TrendingSearchesUpdateWordCountMutation>;
export type TrendingSearchesUpdateWordCountMutationOptions = Apollo.BaseMutationOptions<
  TrendingSearchesUpdateWordCountMutation,
  TrendingSearchesUpdateWordCountMutationVariables
>;
export const AudienceGraduatingYearsOptionsDocument = gql`
  query AudienceGraduatingYearsOptions {
    options: usersList(
      filter: { graduatingYear: { is_not_empty: true }, status: { equals: "active" } }
      groupBy: {
        having: { alias: "count", int: { gte: 1 } }
        sort: [{ alias: "year", direction: DESC }]
        query: {
          graduatingYear: [
            { as: "year", fn: { datePart: YEAR } }
            { as: "count", fn: { aggregate: COUNT } }
          ]
        }
      }
    ) {
      groups {
        year: String
        count: Int
      }
    }
  }
`;

/**
 * __useAudienceGraduatingYearsOptionsQuery__
 *
 * To run a query within a React component, call `useAudienceGraduatingYearsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceGraduatingYearsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceGraduatingYearsOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAudienceGraduatingYearsOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AudienceGraduatingYearsOptionsQuery,
    AudienceGraduatingYearsOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AudienceGraduatingYearsOptionsQuery,
    AudienceGraduatingYearsOptionsQueryVariables
  >(AudienceGraduatingYearsOptionsDocument, options);
}
export function useAudienceGraduatingYearsOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AudienceGraduatingYearsOptionsQuery,
    AudienceGraduatingYearsOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AudienceGraduatingYearsOptionsQuery,
    AudienceGraduatingYearsOptionsQueryVariables
  >(AudienceGraduatingYearsOptionsDocument, options);
}
export type AudienceGraduatingYearsOptionsQueryHookResult = ReturnType<
  typeof useAudienceGraduatingYearsOptionsQuery
>;
export type AudienceGraduatingYearsOptionsLazyQueryHookResult = ReturnType<
  typeof useAudienceGraduatingYearsOptionsLazyQuery
>;
export type AudienceGraduatingYearsOptionsQueryResult = Apollo.QueryResult<
  AudienceGraduatingYearsOptionsQuery,
  AudienceGraduatingYearsOptionsQueryVariables
>;
export const EventsListDocument = gql`
  query EventsList($filter: EventFilter, $sort: [EventSort!], $first: Int, $skip: Int) {
    eventsList(filter: $filter, sort: $sort, first: $first, skip: $skip) {
      count
      items {
        ...EventInfo
      }
    }
  }
  ${EventInfoFragmentDoc}
`;

/**
 * __useEventsListQuery__
 *
 * To run a query within a React component, call `useEventsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useEventsListQuery(
  baseOptions?: Apollo.QueryHookOptions<EventsListQuery, EventsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventsListQuery, EventsListQueryVariables>(EventsListDocument, options);
}
export function useEventsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventsListQuery, EventsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventsListQuery, EventsListQueryVariables>(
    EventsListDocument,
    options,
  );
}
export type EventsListQueryHookResult = ReturnType<typeof useEventsListQuery>;
export type EventsListLazyQueryHookResult = ReturnType<typeof useEventsListLazyQuery>;
export type EventsListQueryResult = Apollo.QueryResult<EventsListQuery, EventsListQueryVariables>;
export const EventSearchDocument = gql`
  query EventSearch(
    $filter: EventFilter
    $sort: [EventSort!]
    $first: Int
    $skip: Int
    $startPointZip: String!
    $radius: String!
    $proximitySort: String
  ) {
    events: eventsFilterByZipRequest(
      startPointZip: $startPointZip
      radius: $radius
      filter: $filter
      sort: $sort
      first: $first
      skip: $skip
      proximitySort: $proximitySort
    ) {
      count
      items {
        ...EventSearch
      }
    }
  }
  ${EventSearchFragmentDoc}
`;

/**
 * __useEventSearchQuery__
 *
 * To run a query within a React component, call `useEventSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      startPointZip: // value for 'startPointZip'
 *      radius: // value for 'radius'
 *      proximitySort: // value for 'proximitySort'
 *   },
 * });
 */
export function useEventSearchQuery(
  baseOptions: Apollo.QueryHookOptions<EventSearchQuery, EventSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventSearchQuery, EventSearchQueryVariables>(EventSearchDocument, options);
}
export function useEventSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventSearchQuery, EventSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventSearchQuery, EventSearchQueryVariables>(
    EventSearchDocument,
    options,
  );
}
export type EventSearchQueryHookResult = ReturnType<typeof useEventSearchQuery>;
export type EventSearchLazyQueryHookResult = ReturnType<typeof useEventSearchLazyQuery>;
export type EventSearchQueryResult = Apollo.QueryResult<
  EventSearchQuery,
  EventSearchQueryVariables
>;
export const EventCreateDocument = gql`
  mutation EventCreate($data: EventCreateInput!) {
    eventCreate(data: $data) {
      ...EventInfo
    }
  }
  ${EventInfoFragmentDoc}
`;
export type EventCreateMutationFn = Apollo.MutationFunction<
  EventCreateMutation,
  EventCreateMutationVariables
>;

/**
 * __useEventCreateMutation__
 *
 * To run a mutation, you first call `useEventCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventCreateMutation, { data, loading, error }] = useEventCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEventCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<EventCreateMutation, EventCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EventCreateMutation, EventCreateMutationVariables>(
    EventCreateDocument,
    options,
  );
}
export type EventCreateMutationHookResult = ReturnType<typeof useEventCreateMutation>;
export type EventCreateMutationResult = Apollo.MutationResult<EventCreateMutation>;
export type EventCreateMutationOptions = Apollo.BaseMutationOptions<
  EventCreateMutation,
  EventCreateMutationVariables
>;
export const EventUpdateDocument = gql`
  mutation EventUpdate($data: EventUpdateInput!) {
    eventUpdate(data: $data) {
      ...EventInfo
    }
  }
  ${EventInfoFragmentDoc}
`;
export type EventUpdateMutationFn = Apollo.MutationFunction<
  EventUpdateMutation,
  EventUpdateMutationVariables
>;

/**
 * __useEventUpdateMutation__
 *
 * To run a mutation, you first call `useEventUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventUpdateMutation, { data, loading, error }] = useEventUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEventUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<EventUpdateMutation, EventUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EventUpdateMutation, EventUpdateMutationVariables>(
    EventUpdateDocument,
    options,
  );
}
export type EventUpdateMutationHookResult = ReturnType<typeof useEventUpdateMutation>;
export type EventUpdateMutationResult = Apollo.MutationResult<EventUpdateMutation>;
export type EventUpdateMutationOptions = Apollo.BaseMutationOptions<
  EventUpdateMutation,
  EventUpdateMutationVariables
>;
export const UploadFileByUrlDocument = gql`
  mutation UploadFileByUrl($url: String!) {
    system {
      fileUploadByUrl(data: { url: $url }) {
        fileId
        meta
      }
    }
  }
`;
export type UploadFileByUrlMutationFn = Apollo.MutationFunction<
  UploadFileByUrlMutation,
  UploadFileByUrlMutationVariables
>;

/**
 * __useUploadFileByUrlMutation__
 *
 * To run a mutation, you first call `useUploadFileByUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileByUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileByUrlMutation, { data, loading, error }] = useUploadFileByUrlMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useUploadFileByUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFileByUrlMutation,
    UploadFileByUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFileByUrlMutation, UploadFileByUrlMutationVariables>(
    UploadFileByUrlDocument,
    options,
  );
}
export type UploadFileByUrlMutationHookResult = ReturnType<typeof useUploadFileByUrlMutation>;
export type UploadFileByUrlMutationResult = Apollo.MutationResult<UploadFileByUrlMutation>;
export type UploadFileByUrlMutationOptions = Apollo.BaseMutationOptions<
  UploadFileByUrlMutation,
  UploadFileByUrlMutationVariables
>;
export const FilesListDocument = gql`
  query FilesList($ids: [String!]) {
    filesList(filter: { fileId: { in: $ids } }) {
      items {
        fileId
        id
        filename
        public
        downloadUrl
      }
    }
  }
`;

/**
 * __useFilesListQuery__
 *
 * To run a query within a React component, call `useFilesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesListQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFilesListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilesListQuery, FilesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilesListQuery, FilesListQueryVariables>(FilesListDocument, options);
}
export function useFilesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilesListQuery, FilesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilesListQuery, FilesListQueryVariables>(FilesListDocument, options);
}
export type FilesListQueryHookResult = ReturnType<typeof useFilesListQuery>;
export type FilesListLazyQueryHookResult = ReturnType<typeof useFilesListLazyQuery>;
export type FilesListQueryResult = Apollo.QueryResult<FilesListQuery, FilesListQueryVariables>;
export const RemoteVariablesDocument = gql`
  query RemoteVariables {
    variables: remoteVariablesList(first: 1) {
      count
      items {
        ...RemoteVariable
      }
    }
  }
  ${RemoteVariableFragmentDoc}
`;

/**
 * __useRemoteVariablesQuery__
 *
 * To run a query within a React component, call `useRemoteVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemoteVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoteVariablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRemoteVariablesQuery(
  baseOptions?: Apollo.QueryHookOptions<RemoteVariablesQuery, RemoteVariablesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RemoteVariablesQuery, RemoteVariablesQueryVariables>(
    RemoteVariablesDocument,
    options,
  );
}
export function useRemoteVariablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RemoteVariablesQuery, RemoteVariablesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RemoteVariablesQuery, RemoteVariablesQueryVariables>(
    RemoteVariablesDocument,
    options,
  );
}
export type RemoteVariablesQueryHookResult = ReturnType<typeof useRemoteVariablesQuery>;
export type RemoteVariablesLazyQueryHookResult = ReturnType<typeof useRemoteVariablesLazyQuery>;
export type RemoteVariablesQueryResult = Apollo.QueryResult<
  RemoteVariablesQuery,
  RemoteVariablesQueryVariables
>;
export const GenerateLinkPreviewDocument = gql`
  query generateLinkPreview($url: String!) {
    preview: generateLinkPreview(url: $url) {
      title
      description
      imageURL
    }
  }
`;

/**
 * __useGenerateLinkPreviewQuery__
 *
 * To run a query within a React component, call `useGenerateLinkPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateLinkPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateLinkPreviewQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGenerateLinkPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<GenerateLinkPreviewQuery, GenerateLinkPreviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerateLinkPreviewQuery, GenerateLinkPreviewQueryVariables>(
    GenerateLinkPreviewDocument,
    options,
  );
}
export function useGenerateLinkPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateLinkPreviewQuery,
    GenerateLinkPreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenerateLinkPreviewQuery, GenerateLinkPreviewQueryVariables>(
    GenerateLinkPreviewDocument,
    options,
  );
}
export type GenerateLinkPreviewQueryHookResult = ReturnType<typeof useGenerateLinkPreviewQuery>;
export type GenerateLinkPreviewLazyQueryHookResult = ReturnType<
  typeof useGenerateLinkPreviewLazyQuery
>;
export type GenerateLinkPreviewQueryResult = Apollo.QueryResult<
  GenerateLinkPreviewQuery,
  GenerateLinkPreviewQueryVariables
>;
export const GraduatingYearInfoDocument = gql`
  query GraduatingYearInfo($id: ID!) {
    graduatingYear(id: $id) {
      ...GraduatingYearInfo
    }
  }
  ${GraduatingYearInfoFragmentDoc}
`;

/**
 * __useGraduatingYearInfoQuery__
 *
 * To run a query within a React component, call `useGraduatingYearInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraduatingYearInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraduatingYearInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGraduatingYearInfoQuery(
  baseOptions: Apollo.QueryHookOptions<GraduatingYearInfoQuery, GraduatingYearInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraduatingYearInfoQuery, GraduatingYearInfoQueryVariables>(
    GraduatingYearInfoDocument,
    options,
  );
}
export function useGraduatingYearInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraduatingYearInfoQuery,
    GraduatingYearInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraduatingYearInfoQuery, GraduatingYearInfoQueryVariables>(
    GraduatingYearInfoDocument,
    options,
  );
}
export type GraduatingYearInfoQueryHookResult = ReturnType<typeof useGraduatingYearInfoQuery>;
export type GraduatingYearInfoLazyQueryHookResult = ReturnType<
  typeof useGraduatingYearInfoLazyQuery
>;
export type GraduatingYearInfoQueryResult = Apollo.QueryResult<
  GraduatingYearInfoQuery,
  GraduatingYearInfoQueryVariables
>;
export const GraduatingYearsListDocument = gql`
  query GraduatingYearsList($filter: GraduatingYearFilter, $skip: Int, $first: Int) {
    graduatingYearsList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...GraduatingYearInfo
      }
    }
  }
  ${GraduatingYearInfoFragmentDoc}
`;

/**
 * __useGraduatingYearsListQuery__
 *
 * To run a query within a React component, call `useGraduatingYearsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraduatingYearsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraduatingYearsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGraduatingYearsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GraduatingYearsListQuery,
    GraduatingYearsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraduatingYearsListQuery, GraduatingYearsListQueryVariables>(
    GraduatingYearsListDocument,
    options,
  );
}
export function useGraduatingYearsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraduatingYearsListQuery,
    GraduatingYearsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraduatingYearsListQuery, GraduatingYearsListQueryVariables>(
    GraduatingYearsListDocument,
    options,
  );
}
export type GraduatingYearsListQueryHookResult = ReturnType<typeof useGraduatingYearsListQuery>;
export type GraduatingYearsListLazyQueryHookResult = ReturnType<
  typeof useGraduatingYearsListLazyQuery
>;
export type GraduatingYearsListQueryResult = Apollo.QueryResult<
  GraduatingYearsListQuery,
  GraduatingYearsListQueryVariables
>;
export const CreateGraduatingYearDocument = gql`
  mutation CreateGraduatingYear($data: GraduatingYearCreateInput!) {
    graduatingYearCreate(data: $data) {
      id
    }
  }
`;
export type CreateGraduatingYearMutationFn = Apollo.MutationFunction<
  CreateGraduatingYearMutation,
  CreateGraduatingYearMutationVariables
>;

/**
 * __useCreateGraduatingYearMutation__
 *
 * To run a mutation, you first call `useCreateGraduatingYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGraduatingYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGraduatingYearMutation, { data, loading, error }] = useCreateGraduatingYearMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateGraduatingYearMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGraduatingYearMutation,
    CreateGraduatingYearMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGraduatingYearMutation, CreateGraduatingYearMutationVariables>(
    CreateGraduatingYearDocument,
    options,
  );
}
export type CreateGraduatingYearMutationHookResult = ReturnType<
  typeof useCreateGraduatingYearMutation
>;
export type CreateGraduatingYearMutationResult =
  Apollo.MutationResult<CreateGraduatingYearMutation>;
export type CreateGraduatingYearMutationOptions = Apollo.BaseMutationOptions<
  CreateGraduatingYearMutation,
  CreateGraduatingYearMutationVariables
>;
export const EditGraduatingYearDocument = gql`
  mutation EditGraduatingYear($id: ID!, $data: GraduatingYearUpdateInput!) {
    graduatingYearUpdate(filter: { id: $id }, data: $data) {
      id
    }
  }
`;
export type EditGraduatingYearMutationFn = Apollo.MutationFunction<
  EditGraduatingYearMutation,
  EditGraduatingYearMutationVariables
>;

/**
 * __useEditGraduatingYearMutation__
 *
 * To run a mutation, you first call `useEditGraduatingYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGraduatingYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGraduatingYearMutation, { data, loading, error }] = useEditGraduatingYearMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditGraduatingYearMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditGraduatingYearMutation,
    EditGraduatingYearMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditGraduatingYearMutation, EditGraduatingYearMutationVariables>(
    EditGraduatingYearDocument,
    options,
  );
}
export type EditGraduatingYearMutationHookResult = ReturnType<typeof useEditGraduatingYearMutation>;
export type EditGraduatingYearMutationResult = Apollo.MutationResult<EditGraduatingYearMutation>;
export type EditGraduatingYearMutationOptions = Apollo.BaseMutationOptions<
  EditGraduatingYearMutation,
  EditGraduatingYearMutationVariables
>;
export const DeleteGraduatingYearDocument = gql`
  mutation DeleteGraduatingYear($data: GraduatingYearDeleteInput!) {
    graduatingYearDelete(data: $data) {
      success
    }
  }
`;
export type DeleteGraduatingYearMutationFn = Apollo.MutationFunction<
  DeleteGraduatingYearMutation,
  DeleteGraduatingYearMutationVariables
>;

/**
 * __useDeleteGraduatingYearMutation__
 *
 * To run a mutation, you first call `useDeleteGraduatingYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGraduatingYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGraduatingYearMutation, { data, loading, error }] = useDeleteGraduatingYearMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteGraduatingYearMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGraduatingYearMutation,
    DeleteGraduatingYearMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGraduatingYearMutation, DeleteGraduatingYearMutationVariables>(
    DeleteGraduatingYearDocument,
    options,
  );
}
export type DeleteGraduatingYearMutationHookResult = ReturnType<
  typeof useDeleteGraduatingYearMutation
>;
export type DeleteGraduatingYearMutationResult =
  Apollo.MutationResult<DeleteGraduatingYearMutation>;
export type DeleteGraduatingYearMutationOptions = Apollo.BaseMutationOptions<
  DeleteGraduatingYearMutation,
  DeleteGraduatingYearMutationVariables
>;
export const Get_User_Joined_GroupsDocument = gql`
  query GET_USER_JOINED_GROUPS($ID: ID!) {
    user(id: $ID) {
      joinedGroups {
        items {
          id
        }
      }
    }
  }
`;

/**
 * __useGet_User_Joined_GroupsQuery__
 *
 * To run a query within a React component, call `useGet_User_Joined_GroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_User_Joined_GroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_User_Joined_GroupsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGet_User_Joined_GroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Get_User_Joined_GroupsQuery,
    Get_User_Joined_GroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_User_Joined_GroupsQuery, Get_User_Joined_GroupsQueryVariables>(
    Get_User_Joined_GroupsDocument,
    options,
  );
}
export function useGet_User_Joined_GroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_User_Joined_GroupsQuery,
    Get_User_Joined_GroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_User_Joined_GroupsQuery, Get_User_Joined_GroupsQueryVariables>(
    Get_User_Joined_GroupsDocument,
    options,
  );
}
export type Get_User_Joined_GroupsQueryHookResult = ReturnType<
  typeof useGet_User_Joined_GroupsQuery
>;
export type Get_User_Joined_GroupsLazyQueryHookResult = ReturnType<
  typeof useGet_User_Joined_GroupsLazyQuery
>;
export type Get_User_Joined_GroupsQueryResult = Apollo.QueryResult<
  Get_User_Joined_GroupsQuery,
  Get_User_Joined_GroupsQueryVariables
>;
export const GroupMemberRequestUpsertDocument = gql`
  mutation GroupMemberRequestUpsert($groupId: ID!, $userId: ID!, $status: String!) {
    transaction: groupMembersRequestUpsert(groupId: $groupId, userId: $userId, status: $status) {
      successful
      message
    }
  }
`;
export type GroupMemberRequestUpsertMutationFn = Apollo.MutationFunction<
  GroupMemberRequestUpsertMutation,
  GroupMemberRequestUpsertMutationVariables
>;

/**
 * __useGroupMemberRequestUpsertMutation__
 *
 * To run a mutation, you first call `useGroupMemberRequestUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupMemberRequestUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupMemberRequestUpsertMutation, { data, loading, error }] = useGroupMemberRequestUpsertMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      userId: // value for 'userId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGroupMemberRequestUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupMemberRequestUpsertMutation,
    GroupMemberRequestUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GroupMemberRequestUpsertMutation,
    GroupMemberRequestUpsertMutationVariables
  >(GroupMemberRequestUpsertDocument, options);
}
export type GroupMemberRequestUpsertMutationHookResult = ReturnType<
  typeof useGroupMemberRequestUpsertMutation
>;
export type GroupMemberRequestUpsertMutationResult =
  Apollo.MutationResult<GroupMemberRequestUpsertMutation>;
export type GroupMemberRequestUpsertMutationOptions = Apollo.BaseMutationOptions<
  GroupMemberRequestUpsertMutation,
  GroupMemberRequestUpsertMutationVariables
>;
export const GroupMemberRequestsDocument = gql`
  query GroupMemberRequests($userId: ID!, $groupId: ID!) {
    requests: groupsMembersRequestsList(
      filter: { user: { id: { equals: $userId } }, group: { id: { equals: $groupId } } }
    ) {
      items {
        id
      }
      count
    }
  }
`;

/**
 * __useGroupMemberRequestsQuery__
 *
 * To run a query within a React component, call `useGroupMemberRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupMemberRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupMemberRequestsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupMemberRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<GroupMemberRequestsQuery, GroupMemberRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupMemberRequestsQuery, GroupMemberRequestsQueryVariables>(
    GroupMemberRequestsDocument,
    options,
  );
}
export function useGroupMemberRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupMemberRequestsQuery,
    GroupMemberRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupMemberRequestsQuery, GroupMemberRequestsQueryVariables>(
    GroupMemberRequestsDocument,
    options,
  );
}
export type GroupMemberRequestsQueryHookResult = ReturnType<typeof useGroupMemberRequestsQuery>;
export type GroupMemberRequestsLazyQueryHookResult = ReturnType<
  typeof useGroupMemberRequestsLazyQuery
>;
export type GroupMemberRequestsQueryResult = Apollo.QueryResult<
  GroupMemberRequestsQuery,
  GroupMemberRequestsQueryVariables
>;
export const HighSchoolsDocument = gql`
  query HighSchools(
    $filter: HighSchoolLocationFilter
    $first: Int
    $skip: Int
    $sort: [HighSchoolLocationSort!]
  ) {
    schools: highSchoolLocationsList(filter: $filter, first: $first, skip: $skip, sort: $sort) {
      count
      items {
        ...HighSchool
      }
    }
  }
  ${HighSchoolFragmentDoc}
`;

/**
 * __useHighSchoolsQuery__
 *
 * To run a query within a React component, call `useHighSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighSchoolsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useHighSchoolsQuery(
  baseOptions?: Apollo.QueryHookOptions<HighSchoolsQuery, HighSchoolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HighSchoolsQuery, HighSchoolsQueryVariables>(HighSchoolsDocument, options);
}
export function useHighSchoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HighSchoolsQuery, HighSchoolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HighSchoolsQuery, HighSchoolsQueryVariables>(
    HighSchoolsDocument,
    options,
  );
}
export type HighSchoolsQueryHookResult = ReturnType<typeof useHighSchoolsQuery>;
export type HighSchoolsLazyQueryHookResult = ReturnType<typeof useHighSchoolsLazyQuery>;
export type HighSchoolsQueryResult = Apollo.QueryResult<
  HighSchoolsQuery,
  HighSchoolsQueryVariables
>;
export const MessageSendRequestDocument = gql`
  mutation MessageSendRequest(
    $subject: JSON!
    $text: String!
    $userIds: [String!]
    $media: String
    $type: String
    $destinationGroups: JSON
  ) {
    messageSendRequest(
      subject: $subject
      text: $text
      userIds: $userIds
      media: $media
      type: $type
      destinationGroups: $destinationGroups
    ) {
      id
      subject
      users {
        items {
          id
          firstName
          lastName
          avatar {
            id
            downloadUrl
          }
        }
      }
      messages {
        items {
          id
          createdAt
          text
          isRead
          isDeleted
          media {
            items {
              id
              downloadUrl
            }
          }
          author {
            id
          }
          inbox {
            id
          }
        }
      }
    }
  }
`;
export type MessageSendRequestMutationFn = Apollo.MutationFunction<
  MessageSendRequestMutation,
  MessageSendRequestMutationVariables
>;

/**
 * __useMessageSendRequestMutation__
 *
 * To run a mutation, you first call `useMessageSendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageSendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageSendRequestMutation, { data, loading, error }] = useMessageSendRequestMutation({
 *   variables: {
 *      subject: // value for 'subject'
 *      text: // value for 'text'
 *      userIds: // value for 'userIds'
 *      media: // value for 'media'
 *      type: // value for 'type'
 *      destinationGroups: // value for 'destinationGroups'
 *   },
 * });
 */
export function useMessageSendRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MessageSendRequestMutation,
    MessageSendRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MessageSendRequestMutation, MessageSendRequestMutationVariables>(
    MessageSendRequestDocument,
    options,
  );
}
export type MessageSendRequestMutationHookResult = ReturnType<typeof useMessageSendRequestMutation>;
export type MessageSendRequestMutationResult = Apollo.MutationResult<MessageSendRequestMutation>;
export type MessageSendRequestMutationOptions = Apollo.BaseMutationOptions<
  MessageSendRequestMutation,
  MessageSendRequestMutationVariables
>;
export const CurrentLocationZipDocument = gql`
  query CurrentLocationZip($data: CurrentLocationZipRequestInput!) {
    currentLocationZipRequest(data: $data) {
      zip
    }
  }
`;

/**
 * __useCurrentLocationZipQuery__
 *
 * To run a query within a React component, call `useCurrentLocationZipQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentLocationZipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentLocationZipQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCurrentLocationZipQuery(
  baseOptions: Apollo.QueryHookOptions<CurrentLocationZipQuery, CurrentLocationZipQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentLocationZipQuery, CurrentLocationZipQueryVariables>(
    CurrentLocationZipDocument,
    options,
  );
}
export function useCurrentLocationZipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentLocationZipQuery,
    CurrentLocationZipQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentLocationZipQuery, CurrentLocationZipQueryVariables>(
    CurrentLocationZipDocument,
    options,
  );
}
export type CurrentLocationZipQueryHookResult = ReturnType<typeof useCurrentLocationZipQuery>;
export type CurrentLocationZipLazyQueryHookResult = ReturnType<
  typeof useCurrentLocationZipLazyQuery
>;
export type CurrentLocationZipQueryResult = Apollo.QueryResult<
  CurrentLocationZipQuery,
  CurrentLocationZipQueryVariables
>;
export const NeedHelpDocument = gql`
  mutation NeedHelp(
    $email: String!
    $firstName: String!
    $lastName: String!
    $subject: String!
    $message: String!
  ) {
    request: needHelpSendRequest(
      email: $email
      firstName: $firstName
      lastName: $lastName
      message: $message
      subject: $subject
    ) {
      success
    }
  }
`;
export type NeedHelpMutationFn = Apollo.MutationFunction<
  NeedHelpMutation,
  NeedHelpMutationVariables
>;

/**
 * __useNeedHelpMutation__
 *
 * To run a mutation, you first call `useNeedHelpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNeedHelpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [needHelpMutation, { data, loading, error }] = useNeedHelpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      subject: // value for 'subject'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useNeedHelpMutation(
  baseOptions?: Apollo.MutationHookOptions<NeedHelpMutation, NeedHelpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NeedHelpMutation, NeedHelpMutationVariables>(NeedHelpDocument, options);
}
export type NeedHelpMutationHookResult = ReturnType<typeof useNeedHelpMutation>;
export type NeedHelpMutationResult = Apollo.MutationResult<NeedHelpMutation>;
export type NeedHelpMutationOptions = Apollo.BaseMutationOptions<
  NeedHelpMutation,
  NeedHelpMutationVariables
>;
export const SendBusinessManagerInvitesDocument = gql`
  mutation SendBusinessManagerInvites(
    $school: SchoolConfigurationKeyFilter
    $organization: OrganizationKeyFilter!
    $invites: [SendBusinessManagerInviteInput!]!
  ) {
    commit: sendBusinessManagerInvites(
      organization: $organization
      school: $school
      invites: $invites
    ) {
      success
    }
  }
`;
export type SendBusinessManagerInvitesMutationFn = Apollo.MutationFunction<
  SendBusinessManagerInvitesMutation,
  SendBusinessManagerInvitesMutationVariables
>;

/**
 * __useSendBusinessManagerInvitesMutation__
 *
 * To run a mutation, you first call `useSendBusinessManagerInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBusinessManagerInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBusinessManagerInvitesMutation, { data, loading, error }] = useSendBusinessManagerInvitesMutation({
 *   variables: {
 *      school: // value for 'school'
 *      organization: // value for 'organization'
 *      invites: // value for 'invites'
 *   },
 * });
 */
export function useSendBusinessManagerInvitesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendBusinessManagerInvitesMutation,
    SendBusinessManagerInvitesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendBusinessManagerInvitesMutation,
    SendBusinessManagerInvitesMutationVariables
  >(SendBusinessManagerInvitesDocument, options);
}
export type SendBusinessManagerInvitesMutationHookResult = ReturnType<
  typeof useSendBusinessManagerInvitesMutation
>;
export type SendBusinessManagerInvitesMutationResult =
  Apollo.MutationResult<SendBusinessManagerInvitesMutation>;
export type SendBusinessManagerInvitesMutationOptions = Apollo.BaseMutationOptions<
  SendBusinessManagerInvitesMutation,
  SendBusinessManagerInvitesMutationVariables
>;
export const PostCheckDocument = gql`
  query PostCheck(
    $text: String
    $media: [FileKeyFilter!]
    $blacklistId: String!
    $postId: String
    $postType: String
  ) {
    postModerateRequest(
      text: $text
      media: $media
      blacklistId: $blacklistId
      postId: $postId
      postType: $postType
    ) {
      verified
    }
  }
`;

/**
 * __usePostCheckQuery__
 *
 * To run a query within a React component, call `usePostCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostCheckQuery({
 *   variables: {
 *      text: // value for 'text'
 *      media: // value for 'media'
 *      blacklistId: // value for 'blacklistId'
 *      postId: // value for 'postId'
 *      postType: // value for 'postType'
 *   },
 * });
 */
export function usePostCheckQuery(
  baseOptions: Apollo.QueryHookOptions<PostCheckQuery, PostCheckQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PostCheckQuery, PostCheckQueryVariables>(PostCheckDocument, options);
}
export function usePostCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PostCheckQuery, PostCheckQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PostCheckQuery, PostCheckQueryVariables>(PostCheckDocument, options);
}
export type PostCheckQueryHookResult = ReturnType<typeof usePostCheckQuery>;
export type PostCheckLazyQueryHookResult = ReturnType<typeof usePostCheckLazyQuery>;
export type PostCheckQueryResult = Apollo.QueryResult<PostCheckQuery, PostCheckQueryVariables>;
export const UpdatePostDocument = gql`
  mutation updatePost($postId: ID!, $postData: JSON!, $postType: String!) {
    updatePost(postData: $postData, postId: $postId, postType: $postType) {
      successful
      data
    }
  }
`;
export type UpdatePostMutationFn = Apollo.MutationFunction<
  UpdatePostMutation,
  UpdatePostMutationVariables
>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      postData: // value for 'postData'
 *      postType: // value for 'postType'
 *   },
 * });
 */
export function useUpdatePostMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(
    UpdatePostDocument,
    options,
  );
}
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostMutation,
  UpdatePostMutationVariables
>;
export const FindPostDocument = gql`
  query FindPost($id: ID!) {
    homeFeedPost(id: $id) {
      ...MinimalHomeFeedPost
    }
    schoolNewsPost(id: $id) {
      ...MinimalSchoolNewsPost
    }
    legacyFeedPost(id: $id) {
      ...MinimalLegacyFeedPost
    }
    groupPost(id: $id) {
      ...MinimalGroupPost
    }
  }
  ${MinimalHomeFeedPostFragmentDoc}
  ${MinimalSchoolNewsPostFragmentDoc}
  ${MinimalLegacyFeedPostFragmentDoc}
  ${MinimalGroupPostFragmentDoc}
`;

/**
 * __useFindPostQuery__
 *
 * To run a query within a React component, call `useFindPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindPostQuery(
  baseOptions: Apollo.QueryHookOptions<FindPostQuery, FindPostQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindPostQuery, FindPostQueryVariables>(FindPostDocument, options);
}
export function useFindPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindPostQuery, FindPostQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindPostQuery, FindPostQueryVariables>(FindPostDocument, options);
}
export type FindPostQueryHookResult = ReturnType<typeof useFindPostQuery>;
export type FindPostLazyQueryHookResult = ReturnType<typeof useFindPostLazyQuery>;
export type FindPostQueryResult = Apollo.QueryResult<FindPostQuery, FindPostQueryVariables>;
export const PromoteSchoolMemberDocument = gql`
  mutation PromoteSchoolMember($user: UserKeyFilter!, $school: SchoolConfigurationKeyFilter!) {
    commit: promoteSchoolMember(user: $user, school: $school) {
      success
    }
  }
`;
export type PromoteSchoolMemberMutationFn = Apollo.MutationFunction<
  PromoteSchoolMemberMutation,
  PromoteSchoolMemberMutationVariables
>;

/**
 * __usePromoteSchoolMemberMutation__
 *
 * To run a mutation, you first call `usePromoteSchoolMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteSchoolMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteSchoolMemberMutation, { data, loading, error }] = usePromoteSchoolMemberMutation({
 *   variables: {
 *      user: // value for 'user'
 *      school: // value for 'school'
 *   },
 * });
 */
export function usePromoteSchoolMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PromoteSchoolMemberMutation,
    PromoteSchoolMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PromoteSchoolMemberMutation, PromoteSchoolMemberMutationVariables>(
    PromoteSchoolMemberDocument,
    options,
  );
}
export type PromoteSchoolMemberMutationHookResult = ReturnType<
  typeof usePromoteSchoolMemberMutation
>;
export type PromoteSchoolMemberMutationResult = Apollo.MutationResult<PromoteSchoolMemberMutation>;
export type PromoteSchoolMemberMutationOptions = Apollo.BaseMutationOptions<
  PromoteSchoolMemberMutation,
  PromoteSchoolMemberMutationVariables
>;
export const RequestCommunityAccessDocument = gql`
  mutation RequestCommunityAccess(
    $email: String!
    $firstname: String!
    $lastname: String!
    $affiliation: String!
    $affiliationDescription: String
    $graduatingYear: String
    $phoneNumber: String
    $school: SchoolConfigurationKeyFilter!
  ) {
    commit: requestCommunityAccess(
      email: $email
      firstname: $firstname
      lastname: $lastname
      affiliation: $affiliation
      affiliationDescription: $affiliationDescription
      graduatingYear: $graduatingYear
      phoneNumber: $phoneNumber
      school: $school
    ) {
      success
    }
  }
`;
export type RequestCommunityAccessMutationFn = Apollo.MutationFunction<
  RequestCommunityAccessMutation,
  RequestCommunityAccessMutationVariables
>;

/**
 * __useRequestCommunityAccessMutation__
 *
 * To run a mutation, you first call `useRequestCommunityAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCommunityAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCommunityAccessMutation, { data, loading, error }] = useRequestCommunityAccessMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      affiliation: // value for 'affiliation'
 *      affiliationDescription: // value for 'affiliationDescription'
 *      graduatingYear: // value for 'graduatingYear'
 *      phoneNumber: // value for 'phoneNumber'
 *      school: // value for 'school'
 *   },
 * });
 */
export function useRequestCommunityAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestCommunityAccessMutation,
    RequestCommunityAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestCommunityAccessMutation,
    RequestCommunityAccessMutationVariables
  >(RequestCommunityAccessDocument, options);
}
export type RequestCommunityAccessMutationHookResult = ReturnType<
  typeof useRequestCommunityAccessMutation
>;
export type RequestCommunityAccessMutationResult =
  Apollo.MutationResult<RequestCommunityAccessMutation>;
export type RequestCommunityAccessMutationOptions = Apollo.BaseMutationOptions<
  RequestCommunityAccessMutation,
  RequestCommunityAccessMutationVariables
>;
export const CurrentUserRoleAddonsDocument = gql`
  query CurrentUserRoleAddons($school: ID!) {
    rolesAddons: roleAddonsList(
      filter: { school: { id: { equals: $school } }, users: { some: { is_self: true } } }
    ) {
      items {
        ...RoleAddon
      }
    }
  }
  ${RoleAddonFragmentDoc}
`;

/**
 * __useCurrentUserRoleAddonsQuery__
 *
 * To run a query within a React component, call `useCurrentUserRoleAddonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserRoleAddonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserRoleAddonsQuery({
 *   variables: {
 *      school: // value for 'school'
 *   },
 * });
 */
export function useCurrentUserRoleAddonsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentUserRoleAddonsQuery,
    CurrentUserRoleAddonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserRoleAddonsQuery, CurrentUserRoleAddonsQueryVariables>(
    CurrentUserRoleAddonsDocument,
    options,
  );
}
export function useCurrentUserRoleAddonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserRoleAddonsQuery,
    CurrentUserRoleAddonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserRoleAddonsQuery, CurrentUserRoleAddonsQueryVariables>(
    CurrentUserRoleAddonsDocument,
    options,
  );
}
export type CurrentUserRoleAddonsQueryHookResult = ReturnType<typeof useCurrentUserRoleAddonsQuery>;
export type CurrentUserRoleAddonsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserRoleAddonsLazyQuery
>;
export type CurrentUserRoleAddonsQueryResult = Apollo.QueryResult<
  CurrentUserRoleAddonsQuery,
  CurrentUserRoleAddonsQueryVariables
>;
export const SystemRolesDocument = gql`
  query SystemRoles($filter: RoleFilter, $skip: Int, $first: Int) {
    rolesList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      items {
        ...RoleInfo
      }
    }
  }
  ${RoleInfoFragmentDoc}
`;

/**
 * __useSystemRolesQuery__
 *
 * To run a query within a React component, call `useSystemRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemRolesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSystemRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<SystemRolesQuery, SystemRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SystemRolesQuery, SystemRolesQueryVariables>(SystemRolesDocument, options);
}
export function useSystemRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SystemRolesQuery, SystemRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SystemRolesQuery, SystemRolesQueryVariables>(
    SystemRolesDocument,
    options,
  );
}
export type SystemRolesQueryHookResult = ReturnType<typeof useSystemRolesQuery>;
export type SystemRolesLazyQueryHookResult = ReturnType<typeof useSystemRolesLazyQuery>;
export type SystemRolesQueryResult = Apollo.QueryResult<
  SystemRolesQuery,
  SystemRolesQueryVariables
>;
export const RoleAddonsDocument = gql`
  query RoleAddons($filter: RoleAddonFilter, $skip: Int, $first: Int) {
    roleAddonsList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      items {
        ...RoleAddonInfo
      }
    }
  }
  ${RoleAddonInfoFragmentDoc}
`;

/**
 * __useRoleAddonsQuery__
 *
 * To run a query within a React component, call `useRoleAddonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleAddonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleAddonsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useRoleAddonsQuery(
  baseOptions?: Apollo.QueryHookOptions<RoleAddonsQuery, RoleAddonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleAddonsQuery, RoleAddonsQueryVariables>(RoleAddonsDocument, options);
}
export function useRoleAddonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RoleAddonsQuery, RoleAddonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RoleAddonsQuery, RoleAddonsQueryVariables>(
    RoleAddonsDocument,
    options,
  );
}
export type RoleAddonsQueryHookResult = ReturnType<typeof useRoleAddonsQuery>;
export type RoleAddonsLazyQueryHookResult = ReturnType<typeof useRoleAddonsLazyQuery>;
export type RoleAddonsQueryResult = Apollo.QueryResult<RoleAddonsQuery, RoleAddonsQueryVariables>;
export const RoleAddonCreateDocument = gql`
  mutation RoleAddonCreate($input: RoleAddonCreateInput!) {
    roleAddonCreate(data: $input) {
      ...RoleAddonInfo
    }
  }
  ${RoleAddonInfoFragmentDoc}
`;
export type RoleAddonCreateMutationFn = Apollo.MutationFunction<
  RoleAddonCreateMutation,
  RoleAddonCreateMutationVariables
>;

/**
 * __useRoleAddonCreateMutation__
 *
 * To run a mutation, you first call `useRoleAddonCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoleAddonCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roleAddonCreateMutation, { data, loading, error }] = useRoleAddonCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRoleAddonCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RoleAddonCreateMutation,
    RoleAddonCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RoleAddonCreateMutation, RoleAddonCreateMutationVariables>(
    RoleAddonCreateDocument,
    options,
  );
}
export type RoleAddonCreateMutationHookResult = ReturnType<typeof useRoleAddonCreateMutation>;
export type RoleAddonCreateMutationResult = Apollo.MutationResult<RoleAddonCreateMutation>;
export type RoleAddonCreateMutationOptions = Apollo.BaseMutationOptions<
  RoleAddonCreateMutation,
  RoleAddonCreateMutationVariables
>;
export const RoleAddonUpdateDocument = gql`
  mutation RoleAddonUpdate($roleId: ID!, $input: RoleAddonUpdateInput!) {
    roleAddonUpdate(filter: { id: $roleId }, data: $input) {
      ...RoleAddonInfo
    }
  }
  ${RoleAddonInfoFragmentDoc}
`;
export type RoleAddonUpdateMutationFn = Apollo.MutationFunction<
  RoleAddonUpdateMutation,
  RoleAddonUpdateMutationVariables
>;

/**
 * __useRoleAddonUpdateMutation__
 *
 * To run a mutation, you first call `useRoleAddonUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoleAddonUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roleAddonUpdateMutation, { data, loading, error }] = useRoleAddonUpdateMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRoleAddonUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RoleAddonUpdateMutation,
    RoleAddonUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RoleAddonUpdateMutation, RoleAddonUpdateMutationVariables>(
    RoleAddonUpdateDocument,
    options,
  );
}
export type RoleAddonUpdateMutationHookResult = ReturnType<typeof useRoleAddonUpdateMutation>;
export type RoleAddonUpdateMutationResult = Apollo.MutationResult<RoleAddonUpdateMutation>;
export type RoleAddonUpdateMutationOptions = Apollo.BaseMutationOptions<
  RoleAddonUpdateMutation,
  RoleAddonUpdateMutationVariables
>;
export const UserUpdateRolesDocument = gql`
  mutation UserUpdateRoles($input: UserUpdateRolesInput!) {
    userUpdateRoles(input: $input) {
      success
    }
  }
`;
export type UserUpdateRolesMutationFn = Apollo.MutationFunction<
  UserUpdateRolesMutation,
  UserUpdateRolesMutationVariables
>;

/**
 * __useUserUpdateRolesMutation__
 *
 * To run a mutation, you first call `useUserUpdateRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateRolesMutation, { data, loading, error }] = useUserUpdateRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateRolesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserUpdateRolesMutation,
    UserUpdateRolesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserUpdateRolesMutation, UserUpdateRolesMutationVariables>(
    UserUpdateRolesDocument,
    options,
  );
}
export type UserUpdateRolesMutationHookResult = ReturnType<typeof useUserUpdateRolesMutation>;
export type UserUpdateRolesMutationResult = Apollo.MutationResult<UserUpdateRolesMutation>;
export type UserUpdateRolesMutationOptions = Apollo.BaseMutationOptions<
  UserUpdateRolesMutation,
  UserUpdateRolesMutationVariables
>;
export const SchoolConfigurationDocument = gql`
  query SchoolConfiguration($id: ID!) {
    school: schoolConfiguration(id: $id) {
      ...SchoolConfiguration
    }
  }
  ${SchoolConfigurationFragmentDoc}
`;

/**
 * __useSchoolConfigurationQuery__
 *
 * To run a query within a React component, call `useSchoolConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<SchoolConfigurationQuery, SchoolConfigurationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchoolConfigurationQuery, SchoolConfigurationQueryVariables>(
    SchoolConfigurationDocument,
    options,
  );
}
export function useSchoolConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolConfigurationQuery,
    SchoolConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchoolConfigurationQuery, SchoolConfigurationQueryVariables>(
    SchoolConfigurationDocument,
    options,
  );
}
export type SchoolConfigurationQueryHookResult = ReturnType<typeof useSchoolConfigurationQuery>;
export type SchoolConfigurationLazyQueryHookResult = ReturnType<
  typeof useSchoolConfigurationLazyQuery
>;
export type SchoolConfigurationQueryResult = Apollo.QueryResult<
  SchoolConfigurationQuery,
  SchoolConfigurationQueryVariables
>;
export const CurrentSchoolConfigurationDocument = gql`
  query CurrentSchoolConfiguration($id: ID!) {
    school: schoolConfiguration(id: $id) {
      ...CurrentSchoolConfiguration
    }
  }
  ${CurrentSchoolConfigurationFragmentDoc}
`;

/**
 * __useCurrentSchoolConfigurationQuery__
 *
 * To run a query within a React component, call `useCurrentSchoolConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentSchoolConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentSchoolConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCurrentSchoolConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentSchoolConfigurationQuery,
    CurrentSchoolConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentSchoolConfigurationQuery, CurrentSchoolConfigurationQueryVariables>(
    CurrentSchoolConfigurationDocument,
    options,
  );
}
export function useCurrentSchoolConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentSchoolConfigurationQuery,
    CurrentSchoolConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentSchoolConfigurationQuery,
    CurrentSchoolConfigurationQueryVariables
  >(CurrentSchoolConfigurationDocument, options);
}
export type CurrentSchoolConfigurationQueryHookResult = ReturnType<
  typeof useCurrentSchoolConfigurationQuery
>;
export type CurrentSchoolConfigurationLazyQueryHookResult = ReturnType<
  typeof useCurrentSchoolConfigurationLazyQuery
>;
export type CurrentSchoolConfigurationQueryResult = Apollo.QueryResult<
  CurrentSchoolConfigurationQuery,
  CurrentSchoolConfigurationQueryVariables
>;
export const SchoolConfigurationsDocument = gql`
  query SchoolConfigurations($filter: SchoolConfigurationFilter) {
    schools: schoolConfigurationsList(filter: $filter) {
      items {
        ...SchoolConfiguration
      }
    }
  }
  ${SchoolConfigurationFragmentDoc}
`;

/**
 * __useSchoolConfigurationsQuery__
 *
 * To run a query within a React component, call `useSchoolConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolConfigurationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSchoolConfigurationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SchoolConfigurationsQuery,
    SchoolConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchoolConfigurationsQuery, SchoolConfigurationsQueryVariables>(
    SchoolConfigurationsDocument,
    options,
  );
}
export function useSchoolConfigurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolConfigurationsQuery,
    SchoolConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchoolConfigurationsQuery, SchoolConfigurationsQueryVariables>(
    SchoolConfigurationsDocument,
    options,
  );
}
export type SchoolConfigurationsQueryHookResult = ReturnType<typeof useSchoolConfigurationsQuery>;
export type SchoolConfigurationsLazyQueryHookResult = ReturnType<
  typeof useSchoolConfigurationsLazyQuery
>;
export type SchoolConfigurationsQueryResult = Apollo.QueryResult<
  SchoolConfigurationsQuery,
  SchoolConfigurationsQueryVariables
>;
export const SendSchoolMemberInvitesDocument = gql`
  mutation SendSchoolMemberInvites(
    $school: SchoolConfigurationKeyFilter!
    $isSchoolAdministratorInviting: Boolean = false
    $invites: [SendSchoolMemberInviteInput!]!
  ) {
    commit: sendSchoolMemberInvites(
      invites: $invites
      school: $school
      isSchoolAdministratorInviting: $isSchoolAdministratorInviting
    ) {
      success
    }
  }
`;
export type SendSchoolMemberInvitesMutationFn = Apollo.MutationFunction<
  SendSchoolMemberInvitesMutation,
  SendSchoolMemberInvitesMutationVariables
>;

/**
 * __useSendSchoolMemberInvitesMutation__
 *
 * To run a mutation, you first call `useSendSchoolMemberInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSchoolMemberInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSchoolMemberInvitesMutation, { data, loading, error }] = useSendSchoolMemberInvitesMutation({
 *   variables: {
 *      school: // value for 'school'
 *      isSchoolAdministratorInviting: // value for 'isSchoolAdministratorInviting'
 *      invites: // value for 'invites'
 *   },
 * });
 */
export function useSendSchoolMemberInvitesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSchoolMemberInvitesMutation,
    SendSchoolMemberInvitesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendSchoolMemberInvitesMutation,
    SendSchoolMemberInvitesMutationVariables
  >(SendSchoolMemberInvitesDocument, options);
}
export type SendSchoolMemberInvitesMutationHookResult = ReturnType<
  typeof useSendSchoolMemberInvitesMutation
>;
export type SendSchoolMemberInvitesMutationResult =
  Apollo.MutationResult<SendSchoolMemberInvitesMutation>;
export type SendSchoolMemberInvitesMutationOptions = Apollo.BaseMutationOptions<
  SendSchoolMemberInvitesMutation,
  SendSchoolMemberInvitesMutationVariables
>;
export const SendSchoolAdministratorInvitesDocument = gql`
  mutation SendSchoolAdministratorInvites(
    $school: SchoolConfigurationKeyFilter!
    $invites: [SendSchoolAdministratorInviteInput!]!
  ) {
    commit: sendSchoolAdministratorInvites(invites: $invites, school: $school) {
      success
    }
  }
`;
export type SendSchoolAdministratorInvitesMutationFn = Apollo.MutationFunction<
  SendSchoolAdministratorInvitesMutation,
  SendSchoolAdministratorInvitesMutationVariables
>;

/**
 * __useSendSchoolAdministratorInvitesMutation__
 *
 * To run a mutation, you first call `useSendSchoolAdministratorInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSchoolAdministratorInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSchoolAdministratorInvitesMutation, { data, loading, error }] = useSendSchoolAdministratorInvitesMutation({
 *   variables: {
 *      school: // value for 'school'
 *      invites: // value for 'invites'
 *   },
 * });
 */
export function useSendSchoolAdministratorInvitesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSchoolAdministratorInvitesMutation,
    SendSchoolAdministratorInvitesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendSchoolAdministratorInvitesMutation,
    SendSchoolAdministratorInvitesMutationVariables
  >(SendSchoolAdministratorInvitesDocument, options);
}
export type SendSchoolAdministratorInvitesMutationHookResult = ReturnType<
  typeof useSendSchoolAdministratorInvitesMutation
>;
export type SendSchoolAdministratorInvitesMutationResult =
  Apollo.MutationResult<SendSchoolAdministratorInvitesMutation>;
export type SendSchoolAdministratorInvitesMutationOptions = Apollo.BaseMutationOptions<
  SendSchoolAdministratorInvitesMutation,
  SendSchoolAdministratorInvitesMutationVariables
>;
export const SendBusinessAdministratorInvitesDocument = gql`
  mutation SendBusinessAdministratorInvites(
    $school: SchoolConfigurationKeyFilter!
    $invites: [SendBusinessAdministratorInviteInput!]!
  ) {
    commit: sendBusinessAdministratorInvites(invites: $invites, school: $school) {
      success
    }
  }
`;
export type SendBusinessAdministratorInvitesMutationFn = Apollo.MutationFunction<
  SendBusinessAdministratorInvitesMutation,
  SendBusinessAdministratorInvitesMutationVariables
>;

/**
 * __useSendBusinessAdministratorInvitesMutation__
 *
 * To run a mutation, you first call `useSendBusinessAdministratorInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBusinessAdministratorInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBusinessAdministratorInvitesMutation, { data, loading, error }] = useSendBusinessAdministratorInvitesMutation({
 *   variables: {
 *      school: // value for 'school'
 *      invites: // value for 'invites'
 *   },
 * });
 */
export function useSendBusinessAdministratorInvitesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendBusinessAdministratorInvitesMutation,
    SendBusinessAdministratorInvitesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendBusinessAdministratorInvitesMutation,
    SendBusinessAdministratorInvitesMutationVariables
  >(SendBusinessAdministratorInvitesDocument, options);
}
export type SendBusinessAdministratorInvitesMutationHookResult = ReturnType<
  typeof useSendBusinessAdministratorInvitesMutation
>;
export type SendBusinessAdministratorInvitesMutationResult =
  Apollo.MutationResult<SendBusinessAdministratorInvitesMutation>;
export type SendBusinessAdministratorInvitesMutationOptions = Apollo.BaseMutationOptions<
  SendBusinessAdministratorInvitesMutation,
  SendBusinessAdministratorInvitesMutationVariables
>;
export const StreamChatAuthorizationDocument = gql`
  query StreamChatAuthorization {
    authorization: streamChatAuthorize {
      token: idToken
    }
  }
`;

/**
 * __useStreamChatAuthorizationQuery__
 *
 * To run a query within a React component, call `useStreamChatAuthorizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreamChatAuthorizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamChatAuthorizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useStreamChatAuthorizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StreamChatAuthorizationQuery,
    StreamChatAuthorizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StreamChatAuthorizationQuery, StreamChatAuthorizationQueryVariables>(
    StreamChatAuthorizationDocument,
    options,
  );
}
export function useStreamChatAuthorizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StreamChatAuthorizationQuery,
    StreamChatAuthorizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StreamChatAuthorizationQuery, StreamChatAuthorizationQueryVariables>(
    StreamChatAuthorizationDocument,
    options,
  );
}
export type StreamChatAuthorizationQueryHookResult = ReturnType<
  typeof useStreamChatAuthorizationQuery
>;
export type StreamChatAuthorizationLazyQueryHookResult = ReturnType<
  typeof useStreamChatAuthorizationLazyQuery
>;
export type StreamChatAuthorizationQueryResult = Apollo.QueryResult<
  StreamChatAuthorizationQuery,
  StreamChatAuthorizationQueryVariables
>;
export const SupportedSchoolsCountDocument = gql`
  query SupportedSchoolsCount($id: ID) {
    schoolSupportersList(filter: { supporter: { id: { equals: $id } } }) {
      count
    }
  }
`;

/**
 * __useSupportedSchoolsCountQuery__
 *
 * To run a query within a React component, call `useSupportedSchoolsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportedSchoolsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportedSchoolsCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupportedSchoolsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SupportedSchoolsCountQuery,
    SupportedSchoolsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SupportedSchoolsCountQuery, SupportedSchoolsCountQueryVariables>(
    SupportedSchoolsCountDocument,
    options,
  );
}
export function useSupportedSchoolsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SupportedSchoolsCountQuery,
    SupportedSchoolsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SupportedSchoolsCountQuery, SupportedSchoolsCountQueryVariables>(
    SupportedSchoolsCountDocument,
    options,
  );
}
export type SupportedSchoolsCountQueryHookResult = ReturnType<typeof useSupportedSchoolsCountQuery>;
export type SupportedSchoolsCountLazyQueryHookResult = ReturnType<
  typeof useSupportedSchoolsCountLazyQuery
>;
export type SupportedSchoolsCountQueryResult = Apollo.QueryResult<
  SupportedSchoolsCountQuery,
  SupportedSchoolsCountQueryVariables
>;
export const SupporterSearchDocument = gql`
  query SupporterSearch($filter: OrganizationFilter, $first: Int!, $sort: [OrganizationSort!]) {
    organizationsList(filter: $filter, first: $first, sort: $sort) {
      items {
        ...SupporterInfo
      }
    }
  }
  ${SupporterInfoFragmentDoc}
`;

/**
 * __useSupporterSearchQuery__
 *
 * To run a query within a React component, call `useSupporterSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupporterSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupporterSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSupporterSearchQuery(
  baseOptions: Apollo.QueryHookOptions<SupporterSearchQuery, SupporterSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SupporterSearchQuery, SupporterSearchQueryVariables>(
    SupporterSearchDocument,
    options,
  );
}
export function useSupporterSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SupporterSearchQuery, SupporterSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SupporterSearchQuery, SupporterSearchQueryVariables>(
    SupporterSearchDocument,
    options,
  );
}
export type SupporterSearchQueryHookResult = ReturnType<typeof useSupporterSearchQuery>;
export type SupporterSearchLazyQueryHookResult = ReturnType<typeof useSupporterSearchLazyQuery>;
export type SupporterSearchQueryResult = Apollo.QueryResult<
  SupporterSearchQuery,
  SupporterSearchQueryVariables
>;
export const CurrentUserDocument = gql`
  query CurrentUser {
    user {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const CurrentUserSavedJobOffersDocument = gql`
  query CurrentUserSavedJobOffers($filter: JobOfferFilter) {
    user {
      preferences: userPreferences {
        saved: jobsOfInterest(filter: $filter) {
          items {
            ...JobOfferPresentation
          }
        }
      }
    }
  }
  ${JobOfferPresentationFragmentDoc}
`;

/**
 * __useCurrentUserSavedJobOffersQuery__
 *
 * To run a query within a React component, call `useCurrentUserSavedJobOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserSavedJobOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserSavedJobOffersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCurrentUserSavedJobOffersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserSavedJobOffersQuery,
    CurrentUserSavedJobOffersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserSavedJobOffersQuery, CurrentUserSavedJobOffersQueryVariables>(
    CurrentUserSavedJobOffersDocument,
    options,
  );
}
export function useCurrentUserSavedJobOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserSavedJobOffersQuery,
    CurrentUserSavedJobOffersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserSavedJobOffersQuery,
    CurrentUserSavedJobOffersQueryVariables
  >(CurrentUserSavedJobOffersDocument, options);
}
export type CurrentUserSavedJobOffersQueryHookResult = ReturnType<
  typeof useCurrentUserSavedJobOffersQuery
>;
export type CurrentUserSavedJobOffersLazyQueryHookResult = ReturnType<
  typeof useCurrentUserSavedJobOffersLazyQuery
>;
export type CurrentUserSavedJobOffersQueryResult = Apollo.QueryResult<
  CurrentUserSavedJobOffersQuery,
  CurrentUserSavedJobOffersQueryVariables
>;
export const UserByIdDocument = gql`
  query UserById($id: ID) {
    user(id: $id) {
      ...UserDetailed
    }
  }
  ${UserDetailedFragmentDoc}
`;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
}
export function useUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
}
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export const UserUpdateDocument = gql`
  mutation UserUpdate($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;
export type UserUpdateMutationFn = Apollo.MutationFunction<
  UserUpdateMutation,
  UserUpdateMutationVariables
>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(
    UserUpdateDocument,
    options,
  );
}
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<
  UserUpdateMutation,
  UserUpdateMutationVariables
>;
export const UserDeleteDocument = gql`
  mutation UserDelete($data: UserDeleteInput!) {
    userDelete(data: $data) {
      success
    }
  }
`;
export type UserDeleteMutationFn = Apollo.MutationFunction<
  UserDeleteMutation,
  UserDeleteMutationVariables
>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<UserDeleteMutation, UserDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(
    UserDeleteDocument,
    options,
  );
}
export type UserDeleteMutationHookResult = ReturnType<typeof useUserDeleteMutation>;
export type UserDeleteMutationResult = Apollo.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<
  UserDeleteMutation,
  UserDeleteMutationVariables
>;
export const UsersListCountDocument = gql`
  query UsersListCount($filter: UserFilter) {
    users: usersList(filter: $filter) {
      count
    }
  }
`;

/**
 * __useUsersListCountQuery__
 *
 * To run a query within a React component, call `useUsersListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUsersListCountQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersListCountQuery, UsersListCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersListCountQuery, UsersListCountQueryVariables>(
    UsersListCountDocument,
    options,
  );
}
export function useUsersListCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersListCountQuery, UsersListCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersListCountQuery, UsersListCountQueryVariables>(
    UsersListCountDocument,
    options,
  );
}
export type UsersListCountQueryHookResult = ReturnType<typeof useUsersListCountQuery>;
export type UsersListCountLazyQueryHookResult = ReturnType<typeof useUsersListCountLazyQuery>;
export type UsersListCountQueryResult = Apollo.QueryResult<
  UsersListCountQuery,
  UsersListCountQueryVariables
>;
export const UsersListDocument = gql`
  query UsersList($filter: UserFilter, $first: Int, $skip: Int, $sort: [UserSort!]) {
    usersList(filter: $filter, first: $first, skip: $skip, sort: $sort) {
      count
      items {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useUsersListQuery__
 *
 * To run a query within a React component, call `useUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUsersListQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersListQuery, UsersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersListQuery, UsersListQueryVariables>(UsersListDocument, options);
}
export function useUsersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersListQuery, UsersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersListQuery, UsersListQueryVariables>(UsersListDocument, options);
}
export type UsersListQueryHookResult = ReturnType<typeof useUsersListQuery>;
export type UsersListLazyQueryHookResult = ReturnType<typeof useUsersListLazyQuery>;
export type UsersListQueryResult = Apollo.QueryResult<UsersListQuery, UsersListQueryVariables>;
export const MinimalUsersListDocument = gql`
  query MinimalUsersList($filter: UserFilter, $skip: Int, $first: Int, $sort: [UserSort!]) {
    users: usersList(filter: $filter, sort: $sort, skip: $skip, first: $first) {
      count
      items {
        ...MinimalUser
      }
    }
  }
  ${MinimalUserFragmentDoc}
`;

/**
 * __useMinimalUsersListQuery__
 *
 * To run a query within a React component, call `useMinimalUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMinimalUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinimalUsersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useMinimalUsersListQuery(
  baseOptions?: Apollo.QueryHookOptions<MinimalUsersListQuery, MinimalUsersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MinimalUsersListQuery, MinimalUsersListQueryVariables>(
    MinimalUsersListDocument,
    options,
  );
}
export function useMinimalUsersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MinimalUsersListQuery, MinimalUsersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MinimalUsersListQuery, MinimalUsersListQueryVariables>(
    MinimalUsersListDocument,
    options,
  );
}
export type MinimalUsersListQueryHookResult = ReturnType<typeof useMinimalUsersListQuery>;
export type MinimalUsersListLazyQueryHookResult = ReturnType<typeof useMinimalUsersListLazyQuery>;
export type MinimalUsersListQueryResult = Apollo.QueryResult<
  MinimalUsersListQuery,
  MinimalUsersListQueryVariables
>;
export const CommentAuthorDocument = gql`
  query CommentAuthor($id: ID!) {
    user(id: $id) {
      ...MinimalUser
    }
  }
  ${MinimalUserFragmentDoc}
`;

/**
 * __useCommentAuthorQuery__
 *
 * To run a query within a React component, call `useCommentAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentAuthorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommentAuthorQuery(
  baseOptions: Apollo.QueryHookOptions<CommentAuthorQuery, CommentAuthorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommentAuthorQuery, CommentAuthorQueryVariables>(
    CommentAuthorDocument,
    options,
  );
}
export function useCommentAuthorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CommentAuthorQuery, CommentAuthorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CommentAuthorQuery, CommentAuthorQueryVariables>(
    CommentAuthorDocument,
    options,
  );
}
export type CommentAuthorQueryHookResult = ReturnType<typeof useCommentAuthorQuery>;
export type CommentAuthorLazyQueryHookResult = ReturnType<typeof useCommentAuthorLazyQuery>;
export type CommentAuthorQueryResult = Apollo.QueryResult<
  CommentAuthorQuery,
  CommentAuthorQueryVariables
>;
export const ResetUserCredentialsDocument = gql`
  mutation ResetUserCredentials($user: UserKeyFilter!) {
    resetUserCredentials(user: $user) {
      success
    }
  }
`;
export type ResetUserCredentialsMutationFn = Apollo.MutationFunction<
  ResetUserCredentialsMutation,
  ResetUserCredentialsMutationVariables
>;

/**
 * __useResetUserCredentialsMutation__
 *
 * To run a mutation, you first call `useResetUserCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserCredentialsMutation, { data, loading, error }] = useResetUserCredentialsMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useResetUserCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetUserCredentialsMutation,
    ResetUserCredentialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetUserCredentialsMutation, ResetUserCredentialsMutationVariables>(
    ResetUserCredentialsDocument,
    options,
  );
}
export type ResetUserCredentialsMutationHookResult = ReturnType<
  typeof useResetUserCredentialsMutation
>;
export type ResetUserCredentialsMutationResult =
  Apollo.MutationResult<ResetUserCredentialsMutation>;
export type ResetUserCredentialsMutationOptions = Apollo.BaseMutationOptions<
  ResetUserCredentialsMutation,
  ResetUserCredentialsMutationVariables
>;
export const RolesListQueryDocument = gql`
  query RolesListQuery {
    rolesList {
      items {
        id
        name
      }
    }
  }
`;

/**
 * __useRolesListQueryQuery__
 *
 * To run a query within a React component, call `useRolesListQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesListQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesListQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesListQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<RolesListQueryQuery, RolesListQueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RolesListQueryQuery, RolesListQueryQueryVariables>(
    RolesListQueryDocument,
    options,
  );
}
export function useRolesListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RolesListQueryQuery, RolesListQueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RolesListQueryQuery, RolesListQueryQueryVariables>(
    RolesListQueryDocument,
    options,
  );
}
export type RolesListQueryQueryHookResult = ReturnType<typeof useRolesListQueryQuery>;
export type RolesListQueryLazyQueryHookResult = ReturnType<typeof useRolesListQueryLazyQuery>;
export type RolesListQueryQueryResult = Apollo.QueryResult<
  RolesListQueryQuery,
  RolesListQueryQueryVariables
>;
export const UserRolesQueryDocument = gql`
  query UserRolesQuery {
    user {
      id
      roles {
        items {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useUserRolesQueryQuery__
 *
 * To run a query within a React component, call `useUserRolesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRolesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRolesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRolesQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<UserRolesQueryQuery, UserRolesQueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserRolesQueryQuery, UserRolesQueryQueryVariables>(
    UserRolesQueryDocument,
    options,
  );
}
export function useUserRolesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserRolesQueryQuery, UserRolesQueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserRolesQueryQuery, UserRolesQueryQueryVariables>(
    UserRolesQueryDocument,
    options,
  );
}
export type UserRolesQueryQueryHookResult = ReturnType<typeof useUserRolesQueryQuery>;
export type UserRolesQueryLazyQueryHookResult = ReturnType<typeof useUserRolesQueryLazyQuery>;
export type UserRolesQueryQueryResult = Apollo.QueryResult<
  UserRolesQueryQuery,
  UserRolesQueryQueryVariables
>;
export const SchoolConfigurationExternalLinkCreateDocument = gql`
  mutation SchoolConfigurationExternalLinkCreate(
    $data: SchoolConfigurationExternalLinkCreateInput!
  ) {
    schoolConfigurationExternalLinkCreate(data: $data) {
      ...SchoolConfigurationExternalLinkItem
    }
  }
  ${SchoolConfigurationExternalLinkItemFragmentDoc}
`;
export type SchoolConfigurationExternalLinkCreateMutationFn = Apollo.MutationFunction<
  SchoolConfigurationExternalLinkCreateMutation,
  SchoolConfigurationExternalLinkCreateMutationVariables
>;

/**
 * __useSchoolConfigurationExternalLinkCreateMutation__
 *
 * To run a mutation, you first call `useSchoolConfigurationExternalLinkCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolConfigurationExternalLinkCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolConfigurationExternalLinkCreateMutation, { data, loading, error }] = useSchoolConfigurationExternalLinkCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSchoolConfigurationExternalLinkCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolConfigurationExternalLinkCreateMutation,
    SchoolConfigurationExternalLinkCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SchoolConfigurationExternalLinkCreateMutation,
    SchoolConfigurationExternalLinkCreateMutationVariables
  >(SchoolConfigurationExternalLinkCreateDocument, options);
}
export type SchoolConfigurationExternalLinkCreateMutationHookResult = ReturnType<
  typeof useSchoolConfigurationExternalLinkCreateMutation
>;
export type SchoolConfigurationExternalLinkCreateMutationResult =
  Apollo.MutationResult<SchoolConfigurationExternalLinkCreateMutation>;
export type SchoolConfigurationExternalLinkCreateMutationOptions = Apollo.BaseMutationOptions<
  SchoolConfigurationExternalLinkCreateMutation,
  SchoolConfigurationExternalLinkCreateMutationVariables
>;
export const SchoolConfigurationExternalLinkUpdateDocument = gql`
  mutation SchoolConfigurationExternalLinkUpdate(
    $data: SchoolConfigurationExternalLinkUpdateInput!
  ) {
    schoolConfigurationExternalLinkUpdate(data: $data) {
      ...SchoolConfigurationExternalLinkItem
    }
  }
  ${SchoolConfigurationExternalLinkItemFragmentDoc}
`;
export type SchoolConfigurationExternalLinkUpdateMutationFn = Apollo.MutationFunction<
  SchoolConfigurationExternalLinkUpdateMutation,
  SchoolConfigurationExternalLinkUpdateMutationVariables
>;

/**
 * __useSchoolConfigurationExternalLinkUpdateMutation__
 *
 * To run a mutation, you first call `useSchoolConfigurationExternalLinkUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolConfigurationExternalLinkUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolConfigurationExternalLinkUpdateMutation, { data, loading, error }] = useSchoolConfigurationExternalLinkUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSchoolConfigurationExternalLinkUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolConfigurationExternalLinkUpdateMutation,
    SchoolConfigurationExternalLinkUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SchoolConfigurationExternalLinkUpdateMutation,
    SchoolConfigurationExternalLinkUpdateMutationVariables
  >(SchoolConfigurationExternalLinkUpdateDocument, options);
}
export type SchoolConfigurationExternalLinkUpdateMutationHookResult = ReturnType<
  typeof useSchoolConfigurationExternalLinkUpdateMutation
>;
export type SchoolConfigurationExternalLinkUpdateMutationResult =
  Apollo.MutationResult<SchoolConfigurationExternalLinkUpdateMutation>;
export type SchoolConfigurationExternalLinkUpdateMutationOptions = Apollo.BaseMutationOptions<
  SchoolConfigurationExternalLinkUpdateMutation,
  SchoolConfigurationExternalLinkUpdateMutationVariables
>;
export const SchoolConfigurationLayoutUpdateDocument = gql`
  mutation SchoolConfigurationLayoutUpdate($data: SchoolConfigurationLayoutUpdateInput!) {
    schoolConfigurationLayoutUpdate(data: $data) {
      ...SchoolConfigurationLayoutItem
    }
  }
  ${SchoolConfigurationLayoutItemFragmentDoc}
`;
export type SchoolConfigurationLayoutUpdateMutationFn = Apollo.MutationFunction<
  SchoolConfigurationLayoutUpdateMutation,
  SchoolConfigurationLayoutUpdateMutationVariables
>;

/**
 * __useSchoolConfigurationLayoutUpdateMutation__
 *
 * To run a mutation, you first call `useSchoolConfigurationLayoutUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolConfigurationLayoutUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolConfigurationLayoutUpdateMutation, { data, loading, error }] = useSchoolConfigurationLayoutUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSchoolConfigurationLayoutUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolConfigurationLayoutUpdateMutation,
    SchoolConfigurationLayoutUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SchoolConfigurationLayoutUpdateMutation,
    SchoolConfigurationLayoutUpdateMutationVariables
  >(SchoolConfigurationLayoutUpdateDocument, options);
}
export type SchoolConfigurationLayoutUpdateMutationHookResult = ReturnType<
  typeof useSchoolConfigurationLayoutUpdateMutation
>;
export type SchoolConfigurationLayoutUpdateMutationResult =
  Apollo.MutationResult<SchoolConfigurationLayoutUpdateMutation>;
export type SchoolConfigurationLayoutUpdateMutationOptions = Apollo.BaseMutationOptions<
  SchoolConfigurationLayoutUpdateMutation,
  SchoolConfigurationLayoutUpdateMutationVariables
>;
export const SchoolConfigurationTopBarCreateDocument = gql`
  mutation SchoolConfigurationTopBarCreate($data: SchoolConfigurationTopBarCreateInput!) {
    schoolConfigurationTopBarCreate(data: $data) {
      id
    }
  }
`;
export type SchoolConfigurationTopBarCreateMutationFn = Apollo.MutationFunction<
  SchoolConfigurationTopBarCreateMutation,
  SchoolConfigurationTopBarCreateMutationVariables
>;

/**
 * __useSchoolConfigurationTopBarCreateMutation__
 *
 * To run a mutation, you first call `useSchoolConfigurationTopBarCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolConfigurationTopBarCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolConfigurationTopBarCreateMutation, { data, loading, error }] = useSchoolConfigurationTopBarCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSchoolConfigurationTopBarCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolConfigurationTopBarCreateMutation,
    SchoolConfigurationTopBarCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SchoolConfigurationTopBarCreateMutation,
    SchoolConfigurationTopBarCreateMutationVariables
  >(SchoolConfigurationTopBarCreateDocument, options);
}
export type SchoolConfigurationTopBarCreateMutationHookResult = ReturnType<
  typeof useSchoolConfigurationTopBarCreateMutation
>;
export type SchoolConfigurationTopBarCreateMutationResult =
  Apollo.MutationResult<SchoolConfigurationTopBarCreateMutation>;
export type SchoolConfigurationTopBarCreateMutationOptions = Apollo.BaseMutationOptions<
  SchoolConfigurationTopBarCreateMutation,
  SchoolConfigurationTopBarCreateMutationVariables
>;
export const SchoolConfigurationTopBarUpdateDocument = gql`
  mutation SchoolConfigurationTopBarUpdate($data: SchoolConfigurationTopBarUpdateInput!) {
    schoolConfigurationTopBarUpdate(data: $data) {
      id
    }
  }
`;
export type SchoolConfigurationTopBarUpdateMutationFn = Apollo.MutationFunction<
  SchoolConfigurationTopBarUpdateMutation,
  SchoolConfigurationTopBarUpdateMutationVariables
>;

/**
 * __useSchoolConfigurationTopBarUpdateMutation__
 *
 * To run a mutation, you first call `useSchoolConfigurationTopBarUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolConfigurationTopBarUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolConfigurationTopBarUpdateMutation, { data, loading, error }] = useSchoolConfigurationTopBarUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSchoolConfigurationTopBarUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolConfigurationTopBarUpdateMutation,
    SchoolConfigurationTopBarUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SchoolConfigurationTopBarUpdateMutation,
    SchoolConfigurationTopBarUpdateMutationVariables
  >(SchoolConfigurationTopBarUpdateDocument, options);
}
export type SchoolConfigurationTopBarUpdateMutationHookResult = ReturnType<
  typeof useSchoolConfigurationTopBarUpdateMutation
>;
export type SchoolConfigurationTopBarUpdateMutationResult =
  Apollo.MutationResult<SchoolConfigurationTopBarUpdateMutation>;
export type SchoolConfigurationTopBarUpdateMutationOptions = Apollo.BaseMutationOptions<
  SchoolConfigurationTopBarUpdateMutation,
  SchoolConfigurationTopBarUpdateMutationVariables
>;
export const SchoolConfigurationTopBarDeleteDocument = gql`
  mutation SchoolConfigurationTopBarDelete($data: SchoolConfigurationTopBarDeleteInput!) {
    schoolConfigurationTopBarDelete(data: $data) {
      success
    }
  }
`;
export type SchoolConfigurationTopBarDeleteMutationFn = Apollo.MutationFunction<
  SchoolConfigurationTopBarDeleteMutation,
  SchoolConfigurationTopBarDeleteMutationVariables
>;

/**
 * __useSchoolConfigurationTopBarDeleteMutation__
 *
 * To run a mutation, you first call `useSchoolConfigurationTopBarDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolConfigurationTopBarDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolConfigurationTopBarDeleteMutation, { data, loading, error }] = useSchoolConfigurationTopBarDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSchoolConfigurationTopBarDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolConfigurationTopBarDeleteMutation,
    SchoolConfigurationTopBarDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SchoolConfigurationTopBarDeleteMutation,
    SchoolConfigurationTopBarDeleteMutationVariables
  >(SchoolConfigurationTopBarDeleteDocument, options);
}
export type SchoolConfigurationTopBarDeleteMutationHookResult = ReturnType<
  typeof useSchoolConfigurationTopBarDeleteMutation
>;
export type SchoolConfigurationTopBarDeleteMutationResult =
  Apollo.MutationResult<SchoolConfigurationTopBarDeleteMutation>;
export type SchoolConfigurationTopBarDeleteMutationOptions = Apollo.BaseMutationOptions<
  SchoolConfigurationTopBarDeleteMutation,
  SchoolConfigurationTopBarDeleteMutationVariables
>;
export const SchoolConfigurationUpdateDocument = gql`
  mutation SchoolConfigurationUpdate($data: SchoolConfigurationUpdateInput!) {
    schoolConfigurationUpdate(data: $data) {
      id
    }
  }
`;
export type SchoolConfigurationUpdateMutationFn = Apollo.MutationFunction<
  SchoolConfigurationUpdateMutation,
  SchoolConfigurationUpdateMutationVariables
>;

/**
 * __useSchoolConfigurationUpdateMutation__
 *
 * To run a mutation, you first call `useSchoolConfigurationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolConfigurationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolConfigurationUpdateMutation, { data, loading, error }] = useSchoolConfigurationUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSchoolConfigurationUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolConfigurationUpdateMutation,
    SchoolConfigurationUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SchoolConfigurationUpdateMutation,
    SchoolConfigurationUpdateMutationVariables
  >(SchoolConfigurationUpdateDocument, options);
}
export type SchoolConfigurationUpdateMutationHookResult = ReturnType<
  typeof useSchoolConfigurationUpdateMutation
>;
export type SchoolConfigurationUpdateMutationResult =
  Apollo.MutationResult<SchoolConfigurationUpdateMutation>;
export type SchoolConfigurationUpdateMutationOptions = Apollo.BaseMutationOptions<
  SchoolConfigurationUpdateMutation,
  SchoolConfigurationUpdateMutationVariables
>;
export const Get_Discount_DataDocument = gql`
  query GET_DISCOUNT_DATA($discountIds: [ID!]) {
    discountsList(filter: { id: { in: $discountIds } }) {
      items {
        title
        amountPercent
        images {
          count
          items {
            id
            downloadUrl
          }
        }
        id
        type
      }
    }
  }
`;

/**
 * __useGet_Discount_DataQuery__
 *
 * To run a query within a React component, call `useGet_Discount_DataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Discount_DataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Discount_DataQuery({
 *   variables: {
 *      discountIds: // value for 'discountIds'
 *   },
 * });
 */
export function useGet_Discount_DataQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Discount_DataQuery, Get_Discount_DataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Discount_DataQuery, Get_Discount_DataQueryVariables>(
    Get_Discount_DataDocument,
    options,
  );
}
export function useGet_Discount_DataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Discount_DataQuery,
    Get_Discount_DataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Discount_DataQuery, Get_Discount_DataQueryVariables>(
    Get_Discount_DataDocument,
    options,
  );
}
export type Get_Discount_DataQueryHookResult = ReturnType<typeof useGet_Discount_DataQuery>;
export type Get_Discount_DataLazyQueryHookResult = ReturnType<typeof useGet_Discount_DataLazyQuery>;
export type Get_Discount_DataQueryResult = Apollo.QueryResult<
  Get_Discount_DataQuery,
  Get_Discount_DataQueryVariables
>;
export const GetFilesListDocument = gql`
  query GetFilesList($fileIds: [ID!]) {
    filesList(filter: { id: { in: $fileIds } }) {
      items {
        downloadUrl
        id
      }
    }
  }
`;

/**
 * __useGetFilesListQuery__
 *
 * To run a query within a React component, call `useGetFilesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilesListQuery({
 *   variables: {
 *      fileIds: // value for 'fileIds'
 *   },
 * });
 */
export function useGetFilesListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFilesListQuery, GetFilesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFilesListQuery, GetFilesListQueryVariables>(
    GetFilesListDocument,
    options,
  );
}
export function useGetFilesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFilesListQuery, GetFilesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFilesListQuery, GetFilesListQueryVariables>(
    GetFilesListDocument,
    options,
  );
}
export type GetFilesListQueryHookResult = ReturnType<typeof useGetFilesListQuery>;
export type GetFilesListLazyQueryHookResult = ReturnType<typeof useGetFilesListLazyQuery>;
export type GetFilesListQueryResult = Apollo.QueryResult<
  GetFilesListQuery,
  GetFilesListQueryVariables
>;
