import { Fragment, ReactNode, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  CheckCircle,
  DeleteForeverSharp,
  Edit,
  ForumTwoTone,
  Mail,
  PersonAdd,
  ArrowCircleUp,
  Forum,
  LockOpen,
} from '@mui/icons-material';

import { UserSchoolMembershipStatus, SORT } from '@jebel/constants';
import {
  canResetSchoolMembershipCredentials,
  canSchoolMemberBecomeAdministrator,
  canSchoolMembershipBeActivated,
  canSchoolMembershipBeApproved,
  canSchoolMembershipBeInactivated,
  canSchoolMembershipBeRejected,
  canSendFinalizeRegistrationAgain,
  createFilterBuilder,
  extractYear,
  formatTableDate,
} from '@jebel/utils';

import { Modal, ChooseStatusChipOption } from 'shared/components/ui';
import { APP_URL, USER_ACTIVITIES_OPTIONS } from 'shared/constants';
import { useSpreadsheetSearch } from 'shared/features/search';
import {
  Spreadsheet,
  SpreadsheetBulkActions,
  SpreadsheetCellActions,
} from 'shared/features/spreadsheet';
import { useSpreadsheetContext } from 'shared/features/spreadsheet/providers';
import {
  Maybe,
  UserKeyFilter,
  UserFilter,
  useResetUserCredentialsMutation,
  SchoolMembersReportQueryVariables,
  SchoolMembersReportQuery,
  SchoolMemberFragment,
} from 'shared/graphql';
import { buildUrl } from 'shared/routes';
import {
  useAudienceGraduatingYearsOptions,
  useDownloadLazyQueryCSV,
  useToast,
  useUserInvitation,
  useUserInvitationCSV,
  useSchoolConfiguration,
  useCanEditMembers,
  useCanAddMembers,
  useCanDeleteMembers,
} from 'shared/hooks';
import { useInboxContext } from 'providers/InboxProvider';
import { getFileNameWithTimestamp } from 'shared/utils/file';
import { useOrganizations } from 'features/organizations/hooks';
import { recordError } from 'shared/utils/record';

import {
  MembersReportHeaders,
  MembersSpreadsheetHeader,
  memberSpreadsheetFilters,
} from '../constants';
import { SCHOOL_MEMBERS_REPORT_QUERY } from '../queries';
import { MemberEditRolesModal } from './MemberEditRolesModal';
import { ChooseUserStatusChip } from './ChooseUserStatusChip';
import {
  useAcceptMember,
  useAcceptMembers,
  useActivateMember,
  useInactivateMember,
  usePromoteMember,
  useRejectMember,
  useRejectMembers,
  useResendMemberInvitation,
  useResendMembersInvitation,
} from '../hooks';
import { formatUserName } from 'shared/utils/user';
import { formatToPhone } from 'shared/utils/form';

const FALLBACK_RADIUS = '-';
const FALLBACK_START_POINT_ZIP = '';
const FALLBACK_SORT = { createdAt: SORT.desc };

const INVITING_CSV_MESSAGE = `Inviting imported users from CSV, please wait until the process finishes.`;
const INVITING_CSV_MESSAGE_KEY = `INVITING_CSV`;

export function MembersSpreadsheet() {
  const canAddMembers = useCanAddMembers();
  const canEditMembers = useCanEditMembers();
  const canDeleteMembers = useCanDeleteMembers();

  const { data: school } = useSchoolConfiguration();
  const { push: navigate } = useHistory();
  const { onOpenInboxModal } = useInboxContext();
  const { showError, showSuccess, showMessage, dismiss } = useToast();
  const { queryParams, sortOption, currentRowId, selected, chipsArray } =
    useSpreadsheetContext<SchoolMembersReportQueryVariables>();

  const { mutate: promoteMember } = usePromoteMember({
    refetchQueries: ['SchoolMembersReport', 'MemberStats'],
    awaitRefetchQueries: true,
  });

  const { mutate: acceptMember } = useAcceptMember({
    refetchQueries: ['SchoolMembersReport', 'MemberStats'],
    awaitRefetchQueries: true,
  });

  const { mutate: acceptMembers } = useAcceptMembers({
    refetchQueries: ['SchoolMembersReport', 'MemberStats'],
  });

  const { mutate: rejectMember } = useRejectMember({
    refetchQueries: ['SchoolMembersReport', 'MemberStats'],
    awaitRefetchQueries: true,
  });

  const { mutate: rejectMembers } = useRejectMembers({
    refetchQueries: ['SchoolMembersReport', 'MemberStats'],
  });

  const { mutate: activateMember } = useActivateMember({
    refetchQueries: ['SchoolMembersReport', 'MemberStats'],
    awaitRefetchQueries: true,
  });

  const { mutate: inactivateMember } = useInactivateMember({
    refetchQueries: ['SchoolMembersReport', 'MemberStats'],
    awaitRefetchQueries: true,
  });

  const { mutate: resendInvitation } = useResendMemberInvitation({
    refetchQueries: ['SchoolMembersReport', 'MemberStats'],
    awaitRefetchQueries: true,
  });

  const { mutate: resendInvitations } = useResendMembersInvitation({
    refetchQueries: ['SchoolMembersReport', 'MemberStats'],
  });

  const { inviteUsers } = useUserInvitation({
    refetchQueries: ['SchoolMembersReport', 'MemberStats'],
  });

  const { handle: handleUploadCSV } = useUserInvitationCSV({
    async onComplete(parsed) {
      showMessage(INVITING_CSV_MESSAGE, { id: INVITING_CSV_MESSAGE_KEY });

      try {
        await inviteUsers(parsed, true);

        showSuccess('Invitations have been sent successfully.');
      } catch (err) {
        recordError(err);

        if (err instanceof Error) {
          showError(err.message, { reportable: false });
        }
      } finally {
        dismiss(INVITING_CSV_MESSAGE_KEY);
      }
    },
  });

  const [editRolesModalMemberId, setEditRolesModalMemberId] = useState<string>();
  const [isEditRolesModalOpen, setIsEditRolesModalOpen] = useState(false);

  const onEditRolesModalOpen = () => {
    setIsEditRolesModalOpen(true);
  };

  const onEditRolesModalClose = () => {
    setIsEditRolesModalOpen(false);
  };

  const withCustomActivityFilter = useMemo(
    () => chipsArray.hobbies.length || chipsArray.clubs.length,
    [chipsArray.clubs.length, chipsArray.hobbies.length],
  );

  const { tableData, tableLoading, queryVariables } = useSpreadsheetSearch<
    SchoolMembersReportQuery,
    SchoolMembersReportQueryVariables
  >({
    skip: !school,
    query: SCHOOL_MEMBERS_REPORT_QUERY,
    searchingFields: ['user.fullName', 'user.firstName', 'user.lastName', 'user.email'],
    queryVariables: {
      ...queryParams,

      school: { id: school?.id },
      startPointZip: queryParams.startPointZip ?? FALLBACK_START_POINT_ZIP,
      radius: queryParams.radius ?? FALLBACK_RADIUS,
      sort: queryParams.sort ?? FALLBACK_SORT,
    },
  });

  const bulkFilter: UserFilter = useMemo(() => {
    const filter = createFilterBuilder<UserFilter>(queryVariables.filter);

    if (selected.length > 0) {
      // Exclude the others by filter with selected IDs.
      return { id: { in: selected } };
    }

    return filter.build();
  }, [queryVariables, selected]);

  const [generateCSV] = useDownloadLazyQueryCSV<
    SchoolMembersReportQuery,
    SchoolMembersReportQueryVariables
  >(SCHOOL_MEMBERS_REPORT_QUERY, {
    filename: getFileNameWithTimestamp('Members.csv'),

    variables: {
      school: { id: school?.id },
      startPointZip: queryVariables.startPointZip ?? FALLBACK_START_POINT_ZIP,
      radius: queryVariables.radius ?? FALLBACK_RADIUS,
      filter: bulkFilter,
    },

    transform(response) {
      const members = response?.members?.items ?? [];
      return transformExport(members);
    },
  });

  const [resetUserCredentials] = useResetUserCredentialsMutation();

  const { data: graduatingYears } = useAudienceGraduatingYearsOptions();
  const { data: organizations } = useOrganizations({ variables: { sort: { name: SORT.asc } } });

  const promoteUser = async (member: UserKeyFilter) => {
    await promoteMember(member);
  };

  const getAvailableActions = (member?: SchoolMemberFragment) => {
    const withApproveAction = canSchoolMembershipBeApproved(member?.status);
    const withActivateAction = canSchoolMembershipBeActivated(member?.status);
    const withInactivateAction = canSchoolMembershipBeInactivated(member?.status);
    const withRejectAction = canSchoolMembershipBeRejected(member?.status);
    const withResetCredentials = canResetSchoolMembershipCredentials(member?.status);
    const withResendInvitation = canSendFinalizeRegistrationAgain(member?.status);

    const withPromoteToAdmin = canSchoolMemberBecomeAdministrator(
      member?.status,
      member?.systemRoles,
    );

    return {
      withRejectAction,
      withActivateAction,
      withInactivateAction,
      withApproveAction,
      withResendInvitation,
      withResetCredentials,
      withPromoteToAdmin,
    };
  };

  const onActivate = async (id: string) => {
    const user = members.find(user => user.id === id);
    // Show the message with the email instead of ID.
    await activateMember({ email: user?.email });
  };

  const onInactivate = async (id: string) => {
    const user = members.find(user => user.id === id);
    // Show the message with the email instead of ID.
    await inactivateMember({ email: user?.email });
  };

  const onApproveAction = async (id: string) => {
    const user = members.find(user => user.id === id);

    // Show the message with the email instead of ID.
    await acceptMember({ email: user?.email });
  };

  const onSendInvitation = async (id: string) => {
    const user = members.find(user => user.id === id);

    // Show the message with the email instead of ID.
    await resendInvitation({ email: user?.email });
  };

  const onRejectAction = async (id: string) => {
    const user = members.find(user => user.id === id);

    // Show the message with the email instead of ID.
    await rejectMember({ email: user?.email });
  };

  const onResetCredentials = async (id: string) => {
    if (!currentMember) {
      return;
    }

    const RESETTING_CREDENTIALS_MESSAGE_KEY = `RESETTING_CREDENTIALS_${id}`;

    showMessage(`Resetting the account credentials for "${currentMember.email}".`, {
      id: RESETTING_CREDENTIALS_MESSAGE_KEY,
    });

    try {
      await resetUserCredentials({ variables: { user: { id } } });

      showSuccess(
        `The account credentials for "${currentMember.email}" have been successfully reset. Instructions will be sent to the user via email.`,
      );
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message, { reportable: false });
      }
    } finally {
      dismiss(RESETTING_CREDENTIALS_MESSAGE_KEY);
    }
  };

  const filterClubs = (
    chipsArray: {
      hobbies: string[];
      clubs: string[];
    },
    member: SchoolMemberFragment,
  ) => {
    return (
      !chipsArray.clubs.length ||
      chipsArray?.clubs?.some(club =>
        member?.activityClubs?.includes(
          USER_ACTIVITIES_OPTIONS.clubs.find(({ label }) => label === club)?.value || '',
        ),
      )
    );
  };

  const filterHobbies = (
    chipsArray: {
      hobbies: string[];
      clubs: string[];
    },
    member: SchoolMemberFragment,
  ) => {
    return (
      !chipsArray.hobbies.length ||
      chipsArray?.hobbies?.some(hobby =>
        member?.hobbies?.includes(
          USER_ACTIVITIES_OPTIONS.hobbies.find(({ label }) => label === hobby)?.value || '',
        ),
      )
    );
  };

  const getMemberStatusComponent = (member: SchoolMemberFragment) => {
    const options: ChooseStatusChipOption[] = [];

    const {
      withApproveAction,
      withActivateAction,
      withInactivateAction,
      withRejectAction,
      withResendInvitation,
    } = getAvailableActions(member);

    if (withResendInvitation) {
      options.push({
        label: 'Re-send Invitation',
        value: UserSchoolMembershipStatus.InvitationSent,
        onClick: () => onSendInvitation(member.id),
      });
    }

    if (withActivateAction && canEditMembers) {
      options.push({
        label: 'Activate',
        value: UserSchoolMembershipStatus.Active,
        onClick: () => onActivate(member.id),
      });
    }

    if (withInactivateAction && canEditMembers) {
      options.push({
        label: 'Inactivate',
        value: UserSchoolMembershipStatus.Inactive,
        onClick: () => onInactivate(member.id),
      });
    }

    if (withApproveAction && canEditMembers) {
      options.push({
        label: 'Approve Request',
        value: UserSchoolMembershipStatus.InvitationSent,
        onClick: () => onApproveAction(member.id),
      });
    }

    if (withRejectAction && canDeleteMembers) {
      options.push({
        label: 'Reject',
        value: UserSchoolMembershipStatus.Rejected,
        onClick: () => onRejectAction(member.id),
      });
    }

    return <ChooseUserStatusChip status={member.status} options={options} />;
  };

  const members = useMemo(() => {
    const members: NormalizedSchoolMember[] = [];
    const response = tableData?.members.items ?? [];

    for (const data of response) {
      if (!filterClubs(chipsArray, data) || !filterHobbies(chipsArray, data)) {
        // Filter "clubs" and "hobbies" manually
        // Because of the lack of support for filters on array types.
        continue;
      }

      const member = normalizeMember(data);
      member.status = getMemberStatusComponent(data);

      members.push(member);
    }

    return members;
  }, [tableData, sortOption, getMemberStatusComponent]);

  const currentMember = useMemo(() => {
    const response = tableData?.members.items ?? [];
    return response.find(user => user.id === currentRowId);
  }, [tableData, currentRowId]);

  const spreadsheetActions = useMemo((): SpreadsheetCellActions => {
    const {
      withApproveAction,
      withActivateAction,
      withInactivateAction,
      withRejectAction,
      withResendInvitation,
      withResetCredentials,
      withPromoteToAdmin,
    } = getAvailableActions(currentMember);

    const options: SpreadsheetCellActions = [
      {
        id: 'view_details',
        title: 'View Details',
        icon: <Edit fontSize="inherit" color="inherit" />,

        onClickAction: (id: string) => {
          navigate(buildUrl(APP_URL.admin.members.information, { pathParams: { id } }));
        },
      },
    ];

    if (withPromoteToAdmin) {
      options.push({
        id: 'promote_to_admin',
        title: 'Promote to Admin',
        icon: <ArrowCircleUp fontSize="inherit" color="inherit" />,

        onClickAction: (id: string) => promoteUser({ id }),
      });
    }

    if (withActivateAction && canEditMembers) {
      options.push({
        id: 'activate',
        title: 'Activate',
        icon: <CheckCircle fontSize="inherit" color="inherit" />,

        onClickAction: onActivate,
      });
    }

    if (withInactivateAction && canEditMembers) {
      options.push({
        id: 'inactivate',
        title: 'Inactivate',
        icon: <DeleteForeverSharp fontSize="inherit" color="inherit" />,

        onClickAction: onInactivate,
      });
    }

    if (withApproveAction && canEditMembers) {
      options.push({
        id: 'approve_request',
        title: 'Approve Request',
        icon: <CheckCircle fontSize="inherit" color="inherit" />,

        onClickAction: onApproveAction,
      });
    }

    if (withResetCredentials) {
      options.push({
        id: 'reset_credentials',
        title: 'Reset Account Credentials',
        icon: <LockOpen fontSize="inherit" color="inherit" />,

        onClickAction: onResetCredentials,
      });
    }

    if (withInactivateAction && canEditMembers) {
      options.push({
        id: 'edit_roles',
        title: 'Edit User Roles',
        icon: <PersonAdd fontSize="inherit" color="inherit" />,

        onClickAction: (id: string) => {
          onEditRolesModalOpen();
          setEditRolesModalMemberId(id);
        },
      });
    }

    if (withInactivateAction) {
      options.push({
        id: 'message',
        title: 'Message',
        icon: <ForumTwoTone fontSize="inherit" color="inherit" />,

        onClickAction: (id: string) => {
          const selected = members.filter(user => user.id === id);

          if (onOpenInboxModal) {
            onOpenInboxModal({
              isOpen: true,
              options: {
                members: selected,
                messageType: 'personal',
              },
            });
          }
        },
      });
    }

    if (withRejectAction && canDeleteMembers) {
      options.push({
        id: 'reject',
        title: 'Reject',
        icon: <DeleteForeverSharp fontSize="inherit" color="inherit" />,

        onClickAction: onRejectAction,
      });
    }

    if (withResendInvitation) {
      options.push({
        id: 'ResendInvitation',
        title: 'Re-send Invitation',
        icon: <Mail fontSize="inherit" color="inherit" />,

        onClickAction: onSendInvitation,
      });
    }

    return options;
  }, [
    currentMember,
    getAvailableActions,
    canEditMembers,
    canAddMembers,
    onApproveAction,
    canDeleteMembers,
    onRejectAction,
    promoteUser,
    members,
    onOpenInboxModal,
  ]);

  const bulkActions: SpreadsheetBulkActions = useMemo(() => {
    const actions: SpreadsheetBulkActions = [
      {
        id: 'message',
        icon: <Forum />,
        label: 'Message',
        disabled: tableLoading || selected.length === 0,

        onClick(ids) {
          const selected = members.filter(user => ids.includes(user.id as string));

          if (onOpenInboxModal) {
            onOpenInboxModal({
              isOpen: true,
              options: { members: selected, messageType: 'personal' },
            });
          }
        },
      },
      {
        id: 'resend',
        icon: <Mail />,
        disabled: tableLoading,

        label:
          selected.length === 0 ? 'Re-send invitations' : `Re-send ${selected.length} invitations`,

        description:
          selected.length === 0
            ? 'Re-send invitations to all "Invitation Sent" users'
            : 'Re-send invitation to selected users.',

        onClick() {
          resendInvitations(bulkFilter);
        },
      },
      {
        id: 'approve',
        icon: <CheckCircle />,
        disabled: tableLoading,

        label: selected.length === 0 ? 'Approve all' : `Approve ${selected.length} users`,

        description:
          selected.length === 0
            ? 'Approve all "Pending" users'
            : 'Approve selected "Pending" users.',

        onClick() {
          acceptMembers(bulkFilter);
        },
      },
      {
        id: 'reject',
        icon: <DeleteForeverSharp />,
        disabled: tableLoading,

        label: selected.length === 0 ? 'Reject all' : `Reject ${selected.length} users`,

        description:
          selected.length === 0 ? 'Reject all "Pending" users' : 'Reject selected "Pending" users.',

        onClick() {
          rejectMembers(bulkFilter);
        },
      },
    ];

    return actions;
  }, [selected, members, bulkFilter, tableLoading]);

  return (
    <Fragment>
      <div css={containerCSS}>
        <Spreadsheet
          data={members}
          headlines={MembersSpreadsheetHeader}
          toolbarOptions={{
            filters: memberSpreadsheetFilters({ organizations, graduatingYears }),
            bulkActions,
            withPerPage: !withCustomActivityFilter,
            withDownload: true,
            downloadHandler: generateCSV,
            rawData: tableData?.members?.items ?? [],
            withUpload: true,
            withSearch: true,
            onUpload: handleUploadCSV,
          }}
          cellActions={spreadsheetActions}
          itemsCount={tableData?.members?.count ?? 0}
          loading={tableLoading}
        />
      </div>

      <Modal
        dialogProps={{ open: isEditRolesModalOpen, onClose: onEditRolesModalClose }}
        titleProps={{ title: 'Edit User Roles' }}
      >
        <MemberEditRolesModal
          memberId={editRolesModalMemberId}
          onModalClose={onEditRolesModalClose}
        />
      </Modal>
    </Fragment>
  );
}

const containerCSS = css`
  display: grid;
  grid-template-columns: 1fr;

  & > * {
    min-width: 0;
  }
`;

export const getStatus = (status?: Maybe<string>) => {
  if (!status || status === UserSchoolMembershipStatus.Pending) {
    return 'pending';
  }

  if (status === UserSchoolMembershipStatus.InvitationSent) {
    return 'invited';
  }

  return status;
};

/** @deprecated Use `ChooseUserStatusChip` instead. */
export const getUserStatusComponent = (status: Maybe<string> | undefined) => {
  return <ChooseUserStatusChip status={getStatus(status)} />;
};

interface NormalizedSchoolMember {
  id: string;
  name: string;
  email: string;
  type: string | null;
  gender: string | null;
  birthDate: string;
  graduatingYear: number | null;
  phoneNumber: string;
  posts: number;
  roles: string;
  status: string | ReactNode;
  createdAt: string;
}

function normalizeMember(member: SchoolMemberFragment): NormalizedSchoolMember {
  const rolesNames = member.roleAddons.map(role => role.name ?? 'UNKNOWN').join(', ');
  const postCount = member.groupPostsCount + member.homePostsCount;

  const phoneNumber = member.phoneNumber ? formatToPhone(member.phoneNumber) : '';

  return {
    id: member.id as string,
    name: formatUserName(member),
    email: member.email,
    type: member.affiliation ?? null,
    gender: member.gender ?? null,
    birthDate: member?.birthDate ? formatTableDate(member.birthDate) : '',
    graduatingYear: extractYear(member.graduatingYear),
    phoneNumber,
    posts: postCount,
    roles: rolesNames,
    status: member.status ?? UserSchoolMembershipStatus.Pending,
    createdAt: member.createdAt ? formatTableDate(member.createdAt) : '',
  };
}

function transformExport(members: SchoolMemberFragment[]) {
  return members.map(member => {
    const rolesNames = member.roleAddons.map(role => role.name ?? 'UNKNOWN').join(', ');
    const postCount = member.groupPostsCount + member.homePostsCount;

    const memberSince = member.registrationCompletedAt
      ? formatTableDate(member.registrationCompletedAt)
      : '';

    const phoneNumber = member.phoneNumber ? formatToPhone(member.phoneNumber) : '';

    return {
      [MembersReportHeaders.name]: formatUserName(member),
      [MembersReportHeaders.email]: member.email,
      [MembersReportHeaders.type]: member.affiliation,
      [MembersReportHeaders.gender]: member.gender,
      [MembersReportHeaders.birthDate]: member?.birthDate ? formatTableDate(member?.birthDate) : '',
      [MembersReportHeaders.GraduatingYear]: extractYear(member.graduatingYear),
      [MembersReportHeaders.PhoneNumber]: phoneNumber,
      [MembersReportHeaders.posts]: postCount,
      [MembersReportHeaders.roles]: rolesNames,
      [MembersReportHeaders.createdOn]: member.createdAt ? formatTableDate(member.createdAt) : '',
      [MembersReportHeaders.status]: member.status ?? UserSchoolMembershipStatus.Pending,
      [MembersReportHeaders.MemberSince]: memberSince,
    };
  });
}
